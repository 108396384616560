import { APIResponseCode, ValidatorErrors } from 'common/constants';
import { APIResponse } from 'common/utils/jsonFetch';
import { IsNRIC, IsNRICOrFIN } from 'common/utils/utility';
import { IterableElement } from 'store/rootTypes';

//TODO: To enhance this validation
function ThrowError(error: string) {
  throw Error(error);
}

export const NotUndefined = (val: any) => {
  // Use juggling-check == to test both null & undefined in 1 condition statement.
  if (val == null) {
    ThrowError(ValidatorErrors.UNDEFINED_VALUE);
  }
  return val;
};

class Validator {
  private param: any;

  constructor(val: any = '') {
    this.param = val;
  }

  GetParam() {
    return this.param;
  }

  IsNRIC() {
    if (this.param && !IsNRIC(this.param)) {
      ThrowError(ValidatorErrors.INVALID_NRIC);
    }
    return this;
  }
  IsNRICOrFIN() {
    if (this.param && !IsNRICOrFIN(this.param)) {
      ThrowError(ValidatorErrors.INVALID_NRIC_OR_FIN);
    }
    return this;
  }

  MaxLength(max = 0) {
    if (this.param.length > max) {
      ThrowError(ValidatorErrors.MAX_LENGTH);
    }
    return this;
  }

  MaxLengthIfNotNull(max = 0) {
    if (this.param === null || this.param === undefined || this.param === '') {
      return this;
    }
    if (this.param.length > max) {
      ThrowError(ValidatorErrors.MAX_LENGTH);
    }
    return this;
  }

  MinLength(min = 0) {
    if (this.param.length < min) {
      ThrowError(ValidatorErrors.MIN_LENGTH);
    }
    return this;
  }

  MinValue(min = 0) {
    if (this.param < min) {
      ThrowError(ValidatorErrors.MIN_VALUE);
    }
    return this;
  }

  MaxValue(max = 0) {
    if (this.param > max) {
      ThrowError(ValidatorErrors.MAX_VALUE);
    }
    return this;
  }

  NotNull() {
    if (this.param === null || this.param === undefined || this.param === '') {
      ThrowError(ValidatorErrors.EMPTY_NULL);
    }
    return this;
  }

  IsFalse() {
    if (this.param) {
      ThrowError(ValidatorErrors.NOT_FALSE);
    }
    return this;
  }

  StringContext(val: string) {
    this.param = val;
    return this;
  }

  ArrayNotEmpty() {
    if (this.param.length == 0) {
      ThrowError(ValidatorErrors.ARRAY_NOT_EMPTY);
    }
    return this;
  }

  InListOfStrings(listOfItems: string[]) {
    if (typeof this.param !== 'string' || !listOfItems.includes(this.param)) {
      ThrowError(ValidatorErrors.NOT_IN_LIST);
    }
    return this;
  }

  MatchRegexPattern(regexPattern: RegExp) {
    if (!regexPattern.test(this.param)) {
      ThrowError(ValidatorErrors.INVALID_REGEX_MATCH);
    }
    return this;
  }

  InEnum(en: Record<string, string | number>) {
    if (!Object.values(en).includes(this.param)) {
      ThrowError(ValidatorErrors.NOT_TRUE);
    }
    return this;
  }

  IsNotEquals(value: string) {
    if (typeof this.param !== 'string' || this.param.toLowerCase() === value.toLowerCase()) {
      ThrowError(ValidatorErrors.NOT_TRUE);
    }
    return this;
  }

  IsEqualsString(value: string, errorMessage: string = ValidatorErrors.NOT_TRUE) {
    if (typeof this.param !== 'string' || this.param.toLowerCase() !== value.toLowerCase()) {
      ThrowError(errorMessage);
    }
    return this;
  }

  ContainsWhitespace() {
    if (typeof this.param !== 'string') {
      ThrowError(ValidatorErrors.NOT_STRING);
    }

    const input = this.param;

    for (let i = 0; i < input.length; i++) {
      if (input[i] === ' ' || input[i] === '\t' || input[i] === '\n' || input[i] === '\r' || input[i] === '\f') {
        ThrowError(ValidatorErrors.CONTAINS_WHITESPACE);
      }
    }

    return this;
  }

  InCollection(col: IterableElement[], errorMessage: string = ValidatorErrors.NOT_EXIST_COLLECTION) {
    if (!col.some((x) => x.id === this.param)) {
      ThrowError(errorMessage);
    }
    return this;
  }

  ValidateResponse(response: APIResponse) {
    new Validator(response).NotNull();
    new Validator(response?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    new Validator(response.Results).NotNull();
    return this;
  }

  ValidateResponseNoResultRequired(response: APIResponse) {
    new Validator(response).NotNull();
    new Validator(response?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    return this;
  }
}
export { Validator };
