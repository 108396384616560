import { Button, Col, DatePicker, Form, Input, InputNumber, Row } from 'antd';
import { useHistory } from 'react-router';

import { Constants } from 'common/constants';
import 'App.css';
import TextArea from 'antd/es/input/TextArea';
import { PATHS } from '@routing/routes';

export const AddExpenses = () => {
  const history = useHistory();

  const onFinish = async (values: any) => {
    console.error('SelectedId is undefined');
  };

  return (
    <div className="App ">
      {/* <Row justify="center" align="middle">
        <h1>Add expenses </h1>
      </Row> */}

      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={14} lg={10} xl={8} className="">
            <h1 className="headermaintext">Add expenses </h1>
            <Form.Item label="Date" name="Date" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <DatePicker size="large" className="full-width" placeholder="Date" />
            </Form.Item>
            <Form.Item label="Amount" name="Amount" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <InputNumber<string>
                min="0"
                max="1000000"
                step={0.01}
                stringMode
                size="large"
                precision={2}
                className="full-width"
                placeholder="0.00"
              />
            </Form.Item>
            <Form.Item label="Details" name="Details" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <TextArea size="large" maxLength={500} />
            </Form.Item>
            <br />
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(PATHS.Expenses.path)}
              >
                {Constants.BTN_BACK}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                {Constants.BTN_SUBMIT}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
