export abstract class Constants {
  static readonly Token: string = 'token';
  static readonly RefreshToken: string = 'refreshToken';
  static readonly IdToken: string = 'idtoken';
  static readonly Signature_Required = 'Signature is required';
  static readonly NoSelectedReceiver = 'NoSelectedReceiver';
  static readonly NoSelectedReceiverText = 'No selected receiver';
  //#region General Section
  /* General Section */
  static readonly KFS_PAGE_TITLE: string = 'KFS online';
  static readonly ERROR_INVALID_LOGIN: string = 'Login failed. Please check your credentials.';
  static readonly ERROR_EMAIL_FORMAT: string = 'Please enter a valid email address';
  static readonly ERROR_PASSWORD_FORMAT: string =
    'Passwords should be a minimum of 8 characters long and contain a mix of numbers, special characters, uppercase, and lowercase letters.';
  static readonly PASSWORD_REGEX =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-={}[\]|\\:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+\-={}[\]|\\:;"'<>,.?/]{8,}$/;
  static readonly ERROR_PASSWORD_ssFORMAT: string = '! @ # $ % ^ & * ( ) _ + - = { } [ ] | \\ : ; " \' < > , . ? /';

  static readonly LOGOUT_LABEL: string = 'Log Out';
  static readonly PRPP_DATE_ONLY_FORMAT: string = 'DD MMM YYYY';
  static readonly DATE_TIME_FORMAT: string = 'DD MMM YYYY hh:mm A';
  static readonly DATE_TIME_FORMAT_NUM_MONTH: string = 'DD/MM/YYYY hh:mm:ss A';
  static readonly FHIR_DATE_ONLY_FORMAT: string = 'YYYY-MM-DD';
  static readonly NRIC_START_WITH_REGEX: RegExp = /^[SsTt]/;
  static readonly FIN_START_WITH_REGEX: RegExp = /^[FfGgMm]/;
  static readonly NRIC_REGEX: RegExp = /^[SsTtFfGgMm]\d{7}[A-Za-z]$/;
  static readonly REQUIRED_FIELD = 'This field is required';
  static readonly KEYBOARD_CHAR_ONLY_REGEX = /^[a-zA-Z0-9.!@?#"$%&:';()*+,/\-=[\\\]^_{|}<>~`\t\n ]*$/;
  static readonly ALPHA_NUMERIC_ONLY_REGEX = /^[a-zA-Z0-9]*$/;
  static readonly MOBILE_NUMBER_REGEX = /^[89]\d{7}$$/;

  static readonly MODAL_BTN_TEXT_SAVE: string = 'Save';
  static readonly MODAL_BTN_TEXT_CONFIRM: string = 'Confirm';
  static readonly MODAL_BTN_TEXT_PROCEED: string = 'Proceed';
  static readonly MODAL_BTN_TEXT_CANCEL: string = 'Cancel';

  static readonly FILTER_ICON_ACTIVE_COLOUR: string = '#1890ff';
  static readonly FILTER_SEARCH_TEXT_HIGHLIGHT_COLOUR: string = '#ffc069';
  static readonly FILTER_MIN_PAGE_NO: number = 1;
  static readonly FILTER_MIN_PAGE_SIZE: number = 10;
  //#endregion

  //Groups Section
  static readonly GROUPS_NAME_REGEX: RegExp = /^[A-Za-z_]+$/;

  //Module Access Section
  static readonly MODULE_ACCESS_ACCESS_REGEX: RegExp = /^[A-Za-z_]+$/;
  //#endregion

  static readonly METHOD_ACCESS_SETTINGS_METHOD_NAME_REGEX: RegExp = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

  static readonly GENERIC_REGEX_NUMBERS_ONLY: RegExp = /^\d+$/;

  static readonly MAX_INT_32: number = 2147483647;
  static readonly MAX_9999: number = 9999;
  static readonly MIN_1: number = 1;

  static readonly GENERIC_REGEX_INPUT_ALPHANUM_DASH: RegExp = /^[\w\- ]+$/;
  static readonly GENERIC_REGEX_INPUT_ALPHANUM_DASH_INVALID = 'Input should only contain a-z A-Z 0-9 _ and -';

  static readonly SG_COUNTRY_CODE = '65';

  //#region Common Regex
  static readonly PATIENT_NAME_REGEX_INPUT: RegExp = /^[a-zA-Z][a-zA-Z '-.‘’]+$/;
  static readonly INPUT_INVALID: string = 'Please input a valid value';
  static readonly EMAIL_REGEX_INPUT: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //#endregion
  static readonly BTN_CONTINUE: string = 'Continue';
  static readonly BTN_DELIVERED: string = 'Update to delivered';
  static readonly BTN_DELETE: string = 'Delete';
  static readonly BTN_CANCEL_ORDERBOX: string = 'Cancel order';
  static readonly BTN_CANCEL: string = 'Cancel';
  static readonly BTN_CANCEL_BOOKING: string = 'Cancel Booking';
  static readonly BTN_SELECTRECEIVER: string = 'Select receiver';
  static readonly BTN_UPDATE: string = 'Update';
  static readonly BTN_SAVE: string = 'Save';
  static readonly BTN_SUBMIT: string = 'Submit';
  static readonly BTN_BACK: string = 'Back';
  static readonly BTN_ADD_Barcode: string = 'Add barcode';
  static readonly BTN_RESET: string = 'Reset';
  static readonly BTN_VIEW: string = 'View';
  static readonly BTN_CLOSE: string = 'Close';
  static readonly BTN_SEARCH: string = 'Search';
  static readonly BTN_COMPLETE_BOOKING: string = 'Complete booking';
  static readonly KFSBOX_EMAIL: string = 'info@kfsbox.com';
  static readonly KFSBOX_MOBILE: string = '03 9969 2205 / 0413 275 288';
  static readonly KFSBOX_BookPickupHeader: string = 'Book a pick up';
  static readonly KFSBOX_BookPickupKFSHeader: string = 'Book a pick up';
}

export abstract class MessageDisplay {
  static readonly PROMPT_PROCEED_WITHOUT_SAVE = (
    <>
      Are you sure you want to leave this page?
      <br />
      All changes you have made will be lost.
    </>
  );

  //Generic
  static readonly GENERIC_TECHNICAL_ERROR = (
    <>
      Oops! Something went wrong on our end. <br />
      Please try again later or contact our support.
    </>
  );
  static readonly EMPTY_NO_RECORDS_FOUND = 'No records found';
}

export abstract class APIResponseCode {
  static readonly BAD_REQUEST: number = 400;
  static readonly NOT_AUTHORIZED: number = 401;
  static readonly NOT_FOUND: number = 404;
  static readonly METHOD_NOT_ALLOWED: number = 405;

  static readonly SUCCESS: string = 'S';
  static readonly ERROR: string = 'E';
}

export abstract class ValidatorErrors {
  static readonly MAX_LENGTH: string = 'Invalid max length';
  static readonly MIN_LENGTH: string = 'Invalid min length';
  static readonly EMPTY_NULL: string = 'Empty/Null value';
  static readonly UNDEFINED_VALUE: string = 'Undefined Value';
  static readonly NOT_BOOLEAN: string = 'This input is not typeof boolean';
  static readonly NOT_NUMBER: string = 'This input is not typeof number';
  static readonly INVALID_NRIC: string = 'Invalid NRIC';
  static readonly INVALID_NRIC_OR_FIN: string = 'Invalid NRIC or FIN';
  static readonly INVALID_REGEX_MATCH: string = 'Input does not match regex pattern';
  static readonly ARRAY_NOT_EMPTY: string = 'Array length is 0';
  static readonly IS_FUTURE_DATE: string = 'Input Date is invalid(future date).';
  static readonly IS_PAST_DATE: string = 'Input Date is invalid(past date).';
  static readonly IS_TODAY: string = 'Input Date is invalid(today).';
  static readonly IS_FUTURE_DATERANGE: string = 'Input Daterange is invalid(future date).';
  static readonly MAX_VALUE: string = 'Exceeds max value';
  static readonly MIN_VALUE: string = 'Below min value';
  static readonly NOT_IN_LIST: string = 'Invalid fixed string value';
  static readonly EXCEED_MAX_VALUE: string = 'Input value exceeded max value';
  static readonly NOT_TRUE: string = 'Value is not true';
  static readonly NOT_FALSE: string = 'Value is not false';
  static readonly CONTAINS_WHITESPACE: string = 'Value contains whitespace';
  static readonly NOT_STRING: string = 'Value is not type string';
  static readonly NOT_EXIST_COLLECTION: string = 'Not exist in collection';
}

export abstract class SenderStatus {
  static readonly New: string = 'N';
  static readonly Update: string = 'U';
  static readonly Booking: string = 'B';
}

export abstract class ReceiverStatus {
  static readonly New: string = 'N';
  static readonly Update: string = 'U';
  static readonly Booking: string = 'B';
}

export abstract class BookingStatus {
  static readonly Idle: string = 'I';
  static readonly New: string = 'N';
  static readonly Update: string = 'U';
}

export abstract class FilterType {
  static readonly RefNo: string = 'R';
  static readonly Email: string = 'E';
  static readonly Date: string = 'D';
}

export abstract class DateRangeOption {
  static readonly Pickup: string = 'P';
  static readonly Booking: string = 'B';
}

export abstract class PageSize {
  static readonly TransactionHistory: number = 10;
}

export abstract class PaymentStatus {
  static readonly Paid: string = 'P';
  static readonly Unpaid: string = 'UP';
  static readonly PartiallyPaid: string = 'PP';
}

export abstract class FromPageStatus {
  static readonly Invoice: string = 'I';
  static readonly Barcode: string = 'B';
}

export abstract class Roles {
  static readonly Customer: string = 'Customer';
  static readonly Agent: string = 'Agent';
  static readonly Admin: string = 'Admin';
  static readonly BackOffice: string = 'BackOffice';
  static readonly Warehouse: string = 'Warehouse';
}

export abstract class TransactionStatus {
  static readonly NW: string = 'NW';
  static readonly PI: string = 'PI';
  static readonly ITRA: string = 'ITRA';
  static readonly ITP: string = 'ITP';
  static readonly ITIS: string = 'ITIS';
  static readonly ITD: string = 'ITD';
  static readonly ITAD: string = 'ITAD';
  static readonly ITDDP: string = 'ITDDP';
  static readonly ITADP: string = 'ITADP';
  static readonly ITWD: string = 'ITWD';
  static readonly DE: string = 'DE';
  static readonly CA: string = 'CA';
  static readonly FD: string = 'FD';
  static readonly ITWHIPH: string = 'ITWHIPH';
}

export abstract class TransactionType {
  static readonly OrderBox: string = 'OB';
  static readonly Booking: string = 'BI';
}

export abstract class TransactionBoxStatus {
  static readonly NW: string = 'NW';
  static readonly DE: string = 'DE';
  static readonly CA: string = 'CA';
  static readonly FD: string = 'FD';
}
