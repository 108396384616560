interface DestinationCode {
  name: string;
  code: string;
}

export const DestinationCodeCodeList: DestinationCode[] = [
  {
    name: 'Metro Manila',
    code: 'MNL',
  },
  {
    name: 'Luzon',
    code: 'LUZ',
  },
  {
    name: 'Visayas',
    code: 'VIS',
  },
  {
    name: 'Mindanao',
    code: 'MIN',
  },
];

export const ContainerFromDepartureCodeList: DestinationCode[] = [
  {
    name: 'Melbourne',
    code: 'MEL',
  },
  {
    name: 'Sydney',
    code: 'SYD',
  },
];

export const ContainerDestinationCodeList: DestinationCode[] = [
  {
    name: 'Manila',
    code: 'MNL',
  },
  {
    name: 'Luzon',
    code: 'LUZ',
  },
  {
    name: 'Visayas',
    code: 'VIS',
  },
  {
    name: 'Mindanao',
    code: 'MIN',
  },
];

export const ContainerTypeCodeList: DestinationCode[] = [
  {
    name: 'Departed origin',
    code: 'DO',
  },
  {
    name: 'Arrived transhipment port',
    code: 'ATP',
  },
  {
    name: 'Departed transhipment port',
    code: 'DTP',
  },
  {
    name: 'Arrived destination',
    code: 'AD',
  },
  {
    name: 'Warehouse-In',
    code: 'ITWHIPH',
  },
];

export const ContainerDepartedCodeList: DestinationCode[] = [
  {
    name: 'Melbourne, Australia',
    code: 'DPM',
  },
  {
    name: 'Sydney, Australia',
    code: 'DSA',
  },
  {
    name: 'Manila, Philippines',
    code: 'DMP',
  },
  {
    name: 'Singapore',
    code: 'DSG',
  },
  {
    name: 'Kaohsiung, Taiwan',
    code: 'DKT',
  },
];

export const ContainerDepartedToCodeList: DestinationCode[] = [
  {
    name: 'Singapore',
    code: 'TSG',
  },
  {
    name: 'China',
    code: 'TCN',
  },
  {
    name: 'Manila, Philippines',
    code: 'TMNL',
  },
  {
    name: 'Kaohsiung, Taiwan',
    code: 'TTW',
  },
];

export const InterStateTypeCodeList: DestinationCode[] = [
  {
    name: 'Inter state - departed origin',
    code: 'ISDO',
  },
  {
    name: 'Inter state - arrived destination',
    code: 'ISAD',
  },
];

export const InterStateDepartedCodeList: DestinationCode[] = [
  // {
  //   name: 'Melbourne, Australia',
  //   code: 'DPM',
  // },
  {
    name: 'Sydney, Australia',
    code: 'DSYD',
  },
];

export const InterStateDepartedToCodeList: DestinationCode[] = [
  {
    name: 'Melbourne, Australia',
    code: 'TMEL',
  },
];
