import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BarcodeResponseModel } from 'common/models/BarcodeTypes';
import { BoxContentModel, BoxDetailsModel, ReceiverModel, SenderModel } from 'common/models/BookingTypes';
import { List } from 'reselect/es/types';
import { OperationStatus } from 'store/rootTypes';

const name = 'Barcode';

interface IBarcodeState {
  submitStatus: OperationStatus;
  BarcodeList?: BarcodeResponseModel[];
}

const initialState: IBarcodeState = {
  submitStatus: OperationStatus.idle,
  BarcodeList: undefined,
};

export const BarcodeSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetGeneratedBarcode: (state, action: PayloadAction<BarcodeResponseModel[]>) => {
      state.BarcodeList = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: BarcodeActions } = BarcodeSlice;

export default BarcodeSlice.reducer;
