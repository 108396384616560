import { Card, Col, Row, Steps } from 'antd';
import Meta from 'antd/es/card/Meta';
import '@features/Webpage/homepage.css';
import {
  boxIconExport,
  businessFreighIcon,
  containerIcon,
  letsTalkIconExport,
  luzonIcon,
  minIcon,
  mnlIcon,
  phoneIconExport,
  visIcon,
  webpageheader,
} from 'common/images';
import { Link } from 'react-router-dom';
import { PATHS } from '@routing/routes';
import { WebsiteFooter } from './Footer';
const { Step } = Steps;
export const Service = () => {
  return (
    <>
      <Row gutter={16}>
        {/* First Column with Ant Design Cards */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>Our services</h3>
          <Row justify="space-between">
            {/* First Column */}
            <Col span={12} className="text-left">
              <b className="textColorPrimary"> The Kalinga balikbayan boxes</b>
            </Col>

            {/* Second Column */}
            <Col span={12} className="textRight">
              <Link to={PATHS.Login.path} className="normal-font">
                Create an account or schedule a pick up {'>>>'}
              </Link>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Card
                title={
                  <div>
                    Biyaheng Metro Manila <br /> $105.00
                  </div>
                }
                cover={<img alt="example" src={mnlIcon} />}
              ></Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Card
                title={
                  <div>
                    Biyaheng Luzon <br /> $115.00
                  </div>
                }
                cover={<img alt="example" src={luzonIcon} />}
              ></Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Card
                title={
                  <div>
                    Biyaheng Visayas <br /> $130.00
                  </div>
                }
                cover={<img alt="example" src={visIcon} />}
              ></Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Card
                className="cardService"
                title={
                  <div>
                    Biyaheng Mindanao <br /> $140.00
                  </div>
                }
                cover={<img alt="example" src={minIcon} />}
              ></Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <h3>The returning residents a.k.a balikbayan</h3>
      <Row gutter={0} align="middle" className="borderBox">
        <Col xs={24} sm={8} md={12} lg={12} xl={12} className="">
          {/* Content for the second column (1 sentence text) */}
          <p className="bold"> We can arrange a whole container for your white goods or all your home contents.</p>
          <p className=""> Full container load (FCL)</p>
          <ul>
            <li className="liWhyKFS">20 footer container</li>
            <br />
            <li className="liWhyKFS">40 footer container</li>
            <br />
          </ul>
          <p className=""> Less container load (LCL)</p>
          <Link to={PATHS.ContactUs.path} className="normal-font floatRight">
            Let’s discuss your options {'>>>'}
          </Link>
        </Col>
        <Col xs={24} sm={16} md={12} lg={12} xl={12}>
          {/* Content for the first column (image header) */}
          <img src={containerIcon} alt="Header" className="full-width heightAKABalikbayan" />
        </Col>
      </Row>
      <br />
      <h3>Business freight solutions</h3>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={16} md={12} lg={12} xl={12}>
          {/* Content for the first column (image header) */}
          <img src={businessFreighIcon} alt="Header" className="full-width" />
        </Col>
        <Col xs={24} sm={8} md={12} lg={12} xl={12} className="commercialTxt">
          {/* Content for the second column (1 sentence text) */}
          {/* <p className="bold pleftCom align-top"> Commercial goods for import and export globally.</p> */}
          <div className="align-top">
            <p className="bold pleftCom">Commercial goods for import and export globally.</p>
          </div>
          {/* <Link to="/" className="normal-font floatRight commercialTxt">
            Let’s talk about your business {'>>>'}
          </Link> */}
          <div className="align-bottom">
            <Link to={PATHS.ContactUs.path} className="normal-font floatRight commercialTxt">
              Let’s talk about your business {'>>>'}
            </Link>
          </div>
        </Col>
      </Row>
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
