import { Col, Row, Space } from 'antd';
import { FaFacebook, FaInstagram, FaAddressCard, FaPhone, FaRegClock } from 'react-icons/fa';
import '@features/Webpage/homepage.css';
import { Link } from 'react-router-dom';
import { PATHS } from '@routing/routes';
export const WebsiteFooter = () => {
  return (
    <>
      <div className="footer-bck">
        <br />
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <p className=" aboutVM footerColortxt">
              <Link to="/privacynotice" className="footerColortxt">
                Privacy
              </Link>{' '}
              |{' '}
              <Link to={PATHS.FAQs.path} className="footerColortxt">
                FAQs
              </Link>{' '}
              |{' '}
              <Link to={PATHS.ContactUs.path} className="footerColortxt">
                Contact us
              </Link>
            </p>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Space className="text-right mr-20">
              <p className="mtp-0 footerColortxt">Connect with us</p>
              <a
                href="https://www.facebook.com/ph.kasangga"
                target="_blank"
                rel="noopener noreferrer"
                className="footerColortxt"
              >
                <FaFacebook className="contact-us-icon2 mb-10" />
              </a>
              <a href="https://www.facebook.com/ph.kasangga" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="contact-us-icon2 mb-10" />
              </a>
            </Space>
            {/* <p className="textColor aboutVM">&copy; Kalinga Freight Solutions Pty Ltd (KFS) 2023 ABN 80 672 974 515</p> */}
          </Col>
        </Row>
      </div>
    </>
  );
};
