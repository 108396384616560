import { Button, Col, DatePicker, Form, Input, InputNumber, Row } from 'antd';
import { useHistory } from 'react-router';

import { Constants } from 'common/constants';
import 'App.css';
import TextArea from 'antd/es/input/TextArea';
import { PATHS } from '@routing/routes';

export const AddEmployee = () => {
  const history = useHistory();

  const onFinish = async (values: any) => {
    console.error('SelectedId is undefined');
  };

  return (
    <div className="App ">
      {/* <Row justify="center" align="middle">
        <h1>Add expenses </h1>
      </Row> */}

      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={14} lg={10} xl={8} className="">
            <h1 className="headermaintext">Add employee </h1>
            <Form.Item label="Name" name="Name" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <Input size="large" maxLength={100} />
            </Form.Item>
            <Form.Item
              label="Hire date"
              name="HireDate"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <DatePicker size="large" className="full-width" placeholder="Date" />
            </Form.Item>

            <br />
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(PATHS.Employee.path)}
              >
                {Constants.BTN_BACK}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                {Constants.BTN_SUBMIT}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
