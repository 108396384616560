import {
  BookingAPIRequestModel,
  BoxContentModel,
  BoxDetailsModel,
  ReferralResponse,
  TransactionsHistoryViewModel,
  TransactionsSaveResponse,
} from 'common/models/BookingTypes';
import { notification } from 'antd';
import { GetNotificationProps } from 'common/utils/utility';
import { APIResponseCode, MessageDisplay } from 'common/constants';
import { APIResponse, GetData, PostData } from 'common/utils/jsonFetch';
import { Validator } from 'common/validators/validators';
import { HandleErrorMessage } from '@features/Common';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { IncentiveActions } from '../store/IncentiveSlice';

export const Refer = (email: string) => async (dispatch) => {
  try {
    dispatch(IncentiveActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Incentives_Refer, email);
    if (response.parsedBody?.Status == APIResponseCode.SUCCESS) {
      dispatch(IncentiveActions.submitStatusSucceeded());
    } else {
      dispatch(IncentiveActions.submitStatusRejected(''));
    }
  } catch (e) {
    dispatch(IncentiveActions.submitStatusRejected(''));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
  }
};
