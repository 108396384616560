import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BookingStatus } from 'common/constants';
import { BoxContentModel, BoxDetailsModel, ReceiverModel, SenderModel } from 'common/models/BookingTypes';
import { OperationStatus } from 'store/rootTypes';

const name = 'Invoice';

interface IInvoiceState {
  submitStatus: OperationStatus;
  Sender?: SenderModel;
  Receiver?: ReceiverModel;
  BoxContent?: BoxContentModel;
  BoxDetails?: BoxDetailsModel;
  BookingStatus: typeof BookingStatus.New | typeof BookingStatus.Update;
}

const initialState: IInvoiceState = {
  submitStatus: OperationStatus.idle,
  BoxContent: undefined,
  Sender: undefined,
  Receiver: undefined,
  BoxDetails: undefined,
  BookingStatus: BookingStatus.Idle,
};

export const InvoiceSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetBookingStatus: (state, action: PayloadAction<typeof BookingStatus.New | typeof BookingStatus.Update>) => {
      state.BookingStatus = action.payload;
    },
    SetSender: (state, action: PayloadAction<SenderModel>) => {
      state.Sender = {
        ...state.Sender,
        ...action.payload,
      };
    },
    SetReceiver: (state, action: PayloadAction<ReceiverModel>) => {
      state.Receiver = action.payload;
    },
    SetBoxContent: (state, action: PayloadAction<BoxContentModel>) => {
      state.BoxContent = action.payload;
    },
    SetBoxDetails: (state, action: PayloadAction<BoxDetailsModel>) => {
      state.BoxDetails = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
    test: () => {
      alert('from test');
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: InvoiceActions } = InvoiceSlice;

export default InvoiceSlice.reducer;
