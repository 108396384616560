import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ContainerBarcodeDetailViewModel,
  ContainerDetailsViewModel,
  ContainerViewModel,
  UploadBarcodeResponse,
} from 'common/models/ContainerTypes';
import {
  InterStateBarcodeViewModel,
  InterStateDetailsViewModel,
  InterStateViewModel,
} from 'common/models/InterStateTypes';
import { OperationStatus } from 'store/rootTypes';

const name = 'Container';

interface IInterState {
  submitStatus: OperationStatus;
  InterStateList?: InterStateViewModel[];
  SelectedInterStateId?: string;
  InterState?: InterStateViewModel;
  InterStateDetails?: InterStateDetailsViewModel;
  InterStateDetailsList?: InterStateDetailsViewModel[];
  //   ContainerDetailsList?: ContainerDetailsViewModel[];
  UploadBarcodeResponse?: UploadBarcodeResponse;
  InterStateBarcodeList?: InterStateBarcodeViewModel[];
  IsFromListing: boolean;
}

const initialState: IInterState = {
  submitStatus: OperationStatus.idle,
  IsFromListing: false,
};

export const InterStateSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetInterStateList: (state, action: PayloadAction<InterStateViewModel[]>) => {
      state.InterStateList = action.payload;
    },
    SetSelectedInterStateId: (state, action: PayloadAction<string>) => {
      state.SelectedInterStateId = action.payload;
    },
    SetInterState: (state, action: PayloadAction<InterStateViewModel | undefined>) => {
      state.InterState = action.payload;
    },
    SetInterStateDetailsList: (state, action: PayloadAction<InterStateDetailsViewModel[]>) => {
      state.InterStateDetailsList = action.payload;
    },
    SetIsFromListing: (state, action: PayloadAction<boolean>) => {
      state.IsFromListing = action.payload;
    },
    SetUploadBarcodeResponse: (state, action: PayloadAction<UploadBarcodeResponse | undefined>) => {
      state.UploadBarcodeResponse = action.payload;
    },
    SetInterStateBarcodeList: (state, action: PayloadAction<InterStateBarcodeViewModel[]>) => {
      state.InterStateBarcodeList = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: InterStateActions } = InterStateSlice;

export default InterStateSlice.reducer;
