import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Spin } from 'antd';
import { persistor, store, history } from 'store';
import { ConnectedRouter } from 'connected-react-router';
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
  <React.StrictMode>
    <PersistGate loading={<Spin />} persistor={persistor}>
      <Provider store={store}>
        <CookiesProvider>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </CookiesProvider>
      </Provider>
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root')
);
