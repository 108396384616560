import {
  BookingAPIRequestModel,
  BoxContentModel,
  BoxDetailsModel,
  ReferralResponse,
  TransactionsHistoryViewModel,
  TransactionsSaveResponse,
  TransactionsUpdateSenderRequest,
} from 'common/models/BookingTypes';
import { notification } from 'antd';
import { GetNotificationProps } from 'common/utils/utility';
import { APIResponseCode, MessageDisplay } from 'common/constants';
import { APIResponse, GetData, PostData } from 'common/utils/jsonFetch';
import { Validator } from 'common/validators/validators';
import { HandleErrorMessage } from '@features/Common';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { BoxOrderViewModel } from 'common/models';
import { OrderBoxActions } from '../store/OrderBoxSlice';

export const SubmitOrderBox = (request: BoxOrderViewModel) => async (dispatch) => {
  const methodName = 'SubmitOrder';

  try {
    dispatch(OrderBoxActions.submitStatus());

    const response = await PostData<APIResponse>(KFSEndpoints.OrderBox_Save, request);

    // Handle API response
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);

    const data: TransactionsSaveResponse = response.parsedBody?.Results;
    dispatch(OrderBoxActions.submitStatusSucceeded(data));

    return data; // Return the data if needed
  } catch (e) {
    dispatch(OrderBoxActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const UpdateOrderBox = (request: BoxOrderViewModel) => async (dispatch) => {
  const methodName = 'UpdateOrderBox';

  try {
    dispatch(OrderBoxActions.submitStatus());

    const response = await PostData<APIResponse>(KFSEndpoints.OrderBox_Update, request);

    // Handle API response
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);

    dispatch(OrderBoxActions.submitStatusSucceededNoPayload());
  } catch (e) {
    dispatch(OrderBoxActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const UpdateTransactionOrderABoxSender = (request: TransactionsUpdateSenderRequest) => async (dispatch) => {
  const methodName = 'UpdateTransactionOrderABoxSender';

  try {
    dispatch(OrderBoxActions.submitStatus());
    const response = await PostData<APIResponse>('/Transaction/UpdateSender', request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(OrderBoxActions.submitStatusSucceededNoPayload());
  } catch (e) {
    dispatch(OrderBoxActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};
