import { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Row, Select } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { SenderDetailsSelector, SenderDetailsActions } from '@features/SenderDetails/';
import { Constants, SenderStatus } from 'common/constants';
import { MobileCountryCodeList } from 'common/code/MobileCountryCode';
import { handleFocusContactNumber, handleKeyDownContactNumberNew } from 'common/utils/utility';
import { TransactionSelector } from '@features/Transaction';
import { BookingSelector } from '../store/BookingSelector';
import { Link } from 'react-router-dom';
import { PATHS } from '@routing/routes';
import { KFSSignupCheckUser } from '@features/Account';
import { commonSelector } from '@features/Common/store/commonSelector';

const { Search } = Input;
export const Sender = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const commonState = useSelector(commonSelector);
  const bookingState = useSelector(BookingSelector);
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const transactionState = useSelector(TransactionSelector);

  const [showSenderForm, setShowSenderForm] = useState(true);
  const [selectedMobileCode, setSelectedMobileCode] = useState(senderDetailsState?.Sender?.MobileCode || '+61');
  const [form] = Form.useForm();

  useEffect(() => {
    if (
      bookingState.IsFromSignupKFS &&
      (bookingState.FromSignupExistingUserId == undefined ||
        bookingState.FromSignupExistingUserId == null ||
        bookingState.FromSignupExistingUserId == '')
    ) {
      const existingData =
        senderDetailsState.Sender?.GivenName ?? transactionState?.TransactionSummary?.Sender?.GivenName;
      if (existingData) {
        setShowSenderForm(true);
      } else {
        setShowSenderForm(false);
      }
    }

    // alert(bookingState.FromSignupExistingUserId);

    if (!transactionState.FromUpdateLink) {
      const id = senderDetailsState.Sender?.Id;

      switch (senderDetailsState.SenderStatus) {
        case SenderStatus.Update:
          if (id === null || id === undefined) {
            history.push('/home');
          }
          break;
        case SenderStatus.Booking:
          history.push('/receiver/listing');
          break;
        default:
          // Handle the default case (if any)
          //SenderStatus.New retain to this page to input the details manually
          break;
      }
    }
  }, []);

  const onFinish = (values: any) => {
    dispatch(
      SenderDetailsActions.SetSender({
        ...values,
        MobileCode: selectedMobileCode,
      })
    );
    history.push('/booking/senderaddress');
  };

  const onBack = () => {
    if (commonState.GlobalTransactionId) {
      history.push(PATHS.SenderListingBooking.path);
    } else if (transactionState.FromUpdateLinkOrderABox) {
      history.push(PATHS.SenderListingOrderBox.path);
    } else if (transactionState.FromUpdateLink) {
      history.push('/transaction/summary');
    } else {
      history.push('/sender/listing');
    }
  };

  const { Option } = Select;

  const selectStyle = { width: '100px' }; // Adjust the width as needed
  const inputStyle = { width: 'calc(100% - 100px)' }; // Adjust the width based on the select width

  const onSearch = async (value: string) => {
    const isValidEmail = Constants.EMAIL_REGEX_INPUT.test(value);
    if (!isValidEmail) return;
    const response = await dispatch(KFSSignupCheckUser(value));

    if (response != undefined) {
      history.push(PATHS.SenderListing.path);
    } else {
      setShowSenderForm(true);
    }
  };

  const handleFormKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className="App">
      {bookingState.IsFromSignupKFS ? (
        <Row justify="center" align="middle">
          <h1>KFS - Customer sign up</h1>
        </Row>
      ) : !transactionState?.FromUpdateLink ? (
        <Row justify="center" align="middle">
          {senderDetailsState.SenderStatus === SenderStatus.Update ? (
            <h1>Update sender</h1>
          ) : senderDetailsState.SenderStatus === SenderStatus.New ? (
            <h1>Add new sender</h1>
          ) : (
            <h1>{Constants.KFSBOX_BookPickupHeader}</h1>
          )}
        </Row>
      ) : (
        <Row justify="center" align="middle">
          <h1>Update sender</h1>
        </Row>
      )}

      <Form name="senderForm" onFinish={onFinish} layout="vertical" onKeyDown={handleFormKeyDown}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            {senderDetailsState.SenderStatus === SenderStatus.Booking ? (
              <Breadcrumb
                items={[
                  {
                    title: 'Sender',
                  },
                ]}
              />
            ) : null}
            <br />
            {bookingState.IsFromSignupKFS &&
              (bookingState.FromSignupExistingUserId == undefined ||
                bookingState.FromSignupExistingUserId == null ||
                bookingState.FromSignupExistingUserId == '') && (
                <>
                  <Form.Item
                    initialValue={senderDetailsState.Sender?.Email}
                    label="Email"
                    name="Email"
                    rules={[
                      { required: true, message: Constants.REQUIRED_FIELD },
                      {
                        pattern: Constants.EMAIL_REGEX_INPUT,
                        message: 'Please enter a valid email address.',
                      },
                    ]}
                  >
                    <Search maxLength={100} enterButton size="large" onSearch={onSearch} />
                  </Form.Item>
                  {!showSenderForm && (
                    <Row justify="center" align="middle">
                      <Col>
                        <Link to={PATHS.Home.path} className="normal-font">
                          Back to dashboard
                        </Link>
                      </Col>
                    </Row>
                  )}
                </>
              )}

            {showSenderForm ? (
              <>
                <Form.Item
                  initialValue={
                    !transactionState.FromUpdateLink
                      ? senderDetailsState.Sender?.GivenName
                      : transactionState?.TransactionSummary?.Sender?.GivenName
                  }
                  label="Given name"
                  name="GivenName"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input size="large" maxLength={25} />
                </Form.Item>
                <Form.Item
                  initialValue={
                    !transactionState.FromUpdateLink
                      ? senderDetailsState.Sender?.Surname
                      : transactionState?.TransactionSummary?.Sender?.Surname
                  }
                  label="Surname"
                  name="Surname"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input size="large" maxLength={25} />
                </Form.Item>
                <Form.Item
                  initialValue={form.getFieldValue('ContactNo') || senderDetailsState?.Sender?.ContactNo}
                  label="Contact number"
                  name="ContactNo"
                  // rules={[{ required: true }]}
                >
                  <Input.Group compact>
                    <Form.Item
                      noStyle // Adjust the name prop for the Select component
                      initialValue="+1"
                    >
                      <Select
                        style={selectStyle}
                        value={selectedMobileCode}
                        onChange={(value) => setSelectedMobileCode(value)}
                        size="large" // Add your onChange logic
                        showSearch // Enable search
                        optionFilterProp="children" // Use the "children" property for filtering
                      >
                        {MobileCountryCodeList.map((country) => (
                          <Option key={country.code} value={country.dial_code}>
                            {`${country.dial_code}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name="ContactNo"
                      initialValue=""
                      rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    >
                      <Input
                        style={inputStyle}
                        placeholder="Mobile number"
                        size="large"
                        // onInput={handleInputChangeContactNumber}
                        onKeyDown={handleKeyDownContactNumberNew}
                        onFocus={handleFocusContactNumber}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <div>
                  {bookingState.IsFromSignupKFS ? (
                    <>
                      <Button
                        className="prevSenderBtn"
                        size="large"
                        type="default"
                        onClick={() => history.push(PATHS.Home.path)}
                      >
                        {Constants.BTN_CLOSE}
                      </Button>
                      <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                        {Constants.BTN_CONTINUE}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button className="prevSenderBtn" size="large" type="default" onClick={() => onBack()}>
                        {!commonState.GlobalTransactionId ? Constants.BTN_CLOSE : Constants.BTN_BACK}
                      </Button>
                      <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                        {Constants.BTN_CONTINUE}
                      </Button>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
