import React from 'react';
import { Layout } from 'antd';
import styles from './RootLayout.module.less';
import { PageContent } from '@Layout/PageContent';
import { AppHeader } from '@Layout/Header';
import { AppFooter } from '@Layout/Footer';
import { Inspector } from 'react-dev-inspector';
import { ROUTES } from 'common/route/routes';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@components/ErrorFallback/ErrorFallback';
import { RecursiveSubRoutes } from 'App';

const { Content } = Layout;
const InspectorWrapper = process.env.NODE_ENV === 'development' ? Inspector : React.Fragment;
const RootLayout = () => {
  return (
    <InspectorWrapper keys={['control', 'shift', 'c']} disableLaunchEditor={false}>
      <Layout className={`${styles.RootLayout} dflex`}>
        <AppHeader />
        <Content className={`${styles.ContentContainer}`}>
          <PageContent>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {ROUTES.map((route) => {
                if (!route.ignoreLayout) {
                  return <RecursiveSubRoutes key={route.displayTitle} {...route} />;
                }
              })}
            </ErrorBoundary>
          </PageContent>
        </Content>
        {/* <AppFooter /> */}
      </Layout>
    </InspectorWrapper>
  );
};

export default RootLayout;
