import { Constants } from 'common/constants';
import { ArgsProps } from 'antd/lib/notification/interface';
import { validate } from 'sgidvalidator';
import { v4 as uuidv4 } from 'uuid';
import { Button, Input, Space, Typography, Radio, DatePicker, Checkbox } from 'antd';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import type { SharedTimeProps } from 'rc-picker/lib/panels/TimePanel';
import { Cookies } from 'react-cookie';
const { Text } = Typography;

export const shouldGoBackToPreviousPage = (totalItems, pageSize, currentPage, deletedCount) => {
  const remainingItemsOnCurrentPage = totalItems - deletedCount;

  // Calculate the total number of pages after deletion
  const totalPagesAfterDeletion = Math.ceil(remainingItemsOnCurrentPage / pageSize);

  if (totalPagesAfterDeletion < currentPage) {
    // If there are not enough pages to reach the current page, go back to the previous page
    return true;
  }

  // Otherwise, stay on the current page
  return false;
};

export const CompareStringToLower = (string1: string | undefined, string2: string | undefined) => {
  return typeof string1 === 'string' && typeof string2 === 'string' && string1.toLowerCase() === string2.toLowerCase();
};

export const GetNotificationProps = (message, description = '') => {
  return {
    key: 'notificationkey',
    message: message,
    description: description,
    duration: 5,
    placement: 'top',
    style: {
      minWidth: 480,
      width: 'auto',
      maxWidth: 500,
      top: 350,
    },
  } as ArgsProps;
};

export const IsNRIC = (value) => {
  if (value) {
    return validate(value) && value.match(Constants.NRIC_START_WITH_REGEX);
  } else {
    return false;
  }
};

export const IsFIN = (value) => {
  if (value) {
    return validate(value) && value.match(Constants.FIN_START_WITH_REGEX);
  } else {
    return false;
  }
};

export const IsNRICOrFIN = (value) => {
  if (value) {
    return validate(value);
  } else {
    return false;
  }
};

export const GetUUID = () => {
  return uuidv4();
};

export const IsDevEnv = () => {
  return process.env.NODE_ENV == 'development';
};

export const MinutesToMilliseconds = (minutes) => {
  return minutes * 1000 * 60;
};

export const ThrowError = (error: string) => {
  throw Error(error);
};

export const GetFileExtension = (file: string) => {
  return file;
};

export const FormulateFilterStatement = (filterValues) => {
  let filter = '';
  for (const [key, value] of Object.entries(filterValues)) {
    if (value == null) {
      continue;
    }

    let appendFilter = '';
    const isDateValue = dayjs.isDayjs(value[0]);

    if (isDateValue) {
      const date = value[0].toDate().getDate();
      const month = value[0].toDate().getMonth() + 1; //add 1 to get numerical month from 0-based month
      const year = value[0].toDate().getFullYear();
      if (value[0].DateTime) {
        const hour = value[0].toDate().getHours();
        const minute = value[0].toDate().getMinutes();
        appendFilter = `(CONVERT(DATETIME, [${key}]) = '${month}/${date}/${year} ${hour}:${minute}')`;
      } else appendFilter = `(CONVERT(DATE, [${key}]) = '${month}/${date}/${year}')`; //convert datetime to date in sql server to make direct date comparison
    } else {
      appendFilter = `([${key}] LIKE '%${value[0]}%')`;
    }

    if (filter === '') {
      filter += `${appendFilter}`;
    } else {
      filter += ` AND ${appendFilter}`;
    }
  }
  return filter;
};

export const AdjustHours = (dateTime: Dayjs, hours: number) => {
  if (!dateTime) {
    return;
  }
  return dateTime.add(hours, 'hour');
};

export const FormatDashDDMMYYYY = (value) => {
  if (value) {
    return dayjs(value).format('DD-MMM-YYYY');
  } else {
    return value;
  }
};

export const GetColumnSearchProps = (fieldName) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div className={`filter`} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        className={`filterInput`}
        placeholder={`Search ${fieldName}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => {
          confirm();
        }}
      />
      <Space>
        <Button
          className={`filterButton`}
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
        >
          Search
        </Button>
        <Button
          className={`filterButton`}
          onClick={() => {
            clearFilters && clearFilters();
            confirm();
          }}
          size="small"
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? Constants.FILTER_ICON_ACTIVE_COLOUR : undefined,
      }}
    />
  ),
});

export const GetColumnSearchPropsRadioBoolean = () => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div className={`filter`} onKeyDown={(e) => e.stopPropagation()}>
      <Text>Show items with value</Text>
      <Radio.Group
        className={`filterInput`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      >
        <Radio value="1">True</Radio>
        <Radio value="0">False</Radio>
      </Radio.Group>

      <Space>
        <Button
          className={`filterButton`}
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
        >
          Search
        </Button>
        <Button
          className={`filterButton`}
          onClick={() => {
            clearFilters && clearFilters();
            confirm();
          }}
          size="small"
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? Constants.FILTER_ICON_ACTIVE_COLOUR : undefined,
      }}
    />
  ),
});
interface IGetColumnSearchPropsDatepicker {
  showTime?: boolean | SharedTimeProps<Dayjs>;
  format?: string;
}
export const GetColumnSearchPropsDatepicker = ({
  showTime = false,
  format = 'DD MMM YYYY',
}: IGetColumnSearchPropsDatepicker = {}) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div className={`filter`} onKeyDown={(e) => e.stopPropagation()}>
      <Text>Show items with date</Text>
      <DatePicker
        className={`filterInputDate`}
        format={format}
        value={selectedKeys[0]}
        showTime={showTime}
        onChange={(date) => setSelectedKeys(date ? [date] : [])}
      />

      <Space>
        <Button
          className={`filterButton`}
          type="primary"
          onClick={() => confirm()}
          icon={<FilterFilled />}
          size="small"
        >
          Filter
        </Button>
        <Button
          className={`filterButton`}
          onClick={() => {
            clearFilters && clearFilters();
            confirm();
          }}
          size="small"
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <FilterFilled
      style={{
        color: filtered ? Constants.FILTER_ICON_ACTIVE_COLOUR : undefined,
      }}
    />
  ),
});

export const GetColumnSearchPropsCheckboxDropdown = (itemList: string[]) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div className={`filter`} onKeyDown={(e) => e.stopPropagation()}>
      <Checkbox.Group
        className="custom-antd-column-filter-dropdown  pb-10"
        options={itemList}
        value={selectedKeys}
        onChange={(values) => setSelectedKeys(values.map(String))}
      />
      <Space>
        <Button
          className={`filterButton`}
          type="primary"
          onClick={() => confirm()}
          icon={<FilterFilled />}
          size="small"
        >
          Filter
        </Button>
        <Button
          className={`filterButton`}
          onClick={() => {
            clearFilters && clearFilters();
            confirm();
          }}
          size="small"
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <FilterFilled
      style={{
        color: filtered ? Constants.FILTER_ICON_ACTIVE_COLOUR : undefined,
      }}
    />
  ),
});

export const handleKeyDownContactNumber = (e) => {
  const maxLength = 20; // Adjust the maximum length as needed
  const inputValue = e.target.value.toString();

  // Allow backspace, delete, arrow keys, and navigation keys
  if (inputValue.length >= maxLength && ![8, 46, 37, 39, 35, 36].includes(e.keyCode)) {
    e.preventDefault(); // Prevent typing more characters
  }
};

const cookies = new Cookies();
export const getCookie = (name) => {
  return cookies.get(name);
};

export const handleKeyDownContactNumberNew = (e) => {
  const maxLength = 12;

  // Allow: backspace, delete, tab, escape, enter, and .
  if (
    [8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
    // Allow: Ctrl+A
    (e.keyCode === 65 && e.ctrlKey === true) ||
    // Allow: home, end, left, right
    (e.keyCode >= 35 && e.keyCode <= 39)
  ) {
    // Let it happen, don't do anything
    return;
  }

  // Ensure that it is a number and stop the keypress
  if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
    e.preventDefault();
  }

  // Check if the length exceeds maxLength
  if (e.target.value.length >= maxLength) {
    e.preventDefault();
  }
};

// export const handleInputChangeContactNumber = (e) => {
//   const maxLength = 12;
//   // Remove non-numeric characters
//   let numericValue = e.target.value.replace(/[^0-9]/g, '');

//   // Remove leading zero if it's the first digit
//   if (numericValue.length > 1 && numericValue[0] === '0') {
//     numericValue = numericValue.slice(1);
//   }

//   // Check if the input is only '0' and prevent it
//   if (numericValue.length === 1 && numericValue[0] === '0') {
//     e.target.value = '';
//     e.preventDefault();
//     return;
//   }

//   // Truncate to maxLength
//   const truncatedValue = numericValue.slice(0, maxLength);

//   // Update the input value
//   e.target.value = truncatedValue;
// };

export const handleFocusContactNumber = (e) => {
  // Remove leading zero if it's the first digit
  // alert(e.target.value);
  if (e.target.value.length > 1 && e.target.value[0] === '0') {
    e.target.value = e.target.value.slice(1);
  }
};
// Set cookie with provided options
export const setCookie = (name, value, options) => {
  cookies.set(name, value, options);
};

export const formatTwoDecimalPlaces = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return ''; // or throw an error, depending on your use case
  }

  return number.toFixed(2);
};

export const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const selectOptions20 = Array.from({ length: 20 }, (_, index) => ({
  value: (index + 1).toString(),
  label: (index + 1).toString(),
}));

export const formatContainerTimestamp = (timestamp) => {
  if (timestamp === null) {
    return '-';
  }
  return new Date(timestamp).toLocaleString().toUpperCase();
};

export const getDescription = (description) => {
  // Check if the description contains HTML tags
  const containsHTML = /<\/?[\w\s="/.':;#-?]+>/gi.test(description);

  if (containsHTML) {
    return { __html: description };
  } else {
    return description;
  }
};
