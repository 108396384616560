import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Constants } from 'common/constants';
import { TransactionsSaveResponse } from 'common/models/BookingTypes';
import { OrderBoxSelector } from '../store/OrderBoxSelector';
import { OrderBoxActions } from '../store/OrderBoxSlice';

export const OrderABoxConfirmation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orderAboxState = useSelector(OrderBoxSelector);

  const [savedResponse, setSavedResponse] = useState<TransactionsSaveResponse | undefined>(undefined);
  useEffect(() => {
    setSavedResponse(orderAboxState.TransactionsSaveResponse);
    dispatch(OrderBoxActions.resetState());
  }, []);
  const onFinish = (values: any) => {
    history.push('/home');
  };

  return (
    <div className="App">
      {/* <Row justify="center" align="middle">
        <h1>Send a box</h1>
      </Row> */}
      <Row justify="center" align="middle">
        <h3>Order an empty box successful! </h3>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={14} xl={12}>
            <br />

            <label>
              Your request has been created and a copy of the transaction details have been sent to you. We will get in
              touch with you shortly to confirm the date and time of drop off.
            </label>
            <br />
            <br />
            <label>
              <b>Date:</b> {savedResponse?.CreatedDate}
            </label>
            <br />
            <label>
              <b>Status:</b> {savedResponse?.Status}
            </label>
            <br />
            <label>
              <b>Reference Number:</b> {savedResponse?.RefNo}
            </label>
            <br />
            <br />
            <label>
              If you have any enquiries, please contact us at {Constants.KFSBOX_MOBILE} or send us a message through{' '}
              {Constants.KFSBOX_EMAIL}.
            </label>
            <br />
            <br />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Link to="/home" className="normal-font">
            Return to dashboard
          </Link>
        </Row>
      </Form>
    </div>
  );
};
