import { Alert, Button, Col, Divider, Form, Input, Row, Select, Space, Steps, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { Constants } from 'common/constants';
import TextArea from 'antd/es/input/TextArea';
import { FaFacebook, FaInstagram, FaAddressCard, FaPhone, FaRegClock, FaPhoneSquare } from 'react-icons/fa';
import { contactusheader } from 'common/images';
import '@features/Webpage/homepage.css';
import { EnquiryCodeList } from 'common/code/ContactUsCode';
import { ContactUsSave } from '../business/WebpageBusiness';
import { useEffect, useState } from 'react';
import { WebsiteFooter } from './Footer';
import { PostStart } from '@features/Common';
export interface ContactViewModel {
  Enquiry: string;
  EnquiryText: string;
  Name: string;
  Email: string;
  ContactNumber: string;
  Message: string;
}
export const ContactUs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PostStart());
  }, []);
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const [showAlert, setShowAlert] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const handleEnquiryChange = (value) => {
    const selectedEnquiry = EnquiryCodeList.find((eq) => eq.code === value);
    setSelectedText(selectedEnquiry ? selectedEnquiry.name : '');
  };
  const onFinish = async (values: ContactViewModel) => {
    notification.info({
      message:
        'Oops! Our app release has been pushed to July 2024 because we are adding more features. For the latest updates, be sure to follow our Facebook page. Thank you!',
      description: (
        <>
          <a href="https://www.facebook.com/ph.kasangga">Follow our Facebook page</a>
          <p>
            For any inquiries, feel free to <a href="https://www.facebook.com/ph.kasangga">message us on Facebook</a>.
            or email us: <a>info@kasangga.com</a>
          </p>
        </>
      ),
    });
    // const updatedValues: ContactViewModel = {
    //   ...values,
    //   EnquiryText: selectedText,
    // };
    // await dispatch(ContactUsSave(updatedValues));
    // setSelectedText('');
    // setShowAlert(true);
    // form.resetFields();
  };
  const [form] = Form.useForm();

  const onclick = () => {
    setShowAlert(false);
  };
  const onReset = () => {
    setShowAlert(false);
    form.resetFields();
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  return (
    <div className="App ">
      <Form name="senderForm" onFinish={onFinish} layout="vertical" form={form}>
        <div className="">
          <Row justify="center" align="middle">
            <div className="center-text">
              <h2 className="mb-0">Get in touch</h2>
              <p className="textColor mtb">Kasangga mo sa negosyo</p>
            </div>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Form.Item label="Name" name="Name" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                <Input size="large" maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Form.Item label="Email" name="Email" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                <Input size="large" maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Form.Item
                label="Contact number"
                name="ContactNumber"
                rules={[
                  { required: true, message: Constants.REQUIRED_FIELD },
                  {
                    pattern: /^(\+[0-9]+|[0-9]+)$/,
                    message: 'Please enter a valid contact number',
                  },
                ]}
              >
                <Input size="large" maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Form.Item label="Message" name="Message" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                <TextArea size="large" maxLength={200} />
              </Form.Item>
            </Col>
          </Row>
          {showAlert && (
            <>
              <Row gutter={24} justify="center" align="middle">
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <Alert
                    message="We have received your message. We will respond to you shortly."
                    type="success"
                    showIcon
                    closable
                    onClose={handleAlertClose}
                  />
                </Col>
              </Row>
              <br />
            </>
          )}
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={12} xl={6} className="center-text">
              <Button className="width100" size="large" type="primary" htmlType="submit" onClick={onclick}>
                Submit
              </Button>
            </Col>
          </Row>
        </div>
        <br />

        <div className="">
          <Divider />
          <Row justify="center" align="middle">
            <h1 className="mtb-0">Contact us</h1>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="right-text">
              <Space>
                <FaAddressCard className="contact-us-icon" />
                <p className="textColor">info@kasangga.com</p>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Space>
                <FaPhoneSquare className="contact-us-icon" />
                <p className="textColor">+65 9892 6495</p>
              </Space>
            </Col>
          </Row>
        </div>
      </Form>
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </div>
  );
};
