import { SenderModel } from 'common/models/BookingTypes';
import { APIResponseCode } from 'common/constants';
import { APIResponse, GetData, PostData } from 'common/utils/jsonFetch';
import { Validator } from 'common/validators/validators';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { SenderDetailsActions } from '../store/SenderDetailsSlice';
import { BookingActions } from '@features/Booking/store/BookingSlice';
import { commonAction } from '@features/Common/store/commonSlice';

export const GetAllSender = async (data: string | undefined): Promise<SenderModel[]> => {
  try {
    const response = await PostData<APIResponse>(KFSEndpoints.SENDER_DETAILS_GET_ALL, data);
    new Validator(response).NotNull();
    new Validator(response.parsedBody?.Status).NotNull();
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);

    // Assuming your API response has a property called 'results' that contains the list of senders
    const senders: SenderModel[] = response.parsedBody?.Results || [];
    return senders;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // You may want to handle the error differently based on your application's needs
  }
};

export const GetSenderById = (id: string) => {
  return async function GetSenderByIdThunk(dispatch) {
    const methodName = 'GetSenderById';
    try {
      dispatch(SenderDetailsActions.submitStatus());

      const response = await PostData<APIResponse>(KFSEndpoints.SENDER_GET_BY_ID, id);

      new Validator(response).NotNull();
      new Validator(response.parsedBody).NotNull();
      new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
      new Validator(response.parsedBody?.Results).NotNull();
      const dataList: SenderModel = response.parsedBody?.Results;
      dispatch(SenderDetailsActions.submitStatusSucceeded(dataList));
    } catch (e) {
      // const errorMessage = HandleErrorMessage(methodName, e);
      // dispatch(ariTestCategoryActions.loadRejected(errorMessage));
    }
  };
};

export const UpdateSender = (sender: SenderModel) => {
  return async function GetSenderByIdThunk(dispatch) {
    const methodName = 'UpdateSender';
    // console.log('UpdateSender assign: ' + JSON.stringify(sender));
    try {
      dispatch(SenderDetailsActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.SENDER_UPDATE, sender);

      new Validator(response).NotNull();
      new Validator(response.parsedBody).NotNull();
      new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(SenderDetailsActions.submitStatusSucceeded(undefined));
      dispatch(commonAction.SetGlobalSenderId(response.parsedBody?.Results));
    } catch (e) {
      // const errorMessage = HandleErrorMessage(methodName, e);
      // dispatch(ariTestCategoryActions.loadRejected(errorMessage));
    }
  };
};

export const AddSender = (receiver: SenderModel) => {
  return async function AddSenderThunk(dispatch) {
    const methodName = 'AddSender';
    // console.log('UpdateSender assign: ' + JSON.stringify(sender));
    try {
      dispatch(SenderDetailsActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.SENDER_SAVE, receiver);

      new Validator(response).NotNull();
      new Validator(response.parsedBody).NotNull();
      new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(commonAction.SetGlobalSenderId(response.parsedBody?.Results));
      dispatch(SenderDetailsActions.SetSelectedId(response.parsedBody?.Results));
      dispatch(SenderDetailsActions.submitStatusSucceeded(undefined));
    } catch (e) {
      // const errorMessage = HandleErrorMessage(methodName, e);
      // dispatch(ariTestCategoryActions.loadRejected(errorMessage));
    }
  };
};
