import { Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { PATHS } from '@routing/routes';
import { WebsiteFooter } from './Footer';
import { KFSBoxDesign, KFSboxSize } from 'common/images';
export const Balikbayanbox = () => {
  return (
    <>
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>{PATHS.Balikbayanbox.displayTitle}</h3>
        </Col>
      </Row>
      <div className="bodyAboutUs">
        <Row gutter={0} align="middle" className="textColor">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
            <p className="mtp-0">
              Presenting our meticulously crafted balikbayan box – a container that goes beyond being a mere package,
              creating a heartfelt connection. Enhance your sending experience by adding a personal message because, at
              Kalinga Freight Solutions (KFS), we are dedicated to delivering more than just parcels.
            </p>
          </Col>
        </Row>
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <img src={KFSBoxDesign} alt="Header" className="full-width" />
          </Col>
        </Row>
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <img src={KFSboxSize} alt="Header" className="full-width" />
          </Col>
        </Row>
        <br />
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <WebsiteFooter />
          </Col>
        </Row>
      </div>
    </>
  );
};
