import { Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { PATHS, ROUTES } from '@routing/routes';
import { WebsiteFooter } from './Footer';
export const PrivacyNotice = () => {
  return (
    <>
      <Row justify="center" align="middle">
        <div className="center-text">
          <h2 className="mtb header-fs2">{PATHS.PrivacyNotice.displayTitle} </h2>
        </div>
      </Row>
      <div className="bodyAboutUs">
        <Row gutter={0} align="middle" className="textColor">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
            <p className="mtp-0">
              At Kasangga, we are committed to protecting the privacy and security of your personal information. This
              Privacy Notice outlines how we collect, use, disclose, and safeguard your data when you use our Kasangga
              app.
            </p>
            <p>
              <b>Information we collect</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  Personal identification: When you create an account with Kasangga, we may collect personal information
                  such as your name, email address, and phone number.
                </li>
              </ul>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="mtp-0">
              <b>How we use your information</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  Personalization: We use your information to personalize your experience within the app, including
                  providing tailored content and recommendations.
                </li>
                <li className="liWhyKFS">
                  Service Improvement: We analyze usage data to improve our services, enhance app functionality, and
                  develop new features.
                </li>
                <li className="liWhyKFS">
                  Communication: We may use your contact information to send you important updates, notifications, and
                  promotional offers related to Kasangga.
                </li>
              </ul>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="mtp-0">
              <b>Data Security</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  We prioritise the security of your information. We employ industry-standard measures to protect
                  against unauthorised access, disclosure, or alteration.
                </li>
              </ul>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="mtp-0">
              <b>Your rights</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  You have the right to access, correct, or delete your personal information. If you have any privacy
                  concerns, please contact our operations team at info@kasangga.com.
                </li>
              </ul>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="mtp-0">
              <b>Updates to Privacy Notice</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  Kasangga may update this privacy notice periodically. The latest version will be available on our
                  website. By using Kasangga app, you agree to the terms outlined in this privacy notice.
                </li>
              </ul>
            </p>
            <p>Last updated on April 10, 2024</p>
          </Col>
        </Row>
        <br />
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <WebsiteFooter />
          </Col>
        </Row>
      </div>
    </>
  );
};
