import { message, notification } from 'antd';
import { APIResponseCode, MessageDisplay } from 'common/constants';
import { GetNotificationProps } from 'common/utils/utility';
import { APIResponse, PostData } from 'common/utils/jsonFetch';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { Validator } from 'common/validators/validators';
import {
  ContainerBarcodeDetailViewModel,
  ContainerDetailsViewModel,
  ContainerViewModel,
  DeleteBarcodeRequest,
  UploadBarcodeRequest,
  UploadBarcodeResponse,
} from 'common/models/ContainerTypes';
import { ContainerActions } from '../store/ContainerSlice';
import { HandleErrorMessage } from '@features/Common';

export const SaveContainer = (request: ContainerViewModel) => async (dispatch) => {
  const methodName = 'SaveContainer';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Container_Save, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    message.success('Container: ' + request.ContainerNo + ' saved successfully');
    dispatch(ContainerActions.SetSelectedContainerId(response.parsedBody?.Results));
    await dispatch(GetContainerById(response.parsedBody?.Results));
    await dispatch(GetContainerDetailsByContainerId(response.parsedBody?.Results));
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    message.error('The container number is already in use. Please enter a different container number.');
    // notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    // throw e; // Rethrow the error to propagate it further if needed
  }
};

export const UpdateContainer = (request: ContainerViewModel) => async (dispatch) => {
  const methodName = 'UpdateContainer';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Container_Update, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    message.success('Container: ' + request.ContainerNo + ' updated successfully');
    await dispatch(GetContainerById(request.Id));
    await dispatch(GetContainerDetailsByContainerId(request.Id));
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    message.error('The container number is already in use. Please enter a different container number.');
    // notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    // throw e; // Rethrow the error to propagate it further if needed
  }
};

export const GetAllContainer = () => async (dispatch) => {
  const methodName = 'GetAllContainer';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Container_GetAll, {});
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    const data: ContainerViewModel[] = response.parsedBody?.Results;
    dispatch(ContainerActions.SetContainerList(data));
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const GetContainerById = (id: string) => async (dispatch) => {
  const methodName = 'GetContainerById';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Container_GetById, id);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    const data: ContainerViewModel = response.parsedBody?.Results;
    dispatch(ContainerActions.SetContainer(data));
    dispatch(ContainerActions.submitStatusSucceeded());
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const DeleteContainer = (id: string) => async (dispatch) => {
  const methodName = 'DeleteContainer';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Container_Delete, id);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    message.success('Container deleted successfully');
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const SaveContainerDetails = (request: ContainerDetailsViewModel) => async (dispatch) => {
  const methodName = 'SaveContainer';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.ContainerDetails_Save, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    await dispatch(GetContainerById(request.ContainerId));
    message.success('Container status saved successfully');
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const UpdateContainerDetails = (request: ContainerDetailsViewModel) => async (dispatch) => {
  const methodName = 'UpdateContainerDetails';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.ContainerDetails_Update, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    await dispatch(GetContainerById(request.ContainerId));
    message.success('Container status updated successfully');
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const GetContainerDetailsByContainerId = (request: string) => async (dispatch) => {
  const methodName = 'GetContainerDetailsByContainerId';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.ContainerDetails_GetByContainerId, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    const data: ContainerDetailsViewModel[] = response.parsedBody?.Results;
    dispatch(ContainerActions.SetContainerDetailsList(data));
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const DeleteContainerDetails = (request: string) => async (dispatch) => {
  const methodName = 'UpdateContainerDetails';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.ContainerDetails_Delete, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    message.success('Container status deleted successfully');
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const LoadToContainer = (request: UploadBarcodeRequest) => async (dispatch) => {
  const methodName = 'LoadToContainer';

  try {
    dispatch(ContainerActions.submitStatus());
    dispatch(ContainerActions.SetUploadBarcodeResponse(undefined));
    const response = await PostData<APIResponse>(KFSEndpoints.Container_UploadBarcode, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    const data: UploadBarcodeResponse = response.parsedBody?.Results;
    if (data.IsSuccess) {
      message.success('Barcode successfully uploaded');
    }
    dispatch(ContainerActions.SetUploadBarcodeResponse(data));
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const GetContainerBarcodeDetails = (request: string) => async (dispatch) => {
  const methodName = 'GetContainerBarcodeDetails';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.ContainerDetails_GetContainerBarcodeDetails, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    const data: ContainerBarcodeDetailViewModel[] = response.parsedBody?.Results;
    dispatch(ContainerActions.SetContainerBarcodeDetailList(data));
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const DeleteContainerBarcode = (request: DeleteBarcodeRequest) => async (dispatch) => {
  const methodName = 'DeleteContainerBarcode';

  try {
    dispatch(ContainerActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.ContainerDetails_DeleteContainerBarcode, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(ContainerActions.submitStatusSucceeded());
    message.success('Barcode successfully deleted');
  } catch (e) {
    dispatch(ContainerActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};
