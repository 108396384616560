export const asyncFunctionMiddleware = (storeAPI) => (next) => (action) => {
  // If the "action" is actually a function instead...
  if (typeof action === 'function') {
    // then call the function and pass `dispatch` as arguments
    return action(storeAPI.dispatch);
  }

  // Otherwise, it's a normal action - send it onwards
  return next(action);
};
