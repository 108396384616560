import { PATHS } from '@routing/routes';
import { Col, Row, Form, Button } from 'antd';
import {
  CodeSandboxOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  FileSearchOutlined,
  LockOutlined,
  BarcodeOutlined,
  FormOutlined,
  GiftOutlined,
  TableOutlined,
  CalculatorOutlined,
  MergeCellsOutlined,
  GlobalOutlined,
  BarChartOutlined,
  AppstoreAddOutlined,
  CreditCardOutlined,
  FileProtectOutlined,
  FileAddOutlined,
  FileTextOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { BookingActions, IsFirstTransaction } from '@features/Booking';
import { useDispatch, useSelector } from 'react-redux';
import { SenderDetailsActions } from '@features/SenderDetails';
import { ReceiverActions } from '@features/Receiver';
import { Constants, FilterType, Roles } from 'common/constants';
import { TransactionActions } from '@features/Transaction';
import { BarcodeActions } from '@features/Barcode';
import { AccountSelector } from '@features/Account';
import { TrackingActions } from '@features/Tracking';
import { OrderBoxActions } from '@features/OrderBox';
import { commonAction } from '@features/Common/store/commonSlice';
import { ContainerActions } from '@features/Container';

export const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accountState = useSelector(AccountSelector);

  useEffect(() => {
    dispatch(BookingActions.resetState());
    dispatch(SenderDetailsActions.resetState());
    dispatch(ReceiverActions.resetState());
    dispatch(BarcodeActions.resetState());
    dispatch(TransactionActions.resetState());
    dispatch(TrackingActions.resetState());
    dispatch(OrderBoxActions.resetState());
    dispatch(commonAction.ResetGlobalState());
    dispatch(ContainerActions.resetState());
    // if (accountState.RoleName === null || accountState.RoleName === undefined) {
    //   history.push(PATHS.Login.path);
    // }
  }, []);

  const onSendaBox = async () => {
    await dispatch(IsFirstTransaction());
    dispatch(BookingActions.SetIsSendABox(true));
    history.push('/sender/listing');
  };

  const onKFSSendaBox = async () => {
    dispatch(BookingActions.SetIsSendABox(true));
    dispatch(BookingActions.SetIsFromSignupKFS(true));
    history.push(PATHS.Sender.path);
  };

  const onSenderList = () => {
    dispatch(BookingActions.SetIsSendABox(false));
    history.push('/sender/listing');
  };

  const onTransactionRedirect = (
    filterType: typeof FilterType.RefNo | typeof FilterType.Email | typeof FilterType.Date
  ) => {
    dispatch(TransactionActions.SetFilterType(filterType));
    history.push('/transaction');
  };

  return (
    <>
      <Row gutter={{ xs: 16, sm: 10 }} justify="center" align="middle">
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<FormOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.Order.path)}
            >
              <div className="menu-small-320">Take order</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<FileSearchOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={onKFSSendaBox}
            >
              <div className="menu-small-320">Sales report</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<AppstoreAddOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={onKFSSendaBox}
            >
              <div className="menu-small-320">Product</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<CreditCardOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.Expenses.path)}
            >
              <div className="menu-small-320">Expenses</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<FileAddOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.Todo.path)}
            >
              <div className="menu-small-320">Todo</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<BarChartOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={onKFSSendaBox}
            >
              <div className="menu-small-320">Data analytics</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<FileProtectOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.Customer.path)}
            >
              <div className="menu-small-320">Customer</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<UsergroupAddOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.Employee.path)}
            >
              <div className="menu-small-320">Employee</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<FileTextOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.EmployeePayroll.path)}
            >
              <div className="menu-small-320">Employee Payroll</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<CopyOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.EmployeeAttendance.path)}
            >
              <div className="menu-small-320">Employee Attendace</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<UserOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.BusinessProfile.path)}
            >
              <div className="menu-small-320">Business profile</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<GlobalOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.BusinessWebsite.path)}
            >
              <div className="menu-small-320">Business website</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}>
          <Form.Item>
            <Button
              type="default"
              icon={<LockOutlined />}
              size="large"
              block
              className="bold left-icon-button main-menu-option site-form-item-icon"
              onClick={() => history.push(PATHS.ChangePassword.path)}
            >
              <div className="menu-small-320">Change password</div>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}></Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}></Col>
        <Col xs={20} sm={16} md={10} lg={6} xl={5}></Col>
      </Row>
    </>
  );
};
