import { Button, Col, Row, DatePicker, Modal, Breadcrumb } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { BookingSelector } from '../store/BookingSelector';
import { useState } from 'react';
import { Constants } from 'common/constants';
import { TermsAndCondition } from '@features/Webpage/components/TermsAndCondition';

export const Declaration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const bookingState = useSelector(BookingSelector);
  const [modalVisible, setModalVisible] = useState(false);

  const handleViewTermsClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleAgree = () => {
    // Perform actions when the user agrees (e.g., update state, submit form)
    // You can add your custom logic here
    setModalVisible(false); // Close the modal
  };
  const onFinish = (values: any) => {
    // alert(JSON.stringify(values));
    // dispatch(
    //   BookingActions.SetReceiver({
    //     ...values,
    //   })
    // );
    history.push('/booking/boxdetails');
  };
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <Col>
          <h1>Sender‘s declaration</h1>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} sm={20} md={16} lg={12} xl={8}>
          <Breadcrumb
            items={[
              {
                title: 'Sender',
              },
              {
                title: 'Sender address',
              },
              {
                title: 'Receiver',
              },
              {
                title: 'Box content',
              },
              {
                title: 'Pick up and box details',
              },
              {
                title: bookingState.ReferralResponse?.IsFirstTransaction ? 'Perks for you' : null,
              },
              {
                title: 'Declaration',
              },
            ]}
          />
          <br />
          <span>This is to certify that:</span>
          <ul>
            <li>I am the sender of the items contained in this parcel being sent to the Philippines;</li>
            <li>The listed items are accurate;</li>
            <li>The items are for personal use only;</li>
            <li>
              There are no undeclared, restricted, illegal, or banned items including firearms, ammunition, illegal
              drugs, combustible goods in this shipment;
            </li>
            <li>I agree to the Terms and Conditions.</li>
          </ul>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <a onClick={handleViewTermsClick}>View full terms and conditions</a>
        </Col>
        <Modal
          title=""
          visible={modalVisible}
          onCancel={handleCloseModal}
          footer={[
            <Button key="agree" type="primary" onClick={handleAgree}>
              Agree
            </Button>,
          ]}
        >
          <div className="scrollable-modal-content-TOC">
            <TermsAndCondition />
          </div>
        </Modal>
      </Row>
      <br />
      <Row justify="center">
        <Col xs={24} sm={20} md={16} lg={12} xl={8}>
          <Button
            className="prevSenderBtn"
            type="default"
            onClick={() =>
              history.push(bookingState.ReferralResponse?.IsFirstTransaction ? '/booking/perks' : '/booking/boxdetails')
            }
          >
            {Constants.BTN_BACK}
          </Button>
          <Button className="nextSenderBtn" type="primary" onClick={() => history.push('/booking/summary')}>
            Accept and continue
          </Button>
        </Col>
      </Row>
    </div>
  );
};
