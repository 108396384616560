import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReceiverStatus } from 'common/constants';
import { ReceiverModel } from 'common/models/BookingTypes';
import { OperationStatus } from 'store/rootTypes';

const name = 'Receiver';

interface IReceivertate {
  submitStatus: OperationStatus;
  Receiver?: ReceiverModel;
  ReceiverStatus: typeof ReceiverStatus.New | typeof ReceiverStatus.Update | typeof ReceiverStatus.Booking;
  SelectedId?: string;
  IsReceiverNoSelected: boolean;
}

const initialState: IReceivertate = {
  submitStatus: OperationStatus.idle,
  Receiver: undefined,
  ReceiverStatus: ReceiverStatus.New,
  SelectedId: undefined,
  IsReceiverNoSelected: false,
};

export const ReceiverSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetReceiverStatus: (
      state,
      action: PayloadAction<typeof ReceiverStatus.New | typeof ReceiverStatus.Update | typeof ReceiverStatus.Booking>
    ) => {
      state.ReceiverStatus = action.payload;
    },
    SetReceiver: (state, action: PayloadAction<ReceiverModel>) => {
      state.Receiver = {
        ...state.Receiver,
        ...action.payload,
      };
    },
    SetSelectedId: (state, action: PayloadAction<string | undefined>) => {
      state.SelectedId = action.payload;
    },
    SetIsReceiverNoSelected: (state, action: PayloadAction<boolean>) => {
      state.IsReceiverNoSelected = action.payload;
    },
    ResetReceiver: (state) => {
      state.Receiver = undefined;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state, action: PayloadAction<ReceiverModel | undefined>) => {
      state.Receiver = action.payload;
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: ReceiverActions } = ReceiverSlice;

export default ReceiverSlice.reducer;
