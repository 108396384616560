import { Button, Col, DatePicker, Form, Row, Table } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { BookingSelector } from '../store/BookingSelector';

import { Constants } from 'common/constants';
import { TransactionSelector } from '@features/Transaction';
import { PATHS } from '@routing/routes';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs'; // Use dayjs instead of moment for consistency
import { useRef } from 'react';
export const Delivered = () => {
  const transactionState = useSelector(TransactionSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef<any>(null); // Reference to the form
  const bookingState = useSelector(BookingSelector);
  const onFinish = async (values: any) => {
    const values1 = columns.map((column) => {
      const { title, dataIndex, key, render } = column;
      return { title, dataIndex, key, render };
    });

    console.log(values);
    // const { Remarks, ...restValues } = values; // Extract remarks and other form values
    // // Get the table data directly from the form
    // const tableData = formRef.current.getFieldValue('DeliveredDate').map((date: any, index: number) => ({
    //   DeliveredDate: dayjs(date).format('YYYY/MM/DD'),
    //   Barcode: `${transactionState?.TransactionSummary?.Barcode[index]?.Abbreviations1}${transactionState?.TransactionSummary?.Barcode[index]?.Number}${transactionState?.TransactionSummary?.Barcode[index]?.Abbreviations2}`,
    // }));
    // const requestData = {
    //   ...restValues, // Add other form values
    //   remarks: Remarks,
    //   tableData: tableData,
    // };
    // // Call your API with requestData
    // console.log(requestData); // This will log the data you're sending to the API
    // Example API call:
    // const response = await yourAPICall(requestData);
  };

  const dateFormat = 'YYYY/MM/DD';
  const columns = [
    {
      title: 'Date delivered',
      dataIndex: 'DeliveredDate',
      key: 'DeliveredDate',
      render: (text: string, record: any) => (
        <DatePicker
          size="large"
          value={record.DeliveredDate ? dayjs(record.DeliveredDate) : undefined}
          className="full-width"
          placeholder="Select Date"
        />
      ),
    },
    {
      title: 'Barcode',
      dataIndex: 'Barcode',
      key: 'Barcode',
      render: (text, record) => {
        return `${record.Abbreviations1}${record.Number}${record.Abbreviations2}`;
      },
    },
  ];

  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Upadate to delivered</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical" ref={formRef}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <Table
              className="table-summary"
              dataSource={transactionState?.TransactionSummary?.Barcode}
              columns={columns}
              pagination={false}
            />
            <br />
            <Form.Item initialValue={bookingState.ReferralResponse?.Referrer} label="Remarks" name="Remarks">
              <TextArea size="large" />
            </Form.Item>
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(PATHS.TransactionSummary.path)}
              >
                {Constants.BTN_BACK}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                {Constants.BTN_SAVE}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
