import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { useHistory } from 'react-router';

import { Constants } from 'common/constants';
import 'App.css';
import TextArea from 'antd/es/input/TextArea';
import { PATHS } from '@routing/routes';
import { AUStateCodeList } from 'common/code/AUPostalCode';
import { useState } from 'react';
import { MobileCountryCodeList } from 'common/code/MobileCountryCode';
import { handleFocusContactNumber, handleKeyDownContactNumberNew } from 'common/utils/utility';
export const BusinessWebsite = () => {
  const history = useHistory();

  const onFinish = async (values: any) => {
    console.error('SelectedId is undefined');
  };
  const [selectedMobileCode, setSelectedMobileCode] = useState('+63');
  const { Option } = Select;

  const selectStyle = { width: '100px' }; // Adjust the width as needed
  const inputStyle = { width: 'calc(100% - 100px)' }; // Adjust the width based on the select width

  return (
    <div className="App ">
      <Row justify="center" align="middle">
        <div className="center-text">
          <h2 className="mb-0">Business website</h2>
          <p className="textColor mtb">Kasangga mo sa negosyo</p>
        </div>
      </Row>

      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={14} lg={10} xl={8} className="">
            <h1 className="headermaintext"> </h1>
            <Form.Item
              label="Website header"
              name="Header"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={25} />
            </Form.Item>
            <Form.Item label="About us" name="AboutUs" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <TextArea size="large" maxLength={1000} />
            </Form.Item>
            <Form.Item
              label="Our clients"
              name="OurClients"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <TextArea size="large" maxLength={1000} />
            </Form.Item>
            <Form.Item
              label="Contact us"
              name="ContactUs"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <TextArea size="large" maxLength={1000} />
            </Form.Item>
            <br />
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(PATHS.Home.path)}
              >
                {Constants.BTN_CLOSE}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                {Constants.BTN_SAVE}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
