interface BookingTypeCode {
  name: string;
  code: string;
}

export const BookingTypeCodeList: BookingTypeCode[] = [
  {
    name: 'Sea',
    code: 'S',
  },
  {
    name: 'Air',
    code: 'A',
  },
];

interface PaymentTypeCode {
  name: string;
  code: string;
}

export const PaymentTypeCodeList: PaymentTypeCode[] = [
  {
    name: 'Cash',
    code: 'CA',
  },
  {
    name: 'Bank transfer',
    code: 'BT',
  },
];

export const getPaymentTypeNameByCode = (code: string): string | undefined => {
  const paymentType = PaymentTypeCodeList.find((item) => item.code === code);
  return paymentType?.name;
};

interface ContainerTypeCode {
  name: string;
  code: string;
}

export const ContainerStatusTypeCodeList: ContainerTypeCode[] = [
  {
    name: 'New',
    code: 'NW',
  },
  {
    name: 'In-Transit',
    code: 'IN',
  },
  {
    name: 'Delivered',
    code: 'DE',
  },
];
