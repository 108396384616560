import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import { useHistory } from 'react-router';

import { Constants } from 'common/constants';
import 'App.css';
import TextArea from 'antd/es/input/TextArea';
import { Table } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { useState } from 'react';
import { ContactUs } from '@features/Webpage/components/ContactUs';
import { PATHS } from '@routing/routes';
import { AUStateCodeList } from 'common/code/AUPostalCode';

export const Customer = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const onBack = () => {
    history.push('/transaction/summary');
  };

  const onFinish = async (values: any) => {
    console.log('');
  };

  interface DataType {
    key: React.Key;
    Name: string;
    Hiredate: number;
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'Name',
    },
    {
      title: 'Hire date',
      dataIndex: 'Hiredate',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
    },
  ];

  const data: DataType[] = [
    {
      key: '1',
      Name: 'John Brown',
      Hiredate: 32,
    },
    {
      key: '2',
      Name: 'Jim Green',
      Hiredate: 42,
    },
    {
      key: '3',
      Name: 'Joe Black',
      Hiredate: 32,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <div className="App borderApp">
      <Row justify="center" align="middle">
        <h1>Customer</h1>
      </Row>

      <Form name="senderForm" form={form} onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <a className="addTextTable" onClick={() => history.push(PATHS.CustomerAdd.path)}>
              Add new customer
            </a>
            <Table columns={columns} dataSource={data} size="middle" className="tableBorder" />
          </Col>
        </Row>
        <br />
        <Row justify="start" align="middle">
          <Col xs={24} sm={20} md={12} lg={10} xl={8}>
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(PATHS.Home.path)}
              >
                {Constants.BTN_CLOSE}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
