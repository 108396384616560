import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import { useHistory } from 'react-router';

import { Constants } from 'common/constants';
import 'App.css';
import TextArea from 'antd/es/input/TextArea';
import { Table } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { useState } from 'react';
import { ContactUs } from '@features/Webpage/components/ContactUs';
import { PATHS } from '@routing/routes';
import { AUStateCodeList } from 'common/code/AUPostalCode';

export const EmployeeAttendance = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const onBack = () => {
    history.push('/transaction/summary');
  };

  const onFinish = async (values: any) => {
    console.log('');
  };

  interface DataType {
    key: React.Key;
    Name: string;
    Hiredate: number;
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'Name',
    },
    {
      title: 'Hire date',
      dataIndex: 'Hiredate',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
    },
  ];

  const data: DataType[] = [
    {
      key: '1',
      Name: 'John Brown',
      Hiredate: 32,
    },
    {
      key: '2',
      Name: 'Jim Green',
      Hiredate: 42,
    },
    {
      key: '3',
      Name: 'Joe Black',
      Hiredate: 32,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <div className="App borderApp">
      <Row justify="center" align="middle">
        <h1>Employee attendance</h1>
      </Row>

      <Form name="senderForm" form={form} onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle" className="textColor">
          <Col xs={24} sm={20} md={12} lg={10} xl={8}>
            <Form.Item label="Name" name="Name">
              <Select size="large" showSearch placeholder="Select employee" optionFilterProp="children">
                {AUStateCodeList.map((destination) => (
                  <Select.Option key={destination.code} value={destination.code}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row gutter={[16, 16]}>
              {' '}
              {/* Added a new Row component */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                {' '}
                {/* Adjusted column sizes */}
                <Form.Item
                  label="Attendance from"
                  name="DateFrom"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <DatePicker size="large" className="full-width" placeholder="Attendance from" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Attendance to"
                  name="DateTo"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <DatePicker size="large" className="full-width" placeholder="Attendance to" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={10} xl={8}>
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(PATHS.Home.path)}
              >
                {Constants.BTN_CLOSE}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                Search
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <a className="addTextTable" onClick={() => history.push(PATHS.AddEmployeeAttendance.path)}>
              Add new attendance
            </a>
            <Table columns={columns} dataSource={data} size="middle" className="tableBorder" />
          </Col>
        </Row>
        {/* <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={10} lg={8} xl={6}>
            <div>
              <Button
                className="nextSenderBtn"
                size="large"
                type="primary"
                onClick={() => history.push(PATHS.AddExpenses.path)}
              >
                Add expenses
              </Button>
            </div>
          </Col>
        </Row> */}
      </Form>
    </div>
  );
};
