import { Link, useHistory } from 'react-router-dom';
import { Avatar, Menu } from 'antd';
import { Constants } from 'common/constants';
import { PATHS } from 'common/route/routes';
import { GetUUID } from 'common/utils/utility';
import { ClearCache } from '@features/Login';
import type { MenuProps } from 'antd';
import { CgLogOut } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CustomMenu.module.less';
import { BookingActions } from '@features/Booking';
import { SenderDetailsActions } from '@features/SenderDetails';
import { BarcodeActions } from '@features/Barcode';
import { ReceiverActions } from '@features/Receiver';
import { TransactionActions } from '@features/Transaction';
import { TrackingActions } from '@features/Tracking';
import { UserOutlined } from '@ant-design/icons';
import { AccountSelector } from '@features/Account';
export const CustomMenu = () => {
  const accountState = useSelector(AccountSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = (e) => {
    // perform different actions based on e.key or e.item
    switch (e.key) {
      case Constants.LOGOUT_LABEL:
        // LogoutRedirect();
        ClearCache();
        dispatch(BookingActions.resetState());
        dispatch(SenderDetailsActions.resetState());
        dispatch(ReceiverActions.resetState());
        dispatch(BarcodeActions.resetState());
        dispatch(TransactionActions.resetState());
        dispatch(TrackingActions.resetState());
        history.push(PATHS.Login.path);
        break;
      default:
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      label: <Link to={PATHS.Home.path}>Home</Link>,
      key: GetUUID(),
    },
    {
      label: <Avatar className={styles.menuAvatar} icon={<UserOutlined />}></Avatar>,
      key: GetUUID(),
      children: [
        {
          label: <span className={styles.menuText}>{accountState?.Username}</span>,
          key: 'username',
          icon: <UserOutlined className={styles.menuText} />,
          disabled: true,
        },
        {
          label: <span className={styles.menuText}>Sign Out</span>,
          key: Constants.LOGOUT_LABEL,
          icon: <CgLogOut className={styles.menuText} />,
        },
      ],
    },
  ];

  return (
    <div>
      <Menu
        className={`justifyContentFlexEnd ${styles.borderBottomUnset}`}
        onClick={handleClick}
        mode="horizontal"
        items={items}
      />
    </div>
  );
};
