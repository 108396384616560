import { Button, Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import { useHistory } from 'react-router';

import { Constants } from 'common/constants';
import 'App.css';
import TextArea from 'antd/es/input/TextArea';
import { Table } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { useState } from 'react';
import { ContactUs } from '@features/Webpage/components/ContactUs';
import { PATHS } from '@routing/routes';

export const Expenses = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const onBack = () => {
    history.push('/transaction/summary');
  };

  const onFinish = async (values: any) => {
    console.log('');
  };

  interface DataType {
    key: React.Key;
    Date: string;
    Details: number;
    Amount: string;
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'Date',
    },
    {
      title: 'Details',
      dataIndex: 'Details',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
    },
  ];

  const data: DataType[] = [
    {
      key: '1',
      Date: 'John Brown',
      Details: 32,
      Amount: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      Date: 'Jim Green',
      Details: 42,
      Amount: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      Date: 'Joe Black',
      Details: 32,
      Amount: 'Sydney No. 1 Lake Park',
    },
  ];
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <div className="App borderApp">
      <Row justify="center" align="middle">
        <h1>Expenses</h1>
      </Row>

      <Form name="senderForm" form={form} onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle" className="textColor">
          <Col xs={24} sm={20} md={12} lg={8} xl={6}>
            <Form.Item
              label="Date from"
              name="DateFrom"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <DatePicker
                size="large"
                className="full-width"
                placeholder="Date from"
                // Set to present date
              />
            </Form.Item>
            <Form.Item label="Date to" name="DateTo" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <DatePicker size="large" className="full-width" placeholder="Date to" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={6}>
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(PATHS.Home.path)}
              >
                {Constants.BTN_CLOSE}
              </Button>
              <Button
                className="nextSenderBtn"
                size="large"
                type="primary"
                onClick={() => history.push(PATHS.AddExpenses.path)}
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <a className="addTextTable" onClick={() => history.push(PATHS.AddExpenses.path)}>
              Add new expenses
            </a>
            <Table columns={columns} dataSource={data} size="middle" className="tableBorder" />
          </Col>
        </Row>
        {/* <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={10} lg={8} xl={6}>
            <div>
              <Button
                className="nextSenderBtn"
                size="large"
                type="primary"
                onClick={() => history.push(PATHS.AddExpenses.path)}
              >
                Add expenses
              </Button>
            </div>
          </Col>
        </Row> */}
      </Form>
    </div>
  );
};
