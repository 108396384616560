import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { useHistory } from 'react-router';

import { Constants } from 'common/constants';
import 'App.css';
import TextArea from 'antd/es/input/TextArea';
import { PATHS } from '@routing/routes';
import { AUStateCodeList } from 'common/code/AUPostalCode';
import { useState } from 'react';
import { MobileCountryCodeList } from 'common/code/MobileCountryCode';
import { handleFocusContactNumber, handleKeyDownContactNumberNew } from 'common/utils/utility';
import PropTypes from 'prop-types'; // Import PropTypes
export const Order = () => {
  const history = useHistory();

  const onFinish = async (values: any) => {
    console.error('SelectedId is undefined');
  };
  const [selectedMobileCode, setSelectedMobileCode] = useState('+63');
  const { Option } = Select;

  const selectStyle = { width: '100px' }; // Adjust the width as needed
  const inputStyle = { width: 'calc(100% - 100px)' }; // Adjust the width based on the select width
  const menus = [
    { id: 1, name: 'Menu 1' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 1, name: 'Menu 1' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 1, name: 'Menu 1' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 1, name: 'Menu 1' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    { id: 2, name: 'Menu 2' },
    // Add more menu items as needed
  ];
  return (
    <div className="App ">
      <Row justify="center" align="middle">
        <div className="center-text">
          <h2 className="mb-0">Take order</h2>
          <p className="textColor mtb">Kasangga mo sa negosyo</p>
        </div>
      </Row>
      <br />
      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row>
          <Col xs={24} sm={24} md={14} lg={10} xl={8} className="">
            <Form.Item
              label="Select Customer"
              name="Name"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Select size="large" showSearch placeholder="Select employee" optionFilterProp="children">
                {AUStateCodeList.map((destination) => (
                  <Select.Option key={destination.code} value={destination.code}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <label>Select item</label>
        <Row gutter={[16, 16]}>
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className=""> */}

          <br />
          <br />
          {menus.map((menu) => (
            <Col key={menu.id} xs={12} sm={16} md={8} lg={6} xl={4}>
              <Form.Item>
                <Button
                  type="default"
                  size="large"
                  block
                  className="bold left-icon-button main-menu-option site-form-item-icon"
                >
                  <div className="menu-small-320">{menu.name}</div>
                </Button>
              </Form.Item>
            </Col>
          ))}
          {/* </Col> */}
        </Row>
        <br />
        <div>
          <Button className="prevSenderBtn" size="large" type="default" onClick={() => history.push(PATHS.Home.path)}>
            {Constants.BTN_CLOSE}
          </Button>
          <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
            {Constants.BTN_SAVE}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const MenuItem = ({ label }) => (
  <Row>
    <Col xs={24} sm={24} md={14} lg={10} xl={8} className="">
      <Col xs={20} sm={16} md={10} lg={6} xl={5}>
        <Form.Item>
          <Button
            type="default"
            size="large"
            block
            className="bold left-icon-button main-menu-option site-form-item-icon"
          >
            <div className="menu-small-320">{label}</div>
          </Button>
        </Form.Item>
      </Col>
    </Col>
  </Row>
);

MenuItem.propTypes = {
  label: PropTypes.string.isRequired, // Define PropTypes for label
};

export default MenuItem;
