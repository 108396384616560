import { Card, Col, Row, Space, Steps, Input, Button, Modal, Divider } from 'antd';
import '@features/Webpage/homepage.css';
import {
  KFSpartnerstoreicon,
  attendance,
  boxIconExport,
  businessFreighIcon,
  containerIcon,
  customer,
  employee,
  employeereport,
  expenses,
  luzonIcon,
  minIcon,
  mnlIcon,
  payroll,
  phoneIconExport,
  productmanagement,
  refer,
  referred,
  salesreport,
  takeorder,
  todolist,
  visIcon,
  webpageheader,
  website,
} from 'common/images';
import { Link, useHistory } from 'react-router-dom';
import { PATHS } from '@routing/routes';
import { Constants, TransactionStatus, TransactionType } from 'common/constants';
import { WebsiteFooter } from './Footer';
import { useEffect, useState } from 'react';
const { Step } = Steps;
const { Search } = Input;
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  SolutionOutlined,
  ClockCircleOutlined,
  FileProtectOutlined,
  HomeOutlined,
  GlobalOutlined,
  InsertRowBelowOutlined,
  CodeSandboxOutlined,
  BankOutlined,
  MergeCellsOutlined,
} from '@ant-design/icons';

import { FaDocker } from 'react-icons/fa';
import { TrackingStatusResponse } from 'common/models';
import { useDispatch, useSelector } from 'react-redux';
import { GetTrackingStatus, TrackingSelector } from '@features/Tracking';
import { PostStart } from '@features/Common';
export const Craft = () => {
  // Adjust the condition based on your requirement
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fromSearchValue, setFromSearch] = useState(false);
  const dispatch = useDispatch();
  const trackingState = useSelector(TrackingSelector);
  const [data, setData] = useState<TrackingStatusResponse[]>([]);
  // let data: TrackingStatusResponse[] = [];
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    // Hide the error message when the user starts typing
    setErrorMessage('');
  };
  const [trackingLabel, setTrackingLabel] = useState('');
  const handleSearchSubmit = async () => {
    setFromSearch(true);
    setData([]);
    if (searchValue.trim() !== '') {
      // Perform your action when the search is not empty
      await dispatch(GetTrackingStatus(searchValue.trim(), true));
      // const updatedData = trackingState.TrackingStatusResponse;
    } else {
      // Set the error message when the search is empty
      setErrorMessage(Constants.REQUIRED_FIELD);
    }
  };

  useEffect(() => {
    if (fromSearchValue) {
      setData(trackingState.TrackingStatusResponse);

      // Check if the data is empty and update the error message accordingly
      if (trackingState.TrackingStatusResponse.length === 0) {
        setErrorMessage('Record not found');
      } else {
        setModalVisible(true);
        setErrorMessage('');
      }
    }
  }, [trackingState.TrackingStatusResponse]);

  const [modalVisible, setModalVisible] = useState(false);

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    setTrackingLabel(data[0]?.Type == TransactionType.OrderBox ? 'Tracking - Empty box order' : 'Tracking');
  }, [data[0]?.Type]);
  const handleAgree = () => {
    // Perform actions when the user agrees (e.g., update state, submit form)
    // You can add your custom logic here
    setModalVisible(false); // Close the modal
  };

  useEffect(() => {
    dispatch(PostStart());
  }, []);
  return (
    <>
      <Row justify="center" align="middle">
        <div className="center-text">
          <h1 className="mtb header-fs">Our craft </h1>
        </div>
      </Row>
      <br />

      <Row gutter={24}>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 24, order: 1 }}
          lg={{ span: 24, order: 1 }}
          xl={{ span: 24, order: 1 }}
        >
          <h3>Our application features</h3>

          <Row gutter={24}>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol mb-20">
              <Card
                title={
                  <div>
                    Take order <br />
                  </div>
                }
                cover={<img alt="example" src={takeorder} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol mb-20">
              <Card
                title={
                  <div>
                    Sales report <br />
                  </div>
                }
                cover={<img alt="example" src={salesreport} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol mb-20">
              <Card
                title={
                  <div>
                    Product management <br />
                  </div>
                }
                cover={<img alt="example" src={productmanagement} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol mb-20">
              <Card
                className="cardService"
                title={
                  <div>
                    Expenses management
                    <br />
                  </div>
                }
                cover={<img alt="example" src={expenses} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol  mb-20">
              <Card
                className="cardService"
                title={
                  <div>
                    Customer management
                    <br />
                  </div>
                }
                cover={<img alt="example" src={customer} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol  mb-20">
              <Card
                title={
                  <div>
                    Todo list <br />
                  </div>
                }
                cover={<img alt="example" src={todolist} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol  mb-20">
              <Card
                title={
                  <div>
                    Data analytics <br />
                  </div>
                }
                cover={<img alt="example" src={mnlIcon} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol  mb-20">
              <Card
                title={
                  <div>
                    Employee management <br />
                  </div>
                }
                cover={<img alt="example" src={employee} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol  mb-20">
              <Card
                title={
                  <div>
                    Employee report <br />
                  </div>
                }
                cover={<img alt="example" src={employeereport} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol  mb-20">
              <Card
                className="cardService"
                title={
                  <div>
                    Employee payroll management
                    <br />
                  </div>
                }
                cover={<img alt="example" src={payroll} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol  mb-20">
              <Card
                className="cardService"
                title={
                  <div>
                    Employee attendance management
                    <br />
                  </div>
                }
                cover={<img alt="example" src={attendance} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol  mb-20">
              <Card
                className="cardService"
                title={
                  <div>
                    Business website
                    <br />
                  </div>
                }
                cover={<img alt="example" src={website} className="serviceImg" />}
              ></Card>
            </Col>
          </Row>
        </Col>

        {/* Second Column with Text */}
        {/* <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 8, order: 2 }}
          lg={{ span: 8, order: 2 }}
          xl={{ span: 8, order: 2 }}
        >
          <h3>Some perks for you!</h3>
          <div>
            <ul>
              <li className="liWhyKFS">Get a $10 credit when you sign up online.</li>
              <br />
              <li className="liWhyKFS">Refer your friends, and you both get a $10 discount on your shipping fees.</li>
              <br />
              <li className="liWhyKFS">Send 10 balikbayan boxes, and ship the next box free of charge.</li>
            </ul>
            <a onClick={() => history.push(PATHS.TermsAndConditionRewards.path)} className="tAndClink">
              Terms and conditions of our perks
            </a>
          </div>
        </Col> */}
      </Row>
      <br />
      <br />

      {/* <br />
      <h3 className="balibayantext">The returning residents a.k.a balikbayan</h3>
      <Row gutter={16} className="boder-balibayantext">
        <Col xs={24} sm={8} md={12} lg={12} xl={12} className="BusinessfreightDiv">
          <p className="bold balibayantext textColor">
            {' '}
            We can arrange a whole container for your white goods or all your home contents.
          </p>

          <ul className="">
            <li className="liWhyKFS textColor">Full container load (FCL)</li>
            <p className="textColor mtb"> - 20 footer container</p>
            <p className="textColor mtb"> - 40 footer container</p>
          </ul>
          <ul className="">
            <li className="liWhyKFS textColor">Less container load (LCL)</li>
          </ul>
          <p className="textColor"> </p>
          <Link to="/contactus" className="normal-font floatRight">
            Let’s discuss your options {'>>>'}
          </Link>
        </Col>
        <Col xs={24} sm={16} md={12} lg={12} xl={12} className="containerIconCol">
          <img src={containerIcon} alt="Header" className="full-width heightAKABalikbayan" />
        </Col>
      </Row>
      <br />
      <h3 className="balibayantext">Business freight solutions</h3>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={16} md={12} lg={12} xl={12} className="flex-container-s">
          <img src={businessFreighIcon} alt="Header" className="full-width heightBusinessFreight" />
        </Col>
        <Col xs={24} sm={8} md={12} lg={12} xl={12} className="BusinessfreightDiv">
          <p className="bold balibayantext commercialTxt">Commercial goods for import and export globally.</p>
          <div className="pleftCom">
            <p className="textColor commercialTxt mtb-0 pleftCom"> Importation</p>
            <ul className="mtp-0">
              <li className="liWhyKFS commercialTxt"> Freight forwarding</li>
              <li className="liWhyKFS commercialTxt">Warehousing</li>
              <li className="liWhyKFS commercialTxt">Local distribution</li>
            </ul>
          </div>
          <Link to="/contactus" className="normal-font floatRight commercialTxt talkBusiness">
            Let’s talk about your business {'>>>'}
          </Link>
        </Col>
      </Row> */}
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};

const getIcon = (code: string) => {
  switch (code) {
    case TransactionStatus.NW: //New
      return <UserOutlined />;
    case TransactionStatus.PI: //For Pick up	For Pick up - [location]
      return <ClockCircleOutlined />;
    case TransactionStatus.ITRA: //In-Transit	Received - with KFS agent
      return <FileProtectOutlined />;
    case TransactionStatus.ITIS: //In-Transit	- Interstate
      return <MergeCellsOutlined />;
    case TransactionStatus.ITD: //In-Transit	Departed - [location]
      return <GlobalOutlined />;
    case TransactionStatus.ITAD: //In-Transit	Arrived - [location] transhipment port
      return <InsertRowBelowOutlined />;
    case TransactionStatus.ITDDP: //In-Transit	Departed - [location] transhipment port to Manila, PH
      return <GlobalOutlined />;
    case TransactionStatus.ITWHIPH: //In-Transit	Arrived - Manila port
      return <BankOutlined />;
    case TransactionStatus.ITADP: //In-Transit	Arrived - Manila port
      return <InsertRowBelowOutlined />;
    case TransactionStatus.ITWD: //In-Transit	Warehouse to destination - estimated delivery : [date]
      return <HomeOutlined />;
    case TransactionStatus.DE: //Delivered	Delivered - received by [name]
      return <CodeSandboxOutlined />;
    case TransactionStatus.CA: //Cancelled
      return <CloseCircleOutlined />;
    default:
      return <FileProtectOutlined />;
  }
};
