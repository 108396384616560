import { Alert, Button, Col, Form, Input, Modal, Row } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Constants } from 'common/constants';
import { PATHS } from '@routing/routes';
import { Refer } from '../business/IncentiveBusiness';
import { IncentiveSelector } from '../index';
import { OperationStatus } from 'store/rootTypes';
import { useState } from 'react';
import { AccountSelector } from '@features/Account';
import { TermsAndConditionRewards } from '@features/Webpage/components/TermsAndConditionRewards';
import '@features/Incentive/incentive.css';
export const Referral = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const incentiveState = useSelector(IncentiveSelector);
  const accountState = useSelector(AccountSelector);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setError] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    setError(false);
    setShowAlert(false);
    if (accountState.Username == values.Email) {
      setError(true);
      return;
    } else {
      await dispatch(Refer(values.Email));
      form.resetFields();
      setShowAlert(true);
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setError(false);
  };
  const [modalVisible, setModalVisible] = useState(false);
  const handleViewTermsClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Invite friends and get paid!</h1>
      </Row>

      <Form name="referralForm" onFinish={onFinish} layout="vertical" form={form}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <br />
            <Form.Item
              label={
                <div>
                  <span>
                    Get $10 for each friend who signs up and sends their first balikabayan box through your invitation.{' '}
                  </span>
                  <br />
                  <br />
                  <span>Plus, they’ll get $10 too!</span>
                  <br />
                  <br />
                  <span>Your friend’s email address</span>
                </div>
              }
              name="Email"
              rules={[
                { required: true, message: Constants.REQUIRED_FIELD },
                {
                  pattern: Constants.EMAIL_REGEX_INPUT,
                  message: 'Please enter a valid email address.',
                },
              ]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>
            {showAlert && incentiveState.submitStatus == OperationStatus.succeeded && (
              <>
                <Col xs={24} sm={20} md={24} lg={24} xl={24}>
                  <Alert
                    message="Invitation sent! Tell your friend to check the spam folder just in case!"
                    type="success"
                    showIcon
                    closable
                    onClose={handleAlertClose}
                  />
                </Col>
                <br />
              </>
            )}
            {showError && (
              <>
                <Col xs={24} sm={20} md={24} lg={24} xl={24}>
                  <Alert
                    message="Please provide the email address of your friend."
                    type="error"
                    showIcon
                    closable
                    onClose={handleAlertClose}
                  />
                </Col>
                <br />
              </>
            )}
            {incentiveState.submitStatus == OperationStatus.failed && (
              <>
                <Col xs={24} sm={20} md={24} lg={24} xl={24}>
                  <Alert
                    message="Unable to refer to that email address."
                    type="error"
                    showIcon
                    closable
                    onClose={handleAlertClose}
                  />
                </Col>
                <br />
              </>
            )}
            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              onClick={() => history.push(PATHS.Home.path)}
              loading={incentiveState.submitStatus == OperationStatus.pending}
            >
              {Constants.BTN_CLOSE}
            </Button>
            <Button
              className="nextSenderBtn"
              size="large"
              type="primary"
              htmlType="submit"
              loading={incentiveState.submitStatus == OperationStatus.pending}
            >
              Send invitation
            </Button>
          </Col>
        </Row>
        <br />
        <Row justify="center" align="middle">
          <Modal
            title=""
            visible={modalVisible}
            onCancel={handleCloseModal}
            className="modeAgree"
            footer={[
              <Button key="agree" type="default" onClick={handleCloseModal} className="textColorPrimary">
                Close
              </Button>,
            ]}
          >
            <div className="scrollable-modal-content-TOC">
              <TermsAndConditionRewards />
            </div>
          </Modal>
          <Col>
            <a onClick={handleViewTermsClick}>Terms and conditions of our perks</a>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
