import { Card, Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
import {
  KFSStoreN1,
  KFSStoreN2,
  KFSStoreN3,
  KFSStoreSE1,
  KFSStoreSE2,
  KFSStoreSE3,
  KFSStoreW1,
  KFSStoreW2,
  KFSStoreW3,
  KFSStoreW4,
  KFSStoreW5,
  KFSStoreW6,
  KFSStoreSE5,
  KFSStoreSE6,
  KFSStoreSE4,
  KFS_Store_Syd1,
  KFS_Store_Syd2,
  KFS_Store_Syd3,
  KFS_Store_Syd4,
  KFS_Store_Syd5,
  KFS_Store_Syd6,
  KFS_Store_Syd7,
  KFS_Store_Syd8,
  KFS_Store_Syd9,
  KFS_Store_Syd10,
} from 'common/images';
import { useState } from 'react';
export const KFSStorePartner = () => {
  const [selectedCity, setSelectedCity] = useState('Melbourne');
  const handleCitySelect = (city) => {
    setSelectedCity(city); // Update selected city state
  };
  return (
    <>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="webPageHeaderTextAboutUs">
          <p className="headertextFAQs">KFS store partners</p>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col sm={24} className="">
          <div className="">
            <a
              className={`normal-font dInlineBlock ${selectedCity === 'Melbourne' ? 'underline' : ''}`}
              onClick={() => handleCitySelect('Melbourne')}
            >
              <h3 className="normalfontweight melsydlbl"> Melbourne </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a
              className={`normal-font dInlineBlock ${selectedCity === 'Sydney' ? 'underline' : ''}`}
              onClick={() => handleCitySelect('Sydney')}
            >
              <h3 className="normalfontweight melsydlbl"> Sydney</h3>
            </a>
          </div>
        </Col>
      </Row>
      <a className="textColor">Grab your KFS balikbayan boxes from our store partners near you.</a>
      {selectedCity === 'Melbourne' && (
        <>
          <h3>North</h3>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreN3} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Inang’s Asian Mart</h3>
                  <p className="textColor mtb">Shop 8 342/338 McDonalds Rd, South Morang VIC 3752</p>
                  <p className="textColor mtp-0">+61 3 9424 6726</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreN2} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor  mtb-0 mtp-0">Pinoy Tayo Asian Grocery</h3>
                  <p className="textColor mtb">
                    Craigieburn Central, Shop c-0012, Woolworths Quadrant, 340 Craigieburn Rd, Craigieburn VIC 3064
                  </p>
                  <p className="textColor mtp-0">+61 413 996 887</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreN1} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor  mtb-0 mtp-0">Tindahan Filipino Asian Grocery</h3>
                  <p className="textColor mtb">Shop 10/22 McKimmies Rd, Lalor VIC 3075</p>
                  <p className="textColor mtp-0">+61 3 9464 7474</p>
                </div>
              </Card>
            </Col>
          </Row>
          <h3>West</h3>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreW1} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Super Pinoy Groceries</h3>
                  <p className="textColor mtb">5 Market Pl, Braybrook VIC 3019</p>
                  <p className="textColor mtp-0">+61 3 9311 6147</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreW2} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor  mtb-0 mtp-0">Ma &quot;Swerte&quot; Filipino, Asian & Money Transfer</h3>
                  <p className="textColor mtb">475 Ballarat Rd, Sunshine VIC 3020</p>
                  <p className="textColor mtp-0">+61 3 9939 4210</p>
                </div>
              </Card>
            </Col>

            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreW4} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor  mtb-0 mtp-0">Amelia&apos;s Variety Store</h3>
                  <p className="textColor mtb"> 9/161-165 Railway Ave, Laverton VIC 3028</p>
                  <p className="textColor mtp-0">+61 3 9369 2393</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreW5} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Happy Hut</h3>
                  <p className="textColor mtb">85-87 Oakwood Rd, Albanvale VIC 3023</p>
                  <p className="textColor mtp-0">+61 3 8390 4212</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreW6} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Asian Pantry</h3>
                  <p className="textColor mtb">13/119 Hopkins St, Footscray VIC 3011</p>
                  <p className="textColor mtp-0">+61 400 962 441</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreW3} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor  mtb-0 mtp-0">Super Pinoy Filipino Groceries</h3>
                  <p className="textColor mtb">Shop 5/20-22 E Esplanade, St Albans VIC 3021</p>
                  <p className="textColor mtp-0">+61 3 9366 1243</p>
                </div>
              </Card>
            </Col>
          </Row>
          <h3>South East</h3>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreSE1} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Sari Sari Asian Groceries</h3>
                  <p className="textColor mtb">26A Webb St, Narre Warren VIC 3805</p>
                  <p className="textColor mtp-0">+61 3 9796 0880</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreSE2} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Sari Sari Asian Groceries Pakenham</h3>
                  <p className="textColor mtb">10/19 - 27 Lakeside Blvd, Pakenham VIC 3810</p>
                  <p className="textColor mtp-0">+61 3 5940 3862</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreSE3} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Filipino & Asian Groceries</h3>
                  <p className="textColor mtb">56 Spring Square, Hallam VIC 3803</p>
                  <p className="textColor mtp-0">+61 433 729 089</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreSE4} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">
                    Philside Pilipino Groceries & Convenience Store (Cravings Filo & AsianMart)
                  </h3>
                  <p className="textColor mtb">645-649 Warrigal Rd, Chadstone VIC 3148</p>
                  <p className="textColor mtp-0">+61 468626522</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreSE5} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Mrs Wan&apos;s Asian Mart</h3>
                  <p className="textColor mtb">Shop, 3/47 High St, Cranbourne VIC 3977</p>
                  <p className="textColor mtp-0">+61 3 7023 6388</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFSStoreSE6} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">One Playzone</h3>
                  <p className="textColor mtb">Unit 14 & 21/10 Assembly Dr, Dandenong South VIC 3175</p>
                  <p className="textColor mtp-0">+61 3 9795 5512</p>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {selectedCity === 'Sydney' && (
        <>
          <br />
          <br />
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd1} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Manila Sari-Sari Store</h3>
                  <p className="textColor mtb">2/427-441 Victoria Ave, Chatswood NSW 2067</p>
                  <p className="textColor mtp-0">+61 2 9411 1855</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd2} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Suki Kart & Linalyn&apos;s</h3>
                  <p className="textColor mtb">10 Rooty Hill Rd S, Rooty Hill NSW 2766</p>
                  <p className="textColor mtp-0">+61 2 8632 0667</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd3} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Suki Kart</h3>
                  <p className="textColor mtb">Shop 3, Ground Floor/25-31 Florence St, Hornsby NSW 2077</p>
                  <p className="textColor mtp-0">+61 2 8924 1165</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd4} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Filo Pride Asian Grocery, Eatery and Catering</h3>
                  <p className="textColor mtb">211 Marrickville Rd, Marrickville NSW 2204</p>
                  <p className="textColor mtp-0"> +61 451 234 689</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd5} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Pinoy To Atbp Asian Goods and Services</h3>
                  <p className="textColor mtb">109 Oxford St, Bondi Junction NSW 2022</p>
                  <p className="textColor mtp-0">+61 2 9389 7484</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd6} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Manila Mart </h3>
                  <p className="textColor mtb">23 Ryedale Rd, West Ryde NSW 2114</p>
                  <p className="textColor mtp-0">+61 2 9809 1997</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd7} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Pasalubong Oriental Foods </h3>
                  <p className="textColor mtb">1073 Botany Rd, Mascot NSW 2020</p>
                  <p className="textColor mtp-0">+61 2 8338 8438</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd8} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0">Tatak Pinoy Land Down Under </h3>
                  <p className="textColor mtb">Shop 2/34 Main St, Blacktown NSW 2148</p>
                  <p className="textColor mtp-0">+61 479 077 914</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd9} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0"> Super Pinoy Sari Sari Store </h3>
                  <p className="textColor mtb">228 Queen St, St Marys NSW 2760</p>
                  <p className="textColor mtp-0">+61 411 785 448</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className="cardKFSPartner">
                <img alt="example" src={KFS_Store_Syd10} className="kfs-partner-store" />
                <div className="pl-10 divTxtAsianStore">
                  <h3 className="textColor mtb-0 mtp-0"> Teresa&apos;s Siopao and Catering </h3>
                  <p className="textColor mtb">4 Mount St, Mount Druitt NSW 2770</p>
                  <p className="textColor mtp-0">+61 416 960 352</p>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
