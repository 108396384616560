import { Constants } from 'common/constants';
import { PATHS } from '@routing/routes';
import SenderAddressCommon from '@features/Common/component/SenderAddressCommon';
import { SenderDetailsSelector } from '@features/SenderDetails';
import { useSelector } from 'react-redux';
import { AccountSelector } from '@features/Account';
import { commonSelector } from '@features/Common/store/commonSelector';

export const OrderBoxAddress = () => {
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const accountState = useSelector(AccountSelector);
  const commonState = useSelector(commonSelector);
  return (
    <div className="App">
      <SenderAddressCommon
        header={senderDetailsState.SelectedId == undefined ? 'Delivery address' : 'Delivery address'}
        onBack={PATHS.OrderEmtpyBox.path}
        onNext={PATHS.SenderListingOrderBox.path}
        buttonNames={{
          prevButton: Constants.BTN_BACK,
          nextButton: senderDetailsState.SelectedId == undefined ? Constants.BTN_SAVE : Constants.BTN_UPDATE,
        }}
        senderModel={senderDetailsState.Sender}
        role={accountState.RoleName}
        userId={commonState.GlobalSenderUserId}
      />
    </div>
  );
};
