import { Button, Col, Form, Input, InputNumber, Row, Select, Spin, message } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Constants, FromPageStatus, Roles } from 'common/constants';
import { BookingTypeCodeList, PaymentTypeCodeList } from 'common/code/BookingCode';
import TextArea from 'antd/es/input/TextArea';
import { InvoiceModel } from 'common/models/BookingTypes';
import { InvoiceSelector } from '@features/Invoice/index';
import { useEffect, useState } from 'react';
import { GetInvoiceByRefId, SaveBookingInvoice, TransactionActions, TransactionSelector } from '@features/Transaction';
import { OperationStatus } from 'store/rootTypes';
import { AccountSelector } from '@features/Account';
import { getAmountByTypeAndDestination } from 'common/code/ChargesCode';
import { DestinationCodeCodeList } from 'common/code/DestinationCode';

export const Invoice = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const invoiceState = useSelector(InvoiceSelector);
  const accountState = useSelector(AccountSelector);
  const transactionState = useSelector(TransactionSelector);
  const [destination, setDestination] = useState(transactionState?.Invoice?.Destination);
  const [bookingType, setBookingType] = useState(transactionState?.Invoice?.Type);
  useEffect(() => {
    if (accountState.RoleName == Roles.Customer) {
      history.push('/home');
    }
  }, []);
  const onFinish = async (values: InvoiceModel) => {
    // Access all form values from the 'values' parameter
    const {
      Type,
      Destination,
      UnitCount,
      Weight,
      BasicCharge,
      ServiceCharge,
      KmCharge,
      OtherCharges,
      InsuranceCharge,
      Deposit,
      AmountPaid,
      Discount,
      TotalAmount,
      Balance,
      Change,
      PaymentMode,
      Notes,
      Bonus,
    } = values;

    // Prepare the data to be sent to your API
    const requestData: InvoiceModel = {
      Type,
      Destination,
      UnitCount,
      Weight,
      BasicCharge,
      ServiceCharge,
      KmCharge,
      OtherCharges,
      InsuranceCharge,
      Deposit,
      AmountPaid,
      Discount,
      TotalAmount,
      Balance,
      Change,
      PaymentMode,
      Notes,
      Id: transactionState?.Invoice?.Id,
      TransactionsId: transactionState.SelectedTransId,
      Bonus,
    };

    await dispatch(SaveBookingInvoice(requestData));

    dispatch(TransactionActions.SetFromStatus(FromPageStatus.Invoice));
    if (
      transactionState.FromUpdateLink &&
      Number(requestData.UnitCount) == transactionState.TransactionSummary?.Barcode.length
    ) {
      message.success('Invoice updated successfully');
      history.push('/transaction/summary');
    } else {
      history.push('/Booking/Barcode');
    }
  };

  const onBack = () => {
    history.push('/transaction/summary');
  };
  const [loading, setLoading] = useState(true);

  const handleMouseLeave = (field, value) => {
    if (
      field === 'UnitCount' ||
      field === 'BasicCharge' ||
      field === 'Weight' ||
      field === 'ServiceCharge' ||
      field === 'KmCharge' ||
      field === 'OtherCharges' ||
      field === 'InsuranceCharge' ||
      field === 'Discount' ||
      field === 'AmountPaid' ||
      field === 'Deposit'
    ) {
      const type = form.getFieldValue('Type');
      const unitCountValue = parseFloat(form.getFieldValue('UnitCount')) || 0;
      const basicChargeValue = parseFloat(form.getFieldValue('BasicCharge')) || 0;
      const serviceChargeValue = parseFloat(form.getFieldValue('ServiceCharge')) || 0;
      const kmChargeValue = parseFloat(form.getFieldValue('KmCharge')) || 0;
      const otherChargesValue = parseFloat(form.getFieldValue('OtherCharges')) || 0;
      const insuranceChargeValue = parseFloat(form.getFieldValue('InsuranceCharge')) || 0;
      const discountValue = parseFloat(form.getFieldValue('Discount')) || 0;
      const amountPaidValue = parseFloat(form.getFieldValue('AmountPaid')) || 0;
      const depositValue = parseFloat(form.getFieldValue('Deposit')) || 0;
      const weightValue = parseFloat(form.getFieldValue('Weight')) || 0;
      const bonusValue = parseFloat(form.getFieldValue('Bonus')) || 0;
      let totalAmount = 0;
      // Calculate TotalAmount based on the provided formula

      if (type === 'S') {
        totalAmount = unitCountValue * basicChargeValue + otherChargesValue + insuranceChargeValue;
      } else {
        // If type is 'A', exclude serviceChargeValue and kmChargeValue
        totalAmount =
          weightValue * basicChargeValue +
          serviceChargeValue +
          kmChargeValue +
          otherChargesValue +
          insuranceChargeValue;
      }
      // Calculate Balance based on the provided formula
      const balance = Math.max(totalAmount - (depositValue + discountValue + amountPaidValue + bonusValue), 0);

      // Calculate Change based on the provided formula
      const change = Math.max(depositValue + discountValue + amountPaidValue + bonusValue - totalAmount, 0);

      form.setFieldsValue({
        TotalAmount: totalAmount.toFixed(2),
        Balance: balance.toFixed(2),
        Change: change.toFixed(2),
      });
    }
  };

  const [form] = Form.useForm();

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    const fetchData = async () => {
      if (accountState.RoleName == Roles.Customer) {
        history.push('/home');
      }
      try {
        if (transactionState.SelectedTransId) {
          await dispatch(GetInvoiceByRefId(transactionState.SelectedTransId));
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, transactionState.SelectedTransId]);

  const [selectedType, setSelectedType] = useState(transactionState?.Invoice?.Type || ''); // Initialize with the initial value

  const handleTypeChange = (value) => {
    // You can perform additional actions here based on the selectedType
    // console.log('Selected Type:', value);
    if (transactionState.TransactionSummary) {
      const amount = getAmountByTypeAndDestination(value, destination);
      // console.log(amount);

      form.setFieldsValue({
        BasicCharge: amount,
      });
      handleMouseLeave('BasicCharge', form.getFieldValue('BasicCharge'));
    }

    setSelectedType(value);
  };

  const handleTypeChangeDestination = (value) => {
    setDestination(value);
    if (transactionState.TransactionSummary) {
      const amount = getAmountByTypeAndDestination(selectedType, value);
      // console.log(amount);

      form.setFieldsValue({
        BasicCharge: amount,
      });
      handleMouseLeave('BasicCharge', form.getFieldValue('BasicCharge'));
    }
  };
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Invoice details</h1>
      </Row>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Form form={form} name="senderForm" onFinish={onFinish} layout="vertical">
          <Row justify="center" align="middle">
            <Col xs={24} sm={20} md={12} lg={8} xl={8}>
              <br />
              <Form.Item
                initialValue={transactionState?.Invoice?.Destination}
                label="Destination"
                name="Destination"
                rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder="Select"
                  onChange={handleTypeChangeDestination}
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {DestinationCodeCodeList.map((destination) => (
                    <Select.Option key={destination.code} value={destination.code}>
                      {destination.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                initialValue={transactionState?.Invoice?.Type}
                label="Type"
                name="Type"
                rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <Select
                  size="large"
                  onChange={handleTypeChange}
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                >
                  {BookingTypeCodeList.map((destination) => (
                    <Select.Option key={destination.code} value={destination.code}>
                      {destination.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Unit count"
                name="UnitCount"
                rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                initialValue={transactionState?.Invoice?.UnitCount}
              >
                <Input
                  size="large"
                  maxLength={150}
                  onBlur={() => handleMouseLeave('UnitCount', form.getFieldValue('UnitCount'))}
                />
              </Form.Item>
              {selectedType === 'A' && (
                <Form.Item
                  label="Weight"
                  name="Weight"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                  initialValue={transactionState?.Invoice?.Weight}
                >
                  <InputNumber<string>
                    min="0"
                    max="1000000"
                    step={0.01}
                    stringMode
                    size="large"
                    precision={2}
                    className="full-width"
                    placeholder="0.00"
                    onBlur={() => handleMouseLeave('Weight', form.getFieldValue('Weight'))}
                  />
                </Form.Item>
              )}
              <Form.Item
                label="Basic charge"
                name="BasicCharge"
                rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                initialValue={transactionState?.Invoice?.BasicCharge}
              >
                <InputNumber<string>
                  min="0"
                  max="1000000"
                  step={0.01}
                  stringMode
                  size="large"
                  precision={2}
                  className="full-width"
                  placeholder="0.00"
                  onBlur={() => handleMouseLeave('BasicCharge', form.getFieldValue('BasicCharge'))}
                />
              </Form.Item>
              {selectedType === 'A' && (
                <>
                  <Form.Item
                    initialValue={transactionState?.Invoice?.ServiceCharge}
                    label="Handling charge"
                    name="ServiceCharge"
                    // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                  >
                    <InputNumber<string>
                      min="0"
                      max="1000000"
                      step={0.01}
                      stringMode
                      size="large"
                      precision={2}
                      className="full-width"
                      placeholder="0.00"
                      onBlur={() => handleMouseLeave('ServiceCharge', form.getFieldValue('ServiceCharge'))}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={transactionState?.Invoice?.KmCharge}
                    label="Area charge"
                    name="KmCharge"
                    // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                  >
                    <InputNumber<string>
                      min="0"
                      max="1000000"
                      step={0.01}
                      stringMode
                      size="large"
                      precision={2}
                      className="full-width"
                      placeholder="0.00"
                      onBlur={() => handleMouseLeave('KmCharge', form.getFieldValue('KmCharge'))}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                initialValue={transactionState?.Invoice?.OtherCharges}
                label="Other charges"
                name="OtherCharges"
                // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <InputNumber<string>
                  min="0"
                  max="1000000"
                  step={0.01}
                  stringMode
                  size="large"
                  precision={2}
                  className="full-width"
                  placeholder="0.00"
                  onBlur={() => handleMouseLeave('OtherCharges', form.getFieldValue('OtherCharges'))}
                />
              </Form.Item>

              <Form.Item
                initialValue={transactionState?.Invoice?.InsuranceCharge}
                label="Insurance charge"
                name="InsuranceCharge"
                // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <InputNumber<string>
                  min="0"
                  max="1000000"
                  step={0.01}
                  stringMode
                  size="large"
                  precision={2}
                  className="full-width"
                  placeholder="0.00"
                  onBlur={() => handleMouseLeave('InsuranceCharge', form.getFieldValue('InsuranceCharge'))}
                />
              </Form.Item>
              <Form.Item
                initialValue={transactionState?.Invoice?.TotalAmount}
                label="Total amount"
                name="TotalAmount"
                // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <InputNumber<string>
                  min="0"
                  max="1000000"
                  step={0.01}
                  stringMode
                  size="large"
                  precision={2}
                  className="full-width"
                  placeholder="0.00"
                  disabled
                />
              </Form.Item>
              <Form.Item
                initialValue={transactionState?.Invoice?.Deposit}
                label="Deposit"
                name="Deposit"
                // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <InputNumber<string>
                  min="0"
                  max="1000000"
                  step={0.01}
                  stringMode
                  size="large"
                  precision={2}
                  className="full-width"
                  placeholder="0.00"
                  onBlur={() => handleMouseLeave('Deposit', form.getFieldValue('Deposit'))}
                />
              </Form.Item>
              {transactionState?.Invoice?.Bonus !== null && transactionState?.Invoice?.Bonus !== undefined && (
                <Form.Item initialValue={transactionState?.Invoice?.Bonus} label="Bonus" name="Bonus">
                  <InputNumber<string>
                    min="0"
                    max="1000000"
                    step={0.01}
                    stringMode
                    size="large"
                    precision={2}
                    className="full-width"
                    placeholder="0.00"
                    disabled
                  />
                </Form.Item>
              )}
              <Form.Item
                initialValue={transactionState?.Invoice?.Discount}
                label="Discount"
                name="Discount"
                //  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <InputNumber<string>
                  min="0"
                  max="1000000"
                  step={0.01}
                  stringMode
                  size="large"
                  precision={2}
                  className="full-width"
                  placeholder="0.00"
                  onBlur={() => handleMouseLeave('Discount', form.getFieldValue('Discount'))}
                />
              </Form.Item>
              <Form.Item
                initialValue={transactionState?.Invoice?.AmountPaid}
                label="Amount paid"
                name="AmountPaid"
                // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <InputNumber<string>
                  min="0"
                  max="1000000"
                  step={0.01}
                  stringMode
                  size="large"
                  precision={2}
                  className="full-width"
                  placeholder="0.00"
                  onBlur={() => handleMouseLeave('AmountPaid', form.getFieldValue('AmountPaid'))}
                />
              </Form.Item>

              <Form.Item label="Amount due" initialValue={transactionState?.Invoice?.Balance} name="Balance">
                <InputNumber<string>
                  min="0"
                  max="1000000"
                  step={0.01}
                  stringMode
                  size="large"
                  precision={2}
                  className="full-width"
                  placeholder="0.00"
                  disabled
                />
              </Form.Item>
              <Form.Item label="Change" name="Change" initialValue={transactionState?.Invoice?.Change}>
                <InputNumber<string>
                  min="0"
                  max="1000000"
                  step={0.01}
                  stringMode
                  size="large"
                  precision={2}
                  className="full-width"
                  placeholder="0.00"
                  disabled
                />
              </Form.Item>

              <Form.Item
                initialValue={transactionState?.Invoice?.PaymentMode}
                label="Mode of payment"
                name="PaymentMode"
                rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                  {PaymentTypeCodeList.map((destination) => (
                    <Select.Option key={destination.code} value={destination.code}>
                      {destination.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Agent’s notes (optional)" name="Notes" initialValue={transactionState?.Invoice?.Notes}>
                <TextArea size="large" maxLength={150} />
              </Form.Item>
              <div>
                <Button
                  className="prevSenderBtn"
                  size="large"
                  type="default"
                  onClick={onBack}
                  loading={transactionState.submitStatus == OperationStatus.pending}
                >
                  {Constants.BTN_BACK}
                </Button>
                <Button
                  className="nextSenderBtn"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={transactionState.submitStatus == OperationStatus.pending}
                >
                  {transactionState.FromUpdateLink ? Constants.BTN_UPDATE : Constants.BTN_CONTINUE}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};
