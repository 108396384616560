import { PATHS } from '@routing/routes';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table } from 'antd';
import { BookingTypeCodeList, ContainerStatusTypeCodeList } from 'common/code/BookingCode';
import { Constants } from 'common/constants';
import { ContainerViewModel } from 'common/models/ContainerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { InterStateSelector } from '../store/InterStateSelector';
import { OperationStatus } from 'store/rootTypes';
import { GetAll, GetById, Save } from '../business/InterStateBusiness';
import { TableLocaleCustom } from '@components/Custom/CustomTable';
import { PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import { InterStateActions } from '../store/InterStateSlice';
import moment from 'moment';
import { formatContainerTimestamp } from 'common/utils/utility';
import { InterStateViewModel } from 'common/models/InterStateTypes';
export const InterStateListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const interState = useSelector(InterStateSelector);
  let data: InterStateViewModel[] = interState.InterStateList || [];
  const onFinish = async () => {
    await dispatch(GetAll());
  };

  const handleReferenceClick = async (id) => {
    dispatch(InterStateActions.SetSelectedInterStateId(id));
    await dispatch(GetById(id));
    dispatch(InterStateActions.SetIsFromListing(false));
    history.push(PATHS.InterStateProfile.path);
  };

  const handleLoadClick = async (id) => {
    dispatch(InterStateActions.SetSelectedInterStateId(id));
    await dispatch(GetById(id));
    dispatch(InterStateActions.SetIsFromListing(true));
    history.push(PATHS.InterStateLoading.path);
  };

  useEffect(() => {
    data = interState.InterStateList || [];
  }, [interState.InterStateList]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(GetAll());
    };

    fetchData();
    dispatch(InterStateActions.SetIsFromListing(false));
    dispatch(InterStateActions.SetSelectedInterStateId(''));
    dispatch(InterStateActions.SetInterStateList([]));
    dispatch(InterStateActions.SetInterStateDetailsList([]));
    dispatch(InterStateActions.SetInterStateBarcodeList([]));
  }, []);

  const columns = [
    {
      title: 'Interstate Id',
      dataIndex: 'TrfId',
      key: 'TrfId',
      render: (text, record) => <a onClick={() => handleReferenceClick(record.Id)}>{text}</a>,
    },
    {
      title: 'Action',
      dataIndex: 'Load',
      key: 'Load',
      render: (text, record) => (
        <span>
          <a onClick={() => handleLoadClick(record.Id)}>Load</a>
          {record.Hastransaction == false && (
            <span>
              {' | '}
              <a onClick={() => showConfirm(record)}>Delete</a>
            </span>
          )}
        </span>
      ),
    },
  ];

  const { confirm } = Modal;
  const showConfirm = (details: InterStateViewModel) => {
    //Shift yes or no to satisfy the req. will refactor next time
    form.resetFields();
    confirm({
      title: 'Do you want to delete this record: ' + details.TrfId + '?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'No',
      cancelText: 'Yes',
      okButtonProps: { className: 'ant-btn-default-okcancelbooking' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      async onCancel() {
        const updatedDetails = { ...details, IsDeleted: true }; // Create a copy with IsDeleted set to true
        await dispatch(Save(updatedDetails));

        await dispatch(GetAll());

        data = interState.InterStateList || [];
      },
      async onOk() {
        form.resetFields();
      },
    });
  };

  const handleCreateClick = async () => {
    await dispatch(InterStateActions.SetInterState(undefined));

    history.push(PATHS.InterStateProfile.path);
  };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Interstate</h1>
      </Row>

      <Form name="containerForm" onFinish={onFinish} form={form} layout="vertical">
        <br />
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="floatRight">
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                size="large"
                loading={interState.submitStatus == OperationStatus.pending}
                onClick={() => handleCreateClick()}
              >
                Create new
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table dataSource={data} columns={columns} locale={TableLocaleCustom} />
          </Col>
        </Row>
        <br />
        <Row gutter={[10, 0]}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              loading={interState.submitStatus == OperationStatus.pending}
              onClick={() => history.push(PATHS.Home.path)}
            >
              {Constants.BTN_CLOSE}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
