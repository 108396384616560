import { useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useSelector } from 'react-redux';
import { ReceiverSelector } from '@features/Receiver';
import { Constants } from 'common/constants';
import { handleFocusContactNumber, handleKeyDownContactNumberNew } from 'common/utils/utility';
import { OperationStatus } from 'store/rootTypes';
import { MobileCountryCodeList } from 'common/code/MobileCountryCode';
import { ReceiverModel } from 'common/models';

interface ReceiverFormProps {
  header?: string;
  onBack?: () => void;
  onFinish?: () => void;
  loadingStatus?: OperationStatus;
  model?: ReceiverModel;
  buttonNames?: {
    prevButton?: string;
    nextButton?: string;
  };
  role?: string;
}
export default function ReceiverCommon({ header, onBack, onFinish, model, buttonNames }: ReceiverFormProps) {
  const receiverState = useSelector(ReceiverSelector);

  const [selectedMobileCode, setSelectedMobileCode] = useState(model?.MobileCode || model?.MobileCode || '+63');
  const [form] = Form.useForm();
  const { Option } = Select;

  const selectStyle = { width: '100px' };
  const inputStyle = { width: 'calc(100% - 100px)' };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>{header}</h1>
      </Row>
      <Form name="receiverForm" onFinish={onFinish} layout="vertical" form={form}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <Form.Item
              initialValue={model?.GivenName}
              label="Given name"
              name="GivenName"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>
            <Form.Item
              initialValue={model?.Surname}
              label="Surname"
              name="Surname"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>
            <Form.Item
              initialValue={model?.Address}
              label="Address"
              name="Address"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>
            <Form.Item
              initialValue={model?.City}
              label="Town"
              name="City"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>
            <Form.Item
              initialValue={model?.Province}
              label="Province"
              name="Province"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>
            <Form.Item
              initialValue={model?.PostalCode}
              label="Postal code"
              name="PostalCode"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={10} />
            </Form.Item>
            <Form.Item
              initialValue={form.getFieldValue('ContactNo') || model?.ContactNo}
              label="Contact number"
              name="ContactNo"
            >
              <Input.Group compact>
                <Form.Item noStyle initialValue="+1">
                  <Select
                    style={selectStyle}
                    value={selectedMobileCode}
                    onChange={(value) => setSelectedMobileCode(value)}
                    size="large"
                    showSearch
                    optionFilterProp="children"
                  >
                    {MobileCountryCodeList.map((country) => (
                      <Option key={country.code} value={country.dial_code}>
                        {`${country.dial_code}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  noStyle
                  name="ContactNo"
                  initialValue=""
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input
                    style={inputStyle}
                    placeholder="Contact number"
                    size="large"
                    onKeyDown={handleKeyDownContactNumberNew}
                    onFocus={handleFocusContactNumber}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <div>
              <Button className="prevSenderBtn" size="large" type="default" onClick={onBack}>
                {buttonNames?.prevButton}
              </Button>
              <Button
                className="nextSenderBtn"
                size="large"
                type="primary"
                htmlType="submit"
                loading={receiverState.submitStatus === OperationStatus.pending}
              >
                {buttonNames?.nextButton}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
