import { GetUUID, IsDevEnv } from 'common/utils/utility';
import { APIResponse, GetData, PostData, get } from 'common/utils/jsonFetch';
import { APIResponseCode, Constants } from 'common/constants';
import { DecodeTokenTypes } from 'common/models/UserTypes';
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import dayjs from 'dayjs';
import { ReferralResponse } from 'common/models';
import { Validator } from 'common/validators/validators';
import { commonAction } from '../store/commonSlice';
import { DeliveryAreaRate } from 'common/models/DeliveryAreaRateTypes';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';

export const HandleErrorMessage = (methodName: string, e: unknown) => {
  const error = e as Error;
  return `${methodName} - ${(error as Error).message}`;
};

export const GetFirstErrorFieldName = (error) => {
  let firstErrorField = '';
  if (error.errorFields?.length > 0 && error.errorFields[0].name?.length > 0) {
    firstErrorField = error.errorFields[0].name[0];
  }
  return firstErrorField;
};

const cookies = new Cookies();

export const GetHeaders = async (requestURL, iskfs = false, hideContentType = false) => {
  const cookies = new Cookies();
  // console.log(cookies.get(Constants.Token));

  if (!IsDevEnv()) {
    await ValidateToken();
  }

  const header = {
    RequestUrl: requestURL,
    Authorization: 'Bearer ' + cookies.get(Constants.Token),
    'app-id': '8D0C93C11DCE44EBAC6AD14ABE603383',
    'request-id': GetUUID(),
  };
  if (!hideContentType) {
    header['Content-Type'] = 'application/json';
  }

  return header;
};

export const ValidateAccess = (allowedPermissions: string, userModuleAccess: string[]) => {
  if (userModuleAccess && userModuleAccess.length > 0) {
    if (allowedPermissions.includes('|')) {
      const data = allowedPermissions.split('|');
      for (let i = 0; i < data.length; i++) {
        if (userModuleAccess.includes(data[i])) {
          return true;
        }
      }
    }
    return userModuleAccess.includes(allowedPermissions);
  } else {
    return false;
  }
};

export const GetExistingToken = () => {
  return cookies.get(Constants.Token);
};

//On api call(in GetHeader)
export const ValidateToken = async () => {
  const token = GetExistingToken();
  if (token) {
    const decoded = jwtDecode<DecodeTokenTypes>(token);
    //if token expired, refresh
    if (dayjs().subtract(1, 'minutes').valueOf() >= decoded.exp * 1000) {
      await RefreshToken();
    }
  } else {
    // LogoutRedirect();
  }
};

export const RefreshToken = async () => {
  try {
    const refreshTokenURL = process.env.REACT_APP_REFRESH_URL ?? '';
    const response = await get<ReferralResponse>(refreshTokenURL);
    if (response.ok) {
      console.log('');
    } else {
      throw Error;
    }
  } catch (e) {
    // LogoutRedirect();
  }
};

export const PostStart = () => async (dispatch) => {
  try {
    const response = await PostData<APIResponse>('/get', null);
    new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    new Validator(response.parsedBody?.Results).NotNull();
  } catch (error) {
    const methodName = 'PostStart';
    console.log('error' + methodName);
  }
};

export const GetProvinceDeliveryArea = () => async (dispatch) => {
  try {
    const response = await GetData<APIResponse>(KFSEndpoints.Common_GetProvinceDeliveryArea);

    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    new Validator(response.parsedBody?.Results).NotNull();
    const apiResponse: string[] = response.parsedBody?.Results;
    dispatch(commonAction.SetDeliveryAreaRate(apiResponse));
  } catch (error) {
    const methodName = 'GetProvinceDeliveryArea';
  }
};

export const GetLocationDeliveryArea = (province: string) => async (dispatch) => {
  try {
    dispatch(commonAction.SetDeliveryAreaRateLocation(undefined));
    const response = await PostData<APIResponse>(KFSEndpoints.Common_GetLocationDeliveryArea, province);

    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    new Validator(response.parsedBody?.Results).NotNull();
    const apiResponse: DeliveryAreaRate[] = response.parsedBody?.Results;
    dispatch(commonAction.SetDeliveryAreaRateLocation(apiResponse));
  } catch (error) {
    const methodName = 'GetLocationDeliveryArea';
  }
};
