import { Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
export const FAQs = () => {
  return (
    <>
      <Row justify="center" align="middle">
        <div className="center-text">
          <h2 className="mtb header-fs2">Frequently asked questions </h2>
        </div>
      </Row>
      <div className="bodyFAQs">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="textColorPrimary mtb-0">1. How much does Kasangga cost per month?</p>
            <ul className="mtp-0">
              <li className="textColor">
                Kasangga offers affordable monthly subscription plans, providing access to its comprehensive features at
                competitive prices suited to businesses of all sizes.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">2. How much does Kasangga cost annually?</p>
            <ul className="mtp-0">
              <li className="textColor">
                Kasangga provides cost-effective annual subscription options, allowing businesses to enjoy additional
                savings by committing to long-term plans.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">3. Is Kasangga subscription-based?</p>
            <ul className="mtp-0">
              <li className="textColor">
                Yes, Kasangga operates on a subscription-based model, offering affordable solutions that provide value
                for money to businesses.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">4. Are there any discounts available?</p>
            <ul className="mtp-0">
              <li className="textColor">
                Yes, Kasangga occasionally offers discounts and promotions on its subscription plans. Keep an eye out
                for special offers to maximize your savings while enjoying the benefits of our platform.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">5. How do I create a Kasangga account?</p>
            <ul className="mtp-0">
              <li className="textColor">
                Creating a Kasangga account is simple and hassle-free. Visit our website, sign up with your details, and
                follow the prompts to set up your account. You can start using Kasangga immediately after registration.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">6. Is Kasangga applicable for all types of businesses?</p>
            <ul className="mtp-0">
              <li className="textColor">
                Yes, Kasangga is designed to be accessible and beneficial for businesses of all sizes and across various
                industries. Our affordable pricing and customizable solutions cater to the diverse needs of businesses.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">7. Can I use Kasangga on any device?</p>
            <ul className="mtp-0">
              <li className="textColor">
                Yes, Kasangga is a cloud-based platform accessible from any device with an internet connection. Whether
                you prefer to use a computer, tablet, or smartphone, you can manage your business operations seamlessly
                with Kasangga.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">
              8. Can I contact Kasangga for custom solutions tailored to my business?
            </p>
            <ul className="mtp-0">
              <li className="textColor">
                Absolutely! Kasangga understands the importance of catering to the unique needs of businesses. We offer
                custom solutions and personalized support at an affordable price, ensuring that you get the most value
                out of our platform. Feel free to reach out to us, and our team will work with you to develop a solution
                that fits your budget and requirements perfectly.
              </li>
            </ul>
          </Col>
        </Row>
        <br />
      </div>
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
