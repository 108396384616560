import { Button, Col, Form, Row, message } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Constants, Roles } from 'common/constants';
import { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styles from 'features/Booking/Booking.module.less';
import { SaveSignature, TransactionSelector } from '@features/Transaction';
import { AccountSelector } from '@features/Account';
export const BookingSignature = () => {
  const history = useHistory();
  const accountState = useSelector(AccountSelector);
  const [form] = Form.useForm();
  const [signature, setSignature] = useState<string | null>(null);
  const [signatureMessage, setSignatureMessage] = useState('Signature updated successfully');
  const signatureRef = useRef<any>(null);
  const transactionState = useSelector(TransactionSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (accountState.RoleName == Roles.Customer) {
      history.push('/home');
    }
  }, []);

  const onFinish = async () => {
    if (signature) {
      console.log('Form submitted with signature:', signature);
    } else {
      console.log('Signature is required.');
    }
  };

  const handleSaveSignature = async () => {
    const isSignatureEmpty = signatureRef.current.isEmpty();
    if (isSignatureEmpty) {
      form.setFields([
        {
          name: 'signature',
          errors: [Constants.Signature_Required],
        },
      ]);
      return;
    }
    form.setFields([
      {
        name: 'signature',
        errors: [''],
      },
    ]);

    if (signatureRef.current) {
      const base64Signature = signatureRef.current.toDataURL();
      setSignature(base64Signature);

      await dispatch(
        SaveSignature({
          Id: transactionState.TransactionSummary?.Signature?.Id,
          TransactionsId: transactionState.SelectedTransId,
          Sender: base64Signature,
        })
      );

      message.success(signatureMessage);
      history.push('/transaction/summary');
    }
  };

  const handleClearSignature = () => {
    signatureRef.current.clear();
    setSignature(null);
  };

  const onBack = () => {
    if (transactionState.FromUpdateLink) {
      history.push('/transaction/summary');
    } else if (transactionState.TransactionSummary?.Barcode.length != Number(transactionState.Invoice?.UnitCount)) {
      history.push('/booking/barcode');
    } else if (!transactionState.TransactionSummary?.Signature) {
      history.push('/transaction/summary');
    }
  };

  useEffect(() => {
    if (transactionState.TransactionSummary?.Barcode.length != Number(transactionState.Invoice?.UnitCount)) {
      history.push('/transaction/summary');
    }
    signatureRef.current.clear();
    if (
      transactionState?.TransactionSummary?.Signature?.Sender !== undefined &&
      transactionState?.TransactionSummary?.Signature?.Sender !== null
    ) {
      signatureRef.current.fromDataURL(transactionState.TransactionSummary.Signature.Sender);
    } else {
      setSignatureMessage('Pick up successfully completed');
    }
  }, []);
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>{Constants.KFSBOX_BookPickupHeader}</h1>
      </Row>

      <Form name="senderForm" form={form} onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={15} lg={8} xl={8}>
            <br />
            <div>
              {/* Signature Canvas */}
              <Form.Item label="Signature" name="signature">
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}
                />
              </Form.Item>
              <Button onClick={handleClearSignature} className={styles.clearSignatureBtn}>
                Clear signature
              </Button>
              <br />
              <br />
              <Button className="prevSenderBtn" size="large" type="default" onClick={onBack}>
                {Constants.BTN_BACK}
              </Button>

              <Button className="nextSenderBtn" size="large" type="primary" onClick={handleSaveSignature}>
                {transactionState.FromUpdateLink ? Constants.BTN_UPDATE : Constants.BTN_CONTINUE}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
