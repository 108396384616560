import { message, notification } from 'antd';
import { APIResponseCode, MessageDisplay } from 'common/constants';
import {
  BarcodeGenerateRequestModel,
  BarcodeResponseModel,
  BarcodeSaveRequestModel,
  BarcodeValidateRequestModel,
} from 'common/models/BarcodeTypes';
import { GetNotificationProps } from 'common/utils/utility';
import { BarcodeActions } from '../store/BarcodeSlice';
import { APIResponse, PostData } from 'common/utils/jsonFetch';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { Validator } from 'common/validators/validators';

export const GenerateBarcodeBusiness = (data: BarcodeGenerateRequestModel) => async (dispatch) => {
  try {
    // console.log(JSON.stringify(data));
    dispatch(BarcodeActions.submitStatus());

    const response = await PostData<APIResponse>(KFSEndpoints.BARCODE_GENERATE, data);

    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    new Validator(response.parsedBody?.Results).NotNull();
    const apiResponse: BarcodeResponseModel[] = response.parsedBody?.Results;
    dispatch(BarcodeActions.submitStatusSucceeded());
    dispatch(BarcodeActions.SetGeneratedBarcode(apiResponse));
  } catch (error) {
    const methodName = 'GenerateBarcodeBusiness';
  }
};

export const PrintBarcode = (data: BarcodeResponseModel[]) => async (dispatch) => {
  try {
    dispatch(BarcodeActions.submitStatus());

    const response = await PostData<APIResponse>(KFSEndpoints.BARCODE_PRINT, data);

    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    new Validator(response.parsedBody?.Results).NotNull();
    dispatch(BarcodeActions.submitStatusSucceeded());
  } catch (error) {
    const methodName = 'PrintBarcode';
  }
};

export const ValidateBarcode = (data: BarcodeValidateRequestModel) => async (dispatch) => {
  try {
    dispatch(BarcodeActions.submitStatus());

    const response = await PostData<APIResponse>(KFSEndpoints.Barcode_Validate, data);

    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    new Validator(response.parsedBody?.Results).NotNull();
    dispatch(BarcodeActions.submitStatusSucceeded());
    return response.parsedBody?.Results;
  } catch (error) {
    // const methodName = 'ValidateBarcode';
    // console.error(`${methodName} error:`, error);
    // throw new Error('An error occurred during barcode validation.'); // Return a meaningful error message
  }
};

export const SaveBarcode = (data: BarcodeSaveRequestModel) => async (dispatch) => {
  try {
    dispatch(BarcodeActions.submitStatus());
    // console.log(JSON.stringify(data));
    const response = await PostData<APIResponse>(KFSEndpoints.Barcode_SaveBarcode, data);

    new Validator(response).NotNull();

    new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(BarcodeActions.submitStatusSucceeded());
    return response.parsedBody?.Results;
  } catch (error) {
    dispatch(BarcodeActions.submitStatusSucceeded());
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
  }
};
