import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Space, Spin, Tag, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import styles from 'features/SenderDetails/Sender.module.less';
import { Constants, ReceiverStatus } from 'common/constants';
import { ReceiverModel } from 'common/models/BookingTypes';
import { useDispatch, useSelector } from 'react-redux';
import { BookingSelector } from '@features/Booking/store/BookingSelector';
import { TransactionSelector, UpdateReceiverTransaction } from '@features/Transaction';
import { GetAllReceiver, GetReceiverById, ReceiverActions, ReceiverSelector } from '@features/Receiver';
import { commonSelector } from '@features/Common/store/commonSelector';
import { PATHS } from '@routing/routes';
import { commonAction } from '@features/Common/store/commonSlice';
const { CheckableTag } = Tag;

export const ReceiverListingBooking = () => {
  const receiverState = useSelector(ReceiverSelector);
  const bookingState = useSelector(BookingSelector);
  const commonState = useSelector(commonSelector);
  const transactionState = useSelector(TransactionSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleChange = (receiverId: string | undefined) => {
    if (receiverId) {
      dispatch(commonAction.SetGlobalReceiverId(receiverId));
    }
  };

  const [data, setData] = useState<ReceiverModel[]>([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        if (
          transactionState.TransactionSummary?.Transactions.Id != '' &&
          transactionState.TransactionSummary?.Transactions.Id != undefined
        ) {
          const result = await GetAllReceiver(commonState.GlobalReceiverUserId);
          if (result && result.length > 0 && (!receiverState.SelectedId || receiverState.SelectedId.trim() === '')) {
            dispatch(ReceiverActions.SetSelectedId(result[0]?.Id));
          }
          setData(result);
        } else {
          const result = await GetAllReceiver(bookingState?.FromSignupExistingUserId);
          if (result && result.length > 0 && (!receiverState.SelectedId || receiverState.SelectedId.trim() === '')) {
            // Check if result is not null or undefined and SelectedId is undefined or empty
            dispatch(ReceiverActions.SetSelectedId(result[0]?.Id));
          }
          setData(result);
        }
      } catch (error) {
        // Handle error as needed
      }
    };
    fetchDataAsync();
  }, []);

  const onFinish = async () => {
    if (receiverState.SelectedId === undefined) return;

    if (commonState.GlobalTransactionId && commonState.GlobalReceiverId) {
      await dispatch(
        UpdateReceiverTransaction({
          Id: commonState.GlobalTransactionId,
          ReceiverId: commonState.GlobalReceiverId,
        })
      );
      // message.success('Receiver updated successfully');
      history.push('/transaction/summary');
    } else {
      history.push('/booking/boxcontent');
    }
  };

  const AddReceiver = () => {
    dispatch(ReceiverActions.ResetReceiver());
    dispatch(ReceiverActions.SetReceiverStatus(ReceiverStatus.New));
  };

  const onUpdate = async (value: any) => {
    if (value === null) return;
    await dispatch(GetReceiverById(value));
    dispatch(ReceiverActions.SetReceiverStatus(ReceiverStatus.Update));
    dispatch(ReceiverActions.SetSelectedId(value));
    history.push('/booking/receiver');
  };

  return (
    <div className="App">
      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row justify="center" align="middle">
          <h1>Receiver details</h1>
        </Row>

        {data ? (
          <Row justify="center" align="middle">
            <Col>
              <Space wrap direction="vertical">
                {data.map((receiver) => (
                  <div key={receiver.Id} className={styles.FlexColumnContainer}>
                    <Row justify="center" align="middle">
                      {receiver.Id != Constants.NoSelectedReceiver && (
                        <Col span={24} className={`${styles.rightText}`}>
                          <a className={styles.link} onClick={() => onUpdate(receiver.Id)}>
                            Update
                          </a>
                        </Col>
                      )}

                      <Col span={24}>
                        <CheckableTag
                          checked={receiver.Id === commonState.GlobalReceiverId}
                          onChange={() => handleChange(receiver?.Id)}
                          className={styles.anttagcheckable}
                        >
                          {receiver.Id != Constants.NoSelectedReceiver ? (
                            <div>
                              <div>
                                {receiver.GivenName} {receiver.Surname}
                              </div>
                              <div>
                                {receiver.Address}, {receiver.City}, {receiver.Province}, {receiver.PostalCode}
                              </div>
                              <div>
                                {receiver.MobileCode} {receiver.ContactNo}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>Select receiver later</div>
                            </div>
                          )}
                        </CheckableTag>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Space>
            </Col>
          </Row>
        ) : (
          <Spin size="large" />
        )}

        <br />
        <Row justify="center" align="middle">
          <Col>
            <Link to="/booking/receiver" className="normal-font" onClick={AddReceiver}>
              Add new receiver
            </Link>
          </Col>
        </Row>
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={8} md={14} lg={10} xl={7} xxl={5}>
            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              onClick={() =>
                history.push(!commonState.GlobalTransactionId ? PATHS.Home.path : PATHS.TransactionSummary.path)
              }
            >
              {!commonState.GlobalTransactionId ? Constants.BTN_CLOSE : Constants.BTN_BACK}
            </Button>
            <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
              {!commonState.GlobalTransactionId ? Constants.BTN_CONTINUE : Constants.BTN_UPDATE}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
