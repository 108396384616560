import { PATHS } from '@routing/routes';
import { Button, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import { BookingTypeCodeList, ContainerStatusTypeCodeList } from 'common/code/BookingCode';
import { Constants } from 'common/constants';
import { ContainerViewModel } from 'common/models/ContainerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ContainerSelector } from '../store/ContainerSelector';
import { OperationStatus } from 'store/rootTypes';
import { GetAllContainer, GetContainerById } from '../business/ContainerBusiness';
import { TableLocaleCustom } from '@components/Custom/CustomTable';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { ContainerActions } from '../store/ContainerSlice';
import moment from 'moment';
import { formatContainerTimestamp } from 'common/utils/utility';
export const ContainerListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const containerState = useSelector(ContainerSelector);
  let data: ContainerViewModel[] = containerState.ContainerList || [];
  const onFinish = async () => {
    await dispatch(GetAllContainer());
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(GetAllContainer());
    };

    fetchData();
    dispatch(ContainerActions.SetUploadBarcodeResponse());
  }, []);
  const handleReferenceClick = async (id) => {
    dispatch(ContainerActions.SetSelectedContainerId(id));
    await dispatch(GetContainerById(id));
    dispatch(ContainerActions.SetIsFromListing(false));
    history.push(PATHS.ContainerProfile.path);
  };

  const handleLoadClick = async (id) => {
    dispatch(ContainerActions.SetSelectedContainerId(id));
    dispatch(ContainerActions.SetIsFromListing(true));
    await dispatch(GetContainerById(id));
    history.push(PATHS.ContainerLoading.path);
  };

  const handleCreateClick = async () => {
    dispatch(ContainerActions.SetSelectedContainerId(''));
    dispatch(ContainerActions.SetContainerDetailsList([]));
    await dispatch(ContainerActions.SetContainer(undefined));

    history.push(PATHS.ContainerProfile.path);
  };

  useEffect(() => {
    data = containerState.ContainerList || [];
  }, [containerState.ContainerList]);

  const columns = [
    {
      title: 'Container no',
      dataIndex: 'ContainerNo',
      key: 'ContainerNo',
      render: (text, record) => <a onClick={() => handleReferenceClick(record.Id)}>{text}</a>,
    },
    {
      title: 'Details',
      dataIndex: 'Details',
      key: 'Details',
      render: (text, record) => {
        return `${record.From} to ${record.To}`;
      },
    },
    {
      title: 'ETD',
      dataIndex: 'ETD',
      key: 'ETD',
      render: (text) => formatContainerTimestamp(text),
    },
    {
      title: 'ATD',
      dataIndex: 'ATD',
      key: 'ATD',
      render: (text) => formatContainerTimestamp(text),
    },
    {
      title: 'ETA',
      dataIndex: 'ETA',
      key: 'ETA',
      render: (text) => formatContainerTimestamp(text),
    },
    {
      title: 'ATA',
      dataIndex: 'ATA',
      key: 'ATA',
      render: (text) => formatContainerTimestamp(text),
    },
    {
      title: 'Remarks',
      dataIndex: 'Remarks',
      key: 'Remarks',
    },
    {
      title: 'Action',
      dataIndex: 'Load',
      key: 'Load',
      render: (text, record) => <a onClick={() => handleLoadClick(record.Id)}>Load to container</a>,
    },
  ];
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Container</h1>
      </Row>

      <Form name="containerForm" onFinish={onFinish} form={form} layout="vertical">
        {/* <Row justify="center" align="middle" gutter={[16, 0]}>
          <Col xs={24} sm={20} md={10} lg={8} xl={4}>
            <Form.Item
              label="Container no"
              name="ContainerNo"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={25} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={20} md={10} lg={8} xl={4}>
            <Form.Item label="Status" name="Status" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                {ContainerStatusTypeCodeList.map((destination) => (
                  <Select.Option key={destination.code} value={destination.code}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={20} lg={16} xl={8}>
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                loading={containerState.submitStatus == OperationStatus.pending}
                onClick={() => history.push(PATHS.Home.path)}
              >
                {Constants.BTN_CLOSE}
              </Button>
              <Button
                className="nextSenderBtn"
                size="large"
                type="primary"
                htmlType="submit"
                loading={containerState.submitStatus == OperationStatus.pending}
              >
                {Constants.BTN_SEARCH}
              </Button>
            </div>
          </Col>
        </Row> */}
        <br />
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="floatRight">
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                size="large"
                loading={containerState.submitStatus == OperationStatus.pending}
                onClick={() => handleCreateClick()}
              >
                Create new container
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table dataSource={data} columns={columns} locale={TableLocaleCustom} />
          </Col>
        </Row>
        <Row gutter={[10, 0]}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              loading={containerState.submitStatus == OperationStatus.pending}
              onClick={() => history.push(PATHS.Home.path)}
            >
              {Constants.BTN_CLOSE}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
