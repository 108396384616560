import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Space, Spin, Tag } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import styles from 'features/SenderDetails/Sender.module.less';
import { BookingStatus, Constants, ReceiverStatus } from 'common/constants';
import { ReceiverModel } from 'common/models/BookingTypes';
import { useDispatch, useSelector } from 'react-redux';
import { BookingSelector } from '@features/Booking/store/BookingSelector';
import { GetAllReceiver, GetReceiverById, UpdateReceiver } from '../business/ReceiverBusiness';
import { ReceiverActions } from '../store/ReceiverSlice';
import { ReceiverSelector } from '../store/ReceiverSelector';
import { TransactionSelector, UpdateReceiverTransaction } from '@features/Transaction';
const { CheckableTag } = Tag;

export const ReceiverListing = () => {
  const receiverState = useSelector(ReceiverSelector);
  const bookingState = useSelector(BookingSelector);
  const transactionState = useSelector(TransactionSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleChange = (userId: string | undefined) => {
    dispatch(ReceiverActions.SetSelectedId(userId));
  };

  const [data, setData] = useState<ReceiverModel[]>([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        if (
          transactionState.TransactionSummary?.Transactions.Id != '' &&
          transactionState.TransactionSummary?.Transactions.Id != undefined
        ) {
          const result = await GetAllReceiver(transactionState.TransactionSummary?.Sender.UserId);
          if (result && result.length > 0 && (!receiverState.SelectedId || receiverState.SelectedId.trim() === '')) {
            // Check if result is not null or undefined and SelectedId is undefined or empty
            dispatch(ReceiverActions.SetSelectedId(result[0]?.Id));
          }
          setData(result);
        } else {
          const result = await GetAllReceiver(bookingState?.FromSignupExistingUserId);
          if (result && result.length > 0 && (!receiverState.SelectedId || receiverState.SelectedId.trim() === '')) {
            // Check if result is not null or undefined and SelectedId is undefined or empty
            dispatch(ReceiverActions.SetSelectedId(result[0]?.Id));
          }
          setData(result);
        }
      } catch (error) {
        // Handle error as needed
      }
    };
    fetchDataAsync();
  }, []);

  const onFinish = async () => {
    if (receiverState.SelectedId === undefined) return;
    await dispatch(GetReceiverById(receiverState.SelectedId));

    if (receiverState.IsReceiverNoSelected) {
      await dispatch(
        UpdateReceiverTransaction({
          Id: transactionState.TransactionSummary?.Transactions.Id,
          ReceiverId: receiverState.SelectedId,
        })
      );
      history.push('/transaction/summary');
    } else {
      history.push('/booking/boxcontent');
    }
  };

  const AddReceiver = () => {
    dispatch(ReceiverActions.ResetReceiver());
    dispatch(ReceiverActions.SetReceiverStatus(ReceiverStatus.New));
  };

  const onUpdate = async (value: any) => {
    if (value === null) return;
    await dispatch(GetReceiverById(value));
    dispatch(ReceiverActions.SetReceiverStatus(ReceiverStatus.Update));
    dispatch(ReceiverActions.SetSelectedId(value));
    history.push('/booking/receiver');
  };

  return (
    <div className="App">
      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row justify="center" align="middle">
          <h1>Receiver details</h1>
        </Row>

        {data ? (
          <Row justify="center" align="middle">
            <Col>
              <Space wrap direction="vertical">
                {data.map((receiver) => (
                  <div key={receiver.Id} className={styles.FlexColumnContainer}>
                    <Row justify="center" align="middle">
                      {receiver.Id != Constants.NoSelectedReceiver && (
                        <Col span={24} className={`${styles.rightText}`}>
                          <a className={styles.link} onClick={() => onUpdate(receiver.Id)}>
                            Update
                          </a>
                        </Col>
                      )}

                      <Col span={24}>
                        <CheckableTag
                          checked={receiver.Id === receiverState.SelectedId}
                          onChange={() => handleChange(receiver?.Id)}
                          className={styles.anttagcheckable}
                        >
                          {receiver.Id != Constants.NoSelectedReceiver ? (
                            <div>
                              <div>
                                {receiver.GivenName} {receiver.Surname}
                              </div>
                              <div>
                                {receiver.Address}, {receiver.City}, {receiver.Province}, {receiver.PostalCode}
                              </div>
                              <div>
                                {receiver.MobileCode} {receiver.ContactNo}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>Select receiver later</div>
                            </div>
                          )}
                        </CheckableTag>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Space>
            </Col>
          </Row>
        ) : (
          <Spin size="large" />
        )}

        <br />
        <Row justify="center" align="middle">
          <Col>
            <Link to="/booking/receiver" className="normal-font" onClick={AddReceiver}>
              Add new receiver
            </Link>
          </Col>
        </Row>
        <br />
        {receiverState.IsReceiverNoSelected === false ? (
          bookingState.IsSendABox ? (
            <Row justify="center" align="middle">
              <Col xs={24} sm={8} md={14} lg={10} xl={7} xxl={5}>
                <Button
                  className="prevSenderBtn"
                  size="large"
                  type="default"
                  onClick={() => history.push('/sender/listing')}
                >
                  {Constants.BTN_BACK}
                </Button>
                <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                  {Constants.BTN_CONTINUE}
                </Button>
              </Col>
            </Row>
          ) : (
            <Row justify="center" align="middle">
              <Col xs={24} sm={8} md={14} lg={10} xl={7} xxl={5}>
                <Button
                  className="prevSenderBtn full-width"
                  size="large"
                  type="default"
                  onClick={() => history.push('/home')}
                >
                  {Constants.BTN_CLOSE}
                </Button>
              </Col>
            </Row>
          )
        ) : (
          <Row justify="center" align="middle">
            <Col xs={24} sm={8} md={14} lg={10} xl={7} xxl={5}>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push('/transaction/summary')}
              >
                {Constants.BTN_BACK}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                {Constants.BTN_SELECTRECEIVER}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};
