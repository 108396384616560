export interface LocationCode {
  postcode: number;
  place_name: string;
  state_name: string;
  state_code: string;
  latitude: number;
  longitude: number;
  accuracy: number | null;
}

// Define an array interface
export const AULocationCodeList: LocationCode[] = [
  {
    postcode: 200,
    place_name: 'Australian National University',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2777,
    longitude: 149.1189,
    accuracy: 1,
  },
  {
    postcode: 221,
    place_name: 'Barton',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3049,
    longitude: 149.1412,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Wreck Bay',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1627,
    longitude: 150.6907,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Hmas Creswell',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.028,
    longitude: 150.5501,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Jervis Bay',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1333,
    longitude: 150.7,
    accuracy: 4,
  },
  {
    postcode: 2600,
    place_name: 'Deakin West',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3126,
    longitude: 149.1278,
    accuracy: 3,
  },
  {
    postcode: 2600,
    place_name: 'Duntroon',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3,
    longitude: 149.1667,
    accuracy: 4,
  },
  {
    postcode: 2600,
    place_name: 'Parliament House',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3126,
    longitude: 149.1278,
    accuracy: 3,
  },
  {
    postcode: 2600,
    place_name: 'Yarralumla',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2998,
    longitude: 149.1058,
    accuracy: 4,
  },
  {
    postcode: 2600,
    place_name: 'Russell',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2977,
    longitude: 149.151,
    accuracy: 4,
  },
  {
    postcode: 2600,
    place_name: 'Barton',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3049,
    longitude: 149.1412,
    accuracy: 4,
  },
  {
    postcode: 2600,
    place_name: 'Deakin',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3193,
    longitude: 149.1031,
    accuracy: 4,
  },
  {
    postcode: 2600,
    place_name: 'Capital Hill',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3066,
    longitude: 149.1246,
    accuracy: 4,
  },
  {
    postcode: 2600,
    place_name: 'Parkes',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3018,
    longitude: 149.1304,
    accuracy: 4,
  },
  {
    postcode: 2600,
    place_name: 'Harman',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.35,
    longitude: 149.2,
    accuracy: 4,
  },
  {
    postcode: 2600,
    place_name: 'Hmas Harman',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.31,
    longitude: 149.1385,
    accuracy: 3,
  },
  {
    postcode: 2600,
    place_name: 'Canberra',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2835,
    longitude: 149.1281,
    accuracy: 4,
  },
  {
    postcode: 2601,
    place_name: 'City',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2813,
    longitude: 149.1293,
    accuracy: 4,
  },
  {
    postcode: 2601,
    place_name: 'Canberra',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2835,
    longitude: 149.1281,
    accuracy: 4,
  },
  {
    postcode: 2601,
    place_name: 'Acton',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2777,
    longitude: 149.1183,
    accuracy: 4,
  },
  {
    postcode: 2601,
    place_name: 'Black Mountain',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2772,
    longitude: 149.1054,
    accuracy: 3,
  },
  {
    postcode: 2602,
    place_name: 'Downer',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2446,
    longitude: 149.1447,
    accuracy: 4,
  },
  {
    postcode: 2602,
    place_name: 'Hackett',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2495,
    longitude: 149.1635,
    accuracy: 4,
  },
  {
    postcode: 2602,
    place_name: 'Watson',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2381,
    longitude: 149.1527,
    accuracy: 4,
  },
  {
    postcode: 2602,
    place_name: 'Lyneham',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2398,
    longitude: 149.1307,
    accuracy: 4,
  },
  {
    postcode: 2602,
    place_name: "O'Connor",
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2564,
    longitude: 149.1125,
    accuracy: 4,
  },
  {
    postcode: 2602,
    place_name: 'Ainslie',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2625,
    longitude: 149.1437,
    accuracy: 4,
  },
  {
    postcode: 2602,
    place_name: 'Dickson',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2508,
    longitude: 149.1393,
    accuracy: 4,
  },
  {
    postcode: 2603,
    place_name: 'Red Hill',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3262,
    longitude: 149.1191,
    accuracy: 4,
  },
  {
    postcode: 2603,
    place_name: 'Manuka',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3126,
    longitude: 149.1278,
    accuracy: 3,
  },
  {
    postcode: 2603,
    place_name: 'Griffith',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3253,
    longitude: 149.1371,
    accuracy: 4,
  },
  {
    postcode: 2603,
    place_name: 'Forrest',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.315,
    longitude: 149.1286,
    accuracy: 4,
  },
  {
    postcode: 2604,
    place_name: 'Narrabundah',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3357,
    longitude: 149.1492,
    accuracy: 4,
  },
  {
    postcode: 2604,
    place_name: 'Causeway',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3151,
    longitude: 149.1512,
    accuracy: 4,
  },
  {
    postcode: 2604,
    place_name: 'Kingston',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3152,
    longitude: 149.1466,
    accuracy: 4,
  },
  {
    postcode: 2605,
    place_name: 'Hughes',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3327,
    longitude: 149.0949,
    accuracy: 4,
  },
  {
    postcode: 2605,
    place_name: 'Garran',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3421,
    longitude: 149.1085,
    accuracy: 4,
  },
  {
    postcode: 2605,
    place_name: 'Curtin',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3246,
    longitude: 149.0776,
    accuracy: 4,
  },
  {
    postcode: 2606,
    place_name: "O'Malley",
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3525,
    longitude: 149.1128,
    accuracy: 4,
  },
  {
    postcode: 2606,
    place_name: 'Lyons',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3406,
    longitude: 149.074,
    accuracy: 4,
  },
  {
    postcode: 2606,
    place_name: 'Chifley',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3534,
    longitude: 149.0768,
    accuracy: 4,
  },
  {
    postcode: 2606,
    place_name: 'Woden',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3481,
    longitude: 149.0905,
    accuracy: 3,
  },
  {
    postcode: 2606,
    place_name: 'Phillip',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3503,
    longitude: 149.0915,
    accuracy: 4,
  },
  {
    postcode: 2606,
    place_name: 'Swinger Hill',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3481,
    longitude: 149.0905,
    accuracy: 3,
  },
  {
    postcode: 2607,
    place_name: 'Isaacs',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3686,
    longitude: 149.1156,
    accuracy: 4,
  },
  {
    postcode: 2607,
    place_name: 'Torrens',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.372,
    longitude: 149.0877,
    accuracy: 4,
  },
  {
    postcode: 2607,
    place_name: 'Mawson',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3634,
    longitude: 149.0986,
    accuracy: 4,
  },
  {
    postcode: 2607,
    place_name: 'Farrer',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3767,
    longitude: 149.105,
    accuracy: 4,
  },
  {
    postcode: 2607,
    place_name: 'Pearce',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3622,
    longitude: 149.0834,
    accuracy: 4,
  },
  {
    postcode: 2608,
    place_name: 'Civic Square',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.31,
    longitude: 149.1933,
    accuracy: 1,
  },
  {
    postcode: 2609,
    place_name: 'Pialligo',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3201,
    longitude: 149.207,
    accuracy: 4,
  },
  {
    postcode: 2609,
    place_name: 'Canberra Airport',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3172,
    longitude: 149.1753,
    accuracy: 3,
  },
  {
    postcode: 2609,
    place_name: 'Fyshwick',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3333,
    longitude: 149.1667,
    accuracy: 4,
  },
  {
    postcode: 2609,
    place_name: 'Symonston',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3516,
    longitude: 149.1592,
    accuracy: 4,
  },
  {
    postcode: 2609,
    place_name: 'Majura',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2667,
    longitude: 149.2,
    accuracy: 4,
  },
  {
    postcode: 2610,
    place_name: 'Canberra Bc',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2778,
    longitude: 149.0111,
    accuracy: 1,
  },
  {
    postcode: 2610,
    place_name: 'Canberra Mc',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2498,
    longitude: 149.0591,
    accuracy: 1,
  },
  {
    postcode: 2611,
    place_name: 'Stirling',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3497,
    longitude: 149.0493,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Wright',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3224,
    longitude: 149.0346,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Coree',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2794,
    longitude: 148.9194,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Uriarra Village',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3503,
    longitude: 149.0155,
    accuracy: 3,
  },
  {
    postcode: 2611,
    place_name: 'Holder',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3344,
    longitude: 149.0461,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Stromlo',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3255,
    longitude: 148.9993,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Waramanga',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.353,
    longitude: 149.0621,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Chapman',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3562,
    longitude: 149.0374,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Weston Creek',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3333,
    longitude: 149.05,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Mount Stromlo',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4171,
    longitude: 148.7769,
    accuracy: 3,
  },
  {
    postcode: 2611,
    place_name: 'Weston',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3358,
    longitude: 149.0593,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Coombs',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3163,
    longitude: 149.0394,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Uriarra',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4171,
    longitude: 148.7769,
    accuracy: 3,
  },
  {
    postcode: 2611,
    place_name: 'Duffy',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3346,
    longitude: 149.0319,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Fisher',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3613,
    longitude: 149.057,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Rivett',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3471,
    longitude: 149.0379,
    accuracy: 4,
  },
  {
    postcode: 2612,
    place_name: 'Campbell',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2891,
    longitude: 149.1538,
    accuracy: 4,
  },
  {
    postcode: 2612,
    place_name: 'Turner',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2688,
    longitude: 149.1247,
    accuracy: 4,
  },
  {
    postcode: 2612,
    place_name: 'Reid',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2858,
    longitude: 149.1391,
    accuracy: 4,
  },
  {
    postcode: 2612,
    place_name: 'Braddon',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2708,
    longitude: 149.1357,
    accuracy: 4,
  },
  {
    postcode: 2614,
    place_name: 'Cook',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2601,
    longitude: 149.0657,
    accuracy: 4,
  },
  {
    postcode: 2614,
    place_name: 'Aranda',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2582,
    longitude: 149.0804,
    accuracy: 4,
  },
  {
    postcode: 2614,
    place_name: 'Page',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2386,
    longitude: 149.0499,
    accuracy: 4,
  },
  {
    postcode: 2614,
    place_name: 'Hawker',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2471,
    longitude: 149.0367,
    accuracy: 4,
  },
  {
    postcode: 2614,
    place_name: 'Scullin',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2346,
    longitude: 149.039,
    accuracy: 4,
  },
  {
    postcode: 2614,
    place_name: 'Jamison Centre',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2498,
    longitude: 149.0591,
    accuracy: 3,
  },
  {
    postcode: 2614,
    place_name: 'Weetangera',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2498,
    longitude: 149.0591,
    accuracy: 3,
  },
  {
    postcode: 2614,
    place_name: 'Macquarie',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2513,
    longitude: 149.0636,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Fraser',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1917,
    longitude: 149.0453,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Spence',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1987,
    longitude: 149.0644,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Florey',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2259,
    longitude: 149.05,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Holt',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2244,
    longitude: 149.0119,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Charnwood',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2002,
    longitude: 149.0341,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Higgins',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2324,
    longitude: 149.0272,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Dunlop',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.194,
    longitude: 149.0198,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Kippax',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2101,
    longitude: 149.034,
    accuracy: 3,
  },
  {
    postcode: 2615,
    place_name: 'Melba',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2102,
    longitude: 149.0541,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Flynn',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2059,
    longitude: 149.0439,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Latham',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2165,
    longitude: 149.0314,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Macgregor',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2098,
    longitude: 149.011,
    accuracy: 4,
  },
  {
    postcode: 2615,
    place_name: 'Kippax Centre',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2101,
    longitude: 149.034,
    accuracy: 3,
  },
  {
    postcode: 2616,
    place_name: 'Belconnen',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2167,
    longitude: 149.0833,
    accuracy: 4,
  },
  {
    postcode: 2617,
    place_name: 'Mckellar',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2175,
    longitude: 149.077,
    accuracy: 4,
  },
  {
    postcode: 2617,
    place_name: 'Belconnen',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2167,
    longitude: 149.0833,
    accuracy: 4,
  },
  {
    postcode: 2617,
    place_name: 'Evatt',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2119,
    longitude: 149.0689,
    accuracy: 4,
  },
  {
    postcode: 2617,
    place_name: 'Lawson',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2241,
    longitude: 149.0998,
    accuracy: 4,
  },
  {
    postcode: 2617,
    place_name: 'Giralang',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2109,
    longitude: 149.096,
    accuracy: 4,
  },
  {
    postcode: 2617,
    place_name: 'University Of Canberra',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2498,
    longitude: 149.0591,
    accuracy: 3,
  },
  {
    postcode: 2617,
    place_name: 'Belconnen DC',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2199,
    longitude: 149.0869,
    accuracy: 3,
  },
  {
    postcode: 2617,
    place_name: 'Kaleen',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2181,
    longitude: 149.1052,
    accuracy: 4,
  },
  {
    postcode: 2617,
    place_name: 'Bruce',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2441,
    longitude: 149.0908,
    accuracy: 4,
  },
  {
    postcode: 2618,
    place_name: 'Hall',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1685,
    longitude: 149.0683,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Hume',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3855,
    longitude: 149.1658,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Paddys River',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'Beard',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3422,
    longitude: 149.2101,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Oaks Estate',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3397,
    longitude: 149.2121,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Tharwa',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.5167,
    longitude: 149.0667,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Kowen',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2900,
    place_name: 'Greenway',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4183,
    longitude: 149.0666,
    accuracy: 4,
  },
  {
    postcode: 2900,
    place_name: 'Tuggeranong',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4158,
    longitude: 149.0649,
    accuracy: 3,
  },
  {
    postcode: 2901,
    place_name: 'Tuggeranong Dc',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4333,
    longitude: 149.15,
    accuracy: 1,
  },
  {
    postcode: 2902,
    place_name: 'Kambah',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3862,
    longitude: 149.058,
    accuracy: 4,
  },
  {
    postcode: 2902,
    place_name: 'Kambah Village',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3862,
    longitude: 149.058,
    accuracy: 3,
  },
  {
    postcode: 2903,
    place_name: 'Erindale Centre',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3998,
    longitude: 149.0888,
    accuracy: 3,
  },
  {
    postcode: 2903,
    place_name: 'Oxley',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4095,
    longitude: 149.0786,
    accuracy: 4,
  },
  {
    postcode: 2903,
    place_name: 'Wanniassa',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.3978,
    longitude: 149.0909,
    accuracy: 4,
  },
  {
    postcode: 2904,
    place_name: 'Fadden',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.405,
    longitude: 149.1166,
    accuracy: 4,
  },
  {
    postcode: 2904,
    place_name: 'Monash',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4158,
    longitude: 149.0906,
    accuracy: 4,
  },
  {
    postcode: 2904,
    place_name: 'Gowrie',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4119,
    longitude: 149.109,
    accuracy: 4,
  },
  {
    postcode: 2904,
    place_name: 'Macarthur',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4089,
    longitude: 149.127,
    accuracy: 4,
  },
  {
    postcode: 2905,
    place_name: 'Isabella Plains',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4283,
    longitude: 149.088,
    accuracy: 4,
  },
  {
    postcode: 2905,
    place_name: 'Theodore',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4496,
    longitude: 149.1197,
    accuracy: 4,
  },
  {
    postcode: 2905,
    place_name: 'Gilmore',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4199,
    longitude: 149.1348,
    accuracy: 4,
  },
  {
    postcode: 2905,
    place_name: 'Richardson',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4279,
    longitude: 149.1138,
    accuracy: 4,
  },
  {
    postcode: 2905,
    place_name: 'Calwell',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4404,
    longitude: 149.1071,
    accuracy: 4,
  },
  {
    postcode: 2905,
    place_name: 'Chisholm',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4125,
    longitude: 149.1283,
    accuracy: 4,
  },
  {
    postcode: 2905,
    place_name: 'Bonython',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4333,
    longitude: 149.0782,
    accuracy: 4,
  },
  {
    postcode: 2906,
    place_name: 'Conder',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4593,
    longitude: 149.1042,
    accuracy: 4,
  },
  {
    postcode: 2906,
    place_name: 'Gordon',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4568,
    longitude: 149.085,
    accuracy: 4,
  },
  {
    postcode: 2906,
    place_name: 'Banks',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.4719,
    longitude: 149.0997,
    accuracy: 4,
  },
  {
    postcode: 2911,
    place_name: 'Mitchell',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2145,
    longitude: 149.1293,
    accuracy: 4,
  },
  {
    postcode: 2911,
    place_name: 'Crace',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.2028,
    longitude: 149.1074,
    accuracy: 4,
  },
  {
    postcode: 2912,
    place_name: 'Gungahlin',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1867,
    longitude: 149.1362,
    accuracy: 4,
  },
  {
    postcode: 2913,
    place_name: 'Casey',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.167,
    longitude: 149.0947,
    accuracy: 4,
  },
  {
    postcode: 2913,
    place_name: 'Franklin',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1995,
    longitude: 149.1433,
    accuracy: 4,
  },
  {
    postcode: 2913,
    place_name: 'Taylor',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1489,
    longitude: 149.1092,
    accuracy: 4,
  },
  {
    postcode: 2913,
    place_name: 'Palmerston',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1945,
    longitude: 149.1194,
    accuracy: 4,
  },
  {
    postcode: 2913,
    place_name: 'Kinlyside',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1754,
    longitude: 149.0798,
    accuracy: 4,
  },
  {
    postcode: 2913,
    place_name: 'Nicholls',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1873,
    longitude: 149.0965,
    accuracy: 4,
  },
  {
    postcode: 2913,
    place_name: 'Ginninderra Village',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1875,
    longitude: 149.1244,
    accuracy: 3,
  },
  {
    postcode: 2913,
    place_name: 'Ngunnawal',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1728,
    longitude: 149.1115,
    accuracy: 4,
  },
  {
    postcode: 2914,
    place_name: 'Bonner',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1623,
    longitude: 149.1386,
    accuracy: 4,
  },
  {
    postcode: 2914,
    place_name: 'Forde',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1682,
    longitude: 149.1461,
    accuracy: 4,
  },
  {
    postcode: 2914,
    place_name: 'Harrison',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1991,
    longitude: 149.1563,
    accuracy: 4,
  },
  {
    postcode: 2914,
    place_name: 'Amaroo',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1696,
    longitude: 149.128,
    accuracy: 4,
  },
  {
    postcode: 2914,
    place_name: 'Moncrieff',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1651,
    longitude: 149.1179,
    accuracy: 4,
  },
  {
    postcode: 2914,
    place_name: 'Jacka',
    state_name: 'Australian Capital Territory',
    state_code: 'ACT',
    latitude: -35.1568,
    longitude: 149.1288,
    accuracy: 4,
  },
  {
    postcode: 1001,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1002,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1003,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1004,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1005,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1006,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1007,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1008,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1009,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1010,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1011,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1020,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1021,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1022,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1023,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1025,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1026,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1027,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1028,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1029,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1030,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1031,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1032,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1033,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1034,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1035,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1036,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1037,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1038,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1039,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1040,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1041,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1042,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1043,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1044,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1045,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1046,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1100,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1101,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1105,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1106,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1107,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1108,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1109,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1110,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1112,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1113,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1114,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1115,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1116,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1117,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1118,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1119,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1120,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1121,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1122,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1123,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1124,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1125,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1126,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1127,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1128,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1129,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1130,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1131,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1132,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1133,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1134,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1135,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1136,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1137,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1138,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1139,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1140,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1141,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1142,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1143,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1144,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1145,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1146,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1147,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1148,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1149,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1150,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1151,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1152,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1153,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1154,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1155,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1156,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1157,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1158,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1159,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1160,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1161,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1162,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1163,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1164,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1165,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1166,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1167,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1168,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1169,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1170,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1171,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1172,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1173,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1174,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1175,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1176,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1177,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1178,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1179,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1180,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1181,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1182,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1183,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1184,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1185,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1186,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1187,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1188,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1189,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1190,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1191,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1192,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1193,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1194,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1195,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1196,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1197,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1198,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1199,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1200,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1201,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1202,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1203,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1204,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1205,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1206,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1207,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1208,
    place_name: 'Haymarket',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 4,
  },
  {
    postcode: 1209,
    place_name: 'Australia Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8691,
    longitude: 151.2071,
    accuracy: 1,
  },
  {
    postcode: 1210,
    place_name: 'Australia Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1211,
    place_name: 'Australia Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1212,
    place_name: 'Australia Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1213,
    place_name: 'Australia Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1214,
    place_name: 'Australia Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1215,
    place_name: 'Australia Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1216,
    place_name: 'Grosvenor Place',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1217,
    place_name: 'Grosvenor Place',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1218,
    place_name: 'Grosvenor Place',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1219,
    place_name: 'Grosvenor Place',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1220,
    place_name: 'Grosvenor Place',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1221,
    place_name: 'Royal Exchange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1222,
    place_name: 'Royal Exchange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1223,
    place_name: 'Royal Exchange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1224,
    place_name: 'Royal Exchange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1225,
    place_name: 'Royal Exchange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1226,
    place_name: 'Queen Victoria Building',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1227,
    place_name: 'Queen Victoria Building',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1228,
    place_name: 'Queen Victoria Building',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1229,
    place_name: 'Queen Victoria Building',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8707,
    longitude: 151.2068,
    accuracy: 1,
  },
  {
    postcode: 1230,
    place_name: 'Queen Victoria Building',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 1,
  },
  {
    postcode: 1231,
    place_name: 'Sydney South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 1,
  },
  {
    postcode: 1232,
    place_name: 'Sydney South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 1,
  },
  {
    postcode: 1233,
    place_name: 'Sydney South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 1,
  },
  {
    postcode: 1234,
    place_name: 'Sydney South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 1,
  },
  {
    postcode: 1235,
    place_name: 'Sydney South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 1,
  },
  {
    postcode: 1236,
    place_name: 'Haymarket',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 4,
  },
  {
    postcode: 1237,
    place_name: 'Haymarket',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 4,
  },
  {
    postcode: 1238,
    place_name: 'Haymarket',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 4,
  },
  {
    postcode: 1239,
    place_name: 'Haymarket',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 4,
  },
  {
    postcode: 1240,
    place_name: 'Haymarket',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 4,
  },
  {
    postcode: 1291,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1292,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1293,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1294,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1295,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1296,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1297,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1298,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1299,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 1300,
    place_name: 'Darlinghurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8794,
    longitude: 151.2193,
    accuracy: 4,
  },
  {
    postcode: 1311,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1312,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1313,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1314,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1315,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1316,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1317,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1318,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1319,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1320,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1321,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1322,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1323,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1324,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1325,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1326,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1327,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1328,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1329,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1330,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8693,
    longitude: 151.2248,
    accuracy: 1,
  },
  {
    postcode: 1331,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8693,
    longitude: 151.2248,
    accuracy: 1,
  },
  {
    postcode: 1332,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8693,
    longitude: 151.2248,
    accuracy: 1,
  },
  {
    postcode: 1333,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8693,
    longitude: 151.2248,
    accuracy: 1,
  },
  {
    postcode: 1334,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8693,
    longitude: 151.2248,
    accuracy: 1,
  },
  {
    postcode: 1335,
    place_name: 'Potts Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8713,
    longitude: 151.222,
    accuracy: 4,
  },
  {
    postcode: 1340,
    place_name: 'Kings Cross',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.875,
    longitude: 151.2223,
    accuracy: 4,
  },
  {
    postcode: 1350,
    place_name: 'Woollahra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8878,
    longitude: 151.2368,
    accuracy: 4,
  },
  {
    postcode: 1355,
    place_name: 'Bondi Junction',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8928,
    longitude: 151.2472,
    accuracy: 4,
  },
  {
    postcode: 1360,
    place_name: 'Double Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8778,
    longitude: 151.2435,
    accuracy: 4,
  },
  {
    postcode: 1363,
    place_name: 'Moore Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9014,
    longitude: 151.2138,
    accuracy: 4,
  },
  {
    postcode: 1391,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8784,
    longitude: 151.2313,
    accuracy: 1,
  },
  {
    postcode: 1401,
    place_name: 'Broadway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1416,
    place_name: 'Southern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1419,
    place_name: 'Southern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1420,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1421,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1422,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1423,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1424,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1425,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1426,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1427,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1428,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1429,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9475,
    longitude: 151.1522,
    accuracy: 1,
  },
  {
    postcode: 1430,
    place_name: 'Eveleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8956,
    longitude: 151.1924,
    accuracy: 4,
  },
  {
    postcode: 1435,
    place_name: 'Alexandria',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9022,
    longitude: 151.2004,
    accuracy: 4,
  },
  {
    postcode: 1440,
    place_name: 'Waterloo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 151.2,
    accuracy: 4,
  },
  {
    postcode: 1445,
    place_name: 'Rosebery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 151.2,
    accuracy: 4,
  },
  {
    postcode: 1450,
    place_name: 'Camperdown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8897,
    longitude: 151.1764,
    accuracy: 4,
  },
  {
    postcode: 1455,
    place_name: 'Botany',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.946,
    longitude: 151.1959,
    accuracy: 4,
  },
  {
    postcode: 1460,
    place_name: 'Mascot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.926,
    longitude: 151.1935,
    accuracy: 4,
  },
  {
    postcode: 1465,
    place_name: 'Kensington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9202,
    longitude: 151.2224,
    accuracy: 4,
  },
  {
    postcode: 1466,
    place_name: 'Unsw Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.925,
    longitude: 151.2083,
    accuracy: 1,
  },
  {
    postcode: 1470,
    place_name: 'Drummoyne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8524,
    longitude: 151.1549,
    accuracy: 4,
  },
  {
    postcode: 1475,
    place_name: 'Marrickville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9032,
    longitude: 151.1518,
    accuracy: 4,
  },
  {
    postcode: 1480,
    place_name: 'Kingsgrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9393,
    longitude: 151.0993,
    accuracy: 4,
  },
  {
    postcode: 1481,
    place_name: 'Hurstville Bc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9687,
    longitude: 151.1109,
    accuracy: 1,
  },
  {
    postcode: 1484,
    place_name: 'Kingsgrove DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9687,
    longitude: 151.1109,
    accuracy: 1,
  },
  {
    postcode: 1485,
    place_name: 'Kogarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9833,
    longitude: 151.1167,
    accuracy: 4,
  },
  {
    postcode: 1487,
    place_name: 'Kogarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9833,
    longitude: 151.1167,
    accuracy: 4,
  },
  {
    postcode: 1490,
    place_name: 'Miranda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0386,
    longitude: 151.1001,
    accuracy: 4,
  },
  {
    postcode: 1493,
    place_name: 'Hurstville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9799,
    longitude: 151.0903,
    accuracy: 4,
  },
  {
    postcode: 1495,
    place_name: 'Caringbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0473,
    longitude: 151.1205,
    accuracy: 4,
  },
  {
    postcode: 1499,
    place_name: 'Sutherland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.031,
    longitude: 151.0553,
    accuracy: 4,
  },
  {
    postcode: 1515,
    place_name: 'West Chatswood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.794,
    longitude: 151.1649,
    accuracy: 4,
  },
  {
    postcode: 1560,
    place_name: 'Northbridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8094,
    longitude: 151.2222,
    accuracy: 4,
  },
  {
    postcode: 1565,
    place_name: 'Milsons Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.846,
    longitude: 151.2119,
    accuracy: 4,
  },
  {
    postcode: 1570,
    place_name: 'Artarmon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 151.1833,
    accuracy: 4,
  },
  {
    postcode: 1582,
    place_name: 'Crows Nest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8265,
    longitude: 151.2019,
    accuracy: 4,
  },
  {
    postcode: 1585,
    place_name: 'Crows Nest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8265,
    longitude: 151.2019,
    accuracy: 4,
  },
  {
    postcode: 1590,
    place_name: 'St Leonards',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8234,
    longitude: 151.1984,
    accuracy: 4,
  },
  {
    postcode: 1595,
    place_name: 'Lane Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8157,
    longitude: 151.1668,
    accuracy: 4,
  },
  {
    postcode: 1597,
    place_name: 'Lane Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8157,
    longitude: 151.1668,
    accuracy: 4,
  },
  {
    postcode: 1602,
    place_name: 'Lane Cove DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1630,
    place_name: 'Hornsby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7024,
    longitude: 151.0993,
    accuracy: 4,
  },
  {
    postcode: 1635,
    place_name: 'Hornsby Westfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7,
    longitude: 151.1,
    accuracy: 1,
  },
  {
    postcode: 1639,
    place_name: 'Frenchs Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7482,
    longitude: 151.2232,
    accuracy: 4,
  },
  {
    postcode: 1640,
    place_name: 'Frenchs Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7482,
    longitude: 151.2232,
    accuracy: 4,
  },
  {
    postcode: 1655,
    place_name: 'Manly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.798,
    longitude: 151.2883,
    accuracy: 4,
  },
  {
    postcode: 1658,
    place_name: 'Mona Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6776,
    longitude: 151.3031,
    accuracy: 4,
  },
  {
    postcode: 1660,
    place_name: 'Mona Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6776,
    longitude: 151.3031,
    accuracy: 4,
  },
  {
    postcode: 1670,
    place_name: 'North Ryde Bc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8151,
    longitude: 151.1288,
    accuracy: 1,
  },
  {
    postcode: 1671,
    place_name: 'North Ryde',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7968,
    longitude: 151.1244,
    accuracy: 4,
  },
  {
    postcode: 1675,
    place_name: 'Gladesville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 151.1333,
    accuracy: 4,
  },
  {
    postcode: 1680,
    place_name: 'Ryde',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8188,
    longitude: 151.1062,
    accuracy: 4,
  },
  {
    postcode: 1685,
    place_name: 'West Ryde',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8054,
    longitude: 151.0739,
    accuracy: 4,
  },
  {
    postcode: 1690,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1691,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1692,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1693,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1694,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1695,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1696,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1697,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1698,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1699,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1998,
    accuracy: 1,
  },
  {
    postcode: 1700,
    place_name: 'Ermington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8148,
    longitude: 151.0547,
    accuracy: 4,
  },
  {
    postcode: 1701,
    place_name: 'Rydalmere Bc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7613,
    longitude: 151.0294,
    accuracy: 1,
  },
  {
    postcode: 1710,
    place_name: 'Epping',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7727,
    longitude: 151.0818,
    accuracy: 4,
  },
  {
    postcode: 1712,
    place_name: 'Epping',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7727,
    longitude: 151.0818,
    accuracy: 4,
  },
  {
    postcode: 1715,
    place_name: 'Pennant Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7378,
    longitude: 151.0722,
    accuracy: 4,
  },
  {
    postcode: 1730,
    place_name: 'Seven Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7833,
    longitude: 150.9333,
    accuracy: 4,
  },
  {
    postcode: 1740,
    place_name: 'Parramatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7613,
    longitude: 151.0294,
    accuracy: 1,
  },
  {
    postcode: 1741,
    place_name: 'Parramatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7613,
    longitude: 151.0294,
    accuracy: 1,
  },
  {
    postcode: 1750,
    place_name: 'North Parramatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7935,
    longitude: 151.0012,
    accuracy: 4,
  },
  {
    postcode: 1755,
    place_name: 'Baulkham Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7588,
    longitude: 150.9929,
    accuracy: 4,
  },
  {
    postcode: 1765,
    place_name: 'Castle Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7333,
    longitude: 151,
    accuracy: 4,
  },
  {
    postcode: 1771,
    place_name: 'Pennant Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7378,
    longitude: 151.0722,
    accuracy: 4,
  },
  {
    postcode: 1781,
    place_name: 'Seven Hills Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7613,
    longitude: 151.0294,
    accuracy: 1,
  },
  {
    postcode: 1790,
    place_name: 'St Marys',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7613,
    longitude: 151.0294,
    accuracy: 1,
  },
  {
    postcode: 1797,
    place_name: 'Penrith South DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7613,
    longitude: 151.0294,
    accuracy: 1,
  },
  {
    postcode: 1800,
    place_name: 'Ashfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8883,
    longitude: 151.1227,
    accuracy: 4,
  },
  {
    postcode: 1805,
    place_name: 'Burwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8833,
    longitude: 151.1,
    accuracy: 4,
  },
  {
    postcode: 1811,
    place_name: 'Silverwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8334,
    longitude: 151.0473,
    accuracy: 4,
  },
  {
    postcode: 1825,
    place_name: 'Lidcombe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8644,
    longitude: 151.0397,
    accuracy: 4,
  },
  {
    postcode: 1826,
    place_name: 'Lidcombe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8644,
    longitude: 151.0397,
    accuracy: 4,
  },
  {
    postcode: 1830,
    place_name: 'Granville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 1831,
    place_name: 'Granville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 1835,
    place_name: 'Auburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.85,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 1848,
    place_name: 'Guildford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8643,
    longitude: 150.9839,
    accuracy: 1,
  },
  {
    postcode: 1851,
    place_name: 'Wetherill Park Dc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8872,
    longitude: 151.0086,
    accuracy: 1,
  },
  {
    postcode: 1860,
    place_name: 'Fairfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8667,
    longitude: 150.95,
    accuracy: 4,
  },
  {
    postcode: 1871,
    place_name: 'Liverpool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9,
    longitude: 150.9333,
    accuracy: 4,
  },
  {
    postcode: 1875,
    place_name: 'Moorebank',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9425,
    longitude: 150.9377,
    accuracy: 4,
  },
  {
    postcode: 1885,
    place_name: 'Bankstown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 1888,
    place_name: 'Bankstown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 1890,
    place_name: 'Ingleburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34,
    longitude: 150.8667,
    accuracy: 4,
  },
  {
    postcode: 1891,
    place_name: 'Milperra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9393,
    longitude: 150.9815,
    accuracy: 4,
  },
  {
    postcode: 1900,
    place_name: 'Leightonfield Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8816,
    longitude: 150.9844,
    accuracy: null,
  },
  {
    postcode: 1902,
    place_name: 'Leightonfield Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8816,
    longitude: 150.9844,
    accuracy: null,
  },
  {
    postcode: 2000,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 2000,
    place_name: 'Dawes Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8561,
    longitude: 151.2077,
    accuracy: 4,
  },
  {
    postcode: 2000,
    place_name: 'The Rocks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8592,
    longitude: 151.2081,
    accuracy: 4,
  },
  {
    postcode: 2000,
    place_name: 'Parliament House',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8651,
    longitude: 151.2053,
    accuracy: 3,
  },
  {
    postcode: 2000,
    place_name: 'Sydney South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8699,
    longitude: 151.2025,
    accuracy: 3,
  },
  {
    postcode: 2000,
    place_name: 'Barangaroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8603,
    longitude: 151.2016,
    accuracy: 4,
  },
  {
    postcode: 2000,
    place_name: 'Millers Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8596,
    longitude: 151.2041,
    accuracy: 4,
  },
  {
    postcode: 2000,
    place_name: 'Haymarket',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8787,
    longitude: 151.2053,
    accuracy: 4,
  },
  {
    postcode: 2001,
    place_name: 'Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8678,
    longitude: 151.2073,
    accuracy: 4,
  },
  {
    postcode: 2002,
    place_name: 'World Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8699,
    longitude: 151.2025,
    accuracy: 1,
  },
  {
    postcode: 2004,
    place_name: 'Alexandria Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8699,
    longitude: 151.2025,
    accuracy: 1,
  },
  {
    postcode: 2004,
    place_name: 'Eastern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8699,
    longitude: 151.2025,
    accuracy: 1,
  },
  {
    postcode: 2006,
    place_name: 'The University Of Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8699,
    longitude: 151.2025,
    accuracy: 1,
  },
  {
    postcode: 2007,
    place_name: 'Broadway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8699,
    longitude: 151.2025,
    accuracy: 3,
  },
  {
    postcode: 2007,
    place_name: 'Ultimo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8772,
    longitude: 151.1972,
    accuracy: 4,
  },
  {
    postcode: 2008,
    place_name: 'Darlington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.891,
    longitude: 151.1955,
    accuracy: 4,
  },
  {
    postcode: 2008,
    place_name: 'Chippendale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8867,
    longitude: 151.1975,
    accuracy: 4,
  },
  {
    postcode: 2009,
    place_name: 'Pyrmont',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8698,
    longitude: 151.194,
    accuracy: 4,
  },
  {
    postcode: 2010,
    place_name: 'Darlinghurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8794,
    longitude: 151.2193,
    accuracy: 4,
  },
  {
    postcode: 2010,
    place_name: 'Surry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8837,
    longitude: 151.2128,
    accuracy: 4,
  },
  {
    postcode: 2011,
    place_name: 'Kings Cross',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.875,
    longitude: 151.2223,
    accuracy: 4,
  },
  {
    postcode: 2011,
    place_name: 'Potts Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8713,
    longitude: 151.222,
    accuracy: 4,
  },
  {
    postcode: 2011,
    place_name: 'Rushcutters Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8748,
    longitude: 151.228,
    accuracy: 4,
  },
  {
    postcode: 2011,
    place_name: 'Elizabeth Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8718,
    longitude: 151.2271,
    accuracy: 4,
  },
  {
    postcode: 2011,
    place_name: 'Woolloomooloo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8704,
    longitude: 151.2197,
    accuracy: 4,
  },
  {
    postcode: 2012,
    place_name: 'Strawberry Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9032,
    longitude: 150.9677,
    accuracy: 1,
  },
  {
    postcode: 2015,
    place_name: 'Eveleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8956,
    longitude: 151.1924,
    accuracy: 4,
  },
  {
    postcode: 2015,
    place_name: 'Alexandria',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9022,
    longitude: 151.2004,
    accuracy: 4,
  },
  {
    postcode: 2015,
    place_name: 'Beaconsfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9115,
    longitude: 151.2004,
    accuracy: 4,
  },
  {
    postcode: 2016,
    place_name: 'Redfern',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8928,
    longitude: 151.2041,
    accuracy: 4,
  },
  {
    postcode: 2017,
    place_name: 'Waterloo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 151.2,
    accuracy: 4,
  },
  {
    postcode: 2017,
    place_name: 'Zetland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9075,
    longitude: 151.2086,
    accuracy: 4,
  },
  {
    postcode: 2018,
    place_name: 'Rosebery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 151.2,
    accuracy: 4,
  },
  {
    postcode: 2018,
    place_name: 'Eastlakes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.932,
    longitude: 151.2121,
    accuracy: 4,
  },
  {
    postcode: 2019,
    place_name: 'Botany',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.946,
    longitude: 151.1959,
    accuracy: 4,
  },
  {
    postcode: 2019,
    place_name: 'Banksmeadow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9566,
    longitude: 151.2096,
    accuracy: 4,
  },
  {
    postcode: 2020,
    place_name: 'Sydney International Airport',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.939,
    longitude: 151.1862,
    accuracy: 3,
  },
  {
    postcode: 2020,
    place_name: 'Mascot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.926,
    longitude: 151.1935,
    accuracy: 4,
  },
  {
    postcode: 2020,
    place_name: 'Sydney Domestic Airport',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.939,
    longitude: 151.1862,
    accuracy: 3,
  },
  {
    postcode: 2021,
    place_name: 'Paddington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8842,
    longitude: 151.2315,
    accuracy: 4,
  },
  {
    postcode: 2021,
    place_name: 'Moore Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9014,
    longitude: 151.2138,
    accuracy: 4,
  },
  {
    postcode: 2021,
    place_name: 'Centennial Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8979,
    longitude: 151.2336,
    accuracy: 4,
  },
  {
    postcode: 2022,
    place_name: 'Queens Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8995,
    longitude: 151.2472,
    accuracy: 4,
  },
  {
    postcode: 2022,
    place_name: 'Bondi Junction',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8928,
    longitude: 151.2472,
    accuracy: 4,
  },
  {
    postcode: 2023,
    place_name: 'Bellevue Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.879,
    longitude: 151.251,
    accuracy: 4,
  },
  {
    postcode: 2024,
    place_name: 'Bronte',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.902,
    longitude: 151.2656,
    accuracy: 4,
  },
  {
    postcode: 2024,
    place_name: 'Waverley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8977,
    longitude: 151.2501,
    accuracy: 4,
  },
  {
    postcode: 2025,
    place_name: 'Woollahra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8878,
    longitude: 151.2368,
    accuracy: 4,
  },
  {
    postcode: 2026,
    place_name: 'Tamarama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8984,
    longitude: 151.2706,
    accuracy: 4,
  },
  {
    postcode: 2026,
    place_name: 'Bondi Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.891,
    longitude: 151.2716,
    accuracy: 4,
  },
  {
    postcode: 2026,
    place_name: 'Bondi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8943,
    longitude: 151.2644,
    accuracy: 4,
  },
  {
    postcode: 2026,
    place_name: 'North Bondi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8853,
    longitude: 151.2752,
    accuracy: 4,
  },
  {
    postcode: 2026,
    place_name: 'Ben Buckler',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8904,
    longitude: 151.2475,
    accuracy: 3,
  },
  {
    postcode: 2027,
    place_name: 'Darling Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8706,
    longitude: 151.2389,
    accuracy: 4,
  },
  {
    postcode: 2027,
    place_name: 'Hmas Rushcutters',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8688,
    longitude: 151.2444,
    accuracy: 3,
  },
  {
    postcode: 2027,
    place_name: 'Point Piper',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8667,
    longitude: 151.25,
    accuracy: 4,
  },
  {
    postcode: 2027,
    place_name: 'Edgecliff',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8833,
    longitude: 151.25,
    accuracy: 4,
  },
  {
    postcode: 2028,
    place_name: 'Double Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8778,
    longitude: 151.2435,
    accuracy: 4,
  },
  {
    postcode: 2029,
    place_name: 'Rose Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8688,
    longitude: 151.2706,
    accuracy: 4,
  },
  {
    postcode: 2030,
    place_name: 'Vaucluse',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8555,
    longitude: 151.2775,
    accuracy: 4,
  },
  {
    postcode: 2030,
    place_name: 'Dover Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8708,
    longitude: 151.2792,
    accuracy: 4,
  },
  {
    postcode: 2030,
    place_name: 'Watsons Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8439,
    longitude: 151.2829,
    accuracy: 4,
  },
  {
    postcode: 2030,
    place_name: 'Hmas Watson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8625,
    longitude: 151.2819,
    accuracy: 3,
  },
  {
    postcode: 2030,
    place_name: 'Rose Bay North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8572,
    longitude: 151.2784,
    accuracy: 3,
  },
  {
    postcode: 2031,
    place_name: 'Clovelly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9121,
    longitude: 151.2588,
    accuracy: 4,
  },
  {
    postcode: 2031,
    place_name: 'Randwick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9144,
    longitude: 151.2489,
    accuracy: 4,
  },
  {
    postcode: 2031,
    place_name: 'St Pauls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9251,
    longitude: 151.231,
    accuracy: 3,
  },
  {
    postcode: 2031,
    place_name: 'Clovelly West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9251,
    longitude: 151.231,
    accuracy: 3,
  },
  {
    postcode: 2032,
    place_name: 'Kingsford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.924,
    longitude: 151.2275,
    accuracy: 4,
  },
  {
    postcode: 2032,
    place_name: 'Daceyville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9333,
    longitude: 151.2333,
    accuracy: 4,
  },
  {
    postcode: 2033,
    place_name: 'Kensington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9202,
    longitude: 151.2224,
    accuracy: 4,
  },
  {
    postcode: 2034,
    place_name: 'South Coogee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9205,
    longitude: 151.2552,
    accuracy: 4,
  },
  {
    postcode: 2034,
    place_name: 'Coogee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9205,
    longitude: 151.2552,
    accuracy: 4,
  },
  {
    postcode: 2035,
    place_name: 'Maroubra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.95,
    longitude: 151.2333,
    accuracy: 4,
  },
  {
    postcode: 2035,
    place_name: 'Pagewood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9414,
    longitude: 151.2109,
    accuracy: 4,
  },
  {
    postcode: 2035,
    place_name: 'Maroubra South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9445,
    longitude: 151.2265,
    accuracy: 3,
  },
  {
    postcode: 2036,
    place_name: 'Phillip Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9816,
    longitude: 151.234,
    accuracy: 4,
  },
  {
    postcode: 2036,
    place_name: 'Hillsdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9515,
    longitude: 151.2278,
    accuracy: 4,
  },
  {
    postcode: 2036,
    place_name: 'Port Botany',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9745,
    longitude: 151.2228,
    accuracy: 3,
  },
  {
    postcode: 2036,
    place_name: 'Eastgardens',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9451,
    longitude: 151.2258,
    accuracy: 4,
  },
  {
    postcode: 2036,
    place_name: 'Little Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.979,
    longitude: 151.243,
    accuracy: 4,
  },
  {
    postcode: 2036,
    place_name: 'Malabar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9622,
    longitude: 151.248,
    accuracy: 4,
  },
  {
    postcode: 2036,
    place_name: 'Chifley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.967,
    longitude: 151.2422,
    accuracy: 4,
  },
  {
    postcode: 2036,
    place_name: 'La Perouse',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9872,
    longitude: 151.2317,
    accuracy: 4,
  },
  {
    postcode: 2036,
    place_name: 'Matraville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.95,
    longitude: 151.2333,
    accuracy: 4,
  },
  {
    postcode: 2037,
    place_name: 'Glebe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8788,
    longitude: 151.1843,
    accuracy: 4,
  },
  {
    postcode: 2037,
    place_name: 'Forest Lodge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8814,
    longitude: 151.18,
    accuracy: 4,
  },
  {
    postcode: 2038,
    place_name: 'Annandale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8833,
    longitude: 151.1667,
    accuracy: 4,
  },
  {
    postcode: 2039,
    place_name: 'Rozelle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8614,
    longitude: 151.1705,
    accuracy: 4,
  },
  {
    postcode: 2040,
    place_name: 'Lilyfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.875,
    longitude: 151.1653,
    accuracy: 4,
  },
  {
    postcode: 2040,
    place_name: 'Leichhardt',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8834,
    longitude: 151.1562,
    accuracy: 4,
  },
  {
    postcode: 2041,
    place_name: 'Balmain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8548,
    longitude: 151.1833,
    accuracy: 4,
  },
  {
    postcode: 2041,
    place_name: 'Balmain East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8571,
    longitude: 151.1928,
    accuracy: 4,
  },
  {
    postcode: 2041,
    place_name: 'Birchgrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8516,
    longitude: 151.1824,
    accuracy: 4,
  },
  {
    postcode: 2042,
    place_name: 'Newtown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8983,
    longitude: 151.1775,
    accuracy: 4,
  },
  {
    postcode: 2042,
    place_name: 'Enmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7333,
    longitude: 151.8,
    accuracy: 4,
  },
  {
    postcode: 2043,
    place_name: 'Erskineville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9025,
    longitude: 151.1858,
    accuracy: 4,
  },
  {
    postcode: 2044,
    place_name: 'St Peters',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 151.1833,
    accuracy: 4,
  },
  {
    postcode: 2044,
    place_name: 'Sydenham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 151.168,
    accuracy: 4,
  },
  {
    postcode: 2044,
    place_name: 'Tempe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9233,
    longitude: 151.1602,
    accuracy: 4,
  },
  {
    postcode: 2045,
    place_name: 'Haberfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8833,
    longitude: 151.2,
    accuracy: 4,
  },
  {
    postcode: 2046,
    place_name: 'Russell Lea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8589,
    longitude: 151.1411,
    accuracy: 4,
  },
  {
    postcode: 2046,
    place_name: 'Wareemba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8582,
    longitude: 151.1309,
    accuracy: 4,
  },
  {
    postcode: 2046,
    place_name: 'Five Dock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8674,
    longitude: 151.1291,
    accuracy: 4,
  },
  {
    postcode: 2046,
    place_name: 'Chiswick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.85,
    longitude: 151.1385,
    accuracy: 4,
  },
  {
    postcode: 2046,
    place_name: 'Abbotsford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8489,
    longitude: 151.128,
    accuracy: 4,
  },
  {
    postcode: 2046,
    place_name: 'Canada Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.85,
    longitude: 151.15,
    accuracy: 4,
  },
  {
    postcode: 2046,
    place_name: 'Rodd Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8666,
    longitude: 151.1399,
    accuracy: 4,
  },
  {
    postcode: 2047,
    place_name: 'Drummoyne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8524,
    longitude: 151.1549,
    accuracy: 4,
  },
  {
    postcode: 2048,
    place_name: 'Westgate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8798,
    longitude: 151.1623,
    accuracy: 3,
  },
  {
    postcode: 2048,
    place_name: 'Stanmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8941,
    longitude: 151.1642,
    accuracy: 4,
  },
  {
    postcode: 2049,
    place_name: 'Petersham North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8946,
    longitude: 151.1549,
    accuracy: 3,
  },
  {
    postcode: 2049,
    place_name: 'Petersham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8946,
    longitude: 151.1549,
    accuracy: 4,
  },
  {
    postcode: 2049,
    place_name: 'Lewisham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8972,
    longitude: 151.1488,
    accuracy: 4,
  },
  {
    postcode: 2050,
    place_name: 'Missenden Road',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8892,
    longitude: 151.1771,
    accuracy: 3,
  },
  {
    postcode: 2050,
    place_name: 'Camperdown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8897,
    longitude: 151.1764,
    accuracy: 4,
  },
  {
    postcode: 2052,
    place_name: 'Unsw Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8427,
    longitude: 151.1936,
    accuracy: 1,
  },
  {
    postcode: 2055,
    place_name: 'North Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.839,
    longitude: 151.2072,
    accuracy: 4,
  },
  {
    postcode: 2057,
    place_name: 'Chatswood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8,
    longitude: 151.1833,
    accuracy: 4,
  },
  {
    postcode: 2058,
    place_name: 'Northern Suburbs Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8427,
    longitude: 151.1936,
    accuracy: 1,
  },
  {
    postcode: 2059,
    place_name: 'North Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.839,
    longitude: 151.2072,
    accuracy: 4,
  },
  {
    postcode: 2060,
    place_name: 'Hmas Waterhen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.842,
    longitude: 151.2046,
    accuracy: 3,
  },
  {
    postcode: 2060,
    place_name: 'Waverton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8386,
    longitude: 151.2005,
    accuracy: 4,
  },
  {
    postcode: 2060,
    place_name: 'Hmas Platypus',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.842,
    longitude: 151.2046,
    accuracy: 3,
  },
  {
    postcode: 2060,
    place_name: 'Mcmahons Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8449,
    longitude: 151.2031,
    accuracy: 4,
  },
  {
    postcode: 2060,
    place_name: 'North Sydney Shoppingworld',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.822,
    longitude: 151.1962,
    accuracy: 3,
  },
  {
    postcode: 2060,
    place_name: 'North Sydney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.839,
    longitude: 151.2072,
    accuracy: 4,
  },
  {
    postcode: 2060,
    place_name: 'Lavender Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8437,
    longitude: 151.2075,
    accuracy: 4,
  },
  {
    postcode: 2061,
    place_name: 'Milsons Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.846,
    longitude: 151.2119,
    accuracy: 4,
  },
  {
    postcode: 2061,
    place_name: 'Kirribilli',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8487,
    longitude: 151.2162,
    accuracy: 4,
  },
  {
    postcode: 2062,
    place_name: 'Cammeray',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8213,
    longitude: 151.2161,
    accuracy: 4,
  },
  {
    postcode: 2063,
    place_name: 'Northbridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8094,
    longitude: 151.2222,
    accuracy: 4,
  },
  {
    postcode: 2064,
    place_name: 'Artarmon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 151.1833,
    accuracy: 4,
  },
  {
    postcode: 2065,
    place_name: 'Greenwich',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8395,
    longitude: 151.183,
    accuracy: 4,
  },
  {
    postcode: 2065,
    place_name: 'Crows Nest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8265,
    longitude: 151.2019,
    accuracy: 4,
  },
  {
    postcode: 2065,
    place_name: 'Gore Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 151.1825,
    accuracy: 4,
  },
  {
    postcode: 2065,
    place_name: 'Wollstonecraft',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8328,
    longitude: 151.1898,
    accuracy: 4,
  },
  {
    postcode: 2065,
    place_name: 'St Leonards',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8234,
    longitude: 151.1984,
    accuracy: 4,
  },
  {
    postcode: 2065,
    place_name: 'Naremburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.817,
    longitude: 151.2008,
    accuracy: 4,
  },
  {
    postcode: 2065,
    place_name: 'Royal North Shore Hospital',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.822,
    longitude: 151.1962,
    accuracy: 3,
  },
  {
    postcode: 2066,
    place_name: 'Northwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8289,
    longitude: 151.1803,
    accuracy: 4,
  },
  {
    postcode: 2066,
    place_name: 'Riverview',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.824,
    longitude: 151.1602,
    accuracy: 4,
  },
  {
    postcode: 2066,
    place_name: 'Longueville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8312,
    longitude: 151.1662,
    accuracy: 4,
  },
  {
    postcode: 2066,
    place_name: 'Lane Cove North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8054,
    longitude: 151.1664,
    accuracy: 4,
  },
  {
    postcode: 2066,
    place_name: 'Linley Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8266,
    longitude: 151.1502,
    accuracy: 4,
  },
  {
    postcode: 2066,
    place_name: 'Lane Cove West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8161,
    longitude: 151.1515,
    accuracy: 4,
  },
  {
    postcode: 2066,
    place_name: 'Lane Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8157,
    longitude: 151.1668,
    accuracy: 4,
  },
  {
    postcode: 2067,
    place_name: 'Chatswood West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.794,
    longitude: 151.1649,
    accuracy: 4,
  },
  {
    postcode: 2067,
    place_name: 'Chatswood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8,
    longitude: 151.1833,
    accuracy: 4,
  },
  {
    postcode: 2068,
    place_name: 'Willoughby East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7984,
    longitude: 151.2052,
    accuracy: 4,
  },
  {
    postcode: 2068,
    place_name: 'Middle Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7928,
    longitude: 151.2125,
    accuracy: 4,
  },
  {
    postcode: 2068,
    place_name: 'Willoughby North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8094,
    longitude: 151.2222,
    accuracy: 3,
  },
  {
    postcode: 2068,
    place_name: 'Castlecrag',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7999,
    longitude: 151.2231,
    accuracy: 4,
  },
  {
    postcode: 2068,
    place_name: 'Willoughby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8049,
    longitude: 151.1993,
    accuracy: 4,
  },
  {
    postcode: 2068,
    place_name: 'North Willoughby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7956,
    longitude: 151.2007,
    accuracy: 4,
  },
  {
    postcode: 2069,
    place_name: 'Castle Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7865,
    longitude: 151.2092,
    accuracy: 4,
  },
  {
    postcode: 2069,
    place_name: 'Roseville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7833,
    longitude: 151.1833,
    accuracy: 4,
  },
  {
    postcode: 2069,
    place_name: 'Roseville Chase',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7785,
    longitude: 151.1968,
    accuracy: 4,
  },
  {
    postcode: 2070,
    place_name: 'East Lindfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7666,
    longitude: 151.1868,
    accuracy: 4,
  },
  {
    postcode: 2070,
    place_name: 'Lindfield West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7766,
    longitude: 151.17,
    accuracy: 3,
  },
  {
    postcode: 2070,
    place_name: 'Lindfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7833,
    longitude: 151.1667,
    accuracy: 4,
  },
  {
    postcode: 2071,
    place_name: 'Killara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.766,
    longitude: 151.1621,
    accuracy: 4,
  },
  {
    postcode: 2071,
    place_name: 'East Killara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7554,
    longitude: 151.1815,
    accuracy: 4,
  },
  {
    postcode: 2072,
    place_name: 'Gordon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7553,
    longitude: 151.1512,
    accuracy: 4,
  },
  {
    postcode: 2073,
    place_name: 'West Pymble',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 151.1333,
    accuracy: 4,
  },
  {
    postcode: 2073,
    place_name: 'Pymble',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7439,
    longitude: 151.1419,
    accuracy: 4,
  },
  {
    postcode: 2074,
    place_name: 'South Turramurra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7494,
    longitude: 151.1126,
    accuracy: 4,
  },
  {
    postcode: 2074,
    place_name: 'Warrawee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7288,
    longitude: 151.1205,
    accuracy: 4,
  },
  {
    postcode: 2074,
    place_name: 'North Turramurra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7131,
    longitude: 151.1464,
    accuracy: 4,
  },
  {
    postcode: 2074,
    place_name: 'Turramurra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7334,
    longitude: 151.1285,
    accuracy: 4,
  },
  {
    postcode: 2075,
    place_name: 'St Ives Chase',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7038,
    longitude: 151.1646,
    accuracy: 4,
  },
  {
    postcode: 2075,
    place_name: 'St Ives',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7209,
    longitude: 151.1708,
    accuracy: 3,
  },
  {
    postcode: 2076,
    place_name: 'Wahroonga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7182,
    longitude: 151.1156,
    accuracy: 4,
  },
  {
    postcode: 2076,
    place_name: 'North Wahroonga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7053,
    longitude: 151.1228,
    accuracy: 4,
  },
  {
    postcode: 2076,
    place_name: 'Normanhurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7333,
    longitude: 151.1,
    accuracy: 4,
  },
  {
    postcode: 2077,
    place_name: 'Waitara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7109,
    longitude: 151.1033,
    accuracy: 4,
  },
  {
    postcode: 2077,
    place_name: 'Hornsby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7024,
    longitude: 151.0993,
    accuracy: 4,
  },
  {
    postcode: 2077,
    place_name: 'Asquith',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6833,
    longitude: 151.1,
    accuracy: 4,
  },
  {
    postcode: 2077,
    place_name: 'Hornsby Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6713,
    longitude: 151.0943,
    accuracy: 4,
  },
  {
    postcode: 2079,
    place_name: 'Mount Colah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6833,
    longitude: 151.1167,
    accuracy: 4,
  },
  {
    postcode: 2080,
    place_name: 'Mount Kuring-Gai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.642,
    longitude: 151.1287,
    accuracy: 4,
  },
  {
    postcode: 2081,
    place_name: 'Berowra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6208,
    longitude: 151.1509,
    accuracy: 4,
  },
  {
    postcode: 2081,
    place_name: 'Cowan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5956,
    longitude: 151.1686,
    accuracy: 4,
  },
  {
    postcode: 2082,
    place_name: 'Berowra Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5827,
    longitude: 151.1221,
    accuracy: 4,
  },
  {
    postcode: 2082,
    place_name: 'Berowra Waters',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6009,
    longitude: 151.1264,
    accuracy: 4,
  },
  {
    postcode: 2082,
    place_name: 'Berowra Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6121,
    longitude: 151.1375,
    accuracy: 4,
  },
  {
    postcode: 2083,
    place_name: 'Cogra Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5198,
    longitude: 151.222,
    accuracy: 4,
  },
  {
    postcode: 2083,
    place_name: 'Dangar Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5386,
    longitude: 151.2415,
    accuracy: 4,
  },
  {
    postcode: 2083,
    place_name: 'Cheero Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5096,
    longitude: 151.1932,
    accuracy: 4,
  },
  {
    postcode: 2083,
    place_name: 'Bar Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5122,
    longitude: 151.1622,
    accuracy: 4,
  },
  {
    postcode: 2083,
    place_name: 'Brooklyn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.549,
    longitude: 151.2249,
    accuracy: 4,
  },
  {
    postcode: 2083,
    place_name: 'Milsons Passage',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5169,
    longitude: 151.1774,
    accuracy: 4,
  },
  {
    postcode: 2083,
    place_name: 'Mooney Mooney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5243,
    longitude: 151.201,
    accuracy: 4,
  },
  {
    postcode: 2084,
    place_name: 'Duffys Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6705,
    longitude: 151.196,
    accuracy: 4,
  },
  {
    postcode: 2084,
    place_name: 'Terrey Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6833,
    longitude: 151.2333,
    accuracy: 4,
  },
  {
    postcode: 2084,
    place_name: 'Cottage Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6197,
    longitude: 151.2036,
    accuracy: 4,
  },
  {
    postcode: 2085,
    place_name: 'Belrose West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7173,
    longitude: 151.232,
    accuracy: 3,
  },
  {
    postcode: 2085,
    place_name: 'Belrose',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7395,
    longitude: 151.2103,
    accuracy: 4,
  },
  {
    postcode: 2085,
    place_name: 'Davidson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.743,
    longitude: 151.2008,
    accuracy: 4,
  },
  {
    postcode: 2086,
    place_name: 'Frenchs Forest East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7482,
    longitude: 151.2232,
    accuracy: 3,
  },
  {
    postcode: 2086,
    place_name: 'Frenchs Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7482,
    longitude: 151.2232,
    accuracy: 4,
  },
  {
    postcode: 2087,
    place_name: 'Killarney Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7745,
    longitude: 151.2203,
    accuracy: 4,
  },
  {
    postcode: 2087,
    place_name: 'Forestville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 151.2083,
    accuracy: 4,
  },
  {
    postcode: 2088,
    place_name: 'Mosman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.839,
    longitude: 151.2396,
    accuracy: 4,
  },
  {
    postcode: 2088,
    place_name: 'Spit Junction',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8291,
    longitude: 151.2448,
    accuracy: 3,
  },
  {
    postcode: 2089,
    place_name: 'Kurraba Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8424,
    longitude: 151.2226,
    accuracy: 4,
  },
  {
    postcode: 2089,
    place_name: 'Neutral Bay Junction',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8358,
    longitude: 151.2183,
    accuracy: 3,
  },
  {
    postcode: 2089,
    place_name: 'Neutral Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8378,
    longitude: 151.2175,
    accuracy: 4,
  },
  {
    postcode: 2090,
    place_name: 'Cremorne Junction',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.83,
    longitude: 151.2267,
    accuracy: 3,
  },
  {
    postcode: 2090,
    place_name: 'Cremorne Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8401,
    longitude: 151.227,
    accuracy: 4,
  },
  {
    postcode: 2090,
    place_name: 'Cremorne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8344,
    longitude: 151.2268,
    accuracy: 4,
  },
  {
    postcode: 2091,
    place_name: 'Hmas Penguin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 151.2745,
    accuracy: 1,
  },
  {
    postcode: 2092,
    place_name: 'Seaforth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8014,
    longitude: 151.2398,
    accuracy: 4,
  },
  {
    postcode: 2093,
    place_name: 'Balgowlah Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8066,
    longitude: 151.2624,
    accuracy: 4,
  },
  {
    postcode: 2093,
    place_name: 'North Balgowlah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7863,
    longitude: 151.248,
    accuracy: 4,
  },
  {
    postcode: 2093,
    place_name: 'Balgowlah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7949,
    longitude: 151.2572,
    accuracy: 4,
  },
  {
    postcode: 2093,
    place_name: 'Manly Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 151.2745,
    accuracy: 3,
  },
  {
    postcode: 2093,
    place_name: 'Clontarf',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8055,
    longitude: 151.2537,
    accuracy: 4,
  },
  {
    postcode: 2094,
    place_name: 'Fairlight',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7957,
    longitude: 151.2735,
    accuracy: 4,
  },
  {
    postcode: 2095,
    place_name: 'Manly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.798,
    longitude: 151.2883,
    accuracy: 4,
  },
  {
    postcode: 2095,
    place_name: 'Manly East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.798,
    longitude: 151.2883,
    accuracy: 3,
  },
  {
    postcode: 2096,
    place_name: 'Curl Curl',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7689,
    longitude: 151.2889,
    accuracy: 4,
  },
  {
    postcode: 2096,
    place_name: 'Harbord',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 151.2745,
    accuracy: 3,
  },
  {
    postcode: 2096,
    place_name: 'Queenscliff',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7828,
    longitude: 151.285,
    accuracy: 4,
  },
  {
    postcode: 2096,
    place_name: 'Freshwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 151.2857,
    accuracy: 4,
  },
  {
    postcode: 2097,
    place_name: 'Collaroy Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.732,
    longitude: 151.3012,
    accuracy: 4,
  },
  {
    postcode: 2097,
    place_name: 'Wheeler Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7274,
    longitude: 151.2791,
    accuracy: 4,
  },
  {
    postcode: 2097,
    place_name: 'Collaroy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.732,
    longitude: 151.3012,
    accuracy: 4,
  },
  {
    postcode: 2097,
    place_name: 'Collaroy Plateau',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7293,
    longitude: 151.2865,
    accuracy: 4,
  },
  {
    postcode: 2099,
    place_name: 'Wingala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7416,
    longitude: 151.2762,
    accuracy: 3,
  },
  {
    postcode: 2099,
    place_name: 'Narraweena',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7552,
    longitude: 151.2766,
    accuracy: 4,
  },
  {
    postcode: 2099,
    place_name: 'Cromer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7312,
    longitude: 151.2679,
    accuracy: 4,
  },
  {
    postcode: 2099,
    place_name: 'Dee Why',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.75,
    longitude: 151.3,
    accuracy: 4,
  },
  {
    postcode: 2099,
    place_name: 'North Curl Curl',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7623,
    longitude: 151.2898,
    accuracy: 4,
  },
  {
    postcode: 2100,
    place_name: 'Brookvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7611,
    longitude: 151.2745,
    accuracy: 4,
  },
  {
    postcode: 2100,
    place_name: 'Warringah Mall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7646,
    longitude: 151.2561,
    accuracy: 3,
  },
  {
    postcode: 2100,
    place_name: 'Beacon Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7528,
    longitude: 151.2586,
    accuracy: 4,
  },
  {
    postcode: 2100,
    place_name: 'Oxford Falls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.739,
    longitude: 151.2444,
    accuracy: 4,
  },
  {
    postcode: 2100,
    place_name: 'Allambie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7666,
    longitude: 151.2498,
    accuracy: 4,
  },
  {
    postcode: 2100,
    place_name: 'North Manly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7756,
    longitude: 151.2692,
    accuracy: 4,
  },
  {
    postcode: 2100,
    place_name: 'Allambie Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7666,
    longitude: 151.2498,
    accuracy: 4,
  },
  {
    postcode: 2101,
    place_name: 'Elanora Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7016,
    longitude: 151.2797,
    accuracy: 4,
  },
  {
    postcode: 2101,
    place_name: 'Narrabeen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7128,
    longitude: 151.2974,
    accuracy: 4,
  },
  {
    postcode: 2101,
    place_name: 'North Narrabeen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7093,
    longitude: 151.2961,
    accuracy: 4,
  },
  {
    postcode: 2101,
    place_name: 'Ingleside',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6846,
    longitude: 151.2635,
    accuracy: 4,
  },
  {
    postcode: 2102,
    place_name: 'Warriewood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6886,
    longitude: 151.2953,
    accuracy: 4,
  },
  {
    postcode: 2102,
    place_name: 'Warriewood Shopping Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6793,
    longitude: 151.2936,
    accuracy: 3,
  },
  {
    postcode: 2103,
    place_name: 'Mona Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6776,
    longitude: 151.3031,
    accuracy: 4,
  },
  {
    postcode: 2104,
    place_name: 'Bayview',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6598,
    longitude: 151.2988,
    accuracy: 4,
  },
  {
    postcode: 2105,
    place_name: 'Church Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6451,
    longitude: 151.2837,
    accuracy: 4,
  },
  {
    postcode: 2105,
    place_name: 'Morning Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.629,
    longitude: 151.2867,
    accuracy: 4,
  },
  {
    postcode: 2105,
    place_name: 'Elvina Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6397,
    longitude: 151.2777,
    accuracy: 4,
  },
  {
    postcode: 2105,
    place_name: 'Lovett Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6499,
    longitude: 151.283,
    accuracy: 3,
  },
  {
    postcode: 2105,
    place_name: 'Scotland Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6407,
    longitude: 151.2875,
    accuracy: 4,
  },
  {
    postcode: 2105,
    place_name: 'Mccarrs Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6344,
    longitude: 151.2889,
    accuracy: 3,
  },
  {
    postcode: 2106,
    place_name: 'Newport',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6552,
    longitude: 151.3213,
    accuracy: 4,
  },
  {
    postcode: 2106,
    place_name: 'Newport Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6569,
    longitude: 151.3155,
    accuracy: 3,
  },
  {
    postcode: 2107,
    place_name: 'Clareville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6297,
    longitude: 151.3168,
    accuracy: 4,
  },
  {
    postcode: 2107,
    place_name: 'Bilgola Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6449,
    longitude: 151.3243,
    accuracy: 4,
  },
  {
    postcode: 2107,
    place_name: 'Whale Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6112,
    longitude: 151.3289,
    accuracy: 4,
  },
  {
    postcode: 2107,
    place_name: 'Bilgola Plateau',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6478,
    longitude: 151.3121,
    accuracy: 4,
  },
  {
    postcode: 2107,
    place_name: 'Careel Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6321,
    longitude: 151.3243,
    accuracy: 3,
  },
  {
    postcode: 2107,
    place_name: 'Avalon Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6359,
    longitude: 151.329,
    accuracy: 4,
  },
  {
    postcode: 2107,
    place_name: 'Bilgola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6321,
    longitude: 151.3243,
    accuracy: 3,
  },
  {
    postcode: 2108,
    place_name: 'Great Mackerel Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5961,
    longitude: 151.297,
    accuracy: 4,
  },
  {
    postcode: 2108,
    place_name: 'Palm Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5966,
    longitude: 151.3238,
    accuracy: 4,
  },
  {
    postcode: 2108,
    place_name: 'Currawong Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5968,
    longitude: 151.2972,
    accuracy: 4,
  },
  {
    postcode: 2108,
    place_name: 'Coasters Retreat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6061,
    longitude: 151.298,
    accuracy: 4,
  },
  {
    postcode: 2108,
    place_name: 'The Basin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6089,
    longitude: 151.2646,
    accuracy: 3,
  },
  {
    postcode: 2109,
    place_name: 'Macquarie University',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7716,
    longitude: 151.1138,
    accuracy: 1,
  },
  {
    postcode: 2110,
    place_name: 'Hunters Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8343,
    longitude: 151.1459,
    accuracy: 4,
  },
  {
    postcode: 2110,
    place_name: 'Woolwich',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8402,
    longitude: 151.1694,
    accuracy: 4,
  },
  {
    postcode: 2111,
    place_name: 'Henley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8434,
    longitude: 151.1356,
    accuracy: 4,
  },
  {
    postcode: 2111,
    place_name: 'Gladesville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 151.1333,
    accuracy: 4,
  },
  {
    postcode: 2111,
    place_name: 'Huntleys Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8405,
    longitude: 151.1402,
    accuracy: 4,
  },
  {
    postcode: 2111,
    place_name: 'Huntleys Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.838,
    longitude: 151.1379,
    accuracy: 4,
  },
  {
    postcode: 2111,
    place_name: 'Tennyson Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8317,
    longitude: 151.1168,
    accuracy: 4,
  },
  {
    postcode: 2111,
    place_name: 'Monash Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8295,
    longitude: 151.1275,
    accuracy: 3,
  },
  {
    postcode: 2111,
    place_name: 'Boronia Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8344,
    longitude: 151.1293,
    accuracy: 3,
  },
  {
    postcode: 2112,
    place_name: 'Ryde',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8188,
    longitude: 151.1062,
    accuracy: 4,
  },
  {
    postcode: 2112,
    place_name: 'Putney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8261,
    longitude: 151.1063,
    accuracy: 4,
  },
  {
    postcode: 2112,
    place_name: 'Denistone East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.796,
    longitude: 151.0974,
    accuracy: 4,
  },
  {
    postcode: 2113,
    place_name: 'Macquarie Centre',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7912,
    longitude: 151.1298,
    accuracy: 3,
  },
  {
    postcode: 2113,
    place_name: 'East Ryde',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8103,
    longitude: 151.1315,
    accuracy: 4,
  },
  {
    postcode: 2113,
    place_name: 'Macquarie Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.775,
    longitude: 151.1125,
    accuracy: 4,
  },
  {
    postcode: 2113,
    place_name: 'North Ryde',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7968,
    longitude: 151.1244,
    accuracy: 4,
  },
  {
    postcode: 2113,
    place_name: 'Blenheim Road',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7912,
    longitude: 151.1298,
    accuracy: 3,
  },
  {
    postcode: 2114,
    place_name: 'Meadowbank',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 151.0886,
    accuracy: 4,
  },
  {
    postcode: 2114,
    place_name: 'West Ryde',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8054,
    longitude: 151.0739,
    accuracy: 4,
  },
  {
    postcode: 2114,
    place_name: 'Denistone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7983,
    longitude: 151.0905,
    accuracy: 4,
  },
  {
    postcode: 2114,
    place_name: 'Denistone West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8005,
    longitude: 151.0762,
    accuracy: 4,
  },
  {
    postcode: 2114,
    place_name: 'Melrose Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8149,
    longitude: 151.0721,
    accuracy: 4,
  },
  {
    postcode: 2115,
    place_name: 'Ermington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8148,
    longitude: 151.0547,
    accuracy: 4,
  },
  {
    postcode: 2116,
    place_name: 'Rydalmere',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8145,
    longitude: 151.0375,
    accuracy: 4,
  },
  {
    postcode: 2117,
    place_name: 'Dundas',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8058,
    longitude: 151.0339,
    accuracy: 4,
  },
  {
    postcode: 2117,
    place_name: 'Dundas Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7883,
    longitude: 151.0526,
    accuracy: 4,
  },
  {
    postcode: 2117,
    place_name: 'Telopea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7928,
    longitude: 151.0386,
    accuracy: 4,
  },
  {
    postcode: 2117,
    place_name: 'Oatlands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.796,
    longitude: 151.0271,
    accuracy: 4,
  },
  {
    postcode: 2118,
    place_name: 'Carlingford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7827,
    longitude: 151.0489,
    accuracy: 4,
  },
  {
    postcode: 2118,
    place_name: 'Carlingford Court',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7776,
    longitude: 151.0418,
    accuracy: 3,
  },
  {
    postcode: 2118,
    place_name: 'Carlingford North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7722,
    longitude: 151.0172,
    accuracy: 4,
  },
  {
    postcode: 2118,
    place_name: 'Kingsdene',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7776,
    longitude: 151.0418,
    accuracy: 3,
  },
  {
    postcode: 2119,
    place_name: 'Beecroft',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7495,
    longitude: 151.0648,
    accuracy: 4,
  },
  {
    postcode: 2119,
    place_name: 'Cheltenham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7543,
    longitude: 151.065,
    accuracy: 3,
  },
  {
    postcode: 2120,
    place_name: 'Pennant Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7378,
    longitude: 151.0722,
    accuracy: 4,
  },
  {
    postcode: 2120,
    place_name: 'Thornleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7323,
    longitude: 151.079,
    accuracy: 4,
  },
  {
    postcode: 2120,
    place_name: 'Westleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7118,
    longitude: 151.0714,
    accuracy: 4,
  },
  {
    postcode: 2121,
    place_name: 'Epping',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7727,
    longitude: 151.0818,
    accuracy: 4,
  },
  {
    postcode: 2121,
    place_name: 'North Epping',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7594,
    longitude: 151.0925,
    accuracy: 4,
  },
  {
    postcode: 2122,
    place_name: 'Marsfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7778,
    longitude: 151.1057,
    accuracy: 4,
  },
  {
    postcode: 2122,
    place_name: 'Eastwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7918,
    longitude: 151.0806,
    accuracy: 4,
  },
  {
    postcode: 2123,
    place_name: 'Parramatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 151,
    accuracy: 1,
  },
  {
    postcode: 2124,
    place_name: 'Parramatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 151,
    accuracy: 1,
  },
  {
    postcode: 2125,
    place_name: 'West Pennant Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7457,
    longitude: 151.0476,
    accuracy: 4,
  },
  {
    postcode: 2126,
    place_name: 'Cherrybrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.722,
    longitude: 151.0461,
    accuracy: 4,
  },
  {
    postcode: 2127,
    place_name: 'Sydney Olympic Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8498,
    longitude: 151.0683,
    accuracy: 4,
  },
  {
    postcode: 2127,
    place_name: 'Wentworth Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8308,
    longitude: 151.0744,
    accuracy: 4,
  },
  {
    postcode: 2127,
    place_name: 'Homebush Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8626,
    longitude: 151.0913,
    accuracy: 4,
  },
  {
    postcode: 2127,
    place_name: 'Newington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8349,
    longitude: 151.057,
    accuracy: 4,
  },
  {
    postcode: 2128,
    place_name: 'Silverwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8334,
    longitude: 151.0473,
    accuracy: 4,
  },
  {
    postcode: 2129,
    place_name: 'Sydney Markets',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7838,
    longitude: 151.0564,
    accuracy: 1,
  },
  {
    postcode: 2130,
    place_name: 'Summer Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8915,
    longitude: 151.1382,
    accuracy: 4,
  },
  {
    postcode: 2131,
    place_name: 'Ashfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8883,
    longitude: 151.1227,
    accuracy: 4,
  },
  {
    postcode: 2132,
    place_name: 'Croydon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8833,
    longitude: 151.1167,
    accuracy: 4,
  },
  {
    postcode: 2133,
    place_name: 'Enfield South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8972,
    longitude: 151.1032,
    accuracy: 1,
  },
  {
    postcode: 2133,
    place_name: 'Croydon Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8972,
    longitude: 151.1032,
    accuracy: 1,
  },
  {
    postcode: 2134,
    place_name: 'Burwood North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8833,
    longitude: 151.1,
    accuracy: 3,
  },
  {
    postcode: 2134,
    place_name: 'Burwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8833,
    longitude: 151.1,
    accuracy: 4,
  },
  {
    postcode: 2135,
    place_name: 'Strathfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.881,
    longitude: 151.0799,
    accuracy: 4,
  },
  {
    postcode: 2136,
    place_name: 'Strathfield South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8925,
    longitude: 151.0833,
    accuracy: 4,
  },
  {
    postcode: 2136,
    place_name: 'Burwood Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8905,
    longitude: 151.1007,
    accuracy: 4,
  },
  {
    postcode: 2136,
    place_name: 'Enfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8657,
    longitude: 151.1042,
    accuracy: 3,
  },
  {
    postcode: 2137,
    place_name: 'Mortlake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8422,
    longitude: 151.1072,
    accuracy: 4,
  },
  {
    postcode: 2137,
    place_name: 'Breakfast Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8429,
    longitude: 151.1107,
    accuracy: 4,
  },
  {
    postcode: 2137,
    place_name: 'Concord',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8472,
    longitude: 151.1038,
    accuracy: 4,
  },
  {
    postcode: 2137,
    place_name: 'Cabarita',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8472,
    longitude: 151.1164,
    accuracy: 4,
  },
  {
    postcode: 2137,
    place_name: 'North Strathfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8569,
    longitude: 151.0912,
    accuracy: 4,
  },
  {
    postcode: 2138,
    place_name: 'Concord West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8481,
    longitude: 151.0861,
    accuracy: 4,
  },
  {
    postcode: 2138,
    place_name: 'Rhodes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8266,
    longitude: 151.0881,
    accuracy: 4,
  },
  {
    postcode: 2138,
    place_name: 'Liberty Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8409,
    longitude: 151.084,
    accuracy: 4,
  },
  {
    postcode: 2139,
    place_name: 'Concord Repatriation Hospital',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8657,
    longitude: 151.1042,
    accuracy: 1,
  },
  {
    postcode: 2140,
    place_name: 'Homebush',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8667,
    longitude: 151.0833,
    accuracy: 4,
  },
  {
    postcode: 2140,
    place_name: 'Homebush South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8667,
    longitude: 151.0833,
    accuracy: 3,
  },
  {
    postcode: 2140,
    place_name: 'Homebush West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8626,
    longitude: 151.0913,
    accuracy: 4,
  },
  {
    postcode: 2141,
    place_name: 'Rookwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8742,
    longitude: 151.0557,
    accuracy: 4,
  },
  {
    postcode: 2141,
    place_name: 'Lidcombe North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8693,
    longitude: 151.0477,
    accuracy: 3,
  },
  {
    postcode: 2141,
    place_name: 'Berala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.872,
    longitude: 151.0339,
    accuracy: 4,
  },
  {
    postcode: 2141,
    place_name: 'Lidcombe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8644,
    longitude: 151.0397,
    accuracy: 4,
  },
  {
    postcode: 2142,
    place_name: 'Camellia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8197,
    longitude: 151.0264,
    accuracy: 4,
  },
  {
    postcode: 2142,
    place_name: 'Blaxcell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8332,
    longitude: 151.0198,
    accuracy: 3,
  },
  {
    postcode: 2142,
    place_name: 'Granville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2142,
    place_name: 'South Granville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2142,
    place_name: 'Rosehill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8201,
    longitude: 151.0245,
    accuracy: 4,
  },
  {
    postcode: 2142,
    place_name: 'Clyde',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2142,
    place_name: 'Holroyd',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 150.95,
    accuracy: 4,
  },
  {
    postcode: 2143,
    place_name: 'Birrong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8925,
    longitude: 151.0207,
    accuracy: 4,
  },
  {
    postcode: 2143,
    place_name: 'Potts Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8931,
    longitude: 151.0337,
    accuracy: 4,
  },
  {
    postcode: 2143,
    place_name: 'Regents Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8833,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2144,
    place_name: 'Auburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.85,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 2145,
    place_name: 'Wentworthville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 150.9667,
    accuracy: 4,
  },
  {
    postcode: 2145,
    place_name: 'Girraween',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.799,
    longitude: 150.943,
    accuracy: 4,
  },
  {
    postcode: 2145,
    place_name: 'Pemulwuy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8227,
    longitude: 150.9239,
    accuracy: 4,
  },
  {
    postcode: 2145,
    place_name: 'Westmead',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8038,
    longitude: 150.9877,
    accuracy: 4,
  },
  {
    postcode: 2145,
    place_name: 'Greystanes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8235,
    longitude: 150.9461,
    accuracy: 4,
  },
  {
    postcode: 2145,
    place_name: 'Mays Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8203,
    longitude: 150.9943,
    accuracy: 4,
  },
  {
    postcode: 2145,
    place_name: 'South Wentworthville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8185,
    longitude: 150.9634,
    accuracy: 4,
  },
  {
    postcode: 2145,
    place_name: 'Pendle Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8,
    longitude: 150.95,
    accuracy: 4,
  },
  {
    postcode: 2145,
    place_name: 'Constitution Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7926,
    longitude: 150.9763,
    accuracy: 4,
  },
  {
    postcode: 2146,
    place_name: 'Toongabbie East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7898,
    longitude: 150.9562,
    accuracy: 3,
  },
  {
    postcode: 2146,
    place_name: 'Toongabbie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7833,
    longitude: 150.95,
    accuracy: 4,
  },
  {
    postcode: 2146,
    place_name: 'Old Toongabbie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7876,
    longitude: 150.969,
    accuracy: 4,
  },
  {
    postcode: 2147,
    place_name: 'Lalor Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7609,
    longitude: 150.9312,
    accuracy: 4,
  },
  {
    postcode: 2147,
    place_name: 'Seven Hills West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7684,
    longitude: 150.9365,
    accuracy: 3,
  },
  {
    postcode: 2147,
    place_name: 'Seven Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7833,
    longitude: 150.9333,
    accuracy: 4,
  },
  {
    postcode: 2147,
    place_name: 'Kings Langley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7501,
    longitude: 150.9354,
    accuracy: 4,
  },
  {
    postcode: 2148,
    place_name: 'Prospect',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8015,
    longitude: 150.9136,
    accuracy: 4,
  },
  {
    postcode: 2148,
    place_name: 'Kings Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7432,
    longitude: 150.9076,
    accuracy: 4,
  },
  {
    postcode: 2148,
    place_name: 'Marayong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7482,
    longitude: 150.8927,
    accuracy: 4,
  },
  {
    postcode: 2148,
    place_name: 'Huntingwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7975,
    longitude: 150.8799,
    accuracy: 4,
  },
  {
    postcode: 2148,
    place_name: 'Blacktown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 150.9167,
    accuracy: 4,
  },
  {
    postcode: 2148,
    place_name: 'Blacktown Westpoint',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7896,
    longitude: 150.9,
    accuracy: 3,
  },
  {
    postcode: 2148,
    place_name: 'Arndell Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7893,
    longitude: 150.8816,
    accuracy: 4,
  },
  {
    postcode: 2150,
    place_name: 'Parramatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8197,
    longitude: 151.0067,
    accuracy: 3,
  },
  {
    postcode: 2150,
    place_name: 'Harris Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8229,
    longitude: 151.0078,
    accuracy: 4,
  },
  {
    postcode: 2150,
    place_name: 'Parramatta Westfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8197,
    longitude: 151.0067,
    accuracy: 3,
  },
  {
    postcode: 2151,
    place_name: 'North Parramatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7935,
    longitude: 151.0012,
    accuracy: 4,
  },
  {
    postcode: 2151,
    place_name: 'North Rocks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7722,
    longitude: 151.0172,
    accuracy: 4,
  },
  {
    postcode: 2152,
    place_name: 'Northmead',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7833,
    longitude: 150.9833,
    accuracy: 4,
  },
  {
    postcode: 2153,
    place_name: 'Winston Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7764,
    longitude: 150.9805,
    accuracy: 4,
  },
  {
    postcode: 2153,
    place_name: 'Bella Vista',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7413,
    longitude: 150.9546,
    accuracy: 4,
  },
  {
    postcode: 2153,
    place_name: 'Baulkham Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7588,
    longitude: 150.9929,
    accuracy: 4,
  },
  {
    postcode: 2154,
    place_name: 'Castle Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7333,
    longitude: 151,
    accuracy: 4,
  },
  {
    postcode: 2155,
    place_name: 'Rouse Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6821,
    longitude: 150.9154,
    accuracy: 4,
  },
  {
    postcode: 2155,
    place_name: 'Beaumont Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6999,
    longitude: 150.9411,
    accuracy: 4,
  },
  {
    postcode: 2155,
    place_name: 'Kellyville Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7013,
    longitude: 150.9231,
    accuracy: 4,
  },
  {
    postcode: 2155,
    place_name: 'Kellyville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6994,
    longitude: 150.9536,
    accuracy: 4,
  },
  {
    postcode: 2156,
    place_name: 'Annangrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6576,
    longitude: 150.9476,
    accuracy: 4,
  },
  {
    postcode: 2156,
    place_name: 'Glenhaven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7,
    longitude: 151,
    accuracy: 4,
  },
  {
    postcode: 2156,
    place_name: 'Kenthurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6553,
    longitude: 151.005,
    accuracy: 4,
  },
  {
    postcode: 2157,
    place_name: 'Glenorie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6018,
    longitude: 151.0089,
    accuracy: 4,
  },
  {
    postcode: 2157,
    place_name: 'Forest Glen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5502,
    longitude: 151.0138,
    accuracy: 4,
  },
  {
    postcode: 2157,
    place_name: 'Canoelands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5,
    longitude: 151.0667,
    accuracy: 4,
  },
  {
    postcode: 2158,
    place_name: 'Round Corner',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6771,
    longitude: 151.0339,
    accuracy: 3,
  },
  {
    postcode: 2158,
    place_name: 'Dural',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6833,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2158,
    place_name: 'Middle Dural',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.646,
    longitude: 151.0205,
    accuracy: 4,
  },
  {
    postcode: 2159,
    place_name: 'Galston',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.653,
    longitude: 151.0471,
    accuracy: 4,
  },
  {
    postcode: 2159,
    place_name: 'Arcadia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6167,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 2159,
    place_name: 'Berrilee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6155,
    longitude: 151.0914,
    accuracy: 4,
  },
  {
    postcode: 2159,
    place_name: 'Fiddletown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6,
    longitude: 151.05,
    accuracy: 4,
  },
  {
    postcode: 2160,
    place_name: 'Merrylands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 150.9833,
    accuracy: 4,
  },
  {
    postcode: 2160,
    place_name: 'Merrylands West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8327,
    longitude: 150.9691,
    accuracy: 4,
  },
  {
    postcode: 2161,
    place_name: 'Guildford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8643,
    longitude: 150.9839,
    accuracy: 3,
  },
  {
    postcode: 2161,
    place_name: 'Old Guildford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8643,
    longitude: 150.9839,
    accuracy: 4,
  },
  {
    postcode: 2161,
    place_name: 'Yennora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8617,
    longitude: 150.9686,
    accuracy: 4,
  },
  {
    postcode: 2161,
    place_name: 'Guildford West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8524,
    longitude: 150.974,
    accuracy: 4,
  },
  {
    postcode: 2162,
    place_name: 'Chester Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.879,
    longitude: 150.9995,
    accuracy: 4,
  },
  {
    postcode: 2162,
    place_name: 'Sefton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8877,
    longitude: 151.0105,
    accuracy: 4,
  },
  {
    postcode: 2163,
    place_name: 'Lansdowne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.896,
    longitude: 150.9756,
    accuracy: 4,
  },
  {
    postcode: 2163,
    place_name: 'Carramar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8838,
    longitude: 150.9615,
    accuracy: 4,
  },
  {
    postcode: 2163,
    place_name: 'Villawood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8833,
    longitude: 150.9667,
    accuracy: 4,
  },
  {
    postcode: 2164,
    place_name: 'Smithfield West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8667,
    longitude: 150.917,
    accuracy: 4,
  },
  {
    postcode: 2164,
    place_name: 'Smithfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.85,
    longitude: 150.9333,
    accuracy: 4,
  },
  {
    postcode: 2164,
    place_name: 'Woodpark',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8414,
    longitude: 150.9605,
    accuracy: 4,
  },
  {
    postcode: 2164,
    place_name: 'Wetherill Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8428,
    longitude: 150.9006,
    accuracy: 4,
  },
  {
    postcode: 2165,
    place_name: 'Fairfield West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8667,
    longitude: 150.917,
    accuracy: 4,
  },
  {
    postcode: 2165,
    place_name: 'Fairfield East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8694,
    longitude: 150.9713,
    accuracy: 4,
  },
  {
    postcode: 2165,
    place_name: 'Fairfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8667,
    longitude: 150.95,
    accuracy: 4,
  },
  {
    postcode: 2165,
    place_name: 'Fairfield Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8644,
    longitude: 150.9388,
    accuracy: 4,
  },
  {
    postcode: 2166,
    place_name: 'Canley Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8866,
    longitude: 150.948,
    accuracy: 4,
  },
  {
    postcode: 2166,
    place_name: 'Cabramatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8974,
    longitude: 150.9345,
    accuracy: 4,
  },
  {
    postcode: 2166,
    place_name: 'Cabramatta West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8967,
    longitude: 150.9106,
    accuracy: 4,
  },
  {
    postcode: 2166,
    place_name: 'Lansvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9014,
    longitude: 150.9637,
    accuracy: 4,
  },
  {
    postcode: 2166,
    place_name: 'Canley Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8826,
    longitude: 150.9241,
    accuracy: 4,
  },
  {
    postcode: 2167,
    place_name: 'Glenfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9667,
    longitude: 150.9,
    accuracy: 4,
  },
  {
    postcode: 2168,
    place_name: 'Green Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9029,
    longitude: 150.8671,
    accuracy: 4,
  },
  {
    postcode: 2168,
    place_name: 'Ashcroft',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.916,
    longitude: 150.8999,
    accuracy: 4,
  },
  {
    postcode: 2168,
    place_name: 'Heckenberg',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9083,
    longitude: 150.8898,
    accuracy: 4,
  },
  {
    postcode: 2168,
    place_name: 'Busby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9112,
    longitude: 150.8807,
    accuracy: 4,
  },
  {
    postcode: 2168,
    place_name: 'Cartwright',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.925,
    longitude: 150.8895,
    accuracy: 4,
  },
  {
    postcode: 2168,
    place_name: 'Miller',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9209,
    longitude: 150.8847,
    accuracy: 4,
  },
  {
    postcode: 2168,
    place_name: 'Hinchinbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9188,
    longitude: 150.8631,
    accuracy: 4,
  },
  {
    postcode: 2168,
    place_name: 'Sadleir',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9171,
    longitude: 150.8909,
    accuracy: 4,
  },
  {
    postcode: 2170,
    place_name: 'Casula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9522,
    longitude: 150.8995,
    accuracy: 4,
  },
  {
    postcode: 2170,
    place_name: 'Lurnea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9354,
    longitude: 150.8967,
    accuracy: 4,
  },
  {
    postcode: 2170,
    place_name: 'Hammondville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9483,
    longitude: 150.9521,
    accuracy: 4,
  },
  {
    postcode: 2170,
    place_name: 'Liverpool South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9,
    longitude: 150.9333,
    accuracy: 3,
  },
  {
    postcode: 2170,
    place_name: 'Chipping Norton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9,
    longitude: 150.95,
    accuracy: 4,
  },
  {
    postcode: 2170,
    place_name: 'Casula Mall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.934,
    longitude: 150.9122,
    accuracy: 3,
  },
  {
    postcode: 2170,
    place_name: 'Mount Pritchard',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.903,
    longitude: 150.9046,
    accuracy: 4,
  },
  {
    postcode: 2170,
    place_name: 'Liverpool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9,
    longitude: 150.9333,
    accuracy: 4,
  },
  {
    postcode: 2170,
    place_name: 'Moorebank',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9425,
    longitude: 150.9377,
    accuracy: 4,
  },
  {
    postcode: 2170,
    place_name: 'Liverpool Westfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9239,
    longitude: 150.9224,
    accuracy: 3,
  },
  {
    postcode: 2170,
    place_name: 'Prestons',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9419,
    longitude: 150.8717,
    accuracy: 4,
  },
  {
    postcode: 2170,
    place_name: 'Warwick Farm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9129,
    longitude: 150.937,
    accuracy: 4,
  },
  {
    postcode: 2171,
    place_name: 'Hoxton Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9313,
    longitude: 150.8541,
    accuracy: 4,
  },
  {
    postcode: 2171,
    place_name: 'Carnes Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9385,
    longitude: 150.8488,
    accuracy: 4,
  },
  {
    postcode: 2171,
    place_name: 'Cecil Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8892,
    longitude: 150.8514,
    accuracy: 4,
  },
  {
    postcode: 2171,
    place_name: 'West Hoxton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 150.85,
    accuracy: 4,
  },
  {
    postcode: 2171,
    place_name: 'Len Waters Estate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9201,
    longitude: 150.8502,
    accuracy: 3,
  },
  {
    postcode: 2171,
    place_name: 'Horningsea Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9434,
    longitude: 150.8453,
    accuracy: 4,
  },
  {
    postcode: 2171,
    place_name: 'Elizabeth Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9201,
    longitude: 150.8502,
    accuracy: 3,
  },
  {
    postcode: 2171,
    place_name: 'Middleton Grange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.917,
    longitude: 150.8419,
    accuracy: 4,
  },
  {
    postcode: 2172,
    place_name: 'Sandy Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0628,
    longitude: 149.8635,
    accuracy: 4,
  },
  {
    postcode: 2172,
    place_name: 'Voyager Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9587,
    longitude: 150.9742,
    accuracy: 4,
  },
  {
    postcode: 2172,
    place_name: 'Pleasure Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9699,
    longitude: 150.9839,
    accuracy: 4,
  },
  {
    postcode: 2173,
    place_name: 'Holsworthy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9833,
    longitude: 150.9667,
    accuracy: 4,
  },
  {
    postcode: 2173,
    place_name: 'Wattle Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9544,
    longitude: 150.9445,
    accuracy: 4,
  },
  {
    postcode: 2174,
    place_name: 'Edmondson Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9569,
    longitude: 150.8613,
    accuracy: 4,
  },
  {
    postcode: 2175,
    place_name: 'Horsley Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8432,
    longitude: 150.8489,
    accuracy: 4,
  },
  {
    postcode: 2176,
    place_name: 'Bossley Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8618,
    longitude: 150.8841,
    accuracy: 4,
  },
  {
    postcode: 2176,
    place_name: 'Abbotsbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8701,
    longitude: 150.8612,
    accuracy: 4,
  },
  {
    postcode: 2176,
    place_name: 'St Johns Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.883,
    longitude: 150.9018,
    accuracy: 4,
  },
  {
    postcode: 2176,
    place_name: 'Greenfield Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8746,
    longitude: 150.8919,
    accuracy: 4,
  },
  {
    postcode: 2176,
    place_name: 'Prairiewood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8632,
    longitude: 150.9052,
    accuracy: 4,
  },
  {
    postcode: 2176,
    place_name: 'Wakeley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8743,
    longitude: 150.9098,
    accuracy: 4,
  },
  {
    postcode: 2176,
    place_name: 'Edensor Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8797,
    longitude: 150.8778,
    accuracy: 4,
  },
  {
    postcode: 2177,
    place_name: 'Bonnyrigg Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8912,
    longitude: 150.8699,
    accuracy: 4,
  },
  {
    postcode: 2177,
    place_name: 'Bonnyrigg',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8936,
    longitude: 150.8891,
    accuracy: 4,
  },
  {
    postcode: 2178,
    place_name: 'Kemps Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8806,
    longitude: 150.7876,
    accuracy: 4,
  },
  {
    postcode: 2178,
    place_name: 'Mount Vernon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8604,
    longitude: 150.8101,
    accuracy: 4,
  },
  {
    postcode: 2178,
    place_name: 'Cecil Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8743,
    longitude: 150.8418,
    accuracy: 4,
  },
  {
    postcode: 2179,
    place_name: 'Austral',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9274,
    longitude: 150.8081,
    accuracy: 4,
  },
  {
    postcode: 2179,
    place_name: 'Leppington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9698,
    longitude: 150.7967,
    accuracy: 4,
  },
  {
    postcode: 2190,
    place_name: 'Mount Lewis',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9175,
    longitude: 151.0483,
    accuracy: 4,
  },
  {
    postcode: 2190,
    place_name: 'Greenacre',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9003,
    longitude: 151.0556,
    accuracy: 4,
  },
  {
    postcode: 2190,
    place_name: 'Chullora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9,
    longitude: 151.05,
    accuracy: 4,
  },
  {
    postcode: 2191,
    place_name: 'Belfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9035,
    longitude: 151.0847,
    accuracy: 4,
  },
  {
    postcode: 2192,
    place_name: 'Belmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.919,
    longitude: 151.0894,
    accuracy: 4,
  },
  {
    postcode: 2193,
    place_name: 'Hurlstone Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9099,
    longitude: 151.1287,
    accuracy: 4,
  },
  {
    postcode: 2193,
    place_name: 'Canterbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9119,
    longitude: 151.1186,
    accuracy: 4,
  },
  {
    postcode: 2193,
    place_name: 'Ashbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8978,
    longitude: 151.1196,
    accuracy: 4,
  },
  {
    postcode: 2194,
    place_name: 'Campsie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9125,
    longitude: 151.1028,
    accuracy: 4,
  },
  {
    postcode: 2195,
    place_name: 'Wiley Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9242,
    longitude: 151.0674,
    accuracy: 4,
  },
  {
    postcode: 2195,
    place_name: 'Lakemba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9197,
    longitude: 151.0759,
    accuracy: 4,
  },
  {
    postcode: 2196,
    place_name: 'Roselands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9332,
    longitude: 151.0732,
    accuracy: 4,
  },
  {
    postcode: 2196,
    place_name: 'Punchbowl',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9289,
    longitude: 151.0511,
    accuracy: 4,
  },
  {
    postcode: 2197,
    place_name: 'Bass Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8986,
    longitude: 150.9954,
    accuracy: 4,
  },
  {
    postcode: 2198,
    place_name: 'Georges Hall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9088,
    longitude: 150.9885,
    accuracy: 4,
  },
  {
    postcode: 2199,
    place_name: 'Yagoona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9049,
    longitude: 151.02,
    accuracy: 4,
  },
  {
    postcode: 2199,
    place_name: 'Yagoona West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9021,
    longitude: 151.0223,
    accuracy: 3,
  },
  {
    postcode: 2200,
    place_name: 'Condell Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9246,
    longitude: 151.0109,
    accuracy: 4,
  },
  {
    postcode: 2200,
    place_name: 'Mount Lewis',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9175,
    longitude: 151.0483,
    accuracy: 4,
  },
  {
    postcode: 2200,
    place_name: 'Bankstown Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9224,
    longitude: 151.0231,
    accuracy: 3,
  },
  {
    postcode: 2200,
    place_name: 'Bankstown Aerodrome',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9206,
    longitude: 151.0221,
    accuracy: 3,
  },
  {
    postcode: 2200,
    place_name: 'Bankstown North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9224,
    longitude: 151.0231,
    accuracy: 3,
  },
  {
    postcode: 2200,
    place_name: 'Manahan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9224,
    longitude: 151.0231,
    accuracy: 3,
  },
  {
    postcode: 2200,
    place_name: 'Bankstown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 2203,
    place_name: 'Dulwich Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9041,
    longitude: 151.1394,
    accuracy: 4,
  },
  {
    postcode: 2204,
    place_name: 'Marrickville Metro',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9112,
    longitude: 151.1557,
    accuracy: 3,
  },
  {
    postcode: 2204,
    place_name: 'Marrickville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9032,
    longitude: 151.1518,
    accuracy: 4,
  },
  {
    postcode: 2204,
    place_name: 'Marrickville South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9032,
    longitude: 151.1518,
    accuracy: 3,
  },
  {
    postcode: 2205,
    place_name: 'Turrella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9303,
    longitude: 151.1421,
    accuracy: 4,
  },
  {
    postcode: 2205,
    place_name: 'Wolli Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9313,
    longitude: 151.1522,
    accuracy: 4,
  },
  {
    postcode: 2205,
    place_name: 'Arncliffe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9363,
    longitude: 151.1482,
    accuracy: 4,
  },
  {
    postcode: 2206,
    place_name: 'Earlwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.95,
    longitude: 151.1,
    accuracy: 4,
  },
  {
    postcode: 2206,
    place_name: 'Undercliffe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9331,
    longitude: 151.1111,
    accuracy: 3,
  },
  {
    postcode: 2206,
    place_name: 'Clemton Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9348,
    longitude: 151.0905,
    accuracy: 4,
  },
  {
    postcode: 2207,
    place_name: 'Bexley South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.95,
    longitude: 151.1167,
    accuracy: 3,
  },
  {
    postcode: 2207,
    place_name: 'Bexley North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9385,
    longitude: 151.1139,
    accuracy: 4,
  },
  {
    postcode: 2207,
    place_name: 'Bexley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.95,
    longitude: 151.1167,
    accuracy: 4,
  },
  {
    postcode: 2207,
    place_name: 'Bardwell Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9353,
    longitude: 151.1257,
    accuracy: 4,
  },
  {
    postcode: 2207,
    place_name: 'Bardwell Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9334,
    longitude: 151.1367,
    accuracy: 4,
  },
  {
    postcode: 2208,
    place_name: 'Kingsway West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9331,
    longitude: 151.1111,
    accuracy: 3,
  },
  {
    postcode: 2208,
    place_name: 'Kingsgrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9393,
    longitude: 151.0993,
    accuracy: 4,
  },
  {
    postcode: 2209,
    place_name: 'Beverly Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.948,
    longitude: 151.0798,
    accuracy: 4,
  },
  {
    postcode: 2209,
    place_name: 'Narwee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9465,
    longitude: 151.0692,
    accuracy: 4,
  },
  {
    postcode: 2210,
    place_name: 'Peakhurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9629,
    longitude: 151.0516,
    accuracy: 4,
  },
  {
    postcode: 2210,
    place_name: 'Peakhurst Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9774,
    longitude: 151.0556,
    accuracy: 4,
  },
  {
    postcode: 2210,
    place_name: 'Lugarno',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9827,
    longitude: 151.0418,
    accuracy: 4,
  },
  {
    postcode: 2210,
    place_name: 'Riverwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9473,
    longitude: 151.0497,
    accuracy: 4,
  },
  {
    postcode: 2211,
    place_name: 'Padstow Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9674,
    longitude: 151.0399,
    accuracy: 4,
  },
  {
    postcode: 2211,
    place_name: 'Padstow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9569,
    longitude: 151.0319,
    accuracy: 4,
  },
  {
    postcode: 2212,
    place_name: 'Revesby Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9671,
    longitude: 151.0184,
    accuracy: 4,
  },
  {
    postcode: 2212,
    place_name: 'Revesby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.95,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2212,
    place_name: 'Revesby North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9498,
    longitude: 151.0148,
    accuracy: 3,
  },
  {
    postcode: 2213,
    place_name: 'Panania',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9537,
    longitude: 150.9973,
    accuracy: 4,
  },
  {
    postcode: 2213,
    place_name: 'East Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9667,
    longitude: 150.9833,
    accuracy: 4,
  },
  {
    postcode: 2213,
    place_name: 'Picnic Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9806,
    longitude: 150.9966,
    accuracy: 4,
  },
  {
    postcode: 2214,
    place_name: 'Milperra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9393,
    longitude: 150.9815,
    accuracy: 4,
  },
  {
    postcode: 2216,
    place_name: 'Rockdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.95,
    longitude: 151.1333,
    accuracy: 4,
  },
  {
    postcode: 2216,
    place_name: 'Kyeemagh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9513,
    longitude: 151.162,
    accuracy: 4,
  },
  {
    postcode: 2216,
    place_name: 'Brighton-Le-Sands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9601,
    longitude: 151.1511,
    accuracy: 4,
  },
  {
    postcode: 2216,
    place_name: 'Banksia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.946,
    longitude: 151.143,
    accuracy: 4,
  },
  {
    postcode: 2217,
    place_name: 'Beverley Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9736,
    longitude: 151.1373,
    accuracy: 3,
  },
  {
    postcode: 2217,
    place_name: 'Ramsgate Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9852,
    longitude: 151.1463,
    accuracy: 4,
  },
  {
    postcode: 2217,
    place_name: 'Kogarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9833,
    longitude: 151.1167,
    accuracy: 4,
  },
  {
    postcode: 2217,
    place_name: 'Kogarah Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9795,
    longitude: 151.1212,
    accuracy: 4,
  },
  {
    postcode: 2217,
    place_name: 'Monterey',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9725,
    longitude: 151.1481,
    accuracy: 4,
  },
  {
    postcode: 2217,
    place_name: 'Ramsgate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9822,
    longitude: 151.14,
    accuracy: 4,
  },
  {
    postcode: 2218,
    place_name: 'Allawah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9726,
    longitude: 151.1144,
    accuracy: 4,
  },
  {
    postcode: 2218,
    place_name: 'Carlton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9711,
    longitude: 151.1214,
    accuracy: 4,
  },
  {
    postcode: 2219,
    place_name: 'Dolls Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.995,
    longitude: 151.1455,
    accuracy: 4,
  },
  {
    postcode: 2219,
    place_name: 'Sans Souci',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9979,
    longitude: 151.1293,
    accuracy: 4,
  },
  {
    postcode: 2219,
    place_name: 'Sandringham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9956,
    longitude: 151.14,
    accuracy: 4,
  },
  {
    postcode: 2220,
    place_name: 'Hurstville Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9799,
    longitude: 151.0903,
    accuracy: 4,
  },
  {
    postcode: 2220,
    place_name: 'Hurstville Westfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9642,
    longitude: 151.0991,
    accuracy: 3,
  },
  {
    postcode: 2220,
    place_name: 'Hurstville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9799,
    longitude: 151.0903,
    accuracy: 4,
  },
  {
    postcode: 2221,
    place_name: 'Blakehurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9915,
    longitude: 151.1088,
    accuracy: 4,
  },
  {
    postcode: 2221,
    place_name: 'Kyle Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9879,
    longitude: 151.0994,
    accuracy: 4,
  },
  {
    postcode: 2221,
    place_name: 'Carss Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9867,
    longitude: 151.1173,
    accuracy: 4,
  },
  {
    postcode: 2221,
    place_name: 'South Hurstville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9776,
    longitude: 151.1055,
    accuracy: 4,
  },
  {
    postcode: 2221,
    place_name: 'Connells Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9883,
    longitude: 151.0909,
    accuracy: 4,
  },
  {
    postcode: 2222,
    place_name: 'Penshurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9667,
    longitude: 151.0833,
    accuracy: 4,
  },
  {
    postcode: 2223,
    place_name: 'Mortdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9667,
    longitude: 151.0833,
    accuracy: 4,
  },
  {
    postcode: 2223,
    place_name: 'Oatley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.98,
    longitude: 151.072,
    accuracy: 4,
  },
  {
    postcode: 2224,
    place_name: 'Kangaroo Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0026,
    longitude: 151.0959,
    accuracy: 4,
  },
  {
    postcode: 2224,
    place_name: 'Sylvania',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0124,
    longitude: 151.0972,
    accuracy: 4,
  },
  {
    postcode: 2224,
    place_name: 'Sylvania Southgate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.01,
    longitude: 151.0947,
    accuracy: 3,
  },
  {
    postcode: 2224,
    place_name: 'Sylvania Waters',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0184,
    longitude: 151.1056,
    accuracy: 4,
  },
  {
    postcode: 2225,
    place_name: 'Oyster Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0041,
    longitude: 151.0759,
    accuracy: 4,
  },
  {
    postcode: 2226,
    place_name: 'Jannali',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0164,
    longitude: 151.0607,
    accuracy: 4,
  },
  {
    postcode: 2226,
    place_name: 'Como',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9994,
    longitude: 151.0639,
    accuracy: 4,
  },
  {
    postcode: 2226,
    place_name: 'Bonnet Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0083,
    longitude: 151.0519,
    accuracy: 4,
  },
  {
    postcode: 2227,
    place_name: 'Gymea Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0521,
    longitude: 151.088,
    accuracy: 4,
  },
  {
    postcode: 2227,
    place_name: 'Gymea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0364,
    longitude: 151.0853,
    accuracy: 4,
  },
  {
    postcode: 2228,
    place_name: 'Miranda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0386,
    longitude: 151.1001,
    accuracy: 4,
  },
  {
    postcode: 2228,
    place_name: 'Yowie Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0512,
    longitude: 151.1023,
    accuracy: 4,
  },
  {
    postcode: 2229,
    place_name: 'Taren Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0194,
    longitude: 151.1183,
    accuracy: 4,
  },
  {
    postcode: 2229,
    place_name: 'Caringbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0473,
    longitude: 151.1205,
    accuracy: 4,
  },
  {
    postcode: 2229,
    place_name: 'Caringbah South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0566,
    longitude: 151.1215,
    accuracy: 4,
  },
  {
    postcode: 2229,
    place_name: 'Port Hacking',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0679,
    longitude: 151.1256,
    accuracy: 4,
  },
  {
    postcode: 2229,
    place_name: 'Dolans Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0665,
    longitude: 151.1281,
    accuracy: 4,
  },
  {
    postcode: 2229,
    place_name: 'Lilli Pilli',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0683,
    longitude: 151.1157,
    accuracy: 4,
  },
  {
    postcode: 2230,
    place_name: 'Greenhills Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0328,
    longitude: 151.1762,
    accuracy: 4,
  },
  {
    postcode: 2230,
    place_name: 'Burraneer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0562,
    longitude: 151.1381,
    accuracy: 4,
  },
  {
    postcode: 2230,
    place_name: 'Maianbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0837,
    longitude: 151.1241,
    accuracy: 4,
  },
  {
    postcode: 2230,
    place_name: 'Woolooware',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0458,
    longitude: 151.1431,
    accuracy: 4,
  },
  {
    postcode: 2230,
    place_name: 'Bundeena',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0838,
    longitude: 151.1502,
    accuracy: 4,
  },
  {
    postcode: 2230,
    place_name: 'Cronulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0625,
    longitude: 151.1496,
    accuracy: 4,
  },
  {
    postcode: 2231,
    place_name: 'Kurnell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0109,
    longitude: 151.2051,
    accuracy: 4,
  },
  {
    postcode: 2232,
    place_name: 'Audley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.106,
    longitude: 151.0832,
    accuracy: 3,
  },
  {
    postcode: 2232,
    place_name: 'Sutherland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.031,
    longitude: 151.0553,
    accuracy: 4,
  },
  {
    postcode: 2232,
    place_name: 'Loftus',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0447,
    longitude: 151.0465,
    accuracy: 4,
  },
  {
    postcode: 2232,
    place_name: 'Kirrawee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0383,
    longitude: 151.069,
    accuracy: 4,
  },
  {
    postcode: 2232,
    place_name: 'Woronora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0244,
    longitude: 151.0386,
    accuracy: 4,
  },
  {
    postcode: 2232,
    place_name: 'Kareela',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0134,
    longitude: 151.0835,
    accuracy: 4,
  },
  {
    postcode: 2232,
    place_name: 'Grays Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0587,
    longitude: 151.086,
    accuracy: 4,
  },
  {
    postcode: 2233,
    place_name: 'Waterfall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1357,
    longitude: 150.9923,
    accuracy: 4,
  },
  {
    postcode: 2233,
    place_name: 'Woronora Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0356,
    longitude: 151.0273,
    accuracy: 4,
  },
  {
    postcode: 2233,
    place_name: 'Heathcote',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0841,
    longitude: 151.013,
    accuracy: 4,
  },
  {
    postcode: 2233,
    place_name: 'Yarrawarrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0535,
    longitude: 151.0356,
    accuracy: 4,
  },
  {
    postcode: 2233,
    place_name: 'Engadine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0656,
    longitude: 151.0127,
    accuracy: 4,
  },
  {
    postcode: 2234,
    place_name: 'Lucas Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.06,
    longitude: 150.9807,
    accuracy: 3,
  },
  {
    postcode: 2234,
    place_name: 'Illawong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 2234,
    place_name: 'Menai Central',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.06,
    longitude: 150.9807,
    accuracy: 3,
  },
  {
    postcode: 2234,
    place_name: 'Menai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0167,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2234,
    place_name: 'Bangor',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0167,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 2234,
    place_name: 'Alfords Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9874,
    longitude: 151.0253,
    accuracy: 4,
  },
  {
    postcode: 2234,
    place_name: 'Barden Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0341,
    longitude: 151.0119,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Wyoming',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4039,
    longitude: 151.3625,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Somersby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3667,
    longitude: 151.2833,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'West Gosford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4258,
    longitude: 151.317,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Mount Elliot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4009,
    longitude: 151.3879,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Erina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4322,
    longitude: 151.3897,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Niagara Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3764,
    longitude: 151.3486,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Central Mangrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.293,
    longitude: 151.2387,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Mangrove Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3,
    longitude: 151.2,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Glenworth Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4333,
    longitude: 151.1833,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Kariong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4397,
    longitude: 151.2945,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Narara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4,
    longitude: 151.35,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Tascott',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4502,
    longitude: 151.3137,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Peats Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3167,
    longitude: 151.2333,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Mount White',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4532,
    longitude: 151.2004,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Kulnura',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.205,
    longitude: 151.1759,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Springfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4294,
    longitude: 151.3574,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Point Clare',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4438,
    longitude: 151.3273,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Mooney Mooney Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4826,
    longitude: 151.2275,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Lisarow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3833,
    longitude: 151.3667,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Gosford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4244,
    longitude: 151.344,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'East Gosford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4387,
    longitude: 151.3534,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Mangrove Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.35,
    longitude: 151.15,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Upper Mangrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2833,
    longitude: 151.1167,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'North Gosford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.389,
    longitude: 151.2859,
    accuracy: 3,
  },
  {
    postcode: 2250,
    place_name: 'Matcham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4167,
    longitude: 151.4167,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Ten Mile Hollow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.389,
    longitude: 151.2859,
    accuracy: 3,
  },
  {
    postcode: 2250,
    place_name: 'Erina Fair',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3232,
    longitude: 151.2282,
    accuracy: 3,
  },
  {
    postcode: 2250,
    place_name: 'Point Frederick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4404,
    longitude: 151.3432,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Holgate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4,
    longitude: 151.4167,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Bucketty',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.389,
    longitude: 151.2859,
    accuracy: 3,
  },
  {
    postcode: 2250,
    place_name: 'Lower Mangrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4,
    longitude: 151.15,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Wendoree Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4566,
    longitude: 151.1675,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Greengrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3833,
    longitude: 151.15,
    accuracy: 4,
  },
  {
    postcode: 2250,
    place_name: 'Calga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4312,
    longitude: 151.2275,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Picketts Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4553,
    longitude: 151.4109,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Bouddi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.518,
    longitude: 151.3858,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Davistown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4856,
    longitude: 151.3615,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Copacabana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4869,
    longitude: 151.4359,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Green Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2493,
    longitude: 152.5176,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Yattalunga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4809,
    longitude: 151.3966,
    accuracy: 3,
  },
  {
    postcode: 2251,
    place_name: 'Kincumber',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4674,
    longitude: 151.381,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Macmasters Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4966,
    longitude: 151.4221,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Avoca Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4682,
    longitude: 151.4339,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Saratoga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4756,
    longitude: 151.3521,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Bensville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4976,
    longitude: 151.3808,
    accuracy: 4,
  },
  {
    postcode: 2251,
    place_name: 'Kincumber South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4866,
    longitude: 151.377,
    accuracy: 4,
  },
  {
    postcode: 2252,
    place_name: 'Central Coast Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.344,
    longitude: 151.382,
    accuracy: 1,
  },
  {
    postcode: 2256,
    place_name: 'Horsfield Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4924,
    longitude: 151.301,
    accuracy: 4,
  },
  {
    postcode: 2256,
    place_name: 'Phegans Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4878,
    longitude: 151.3082,
    accuracy: 4,
  },
  {
    postcode: 2256,
    place_name: 'Woy Woy Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4834,
    longitude: 151.312,
    accuracy: 4,
  },
  {
    postcode: 2256,
    place_name: 'Little Wobby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5454,
    longitude: 151.2564,
    accuracy: 4,
  },
  {
    postcode: 2256,
    place_name: 'Patonga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5486,
    longitude: 151.2719,
    accuracy: 4,
  },
  {
    postcode: 2256,
    place_name: 'Koolewong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.465,
    longitude: 151.3157,
    accuracy: 4,
  },
  {
    postcode: 2256,
    place_name: 'Pearl Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5444,
    longitude: 151.3034,
    accuracy: 4,
  },
  {
    postcode: 2256,
    place_name: 'Blackwall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5019,
    longitude: 151.3237,
    accuracy: 4,
  },
  {
    postcode: 2256,
    place_name: 'Wondabyne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4876,
    longitude: 151.262,
    accuracy: 4,
  },
  {
    postcode: 2256,
    place_name: 'Woy Woy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4843,
    longitude: 151.3247,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Killcare Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5239,
    longitude: 151.3682,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Booker Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5167,
    longitude: 151.35,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Daleys Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4969,
    longitude: 151.355,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Empire Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4939,
    longitude: 151.3629,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Hardys Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5247,
    longitude: 151.3617,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Pretty Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5167,
    longitude: 151.35,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Box Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5308,
    longitude: 151.355,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Killcare',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5167,
    longitude: 151.3667,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'St Huberts Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4956,
    longitude: 151.3462,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Umina Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5231,
    longitude: 151.3132,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Ettalong Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5106,
    longitude: 151.3304,
    accuracy: 4,
  },
  {
    postcode: 2257,
    place_name: 'Wagstaffe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5236,
    longitude: 151.3409,
    accuracy: 4,
  },
  {
    postcode: 2258,
    place_name: 'Palmdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3333,
    longitude: 151.3667,
    accuracy: 4,
  },
  {
    postcode: 2258,
    place_name: 'Palm Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3333,
    longitude: 151.3167,
    accuracy: 4,
  },
  {
    postcode: 2258,
    place_name: 'Fountaindale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3477,
    longitude: 151.4011,
    accuracy: 4,
  },
  {
    postcode: 2258,
    place_name: 'Kangy Angy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3188,
    longitude: 151.3964,
    accuracy: 4,
  },
  {
    postcode: 2258,
    place_name: 'Ourimbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3667,
    longitude: 151.4333,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Wybung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2192,
    longitude: 151.4569,
    accuracy: 3,
  },
  {
    postcode: 2259,
    place_name: 'Yarramalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2333,
    longitude: 151.2833,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Cedar Brush Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1667,
    longitude: 151.2833,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Kiar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2012,
    longitude: 151.4376,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Frazer Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2192,
    longitude: 151.4569,
    accuracy: 3,
  },
  {
    postcode: 2259,
    place_name: 'Lake Munmorah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1972,
    longitude: 151.5824,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Dooralong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1833,
    longitude: 151.35,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Wadalba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2723,
    longitude: 151.4649,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Bushells Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.21,
    longitude: 151.4747,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Mardi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2937,
    longitude: 151.4015,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Little Jilliby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2407,
    longitude: 151.3701,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Warnervale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2466,
    longitude: 151.4517,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Gwandalan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1354,
    longitude: 151.5829,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Alison',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2768,
    longitude: 151.4021,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Mannering Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1581,
    longitude: 151.5348,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Freemans',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2063,
    longitude: 151.6012,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Tacoma South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.293,
    longitude: 151.4534,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Halloran',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2323,
    longitude: 151.4367,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Watanobbi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2677,
    longitude: 151.4225,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Jilliby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2261,
    longitude: 151.4168,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Crangan Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1735,
    longitude: 151.5882,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Wyong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2825,
    longitude: 151.4233,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Durren Durren',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2,
    longitude: 151.4,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Point Wolstoncroft',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1203,
    longitude: 151.5844,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Moonee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2057,
    longitude: 153.1529,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Tacoma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2855,
    longitude: 151.4537,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Kingfisher Shores',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1683,
    longitude: 151.5531,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Wyong Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2667,
    longitude: 151.3667,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Chain Valley Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1724,
    longitude: 151.5714,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Ravensdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1744,
    longitude: 151.3007,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Rocky Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2934,
    longitude: 151.4699,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Wyee Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1278,
    longitude: 151.5131,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Lemon Tree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1492,
    longitude: 151.3635,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Wyee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1825,
    longitude: 151.488,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Kanwal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.253,
    longitude: 151.4911,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Wallarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2291,
    longitude: 151.4547,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Tuggerah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3167,
    longitude: 151.4167,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Tuggerawong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2804,
    longitude: 151.4804,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Woongarrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2413,
    longitude: 151.4756,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Summerland Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1409,
    longitude: 151.5656,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Wyongah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2747,
    longitude: 151.489,
    accuracy: 4,
  },
  {
    postcode: 2259,
    place_name: 'Hamlyn Terrace',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2512,
    longitude: 151.4763,
    accuracy: 4,
  },
  {
    postcode: 2260,
    place_name: 'Terrigal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4482,
    longitude: 151.4467,
    accuracy: 4,
  },
  {
    postcode: 2260,
    place_name: 'Wamberal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4155,
    longitude: 151.4456,
    accuracy: 4,
  },
  {
    postcode: 2260,
    place_name: 'Erina Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4252,
    longitude: 151.3995,
    accuracy: 4,
  },
  {
    postcode: 2260,
    place_name: 'Forresters Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.407,
    longitude: 151.476,
    accuracy: 4,
  },
  {
    postcode: 2260,
    place_name: 'North Avoca',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4561,
    longitude: 151.4351,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Blue Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3541,
    longitude: 151.5002,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Killarney Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3549,
    longitude: 151.4722,
    accuracy: 3,
  },
  {
    postcode: 2261,
    place_name: 'Bateau Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3833,
    longitude: 151.4667,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Bay Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3549,
    longitude: 151.4722,
    accuracy: 3,
  },
  {
    postcode: 2261,
    place_name: 'Tumbi Umbi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3667,
    longitude: 151.45,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Chittaway Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3236,
    longitude: 151.4419,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Long Jetty',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.358,
    longitude: 151.4814,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'The Entrance North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3357,
    longitude: 151.5034,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Magenta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.292,
    longitude: 151.5469,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'The Entrance',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3388,
    longitude: 151.4978,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Toowoon Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3581,
    longitude: 151.4971,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Berkeley Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.35,
    longitude: 151.4333,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Glenning Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3537,
    longitude: 151.4262,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Shelly Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.37,
    longitude: 151.4852,
    accuracy: 4,
  },
  {
    postcode: 2261,
    place_name: 'Chittaway Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3277,
    longitude: 151.4297,
    accuracy: 4,
  },
  {
    postcode: 2262,
    place_name: 'Colongra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2069,
    longitude: 151.5436,
    accuracy: 4,
  },
  {
    postcode: 2262,
    place_name: 'Budgewoi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2339,
    longitude: 151.5541,
    accuracy: 4,
  },
  {
    postcode: 2262,
    place_name: 'Halekulani',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2191,
    longitude: 151.5525,
    accuracy: 4,
  },
  {
    postcode: 2262,
    place_name: 'Doyalson North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1873,
    longitude: 151.5488,
    accuracy: 4,
  },
  {
    postcode: 2262,
    place_name: 'San Remo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2147,
    longitude: 151.521,
    accuracy: 4,
  },
  {
    postcode: 2262,
    place_name: 'Budgewoi Peninsula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2106,
    longitude: 151.5855,
    accuracy: 4,
  },
  {
    postcode: 2262,
    place_name: 'Buff Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2229,
    longitude: 151.5312,
    accuracy: 4,
  },
  {
    postcode: 2262,
    place_name: 'Doyalson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1963,
    longitude: 151.5128,
    accuracy: 4,
  },
  {
    postcode: 2262,
    place_name: 'Blue Haven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2111,
    longitude: 151.5035,
    accuracy: 4,
  },
  {
    postcode: 2263,
    place_name: 'Charmhaven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2267,
    longitude: 151.5028,
    accuracy: 4,
  },
  {
    postcode: 2263,
    place_name: 'Gorokan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2576,
    longitude: 151.5097,
    accuracy: 4,
  },
  {
    postcode: 2263,
    place_name: 'Norah Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2833,
    longitude: 151.5667,
    accuracy: 4,
  },
  {
    postcode: 2263,
    place_name: 'Canton Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2744,
    longitude: 151.5461,
    accuracy: 4,
  },
  {
    postcode: 2263,
    place_name: 'Toukley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2637,
    longitude: 151.5384,
    accuracy: 4,
  },
  {
    postcode: 2263,
    place_name: 'Noraville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2679,
    longitude: 151.5535,
    accuracy: 4,
  },
  {
    postcode: 2263,
    place_name: 'Lake Haven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.243,
    longitude: 151.5043,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Yarrawonga Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0946,
    longitude: 151.5471,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Myuna Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.045,
    longitude: 151.5281,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Dora Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0814,
    longitude: 151.4968,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Silverwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.104,
    longitude: 151.5636,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Mirrabooka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.111,
    longitude: 151.5543,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Mandalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1333,
    longitude: 151.4333,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Balcolyn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0957,
    longitude: 151.552,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Morisset',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.108,
    longitude: 151.4871,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Eraring',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0833,
    longitude: 151.5333,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Sunshine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7454,
    longitude: 150.2079,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Windermere Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.121,
    longitude: 151.5307,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Morisset Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1198,
    longitude: 151.537,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Bonnells Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1097,
    longitude: 151.5323,
    accuracy: 4,
  },
  {
    postcode: 2264,
    place_name: 'Brightwaters',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1136,
    longitude: 151.5447,
    accuracy: 4,
  },
  {
    postcode: 2265,
    place_name: 'Cooranbong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0762,
    longitude: 151.4541,
    accuracy: 4,
  },
  {
    postcode: 2265,
    place_name: 'Martinsville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0667,
    longitude: 151.4167,
    accuracy: 4,
  },
  {
    postcode: 2267,
    place_name: 'Wangi Wangi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0718,
    longitude: 151.5984,
    accuracy: 4,
  },
  {
    postcode: 2278,
    place_name: 'Barnsley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9333,
    longitude: 151.5833,
    accuracy: 4,
  },
  {
    postcode: 2278,
    place_name: 'Killingworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9341,
    longitude: 151.556,
    accuracy: 4,
  },
  {
    postcode: 2278,
    place_name: 'Wakefield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9682,
    longitude: 151.5609,
    accuracy: 4,
  },
  {
    postcode: 2280,
    place_name: 'Croudace Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0048,
    longitude: 151.6482,
    accuracy: 4,
  },
  {
    postcode: 2280,
    place_name: 'Marks Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0612,
    longitude: 151.6472,
    accuracy: 4,
  },
  {
    postcode: 2280,
    place_name: 'Belmont South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0493,
    longitude: 151.6569,
    accuracy: 4,
  },
  {
    postcode: 2280,
    place_name: 'Jewells',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0139,
    longitude: 151.6831,
    accuracy: 4,
  },
  {
    postcode: 2280,
    place_name: 'Belmont',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0274,
    longitude: 151.6601,
    accuracy: 4,
  },
  {
    postcode: 2280,
    place_name: 'Valentine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.015,
    longitude: 151.6429,
    accuracy: 4,
  },
  {
    postcode: 2280,
    place_name: 'Floraville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0112,
    longitude: 151.6648,
    accuracy: 4,
  },
  {
    postcode: 2280,
    place_name: 'Belmont North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0208,
    longitude: 151.6685,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Blacksmiths',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0717,
    longitude: 151.6553,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Little Pelican',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0823,
    longitude: 151.6455,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Swansea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0833,
    longitude: 151.6167,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Middle Camp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.15,
    longitude: 151.6333,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Murrays Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1201,
    longitude: 151.6297,
    accuracy: 3,
  },
  {
    postcode: 2281,
    place_name: 'Pinny Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1217,
    longitude: 151.639,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Pelican',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0684,
    longitude: 151.6446,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Swansea Heads',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0965,
    longitude: 151.655,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Caves Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1064,
    longitude: 151.644,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Cams Wharf',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1261,
    longitude: 151.6209,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Nords Wharf',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1313,
    longitude: 151.6073,
    accuracy: 4,
  },
  {
    postcode: 2281,
    place_name: 'Catherine Hill Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1667,
    longitude: 151.6333,
    accuracy: 4,
  },
  {
    postcode: 2282,
    place_name: 'Warners Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9725,
    longitude: 151.6527,
    accuracy: 4,
  },
  {
    postcode: 2282,
    place_name: 'Lakelands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9619,
    longitude: 151.6497,
    accuracy: 4,
  },
  {
    postcode: 2282,
    place_name: 'Eleebana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9896,
    longitude: 151.6364,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Buttaba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0533,
    longitude: 151.5787,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Balmoral',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0667,
    longitude: 151.5833,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Carey Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0266,
    longitude: 151.6057,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Coal Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0423,
    longitude: 151.6116,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Fishing Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0538,
    longitude: 151.5948,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Rathmines',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0377,
    longitude: 151.5844,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Fassifern',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9833,
    longitude: 151.5833,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Arcadia Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0605,
    longitude: 151.5841,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Kilaben Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.024,
    longitude: 151.592,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Ryhope',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9913,
    longitude: 151.522,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Bolton Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0018,
    longitude: 151.6101,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Toronto',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33,
    longitude: 151.6,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Blackalls Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9989,
    longitude: 151.5802,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Fennell Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9922,
    longitude: 151.6001,
    accuracy: 4,
  },
  {
    postcode: 2283,
    place_name: 'Awaba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0082,
    longitude: 151.551,
    accuracy: 4,
  },
  {
    postcode: 2284,
    place_name: 'Teralba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9667,
    longitude: 151.6,
    accuracy: 4,
  },
  {
    postcode: 2284,
    place_name: 'Argenton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.935,
    longitude: 151.6306,
    accuracy: 4,
  },
  {
    postcode: 2284,
    place_name: 'Marmong Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9806,
    longitude: 151.6184,
    accuracy: 4,
  },
  {
    postcode: 2284,
    place_name: 'Boolaroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.95,
    longitude: 151.6167,
    accuracy: 4,
  },
  {
    postcode: 2284,
    place_name: 'Woodrising',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.986,
    longitude: 151.6066,
    accuracy: 4,
  },
  {
    postcode: 2284,
    place_name: 'Speers Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9638,
    longitude: 151.6262,
    accuracy: 4,
  },
  {
    postcode: 2284,
    place_name: 'Booragul',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9749,
    longitude: 151.6098,
    accuracy: 4,
  },
  {
    postcode: 2285,
    place_name: 'Macquarie Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9511,
    longitude: 151.6446,
    accuracy: 4,
  },
  {
    postcode: 2285,
    place_name: 'Cardiff South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9547,
    longitude: 151.6645,
    accuracy: 4,
  },
  {
    postcode: 2285,
    place_name: 'Cameron Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.907,
    longitude: 151.6053,
    accuracy: 4,
  },
  {
    postcode: 2285,
    place_name: 'Edgeworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9251,
    longitude: 151.6161,
    accuracy: 4,
  },
  {
    postcode: 2285,
    place_name: 'Cardiff',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.95,
    longitude: 151.6667,
    accuracy: 4,
  },
  {
    postcode: 2285,
    place_name: 'Cardiff Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9363,
    longitude: 151.6723,
    accuracy: 4,
  },
  {
    postcode: 2285,
    place_name: 'Glendale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9319,
    longitude: 151.641,
    accuracy: 4,
  },
  {
    postcode: 2286,
    place_name: 'West Wallsend',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9,
    longitude: 151.5833,
    accuracy: 4,
  },
  {
    postcode: 2286,
    place_name: 'Seahampton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8895,
    longitude: 151.5832,
    accuracy: 4,
  },
  {
    postcode: 2286,
    place_name: 'Holmesville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9167,
    longitude: 151.5833,
    accuracy: 4,
  },
  {
    postcode: 2287,
    place_name: 'Birmingham Gardens',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8937,
    longitude: 151.6901,
    accuracy: 4,
  },
  {
    postcode: 2287,
    place_name: 'Rankin Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9252,
    longitude: 151.6802,
    accuracy: 4,
  },
  {
    postcode: 2287,
    place_name: 'Elermore Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9162,
    longitude: 151.6766,
    accuracy: 4,
  },
  {
    postcode: 2287,
    place_name: 'Minmi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8783,
    longitude: 151.6173,
    accuracy: 4,
  },
  {
    postcode: 2287,
    place_name: 'Maryland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8789,
    longitude: 151.6614,
    accuracy: 4,
  },
  {
    postcode: 2287,
    place_name: 'Fletcher',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8723,
    longitude: 151.6401,
    accuracy: 4,
  },
  {
    postcode: 2287,
    place_name: 'Wallsend South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.897,
    longitude: 151.6562,
    accuracy: 3,
  },
  {
    postcode: 2287,
    place_name: 'Wallsend',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9167,
    longitude: 151.6667,
    accuracy: 4,
  },
  {
    postcode: 2287,
    place_name: 'Summer Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8915,
    longitude: 151.1382,
    accuracy: 4,
  },
  {
    postcode: 2289,
    place_name: 'Highfields',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9533,
    longitude: 151.7123,
    accuracy: 4,
  },
  {
    postcode: 2289,
    place_name: 'Kotara Fair',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9445,
    longitude: 151.7073,
    accuracy: 3,
  },
  {
    postcode: 2289,
    place_name: 'Kotara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.95,
    longitude: 151.6833,
    accuracy: 4,
  },
  {
    postcode: 2289,
    place_name: 'Kotara South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.95,
    longitude: 151.6833,
    accuracy: 4,
  },
  {
    postcode: 2289,
    place_name: 'Adamstown Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9491,
    longitude: 151.7101,
    accuracy: 4,
  },
  {
    postcode: 2289,
    place_name: 'Garden Suburb',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9437,
    longitude: 151.6808,
    accuracy: 4,
  },
  {
    postcode: 2289,
    place_name: 'Adamstown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9382,
    longitude: 151.7254,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Charlestown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.95,
    longitude: 151.6667,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Dudley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9937,
    longitude: 151.722,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Whitebridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9833,
    longitude: 151.7167,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Bennetts Green',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9955,
    longitude: 151.6879,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Redhead',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0118,
    longitude: 151.7114,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Mount Hutton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9833,
    longitude: 151.6701,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Gateshead',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9821,
    longitude: 151.6919,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Kahibah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9667,
    longitude: 151.7167,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Hillsborough',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9655,
    longitude: 151.6724,
    accuracy: 4,
  },
  {
    postcode: 2290,
    place_name: 'Tingira Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9973,
    longitude: 151.6702,
    accuracy: 4,
  },
  {
    postcode: 2291,
    place_name: 'The Junction',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9382,
    longitude: 151.7591,
    accuracy: 4,
  },
  {
    postcode: 2291,
    place_name: 'Merewether',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.948,
    longitude: 151.7433,
    accuracy: 4,
  },
  {
    postcode: 2291,
    place_name: 'Merewether Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9473,
    longitude: 151.7356,
    accuracy: 4,
  },
  {
    postcode: 2292,
    place_name: 'Hamilton North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9119,
    longitude: 151.7368,
    accuracy: 4,
  },
  {
    postcode: 2292,
    place_name: 'Broadmeadow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9237,
    longitude: 151.7285,
    accuracy: 4,
  },
  {
    postcode: 2293,
    place_name: 'Maryville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9112,
    longitude: 151.7554,
    accuracy: 4,
  },
  {
    postcode: 2293,
    place_name: 'Wickham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9192,
    longitude: 151.7569,
    accuracy: 4,
  },
  {
    postcode: 2294,
    place_name: 'Carrington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.915,
    longitude: 151.7644,
    accuracy: 4,
  },
  {
    postcode: 2295,
    place_name: 'Fern Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8643,
    longitude: 151.8131,
    accuracy: 4,
  },
  {
    postcode: 2295,
    place_name: 'Stockton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9091,
    longitude: 151.7836,
    accuracy: 4,
  },
  {
    postcode: 2296,
    place_name: 'Islington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9139,
    longitude: 151.7474,
    accuracy: 4,
  },
  {
    postcode: 2297,
    place_name: 'Tighes Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9073,
    longitude: 151.7509,
    accuracy: 4,
  },
  {
    postcode: 2298,
    place_name: 'Waratah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9067,
    longitude: 151.7265,
    accuracy: 4,
  },
  {
    postcode: 2298,
    place_name: 'Georgetown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9081,
    longitude: 151.7312,
    accuracy: 4,
  },
  {
    postcode: 2298,
    place_name: 'Waratah West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9,
    longitude: 151.7117,
    accuracy: 4,
  },
  {
    postcode: 2299,
    place_name: 'Lambton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9167,
    longitude: 151.7,
    accuracy: 4,
  },
  {
    postcode: 2299,
    place_name: 'Jesmond',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9028,
    longitude: 151.6907,
    accuracy: 4,
  },
  {
    postcode: 2299,
    place_name: 'North Lambton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9061,
    longitude: 151.7057,
    accuracy: 4,
  },
  {
    postcode: 2300,
    place_name: 'Newcastle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9271,
    longitude: 151.7765,
    accuracy: 4,
  },
  {
    postcode: 2300,
    place_name: 'The Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.931,
    longitude: 151.7754,
    accuracy: 3,
  },
  {
    postcode: 2300,
    place_name: 'Cooks Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9324,
    longitude: 151.7711,
    accuracy: 4,
  },
  {
    postcode: 2300,
    place_name: 'Newcastle East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9277,
    longitude: 151.7884,
    accuracy: 4,
  },
  {
    postcode: 2300,
    place_name: 'Bar Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9413,
    longitude: 151.7654,
    accuracy: 4,
  },
  {
    postcode: 2302,
    place_name: 'Newcastle West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9245,
    longitude: 151.7571,
    accuracy: 4,
  },
  {
    postcode: 2303,
    place_name: 'Hamilton South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9279,
    longitude: 151.7465,
    accuracy: 3,
  },
  {
    postcode: 2303,
    place_name: 'Hamilton East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9258,
    longitude: 151.7535,
    accuracy: 4,
  },
  {
    postcode: 2303,
    place_name: 'Hamilton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9221,
    longitude: 151.7471,
    accuracy: 4,
  },
  {
    postcode: 2304,
    place_name: 'Kooragang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8843,
    longitude: 151.771,
    accuracy: 4,
  },
  {
    postcode: 2304,
    place_name: 'Warabrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8888,
    longitude: 151.7149,
    accuracy: 4,
  },
  {
    postcode: 2304,
    place_name: 'Mayfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8979,
    longitude: 151.7361,
    accuracy: 4,
  },
  {
    postcode: 2304,
    place_name: 'Mayfield West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.892,
    longitude: 151.7271,
    accuracy: 4,
  },
  {
    postcode: 2304,
    place_name: 'Sandgate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8667,
    longitude: 151.7,
    accuracy: 4,
  },
  {
    postcode: 2304,
    place_name: 'Mayfield East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9003,
    longitude: 151.7497,
    accuracy: 4,
  },
  {
    postcode: 2304,
    place_name: 'Mayfield North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8928,
    longitude: 151.7482,
    accuracy: 4,
  },
  {
    postcode: 2305,
    place_name: 'New Lambton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.95,
    longitude: 151.6833,
    accuracy: 4,
  },
  {
    postcode: 2305,
    place_name: 'Kotara East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.928,
    longitude: 151.7031,
    accuracy: 3,
  },
  {
    postcode: 2305,
    place_name: 'New Lambton Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9247,
    longitude: 151.6936,
    accuracy: 4,
  },
  {
    postcode: 2306,
    place_name: 'Windale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9928,
    longitude: 151.6817,
    accuracy: 4,
  },
  {
    postcode: 2307,
    place_name: 'Shortland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8788,
    longitude: 151.691,
    accuracy: 4,
  },
  {
    postcode: 2308,
    place_name: 'Newcastle University',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.892,
    longitude: 151.7053,
    accuracy: 3,
  },
  {
    postcode: 2308,
    place_name: 'Callaghan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.892,
    longitude: 151.7053,
    accuracy: 4,
  },
  {
    postcode: 2309,
    place_name: 'Dangar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5386,
    longitude: 151.2415,
    accuracy: 4,
  },
  {
    postcode: 2310,
    place_name: 'Hunter Region Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5879,
    longitude: 151.8955,
    accuracy: 1,
  },
  {
    postcode: 2311,
    place_name: 'East Gresford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4138,
    longitude: 151.5565,
    accuracy: 4,
  },
  {
    postcode: 2311,
    place_name: 'Halton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3167,
    longitude: 151.5167,
    accuracy: 4,
  },
  {
    postcode: 2311,
    place_name: 'Gresford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4273,
    longitude: 151.5375,
    accuracy: 4,
  },
  {
    postcode: 2311,
    place_name: 'Bingleburra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3529,
    longitude: 151.5156,
    accuracy: 3,
  },
  {
    postcode: 2311,
    place_name: 'Mount Rivers',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3652,
    longitude: 151.4846,
    accuracy: 4,
  },
  {
    postcode: 2311,
    place_name: 'Upper Allyn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3529,
    longitude: 151.5156,
    accuracy: 3,
  },
  {
    postcode: 2311,
    place_name: 'Lostock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3333,
    longitude: 151.45,
    accuracy: 4,
  },
  {
    postcode: 2311,
    place_name: 'Allynbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3614,
    longitude: 151.5364,
    accuracy: 4,
  },
  {
    postcode: 2311,
    place_name: 'Eccleston',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.25,
    longitude: 151.5,
    accuracy: 4,
  },
  {
    postcode: 2311,
    place_name: 'Carrabolla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3529,
    longitude: 151.5156,
    accuracy: 3,
  },
  {
    postcode: 2311,
    place_name: 'Lewinsbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3529,
    longitude: 151.5156,
    accuracy: 3,
  },
  {
    postcode: 2312,
    place_name: 'Minimbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1577,
    longitude: 152.3748,
    accuracy: 4,
  },
  {
    postcode: 2312,
    place_name: 'Nabiac',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0984,
    longitude: 152.3763,
    accuracy: 4,
  },
  {
    postcode: 2314,
    place_name: 'Williamtown Raaf',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5879,
    longitude: 151.8955,
    accuracy: 1,
  },
  {
    postcode: 2315,
    place_name: 'Fingal Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7497,
    longitude: 152.171,
    accuracy: 4,
  },
  {
    postcode: 2315,
    place_name: 'Corlette',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7201,
    longitude: 152.1085,
    accuracy: 4,
  },
  {
    postcode: 2315,
    place_name: 'Shoal Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7223,
    longitude: 152.175,
    accuracy: 4,
  },
  {
    postcode: 2315,
    place_name: 'Nelson Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7204,
    longitude: 152.144,
    accuracy: 4,
  },
  {
    postcode: 2316,
    place_name: 'Taylors Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7488,
    longitude: 152.067,
    accuracy: 4,
  },
  {
    postcode: 2316,
    place_name: 'Fishermans Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7858,
    longitude: 152.0896,
    accuracy: 4,
  },
  {
    postcode: 2316,
    place_name: 'Boat Harbour',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7896,
    longitude: 152.1091,
    accuracy: 4,
  },
  {
    postcode: 2316,
    place_name: 'Anna Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7813,
    longitude: 152.0859,
    accuracy: 4,
  },
  {
    postcode: 2316,
    place_name: 'Bobs Farm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7667,
    longitude: 152.0167,
    accuracy: 4,
  },
  {
    postcode: 2316,
    place_name: 'One Mile',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.77,
    longitude: 152.07,
    accuracy: 3,
  },
  {
    postcode: 2317,
    place_name: 'Soldiers Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7105,
    longitude: 152.0698,
    accuracy: 4,
  },
  {
    postcode: 2317,
    place_name: 'Salamander Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7225,
    longitude: 152.0794,
    accuracy: 4,
  },
  {
    postcode: 2318,
    place_name: 'Medowie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7415,
    longitude: 151.8676,
    accuracy: 4,
  },
  {
    postcode: 2318,
    place_name: 'Salt Ash',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7833,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 2318,
    place_name: 'Ferodale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7333,
    longitude: 151.85,
    accuracy: 4,
  },
  {
    postcode: 2318,
    place_name: 'Campvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7726,
    longitude: 151.856,
    accuracy: 4,
  },
  {
    postcode: 2318,
    place_name: 'Williamtown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8064,
    longitude: 151.8436,
    accuracy: 4,
  },
  {
    postcode: 2318,
    place_name: 'Oyster Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7387,
    longitude: 151.9564,
    accuracy: 4,
  },
  {
    postcode: 2318,
    place_name: 'Fullerton Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8405,
    longitude: 151.8379,
    accuracy: 4,
  },
  {
    postcode: 2319,
    place_name: 'Tilligerry Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7393,
    longitude: 152.0172,
    accuracy: 3,
  },
  {
    postcode: 2319,
    place_name: 'Tanilba Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7471,
    longitude: 151.9971,
    accuracy: 4,
  },
  {
    postcode: 2319,
    place_name: 'Mallabula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7282,
    longitude: 152.0126,
    accuracy: 4,
  },
  {
    postcode: 2319,
    place_name: 'Lemon Tree Passage',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7305,
    longitude: 152.0386,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Mindaribba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7331,
    longitude: 151.5279,
    accuracy: 3,
  },
  {
    postcode: 2320,
    place_name: 'Bolwarra Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.701,
    longitude: 151.5854,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Keinbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7331,
    longitude: 151.5279,
    accuracy: 3,
  },
  {
    postcode: 2320,
    place_name: 'Allandale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7196,
    longitude: 151.4028,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Mount Dee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7331,
    longitude: 151.5279,
    accuracy: 3,
  },
  {
    postcode: 2320,
    place_name: 'South Maitland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7453,
    longitude: 151.5634,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Anambah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7331,
    longitude: 151.5279,
    accuracy: 3,
  },
  {
    postcode: 2320,
    place_name: 'Louth Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7709,
    longitude: 151.5483,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Farley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7333,
    longitude: 151.5167,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Lorn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7333,
    longitude: 151.5667,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Rutherford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7167,
    longitude: 151.5333,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Largs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7,
    longitude: 151.6,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Wallalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6956,
    longitude: 151.6496,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Horseshoe Bend',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7353,
    longitude: 151.5683,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Maitland Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7331,
    longitude: 151.5279,
    accuracy: 3,
  },
  {
    postcode: 2320,
    place_name: 'Windella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7033,
    longitude: 151.4802,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Maitland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7331,
    longitude: 151.5574,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Oakhampton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7,
    longitude: 151.5667,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Gosforth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6667,
    longitude: 151.4833,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Telarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7333,
    longitude: 151.5333,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Maitland North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7164,
    longitude: 151.5373,
    accuracy: 3,
  },
  {
    postcode: 2320,
    place_name: 'Bolwarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7167,
    longitude: 151.5667,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Pokolbin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8,
    longitude: 151.2833,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Rosebrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.65,
    longitude: 151.5167,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Rothbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7485,
    longitude: 151.3429,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Aberglasslyn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7,
    longitude: 151.5333,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Oakhampton Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7331,
    longitude: 151.5279,
    accuracy: 3,
  },
  {
    postcode: 2320,
    place_name: 'Hillsborough',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9655,
    longitude: 151.6724,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Glen Oak',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6167,
    longitude: 151.7167,
    accuracy: 4,
  },
  {
    postcode: 2320,
    place_name: 'Melville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7331,
    longitude: 151.5279,
    accuracy: 3,
  },
  {
    postcode: 2321,
    place_name: 'Butterwick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6564,
    longitude: 151.6369,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Duns Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7353,
    longitude: 151.622,
    accuracy: 3,
  },
  {
    postcode: 2321,
    place_name: 'Morpeth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7333,
    longitude: 151.6333,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Woodville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6833,
    longitude: 151.6167,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Lochinvar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6987,
    longitude: 151.4552,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Duckenfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7333,
    longitude: 151.6833,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Luskintyre',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6834,
    longitude: 151.427,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Raworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7333,
    longitude: 151.6167,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Cliftleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7353,
    longitude: 151.622,
    accuracy: 3,
  },
  {
    postcode: 2321,
    place_name: 'Berry Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7333,
    longitude: 151.6667,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Clarence Town',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.584,
    longitude: 151.7776,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Glen William',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5333,
    longitude: 151.8,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Oswald',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7062,
    longitude: 151.4215,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Heddon Greta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.802,
    longitude: 151.5133,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Glen Martin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7353,
    longitude: 151.622,
    accuracy: 3,
  },
  {
    postcode: 2321,
    place_name: 'Gillieston Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7616,
    longitude: 151.5286,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Harpers Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7353,
    longitude: 151.622,
    accuracy: 3,
  },
  {
    postcode: 2321,
    place_name: 'Windermere',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6897,
    longitude: 151.4474,
    accuracy: 4,
  },
  {
    postcode: 2321,
    place_name: 'Phoenix Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7353,
    longitude: 151.622,
    accuracy: 3,
  },
  {
    postcode: 2321,
    place_name: 'Hinton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7147,
    longitude: 151.649,
    accuracy: 4,
  },
  {
    postcode: 2322,
    place_name: 'Hexham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8293,
    longitude: 151.6844,
    accuracy: 4,
  },
  {
    postcode: 2322,
    place_name: 'Stockrington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8072,
    longitude: 151.6739,
    accuracy: 3,
  },
  {
    postcode: 2322,
    place_name: 'Chisholm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8072,
    longitude: 151.6739,
    accuracy: 3,
  },
  {
    postcode: 2322,
    place_name: 'Lenaghan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8417,
    longitude: 151.6359,
    accuracy: 4,
  },
  {
    postcode: 2322,
    place_name: 'Black Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8377,
    longitude: 151.6187,
    accuracy: 4,
  },
  {
    postcode: 2322,
    place_name: 'Woodberry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7931,
    longitude: 151.6769,
    accuracy: 4,
  },
  {
    postcode: 2322,
    place_name: 'Tarro',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8,
    longitude: 151.6667,
    accuracy: 4,
  },
  {
    postcode: 2322,
    place_name: 'Beresfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8,
    longitude: 151.65,
    accuracy: 4,
  },
  {
    postcode: 2322,
    place_name: 'Thornton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7833,
    longitude: 151.6333,
    accuracy: 4,
  },
  {
    postcode: 2322,
    place_name: 'Tomago',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.827,
    longitude: 151.7131,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'East Maitland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.75,
    longitude: 151.5833,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Mulbring',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9048,
    longitude: 151.4851,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Metford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.765,
    longitude: 151.6094,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Ashtonfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7733,
    longitude: 151.6058,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Four Mile Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4318,
    longitude: 148.9512,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Buttai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8219,
    longitude: 151.5609,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Brunkerville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9425,
    longitude: 151.4795,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Green Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9176,
    longitude: 149.5836,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Metford Dc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8708,
    longitude: 151.5188,
    accuracy: 3,
  },
  {
    postcode: 2323,
    place_name: 'Pitnacree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8048,
    longitude: 151.5627,
    accuracy: 3,
  },
  {
    postcode: 2323,
    place_name: 'Freemans Waterhole',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9847,
    longitude: 151.4846,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Buchanan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8305,
    longitude: 151.5312,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Mount Vincent',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9174,
    longitude: 151.4774,
    accuracy: 3,
  },
  {
    postcode: 2323,
    place_name: 'Tenambit',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7333,
    longitude: 151.6167,
    accuracy: 4,
  },
  {
    postcode: 2323,
    place_name: 'Richmond Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8048,
    longitude: 151.5627,
    accuracy: 3,
  },
  {
    postcode: 2324,
    place_name: 'Limeburners Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6167,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Twelve Mile Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6447,
    longitude: 151.865,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'East Seaham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6478,
    longitude: 151.7616,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Millers Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.75,
    longitude: 151.7,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Seaham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6622,
    longitude: 151.7266,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Tea Gardens',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6635,
    longitude: 152.154,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Eagleton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.707,
    longitude: 151.7553,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Cells River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6462,
    longitude: 151.9955,
    accuracy: 3,
  },
  {
    postcode: 2324,
    place_name: 'Tahlee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6667,
    longitude: 152,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Osterley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7245,
    longitude: 151.6998,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Brandy Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6893,
    longitude: 151.696,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Hawks Nest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6675,
    longitude: 152.1783,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'North Arm Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6706,
    longitude: 152.0448,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Raymond Terrace East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6462,
    longitude: 151.9955,
    accuracy: 3,
  },
  {
    postcode: 2324,
    place_name: 'Pindimar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6828,
    longitude: 152.0981,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Carrington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.915,
    longitude: 151.7644,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Heatherbrae',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7889,
    longitude: 151.7354,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Bundabah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6462,
    longitude: 151.9955,
    accuracy: 3,
  },
  {
    postcode: 2324,
    place_name: 'Nelsons Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7205,
    longitude: 151.7173,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Swan Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6918,
    longitude: 151.9289,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Balickera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6589,
    longitude: 151.8119,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Raymond Terrace',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.75,
    longitude: 151.75,
    accuracy: 4,
  },
  {
    postcode: 2324,
    place_name: 'Karuah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6539,
    longitude: 151.9604,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Paxton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9061,
    longitude: 151.2849,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Quorrobolong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9225,
    longitude: 151.364,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Wollombi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9388,
    longitude: 151.1415,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Cessnock West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9386,
    longitude: 151.2758,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Kearsley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8586,
    longitude: 151.3957,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Cessnock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8321,
    longitude: 151.3562,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Kitchener',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8813,
    longitude: 151.3671,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Ellalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9151,
    longitude: 151.3116,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Bellbird',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8599,
    longitude: 151.3187,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Mount View',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.85,
    longitude: 151.2833,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Elrington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.531,
    longitude: 149.6453,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Pelton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8783,
    longitude: 151.3109,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Nulkaba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8103,
    longitude: 151.3483,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Lovedale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7705,
    longitude: 151.3648,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Laguna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9902,
    longitude: 151.1346,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Abernethy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9,
    longitude: 151.4,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Millfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8886,
    longitude: 151.2613,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Greta Main',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.88,
    longitude: 151.3082,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Sweetmans Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.88,
    longitude: 151.3082,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Olney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.88,
    longitude: 151.3082,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Paynes Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9386,
    longitude: 151.2758,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Cedar Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8552,
    longitude: 151.2186,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Congewai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9386,
    longitude: 151.2758,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Moruben',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.88,
    longitude: 151.3082,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Corrabare',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.88,
    longitude: 151.3082,
    accuracy: 3,
  },
  {
    postcode: 2325,
    place_name: 'Aberdare',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8333,
    longitude: 151.3667,
    accuracy: 4,
  },
  {
    postcode: 2325,
    place_name: 'Bellbird Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8504,
    longitude: 151.329,
    accuracy: 4,
  },
  {
    postcode: 2326,
    place_name: 'Neath',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8276,
    longitude: 151.4082,
    accuracy: 4,
  },
  {
    postcode: 2326,
    place_name: 'Abermain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8074,
    longitude: 151.4275,
    accuracy: 4,
  },
  {
    postcode: 2326,
    place_name: 'Bishops Bridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7455,
    longitude: 151.4689,
    accuracy: 4,
  },
  {
    postcode: 2326,
    place_name: 'Weston',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.811,
    longitude: 151.4366,
    accuracy: 4,
  },
  {
    postcode: 2326,
    place_name: 'Loxford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.791,
    longitude: 151.4554,
    accuracy: 3,
  },
  {
    postcode: 2326,
    place_name: 'Sawyers Gully',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8002,
    longitude: 151.4368,
    accuracy: 3,
  },
  {
    postcode: 2327,
    place_name: 'Stanford Merthyr',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8247,
    longitude: 151.4936,
    accuracy: 4,
  },
  {
    postcode: 2327,
    place_name: 'Pelaw Main',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8378,
    longitude: 151.4815,
    accuracy: 4,
  },
  {
    postcode: 2327,
    place_name: 'Kurri Kurri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8193,
    longitude: 151.4791,
    accuracy: 4,
  },
  {
    postcode: 2328,
    place_name: 'Denman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3879,
    longitude: 150.6893,
    accuracy: 4,
  },
  {
    postcode: 2328,
    place_name: 'Widden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5167,
    longitude: 150.3667,
    accuracy: 4,
  },
  {
    postcode: 2328,
    place_name: 'Kerrabee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4167,
    longitude: 150.3,
    accuracy: 4,
  },
  {
    postcode: 2328,
    place_name: 'Martindale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4404,
    longitude: 150.452,
    accuracy: 3,
  },
  {
    postcode: 2328,
    place_name: 'Bureen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4404,
    longitude: 150.452,
    accuracy: 3,
  },
  {
    postcode: 2328,
    place_name: 'Hollydeen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5658,
    longitude: 150.5176,
    accuracy: 3,
  },
  {
    postcode: 2328,
    place_name: 'Giants Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4404,
    longitude: 150.452,
    accuracy: 3,
  },
  {
    postcode: 2328,
    place_name: 'Yarrawa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4404,
    longitude: 150.452,
    accuracy: 3,
  },
  {
    postcode: 2328,
    place_name: 'Dalswinton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4404,
    longitude: 150.452,
    accuracy: 3,
  },
  {
    postcode: 2328,
    place_name: 'Mangoola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4404,
    longitude: 150.452,
    accuracy: 3,
  },
  {
    postcode: 2329,
    place_name: 'Cassilis',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32,
    longitude: 149.9833,
    accuracy: 4,
  },
  {
    postcode: 2329,
    place_name: 'Borambil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0333,
    longitude: 150,
    accuracy: 4,
  },
  {
    postcode: 2329,
    place_name: 'Uarbry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0485,
    longitude: 149.7679,
    accuracy: 4,
  },
  {
    postcode: 2329,
    place_name: 'Merriwa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1392,
    longitude: 150.3556,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Ravensworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.45,
    longitude: 151.0667,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Gowrie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.35,
    longitude: 150.85,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Glenridding',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Howick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Warkworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5833,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Glendon Brook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5207,
    longitude: 151.3346,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Scotts Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Hunterview',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5434,
    longitude: 151.1772,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Putty',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9652,
    longitude: 150.6679,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Dunolly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Gouldsville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Milbrodale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Long Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0167,
    longitude: 150.9,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Goorangoola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Mitchells Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5485,
    longitude: 151.2846,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Redbournberry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Whittingham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6167,
    longitude: 151.2,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Big Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Doyles Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5485,
    longitude: 150.7846,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Mirannie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6993,
    longitude: 150.95,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Glendon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5794,
    longitude: 150.4082,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Hebden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3833,
    longitude: 151.0667,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Wollemi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.044,
    longitude: 150.6799,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Darlington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.891,
    longitude: 151.1955,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Sedgefield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5402,
    longitude: 151.2512,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Mount Thorley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Bowmans Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2652,
    longitude: 151.1346,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Mount Olive',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6993,
    longitude: 150.95,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Maison Dieu',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5378,
    longitude: 151.0902,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Appletree Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Camberwell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4833,
    longitude: 151.0833,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Bridgman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4543,
    longitude: 151.1882,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Singleton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5674,
    longitude: 151.166,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Combo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Middle Falbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Westbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Mount Royal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2075,
    longitude: 151.2993,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Obanvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Reedy Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6993,
    longitude: 150.95,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Jerrys Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5,
    longitude: 150.9167,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Big Yengo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8931,
    longitude: 150.8457,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Lemington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Fern Gully',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Garland Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Fordwich',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Rixs Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Hambledon Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Howes Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8485,
    longitude: 150.8512,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'St Clair',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7973,
    longitude: 150.7847,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Dyrring',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Clydesdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Wattle Ponds',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Dural',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6833,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Roughit',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Falbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Wylies Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Bulga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6667,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Mcdougalls Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Broke',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7512,
    longitude: 151.1045,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Greenlands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6993,
    longitude: 150.95,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Glennies Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.45,
    longitude: 151.1167,
    accuracy: 4,
  },
  {
    postcode: 2330,
    place_name: 'Singleton Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6686,
    longitude: 151.0454,
    accuracy: 3,
  },
  {
    postcode: 2330,
    place_name: 'Carrowbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6993,
    longitude: 150.95,
    accuracy: 3,
  },
  {
    postcode: 2331,
    place_name: 'Singleton Milpo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6895,
    longitude: 151.1897,
    accuracy: 1,
  },
  {
    postcode: 2331,
    place_name: 'Singleton Military Area',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3281,
    longitude: 150.986,
    accuracy: 1,
  },
  {
    postcode: 2333,
    place_name: 'Baerami Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5,
    longitude: 150.4667,
    accuracy: 4,
  },
  {
    postcode: 2333,
    place_name: 'Mccullys Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2966,
    longitude: 150.7584,
    accuracy: 3,
  },
  {
    postcode: 2333,
    place_name: 'Liddell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4167,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 2333,
    place_name: 'Edderton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2966,
    longitude: 150.7584,
    accuracy: 3,
  },
  {
    postcode: 2333,
    place_name: 'Sandy Hollow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3333,
    longitude: 150.5667,
    accuracy: 4,
  },
  {
    postcode: 2333,
    place_name: 'Castle Rock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3306,
    longitude: 150.68,
    accuracy: 3,
  },
  {
    postcode: 2333,
    place_name: 'Muscle Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2704,
    longitude: 150.9978,
    accuracy: 3,
  },
  {
    postcode: 2333,
    place_name: 'Gungal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2652,
    longitude: 150.4846,
    accuracy: 4,
  },
  {
    postcode: 2333,
    place_name: 'Kayuga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2,
    longitude: 150.8667,
    accuracy: 4,
  },
  {
    postcode: 2333,
    place_name: 'Bengalla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3306,
    longitude: 150.68,
    accuracy: 3,
  },
  {
    postcode: 2333,
    place_name: 'Baerami',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3818,
    longitude: 150.4679,
    accuracy: 4,
  },
  {
    postcode: 2333,
    place_name: 'Manobalai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1896,
    longitude: 150.676,
    accuracy: 4,
  },
  {
    postcode: 2333,
    place_name: 'Muswellbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2612,
    longitude: 150.8901,
    accuracy: 4,
  },
  {
    postcode: 2333,
    place_name: 'Wybong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2667,
    longitude: 150.6333,
    accuracy: 4,
  },
  {
    postcode: 2334,
    place_name: 'Greta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6778,
    longitude: 151.3892,
    accuracy: 4,
  },
  {
    postcode: 2335,
    place_name: 'Leconfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0131,
    longitude: 151.1701,
    accuracy: 3,
  },
  {
    postcode: 2335,
    place_name: 'Elderslie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.05,
    longitude: 150.7,
    accuracy: 4,
  },
  {
    postcode: 2335,
    place_name: 'Stanhope',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7164,
    longitude: 150.9261,
    accuracy: 4,
  },
  {
    postcode: 2335,
    place_name: 'North Rothbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6934,
    longitude: 151.3438,
    accuracy: 4,
  },
  {
    postcode: 2335,
    place_name: 'Lower Belford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0131,
    longitude: 151.1701,
    accuracy: 3,
  },
  {
    postcode: 2335,
    place_name: 'East Branxton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0131,
    longitude: 151.1701,
    accuracy: 3,
  },
  {
    postcode: 2335,
    place_name: 'Dalwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6484,
    longitude: 151.3046,
    accuracy: 3,
  },
  {
    postcode: 2335,
    place_name: 'Lambs Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0131,
    longitude: 151.1701,
    accuracy: 3,
  },
  {
    postcode: 2335,
    place_name: 'Belford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6667,
    longitude: 151.2833,
    accuracy: 4,
  },
  {
    postcode: 2335,
    place_name: 'Branxton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6555,
    longitude: 151.3511,
    accuracy: 4,
  },
  {
    postcode: 2336,
    place_name: 'Upper Rouchel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1167,
    longitude: 151.0833,
    accuracy: 4,
  },
  {
    postcode: 2336,
    place_name: 'Rouchel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1167,
    longitude: 151.0833,
    accuracy: 4,
  },
  {
    postcode: 2336,
    place_name: 'Dartbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1568,
    longitude: 150.8063,
    accuracy: 4,
  },
  {
    postcode: 2336,
    place_name: 'Upper Dartbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1568,
    longitude: 150.8063,
    accuracy: 4,
  },
  {
    postcode: 2336,
    place_name: 'Aberdeen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1659,
    longitude: 150.89,
    accuracy: 4,
  },
  {
    postcode: 2336,
    place_name: 'Rouchel Brook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1254,
    longitude: 151.0481,
    accuracy: 3,
  },
  {
    postcode: 2336,
    place_name: 'Davis Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1023,
    longitude: 151.1358,
    accuracy: 3,
  },
  {
    postcode: 2336,
    place_name: 'Rossgole',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1254,
    longitude: 151.0481,
    accuracy: 3,
  },
  {
    postcode: 2337,
    place_name: 'Stewarts Brook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9985,
    longitude: 151.3179,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Owens Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.05,
    longitude: 150.7,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Moonan Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9167,
    longitude: 151.25,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Ellerston',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8167,
    longitude: 151.3167,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Satur',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.041,
    longitude: 150.8416,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Wingen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8833,
    longitude: 150.8833,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Moonan Brook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9415,
    longitude: 151.2792,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Moobi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9573,
    longitude: 151.0389,
    accuracy: 3,
  },
  {
    postcode: 2337,
    place_name: 'Glenrock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6652,
    longitude: 151.4179,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Segenhoe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1167,
    longitude: 150.9167,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Scone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0501,
    longitude: 150.8689,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Glenbawn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9573,
    longitude: 151.0389,
    accuracy: 3,
  },
  {
    postcode: 2337,
    place_name: 'Tomalla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.85,
    longitude: 151.4667,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Gundy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0167,
    longitude: 151,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Belltrees',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9906,
    longitude: 151.1232,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Murulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9573,
    longitude: 151.0389,
    accuracy: 3,
  },
  {
    postcode: 2337,
    place_name: 'Bunnan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.05,
    longitude: 150.5833,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Kars Springs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9,
    longitude: 150.5667,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Parkville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9809,
    longitude: 150.8628,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Middle Brook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9573,
    longitude: 151.0389,
    accuracy: 3,
  },
  {
    postcode: 2337,
    place_name: 'Pages Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9573,
    longitude: 151.0389,
    accuracy: 3,
  },
  {
    postcode: 2337,
    place_name: 'Woolooma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32,
    longitude: 151.25,
    accuracy: 4,
  },
  {
    postcode: 2337,
    place_name: 'Omadale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9573,
    longitude: 151.0389,
    accuracy: 3,
  },
  {
    postcode: 2337,
    place_name: 'Waverly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9573,
    longitude: 151.0389,
    accuracy: 3,
  },
  {
    postcode: 2337,
    place_name: 'Brawboy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9573,
    longitude: 151.0389,
    accuracy: 3,
  },
  {
    postcode: 2337,
    place_name: 'Dry Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9573,
    longitude: 151.0389,
    accuracy: 3,
  },
  {
    postcode: 2338,
    place_name: 'Timor',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7833,
    longitude: 151.0833,
    accuracy: 4,
  },
  {
    postcode: 2338,
    place_name: 'Ardglen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7358,
    longitude: 150.7484,
    accuracy: 4,
  },
  {
    postcode: 2338,
    place_name: 'Pages River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7744,
    longitude: 150.9006,
    accuracy: 3,
  },
  {
    postcode: 2338,
    place_name: 'Murrurundi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7642,
    longitude: 150.8358,
    accuracy: 4,
  },
  {
    postcode: 2338,
    place_name: 'Green Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7744,
    longitude: 150.9006,
    accuracy: 3,
  },
  {
    postcode: 2338,
    place_name: 'Blandford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7849,
    longitude: 150.8935,
    accuracy: 4,
  },
  {
    postcode: 2338,
    place_name: 'Crawney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7744,
    longitude: 150.9006,
    accuracy: 3,
  },
  {
    postcode: 2338,
    place_name: 'Sandy Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7744,
    longitude: 150.9006,
    accuracy: 3,
  },
  {
    postcode: 2338,
    place_name: 'Scotts Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7744,
    longitude: 150.9006,
    accuracy: 3,
  },
  {
    postcode: 2339,
    place_name: 'Warrah Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7152,
    longitude: 150.6512,
    accuracy: 4,
  },
  {
    postcode: 2339,
    place_name: 'Cattle Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6778,
    longitude: 150.6778,
    accuracy: 3,
  },
  {
    postcode: 2339,
    place_name: 'Braefield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5707,
    longitude: 150.7018,
    accuracy: 4,
  },
  {
    postcode: 2339,
    place_name: 'Little Jacks Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6778,
    longitude: 150.6778,
    accuracy: 3,
  },
  {
    postcode: 2339,
    place_name: 'Willow Tree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6495,
    longitude: 150.7264,
    accuracy: 4,
  },
  {
    postcode: 2339,
    place_name: 'Macdonalds Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6778,
    longitude: 150.6778,
    accuracy: 3,
  },
  {
    postcode: 2339,
    place_name: 'Warrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.65,
    longitude: 150.65,
    accuracy: 4,
  },
  {
    postcode: 2339,
    place_name: 'Parraweena',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6778,
    longitude: 150.6778,
    accuracy: 3,
  },
  {
    postcode: 2339,
    place_name: 'Big Jacks Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6778,
    longitude: 150.6778,
    accuracy: 3,
  },
  {
    postcode: 2339,
    place_name: 'Chilcotts Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6778,
    longitude: 150.6778,
    accuracy: 3,
  },
  {
    postcode: 2340,
    place_name: 'Loomberah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1833,
    longitude: 151,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Daruka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0373,
    longitude: 150.9707,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Somerton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9411,
    longitude: 150.6383,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Hillvue',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1276,
    longitude: 150.9071,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Moore Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1512,
    longitude: 150.9383,
    accuracy: 3,
  },
  {
    postcode: 2340,
    place_name: 'Garoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1512,
    longitude: 150.9383,
    accuracy: 3,
  },
  {
    postcode: 2340,
    place_name: 'Hanging Rock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4822,
    longitude: 151.1921,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Appleby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9667,
    longitude: 150.85,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Westdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0905,
    longitude: 150.8568,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Gowrie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.35,
    longitude: 150.85,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Goonoo Goonoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3079,
    longitude: 150.9027,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'South Tamworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.11,
    longitude: 150.9225,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Nundle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.465,
    longitude: 151.1264,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Calala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1736,
    longitude: 150.9806,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Carroll',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9894,
    longitude: 150.4442,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Bithramere',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1357,
    longitude: 150.7811,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Woolomin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.305,
    longitude: 151.1502,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Tamworth South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2744,
    longitude: 151.0275,
    accuracy: 3,
  },
  {
    postcode: 2340,
    place_name: 'Gidley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0119,
    longitude: 150.8387,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Keepit',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8333,
    longitude: 150.5167,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Barry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5817,
    longitude: 151.3183,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Oxley Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0632,
    longitude: 150.9006,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Duncans Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3667,
    longitude: 151.2,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Weabonga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2167,
    longitude: 151.3167,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Warral',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1637,
    longitude: 150.8567,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Ogunbil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1512,
    longitude: 150.9383,
    accuracy: 3,
  },
  {
    postcode: 2340,
    place_name: 'North Tamworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0786,
    longitude: 150.9222,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Kingswood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.75,
    longitude: 150.75,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Dungowan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2152,
    longitude: 151.1179,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Wallamore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1512,
    longitude: 150.9383,
    accuracy: 3,
  },
  {
    postcode: 2340,
    place_name: 'Bective',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0042,
    longitude: 150.7269,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Hallsville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0112,
    longitude: 150.874,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Piallamore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1659,
    longitude: 151.0576,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Taminda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0941,
    longitude: 150.8962,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'West Tamworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1024,
    longitude: 150.9145,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Nemingha',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1237,
    longitude: 150.9892,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Timbumburi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1512,
    longitude: 150.9383,
    accuracy: 3,
  },
  {
    postcode: 2340,
    place_name: 'Bowling Alley Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3956,
    longitude: 151.1397,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'East Tamworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0855,
    longitude: 150.9372,
    accuracy: 4,
  },
  {
    postcode: 2340,
    place_name: 'Tamworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0905,
    longitude: 150.929,
    accuracy: 4,
  },
  {
    postcode: 2341,
    place_name: 'Werris Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3491,
    longitude: 150.6487,
    accuracy: 4,
  },
  {
    postcode: 2342,
    place_name: 'Piallaway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1667,
    longitude: 150.5833,
    accuracy: 4,
  },
  {
    postcode: 2342,
    place_name: 'Currabubula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2667,
    longitude: 150.7333,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Coomoo Coomoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5553,
    longitude: 150.3414,
    accuracy: 3,
  },
  {
    postcode: 2343,
    place_name: 'Warrah Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5667,
    longitude: 150.55,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Spring Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Quirindi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5076,
    longitude: 150.679,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Pine Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.499,
    longitude: 150.4539,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Blackville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6438,
    longitude: 150.2361,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Colly Blue',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4592,
    longitude: 150.1458,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Yarraman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6167,
    longitude: 150.2,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Wallabadah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5425,
    longitude: 150.8282,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Caroona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4066,
    longitude: 150.4264,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Borambil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5071,
    longitude: 150.6419,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Bundella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5664,
    longitude: 149.9929,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Windy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5976,
    longitude: 150.3836,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Yannergee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4437,
    longitude: 150.0303,
    accuracy: 4,
  },
  {
    postcode: 2343,
    place_name: 'Quipolly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4284,
    longitude: 150.6564,
    accuracy: 4,
  },
  {
    postcode: 2344,
    place_name: 'Duri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2141,
    longitude: 150.8219,
    accuracy: 4,
  },
  {
    postcode: 2344,
    place_name: 'Winton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0818,
    longitude: 150.7179,
    accuracy: 4,
  },
  {
    postcode: 2345,
    place_name: 'Garthowen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8951,
    longitude: 150.7835,
    accuracy: 3,
  },
  {
    postcode: 2345,
    place_name: 'Attunga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9303,
    longitude: 150.8468,
    accuracy: 4,
  },
  {
    postcode: 2346,
    place_name: 'Wongo Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7,
    longitude: 150.5,
    accuracy: 4,
  },
  {
    postcode: 2346,
    place_name: 'Borah Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7295,
    longitude: 150.6539,
    accuracy: 3,
  },
  {
    postcode: 2346,
    place_name: 'Manilla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7475,
    longitude: 150.7197,
    accuracy: 4,
  },
  {
    postcode: 2346,
    place_name: 'Halls Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7295,
    longitude: 150.6539,
    accuracy: 3,
  },
  {
    postcode: 2346,
    place_name: 'Klori',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8318,
    longitude: 150.7679,
    accuracy: 4,
  },
  {
    postcode: 2346,
    place_name: 'Namoi River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7295,
    longitude: 150.6539,
    accuracy: 3,
  },
  {
    postcode: 2346,
    place_name: 'Upper Manilla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6385,
    longitude: 150.6635,
    accuracy: 4,
  },
  {
    postcode: 2346,
    place_name: 'Wimborne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7295,
    longitude: 150.6539,
    accuracy: 3,
  },
  {
    postcode: 2346,
    place_name: 'New Mexico',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7424,
    longitude: 150.6185,
    accuracy: 4,
  },
  {
    postcode: 2346,
    place_name: 'Rushes Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7295,
    longitude: 150.6539,
    accuracy: 3,
  },
  {
    postcode: 2346,
    place_name: 'Warrabah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7295,
    longitude: 150.6539,
    accuracy: 3,
  },
  {
    postcode: 2347,
    place_name: 'Thirldene',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2297,
    longitude: 150.5773,
    accuracy: 3,
  },
  {
    postcode: 2347,
    place_name: 'Woodsreef',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3818,
    longitude: 150.7346,
    accuracy: 4,
  },
  {
    postcode: 2347,
    place_name: 'Cobbadah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2286,
    longitude: 150.5823,
    accuracy: 4,
  },
  {
    postcode: 2347,
    place_name: 'Banoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2297,
    longitude: 150.5773,
    accuracy: 3,
  },
  {
    postcode: 2347,
    place_name: 'Red Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0523,
    longitude: 149.1643,
    accuracy: 3,
  },
  {
    postcode: 2347,
    place_name: 'Barraba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3855,
    longitude: 150.609,
    accuracy: 4,
  },
  {
    postcode: 2347,
    place_name: 'Gundamulda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2297,
    longitude: 150.5773,
    accuracy: 3,
  },
  {
    postcode: 2347,
    place_name: 'Gulf Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1985,
    longitude: 150.7012,
    accuracy: 4,
  },
  {
    postcode: 2347,
    place_name: 'Mayvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2297,
    longitude: 150.5773,
    accuracy: 3,
  },
  {
    postcode: 2347,
    place_name: 'Upper Horton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1333,
    longitude: 150.4333,
    accuracy: 4,
  },
  {
    postcode: 2347,
    place_name: 'Ironbark',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2297,
    longitude: 150.5773,
    accuracy: 3,
  },
  {
    postcode: 2347,
    place_name: 'Lindesay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2297,
    longitude: 150.5773,
    accuracy: 3,
  },
  {
    postcode: 2347,
    place_name: 'Longarm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2297,
    longitude: 150.5773,
    accuracy: 3,
  },
  {
    postcode: 2348,
    place_name: 'New England Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.191,
    longitude: 150.7174,
    accuracy: 1,
  },
  {
    postcode: 2350,
    place_name: 'Hillgrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5712,
    longitude: 151.9045,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Tilbuster',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4333,
    longitude: 151.6667,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Dangarsleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6055,
    longitude: 151.6898,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Lyndhurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3167,
    longitude: 152.05,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Castle Doyle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6152,
    longitude: 151.7679,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Duval',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.461,
    longitude: 151.7789,
    accuracy: 3,
  },
  {
    postcode: 2350,
    place_name: 'Wongwibinda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2928,
    longitude: 152.1661,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Abington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2833,
    longitude: 151.1833,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Dumaresq',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4667,
    longitude: 151.5833,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Aberfoyle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2667,
    longitude: 152.0167,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Saumarez',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5546,
    longitude: 151.5837,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'East Armidale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.506,
    longitude: 151.9832,
    accuracy: 3,
  },
  {
    postcode: 2350,
    place_name: 'Boorolong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3235,
    longitude: 151.5346,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Wollomombi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5143,
    longitude: 152.0435,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Kellys Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5712,
    longitude: 151.6436,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Jeogla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5765,
    longitude: 152.1184,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Enmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7333,
    longitude: 151.8,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Wards Mistake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1318,
    longitude: 152.0096,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Armidale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5083,
    longitude: 151.6712,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Saumarez Ponds',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.461,
    longitude: 151.7789,
    accuracy: 3,
  },
  {
    postcode: 2350,
    place_name: 'Invergowrie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.512,
    longitude: 151.507,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Thalgarrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.461,
    longitude: 151.7789,
    accuracy: 3,
  },
  {
    postcode: 2350,
    place_name: 'West Armidale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.506,
    longitude: 151.9832,
    accuracy: 3,
  },
  {
    postcode: 2350,
    place_name: 'Puddledock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3667,
    longitude: 151.7667,
    accuracy: 4,
  },
  {
    postcode: 2350,
    place_name: 'Donald Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.461,
    longitude: 151.7789,
    accuracy: 3,
  },
  {
    postcode: 2350,
    place_name: 'Argyle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.461,
    longitude: 151.7789,
    accuracy: 3,
  },
  {
    postcode: 2350,
    place_name: 'Metz',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5785,
    longitude: 151.8735,
    accuracy: 4,
  },
  {
    postcode: 2351,
    place_name: 'University Of New England',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.85,
    longitude: 151.0635,
    accuracy: 1,
  },
  {
    postcode: 2352,
    place_name: 'Limbri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0388,
    longitude: 151.1553,
    accuracy: 4,
  },
  {
    postcode: 2352,
    place_name: 'Mulla Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0695,
    longitude: 151.0734,
    accuracy: 3,
  },
  {
    postcode: 2352,
    place_name: 'Kootingal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0586,
    longitude: 151.0535,
    accuracy: 4,
  },
  {
    postcode: 2352,
    place_name: 'Tintinhull',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0925,
    longitude: 151.0202,
    accuracy: 4,
  },
  {
    postcode: 2353,
    place_name: 'Moonbi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0177,
    longitude: 151.0706,
    accuracy: 4,
  },
  {
    postcode: 2354,
    place_name: 'Woolbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9652,
    longitude: 151.3512,
    accuracy: 4,
  },
  {
    postcode: 2354,
    place_name: 'Niangala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3001,
    longitude: 151.4049,
    accuracy: 4,
  },
  {
    postcode: 2354,
    place_name: 'Kentucky',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7614,
    longitude: 151.4489,
    accuracy: 4,
  },
  {
    postcode: 2354,
    place_name: 'Walcha',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9849,
    longitude: 151.5934,
    accuracy: 4,
  },
  {
    postcode: 2354,
    place_name: 'Nowendoc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5175,
    longitude: 151.7148,
    accuracy: 4,
  },
  {
    postcode: 2354,
    place_name: 'Wollun',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8464,
    longitude: 151.4263,
    accuracy: 4,
  },
  {
    postcode: 2354,
    place_name: 'Yarrowitch',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2701,
    longitude: 151.971,
    accuracy: 4,
  },
  {
    postcode: 2354,
    place_name: 'Walcha Road',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9441,
    longitude: 151.4,
    accuracy: 4,
  },
  {
    postcode: 2354,
    place_name: 'Kentucky South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7973,
    longitude: 151.4401,
    accuracy: 4,
  },
  {
    postcode: 2355,
    place_name: 'Watsons Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7384,
    longitude: 151.0513,
    accuracy: 4,
  },
  {
    postcode: 2355,
    place_name: 'Retreat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6068,
    longitude: 151.1068,
    accuracy: 4,
  },
  {
    postcode: 2355,
    place_name: 'Bendemeer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8865,
    longitude: 151.1535,
    accuracy: 4,
  },
  {
    postcode: 2356,
    place_name: 'Gwabegar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6101,
    longitude: 148.9675,
    accuracy: 4,
  },
  {
    postcode: 2357,
    place_name: 'Coonabarabran',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2773,
    longitude: 149.279,
    accuracy: 4,
  },
  {
    postcode: 2357,
    place_name: 'Ulamambri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.328,
    longitude: 149.4007,
    accuracy: 4,
  },
  {
    postcode: 2357,
    place_name: 'Gowang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4235,
    longitude: 149.0846,
    accuracy: 4,
  },
  {
    postcode: 2357,
    place_name: 'Wattle Springs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2332,
    longitude: 149.2463,
    accuracy: 3,
  },
  {
    postcode: 2357,
    place_name: 'Purlewaugh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.346,
    longitude: 149.5092,
    accuracy: 4,
  },
  {
    postcode: 2357,
    place_name: 'Box Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.25,
    longitude: 148.8167,
    accuracy: 4,
  },
  {
    postcode: 2357,
    place_name: 'Dandry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0277,
    longitude: 149.4043,
    accuracy: 4,
  },
  {
    postcode: 2357,
    place_name: 'Rocky Glen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1167,
    longitude: 149.5667,
    accuracy: 4,
  },
  {
    postcode: 2357,
    place_name: 'Tannabar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2332,
    longitude: 149.2463,
    accuracy: 3,
  },
  {
    postcode: 2357,
    place_name: 'Bugaldie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1252,
    longitude: 149.1116,
    accuracy: 4,
  },
  {
    postcode: 2357,
    place_name: 'Bomera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2332,
    longitude: 149.2463,
    accuracy: 3,
  },
  {
    postcode: 2358,
    place_name: 'Gostwyck',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6941,
    longitude: 151.5834,
    accuracy: 4,
  },
  {
    postcode: 2358,
    place_name: 'Arding',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5677,
    longitude: 151.5254,
    accuracy: 4,
  },
  {
    postcode: 2358,
    place_name: 'Yarrowyck',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4639,
    longitude: 151.3222,
    accuracy: 4,
  },
  {
    postcode: 2358,
    place_name: 'Enmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7333,
    longitude: 151.8,
    accuracy: 4,
  },
  {
    postcode: 2358,
    place_name: 'Rocky River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6167,
    longitude: 151.5,
    accuracy: 4,
  },
  {
    postcode: 2358,
    place_name: 'Torryburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.45,
    longitude: 151.2167,
    accuracy: 4,
  },
  {
    postcode: 2358,
    place_name: 'Balala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6046,
    longitude: 151.4612,
    accuracy: 3,
  },
  {
    postcode: 2358,
    place_name: 'Salisbury Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7667,
    longitude: 151.5333,
    accuracy: 4,
  },
  {
    postcode: 2358,
    place_name: 'Mihi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6046,
    longitude: 151.4612,
    accuracy: 3,
  },
  {
    postcode: 2358,
    place_name: 'Kingstown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.504,
    longitude: 151.1139,
    accuracy: 4,
  },
  {
    postcode: 2358,
    place_name: 'Uralla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6411,
    longitude: 151.5007,
    accuracy: 4,
  },
  {
    postcode: 2359,
    place_name: 'Bakers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2667,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2359,
    place_name: 'Bundarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1721,
    longitude: 151.0762,
    accuracy: 4,
  },
  {
    postcode: 2359,
    place_name: 'Aberdeen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1659,
    longitude: 150.89,
    accuracy: 4,
  },
  {
    postcode: 2359,
    place_name: 'Camerons Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2194,
    longitude: 151.0464,
    accuracy: 3,
  },
  {
    postcode: 2360,
    place_name: 'Wandera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6833,
    longitude: 151.1667,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Copeton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9167,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Mount Russell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6772,
    longitude: 150.9286,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Little Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7303,
    longitude: 150.955,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Auburn Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.821,
    longitude: 151.0454,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Rob Roy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.75,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Cherry Tree Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5368,
    longitude: 150.9946,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Stanborough',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.95,
    longitude: 151.15,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Sapphire',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6846,
    longitude: 151.3375,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Swanbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.718,
    longitude: 151.0965,
    accuracy: 3,
  },
  {
    postcode: 2360,
    place_name: 'Inverell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.774,
    longitude: 151.1117,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Bukkulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5045,
    longitude: 151.1293,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Howell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.95,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Spring Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.718,
    longitude: 151.0965,
    accuracy: 3,
  },
  {
    postcode: 2360,
    place_name: 'Gilgai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8527,
    longitude: 151.1181,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Nullamanna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.65,
    longitude: 151.2167,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Brodies Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8094,
    longitude: 151.1956,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Long Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.718,
    longitude: 151.0965,
    accuracy: 3,
  },
  {
    postcode: 2360,
    place_name: 'Woodstock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.718,
    longitude: 151.0965,
    accuracy: 3,
  },
  {
    postcode: 2360,
    place_name: 'Wallangra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.2468,
    longitude: 150.8931,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Oakwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6458,
    longitude: 151.0442,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Kings Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.718,
    longitude: 151.0965,
    accuracy: 3,
  },
  {
    postcode: 2360,
    place_name: 'Newstead',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8333,
    longitude: 151.35,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Paradise',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.718,
    longitude: 151.0965,
    accuracy: 3,
  },
  {
    postcode: 2360,
    place_name: 'Elsmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8065,
    longitude: 151.2689,
    accuracy: 4,
  },
  {
    postcode: 2360,
    place_name: 'Gum Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7943,
    longitude: 150.9296,
    accuracy: 3,
  },
  {
    postcode: 2360,
    place_name: 'Graman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4694,
    longitude: 150.9267,
    accuracy: 4,
  },
  {
    postcode: 2361,
    place_name: 'Ashford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3219,
    longitude: 151.0931,
    accuracy: 4,
  },
  {
    postcode: 2361,
    place_name: 'Atholwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9985,
    longitude: 151.0512,
    accuracy: 4,
  },
  {
    postcode: 2361,
    place_name: 'Limestone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1295,
    longitude: 151.1366,
    accuracy: 3,
  },
  {
    postcode: 2361,
    place_name: 'Bonshaw',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0479,
    longitude: 151.2757,
    accuracy: 4,
  },
  {
    postcode: 2361,
    place_name: 'Pindaroi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1295,
    longitude: 151.1366,
    accuracy: 3,
  },
  {
    postcode: 2365,
    place_name: 'Oban',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1333,
    longitude: 151.8667,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Brushy Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1052,
    longitude: 151.7015,
    accuracy: 3,
  },
  {
    postcode: 2365,
    place_name: 'Tubbamurra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1152,
    longitude: 151.7679,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Glencoe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9249,
    longitude: 151.7223,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Brockley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2833,
    longitude: 151.8167,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Ben Lomond',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0167,
    longitude: 151.6667,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Georges Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7461,
    longitude: 152.1945,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Guyra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2167,
    longitude: 151.6744,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'The Gulf',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3167,
    longitude: 151.4833,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Falconer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1052,
    longitude: 151.7015,
    accuracy: 3,
  },
  {
    postcode: 2365,
    place_name: 'Llangothlin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1372,
    longitude: 151.6843,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Black Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3107,
    longitude: 151.6582,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Baldersleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2555,
    longitude: 151.426,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Bald Blair',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1052,
    longitude: 151.7015,
    accuracy: 3,
  },
  {
    postcode: 2365,
    place_name: 'Mount Mitchell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0167,
    longitude: 151.85,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Wandsworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0671,
    longitude: 151.52,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Maybole',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8985,
    longitude: 151.5846,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'South Guyra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1052,
    longitude: 151.7015,
    accuracy: 3,
  },
  {
    postcode: 2365,
    place_name: 'Backwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0652,
    longitude: 151.8846,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Tenterden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1318,
    longitude: 151.4346,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Green Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9176,
    longitude: 149.5836,
    accuracy: 4,
  },
  {
    postcode: 2365,
    place_name: 'Briarbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1052,
    longitude: 151.7015,
    accuracy: 3,
  },
  {
    postcode: 2365,
    place_name: 'Bassendean',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1052,
    longitude: 151.7015,
    accuracy: 3,
  },
  {
    postcode: 2365,
    place_name: 'Glen Nevis',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1052,
    longitude: 151.7015,
    accuracy: 3,
  },
  {
    postcode: 2365,
    place_name: 'The Basin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1052,
    longitude: 151.7015,
    accuracy: 3,
  },
  {
    postcode: 2365,
    place_name: 'New Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1052,
    longitude: 151.7015,
    accuracy: 3,
  },
  {
    postcode: 2369,
    place_name: 'Tingha',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9559,
    longitude: 151.2125,
    accuracy: 4,
  },
  {
    postcode: 2369,
    place_name: 'Old Mill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9196,
    longitude: 151.2146,
    accuracy: 3,
  },
  {
    postcode: 2369,
    place_name: 'Stannifer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8833,
    longitude: 151.2167,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Dundee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5724,
    longitude: 151.8663,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Moggs Swamp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Shannon Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6985,
    longitude: 151.8679,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Glen Innes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7349,
    longitude: 151.7385,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Wellingrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6334,
    longitude: 151.5692,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Morven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Glen Elgin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5833,
    longitude: 152.1167,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Kingsland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Stonehenge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8164,
    longitude: 151.7351,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Diehard',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Reddestone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Pinkett',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Matheson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7383,
    longitude: 151.574,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Bald Nob',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Swan Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7833,
    longitude: 151.4333,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Gibraltar Range',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Newton Boyd',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7976,
    longitude: 152.2659,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Lambs Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Kookabookra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0333,
    longitude: 152.0667,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Yarrowford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Moogem',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7311,
    longitude: 151.8242,
    accuracy: 3,
  },
  {
    postcode: 2370,
    place_name: 'Furracabad',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7818,
    longitude: 151.6679,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Kingsgate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8,
    longitude: 151.9833,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Red Range',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7757,
    longitude: 151.8966,
    accuracy: 4,
  },
  {
    postcode: 2370,
    place_name: 'Rangers Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5167,
    longitude: 151.75,
    accuracy: 4,
  },
  {
    postcode: 2371,
    place_name: 'Rocky Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3,
    longitude: 151.3667,
    accuracy: 4,
  },
  {
    postcode: 2371,
    place_name: 'Yellow Dam',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3724,
    longitude: 151.6768,
    accuracy: 3,
  },
  {
    postcode: 2371,
    place_name: 'Capoompeta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3724,
    longitude: 151.6768,
    accuracy: 3,
  },
  {
    postcode: 2371,
    place_name: 'Emmaville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4441,
    longitude: 151.5979,
    accuracy: 4,
  },
  {
    postcode: 2371,
    place_name: 'Stannum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3279,
    longitude: 151.7869,
    accuracy: 4,
  },
  {
    postcode: 2371,
    place_name: 'Deepwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4404,
    longitude: 151.8461,
    accuracy: 4,
  },
  {
    postcode: 2371,
    place_name: 'Wellington Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4152,
    longitude: 151.7512,
    accuracy: 4,
  },
  {
    postcode: 2371,
    place_name: 'Torrington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3152,
    longitude: 151.691,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Silent Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1167,
    longitude: 151.6667,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Woodside',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1538,
    longitude: 151.9669,
    accuracy: 3,
  },
  {
    postcode: 2372,
    place_name: 'Liston',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6492,
    longitude: 152.087,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Bolivia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3015,
    longitude: 151.9539,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Sandy Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9167,
    longitude: 152.25,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Back Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1538,
    longitude: 151.9669,
    accuracy: 3,
  },
  {
    postcode: 2372,
    place_name: 'Rocky River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6167,
    longitude: 151.5,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Tarban',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9818,
    longitude: 151.9012,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Black Swamp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29,
    longitude: 152.15,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Boorook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1538,
    longitude: 151.9669,
    accuracy: 3,
  },
  {
    postcode: 2372,
    place_name: 'Sandy Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.2307,
    longitude: 152.0048,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Rivertree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1538,
    longitude: 151.9669,
    accuracy: 3,
  },
  {
    postcode: 2372,
    place_name: 'Boonoo Boonoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8777,
    longitude: 152.1012,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Dumaresq Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1538,
    longitude: 151.9669,
    accuracy: 3,
  },
  {
    postcode: 2372,
    place_name: 'Mole River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1167,
    longitude: 151.7667,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Wylie Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5485,
    longitude: 152.1512,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Forest Land',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1538,
    longitude: 151.9669,
    accuracy: 3,
  },
  {
    postcode: 2372,
    place_name: 'Timbarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0167,
    longitude: 152.1833,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Bookookoorara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1538,
    longitude: 151.9669,
    accuracy: 3,
  },
  {
    postcode: 2372,
    place_name: 'Tenterfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0495,
    longitude: 152.0195,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Bungulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1333,
    longitude: 151.9833,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Carrolls Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8227,
    longitude: 152.103,
    accuracy: 4,
  },
  {
    postcode: 2372,
    place_name: 'Willsons Downfall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1538,
    longitude: 151.9669,
    accuracy: 3,
  },
  {
    postcode: 2372,
    place_name: 'Cullendore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1538,
    longitude: 151.9669,
    accuracy: 3,
  },
  {
    postcode: 2379,
    place_name: 'Goolhi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1,
    longitude: 149.9,
    accuracy: 3,
  },
  {
    postcode: 2379,
    place_name: 'Mullaley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1011,
    longitude: 149.9058,
    accuracy: 4,
  },
  {
    postcode: 2379,
    place_name: 'Napier Lane',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1,
    longitude: 149.9,
    accuracy: 3,
  },
  {
    postcode: 2379,
    place_name: 'Nombi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1,
    longitude: 149.9,
    accuracy: 3,
  },
  {
    postcode: 2380,
    place_name: 'Coocooboonah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31,
    longitude: 150.1333,
    accuracy: 4,
  },
  {
    postcode: 2380,
    place_name: 'Ghoolendaadi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9702,
    longitude: 150.1854,
    accuracy: 3,
  },
  {
    postcode: 2380,
    place_name: 'Kelvin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8152,
    longitude: 150.3512,
    accuracy: 4,
  },
  {
    postcode: 2380,
    place_name: 'Rangari',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9702,
    longitude: 150.1854,
    accuracy: 3,
  },
  {
    postcode: 2380,
    place_name: 'Orange Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0473,
    longitude: 146.6726,
    accuracy: 4,
  },
  {
    postcode: 2380,
    place_name: 'Milroy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.15,
    longitude: 150.05,
    accuracy: 4,
  },
  {
    postcode: 2380,
    place_name: 'Emerald Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8833,
    longitude: 150.0833,
    accuracy: 4,
  },
  {
    postcode: 2380,
    place_name: 'Marys Mount',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7549,
    longitude: 150.7844,
    accuracy: 4,
  },
  {
    postcode: 2380,
    place_name: 'Gunnedah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9807,
    longitude: 150.2584,
    accuracy: 4,
  },
  {
    postcode: 2380,
    place_name: 'Burburgate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9908,
    longitude: 150.2538,
    accuracy: 3,
  },
  {
    postcode: 2380,
    place_name: 'Blue Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9702,
    longitude: 150.1854,
    accuracy: 3,
  },
  {
    postcode: 2381,
    place_name: 'Curlewis',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1167,
    longitude: 150.2675,
    accuracy: 4,
  },
  {
    postcode: 2381,
    place_name: 'Pullaming',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2415,
    longitude: 150.4857,
    accuracy: 3,
  },
  {
    postcode: 2381,
    place_name: 'Breeza',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2497,
    longitude: 150.4629,
    accuracy: 4,
  },
  {
    postcode: 2381,
    place_name: 'Tambar Springs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3495,
    longitude: 149.83,
    accuracy: 4,
  },
  {
    postcode: 2381,
    place_name: 'Premer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4567,
    longitude: 149.909,
    accuracy: 4,
  },
  {
    postcode: 2382,
    place_name: 'Maules Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7365,
    longitude: 150.0637,
    accuracy: 3,
  },
  {
    postcode: 2382,
    place_name: 'Willala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8152,
    longitude: 149.8679,
    accuracy: 4,
  },
  {
    postcode: 2382,
    place_name: 'Wean',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7,
    longitude: 150.2833,
    accuracy: 4,
  },
  {
    postcode: 2382,
    place_name: 'Boggabri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7095,
    longitude: 150.0412,
    accuracy: 4,
  },
  {
    postcode: 2386,
    place_name: 'Drildool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1167,
    longitude: 148.9667,
    accuracy: 3,
  },
  {
    postcode: 2386,
    place_name: 'Burren Junction',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0985,
    longitude: 148.9679,
    accuracy: 4,
  },
  {
    postcode: 2386,
    place_name: 'Nowley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1167,
    longitude: 148.9667,
    accuracy: 3,
  },
  {
    postcode: 2387,
    place_name: 'Rowena',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8169,
    longitude: 148.909,
    accuracy: 4,
  },
  {
    postcode: 2387,
    place_name: 'Bulyeroi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8,
    longitude: 148.9167,
    accuracy: 3,
  },
  {
    postcode: 2388,
    place_name: 'Merah North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1831,
    longitude: 149.2951,
    accuracy: 4,
  },
  {
    postcode: 2388,
    place_name: 'Cuttabri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2785,
    longitude: 149.2051,
    accuracy: 3,
  },
  {
    postcode: 2388,
    place_name: 'Yarrie Lake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.35,
    longitude: 149.5167,
    accuracy: 4,
  },
  {
    postcode: 2388,
    place_name: 'Spring Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 2388,
    place_name: 'Boolcarroll',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.502,
    longitude: 149.4814,
    accuracy: 3,
  },
  {
    postcode: 2388,
    place_name: 'Pilliga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3558,
    longitude: 148.8879,
    accuracy: 4,
  },
  {
    postcode: 2388,
    place_name: 'The Pilliga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.502,
    longitude: 149.4814,
    accuracy: 3,
  },
  {
    postcode: 2388,
    place_name: 'Wee Waa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2266,
    longitude: 149.4404,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Jacks Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4977,
    longitude: 149.7394,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Kaputar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2941,
    longitude: 150.1447,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Turrawan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4642,
    longitude: 149.8849,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Eulah Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.364,
    longitude: 149.8906,
    accuracy: 3,
  },
  {
    postcode: 2390,
    place_name: 'Edgeroi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1221,
    longitude: 149.8012,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Harparary',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5333,
    longitude: 150.0167,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Narrabri West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3333,
    longitude: 149.75,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Berrigal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.364,
    longitude: 149.8906,
    accuracy: 3,
  },
  {
    postcode: 2390,
    place_name: 'Rocky Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0333,
    longitude: 150.2667,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Baan Baa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5978,
    longitude: 149.9496,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Bullawa Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3068,
    longitude: 150.0012,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Couradda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.364,
    longitude: 149.8906,
    accuracy: 3,
  },
  {
    postcode: 2390,
    place_name: 'Back Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.364,
    longitude: 149.8906,
    accuracy: 3,
  },
  {
    postcode: 2390,
    place_name: 'Narrabri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3261,
    longitude: 149.7837,
    accuracy: 4,
  },
  {
    postcode: 2390,
    place_name: 'Tarriaro',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.364,
    longitude: 149.8906,
    accuracy: 3,
  },
  {
    postcode: 2390,
    place_name: 'Bohena Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4167,
    longitude: 149.7,
    accuracy: 4,
  },
  {
    postcode: 2395,
    place_name: 'Ropers Road',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5928,
    longitude: 149.481,
    accuracy: 3,
  },
  {
    postcode: 2395,
    place_name: 'Binnaway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5523,
    longitude: 149.3787,
    accuracy: 4,
  },
  {
    postcode: 2395,
    place_name: 'Weetaliba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.644,
    longitude: 149.5845,
    accuracy: 4,
  },
  {
    postcode: 2396,
    place_name: 'Barwon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9329,
    longitude: 149.0119,
    accuracy: 3,
  },
  {
    postcode: 2396,
    place_name: 'Goorianawa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0833,
    longitude: 148.9333,
    accuracy: 4,
  },
  {
    postcode: 2396,
    place_name: 'Kenebri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7751,
    longitude: 149.0236,
    accuracy: 4,
  },
  {
    postcode: 2396,
    place_name: 'Baradine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9487,
    longitude: 149.0692,
    accuracy: 4,
  },
  {
    postcode: 2397,
    place_name: 'Millie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8152,
    longitude: 149.5679,
    accuracy: 4,
  },
  {
    postcode: 2397,
    place_name: 'Bellata',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9191,
    longitude: 149.7889,
    accuracy: 4,
  },
  {
    postcode: 2397,
    place_name: 'Jews Lagoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8667,
    longitude: 149.675,
    accuracy: 3,
  },
  {
    postcode: 2398,
    place_name: 'Gurley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7333,
    longitude: 149.8,
    accuracy: 4,
  },
  {
    postcode: 2399,
    place_name: 'Biniguy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5435,
    longitude: 150.2033,
    accuracy: 4,
  },
  {
    postcode: 2399,
    place_name: 'Pallamallawa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4741,
    longitude: 150.134,
    accuracy: 4,
  },
  {
    postcode: 2400,
    place_name: 'Bullarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4657,
    longitude: 150.0146,
    accuracy: 3,
  },
  {
    postcode: 2400,
    place_name: 'Terry Hie Hie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7968,
    longitude: 150.148,
    accuracy: 4,
  },
  {
    postcode: 2400,
    place_name: 'Moree East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4657,
    longitude: 150.0146,
    accuracy: 3,
  },
  {
    postcode: 2400,
    place_name: 'Moree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4628,
    longitude: 149.8416,
    accuracy: 4,
  },
  {
    postcode: 2400,
    place_name: 'Crooble',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.2659,
    longitude: 150.2555,
    accuracy: 4,
  },
  {
    postcode: 2400,
    place_name: 'Tulloona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4657,
    longitude: 150.0146,
    accuracy: 3,
  },
  {
    postcode: 2400,
    place_name: 'Mallowa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4657,
    longitude: 150.0146,
    accuracy: 3,
  },
  {
    postcode: 2400,
    place_name: 'Ashley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3136,
    longitude: 149.8081,
    accuracy: 4,
  },
  {
    postcode: 2401,
    place_name: 'Gravesend',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5833,
    longitude: 150.3331,
    accuracy: 4,
  },
  {
    postcode: 2402,
    place_name: 'Coolatai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.2571,
    longitude: 150.7473,
    accuracy: 4,
  },
  {
    postcode: 2402,
    place_name: 'Warialda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5435,
    longitude: 150.5754,
    accuracy: 4,
  },
  {
    postcode: 2402,
    place_name: 'Warialda Rail',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5766,
    longitude: 150.5319,
    accuracy: 4,
  },
  {
    postcode: 2403,
    place_name: 'Delungra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.654,
    longitude: 150.83,
    accuracy: 4,
  },
  {
    postcode: 2403,
    place_name: 'Myall Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.75,
    longitude: 150.7667,
    accuracy: 4,
  },
  {
    postcode: 2403,
    place_name: 'Koloona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.626,
    longitude: 150.7333,
    accuracy: 4,
  },
  {
    postcode: 2403,
    place_name: 'Gragin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6791,
    longitude: 150.7711,
    accuracy: 3,
  },
  {
    postcode: 2403,
    place_name: 'Balfours Peak',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6791,
    longitude: 150.7711,
    accuracy: 3,
  },
  {
    postcode: 2404,
    place_name: 'Bingara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8693,
    longitude: 150.572,
    accuracy: 4,
  },
  {
    postcode: 2404,
    place_name: 'Elcombe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7667,
    longitude: 150.3833,
    accuracy: 4,
  },
  {
    postcode: 2404,
    place_name: 'Keera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9818,
    longitude: 150.7679,
    accuracy: 4,
  },
  {
    postcode: 2404,
    place_name: 'Gineroi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7667,
    longitude: 150.5,
    accuracy: 4,
  },
  {
    postcode: 2404,
    place_name: 'Bangheet',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8782,
    longitude: 150.537,
    accuracy: 3,
  },
  {
    postcode: 2404,
    place_name: 'Pallal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9167,
    longitude: 150.3833,
    accuracy: 4,
  },
  {
    postcode: 2404,
    place_name: 'Riverview',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.824,
    longitude: 151.1602,
    accuracy: 4,
  },
  {
    postcode: 2404,
    place_name: 'Dinoga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9667,
    longitude: 150.6,
    accuracy: 4,
  },
  {
    postcode: 2404,
    place_name: 'Upper Bingara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.05,
    longitude: 150.65,
    accuracy: 4,
  },
  {
    postcode: 2405,
    place_name: 'Boomi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7233,
    longitude: 149.5794,
    accuracy: 4,
  },
  {
    postcode: 2405,
    place_name: 'Garah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0759,
    longitude: 149.6377,
    accuracy: 4,
  },
  {
    postcode: 2406,
    place_name: 'Weemelah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0185,
    longitude: 149.2548,
    accuracy: 4,
  },
  {
    postcode: 2406,
    place_name: 'Mungindi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9767,
    longitude: 148.9881,
    accuracy: 4,
  },
  {
    postcode: 2408,
    place_name: 'North Star',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9348,
    longitude: 150.3896,
    accuracy: 4,
  },
  {
    postcode: 2408,
    place_name: 'Blue Nobby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0246,
    longitude: 150.4374,
    accuracy: 3,
  },
  {
    postcode: 2408,
    place_name: 'Yallaroi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1167,
    longitude: 150.4833,
    accuracy: 4,
  },
  {
    postcode: 2409,
    place_name: 'Boggabilla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6048,
    longitude: 150.3593,
    accuracy: 4,
  },
  {
    postcode: 2409,
    place_name: 'Boonal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6048,
    longitude: 150.3593,
    accuracy: 3,
  },
  {
    postcode: 2410,
    place_name: 'Yetman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9043,
    longitude: 150.7769,
    accuracy: 4,
  },
  {
    postcode: 2410,
    place_name: 'Twin Rivers',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9,
    longitude: 150.7833,
    accuracy: 3,
  },
  {
    postcode: 2411,
    place_name: 'Croppa Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1331,
    longitude: 150.3014,
    accuracy: 4,
  },
  {
    postcode: 2415,
    place_name: 'Monkerai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2915,
    longitude: 151.8602,
    accuracy: 4,
  },
  {
    postcode: 2415,
    place_name: 'Nooroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3207,
    longitude: 151.8968,
    accuracy: 3,
  },
  {
    postcode: 2415,
    place_name: 'Stroud Road',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.35,
    longitude: 151.9333,
    accuracy: 4,
  },
  {
    postcode: 2415,
    place_name: 'Upper Karuah River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3207,
    longitude: 151.8968,
    accuracy: 3,
  },
  {
    postcode: 2415,
    place_name: 'Weismantels',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2608,
    longitude: 151.9427,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Fosterton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3474,
    longitude: 151.7612,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Wallarobba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5046,
    longitude: 151.7007,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Underbank',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Dungog',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4003,
    longitude: 151.7576,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Stroud Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Bendolba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3518,
    longitude: 151.7301,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Main Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Wirragulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4596,
    longitude: 151.746,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Sugarloaf',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Brookfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4985,
    longitude: 151.7679,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Bandon Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2999,
    longitude: 151.7159,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Salisbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.25,
    longitude: 151.5667,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Hanleys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Marshdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3707,
    longitude: 151.7022,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Munni',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Flat Tops',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Wallaringa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Cambra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Tabbil Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 151.6842,
    accuracy: 3,
  },
  {
    postcode: 2420,
    place_name: 'Hilldale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5,
    longitude: 151.65,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Alison',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2768,
    longitude: 151.4021,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Chichester',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2,
    longitude: 151.6167,
    accuracy: 4,
  },
  {
    postcode: 2420,
    place_name: 'Martins Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.55,
    longitude: 151.6167,
    accuracy: 4,
  },
  {
    postcode: 2421,
    place_name: 'Webbers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6061,
    longitude: 151.6159,
    accuracy: 3,
  },
  {
    postcode: 2421,
    place_name: 'Tocal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.633,
    longitude: 151.5913,
    accuracy: 4,
  },
  {
    postcode: 2421,
    place_name: 'Fishers Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6061,
    longitude: 151.6159,
    accuracy: 3,
  },
  {
    postcode: 2421,
    place_name: 'Trevallyn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5432,
    longitude: 151.5372,
    accuracy: 3,
  },
  {
    postcode: 2421,
    place_name: 'Vacy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5316,
    longitude: 151.569,
    accuracy: 4,
  },
  {
    postcode: 2421,
    place_name: 'Paterson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6061,
    longitude: 151.6159,
    accuracy: 4,
  },
  {
    postcode: 2421,
    place_name: 'Torryburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.45,
    longitude: 151.2167,
    accuracy: 4,
  },
  {
    postcode: 2421,
    place_name: 'Summer Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8915,
    longitude: 151.1382,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Gloucester Tops',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9223,
    longitude: 151.5346,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Belbora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0333,
    longitude: 152.1833,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Barrington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9726,
    longitude: 151.9031,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Bulliac',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9167,
    longitude: 152.0333,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Stratford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1167,
    longitude: 151.9333,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Cobark',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9485,
    longitude: 151.7096,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Rawdon Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9833,
    longitude: 151.7,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Gangat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0235,
    longitude: 152.0846,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Gloucester',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0077,
    longitude: 151.9633,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Craven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1485,
    longitude: 151.9512,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Copeland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32,
    longitude: 151.8333,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Giro',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7152,
    longitude: 151.8096,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Bundook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8906,
    longitude: 152.1219,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Wards River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2166,
    longitude: 151.9423,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Forbesdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Rookhurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8833,
    longitude: 151.8833,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Dewitt',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Curricabark',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7447,
    longitude: 151.6389,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Callaghans Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Bowman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9223,
    longitude: 151.7831,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Faulkland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Mograni',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0333,
    longitude: 152.0333,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Bindera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Upper Bowman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9223,
    longitude: 151.7831,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Titaatee Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Baxters Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Barrington Tops',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9223,
    longitude: 151.5346,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Coneac',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Terreel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Bretti',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9362,
    longitude: 151.7966,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Craven Plateau',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Tugrabakh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Invergordon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Glen Ward',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Bowman Farm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Kia Ora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Back Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Waukivory',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1167,
    longitude: 152.05,
    accuracy: 4,
  },
  {
    postcode: 2422,
    place_name: 'Tibbuc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Woko',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Berrico',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Mernot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Wallanbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Moppy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Mares Run',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9758,
    longitude: 151.9086,
    accuracy: 3,
  },
  {
    postcode: 2422,
    place_name: 'Bakers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2667,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Upper Myall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3,
    longitude: 152.1667,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Bungwahl',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3931,
    longitude: 152.4487,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Crawford River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.45,
    longitude: 152.1333,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Violet Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3182,
    longitude: 152.2701,
    accuracy: 3,
  },
  {
    postcode: 2423,
    place_name: 'Wang Wauk',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1667,
    longitude: 152.3333,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Mayers Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.388,
    longitude: 152.3318,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Wootton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3021,
    longitude: 152.3171,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Boolambayte',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3982,
    longitude: 152.2696,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Topi Topi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3667,
    longitude: 152.3833,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Bulahdelah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.407,
    longitude: 152.2119,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Markwell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3333,
    longitude: 152.1833,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Mungo Brush',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3182,
    longitude: 152.2701,
    accuracy: 3,
  },
  {
    postcode: 2423,
    place_name: 'Warranulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1963,
    longitude: 152.1354,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Myall Lake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3182,
    longitude: 152.2701,
    accuracy: 3,
  },
  {
    postcode: 2423,
    place_name: 'Nerong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5235,
    longitude: 152.1982,
    accuracy: 3,
  },
  {
    postcode: 2423,
    place_name: 'Bombah Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3182,
    longitude: 152.2701,
    accuracy: 3,
  },
  {
    postcode: 2423,
    place_name: 'Coolongolook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2174,
    longitude: 152.3221,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Yagon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3182,
    longitude: 152.2701,
    accuracy: 3,
  },
  {
    postcode: 2423,
    place_name: 'Willina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1766,
    longitude: 152.2712,
    accuracy: 4,
  },
  {
    postcode: 2423,
    place_name: 'Seal Rocks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4399,
    longitude: 152.5317,
    accuracy: 4,
  },
  {
    postcode: 2424,
    place_name: 'Knorrit Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8414,
    longitude: 152.112,
    accuracy: 4,
  },
  {
    postcode: 2424,
    place_name: 'Mount George',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8884,
    longitude: 152.1854,
    accuracy: 4,
  },
  {
    postcode: 2424,
    place_name: 'Cells River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8167,
    longitude: 152.0792,
    accuracy: 3,
  },
  {
    postcode: 2424,
    place_name: 'Cundle Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8167,
    longitude: 151.9833,
    accuracy: 4,
  },
  {
    postcode: 2424,
    place_name: 'Caffreys Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8167,
    longitude: 152.0792,
    accuracy: 3,
  },
  {
    postcode: 2424,
    place_name: 'Number One',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.738,
    longitude: 152.0665,
    accuracy: 4,
  },
  {
    postcode: 2424,
    place_name: 'Tiri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8167,
    longitude: 152.0792,
    accuracy: 3,
  },
  {
    postcode: 2424,
    place_name: 'Cooplacurripa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8167,
    longitude: 152.0792,
    accuracy: 3,
  },
  {
    postcode: 2424,
    place_name: 'Knorrit Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8167,
    longitude: 152.0792,
    accuracy: 3,
  },
  {
    postcode: 2425,
    place_name: 'Allworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5368,
    longitude: 151.9418,
    accuracy: 4,
  },
  {
    postcode: 2425,
    place_name: 'Washpool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.395,
    longitude: 152.0141,
    accuracy: 3,
  },
  {
    postcode: 2425,
    place_name: 'Stroud',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4037,
    longitude: 151.967,
    accuracy: 4,
  },
  {
    postcode: 2425,
    place_name: 'The Branch',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5333,
    longitude: 152.0167,
    accuracy: 4,
  },
  {
    postcode: 2425,
    place_name: 'Girvan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4777,
    longitude: 152.0651,
    accuracy: 4,
  },
  {
    postcode: 2425,
    place_name: 'Booral',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4685,
    longitude: 151.986,
    accuracy: 4,
  },
  {
    postcode: 2426,
    place_name: 'Moto',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8127,
    longitude: 152.589,
    accuracy: 3,
  },
  {
    postcode: 2426,
    place_name: 'Coopernook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8254,
    longitude: 152.6114,
    accuracy: 4,
  },
  {
    postcode: 2426,
    place_name: 'Langley Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8,
    longitude: 152.5667,
    accuracy: 4,
  },
  {
    postcode: 2427,
    place_name: 'Harrington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8723,
    longitude: 152.6917,
    accuracy: 4,
  },
  {
    postcode: 2427,
    place_name: 'Crowdy Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8448,
    longitude: 152.7389,
    accuracy: 3,
  },
  {
    postcode: 2428,
    place_name: 'Whoota',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3157,
    longitude: 152.4818,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Coomba Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.28,
    longitude: 152.5039,
    accuracy: 3,
  },
  {
    postcode: 2428,
    place_name: 'Coomba Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2377,
    longitude: 152.4571,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Elizabeth Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3195,
    longitude: 152.5275,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Blueys Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3472,
    longitude: 152.5357,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Smiths Lake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3824,
    longitude: 152.5018,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Booti Booti',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3168,
    longitude: 152.5221,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Tiona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.28,
    longitude: 152.5039,
    accuracy: 3,
  },
  {
    postcode: 2428,
    place_name: 'Forster',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1814,
    longitude: 152.5172,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Shallow Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.28,
    longitude: 152.5039,
    accuracy: 3,
  },
  {
    postcode: 2428,
    place_name: 'Green Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2493,
    longitude: 152.5176,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Forster Shopping Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2335,
    longitude: 152.4559,
    accuracy: 3,
  },
  {
    postcode: 2428,
    place_name: 'Tuncurry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1744,
    longitude: 152.4988,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Wallingat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.28,
    longitude: 152.5039,
    accuracy: 3,
  },
  {
    postcode: 2428,
    place_name: 'Charlotte Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.351,
    longitude: 152.5079,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Tarbuck Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3645,
    longitude: 152.4779,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Darawank',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1333,
    longitude: 152.4833,
    accuracy: 4,
  },
  {
    postcode: 2428,
    place_name: 'Sandbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.28,
    longitude: 152.5039,
    accuracy: 3,
  },
  {
    postcode: 2428,
    place_name: 'Pacific Palms',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2335,
    longitude: 152.4559,
    accuracy: 3,
  },
  {
    postcode: 2428,
    place_name: 'Wallis Lake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.28,
    longitude: 152.5039,
    accuracy: 3,
  },
  {
    postcode: 2428,
    place_name: 'Boomerang Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.28,
    longitude: 152.5039,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Bobin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7167,
    longitude: 152.2833,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Kimbriki',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9252,
    longitude: 152.2107,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Cedar Party',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8,
    longitude: 152.3833,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Bucca Wauka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1,
    longitude: 152.1667,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Burrell Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9333,
    longitude: 152.3,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Krambach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.05,
    longitude: 152.2667,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Dyers Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1,
    longitude: 152.3,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Yarratt Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Killawarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9049,
    longitude: 152.2872,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Killabakh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7333,
    longitude: 152.4,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Wingham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8668,
    longitude: 152.3699,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Innes View',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Comboyne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6092,
    longitude: 152.4742,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Kundibakh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9833,
    longitude: 152.25,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Elands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6385,
    longitude: 152.2981,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Bulga Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Wherrol Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7816,
    longitude: 152.2269,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Khatambuhl',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Marlee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8014,
    longitude: 152.3157,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Dollys Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Mooral Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7248,
    longitude: 152.355,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'Strathcedar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Bunyah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1667,
    longitude: 152.2167,
    accuracy: 4,
  },
  {
    postcode: 2429,
    place_name: 'The Bight',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Warriwillah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7783,
    longitude: 152.2596,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Tipperary',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Caparra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7783,
    longitude: 152.2596,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Boorganna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Firefly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0826,
    longitude: 152.2449,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Kippaxs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Dingo Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2429,
    place_name: 'Karaak Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8738,
    longitude: 152.2973,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Purfleet',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9428,
    longitude: 152.4673,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Cundletown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8941,
    longitude: 152.5214,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Bohnock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.95,
    longitude: 152.5667,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Rainbow Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0333,
    longitude: 152.4833,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Taree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.911,
    longitude: 152.4539,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Brimbin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Cabbage Tree Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9804,
    longitude: 153.4567,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Kundle Kundle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Glenthorne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9279,
    longitude: 152.4788,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Black Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Oxley Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9167,
    longitude: 152.5667,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Dumaresq Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Chatham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9,
    longitude: 152.4833,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Ghinni Ghinni',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8833,
    longitude: 152.5667,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Old Bar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9694,
    longitude: 152.5881,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Lansdowne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7849,
    longitude: 152.5192,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Mitchells Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9,
    longitude: 152.6167,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Hallidays Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0713,
    longitude: 152.5445,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Possum Brush',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0651,
    longitude: 152.4382,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Lansdowne Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7469,
    longitude: 152.5757,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Koorainghat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.986,
    longitude: 152.4799,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Diamond Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0435,
    longitude: 152.5346,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Kolodong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8833,
    longitude: 152.4333,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Tallwoods Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0554,
    longitude: 152.5104,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Failford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0918,
    longitude: 152.446,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Bootawa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Upper Lansdowne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7108,
    longitude: 152.4658,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Hillville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9833,
    longitude: 152.3667,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Red Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.057,
    longitude: 152.5357,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Kiwarrak',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Croki',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8733,
    longitude: 152.5926,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Tinonee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9363,
    longitude: 152.4142,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Jones Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Wallabi Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9951,
    longitude: 152.5685,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Saltwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Manning Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8963,
    longitude: 152.6617,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Pampoolah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9333,
    longitude: 152.5333,
    accuracy: 4,
  },
  {
    postcode: 2430,
    place_name: 'Mondrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Taree South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2430,
    place_name: 'Melinga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9412,
    longitude: 152.5161,
    accuracy: 3,
  },
  {
    postcode: 2431,
    place_name: 'South West Rocks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8855,
    longitude: 153.0413,
    accuracy: 4,
  },
  {
    postcode: 2431,
    place_name: 'Arakoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8875,
    longitude: 153.0721,
    accuracy: 4,
  },
  {
    postcode: 2431,
    place_name: 'Jerseyville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9167,
    longitude: 153.0333,
    accuracy: 4,
  },
  {
    postcode: 2439,
    place_name: 'Kerewong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6466,
    longitude: 152.6875,
    accuracy: 3,
  },
  {
    postcode: 2439,
    place_name: 'Lorne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6612,
    longitude: 152.6117,
    accuracy: 4,
  },
  {
    postcode: 2439,
    place_name: 'Black Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6466,
    longitude: 152.6875,
    accuracy: 3,
  },
  {
    postcode: 2439,
    place_name: 'Rossglen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6658,
    longitude: 152.7251,
    accuracy: 4,
  },
  {
    postcode: 2439,
    place_name: 'Batar Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6466,
    longitude: 152.6875,
    accuracy: 3,
  },
  {
    postcode: 2439,
    place_name: 'Upsalls Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6333,
    longitude: 152.6667,
    accuracy: 4,
  },
  {
    postcode: 2439,
    place_name: 'Kew',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.636,
    longitude: 152.7266,
    accuracy: 4,
  },
  {
    postcode: 2439,
    place_name: 'Logans Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6466,
    longitude: 152.6875,
    accuracy: 3,
  },
  {
    postcode: 2439,
    place_name: 'Kendall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.633,
    longitude: 152.7039,
    accuracy: 4,
  },
  {
    postcode: 2439,
    place_name: 'Swans Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6466,
    longitude: 152.6875,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'West Kempsey',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8855,
    longitude: 153.0413,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Yarravel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.043,
    longitude: 152.7619,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Dondingalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1332,
    longitude: 152.7514,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'East Kempsey',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Lower Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7342,
    longitude: 152.2575,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Old Station',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Frederickton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0375,
    longitude: 152.8753,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Temagog',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Comara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.78,
    longitude: 152.3772,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Rainbow Reach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Kinchela',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9939,
    longitude: 152.9914,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Yessabah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'South Kempsey',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.079,
    longitude: 152.8309,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Wittitrin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1333,
    longitude: 152.6667,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Skillion Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0099,
    longitude: 152.7329,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Toorooka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9307,
    longitude: 152.6368,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Sherwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0649,
    longitude: 152.7288,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Hampden Hall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Willi Willi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9333,
    longitude: 152.45,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Bellimbopinni',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0167,
    longitude: 152.9167,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Willawarrin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9288,
    longitude: 152.6253,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Belmore River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1167,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Kempsey',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.079,
    longitude: 152.8309,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Greenhill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.059,
    longitude: 152.8017,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Aldavilla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0818,
    longitude: 152.7679,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Carrai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9307,
    longitude: 152.6368,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Hickeys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8985,
    longitude: 152.6179,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Gladstone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0288,
    longitude: 152.9476,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Millbank',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8612,
    longitude: 152.6357,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Pola Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Austral Eden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0333,
    longitude: 152.9167,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Hat Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0555,
    longitude: 153.0472,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Euroka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.077,
    longitude: 152.8005,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Deep Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3667,
    longitude: 149.8333,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Clybucca',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9377,
    longitude: 152.9413,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Seven Oaks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Mooneba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0402,
    longitude: 152.6846,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Turners Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0152,
    longitude: 152.7012,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Crescent Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1887,
    longitude: 152.973,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Moparrabah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Smithtown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0186,
    longitude: 152.9503,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Collombatti',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9812,
    longitude: 152.8251,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Burnt Bridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.108,
    longitude: 152.8062,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Summer Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Bellbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8199,
    longitude: 152.5102,
    accuracy: 4,
  },
  {
    postcode: 2440,
    place_name: 'Mungay Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Corangula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0123,
    longitude: 152.7651,
    accuracy: 3,
  },
  {
    postcode: 2440,
    place_name: 'Verges Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0878,
    longitude: 152.8997,
    accuracy: 3,
  },
  {
    postcode: 2441,
    place_name: 'Eungai Rail',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8463,
    longitude: 152.9006,
    accuracy: 3,
  },
  {
    postcode: 2441,
    place_name: 'Yarrahapinni',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8281,
    longitude: 152.9538,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Kundabung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2094,
    longitude: 152.8316,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Bril Bril',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0274,
    longitude: 152.8364,
    accuracy: 3,
  },
  {
    postcode: 2441,
    place_name: 'Upper Rollands Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2434,
    longitude: 152.6205,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Cooperabung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3,
    longitude: 152.8,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Eungai Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8333,
    longitude: 152.8833,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Stuarts Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8208,
    longitude: 152.9933,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Tamban',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8827,
    longitude: 152.826,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Crossmaglen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1374,
    longitude: 152.7419,
    accuracy: 3,
  },
  {
    postcode: 2441,
    place_name: 'Telegraph Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3247,
    longitude: 152.8005,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Fishermans Reach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0274,
    longitude: 152.8364,
    accuracy: 3,
  },
  {
    postcode: 2441,
    place_name: 'Allgomera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8182,
    longitude: 152.7955,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Grassy Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1374,
    longitude: 152.7419,
    accuracy: 3,
  },
  {
    postcode: 2441,
    place_name: 'Ballengarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3167,
    longitude: 152.75,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Gum Scrub',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3,
    longitude: 152.7333,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Barraganyatti',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8568,
    longitude: 152.9346,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Marlo Merrican',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0274,
    longitude: 152.8364,
    accuracy: 3,
  },
  {
    postcode: 2441,
    place_name: 'Kippara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2209,
    longitude: 152.5225,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Bonville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3828,
    longitude: 153.0596,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Rollands Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2818,
    longitude: 152.6846,
    accuracy: 4,
  },
  {
    postcode: 2441,
    place_name: 'Hacks Ferry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0274,
    longitude: 152.8364,
    accuracy: 3,
  },
  {
    postcode: 2442,
    place_name: 'Mid North Coast Msc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1374,
    longitude: 152.7419,
    accuracy: 1,
  },
  {
    postcode: 2442,
    place_name: 'Mid North Coast Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1374,
    longitude: 152.7419,
    accuracy: 1,
  },
  {
    postcode: 2443,
    place_name: 'Diamond Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.057,
    longitude: 152.5357,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'Laurieton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6448,
    longitude: 152.7946,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'Coralville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6915,
    longitude: 152.7267,
    accuracy: 3,
  },
  {
    postcode: 2443,
    place_name: 'Waitui',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6915,
    longitude: 152.7267,
    accuracy: 3,
  },
  {
    postcode: 2443,
    place_name: 'Bobs Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6915,
    longitude: 152.7267,
    accuracy: 3,
  },
  {
    postcode: 2443,
    place_name: 'North Haven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6368,
    longitude: 152.8136,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'Deauville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6915,
    longitude: 152.7267,
    accuracy: 3,
  },
  {
    postcode: 2443,
    place_name: 'Middle Brother',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6915,
    longitude: 152.7267,
    accuracy: 3,
  },
  {
    postcode: 2443,
    place_name: 'Stewarts River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7333,
    longitude: 152.65,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'Dunbogan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.65,
    longitude: 152.8167,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'Lakewood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6321,
    longitude: 152.7582,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'North Brother',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6915,
    longitude: 152.7267,
    accuracy: 3,
  },
  {
    postcode: 2443,
    place_name: 'Camden Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6469,
    longitude: 152.8346,
    accuracy: 3,
  },
  {
    postcode: 2443,
    place_name: 'Moorland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7929,
    longitude: 152.6518,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'Hannam Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7167,
    longitude: 152.6,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'Crowdy Bay National Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.788,
    longitude: 152.7311,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'West Haven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6344,
    longitude: 152.7825,
    accuracy: 3,
  },
  {
    postcode: 2443,
    place_name: 'Herons Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5918,
    longitude: 152.7274,
    accuracy: 4,
  },
  {
    postcode: 2443,
    place_name: 'Johns River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7339,
    longitude: 152.6965,
    accuracy: 4,
  },
  {
    postcode: 2444,
    place_name: 'Riverside',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7083,
    longitude: 152.6444,
    accuracy: 3,
  },
  {
    postcode: 2444,
    place_name: 'Limeburners Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6167,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 2444,
    place_name: 'Blackmans Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3906,
    longitude: 152.8314,
    accuracy: 4,
  },
  {
    postcode: 2444,
    place_name: 'Fernbank Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4212,
    longitude: 152.8404,
    accuracy: 4,
  },
  {
    postcode: 2444,
    place_name: 'Port Macquarie Bc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3892,
    longitude: 152.8614,
    accuracy: 3,
  },
  {
    postcode: 2444,
    place_name: 'North Shore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4024,
    longitude: 152.9019,
    accuracy: 4,
  },
  {
    postcode: 2444,
    place_name: 'The Hatch',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7083,
    longitude: 152.6444,
    accuracy: 3,
  },
  {
    postcode: 2444,
    place_name: 'Flynns Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3892,
    longitude: 152.8614,
    accuracy: 3,
  },
  {
    postcode: 2444,
    place_name: 'Port Macquarie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4308,
    longitude: 152.9089,
    accuracy: 4,
  },
  {
    postcode: 2444,
    place_name: 'Thrumster',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7083,
    longitude: 152.6444,
    accuracy: 3,
  },
  {
    postcode: 2444,
    place_name: 'Settlement City',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7083,
    longitude: 152.6444,
    accuracy: 3,
  },
  {
    postcode: 2444,
    place_name: 'Lighthouse Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7083,
    longitude: 152.6444,
    accuracy: 3,
  },
  {
    postcode: 2445,
    place_name: 'Bonny Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5906,
    longitude: 152.8391,
    accuracy: 4,
  },
  {
    postcode: 2445,
    place_name: 'Lake Cathie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5518,
    longitude: 152.8546,
    accuracy: 4,
  },
  {
    postcode: 2445,
    place_name: 'Grants Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5712,
    longitude: 152.8469,
    accuracy: 3,
  },
  {
    postcode: 2445,
    place_name: 'Jolly Nose',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5712,
    longitude: 152.8469,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Wauchope',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4579,
    longitude: 152.7262,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Hartys Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Banda Banda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.2157,
    longitude: 152.4566,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Yippin Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Redbank',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4242,
    longitude: 152.7393,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Long Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4395,
    longitude: 152.4925,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Pembrooke',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3935,
    longitude: 152.7508,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Bago',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Ellenborough',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.45,
    longitude: 152.4667,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Birdwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3485,
    longitude: 152.3346,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Bellangry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3284,
    longitude: 152.6077,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Lower Pappinbarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Forbes River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3667,
    longitude: 152.3167,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Hollisdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4,
    longitude: 152.5333,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Sancrox',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4318,
    longitude: 152.8012,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Toms Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3861,
    longitude: 152.4402,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Rawdon Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4333,
    longitude: 152.7667,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'King Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4924,
    longitude: 152.7566,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Brombin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4578,
    longitude: 152.6315,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Rosewood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Beechwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4365,
    longitude: 152.6768,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Pappinbarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3666,
    longitude: 152.4943,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Yarras',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4189,
    longitude: 152.3198,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Cairncross',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Byabarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.523,
    longitude: 152.546,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Huntingdon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4639,
    longitude: 152.6521,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Mount Seaview',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3966,
    longitude: 152.0798,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Kindee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Mortons Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3829,
    longitude: 152.6427,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Doyles River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Gearys Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Upper Pappinbarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Werrikimbe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Frazers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Bagnoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4652,
    longitude: 152.5346,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Debenham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Lake Innes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5038,
    longitude: 152.8165,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Kings Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3861,
    longitude: 152.4402,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Crosslands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4393,
    longitude: 152.7154,
    accuracy: 4,
  },
  {
    postcode: 2446,
    place_name: 'Hyndmans Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2446,
    place_name: 'Pipeclay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4343,
    longitude: 152.6221,
    accuracy: 3,
  },
  {
    postcode: 2447,
    place_name: 'Gumma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7172,
    longitude: 152.9694,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Taylors Arm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7833,
    longitude: 152.7,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Wirrimbi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6667,
    longitude: 152.9333,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Congarinni',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6867,
    longitude: 152.7061,
    accuracy: 3,
  },
  {
    postcode: 2447,
    place_name: 'Scotts Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7477,
    longitude: 152.9961,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Newee Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6316,
    longitude: 152.9275,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Warrell Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7718,
    longitude: 152.8924,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Upper Taylors Arm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6867,
    longitude: 152.7061,
    accuracy: 3,
  },
  {
    postcode: 2447,
    place_name: 'Thumb Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.683,
    longitude: 152.6154,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Talarm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7016,
    longitude: 152.8501,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Macksville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7078,
    longitude: 152.9203,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Congarinni North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6867,
    longitude: 152.7061,
    accuracy: 3,
  },
  {
    postcode: 2447,
    place_name: 'Burrapine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7176,
    longitude: 152.6371,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Donnellyville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7554,
    longitude: 152.9155,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Yarranbella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6867,
    longitude: 152.7061,
    accuracy: 3,
  },
  {
    postcode: 2447,
    place_name: 'Bakers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2667,
    longitude: 151.0167,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'Way Way',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6867,
    longitude: 152.7061,
    accuracy: 3,
  },
  {
    postcode: 2447,
    place_name: 'Utungun',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7427,
    longitude: 152.8276,
    accuracy: 4,
  },
  {
    postcode: 2447,
    place_name: 'North Macksville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6867,
    longitude: 152.7061,
    accuracy: 3,
  },
  {
    postcode: 2448,
    place_name: 'Hyland Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6124,
    longitude: 153.0001,
    accuracy: 4,
  },
  {
    postcode: 2448,
    place_name: 'Valla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5926,
    longitude: 153.0113,
    accuracy: 4,
  },
  {
    postcode: 2448,
    place_name: 'Valla Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5926,
    longitude: 153.0113,
    accuracy: 4,
  },
  {
    postcode: 2448,
    place_name: 'Nambucca Heads',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6432,
    longitude: 153.0088,
    accuracy: 4,
  },
  {
    postcode: 2449,
    place_name: 'South Arm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6888,
    longitude: 152.7396,
    accuracy: 4,
  },
  {
    postcode: 2449,
    place_name: 'Missabotti',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5747,
    longitude: 152.8158,
    accuracy: 4,
  },
  {
    postcode: 2449,
    place_name: 'Bowraville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.65,
    longitude: 152.8515,
    accuracy: 4,
  },
  {
    postcode: 2449,
    place_name: 'Buckra Bendinni',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6389,
    longitude: 152.7853,
    accuracy: 3,
  },
  {
    postcode: 2449,
    place_name: 'Killiekrankie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6389,
    longitude: 152.7853,
    accuracy: 3,
  },
  {
    postcode: 2449,
    place_name: 'Tewinga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6389,
    longitude: 152.7853,
    accuracy: 3,
  },
  {
    postcode: 2449,
    place_name: 'Girralong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5696,
    longitude: 152.6554,
    accuracy: 4,
  },
  {
    postcode: 2449,
    place_name: 'Argents Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6415,
    longitude: 152.7491,
    accuracy: 4,
  },
  {
    postcode: 2449,
    place_name: 'Kennaicle Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6389,
    longitude: 152.7853,
    accuracy: 3,
  },
  {
    postcode: 2450,
    place_name: 'Upper Orara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2833,
    longitude: 153,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Coffs Harbour',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2963,
    longitude: 153.1135,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Coffs Harbour Plaza',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0947,
    longitude: 152.6671,
    accuracy: 3,
  },
  {
    postcode: 2450,
    place_name: 'Moonee Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2057,
    longitude: 153.1529,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Nana Glen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1333,
    longitude: 153.0167,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Bonville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3828,
    longitude: 153.0596,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Sapphire Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2325,
    longitude: 153.1483,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Brooklana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2488,
    longitude: 152.8432,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Ulong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2384,
    longitude: 152.8799,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Korora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.259,
    longitude: 153.1306,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Lower Bucca',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.15,
    longitude: 153.0833,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Glenreagh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0534,
    longitude: 152.9786,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Coffs Harbour Jetty',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0947,
    longitude: 152.6671,
    accuracy: 3,
  },
  {
    postcode: 2450,
    place_name: 'Sherwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0649,
    longitude: 152.7288,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Lowanna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2167,
    longitude: 152.9,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'North Boambee Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2807,
    longitude: 153.0196,
    accuracy: 3,
  },
  {
    postcode: 2450,
    place_name: 'Bucca',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1574,
    longitude: 153.0958,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Coramba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2202,
    longitude: 153.0134,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Boambee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3398,
    longitude: 153.0678,
    accuracy: 4,
  },
  {
    postcode: 2450,
    place_name: 'Karangi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2667,
    longitude: 153.05,
    accuracy: 4,
  },
  {
    postcode: 2452,
    place_name: 'Sawtell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3646,
    longitude: 153.1014,
    accuracy: 4,
  },
  {
    postcode: 2452,
    place_name: 'Boambee East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3466,
    longitude: 153.0732,
    accuracy: 4,
  },
  {
    postcode: 2452,
    place_name: 'Toormina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3538,
    longitude: 153.0884,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Never Never',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.282,
    longitude: 152.61,
    accuracy: 3,
  },
  {
    postcode: 2453,
    place_name: 'Deer Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3485,
    longitude: 152.5512,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Billys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1674,
    longitude: 152.5747,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'North Dorrigo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2955,
    longitude: 152.6862,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Ebor',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4018,
    longitude: 152.349,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Marengo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1725,
    longitude: 152.3615,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Dundurrabin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1919,
    longitude: 152.5513,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Clouds Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1019,
    longitude: 152.6498,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Tallowwood Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.282,
    longitude: 152.61,
    accuracy: 3,
  },
  {
    postcode: 2453,
    place_name: 'Cascade',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2317,
    longitude: 152.7898,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Bielsdown Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.282,
    longitude: 152.61,
    accuracy: 3,
  },
  {
    postcode: 2453,
    place_name: 'Tyringham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2149,
    longitude: 152.5434,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Bostobrick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2771,
    longitude: 152.6293,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Megan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2857,
    longitude: 152.7691,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Hernani',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2969,
    longitude: 152.4208,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Dorrigo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3411,
    longitude: 152.7139,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Fernbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.282,
    longitude: 152.61,
    accuracy: 3,
  },
  {
    postcode: 2453,
    place_name: 'Briggsvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.2497,
    longitude: 152.7784,
    accuracy: 4,
  },
  {
    postcode: 2453,
    place_name: 'Dorrigo Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.282,
    longitude: 152.61,
    accuracy: 3,
  },
  {
    postcode: 2453,
    place_name: 'Wild Cattle Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.282,
    longitude: 152.61,
    accuracy: 3,
  },
  {
    postcode: 2453,
    place_name: 'Moonpar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.282,
    longitude: 152.61,
    accuracy: 3,
  },
  {
    postcode: 2454,
    place_name: 'Mylestom',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4649,
    longitude: 153.0432,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Fernmount',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4667,
    longitude: 152.9333,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Valery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4055,
    longitude: 152.9515,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Raleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4396,
    longitude: 152.9712,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Thora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4397,
    longitude: 152.7868,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Spicketts Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.441,
    longitude: 152.9121,
    accuracy: 3,
  },
  {
    postcode: 2454,
    place_name: 'Brinerville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.441,
    longitude: 152.9121,
    accuracy: 3,
  },
  {
    postcode: 2454,
    place_name: 'Kalang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5083,
    longitude: 152.7742,
    accuracy: 3,
  },
  {
    postcode: 2454,
    place_name: 'Sunny Corner',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4511,
    longitude: 152.743,
    accuracy: 3,
  },
  {
    postcode: 2454,
    place_name: 'Repton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.45,
    longitude: 153.0167,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Brierfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5,
    longitude: 152.9,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Bundagen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4132,
    longitude: 153.0584,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Darkwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4535,
    longitude: 152.5487,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Gleniffer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3885,
    longitude: 152.8871,
    accuracy: 4,
  },
  {
    postcode: 2454,
    place_name: 'Bellingen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4529,
    longitude: 152.8991,
    accuracy: 4,
  },
  {
    postcode: 2455,
    place_name: 'Urunga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.497,
    longitude: 153.0142,
    accuracy: 4,
  },
  {
    postcode: 2456,
    place_name: 'Mullaway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0804,
    longitude: 153.1959,
    accuracy: 4,
  },
  {
    postcode: 2456,
    place_name: 'Emerald Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1639,
    longitude: 153.1819,
    accuracy: 4,
  },
  {
    postcode: 2456,
    place_name: 'Upper Corindi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0388,
    longitude: 153.1131,
    accuracy: 4,
  },
  {
    postcode: 2456,
    place_name: 'Corindi Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0295,
    longitude: 153.1595,
    accuracy: 3,
  },
  {
    postcode: 2456,
    place_name: 'Red Rock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9845,
    longitude: 153.2262,
    accuracy: 4,
  },
  {
    postcode: 2456,
    place_name: 'Arrawarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0606,
    longitude: 153.1907,
    accuracy: 4,
  },
  {
    postcode: 2456,
    place_name: 'Arrawarra Headland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0598,
    longitude: 153.2027,
    accuracy: 3,
  },
  {
    postcode: 2456,
    place_name: 'Safety Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0968,
    longitude: 153.1877,
    accuracy: 4,
  },
  {
    postcode: 2456,
    place_name: 'Dirty Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0867,
    longitude: 153.1898,
    accuracy: 3,
  },
  {
    postcode: 2456,
    place_name: 'Woolgoolga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1106,
    longitude: 153.2007,
    accuracy: 4,
  },
  {
    postcode: 2456,
    place_name: 'Sandy Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.1468,
    longitude: 153.1924,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Smiths Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4277,
    longitude: 153.378,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Halfway Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9318,
    longitude: 153.0679,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Gurranang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4676,
    longitude: 152.9929,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Eatonsville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6333,
    longitude: 152.8333,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Braunstone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8027,
    longitude: 152.9581,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Buccarumbi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8318,
    longitude: 152.5846,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Kungala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9535,
    longitude: 153.0086,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Clifden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5667,
    longitude: 152.9167,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Lanitza',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8868,
    longitude: 153.0017,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Whiteman Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5833,
    longitude: 152.8833,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Banyabba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.342,
    longitude: 153.0165,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Fine Flower',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3833,
    longitude: 152.6667,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Carrs Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6488,
    longitude: 152.9284,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Coutts Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8262,
    longitude: 152.8916,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Tyndale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5167,
    longitude: 153.2,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Baryulgil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.2216,
    longitude: 152.605,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Collum Collum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Bookram',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Waterview',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6652,
    longitude: 152.8679,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Eighteen Mile',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Brushgrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5663,
    longitude: 153.0785,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Jackadgery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5863,
    longitude: 152.5635,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Mountain View',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.41,
    longitude: 149.1625,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Fortis Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Alumy Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6485,
    longitude: 152.9512,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Grafton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6901,
    longitude: 152.9334,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Lionsville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1919,
    longitude: 152.4907,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Lilydale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Dalmorton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8631,
    longitude: 152.4629,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Kangaroo Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8667,
    longitude: 152.9333,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Lower Southgate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.55,
    longitude: 153.0833,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Great Marlow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Seelands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6152,
    longitude: 152.9179,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Chambigne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7333,
    longitude: 152.7833,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'South Grafton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7076,
    longitude: 152.9263,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Grafton West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.624,
    longitude: 152.7585,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Junction Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6411,
    longitude: 152.9249,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Copmanhurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5852,
    longitude: 152.779,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Dilkoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4985,
    longitude: 153.0012,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Levenstrath',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'South Arm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6888,
    longitude: 152.7396,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Bom Bom',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Ramornie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.65,
    longitude: 152.7833,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Upper Copmanhurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Stockyard Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Coaldale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3943,
    longitude: 152.8089,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Cowper',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5796,
    longitude: 153.0761,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Heifer Station',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'The Pinnacles',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Cangai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4985,
    longitude: 152.4679,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Southgate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5833,
    longitude: 153.0333,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Wells Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Elland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Clarenza',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7037,
    longitude: 153.0006,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Waterview Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6652,
    longitude: 152.8679,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Newbold',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Deep Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3667,
    longitude: 149.8333,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Punchbowl',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Koolkhan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Washpool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Carnham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Trenayr',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'The Whiteman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Wombat Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Moleville Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Mylneford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Upper Fine Flower',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Glenugie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Dumbudgery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Chaelundi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Kyarran',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5808,
    longitude: 152.9743,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Pulganbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Winegrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.624,
    longitude: 152.7585,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Towallum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Crowther Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Nymboida',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.926,
    longitude: 152.7479,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Southampton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Sandy Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Calamia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Blaxlands Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Barcoongere',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Warragai Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Coombadjha',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Rushforth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Malabugilmah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Barretts Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Lawrence',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4921,
    longitude: 153.0969,
    accuracy: 4,
  },
  {
    postcode: 2460,
    place_name: 'Kremnos',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Shannondale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Carrs Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2460,
    place_name: 'Carrs Peninsular',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6305,
    longitude: 152.875,
    accuracy: 3,
  },
  {
    postcode: 2462,
    place_name: 'Wooli',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8616,
    longitude: 153.2677,
    accuracy: 4,
  },
  {
    postcode: 2462,
    place_name: 'Lavadia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7667,
    longitude: 153.1,
    accuracy: 4,
  },
  {
    postcode: 2462,
    place_name: 'Ulmarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6313,
    longitude: 153.0299,
    accuracy: 4,
  },
  {
    postcode: 2462,
    place_name: 'Swan Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7122,
    longitude: 153.1253,
    accuracy: 3,
  },
  {
    postcode: 2462,
    place_name: 'Pillar Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7558,
    longitude: 153.1112,
    accuracy: 4,
  },
  {
    postcode: 2462,
    place_name: 'Gilletts Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7122,
    longitude: 153.1253,
    accuracy: 3,
  },
  {
    postcode: 2462,
    place_name: 'Minnie Water',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7759,
    longitude: 153.2973,
    accuracy: 4,
  },
  {
    postcode: 2462,
    place_name: 'Lake Hiawatha',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7122,
    longitude: 153.1253,
    accuracy: 3,
  },
  {
    postcode: 2462,
    place_name: 'Diggers Camp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.8141,
    longitude: 153.2853,
    accuracy: 4,
  },
  {
    postcode: 2462,
    place_name: 'Calliope',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7122,
    longitude: 153.1253,
    accuracy: 3,
  },
  {
    postcode: 2462,
    place_name: 'Tucabia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6636,
    longitude: 153.1043,
    accuracy: 4,
  },
  {
    postcode: 2462,
    place_name: 'Coldstream',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5833,
    longitude: 153.1333,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'Maclean',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4581,
    longitude: 153.1975,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'Palmers Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4167,
    longitude: 153.2833,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'Townsend',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4662,
    longitude: 153.2207,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'The Sandon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4726,
    longitude: 153.2191,
    accuracy: 3,
  },
  {
    postcode: 2463,
    place_name: 'Shark Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5167,
    longitude: 153.2,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'Palmers Channel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4726,
    longitude: 153.2191,
    accuracy: 3,
  },
  {
    postcode: 2463,
    place_name: 'Ashby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4318,
    longitude: 153.1929,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'Ashby Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4726,
    longitude: 153.2191,
    accuracy: 3,
  },
  {
    postcode: 2463,
    place_name: 'Gulmarrad',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.487,
    longitude: 153.2334,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'Woodford Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4726,
    longitude: 153.2191,
    accuracy: 3,
  },
  {
    postcode: 2463,
    place_name: 'Ilarwill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4818,
    longitude: 153.1846,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'James Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4726,
    longitude: 153.2191,
    accuracy: 3,
  },
  {
    postcode: 2463,
    place_name: 'Tullymorgan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3735,
    longitude: 153.1179,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'Jacky Bulbin Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4726,
    longitude: 153.2191,
    accuracy: 3,
  },
  {
    postcode: 2463,
    place_name: 'Brooms Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6089,
    longitude: 153.3365,
    accuracy: 4,
  },
  {
    postcode: 2463,
    place_name: 'Sandon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4726,
    longitude: 153.2191,
    accuracy: 3,
  },
  {
    postcode: 2463,
    place_name: 'Taloumbi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4726,
    longitude: 153.2191,
    accuracy: 3,
  },
  {
    postcode: 2463,
    place_name: 'Woodford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5433,
    longitude: 153.2368,
    accuracy: 3,
  },
  {
    postcode: 2463,
    place_name: 'Ashby Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4726,
    longitude: 153.2191,
    accuracy: 3,
  },
  {
    postcode: 2464,
    place_name: 'Freeburn Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4042,
    longitude: 153.3322,
    accuracy: 4,
  },
  {
    postcode: 2464,
    place_name: 'Angourie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4763,
    longitude: 153.3588,
    accuracy: 4,
  },
  {
    postcode: 2464,
    place_name: 'Micalo Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4927,
    longitude: 153.3425,
    accuracy: 3,
  },
  {
    postcode: 2464,
    place_name: 'Yuraygir',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5765,
    longitude: 153.307,
    accuracy: 4,
  },
  {
    postcode: 2464,
    place_name: 'Yamba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4375,
    longitude: 153.3591,
    accuracy: 4,
  },
  {
    postcode: 2464,
    place_name: 'Wooloweyah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4809,
    longitude: 153.3428,
    accuracy: 4,
  },
  {
    postcode: 2465,
    place_name: 'Harwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4258,
    longitude: 153.2418,
    accuracy: 4,
  },
  {
    postcode: 2466,
    place_name: 'Woody Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4067,
    longitude: 153.3524,
    accuracy: 3,
  },
  {
    postcode: 2466,
    place_name: 'Iluka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4076,
    longitude: 153.3495,
    accuracy: 4,
  },
  {
    postcode: 2466,
    place_name: 'The Freshwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4067,
    longitude: 153.3524,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Haystack',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Lower Duck Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Paddys Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7152,
    longitude: 152.4179,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Bonalbo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7371,
    longitude: 152.6239,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Ewingar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Rappville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0867,
    longitude: 152.9528,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Theresa Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Gorge Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Goodwood Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3792,
    longitude: 153.3012,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Pikapene',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Camira',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.2538,
    longitude: 152.9636,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Tabulam',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8928,
    longitude: 152.5652,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Mallanganee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9076,
    longitude: 152.7196,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Chatsworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3921,
    longitude: 153.2341,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Drake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9283,
    longitude: 152.3723,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Kippenduff',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Old Bonalbo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6569,
    longitude: 152.5974,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Pagans Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Bottle Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7888,
    longitude: 152.6521,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Duck Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Mummulgum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8496,
    longitude: 152.8048,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Mount Marsh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Alice',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0555,
    longitude: 152.5879,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Capeen Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Myrtle Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1122,
    longitude: 152.9448,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Drake Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Mororo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3333,
    longitude: 153.25,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Lower Bottle Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Whiporie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.2879,
    longitude: 152.9926,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Deep Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3667,
    longitude: 149.8333,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Gibberagee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.2318,
    longitude: 153.1012,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Jacksons Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Clearfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1445,
    longitude: 152.936,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Joes Box',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Six Mile Swamp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Louisa Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Bean Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Keybarbin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.15,
    longitude: 152.6,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Tunglebung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Wyan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0667,
    longitude: 152.8667,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Peacock Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Woombah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3667,
    longitude: 153.2833,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Lower Peacock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Bungawalbin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0333,
    longitude: 153.2833,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Hogarth Range',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Busbys Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0485,
    longitude: 152.8012,
    accuracy: 4,
  },
  {
    postcode: 2469,
    place_name: 'Warregah Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Mookima Wybra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Bingeebeebra Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Cambridge Plateau',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Coongbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Yabbra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Culmaran Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Sandilands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Simpkins Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Upper Duck Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2469,
    place_name: 'Bulldog',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0454,
    longitude: 152.8384,
    accuracy: 3,
  },
  {
    postcode: 2470,
    place_name: 'Ellangowan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0333,
    longitude: 153.0667,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Sextonville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8492,
    longitude: 152.9372,
    accuracy: 3,
  },
  {
    postcode: 2470,
    place_name: 'Yorklea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9481,
    longitude: 153.0584,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Irvington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8492,
    longitude: 152.9372,
    accuracy: 3,
  },
  {
    postcode: 2470,
    place_name: 'Fairy Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7667,
    longitude: 153.0167,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Babyl Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8732,
    longitude: 152.9852,
    accuracy: 3,
  },
  {
    postcode: 2470,
    place_name: 'Dobies Bight',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8016,
    longitude: 152.9368,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Dyraaba Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8492,
    longitude: 152.9372,
    accuracy: 3,
  },
  {
    postcode: 2470,
    place_name: 'Shannon Brook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9167,
    longitude: 152.9667,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Stratheden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7505,
    longitude: 152.9468,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Dyraaba Central',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7161,
    longitude: 152.8628,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Baraimal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8492,
    longitude: 152.9372,
    accuracy: 3,
  },
  {
    postcode: 2470,
    place_name: 'Spring Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8331,
    longitude: 153.1557,
    accuracy: 3,
  },
  {
    postcode: 2470,
    place_name: 'Leeville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.95,
    longitude: 153,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Dyraaba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7161,
    longitude: 152.8628,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Coombell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0157,
    longitude: 152.9732,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Doubtful Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7538,
    longitude: 152.9205,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Naughtons Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8047,
    longitude: 153.089,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Casino',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8582,
    longitude: 153.0475,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Backmede',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.75,
    longitude: 153.0167,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Tomki',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8618,
    longitude: 153.1323,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Woodview',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8667,
    longitude: 152.9667,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Upper Mongogarie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9833,
    longitude: 152.85,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Lower Dyraaba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8732,
    longitude: 152.9852,
    accuracy: 3,
  },
  {
    postcode: 2470,
    place_name: 'Woolners Arm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6849,
    longitude: 152.8415,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'North Casino',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8732,
    longitude: 152.9852,
    accuracy: 3,
  },
  {
    postcode: 2470,
    place_name: 'Piora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8549,
    longitude: 152.8879,
    accuracy: 4,
  },
  {
    postcode: 2470,
    place_name: 'Mongogarie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.95,
    longitude: 152.9333,
    accuracy: 4,
  },
  {
    postcode: 2471,
    place_name: 'Codrington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9494,
    longitude: 153.2418,
    accuracy: 4,
  },
  {
    postcode: 2471,
    place_name: 'Bora Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9775,
    longitude: 153.2497,
    accuracy: 3,
  },
  {
    postcode: 2471,
    place_name: 'Tatham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9282,
    longitude: 153.1552,
    accuracy: 4,
  },
  {
    postcode: 2471,
    place_name: 'Coraki',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9933,
    longitude: 153.2821,
    accuracy: 4,
  },
  {
    postcode: 2471,
    place_name: 'North Woodburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9775,
    longitude: 153.2497,
    accuracy: 3,
  },
  {
    postcode: 2471,
    place_name: 'Swan Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.05,
    longitude: 153.3167,
    accuracy: 4,
  },
  {
    postcode: 2471,
    place_name: 'Greenridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9775,
    longitude: 153.2497,
    accuracy: 3,
  },
  {
    postcode: 2471,
    place_name: 'East Coraki',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9775,
    longitude: 153.2497,
    accuracy: 3,
  },
  {
    postcode: 2471,
    place_name: 'West Coraki',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9775,
    longitude: 153.2497,
    accuracy: 3,
  },
  {
    postcode: 2471,
    place_name: 'West Bungawalbin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9775,
    longitude: 153.2497,
    accuracy: 3,
  },
  {
    postcode: 2471,
    place_name: 'Green Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9775,
    longitude: 153.2497,
    accuracy: 3,
  },
  {
    postcode: 2472,
    place_name: 'Rileys Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0167,
    longitude: 153.4,
    accuracy: 4,
  },
  {
    postcode: 2472,
    place_name: 'Broadwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0111,
    longitude: 153.4356,
    accuracy: 4,
  },
  {
    postcode: 2472,
    place_name: 'Buckendoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0485,
    longitude: 153.3512,
    accuracy: 4,
  },
  {
    postcode: 2472,
    place_name: 'Esk',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0832,
    longitude: 153.3442,
    accuracy: 3,
  },
  {
    postcode: 2472,
    place_name: 'Woodburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0716,
    longitude: 153.3461,
    accuracy: 4,
  },
  {
    postcode: 2472,
    place_name: 'Tabbimoble',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1985,
    longitude: 153.2679,
    accuracy: 4,
  },
  {
    postcode: 2472,
    place_name: 'New Italy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1318,
    longitude: 153.2679,
    accuracy: 4,
  },
  {
    postcode: 2472,
    place_name: 'Trustums Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0832,
    longitude: 153.3442,
    accuracy: 3,
  },
  {
    postcode: 2472,
    place_name: 'The Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0832,
    longitude: 153.3442,
    accuracy: 3,
  },
  {
    postcode: 2472,
    place_name: 'Kilgin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0832,
    longitude: 153.3442,
    accuracy: 3,
  },
  {
    postcode: 2472,
    place_name: 'Moonem',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0832,
    longitude: 153.3442,
    accuracy: 3,
  },
  {
    postcode: 2473,
    place_name: 'Evans Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1178,
    longitude: 153.4307,
    accuracy: 4,
  },
  {
    postcode: 2473,
    place_name: 'South Evans Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1178,
    longitude: 153.4307,
    accuracy: 4,
  },
  {
    postcode: 2473,
    place_name: 'Bundjalung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1178,
    longitude: 153.4307,
    accuracy: 3,
  },
  {
    postcode: 2473,
    place_name: 'Iron Gates',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1178,
    longitude: 153.4307,
    accuracy: 3,
  },
  {
    postcode: 2473,
    place_name: 'Doonbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1178,
    longitude: 153.4307,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Cawongla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6013,
    longitude: 153.0974,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Findon Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Loadstone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3907,
    longitude: 152.9904,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Rukenvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4787,
    longitude: 152.9119,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Green Pigeon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4806,
    longitude: 153.0856,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Roseberry Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Cedar Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6843,
    longitude: 153.0021,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Sawpit Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Horseshoe Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5352,
    longitude: 153.0376,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Border Ranges',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Barkers Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5366,
    longitude: 153.1207,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Eden Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Roseberry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4888,
    longitude: 152.909,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Upper Eden Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Wadeville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5607,
    longitude: 153.1354,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Kilgra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Homeleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5685,
    longitude: 153.0643,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Kyogle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6208,
    longitude: 153.0048,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'The Risk',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4825,
    longitude: 152.9398,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Edenville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Upper Horseshoe Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5387,
    longitude: 153.0651,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Wiangaree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5148,
    longitude: 152.961,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Afterlee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5741,
    longitude: 152.8287,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Toonumbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5807,
    longitude: 152.7629,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Sherwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0649,
    longitude: 152.7288,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Gradys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Cougal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3667,
    longitude: 152.9667,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Wyneden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Geneva',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6219,
    longitude: 152.989,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Ettrick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6637,
    longitude: 152.9125,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Dairy Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'New Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Smiths Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4277,
    longitude: 153.378,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Little Back Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'West Wiangaree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Grevillia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4431,
    longitude: 152.8332,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Warrazambil Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Fawcetts Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Unumgar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4096,
    longitude: 152.7429,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Iron Pot Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Ghinni Ghi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Horse Station Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Terrace Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Lynchs Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.45,
    longitude: 153,
    accuracy: 4,
  },
  {
    postcode: 2474,
    place_name: 'Old Grevillia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2474,
    place_name: 'Collins Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.536,
    longitude: 152.9961,
    accuracy: 3,
  },
  {
    postcode: 2475,
    place_name: 'Upper Tooloom',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.545,
    longitude: 152.483,
    accuracy: 3,
  },
  {
    postcode: 2475,
    place_name: 'Tooloom',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6167,
    longitude: 152.4167,
    accuracy: 4,
  },
  {
    postcode: 2475,
    place_name: 'Urbenville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4732,
    longitude: 152.5494,
    accuracy: 4,
  },
  {
    postcode: 2476,
    place_name: 'Koreelah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4799,
    longitude: 152.3275,
    accuracy: 4,
  },
  {
    postcode: 2476,
    place_name: 'Old Koreelah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4,
    longitude: 152.4167,
    accuracy: 4,
  },
  {
    postcode: 2476,
    place_name: 'Legume',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4081,
    longitude: 152.3095,
    accuracy: 4,
  },
  {
    postcode: 2476,
    place_name: 'Muli Muli',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4182,
    longitude: 152.5834,
    accuracy: 4,
  },
  {
    postcode: 2476,
    place_name: 'The Glen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1145,
    longitude: 148.2097,
    accuracy: 3,
  },
  {
    postcode: 2476,
    place_name: 'Lindesay Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4177,
    longitude: 152.3993,
    accuracy: 3,
  },
  {
    postcode: 2476,
    place_name: 'Acacia Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3652,
    longitude: 152.3179,
    accuracy: 4,
  },
  {
    postcode: 2476,
    place_name: 'Acacia Plateau',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4516,
    longitude: 152.3925,
    accuracy: 3,
  },
  {
    postcode: 2476,
    place_name: 'Lower Acacia Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4318,
    longitude: 152.2346,
    accuracy: 4,
  },
  {
    postcode: 2476,
    place_name: 'Brumby Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4177,
    longitude: 152.3993,
    accuracy: 3,
  },
  {
    postcode: 2476,
    place_name: 'Woodenbong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3892,
    longitude: 152.6115,
    accuracy: 4,
  },
  {
    postcode: 2476,
    place_name: 'Boomi Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4177,
    longitude: 152.3993,
    accuracy: 3,
  },
  {
    postcode: 2477,
    place_name: 'Lynwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.872,
    longitude: 153.4425,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'Wardell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9489,
    longitude: 153.4651,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'Rous',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8764,
    longitude: 153.3918,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'Alstonvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8949,
    longitude: 153.4304,
    accuracy: 3,
  },
  {
    postcode: 2477,
    place_name: 'Rous Mill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8764,
    longitude: 153.3918,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'Tuckombil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8143,
    longitude: 153.468,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'Bagotville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9828,
    longitude: 153.4212,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'Pearces Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8949,
    longitude: 153.4304,
    accuracy: 3,
  },
  {
    postcode: 2477,
    place_name: 'Meerschaum Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9167,
    longitude: 153.4333,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'Cabbage Tree Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9804,
    longitude: 153.4567,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'East Wardell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8949,
    longitude: 153.4304,
    accuracy: 3,
  },
  {
    postcode: 2477,
    place_name: 'Wollongbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8251,
    longitude: 153.4148,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'Goat Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8949,
    longitude: 153.4304,
    accuracy: 3,
  },
  {
    postcode: 2477,
    place_name: 'Alstonville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8419,
    longitude: 153.4402,
    accuracy: 4,
  },
  {
    postcode: 2477,
    place_name: 'Dalwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8923,
    longitude: 153.4314,
    accuracy: 3,
  },
  {
    postcode: 2477,
    place_name: 'Uralba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8736,
    longitude: 153.4713,
    accuracy: 3,
  },
  {
    postcode: 2478,
    place_name: 'Empire Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9163,
    longitude: 153.5135,
    accuracy: 4,
  },
  {
    postcode: 2478,
    place_name: 'East Ballina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8667,
    longitude: 153.5833,
    accuracy: 4,
  },
  {
    postcode: 2478,
    place_name: 'Ballina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8641,
    longitude: 153.5654,
    accuracy: 4,
  },
  {
    postcode: 2478,
    place_name: 'South Ballina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8555,
    longitude: 153.5391,
    accuracy: 3,
  },
  {
    postcode: 2478,
    place_name: 'Cumbalum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8167,
    longitude: 153.5333,
    accuracy: 4,
  },
  {
    postcode: 2478,
    place_name: 'Patchs Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8555,
    longitude: 153.5391,
    accuracy: 3,
  },
  {
    postcode: 2478,
    place_name: 'Lennox Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7931,
    longitude: 153.5939,
    accuracy: 4,
  },
  {
    postcode: 2478,
    place_name: 'Pimlico Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8555,
    longitude: 153.5391,
    accuracy: 3,
  },
  {
    postcode: 2478,
    place_name: 'Pimlico',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9152,
    longitude: 153.4846,
    accuracy: 4,
  },
  {
    postcode: 2478,
    place_name: 'Keith Hall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8763,
    longitude: 153.5346,
    accuracy: 4,
  },
  {
    postcode: 2478,
    place_name: 'Skennars Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8555,
    longitude: 153.5391,
    accuracy: 3,
  },
  {
    postcode: 2478,
    place_name: 'Tintenbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8,
    longitude: 153.5167,
    accuracy: 4,
  },
  {
    postcode: 2478,
    place_name: 'Coolgardie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8555,
    longitude: 153.5391,
    accuracy: 3,
  },
  {
    postcode: 2478,
    place_name: 'West Ballina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8641,
    longitude: 153.5654,
    accuracy: 4,
  },
  {
    postcode: 2478,
    place_name: 'Teven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8167,
    longitude: 153.5,
    accuracy: 4,
  },
  {
    postcode: 2479,
    place_name: 'Bangalow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6867,
    longitude: 153.5239,
    accuracy: 4,
  },
  {
    postcode: 2479,
    place_name: 'Brooklet',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7465,
    longitude: 153.5087,
    accuracy: 4,
  },
  {
    postcode: 2479,
    place_name: 'Binna Burra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7,
    longitude: 153.5,
    accuracy: 4,
  },
  {
    postcode: 2479,
    place_name: 'Knockrow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7667,
    longitude: 153.5333,
    accuracy: 4,
  },
  {
    postcode: 2479,
    place_name: 'Coopers Shoot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6833,
    longitude: 153.5833,
    accuracy: 4,
  },
  {
    postcode: 2479,
    place_name: 'Coorabell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6333,
    longitude: 153.4833,
    accuracy: 4,
  },
  {
    postcode: 2479,
    place_name: 'Newrybar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7167,
    longitude: 153.5333,
    accuracy: 4,
  },
  {
    postcode: 2479,
    place_name: 'Mcleods Shoot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7133,
    longitude: 153.5156,
    accuracy: 3,
  },
  {
    postcode: 2479,
    place_name: 'Nashua',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7286,
    longitude: 153.4646,
    accuracy: 4,
  },
  {
    postcode: 2479,
    place_name: 'Possum Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7133,
    longitude: 153.5156,
    accuracy: 3,
  },
  {
    postcode: 2479,
    place_name: 'Fernleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7667,
    longitude: 153.5,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Tucki Tucki',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9333,
    longitude: 153.3167,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Marom Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9041,
    longitude: 153.3699,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'East Lismore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8193,
    longitude: 153.2874,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Mcleans Ridges',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8,
    longitude: 153.3833,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Lismore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8135,
    longitude: 153.2773,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Bentley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7667,
    longitude: 153.1167,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'South Gundurimba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.85,
    longitude: 153.2667,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Blue Knob',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5,
    longitude: 153.1833,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Blakebrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7667,
    longitude: 153.2333,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Eltham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7596,
    longitude: 153.4084,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Rosebank',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6667,
    longitude: 153.4,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Goonellabah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8167,
    longitude: 153.3167,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Bexhill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7667,
    longitude: 153.35,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Clovass',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8667,
    longitude: 153.15,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'South Lismore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'The Channon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6667,
    longitude: 153.2833,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Tregeagle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.85,
    longitude: 153.3667,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Jiggi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.677,
    longitude: 153.1843,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Chilcotts Grass',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Nimbin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5973,
    longitude: 153.2225,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Modanville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7219,
    longitude: 153.2956,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Fernside',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7818,
    longitude: 153.1679,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Keerrong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Bungabbee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7828,
    longitude: 153.1472,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Wyrallah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9,
    longitude: 153.3,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Koonorigan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6833,
    longitude: 153.2333,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Lismore Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Booyong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7484,
    longitude: 153.4494,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Eureka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6818,
    longitude: 153.4346,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Lillian Rock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5349,
    longitude: 153.1585,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Repentance Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Goolmangar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.75,
    longitude: 153.2167,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Tuncester',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8,
    longitude: 153.2167,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Whian Whian',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6396,
    longitude: 153.3145,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Dorroughby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6667,
    longitude: 153.35,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Rock Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7485,
    longitude: 153.1679,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Corndale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6985,
    longitude: 153.3679,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Tullera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7652,
    longitude: 153.3012,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Clunes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7281,
    longitude: 153.4075,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'North Lismore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.788,
    longitude: 153.2772,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Caniaba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8302,
    longitude: 153.2036,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Numulgi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7485,
    longitude: 153.3346,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Richmond Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7221,
    longitude: 153.2565,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Larnook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6516,
    longitude: 153.1108,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Coffee Camp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6569,
    longitude: 153.2281,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Leycester',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7833,
    longitude: 153.2,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Stony Chute',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Monaltrie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8521,
    longitude: 153.2906,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Nightcap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Girards Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8177,
    longitude: 153.2787,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Mountain Top',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Federal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6485,
    longitude: 153.4512,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Tuntable Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Georgica',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6606,
    longitude: 153.1724,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Booerie Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Dungarubba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9833,
    longitude: 153.3833,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Boorabee Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Mckees Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.8833,
    longitude: 153.2,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Terania Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Ruthven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9333,
    longitude: 153.2833,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Lindendale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Dunoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6823,
    longitude: 153.3175,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Loftville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Tuckurimba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9667,
    longitude: 153.3167,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Howards Grass',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Boat Harbour',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3167,
    longitude: 153.35,
    accuracy: 4,
  },
  {
    postcode: 2480,
    place_name: 'Lagoon Grass',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2480,
    place_name: 'Woodlawn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7522,
    longitude: 153.2836,
    accuracy: 3,
  },
  {
    postcode: 2481,
    place_name: 'Hayters Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6309,
    longitude: 153.5669,
    accuracy: 3,
  },
  {
    postcode: 2481,
    place_name: 'Broken Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7172,
    longitude: 153.5923,
    accuracy: 3,
  },
  {
    postcode: 2481,
    place_name: 'Suffolk Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6881,
    longitude: 153.6098,
    accuracy: 4,
  },
  {
    postcode: 2481,
    place_name: 'Talofa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6309,
    longitude: 153.5669,
    accuracy: 3,
  },
  {
    postcode: 2481,
    place_name: 'Ewingsdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6333,
    longitude: 153.55,
    accuracy: 4,
  },
  {
    postcode: 2481,
    place_name: 'Tyagarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6,
    longitude: 153.55,
    accuracy: 4,
  },
  {
    postcode: 2481,
    place_name: 'Byron Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.642,
    longitude: 153.6119,
    accuracy: 4,
  },
  {
    postcode: 2481,
    place_name: 'Skinners Shoot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6309,
    longitude: 153.5669,
    accuracy: 3,
  },
  {
    postcode: 2481,
    place_name: 'Myocum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.596,
    longitude: 153.5061,
    accuracy: 4,
  },
  {
    postcode: 2482,
    place_name: 'Upper Wilsons Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5657,
    longitude: 153.4396,
    accuracy: 3,
  },
  {
    postcode: 2482,
    place_name: 'Main Arm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5657,
    longitude: 153.4396,
    accuracy: 3,
  },
  {
    postcode: 2482,
    place_name: 'Upper Coopers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5657,
    longitude: 153.4396,
    accuracy: 3,
  },
  {
    postcode: 2482,
    place_name: 'Goonengerry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6107,
    longitude: 153.44,
    accuracy: 4,
  },
  {
    postcode: 2482,
    place_name: 'Montecollum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5657,
    longitude: 153.4396,
    accuracy: 3,
  },
  {
    postcode: 2482,
    place_name: 'Huonbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5485,
    longitude: 153.3846,
    accuracy: 4,
  },
  {
    postcode: 2482,
    place_name: 'Mullumbimby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5524,
    longitude: 153.4996,
    accuracy: 4,
  },
  {
    postcode: 2482,
    place_name: 'Mullumbimby Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5657,
    longitude: 153.4396,
    accuracy: 3,
  },
  {
    postcode: 2482,
    place_name: 'Upper Main Arm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5657,
    longitude: 153.4396,
    accuracy: 3,
  },
  {
    postcode: 2482,
    place_name: 'Koonyum Range',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5657,
    longitude: 153.4396,
    accuracy: 3,
  },
  {
    postcode: 2482,
    place_name: 'Wanganui',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5657,
    longitude: 153.4396,
    accuracy: 3,
  },
  {
    postcode: 2482,
    place_name: 'Palmwoods',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5657,
    longitude: 153.4396,
    accuracy: 3,
  },
  {
    postcode: 2482,
    place_name: 'Wilsons Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5606,
    longitude: 153.4255,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'Sleepy Hollow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4177,
    longitude: 153.5198,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'Middle Pocket',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4833,
    longitude: 153.5111,
    accuracy: 3,
  },
  {
    postcode: 2483,
    place_name: 'Crabbes Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.461,
    longitude: 153.4823,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'Burringbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4358,
    longitude: 153.4732,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'Upper Burringbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.45,
    longitude: 153.45,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'Wooyung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4833,
    longitude: 153.5111,
    accuracy: 3,
  },
  {
    postcode: 2483,
    place_name: 'Ocean Shores',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5093,
    longitude: 153.5376,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'Mooball',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.45,
    longitude: 153.4833,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'Yelgun',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.48,
    longitude: 153.4949,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'The Pocket',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5167,
    longitude: 153.4833,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'Brunswick Heads',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5415,
    longitude: 153.5481,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'New Brighton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5123,
    longitude: 153.5491,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'Billinudgel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5,
    longitude: 153.5333,
    accuracy: 4,
  },
  {
    postcode: 2483,
    place_name: 'South Golden Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.498,
    longitude: 153.547,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Dum Dum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3833,
    longitude: 153.3333,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Condong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3188,
    longitude: 153.4378,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Pumpenbil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3784,
    longitude: 153.1461,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Kielvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3333,
    longitude: 153.4338,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Chillingham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3135,
    longitude: 153.2752,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Crystal Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3143,
    longitude: 153.3151,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Reserve Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3503,
    longitude: 153.4924,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Murwillumbah South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3273,
    longitude: 153.3934,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Murwillumbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3273,
    longitude: 153.3934,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Kunghur',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4714,
    longitude: 153.252,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Terragon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4355,
    longitude: 153.263,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Mebbin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Round Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3549,
    longitude: 153.5443,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Fernvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3652,
    longitude: 153.419,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Dunbible',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3884,
    longitude: 153.4205,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Uki',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4141,
    longitude: 153.3366,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Dungay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2724,
    longitude: 153.3585,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Mount Burrell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5,
    longitude: 153.2167,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Clothiers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3302,
    longitude: 153.496,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Rowlands Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Zara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3163,
    longitude: 153.2585,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Dulguigan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Cudgera Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3833,
    longitude: 153.5167,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Tyalgum Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Limpinwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3076,
    longitude: 153.2173,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Doon Doon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.5167,
    longitude: 153.3,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Stokers Siding',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4085,
    longitude: 153.4143,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Farrants Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Eungella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3534,
    longitude: 153.3161,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Urliup',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Tomewin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2446,
    longitude: 153.3779,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Chowan Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Nobbys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2943,
    longitude: 153.3376,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Smiths Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4277,
    longitude: 153.378,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Commissioners Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4985,
    longitude: 153.3321,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Hopkins Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Byangum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.35,
    longitude: 153.3667,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'North Arm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'South Murwillumbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3273,
    longitude: 153.3934,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Back Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Palmvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3667,
    longitude: 153.4833,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Kunghur Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Numinbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2833,
    longitude: 153.25,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Cedar Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Eviron',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Byrrill Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Upper Crystal Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2997,
    longitude: 153.3014,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Mount Warning',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Bray Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3414,
    longitude: 153.3761,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Kynnumboon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Nunderi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3221,
    longitude: 153.4597,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Tygalgah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Brays Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4,
    longitude: 153.2,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Wardrop Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3665,
    longitude: 153.3497,
    accuracy: 3,
  },
  {
    postcode: 2484,
    place_name: 'Midginbil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.4971,
    longitude: 153.2596,
    accuracy: 4,
  },
  {
    postcode: 2484,
    place_name: 'Tyalgum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3567,
    longitude: 153.2058,
    accuracy: 4,
  },
  {
    postcode: 2485,
    place_name: 'Tweed Heads West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.1874,
    longitude: 153.5228,
    accuracy: 4,
  },
  {
    postcode: 2485,
    place_name: 'Tweed Heads',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.1756,
    longitude: 153.542,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Piggabeen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.1909,
    longitude: 153.4504,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Carool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2333,
    longitude: 153.4167,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Bilambil Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2161,
    longitude: 153.4841,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Tweed Heads South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.1756,
    longitude: 153.542,
    accuracy: 3,
  },
  {
    postcode: 2486,
    place_name: 'Cobaki',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.1985,
    longitude: 153.4644,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Banora Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.213,
    longitude: 153.5363,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Upper Duroby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2621,
    longitude: 153.4718,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Glengarrie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2173,
    longitude: 153.4848,
    accuracy: 3,
  },
  {
    postcode: 2486,
    place_name: 'Duroby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2621,
    longitude: 153.4718,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Terranora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2383,
    longitude: 153.5008,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Bilambil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2266,
    longitude: 153.4676,
    accuracy: 4,
  },
  {
    postcode: 2486,
    place_name: 'Cobaki Lakes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2173,
    longitude: 153.4848,
    accuracy: 3,
  },
  {
    postcode: 2486,
    place_name: 'Bungalora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2173,
    longitude: 153.4848,
    accuracy: 3,
  },
  {
    postcode: 2487,
    place_name: 'Duranbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3167,
    longitude: 153.5333,
    accuracy: 4,
  },
  {
    postcode: 2487,
    place_name: 'Cudgen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2667,
    longitude: 153.55,
    accuracy: 4,
  },
  {
    postcode: 2487,
    place_name: 'Kingscliff',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2598,
    longitude: 153.5782,
    accuracy: 4,
  },
  {
    postcode: 2487,
    place_name: 'Kings Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2646,
    longitude: 153.5474,
    accuracy: 3,
  },
  {
    postcode: 2487,
    place_name: 'Fingal Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2018,
    longitude: 153.5652,
    accuracy: 4,
  },
  {
    postcode: 2487,
    place_name: 'Casuarina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3004,
    longitude: 153.5706,
    accuracy: 4,
  },
  {
    postcode: 2487,
    place_name: 'Stotts Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2821,
    longitude: 153.509,
    accuracy: 4,
  },
  {
    postcode: 2487,
    place_name: 'Chinderah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2333,
    longitude: 153.55,
    accuracy: 4,
  },
  {
    postcode: 2488,
    place_name: 'Bogangar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3294,
    longitude: 153.5686,
    accuracy: 4,
  },
  {
    postcode: 2488,
    place_name: 'Tanglewood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3376,
    longitude: 153.5335,
    accuracy: 4,
  },
  {
    postcode: 2488,
    place_name: 'Cabarita Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3336,
    longitude: 153.5666,
    accuracy: 4,
  },
  {
    postcode: 2489,
    place_name: 'Pottsville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3885,
    longitude: 153.5656,
    accuracy: 4,
  },
  {
    postcode: 2489,
    place_name: 'Pottsville Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3885,
    longitude: 153.5656,
    accuracy: 4,
  },
  {
    postcode: 2489,
    place_name: 'Hastings Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.3641,
    longitude: 153.5748,
    accuracy: 4,
  },
  {
    postcode: 2490,
    place_name: 'North Tumbulgum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2621,
    longitude: 153.4718,
    accuracy: 4,
  },
  {
    postcode: 2490,
    place_name: 'Tumbulgum',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.2752,
    longitude: 153.4628,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'Wollongong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.424,
    longitude: 150.8934,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'Wollongong DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4295,
    longitude: 150.8756,
    accuracy: 3,
  },
  {
    postcode: 2500,
    place_name: 'Keiraville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4167,
    longitude: 150.8667,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'Mount Keira',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4189,
    longitude: 150.8542,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'Gwynneville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4167,
    longitude: 150.8875,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'Wollongong West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4248,
    longitude: 150.8642,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'Mangerton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4367,
    longitude: 150.8717,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'Spring Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3988,
    longitude: 149.1525,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'North Wollongong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4151,
    longitude: 150.8986,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'Coniston',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.45,
    longitude: 150.8833,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'Mount Saint Thomas',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4433,
    longitude: 150.8722,
    accuracy: 4,
  },
  {
    postcode: 2500,
    place_name: 'West Wollongong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4248,
    longitude: 150.8642,
    accuracy: 4,
  },
  {
    postcode: 2502,
    place_name: 'Primbee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5036,
    longitude: 150.8795,
    accuracy: 4,
  },
  {
    postcode: 2502,
    place_name: 'Cringila',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4667,
    longitude: 150.8833,
    accuracy: 4,
  },
  {
    postcode: 2502,
    place_name: 'Warrawong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.485,
    longitude: 150.8883,
    accuracy: 4,
  },
  {
    postcode: 2502,
    place_name: 'Lake Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4867,
    longitude: 150.875,
    accuracy: 4,
  },
  {
    postcode: 2505,
    place_name: 'Kemblawarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4944,
    longitude: 150.89,
    accuracy: 4,
  },
  {
    postcode: 2505,
    place_name: 'Port Kembla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4818,
    longitude: 150.9012,
    accuracy: 4,
  },
  {
    postcode: 2506,
    place_name: 'Berkeley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4833,
    longitude: 150.85,
    accuracy: 4,
  },
  {
    postcode: 2508,
    place_name: 'Maddens Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2005,
    longitude: 150.9802,
    accuracy: 3,
  },
  {
    postcode: 2508,
    place_name: 'Coalcliff',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2457,
    longitude: 150.9682,
    accuracy: 4,
  },
  {
    postcode: 2508,
    place_name: 'Helensburgh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1784,
    longitude: 150.9952,
    accuracy: 4,
  },
  {
    postcode: 2508,
    place_name: 'Otford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2129,
    longitude: 150.9982,
    accuracy: 4,
  },
  {
    postcode: 2508,
    place_name: 'Woronora Dam',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1167,
    longitude: 150.9333,
    accuracy: 4,
  },
  {
    postcode: 2508,
    place_name: 'Darkes Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2377,
    longitude: 150.9195,
    accuracy: 4,
  },
  {
    postcode: 2508,
    place_name: 'Stanwell Tops',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2193,
    longitude: 150.9764,
    accuracy: 4,
  },
  {
    postcode: 2508,
    place_name: 'Stanwell Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2261,
    longitude: 150.9858,
    accuracy: 4,
  },
  {
    postcode: 2508,
    place_name: 'Lilyvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1861,
    longitude: 151.0165,
    accuracy: 4,
  },
  {
    postcode: 2515,
    place_name: 'Coledale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.291,
    longitude: 150.9447,
    accuracy: 4,
  },
  {
    postcode: 2515,
    place_name: 'Thirroul',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.316,
    longitude: 150.9214,
    accuracy: 4,
  },
  {
    postcode: 2515,
    place_name: 'Clifton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.25,
    longitude: 150.9667,
    accuracy: 4,
  },
  {
    postcode: 2515,
    place_name: 'Wombarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2833,
    longitude: 150.95,
    accuracy: 4,
  },
  {
    postcode: 2515,
    place_name: 'Scarborough',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2732,
    longitude: 150.9541,
    accuracy: 4,
  },
  {
    postcode: 2515,
    place_name: 'Austinmer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3052,
    longitude: 150.9334,
    accuracy: 4,
  },
  {
    postcode: 2516,
    place_name: 'Bulli',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3383,
    longitude: 150.9135,
    accuracy: 4,
  },
  {
    postcode: 2517,
    place_name: 'Woonona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3493,
    longitude: 150.9144,
    accuracy: 4,
  },
  {
    postcode: 2517,
    place_name: 'Woonona East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3524,
    longitude: 150.9044,
    accuracy: 3,
  },
  {
    postcode: 2517,
    place_name: 'Russell Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3554,
    longitude: 150.8943,
    accuracy: 4,
  },
  {
    postcode: 2518,
    place_name: 'Bellambi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3667,
    longitude: 150.9167,
    accuracy: 4,
  },
  {
    postcode: 2518,
    place_name: 'Corrimal East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3761,
    longitude: 150.9108,
    accuracy: 4,
  },
  {
    postcode: 2518,
    place_name: 'East Corrimal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3761,
    longitude: 150.9108,
    accuracy: 4,
  },
  {
    postcode: 2518,
    place_name: 'Tarrawanna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3815,
    longitude: 150.888,
    accuracy: 4,
  },
  {
    postcode: 2518,
    place_name: 'Corrimal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3786,
    longitude: 150.9036,
    accuracy: 4,
  },
  {
    postcode: 2518,
    place_name: 'Towradgi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3867,
    longitude: 150.9028,
    accuracy: 4,
  },
  {
    postcode: 2519,
    place_name: 'Balgownie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3951,
    longitude: 150.8805,
    accuracy: 4,
  },
  {
    postcode: 2519,
    place_name: 'Fernhill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.17,
    longitude: 151.0052,
    accuracy: 3,
  },
  {
    postcode: 2519,
    place_name: 'Mount Ousley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4021,
    longitude: 150.8879,
    accuracy: 4,
  },
  {
    postcode: 2519,
    place_name: 'Fairy Meadow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.393,
    longitude: 150.8928,
    accuracy: 4,
  },
  {
    postcode: 2519,
    place_name: 'Mount Pleasant',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3953,
    longitude: 150.8666,
    accuracy: 4,
  },
  {
    postcode: 2520,
    place_name: 'Wollongong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.424,
    longitude: 150.8934,
    accuracy: 4,
  },
  {
    postcode: 2521,
    place_name: 'South Coast Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5262,
    longitude: 150.8534,
    accuracy: 1,
  },
  {
    postcode: 2522,
    place_name: 'University Of Wollongong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5262,
    longitude: 150.8534,
    accuracy: 1,
  },
  {
    postcode: 2525,
    place_name: 'Figtree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4356,
    longitude: 150.8583,
    accuracy: 4,
  },
  {
    postcode: 2526,
    place_name: 'Cordeaux',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4404,
    longitude: 150.8375,
    accuracy: 4,
  },
  {
    postcode: 2526,
    place_name: 'Cordeaux Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4404,
    longitude: 150.8375,
    accuracy: 4,
  },
  {
    postcode: 2526,
    place_name: 'Kembla Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4305,
    longitude: 150.8069,
    accuracy: 4,
  },
  {
    postcode: 2526,
    place_name: 'Kembla Grange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4663,
    longitude: 150.8067,
    accuracy: 4,
  },
  {
    postcode: 2526,
    place_name: 'Mount Kembla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4328,
    longitude: 150.8205,
    accuracy: 3,
  },
  {
    postcode: 2526,
    place_name: 'Farmborough Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.455,
    longitude: 150.8131,
    accuracy: 4,
  },
  {
    postcode: 2526,
    place_name: 'Unanderra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4531,
    longitude: 150.8475,
    accuracy: 4,
  },
  {
    postcode: 2527,
    place_name: 'Tullimbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5833,
    longitude: 150.75,
    accuracy: 4,
  },
  {
    postcode: 2527,
    place_name: 'Tongarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5833,
    longitude: 150.6833,
    accuracy: 4,
  },
  {
    postcode: 2527,
    place_name: 'Calderwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5505,
    longitude: 150.7113,
    accuracy: 4,
  },
  {
    postcode: 2527,
    place_name: 'Yellow Rock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6,
    longitude: 150.7333,
    accuracy: 4,
  },
  {
    postcode: 2527,
    place_name: 'Albion Park Rail',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5612,
    longitude: 150.7968,
    accuracy: 4,
  },
  {
    postcode: 2527,
    place_name: 'North Macquarie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5845,
    longitude: 150.7356,
    accuracy: 3,
  },
  {
    postcode: 2527,
    place_name: 'Albion Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5713,
    longitude: 150.7757,
    accuracy: 4,
  },
  {
    postcode: 2527,
    place_name: 'Croom',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5943,
    longitude: 150.8413,
    accuracy: 4,
  },
  {
    postcode: 2528,
    place_name: 'Warilla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5519,
    longitude: 150.8583,
    accuracy: 4,
  },
  {
    postcode: 2528,
    place_name: 'Lake Illawarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5466,
    longitude: 150.8564,
    accuracy: 4,
  },
  {
    postcode: 2528,
    place_name: 'Windang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5333,
    longitude: 150.8667,
    accuracy: 4,
  },
  {
    postcode: 2528,
    place_name: 'Barrack Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.566,
    longitude: 150.8552,
    accuracy: 4,
  },
  {
    postcode: 2528,
    place_name: 'Mount Warrigal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.548,
    longitude: 150.8375,
    accuracy: 4,
  },
  {
    postcode: 2528,
    place_name: 'Barrack Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5628,
    longitude: 150.8668,
    accuracy: 4,
  },
  {
    postcode: 2529,
    place_name: 'Shell Cove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5887,
    longitude: 150.8725,
    accuracy: 4,
  },
  {
    postcode: 2529,
    place_name: 'Shellharbour City Centre',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5636,
    longitude: 150.8383,
    accuracy: 4,
  },
  {
    postcode: 2529,
    place_name: 'Blackbutt',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5706,
    longitude: 150.8396,
    accuracy: 4,
  },
  {
    postcode: 2529,
    place_name: 'Oak Flats',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5623,
    longitude: 150.8219,
    accuracy: 4,
  },
  {
    postcode: 2529,
    place_name: 'Flinders',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5833,
    longitude: 150.8552,
    accuracy: 4,
  },
  {
    postcode: 2529,
    place_name: 'Shellharbour Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5753,
    longitude: 150.8442,
    accuracy: 3,
  },
  {
    postcode: 2529,
    place_name: 'Dunmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6,
    longitude: 150.85,
    accuracy: 4,
  },
  {
    postcode: 2529,
    place_name: 'Shellharbour',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5833,
    longitude: 150.8667,
    accuracy: 4,
  },
  {
    postcode: 2529,
    place_name: 'Oak Flats Dc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5753,
    longitude: 150.8442,
    accuracy: 3,
  },
  {
    postcode: 2530,
    place_name: 'Penrose',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6707,
    longitude: 150.2137,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Yallah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5369,
    longitude: 150.7852,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Koonawarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5021,
    longitude: 150.8085,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Dombarton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4586,
    longitude: 150.7718,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Huntley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4976,
    longitude: 150.7421,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Brownsville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4833,
    longitude: 150.8,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Haywards Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5339,
    longitude: 150.7916,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Cleveland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5492,
    longitude: 150.4827,
    accuracy: 3,
  },
  {
    postcode: 2530,
    place_name: 'Kanahooka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4933,
    longitude: 150.8188,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Dapto',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5,
    longitude: 150.7833,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Marshall Mount',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.55,
    longitude: 150.75,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Wongawilli',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4775,
    longitude: 150.7568,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Avondale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5175,
    longitude: 150.7501,
    accuracy: 4,
  },
  {
    postcode: 2530,
    place_name: 'Horsley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4876,
    longitude: 150.778,
    accuracy: 4,
  },
  {
    postcode: 2533,
    place_name: 'Jerrara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6701,
    longitude: 150.8231,
    accuracy: 4,
  },
  {
    postcode: 2533,
    place_name: 'Kiama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6683,
    longitude: 150.8522,
    accuracy: 4,
  },
  {
    postcode: 2533,
    place_name: 'Jamberoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6474,
    longitude: 150.7746,
    accuracy: 4,
  },
  {
    postcode: 2533,
    place_name: 'Curramore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6234,
    longitude: 150.7607,
    accuracy: 4,
  },
  {
    postcode: 2533,
    place_name: 'Bombo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6521,
    longitude: 150.8596,
    accuracy: 4,
  },
  {
    postcode: 2533,
    place_name: 'Kiama Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6497,
    longitude: 150.8256,
    accuracy: 3,
  },
  {
    postcode: 2533,
    place_name: 'Minnamurra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6333,
    longitude: 150.85,
    accuracy: 4,
  },
  {
    postcode: 2533,
    place_name: 'Kiama Downs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6334,
    longitude: 150.8546,
    accuracy: 4,
  },
  {
    postcode: 2533,
    place_name: 'Saddleback Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6961,
    longitude: 150.8169,
    accuracy: 4,
  },
  {
    postcode: 2534,
    place_name: 'Broughton Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7667,
    longitude: 150.75,
    accuracy: 4,
  },
  {
    postcode: 2534,
    place_name: 'Willow Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7381,
    longitude: 150.7998,
    accuracy: 4,
  },
  {
    postcode: 2534,
    place_name: 'Werri Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7368,
    longitude: 150.8309,
    accuracy: 4,
  },
  {
    postcode: 2534,
    place_name: 'Toolijooa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7649,
    longitude: 150.7831,
    accuracy: 4,
  },
  {
    postcode: 2534,
    place_name: 'Gerringong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.747,
    longitude: 150.8281,
    accuracy: 4,
  },
  {
    postcode: 2534,
    place_name: 'Rose Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7235,
    longitude: 150.8096,
    accuracy: 4,
  },
  {
    postcode: 2534,
    place_name: 'Gerroa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7691,
    longitude: 150.8101,
    accuracy: 4,
  },
  {
    postcode: 2534,
    place_name: 'Foxground',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7152,
    longitude: 150.7679,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Coolangatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8528,
    longitude: 150.7262,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Broughton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.75,
    longitude: 150.7667,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Jaspers Brush',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8167,
    longitude: 150.6667,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Far Meadow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.781,
    longitude: 150.705,
    accuracy: 3,
  },
  {
    postcode: 2535,
    place_name: 'Wattamolla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7333,
    longitude: 150.65,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Bellawongarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7667,
    longitude: 150.6333,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Woodhill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7333,
    longitude: 150.7,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Budderoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7819,
    longitude: 150.68,
    accuracy: 3,
  },
  {
    postcode: 2535,
    place_name: 'Shoalhaven Heads',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8509,
    longitude: 150.7451,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Berry Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.781,
    longitude: 150.705,
    accuracy: 3,
  },
  {
    postcode: 2535,
    place_name: 'Broughton Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.75,
    longitude: 150.7667,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Brogers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.781,
    longitude: 150.705,
    accuracy: 3,
  },
  {
    postcode: 2535,
    place_name: 'Berry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7751,
    longitude: 150.6944,
    accuracy: 4,
  },
  {
    postcode: 2535,
    place_name: 'Bundewallah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.781,
    longitude: 150.705,
    accuracy: 3,
  },
  {
    postcode: 2535,
    place_name: 'Back Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.781,
    longitude: 150.705,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'South Durras',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6513,
    longitude: 150.2952,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Guerilla Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6319,
    longitude: 150.1637,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'Catalina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.73,
    longitude: 150.1849,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Denhams Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7509,
    longitude: 150.2114,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Maloneys Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7059,
    longitude: 150.248,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'North Batemans Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7003,
    longitude: 150.1832,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'Jeremadra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8091,
    longitude: 150.1366,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'Pebbly Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.64,
    longitude: 150.1533,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'Surfside',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6953,
    longitude: 150.1995,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Bimbimbie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6319,
    longitude: 150.1637,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'Benandarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6333,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Buckenbowra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7333,
    longitude: 150.0667,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Long Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7083,
    longitude: 150.2448,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Surf Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6319,
    longitude: 150.1637,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'Mogo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7848,
    longitude: 150.1417,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Depot Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.64,
    longitude: 150.1533,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'Lilli Pilli',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7759,
    longitude: 150.228,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Runnyford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6319,
    longitude: 150.1637,
    accuracy: 3,
  },
  {
    postcode: 2536,
    place_name: 'Batemans Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7066,
    longitude: 150.1754,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Sunshine Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7454,
    longitude: 150.2079,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Malua Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7938,
    longitude: 150.2283,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Batehaven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7376,
    longitude: 150.1984,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Nelligen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6467,
    longitude: 150.1362,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Woodlands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3166,
    longitude: 149.8863,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Rosedale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.816,
    longitude: 150.2225,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'East Lynne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.59,
    longitude: 150.2927,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Currowan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5833,
    longitude: 150.1667,
    accuracy: 4,
  },
  {
    postcode: 2536,
    place_name: 'Durras North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6319,
    longitude: 150.1637,
    accuracy: 3,
  },
  {
    postcode: 2537,
    place_name: 'Congo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9552,
    longitude: 150.1575,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Meringo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8613,
    longitude: 149.9495,
    accuracy: 3,
  },
  {
    postcode: 2537,
    place_name: 'Mossy Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8398,
    longitude: 150.1804,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Moruya Heads',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9177,
    longitude: 150.1537,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Bergalia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9818,
    longitude: 150.1012,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Deua',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8613,
    longitude: 149.9495,
    accuracy: 3,
  },
  {
    postcode: 2537,
    place_name: 'Bingie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0105,
    longitude: 150.1483,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Moruya',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9125,
    longitude: 150.0814,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Mogendoura',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8652,
    longitude: 150.0512,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Wamban',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9334,
    longitude: 150.1203,
    accuracy: 3,
  },
  {
    postcode: 2537,
    place_name: 'Broulee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8547,
    longitude: 150.1739,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Coila',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0068,
    longitude: 150.0965,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Tuross Head',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0533,
    longitude: 150.1332,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Deua River Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8613,
    longitude: 149.9495,
    accuracy: 3,
  },
  {
    postcode: 2537,
    place_name: 'Tomakin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8233,
    longitude: 150.1879,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Kiora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9333,
    longitude: 150.0333,
    accuracy: 4,
  },
  {
    postcode: 2537,
    place_name: 'Turlinjah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0333,
    longitude: 150.1,
    accuracy: 4,
  },
  {
    postcode: 2538,
    place_name: 'Mogood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4918,
    longitude: 150.1905,
    accuracy: 4,
  },
  {
    postcode: 2538,
    place_name: 'Little Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4271,
    longitude: 150.3108,
    accuracy: 3,
  },
  {
    postcode: 2538,
    place_name: 'Brooman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 2538,
    place_name: 'Woodstock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7446,
    longitude: 148.8489,
    accuracy: 4,
  },
  {
    postcode: 2538,
    place_name: 'Milton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3164,
    longitude: 150.4361,
    accuracy: 4,
  },
  {
    postcode: 2538,
    place_name: 'Porters Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4271,
    longitude: 150.3108,
    accuracy: 3,
  },
  {
    postcode: 2538,
    place_name: 'Morton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4,
    longitude: 150.3667,
    accuracy: 4,
  },
  {
    postcode: 2538,
    place_name: 'Woodburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4271,
    longitude: 150.3108,
    accuracy: 3,
  },
  {
    postcode: 2539,
    place_name: 'Conjola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2167,
    longitude: 150.45,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Croobyar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3549,
    longitude: 150.447,
    accuracy: 3,
  },
  {
    postcode: 2539,
    place_name: 'Bawley Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5078,
    longitude: 150.3892,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Yatte Yattah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2588,
    longitude: 150.4258,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Burrill Lake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3872,
    longitude: 150.4495,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Kioloa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5542,
    longitude: 150.3789,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Fishermans Paradise',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2296,
    longitude: 150.4449,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Berringer Lake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2533,
    longitude: 150.498,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Cunjurong Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2598,
    longitude: 150.5049,
    accuracy: 3,
  },
  {
    postcode: 2539,
    place_name: 'Pretty Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5167,
    longitude: 151.35,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Mollymook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3297,
    longitude: 150.4717,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Lake Tabourie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4392,
    longitude: 150.3983,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Bendalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2467,
    longitude: 150.5284,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Cockwhy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3549,
    longitude: 150.447,
    accuracy: 3,
  },
  {
    postcode: 2539,
    place_name: 'Conjola Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2631,
    longitude: 150.4398,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Yadboro',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3549,
    longitude: 150.447,
    accuracy: 3,
  },
  {
    postcode: 2539,
    place_name: 'Ulladulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3591,
    longitude: 150.4725,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Mollymook Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3297,
    longitude: 150.4717,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Termeil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4667,
    longitude: 150.35,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Pointer Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3549,
    longitude: 150.447,
    accuracy: 3,
  },
  {
    postcode: 2539,
    place_name: 'Manyana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2551,
    longitude: 150.5137,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Narrawallee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3124,
    longitude: 150.4635,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Lake Conjola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2681,
    longitude: 150.4908,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Kings Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3702,
    longitude: 150.4341,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Dolphin Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4018,
    longitude: 150.4436,
    accuracy: 4,
  },
  {
    postcode: 2539,
    place_name: 'Mount Kingiman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3549,
    longitude: 150.447,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Culburra Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9303,
    longitude: 150.7587,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Tomerong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0523,
    longitude: 150.5865,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Sussex Inlet',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1567,
    longitude: 150.5853,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Currarong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0151,
    longitude: 150.8269,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Worrigee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8904,
    longitude: 150.6261,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Cambewarra Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Cudmirrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.198,
    longitude: 150.5562,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Terara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8833,
    longitude: 150.6333,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Meroo Meadow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8099,
    longitude: 150.6184,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Browns Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Illaroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Old Erowal Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0846,
    longitude: 150.6457,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Worrowing Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.077,
    longitude: 150.6406,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Twelve Mile Peg',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Hyams Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1014,
    longitude: 150.6924,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Brundee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9,
    longitude: 150.6833,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Nowra Naval Po',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Nowra Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Sanctuary Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1036,
    longitude: 150.6267,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'St Georges Basin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0908,
    longitude: 150.5978,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Pyree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9167,
    longitude: 150.7,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Moollattoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Callala Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0056,
    longitude: 150.6958,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Myola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0231,
    longitude: 150.6758,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Orient Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9091,
    longitude: 150.7497,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Wrights Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.028,
    longitude: 150.5501,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Mundamia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Tullarwalla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Tallowal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Bream Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Callala Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9967,
    longitude: 150.7228,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Yalwal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9333,
    longitude: 150.4,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Boolijah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Yerriyong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Berrara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.205,
    longitude: 150.5525,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Vincentia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0682,
    longitude: 150.6748,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Greenwell Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9075,
    longitude: 150.7311,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Mondayong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Kinghorne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9896,
    longitude: 150.7837,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Wandandian',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1,
    longitude: 150.5,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Falls Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9667,
    longitude: 150.6,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Ettrema',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Woollamia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0167,
    longitude: 150.65,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Beecroft Peninsula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0431,
    longitude: 150.8071,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Wollumboola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9884,
    longitude: 150.7436,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Bewong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Erowal Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0999,
    longitude: 150.6505,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Burrier',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8833,
    longitude: 150.4667,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Comerong Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8654,
    longitude: 150.7365,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Swanhaven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.028,
    longitude: 150.5501,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Basin View',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0883,
    longitude: 150.5553,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Bamarang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.028,
    longitude: 150.5501,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Bolong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.85,
    longitude: 150.6667,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Numbaa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.028,
    longitude: 150.5501,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Hmas Albatross',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.028,
    longitude: 150.5501,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Buangla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Barringella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Comberton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Longreach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Cambewarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8233,
    longitude: 150.5594,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Jerrawangala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1167,
    longitude: 150.4833,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Watersleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Huskisson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0401,
    longitude: 150.668,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Parma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0169,
    longitude: 150.6319,
    accuracy: 3,
  },
  {
    postcode: 2540,
    place_name: 'Tapitallee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8298,
    longitude: 150.5397,
    accuracy: 4,
  },
  {
    postcode: 2540,
    place_name: 'Mayfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8979,
    longitude: 151.7361,
    accuracy: 4,
  },
  {
    postcode: 2541,
    place_name: 'Nowra Dc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8545,
    longitude: 150.6167,
    accuracy: 3,
  },
  {
    postcode: 2541,
    place_name: 'Bomaderry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8497,
    longitude: 150.6109,
    accuracy: 4,
  },
  {
    postcode: 2541,
    place_name: 'Nowra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8842,
    longitude: 150.6004,
    accuracy: 4,
  },
  {
    postcode: 2541,
    place_name: 'West Nowra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8727,
    longitude: 150.6039,
    accuracy: 3,
  },
  {
    postcode: 2541,
    place_name: 'South Nowra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8842,
    longitude: 150.6004,
    accuracy: 4,
  },
  {
    postcode: 2541,
    place_name: 'Nowra East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8727,
    longitude: 150.6039,
    accuracy: 3,
  },
  {
    postcode: 2541,
    place_name: 'North Nowra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8727,
    longitude: 150.6039,
    accuracy: 3,
  },
  {
    postcode: 2541,
    place_name: 'Nowra North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8727,
    longitude: 150.6039,
    accuracy: 3,
  },
  {
    postcode: 2541,
    place_name: 'Bangalee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8545,
    longitude: 150.6167,
    accuracy: 3,
  },
  {
    postcode: 2545,
    place_name: 'Belowra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.199,
    longitude: 149.7088,
    accuracy: 4,
  },
  {
    postcode: 2545,
    place_name: 'Bodalla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0912,
    longitude: 150.0506,
    accuracy: 4,
  },
  {
    postcode: 2545,
    place_name: 'Eurobodalla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1485,
    longitude: 149.9679,
    accuracy: 4,
  },
  {
    postcode: 2545,
    place_name: 'Potato Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.138,
    longitude: 149.9085,
    accuracy: 3,
  },
  {
    postcode: 2545,
    place_name: 'Cadgee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1582,
    longitude: 149.8996,
    accuracy: 4,
  },
  {
    postcode: 2545,
    place_name: 'Nerrigundah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1167,
    longitude: 149.9,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Wallaga Lake Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3684,
    longitude: 150.0718,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Murrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5,
    longitude: 150.0167,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Narooma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2178,
    longitude: 150.1325,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Tinpot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2167,
    longitude: 149.8667,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Akolele',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3555,
    longitude: 150.0772,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Bermagui',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.419,
    longitude: 150.0636,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Corunna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2818,
    longitude: 150.1012,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Kianga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1945,
    longitude: 150.1268,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Central Tilba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3131,
    longitude: 150.0731,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Tilba Tilba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3247,
    longitude: 150.0599,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Dalmeny',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1661,
    longitude: 150.1291,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Beauty Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2614,
    longitude: 150.0075,
    accuracy: 3,
  },
  {
    postcode: 2546,
    place_name: 'Wallaga Lake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3684,
    longitude: 150.0718,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Cuttagee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2614,
    longitude: 150.0075,
    accuracy: 3,
  },
  {
    postcode: 2546,
    place_name: 'Dignams Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3485,
    longitude: 149.9846,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Barragga Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2614,
    longitude: 150.0075,
    accuracy: 3,
  },
  {
    postcode: 2546,
    place_name: 'Wadbilliga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2555,
    longitude: 149.6512,
    accuracy: 4,
  },
  {
    postcode: 2546,
    place_name: 'Mystery Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3073,
    longitude: 150.0259,
    accuracy: 3,
  },
  {
    postcode: 2546,
    place_name: 'North Narooma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2027,
    longitude: 150.1191,
    accuracy: 3,
  },
  {
    postcode: 2548,
    place_name: 'Bournda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8763,
    longitude: 149.9199,
    accuracy: 3,
  },
  {
    postcode: 2548,
    place_name: 'Tura Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8636,
    longitude: 149.9303,
    accuracy: 4,
  },
  {
    postcode: 2548,
    place_name: 'Merimbula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.889,
    longitude: 149.9096,
    accuracy: 4,
  },
  {
    postcode: 2548,
    place_name: 'Berrambool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8911,
    longitude: 149.9075,
    accuracy: 3,
  },
  {
    postcode: 2548,
    place_name: 'Mirador',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8763,
    longitude: 149.9199,
    accuracy: 3,
  },
  {
    postcode: 2548,
    place_name: 'Yellow Pinch',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8763,
    longitude: 149.9199,
    accuracy: 3,
  },
  {
    postcode: 2549,
    place_name: 'Nethercote',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.0152,
    longitude: 149.8346,
    accuracy: 4,
  },
  {
    postcode: 2549,
    place_name: 'Broadwater',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9354,
    longitude: 149.8338,
    accuracy: 3,
  },
  {
    postcode: 2549,
    place_name: 'Pambula Beach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9417,
    longitude: 149.9034,
    accuracy: 4,
  },
  {
    postcode: 2549,
    place_name: 'Pambula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9301,
    longitude: 149.8745,
    accuracy: 4,
  },
  {
    postcode: 2549,
    place_name: 'Greigs Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9652,
    longitude: 149.8679,
    accuracy: 4,
  },
  {
    postcode: 2549,
    place_name: 'Millingandi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8667,
    longitude: 149.8333,
    accuracy: 4,
  },
  {
    postcode: 2549,
    place_name: 'Lochiel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9485,
    longitude: 149.8179,
    accuracy: 4,
  },
  {
    postcode: 2549,
    place_name: 'Bald Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9117,
    longitude: 149.8428,
    accuracy: 4,
  },
  {
    postcode: 2549,
    place_name: 'South Pambula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9301,
    longitude: 149.8745,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Wog Wog',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Jellat Jellat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7167,
    longitude: 149.8833,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Nelson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6462,
    longitude: 150.9214,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Bournda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8368,
    longitude: 149.913,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Mumbulla Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7267,
    longitude: 149.6829,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Burragate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37,
    longitude: 149.6167,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Coolangubra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Wandella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9137,
    longitude: 146.0441,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Doctor George Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7267,
    longitude: 149.6829,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Quaama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4667,
    longitude: 149.8667,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Greendale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4255,
    longitude: 149.9534,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Mogilla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6985,
    longitude: 149.5846,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Devils Hole',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Mogareeka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6984,
    longitude: 149.976,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Verona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4667,
    longitude: 149.8167,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Towamba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.0833,
    longitude: 149.6833,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Reedy Swamp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Cobargo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3879,
    longitude: 149.8863,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Stony Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Kalaru',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7312,
    longitude: 149.9294,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Myrtle Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Numbugga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6333,
    longitude: 149.75,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Yambulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2167,
    longitude: 149.55,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Kameruka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7318,
    longitude: 149.7012,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Candelo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7677,
    longitude: 149.6947,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Bemboka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6289,
    longitude: 149.5718,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Tarraganda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6609,
    longitude: 149.8662,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Angledale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6318,
    longitude: 149.8846,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Rocky Hall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9318,
    longitude: 149.5096,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'South Wolumla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8152,
    longitude: 149.7512,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Wapengo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5985,
    longitude: 149.9846,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Bega',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6739,
    longitude: 149.8418,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Black Range',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Tanja',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6,
    longitude: 149.9833,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Pericoe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.1167,
    longitude: 149.6167,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Morans Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6667,
    longitude: 149.65,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Tathra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7313,
    longitude: 149.9833,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Wolumla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8308,
    longitude: 149.8131,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Wallagoot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Brogo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5333,
    longitude: 149.8333,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Tantawangalo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Chinnock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Kanoona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'New Buildings',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Frogs Hollow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Buckajo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7167,
    longitude: 149.7167,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Wyndham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9318,
    longitude: 149.6512,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Yowrie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3,
    longitude: 149.7167,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Coolagolite',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Toothdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7818,
    longitude: 149.7512,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Yankees Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2550,
    place_name: 'Kingswood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.75,
    longitude: 150.75,
    accuracy: 4,
  },
  {
    postcode: 2550,
    place_name: 'Coopers Gully',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.714,
    longitude: 149.7654,
    accuracy: 3,
  },
  {
    postcode: 2551,
    place_name: 'Boydtown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.0833,
    longitude: 149.9167,
    accuracy: 4,
  },
  {
    postcode: 2551,
    place_name: 'Green Cape',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2032,
    longitude: 149.7397,
    accuracy: 3,
  },
  {
    postcode: 2551,
    place_name: 'Wonboyn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2505,
    longitude: 149.9382,
    accuracy: 4,
  },
  {
    postcode: 2551,
    place_name: 'Kiah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.15,
    longitude: 149.8667,
    accuracy: 4,
  },
  {
    postcode: 2551,
    place_name: 'Eden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.0632,
    longitude: 149.904,
    accuracy: 4,
  },
  {
    postcode: 2551,
    place_name: 'Wonboyn North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2032,
    longitude: 149.7397,
    accuracy: 3,
  },
  {
    postcode: 2551,
    place_name: 'Timbillica',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.3652,
    longitude: 149.7012,
    accuracy: 4,
  },
  {
    postcode: 2551,
    place_name: 'Wonboyn Lake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.219,
    longitude: 149.83,
    accuracy: 3,
  },
  {
    postcode: 2551,
    place_name: 'Nungatta South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2152,
    longitude: 149.4012,
    accuracy: 4,
  },
  {
    postcode: 2551,
    place_name: 'Edrom',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2032,
    longitude: 149.7397,
    accuracy: 3,
  },
  {
    postcode: 2551,
    place_name: 'Nungatta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2152,
    longitude: 149.4012,
    accuracy: 4,
  },
  {
    postcode: 2551,
    place_name: 'Nadgee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2032,
    longitude: 149.7397,
    accuracy: 3,
  },
  {
    postcode: 2551,
    place_name: 'Narrabarba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2568,
    longitude: 149.8179,
    accuracy: 4,
  },
  {
    postcode: 2551,
    place_name: 'Nullica',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.2032,
    longitude: 149.7397,
    accuracy: 3,
  },
  {
    postcode: 2555,
    place_name: 'Badgerys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8907,
    longitude: 150.7427,
    accuracy: 4,
  },
  {
    postcode: 2556,
    place_name: 'Bringelly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9333,
    longitude: 150.7333,
    accuracy: 4,
  },
  {
    postcode: 2557,
    place_name: 'Rossmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9748,
    longitude: 150.7634,
    accuracy: 3,
  },
  {
    postcode: 2557,
    place_name: 'Catherine Field',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0044,
    longitude: 150.7725,
    accuracy: 4,
  },
  {
    postcode: 2557,
    place_name: 'Gregory Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0267,
    longitude: 150.7707,
    accuracy: 4,
  },
  {
    postcode: 2557,
    place_name: 'Gledswood Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0158,
    longitude: 150.7651,
    accuracy: 4,
  },
  {
    postcode: 2558,
    place_name: 'Eschol Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0298,
    longitude: 150.8096,
    accuracy: 4,
  },
  {
    postcode: 2558,
    place_name: 'Kearns',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0219,
    longitude: 150.8008,
    accuracy: 4,
  },
  {
    postcode: 2558,
    place_name: 'Eagle Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0375,
    longitude: 150.8136,
    accuracy: 4,
  },
  {
    postcode: 2559,
    place_name: 'Claymore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0465,
    longitude: 150.8111,
    accuracy: 4,
  },
  {
    postcode: 2559,
    place_name: 'Blairmount',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0508,
    longitude: 150.7924,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Leumeah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.05,
    longitude: 150.8333,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Englorie Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0804,
    longitude: 150.7961,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Woodbine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0459,
    longitude: 150.8215,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Glen Alpine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.086,
    longitude: 150.7851,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Kentlyn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0604,
    longitude: 150.8654,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Wedderburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1333,
    longitude: 150.8167,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Macarthur Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0887,
    longitude: 150.8165,
    accuracy: 3,
  },
  {
    postcode: 2560,
    place_name: 'Blair Athol',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0621,
    longitude: 150.8014,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Appin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2035,
    longitude: 150.7864,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Gilead',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1057,
    longitude: 150.764,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Rosemeadow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1041,
    longitude: 150.7928,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Airds',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.086,
    longitude: 150.8332,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Campbelltown North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0887,
    longitude: 150.8165,
    accuracy: 3,
  },
  {
    postcode: 2560,
    place_name: 'Ruse',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0698,
    longitude: 150.8417,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Cataract',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0887,
    longitude: 150.8165,
    accuracy: 3,
  },
  {
    postcode: 2560,
    place_name: 'Bradbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0854,
    longitude: 150.8154,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'St Helens Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1047,
    longitude: 150.8137,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Campbelltown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0667,
    longitude: 150.8167,
    accuracy: 4,
  },
  {
    postcode: 2560,
    place_name: 'Ambarvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0894,
    longitude: 150.7966,
    accuracy: 4,
  },
  {
    postcode: 2563,
    place_name: 'Menangle Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1044,
    longitude: 150.7507,
    accuracy: 4,
  },
  {
    postcode: 2564,
    place_name: 'Long Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0167,
    longitude: 150.9,
    accuracy: 4,
  },
  {
    postcode: 2564,
    place_name: 'Macquarie Fields',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9921,
    longitude: 150.8931,
    accuracy: 4,
  },
  {
    postcode: 2564,
    place_name: 'Glenquarie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0044,
    longitude: 150.8965,
    accuracy: 3,
  },
  {
    postcode: 2565,
    place_name: 'Ingleburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34,
    longitude: 150.8667,
    accuracy: 4,
  },
  {
    postcode: 2565,
    place_name: 'Macquarie Links',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9845,
    longitude: 150.8703,
    accuracy: 4,
  },
  {
    postcode: 2565,
    place_name: 'Denham Court',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9831,
    longitude: 150.8461,
    accuracy: 4,
  },
  {
    postcode: 2565,
    place_name: 'Bardia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34,
    longitude: 150.8667,
    accuracy: 3,
  },
  {
    postcode: 2566,
    place_name: 'Minto Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0532,
    longitude: 150.844,
    accuracy: 4,
  },
  {
    postcode: 2566,
    place_name: 'Minto DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0188,
    longitude: 150.8297,
    accuracy: 3,
  },
  {
    postcode: 2566,
    place_name: 'St Andrews',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0224,
    longitude: 150.8296,
    accuracy: 4,
  },
  {
    postcode: 2566,
    place_name: 'Bow Bowing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0165,
    longitude: 150.8403,
    accuracy: 4,
  },
  {
    postcode: 2566,
    place_name: 'Raby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0185,
    longitude: 150.8176,
    accuracy: 4,
  },
  {
    postcode: 2566,
    place_name: 'Varroville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0032,
    longitude: 150.8111,
    accuracy: 4,
  },
  {
    postcode: 2566,
    place_name: 'Minto',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0333,
    longitude: 150.85,
    accuracy: 4,
  },
  {
    postcode: 2567,
    place_name: 'Narellan Dc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0474,
    longitude: 150.7534,
    accuracy: 3,
  },
  {
    postcode: 2567,
    place_name: 'Smeaton Grange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0371,
    longitude: 150.7577,
    accuracy: 4,
  },
  {
    postcode: 2567,
    place_name: 'Harrington Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.024,
    longitude: 150.735,
    accuracy: 4,
  },
  {
    postcode: 2567,
    place_name: 'Narellan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0333,
    longitude: 150.7333,
    accuracy: 4,
  },
  {
    postcode: 2567,
    place_name: 'Currans Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0431,
    longitude: 150.773,
    accuracy: 4,
  },
  {
    postcode: 2567,
    place_name: 'Mount Annan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0529,
    longitude: 150.7598,
    accuracy: 4,
  },
  {
    postcode: 2567,
    place_name: 'Narellan Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0497,
    longitude: 150.7439,
    accuracy: 4,
  },
  {
    postcode: 2568,
    place_name: 'Menangle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1268,
    longitude: 150.7387,
    accuracy: 4,
  },
  {
    postcode: 2569,
    place_name: 'Douglas Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.185,
    longitude: 150.7145,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Glenmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7907,
    longitude: 150.6693,
    accuracy: 3,
  },
  {
    postcode: 2570,
    place_name: 'Werombi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9833,
    longitude: 150.5833,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Orangeville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0311,
    longitude: 150.6018,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Grasmere',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0539,
    longitude: 150.666,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Camden Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1241,
    longitude: 150.4295,
    accuracy: 3,
  },
  {
    postcode: 2570,
    place_name: 'Brownlow Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0333,
    longitude: 150.6667,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Mount Hunter',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0717,
    longitude: 150.6378,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Oakdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0793,
    longitude: 150.5132,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Camden South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0831,
    longitude: 150.6949,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Spring Farm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0685,
    longitude: 150.7118,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Theresa Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0068,
    longitude: 150.6346,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Bickley Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0733,
    longitude: 150.6644,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Nattai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1241,
    longitude: 150.4295,
    accuracy: 3,
  },
  {
    postcode: 2570,
    place_name: 'Oran Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0056,
    longitude: 150.7403,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Cawdor',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1,
    longitude: 150.6667,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Cobbitty',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0159,
    longitude: 150.6785,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Belimbla Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0841,
    longitude: 150.5398,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Ellis Lane',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0329,
    longitude: 150.6782,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Elderslie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.05,
    longitude: 150.7,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Camden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.054,
    longitude: 150.6962,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'The Oaks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0799,
    longitude: 150.57,
    accuracy: 4,
  },
  {
    postcode: 2570,
    place_name: 'Kirkham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0388,
    longitude: 150.7111,
    accuracy: 4,
  },
  {
    postcode: 2571,
    place_name: 'Balmoral Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3149,
    longitude: 150.5165,
    accuracy: 4,
  },
  {
    postcode: 2571,
    place_name: 'Balmoral',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3077,
    longitude: 150.521,
    accuracy: 4,
  },
  {
    postcode: 2571,
    place_name: 'Maldon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2,
    longitude: 150.6333,
    accuracy: 4,
  },
  {
    postcode: 2571,
    place_name: 'Wilton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2405,
    longitude: 150.6977,
    accuracy: 4,
  },
  {
    postcode: 2571,
    place_name: 'Couridjah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2336,
    longitude: 150.5513,
    accuracy: 4,
  },
  {
    postcode: 2571,
    place_name: 'Razorback',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1524,
    longitude: 150.6531,
    accuracy: 4,
  },
  {
    postcode: 2571,
    place_name: 'Picton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1699,
    longitude: 150.6117,
    accuracy: 4,
  },
  {
    postcode: 2571,
    place_name: 'Buxton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2602,
    longitude: 150.5329,
    accuracy: 4,
  },
  {
    postcode: 2571,
    place_name: 'Mowbray Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.15,
    longitude: 150.55,
    accuracy: 4,
  },
  {
    postcode: 2572,
    place_name: 'Thirlmere',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2046,
    longitude: 150.5677,
    accuracy: 4,
  },
  {
    postcode: 2572,
    place_name: 'Lakesland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1652,
    longitude: 150.5346,
    accuracy: 4,
  },
  {
    postcode: 2573,
    place_name: 'Tahmoor',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2225,
    longitude: 150.5947,
    accuracy: 4,
  },
  {
    postcode: 2574,
    place_name: 'Pheasants Nest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2558,
    longitude: 150.634,
    accuracy: 4,
  },
  {
    postcode: 2574,
    place_name: 'Avon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4271,
    longitude: 150.7012,
    accuracy: 4,
  },
  {
    postcode: 2574,
    place_name: 'Bargo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.293,
    longitude: 150.5781,
    accuracy: 4,
  },
  {
    postcode: 2574,
    place_name: 'Yanderra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3196,
    longitude: 150.5753,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Yerrinbool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3688,
    longitude: 150.5385,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Mount Lindsey',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4137,
    longitude: 150.4398,
    accuracy: 3,
  },
  {
    postcode: 2575,
    place_name: 'High Range',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3652,
    longitude: 150.3012,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Mittagong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.45,
    longitude: 150.4457,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Alpine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4093,
    longitude: 150.5296,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Willow Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4351,
    longitude: 150.47,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Colo Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.401,
    longitude: 150.4857,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Mandemar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4332,
    longitude: 150.3026,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Aylmerton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.421,
    longitude: 150.4973,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Wattle Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4137,
    longitude: 150.4398,
    accuracy: 3,
  },
  {
    postcode: 2575,
    place_name: 'Woodlands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4057,
    longitude: 150.3943,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Goodmans Ford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4137,
    longitude: 150.4398,
    accuracy: 3,
  },
  {
    postcode: 2575,
    place_name: 'Renwick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4137,
    longitude: 150.4398,
    accuracy: 3,
  },
  {
    postcode: 2575,
    place_name: 'Bullio',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4137,
    longitude: 150.4398,
    accuracy: 3,
  },
  {
    postcode: 2575,
    place_name: 'Braemar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4274,
    longitude: 150.484,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Welby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4393,
    longitude: 150.4306,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Balaclava',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4137,
    longitude: 150.4398,
    accuracy: 3,
  },
  {
    postcode: 2575,
    place_name: 'Joadja',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4167,
    longitude: 150.2167,
    accuracy: 4,
  },
  {
    postcode: 2575,
    place_name: 'Hill Top',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3479,
    longitude: 150.4955,
    accuracy: 4,
  },
  {
    postcode: 2576,
    place_name: 'East Bowral',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5255,
    longitude: 150.4874,
    accuracy: 3,
  },
  {
    postcode: 2576,
    place_name: 'Burradoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5032,
    longitude: 150.409,
    accuracy: 4,
  },
  {
    postcode: 2576,
    place_name: 'Bowral',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4775,
    longitude: 150.4204,
    accuracy: 4,
  },
  {
    postcode: 2576,
    place_name: 'East Kangaloon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5667,
    longitude: 150.5833,
    accuracy: 4,
  },
  {
    postcode: 2576,
    place_name: 'Glenquarry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5263,
    longitude: 150.4846,
    accuracy: 4,
  },
  {
    postcode: 2576,
    place_name: 'Kangaloon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.546,
    longitude: 150.544,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Paddys River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.764,
    longitude: 148.1853,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Kangaroo Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7381,
    longitude: 150.5366,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Burrawang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5853,
    longitude: 150.5115,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Medway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4916,
    longitude: 150.2823,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Budgong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Berrima',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4877,
    longitude: 150.339,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'New Berrima',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4877,
    longitude: 150.339,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Upper Kangaroo River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.694,
    longitude: 150.5986,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Yarrunga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7227,
    longitude: 150.3099,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Sutton Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5833,
    longitude: 150.3167,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Carrington Falls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Barrengarry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6833,
    longitude: 150.5167,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Macquarie Pass',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Belanglo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Meryla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Beaumont',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7833,
    longitude: 150.5667,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Werai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Canyonleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Knights Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Robertson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5874,
    longitude: 150.5912,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Wildes Meadow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.623,
    longitude: 150.5262,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Avoca',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5953,
    longitude: 149.2917,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Moss Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5537,
    longitude: 150.3712,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Barren Grounds',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Mount Murray',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Red Rocks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Fitzroy Falls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.65,
    longitude: 150.4833,
    accuracy: 4,
  },
  {
    postcode: 2577,
    place_name: 'Manchester Square',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6192,
    longitude: 150.3835,
    accuracy: 3,
  },
  {
    postcode: 2577,
    place_name: 'Upper Kangaroo Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.694,
    longitude: 150.5986,
    accuracy: 4,
  },
  {
    postcode: 2578,
    place_name: 'Bundanoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6566,
    longitude: 150.2962,
    accuracy: 4,
  },
  {
    postcode: 2579,
    place_name: 'Brayton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6487,
    longitude: 150.2702,
    accuracy: 3,
  },
  {
    postcode: 2579,
    place_name: 'Exeter',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6128,
    longitude: 150.3208,
    accuracy: 4,
  },
  {
    postcode: 2579,
    place_name: 'Marulan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7084,
    longitude: 150.0097,
    accuracy: 4,
  },
  {
    postcode: 2579,
    place_name: 'Tallong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7167,
    longitude: 150.0833,
    accuracy: 4,
  },
  {
    postcode: 2579,
    place_name: 'Wingello',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6948,
    longitude: 150.1582,
    accuracy: 4,
  },
  {
    postcode: 2579,
    place_name: 'Penrose',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5107,
    longitude: 150.7791,
    accuracy: 4,
  },
  {
    postcode: 2579,
    place_name: 'Big Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6487,
    longitude: 150.2702,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Carrick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7,
    longitude: 149.8833,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Bannaby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.45,
    longitude: 149.9833,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Richlands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3167,
    longitude: 149.8167,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Mayfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8979,
    longitude: 151.7361,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Chatsbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5485,
    longitude: 149.8179,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Middle Arm',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Wayo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.599,
    longitude: 149.6377,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Towrang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7,
    longitude: 149.85,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Gundary',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8318,
    longitude: 149.7512,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Curraweela',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Myrtleville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4818,
    longitude: 149.8179,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Taralga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.404,
    longitude: 149.8196,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Currawang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9833,
    longitude: 149.5167,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Goulburn North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Yarra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7833,
    longitude: 149.6333,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Quialigo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9281,
    longitude: 149.7431,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Windellama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.022,
    longitude: 149.8728,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Kingsdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6535,
    longitude: 149.7676,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Yalbraith',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3,
    longitude: 149.75,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Baw Baw',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.75,
    longitude: 149.6667,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Tarlo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6,
    longitude: 149.8,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Stonequarry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Bungonia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8485,
    longitude: 149.9512,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Mount Fairy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1833,
    longitude: 149.6,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Goulburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7515,
    longitude: 149.7209,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Wombeyan Caves',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3296,
    longitude: 150.0227,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Pomeroy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6568,
    longitude: 149.5012,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Run-O-Waters',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7665,
    longitude: 149.6702,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Goulburn DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Tarago',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0978,
    longitude: 149.526,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Golspie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2985,
    longitude: 149.6679,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Lower Boro',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1667,
    longitude: 149.7667,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Woodhouselee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.55,
    longitude: 149.6333,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Tirrannaville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8167,
    longitude: 149.6833,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Bannister',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5985,
    longitude: 149.4929,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Roslyn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6776,
    longitude: 149.0173,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Paling Yards',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Greenwich Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5818,
    longitude: 149.9346,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Jerrong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Mummel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Lake Bathurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0167,
    longitude: 149.65,
    accuracy: 4,
  },
  {
    postcode: 2580,
    place_name: 'Parkesbourne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Brisbane Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Boxers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2580,
    place_name: 'Wiarborough',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7108,
    longitude: 149.7312,
    accuracy: 3,
  },
  {
    postcode: 2581,
    place_name: 'Dalton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.718,
    longitude: 149.1932,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Broadway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7466,
    longitude: 149.305,
    accuracy: 3,
  },
  {
    postcode: 2581,
    place_name: 'Gurrundah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.661,
    longitude: 149.4068,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Lake George',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1452,
    longitude: 149.4752,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Blakney Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.633,
    longitude: 149.0212,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Merrill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7466,
    longitude: 149.305,
    accuracy: 3,
  },
  {
    postcode: 2581,
    place_name: 'Biala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5946,
    longitude: 149.2626,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Bellmount Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7466,
    longitude: 149.305,
    accuracy: 3,
  },
  {
    postcode: 2581,
    place_name: 'Oolong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7907,
    longitude: 149.179,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Wollogorang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7466,
    longitude: 149.305,
    accuracy: 3,
  },
  {
    postcode: 2581,
    place_name: 'Breadalbane',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8,
    longitude: 149.4833,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Bevendale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5333,
    longitude: 149.1167,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Collector',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9167,
    longitude: 149.4333,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Lade Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7466,
    longitude: 149.305,
    accuracy: 3,
  },
  {
    postcode: 2581,
    place_name: 'Gunning',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7825,
    longitude: 149.2662,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Lerida',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8833,
    longitude: 149.3333,
    accuracy: 4,
  },
  {
    postcode: 2581,
    place_name: 'Cullerin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7833,
    longitude: 149.4167,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Mullion',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1252,
    longitude: 149.1276,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Jeir',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0563,
    longitude: 148.9719,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Yass',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8404,
    longitude: 148.9099,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Jerrawa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7788,
    longitude: 149.0748,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Cavan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0129,
    longitude: 148.7983,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Good Hope',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9167,
    longitude: 148.8167,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Wee Jasper',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.15,
    longitude: 148.7,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Bango',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7363,
    longitude: 148.8274,
    accuracy: 3,
  },
  {
    postcode: 2582,
    place_name: 'Bowning',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7449,
    longitude: 148.809,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Manton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7363,
    longitude: 148.8274,
    accuracy: 3,
  },
  {
    postcode: 2582,
    place_name: 'Bookham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8126,
    longitude: 148.6396,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Boambolo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7363,
    longitude: 148.8274,
    accuracy: 3,
  },
  {
    postcode: 2582,
    place_name: 'Kangiara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5985,
    longitude: 148.7512,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Yass River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7363,
    longitude: 148.8274,
    accuracy: 3,
  },
  {
    postcode: 2582,
    place_name: 'Laverstock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7363,
    longitude: 148.8274,
    accuracy: 3,
  },
  {
    postcode: 2582,
    place_name: 'Marchmont',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7363,
    longitude: 148.8274,
    accuracy: 3,
  },
  {
    postcode: 2582,
    place_name: 'Murrumbateman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.972,
    longitude: 149.0298,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Burrinjuck',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35,
    longitude: 148.6,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Berremangra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8,
    longitude: 148.4833,
    accuracy: 4,
  },
  {
    postcode: 2582,
    place_name: 'Woolgarlo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7363,
    longitude: 148.8274,
    accuracy: 3,
  },
  {
    postcode: 2582,
    place_name: 'Narrangullen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7363,
    longitude: 148.8274,
    accuracy: 3,
  },
  {
    postcode: 2583,
    place_name: 'Binda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3333,
    longitude: 149.3667,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Laggan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4167,
    longitude: 149.5333,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Narrawa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4152,
    longitude: 149.0846,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Limerick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1929,
    longitude: 149.2818,
    accuracy: 3,
  },
  {
    postcode: 2583,
    place_name: 'Grabben Gullen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5402,
    longitude: 149.4012,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Crookwell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4593,
    longitude: 149.4714,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Pejar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5833,
    longitude: 149.5833,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Rugby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3833,
    longitude: 149.0167,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Tuena',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0235,
    longitude: 149.3262,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Lost River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3411,
    longitude: 149.348,
    accuracy: 3,
  },
  {
    postcode: 2583,
    place_name: 'Bigga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0833,
    longitude: 149.15,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Crooked Corner',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1929,
    longitude: 149.2818,
    accuracy: 3,
  },
  {
    postcode: 2583,
    place_name: 'Wheeo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5,
    longitude: 149.2833,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Blanket Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.15,
    longitude: 149.2167,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Kialla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5333,
    longitude: 149.45,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Peelwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1068,
    longitude: 149.4346,
    accuracy: 4,
  },
  {
    postcode: 2583,
    place_name: 'Fullerton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2333,
    longitude: 149.55,
    accuracy: 4,
  },
  {
    postcode: 2584,
    place_name: 'Binalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6717,
    longitude: 148.6348,
    accuracy: 4,
  },
  {
    postcode: 2585,
    place_name: 'Galong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6,
    longitude: 148.55,
    accuracy: 4,
  },
  {
    postcode: 2586,
    place_name: 'Boorowa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4366,
    longitude: 148.7163,
    accuracy: 4,
  },
  {
    postcode: 2586,
    place_name: 'Frogmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2667,
    longitude: 148.85,
    accuracy: 4,
  },
  {
    postcode: 2586,
    place_name: 'Murringo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3,
    longitude: 148.5167,
    accuracy: 4,
  },
  {
    postcode: 2586,
    place_name: 'Godfreys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1485,
    longitude: 148.7012,
    accuracy: 4,
  },
  {
    postcode: 2586,
    place_name: 'Taylors Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2735,
    longitude: 149.0179,
    accuracy: 4,
  },
  {
    postcode: 2586,
    place_name: 'Rye Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5152,
    longitude: 148.9179,
    accuracy: 4,
  },
  {
    postcode: 2586,
    place_name: 'Reids Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1223,
    longitude: 149.0785,
    accuracy: 4,
  },
  {
    postcode: 2587,
    place_name: 'Wombat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4167,
    longitude: 148.25,
    accuracy: 4,
  },
  {
    postcode: 2587,
    place_name: 'Cunningar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5607,
    longitude: 148.4393,
    accuracy: 4,
  },
  {
    postcode: 2587,
    place_name: 'Murrumburrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5541,
    longitude: 148.3676,
    accuracy: 4,
  },
  {
    postcode: 2587,
    place_name: 'Nubba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5329,
    longitude: 148.2352,
    accuracy: 4,
  },
  {
    postcode: 2587,
    place_name: 'Beggan Beggan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6818,
    longitude: 148.2679,
    accuracy: 4,
  },
  {
    postcode: 2587,
    place_name: 'Kingsvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4333,
    longitude: 148.3167,
    accuracy: 4,
  },
  {
    postcode: 2587,
    place_name: 'Harden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5541,
    longitude: 148.3676,
    accuracy: 4,
  },
  {
    postcode: 2587,
    place_name: 'Mcmahons Reef',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6667,
    longitude: 148.45,
    accuracy: 4,
  },
  {
    postcode: 2588,
    place_name: 'Wallendbeen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5333,
    longitude: 148.15,
    accuracy: 4,
  },
  {
    postcode: 2590,
    place_name: 'Bethungra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.761,
    longitude: 147.8554,
    accuracy: 4,
  },
  {
    postcode: 2590,
    place_name: 'Cootamundra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6409,
    longitude: 148.0284,
    accuracy: 4,
  },
  {
    postcode: 2590,
    place_name: 'Illabo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8318,
    longitude: 147.7537,
    accuracy: 4,
  },
  {
    postcode: 2594,
    place_name: 'Burrangong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2916,
    longitude: 148.2481,
    accuracy: 4,
  },
  {
    postcode: 2594,
    place_name: 'Young',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3135,
    longitude: 148.3011,
    accuracy: 4,
  },
  {
    postcode: 2594,
    place_name: 'Bulla Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2339,
    longitude: 148.1092,
    accuracy: 3,
  },
  {
    postcode: 2594,
    place_name: 'Milvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3167,
    longitude: 147.9167,
    accuracy: 4,
  },
  {
    postcode: 2594,
    place_name: 'Monteagle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1833,
    longitude: 148.35,
    accuracy: 4,
  },
  {
    postcode: 2594,
    place_name: 'Maimuru',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2667,
    longitude: 148.2667,
    accuracy: 4,
  },
  {
    postcode: 2594,
    place_name: 'Barwang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2339,
    longitude: 148.1092,
    accuracy: 3,
  },
  {
    postcode: 2594,
    place_name: 'Tubbul',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2652,
    longitude: 148.0012,
    accuracy: 4,
  },
  {
    postcode: 2594,
    place_name: 'Kikiamah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2339,
    longitude: 148.1092,
    accuracy: 3,
  },
  {
    postcode: 2594,
    place_name: 'Weedallion',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1985,
    longitude: 147.9512,
    accuracy: 4,
  },
  {
    postcode: 2594,
    place_name: 'Memagong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2339,
    longitude: 148.1092,
    accuracy: 3,
  },
  {
    postcode: 2594,
    place_name: 'Bribbaree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1199,
    longitude: 147.8792,
    accuracy: 4,
  },
  {
    postcode: 2594,
    place_name: 'Berthong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2339,
    longitude: 148.1092,
    accuracy: 3,
  },
  {
    postcode: 2594,
    place_name: 'Thuddungra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.15,
    longitude: 148.1167,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Bimberi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3503,
    longitude: 149.0155,
    accuracy: 3,
  },
  {
    postcode: 2611,
    place_name: 'Brindabella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4,
    longitude: 148.7333,
    accuracy: 4,
  },
  {
    postcode: 2611,
    place_name: 'Cooleman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3503,
    longitude: 149.0155,
    accuracy: 3,
  },
  {
    postcode: 2618,
    place_name: 'Springrange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.076,
    longitude: 149.0889,
    accuracy: 4,
  },
  {
    postcode: 2618,
    place_name: 'Nanima',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0129,
    longitude: 149.1277,
    accuracy: 4,
  },
  {
    postcode: 2618,
    place_name: 'Wallaroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1468,
    longitude: 149.0128,
    accuracy: 4,
  },
  {
    postcode: 2619,
    place_name: 'Jerrabomberra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3844,
    longitude: 149.2025,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Queanbeyan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3549,
    longitude: 149.232,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Yarrow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'Tinderry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7348,
    longitude: 149.34,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Queanbeyan East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3428,
    longitude: 149.2444,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'The Ridgeway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3389,
    longitude: 149.2609,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Sutton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1667,
    longitude: 149.25,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Williamsdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5667,
    longitude: 149.1333,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Queanbeyan West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'Karabar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.376,
    longitude: 149.2328,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Burra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5594,
    longitude: 149.2235,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Michelago',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7167,
    longitude: 149.1667,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Tralee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'Royalla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5167,
    longitude: 149.15,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Crestwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3484,
    longitude: 149.2176,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Urila',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'Gundaroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0333,
    longitude: 149.25,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Clear Range',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'Carwoola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3784,
    longitude: 149.3215,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'The Angle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'Wamboin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2507,
    longitude: 149.3328,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Environa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'Greenleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 149.2528,
    accuracy: 4,
  },
  {
    postcode: 2620,
    place_name: 'Queanbeyan DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3914,
    longitude: 149.2166,
    accuracy: 3,
  },
  {
    postcode: 2620,
    place_name: 'Googong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4387,
    longitude: 149.2131,
    accuracy: 4,
  },
  {
    postcode: 2621,
    place_name: 'Bungendore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2538,
    longitude: 149.4401,
    accuracy: 4,
  },
  {
    postcode: 2621,
    place_name: 'Anembo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3262,
    longitude: 149.4297,
    accuracy: 3,
  },
  {
    postcode: 2621,
    place_name: 'Bywong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1648,
    longitude: 149.3289,
    accuracy: 4,
  },
  {
    postcode: 2621,
    place_name: 'Forbes Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3262,
    longitude: 149.4297,
    accuracy: 3,
  },
  {
    postcode: 2621,
    place_name: 'Hoskinstown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4167,
    longitude: 149.45,
    accuracy: 4,
  },
  {
    postcode: 2621,
    place_name: 'Primrose Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3262,
    longitude: 149.4297,
    accuracy: 3,
  },
  {
    postcode: 2621,
    place_name: 'Rossi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4667,
    longitude: 149.5,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Bendoura',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5167,
    longitude: 149.7,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Corang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2,
    longitude: 150.0167,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Braidwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4415,
    longitude: 149.7998,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Ballalaba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4771,
    longitude: 149.7702,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Nerriga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1167,
    longitude: 150.0833,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Warri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5617,
    longitude: 149.2356,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Araluen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6459,
    longitude: 149.8174,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Jinden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Reidsdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5818,
    longitude: 149.8512,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Mulloon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2982,
    longitude: 149.6088,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Boro',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.15,
    longitude: 149.6667,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Charleys Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Endrick',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Back Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Oallen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1665,
    longitude: 149.9622,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Oranmeir',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4771,
    longitude: 149.7702,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Krawarree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8152,
    longitude: 149.6346,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Snowball',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9333,
    longitude: 149.5833,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Larbert',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2985,
    longitude: 149.7679,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Palerang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Merricumbene',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7335,
    longitude: 149.896,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Wog Wog',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Touga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9546,
    longitude: 150.0911,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'St George',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Coolumburra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Gundillion',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4771,
    longitude: 149.7702,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Manar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3,
    longitude: 149.7,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Mayfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8979,
    longitude: 151.7361,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Northangera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Jembaicumbene',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4771,
    longitude: 149.7702,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Jerrabattgulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Farringdon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Tomboye',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Wyanbene',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Durran Durra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Quiera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Monga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5755,
    longitude: 149.9204,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Tolwong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Hereford Hall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Budawang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Jingera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Kindervale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Murrengenburg',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Majors Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5689,
    longitude: 149.7455,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Sassafras',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Marlowe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3,
    longitude: 149.8833,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Harolds Cross',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Neringla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Bulee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Mongarlowe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4333,
    longitude: 149.9333,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Berlang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2622,
    place_name: 'Tianjara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1167,
    longitude: 150.3333,
    accuracy: 4,
  },
  {
    postcode: 2622,
    place_name: 'Bombay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4068,
    longitude: 149.8505,
    accuracy: 3,
  },
  {
    postcode: 2623,
    place_name: 'Captains Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5911,
    longitude: 149.4452,
    accuracy: 4,
  },
  {
    postcode: 2624,
    place_name: 'Charlottes Pass',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6077,
    longitude: 148.3881,
    accuracy: 3,
  },
  {
    postcode: 2624,
    place_name: 'Smiggin Holes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3932,
    longitude: 148.4337,
    accuracy: 4,
  },
  {
    postcode: 2624,
    place_name: 'Perisher Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.406,
    longitude: 148.4117,
    accuracy: 4,
  },
  {
    postcode: 2625,
    place_name: 'Thredbo Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5052,
    longitude: 148.3076,
    accuracy: 4,
  },
  {
    postcode: 2625,
    place_name: 'Thredbo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5052,
    longitude: 148.3076,
    accuracy: 4,
  },
  {
    postcode: 2626,
    place_name: 'Bredbo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.95,
    longitude: 149.15,
    accuracy: 4,
  },
  {
    postcode: 2626,
    place_name: 'Bumbalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9083,
    longitude: 149.1583,
    accuracy: 3,
  },
  {
    postcode: 2626,
    place_name: 'Colinton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8667,
    longitude: 149.1667,
    accuracy: 4,
  },
  {
    postcode: 2627,
    place_name: 'Kosciuszko National Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2644,
    longitude: 148.4818,
    accuracy: 4,
  },
  {
    postcode: 2627,
    place_name: 'Ingebirah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.65,
    longitude: 148.5,
    accuracy: 4,
  },
  {
    postcode: 2627,
    place_name: 'Jindabyne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4162,
    longitude: 148.625,
    accuracy: 4,
  },
  {
    postcode: 2627,
    place_name: 'Pilot Wilderness',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4102,
    longitude: 148.5365,
    accuracy: 3,
  },
  {
    postcode: 2627,
    place_name: 'Grosses Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4102,
    longitude: 148.5365,
    accuracy: 3,
  },
  {
    postcode: 2627,
    place_name: 'Crackenback',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4429,
    longitude: 148.5001,
    accuracy: 4,
  },
  {
    postcode: 2627,
    place_name: 'East Jindabyne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4102,
    longitude: 148.5365,
    accuracy: 3,
  },
  {
    postcode: 2627,
    place_name: 'Kalkite',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3318,
    longitude: 148.6429,
    accuracy: 4,
  },
  {
    postcode: 2627,
    place_name: 'Kosciuszko',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2644,
    longitude: 148.4818,
    accuracy: 4,
  },
  {
    postcode: 2627,
    place_name: 'Moonbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4818,
    longitude: 148.5512,
    accuracy: 4,
  },
  {
    postcode: 2628,
    place_name: 'Beloka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5333,
    longitude: 148.7333,
    accuracy: 4,
  },
  {
    postcode: 2628,
    place_name: 'Eucumbene',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1383,
    longitude: 148.6349,
    accuracy: 4,
  },
  {
    postcode: 2628,
    place_name: 'Hill Top',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3479,
    longitude: 150.4955,
    accuracy: 4,
  },
  {
    postcode: 2628,
    place_name: 'Snowy Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1167,
    longitude: 148.8667,
    accuracy: 4,
  },
  {
    postcode: 2628,
    place_name: 'Nimmo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3943,
    longitude: 148.7809,
    accuracy: 3,
  },
  {
    postcode: 2628,
    place_name: 'Dalgety',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5,
    longitude: 148.8333,
    accuracy: 4,
  },
  {
    postcode: 2628,
    place_name: 'Berridale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3657,
    longitude: 148.8268,
    accuracy: 4,
  },
  {
    postcode: 2628,
    place_name: 'Paupong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5833,
    longitude: 148.6833,
    accuracy: 4,
  },
  {
    postcode: 2628,
    place_name: 'Byadbo Wilderness',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3943,
    longitude: 148.7809,
    accuracy: 3,
  },
  {
    postcode: 2628,
    place_name: 'Braemar Bay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3943,
    longitude: 148.7809,
    accuracy: 3,
  },
  {
    postcode: 2628,
    place_name: 'Avonside',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3943,
    longitude: 148.7809,
    accuracy: 3,
  },
  {
    postcode: 2628,
    place_name: 'Rocky Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3943,
    longitude: 148.7809,
    accuracy: 3,
  },
  {
    postcode: 2628,
    place_name: 'Cootralantra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2652,
    longitude: 148.8679,
    accuracy: 4,
  },
  {
    postcode: 2628,
    place_name: 'Numbla Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.65,
    longitude: 148.8,
    accuracy: 4,
  },
  {
    postcode: 2629,
    place_name: 'Tantangara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8167,
    longitude: 148.5667,
    accuracy: 4,
  },
  {
    postcode: 2629,
    place_name: 'Cabramurra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9357,
    longitude: 148.3839,
    accuracy: 4,
  },
  {
    postcode: 2629,
    place_name: 'Anglers Reach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0167,
    longitude: 148.6667,
    accuracy: 4,
  },
  {
    postcode: 2629,
    place_name: 'Adaminaby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9969,
    longitude: 148.7697,
    accuracy: 4,
  },
  {
    postcode: 2629,
    place_name: 'Old Adaminaby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0349,
    longitude: 148.7016,
    accuracy: 4,
  },
  {
    postcode: 2629,
    place_name: 'Long Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9287,
    longitude: 148.6269,
    accuracy: 3,
  },
  {
    postcode: 2629,
    place_name: 'Kiandra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8833,
    longitude: 148.5,
    accuracy: 4,
  },
  {
    postcode: 2629,
    place_name: 'Bolaro',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9287,
    longitude: 148.6269,
    accuracy: 3,
  },
  {
    postcode: 2629,
    place_name: 'Yaouk',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8152,
    longitude: 148.8012,
    accuracy: 4,
  },
  {
    postcode: 2629,
    place_name: 'Providence Portal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9287,
    longitude: 148.6269,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Rhine Falls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1667,
    longitude: 148.8667,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Bungarby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2254,
    longitude: 148.9852,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Bunyan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1696,
    longitude: 149.1538,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Cooma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2352,
    longitude: 149.1241,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Jerangle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8667,
    longitude: 149.3667,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Billilingra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Maffra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5318,
    longitude: 148.9679,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Bobundara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5167,
    longitude: 148.9333,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Coolringdon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2833,
    longitude: 148.9833,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Frying Pan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Badja',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0527,
    longitude: 149.5498,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Middle Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Numeralla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1667,
    longitude: 149.3333,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Peak View',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2254,
    longitude: 148.9852,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Rock Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.35,
    longitude: 149.2,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Binjura',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Dairymans Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'The Brothers',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Glen Fergus',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Dry Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1167,
    longitude: 148.8667,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Dangelong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Springfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3433,
    longitude: 149.2635,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Cooma North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Countegany',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1833,
    longitude: 149.45,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Middlingbank',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2167,
    longitude: 148.8333,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Wambrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Buckenderra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Murrumbucca',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0985,
    longitude: 149.0512,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Polo Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2315,
    longitude: 149.151,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Pine Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Rose Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7235,
    longitude: 150.8096,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Myalla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4167,
    longitude: 149.1167,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Tuross',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0533,
    longitude: 150.1332,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Shannons Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Carlaminda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2601,
    longitude: 149.3051,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Chakola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0985,
    longitude: 149.1679,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Ironmungy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5833,
    longitude: 148.9333,
    accuracy: 4,
  },
  {
    postcode: 2630,
    place_name: 'Jimenbuen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 149.249,
    accuracy: 3,
  },
  {
    postcode: 2630,
    place_name: 'Arable',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.35,
    longitude: 149.0333,
    accuracy: 4,
  },
  {
    postcode: 2631,
    place_name: 'Holts Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6667,
    longitude: 149.2833,
    accuracy: 4,
  },
  {
    postcode: 2631,
    place_name: 'Winifred',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6223,
    longitude: 149.3001,
    accuracy: 3,
  },
  {
    postcode: 2631,
    place_name: 'Jincumbilly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7167,
    longitude: 149.2167,
    accuracy: 4,
  },
  {
    postcode: 2631,
    place_name: 'Glen Allen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6985,
    longitude: 149.3679,
    accuracy: 4,
  },
  {
    postcode: 2631,
    place_name: 'Mount Cooper',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6833,
    longitude: 149.2167,
    accuracy: 4,
  },
  {
    postcode: 2631,
    place_name: 'Greenlands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6223,
    longitude: 149.3001,
    accuracy: 3,
  },
  {
    postcode: 2631,
    place_name: 'Steeple Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5485,
    longitude: 149.3846,
    accuracy: 4,
  },
  {
    postcode: 2631,
    place_name: 'Nimmitabel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.5119,
    longitude: 149.2838,
    accuracy: 4,
  },
  {
    postcode: 2631,
    place_name: 'Boco',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.6223,
    longitude: 149.3001,
    accuracy: 3,
  },
  {
    postcode: 2631,
    place_name: 'Ando',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7167,
    longitude: 149.25,
    accuracy: 4,
  },
  {
    postcode: 2631,
    place_name: 'Creewah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.75,
    longitude: 149.3667,
    accuracy: 4,
  },
  {
    postcode: 2631,
    place_name: 'Kybeyan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3485,
    longitude: 149.4179,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Cathcart',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8333,
    longitude: 149.3667,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Paddys Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9232,
    longitude: 149.2451,
    accuracy: 3,
  },
  {
    postcode: 2632,
    place_name: 'Rockton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.1318,
    longitude: 149.3179,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Palarang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9232,
    longitude: 149.2451,
    accuracy: 3,
  },
  {
    postcode: 2632,
    place_name: 'Bukalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.7985,
    longitude: 149.2012,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Lords Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9232,
    longitude: 149.2451,
    accuracy: 3,
  },
  {
    postcode: 2632,
    place_name: 'Mount Darragh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8485,
    longitude: 149.5179,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Gunningrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9232,
    longitude: 149.2451,
    accuracy: 3,
  },
  {
    postcode: 2632,
    place_name: 'Mila',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.0485,
    longitude: 149.1679,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Merriangaah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9232,
    longitude: 149.2451,
    accuracy: 3,
  },
  {
    postcode: 2632,
    place_name: 'Cambalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8167,
    longitude: 149.0833,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Rosemeath',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9232,
    longitude: 149.2451,
    accuracy: 3,
  },
  {
    postcode: 2632,
    place_name: 'Bombala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9121,
    longitude: 149.2367,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Coolumbooka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9232,
    longitude: 149.2451,
    accuracy: 3,
  },
  {
    postcode: 2632,
    place_name: 'Bondi Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9232,
    longitude: 149.2451,
    accuracy: 3,
  },
  {
    postcode: 2632,
    place_name: 'Bibbenluke',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.8134,
    longitude: 149.2863,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Craigie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.0833,
    longitude: 149.05,
    accuracy: 4,
  },
  {
    postcode: 2632,
    place_name: 'Quidong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9232,
    longitude: 149.2451,
    accuracy: 3,
  },
  {
    postcode: 2633,
    place_name: 'Corrowong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9802,
    longitude: 148.9291,
    accuracy: 3,
  },
  {
    postcode: 2633,
    place_name: 'Tombong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.9,
    longitude: 148.9319,
    accuracy: 4,
  },
  {
    postcode: 2633,
    place_name: 'Delegate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -37.0438,
    longitude: 148.9415,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Talmalmo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9318,
    longitude: 147.5012,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Table Top',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9667,
    longitude: 147,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'North Albury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0575,
    longitude: 146.9299,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Ournie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9318,
    longitude: 147.8512,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Moorwatha',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9833,
    longitude: 146.7,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Thurgoona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0363,
    longitude: 146.9961,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Lavington Dc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0351,
    longitude: 147.0025,
    accuracy: 3,
  },
  {
    postcode: 2640,
    place_name: 'Bungowannah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0056,
    longitude: 146.7702,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'East Albury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0814,
    longitude: 146.9299,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Wymah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0139,
    longitude: 147.0421,
    accuracy: 3,
  },
  {
    postcode: 2640,
    place_name: 'South Albury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0865,
    longitude: 146.9091,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'West Albury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0798,
    longitude: 146.8923,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Wirlinga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0139,
    longitude: 147.0421,
    accuracy: 3,
  },
  {
    postcode: 2640,
    place_name: 'Glenroy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0502,
    longitude: 146.9106,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Ettamogah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0139,
    longitude: 147.0421,
    accuracy: 3,
  },
  {
    postcode: 2640,
    place_name: 'Splitters Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.053,
    longitude: 146.85,
    accuracy: 4,
  },
  {
    postcode: 2640,
    place_name: 'Albury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0748,
    longitude: 146.924,
    accuracy: 4,
  },
  {
    postcode: 2641,
    place_name: 'Lavington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0398,
    longitude: 146.9396,
    accuracy: 4,
  },
  {
    postcode: 2641,
    place_name: 'Hamilton Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0379,
    longitude: 146.9177,
    accuracy: 4,
  },
  {
    postcode: 2641,
    place_name: 'Springdale Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.031,
    longitude: 146.9478,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Jagumba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8945,
    longitude: 147.3359,
    accuracy: 3,
  },
  {
    postcode: 2642,
    place_name: 'Tooma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9667,
    longitude: 148.0667,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Burrumbuttock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8375,
    longitude: 146.8023,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Welaregang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.998,
    longitude: 147.9637,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Bidgeemia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8945,
    longitude: 147.3359,
    accuracy: 3,
  },
  {
    postcode: 2642,
    place_name: 'Rand',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5929,
    longitude: 146.5811,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Brocklesby',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8249,
    longitude: 146.6751,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Walbundrie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6985,
    longitude: 146.7179,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Jagungal Wilderness',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8945,
    longitude: 147.3359,
    accuracy: 3,
  },
  {
    postcode: 2642,
    place_name: 'Greg Greg',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0485,
    longitude: 148.0512,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Glenellen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9152,
    longitude: 146.9179,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Jingellic',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9152,
    longitude: 147.6846,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Wrathall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8945,
    longitude: 147.3359,
    accuracy: 3,
  },
  {
    postcode: 2642,
    place_name: 'Yerong Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3868,
    longitude: 147.0608,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Murray Gorge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8945,
    longitude: 147.3359,
    accuracy: 3,
  },
  {
    postcode: 2642,
    place_name: 'Gerogery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8344,
    longitude: 146.9928,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Geehi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.3667,
    longitude: 148.1667,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Khancoban',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2181,
    longitude: 148.1305,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Indi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0333,
    longitude: 149.4167,
    accuracy: 4,
  },
  {
    postcode: 2642,
    place_name: 'Jindera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9547,
    longitude: 146.8885,
    accuracy: 4,
  },
  {
    postcode: 2643,
    place_name: 'Howlong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.982,
    longitude: 146.6325,
    accuracy: 4,
  },
  {
    postcode: 2644,
    place_name: 'Little Billabong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5818,
    longitude: 147.5346,
    accuracy: 4,
  },
  {
    postcode: 2644,
    place_name: 'Bowna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7649,
    longitude: 147.4557,
    accuracy: 3,
  },
  {
    postcode: 2644,
    place_name: 'Mountain Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7652,
    longitude: 147.1346,
    accuracy: 4,
  },
  {
    postcode: 2644,
    place_name: 'Woomargama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8333,
    longitude: 147.25,
    accuracy: 4,
  },
  {
    postcode: 2644,
    place_name: 'Lankeys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8152,
    longitude: 147.6512,
    accuracy: 4,
  },
  {
    postcode: 2644,
    place_name: 'Holbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7219,
    longitude: 147.3139,
    accuracy: 4,
  },
  {
    postcode: 2644,
    place_name: 'Yarara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7648,
    longitude: 147.3468,
    accuracy: 3,
  },
  {
    postcode: 2644,
    place_name: 'Mullengandra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8833,
    longitude: 147.1667,
    accuracy: 4,
  },
  {
    postcode: 2644,
    place_name: 'Wantagong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7648,
    longitude: 147.3468,
    accuracy: 3,
  },
  {
    postcode: 2644,
    place_name: 'Coppabella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7648,
    longitude: 147.3468,
    accuracy: 3,
  },
  {
    postcode: 2645,
    place_name: 'Urana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3293,
    longitude: 146.2666,
    accuracy: 4,
  },
  {
    postcode: 2645,
    place_name: 'Cullivel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2627,
    longitude: 146.382,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Daysdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6436,
    longitude: 146.302,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Rennie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.809,
    longitude: 146.1362,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Oaklands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5576,
    longitude: 146.163,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Balldale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8466,
    longitude: 146.5197,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Coreen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7667,
    longitude: 146.35,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Savernake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7345,
    longitude: 146.051,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Corowa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.997,
    longitude: 146.3855,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Goombargana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7924,
    longitude: 146.2899,
    accuracy: 3,
  },
  {
    postcode: 2646,
    place_name: 'Lowesdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8318,
    longitude: 146.3512,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Sanger',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7924,
    longitude: 146.2899,
    accuracy: 3,
  },
  {
    postcode: 2646,
    place_name: 'Ringwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8,
    longitude: 146.2,
    accuracy: 3,
  },
  {
    postcode: 2646,
    place_name: 'Hopefield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8824,
    longitude: 146.4377,
    accuracy: 4,
  },
  {
    postcode: 2646,
    place_name: 'Redlands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7924,
    longitude: 146.2899,
    accuracy: 3,
  },
  {
    postcode: 2646,
    place_name: 'Nyora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7924,
    longitude: 146.2899,
    accuracy: 3,
  },
  {
    postcode: 2646,
    place_name: 'Collendina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7924,
    longitude: 146.2899,
    accuracy: 3,
  },
  {
    postcode: 2647,
    place_name: 'Mulwala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9854,
    longitude: 146.0053,
    accuracy: 4,
  },
  {
    postcode: 2648,
    place_name: 'Mourquong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4923,
    longitude: 142.2143,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Ellerslie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3103,
    longitude: 147.2462,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Anabranch North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8604,
    longitude: 146.9648,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Palinyewah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4923,
    longitude: 142.2143,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Rufus',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8604,
    longitude: 146.9648,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Pomona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.023,
    longitude: 141.8896,
    accuracy: 4,
  },
  {
    postcode: 2648,
    place_name: 'Wentworth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1073,
    longitude: 141.9131,
    accuracy: 4,
  },
  {
    postcode: 2648,
    place_name: 'Rufus River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4923,
    longitude: 142.2143,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Pooncarie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3853,
    longitude: 142.5699,
    accuracy: 4,
  },
  {
    postcode: 2648,
    place_name: 'Moorara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8604,
    longitude: 146.9648,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Cal Lal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0452,
    longitude: 141.0884,
    accuracy: 4,
  },
  {
    postcode: 2648,
    place_name: 'Para',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8604,
    longitude: 146.9648,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Scotia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8604,
    longitude: 146.9648,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Pan Ban',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8604,
    longitude: 146.9648,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Anabranch South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8604,
    longitude: 146.9648,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Pine Camp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8604,
    longitude: 146.9648,
    accuracy: 3,
  },
  {
    postcode: 2648,
    place_name: 'Curlwaa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1077,
    longitude: 141.9669,
    accuracy: 4,
  },
  {
    postcode: 2649,
    place_name: 'Laurel Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6167,
    longitude: 148.0833,
    accuracy: 4,
  },
  {
    postcode: 2649,
    place_name: 'Nurenmerenmong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6167,
    longitude: 148.0833,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Tolland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1452,
    longitude: 147.3516,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Burrandana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Bomen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0667,
    longitude: 147.4167,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Pulletop',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'East Wagga Wagga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1276,
    longitude: 147.407,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Estella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0725,
    longitude: 147.3575,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Glenfield Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1373,
    longitude: 147.3328,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Yathella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Wagga Wagga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1258,
    longitude: 147.3537,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Wantabadgery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.05,
    longitude: 147.7333,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Mount Austin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1368,
    longitude: 147.353,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Eunanoreenya',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Oura',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1167,
    longitude: 147.5333,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Oberne Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4,
    longitude: 147.8333,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Galore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.998,
    longitude: 146.8128,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Maxwell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Lake Albert',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1667,
    longitude: 147.3833,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Ashmont',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1249,
    longitude: 147.3317,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Gregadoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2333,
    longitude: 147.4333,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Wallacetown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Boorooma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0744,
    longitude: 147.3646,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Kyeamba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4333,
    longitude: 147.6167,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Currawarna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0167,
    longitude: 147.0833,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Belfrayden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Cookardinia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5667,
    longitude: 147.2167,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Tatton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1599,
    longitude: 147.3587,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Cartwrights Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0748,
    longitude: 147.392,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Big Springs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Brucedale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0333,
    longitude: 147.4167,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Moorong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1119,
    longitude: 147.3125,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'San Isidore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1248,
    longitude: 147.2894,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Wagga Wagga South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1127,
    longitude: 147.4103,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Bourkelands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1559,
    longitude: 147.347,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Gobbagombalin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0592,
    longitude: 147.3436,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Carabost',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5985,
    longitude: 147.7179,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Downside',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35,
    longitude: 147.35,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Turvey Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1285,
    longitude: 147.3626,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Springvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1669,
    longitude: 147.3306,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Yarragundry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1167,
    longitude: 147.2333,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Book Book',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3693,
    longitude: 147.5624,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Harefield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9667,
    longitude: 147.5333,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Borambola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2,
    longitude: 147.6667,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Alfredtown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1667,
    longitude: 147.5167,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Hillgrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Bulgary',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0772,
    longitude: 146.9725,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Wagga Wagga BC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'Rowan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2,
    longitude: 147.3333,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Lloyd',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1507,
    longitude: 147.3371,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Kooringal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1407,
    longitude: 147.3768,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'The Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2650,
    place_name: 'North Wagga Wagga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1,
    longitude: 147.3833,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Collingullie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0833,
    longitude: 147.1167,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Euberta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0667,
    longitude: 147.2167,
    accuracy: 4,
  },
  {
    postcode: 2650,
    place_name: 'Gelston Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.153,
    longitude: 147.3705,
    accuracy: 3,
  },
  {
    postcode: 2651,
    place_name: 'Forest Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1481,
    longitude: 147.4671,
    accuracy: 4,
  },
  {
    postcode: 2651,
    place_name: 'Wagga Wagga Raaf',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 147.4662,
    accuracy: 3,
  },
  {
    postcode: 2652,
    place_name: 'Boree Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1066,
    longitude: 146.6085,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Boorga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0326,
    longitude: 146.021,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Humula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4833,
    longitude: 147.75,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Galore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.998,
    longitude: 146.8128,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Marrar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8333,
    longitude: 147.3667,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Gumly Gumly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1273,
    longitude: 147.4335,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Tarcutta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2774,
    longitude: 147.7359,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Tabbita',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1071,
    longitude: 145.8463,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Uranquinty',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1935,
    longitude: 147.246,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Landervale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5454,
    longitude: 146.8317,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Ladysmith',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2095,
    longitude: 147.5118,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Grong Grong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7368,
    longitude: 146.7822,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Rosewood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7,
    longitude: 147.8667,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Matong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7671,
    longitude: 146.9239,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Cowabbie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5815,
    longitude: 146.9698,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Old Junee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8333,
    longitude: 147.5333,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Merriwagga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 145.6167,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Mangoplah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3833,
    longitude: 147.25,
    accuracy: 4,
  },
  {
    postcode: 2652,
    place_name: 'Murrulebale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8864,
    longitude: 146.9996,
    accuracy: 3,
  },
  {
    postcode: 2652,
    place_name: 'Goolgowi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9781,
    longitude: 145.7087,
    accuracy: 4,
  },
  {
    postcode: 2653,
    place_name: 'Burra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5594,
    longitude: 149.2235,
    accuracy: 4,
  },
  {
    postcode: 2653,
    place_name: 'Courabyra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7,
    longitude: 148.0333,
    accuracy: 4,
  },
  {
    postcode: 2653,
    place_name: 'Glenroy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.0502,
    longitude: 146.9106,
    accuracy: 4,
  },
  {
    postcode: 2653,
    place_name: 'Paddys River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.764,
    longitude: 148.1853,
    accuracy: 4,
  },
  {
    postcode: 2653,
    place_name: 'Mannus',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8,
    longitude: 147.95,
    accuracy: 4,
  },
  {
    postcode: 2653,
    place_name: 'Taradale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7893,
    longitude: 147.9991,
    accuracy: 3,
  },
  {
    postcode: 2653,
    place_name: 'Willigobung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7893,
    longitude: 147.9991,
    accuracy: 3,
  },
  {
    postcode: 2653,
    place_name: 'Munderoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8318,
    longitude: 147.8679,
    accuracy: 4,
  },
  {
    postcode: 2653,
    place_name: 'Westdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7893,
    longitude: 147.9991,
    accuracy: 3,
  },
  {
    postcode: 2653,
    place_name: 'Tumbarumba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7762,
    longitude: 148.0105,
    accuracy: 4,
  },
  {
    postcode: 2653,
    place_name: 'Maragle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7893,
    longitude: 147.9991,
    accuracy: 3,
  },
  {
    postcode: 2655,
    place_name: 'The Rock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.268,
    longitude: 147.1142,
    accuracy: 4,
  },
  {
    postcode: 2655,
    place_name: 'Tootool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.263,
    longitude: 146.9936,
    accuracy: 4,
  },
  {
    postcode: 2655,
    place_name: 'French Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2623,
    longitude: 146.927,
    accuracy: 4,
  },
  {
    postcode: 2656,
    place_name: 'Osborne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3545,
    longitude: 146.7228,
    accuracy: 4,
  },
  {
    postcode: 2656,
    place_name: 'Urangeline East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4874,
    longitude: 146.7,
    accuracy: 4,
  },
  {
    postcode: 2656,
    place_name: 'Brookong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1844,
    longitude: 146.6364,
    accuracy: 4,
  },
  {
    postcode: 2656,
    place_name: 'Fargunyah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3398,
    longitude: 146.7235,
    accuracy: 3,
  },
  {
    postcode: 2656,
    place_name: 'Urangeline',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4874,
    longitude: 146.7,
    accuracy: 4,
  },
  {
    postcode: 2656,
    place_name: 'Lockhart',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2233,
    longitude: 146.7182,
    accuracy: 4,
  },
  {
    postcode: 2656,
    place_name: 'Brookdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3398,
    longitude: 146.7235,
    accuracy: 3,
  },
  {
    postcode: 2656,
    place_name: 'Milbrulong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2532,
    longitude: 146.8428,
    accuracy: 4,
  },
  {
    postcode: 2658,
    place_name: 'Pleasant Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4622,
    longitude: 146.7978,
    accuracy: 4,
  },
  {
    postcode: 2658,
    place_name: 'Henty',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5207,
    longitude: 147.0349,
    accuracy: 4,
  },
  {
    postcode: 2658,
    place_name: 'Ryan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5564,
    longitude: 146.8684,
    accuracy: 4,
  },
  {
    postcode: 2658,
    place_name: 'Munyabla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4963,
    longitude: 146.8718,
    accuracy: 4,
  },
  {
    postcode: 2659,
    place_name: 'Alma Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7568,
    longitude: 146.9108,
    accuracy: 3,
  },
  {
    postcode: 2659,
    place_name: 'Walla Walla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7622,
    longitude: 146.9011,
    accuracy: 4,
  },
  {
    postcode: 2660,
    place_name: 'Morven',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.65,
    longitude: 147.1,
    accuracy: 4,
  },
  {
    postcode: 2660,
    place_name: 'Culcairn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6667,
    longitude: 147.0385,
    accuracy: 4,
  },
  {
    postcode: 2661,
    place_name: 'Kapooka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1569,
    longitude: 147.2844,
    accuracy: 4,
  },
  {
    postcode: 2663,
    place_name: 'Erin Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9061,
    longitude: 147.672,
    accuracy: 3,
  },
  {
    postcode: 2663,
    place_name: 'Eurongilly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.95,
    longitude: 147.7667,
    accuracy: 4,
  },
  {
    postcode: 2663,
    place_name: 'Wantiool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9,
    longitude: 147.6667,
    accuracy: 4,
  },
  {
    postcode: 2663,
    place_name: 'Marinna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9061,
    longitude: 147.672,
    accuracy: 3,
  },
  {
    postcode: 2663,
    place_name: 'Junee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8682,
    longitude: 147.5827,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Mirrool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3167,
    longitude: 147.1,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Barellan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2849,
    longitude: 146.5722,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Tara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5,
    longitude: 147.1667,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Bectric',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3391,
    longitude: 146.9046,
    accuracy: 3,
  },
  {
    postcode: 2665,
    place_name: 'Kamarah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3255,
    longitude: 146.7831,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Moombooldool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3,
    longitude: 146.6833,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Beckom',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3152,
    longitude: 146.9679,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Binya',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2333,
    longitude: 146.35,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Walleroobie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3391,
    longitude: 146.9046,
    accuracy: 3,
  },
  {
    postcode: 2665,
    place_name: 'Ariah Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3493,
    longitude: 147.2218,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Quandary',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3833,
    longitude: 147.3167,
    accuracy: 4,
  },
  {
    postcode: 2665,
    place_name: 'Ardlethan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3569,
    longitude: 146.9018,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Grogan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3167,
    longitude: 147.8,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Reefton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.25,
    longitude: 147.4333,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Dirnaseer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.414,
    longitude: 147.5797,
    accuracy: 3,
  },
  {
    postcode: 2666,
    place_name: 'Junee Reefs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7318,
    longitude: 147.6179,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Morangarell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.15,
    longitude: 147.7,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Mimosa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6,
    longitude: 147.45,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Combaning',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.45,
    longitude: 147.6833,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Pucawan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4152,
    longitude: 147.3512,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Sebastopol',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5833,
    longitude: 147.5333,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Temora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4483,
    longitude: 147.5356,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Gidginbung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3333,
    longitude: 147.4667,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Trungley Hall',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2985,
    longitude: 147.5679,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Narraburra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3318,
    longitude: 147.6512,
    accuracy: 4,
  },
  {
    postcode: 2666,
    place_name: 'Springdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4667,
    longitude: 147.7333,
    accuracy: 4,
  },
  {
    postcode: 2668,
    place_name: 'Barmedman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1449,
    longitude: 147.3873,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Rankins Springs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8318,
    longitude: 146.2512,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Melbergen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7,
    longitude: 146.05,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Tallimba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9935,
    longitude: 146.8796,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Erigolia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8546,
    longitude: 146.3733,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Gubbata',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.778,
    longitude: 146.5263,
    accuracy: 3,
  },
  {
    postcode: 2669,
    place_name: 'Tullibigeal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4167,
    longitude: 146.7333,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Naradhan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6152,
    longitude: 146.3179,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Girral',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7058,
    longitude: 147.0758,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Weethalle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8818,
    longitude: 146.6346,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Bygalorie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4667,
    longitude: 146.8,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Ungarie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6318,
    longitude: 146.9679,
    accuracy: 4,
  },
  {
    postcode: 2669,
    place_name: 'Kikoira',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6496,
    longitude: 146.6615,
    accuracy: 4,
  },
  {
    postcode: 2671,
    place_name: 'West Wyalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9237,
    longitude: 147.2047,
    accuracy: 4,
  },
  {
    postcode: 2671,
    place_name: 'North Yalgogrin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.85,
    longitude: 146.8333,
    accuracy: 3,
  },
  {
    postcode: 2671,
    place_name: 'Wyalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9333,
    longitude: 147.25,
    accuracy: 4,
  },
  {
    postcode: 2671,
    place_name: 'Alleena',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0688,
    longitude: 147.1392,
    accuracy: 4,
  },
  {
    postcode: 2671,
    place_name: 'Lake Cowal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6877,
    longitude: 147.3586,
    accuracy: 4,
  },
  {
    postcode: 2671,
    place_name: 'Back Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8345,
    longitude: 147.173,
    accuracy: 3,
  },
  {
    postcode: 2671,
    place_name: 'Burcher',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5141,
    longitude: 147.2532,
    accuracy: 4,
  },
  {
    postcode: 2672,
    place_name: 'Lake Cargelligo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2987,
    longitude: 146.3739,
    accuracy: 4,
  },
  {
    postcode: 2672,
    place_name: 'Curlew Waters',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2963,
    longitude: 146.4428,
    accuracy: 3,
  },
  {
    postcode: 2672,
    place_name: 'Murrin Bridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2068,
    longitude: 146.3711,
    accuracy: 4,
  },
  {
    postcode: 2672,
    place_name: 'Burgooney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3861,
    longitude: 146.5752,
    accuracy: 4,
  },
  {
    postcode: 2675,
    place_name: 'Hillston',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4877,
    longitude: 145.5403,
    accuracy: 4,
  },
  {
    postcode: 2675,
    place_name: 'Wallanthery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2655,
    longitude: 145.5005,
    accuracy: 3,
  },
  {
    postcode: 2675,
    place_name: 'Lake Brewster',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2655,
    longitude: 145.5005,
    accuracy: 3,
  },
  {
    postcode: 2675,
    place_name: 'Monia Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2655,
    longitude: 145.5005,
    accuracy: 3,
  },
  {
    postcode: 2675,
    place_name: 'Roto',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.05,
    longitude: 145.4667,
    accuracy: 4,
  },
  {
    postcode: 2678,
    place_name: 'Riverina Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5843,
    longitude: 146.6698,
    accuracy: 1,
  },
  {
    postcode: 2678,
    place_name: 'Charles Sturt University',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0593,
    longitude: 147.352,
    accuracy: 1,
  },
  {
    postcode: 2680,
    place_name: 'Beelbangera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2553,
    longitude: 146.0985,
    accuracy: 4,
  },
  {
    postcode: 2680,
    place_name: 'Nericon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1781,
    longitude: 146.0355,
    accuracy: 4,
  },
  {
    postcode: 2680,
    place_name: 'Bilbul',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2718,
    longitude: 146.1405,
    accuracy: 4,
  },
  {
    postcode: 2680,
    place_name: 'Griffith East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2768,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2680,
    place_name: 'Yoogali',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.3,
    longitude: 146.0833,
    accuracy: 4,
  },
  {
    postcode: 2680,
    place_name: 'Willbriggie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.466,
    longitude: 146.0149,
    accuracy: 4,
  },
  {
    postcode: 2680,
    place_name: 'Warburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1858,
    longitude: 145.9442,
    accuracy: 4,
  },
  {
    postcode: 2680,
    place_name: 'Warrawidgee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2768,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2680,
    place_name: 'Hanwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.331,
    longitude: 146.0414,
    accuracy: 4,
  },
  {
    postcode: 2680,
    place_name: 'Kooba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2768,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2680,
    place_name: 'Griffith',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2885,
    longitude: 146.0509,
    accuracy: 4,
  },
  {
    postcode: 2680,
    place_name: 'Griffith DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2768,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2680,
    place_name: 'Lake Wyangan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.232,
    longitude: 146.0208,
    accuracy: 4,
  },
  {
    postcode: 2680,
    place_name: 'Tharbogang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.299,
    longitude: 145.826,
    accuracy: 3,
  },
  {
    postcode: 2680,
    place_name: 'Widgelli',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.299,
    longitude: 145.826,
    accuracy: 3,
  },
  {
    postcode: 2680,
    place_name: 'Benerembah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.299,
    longitude: 145.826,
    accuracy: 3,
  },
  {
    postcode: 2681,
    place_name: 'Yenda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2501,
    longitude: 146.199,
    accuracy: 4,
  },
  {
    postcode: 2681,
    place_name: 'Myall Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2501,
    longitude: 146.199,
    accuracy: 3,
  },
  {
    postcode: 2700,
    place_name: 'Sandigo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9214,
    longitude: 146.6613,
    accuracy: 4,
  },
  {
    postcode: 2700,
    place_name: 'Morundah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9333,
    longitude: 146.3,
    accuracy: 4,
  },
  {
    postcode: 2700,
    place_name: 'Narrandera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7475,
    longitude: 146.551,
    accuracy: 4,
  },
  {
    postcode: 2700,
    place_name: 'Gillenbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8246,
    longitude: 146.4029,
    accuracy: 3,
  },
  {
    postcode: 2700,
    place_name: 'Colinroobie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.454,
    longitude: 146.6034,
    accuracy: 4,
  },
  {
    postcode: 2700,
    place_name: 'Bundure',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1708,
    longitude: 146.0078,
    accuracy: 4,
  },
  {
    postcode: 2700,
    place_name: 'Cudgel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8246,
    longitude: 146.4029,
    accuracy: 3,
  },
  {
    postcode: 2700,
    place_name: 'Euroley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7167,
    longitude: 146.35,
    accuracy: 4,
  },
  {
    postcode: 2700,
    place_name: 'Corobimilla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9139,
    longitude: 146.1765,
    accuracy: 3,
  },
  {
    postcode: 2701,
    place_name: 'Methul',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5985,
    longitude: 147.1512,
    accuracy: 4,
  },
  {
    postcode: 2701,
    place_name: 'Berry Jerry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6893,
    longitude: 147.2003,
    accuracy: 3,
  },
  {
    postcode: 2701,
    place_name: 'Coolamon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8164,
    longitude: 147.1958,
    accuracy: 4,
  },
  {
    postcode: 2701,
    place_name: 'Rannock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6485,
    longitude: 147.2512,
    accuracy: 4,
  },
  {
    postcode: 2702,
    place_name: 'Ganmain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7932,
    longitude: 147.0391,
    accuracy: 4,
  },
  {
    postcode: 2703,
    place_name: 'Yanco',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5985,
    longitude: 146.4095,
    accuracy: 4,
  },
  {
    postcode: 2705,
    place_name: 'Corbie Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.563,
    longitude: 146.4833,
    accuracy: 4,
  },
  {
    postcode: 2705,
    place_name: 'Whitton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5187,
    longitude: 146.1851,
    accuracy: 4,
  },
  {
    postcode: 2705,
    place_name: 'Wamoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5358,
    longitude: 146.3366,
    accuracy: 4,
  },
  {
    postcode: 2705,
    place_name: 'Merungle Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.615,
    longitude: 146.4652,
    accuracy: 4,
  },
  {
    postcode: 2705,
    place_name: 'Murrami',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4231,
    longitude: 146.3057,
    accuracy: 4,
  },
  {
    postcode: 2705,
    place_name: 'Leeton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.551,
    longitude: 146.4048,
    accuracy: 4,
  },
  {
    postcode: 2705,
    place_name: 'Stanbridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5152,
    longitude: 146.2346,
    accuracy: 4,
  },
  {
    postcode: 2705,
    place_name: 'Brobenah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.531,
    longitude: 146.3361,
    accuracy: 3,
  },
  {
    postcode: 2705,
    place_name: 'Gogeldrie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5485,
    longitude: 146.3012,
    accuracy: 4,
  },
  {
    postcode: 2706,
    place_name: 'Darlington Point',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5694,
    longitude: 145.9987,
    accuracy: 4,
  },
  {
    postcode: 2707,
    place_name: 'Coleambally',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8039,
    longitude: 145.8804,
    accuracy: 4,
  },
  {
    postcode: 2707,
    place_name: 'Argoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9477,
    longitude: 145.7211,
    accuracy: 4,
  },
  {
    postcode: 2708,
    place_name: 'Murray Region Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6742,
    longitude: 146.3985,
    accuracy: 1,
  },
  {
    postcode: 2710,
    place_name: 'Pretty Pine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4152,
    longitude: 144.8846,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Mathoura',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8152,
    longitude: 144.9012,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Wakool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4706,
    longitude: 144.3957,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Wandook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4621,
    longitude: 144.9959,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Birganbigil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 145.4667,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Caldwell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6318,
    longitude: 144.5096,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Moonbria',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 145.4667,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Deniliquin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5324,
    longitude: 144.9536,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Stud Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 145.4667,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Conargo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3035,
    longitude: 145.1801,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Steam Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 145.4667,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Calimo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4152,
    longitude: 144.5846,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Gulpa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7,
    longitude: 144.8833,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Wanganella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2121,
    longitude: 144.8194,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Booroorban',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9325,
    longitude: 144.7619,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Lindifferon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 145.4667,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Barratta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2955,
    longitude: 144.5737,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Coree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3454,
    longitude: 145.4289,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Moira',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9318,
    longitude: 144.8346,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Hartwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 145.4667,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Morago',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3718,
    longitude: 144.6606,
    accuracy: 4,
  },
  {
    postcode: 2710,
    place_name: 'Bullatale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 145.4667,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Mayrung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3251,
    longitude: 144.8201,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Willurah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 145.4667,
    accuracy: 3,
  },
  {
    postcode: 2710,
    place_name: 'Warragoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3667,
    longitude: 145.4667,
    accuracy: 3,
  },
  {
    postcode: 2711,
    place_name: 'Oxley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1936,
    longitude: 144.1024,
    accuracy: 4,
  },
  {
    postcode: 2711,
    place_name: 'Carrathool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4075,
    longitude: 145.4358,
    accuracy: 4,
  },
  {
    postcode: 2711,
    place_name: 'Maude',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4739,
    longitude: 144.3003,
    accuracy: 4,
  },
  {
    postcode: 2711,
    place_name: 'Yanga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2167,
    longitude: 145.425,
    accuracy: 3,
  },
  {
    postcode: 2711,
    place_name: 'Gunbar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0444,
    longitude: 145.4043,
    accuracy: 4,
  },
  {
    postcode: 2711,
    place_name: 'Keri Keri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2167,
    longitude: 145.425,
    accuracy: 3,
  },
  {
    postcode: 2711,
    place_name: 'Corrong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2176,
    longitude: 144.4683,
    accuracy: 4,
  },
  {
    postcode: 2711,
    place_name: 'Waugorah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2167,
    longitude: 145.425,
    accuracy: 3,
  },
  {
    postcode: 2711,
    place_name: 'Booligal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1481,
    longitude: 144.6732,
    accuracy: 3,
  },
  {
    postcode: 2711,
    place_name: 'Hay South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2167,
    longitude: 145.425,
    accuracy: 3,
  },
  {
    postcode: 2711,
    place_name: 'One Tree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1889,
    longitude: 144.7172,
    accuracy: 4,
  },
  {
    postcode: 2711,
    place_name: 'Clare',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4,
    longitude: 143.9303,
    accuracy: 4,
  },
  {
    postcode: 2711,
    place_name: 'Hay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.509,
    longitude: 144.8431,
    accuracy: 4,
  },
  {
    postcode: 2712,
    place_name: 'Berrigan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6588,
    longitude: 145.8114,
    accuracy: 4,
  },
  {
    postcode: 2712,
    place_name: 'Boomanoomana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8831,
    longitude: 145.7929,
    accuracy: 4,
  },
  {
    postcode: 2713,
    place_name: 'Myrtle Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.649,
    longitude: 145.577,
    accuracy: 3,
  },
  {
    postcode: 2713,
    place_name: 'Blighty',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5883,
    longitude: 145.2859,
    accuracy: 4,
  },
  {
    postcode: 2713,
    place_name: 'Logie Brae',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.649,
    longitude: 145.577,
    accuracy: 3,
  },
  {
    postcode: 2713,
    place_name: 'Finley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.655,
    longitude: 145.5711,
    accuracy: 4,
  },
  {
    postcode: 2714,
    place_name: 'Tuppal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6818,
    longitude: 145.2512,
    accuracy: 4,
  },
  {
    postcode: 2714,
    place_name: 'Tocumwal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8115,
    longitude: 145.5692,
    accuracy: 4,
  },
  {
    postcode: 2714,
    place_name: 'Aratula',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.231,
    longitude: 145.6188,
    accuracy: 3,
  },
  {
    postcode: 2714,
    place_name: 'Pine Lodge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.231,
    longitude: 145.6188,
    accuracy: 3,
  },
  {
    postcode: 2715,
    place_name: 'Hatfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8665,
    longitude: 143.738,
    accuracy: 4,
  },
  {
    postcode: 2715,
    place_name: 'Mungo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.231,
    longitude: 145.6188,
    accuracy: 3,
  },
  {
    postcode: 2715,
    place_name: 'Balranald',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.6365,
    longitude: 143.5614,
    accuracy: 4,
  },
  {
    postcode: 2715,
    place_name: 'Kyalite',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9532,
    longitude: 143.4775,
    accuracy: 4,
  },
  {
    postcode: 2715,
    place_name: 'Arumpo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.231,
    longitude: 145.6188,
    accuracy: 3,
  },
  {
    postcode: 2716,
    place_name: 'Four Corners',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.136,
    longitude: 146.3019,
    accuracy: 4,
  },
  {
    postcode: 2716,
    place_name: 'Mairjimmy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4932,
    longitude: 145.7475,
    accuracy: 4,
  },
  {
    postcode: 2716,
    place_name: 'Gala Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4283,
    longitude: 145.7311,
    accuracy: 3,
  },
  {
    postcode: 2716,
    place_name: 'Mabins Well',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.247,
    longitude: 145.5718,
    accuracy: 3,
  },
  {
    postcode: 2716,
    place_name: 'Jerilderie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3576,
    longitude: 145.7296,
    accuracy: 4,
  },
  {
    postcode: 2717,
    place_name: 'Dareton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0947,
    longitude: 142.0397,
    accuracy: 4,
  },
  {
    postcode: 2717,
    place_name: 'Coomealla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0981,
    longitude: 142.0925,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Bombowlee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2628,
    longitude: 148.2449,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Pinbeyan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Lacmalac',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3167,
    longitude: 148.3167,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Blowering',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5117,
    longitude: 148.2658,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Talbingo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.581,
    longitude: 148.2948,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Gadara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Red Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0523,
    longitude: 149.1643,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Tumut',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3002,
    longitude: 148.2251,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Minjary',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1999,
    longitude: 148.1346,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Mundongo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2907,
    longitude: 148.2805,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Tumorrama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1667,
    longitude: 148.4333,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Argalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3,
    longitude: 148.4333,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Yarrangobilly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.65,
    longitude: 148.4833,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Couragago',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Gocup',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2333,
    longitude: 148.2,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Gilmore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3333,
    longitude: 148.1833,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Killimicat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Little River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Goobarragandra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Jones Bridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Bombowlee Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Wyangle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2333,
    longitude: 148.3,
    accuracy: 4,
  },
  {
    postcode: 2720,
    place_name: 'Buddong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Windowie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Tumut Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Bogong Peaks Wilderness',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Wereboldera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2720,
    place_name: 'Wermatong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3366,
    longitude: 148.2926,
    accuracy: 3,
  },
  {
    postcode: 2721,
    place_name: 'Quandialla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0124,
    longitude: 147.7876,
    accuracy: 4,
  },
  {
    postcode: 2721,
    place_name: 'Bland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9833,
    longitude: 147.6833,
    accuracy: 4,
  },
  {
    postcode: 2722,
    place_name: 'Gundagai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0652,
    longitude: 148.108,
    accuracy: 4,
  },
  {
    postcode: 2722,
    place_name: 'Darbalara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0167,
    longitude: 148.2,
    accuracy: 4,
  },
  {
    postcode: 2722,
    place_name: 'South Gundagai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0833,
    longitude: 148.1,
    accuracy: 4,
  },
  {
    postcode: 2722,
    place_name: 'Brungle Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0275,
    longitude: 148.1097,
    accuracy: 3,
  },
  {
    postcode: 2722,
    place_name: 'Brungle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.15,
    longitude: 148.2333,
    accuracy: 4,
  },
  {
    postcode: 2722,
    place_name: 'Jones Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0275,
    longitude: 148.1097,
    accuracy: 3,
  },
  {
    postcode: 2722,
    place_name: 'Muttama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.7985,
    longitude: 148.1179,
    accuracy: 4,
  },
  {
    postcode: 2722,
    place_name: 'Burra Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0275,
    longitude: 148.1097,
    accuracy: 3,
  },
  {
    postcode: 2722,
    place_name: 'Nangus',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.05,
    longitude: 147.9,
    accuracy: 4,
  },
  {
    postcode: 2725,
    place_name: 'Stockinbingal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4989,
    longitude: 147.882,
    accuracy: 4,
  },
  {
    postcode: 2726,
    place_name: 'Cooneys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8333,
    longitude: 148.3333,
    accuracy: 3,
  },
  {
    postcode: 2726,
    place_name: 'Jugiong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8333,
    longitude: 148.3333,
    accuracy: 4,
  },
  {
    postcode: 2727,
    place_name: 'Coolac',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9285,
    longitude: 148.1651,
    accuracy: 4,
  },
  {
    postcode: 2727,
    place_name: 'Gobarralong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9902,
    longitude: 148.2512,
    accuracy: 4,
  },
  {
    postcode: 2727,
    place_name: 'Adjungbilly',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0873,
    longitude: 148.4185,
    accuracy: 4,
  },
  {
    postcode: 2729,
    place_name: 'Westwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2452,
    longitude: 147.9929,
    accuracy: 3,
  },
  {
    postcode: 2729,
    place_name: 'Mount Horeb',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2167,
    longitude: 148.0333,
    accuracy: 4,
  },
  {
    postcode: 2729,
    place_name: 'Sandy Gully',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2452,
    longitude: 147.9929,
    accuracy: 3,
  },
  {
    postcode: 2729,
    place_name: 'Cooleys Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2633,
    longitude: 148.0041,
    accuracy: 3,
  },
  {
    postcode: 2729,
    place_name: 'Califat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2452,
    longitude: 147.9929,
    accuracy: 3,
  },
  {
    postcode: 2729,
    place_name: 'Black Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2452,
    longitude: 147.9929,
    accuracy: 3,
  },
  {
    postcode: 2729,
    place_name: 'Adelong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.3049,
    longitude: 148.069,
    accuracy: 4,
  },
  {
    postcode: 2729,
    place_name: 'Tumblong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.15,
    longitude: 148,
    accuracy: 4,
  },
  {
    postcode: 2729,
    place_name: 'Grahamstown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2667,
    longitude: 148.0333,
    accuracy: 4,
  },
  {
    postcode: 2729,
    place_name: 'Darlow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2452,
    longitude: 147.9929,
    accuracy: 3,
  },
  {
    postcode: 2729,
    place_name: 'Sharps Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2452,
    longitude: 147.9929,
    accuracy: 3,
  },
  {
    postcode: 2729,
    place_name: 'Mundarlo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1333,
    longitude: 147.8167,
    accuracy: 4,
  },
  {
    postcode: 2729,
    place_name: 'Yaven Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2452,
    longitude: 147.9929,
    accuracy: 3,
  },
  {
    postcode: 2729,
    place_name: 'Bangadang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2452,
    longitude: 147.9929,
    accuracy: 3,
  },
  {
    postcode: 2729,
    place_name: 'Ellerslie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3103,
    longitude: 147.2462,
    accuracy: 4,
  },
  {
    postcode: 2729,
    place_name: 'Mount Adrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.2167,
    longitude: 147.9,
    accuracy: 4,
  },
  {
    postcode: 2729,
    place_name: 'Wondalga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.4167,
    longitude: 148.1167,
    accuracy: 4,
  },
  {
    postcode: 2730,
    place_name: 'Green Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9176,
    longitude: 149.5836,
    accuracy: 4,
  },
  {
    postcode: 2730,
    place_name: 'Kunama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5494,
    longitude: 148.0982,
    accuracy: 4,
  },
  {
    postcode: 2730,
    place_name: 'Lower Bago',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.524,
    longitude: 148.0994,
    accuracy: 3,
  },
  {
    postcode: 2730,
    place_name: 'Batlow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.5191,
    longitude: 148.146,
    accuracy: 4,
  },
  {
    postcode: 2731,
    place_name: 'Bunnaloo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7869,
    longitude: 144.5977,
    accuracy: 4,
  },
  {
    postcode: 2731,
    place_name: 'Tantonan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2731,
    place_name: 'Womboota',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9073,
    longitude: 144.6,
    accuracy: 4,
  },
  {
    postcode: 2731,
    place_name: 'Thyra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.8401,
    longitude: 144.6356,
    accuracy: 4,
  },
  {
    postcode: 2731,
    place_name: 'Moama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.1041,
    longitude: 144.7608,
    accuracy: 4,
  },
  {
    postcode: 2732,
    place_name: 'Burraboi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.393,
    longitude: 144.3159,
    accuracy: 4,
  },
  {
    postcode: 2732,
    place_name: 'Gonn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2732,
    place_name: 'Cobramunga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2732,
    place_name: 'Tullakool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2732,
    place_name: 'Thule',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2732,
    place_name: 'Barham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.6265,
    longitude: 144.129,
    accuracy: 4,
  },
  {
    postcode: 2732,
    place_name: 'Noorong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2733,
    place_name: 'Dhuragoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2733,
    place_name: 'Niemur',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2733,
    place_name: 'Moulamein',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0906,
    longitude: 144.0363,
    accuracy: 4,
  },
  {
    postcode: 2734,
    place_name: 'Dilpurra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1652,
    longitude: 143.6846,
    accuracy: 4,
  },
  {
    postcode: 2734,
    place_name: 'Stony Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0813,
    longitude: 143.5498,
    accuracy: 4,
  },
  {
    postcode: 2734,
    place_name: 'Cunninyeuk',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2734,
    place_name: 'Kyalite',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.9532,
    longitude: 143.4775,
    accuracy: 4,
  },
  {
    postcode: 2734,
    place_name: 'Mallan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2734,
    place_name: 'Wetuppa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2734,
    place_name: 'Tooranie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2734,
    place_name: 'Murray Downs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2734,
    place_name: 'Mellool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2734,
    place_name: 'Moolpa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2735,
    place_name: 'Koraleigh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1286,
    longitude: 143.4404,
    accuracy: 4,
  },
  {
    postcode: 2735,
    place_name: 'Speewa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2736,
    place_name: 'Goodnight',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.959,
    longitude: 143.3395,
    accuracy: 4,
  },
  {
    postcode: 2736,
    place_name: 'Tooleybuc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0318,
    longitude: 143.3346,
    accuracy: 4,
  },
  {
    postcode: 2737,
    place_name: 'Euston',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.5744,
    longitude: 142.7448,
    accuracy: 4,
  },
  {
    postcode: 2738,
    place_name: 'Paringi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2366,
    longitude: 142.5301,
    accuracy: 3,
  },
  {
    postcode: 2738,
    place_name: 'Gol Gol',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1739,
    longitude: 142.2219,
    accuracy: 4,
  },
  {
    postcode: 2738,
    place_name: 'Trentham Cliffs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2366,
    longitude: 142.5301,
    accuracy: 3,
  },
  {
    postcode: 2738,
    place_name: 'Mallee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2366,
    longitude: 142.5301,
    accuracy: 3,
  },
  {
    postcode: 2738,
    place_name: 'Monak',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.2818,
    longitude: 142.2679,
    accuracy: 4,
  },
  {
    postcode: 2739,
    place_name: 'Mourquong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1588,
    longitude: 146.0447,
    accuracy: 3,
  },
  {
    postcode: 2739,
    place_name: 'Boeill Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1432,
    longitude: 142.1201,
    accuracy: 4,
  },
  {
    postcode: 2739,
    place_name: 'Buronga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1707,
    longitude: 142.1735,
    accuracy: 4,
  },
  {
    postcode: 2745,
    place_name: 'Mulgoa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.838,
    longitude: 150.6496,
    accuracy: 4,
  },
  {
    postcode: 2745,
    place_name: 'Glenmore Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7907,
    longitude: 150.6693,
    accuracy: 4,
  },
  {
    postcode: 2745,
    place_name: 'Greendale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9274,
    longitude: 150.663,
    accuracy: 4,
  },
  {
    postcode: 2745,
    place_name: 'Luddenham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8785,
    longitude: 150.6886,
    accuracy: 4,
  },
  {
    postcode: 2745,
    place_name: 'Regentville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7752,
    longitude: 150.6655,
    accuracy: 4,
  },
  {
    postcode: 2745,
    place_name: 'Wallacia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8658,
    longitude: 150.6402,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Claremont Meadows',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7759,
    longitude: 150.7519,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Kingswood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.75,
    longitude: 150.75,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Shanes Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7033,
    longitude: 150.783,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Cambridge Gardens',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.738,
    longitude: 150.7207,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Caddens',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7472,
    longitude: 150.7444,
    accuracy: 3,
  },
  {
    postcode: 2747,
    place_name: 'Werrington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7592,
    longitude: 150.7527,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Cambridge Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7478,
    longitude: 150.7221,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Werrington County',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7465,
    longitude: 150.7393,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Llandilo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7129,
    longitude: 150.7465,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Werrington Downs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7421,
    longitude: 150.7278,
    accuracy: 4,
  },
  {
    postcode: 2747,
    place_name: 'Jordan Springs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7472,
    longitude: 150.7444,
    accuracy: 3,
  },
  {
    postcode: 2748,
    place_name: 'Orchard Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7823,
    longitude: 150.7109,
    accuracy: 4,
  },
  {
    postcode: 2749,
    place_name: 'Castlereagh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6898,
    longitude: 150.6789,
    accuracy: 4,
  },
  {
    postcode: 2749,
    place_name: 'Cranebrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7061,
    longitude: 150.7094,
    accuracy: 4,
  },
  {
    postcode: 2750,
    place_name: 'Penrith',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.75,
    longitude: 150.7,
    accuracy: 4,
  },
  {
    postcode: 2750,
    place_name: 'Leonay',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7654,
    longitude: 150.6481,
    accuracy: 4,
  },
  {
    postcode: 2750,
    place_name: 'Jamisontown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.768,
    longitude: 150.6768,
    accuracy: 4,
  },
  {
    postcode: 2750,
    place_name: 'Emu Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7345,
    longitude: 150.6487,
    accuracy: 4,
  },
  {
    postcode: 2750,
    place_name: 'South Penrith',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7735,
    longitude: 150.6947,
    accuracy: 4,
  },
  {
    postcode: 2750,
    place_name: 'Emu Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.75,
    longitude: 150.6667,
    accuracy: 4,
  },
  {
    postcode: 2750,
    place_name: 'Penrith Plaza',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7583,
    longitude: 150.6729,
    accuracy: 3,
  },
  {
    postcode: 2750,
    place_name: 'Penrith South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7583,
    longitude: 150.6729,
    accuracy: 3,
  },
  {
    postcode: 2751,
    place_name: 'Penrith',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.75,
    longitude: 150.7,
    accuracy: 4,
  },
  {
    postcode: 2752,
    place_name: 'Warragamba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8919,
    longitude: 150.6046,
    accuracy: 4,
  },
  {
    postcode: 2752,
    place_name: 'Silverdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9152,
    longitude: 150.6096,
    accuracy: 4,
  },
  {
    postcode: 2753,
    place_name: 'Londonderry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6466,
    longitude: 150.7352,
    accuracy: 4,
  },
  {
    postcode: 2753,
    place_name: 'Agnes Banks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6094,
    longitude: 150.7167,
    accuracy: 4,
  },
  {
    postcode: 2753,
    place_name: 'Grose Wold',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6075,
    longitude: 150.6945,
    accuracy: 3,
  },
  {
    postcode: 2753,
    place_name: 'Hobartville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6044,
    longitude: 150.743,
    accuracy: 4,
  },
  {
    postcode: 2753,
    place_name: 'Bowen Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5719,
    longitude: 150.6256,
    accuracy: 4,
  },
  {
    postcode: 2753,
    place_name: 'Yarramundi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6194,
    longitude: 150.6748,
    accuracy: 4,
  },
  {
    postcode: 2753,
    place_name: 'Grose Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5871,
    longitude: 150.6556,
    accuracy: 4,
  },
  {
    postcode: 2753,
    place_name: 'Richmond',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5996,
    longitude: 150.7514,
    accuracy: 4,
  },
  {
    postcode: 2753,
    place_name: 'Richmond Lowlands',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.608,
    longitude: 150.6988,
    accuracy: 3,
  },
  {
    postcode: 2754,
    place_name: 'The Slopes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5324,
    longitude: 150.7056,
    accuracy: 4,
  },
  {
    postcode: 2754,
    place_name: 'North Richmond',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5812,
    longitude: 150.7194,
    accuracy: 4,
  },
  {
    postcode: 2754,
    place_name: 'Tennyson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.533,
    longitude: 150.7375,
    accuracy: 4,
  },
  {
    postcode: 2755,
    place_name: 'Richmond Raaf',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5971,
    longitude: 150.7584,
    accuracy: 3,
  },
  {
    postcode: 2755,
    place_name: 'Richmond',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5996,
    longitude: 150.7514,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Bligh Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6398,
    longitude: 150.8025,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Cornwallis',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5006,
    longitude: 150.8816,
    accuracy: 3,
  },
  {
    postcode: 2756,
    place_name: 'Colo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4333,
    longitude: 150.8333,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Mellong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.146,
    longitude: 150.6847,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Ebenezer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5198,
    longitude: 150.8863,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Freemans Reach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5579,
    longitude: 150.7955,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Cumberland Reach',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4648,
    longitude: 150.895,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Colo Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3685,
    longitude: 150.7208,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Glossodia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5362,
    longitude: 150.7739,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Sackville North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4841,
    longitude: 150.888,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Cattai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5457,
    longitude: 150.9143,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Upper Colo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4215,
    longitude: 150.7264,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Pitt Town',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5872,
    longitude: 150.8586,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Mulgrave',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5006,
    longitude: 150.8816,
    accuracy: 3,
  },
  {
    postcode: 2756,
    place_name: 'Mcgraths Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6155,
    longitude: 150.8337,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Pitt Town Bottoms',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5274,
    longitude: 150.8499,
    accuracy: 3,
  },
  {
    postcode: 2756,
    place_name: 'South Maroota',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.504,
    longitude: 150.9568,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Sackville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4833,
    longitude: 150.9,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Maroota',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4833,
    longitude: 150.95,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Windsor Downs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6677,
    longitude: 150.8026,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Central Colo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4333,
    longitude: 150.8333,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Windsor',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6131,
    longitude: 150.8142,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Womerah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5274,
    longitude: 150.8499,
    accuracy: 3,
  },
  {
    postcode: 2756,
    place_name: 'Scheyville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6045,
    longitude: 150.8831,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'South Windsor',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6181,
    longitude: 150.8044,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Wilberforce',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5626,
    longitude: 150.8378,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Lower Portland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.45,
    longitude: 150.8833,
    accuracy: 4,
  },
  {
    postcode: 2756,
    place_name: 'Clarendon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6096,
    longitude: 150.7867,
    accuracy: 4,
  },
  {
    postcode: 2757,
    place_name: 'Kurmond',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5533,
    longitude: 150.6947,
    accuracy: 4,
  },
  {
    postcode: 2758,
    place_name: 'Blaxlands Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4733,
    longitude: 150.7981,
    accuracy: 4,
  },
  {
    postcode: 2758,
    place_name: 'Wheeny Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5068,
    longitude: 150.6768,
    accuracy: 3,
  },
  {
    postcode: 2758,
    place_name: 'Mountain Lagoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5068,
    longitude: 150.6768,
    accuracy: 3,
  },
  {
    postcode: 2758,
    place_name: 'Bilpin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5,
    longitude: 150.5333,
    accuracy: 4,
  },
  {
    postcode: 2758,
    place_name: 'Mount Tomah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5466,
    longitude: 150.4223,
    accuracy: 4,
  },
  {
    postcode: 2758,
    place_name: 'Kurrajong Hills',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5068,
    longitude: 150.6768,
    accuracy: 3,
  },
  {
    postcode: 2758,
    place_name: 'East Kurrajong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5055,
    longitude: 150.7943,
    accuracy: 4,
  },
  {
    postcode: 2758,
    place_name: 'Berambing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5326,
    longitude: 150.4439,
    accuracy: 4,
  },
  {
    postcode: 2758,
    place_name: 'Kurrajong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5277,
    longitude: 150.6291,
    accuracy: 4,
  },
  {
    postcode: 2758,
    place_name: 'The Devils Wilderness',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5068,
    longitude: 150.6768,
    accuracy: 3,
  },
  {
    postcode: 2758,
    place_name: 'Kurrajong Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5277,
    longitude: 150.6291,
    accuracy: 4,
  },
  {
    postcode: 2759,
    place_name: 'St Clair',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7973,
    longitude: 150.7847,
    accuracy: 4,
  },
  {
    postcode: 2759,
    place_name: 'Erskine Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.813,
    longitude: 150.7977,
    accuracy: 4,
  },
  {
    postcode: 2760,
    place_name: 'Colyton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7828,
    longitude: 150.7968,
    accuracy: 4,
  },
  {
    postcode: 2760,
    place_name: 'St Marys East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7593,
    longitude: 150.7883,
    accuracy: 3,
  },
  {
    postcode: 2760,
    place_name: 'North St Marys',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7549,
    longitude: 150.7844,
    accuracy: 4,
  },
  {
    postcode: 2760,
    place_name: 'Ropes Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7288,
    longitude: 150.777,
    accuracy: 4,
  },
  {
    postcode: 2760,
    place_name: 'Oxley Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7707,
    longitude: 150.795,
    accuracy: 4,
  },
  {
    postcode: 2760,
    place_name: 'St Marys',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.744,
    longitude: 150.783,
    accuracy: 3,
  },
  {
    postcode: 2760,
    place_name: 'St Marys South',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7593,
    longitude: 150.7883,
    accuracy: 3,
  },
  {
    postcode: 2761,
    place_name: 'Plumpton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7518,
    longitude: 150.8369,
    accuracy: 4,
  },
  {
    postcode: 2761,
    place_name: 'Hassall Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7335,
    longitude: 150.8354,
    accuracy: 4,
  },
  {
    postcode: 2761,
    place_name: 'Dean Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7354,
    longitude: 150.8596,
    accuracy: 4,
  },
  {
    postcode: 2761,
    place_name: 'Oakhurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7388,
    longitude: 150.8384,
    accuracy: 4,
  },
  {
    postcode: 2761,
    place_name: 'Colebee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7277,
    longitude: 150.8474,
    accuracy: 4,
  },
  {
    postcode: 2761,
    place_name: 'Glendenning',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7483,
    longitude: 150.8541,
    accuracy: 4,
  },
  {
    postcode: 2762,
    place_name: 'Schofields',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7167,
    longitude: 150.8667,
    accuracy: 4,
  },
  {
    postcode: 2763,
    place_name: 'Quakers Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7333,
    longitude: 150.8833,
    accuracy: 4,
  },
  {
    postcode: 2763,
    place_name: 'Acacia Gardens',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7334,
    longitude: 150.9139,
    accuracy: 4,
  },
  {
    postcode: 2765,
    place_name: 'Box Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6441,
    longitude: 150.8684,
    accuracy: 3,
  },
  {
    postcode: 2765,
    place_name: 'Vineyard',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6499,
    longitude: 150.8526,
    accuracy: 4,
  },
  {
    postcode: 2765,
    place_name: 'Nelson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6462,
    longitude: 150.9214,
    accuracy: 4,
  },
  {
    postcode: 2765,
    place_name: 'Oakville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6156,
    longitude: 150.8801,
    accuracy: 4,
  },
  {
    postcode: 2765,
    place_name: 'Riverstone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6833,
    longitude: 150.8667,
    accuracy: 4,
  },
  {
    postcode: 2765,
    place_name: 'Marsden Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.692,
    longitude: 150.8373,
    accuracy: 4,
  },
  {
    postcode: 2765,
    place_name: 'Maraylya',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5922,
    longitude: 150.9198,
    accuracy: 4,
  },
  {
    postcode: 2765,
    place_name: 'Berkshire Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6728,
    longitude: 150.7752,
    accuracy: 4,
  },
  {
    postcode: 2766,
    place_name: 'Eastern Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8063,
    longitude: 150.8612,
    accuracy: 4,
  },
  {
    postcode: 2766,
    place_name: 'Rooty Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 150.8333,
    accuracy: 4,
  },
  {
    postcode: 2767,
    place_name: 'Bungarribee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7615,
    longitude: 150.8737,
    accuracy: 3,
  },
  {
    postcode: 2767,
    place_name: 'Woodcroft',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7564,
    longitude: 150.8807,
    accuracy: 4,
  },
  {
    postcode: 2767,
    place_name: 'Doonside',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 150.8667,
    accuracy: 4,
  },
  {
    postcode: 2768,
    place_name: 'Parklea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7244,
    longitude: 150.9182,
    accuracy: 4,
  },
  {
    postcode: 2768,
    place_name: 'Stanhope Gardens',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7164,
    longitude: 150.9261,
    accuracy: 4,
  },
  {
    postcode: 2768,
    place_name: 'Glenwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7357,
    longitude: 150.9348,
    accuracy: 4,
  },
  {
    postcode: 2769,
    place_name: 'The Ponds',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7061,
    longitude: 150.9088,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Lethbridge Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7372,
    longitude: 150.8004,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Tregear',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7456,
    longitude: 150.7929,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Bidwill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7301,
    longitude: 150.8222,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Dharruk',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7481,
    longitude: 150.8156,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Hebersham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7458,
    longitude: 150.8239,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Whalan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.757,
    longitude: 150.804,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Willmot',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7241,
    longitude: 150.7928,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Minchinbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7872,
    longitude: 150.8296,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Emerton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7441,
    longitude: 150.8064,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Mount Druitt Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7467,
    longitude: 150.8101,
    accuracy: 3,
  },
  {
    postcode: 2770,
    place_name: 'Mount Druitt',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 150.8167,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Shalvey',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7276,
    longitude: 150.8066,
    accuracy: 4,
  },
  {
    postcode: 2770,
    place_name: 'Blackett',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7379,
    longitude: 150.8181,
    accuracy: 4,
  },
  {
    postcode: 2773,
    place_name: 'Lapstone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7742,
    longitude: 150.6357,
    accuracy: 4,
  },
  {
    postcode: 2773,
    place_name: 'Glenbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 150.6167,
    accuracy: 4,
  },
  {
    postcode: 2774,
    place_name: 'Mount Riverview',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7307,
    longitude: 150.6353,
    accuracy: 4,
  },
  {
    postcode: 2774,
    place_name: 'Blaxland East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7325,
    longitude: 150.6118,
    accuracy: 3,
  },
  {
    postcode: 2774,
    place_name: 'Blaxland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.75,
    longitude: 150.6,
    accuracy: 4,
  },
  {
    postcode: 2774,
    place_name: 'Warrimoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7167,
    longitude: 150.6,
    accuracy: 4,
  },
  {
    postcode: 2775,
    place_name: 'Wisemans Ferry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3833,
    longitude: 150.9833,
    accuracy: 4,
  },
  {
    postcode: 2775,
    place_name: 'Spencer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4593,
    longitude: 151.1447,
    accuracy: 4,
  },
  {
    postcode: 2775,
    place_name: 'Marlow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4639,
    longitude: 151.166,
    accuracy: 4,
  },
  {
    postcode: 2775,
    place_name: 'St Albans',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2833,
    longitude: 150.9833,
    accuracy: 4,
  },
  {
    postcode: 2775,
    place_name: 'Higher Macdonald',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3998,
    longitude: 151.0311,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Singletons Mill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3998,
    longitude: 151.0311,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Perrys Crossing',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3998,
    longitude: 151.0311,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Lower Macdonald',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3653,
    longitude: 150.9511,
    accuracy: 4,
  },
  {
    postcode: 2775,
    place_name: 'Central Macdonald',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2453,
    longitude: 150.8513,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Webbs Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3998,
    longitude: 151.0311,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Leets Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3998,
    longitude: 151.0311,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Fernances',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3998,
    longitude: 151.0311,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Wrights Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3998,
    longitude: 151.0311,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Upper Macdonald',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3998,
    longitude: 151.0311,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Laughtondale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4167,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 2775,
    place_name: 'Gunderman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.45,
    longitude: 151.0667,
    accuracy: 4,
  },
  {
    postcode: 2775,
    place_name: 'Mogo Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3998,
    longitude: 151.0311,
    accuracy: 3,
  },
  {
    postcode: 2775,
    place_name: 'Lower Hawkesbury',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.45,
    longitude: 151.05,
    accuracy: 4,
  },
  {
    postcode: 2776,
    place_name: 'Faulconbridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7,
    longitude: 150.5333,
    accuracy: 4,
  },
  {
    postcode: 2777,
    place_name: 'Hawkesbury Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6657,
    longitude: 150.6426,
    accuracy: 4,
  },
  {
    postcode: 2777,
    place_name: 'Sun Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7088,
    longitude: 150.5937,
    accuracy: 4,
  },
  {
    postcode: 2777,
    place_name: 'Winmalee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6785,
    longitude: 150.6121,
    accuracy: 4,
  },
  {
    postcode: 2777,
    place_name: 'Yellow Rock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6985,
    longitude: 150.6346,
    accuracy: 4,
  },
  {
    postcode: 2777,
    place_name: 'Springwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7,
    longitude: 150.55,
    accuracy: 4,
  },
  {
    postcode: 2777,
    place_name: 'Valley Heights',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7,
    longitude: 150.5833,
    accuracy: 4,
  },
  {
    postcode: 2778,
    place_name: 'Linden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7167,
    longitude: 150.5,
    accuracy: 4,
  },
  {
    postcode: 2778,
    place_name: 'Woodford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7333,
    longitude: 150.4833,
    accuracy: 4,
  },
  {
    postcode: 2779,
    place_name: 'Hazelbrook',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7245,
    longitude: 150.4584,
    accuracy: 4,
  },
  {
    postcode: 2780,
    place_name: 'Katoomba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7198,
    longitude: 150.3074,
    accuracy: 4,
  },
  {
    postcode: 2780,
    place_name: 'Katoomba DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7053,
    longitude: 150.3076,
    accuracy: 3,
  },
  {
    postcode: 2780,
    place_name: 'Medlow Bath',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6746,
    longitude: 150.2788,
    accuracy: 4,
  },
  {
    postcode: 2780,
    place_name: 'Leura',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7167,
    longitude: 150.3333,
    accuracy: 4,
  },
  {
    postcode: 2782,
    place_name: 'Wentworth Falls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7103,
    longitude: 150.3753,
    accuracy: 4,
  },
  {
    postcode: 2783,
    place_name: 'Lawson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7206,
    longitude: 150.4297,
    accuracy: 4,
  },
  {
    postcode: 2784,
    place_name: 'Bullaburra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7292,
    longitude: 150.4177,
    accuracy: 4,
  },
  {
    postcode: 2785,
    place_name: 'Megalong Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6835,
    longitude: 150.2679,
    accuracy: 3,
  },
  {
    postcode: 2785,
    place_name: 'Blackheath',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6357,
    longitude: 150.2832,
    accuracy: 4,
  },
  {
    postcode: 2785,
    place_name: 'Megalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7333,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 2786,
    place_name: 'Mount Irvine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4849,
    longitude: 150.4612,
    accuracy: 4,
  },
  {
    postcode: 2786,
    place_name: 'Mount Victoria',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.591,
    longitude: 150.2554,
    accuracy: 4,
  },
  {
    postcode: 2786,
    place_name: 'Bell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5167,
    longitude: 150.2,
    accuracy: 4,
  },
  {
    postcode: 2786,
    place_name: 'Mount Wilson',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5035,
    longitude: 150.3749,
    accuracy: 4,
  },
  {
    postcode: 2786,
    place_name: 'Dargan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4896,
    longitude: 150.2504,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Duckmaloi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6799,
    longitude: 149.9642,
    accuracy: 3,
  },
  {
    postcode: 2787,
    place_name: 'Essington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7,
    longitude: 149.7167,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Oberon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.704,
    longitude: 149.8591,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Edith',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8,
    longitude: 149.9167,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Shooters Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9167,
    longitude: 149.8667,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Black Springs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8667,
    longitude: 149.7167,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Mount Werong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 149.8476,
    accuracy: 3,
  },
  {
    postcode: 2787,
    place_name: 'Mayfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8979,
    longitude: 151.7361,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Gurnang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 149.8476,
    accuracy: 3,
  },
  {
    postcode: 2787,
    place_name: 'Tarana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5167,
    longitude: 149.9167,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Hazelgrove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.65,
    longitude: 149.8667,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Porters Retreat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34,
    longitude: 149.8167,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Gingkin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8667,
    longitude: 149.9333,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'Chatham Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 149.8476,
    accuracy: 3,
  },
  {
    postcode: 2787,
    place_name: 'Mozart',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 149.8476,
    accuracy: 3,
  },
  {
    postcode: 2787,
    place_name: 'Norway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 149.8667,
    accuracy: 4,
  },
  {
    postcode: 2787,
    place_name: 'The Meadows',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 149.8476,
    accuracy: 3,
  },
  {
    postcode: 2787,
    place_name: 'Jaunter',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 149.8476,
    accuracy: 3,
  },
  {
    postcode: 2787,
    place_name: 'Kanangra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 149.8476,
    accuracy: 3,
  },
  {
    postcode: 2787,
    place_name: 'Mount Olive',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7787,
    longitude: 149.8476,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Springvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8595,
    longitude: 149.8401,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Littleton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5006,
    longitude: 150.1373,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Little Hartley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5717,
    longitude: 150.2086,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Oaky Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4643,
    longitude: 150.1829,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Hartley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5465,
    longitude: 150.1757,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Hermitage Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4768,
    longitude: 150.1432,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Hartley Vale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5333,
    longitude: 150.2333,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Good Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Bowenfels',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5167,
    longitude: 150.1167,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Clarence',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4723,
    longitude: 150.222,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Lidsdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4,
    longitude: 150.0833,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Kanimbla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Jenolan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 150.0333,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'South Littleton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5006,
    longitude: 150.1373,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Blackmans Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3656,
    longitude: 150.0651,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Hassans Walls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Rydal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4833,
    longitude: 150.0333,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Hampton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.65,
    longitude: 150.05,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Pottery Estate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Wollangambe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Cullen Bullen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2989,
    longitude: 150.0328,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'South Bowenfels',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5167,
    longitude: 150.1167,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Morts Estate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4583,
    longitude: 150.1731,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Newnes Plateau',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Lowther',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6167,
    longitude: 150.1,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Lithgow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4825,
    longitude: 150.1363,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Sodwalls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5167,
    longitude: 149.9833,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'State Mine Gully',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Newnes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1833,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Mount Lambie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Ben Bullen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2167,
    longitude: 150.0333,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Doctors Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Ganbenang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Wolgan Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Vale Of Clwydd',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Corney Town',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Mckellars Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Sheedys Gully',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4245,
    longitude: 150.1024,
    accuracy: 3,
  },
  {
    postcode: 2790,
    place_name: 'Jenolan Caves',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 150.0333,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Marrangaroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4333,
    longitude: 150.1167,
    accuracy: 4,
  },
  {
    postcode: 2790,
    place_name: 'Cobar Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4228,
    longitude: 150.258,
    accuracy: 3,
  },
  {
    postcode: 2791,
    place_name: 'Carcoar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6,
    longitude: 149.1333,
    accuracy: 4,
  },
  {
    postcode: 2791,
    place_name: 'Errowanbang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6,
    longitude: 149.1333,
    accuracy: 3,
  },
  {
    postcode: 2792,
    place_name: 'Mandurama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.65,
    longitude: 149.0833,
    accuracy: 4,
  },
  {
    postcode: 2792,
    place_name: 'Burnt Yards',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5833,
    longitude: 149.0167,
    accuracy: 4,
  },
  {
    postcode: 2793,
    place_name: 'Darbys Falls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9024,
    longitude: 148.856,
    accuracy: 4,
  },
  {
    postcode: 2793,
    place_name: 'Roseberg',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8378,
    longitude: 148.854,
    accuracy: 3,
  },
  {
    postcode: 2793,
    place_name: 'Woodstock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7446,
    longitude: 148.8489,
    accuracy: 4,
  },
  {
    postcode: 2794,
    place_name: 'Noonbinna',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8834,
    longitude: 148.6441,
    accuracy: 4,
  },
  {
    postcode: 2794,
    place_name: 'Westville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 148.8,
    accuracy: 4,
  },
  {
    postcode: 2794,
    place_name: 'Wattamondara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9406,
    longitude: 148.6067,
    accuracy: 4,
  },
  {
    postcode: 2794,
    place_name: 'Cowra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8355,
    longitude: 148.6966,
    accuracy: 4,
  },
  {
    postcode: 2794,
    place_name: 'Hovells Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0485,
    longitude: 148.8846,
    accuracy: 4,
  },
  {
    postcode: 2794,
    place_name: 'Mount Collins',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8976,
    longitude: 148.6717,
    accuracy: 3,
  },
  {
    postcode: 2794,
    place_name: 'Bumbaldry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.9,
    longitude: 148.4667,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Locksley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5142,
    longitude: 149.7982,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Fosters Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Georges Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5,
    longitude: 149.5333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Triangle Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.75,
    longitude: 149.5,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Wimbledon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5333,
    longitude: 149.4333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Laffing Waters',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Windradyne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4054,
    longitude: 149.5451,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Mitchell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4273,
    longitude: 149.5567,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Sunny Corner',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3833,
    longitude: 149.9,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Billywillinga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Meadow Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4333,
    longitude: 149.9333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Copperhannia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Robin Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4229,
    longitude: 149.5328,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Kirkconnell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Peel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3167,
    longitude: 149.6333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Dunkeld',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4106,
    longitude: 149.4808,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'West Bathurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4127,
    longitude: 149.5648,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Jeremy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Glanmire',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4167,
    longitude: 149.7167,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Mount David',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 149.5833,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Crudine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9667,
    longitude: 149.7,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Fitzgeralds Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Yetholme',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.45,
    longitude: 149.8333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Raglan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4167,
    longitude: 149.6667,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Gowan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1833,
    longitude: 149.3667,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Cow Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Burraga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.95,
    longitude: 149.5333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Wisemans Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4708,
    longitude: 149.6033,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Arkell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7284,
    longitude: 149.3909,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Clear Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: "O'Connell",
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5333,
    longitude: 149.7333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Colo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4333,
    longitude: 150.8333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Hobbys Yards',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6941,
    longitude: 149.3274,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Forest Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Trunkey Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8167,
    longitude: 149.3333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Wiagdon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Llanarth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3969,
    longitude: 149.551,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Gemalla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5333,
    longitude: 149.8333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Caloola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6,
    longitude: 149.4333,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Abercrombie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3887,
    longitude: 149.5458,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Palmers Oaky',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2,
    longitude: 149.8667,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Yarras',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4189,
    longitude: 152.3198,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Dog Rocks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Wattle Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.15,
    longitude: 149.7,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Stewarts Mount',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Perthville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4874,
    longitude: 149.5464,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Walang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'The Lagoon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.55,
    longitude: 149.6167,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Arkstone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Isabella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.95,
    longitude: 149.6667,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Bathurst West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Kelso',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4167,
    longitude: 149.6,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Twenty Forests',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Paling Yards',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Ballyroe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4708,
    longitude: 149.6033,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Mount Rankin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2956,
    longitude: 149.4837,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Moorilda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Gilmandyke',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Milkers Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Eglinton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3773,
    longitude: 149.5465,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Millah Murrah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Mount Panorama',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Killongbutta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Bathurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4166,
    longitude: 149.5806,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Watton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Charles Sturt University',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Abercrombie River',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Dark Corner',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3333,
    longitude: 149.8667,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Evans Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Charlton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Limekilns',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Turondale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0833,
    longitude: 149.6167,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'South Bathurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Rockley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7,
    longitude: 149.5667,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Gormans Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Brewongle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4833,
    longitude: 149.7167,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'White Rock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Winburndale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Tannas Mount',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Sofala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0833,
    longitude: 149.7,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Freemantle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4708,
    longitude: 149.6033,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Orton Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4667,
    longitude: 149.5667,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'The Rocks',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8592,
    longitude: 151.2081,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Upper Turon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1,
    longitude: 149.7667,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Judds Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4708,
    longitude: 149.6033,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Bruinbun',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Bald Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4708,
    longitude: 149.6033,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Napoleon Reef',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Bathampton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Newbridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5833,
    longitude: 149.3833,
    accuracy: 4,
  },
  {
    postcode: 2795,
    place_name: 'Rock Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Wambool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4708,
    longitude: 149.6033,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Duramana',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4708,
    longitude: 149.6033,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Curragh',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2795,
    place_name: 'Rockley Mount',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4549,
    longitude: 149.6841,
    accuracy: 3,
  },
  {
    postcode: 2797,
    place_name: 'Garland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7167,
    longitude: 149.0333,
    accuracy: 4,
  },
  {
    postcode: 2797,
    place_name: 'Lyndhurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6733,
    longitude: 149.0477,
    accuracy: 4,
  },
  {
    postcode: 2798,
    place_name: 'Forest Reefs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4645,
    longitude: 149.079,
    accuracy: 4,
  },
  {
    postcode: 2798,
    place_name: 'Tallwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4894,
    longitude: 149.084,
    accuracy: 3,
  },
  {
    postcode: 2798,
    place_name: 'Spring Terrace',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4,
    longitude: 149.1167,
    accuracy: 4,
  },
  {
    postcode: 2798,
    place_name: 'Guyong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4368,
    longitude: 149.127,
    accuracy: 3,
  },
  {
    postcode: 2798,
    place_name: 'Millthorpe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.446,
    longitude: 149.1854,
    accuracy: 4,
  },
  {
    postcode: 2799,
    place_name: 'Neville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7118,
    longitude: 149.2182,
    accuracy: 4,
  },
  {
    postcode: 2799,
    place_name: 'Kings Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5,
    longitude: 149.3333,
    accuracy: 4,
  },
  {
    postcode: 2799,
    place_name: 'Barry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6484,
    longitude: 149.2743,
    accuracy: 4,
  },
  {
    postcode: 2799,
    place_name: 'Blayney',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5323,
    longitude: 149.2537,
    accuracy: 4,
  },
  {
    postcode: 2799,
    place_name: 'Vittoria',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5868,
    longitude: 149.2635,
    accuracy: 3,
  },
  {
    postcode: 2799,
    place_name: 'Fitzgeralds Mount',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5997,
    longitude: 149.2467,
    accuracy: 3,
  },
  {
    postcode: 2799,
    place_name: 'Browns Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5868,
    longitude: 149.2635,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Lucknow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.346,
    longitude: 149.1621,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Nashdale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2833,
    longitude: 149.0333,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Summer Hill Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Huntley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3695,
    longitude: 149.1343,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Canobolas',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3,
    longitude: 149.0667,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Borenore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2346,
    longitude: 148.9815,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Ophir',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1667,
    longitude: 149.2333,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'March',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2789,
    longitude: 149.0298,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Spring Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3988,
    longitude: 149.1525,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Clergate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1833,
    longitude: 149.1333,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Clifton Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2547,
    longitude: 149.156,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Spring Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Lidster',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2833,
    longitude: 149.0167,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Bowen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2789,
    longitude: 149.0298,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Lewis Ponds',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2667,
    longitude: 149.2667,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Windera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Byng',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3476,
    longitude: 149.2551,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Lower Lewis Ponds',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2,
    longitude: 149.25,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Cadia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4485,
    longitude: 148.9846,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Waldegrave',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Mullion Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1252,
    longitude: 149.1276,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Orange',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.284,
    longitude: 149.1002,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Shadforth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4,
    longitude: 149.2333,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Orange East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Four Mile Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4318,
    longitude: 148.9512,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Kerrs Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0505,
    longitude: 149.1004,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Belgravia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1152,
    longitude: 149.0346,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Narrambla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2789,
    longitude: 149.0298,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Emu Swamp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Kangaroobie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Springside',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Panuara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Cargo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4241,
    longitude: 148.809,
    accuracy: 4,
  },
  {
    postcode: 2800,
    place_name: 'Orange DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2968,
    longitude: 149.174,
    accuracy: 3,
  },
  {
    postcode: 2800,
    place_name: 'Boree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.1066,
    longitude: 146.6085,
    accuracy: 4,
  },
  {
    postcode: 2803,
    place_name: 'Crowther',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1167,
    longitude: 148.4833,
    accuracy: 4,
  },
  {
    postcode: 2803,
    place_name: 'Wirrimah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1471,
    longitude: 148.5206,
    accuracy: 3,
  },
  {
    postcode: 2803,
    place_name: 'Bendick Murrell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1667,
    longitude: 148.4333,
    accuracy: 4,
  },
  {
    postcode: 2804,
    place_name: 'Nyrang Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5333,
    longitude: 148.55,
    accuracy: 4,
  },
  {
    postcode: 2804,
    place_name: 'Moorbel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.593,
    longitude: 148.6103,
    accuracy: 3,
  },
  {
    postcode: 2804,
    place_name: 'Canowindra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.5625,
    longitude: 148.6643,
    accuracy: 4,
  },
  {
    postcode: 2804,
    place_name: 'Billimari',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6741,
    longitude: 148.6196,
    accuracy: 4,
  },
  {
    postcode: 2805,
    place_name: 'Gooloogong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6241,
    longitude: 148.409,
    accuracy: 4,
  },
  {
    postcode: 2806,
    place_name: 'Eugowra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4273,
    longitude: 148.3714,
    accuracy: 4,
  },
  {
    postcode: 2807,
    place_name: 'Koorawatha',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.0378,
    longitude: 148.5561,
    accuracy: 4,
  },
  {
    postcode: 2808,
    place_name: 'Wyangala',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.968,
    longitude: 148.9482,
    accuracy: 4,
  },
  {
    postcode: 2809,
    place_name: 'Greenethorpe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34,
    longitude: 148.4,
    accuracy: 4,
  },
  {
    postcode: 2810,
    place_name: 'Pinnacle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8576,
    longitude: 147.9607,
    accuracy: 3,
  },
  {
    postcode: 2810,
    place_name: 'Piney Range',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.85,
    longitude: 147.9167,
    accuracy: 4,
  },
  {
    postcode: 2810,
    place_name: 'Warraderry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7667,
    longitude: 148.2167,
    accuracy: 4,
  },
  {
    postcode: 2810,
    place_name: 'Pullabooka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7452,
    longitude: 147.7893,
    accuracy: 4,
  },
  {
    postcode: 2810,
    place_name: 'Glenelg',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8576,
    longitude: 147.9607,
    accuracy: 3,
  },
  {
    postcode: 2810,
    place_name: 'Caragabal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8333,
    longitude: 147.75,
    accuracy: 4,
  },
  {
    postcode: 2810,
    place_name: 'Bimbi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.05,
    longitude: 147.95,
    accuracy: 4,
  },
  {
    postcode: 2810,
    place_name: 'Grenfell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.8955,
    longitude: 148.1644,
    accuracy: 4,
  },
  {
    postcode: 2818,
    place_name: 'Terrabella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4318,
    longitude: 148.7429,
    accuracy: 4,
  },
  {
    postcode: 2818,
    place_name: 'Benolong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4006,
    longitude: 148.8303,
    accuracy: 3,
  },
  {
    postcode: 2818,
    place_name: 'Ponto',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4006,
    longitude: 148.8303,
    accuracy: 3,
  },
  {
    postcode: 2818,
    place_name: 'Geurie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4006,
    longitude: 148.8303,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Medway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.4916,
    longitude: 150.2823,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Apsley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5985,
    longitude: 148.9679,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Walmer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6667,
    longitude: 148.75,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Gollan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2667,
    longitude: 149.0833,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Bakers Swamp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.781,
    longitude: 148.9153,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Stuart Town',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8,
    longitude: 149.0833,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Maryvale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4735,
    longitude: 148.9012,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Curra Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6985,
    longitude: 148.8179,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Arthurville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5667,
    longitude: 148.75,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Montefiores',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5333,
    longitude: 148.9333,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Bodangora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.45,
    longitude: 149,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Neurea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.69,
    longitude: 148.9489,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Dripstone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.65,
    longitude: 149,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Mount Arthur',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6003,
    longitude: 148.9534,
    accuracy: 3,
  },
  {
    postcode: 2820,
    place_name: 'Mumbil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7167,
    longitude: 149.05,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Wuuluman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.55,
    longitude: 149.0833,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Wellington',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5559,
    longitude: 148.9451,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Spicers Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3958,
    longitude: 149.143,
    accuracy: 3,
  },
  {
    postcode: 2820,
    place_name: 'Farnham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.85,
    longitude: 149.0833,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Suntop',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5652,
    longitude: 148.8346,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Comobella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4,
    longitude: 148.9667,
    accuracy: 4,
  },
  {
    postcode: 2820,
    place_name: 'Lake Burrendong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6003,
    longitude: 148.9534,
    accuracy: 3,
  },
  {
    postcode: 2820,
    place_name: 'Mount Aquila',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6003,
    longitude: 148.9534,
    accuracy: 3,
  },
  {
    postcode: 2820,
    place_name: 'Mookerawa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6003,
    longitude: 148.9534,
    accuracy: 3,
  },
  {
    postcode: 2820,
    place_name: 'Yarragal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6003,
    longitude: 148.9534,
    accuracy: 3,
  },
  {
    postcode: 2821,
    place_name: 'Narromine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2312,
    longitude: 148.2405,
    accuracy: 4,
  },
  {
    postcode: 2821,
    place_name: 'Burroway',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2312,
    longitude: 148.2405,
    accuracy: 3,
  },
  {
    postcode: 2823,
    place_name: 'Trangie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0315,
    longitude: 147.9834,
    accuracy: 4,
  },
  {
    postcode: 2823,
    place_name: 'Cathundral',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9167,
    longitude: 147.8333,
    accuracy: 4,
  },
  {
    postcode: 2823,
    place_name: 'Dandaloo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2833,
    longitude: 147.6333,
    accuracy: 4,
  },
  {
    postcode: 2823,
    place_name: 'Gin Gin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9116,
    longitude: 148.0898,
    accuracy: 4,
  },
  {
    postcode: 2823,
    place_name: 'Bundemar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0772,
    longitude: 147.8167,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Mount Foster',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Warren',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7022,
    longitude: 147.8339,
    accuracy: 4,
  },
  {
    postcode: 2824,
    place_name: 'Mount Harris',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Snakes Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Eenaweena',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Tenandra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Marthaguy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Ravenswood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6971,
    longitude: 147.84,
    accuracy: 4,
  },
  {
    postcode: 2824,
    place_name: 'Red Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.0523,
    longitude: 149.1643,
    accuracy: 4,
  },
  {
    postcode: 2824,
    place_name: 'Beemunnel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6624,
    longitude: 147.8596,
    accuracy: 4,
  },
  {
    postcode: 2824,
    place_name: 'Bullagreen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Pine Clump',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Pigeonbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Mumblebone Plain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2824,
    place_name: 'Oxley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6887,
    longitude: 147.8413,
    accuracy: 3,
  },
  {
    postcode: 2825,
    place_name: 'Nyngan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5609,
    longitude: 147.1882,
    accuracy: 4,
  },
  {
    postcode: 2825,
    place_name: 'Mulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.608,
    longitude: 147.1822,
    accuracy: 3,
  },
  {
    postcode: 2825,
    place_name: 'Miandetta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5833,
    longitude: 146.9667,
    accuracy: 4,
  },
  {
    postcode: 2825,
    place_name: 'Bogan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6046,
    longitude: 147.1405,
    accuracy: 3,
  },
  {
    postcode: 2825,
    place_name: 'Mullengudgery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6985,
    longitude: 147.4179,
    accuracy: 4,
  },
  {
    postcode: 2825,
    place_name: 'Honeybugle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.608,
    longitude: 147.1822,
    accuracy: 3,
  },
  {
    postcode: 2825,
    place_name: 'Canonba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3447,
    longitude: 147.3473,
    accuracy: 4,
  },
  {
    postcode: 2825,
    place_name: 'Pangee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.608,
    longitude: 147.1822,
    accuracy: 3,
  },
  {
    postcode: 2825,
    place_name: 'Murrawombie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.608,
    longitude: 147.1822,
    accuracy: 3,
  },
  {
    postcode: 2825,
    place_name: 'Babinda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.608,
    longitude: 147.1822,
    accuracy: 3,
  },
  {
    postcode: 2825,
    place_name: 'Buddabadah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.608,
    longitude: 147.1822,
    accuracy: 3,
  },
  {
    postcode: 2826,
    place_name: 'Nevertire',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8478,
    longitude: 147.7215,
    accuracy: 4,
  },
  {
    postcode: 2826,
    place_name: 'Bogan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.85,
    longitude: 147.7167,
    accuracy: 3,
  },
  {
    postcode: 2827,
    place_name: 'Biddon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5602,
    longitude: 148.8023,
    accuracy: 4,
  },
  {
    postcode: 2827,
    place_name: 'Collie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.6667,
    longitude: 148.3,
    accuracy: 4,
  },
  {
    postcode: 2827,
    place_name: 'Gilgandra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7117,
    longitude: 148.6625,
    accuracy: 4,
  },
  {
    postcode: 2827,
    place_name: 'Curban',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5418,
    longitude: 148.5887,
    accuracy: 4,
  },
  {
    postcode: 2827,
    place_name: 'Bearbong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.659,
    longitude: 148.6258,
    accuracy: 3,
  },
  {
    postcode: 2827,
    place_name: 'Breelong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8152,
    longitude: 148.8012,
    accuracy: 4,
  },
  {
    postcode: 2827,
    place_name: 'Merrigal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.659,
    longitude: 148.6258,
    accuracy: 3,
  },
  {
    postcode: 2828,
    place_name: 'Armatree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.446,
    longitude: 148.4748,
    accuracy: 4,
  },
  {
    postcode: 2828,
    place_name: 'Tonderburine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3843,
    longitude: 148.4696,
    accuracy: 3,
  },
  {
    postcode: 2828,
    place_name: 'Black Hollow',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3843,
    longitude: 148.4696,
    accuracy: 3,
  },
  {
    postcode: 2828,
    place_name: 'Bourbah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3843,
    longitude: 148.4696,
    accuracy: 3,
  },
  {
    postcode: 2828,
    place_name: 'Warrumbungle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3178,
    longitude: 148.6229,
    accuracy: 3,
  },
  {
    postcode: 2828,
    place_name: 'Gulargambone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3353,
    longitude: 148.4725,
    accuracy: 4,
  },
  {
    postcode: 2828,
    place_name: 'Quanda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3843,
    longitude: 148.4696,
    accuracy: 3,
  },
  {
    postcode: 2828,
    place_name: 'Mount Tenandra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.3843,
    longitude: 148.4696,
    accuracy: 3,
  },
  {
    postcode: 2829,
    place_name: 'Gilgooma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7557,
    longitude: 148.5391,
    accuracy: 4,
  },
  {
    postcode: 2829,
    place_name: 'Gungalman',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8774,
    longitude: 148.5111,
    accuracy: 3,
  },
  {
    postcode: 2829,
    place_name: 'Pine Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8774,
    longitude: 148.5111,
    accuracy: 3,
  },
  {
    postcode: 2829,
    place_name: 'Combara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.1168,
    longitude: 148.3705,
    accuracy: 4,
  },
  {
    postcode: 2829,
    place_name: 'Nebea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8774,
    longitude: 148.5111,
    accuracy: 3,
  },
  {
    postcode: 2829,
    place_name: 'Teridgerie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8667,
    longitude: 148.8333,
    accuracy: 4,
  },
  {
    postcode: 2829,
    place_name: 'Billeroy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.7,
    longitude: 148.45,
    accuracy: 4,
  },
  {
    postcode: 2829,
    place_name: 'Tooloon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8774,
    longitude: 148.5111,
    accuracy: 3,
  },
  {
    postcode: 2829,
    place_name: 'Magometon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8774,
    longitude: 148.5111,
    accuracy: 3,
  },
  {
    postcode: 2829,
    place_name: 'Conimbia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8774,
    longitude: 148.5111,
    accuracy: 3,
  },
  {
    postcode: 2829,
    place_name: 'Coonamble',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9539,
    longitude: 148.3888,
    accuracy: 4,
  },
  {
    postcode: 2829,
    place_name: 'Urawilkie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8774,
    longitude: 148.5111,
    accuracy: 3,
  },
  {
    postcode: 2829,
    place_name: 'Wingadee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8774,
    longitude: 148.5111,
    accuracy: 3,
  },
  {
    postcode: 2830,
    place_name: 'Dubbo West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1582,
    longitude: 148.6012,
    accuracy: 3,
  },
  {
    postcode: 2830,
    place_name: 'Minore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.25,
    longitude: 148.4667,
    accuracy: 4,
  },
  {
    postcode: 2830,
    place_name: 'Mogriguy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0667,
    longitude: 148.6833,
    accuracy: 4,
  },
  {
    postcode: 2830,
    place_name: 'Brocklehurst',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1806,
    longitude: 148.6229,
    accuracy: 4,
  },
  {
    postcode: 2830,
    place_name: 'Ballimore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2,
    longitude: 148.9,
    accuracy: 4,
  },
  {
    postcode: 2830,
    place_name: 'Muronbung',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1639,
    longitude: 148.642,
    accuracy: 3,
  },
  {
    postcode: 2830,
    place_name: 'Terramungamine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0667,
    longitude: 148.55,
    accuracy: 4,
  },
  {
    postcode: 2830,
    place_name: 'Goonoo Forest',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1582,
    longitude: 148.6012,
    accuracy: 3,
  },
  {
    postcode: 2830,
    place_name: 'Rawsonville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1667,
    longitude: 148.5,
    accuracy: 4,
  },
  {
    postcode: 2830,
    place_name: 'Dubbo Grove',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1582,
    longitude: 148.6012,
    accuracy: 3,
  },
  {
    postcode: 2830,
    place_name: 'Dubbo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.243,
    longitude: 148.6048,
    accuracy: 4,
  },
  {
    postcode: 2830,
    place_name: 'Dubbo East',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1582,
    longitude: 148.6012,
    accuracy: 3,
  },
  {
    postcode: 2830,
    place_name: 'Kickabil',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8,
    longitude: 148.4833,
    accuracy: 4,
  },
  {
    postcode: 2830,
    place_name: 'Dubbo DC',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1582,
    longitude: 148.6012,
    accuracy: 3,
  },
  {
    postcode: 2830,
    place_name: 'Toongi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.45,
    longitude: 148.6,
    accuracy: 4,
  },
  {
    postcode: 2830,
    place_name: 'Wambangalang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1582,
    longitude: 148.6012,
    accuracy: 3,
  },
  {
    postcode: 2831,
    place_name: 'Wongarbon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3328,
    longitude: 148.7593,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Goodooga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1131,
    longitude: 147.4525,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Neilrex',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.7226,
    longitude: 149.3043,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Girilambone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4472,
    longitude: 146.3418,
    accuracy: 3,
  },
  {
    postcode: 2831,
    place_name: 'Quambone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9331,
    longitude: 147.8722,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'The Marra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5488,
    longitude: 148.1209,
    accuracy: 3,
  },
  {
    postcode: 2831,
    place_name: 'Merrygoen',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8279,
    longitude: 149.2326,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Hermidale',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4472,
    longitude: 146.3418,
    accuracy: 3,
  },
  {
    postcode: 2831,
    place_name: 'Armatree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.446,
    longitude: 148.4748,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Nevertire',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8478,
    longitude: 147.7215,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Elong Elong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1235,
    longitude: 149.0262,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Macquarie Marshes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5488,
    longitude: 148.1209,
    accuracy: 3,
  },
  {
    postcode: 2831,
    place_name: 'Balladoran',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.865,
    longitude: 148.6521,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Tooraweenah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4333,
    longitude: 148.9,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Nymagee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0667,
    longitude: 146.3333,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Carinda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.4667,
    longitude: 147.6833,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Eumungerie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.95,
    longitude: 148.6215,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Byrock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.6606,
    longitude: 146.4012,
    accuracy: 4,
  },
  {
    postcode: 2831,
    place_name: 'Coolabah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.0295,
    longitude: 146.7149,
    accuracy: 4,
  },
  {
    postcode: 2832,
    place_name: 'Come By Chance',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3726,
    longitude: 148.4874,
    accuracy: 4,
  },
  {
    postcode: 2832,
    place_name: 'Cryon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0086,
    longitude: 148.6135,
    accuracy: 4,
  },
  {
    postcode: 2832,
    place_name: 'Cumborah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7469,
    longitude: 147.7664,
    accuracy: 4,
  },
  {
    postcode: 2832,
    place_name: 'Walgett',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0246,
    longitude: 148.1157,
    accuracy: 4,
  },
  {
    postcode: 2833,
    place_name: 'Collarenebri',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.5438,
    longitude: 148.5759,
    accuracy: 4,
  },
  {
    postcode: 2834,
    place_name: 'Angledool',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1102,
    longitude: 147.9097,
    accuracy: 4,
  },
  {
    postcode: 2834,
    place_name: 'Lightning Ridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4274,
    longitude: 147.9787,
    accuracy: 4,
  },
  {
    postcode: 2835,
    place_name: 'Cobar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.4987,
    longitude: 145.8418,
    accuracy: 4,
  },
  {
    postcode: 2835,
    place_name: 'Bulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8274,
    longitude: 146.0639,
    accuracy: 3,
  },
  {
    postcode: 2835,
    place_name: 'Irymple',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4905,
    longitude: 145.6074,
    accuracy: 4,
  },
  {
    postcode: 2835,
    place_name: 'Gilgunnia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4134,
    longitude: 146.0379,
    accuracy: 4,
  },
  {
    postcode: 2835,
    place_name: 'Canbelego',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5577,
    longitude: 146.3216,
    accuracy: 4,
  },
  {
    postcode: 2835,
    place_name: 'Kerrigundi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8274,
    longitude: 146.0639,
    accuracy: 3,
  },
  {
    postcode: 2835,
    place_name: 'Tindarey',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8274,
    longitude: 146.0639,
    accuracy: 3,
  },
  {
    postcode: 2835,
    place_name: 'Noona',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8274,
    longitude: 146.0639,
    accuracy: 3,
  },
  {
    postcode: 2835,
    place_name: 'Cubba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8274,
    longitude: 146.0639,
    accuracy: 3,
  },
  {
    postcode: 2835,
    place_name: 'Kulwin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8274,
    longitude: 146.0639,
    accuracy: 3,
  },
  {
    postcode: 2835,
    place_name: 'Sandy Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8274,
    longitude: 146.0639,
    accuracy: 3,
  },
  {
    postcode: 2836,
    place_name: 'White Cliffs',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.8521,
    longitude: 143.0886,
    accuracy: 4,
  },
  {
    postcode: 2836,
    place_name: 'Wilcannia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5588,
    longitude: 143.3769,
    accuracy: 4,
  },
  {
    postcode: 2838,
    place_name: 'Goodooga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.1131,
    longitude: 147.4525,
    accuracy: 4,
  },
  {
    postcode: 2839,
    place_name: 'Narran Lake',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.998,
    longitude: 146.8033,
    accuracy: 3,
  },
  {
    postcode: 2839,
    place_name: 'Collerina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.6915,
    longitude: 146.6479,
    accuracy: 4,
  },
  {
    postcode: 2839,
    place_name: 'Talawanta',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.998,
    longitude: 146.8033,
    accuracy: 3,
  },
  {
    postcode: 2839,
    place_name: 'Gongolgon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.3488,
    longitude: 146.8979,
    accuracy: 4,
  },
  {
    postcode: 2839,
    place_name: 'Weilmoringle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.2428,
    longitude: 146.9219,
    accuracy: 4,
  },
  {
    postcode: 2839,
    place_name: 'Brewarrina',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.9607,
    longitude: 146.8599,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Enngonia',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3223,
    longitude: 145.8471,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Hungerford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.482,
    longitude: 150.4871,
    accuracy: 3,
  },
  {
    postcode: 2840,
    place_name: 'Louth',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.5388,
    longitude: 145.116,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Bourke',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0901,
    longitude: 145.9365,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Yantabulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.3457,
    longitude: 145.0012,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Barringun',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.0141,
    longitude: 145.7159,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Fords Bridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7515,
    longitude: 145.4273,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'North Bourke',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.0523,
    longitude: 145.9489,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Wanaaring',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7027,
    longitude: 144.1235,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Urisino',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7252,
    longitude: 143.7619,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Gunderbooka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.07,
    longitude: 145.9477,
    accuracy: 3,
  },
  {
    postcode: 2840,
    place_name: 'Tilpa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.9356,
    longitude: 144.4165,
    accuracy: 4,
  },
  {
    postcode: 2840,
    place_name: 'Gumbalie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -30.07,
    longitude: 145.9477,
    accuracy: 3,
  },
  {
    postcode: 2842,
    place_name: 'Mendooran',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8226,
    longitude: 149.1164,
    accuracy: 4,
  },
  {
    postcode: 2842,
    place_name: 'Yarragrin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8226,
    longitude: 149.1164,
    accuracy: 3,
  },
  {
    postcode: 2842,
    place_name: 'New Mollyan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8262,
    longitude: 149.1256,
    accuracy: 3,
  },
  {
    postcode: 2842,
    place_name: 'Mollyan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8226,
    longitude: 149.1164,
    accuracy: 3,
  },
  {
    postcode: 2843,
    place_name: 'Coolah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.8279,
    longitude: 149.7156,
    accuracy: 4,
  },
  {
    postcode: 2844,
    place_name: 'Leadville',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32,
    longitude: 149.55,
    accuracy: 4,
  },
  {
    postcode: 2844,
    place_name: 'Birriwa',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1167,
    longitude: 149.4667,
    accuracy: 4,
  },
  {
    postcode: 2844,
    place_name: 'Cobbora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0667,
    longitude: 149.25,
    accuracy: 4,
  },
  {
    postcode: 2844,
    place_name: 'Dunedoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.0163,
    longitude: 149.3871,
    accuracy: 4,
  },
  {
    postcode: 2845,
    place_name: 'Wallerawang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.411,
    longitude: 150.0646,
    accuracy: 4,
  },
  {
    postcode: 2846,
    place_name: 'Glen Davis',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1167,
    longitude: 150.2833,
    accuracy: 4,
  },
  {
    postcode: 2846,
    place_name: 'Round Swamp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0735,
    longitude: 149.9429,
    accuracy: 4,
  },
  {
    postcode: 2846,
    place_name: 'Capertee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.15,
    longitude: 149.9833,
    accuracy: 4,
  },
  {
    postcode: 2847,
    place_name: 'Pipers Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4436,
    longitude: 149.9763,
    accuracy: 3,
  },
  {
    postcode: 2847,
    place_name: 'Portland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3571,
    longitude: 149.9815,
    accuracy: 4,
  },
  {
    postcode: 2848,
    place_name: 'Clandulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8833,
    longitude: 149.9667,
    accuracy: 4,
  },
  {
    postcode: 2848,
    place_name: 'Brogans Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33,
    longitude: 149.9333,
    accuracy: 4,
  },
  {
    postcode: 2848,
    place_name: 'Kandos',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8577,
    longitude: 149.9683,
    accuracy: 4,
  },
  {
    postcode: 2848,
    place_name: 'Charbon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8839,
    longitude: 149.9701,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Upper Bylong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4119,
    longitude: 150.1201,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Bylong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4119,
    longitude: 150.1201,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Budden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5152,
    longitude: 150.0679,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Lee Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Ginghi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5818,
    longitude: 150.0846,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Coggan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Bogee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9488,
    longitude: 150.0938,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Dungeree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Camboon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6985,
    longitude: 149.9512,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Wirraba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Breakfast Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6818,
    longitude: 150.0012,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Mount Marsden',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Rylstone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7995,
    longitude: 149.9694,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Upper Nile',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Olinda',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8333,
    longitude: 150.1333,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Kelgoola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Pinnacle Swamp',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Coxs Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Dunville Loop',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Coxs Crown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Murrumbo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Dabee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Growee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Pyangle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Glen Alice',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.05,
    longitude: 150.2167,
    accuracy: 4,
  },
  {
    postcode: 2849,
    place_name: 'Reedy Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Nullo Mountain',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Upper Growee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2849,
    place_name: 'Carwell',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6562,
    longitude: 150.0774,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Mogo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.7848,
    longitude: 150.1417,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Turill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.1152,
    longitude: 149.8429,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Lue',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6667,
    longitude: 149.85,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Wilbetree',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6849,
    longitude: 149.8056,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Bombira',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5719,
    longitude: 149.6058,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Stony Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4735,
    longitude: 149.7346,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Apple Tree Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6818,
    longitude: 149.7012,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Ulan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2833,
    longitude: 149.7333,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Wollar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.35,
    longitude: 149.95,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Twelve Mile',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5,
    longitude: 149.2667,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Bara',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Hayes Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Mudgee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5943,
    longitude: 149.5871,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Windeyer',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7667,
    longitude: 149.55,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Pyramul',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8833,
    longitude: 149.6167,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Cross Roads',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Cooyal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4485,
    longitude: 149.7596,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Running Stream',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0318,
    longitude: 149.9096,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Caerleon',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Home Rule',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4167,
    longitude: 149.6333,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Collingwood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Sallys Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33,
    longitude: 149.5833,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Aarons Pass',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Ullamalla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Avisford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7318,
    longitude: 149.4846,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Ilford',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9667,
    longitude: 149.8667,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Putta Bucca',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5753,
    longitude: 149.5848,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Mullamuddy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Budgee Budgee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5318,
    longitude: 149.6679,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Erudgere',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Hargraves',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7866,
    longitude: 149.4579,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Maitland Bar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Barigan',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Piambong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4985,
    longitude: 149.3846,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Menah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5494,
    longitude: 149.5432,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Milroy',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.15,
    longitude: 150.05,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Mount Frome',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6,
    longitude: 149.6333,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Munghorn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.45,
    longitude: 149.8,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Yarrawonga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4514,
    longitude: 150.5858,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Canadian Lead',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Tambaroora',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33,
    longitude: 149.4333,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Meroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.8333,
    longitude: 150.6167,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Cullenbone',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5,
    longitude: 149.5167,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Eurunderee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Buckaroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.55,
    longitude: 149.6333,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Triamble',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8833,
    longitude: 149.3,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Grattai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6833,
    longitude: 149.4833,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Monivae',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Bocoble',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Spring Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Linburn',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4235,
    longitude: 149.7179,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Worlds End',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Kains Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Tichular',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Yarrabin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Galambine',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Cudgegong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8,
    longitude: 149.8167,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'St Fillans',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Mount Knowles',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6152,
    longitude: 149.7179,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Carcalgong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Hill End',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0333,
    longitude: 149.4167,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Cumbo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Moolarben',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3485,
    longitude: 149.7762,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Green Gully',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Havilah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6187,
    longitude: 149.765,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Queens Pinch',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Riverlea',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Frog Rock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Wilpinjong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3318,
    longitude: 149.8846,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Botobolar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5167,
    longitude: 149.7833,
    accuracy: 4,
  },
  {
    postcode: 2850,
    place_name: 'Burrundulla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Totnes Valley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6278,
    longitude: 149.652,
    accuracy: 3,
  },
  {
    postcode: 2850,
    place_name: 'Cooks Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3557,
    longitude: 149.7117,
    accuracy: 4,
  },
  {
    postcode: 2852,
    place_name: 'Stubbo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3,
    longitude: 149.5333,
    accuracy: 4,
  },
  {
    postcode: 2852,
    place_name: 'Bungaba',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3453,
    longitude: 149.4269,
    accuracy: 3,
  },
  {
    postcode: 2852,
    place_name: 'Guntawang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3985,
    longitude: 149.4846,
    accuracy: 4,
  },
  {
    postcode: 2852,
    place_name: 'Two Mile Flat',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.4068,
    longitude: 149.3596,
    accuracy: 4,
  },
  {
    postcode: 2852,
    place_name: 'Beryl',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3485,
    longitude: 149.4429,
    accuracy: 4,
  },
  {
    postcode: 2852,
    place_name: 'Barneys Reef',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3453,
    longitude: 149.4269,
    accuracy: 3,
  },
  {
    postcode: 2852,
    place_name: 'Mebul',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3485,
    longitude: 149.3346,
    accuracy: 4,
  },
  {
    postcode: 2852,
    place_name: 'Gulgong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3625,
    longitude: 149.532,
    accuracy: 4,
  },
  {
    postcode: 2852,
    place_name: 'Tallawang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2,
    longitude: 149.45,
    accuracy: 4,
  },
  {
    postcode: 2852,
    place_name: 'Cope',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3453,
    longitude: 149.4269,
    accuracy: 3,
  },
  {
    postcode: 2852,
    place_name: 'Goolma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3818,
    longitude: 149.2762,
    accuracy: 4,
  },
  {
    postcode: 2852,
    place_name: 'Cumbandry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3453,
    longitude: 149.4269,
    accuracy: 3,
  },
  {
    postcode: 2852,
    place_name: 'Merotherie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3453,
    longitude: 149.4269,
    accuracy: 3,
  },
  {
    postcode: 2864,
    place_name: 'Murga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3688,
    longitude: 148.5511,
    accuracy: 4,
  },
  {
    postcode: 2864,
    place_name: 'Cudal',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2856,
    longitude: 148.7407,
    accuracy: 4,
  },
  {
    postcode: 2864,
    place_name: 'Bowan Park',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3,
    longitude: 148.8167,
    accuracy: 4,
  },
  {
    postcode: 2864,
    place_name: 'Toogong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3333,
    longitude: 148.6333,
    accuracy: 4,
  },
  {
    postcode: 2865,
    place_name: 'Gumble',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0818,
    longitude: 148.6512,
    accuracy: 4,
  },
  {
    postcode: 2865,
    place_name: 'Bocobra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1329,
    longitude: 148.6695,
    accuracy: 3,
  },
  {
    postcode: 2865,
    place_name: 'Manildra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1825,
    longitude: 148.6891,
    accuracy: 4,
  },
  {
    postcode: 2866,
    place_name: 'Amaroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1833,
    longitude: 148.9333,
    accuracy: 4,
  },
  {
    postcode: 2866,
    place_name: 'Boomey',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0618,
    longitude: 148.9073,
    accuracy: 3,
  },
  {
    postcode: 2866,
    place_name: 'Molong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0923,
    longitude: 148.87,
    accuracy: 4,
  },
  {
    postcode: 2866,
    place_name: 'Euchareena',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9167,
    longitude: 149.1167,
    accuracy: 4,
  },
  {
    postcode: 2866,
    place_name: 'Garra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1167,
    longitude: 148.7667,
    accuracy: 4,
  },
  {
    postcode: 2866,
    place_name: 'Larras Lee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33,
    longitude: 148.85,
    accuracy: 4,
  },
  {
    postcode: 2866,
    place_name: 'Cundumbul',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0618,
    longitude: 148.9073,
    accuracy: 3,
  },
  {
    postcode: 2867,
    place_name: 'Baldry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8667,
    longitude: 148.5,
    accuracy: 4,
  },
  {
    postcode: 2867,
    place_name: 'Loombah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.888,
    longitude: 148.7016,
    accuracy: 3,
  },
  {
    postcode: 2867,
    place_name: 'Cumnock',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9306,
    longitude: 148.7549,
    accuracy: 4,
  },
  {
    postcode: 2867,
    place_name: 'Yullundry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.888,
    longitude: 148.7016,
    accuracy: 3,
  },
  {
    postcode: 2867,
    place_name: 'Eurimbla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8463,
    longitude: 148.8435,
    accuracy: 4,
  },
  {
    postcode: 2868,
    place_name: 'Obley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7,
    longitude: 148.5667,
    accuracy: 4,
  },
  {
    postcode: 2868,
    place_name: 'North Yeoval',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7271,
    longitude: 148.6074,
    accuracy: 3,
  },
  {
    postcode: 2868,
    place_name: 'Yeoval',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7542,
    longitude: 148.6481,
    accuracy: 4,
  },
  {
    postcode: 2868,
    place_name: 'Bournewood',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7271,
    longitude: 148.6074,
    accuracy: 3,
  },
  {
    postcode: 2869,
    place_name: 'Trewilga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7866,
    longitude: 148.2298,
    accuracy: 4,
  },
  {
    postcode: 2869,
    place_name: 'Tomingley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5667,
    longitude: 148.2333,
    accuracy: 4,
  },
  {
    postcode: 2869,
    place_name: 'Peak Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7239,
    longitude: 148.1905,
    accuracy: 4,
  },
  {
    postcode: 2870,
    place_name: 'Goonumbla',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33,
    longitude: 148.1333,
    accuracy: 4,
  },
  {
    postcode: 2870,
    place_name: 'Cooks Myalls',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1546,
    longitude: 148.2428,
    accuracy: 3,
  },
  {
    postcode: 2870,
    place_name: 'Cookamidgera',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2,
    longitude: 148.3,
    accuracy: 4,
  },
  {
    postcode: 2870,
    place_name: 'Alectown',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9333,
    longitude: 148.25,
    accuracy: 4,
  },
  {
    postcode: 2870,
    place_name: 'Mandagery',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2167,
    longitude: 148.4167,
    accuracy: 4,
  },
  {
    postcode: 2870,
    place_name: 'Daroobalgie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3333,
    longitude: 148.0667,
    accuracy: 4,
  },
  {
    postcode: 2870,
    place_name: 'Parkes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1372,
    longitude: 148.1759,
    accuracy: 4,
  },
  {
    postcode: 2870,
    place_name: 'Bumberry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2,
    longitude: 148.4833,
    accuracy: 4,
  },
  {
    postcode: 2870,
    place_name: 'Tichborne',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2167,
    longitude: 148.1167,
    accuracy: 4,
  },
  {
    postcode: 2871,
    place_name: 'Bedgerebong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.35,
    longitude: 147.7,
    accuracy: 4,
  },
  {
    postcode: 2871,
    place_name: 'Forbes',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3844,
    longitude: 148.0076,
    accuracy: 4,
  },
  {
    postcode: 2871,
    place_name: 'Ooma',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.7233,
    longitude: 148.0891,
    accuracy: 4,
  },
  {
    postcode: 2871,
    place_name: 'Bedgerabong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4692,
    longitude: 147.7868,
    accuracy: 3,
  },
  {
    postcode: 2871,
    place_name: 'Fairholme',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2652,
    longitude: 147.3846,
    accuracy: 4,
  },
  {
    postcode: 2871,
    place_name: 'Warroo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.3333,
    longitude: 147.5833,
    accuracy: 4,
  },
  {
    postcode: 2871,
    place_name: 'Paytens Bridge',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4692,
    longitude: 147.7868,
    accuracy: 3,
  },
  {
    postcode: 2871,
    place_name: 'Gunning Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4692,
    longitude: 147.7868,
    accuracy: 3,
  },
  {
    postcode: 2871,
    place_name: 'Wirrinya',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.6667,
    longitude: 147.8167,
    accuracy: 4,
  },
  {
    postcode: 2871,
    place_name: 'Corinella',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4692,
    longitude: 147.7868,
    accuracy: 3,
  },
  {
    postcode: 2871,
    place_name: 'Jemalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4692,
    longitude: 147.7868,
    accuracy: 3,
  },
  {
    postcode: 2871,
    place_name: 'Mulyandry',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.4692,
    longitude: 147.7868,
    accuracy: 3,
  },
  {
    postcode: 2871,
    place_name: 'Garema',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.55,
    longitude: 147.9333,
    accuracy: 4,
  },
  {
    postcode: 2873,
    place_name: 'Albert',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.35,
    longitude: 147.5,
    accuracy: 4,
  },
  {
    postcode: 2873,
    place_name: 'Tottenham',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2431,
    longitude: 147.3559,
    accuracy: 4,
  },
  {
    postcode: 2873,
    place_name: 'Miamley',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2966,
    longitude: 147.428,
    accuracy: 3,
  },
  {
    postcode: 2873,
    place_name: 'Five Ways',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2966,
    longitude: 147.428,
    accuracy: 3,
  },
  {
    postcode: 2874,
    place_name: 'Tullamore',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6311,
    longitude: 147.5661,
    accuracy: 4,
  },
  {
    postcode: 2875,
    place_name: 'Bruie Plains',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7833,
    longitude: 147.85,
    accuracy: 4,
  },
  {
    postcode: 2875,
    place_name: 'Trundle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9248,
    longitude: 147.7105,
    accuracy: 4,
  },
  {
    postcode: 2875,
    place_name: 'Yarrabandai',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.128,
    longitude: 147.579,
    accuracy: 4,
  },
  {
    postcode: 2875,
    place_name: 'Ootha',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1167,
    longitude: 147.45,
    accuracy: 4,
  },
  {
    postcode: 2875,
    place_name: 'Fifield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.7954,
    longitude: 147.4656,
    accuracy: 4,
  },
  {
    postcode: 2876,
    place_name: 'Bogan Gate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1167,
    longitude: 147.8167,
    accuracy: 4,
  },
  {
    postcode: 2876,
    place_name: 'Nelungaloo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1333,
    longitude: 148,
    accuracy: 4,
  },
  {
    postcode: 2876,
    place_name: 'Gunningbland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.1193,
    longitude: 147.9146,
    accuracy: 4,
  },
  {
    postcode: 2877,
    place_name: 'Bobadah',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.519,
    longitude: 146.7503,
    accuracy: 4,
  },
  {
    postcode: 2877,
    place_name: 'Euabalong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0574,
    longitude: 146.3957,
    accuracy: 4,
  },
  {
    postcode: 2877,
    place_name: 'Eremerang',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8447,
    longitude: 146.7229,
    accuracy: 3,
  },
  {
    postcode: 2877,
    place_name: 'Condobolin',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0881,
    longitude: 147.1451,
    accuracy: 4,
  },
  {
    postcode: 2877,
    place_name: 'Mulguthrie',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.8447,
    longitude: 146.7229,
    accuracy: 3,
  },
  {
    postcode: 2877,
    place_name: 'Boona Mount',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6333,
    longitude: 147.1667,
    accuracy: 4,
  },
  {
    postcode: 2877,
    place_name: 'Mount Hope',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.839,
    longitude: 145.8806,
    accuracy: 4,
  },
  {
    postcode: 2877,
    place_name: 'Euabalong West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0574,
    longitude: 146.3957,
    accuracy: 4,
  },
  {
    postcode: 2877,
    place_name: 'Derriwong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0985,
    longitude: 147.3346,
    accuracy: 4,
  },
  {
    postcode: 2877,
    place_name: 'Kiacatoo',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0652,
    longitude: 146.8179,
    accuracy: 4,
  },
  {
    postcode: 2878,
    place_name: 'Ivanhoe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.9004,
    longitude: 144.3015,
    accuracy: 4,
  },
  {
    postcode: 2878,
    place_name: 'Trida',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.0185,
    longitude: 145.0086,
    accuracy: 4,
  },
  {
    postcode: 2878,
    place_name: 'Mossgiel',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -33.2495,
    longitude: 144.5657,
    accuracy: 4,
  },
  {
    postcode: 2878,
    place_name: 'Conoble',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.6986,
    longitude: 144.1619,
    accuracy: 3,
  },
  {
    postcode: 2879,
    place_name: 'Menindee',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.3929,
    longitude: 142.4182,
    accuracy: 4,
  },
  {
    postcode: 2879,
    place_name: 'Sunset Strip',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.2652,
    longitude: 142.3012,
    accuracy: 4,
  },
  {
    postcode: 2880,
    place_name: 'Broken Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9652,
    longitude: 141.4512,
    accuracy: 4,
  },
  {
    postcode: 2880,
    place_name: 'Broken Hill West',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5166,
    longitude: 148.6724,
    accuracy: 3,
  },
  {
    postcode: 2880,
    place_name: 'Milparinka',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.7345,
    longitude: 141.8849,
    accuracy: 4,
  },
  {
    postcode: 2880,
    place_name: 'Broken Hill North',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5166,
    longitude: 148.6724,
    accuracy: 3,
  },
  {
    postcode: 2880,
    place_name: 'South Broken Hill',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.9652,
    longitude: 141.4512,
    accuracy: 4,
  },
  {
    postcode: 2880,
    place_name: 'Silverton',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.884,
    longitude: 141.2233,
    accuracy: 4,
  },
  {
    postcode: 2880,
    place_name: 'Packsaddle',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5166,
    longitude: 148.6724,
    accuracy: 3,
  },
  {
    postcode: 2880,
    place_name: 'Broughams Gate',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5166,
    longitude: 148.6724,
    accuracy: 3,
  },
  {
    postcode: 2880,
    place_name: 'Mutawintji',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5166,
    longitude: 148.6724,
    accuracy: 3,
  },
  {
    postcode: 2880,
    place_name: 'Tibooburra',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -29.4339,
    longitude: 142.0102,
    accuracy: 4,
  },
  {
    postcode: 2880,
    place_name: 'Little Topar',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5166,
    longitude: 148.6724,
    accuracy: 3,
  },
  {
    postcode: 2880,
    place_name: 'Fowlers Gap',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5166,
    longitude: 148.6724,
    accuracy: 3,
  },
  {
    postcode: 2890,
    place_name: 'Australian Defence Forces',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.309,
    longitude: 148.084,
    accuracy: 1,
  },
  {
    postcode: 2891,
    place_name: 'International Mc',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.5166,
    longitude: 148.6724,
    accuracy: 1,
  },
  {
    postcode: 2898,
    place_name: 'Lord Howe Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -31.5526,
    longitude: 159.0768,
    accuracy: 1,
  },
  {
    postcode: 2899,
    place_name: 'Norfolk Island',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -32.309,
    longitude: 148.084,
    accuracy: 1,
  },
  {
    postcode: 3500,
    place_name: 'Paringi',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -34.1979,
    longitude: 142.1515,
    accuracy: 3,
  },
  {
    postcode: 3644,
    place_name: 'Barooga',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.906,
    longitude: 145.6958,
    accuracy: 4,
  },
  {
    postcode: 3644,
    place_name: 'Lalalty',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -35.9503,
    longitude: 145.6546,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Lake Hume Village',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.2843,
    longitude: 147.0692,
    accuracy: 3,
  },
  {
    postcode: 3707,
    place_name: 'Bringenbrong',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -36.4104,
    longitude: 147.9589,
    accuracy: 3,
  },
  {
    postcode: 4377,
    place_name: 'Maryland',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.55,
    longitude: 152,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Mingoola',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9833,
    longitude: 151.5167,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Amosfield',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.6667,
    longitude: 152.073,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Ruby Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Undercliffe',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4383,
    place_name: 'Jennings',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.9307,
    longitude: 151.9382,
    accuracy: 4,
  },
  {
    postcode: 4385,
    place_name: 'Camp Creek',
    state_name: 'New South Wales',
    state_code: 'NSW',
    latitude: -28.85,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 800,
    place_name: 'Darwin',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4611,
    longitude: 130.8418,
    accuracy: 4,
  },
  {
    postcode: 801,
    place_name: 'Darwin',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4611,
    longitude: 130.8418,
    accuracy: 4,
  },
  {
    postcode: 803,
    place_name: 'Wagait Beach',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4348,
    longitude: 130.7443,
    accuracy: 4,
  },
  {
    postcode: 804,
    place_name: 'Parap',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4305,
    longitude: 130.8414,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Casuarina',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.374,
    longitude: 130.8822,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Tiwi',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3588,
    longitude: 130.878,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Lyons',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3591,
    longitude: 130.8885,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Nightcliff',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.383,
    longitude: 130.8517,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Brinkin',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3706,
    longitude: 130.868,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Moil',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3885,
    longitude: 130.8809,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Millner',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3919,
    longitude: 130.8625,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Jingili',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3889,
    longitude: 130.8728,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Rapid Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3804,
    longitude: 130.8592,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Alawa',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3795,
    longitude: 130.8732,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Muirhead',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3586,
    longitude: 130.8935,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Nakara',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3705,
    longitude: 130.8773,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Wagaman',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3807,
    longitude: 130.8854,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Coconut Grove',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.396,
    longitude: 130.8519,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Wanguri',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3713,
    longitude: 130.8881,
    accuracy: 4,
  },
  {
    postcode: 810,
    place_name: 'Lee Point',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3415,
    longitude: 130.8895,
    accuracy: 4,
  },
  {
    postcode: 811,
    place_name: 'Casuarina',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.374,
    longitude: 130.8822,
    accuracy: 4,
  },
  {
    postcode: 812,
    place_name: 'Sanderson',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3792,
    longitude: 130.8784,
    accuracy: 3,
  },
  {
    postcode: 812,
    place_name: 'Marrara',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4004,
    longitude: 130.8929,
    accuracy: 4,
  },
  {
    postcode: 812,
    place_name: 'Leanyer',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3667,
    longitude: 130.9,
    accuracy: 4,
  },
  {
    postcode: 812,
    place_name: 'Anula',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3912,
    longitude: 130.8905,
    accuracy: 4,
  },
  {
    postcode: 812,
    place_name: 'Woodleigh Gardens',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3792,
    longitude: 130.8784,
    accuracy: 3,
  },
  {
    postcode: 812,
    place_name: 'Malak',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3929,
    longitude: 130.904,
    accuracy: 4,
  },
  {
    postcode: 812,
    place_name: 'Buffalo Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3368,
    longitude: 130.9054,
    accuracy: 4,
  },
  {
    postcode: 812,
    place_name: 'Holmes',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 3,
  },
  {
    postcode: 812,
    place_name: 'Karama',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4022,
    longitude: 130.916,
    accuracy: 4,
  },
  {
    postcode: 812,
    place_name: 'Wulagi',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3834,
    longitude: 130.8954,
    accuracy: 4,
  },
  {
    postcode: 813,
    place_name: 'Sanderson',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3792,
    longitude: 130.8784,
    accuracy: 3,
  },
  {
    postcode: 813,
    place_name: 'Karama',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4022,
    longitude: 130.916,
    accuracy: 4,
  },
  {
    postcode: 814,
    place_name: 'Nightcliff',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.383,
    longitude: 130.8517,
    accuracy: 4,
  },
  {
    postcode: 815,
    place_name: 'Charles Darwin University',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3792,
    longitude: 130.8784,
    accuracy: 1,
  },
  {
    postcode: 820,
    place_name: 'Winnellie',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4292,
    longitude: 130.8859,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'East Point',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4078,
    longitude: 130.8194,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Bagot',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4153,
    longitude: 130.8568,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Charles Darwin',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4338,
    longitude: 130.8737,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Darwin DC',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4252,
    longitude: 130.8697,
    accuracy: 3,
  },
  {
    postcode: 820,
    place_name: 'Coonawarra',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4341,
    longitude: 130.9098,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Stuart Park',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.445,
    longitude: 130.843,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Ludmilla',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4203,
    longitude: 130.8551,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Darwin International Airport',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4252,
    longitude: 130.8697,
    accuracy: 3,
  },
  {
    postcode: 820,
    place_name: 'Fannie Bay',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4227,
    longitude: 130.8363,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'The Gardens',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.449,
    longitude: 130.8316,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Larrakeyah',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4553,
    longitude: 130.8317,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Eaton',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4124,
    longitude: 130.8807,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Bayview',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4336,
    longitude: 130.857,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Woolner',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4354,
    longitude: 130.8479,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'Parap',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4305,
    longitude: 130.8414,
    accuracy: 4,
  },
  {
    postcode: 820,
    place_name: 'The Narrows',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4264,
    longitude: 130.8595,
    accuracy: 4,
  },
  {
    postcode: 821,
    place_name: 'Winnellie',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4292,
    longitude: 130.8859,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Milyakburra',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.7794,
    longitude: 136.2019,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Blackmore',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.7593,
    longitude: 130.9382,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Murrumujuk',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.2609,
    longitude: 131.0717,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Maningrida',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.0674,
    longitude: 134.0866,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Nganmarriyanga',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.335,
    longitude: 129.8835,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Gunbalanya',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3247,
    longitude: 133.0509,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Tiwi Islands',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -11.5602,
    longitude: 130.8174,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Coomalie Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Darwin River Dam',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Acacia Hills',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.7941,
    longitude: 131.1254,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Charles Darwin',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4338,
    longitude: 130.8737,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Anindilyakwa',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Daly River',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.7654,
    longitude: 130.7123,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Lloyd Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6767,
    longitude: 131.1338,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Mandorah',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4457,
    longitude: 130.7574,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Thamarrurr',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.2032,
    longitude: 129.7052,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'East Arnhem',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Wickham',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5539,
    longitude: 130.9223,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Numbulwar',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.2761,
    longitude: 135.7372,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Darwin Mc',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.0616,
    longitude: 132.8034,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Nauiyu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.7425,
    longitude: 130.6956,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Tumbling Waters',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.7685,
    longitude: 130.95,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Kakadu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.8685,
    longitude: 132.474,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Bathurst Island',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Wadeye',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.2383,
    longitude: 129.5218,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Hughes',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.7007,
    longitude: 131.0981,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Marrakai',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.7505,
    longitude: 131.4806,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Delissaville',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.0616,
    longitude: 132.8034,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Pularumpi',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -11.4049,
    longitude: 130.4156,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Pirlangimpi',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -11.403,
    longitude: 130.429,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Croker Island',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.0616,
    longitude: 132.8034,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Peppimenarti',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.1555,
    longitude: 130.0815,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Douglas-Daly',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Numburindi',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.9293,
    longitude: 135.704,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Litchfield Park',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Wurrumiyanga',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -11.7608,
    longitude: 130.6256,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Nemarluk',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.2768,
    longitude: 130.3759,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'West Arnhem',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.2352,
    longitude: 134.0603,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Umbakumba',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.0616,
    longitude: 132.8034,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Charlotte',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Hidden Valley',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4424,
    longitude: 130.9094,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Southport',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.7218,
    longitude: 130.9514,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Bynoe Harbour',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Warruwi',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -11.6457,
    longitude: 133.3884,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Angurugu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.0616,
    longitude: 132.8034,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Cox Peninsula',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4268,
    longitude: 130.6408,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Bynoe',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Black Jungle',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5138,
    longitude: 131.2193,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Weddell',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Fly Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.776,
    longitude: 131.0445,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Claravale',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Goulburn Island',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -11.65,
    longitude: 133.3883,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Mcminns Lagoon',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.554,
    longitude: 131.1116,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Lambells Lagoon',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5845,
    longitude: 131.2503,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'East Arm',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Bees Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5795,
    longitude: 131.0593,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Finniss Valley',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Livingstone',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6814,
    longitude: 131.0741,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Tipperary',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Annie River',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Tortilla Flats',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Burrundie',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Tivendale',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Nguiu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -11.7604,
    longitude: 130.636,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Vernon Islands',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Minjilang',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -11.1584,
    longitude: 132.5453,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Stapleton',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Oenpelli',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3237,
    longitude: 133.0576,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Rakula',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Galiwinku',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.0281,
    longitude: 135.5649,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Robin Falls',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Channel Island',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5533,
    longitude: 130.8685,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Wak Wak',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Milingimbi',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.1019,
    longitude: 134.919,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Glyde Point',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Camp Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Eva Valley',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Winnellie',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4292,
    longitude: 130.8859,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Daly',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Hotham',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Hayes Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Ramingining',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.0616,
    longitude: 132.8034,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Mapuru',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Freds Pass',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5394,
    longitude: 131.0569,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Middle Point',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Koolpinyah',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Wagait Beach',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4348,
    longitude: 130.7443,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Mickett Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Point Stephens',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Belyuen',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5381,
    longitude: 130.6982,
    accuracy: 4,
  },
  {
    postcode: 822,
    place_name: 'Margaret River',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Woolaning',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Wishart',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Collett Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Maranunga',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Milikapiti',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -11.4255,
    longitude: 130.6748,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Lake Bennett',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Cobourg',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Fleming',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Sandpalms Roadhouse',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Gunn Point',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Point Stuart',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Mount Bundey',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Rum Jungle',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6978,
    longitude: 130.8459,
    accuracy: 3,
  },
  {
    postcode: 822,
    place_name: 'Elrundie',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4789,
    longitude: 130.9599,
    accuracy: 4,
  },
  {
    postcode: 828,
    place_name: 'Berrimah',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4351,
    longitude: 130.9261,
    accuracy: 4,
  },
  {
    postcode: 828,
    place_name: 'Knuckey Lagoon',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4266,
    longitude: 130.9457,
    accuracy: 4,
  },
  {
    postcode: 829,
    place_name: 'Pinelands',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4576,
    longitude: 130.9598,
    accuracy: 4,
  },
  {
    postcode: 829,
    place_name: 'Holtze',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4485,
    longitude: 131.0056,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Driver',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.486,
    longitude: 130.9754,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Archer',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5144,
    longitude: 130.9688,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Shoal Bay',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4731,
    longitude: 130.9721,
    accuracy: 3,
  },
  {
    postcode: 830,
    place_name: 'Marlow Lagoon',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4913,
    longitude: 130.9641,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Farrar',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4803,
    longitude: 130.9982,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Durack',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4731,
    longitude: 130.9721,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Palmerston',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.486,
    longitude: 130.9833,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Gray',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4904,
    longitude: 130.9823,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Woodroffe',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5017,
    longitude: 130.9802,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Moulden',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5056,
    longitude: 130.9731,
    accuracy: 4,
  },
  {
    postcode: 830,
    place_name: 'Yarrawonga',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4725,
    longitude: 130.9884,
    accuracy: 4,
  },
  {
    postcode: 831,
    place_name: 'Palmerston',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.486,
    longitude: 130.9833,
    accuracy: 4,
  },
  {
    postcode: 832,
    place_name: 'Zuccoli',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4876,
    longitude: 131.0117,
    accuracy: 3,
  },
  {
    postcode: 832,
    place_name: 'Bakewell',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4968,
    longitude: 130.9934,
    accuracy: 4,
  },
  {
    postcode: 832,
    place_name: 'Johnston',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4876,
    longitude: 131.0117,
    accuracy: 4,
  },
  {
    postcode: 832,
    place_name: 'Gunn',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4876,
    longitude: 130.9932,
    accuracy: 4,
  },
  {
    postcode: 832,
    place_name: 'Rosebery',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5105,
    longitude: 130.9878,
    accuracy: 4,
  },
  {
    postcode: 832,
    place_name: 'Mitchell',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4986,
    longitude: 130.9984,
    accuracy: 3,
  },
  {
    postcode: 832,
    place_name: 'Rosebery Heights',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4876,
    longitude: 131.0117,
    accuracy: 3,
  },
  {
    postcode: 832,
    place_name: 'Bellamack',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5171,
    longitude: 130.9836,
    accuracy: 4,
  },
  {
    postcode: 834,
    place_name: 'Virginia',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.519,
    longitude: 131.0284,
    accuracy: 4,
  },
  {
    postcode: 835,
    place_name: 'Coolalinga',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5231,
    longitude: 131.0415,
    accuracy: 4,
  },
  {
    postcode: 835,
    place_name: 'Howard Springs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4958,
    longitude: 131.0445,
    accuracy: 4,
  },
  {
    postcode: 835,
    place_name: 'Virginia',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.519,
    longitude: 131.0284,
    accuracy: 4,
  },
  {
    postcode: 836,
    place_name: 'Girraween',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5253,
    longitude: 131.0957,
    accuracy: 4,
  },
  {
    postcode: 836,
    place_name: 'Humpty Doo',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5841,
    longitude: 131.1367,
    accuracy: 4,
  },
  {
    postcode: 836,
    place_name: 'Herbert',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5341,
    longitude: 131.162,
    accuracy: 4,
  },
  {
    postcode: 837,
    place_name: 'Manton',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.8937,
    longitude: 131.1132,
    accuracy: 4,
  },
  {
    postcode: 837,
    place_name: 'Noonamah',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6312,
    longitude: 131.0799,
    accuracy: 4,
  },
  {
    postcode: 838,
    place_name: 'Berry Springs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6968,
    longitude: 131.01,
    accuracy: 4,
  },
  {
    postcode: 839,
    place_name: 'Coolalinga',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5231,
    longitude: 131.0415,
    accuracy: 4,
  },
  {
    postcode: 840,
    place_name: 'Dundee Beach',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.7176,
    longitude: 130.3513,
    accuracy: 1,
  },
  {
    postcode: 840,
    place_name: 'Dundee Downs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.7522,
    longitude: 130.5154,
    accuracy: 1,
  },
  {
    postcode: 840,
    place_name: 'Dundee Forest',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.7168,
    longitude: 130.4475,
    accuracy: 1,
  },
  {
    postcode: 841,
    place_name: 'Darwin River',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.8193,
    longitude: 130.9697,
    accuracy: 4,
  },
  {
    postcode: 845,
    place_name: 'Batchelor',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.0505,
    longitude: 131.0307,
    accuracy: 4,
  },
  {
    postcode: 846,
    place_name: 'Adelaide River',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.2379,
    longitude: 131.1056,
    accuracy: 4,
  },
  {
    postcode: 847,
    place_name: 'Pine Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.8208,
    longitude: 131.8329,
    accuracy: 4,
  },
  {
    postcode: 850,
    place_name: 'Cossack',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.4555,
    longitude: 132.1748,
    accuracy: 4,
  },
  {
    postcode: 850,
    place_name: 'Katherine',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.4652,
    longitude: 132.2635,
    accuracy: 4,
  },
  {
    postcode: 850,
    place_name: 'Katherine South',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.4435,
    longitude: 132.2814,
    accuracy: 3,
  },
  {
    postcode: 850,
    place_name: 'Lansdowne',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.4314,
    longitude: 132.3879,
    accuracy: 4,
  },
  {
    postcode: 850,
    place_name: 'Emungalan',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.4435,
    longitude: 132.2814,
    accuracy: 3,
  },
  {
    postcode: 850,
    place_name: 'Katherine East',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.4435,
    longitude: 132.2814,
    accuracy: 3,
  },
  {
    postcode: 851,
    place_name: 'Katherine',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.4652,
    longitude: 132.2635,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Yarralin',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -16.4526,
    longitude: 130.8419,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Beswick',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.558,
    longitude: 133.1151,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Barunga',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.5196,
    longitude: 132.8644,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Wave Hill',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -17.4476,
    longitude: 130.8344,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Jilkminggan',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.9615,
    longitude: 133.2928,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Maranboy',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.5333,
    longitude: 132.7833,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Daguragu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -17.4193,
    longitude: 130.8021,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Wilton',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Numbulwar',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.2761,
    longitude: 135.7372,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Ngukurr',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.7308,
    longitude: 134.731,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Baines',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.9828,
    longitude: 129.4996,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Uralla',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.4911,
    longitude: 132.2983,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Gregory',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Victoria River Downs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -16.0432,
    longitude: 132.4219,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Venn',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Timber Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.6625,
    longitude: 130.4804,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Edith',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Binjari',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.5501,
    longitude: 132.1842,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Manbulloo',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Top Springs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -16.5438,
    longitude: 131.7977,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Gurindji',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Bulman Weemol',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Kalkarindji',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -17.4476,
    longitude: 130.8344,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Limmen',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.5241,
    longitude: 134.8004,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Birdum',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.65,
    longitude: 133.2167,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Arnold',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Elsey',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Bradshaw',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Creswell',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Tanami East',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Delamere',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Lajamanu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -16.0432,
    longitude: 132.4219,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Flying Fox',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Beswick Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Victoria River',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Pellew Islands',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Florina',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Robinson River',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Daly Waters',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -16.2533,
    longitude: 133.3693,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Larrimah',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -16.0432,
    longitude: 132.4219,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Gulung Mardrulk',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Pigeon Hole',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Miniyeri',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.2255,
    longitude: 134.0798,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Katherine',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.4652,
    longitude: 132.2635,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Mataranka',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.9213,
    longitude: 133.065,
    accuracy: 4,
  },
  {
    postcode: 852,
    place_name: 'Helen Springs Station',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Mcarthur',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Buchanan',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Sturt Plateau',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Warumungu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 852,
    place_name: 'Nitmiluk',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -15.0045,
    longitude: 133.3768,
    accuracy: 3,
  },
  {
    postcode: 853,
    place_name: 'Tindal',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.5312,
    longitude: 132.3793,
    accuracy: 4,
  },
  {
    postcode: 853,
    place_name: 'Tindal Raaf',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.5733,
    longitude: 132.54,
    accuracy: 3,
  },
  {
    postcode: 854,
    place_name: 'King Ash Bay',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.8923,
    longitude: 133.1577,
    accuracy: 1,
  },
  {
    postcode: 854,
    place_name: 'Borroloola',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -16.0703,
    longitude: 136.3087,
    accuracy: 1,
  },
  {
    postcode: 860,
    place_name: 'Tennant Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -19.6548,
    longitude: 134.1871,
    accuracy: 1,
  },
  {
    postcode: 861,
    place_name: 'Tennant Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7,
    longitude: 133.8833,
    accuracy: 1,
  },
  {
    postcode: 862,
    place_name: 'Warrego',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -19.4362,
    longitude: 133.8208,
    accuracy: 4,
  },
  {
    postcode: 862,
    place_name: 'Tennant Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -19.6497,
    longitude: 134.1915,
    accuracy: 4,
  },
  {
    postcode: 862,
    place_name: 'Newcastle Waters',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -18.9889,
    longitude: 135.0004,
    accuracy: 3,
  },
  {
    postcode: 862,
    place_name: 'Renner Springs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -37.8421,
    longitude: 145.0694,
    accuracy: 3,
  },
  {
    postcode: 862,
    place_name: 'Calvert',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -16.9206,
    longitude: 137.2034,
    accuracy: 4,
  },
  {
    postcode: 862,
    place_name: 'Tablelands',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -37.8421,
    longitude: 145.0694,
    accuracy: 3,
  },
  {
    postcode: 862,
    place_name: 'Nicholson',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -37.8421,
    longitude: 145.0694,
    accuracy: 3,
  },
  {
    postcode: 862,
    place_name: 'Pamayu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -37.8421,
    longitude: 145.0694,
    accuracy: 3,
  },
  {
    postcode: 862,
    place_name: 'Elliott',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -17.5507,
    longitude: 133.5407,
    accuracy: 4,
  },
  {
    postcode: 870,
    place_name: 'Stuart',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.6975,
    longitude: 133.8836,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Ross',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7442,
    longitude: 133.9036,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Larapinta',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.6803,
    longitude: 133.8311,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Araluen',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.696,
    longitude: 133.854,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Mount Johns',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7232,
    longitude: 133.913,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Flynn',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7135,
    longitude: 133.8356,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'The Gap',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7133,
    longitude: 133.8739,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Sadadeen',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.704,
    longitude: 133.9004,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'East Side',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.692,
    longitude: 133.8932,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Arumbera',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7,
    longitude: 133.874,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Connellan',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7,
    longitude: 133.874,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Alice Springs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.735,
    longitude: 133.8548,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Irlpme',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.6469,
    longitude: 133.8648,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Ilparpa',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7,
    longitude: 133.874,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Undoolya',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7,
    longitude: 133.874,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Gillen',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7092,
    longitude: 133.866,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Ciccone',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7,
    longitude: 133.874,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'White Gums',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7,
    longitude: 133.874,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Braitling',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.68,
    longitude: 133.8688,
    accuracy: 1,
  },
  {
    postcode: 870,
    place_name: 'Desert Springs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7,
    longitude: 133.874,
    accuracy: 1,
  },
  {
    postcode: 871,
    place_name: 'Alice Springs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7,
    longitude: 133.8833,
    accuracy: 1,
  },
  {
    postcode: 872,
    place_name: 'Ampilatwatja',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -21.657,
    longitude: 135.2259,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Wilora',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -21.7443,
    longitude: 133.7265,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Barrow Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -21.55,
    longitude: 133.8833,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Hale',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Laramba',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -22.5434,
    longitude: 132.7624,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Ali Curung',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -21.0199,
    longitude: 134.3249,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Wallace Rockhole',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -24.1242,
    longitude: 133.091,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Yuendumu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -22.2667,
    longitude: 131.8167,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Papunya',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.2045,
    longitude: 131.9122,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Tara',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -21.4833,
    longitude: 133.9992,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Davenport',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Kulgera',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Anmatjere',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Wutunugurra',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -20.4623,
    longitude: 135.2505,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Namatjira',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Canteen Creek',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -20.6403,
    longitude: 135.5865,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Hart',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Burt Plain',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Napperby Station',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Engawala',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -22.8031,
    longitude: 134.4564,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Atitjere',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Nyirripi',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -22.6492,
    longitude: 130.5639,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Imanpa',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -25.1176,
    longitude: 132.5688,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Sandover',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -21.9596,
    longitude: 135.5128,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Finke',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -25.698,
    longitude: 134.3397,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Tanami',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Santa Teresa',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -24.7306,
    longitude: 135.3322,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Mutitjulu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Anatye',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Costello',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -14.5655,
    longitude: 134.8794,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Erldunda',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Chilla Well',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Alice Springs',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7917,
    longitude: 132.6818,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Titjikala',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -24.6802,
    longitude: 134.0751,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Areyonga',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -24.1669,
    longitude: 132.2868,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Willowra',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -21.2632,
    longitude: 132.6191,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Hart Range',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Yuelamu',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -22.2617,
    longitude: 132.1994,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Ti Tree',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -22.1338,
    longitude: 133.4139,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Yulara',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -25.242,
    longitude: 130.9856,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Haasts Bluff',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.5093,
    longitude: 132.1852,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Mereenie',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Mount Zeil',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Kaltukatjara',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7917,
    longitude: 132.6818,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Hermannsburg',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7917,
    longitude: 132.6818,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Kings Creek Station',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Kintore',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7917,
    longitude: 132.6818,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Hugh',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Telegraph Station',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Lake Mackay',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Ininti Store',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Macdonnell Range',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Simpson',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Mount Liebig',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Ghan',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Kunparrka',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3611,
    longitude: 131.6665,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Amoonguna',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.7709,
    longitude: 133.9365,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Petermann',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 873,
    place_name: 'Heavitree Gap Cpa',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -23.5298,
    longitude: 133.5021,
    accuracy: 1,
  },
  {
    postcode: 880,
    place_name: 'Nhulunbuy',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.1816,
    longitude: 136.7784,
    accuracy: 4,
  },
  {
    postcode: 880,
    place_name: 'Yirrkala',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.2549,
    longitude: 136.8899,
    accuracy: 4,
  },
  {
    postcode: 880,
    place_name: 'Gapuwiyak',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.5031,
    longitude: 135.8105,
    accuracy: 4,
  },
  {
    postcode: 880,
    place_name: 'Gunyangara',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.2165,
    longitude: 136.7054,
    accuracy: 4,
  },
  {
    postcode: 881,
    place_name: 'Nhulunbuy',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.1816,
    longitude: 136.7784,
    accuracy: 4,
  },
  {
    postcode: 885,
    place_name: 'Alyangula',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -13.8541,
    longitude: 136.4213,
    accuracy: 4,
  },
  {
    postcode: 886,
    place_name: 'Jabiru',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.6705,
    longitude: 132.836,
    accuracy: 4,
  },
  {
    postcode: 906,
    place_name: 'Winnellie',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4292,
    longitude: 130.8859,
    accuracy: 4,
  },
  {
    postcode: 907,
    place_name: 'Winnellie',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.4292,
    longitude: 130.8859,
    accuracy: 4,
  },
  {
    postcode: 909,
    place_name: 'Charles Darwin University',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -12.3731,
    longitude: 130.8681,
    accuracy: 1,
  },
  {
    postcode: 4825,
    place_name: 'Alpurrurulam',
    state_name: 'Northern Territory',
    state_code: 'NT',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4000,
    place_name: 'Brisbane City',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 4000,
    place_name: 'Brisbane Adelaide Street',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.454,
    longitude: 153.0426,
    accuracy: 3,
  },
  {
    postcode: 4000,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 4000,
    place_name: 'Petrie Terrace',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.463,
    longitude: 153.0131,
    accuracy: 4,
  },
  {
    postcode: 4000,
    place_name: 'Spring Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4614,
    longitude: 153.0231,
    accuracy: 4,
  },
  {
    postcode: 4000,
    place_name: 'Brisbane GPO',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4651,
    longitude: 153.0231,
    accuracy: 3,
  },
  {
    postcode: 4001,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 4001,
    place_name: 'Central Plaza',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5,
    longitude: 153.0167,
    accuracy: 3,
  },
  {
    postcode: 4001,
    place_name: 'Riverside Centre',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5,
    longitude: 153.0167,
    accuracy: 3,
  },
  {
    postcode: 4001,
    place_name: 'Waterfront Place',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5,
    longitude: 153.0167,
    accuracy: 3,
  },
  {
    postcode: 4002,
    place_name: 'City East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.454,
    longitude: 153.0426,
    accuracy: 1,
  },
  {
    postcode: 4002,
    place_name: 'Wintergarden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.454,
    longitude: 153.0426,
    accuracy: 1,
  },
  {
    postcode: 4003,
    place_name: 'George Street',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.454,
    longitude: 153.0426,
    accuracy: 1,
  },
  {
    postcode: 4004,
    place_name: 'Spring Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4614,
    longitude: 153.0231,
    accuracy: 4,
  },
  {
    postcode: 4005,
    place_name: 'New Farm',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4667,
    longitude: 153.05,
    accuracy: 4,
  },
  {
    postcode: 4005,
    place_name: 'Teneriffe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4559,
    longitude: 153.047,
    accuracy: 4,
  },
  {
    postcode: 4006,
    place_name: 'Newstead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4436,
    longitude: 153.0443,
    accuracy: 4,
  },
  {
    postcode: 4006,
    place_name: 'Fortitude Valley BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4473,
    longitude: 153.032,
    accuracy: 3,
  },
  {
    postcode: 4006,
    place_name: 'Bowen Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4432,
    longitude: 153.0302,
    accuracy: 4,
  },
  {
    postcode: 4006,
    place_name: 'Herston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4445,
    longitude: 153.0185,
    accuracy: 4,
  },
  {
    postcode: 4006,
    place_name: 'Fortitude Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4571,
    longitude: 153.0318,
    accuracy: 4,
  },
  {
    postcode: 4007,
    place_name: 'Hamilton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.439,
    longitude: 153.0629,
    accuracy: 4,
  },
  {
    postcode: 4007,
    place_name: 'Ascot',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4315,
    longitude: 153.058,
    accuracy: 4,
  },
  {
    postcode: 4007,
    place_name: 'Hamilton Central',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4417,
    longitude: 153.0667,
    accuracy: 3,
  },
  {
    postcode: 4008,
    place_name: 'Pinkenba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.428,
    longitude: 153.1167,
    accuracy: 4,
  },
  {
    postcode: 4008,
    place_name: 'Brisbane Airport',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3947,
    longitude: 153.114,
    accuracy: 4,
  },
  {
    postcode: 4009,
    place_name: 'Eagle Farm BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4306,
    longitude: 153.0906,
    accuracy: 3,
  },
  {
    postcode: 4009,
    place_name: 'Eagle Farm',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4333,
    longitude: 153.0833,
    accuracy: 4,
  },
  {
    postcode: 4010,
    place_name: 'Albion DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4301,
    longitude: 153.0379,
    accuracy: 3,
  },
  {
    postcode: 4010,
    place_name: 'Albion',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4301,
    longitude: 153.0379,
    accuracy: 4,
  },
  {
    postcode: 4010,
    place_name: 'Albion BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4301,
    longitude: 153.0379,
    accuracy: 3,
  },
  {
    postcode: 4011,
    place_name: 'Clayfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4189,
    longitude: 153.0582,
    accuracy: 4,
  },
  {
    postcode: 4011,
    place_name: 'Hendra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4177,
    longitude: 153.0703,
    accuracy: 4,
  },
  {
    postcode: 4012,
    place_name: 'Nundah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4025,
    longitude: 153.0617,
    accuracy: 4,
  },
  {
    postcode: 4012,
    place_name: 'Wavell Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3938,
    longitude: 153.047,
    accuracy: 4,
  },
  {
    postcode: 4012,
    place_name: 'Toombul',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4096,
    longitude: 153.0584,
    accuracy: 4,
  },
  {
    postcode: 4012,
    place_name: 'Wavell Heights North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3974,
    longitude: 153.0546,
    accuracy: 3,
  },
  {
    postcode: 4013,
    place_name: 'Northgate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4,
    longitude: 153.0667,
    accuracy: 4,
  },
  {
    postcode: 4014,
    place_name: 'Nudgee Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3462,
    longitude: 153.1037,
    accuracy: 4,
  },
  {
    postcode: 4014,
    place_name: 'Banyo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3824,
    longitude: 153.079,
    accuracy: 4,
  },
  {
    postcode: 4014,
    place_name: 'Virginia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3833,
    longitude: 153.0667,
    accuracy: 4,
  },
  {
    postcode: 4014,
    place_name: 'Virginia BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3783,
    longitude: 153.0768,
    accuracy: 3,
  },
  {
    postcode: 4014,
    place_name: 'Nudgee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3691,
    longitude: 153.0847,
    accuracy: 4,
  },
  {
    postcode: 4014,
    place_name: 'Virginia DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3783,
    longitude: 153.0768,
    accuracy: 3,
  },
  {
    postcode: 4017,
    place_name: 'Brighton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2958,
    longitude: 153.0572,
    accuracy: 4,
  },
  {
    postcode: 4017,
    place_name: 'Brighton Nathan Street',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3192,
    longitude: 153.0612,
    accuracy: 3,
  },
  {
    postcode: 4017,
    place_name: 'Deagon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3333,
    longitude: 153.0667,
    accuracy: 4,
  },
  {
    postcode: 4017,
    place_name: 'Sandgate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.322,
    longitude: 153.0695,
    accuracy: 4,
  },
  {
    postcode: 4017,
    place_name: 'Shorncliffe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3276,
    longitude: 153.0816,
    accuracy: 4,
  },
  {
    postcode: 4017,
    place_name: 'Brighton Eventide',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3192,
    longitude: 153.0612,
    accuracy: 3,
  },
  {
    postcode: 4017,
    place_name: 'Bracken Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3171,
    longitude: 153.031,
    accuracy: 4,
  },
  {
    postcode: 4017,
    place_name: 'Sandgate DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3192,
    longitude: 153.0612,
    accuracy: 3,
  },
  {
    postcode: 4018,
    place_name: 'Taigum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3426,
    longitude: 153.0444,
    accuracy: 4,
  },
  {
    postcode: 4018,
    place_name: 'Fitzgibbon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3397,
    longitude: 153.0292,
    accuracy: 4,
  },
  {
    postcode: 4019,
    place_name: 'Woody Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2561,
    longitude: 153.1043,
    accuracy: 4,
  },
  {
    postcode: 4019,
    place_name: 'Margate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2476,
    longitude: 153.0981,
    accuracy: 4,
  },
  {
    postcode: 4019,
    place_name: 'Margate Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2473,
    longitude: 153.0905,
    accuracy: 3,
  },
  {
    postcode: 4019,
    place_name: 'Clontarf',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2534,
    longitude: 153.0783,
    accuracy: 4,
  },
  {
    postcode: 4019,
    place_name: 'Clontarf DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2524,
    longitude: 153.0936,
    accuracy: 3,
  },
  {
    postcode: 4019,
    place_name: 'Clontarf Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2524,
    longitude: 153.0936,
    accuracy: 3,
  },
  {
    postcode: 4020,
    place_name: 'Newport',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2073,
    longitude: 153.1013,
    accuracy: 4,
  },
  {
    postcode: 4020,
    place_name: 'Redcliffe North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.213,
    longitude: 153.103,
    accuracy: 3,
  },
  {
    postcode: 4020,
    place_name: 'Scarborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2012,
    longitude: 153.1094,
    accuracy: 4,
  },
  {
    postcode: 4020,
    place_name: 'Redcliffe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2307,
    longitude: 153.0973,
    accuracy: 4,
  },
  {
    postcode: 4021,
    place_name: 'Kippa-Ring',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2259,
    longitude: 153.0835,
    accuracy: 4,
  },
  {
    postcode: 4022,
    place_name: 'Rothwell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2143,
    longitude: 153.0468,
    accuracy: 4,
  },
  {
    postcode: 4025,
    place_name: 'Tangalooma',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1794,
    longitude: 153.3743,
    accuracy: 4,
  },
  {
    postcode: 4025,
    place_name: 'Cape Moreton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0282,
    longitude: 153.4674,
    accuracy: 4,
  },
  {
    postcode: 4025,
    place_name: 'Cowan Cowan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1259,
    longitude: 153.3661,
    accuracy: 4,
  },
  {
    postcode: 4025,
    place_name: 'Bulwer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0787,
    longitude: 153.3697,
    accuracy: 4,
  },
  {
    postcode: 4025,
    place_name: 'Kooringal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3536,
    longitude: 153.4247,
    accuracy: 4,
  },
  {
    postcode: 4029,
    place_name: 'Royal Brisbane Hospital',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1962,
    longitude: 153.2931,
    accuracy: 1,
  },
  {
    postcode: 4030,
    place_name: 'Wooloowin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4224,
    longitude: 153.042,
    accuracy: 4,
  },
  {
    postcode: 4030,
    place_name: 'Lutwyche',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4227,
    longitude: 153.0335,
    accuracy: 4,
  },
  {
    postcode: 4030,
    place_name: 'Windsor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4363,
    longitude: 153.0288,
    accuracy: 4,
  },
  {
    postcode: 4031,
    place_name: 'Kedron',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4029,
    longitude: 153.0297,
    accuracy: 4,
  },
  {
    postcode: 4031,
    place_name: 'Gordon Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.419,
    longitude: 153.0255,
    accuracy: 4,
  },
  {
    postcode: 4032,
    place_name: 'Chermside West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3838,
    longitude: 153.0159,
    accuracy: 4,
  },
  {
    postcode: 4032,
    place_name: 'Chermside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3847,
    longitude: 153.0306,
    accuracy: 4,
  },
  {
    postcode: 4032,
    place_name: 'Chermside Centre',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3847,
    longitude: 153.0306,
    accuracy: 3,
  },
  {
    postcode: 4032,
    place_name: 'Chermside South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3847,
    longitude: 153.0306,
    accuracy: 3,
  },
  {
    postcode: 4034,
    place_name: 'Carseldine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3475,
    longitude: 153.0231,
    accuracy: 4,
  },
  {
    postcode: 4034,
    place_name: 'Boondall',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3532,
    longitude: 153.0609,
    accuracy: 4,
  },
  {
    postcode: 4034,
    place_name: 'Aspley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3667,
    longitude: 153.0167,
    accuracy: 4,
  },
  {
    postcode: 4034,
    place_name: 'Zillmere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3559,
    longitude: 153.0445,
    accuracy: 4,
  },
  {
    postcode: 4034,
    place_name: 'Geebung',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3725,
    longitude: 153.0471,
    accuracy: 4,
  },
  {
    postcode: 4035,
    place_name: 'Albany Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.35,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4035,
    place_name: 'Bridgeman Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.353,
    longitude: 152.9817,
    accuracy: 3,
  },
  {
    postcode: 4036,
    place_name: 'Bald Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3211,
    longitude: 153.0086,
    accuracy: 4,
  },
  {
    postcode: 4037,
    place_name: 'Eatons Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3392,
    longitude: 152.9599,
    accuracy: 4,
  },
  {
    postcode: 4051,
    place_name: 'Enoggera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4283,
    longitude: 152.9747,
    accuracy: 4,
  },
  {
    postcode: 4051,
    place_name: 'Grange',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4225,
    longitude: 153.0153,
    accuracy: 4,
  },
  {
    postcode: 4051,
    place_name: 'Newmarket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4333,
    longitude: 153.0167,
    accuracy: 4,
  },
  {
    postcode: 4051,
    place_name: 'Gaythorne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4167,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4051,
    place_name: 'Alderley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4255,
    longitude: 153.001,
    accuracy: 4,
  },
  {
    postcode: 4051,
    place_name: 'Wilston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4322,
    longitude: 153.019,
    accuracy: 4,
  },
  {
    postcode: 4053,
    place_name: 'Everton Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3891,
    longitude: 152.9713,
    accuracy: 4,
  },
  {
    postcode: 4053,
    place_name: 'Brookside Centre',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4155,
    longitude: 152.9766,
    accuracy: 3,
  },
  {
    postcode: 4053,
    place_name: 'Mitchelton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4167,
    longitude: 152.9667,
    accuracy: 4,
  },
  {
    postcode: 4053,
    place_name: 'Stafford DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3961,
    longitude: 152.9766,
    accuracy: 3,
  },
  {
    postcode: 4053,
    place_name: 'Mcdowall',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3827,
    longitude: 152.9919,
    accuracy: 4,
  },
  {
    postcode: 4053,
    place_name: 'Stafford Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3935,
    longitude: 153.0105,
    accuracy: 4,
  },
  {
    postcode: 4053,
    place_name: 'Stafford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4104,
    longitude: 153.0111,
    accuracy: 4,
  },
  {
    postcode: 4053,
    place_name: 'Everton Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4073,
    longitude: 152.9884,
    accuracy: 4,
  },
  {
    postcode: 4054,
    place_name: 'Arana Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3981,
    longitude: 152.958,
    accuracy: 4,
  },
  {
    postcode: 4054,
    place_name: 'Keperra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4138,
    longitude: 152.947,
    accuracy: 4,
  },
  {
    postcode: 4055,
    place_name: 'Ferny Grove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4008,
    longitude: 152.9348,
    accuracy: 4,
  },
  {
    postcode: 4055,
    place_name: 'Bunya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3694,
    longitude: 152.9433,
    accuracy: 4,
  },
  {
    postcode: 4055,
    place_name: 'Ferny Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3959,
    longitude: 152.9374,
    accuracy: 4,
  },
  {
    postcode: 4055,
    place_name: 'Ferny Hills DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3963,
    longitude: 152.9319,
    accuracy: 3,
  },
  {
    postcode: 4055,
    place_name: 'Upper Kedron',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4188,
    longitude: 152.9178,
    accuracy: 4,
  },
  {
    postcode: 4059,
    place_name: 'Red Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4525,
    longitude: 153.0021,
    accuracy: 4,
  },
  {
    postcode: 4059,
    place_name: 'Kelvin Grove Bc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4507,
    longitude: 153.007,
    accuracy: 3,
  },
  {
    postcode: 4059,
    place_name: 'Kelvin Grove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4482,
    longitude: 153.0134,
    accuracy: 4,
  },
  {
    postcode: 4059,
    place_name: 'Kelvin Grove Dc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4507,
    longitude: 153.007,
    accuracy: 3,
  },
  {
    postcode: 4060,
    place_name: 'Ashgrove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4455,
    longitude: 152.992,
    accuracy: 4,
  },
  {
    postcode: 4060,
    place_name: 'Ashgrove East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4455,
    longitude: 152.992,
    accuracy: 3,
  },
  {
    postcode: 4060,
    place_name: 'Dorrington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4425,
    longitude: 152.9864,
    accuracy: 3,
  },
  {
    postcode: 4061,
    place_name: 'The Gap',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4419,
    longitude: 152.9386,
    accuracy: 4,
  },
  {
    postcode: 4064,
    place_name: 'Milton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4704,
    longitude: 153.0031,
    accuracy: 4,
  },
  {
    postcode: 4064,
    place_name: 'Milton BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4649,
    longitude: 152.9991,
    accuracy: 3,
  },
  {
    postcode: 4064,
    place_name: 'Paddington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4594,
    longitude: 152.9951,
    accuracy: 4,
  },
  {
    postcode: 4065,
    place_name: 'Bardon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.461,
    longitude: 152.9792,
    accuracy: 4,
  },
  {
    postcode: 4066,
    place_name: 'Toowong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4066,
    place_name: 'Toowong DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.48,
    longitude: 152.993,
    accuracy: 3,
  },
  {
    postcode: 4066,
    place_name: 'Toowong BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.48,
    longitude: 152.993,
    accuracy: 3,
  },
  {
    postcode: 4066,
    place_name: 'Mount Coot-Tha',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4868,
    longitude: 152.9592,
    accuracy: 3,
  },
  {
    postcode: 4066,
    place_name: 'Auchenflower',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4765,
    longitude: 152.9966,
    accuracy: 4,
  },
  {
    postcode: 4067,
    place_name: 'St Lucia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4982,
    longitude: 153.0004,
    accuracy: 1,
  },
  {
    postcode: 4067,
    place_name: 'St Lucia South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4868,
    longitude: 152.9592,
    accuracy: 1,
  },
  {
    postcode: 4068,
    place_name: 'Chelmer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5133,
    longitude: 152.9752,
    accuracy: 4,
  },
  {
    postcode: 4068,
    place_name: 'Indooroopilly',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.503,
    longitude: 152.9752,
    accuracy: 4,
  },
  {
    postcode: 4068,
    place_name: 'Indooroopilly Centre',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5023,
    longitude: 152.9763,
    accuracy: 3,
  },
  {
    postcode: 4068,
    place_name: 'Taringa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4906,
    longitude: 152.9786,
    accuracy: 4,
  },
  {
    postcode: 4069,
    place_name: 'Upper Brookfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4667,
    longitude: 152.8667,
    accuracy: 4,
  },
  {
    postcode: 4069,
    place_name: 'Pullenvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5228,
    longitude: 152.8865,
    accuracy: 4,
  },
  {
    postcode: 4069,
    place_name: 'Brookfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5,
    longitude: 152.9,
    accuracy: 4,
  },
  {
    postcode: 4069,
    place_name: 'Kenmore DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5072,
    longitude: 152.9208,
    accuracy: 3,
  },
  {
    postcode: 4069,
    place_name: 'Chapel Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5026,
    longitude: 152.9501,
    accuracy: 4,
  },
  {
    postcode: 4069,
    place_name: 'Fig Tree Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5278,
    longitude: 152.9619,
    accuracy: 4,
  },
  {
    postcode: 4069,
    place_name: 'Kenmore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5076,
    longitude: 152.9388,
    accuracy: 4,
  },
  {
    postcode: 4069,
    place_name: 'Pinjarra Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5383,
    longitude: 152.9074,
    accuracy: 4,
  },
  {
    postcode: 4069,
    place_name: 'Kenmore East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5072,
    longitude: 152.9208,
    accuracy: 3,
  },
  {
    postcode: 4069,
    place_name: 'Kenmore Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5042,
    longitude: 152.9318,
    accuracy: 4,
  },
  {
    postcode: 4070,
    place_name: 'Anstead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5378,
    longitude: 152.8619,
    accuracy: 4,
  },
  {
    postcode: 4070,
    place_name: 'Moggill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5833,
    longitude: 152.8667,
    accuracy: 4,
  },
  {
    postcode: 4070,
    place_name: 'Bellbowrie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5594,
    longitude: 152.8828,
    accuracy: 4,
  },
  {
    postcode: 4072,
    place_name: 'University Of Queensland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4978,
    longitude: 153.0135,
    accuracy: 1,
  },
  {
    postcode: 4073,
    place_name: 'Seventeen Mile Rocks',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5507,
    longitude: 152.959,
    accuracy: 4,
  },
  {
    postcode: 4073,
    place_name: 'Sinnamon Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5444,
    longitude: 152.9489,
    accuracy: 4,
  },
  {
    postcode: 4074,
    place_name: 'Sumner',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5655,
    longitude: 152.9334,
    accuracy: 4,
  },
  {
    postcode: 4074,
    place_name: 'Riverhills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5593,
    longitude: 152.9111,
    accuracy: 4,
  },
  {
    postcode: 4074,
    place_name: 'Mount Ommaney',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5354,
    longitude: 152.9382,
    accuracy: 4,
  },
  {
    postcode: 4074,
    place_name: 'Jindalee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5345,
    longitude: 152.9384,
    accuracy: 4,
  },
  {
    postcode: 4074,
    place_name: 'Westlake',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5486,
    longitude: 152.9128,
    accuracy: 4,
  },
  {
    postcode: 4074,
    place_name: 'Jamboree Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5563,
    longitude: 152.934,
    accuracy: 4,
  },
  {
    postcode: 4074,
    place_name: 'Sumner Park Bc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5608,
    longitude: 152.9429,
    accuracy: 3,
  },
  {
    postcode: 4074,
    place_name: 'Middle Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.556,
    longitude: 152.9222,
    accuracy: 4,
  },
  {
    postcode: 4075,
    place_name: 'Sherwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5247,
    longitude: 152.9807,
    accuracy: 4,
  },
  {
    postcode: 4075,
    place_name: 'Graceville East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5473,
    longitude: 152.9791,
    accuracy: 3,
  },
  {
    postcode: 4075,
    place_name: 'Corinda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4075,
    place_name: 'Graceville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.522,
    longitude: 152.9822,
    accuracy: 4,
  },
  {
    postcode: 4075,
    place_name: 'Oxley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.55,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4076,
    place_name: 'Darra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5667,
    longitude: 152.9667,
    accuracy: 4,
  },
  {
    postcode: 4076,
    place_name: 'Wacol',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5833,
    longitude: 152.9333,
    accuracy: 4,
  },
  {
    postcode: 4077,
    place_name: 'Inala Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 152.9819,
    accuracy: 3,
  },
  {
    postcode: 4077,
    place_name: 'Richlands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5962,
    longitude: 152.9532,
    accuracy: 4,
  },
  {
    postcode: 4077,
    place_name: 'Durack',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5895,
    longitude: 152.9858,
    accuracy: 4,
  },
  {
    postcode: 4077,
    place_name: 'Inala East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 152.9819,
    accuracy: 3,
  },
  {
    postcode: 4077,
    place_name: 'Doolandella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6133,
    longitude: 152.9855,
    accuracy: 4,
  },
  {
    postcode: 4077,
    place_name: 'Inala',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5972,
    longitude: 152.9743,
    accuracy: 4,
  },
  {
    postcode: 4078,
    place_name: 'Ellen Grove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6153,
    longitude: 152.9439,
    accuracy: 4,
  },
  {
    postcode: 4078,
    place_name: 'Forest Lake',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6256,
    longitude: 152.9688,
    accuracy: 4,
  },
  {
    postcode: 4101,
    place_name: 'South Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4803,
    longitude: 153.0205,
    accuracy: 4,
  },
  {
    postcode: 4101,
    place_name: 'West End',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 153,
    accuracy: 4,
  },
  {
    postcode: 4101,
    place_name: 'Highgate Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4891,
    longitude: 153.0188,
    accuracy: 4,
  },
  {
    postcode: 4101,
    place_name: 'South Brisbane BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4839,
    longitude: 153.015,
    accuracy: 3,
  },
  {
    postcode: 4102,
    place_name: 'Woolloongabba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4886,
    longitude: 153.0365,
    accuracy: 4,
  },
  {
    postcode: 4102,
    place_name: 'Dutton Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4966,
    longitude: 153.028,
    accuracy: 4,
  },
  {
    postcode: 4102,
    place_name: 'Buranda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5202,
    longitude: 153.0278,
    accuracy: 3,
  },
  {
    postcode: 4103,
    place_name: 'Fairfield Gardens',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5094,
    longitude: 153.0286,
    accuracy: 3,
  },
  {
    postcode: 4103,
    place_name: 'Fairfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5064,
    longitude: 153.0248,
    accuracy: 4,
  },
  {
    postcode: 4103,
    place_name: 'Annerley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5123,
    longitude: 153.0325,
    accuracy: 4,
  },
  {
    postcode: 4104,
    place_name: 'Yeronga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5157,
    longitude: 153.0164,
    accuracy: 4,
  },
  {
    postcode: 4105,
    place_name: 'Tennyson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 153.0167,
    accuracy: 4,
  },
  {
    postcode: 4105,
    place_name: 'Moorooka',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5344,
    longitude: 153.0246,
    accuracy: 4,
  },
  {
    postcode: 4105,
    place_name: 'Yeerongpilly',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5215,
    longitude: 153.0123,
    accuracy: 4,
  },
  {
    postcode: 4106,
    place_name: 'Brisbane Market',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.547,
    longitude: 153.0022,
    accuracy: 3,
  },
  {
    postcode: 4106,
    place_name: 'Rocklea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5392,
    longitude: 153.004,
    accuracy: 4,
  },
  {
    postcode: 4106,
    place_name: 'Rocklea Dc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.547,
    longitude: 153.0022,
    accuracy: 3,
  },
  {
    postcode: 4107,
    place_name: 'Salisbury',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5514,
    longitude: 153.0325,
    accuracy: 4,
  },
  {
    postcode: 4107,
    place_name: 'Salisbury East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5514,
    longitude: 153.0325,
    accuracy: 3,
  },
  {
    postcode: 4108,
    place_name: 'Archerfield BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5202,
    longitude: 153.0278,
    accuracy: 3,
  },
  {
    postcode: 4108,
    place_name: 'Archerfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5671,
    longitude: 153.0145,
    accuracy: 4,
  },
  {
    postcode: 4108,
    place_name: 'Coopers Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5646,
    longitude: 153.0407,
    accuracy: 4,
  },
  {
    postcode: 4109,
    place_name: 'Macgregor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5649,
    longitude: 153.0665,
    accuracy: 4,
  },
  {
    postcode: 4109,
    place_name: 'Robertson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5658,
    longitude: 153.0574,
    accuracy: 4,
  },
  {
    postcode: 4109,
    place_name: 'Sunnybank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5833,
    longitude: 153.05,
    accuracy: 4,
  },
  {
    postcode: 4109,
    place_name: 'Sunnybank Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6103,
    longitude: 153.0539,
    accuracy: 4,
  },
  {
    postcode: 4109,
    place_name: 'Sunnybank South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5741,
    longitude: 153.0583,
    accuracy: 3,
  },
  {
    postcode: 4110,
    place_name: 'Willawong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5972,
    longitude: 153.0074,
    accuracy: 4,
  },
  {
    postcode: 4110,
    place_name: 'Larapinta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6426,
    longitude: 153.0052,
    accuracy: 4,
  },
  {
    postcode: 4110,
    place_name: 'Heathwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6362,
    longitude: 152.9843,
    accuracy: 4,
  },
  {
    postcode: 4110,
    place_name: 'Acacia Ridge Dc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6116,
    longitude: 153.0092,
    accuracy: 3,
  },
  {
    postcode: 4110,
    place_name: 'Acacia Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5833,
    longitude: 153.0333,
    accuracy: 4,
  },
  {
    postcode: 4110,
    place_name: 'Heathwood Df',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.616,
    longitude: 153.0072,
    accuracy: 3,
  },
  {
    postcode: 4110,
    place_name: 'Pallara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6208,
    longitude: 153.0058,
    accuracy: 4,
  },
  {
    postcode: 4111,
    place_name: 'Nathan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5589,
    longitude: 153.0623,
    accuracy: 4,
  },
  {
    postcode: 4112,
    place_name: 'Kuraby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6068,
    longitude: 153.0937,
    accuracy: 4,
  },
  {
    postcode: 4113,
    place_name: 'Eight Mile Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5833,
    longitude: 153.1,
    accuracy: 4,
  },
  {
    postcode: 4113,
    place_name: 'Runcorn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5976,
    longitude: 153.077,
    accuracy: 4,
  },
  {
    postcode: 4114,
    place_name: 'Logan City Dc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6463,
    longitude: 153.1111,
    accuracy: 3,
  },
  {
    postcode: 4114,
    place_name: 'Kingston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6667,
    longitude: 153.1167,
    accuracy: 4,
  },
  {
    postcode: 4114,
    place_name: 'Woodridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6333,
    longitude: 153.1,
    accuracy: 4,
  },
  {
    postcode: 4114,
    place_name: 'Logan Central',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6438,
    longitude: 153.1073,
    accuracy: 4,
  },
  {
    postcode: 4115,
    place_name: 'Algester',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6128,
    longitude: 153.0324,
    accuracy: 4,
  },
  {
    postcode: 4115,
    place_name: 'Parkinson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6434,
    longitude: 153.0298,
    accuracy: 4,
  },
  {
    postcode: 4116,
    place_name: 'Stretton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6221,
    longitude: 153.0661,
    accuracy: 4,
  },
  {
    postcode: 4116,
    place_name: 'Calamvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6233,
    longitude: 153.0479,
    accuracy: 4,
  },
  {
    postcode: 4116,
    place_name: 'Drewvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6477,
    longitude: 153.0546,
    accuracy: 4,
  },
  {
    postcode: 4117,
    place_name: 'Berrinba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6585,
    longitude: 153.0789,
    accuracy: 4,
  },
  {
    postcode: 4117,
    place_name: 'Karawatha',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6199,
    longitude: 153.091,
    accuracy: 4,
  },
  {
    postcode: 4118,
    place_name: 'Forestdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6633,
    longitude: 153.0038,
    accuracy: 4,
  },
  {
    postcode: 4118,
    place_name: 'Browns Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.65,
    longitude: 153.0333,
    accuracy: 4,
  },
  {
    postcode: 4118,
    place_name: 'Regents Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6766,
    longitude: 153.0417,
    accuracy: 4,
  },
  {
    postcode: 4118,
    place_name: 'Heritage Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6827,
    longitude: 153.0608,
    accuracy: 4,
  },
  {
    postcode: 4118,
    place_name: 'Hillcrest',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6686,
    longitude: 153.0261,
    accuracy: 4,
  },
  {
    postcode: 4119,
    place_name: 'Underwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6089,
    longitude: 153.1113,
    accuracy: 4,
  },
  {
    postcode: 4120,
    place_name: 'Greenslopes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5082,
    longitude: 153.0495,
    accuracy: 4,
  },
  {
    postcode: 4120,
    place_name: 'Stones Corner',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5071,
    longitude: 153.0477,
    accuracy: 3,
  },
  {
    postcode: 4121,
    place_name: 'Holland Park West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5262,
    longitude: 153.0605,
    accuracy: 4,
  },
  {
    postcode: 4121,
    place_name: 'Holland Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5262,
    longitude: 153.0605,
    accuracy: 4,
  },
  {
    postcode: 4121,
    place_name: 'Tarragindi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 153.0333,
    accuracy: 4,
  },
  {
    postcode: 4121,
    place_name: 'Holland Park East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5298,
    longitude: 153.0511,
    accuracy: 3,
  },
  {
    postcode: 4121,
    place_name: 'Wellers Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 153.05,
    accuracy: 4,
  },
  {
    postcode: 4122,
    place_name: 'Upper Mount Gravatt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5613,
    longitude: 153.0845,
    accuracy: 4,
  },
  {
    postcode: 4122,
    place_name: 'Mansfield DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5439,
    longitude: 153.0922,
    accuracy: 3,
  },
  {
    postcode: 4122,
    place_name: 'Mansfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5393,
    longitude: 153.099,
    accuracy: 4,
  },
  {
    postcode: 4122,
    place_name: 'Mount Gravatt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 153.0833,
    accuracy: 4,
  },
  {
    postcode: 4122,
    place_name: 'Wishart',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5545,
    longitude: 153.1011,
    accuracy: 4,
  },
  {
    postcode: 4122,
    place_name: 'Upper Mount Gravatt BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5439,
    longitude: 153.0922,
    accuracy: 3,
  },
  {
    postcode: 4122,
    place_name: 'Mount Gravatt East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5405,
    longitude: 153.0822,
    accuracy: 4,
  },
  {
    postcode: 4122,
    place_name: 'Mansfield BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5439,
    longitude: 153.0922,
    accuracy: 3,
  },
  {
    postcode: 4123,
    place_name: 'Rochedale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5667,
    longitude: 153.1333,
    accuracy: 4,
  },
  {
    postcode: 4123,
    place_name: 'Rochedale South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5952,
    longitude: 153.1233,
    accuracy: 4,
  },
  {
    postcode: 4124,
    place_name: 'New Beith',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7936,
    longitude: 152.8844,
    accuracy: 4,
  },
  {
    postcode: 4124,
    place_name: 'Lyons',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7335,
    longitude: 152.8523,
    accuracy: 4,
  },
  {
    postcode: 4124,
    place_name: 'Greenbank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7167,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4124,
    place_name: 'Boronia Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6885,
    longitude: 153.0196,
    accuracy: 4,
  },
  {
    postcode: 4124,
    place_name: 'Spring Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6892,
    longitude: 152.8954,
    accuracy: 4,
  },
  {
    postcode: 4125,
    place_name: 'Park Ridge South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7271,
    longitude: 153.0332,
    accuracy: 4,
  },
  {
    postcode: 4125,
    place_name: 'Munruben',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7463,
    longitude: 153.0312,
    accuracy: 4,
  },
  {
    postcode: 4125,
    place_name: 'Park Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7167,
    longitude: 153.0333,
    accuracy: 4,
  },
  {
    postcode: 4127,
    place_name: 'Springwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6118,
    longitude: 153.129,
    accuracy: 4,
  },
  {
    postcode: 4127,
    place_name: 'Daisy Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6303,
    longitude: 153.1578,
    accuracy: 4,
  },
  {
    postcode: 4127,
    place_name: 'Slacks Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.65,
    longitude: 153.15,
    accuracy: 4,
  },
  {
    postcode: 4127,
    place_name: 'Priestdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 153.1333,
    accuracy: 4,
  },
  {
    postcode: 4128,
    place_name: 'Shailer Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6498,
    longitude: 153.1775,
    accuracy: 4,
  },
  {
    postcode: 4128,
    place_name: 'Tanah Merah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6715,
    longitude: 153.1702,
    accuracy: 4,
  },
  {
    postcode: 4129,
    place_name: 'Loganholme',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7,
    longitude: 153.2,
    accuracy: 4,
  },
  {
    postcode: 4129,
    place_name: 'Loganholme DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7,
    longitude: 153.2,
    accuracy: 3,
  },
  {
    postcode: 4129,
    place_name: 'Loganholme BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7,
    longitude: 153.2,
    accuracy: 3,
  },
  {
    postcode: 4130,
    place_name: 'Cornubia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6667,
    longitude: 153.2167,
    accuracy: 4,
  },
  {
    postcode: 4130,
    place_name: 'Carbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6833,
    longitude: 153.25,
    accuracy: 4,
  },
  {
    postcode: 4131,
    place_name: 'Loganlea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6667,
    longitude: 153.1333,
    accuracy: 4,
  },
  {
    postcode: 4131,
    place_name: 'Meadowbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.664,
    longitude: 153.1447,
    accuracy: 4,
  },
  {
    postcode: 4132,
    place_name: 'Marsden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6734,
    longitude: 153.0973,
    accuracy: 4,
  },
  {
    postcode: 4132,
    place_name: 'Crestmead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6876,
    longitude: 153.0844,
    accuracy: 4,
  },
  {
    postcode: 4133,
    place_name: 'Waterford West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6906,
    longitude: 153.1332,
    accuracy: 4,
  },
  {
    postcode: 4133,
    place_name: 'Waterford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7,
    longitude: 153.15,
    accuracy: 4,
  },
  {
    postcode: 4133,
    place_name: 'Chambers Flat',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7481,
    longitude: 153.0776,
    accuracy: 4,
  },
  {
    postcode: 4133,
    place_name: 'Logan Reserve',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7241,
    longitude: 153.09,
    accuracy: 4,
  },
  {
    postcode: 4151,
    place_name: 'Coorparoo DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4932,
    longitude: 153.0583,
    accuracy: 3,
  },
  {
    postcode: 4151,
    place_name: 'Coorparoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4932,
    longitude: 153.0583,
    accuracy: 4,
  },
  {
    postcode: 4152,
    place_name: 'Carindale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5058,
    longitude: 153.1024,
    accuracy: 4,
  },
  {
    postcode: 4152,
    place_name: 'Carina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5072,
    longitude: 153.0913,
    accuracy: 4,
  },
  {
    postcode: 4152,
    place_name: 'Carina Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5072,
    longitude: 153.0913,
    accuracy: 4,
  },
  {
    postcode: 4152,
    place_name: 'Camp Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4935,
    longitude: 153.0763,
    accuracy: 4,
  },
  {
    postcode: 4153,
    place_name: 'Belmont',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5,
    longitude: 153.1167,
    accuracy: 4,
  },
  {
    postcode: 4154,
    place_name: 'Ransome',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4881,
    longitude: 153.1793,
    accuracy: 4,
  },
  {
    postcode: 4154,
    place_name: 'Wakerley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.483,
    longitude: 153.1559,
    accuracy: 4,
  },
  {
    postcode: 4154,
    place_name: 'Gumdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4919,
    longitude: 153.1532,
    accuracy: 4,
  },
  {
    postcode: 4155,
    place_name: 'Chandler',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.514,
    longitude: 153.1491,
    accuracy: 4,
  },
  {
    postcode: 4156,
    place_name: 'Mackenzie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5435,
    longitude: 153.1206,
    accuracy: 4,
  },
  {
    postcode: 4156,
    place_name: 'Burbank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5558,
    longitude: 153.147,
    accuracy: 4,
  },
  {
    postcode: 4157,
    place_name: 'Sheldon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5844,
    longitude: 153.2004,
    accuracy: 4,
  },
  {
    postcode: 4157,
    place_name: 'Capalaba West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.56,
    longitude: 153.1993,
    accuracy: 3,
  },
  {
    postcode: 4157,
    place_name: 'Capalaba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5167,
    longitude: 153.1833,
    accuracy: 4,
  },
  {
    postcode: 4157,
    place_name: 'Capalaba DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5505,
    longitude: 153.1919,
    accuracy: 3,
  },
  {
    postcode: 4157,
    place_name: 'Capalaba BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5505,
    longitude: 153.1919,
    accuracy: 3,
  },
  {
    postcode: 4158,
    place_name: 'Thorneside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4878,
    longitude: 153.1982,
    accuracy: 4,
  },
  {
    postcode: 4159,
    place_name: 'Birkdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 153.2167,
    accuracy: 4,
  },
  {
    postcode: 4160,
    place_name: 'Wellington Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 153.25,
    accuracy: 4,
  },
  {
    postcode: 4160,
    place_name: 'Ormiston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5167,
    longitude: 153.25,
    accuracy: 4,
  },
  {
    postcode: 4161,
    place_name: 'Alexandra Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5322,
    longitude: 153.2289,
    accuracy: 4,
  },
  {
    postcode: 4163,
    place_name: 'Cleveland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5268,
    longitude: 153.2652,
    accuracy: 4,
  },
  {
    postcode: 4163,
    place_name: 'Cleveland DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 153.2667,
    accuracy: 3,
  },
  {
    postcode: 4164,
    place_name: 'Thornlands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5575,
    longitude: 153.2648,
    accuracy: 4,
  },
  {
    postcode: 4165,
    place_name: 'Mount Cotton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6333,
    longitude: 153.2333,
    accuracy: 4,
  },
  {
    postcode: 4165,
    place_name: 'Redland Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6113,
    longitude: 153.3002,
    accuracy: 4,
  },
  {
    postcode: 4165,
    place_name: 'Victoria Point West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.61,
    longitude: 153.2825,
    accuracy: 3,
  },
  {
    postcode: 4165,
    place_name: 'Victoria Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5833,
    longitude: 153.3117,
    accuracy: 4,
  },
  {
    postcode: 4169,
    place_name: 'East Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.481,
    longitude: 153.044,
    accuracy: 4,
  },
  {
    postcode: 4169,
    place_name: 'Kangaroo Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4768,
    longitude: 153.0367,
    accuracy: 4,
  },
  {
    postcode: 4170,
    place_name: 'Norman Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4792,
    longitude: 153.0625,
    accuracy: 4,
  },
  {
    postcode: 4170,
    place_name: 'Seven Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4834,
    longitude: 153.0747,
    accuracy: 4,
  },
  {
    postcode: 4170,
    place_name: 'Cannon Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4724,
    longitude: 153.0947,
    accuracy: 4,
  },
  {
    postcode: 4170,
    place_name: 'Morningside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4673,
    longitude: 153.0732,
    accuracy: 4,
  },
  {
    postcode: 4171,
    place_name: 'Balmoral',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4595,
    longitude: 153.0638,
    accuracy: 4,
  },
  {
    postcode: 4171,
    place_name: 'Bulimba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.45,
    longitude: 153.0667,
    accuracy: 4,
  },
  {
    postcode: 4171,
    place_name: 'Hawthorne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4633,
    longitude: 153.0595,
    accuracy: 4,
  },
  {
    postcode: 4172,
    place_name: 'Murarrie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4629,
    longitude: 153.0981,
    accuracy: 4,
  },
  {
    postcode: 4173,
    place_name: 'Tingalpa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4736,
    longitude: 153.127,
    accuracy: 4,
  },
  {
    postcode: 4173,
    place_name: 'Tingalpa Bc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4594,
    longitude: 153.1211,
    accuracy: 3,
  },
  {
    postcode: 4173,
    place_name: 'Tingalpa Dc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4594,
    longitude: 153.1211,
    accuracy: 3,
  },
  {
    postcode: 4174,
    place_name: 'Hemmant',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4478,
    longitude: 153.1317,
    accuracy: 4,
  },
  {
    postcode: 4178,
    place_name: 'Wynnum North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4191,
    longitude: 153.1677,
    accuracy: 3,
  },
  {
    postcode: 4178,
    place_name: 'Wynnum Plaza',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4333,
    longitude: 153.1667,
    accuracy: 3,
  },
  {
    postcode: 4178,
    place_name: 'Wynnum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4453,
    longitude: 153.1581,
    accuracy: 4,
  },
  {
    postcode: 4178,
    place_name: 'Port Of Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3903,
    longitude: 153.1772,
    accuracy: 4,
  },
  {
    postcode: 4178,
    place_name: 'Lytton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.423,
    longitude: 153.1511,
    accuracy: 4,
  },
  {
    postcode: 4178,
    place_name: 'Wynnum West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4582,
    longitude: 153.1532,
    accuracy: 4,
  },
  {
    postcode: 4179,
    place_name: 'Lota',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4692,
    longitude: 153.1858,
    accuracy: 4,
  },
  {
    postcode: 4179,
    place_name: 'Manly',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4667,
    longitude: 153.1833,
    accuracy: 4,
  },
  {
    postcode: 4179,
    place_name: 'Manly West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4738,
    longitude: 153.1664,
    accuracy: 4,
  },
  {
    postcode: 4183,
    place_name: 'North Stradbroke Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4332,
    longitude: 153.4627,
    accuracy: 4,
  },
  {
    postcode: 4183,
    place_name: 'Point Lookout',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4273,
    longitude: 153.5366,
    accuracy: 4,
  },
  {
    postcode: 4183,
    place_name: 'Amity Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3982,
    longitude: 153.438,
    accuracy: 4,
  },
  {
    postcode: 4183,
    place_name: 'Amity',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3982,
    longitude: 153.438,
    accuracy: 4,
  },
  {
    postcode: 4183,
    place_name: 'Dunwich',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4984,
    longitude: 153.402,
    accuracy: 4,
  },
  {
    postcode: 4184,
    place_name: 'Peel Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.651,
    longitude: 153.363,
    accuracy: 3,
  },
  {
    postcode: 4184,
    place_name: 'Lamb Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6248,
    longitude: 153.3802,
    accuracy: 4,
  },
  {
    postcode: 4184,
    place_name: 'Perulpa Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6019,
    longitude: 153.3569,
    accuracy: 3,
  },
  {
    postcode: 4184,
    place_name: 'Macleay Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6109,
    longitude: 153.36,
    accuracy: 4,
  },
  {
    postcode: 4184,
    place_name: 'Karragarra Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6368,
    longitude: 153.3681,
    accuracy: 4,
  },
  {
    postcode: 4184,
    place_name: 'Coochiemudlo Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5736,
    longitude: 153.3302,
    accuracy: 4,
  },
  {
    postcode: 4184,
    place_name: 'Russell Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6487,
    longitude: 153.3812,
    accuracy: 4,
  },
  {
    postcode: 4205,
    place_name: 'Bethania',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6833,
    longitude: 153.1667,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Cedar Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8618,
    longitude: 153.1999,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Yarrabilba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8234,
    longitude: 153.0965,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Eagleby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6833,
    longitude: 153.2167,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Alberton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7,
    longitude: 153.25,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Bahrs Scrub',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7333,
    longitude: 153.1667,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Buccan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.75,
    longitude: 153.1333,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Mount Warren Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7311,
    longitude: 153.2055,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Bannockburn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7599,
    longitude: 153.1922,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Wolffdene',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7833,
    longitude: 153.1833,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Belivah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7582,
    longitude: 153.175,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Holmview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7163,
    longitude: 153.1705,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Steiglitz',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.75,
    longitude: 153.35,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Logan Village',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7683,
    longitude: 153.1076,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Windaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7333,
    longitude: 153.2,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Yatala',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7167,
    longitude: 153.2167,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Woongoolba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.75,
    longitude: 153.3333,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Edens Landing',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7027,
    longitude: 153.1694,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Stapylton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7333,
    longitude: 153.2333,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Luscombe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7755,
    longitude: 153.2064,
    accuracy: 4,
  },
  {
    postcode: 4207,
    place_name: 'Yatala DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7079,
    longitude: 153.1842,
    accuracy: 3,
  },
  {
    postcode: 4207,
    place_name: 'Beenleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7114,
    longitude: 153.2029,
    accuracy: 4,
  },
  {
    postcode: 4208,
    place_name: 'Ormeau Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7965,
    longitude: 153.2567,
    accuracy: 4,
  },
  {
    postcode: 4208,
    place_name: 'Norwell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7833,
    longitude: 153.3167,
    accuracy: 4,
  },
  {
    postcode: 4208,
    place_name: 'Gilberton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7333,
    longitude: 153.2833,
    accuracy: 4,
  },
  {
    postcode: 4208,
    place_name: 'Ormeau',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7679,
    longitude: 153.2428,
    accuracy: 4,
  },
  {
    postcode: 4208,
    place_name: 'Jacobs Well',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7799,
    longitude: 153.3615,
    accuracy: 4,
  },
  {
    postcode: 4208,
    place_name: 'Kingsholme',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8254,
    longitude: 153.2335,
    accuracy: 4,
  },
  {
    postcode: 4209,
    place_name: 'Willow Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.845,
    longitude: 153.2631,
    accuracy: 4,
  },
  {
    postcode: 4209,
    place_name: 'Pimpama',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8167,
    longitude: 153.3,
    accuracy: 4,
  },
  {
    postcode: 4209,
    place_name: 'Coomera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8833,
    longitude: 153.3,
    accuracy: 4,
  },
  {
    postcode: 4209,
    place_name: 'Upper Coomera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9,
    longitude: 153.3,
    accuracy: 4,
  },
  {
    postcode: 4210,
    place_name: 'Wongawallan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8755,
    longitude: 153.2287,
    accuracy: 4,
  },
  {
    postcode: 4210,
    place_name: 'Studio Village',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9079,
    longitude: 153.2566,
    accuracy: 3,
  },
  {
    postcode: 4210,
    place_name: 'Guanaba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9381,
    longitude: 153.2393,
    accuracy: 4,
  },
  {
    postcode: 4210,
    place_name: 'Oxenford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8903,
    longitude: 153.3131,
    accuracy: 4,
  },
  {
    postcode: 4210,
    place_name: 'Maudsland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.95,
    longitude: 153.2667,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Beechmont',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1214,
    longitude: 153.192,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Gaven',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9565,
    longitude: 153.3345,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Southern Lamington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0195,
    longitude: 153.2946,
    accuracy: 3,
  },
  {
    postcode: 4211,
    place_name: 'Highland Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0139,
    longitude: 153.3331,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Nerang',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9894,
    longitude: 153.3363,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Gilston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0333,
    longitude: 153.3,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Clagiraba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9939,
    longitude: 153.2424,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Numinbah Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1417,
    longitude: 153.2231,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Nerang BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0195,
    longitude: 153.2946,
    accuracy: 3,
  },
  {
    postcode: 4211,
    place_name: 'Mount Nathan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9643,
    longitude: 153.272,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Binna Burra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0195,
    longitude: 153.2946,
    accuracy: 3,
  },
  {
    postcode: 4211,
    place_name: 'Advancetown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0667,
    longitude: 153.2667,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Natural Bridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2212,
    longitude: 153.236,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Pacific Pines',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9399,
    longitude: 153.3144,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Lower Beechmont',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0475,
    longitude: 153.2457,
    accuracy: 4,
  },
  {
    postcode: 4211,
    place_name: 'Nerang DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0195,
    longitude: 153.2946,
    accuracy: 3,
  },
  {
    postcode: 4211,
    place_name: 'Carrara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0215,
    longitude: 153.3664,
    accuracy: 4,
  },
  {
    postcode: 4212,
    place_name: 'Helensvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9183,
    longitude: 153.3327,
    accuracy: 4,
  },
  {
    postcode: 4212,
    place_name: 'Sanctuary Cove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.86,
    longitude: 153.3719,
    accuracy: 4,
  },
  {
    postcode: 4212,
    place_name: 'Helensvale Town Centre',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8875,
    longitude: 153.3463,
    accuracy: 3,
  },
  {
    postcode: 4212,
    place_name: 'Hope Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8704,
    longitude: 153.3516,
    accuracy: 4,
  },
  {
    postcode: 4213,
    place_name: 'Worongary',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.05,
    longitude: 153.35,
    accuracy: 4,
  },
  {
    postcode: 4213,
    place_name: 'Austinville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1545,
    longitude: 153.3092,
    accuracy: 4,
  },
  {
    postcode: 4213,
    place_name: 'Neranwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1248,
    longitude: 153.2983,
    accuracy: 4,
  },
  {
    postcode: 4213,
    place_name: 'Tallai',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0641,
    longitude: 153.3259,
    accuracy: 4,
  },
  {
    postcode: 4213,
    place_name: 'Springbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1917,
    longitude: 153.263,
    accuracy: 4,
  },
  {
    postcode: 4213,
    place_name: 'Mudgeeraba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0833,
    longitude: 153.3667,
    accuracy: 4,
  },
  {
    postcode: 4213,
    place_name: 'Bonogin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1393,
    longitude: 153.353,
    accuracy: 4,
  },
  {
    postcode: 4214,
    place_name: 'Molendinar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9741,
    longitude: 153.3607,
    accuracy: 4,
  },
  {
    postcode: 4214,
    place_name: 'Ashmore City',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9638,
    longitude: 153.3658,
    accuracy: 3,
  },
  {
    postcode: 4214,
    place_name: 'Parkwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9546,
    longitude: 153.363,
    accuracy: 4,
  },
  {
    postcode: 4214,
    place_name: 'Ashmore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9888,
    longitude: 153.3765,
    accuracy: 4,
  },
  {
    postcode: 4214,
    place_name: 'Arundel',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9377,
    longitude: 153.363,
    accuracy: 4,
  },
  {
    postcode: 4214,
    place_name: 'Arundel Bc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9631,
    longitude: 153.3656,
    accuracy: 3,
  },
  {
    postcode: 4214,
    place_name: 'Arundel Dc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9631,
    longitude: 153.3656,
    accuracy: 3,
  },
  {
    postcode: 4215,
    place_name: 'Southport',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9672,
    longitude: 153.398,
    accuracy: 4,
  },
  {
    postcode: 4215,
    place_name: 'Labrador',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.944,
    longitude: 153.3982,
    accuracy: 4,
  },
  {
    postcode: 4215,
    place_name: 'Australia Fair',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9553,
    longitude: 153.3991,
    accuracy: 3,
  },
  {
    postcode: 4215,
    place_name: 'Southport BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9553,
    longitude: 153.3991,
    accuracy: 3,
  },
  {
    postcode: 4215,
    place_name: 'Southport Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9553,
    longitude: 153.3991,
    accuracy: 3,
  },
  {
    postcode: 4215,
    place_name: 'Chirn Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9553,
    longitude: 153.3991,
    accuracy: 3,
  },
  {
    postcode: 4216,
    place_name: 'South Stradbroke',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8329,
    longitude: 153.4207,
    accuracy: 4,
  },
  {
    postcode: 4216,
    place_name: 'Runaway Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9139,
    longitude: 153.3977,
    accuracy: 4,
  },
  {
    postcode: 4216,
    place_name: 'Paradise Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8866,
    longitude: 153.3933,
    accuracy: 4,
  },
  {
    postcode: 4216,
    place_name: 'Biggera Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9324,
    longitude: 153.4002,
    accuracy: 4,
  },
  {
    postcode: 4216,
    place_name: 'Coombabah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9107,
    longitude: 153.3709,
    accuracy: 4,
  },
  {
    postcode: 4216,
    place_name: 'Hollywell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9008,
    longitude: 153.3961,
    accuracy: 4,
  },
  {
    postcode: 4217,
    place_name: 'Main Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9788,
    longitude: 153.4266,
    accuracy: 4,
  },
  {
    postcode: 4217,
    place_name: 'Surfers Paradise',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0027,
    longitude: 153.43,
    accuracy: 4,
  },
  {
    postcode: 4217,
    place_name: 'Bundall',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0111,
    longitude: 153.4048,
    accuracy: 4,
  },
  {
    postcode: 4217,
    place_name: 'Benowa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0167,
    longitude: 153.4,
    accuracy: 4,
  },
  {
    postcode: 4217,
    place_name: 'Isle Of Capri',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9996,
    longitude: 153.4093,
    accuracy: 3,
  },
  {
    postcode: 4217,
    place_name: 'Bundall BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0023,
    longitude: 153.4153,
    accuracy: 3,
  },
  {
    postcode: 4217,
    place_name: 'Bundall DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0023,
    longitude: 153.4153,
    accuracy: 3,
  },
  {
    postcode: 4217,
    place_name: 'Gold Coast Mc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0023,
    longitude: 153.4153,
    accuracy: 3,
  },
  {
    postcode: 4217,
    place_name: 'Chevron Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9996,
    longitude: 153.4093,
    accuracy: 3,
  },
  {
    postcode: 4218,
    place_name: 'Broadbeach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0278,
    longitude: 153.4334,
    accuracy: 4,
  },
  {
    postcode: 4218,
    place_name: 'Mermaid Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0441,
    longitude: 153.4347,
    accuracy: 4,
  },
  {
    postcode: 4218,
    place_name: 'Mermaid Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0491,
    longitude: 153.4308,
    accuracy: 4,
  },
  {
    postcode: 4218,
    place_name: 'Broadbeach Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.035,
    longitude: 153.425,
    accuracy: 4,
  },
  {
    postcode: 4218,
    place_name: 'Nobby Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0396,
    longitude: 153.4217,
    accuracy: 3,
  },
  {
    postcode: 4218,
    place_name: 'Q Supercentre',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0396,
    longitude: 153.4217,
    accuracy: 3,
  },
  {
    postcode: 4218,
    place_name: 'Pacific Fair',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0396,
    longitude: 153.4217,
    accuracy: 3,
  },
  {
    postcode: 4219,
    place_name: 'West Burleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1167,
    longitude: 153.4333,
    accuracy: 4,
  },
  {
    postcode: 4220,
    place_name: 'Miami',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0717,
    longitude: 153.4416,
    accuracy: 4,
  },
  {
    postcode: 4220,
    place_name: 'Burleigh Town',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0895,
    longitude: 153.4341,
    accuracy: 3,
  },
  {
    postcode: 4220,
    place_name: 'Burleigh Heads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1,
    longitude: 153.45,
    accuracy: 4,
  },
  {
    postcode: 4220,
    place_name: 'Burleigh Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0998,
    longitude: 153.4246,
    accuracy: 4,
  },
  {
    postcode: 4220,
    place_name: 'Burleigh DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0859,
    longitude: 153.4458,
    accuracy: 3,
  },
  {
    postcode: 4221,
    place_name: 'Elanora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1358,
    longitude: 153.4493,
    accuracy: 4,
  },
  {
    postcode: 4221,
    place_name: 'Palm Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1169,
    longitude: 153.4658,
    accuracy: 4,
  },
  {
    postcode: 4222,
    place_name: 'Griffith University',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1235,
    longitude: 153.4442,
    accuracy: 1,
  },
  {
    postcode: 4223,
    place_name: 'Currumbin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1407,
    longitude: 153.45,
    accuracy: 4,
  },
  {
    postcode: 4223,
    place_name: 'Currumbin Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1544,
    longitude: 153.4728,
    accuracy: 4,
  },
  {
    postcode: 4223,
    place_name: 'Currumbin Dc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1947,
    longitude: 153.4129,
    accuracy: 3,
  },
  {
    postcode: 4223,
    place_name: 'Currumbin Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2081,
    longitude: 153.3942,
    accuracy: 4,
  },
  {
    postcode: 4224,
    place_name: 'Tugun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.15,
    longitude: 153.5,
    accuracy: 4,
  },
  {
    postcode: 4225,
    place_name: 'Coolangatta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1694,
    longitude: 153.5347,
    accuracy: 4,
  },
  {
    postcode: 4225,
    place_name: 'Bilinga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1667,
    longitude: 153.5167,
    accuracy: 4,
  },
  {
    postcode: 4226,
    place_name: 'Robina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0707,
    longitude: 153.3933,
    accuracy: 4,
  },
  {
    postcode: 4226,
    place_name: 'Clear Island Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0531,
    longitude: 153.3981,
    accuracy: 4,
  },
  {
    postcode: 4226,
    place_name: 'Robina DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0642,
    longitude: 153.3794,
    accuracy: 3,
  },
  {
    postcode: 4226,
    place_name: 'Merrimac',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0509,
    longitude: 153.3731,
    accuracy: 4,
  },
  {
    postcode: 4227,
    place_name: 'Reedy Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1124,
    longitude: 153.3964,
    accuracy: 4,
  },
  {
    postcode: 4227,
    place_name: 'Varsity Lakes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0894,
    longitude: 153.4122,
    accuracy: 4,
  },
  {
    postcode: 4228,
    place_name: 'Tallebudgera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.15,
    longitude: 153.4333,
    accuracy: 4,
  },
  {
    postcode: 4228,
    place_name: 'Tallebudgera Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1927,
    longitude: 153.3549,
    accuracy: 4,
  },
  {
    postcode: 4229,
    place_name: 'Bond University',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0779,
    longitude: 153.4123,
    accuracy: 1,
  },
  {
    postcode: 4230,
    place_name: 'Robina Town Centre',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0757,
    longitude: 153.3842,
    accuracy: 1,
  },
  {
    postcode: 4270,
    place_name: 'Tamborine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8833,
    longitude: 153.1333,
    accuracy: 4,
  },
  {
    postcode: 4271,
    place_name: 'Eagle Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9193,
    longitude: 153.1994,
    accuracy: 4,
  },
  {
    postcode: 4272,
    place_name: 'Mount Tamborine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9695,
    longitude: 153.1994,
    accuracy: 4,
  },
  {
    postcode: 4272,
    place_name: 'Tamborine Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9695,
    longitude: 153.1994,
    accuracy: 4,
  },
  {
    postcode: 4272,
    place_name: 'North Tamborine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9276,
    longitude: 153.1848,
    accuracy: 4,
  },
  {
    postcode: 4275,
    place_name: 'Sarabah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9973,
    longitude: 153.1642,
    accuracy: 3,
  },
  {
    postcode: 4275,
    place_name: 'Witheren',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0392,
    longitude: 153.1916,
    accuracy: 4,
  },
  {
    postcode: 4275,
    place_name: 'Boyland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.95,
    longitude: 153.1333,
    accuracy: 4,
  },
  {
    postcode: 4275,
    place_name: 'Benobble',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9833,
    longitude: 153.1667,
    accuracy: 4,
  },
  {
    postcode: 4275,
    place_name: 'Wonglepong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9618,
    longitude: 153.1749,
    accuracy: 3,
  },
  {
    postcode: 4275,
    place_name: 'Canungra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0169,
    longitude: 153.1653,
    accuracy: 4,
  },
  {
    postcode: 4275,
    place_name: 'Lamington National Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1139,
    longitude: 153.134,
    accuracy: 3,
  },
  {
    postcode: 4275,
    place_name: 'Ferny Glen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9973,
    longitude: 153.1642,
    accuracy: 3,
  },
  {
    postcode: 4275,
    place_name: 'Flying Fox',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9973,
    longitude: 153.1642,
    accuracy: 3,
  },
  {
    postcode: 4275,
    place_name: 'Illinbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9973,
    longitude: 153.1642,
    accuracy: 3,
  },
  {
    postcode: 4275,
    place_name: 'Biddaddaba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9973,
    longitude: 153.1642,
    accuracy: 3,
  },
  {
    postcode: 4280,
    place_name: 'Jimboomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8312,
    longitude: 153.0274,
    accuracy: 4,
  },
  {
    postcode: 4280,
    place_name: 'South Maclean',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7924,
    longitude: 153.016,
    accuracy: 4,
  },
  {
    postcode: 4280,
    place_name: 'North Maclean',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7676,
    longitude: 153.0165,
    accuracy: 4,
  },
  {
    postcode: 4280,
    place_name: 'Stockleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7667,
    longitude: 153.0667,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Veresdale Scrub',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8916,
    longitude: 152.9977,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Darlington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.239,
    longitude: 153.067,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Beaudesert',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9869,
    longitude: 152.9966,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Veresdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9167,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Birnam',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Kerry',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1,
    longitude: 153.0167,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Lamington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.245,
    longitude: 152.9933,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Laravale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0833,
    longitude: 152.9333,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Cedar Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8892,
    longitude: 153.0289,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Oaky Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Tabooba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1333,
    longitude: 152.95,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Mount Gipps',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Kagaru',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8657,
    longitude: 152.9238,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Bromelton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9833,
    longitude: 152.9333,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Josephville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0305,
    longitude: 152.9438,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Kooralbyn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0796,
    longitude: 152.8397,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Tamrookum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1242,
    longitude: 152.9159,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Cryna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Hillview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2137,
    longitude: 153.0123,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Chinghee Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Cedar Grove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8667,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Allenview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Mundoolun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8994,
    longitude: 153.0715,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Christmas Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Cainbable',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Nindooinbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Woodhill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9,
    longitude: 152.9667,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Knapp Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Tamrookum Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Tabragalba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0067,
    longitude: 152.9125,
    accuracy: 3,
  },
  {
    postcode: 4285,
    place_name: 'Innisplain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1667,
    longitude: 152.9,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Gleneagle',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9333,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4285,
    place_name: 'Undullah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8088,
    longitude: 152.9491,
    accuracy: 4,
  },
  {
    postcode: 4287,
    place_name: 'Rathdowney',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2108,
    longitude: 152.8641,
    accuracy: 4,
  },
  {
    postcode: 4287,
    place_name: 'Barney View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2333,
    longitude: 152.7833,
    accuracy: 4,
  },
  {
    postcode: 4287,
    place_name: 'Running Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -14.3097,
    longitude: 143.7279,
    accuracy: 3,
  },
  {
    postcode: 4287,
    place_name: 'Mount Barney',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2369,
    longitude: 152.8158,
    accuracy: 3,
  },
  {
    postcode: 4287,
    place_name: 'Palen Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2667,
    longitude: 152.8,
    accuracy: 4,
  },
  {
    postcode: 4287,
    place_name: 'Mount Lindesay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2369,
    longitude: 152.8158,
    accuracy: 3,
  },
  {
    postcode: 4300,
    place_name: 'Gailes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6113,
    longitude: 152.9132,
    accuracy: 4,
  },
  {
    postcode: 4300,
    place_name: 'Springfield Lakes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6676,
    longitude: 152.9249,
    accuracy: 4,
  },
  {
    postcode: 4300,
    place_name: 'Goodna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6105,
    longitude: 152.899,
    accuracy: 4,
  },
  {
    postcode: 4300,
    place_name: 'Springfield Central',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6805,
    longitude: 152.9042,
    accuracy: 4,
  },
  {
    postcode: 4300,
    place_name: 'Camira',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6346,
    longitude: 152.9209,
    accuracy: 4,
  },
  {
    postcode: 4300,
    place_name: 'Bellbird Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6367,
    longitude: 152.8866,
    accuracy: 4,
  },
  {
    postcode: 4300,
    place_name: 'Springfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6536,
    longitude: 152.9172,
    accuracy: 4,
  },
  {
    postcode: 4300,
    place_name: 'Augustine Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6605,
    longitude: 152.879,
    accuracy: 4,
  },
  {
    postcode: 4300,
    place_name: 'Carole Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6172,
    longitude: 152.9316,
    accuracy: 4,
  },
  {
    postcode: 4300,
    place_name: 'Brookwater',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6613,
    longitude: 152.8967,
    accuracy: 4,
  },
  {
    postcode: 4301,
    place_name: 'Redbank Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6461,
    longitude: 152.8596,
    accuracy: 4,
  },
  {
    postcode: 4301,
    place_name: 'Collingwood Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6128,
    longitude: 152.8601,
    accuracy: 4,
  },
  {
    postcode: 4301,
    place_name: 'Redbank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 152.8667,
    accuracy: 4,
  },
  {
    postcode: 4303,
    place_name: 'Dinmore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 152.8333,
    accuracy: 4,
  },
  {
    postcode: 4303,
    place_name: 'Riverview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 152.85,
    accuracy: 4,
  },
  {
    postcode: 4303,
    place_name: 'New Chum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.618,
    longitude: 152.8326,
    accuracy: 4,
  },
  {
    postcode: 4304,
    place_name: 'North Booval',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6039,
    longitude: 152.7948,
    accuracy: 4,
  },
  {
    postcode: 4304,
    place_name: 'Booval Fair',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6122,
    longitude: 152.8014,
    accuracy: 3,
  },
  {
    postcode: 4304,
    place_name: 'Silkstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6213,
    longitude: 152.7877,
    accuracy: 4,
  },
  {
    postcode: 4304,
    place_name: 'Blackstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6251,
    longitude: 152.8084,
    accuracy: 4,
  },
  {
    postcode: 4304,
    place_name: 'Booval',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6133,
    longitude: 152.7894,
    accuracy: 4,
  },
  {
    postcode: 4304,
    place_name: 'Bundamba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6095,
    longitude: 152.8113,
    accuracy: 4,
  },
  {
    postcode: 4304,
    place_name: 'Ebbw Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 152.8167,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Limestone Ridges',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8365,
    longitude: 152.726,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'North Ipswich',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6029,
    longitude: 152.7615,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Brassall',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5975,
    longitude: 152.7476,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Coalfalls',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6084,
    longitude: 152.7468,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Basin Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5999,
    longitude: 152.7713,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Yamanto',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6603,
    longitude: 152.7386,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Bremer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6204,
    longitude: 152.7567,
    accuracy: 3,
  },
  {
    postcode: 4305,
    place_name: 'East Ipswich',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6064,
    longitude: 152.7723,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'West Ipswich',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6213,
    longitude: 152.7482,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Wulkuraka',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6181,
    longitude: 152.7144,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Moores Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5978,
    longitude: 152.7773,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Leichhardt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6228,
    longitude: 152.7337,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Raceview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6347,
    longitude: 152.7752,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Newtown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6163,
    longitude: 152.7768,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Woodend',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6055,
    longitude: 152.7531,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Ipswich',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6118,
    longitude: 152.7682,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'North Tivoli',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5761,
    longitude: 152.7877,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'One Mile',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.629,
    longitude: 152.7377,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Sadliers Crossing',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6125,
    longitude: 152.7458,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Eastern Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6285,
    longitude: 152.7783,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Flinders View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6505,
    longitude: 152.7745,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Churchill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6423,
    longitude: 152.7502,
    accuracy: 4,
  },
  {
    postcode: 4305,
    place_name: 'Tivoli',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5928,
    longitude: 152.768,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'England Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3362,
    longitude: 152.7616,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Fernvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4548,
    longitude: 152.6532,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'South Ripley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7174,
    longitude: 152.8164,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Blacksoil',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.577,
    longitude: 152.7052,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Karrabin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 152.7167,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Goolman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7333,
    longitude: 152.75,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Wanora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5167,
    longitude: 152.6667,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Muirlea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5667,
    longitude: 152.7167,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Harlin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9803,
    longitude: 152.358,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Blackbutt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8863,
    longitude: 152.1017,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Moore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9005,
    longitude: 152.2934,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Nukku',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8833,
    longitude: 152.0667,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Purga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6592,
    longitude: 152.7158,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Pine Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.55,
    longitude: 152.7,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Peak Crossing',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7837,
    longitude: 152.726,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Ironbark',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5564,
    longitude: 152.6716,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Dundas',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3,
    longitude: 152.6167,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Thagoona',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6333,
    longitude: 152.6333,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Mount Binga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1074,
    longitude: 152.4405,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Fairney View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 152.6667,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Mount Crosby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5395,
    longitude: 152.8058,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Swanbank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6569,
    longitude: 152.8072,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Split Yard Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4218,
    longitude: 152.5998,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Borallon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 152.6833,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Deebing Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6672,
    longitude: 152.7487,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Linville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8443,
    longitude: 152.276,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Benarkin North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8572,
    longitude: 152.1501,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Banks Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4131,
    longitude: 152.7237,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Colinton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9333,
    longitude: 152.3167,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Teelah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1074,
    longitude: 152.4405,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Ripley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7167,
    longitude: 152.8333,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Blackbutt North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8643,
    longitude: 152.1129,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Blackbutt South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8863,
    longitude: 152.1017,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Avoca Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1074,
    longitude: 152.4405,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Walloon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6055,
    longitude: 152.6643,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Lake Manchester',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1074,
    longitude: 152.4405,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Karana Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5486,
    longitude: 152.8073,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Washpool',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4218,
    longitude: 152.5998,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Willowbank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6821,
    longitude: 152.6741,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Cherry Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1074,
    longitude: 152.4405,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Chuwar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5662,
    longitude: 152.7784,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Glamorgan Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5167,
    longitude: 152.6333,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Vernor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4667,
    longitude: 152.6167,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Googa Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1074,
    longitude: 152.4405,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Amberley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6333,
    longitude: 152.7,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Mount Stanley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1074,
    longitude: 152.4405,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Lark Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5265,
    longitude: 152.6031,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'White Rock',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4218,
    longitude: 152.5998,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Barellan Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.572,
    longitude: 152.8438,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Wivenhoe Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4219,
    longitude: 152.6217,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Haigslea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5725,
    longitude: 152.6294,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Taromeo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4218,
    longitude: 152.5998,
    accuracy: 3,
  },
  {
    postcode: 4306,
    place_name: 'Benarkin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8918,
    longitude: 152.1394,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Mount Marrow',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6039,
    longitude: 152.6209,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Kholo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4958,
    longitude: 152.7608,
    accuracy: 4,
  },
  {
    postcode: 4306,
    place_name: 'Karalee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5648,
    longitude: 152.8245,
    accuracy: 4,
  },
  {
    postcode: 4307,
    place_name: 'Mutdapilly',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7667,
    longitude: 152.65,
    accuracy: 4,
  },
  {
    postcode: 4307,
    place_name: 'Warrill View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8241,
    longitude: 152.6137,
    accuracy: 4,
  },
  {
    postcode: 4307,
    place_name: 'Silverdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8993,
    longitude: 152.6048,
    accuracy: 4,
  },
  {
    postcode: 4307,
    place_name: 'Coleyville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.809,
    longitude: 152.5668,
    accuracy: 4,
  },
  {
    postcode: 4307,
    place_name: 'Radford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8522,
    longitude: 152.6295,
    accuracy: 4,
  },
  {
    postcode: 4307,
    place_name: 'Wilsons Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8409,
    longitude: 152.6528,
    accuracy: 4,
  },
  {
    postcode: 4307,
    place_name: 'Harrisville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.811,
    longitude: 152.6646,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Charlwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0167,
    longitude: 152.5667,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Mount Edwards',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0167,
    longitude: 152.5167,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Frazerview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.023,
    longitude: 152.5304,
    accuracy: 3,
  },
  {
    postcode: 4309,
    place_name: 'Teviotville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.95,
    longitude: 152.6833,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Fassifern',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9667,
    longitude: 152.6,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Kulgun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9333,
    longitude: 152.6833,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Aratula',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9833,
    longitude: 152.5333,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Obum Obum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9158,
    longitude: 152.652,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Kalbar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9417,
    longitude: 152.6237,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Kents Lagoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8987,
    longitude: 152.6186,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Washpool',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.023,
    longitude: 152.5304,
    accuracy: 3,
  },
  {
    postcode: 4309,
    place_name: 'Morwincha',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9833,
    longitude: 152.5833,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Fassifern Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9667,
    longitude: 152.6,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Milora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8319,
    longitude: 152.693,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Clumber',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0667,
    longitude: 152.4667,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Munbilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8751,
    longitude: 152.6592,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Moogerah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0886,
    longitude: 152.5223,
    accuracy: 4,
  },
  {
    postcode: 4309,
    place_name: 'Tarome',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9764,
    longitude: 152.591,
    accuracy: 3,
  },
  {
    postcode: 4310,
    place_name: 'Mount Alford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0707,
    longitude: 152.5944,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Boonah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9972,
    longitude: 152.6811,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Maroon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1773,
    longitude: 152.7178,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Burnett Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0242,
    longitude: 152.668,
    accuracy: 3,
  },
  {
    postcode: 4310,
    place_name: 'Bunburra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0667,
    longitude: 152.6833,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Anthony',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8779,
    longitude: 152.6828,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Hoya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9667,
    longitude: 152.6833,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Milford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0333,
    longitude: 152.7167,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Frenches Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.019,
    longitude: 152.6327,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Coulson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0242,
    longitude: 152.668,
    accuracy: 3,
  },
  {
    postcode: 4310,
    place_name: 'Cannon Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0627,
    longitude: 152.7241,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Wallaces Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0705,
    longitude: 152.6535,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Blantyre',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9039,
    longitude: 152.678,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Dugandan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0211,
    longitude: 152.6714,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Allandale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0121,
    longitude: 152.7482,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Templin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9714,
    longitude: 152.6478,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Roadvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9177,
    longitude: 152.6882,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Mount French',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0242,
    longitude: 152.668,
    accuracy: 3,
  },
  {
    postcode: 4310,
    place_name: 'Kents Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9891,
    longitude: 152.6561,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Croftby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1333,
    longitude: 152.5667,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Wyaralong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0242,
    longitude: 152.668,
    accuracy: 3,
  },
  {
    postcode: 4310,
    place_name: 'Coochin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0989,
    longitude: 152.643,
    accuracy: 4,
  },
  {
    postcode: 4310,
    place_name: 'Milbong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0242,
    longitude: 152.668,
    accuracy: 3,
  },
  {
    postcode: 4310,
    place_name: 'Carneys Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0242,
    longitude: 152.668,
    accuracy: 3,
  },
  {
    postcode: 4310,
    place_name: 'Woolooman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0242,
    longitude: 152.668,
    accuracy: 3,
  },
  {
    postcode: 4310,
    place_name: 'Bunjurgen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0412,
    longitude: 152.6236,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Lowood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4655,
    longitude: 152.5762,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Coolana',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 152.55,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Atkinsons Dam',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4214,
    longitude: 152.4339,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Mount Tarampa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4609,
    longitude: 152.4899,
    accuracy: 3,
  },
  {
    postcode: 4311,
    place_name: 'Tarampa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 152.55,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Minden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5596,
    longitude: 152.5449,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Buaraba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3667,
    longitude: 152.3167,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Buaraba South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4291,
    longitude: 152.4881,
    accuracy: 3,
  },
  {
    postcode: 4311,
    place_name: 'Rifle Range',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4595,
    longitude: 152.5312,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Coominya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3901,
    longitude: 152.5006,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Churchable',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4259,
    longitude: 152.3698,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Wivenhoe Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4291,
    longitude: 152.4881,
    accuracy: 3,
  },
  {
    postcode: 4311,
    place_name: 'Patrick Estate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4185,
    longitude: 152.5819,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Lockyer Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4539,
    longitude: 152.4058,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Brightview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5,
    longitude: 152.4833,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Prenzlau',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 152.5167,
    accuracy: 4,
  },
  {
    postcode: 4311,
    place_name: 'Clarendon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4167,
    longitude: 152.5333,
    accuracy: 4,
  },
  {
    postcode: 4312,
    place_name: 'Somerset Dam',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1214,
    longitude: 152.5497,
    accuracy: 4,
  },
  {
    postcode: 4312,
    place_name: 'Esk',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.239,
    longitude: 152.4222,
    accuracy: 4,
  },
  {
    postcode: 4312,
    place_name: 'Redbank Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2862,
    longitude: 152.3039,
    accuracy: 3,
  },
  {
    postcode: 4312,
    place_name: 'Bryden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2692,
    longitude: 152.5813,
    accuracy: 4,
  },
  {
    postcode: 4312,
    place_name: 'Mount Hallen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3167,
    longitude: 152.4167,
    accuracy: 4,
  },
  {
    postcode: 4312,
    place_name: 'Caboonbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.15,
    longitude: 152.4833,
    accuracy: 4,
  },
  {
    postcode: 4312,
    place_name: 'Murrumba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2,
    longitude: 152.5,
    accuracy: 4,
  },
  {
    postcode: 4312,
    place_name: 'Moombra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.229,
    longitude: 152.4719,
    accuracy: 3,
  },
  {
    postcode: 4312,
    place_name: 'Mount Byron',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.229,
    longitude: 152.4719,
    accuracy: 3,
  },
  {
    postcode: 4312,
    place_name: 'Glen Esk',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.229,
    longitude: 152.4719,
    accuracy: 3,
  },
  {
    postcode: 4312,
    place_name: 'Crossdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.229,
    longitude: 152.4719,
    accuracy: 3,
  },
  {
    postcode: 4312,
    place_name: 'Coal Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2148,
    longitude: 152.4932,
    accuracy: 3,
  },
  {
    postcode: 4312,
    place_name: 'Eskdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2148,
    longitude: 152.4932,
    accuracy: 3,
  },
  {
    postcode: 4313,
    place_name: 'Cressbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0833,
    longitude: 152.4833,
    accuracy: 3,
  },
  {
    postcode: 4313,
    place_name: 'Yimbun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0333,
    longitude: 152.3667,
    accuracy: 4,
  },
  {
    postcode: 4313,
    place_name: 'Fulham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0667,
    longitude: 152.45,
    accuracy: 4,
  },
  {
    postcode: 4313,
    place_name: 'Cooeeimbardi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1009,
    longitude: 152.3312,
    accuracy: 3,
  },
  {
    postcode: 4313,
    place_name: 'Toogoolawah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0866,
    longitude: 152.3764,
    accuracy: 4,
  },
  {
    postcode: 4313,
    place_name: 'Braemore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1009,
    longitude: 152.3312,
    accuracy: 3,
  },
  {
    postcode: 4313,
    place_name: 'Lower Cressbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0833,
    longitude: 152.4833,
    accuracy: 4,
  },
  {
    postcode: 4313,
    place_name: 'Scrub Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1009,
    longitude: 152.3312,
    accuracy: 3,
  },
  {
    postcode: 4313,
    place_name: 'Mount Beppo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0839,
    longitude: 152.4238,
    accuracy: 3,
  },
  {
    postcode: 4313,
    place_name: 'Ottaba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.15,
    longitude: 152.3833,
    accuracy: 4,
  },
  {
    postcode: 4313,
    place_name: 'Biarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0839,
    longitude: 152.4238,
    accuracy: 3,
  },
  {
    postcode: 4313,
    place_name: 'Gregors Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0839,
    longitude: 152.4238,
    accuracy: 3,
  },
  {
    postcode: 4313,
    place_name: 'Ivory Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0839,
    longitude: 152.4238,
    accuracy: 3,
  },
  {
    postcode: 4340,
    place_name: 'Rosewood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6335,
    longitude: 152.5895,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Jeebropilly',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6461,
    longitude: 152.6586,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Tallegalla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 152.5667,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Lanefield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.65,
    longitude: 152.55,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'The Bluff',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6235,
    longitude: 152.5355,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Mount Walker',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7833,
    longitude: 152.5333,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Grandchester',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.664,
    longitude: 152.462,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Woolshed',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7448,
    longitude: 152.5241,
    accuracy: 3,
  },
  {
    postcode: 4340,
    place_name: 'Calvert',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6667,
    longitude: 152.5167,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Lower Mount Walker',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7609,
    longitude: 152.5176,
    accuracy: 3,
  },
  {
    postcode: 4340,
    place_name: 'Rosevale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8701,
    longitude: 152.4852,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Ebenezer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6833,
    longitude: 152.6333,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Mount Mort',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8167,
    longitude: 152.4167,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Mount Walker West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7609,
    longitude: 152.5176,
    accuracy: 3,
  },
  {
    postcode: 4340,
    place_name: 'Ashwell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6299,
    longitude: 152.5586,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Merryvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7925,
    longitude: 152.4928,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Mount Forbes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7333,
    longitude: 152.6167,
    accuracy: 4,
  },
  {
    postcode: 4340,
    place_name: 'Moorang',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9167,
    longitude: 152.4667,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Kentville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 152.4333,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Kensington Grove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5328,
    longitude: 152.4721,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Mulgowie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7333,
    longitude: 152.3667,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Thornton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8167,
    longitude: 152.3833,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Regency Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5336,
    longitude: 152.4405,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Blenheim',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6667,
    longitude: 152.3167,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Plainland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5667,
    longitude: 152.4167,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Mount Berryman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7167,
    longitude: 152.3333,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Laidley South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6458,
    longitude: 152.4032,
    accuracy: 3,
  },
  {
    postcode: 4341,
    place_name: 'Hatton Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5667,
    longitude: 152.4667,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Laidley Creek West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7125,
    longitude: 152.3775,
    accuracy: 3,
  },
  {
    postcode: 4341,
    place_name: 'Laidley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6328,
    longitude: 152.3929,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Laidley North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7125,
    longitude: 152.3775,
    accuracy: 3,
  },
  {
    postcode: 4341,
    place_name: 'Townson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8833,
    longitude: 152.3667,
    accuracy: 4,
  },
  {
    postcode: 4341,
    place_name: 'Laidley Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6246,
    longitude: 152.3776,
    accuracy: 3,
  },
  {
    postcode: 4341,
    place_name: 'Summerholm',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6167,
    longitude: 152.45,
    accuracy: 4,
  },
  {
    postcode: 4342,
    place_name: 'Glenore Grove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 152.4,
    accuracy: 4,
  },
  {
    postcode: 4342,
    place_name: 'Lynford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5371,
    longitude: 152.4037,
    accuracy: 3,
  },
  {
    postcode: 4342,
    place_name: 'Forest Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5926,
    longitude: 152.3557,
    accuracy: 4,
  },
  {
    postcode: 4342,
    place_name: 'Glen Cairn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5371,
    longitude: 152.4037,
    accuracy: 3,
  },
  {
    postcode: 4342,
    place_name: 'Crowley Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5383,
    longitude: 152.4052,
    accuracy: 3,
  },
  {
    postcode: 4342,
    place_name: 'Lockrose',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.489,
    longitude: 152.46,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Morton Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4869,
    longitude: 152.3892,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Spring Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4532,
    longitude: 152.332,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Placid Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5613,
    longitude: 152.2325,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'College View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Caffey',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6997,
    longitude: 152.2199,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Fordsdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7094,
    longitude: 152.1277,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Lower Tenthill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.612,
    longitude: 152.2315,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'East Haldon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Black Duck Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8333,
    longitude: 152.1833,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Woodlands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Adare',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5127,
    longitude: 152.2954,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Ingoldsby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Woodbine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7833,
    longitude: 152.1833,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Ropeley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6667,
    longitude: 152.2667,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Junction View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8,
    longitude: 152.1833,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Gatton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5587,
    longitude: 152.2762,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Vinegar Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Mount Sylvia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7253,
    longitude: 152.2222,
    accuracy: 4,
  },
  {
    postcode: 4343,
    place_name: 'Upper Tenthill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Lawes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Rockside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Ringwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Lefthand Branch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6758,
    longitude: 152.2491,
    accuracy: 3,
  },
  {
    postcode: 4343,
    place_name: 'Lake Clarendon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5167,
    longitude: 152.3667,
    accuracy: 4,
  },
  {
    postcode: 4344,
    place_name: 'Seventeen Mile',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5507,
    longitude: 152.959,
    accuracy: 4,
  },
  {
    postcode: 4344,
    place_name: 'Egypt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5987,
    longitude: 152.0996,
    accuracy: 3,
  },
  {
    postcode: 4344,
    place_name: 'Helidon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5532,
    longitude: 152.124,
    accuracy: 4,
  },
  {
    postcode: 4344,
    place_name: 'Lockyer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5135,
    longitude: 152.0822,
    accuracy: 4,
  },
  {
    postcode: 4344,
    place_name: 'Carpendale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5891,
    longitude: 152.1533,
    accuracy: 3,
  },
  {
    postcode: 4344,
    place_name: 'Helidon Spa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5987,
    longitude: 152.0996,
    accuracy: 3,
  },
  {
    postcode: 4344,
    place_name: 'Rockmount',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6833,
    longitude: 152.0333,
    accuracy: 4,
  },
  {
    postcode: 4344,
    place_name: 'Lilydale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6074,
    longitude: 152.1308,
    accuracy: 4,
  },
  {
    postcode: 4344,
    place_name: 'Iredale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6003,
    longitude: 152.0873,
    accuracy: 3,
  },
  {
    postcode: 4344,
    place_name: 'Stockyard',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6333,
    longitude: 152.0667,
    accuracy: 4,
  },
  {
    postcode: 4344,
    place_name: 'Upper Flagstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5987,
    longitude: 152.0996,
    accuracy: 3,
  },
  {
    postcode: 4344,
    place_name: 'Flagstone Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6167,
    longitude: 152.1167,
    accuracy: 4,
  },
  {
    postcode: 4344,
    place_name: 'Silver Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5987,
    longitude: 152.0996,
    accuracy: 3,
  },
  {
    postcode: 4345,
    place_name: 'Gatton College',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5546,
    longitude: 152.337,
    accuracy: 1,
  },
  {
    postcode: 4346,
    place_name: 'Marburg',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5661,
    longitude: 152.597,
    accuracy: 4,
  },
  {
    postcode: 4347,
    place_name: 'Veradilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5934,
    longitude: 152.2167,
    accuracy: 3,
  },
  {
    postcode: 4347,
    place_name: 'Winwill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6,
    longitude: 152.2167,
    accuracy: 4,
  },
  {
    postcode: 4347,
    place_name: 'Ma Ma Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6323,
    longitude: 152.187,
    accuracy: 4,
  },
  {
    postcode: 4347,
    place_name: 'Mount Whitestone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6667,
    longitude: 152.1667,
    accuracy: 4,
  },
  {
    postcode: 4347,
    place_name: 'Grantham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5792,
    longitude: 152.2017,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Athol',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6081,
    longitude: 151.7545,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Southtown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5717,
    longitude: 151.8559,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Westbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6167,
    longitude: 151.8667,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Toowoomba Village Fair',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6284,
    longitude: 151.8604,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Harristown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5833,
    longitude: 151.9333,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Toowoomba City',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5802,
    longitude: 151.9439,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Mount Rascal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.635,
    longitude: 151.9143,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Toowoomba West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7858,
    longitude: 151.9008,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Prince Henry Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5511,
    longitude: 151.991,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Rangeville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5832,
    longitude: 151.98,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Rockville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5361,
    longitude: 151.938,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Clifford Gardens',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6284,
    longitude: 151.8604,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Cranley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5167,
    longitude: 151.95,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'East Toowoomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5709,
    longitude: 151.9742,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Harlaxton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 151.9667,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Wellcamp',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.55,
    longitude: 151.8667,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Drayton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5833,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Drayton North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6284,
    longitude: 151.8604,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'North Toowoomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5441,
    longitude: 151.9612,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Toowoomba DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6284,
    longitude: 151.8604,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Cotswold Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5224,
    longitude: 151.8952,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Northlands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5717,
    longitude: 151.8559,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Blue Mountain Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5049,
    longitude: 151.9559,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Centenary Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5868,
    longitude: 151.9625,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Glenvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5682,
    longitude: 151.8934,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Toowoomba South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5717,
    longitude: 151.8559,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Wilsonton Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5323,
    longitude: 151.9244,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Toowoomba BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6284,
    longitude: 151.8604,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Toowoomba East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5717,
    longitude: 151.8559,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Finnie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6167,
    longitude: 151.9,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Gowrie Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6284,
    longitude: 151.8604,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Top Camp',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6358,
    longitude: 151.9392,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Darling Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5966,
    longitude: 151.9305,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Mount Lofty',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5463,
    longitude: 151.9722,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Newtown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.556,
    longitude: 151.9325,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Charlton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5333,
    longitude: 151.8667,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Wilsonton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5431,
    longitude: 151.9168,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Middle Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6071,
    longitude: 151.9669,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Torrington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5424,
    longitude: 151.8879,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Mount Kynoch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5107,
    longitude: 151.9481,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Wyalla Plaza',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5717,
    longitude: 151.8559,
    accuracy: 3,
  },
  {
    postcode: 4350,
    place_name: 'Kearneys Spring',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6049,
    longitude: 151.941,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'South Toowoomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.571,
    longitude: 151.9465,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Toowoomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5606,
    longitude: 151.9539,
    accuracy: 4,
  },
  {
    postcode: 4350,
    place_name: 'Redwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5648,
    longitude: 151.9791,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Umbiram',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6692,
    longitude: 151.7663,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Cement Mills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.3333,
    longitude: 151.5,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Meringandan West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4258,
    longitude: 151.9007,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Spring Bluff',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4625,
    longitude: 151.9698,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Kulpi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1901,
    longitude: 151.6984,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Ballard',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Cabarlah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4333,
    longitude: 152,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Highgrove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1333,
    longitude: 151.7667,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Wyreema',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6558,
    longitude: 151.8553,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Fifteen Mile',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Groomsville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3136,
    longitude: 151.9654,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Highfields',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4634,
    longitude: 151.9539,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Yalangur',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4167,
    longitude: 151.8333,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Hodgson Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6652,
    longitude: 151.9325,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Karara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2085,
    longitude: 151.5647,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Hampton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.35,
    longitude: 152.0833,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Pampas',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7929,
    longitude: 151.4161,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Coalbank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1167,
    longitude: 151.85,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Maclagan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0867,
    longitude: 151.6318,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Perseverance',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3667,
    longitude: 152.1333,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Kleinton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4333,
    longitude: 151.95,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Ravensbourne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3667,
    longitude: 152.1667,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Meringandan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4301,
    longitude: 151.9136,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Glencoe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5997,
    longitude: 151.838,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Peranga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1469,
    longitude: 151.6939,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Djuan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1167,
    longitude: 151.9833,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Muniganeen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Pechey',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3,
    longitude: 152.05,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Tummaville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8754,
    longitude: 151.5056,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Branchview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Geham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4,
    longitude: 152.0167,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Evergreen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.15,
    longitude: 151.7333,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Upper Lockyer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6667,
    longitude: 152.2333,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Blanchview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5667,
    longitude: 152.0333,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Cutella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.45,
    longitude: 151.8167,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Nutgrove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.05,
    longitude: 151.7667,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Gore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2833,
    longitude: 151.4833,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Merritts Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Severnlea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7023,
    longitude: 151.9027,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Narko',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Oman Ama',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4,
    longitude: 151.3,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Derrymore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Birnam',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Mount Luke',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4,
    longitude: 150.0333,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Silver Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5997,
    longitude: 151.838,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Condamine Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Wutul',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0333,
    longitude: 151.8,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Malling',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0709,
    longitude: 151.5901,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Woolmer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.45,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Doctor Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Murphys Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4637,
    longitude: 152.0549,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Lyra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.829,
    longitude: 151.8552,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Vale View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6619,
    longitude: 151.899,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Thornville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Ramsay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7232,
    longitude: 151.9886,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Yandilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.85,
    longitude: 151.3667,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Preston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6604,
    longitude: 151.9866,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Toowoomba Mc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Amiens',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.588,
    longitude: 151.8074,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'St Aubyn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0833,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Bapaume',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5699,
    longitude: 151.8438,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Palmtree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Gowrie Junction',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4833,
    longitude: 151.8833,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Lilyvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Woodleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Gowrie Little Plain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Grapetree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Withcott',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5541,
    longitude: 152.0243,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'White Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Kurrowah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Whichello',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Iredale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Rangemore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Postmans Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5309,
    longitude: 152.0528,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'North Maclagan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7753,
    longitude: 151.6342,
    accuracy: 3,
  },
  {
    postcode: 4352,
    place_name: 'Pozieres',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5269,
    longitude: 151.8829,
    accuracy: 4,
  },
  {
    postcode: 4352,
    place_name: 'Cawdor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4667,
    longitude: 151.9,
    accuracy: 4,
  },
  {
    postcode: 4353,
    place_name: 'Bergen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2167,
    longitude: 151.8833,
    accuracy: 3,
  },
  {
    postcode: 4353,
    place_name: 'East Cooyar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2167,
    longitude: 151.8833,
    accuracy: 3,
  },
  {
    postcode: 4353,
    place_name: 'Haden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2266,
    longitude: 151.8858,
    accuracy: 4,
  },
  {
    postcode: 4354,
    place_name: 'Kilbirnie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2167,
    longitude: 151.85,
    accuracy: 4,
  },
  {
    postcode: 4354,
    place_name: 'Goombungee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3167,
    longitude: 151.7,
    accuracy: 4,
  },
  {
    postcode: 4354,
    place_name: 'Douglas',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3175,
    longitude: 151.8788,
    accuracy: 3,
  },
  {
    postcode: 4355,
    place_name: 'The Bluff',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6235,
    longitude: 152.5355,
    accuracy: 4,
  },
  {
    postcode: 4355,
    place_name: 'Mountain Camp',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4,
    longitude: 152.8833,
    accuracy: 4,
  },
  {
    postcode: 4355,
    place_name: 'Pinelands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.27,
    longitude: 152.345,
    accuracy: 3,
  },
  {
    postcode: 4355,
    place_name: 'Crows Nest',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2599,
    longitude: 152.0516,
    accuracy: 4,
  },
  {
    postcode: 4355,
    place_name: 'Anduramba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.15,
    longitude: 152.1,
    accuracy: 4,
  },
  {
    postcode: 4355,
    place_name: 'Pierces Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2077,
    longitude: 152.0273,
    accuracy: 3,
  },
  {
    postcode: 4355,
    place_name: 'Cressbrook Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.27,
    longitude: 152.345,
    accuracy: 3,
  },
  {
    postcode: 4355,
    place_name: 'Jones Gully',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.27,
    longitude: 152.345,
    accuracy: 3,
  },
  {
    postcode: 4355,
    place_name: 'Glenaven',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2077,
    longitude: 152.0273,
    accuracy: 3,
  },
  {
    postcode: 4355,
    place_name: 'Upper Pinelands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.27,
    longitude: 152.345,
    accuracy: 3,
  },
  {
    postcode: 4355,
    place_name: 'Plainby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.27,
    longitude: 152.345,
    accuracy: 3,
  },
  {
    postcode: 4355,
    place_name: 'Emu Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.27,
    longitude: 152.345,
    accuracy: 3,
  },
  {
    postcode: 4356,
    place_name: 'Mount Tyson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5833,
    longitude: 151.5667,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'Linthorpe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5976,
    longitude: 151.6262,
    accuracy: 3,
  },
  {
    postcode: 4356,
    place_name: 'Yarranlea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7283,
    longitude: 151.5257,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'Purrawunda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7002,
    longitude: 151.5413,
    accuracy: 3,
  },
  {
    postcode: 4356,
    place_name: 'Bongeen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5667,
    longitude: 151.45,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'North Branch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0368,
    longitude: 152.3298,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'Motley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.55,
    longitude: 151.6167,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'Irongate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7002,
    longitude: 151.5413,
    accuracy: 3,
  },
  {
    postcode: 4356,
    place_name: 'Stoneleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5766,
    longitude: 151.3706,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'Broxburn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7,
    longitude: 151.6667,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'Norwin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5667,
    longitude: 151.3833,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'Pittsworth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7164,
    longitude: 151.634,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'Springside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6744,
    longitude: 151.5921,
    accuracy: 4,
  },
  {
    postcode: 4356,
    place_name: 'Evanslea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7002,
    longitude: 151.5413,
    accuracy: 3,
  },
  {
    postcode: 4356,
    place_name: 'Kincora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7002,
    longitude: 151.5413,
    accuracy: 3,
  },
  {
    postcode: 4356,
    place_name: 'Scrubby Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7692,
    longitude: 151.585,
    accuracy: 3,
  },
  {
    postcode: 4356,
    place_name: 'Rossvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7002,
    longitude: 151.5413,
    accuracy: 3,
  },
  {
    postcode: 4356,
    place_name: 'St Helens',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7002,
    longitude: 151.5413,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Captains Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9222,
    longitude: 151.1706,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Kooroongarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1032,
    longitude: 151.2586,
    accuracy: 4,
  },
  {
    postcode: 4357,
    place_name: 'Cypress Gardens',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Turallin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8333,
    longitude: 151.2167,
    accuracy: 4,
  },
  {
    postcode: 4357,
    place_name: 'Bringalily',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Millmerran Woods',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Bulli Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Clontarf',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9667,
    longitude: 151.2167,
    accuracy: 4,
  },
  {
    postcode: 4357,
    place_name: 'Condamine Farms',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Lavelle',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Western Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5167,
    longitude: 149.9333,
    accuracy: 4,
  },
  {
    postcode: 4357,
    place_name: 'Rocky Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0167,
    longitude: 151.35,
    accuracy: 4,
  },
  {
    postcode: 4357,
    place_name: 'The Pines',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Mount Emlyn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0397,
    longitude: 151.3342,
    accuracy: 4,
  },
  {
    postcode: 4357,
    place_name: 'Forest Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Millmerran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8791,
    longitude: 151.2706,
    accuracy: 4,
  },
  {
    postcode: 4357,
    place_name: 'Domville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Stonehenge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Canning Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Millmerran Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Millwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Wattle Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Lemontree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Grays Gate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Woondul',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4357,
    place_name: 'Punchs Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8684,
    longitude: 151.0835,
    accuracy: 3,
  },
  {
    postcode: 4358,
    place_name: 'Cambooya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7145,
    longitude: 151.8603,
    accuracy: 4,
  },
  {
    postcode: 4358,
    place_name: 'Vale View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6619,
    longitude: 151.899,
    accuracy: 4,
  },
  {
    postcode: 4358,
    place_name: 'Ramsay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7232,
    longitude: 151.9886,
    accuracy: 4,
  },
  {
    postcode: 4358,
    place_name: 'Felton South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7484,
    longitude: 151.8971,
    accuracy: 3,
  },
  {
    postcode: 4358,
    place_name: 'Felton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8022,
    longitude: 151.7693,
    accuracy: 4,
  },
  {
    postcode: 4359,
    place_name: 'Hirstglen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8333,
    longitude: 152.1,
    accuracy: 4,
  },
  {
    postcode: 4359,
    place_name: 'Greenmount East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7976,
    longitude: 152.0087,
    accuracy: 3,
  },
  {
    postcode: 4359,
    place_name: 'Ascot',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4315,
    longitude: 153.058,
    accuracy: 4,
  },
  {
    postcode: 4359,
    place_name: 'East Greenmount',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8104,
    longitude: 152.0021,
    accuracy: 3,
  },
  {
    postcode: 4359,
    place_name: 'West Haldon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7976,
    longitude: 152.0087,
    accuracy: 3,
  },
  {
    postcode: 4359,
    place_name: 'Greenmount',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7858,
    longitude: 151.9008,
    accuracy: 4,
  },
  {
    postcode: 4359,
    place_name: 'Budgee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7976,
    longitude: 152.0087,
    accuracy: 3,
  },
  {
    postcode: 4360,
    place_name: 'Nobby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8616,
    longitude: 151.9011,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Pilton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8701,
    longitude: 152.0458,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Manapouri',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9373,
    longitude: 151.9367,
    accuracy: 3,
  },
  {
    postcode: 4361,
    place_name: 'Upper Pilton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8701,
    longitude: 152.0458,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Ryeford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9373,
    longitude: 151.9367,
    accuracy: 3,
  },
  {
    postcode: 4361,
    place_name: 'Elphinstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.973,
    longitude: 151.9023,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Missen Flat',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9373,
    longitude: 151.9367,
    accuracy: 3,
  },
  {
    postcode: 4361,
    place_name: 'Back Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9,
    longitude: 151.7833,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Mount Molar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8816,
    longitude: 151.8517,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Kings Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Victoria Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9373,
    longitude: 151.9367,
    accuracy: 3,
  },
  {
    postcode: 4361,
    place_name: 'Spring Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9333,
    longitude: 151.9833,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Nevilton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9373,
    longitude: 151.9367,
    accuracy: 3,
  },
  {
    postcode: 4361,
    place_name: 'Ellangowan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9415,
    longitude: 151.7273,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Sandy Camp',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9373,
    longitude: 151.9367,
    accuracy: 3,
  },
  {
    postcode: 4361,
    place_name: 'Clifton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9337,
    longitude: 151.9066,
    accuracy: 4,
  },
  {
    postcode: 4361,
    place_name: 'Headington Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9373,
    longitude: 151.9367,
    accuracy: 3,
  },
  {
    postcode: 4362,
    place_name: 'Berat',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.05,
    longitude: 152.05,
    accuracy: 4,
  },
  {
    postcode: 4362,
    place_name: 'Mount Marshall',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0833,
    longitude: 152.0333,
    accuracy: 4,
  },
  {
    postcode: 4362,
    place_name: 'Allora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0348,
    longitude: 151.9806,
    accuracy: 4,
  },
  {
    postcode: 4362,
    place_name: 'Ellinthorp',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0512,
    longitude: 151.9221,
    accuracy: 4,
  },
  {
    postcode: 4362,
    place_name: 'Deuchar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1176,
    longitude: 151.9575,
    accuracy: 4,
  },
  {
    postcode: 4362,
    place_name: 'Talgai',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9864,
    longitude: 151.7704,
    accuracy: 4,
  },
  {
    postcode: 4362,
    place_name: 'Goomburra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.05,
    longitude: 152.1333,
    accuracy: 4,
  },
  {
    postcode: 4362,
    place_name: 'Hendon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0772,
    longitude: 151.9492,
    accuracy: 4,
  },
  {
    postcode: 4363,
    place_name: 'Southbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6833,
    longitude: 151.7167,
    accuracy: 4,
  },
  {
    postcode: 4364,
    place_name: 'Brookstead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7607,
    longitude: 151.4456,
    accuracy: 4,
  },
  {
    postcode: 4365,
    place_name: 'Leyburn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.011,
    longitude: 151.5821,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Morgan Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2562,
    longitude: 152.0454,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Wildash',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Thanes Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1167,
    longitude: 151.6833,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Mount Tabor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Massie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1478,
    longitude: 151.9556,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Clintonvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1,
    longitude: 152.1167,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Cunningham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1613,
    longitude: 151.8421,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Danderoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.25,
    longitude: 152.2333,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Pratten',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0892,
    longitude: 151.7827,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Elbow Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.3833,
    longitude: 152.1333,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Allan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2017,
    longitude: 151.9512,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Leslie Dam',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Leslie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1787,
    longitude: 151.9217,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Swan Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2,
    longitude: 152.1333,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Thane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1621,
    longitude: 151.7012,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Wiyarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2667,
    longitude: 152.2333,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'North Branch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0368,
    longitude: 152.3298,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Sladevale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1667,
    longitude: 152.0667,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Montrose',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1817,
    longitude: 151.7933,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Murrays Bridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Cherry Gully',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4331,
    longitude: 152.0321,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Canningvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2441,
    longitude: 152.0564,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Loch Lomond',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.3167,
    longitude: 152.2,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Wheatvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1667,
    longitude: 151.8833,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Bony Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1333,
    longitude: 151.8333,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Rodgers Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Warwick',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.219,
    longitude: 152.0344,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Rosenthal Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2401,
    longitude: 152.0133,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Mount Sturt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2,
    longitude: 152.1833,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Rosehill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1833,
    longitude: 152,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Womina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1833,
    longitude: 152.05,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'The Hermitage',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Junabee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.25,
    longitude: 152.1333,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Glengallan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Greymare',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1667,
    longitude: 151.7667,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Warwick DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Upper Freestone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1333,
    longitude: 152.2,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Tregony',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Mount Colliery',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2503,
    longitude: 152.3738,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Willowvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'Freestone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1333,
    longitude: 152.1333,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Silverwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.3239,
    longitude: 152.0051,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Upper Wheatvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4370,
    place_name: 'The Glen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.3574,
    longitude: 151.9244,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Toolburra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1667,
    longitude: 151.9667,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Maryvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0687,
    longitude: 152.2436,
    accuracy: 4,
  },
  {
    postcode: 4370,
    place_name: 'Gladfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1853,
    longitude: 152.02,
    accuracy: 3,
  },
  {
    postcode: 4371,
    place_name: 'Emu Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2241,
    longitude: 152.2467,
    accuracy: 4,
  },
  {
    postcode: 4371,
    place_name: 'Swanfels',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2141,
    longitude: 152.2288,
    accuracy: 3,
  },
  {
    postcode: 4371,
    place_name: 'Yangan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1949,
    longitude: 152.2075,
    accuracy: 4,
  },
  {
    postcode: 4372,
    place_name: 'Tannymorel',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2927,
    longitude: 152.2472,
    accuracy: 4,
  },
  {
    postcode: 4373,
    place_name: 'The Head',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2833,
    longitude: 152.4167,
    accuracy: 4,
  },
  {
    postcode: 4373,
    place_name: 'Killarney',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.3327,
    longitude: 152.2942,
    accuracy: 4,
  },
  {
    postcode: 4373,
    place_name: 'The Falls',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.311,
    longitude: 152.3559,
    accuracy: 3,
  },
  {
    postcode: 4374,
    place_name: 'Dalveen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4871,
    longitude: 151.9674,
    accuracy: 4,
  },
  {
    postcode: 4375,
    place_name: 'Fleurbaix',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5333,
    longitude: 151.9167,
    accuracy: 4,
  },
  {
    postcode: 4375,
    place_name: 'Cottonvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7167,
    longitude: 152.4667,
    accuracy: 4,
  },
  {
    postcode: 4376,
    place_name: 'Thulimbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5506,
    longitude: 151.9486,
    accuracy: 4,
  },
  {
    postcode: 4377,
    place_name: 'Glen Niven',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.6,
    longitude: 151.9667,
    accuracy: 4,
  },
  {
    postcode: 4377,
    place_name: 'The Summit',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5805,
    longitude: 151.9533,
    accuracy: 4,
  },
  {
    postcode: 4378,
    place_name: 'Applethorpe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.6193,
    longitude: 151.9601,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Severnlea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7023,
    longitude: 151.9027,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Springdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Stanthorpe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.6543,
    longitude: 151.9339,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Glenlyon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Thorndale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Pikedale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.65,
    longitude: 151.6333,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Amiens',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.588,
    longitude: 151.8074,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Sugarloaf',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Pikes Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Broadwater',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Eukey',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7667,
    longitude: 151.9833,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Nundubbermere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Greenlands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Mingoola',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.9833,
    longitude: 151.5167,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Kyoomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.6833,
    longitude: 152,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Dalcouth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Storm King',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7241,
    longitude: 151.825,
    accuracy: 3,
  },
  {
    postcode: 4380,
    place_name: 'Mount Tully',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7646,
    longitude: 151.9481,
    accuracy: 4,
  },
  {
    postcode: 4380,
    place_name: 'Cannon Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.6036,
    longitude: 151.8722,
    accuracy: 4,
  },
  {
    postcode: 4381,
    place_name: 'Fletcher',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.774,
    longitude: 151.8637,
    accuracy: 4,
  },
  {
    postcode: 4381,
    place_name: 'Glen Aplin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7338,
    longitude: 151.8797,
    accuracy: 4,
  },
  {
    postcode: 4382,
    place_name: 'Lyra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.829,
    longitude: 151.8552,
    accuracy: 4,
  },
  {
    postcode: 4382,
    place_name: 'Girraween',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.8278,
    longitude: 151.8556,
    accuracy: 3,
  },
  {
    postcode: 4382,
    place_name: 'Wyberba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.8619,
    longitude: 151.8646,
    accuracy: 4,
  },
  {
    postcode: 4382,
    place_name: 'Ballandean',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.798,
    longitude: 151.8401,
    accuracy: 4,
  },
  {
    postcode: 4382,
    place_name: 'Somme',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.8278,
    longitude: 151.8556,
    accuracy: 3,
  },
  {
    postcode: 4383,
    place_name: 'Wallangarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.9239,
    longitude: 151.9282,
    accuracy: 4,
  },
  {
    postcode: 4384,
    place_name: 'Limevale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.721,
    longitude: 151.1824,
    accuracy: 4,
  },
  {
    postcode: 4385,
    place_name: 'Texas',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.8542,
    longitude: 151.165,
    accuracy: 4,
  },
  {
    postcode: 4385,
    place_name: 'Smithlea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7884,
    longitude: 151.01,
    accuracy: 3,
  },
  {
    postcode: 4385,
    place_name: 'Glenarbon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.6835,
    longitude: 150.8928,
    accuracy: 4,
  },
  {
    postcode: 4385,
    place_name: 'Riverton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7884,
    longitude: 151.01,
    accuracy: 3,
  },
  {
    postcode: 4385,
    place_name: 'Beebo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7239,
    longitude: 150.966,
    accuracy: 4,
  },
  {
    postcode: 4385,
    place_name: 'Watsons Crossing',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7884,
    longitude: 151.01,
    accuracy: 3,
  },
  {
    postcode: 4385,
    place_name: 'Bonshaw',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7884,
    longitude: 151.01,
    accuracy: 3,
  },
  {
    postcode: 4385,
    place_name: 'Maidenhead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7884,
    longitude: 151.01,
    accuracy: 3,
  },
  {
    postcode: 4385,
    place_name: 'Silver Spur',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7884,
    longitude: 151.01,
    accuracy: 3,
  },
  {
    postcode: 4387,
    place_name: 'Whetstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5,
    longitude: 150.9333,
    accuracy: 4,
  },
  {
    postcode: 4387,
    place_name: 'Inglewood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4159,
    longitude: 151.0807,
    accuracy: 4,
  },
  {
    postcode: 4387,
    place_name: 'Coolmunda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4171,
    longitude: 151.1449,
    accuracy: 4,
  },
  {
    postcode: 4387,
    place_name: 'Warroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4789,
    longitude: 151.1134,
    accuracy: 3,
  },
  {
    postcode: 4387,
    place_name: 'Terrica',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5219,
    longitude: 151.4786,
    accuracy: 4,
  },
  {
    postcode: 4387,
    place_name: 'Bybera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4789,
    longitude: 151.1134,
    accuracy: 3,
  },
  {
    postcode: 4387,
    place_name: 'Greenup',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4789,
    longitude: 151.1134,
    accuracy: 3,
  },
  {
    postcode: 4387,
    place_name: 'Brush Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4789,
    longitude: 151.1134,
    accuracy: 3,
  },
  {
    postcode: 4387,
    place_name: 'Mosquito Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4789,
    longitude: 151.1134,
    accuracy: 3,
  },
  {
    postcode: 4388,
    place_name: 'Yelarbon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5725,
    longitude: 150.7513,
    accuracy: 4,
  },
  {
    postcode: 4388,
    place_name: 'Kurumbul',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.6167,
    longitude: 150.55,
    accuracy: 4,
  },
  {
    postcode: 4390,
    place_name: 'Callandoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4958,
    longitude: 150.1831,
    accuracy: 4,
  },
  {
    postcode: 4390,
    place_name: 'Calingunee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.194,
    longitude: 150.3565,
    accuracy: 3,
  },
  {
    postcode: 4390,
    place_name: 'Goondiwindi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5471,
    longitude: 150.3055,
    accuracy: 4,
  },
  {
    postcode: 4390,
    place_name: 'Kindon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.194,
    longitude: 150.3565,
    accuracy: 3,
  },
  {
    postcode: 4390,
    place_name: 'Wondalli',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.194,
    longitude: 150.3565,
    accuracy: 3,
  },
  {
    postcode: 4390,
    place_name: 'Lundavra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.194,
    longitude: 150.3565,
    accuracy: 3,
  },
  {
    postcode: 4390,
    place_name: 'Yagaburne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.194,
    longitude: 150.3565,
    accuracy: 3,
  },
  {
    postcode: 4390,
    place_name: 'Wyaga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.194,
    longitude: 150.3565,
    accuracy: 3,
  },
  {
    postcode: 4390,
    place_name: 'Goodar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.194,
    longitude: 150.3565,
    accuracy: 3,
  },
  {
    postcode: 4390,
    place_name: 'Billa Billa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.194,
    longitude: 150.3565,
    accuracy: 3,
  },
  {
    postcode: 4400,
    place_name: 'Kingsthorpe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4756,
    longitude: 151.8141,
    accuracy: 4,
  },
  {
    postcode: 4401,
    place_name: 'Sabine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.35,
    longitude: 151.7167,
    accuracy: 4,
  },
  {
    postcode: 4401,
    place_name: 'Highland Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4617,
    longitude: 151.6957,
    accuracy: 3,
  },
  {
    postcode: 4401,
    place_name: 'Muldu',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2667,
    longitude: 151.6833,
    accuracy: 4,
  },
  {
    postcode: 4401,
    place_name: 'Aubigny',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5234,
    longitude: 151.6348,
    accuracy: 4,
  },
  {
    postcode: 4401,
    place_name: 'Boodua',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3833,
    longitude: 151.8333,
    accuracy: 4,
  },
  {
    postcode: 4401,
    place_name: 'Devon Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4617,
    longitude: 151.6957,
    accuracy: 3,
  },
  {
    postcode: 4401,
    place_name: 'Rosalie Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2167,
    longitude: 151.7,
    accuracy: 4,
  },
  {
    postcode: 4401,
    place_name: 'Acland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3086,
    longitude: 151.6898,
    accuracy: 4,
  },
  {
    postcode: 4401,
    place_name: 'Biddeston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5592,
    longitude: 151.718,
    accuracy: 4,
  },
  {
    postcode: 4401,
    place_name: 'Greenwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4617,
    longitude: 151.6957,
    accuracy: 3,
  },
  {
    postcode: 4401,
    place_name: 'Oakey',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4331,
    longitude: 151.7206,
    accuracy: 4,
  },
  {
    postcode: 4401,
    place_name: 'Silverleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4617,
    longitude: 151.6957,
    accuracy: 3,
  },
  {
    postcode: 4401,
    place_name: 'Balgowan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4617,
    longitude: 151.6957,
    accuracy: 3,
  },
  {
    postcode: 4401,
    place_name: 'Kelvinhaugh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4617,
    longitude: 151.6957,
    accuracy: 3,
  },
  {
    postcode: 4401,
    place_name: 'Mount Irving',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4617,
    longitude: 151.6957,
    accuracy: 3,
  },
  {
    postcode: 4401,
    place_name: 'Yargullen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5,
    longitude: 151.65,
    accuracy: 4,
  },
  {
    postcode: 4402,
    place_name: 'Kooralgin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9333,
    longitude: 151.95,
    accuracy: 4,
  },
  {
    postcode: 4402,
    place_name: 'Upper Cooyar Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9583,
    longitude: 151.8917,
    accuracy: 3,
  },
  {
    postcode: 4402,
    place_name: 'Cooyar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9844,
    longitude: 151.8297,
    accuracy: 4,
  },
  {
    postcode: 4403,
    place_name: 'Brymaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2835,
    longitude: 151.6046,
    accuracy: 3,
  },
  {
    postcode: 4403,
    place_name: 'Quinalow',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1123,
    longitude: 151.6209,
    accuracy: 4,
  },
  {
    postcode: 4403,
    place_name: 'West Prairie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2835,
    longitude: 151.6046,
    accuracy: 3,
  },
  {
    postcode: 4403,
    place_name: 'Malu',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.35,
    longitude: 151.5667,
    accuracy: 4,
  },
  {
    postcode: 4403,
    place_name: 'Jondaryan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3703,
    longitude: 151.5904,
    accuracy: 4,
  },
  {
    postcode: 4403,
    place_name: 'Mount Moriah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2835,
    longitude: 151.6046,
    accuracy: 3,
  },
  {
    postcode: 4404,
    place_name: 'Formartin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3959,
    longitude: 151.4118,
    accuracy: 4,
  },
  {
    postcode: 4404,
    place_name: 'Irvingdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3495,
    longitude: 151.4651,
    accuracy: 3,
  },
  {
    postcode: 4404,
    place_name: 'Bowenville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3055,
    longitude: 151.4899,
    accuracy: 4,
  },
  {
    postcode: 4404,
    place_name: 'Wainui',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3495,
    longitude: 151.4651,
    accuracy: 3,
  },
  {
    postcode: 4405,
    place_name: 'Ducklo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2552,
    longitude: 151.011,
    accuracy: 4,
  },
  {
    postcode: 4405,
    place_name: 'Dalby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1817,
    longitude: 151.2621,
    accuracy: 4,
  },
  {
    postcode: 4405,
    place_name: 'Blaxland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1987,
    longitude: 151.3211,
    accuracy: 4,
  },
  {
    postcode: 4405,
    place_name: 'Bunya Mountains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.236,
    longitude: 151.1628,
    accuracy: 3,
  },
  {
    postcode: 4405,
    place_name: 'Weranga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3051,
    longitude: 150.7262,
    accuracy: 4,
  },
  {
    postcode: 4405,
    place_name: 'Grassdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.236,
    longitude: 151.1628,
    accuracy: 3,
  },
  {
    postcode: 4405,
    place_name: 'Tipton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3892,
    longitude: 151.2704,
    accuracy: 4,
  },
  {
    postcode: 4405,
    place_name: 'St Ruth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2763,
    longitude: 151.1157,
    accuracy: 3,
  },
  {
    postcode: 4405,
    place_name: 'Ranges Bridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2763,
    longitude: 151.1157,
    accuracy: 3,
  },
  {
    postcode: 4405,
    place_name: 'Marmadua',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2763,
    longitude: 151.1157,
    accuracy: 3,
  },
  {
    postcode: 4405,
    place_name: 'Pirrinuan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2763,
    longitude: 151.1157,
    accuracy: 3,
  },
  {
    postcode: 4406,
    place_name: 'Kaimkillenbun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0627,
    longitude: 151.4362,
    accuracy: 4,
  },
  {
    postcode: 4406,
    place_name: 'Weir River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1306,
    longitude: 150.7917,
    accuracy: 3,
  },
  {
    postcode: 4406,
    place_name: 'The Gums',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3333,
    longitude: 150.1833,
    accuracy: 4,
  },
  {
    postcode: 4406,
    place_name: 'Boondandilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1306,
    longitude: 150.7917,
    accuracy: 3,
  },
  {
    postcode: 4406,
    place_name: 'Moonie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7184,
    longitude: 150.3697,
    accuracy: 4,
  },
  {
    postcode: 4406,
    place_name: 'Macalister',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0457,
    longitude: 151.081,
    accuracy: 4,
  },
  {
    postcode: 4406,
    place_name: 'Hannaford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3296,
    longitude: 150.0631,
    accuracy: 4,
  },
  {
    postcode: 4406,
    place_name: 'Jimbour',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9627,
    longitude: 151.2145,
    accuracy: 4,
  },
  {
    postcode: 4406,
    place_name: 'Southwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.7683,
    longitude: 150.0713,
    accuracy: 4,
  },
  {
    postcode: 4406,
    place_name: 'Kogan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0333,
    longitude: 150.7667,
    accuracy: 4,
  },
  {
    postcode: 4407,
    place_name: 'Cattle Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -13.1617,
    longitude: 143.0534,
    accuracy: 3,
  },
  {
    postcode: 4407,
    place_name: 'Cecil Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.5332,
    longitude: 151.1962,
    accuracy: 4,
  },
  {
    postcode: 4407,
    place_name: 'Dunmore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6386,
    longitude: 151.2709,
    accuracy: 3,
  },
  {
    postcode: 4407,
    place_name: 'Nangwee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.55,
    longitude: 151.3,
    accuracy: 4,
  },
  {
    postcode: 4408,
    place_name: 'Bell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9354,
    longitude: 151.451,
    accuracy: 4,
  },
  {
    postcode: 4410,
    place_name: 'Jandowae',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7841,
    longitude: 151.1124,
    accuracy: 4,
  },
  {
    postcode: 4411,
    place_name: 'Warra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9323,
    longitude: 150.9229,
    accuracy: 4,
  },
  {
    postcode: 4412,
    place_name: 'Brigalow',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8452,
    longitude: 150.7916,
    accuracy: 4,
  },
  {
    postcode: 4413,
    place_name: 'Durah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7809,
    longitude: 150.6226,
    accuracy: 3,
  },
  {
    postcode: 4413,
    place_name: 'Boonarga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7957,
    longitude: 150.7172,
    accuracy: 4,
  },
  {
    postcode: 4413,
    place_name: 'Hopeland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3471,
    longitude: 150.6717,
    accuracy: 3,
  },
  {
    postcode: 4413,
    place_name: 'Montrose',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1817,
    longitude: 151.7933,
    accuracy: 4,
  },
  {
    postcode: 4413,
    place_name: 'Canaga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7809,
    longitude: 150.6226,
    accuracy: 3,
  },
  {
    postcode: 4413,
    place_name: 'Baking Board',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7078,
    longitude: 150.5455,
    accuracy: 4,
  },
  {
    postcode: 4413,
    place_name: 'Burncluith',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6333,
    longitude: 150.7167,
    accuracy: 4,
  },
  {
    postcode: 4413,
    place_name: 'Wieambilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0167,
    longitude: 150.5,
    accuracy: 4,
  },
  {
    postcode: 4413,
    place_name: 'Chances Plain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7809,
    longitude: 150.6226,
    accuracy: 3,
  },
  {
    postcode: 4413,
    place_name: 'Chinchilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7379,
    longitude: 150.6297,
    accuracy: 4,
  },
  {
    postcode: 4415,
    place_name: 'Dalwogon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5727,
    longitude: 150.1489,
    accuracy: 4,
  },
  {
    postcode: 4415,
    place_name: 'Hookswood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5532,
    longitude: 150.2053,
    accuracy: 3,
  },
  {
    postcode: 4415,
    place_name: 'Columboola',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6727,
    longitude: 150.3432,
    accuracy: 4,
  },
  {
    postcode: 4415,
    place_name: 'Myall Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5532,
    longitude: 150.2053,
    accuracy: 3,
  },
  {
    postcode: 4415,
    place_name: 'Miles',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6584,
    longitude: 150.1882,
    accuracy: 4,
  },
  {
    postcode: 4415,
    place_name: 'Pelham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5532,
    longitude: 150.2053,
    accuracy: 3,
  },
  {
    postcode: 4415,
    place_name: 'Gurulmundi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4172,
    longitude: 150.0574,
    accuracy: 4,
  },
  {
    postcode: 4415,
    place_name: 'Kowguran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4953,
    longitude: 150.1036,
    accuracy: 4,
  },
  {
    postcode: 4416,
    place_name: 'Yulabilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9843,
    longitude: 150.0054,
    accuracy: 3,
  },
  {
    postcode: 4416,
    place_name: 'Condamine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9286,
    longitude: 150.1404,
    accuracy: 4,
  },
  {
    postcode: 4416,
    place_name: 'Pine Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9843,
    longitude: 150.0054,
    accuracy: 3,
  },
  {
    postcode: 4416,
    place_name: 'Moraby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9843,
    longitude: 150.0054,
    accuracy: 3,
  },
  {
    postcode: 4416,
    place_name: 'Nangram',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9843,
    longitude: 150.0054,
    accuracy: 3,
  },
  {
    postcode: 4416,
    place_name: 'Barramornie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9843,
    longitude: 150.0054,
    accuracy: 3,
  },
  {
    postcode: 4416,
    place_name: 'Sunnyside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9843,
    longitude: 150.0054,
    accuracy: 3,
  },
  {
    postcode: 4417,
    place_name: 'Wellesley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.631,
    longitude: 150.4009,
    accuracy: 3,
  },
  {
    postcode: 4417,
    place_name: 'Parknook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.631,
    longitude: 150.4009,
    accuracy: 3,
  },
  {
    postcode: 4417,
    place_name: 'Surat',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1529,
    longitude: 149.0681,
    accuracy: 4,
  },
  {
    postcode: 4417,
    place_name: 'Warkon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.631,
    longitude: 150.4009,
    accuracy: 3,
  },
  {
    postcode: 4417,
    place_name: 'Oberina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.631,
    longitude: 150.4009,
    accuracy: 3,
  },
  {
    postcode: 4417,
    place_name: 'Noorindoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.631,
    longitude: 150.4009,
    accuracy: 3,
  },
  {
    postcode: 4417,
    place_name: 'Weribone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.631,
    longitude: 150.4009,
    accuracy: 3,
  },
  {
    postcode: 4418,
    place_name: 'Guluguba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2593,
    longitude: 150.0493,
    accuracy: 4,
  },
  {
    postcode: 4419,
    place_name: 'Cockatoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -14.9895,
    longitude: 142.77,
    accuracy: 3,
  },
  {
    postcode: 4419,
    place_name: 'Wandoan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1228,
    longitude: 149.9603,
    accuracy: 4,
  },
  {
    postcode: 4419,
    place_name: 'Grosmont',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9419,
    longitude: 149.9011,
    accuracy: 3,
  },
  {
    postcode: 4420,
    place_name: 'Spring Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9333,
    longitude: 151.9833,
    accuracy: 4,
  },
  {
    postcode: 4420,
    place_name: 'Coorada',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4868,
    longitude: 149.6225,
    accuracy: 3,
  },
  {
    postcode: 4420,
    place_name: 'Ghinghinda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4868,
    longitude: 149.6225,
    accuracy: 3,
  },
  {
    postcode: 4420,
    place_name: 'Peek-A-Doo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4868,
    longitude: 149.6225,
    accuracy: 3,
  },
  {
    postcode: 4420,
    place_name: 'Taroom',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6393,
    longitude: 149.7998,
    accuracy: 4,
  },
  {
    postcode: 4420,
    place_name: 'Gwambegwine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4868,
    longitude: 149.6225,
    accuracy: 3,
  },
  {
    postcode: 4420,
    place_name: 'Hornet Bank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4868,
    longitude: 149.6225,
    accuracy: 3,
  },
  {
    postcode: 4420,
    place_name: 'Glenhaughton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4868,
    longitude: 149.6225,
    accuracy: 3,
  },
  {
    postcode: 4420,
    place_name: 'Broadmere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4868,
    longitude: 149.6225,
    accuracy: 3,
  },
  {
    postcode: 4421,
    place_name: 'Tara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2761,
    longitude: 150.4568,
    accuracy: 4,
  },
  {
    postcode: 4421,
    place_name: 'Goranba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2879,
    longitude: 150.6003,
    accuracy: 4,
  },
  {
    postcode: 4422,
    place_name: 'Flinton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.8833,
    longitude: 149.5667,
    accuracy: 4,
  },
  {
    postcode: 4422,
    place_name: 'Inglestone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6083,
    longitude: 149.725,
    accuracy: 3,
  },
  {
    postcode: 4422,
    place_name: 'Meandarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3228,
    longitude: 149.8825,
    accuracy: 4,
  },
  {
    postcode: 4422,
    place_name: 'Coomrith',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6083,
    longitude: 149.725,
    accuracy: 3,
  },
  {
    postcode: 4422,
    place_name: 'Westmar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6083,
    longitude: 149.725,
    accuracy: 3,
  },
  {
    postcode: 4423,
    place_name: 'Glenmorgan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.254,
    longitude: 149.681,
    accuracy: 4,
  },
  {
    postcode: 4423,
    place_name: 'Teelba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.25,
    longitude: 149.6833,
    accuracy: 3,
  },
  {
    postcode: 4424,
    place_name: 'Drillham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6431,
    longitude: 149.9762,
    accuracy: 4,
  },
  {
    postcode: 4424,
    place_name: 'Drillham South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7755,
    longitude: 149.8266,
    accuracy: 3,
  },
  {
    postcode: 4424,
    place_name: 'Glenaubyn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7755,
    longitude: 149.8266,
    accuracy: 3,
  },
  {
    postcode: 4425,
    place_name: 'Dulacca',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6452,
    longitude: 149.7612,
    accuracy: 4,
  },
  {
    postcode: 4425,
    place_name: 'Bogandilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5,
    longitude: 149.8,
    accuracy: 4,
  },
  {
    postcode: 4426,
    place_name: 'Jackson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6452,
    longitude: 149.626,
    accuracy: 4,
  },
  {
    postcode: 4426,
    place_name: 'Jackson North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5332,
    longitude: 149.5778,
    accuracy: 3,
  },
  {
    postcode: 4426,
    place_name: 'Jackson South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5332,
    longitude: 149.5778,
    accuracy: 3,
  },
  {
    postcode: 4427,
    place_name: 'Yuleba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6115,
    longitude: 149.3821,
    accuracy: 4,
  },
  {
    postcode: 4427,
    place_name: 'Clifford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6115,
    longitude: 149.3821,
    accuracy: 3,
  },
  {
    postcode: 4427,
    place_name: 'Yuleba South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6171,
    longitude: 149.3784,
    accuracy: 3,
  },
  {
    postcode: 4427,
    place_name: 'Yuleba North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6171,
    longitude: 149.3784,
    accuracy: 3,
  },
  {
    postcode: 4428,
    place_name: 'Wallumbilla South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6506,
    longitude: 149.2272,
    accuracy: 3,
  },
  {
    postcode: 4428,
    place_name: 'Wallumbilla North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3092,
    longitude: 149.2359,
    accuracy: 3,
  },
  {
    postcode: 4428,
    place_name: 'Wallumbilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5827,
    longitude: 149.1871,
    accuracy: 4,
  },
  {
    postcode: 4428,
    place_name: 'Pickanjinnie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5831,
    longitude: 149.1133,
    accuracy: 4,
  },
  {
    postcode: 4454,
    place_name: 'Durham Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4454,
    place_name: 'Injune',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.8411,
    longitude: 148.5665,
    accuracy: 4,
  },
  {
    postcode: 4454,
    place_name: 'Baffle West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4454,
    place_name: 'Highland Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4454,
    place_name: 'Hutton Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4454,
    place_name: 'Beilba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4454,
    place_name: 'Simmie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4454,
    place_name: 'Westgrove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4454,
    place_name: 'Upper Dawson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4454,
    place_name: 'Mount Hutton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4454,
    place_name: 'Pony Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5572,
    longitude: 148.1783,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Orallo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.276,
    longitude: 148.5434,
    accuracy: 4,
  },
  {
    postcode: 4455,
    place_name: 'Roma',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5674,
    longitude: 148.7875,
    accuracy: 4,
  },
  {
    postcode: 4455,
    place_name: 'Dargal Road',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5495,
    longitude: 148.7172,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Wycombe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3605,
    longitude: 148.7068,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Euthulla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4913,
    longitude: 148.6656,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Mooga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2667,
    longitude: 148.9333,
    accuracy: 4,
  },
  {
    postcode: 4455,
    place_name: 'Tingun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.744,
    longitude: 148.9144,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Cornwall',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7779,
    longitude: 148.6938,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Ballaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3605,
    longitude: 148.7068,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Mount Abundance',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7779,
    longitude: 148.6938,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Bymount',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0667,
    longitude: 148.5333,
    accuracy: 4,
  },
  {
    postcode: 4455,
    place_name: 'Eumamurrin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1565,
    longitude: 148.6575,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Blythdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.581,
    longitude: 148.9559,
    accuracy: 4,
  },
  {
    postcode: 4455,
    place_name: 'Orange Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7779,
    longitude: 148.6938,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Hodgson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5667,
    longitude: 148.6167,
    accuracy: 4,
  },
  {
    postcode: 4455,
    place_name: 'Mount Bindango',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7779,
    longitude: 148.6938,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Gunnewin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9884,
    longitude: 148.5586,
    accuracy: 4,
  },
  {
    postcode: 4455,
    place_name: 'Bungil',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7779,
    longitude: 148.6938,
    accuracy: 3,
  },
  {
    postcode: 4455,
    place_name: 'Bungeworgorai',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5798,
    longitude: 148.7197,
    accuracy: 4,
  },
  {
    postcode: 4461,
    place_name: 'Muckadilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5896,
    longitude: 148.3919,
    accuracy: 4,
  },
  {
    postcode: 4462,
    place_name: 'Amby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.55,
    longitude: 148.1833,
    accuracy: 4,
  },
  {
    postcode: 4465,
    place_name: 'Forestvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4924,
    longitude: 147.9038,
    accuracy: 3,
  },
  {
    postcode: 4465,
    place_name: 'Womalilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5,
    longitude: 147.8333,
    accuracy: 4,
  },
  {
    postcode: 4465,
    place_name: 'Dunkeld',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4924,
    longitude: 147.9038,
    accuracy: 3,
  },
  {
    postcode: 4465,
    place_name: 'V Gate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4924,
    longitude: 147.9038,
    accuracy: 3,
  },
  {
    postcode: 4465,
    place_name: 'Mitchell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4849,
    longitude: 147.9742,
    accuracy: 4,
  },
  {
    postcode: 4467,
    place_name: 'Mungallala',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4491,
    longitude: 147.5418,
    accuracy: 4,
  },
  {
    postcode: 4467,
    place_name: 'Tyrconnel',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.45,
    longitude: 147.5333,
    accuracy: 3,
  },
  {
    postcode: 4467,
    place_name: 'Redford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.45,
    longitude: 147.5333,
    accuracy: 3,
  },
  {
    postcode: 4468,
    place_name: 'Clara Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4167,
    longitude: 147.1167,
    accuracy: 3,
  },
  {
    postcode: 4468,
    place_name: 'Morven',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4177,
    longitude: 147.1139,
    accuracy: 4,
  },
  {
    postcode: 4470,
    place_name: 'Riversleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2694,
    longitude: 145.9547,
    accuracy: 3,
  },
  {
    postcode: 4470,
    place_name: 'Charleville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4054,
    longitude: 146.2427,
    accuracy: 4,
  },
  {
    postcode: 4470,
    place_name: 'Bakers Bend',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2694,
    longitude: 145.9547,
    accuracy: 3,
  },
  {
    postcode: 4470,
    place_name: 'Langlo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1333,
    longitude: 145.6667,
    accuracy: 4,
  },
  {
    postcode: 4470,
    place_name: 'Murweh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2694,
    longitude: 145.9547,
    accuracy: 3,
  },
  {
    postcode: 4470,
    place_name: 'Gowrie Station',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2694,
    longitude: 145.9547,
    accuracy: 3,
  },
  {
    postcode: 4471,
    place_name: 'Claverton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3726,
    longitude: 145.9577,
    accuracy: 4,
  },
  {
    postcode: 4472,
    place_name: 'Blackall',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4233,
    longitude: 145.463,
    accuracy: 4,
  },
  {
    postcode: 4472,
    place_name: 'Mount Enniskillen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.587,
    longitude: 145.403,
    accuracy: 3,
  },
  {
    postcode: 4474,
    place_name: 'Adavale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9073,
    longitude: 144.6008,
    accuracy: 4,
  },
  {
    postcode: 4475,
    place_name: 'Cheepie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6333,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 4477,
    place_name: 'Augathella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7962,
    longitude: 146.5845,
    accuracy: 4,
  },
  {
    postcode: 4477,
    place_name: 'Upper Warrego',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6351,
    longitude: 145.9587,
    accuracy: 3,
  },
  {
    postcode: 4478,
    place_name: 'Tambo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8819,
    longitude: 146.2575,
    accuracy: 4,
  },
  {
    postcode: 4478,
    place_name: 'Lumeah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0182,
    longitude: 146.2489,
    accuracy: 3,
  },
  {
    postcode: 4478,
    place_name: 'Caldervale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0182,
    longitude: 146.2489,
    accuracy: 3,
  },
  {
    postcode: 4478,
    place_name: 'Macfarlane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0182,
    longitude: 146.2489,
    accuracy: 3,
  },
  {
    postcode: 4478,
    place_name: 'Minnie Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0182,
    longitude: 146.2489,
    accuracy: 3,
  },
  {
    postcode: 4478,
    place_name: 'Bayrick',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0182,
    longitude: 146.2489,
    accuracy: 3,
  },
  {
    postcode: 4478,
    place_name: 'Windeyer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0182,
    longitude: 146.2489,
    accuracy: 3,
  },
  {
    postcode: 4478,
    place_name: 'Yandarlo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0182,
    longitude: 146.2489,
    accuracy: 3,
  },
  {
    postcode: 4478,
    place_name: 'Lansdowne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0182,
    longitude: 146.2489,
    accuracy: 3,
  },
  {
    postcode: 4478,
    place_name: 'Scrubby Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0182,
    longitude: 146.2489,
    accuracy: 3,
  },
  {
    postcode: 4479,
    place_name: 'Cooladdi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6426,
    longitude: 145.4644,
    accuracy: 4,
  },
  {
    postcode: 4480,
    place_name: 'Quilpie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6135,
    longitude: 144.2695,
    accuracy: 4,
  },
  {
    postcode: 4480,
    place_name: 'Eromanga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6706,
    longitude: 143.2664,
    accuracy: 4,
  },
  {
    postcode: 4481,
    place_name: 'Tanbar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4211,
    longitude: 141.8981,
    accuracy: 3,
  },
  {
    postcode: 4481,
    place_name: 'Windorah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4222,
    longitude: 142.6566,
    accuracy: 4,
  },
  {
    postcode: 4481,
    place_name: 'Farrars Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1566,
    longitude: 147.6171,
    accuracy: 3,
  },
  {
    postcode: 4482,
    place_name: 'Birdsville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.592,
    longitude: 140.1157,
    accuracy: 1,
  },
  {
    postcode: 4486,
    place_name: 'Hebel',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.9733,
    longitude: 147.7948,
    accuracy: 4,
  },
  {
    postcode: 4486,
    place_name: 'Dirranbandi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5854,
    longitude: 148.2255,
    accuracy: 4,
  },
  {
    postcode: 4487,
    place_name: 'St George',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9476,
    longitude: 148.6099,
    accuracy: 4,
  },
  {
    postcode: 4487,
    place_name: 'Begonia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9476,
    longitude: 148.6099,
    accuracy: 3,
  },
  {
    postcode: 4488,
    place_name: 'Nebine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2776,
    longitude: 147.4572,
    accuracy: 3,
  },
  {
    postcode: 4488,
    place_name: 'Bollon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0333,
    longitude: 147.4833,
    accuracy: 4,
  },
  {
    postcode: 4489,
    place_name: 'Wyandra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2478,
    longitude: 145.9805,
    accuracy: 4,
  },
  {
    postcode: 4490,
    place_name: 'Cunnamulla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0677,
    longitude: 145.6844,
    accuracy: 4,
  },
  {
    postcode: 4490,
    place_name: 'Tuen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5667,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 4490,
    place_name: 'Widgeegoara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2377,
    longitude: 145.9073,
    accuracy: 3,
  },
  {
    postcode: 4490,
    place_name: 'Barringun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -29.0141,
    longitude: 145.7159,
    accuracy: 4,
  },
  {
    postcode: 4490,
    place_name: 'Coongoola',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.6536,
    longitude: 145.9022,
    accuracy: 4,
  },
  {
    postcode: 4490,
    place_name: 'Yowah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.9683,
    longitude: 144.6362,
    accuracy: 3,
  },
  {
    postcode: 4490,
    place_name: 'Noorama',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2377,
    longitude: 145.9073,
    accuracy: 3,
  },
  {
    postcode: 4490,
    place_name: 'Cuttaburra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2377,
    longitude: 145.9073,
    accuracy: 3,
  },
  {
    postcode: 4490,
    place_name: 'Humeburn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2377,
    longitude: 145.9073,
    accuracy: 3,
  },
  {
    postcode: 4490,
    place_name: 'Linden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2377,
    longitude: 145.9073,
    accuracy: 3,
  },
  {
    postcode: 4490,
    place_name: 'Jobs Gate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2377,
    longitude: 145.9073,
    accuracy: 3,
  },
  {
    postcode: 4491,
    place_name: 'Eulo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.1599,
    longitude: 145.0471,
    accuracy: 4,
  },
  {
    postcode: 4492,
    place_name: 'Dynevor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.375,
    longitude: 148.7972,
    accuracy: 3,
  },
  {
    postcode: 4492,
    place_name: 'Nockatunga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.375,
    longitude: 148.7972,
    accuracy: 3,
  },
  {
    postcode: 4492,
    place_name: 'Bullawarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.375,
    longitude: 148.7972,
    accuracy: 3,
  },
  {
    postcode: 4492,
    place_name: 'Norley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.375,
    longitude: 148.7972,
    accuracy: 3,
  },
  {
    postcode: 4492,
    place_name: 'Bulloo Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.375,
    longitude: 148.7972,
    accuracy: 3,
  },
  {
    postcode: 4492,
    place_name: 'Thargomindah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.995,
    longitude: 143.8239,
    accuracy: 4,
  },
  {
    postcode: 4493,
    place_name: 'Hungerford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.9963,
    longitude: 144.407,
    accuracy: 4,
  },
  {
    postcode: 4494,
    place_name: 'Bungunya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4294,
    longitude: 149.6555,
    accuracy: 4,
  },
  {
    postcode: 4494,
    place_name: 'North Bungunya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2718,
    longitude: 149.8403,
    accuracy: 3,
  },
  {
    postcode: 4494,
    place_name: 'Tarawera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.2718,
    longitude: 149.8403,
    accuracy: 3,
  },
  {
    postcode: 4496,
    place_name: 'Talwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4858,
    longitude: 149.4681,
    accuracy: 4,
  },
  {
    postcode: 4496,
    place_name: 'South Talwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.3773,
    longitude: 149.1424,
    accuracy: 3,
  },
  {
    postcode: 4496,
    place_name: 'North Talwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.3773,
    longitude: 149.1424,
    accuracy: 3,
  },
  {
    postcode: 4497,
    place_name: 'Thallon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.6379,
    longitude: 148.8683,
    accuracy: 4,
  },
  {
    postcode: 4497,
    place_name: 'Weengallon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.5021,
    longitude: 148.8399,
    accuracy: 3,
  },
  {
    postcode: 4497,
    place_name: 'Daymar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.6043,
    longitude: 148.9792,
    accuracy: 4,
  },
  {
    postcode: 4498,
    place_name: 'Kioma',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.332,
    longitude: 149.5801,
    accuracy: 3,
  },
  {
    postcode: 4498,
    place_name: 'Toobeah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.4206,
    longitude: 149.8662,
    accuracy: 4,
  },
  {
    postcode: 4500,
    place_name: 'Brendale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3211,
    longitude: 152.9839,
    accuracy: 4,
  },
  {
    postcode: 4500,
    place_name: 'Warner',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3122,
    longitude: 152.9498,
    accuracy: 4,
  },
  {
    postcode: 4500,
    place_name: 'Brendale BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3006,
    longitude: 152.9571,
    accuracy: 3,
  },
  {
    postcode: 4500,
    place_name: 'Clear Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3052,
    longitude: 152.883,
    accuracy: 4,
  },
  {
    postcode: 4500,
    place_name: 'Strathpine Centre',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3006,
    longitude: 152.9571,
    accuracy: 3,
  },
  {
    postcode: 4500,
    place_name: 'Joyner',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2761,
    longitude: 152.9447,
    accuracy: 4,
  },
  {
    postcode: 4500,
    place_name: 'Brendale DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3006,
    longitude: 152.9571,
    accuracy: 3,
  },
  {
    postcode: 4500,
    place_name: 'Strathpine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3041,
    longitude: 152.9898,
    accuracy: 4,
  },
  {
    postcode: 4500,
    place_name: 'Cashmere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2977,
    longitude: 152.9066,
    accuracy: 4,
  },
  {
    postcode: 4500,
    place_name: 'Bray Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2925,
    longitude: 152.9678,
    accuracy: 4,
  },
  {
    postcode: 4501,
    place_name: 'Lawnton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2833,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4502,
    place_name: 'Petrie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2667,
    longitude: 152.9833,
    accuracy: 4,
  },
  {
    postcode: 4503,
    place_name: 'Murrumba Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2673,
    longitude: 153.0105,
    accuracy: 4,
  },
  {
    postcode: 4503,
    place_name: 'Kallangur',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.252,
    longitude: 152.9923,
    accuracy: 4,
  },
  {
    postcode: 4503,
    place_name: 'Griffin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2715,
    longitude: 153.0394,
    accuracy: 4,
  },
  {
    postcode: 4503,
    place_name: 'Kurwongbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2325,
    longitude: 152.9762,
    accuracy: 4,
  },
  {
    postcode: 4503,
    place_name: 'Dakabin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2259,
    longitude: 152.991,
    accuracy: 4,
  },
  {
    postcode: 4503,
    place_name: 'Whiteside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2571,
    longitude: 152.9116,
    accuracy: 4,
  },
  {
    postcode: 4504,
    place_name: 'Narangba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2034,
    longitude: 152.9592,
    accuracy: 4,
  },
  {
    postcode: 4505,
    place_name: 'Burpengary',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1575,
    longitude: 152.9576,
    accuracy: 4,
  },
  {
    postcode: 4505,
    place_name: 'Burpengary DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1575,
    longitude: 152.9576,
    accuracy: 3,
  },
  {
    postcode: 4505,
    place_name: 'Burpengary East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1575,
    longitude: 152.9576,
    accuracy: 3,
  },
  {
    postcode: 4506,
    place_name: 'Moorina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1491,
    longitude: 152.873,
    accuracy: 4,
  },
  {
    postcode: 4506,
    place_name: 'Morayfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1088,
    longitude: 152.9491,
    accuracy: 4,
  },
  {
    postcode: 4507,
    place_name: 'Bongaree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0837,
    longitude: 153.1594,
    accuracy: 4,
  },
  {
    postcode: 4507,
    place_name: 'Bellara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0639,
    longitude: 153.1496,
    accuracy: 4,
  },
  {
    postcode: 4507,
    place_name: 'Banksia Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0403,
    longitude: 153.1439,
    accuracy: 4,
  },
  {
    postcode: 4507,
    place_name: 'Woorim',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0693,
    longitude: 153.2043,
    accuracy: 4,
  },
  {
    postcode: 4507,
    place_name: 'Bribie Island North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0149,
    longitude: 153.1446,
    accuracy: 3,
  },
  {
    postcode: 4507,
    place_name: 'White Patch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0307,
    longitude: 153.1282,
    accuracy: 4,
  },
  {
    postcode: 4507,
    place_name: 'Bribie Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0149,
    longitude: 153.1446,
    accuracy: 3,
  },
  {
    postcode: 4507,
    place_name: 'Welsby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9714,
    longitude: 153.1292,
    accuracy: 4,
  },
  {
    postcode: 4508,
    place_name: 'Deception Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1935,
    longitude: 153.0263,
    accuracy: 4,
  },
  {
    postcode: 4509,
    place_name: 'North Lakes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2243,
    longitude: 153.0208,
    accuracy: 4,
  },
  {
    postcode: 4509,
    place_name: 'Mango Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2435,
    longitude: 153.0238,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Caboolture',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0846,
    longitude: 152.9511,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Rocksberg',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1,
    longitude: 152.8333,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Upper Caboolture',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1167,
    longitude: 152.8833,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Caboolture BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0772,
    longitude: 152.9595,
    accuracy: 3,
  },
  {
    postcode: 4510,
    place_name: 'Caboolture South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0939,
    longitude: 152.9487,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Donnybrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.005,
    longitude: 153.0673,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Moodlu',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0667,
    longitude: 152.9,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Toorbul',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0322,
    longitude: 153.0983,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Bellmere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0844,
    longitude: 152.89,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Meldale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0323,
    longitude: 153.0724,
    accuracy: 4,
  },
  {
    postcode: 4510,
    place_name: 'Beachmere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1281,
    longitude: 153.0524,
    accuracy: 4,
  },
  {
    postcode: 4511,
    place_name: 'Godwin Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0861,
    longitude: 153.0986,
    accuracy: 4,
  },
  {
    postcode: 4511,
    place_name: 'Sandstone Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0837,
    longitude: 153.1324,
    accuracy: 4,
  },
  {
    postcode: 4511,
    place_name: 'Ningi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0611,
    longitude: 153.0788,
    accuracy: 4,
  },
  {
    postcode: 4512,
    place_name: 'Wamuran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0391,
    longitude: 152.8654,
    accuracy: 4,
  },
  {
    postcode: 4512,
    place_name: 'Wamuran Basin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0475,
    longitude: 152.8262,
    accuracy: 4,
  },
  {
    postcode: 4512,
    place_name: 'Bracalba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0167,
    longitude: 152.85,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: "D'Aguilar",
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9833,
    longitude: 152.8,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Woodford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9529,
    longitude: 152.7775,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Mount Delaney',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0147,
    longitude: 152.7148,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Villeneuve',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9667,
    longitude: 152.6333,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Neurum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9667,
    longitude: 152.7,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Bellthorpe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8554,
    longitude: 152.7609,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Stony Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9272,
    longitude: 152.7124,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Stanmore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8814,
    longitude: 152.788,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Cedarton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.85,
    longitude: 152.8167,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Delaneys Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0002,
    longitude: 152.7955,
    accuracy: 4,
  },
  {
    postcode: 4514,
    place_name: 'Mount Archer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9307,
    longitude: 152.7552,
    accuracy: 3,
  },
  {
    postcode: 4514,
    place_name: 'Commissioners Flat',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9307,
    longitude: 152.7552,
    accuracy: 3,
  },
  {
    postcode: 4515,
    place_name: 'Hazeldean',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0321,
    longitude: 152.5404,
    accuracy: 4,
  },
  {
    postcode: 4515,
    place_name: 'Jimna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6676,
    longitude: 152.4633,
    accuracy: 4,
  },
  {
    postcode: 4515,
    place_name: 'Royston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.95,
    longitude: 152.65,
    accuracy: 4,
  },
  {
    postcode: 4515,
    place_name: 'Kilcoy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.943,
    longitude: 152.5654,
    accuracy: 4,
  },
  {
    postcode: 4515,
    place_name: 'Sandy Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8506,
    longitude: 152.6377,
    accuracy: 4,
  },
  {
    postcode: 4515,
    place_name: 'Woolmar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8907,
    longitude: 152.577,
    accuracy: 3,
  },
  {
    postcode: 4515,
    place_name: 'Winya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8907,
    longitude: 152.577,
    accuracy: 3,
  },
  {
    postcode: 4515,
    place_name: 'Sheep Station Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8907,
    longitude: 152.577,
    accuracy: 3,
  },
  {
    postcode: 4515,
    place_name: 'Mount Kilcoy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8426,
    longitude: 152.5787,
    accuracy: 4,
  },
  {
    postcode: 4515,
    place_name: 'Hazledean',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7416,
    longitude: 152.4735,
    accuracy: 3,
  },
  {
    postcode: 4515,
    place_name: 'Glenfern',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.95,
    longitude: 152.6,
    accuracy: 4,
  },
  {
    postcode: 4515,
    place_name: 'Monsildale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7416,
    longitude: 152.4735,
    accuracy: 3,
  },
  {
    postcode: 4515,
    place_name: 'Kingaham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8907,
    longitude: 152.577,
    accuracy: 3,
  },
  {
    postcode: 4516,
    place_name: 'Elimbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0167,
    longitude: 152.95,
    accuracy: 4,
  },
  {
    postcode: 4517,
    place_name: 'Beerburrum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.957,
    longitude: 152.9554,
    accuracy: 4,
  },
  {
    postcode: 4518,
    place_name: 'Glass House Mountains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8976,
    longitude: 152.9532,
    accuracy: 4,
  },
  {
    postcode: 4519,
    place_name: 'Beerwah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8588,
    longitude: 152.9614,
    accuracy: 4,
  },
  {
    postcode: 4519,
    place_name: 'Crohamhurst',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.807,
    longitude: 152.8617,
    accuracy: 4,
  },
  {
    postcode: 4519,
    place_name: 'Peachester',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8455,
    longitude: 152.884,
    accuracy: 4,
  },
  {
    postcode: 4519,
    place_name: 'Coochin Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.9051,
    longitude: 153.0749,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Mount Samson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3,
    longitude: 152.85,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Samford Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3727,
    longitude: 152.867,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Mount Nebo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3974,
    longitude: 152.7845,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Kobble Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.25,
    longitude: 152.8333,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Cedar Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3335,
    longitude: 152.8187,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Yugar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3457,
    longitude: 152.8718,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Highvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3723,
    longitude: 152.802,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Mount Glorious',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3339,
    longitude: 152.7668,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Samford Village',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3727,
    longitude: 152.8866,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Wights Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3933,
    longitude: 152.8554,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Closeburn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3333,
    longitude: 152.8333,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Samford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3727,
    longitude: 152.8866,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Draper',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.3526,
    longitude: 152.9065,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Jollys Lookout',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4001,
    longitude: 152.8073,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Armstrong Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2167,
    longitude: 152.8333,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Enoggera Reservoir',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4335,
    longitude: 152.8705,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Samsonvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2667,
    longitude: 152.85,
    accuracy: 4,
  },
  {
    postcode: 4520,
    place_name: 'Camp Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4,
    longitude: 152.8833,
    accuracy: 4,
  },
  {
    postcode: 4521,
    place_name: 'Campbells Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0767,
    longitude: 152.805,
    accuracy: 4,
  },
  {
    postcode: 4521,
    place_name: 'Dayboro',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1963,
    longitude: 152.8225,
    accuracy: 4,
  },
  {
    postcode: 4521,
    place_name: 'Ocean View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1271,
    longitude: 152.8053,
    accuracy: 4,
  },
  {
    postcode: 4521,
    place_name: 'Mount Pleasant',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1489,
    longitude: 152.7636,
    accuracy: 4,
  },
  {
    postcode: 4521,
    place_name: 'Mount Mee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.0806,
    longitude: 152.7702,
    accuracy: 4,
  },
  {
    postcode: 4521,
    place_name: 'Rush Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2086,
    longitude: 152.8642,
    accuracy: 4,
  },
  {
    postcode: 4521,
    place_name: 'Laceys Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.2319,
    longitude: 152.7313,
    accuracy: 4,
  },
  {
    postcode: 4521,
    place_name: 'King Scrub',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.1732,
    longitude: 152.8215,
    accuracy: 4,
  },
  {
    postcode: 4550,
    place_name: 'Landsborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8067,
    longitude: 152.961,
    accuracy: 4,
  },
  {
    postcode: 4550,
    place_name: 'Mount Mellum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.816,
    longitude: 152.9335,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Kings Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8017,
    longitude: 153.1426,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Shelly Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7965,
    longitude: 153.1458,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Aroona',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7817,
    longitude: 153.1165,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Little Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7844,
    longitude: 153.0922,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Caloundra BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7961,
    longitude: 153.1229,
    accuracy: 3,
  },
  {
    postcode: 4551,
    place_name: 'Bells Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7836,
    longitude: 153.0605,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Golden Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8218,
    longitude: 153.1197,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Currimundi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7693,
    longitude: 153.1224,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Battery Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7788,
    longitude: 153.1289,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Pelican Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8335,
    longitude: 153.1001,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Caloundra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8035,
    longitude: 153.1219,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Moffat Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7905,
    longitude: 153.14,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Caloundra DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7961,
    longitude: 153.1229,
    accuracy: 3,
  },
  {
    postcode: 4551,
    place_name: 'Caloundra West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7985,
    longitude: 153.114,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Meridan Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7735,
    longitude: 153.0619,
    accuracy: 4,
  },
  {
    postcode: 4551,
    place_name: 'Dicky Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7836,
    longitude: 153.1377,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Booroobin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8224,
    longitude: 152.7666,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Maleny',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.76,
    longitude: 152.8493,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'North Maleny',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7521,
    longitude: 152.8583,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Curramore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6937,
    longitude: 152.803,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Cambroon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6394,
    longitude: 152.6853,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Reesville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7595,
    longitude: 152.7879,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Bald Knob',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.779,
    longitude: 152.9118,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Elaman Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7374,
    longitude: 152.7524,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Conondale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7364,
    longitude: 152.7199,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Balmoral Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7445,
    longitude: 152.8922,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Crystal Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7395,
    longitude: 152.8249,
    accuracy: 3,
  },
  {
    postcode: 4552,
    place_name: 'Wootha',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7882,
    longitude: 152.8009,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Witta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7,
    longitude: 152.8167,
    accuracy: 4,
  },
  {
    postcode: 4552,
    place_name: 'Harper Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7623,
    longitude: 152.7117,
    accuracy: 4,
  },
  {
    postcode: 4553,
    place_name: 'Glenview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7667,
    longitude: 153.0167,
    accuracy: 4,
  },
  {
    postcode: 4553,
    place_name: 'Mooloolah Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7655,
    longitude: 152.9587,
    accuracy: 4,
  },
  {
    postcode: 4553,
    place_name: 'Mooloolah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7655,
    longitude: 152.9587,
    accuracy: 4,
  },
  {
    postcode: 4553,
    place_name: 'Diamond Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7552,
    longitude: 152.9293,
    accuracy: 4,
  },
  {
    postcode: 4553,
    place_name: 'Palmview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.746,
    longitude: 153.0599,
    accuracy: 4,
  },
  {
    postcode: 4554,
    place_name: 'Ilkley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.731,
    longitude: 152.9977,
    accuracy: 4,
  },
  {
    postcode: 4554,
    place_name: 'Eudlo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7296,
    longitude: 152.954,
    accuracy: 4,
  },
  {
    postcode: 4555,
    place_name: 'Chevallum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7007,
    longitude: 152.9935,
    accuracy: 4,
  },
  {
    postcode: 4555,
    place_name: 'Palmwoods',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6858,
    longitude: 152.9613,
    accuracy: 4,
  },
  {
    postcode: 4555,
    place_name: 'Hunchy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6799,
    longitude: 152.9109,
    accuracy: 4,
  },
  {
    postcode: 4555,
    place_name: 'Landers Shoot',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7134,
    longitude: 152.9267,
    accuracy: 4,
  },
  {
    postcode: 4556,
    place_name: 'Mons',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6916,
    longitude: 153.025,
    accuracy: 4,
  },
  {
    postcode: 4556,
    place_name: 'Forest Glen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6886,
    longitude: 153.0045,
    accuracy: 4,
  },
  {
    postcode: 4556,
    place_name: 'Sippy Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7179,
    longitude: 153.0547,
    accuracy: 4,
  },
  {
    postcode: 4556,
    place_name: 'Buderim',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6844,
    longitude: 153.0571,
    accuracy: 4,
  },
  {
    postcode: 4556,
    place_name: 'Kunda Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6643,
    longitude: 153.0354,
    accuracy: 4,
  },
  {
    postcode: 4556,
    place_name: 'Tanawha',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7198,
    longitude: 153.0302,
    accuracy: 4,
  },
  {
    postcode: 4557,
    place_name: 'Mountain Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7028,
    longitude: 153.1013,
    accuracy: 4,
  },
  {
    postcode: 4557,
    place_name: 'Mooloolaba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6816,
    longitude: 153.1192,
    accuracy: 4,
  },
  {
    postcode: 4558,
    place_name: 'Maroochydore DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6589,
    longitude: 153.0778,
    accuracy: 3,
  },
  {
    postcode: 4558,
    place_name: 'Sunshine Plaza',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6546,
    longitude: 153.0777,
    accuracy: 3,
  },
  {
    postcode: 4558,
    place_name: 'Maroochydore Bc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6546,
    longitude: 153.0777,
    accuracy: 3,
  },
  {
    postcode: 4558,
    place_name: 'Maroochydore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6601,
    longitude: 153.0995,
    accuracy: 4,
  },
  {
    postcode: 4558,
    place_name: 'Kuluin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6578,
    longitude: 153.0561,
    accuracy: 4,
  },
  {
    postcode: 4558,
    place_name: 'Cotton Tree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6535,
    longitude: 153.1012,
    accuracy: 4,
  },
  {
    postcode: 4559,
    place_name: 'Woombye',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6605,
    longitude: 152.9695,
    accuracy: 4,
  },
  {
    postcode: 4559,
    place_name: 'Diddillibah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6417,
    longitude: 153.0496,
    accuracy: 4,
  },
  {
    postcode: 4559,
    place_name: 'Kiels Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6565,
    longitude: 153.0081,
    accuracy: 4,
  },
  {
    postcode: 4559,
    place_name: 'West Woombye',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6664,
    longitude: 152.9256,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Parklands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5955,
    longitude: 152.9789,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Nambour BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6218,
    longitude: 152.9337,
    accuracy: 3,
  },
  {
    postcode: 4560,
    place_name: 'Burnside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6325,
    longitude: 152.9406,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Towen Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6468,
    longitude: 152.9284,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Rosemount',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6303,
    longitude: 152.9997,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Kiamba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5727,
    longitude: 152.9241,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Kulangoor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5833,
    longitude: 152.9667,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Nambour',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6261,
    longitude: 152.9594,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Dulong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6339,
    longitude: 152.9062,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Image Flat',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.601,
    longitude: 152.933,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Mapleton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6243,
    longitude: 152.8678,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Highworth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6174,
    longitude: 152.9344,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Perwillowen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6333,
    longitude: 152.9167,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Coes Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6472,
    longitude: 152.9494,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Montville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6833,
    longitude: 152.8833,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Nambour DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6218,
    longitude: 152.9337,
    accuracy: 3,
  },
  {
    postcode: 4560,
    place_name: 'Cooloolabin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.55,
    longitude: 152.8833,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Sunshine Coast Mc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6218,
    longitude: 152.9337,
    accuracy: 3,
  },
  {
    postcode: 4560,
    place_name: 'Nambour West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6218,
    longitude: 152.9337,
    accuracy: 3,
  },
  {
    postcode: 4560,
    place_name: 'Kureelpa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6067,
    longitude: 152.891,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Flaxton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6571,
    longitude: 152.8641,
    accuracy: 4,
  },
  {
    postcode: 4560,
    place_name: 'Bli Bli',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6172,
    longitude: 153.0367,
    accuracy: 4,
  },
  {
    postcode: 4561,
    place_name: 'Yandina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5616,
    longitude: 152.9559,
    accuracy: 4,
  },
  {
    postcode: 4561,
    place_name: 'Valdora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5459,
    longitude: 153.0068,
    accuracy: 4,
  },
  {
    postcode: 4561,
    place_name: 'Yandina Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5387,
    longitude: 153.0389,
    accuracy: 4,
  },
  {
    postcode: 4561,
    place_name: 'Maroochy River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5777,
    longitude: 153.0188,
    accuracy: 4,
  },
  {
    postcode: 4561,
    place_name: 'Ninderry',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5409,
    longitude: 152.9752,
    accuracy: 4,
  },
  {
    postcode: 4561,
    place_name: 'Bridges',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5333,
    longitude: 152.95,
    accuracy: 4,
  },
  {
    postcode: 4561,
    place_name: 'North Arm',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5248,
    longitude: 152.9552,
    accuracy: 4,
  },
  {
    postcode: 4562,
    place_name: 'Belli Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5085,
    longitude: 152.8212,
    accuracy: 4,
  },
  {
    postcode: 4562,
    place_name: 'Doonan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4396,
    longitude: 152.992,
    accuracy: 4,
  },
  {
    postcode: 4562,
    place_name: 'Verrierdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4821,
    longitude: 152.9986,
    accuracy: 4,
  },
  {
    postcode: 4562,
    place_name: 'Weyba Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4495,
    longitude: 153.0472,
    accuracy: 4,
  },
  {
    postcode: 4562,
    place_name: 'Eumundi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4774,
    longitude: 152.9512,
    accuracy: 4,
  },
  {
    postcode: 4562,
    place_name: 'Eerwah Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4794,
    longitude: 152.9112,
    accuracy: 4,
  },
  {
    postcode: 4563,
    place_name: 'Carters Ridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4525,
    longitude: 152.772,
    accuracy: 4,
  },
  {
    postcode: 4563,
    place_name: 'Black Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3989,
    longitude: 152.8756,
    accuracy: 4,
  },
  {
    postcode: 4563,
    place_name: 'Cooroy Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4252,
    longitude: 152.9541,
    accuracy: 4,
  },
  {
    postcode: 4563,
    place_name: 'Tinbeerwah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4044,
    longitude: 152.9826,
    accuracy: 4,
  },
  {
    postcode: 4563,
    place_name: 'Cooroy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4178,
    longitude: 152.9115,
    accuracy: 4,
  },
  {
    postcode: 4563,
    place_name: 'Ridgewood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4605,
    longitude: 152.7991,
    accuracy: 4,
  },
  {
    postcode: 4563,
    place_name: 'Lake Macdonald',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3848,
    longitude: 152.9321,
    accuracy: 4,
  },
  {
    postcode: 4564,
    place_name: 'Pacific Paradise',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6181,
    longitude: 153.0778,
    accuracy: 4,
  },
  {
    postcode: 4564,
    place_name: 'Marcoola',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5845,
    longitude: 153.0947,
    accuracy: 4,
  },
  {
    postcode: 4564,
    place_name: 'Mudjimba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.615,
    longitude: 153.0989,
    accuracy: 4,
  },
  {
    postcode: 4564,
    place_name: 'Twin Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.61,
    longitude: 153,
    accuracy: 4,
  },
  {
    postcode: 4565,
    place_name: 'Ringtail Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3439,
    longitude: 152.9668,
    accuracy: 4,
  },
  {
    postcode: 4565,
    place_name: 'Cootharaba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2667,
    longitude: 152.9667,
    accuracy: 4,
  },
  {
    postcode: 4565,
    place_name: 'Boreen Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2861,
    longitude: 152.9882,
    accuracy: 4,
  },
  {
    postcode: 4565,
    place_name: 'Noosa North Shore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3704,
    longitude: 153.0404,
    accuracy: 4,
  },
  {
    postcode: 4565,
    place_name: 'Cooroibah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3632,
    longitude: 152.986,
    accuracy: 4,
  },
  {
    postcode: 4565,
    place_name: 'Tewantin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.391,
    longitude: 153.0343,
    accuracy: 4,
  },
  {
    postcode: 4565,
    place_name: 'North Shore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3704,
    longitude: 153.0404,
    accuracy: 4,
  },
  {
    postcode: 4566,
    place_name: 'Noosaville DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4,
    longitude: 153.0667,
    accuracy: 3,
  },
  {
    postcode: 4566,
    place_name: 'Noosaville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4,
    longitude: 153.0667,
    accuracy: 4,
  },
  {
    postcode: 4567,
    place_name: 'Castaways Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4299,
    longitude: 153.0979,
    accuracy: 4,
  },
  {
    postcode: 4567,
    place_name: 'Sunshine Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.403,
    longitude: 153.0963,
    accuracy: 3,
  },
  {
    postcode: 4567,
    place_name: 'Sunrise Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4165,
    longitude: 153.103,
    accuracy: 4,
  },
  {
    postcode: 4567,
    place_name: 'Noosa Heads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3943,
    longitude: 153.0901,
    accuracy: 4,
  },
  {
    postcode: 4568,
    place_name: 'Pinbarren',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3333,
    longitude: 152.85,
    accuracy: 4,
  },
  {
    postcode: 4568,
    place_name: 'Federal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3942,
    longitude: 152.8076,
    accuracy: 4,
  },
  {
    postcode: 4568,
    place_name: 'Pomona',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3668,
    longitude: 152.8557,
    accuracy: 4,
  },
  {
    postcode: 4569,
    place_name: 'Cooran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3369,
    longitude: 152.8225,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Bollier',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4368,
    longitude: 152.7333,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Chatsworth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.144,
    longitude: 152.615,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Widgee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2203,
    longitude: 152.4079,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Gympie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1898,
    longitude: 152.665,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Theebine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9505,
    longitude: 152.5409,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Calgoa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Nahrunda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2026,
    longitude: 152.6246,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Traveston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3167,
    longitude: 152.7833,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Lagoon Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2667,
    longitude: 152.6667,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Veteran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.159,
    longitude: 152.6898,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Gunalda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9987,
    longitude: 152.562,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Southside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.21,
    longitude: 152.6472,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Tamaree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1346,
    longitude: 152.6636,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Kia Ora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Langshaw',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3,
    longitude: 152.5667,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Imbil',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4596,
    longitude: 152.6781,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Dagun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3167,
    longitude: 152.6833,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Banks Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Kandanga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3927,
    longitude: 152.6767,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'East Deep Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1976,
    longitude: 152.7175,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Amamoor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3492,
    longitude: 152.6793,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Mooloo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Wallu',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2008,
    longitude: 152.6451,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Victory Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1723,
    longitude: 152.6895,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Bells Bridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1258,
    longitude: 152.5387,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Canina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.144,
    longitude: 152.7405,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Pie Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2008,
    longitude: 152.6451,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Upper Kandanga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4167,
    longitude: 152.5833,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Woondum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.25,
    longitude: 152.7333,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Kanigan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9309,
    longitude: 152.5983,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Gympie DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2008,
    longitude: 152.6451,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Gilldora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3,
    longitude: 152.6667,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Glanmire',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2294,
    longitude: 152.6964,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Glenwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Cedar Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2008,
    longitude: 152.6451,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Curra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0667,
    longitude: 152.5833,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Kybong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3167,
    longitude: 152.7167,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Coles Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Toolara Forest',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9966,
    longitude: 152.8371,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Paterson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.8908,
    longitude: 152.5438,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Monkland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2119,
    longitude: 152.6824,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Fishermans Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Mothar Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2499,
    longitude: 152.7737,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Widgee Crossing South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Tandur',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2833,
    longitude: 152.75,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Glen Echo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Goomboorian',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.081,
    longitude: 152.7912,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Greens Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1705,
    longitude: 152.7536,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Wilsons Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1231,
    longitude: 152.7999,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Lake Borumba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Araluen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1571,
    longitude: 152.6522,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Corella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1,
    longitude: 152.6333,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Jones Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2292,
    longitude: 152.6688,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Neusa Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Lower Wonga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2008,
    longitude: 152.6451,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Calico Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2008,
    longitude: 152.6451,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Two Mile',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1689,
    longitude: 152.641,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Neerdie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2008,
    longitude: 152.6451,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Miva',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9606,
    longitude: 152.4913,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Beenaam Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Downsfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0667,
    longitude: 152.7333,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Bella Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Woolooga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.055,
    longitude: 152.3951,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Long Flat',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'The Palms',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.205,
    longitude: 152.5865,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Scrubby Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Wolvi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1667,
    longitude: 152.8333,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Marodian',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Melawondi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4167,
    longitude: 152.6667,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Munna Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Brooloo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5,
    longitude: 152.7,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Tuchekoi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2008,
    longitude: 152.6451,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Sexton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26,
    longitude: 152.4333,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Kandanga Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3878,
    longitude: 152.6474,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Glastonbury',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2167,
    longitude: 152.5167,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Anderleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Upper Glastonbury',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.25,
    longitude: 152.5,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Marys Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'The Dawn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2418,
    longitude: 152.6792,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'Mcintosh Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Coondoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1667,
    longitude: 152.8833,
    accuracy: 4,
  },
  {
    postcode: 4570,
    place_name: 'North Deep Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1365,
    longitude: 152.6811,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Ross Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Scotchy Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Widgee Crossing North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'Amamoor Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4570,
    place_name: 'St Mary',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1931,
    longitude: 152.5564,
    accuracy: 3,
  },
  {
    postcode: 4571,
    place_name: 'Kin Kin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2709,
    longitude: 152.8773,
    accuracy: 4,
  },
  {
    postcode: 4571,
    place_name: 'Como',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2045,
    longitude: 152.9656,
    accuracy: 4,
  },
  {
    postcode: 4572,
    place_name: 'Alexandra Headland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.672,
    longitude: 153.1079,
    accuracy: 4,
  },
  {
    postcode: 4573,
    place_name: 'Mount Coolum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5658,
    longitude: 153.0914,
    accuracy: 4,
  },
  {
    postcode: 4573,
    place_name: 'Peregian Springs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.488,
    longitude: 153.0758,
    accuracy: 4,
  },
  {
    postcode: 4573,
    place_name: 'Yaroomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5513,
    longitude: 153.097,
    accuracy: 4,
  },
  {
    postcode: 4573,
    place_name: 'Coolum Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5283,
    longitude: 153.0881,
    accuracy: 4,
  },
  {
    postcode: 4573,
    place_name: 'Point Arkwright',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5461,
    longitude: 153.1006,
    accuracy: 4,
  },
  {
    postcode: 4573,
    place_name: 'Marcus Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4531,
    longitude: 153.1006,
    accuracy: 4,
  },
  {
    postcode: 4573,
    place_name: 'Peregian Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.481,
    longitude: 153.096,
    accuracy: 4,
  },
  {
    postcode: 4574,
    place_name: 'Kenilworth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5966,
    longitude: 152.7281,
    accuracy: 4,
  },
  {
    postcode: 4574,
    place_name: 'Moy Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6155,
    longitude: 152.7203,
    accuracy: 3,
  },
  {
    postcode: 4574,
    place_name: 'Kidaman Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6312,
    longitude: 152.7675,
    accuracy: 4,
  },
  {
    postcode: 4574,
    place_name: 'Obi Obi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.65,
    longitude: 152.8,
    accuracy: 4,
  },
  {
    postcode: 4574,
    place_name: 'Coolabine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5933,
    longitude: 152.764,
    accuracy: 4,
  },
  {
    postcode: 4574,
    place_name: 'Gheerulla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5667,
    longitude: 152.7667,
    accuracy: 4,
  },
  {
    postcode: 4575,
    place_name: 'Bokarina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7384,
    longitude: 153.1305,
    accuracy: 4,
  },
  {
    postcode: 4575,
    place_name: 'Minyama',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6957,
    longitude: 153.1235,
    accuracy: 4,
  },
  {
    postcode: 4575,
    place_name: 'Birtinya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7432,
    longitude: 153.1191,
    accuracy: 4,
  },
  {
    postcode: 4575,
    place_name: 'Parrearra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7115,
    longitude: 153.122,
    accuracy: 4,
  },
  {
    postcode: 4575,
    place_name: 'Wurtulla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7565,
    longitude: 153.128,
    accuracy: 4,
  },
  {
    postcode: 4575,
    place_name: 'Warana',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7227,
    longitude: 153.1269,
    accuracy: 4,
  },
  {
    postcode: 4575,
    place_name: 'Buddina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6956,
    longitude: 153.133,
    accuracy: 4,
  },
  {
    postcode: 4580,
    place_name: 'Tin Can Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9133,
    longitude: 153.0058,
    accuracy: 4,
  },
  {
    postcode: 4580,
    place_name: 'Cooloola Cove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9864,
    longitude: 152.9935,
    accuracy: 4,
  },
  {
    postcode: 4580,
    place_name: 'Cooloola',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9864,
    longitude: 152.9935,
    accuracy: 4,
  },
  {
    postcode: 4581,
    place_name: 'Fraser Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9673,
    longitude: 153.3144,
    accuracy: 4,
  },
  {
    postcode: 4581,
    place_name: 'Inskip',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.8283,
    longitude: 153.0675,
    accuracy: 4,
  },
  {
    postcode: 4581,
    place_name: 'Eurong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5092,
    longitude: 153.125,
    accuracy: 4,
  },
  {
    postcode: 4581,
    place_name: 'Orchid Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9673,
    longitude: 153.3144,
    accuracy: 4,
  },
  {
    postcode: 4581,
    place_name: 'Rainbow Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.9043,
    longitude: 153.0917,
    accuracy: 4,
  },
  {
    postcode: 4600,
    place_name: 'Oakview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0833,
    longitude: 152.3333,
    accuracy: 4,
  },
  {
    postcode: 4600,
    place_name: 'Kilkivan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0869,
    longitude: 152.234,
    accuracy: 4,
  },
  {
    postcode: 4600,
    place_name: 'Mudlo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1644,
    longitude: 152.2093,
    accuracy: 3,
  },
  {
    postcode: 4600,
    place_name: 'Cinnabar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1224,
    longitude: 152.1854,
    accuracy: 4,
  },
  {
    postcode: 4600,
    place_name: 'Black Snake',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1644,
    longitude: 152.2093,
    accuracy: 3,
  },
  {
    postcode: 4601,
    place_name: 'Boonara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0802,
    longitude: 152.0546,
    accuracy: 4,
  },
  {
    postcode: 4601,
    place_name: 'Goomeribong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1146,
    longitude: 152.0795,
    accuracy: 3,
  },
  {
    postcode: 4601,
    place_name: 'Tansey',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0467,
    longitude: 152.0514,
    accuracy: 4,
  },
  {
    postcode: 4601,
    place_name: 'Wrattens Forest',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1146,
    longitude: 152.0795,
    accuracy: 3,
  },
  {
    postcode: 4601,
    place_name: 'Manyung',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2,
    longitude: 152.0333,
    accuracy: 4,
  },
  {
    postcode: 4601,
    place_name: 'Barambah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1146,
    longitude: 152.0795,
    accuracy: 3,
  },
  {
    postcode: 4601,
    place_name: 'Goomeri',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1838,
    longitude: 152.0687,
    accuracy: 4,
  },
  {
    postcode: 4601,
    place_name: 'Kinbombi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1981,
    longitude: 152.1221,
    accuracy: 4,
  },
  {
    postcode: 4601,
    place_name: 'Manumbar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4,
    longitude: 152.2833,
    accuracy: 4,
  },
  {
    postcode: 4601,
    place_name: 'Booubyjan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1146,
    longitude: 152.0795,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Manyung',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2,
    longitude: 152.0333,
    accuracy: 4,
  },
  {
    postcode: 4605,
    place_name: 'Kitoba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Windera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.05,
    longitude: 151.8333,
    accuracy: 4,
  },
  {
    postcode: 4605,
    place_name: 'Warnung',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Byee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1833,
    longitude: 151.85,
    accuracy: 4,
  },
  {
    postcode: 4605,
    place_name: 'Redgate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Moffatdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3079,
    longitude: 152.0125,
    accuracy: 4,
  },
  {
    postcode: 4605,
    place_name: 'Oakdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Barlil',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2,
    longitude: 151.8833,
    accuracy: 4,
  },
  {
    postcode: 4605,
    place_name: 'Cherbourg',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2919,
    longitude: 151.9521,
    accuracy: 4,
  },
  {
    postcode: 4605,
    place_name: 'Moondooner',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2333,
    longitude: 152,
    accuracy: 4,
  },
  {
    postcode: 4605,
    place_name: 'Crownthorpe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1896,
    longitude: 151.9157,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Cloyna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1112,
    longitude: 151.8459,
    accuracy: 4,
  },
  {
    postcode: 4605,
    place_name: 'Wooroonden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Silverleaf',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Murgon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2417,
    longitude: 151.9405,
    accuracy: 4,
  },
  {
    postcode: 4605,
    place_name: 'Cobbs Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Glenrock',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Merlwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Sunny Nook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4605,
    place_name: 'Tablelands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1525,
    longitude: 151.893,
    accuracy: 3,
  },
  {
    postcode: 4606,
    place_name: 'Wondai',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3177,
    longitude: 151.8724,
    accuracy: 4,
  },
  {
    postcode: 4606,
    place_name: 'Fairdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2833,
    longitude: 151.7833,
    accuracy: 4,
  },
  {
    postcode: 4606,
    place_name: 'Wheatlands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2762,
    longitude: 151.8292,
    accuracy: 3,
  },
  {
    postcode: 4606,
    place_name: 'Leafdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2762,
    longitude: 151.8292,
    accuracy: 3,
  },
  {
    postcode: 4606,
    place_name: 'Mp Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2762,
    longitude: 151.8292,
    accuracy: 3,
  },
  {
    postcode: 4606,
    place_name: 'Mount Mceuen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2762,
    longitude: 151.8292,
    accuracy: 3,
  },
  {
    postcode: 4606,
    place_name: 'Chelmsford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2762,
    longitude: 151.8292,
    accuracy: 3,
  },
  {
    postcode: 4606,
    place_name: 'Greenview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3005,
    longitude: 151.8279,
    accuracy: 3,
  },
  {
    postcode: 4606,
    place_name: 'Ficks Crossing',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3005,
    longitude: 151.8279,
    accuracy: 3,
  },
  {
    postcode: 4608,
    place_name: 'Tingoora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3667,
    longitude: 151.8167,
    accuracy: 4,
  },
  {
    postcode: 4608,
    place_name: 'Cushnie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3833,
    longitude: 151.7611,
    accuracy: 3,
  },
  {
    postcode: 4608,
    place_name: 'Wooroolin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4167,
    longitude: 151.8167,
    accuracy: 4,
  },
  {
    postcode: 4608,
    place_name: 'Charlestown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3093,
    longitude: 151.7816,
    accuracy: 3,
  },
  {
    postcode: 4608,
    place_name: 'Wilkesdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3667,
    longitude: 151.65,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Haly Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6833,
    longitude: 151.75,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Ballogie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3565,
    longitude: 151.5347,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Mannuem',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5796,
    longitude: 151.5673,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Boyneside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7167,
    longitude: 151.55,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Dangore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.45,
    longitude: 151.5833,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Booie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5368,
    longitude: 151.9418,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Wattle Grove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5796,
    longitude: 151.5673,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Kumbia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6947,
    longitude: 151.6551,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Coolabunia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6,
    longitude: 151.9,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Memerambi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4623,
    longitude: 151.8219,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Inverlaw',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5948,
    longitude: 151.7663,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Durong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3333,
    longitude: 151.25,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Hodgleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6333,
    longitude: 151.9333,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Ellesmere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5519,
    longitude: 151.7104,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Durong South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3333,
    longitude: 151.25,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Ironpot',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5796,
    longitude: 151.5673,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Goodger',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6667,
    longitude: 151.8167,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Benair',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5796,
    longitude: 151.5673,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Taabinga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5833,
    longitude: 151.8333,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Corndale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5796,
    longitude: 151.5673,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Crawford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5,
    longitude: 151.8167,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Kingaroy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5399,
    longitude: 151.8373,
    accuracy: 4,
  },
  {
    postcode: 4610,
    place_name: 'Chahpingah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5796,
    longitude: 151.5673,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Gordonbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4263,
    longitude: 151.6484,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Alice Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5796,
    longitude: 151.5673,
    accuracy: 3,
  },
  {
    postcode: 4610,
    place_name: 'Kingaroy DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.5519,
    longitude: 151.7104,
    accuracy: 3,
  },
  {
    postcode: 4611,
    place_name: 'Marshlands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1833,
    longitude: 151.7667,
    accuracy: 3,
  },
  {
    postcode: 4611,
    place_name: 'Mondure',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1837,
    longitude: 151.7724,
    accuracy: 4,
  },
  {
    postcode: 4612,
    place_name: 'Kawl Kawl',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1833,
    longitude: 151.7333,
    accuracy: 4,
  },
  {
    postcode: 4612,
    place_name: 'Hivesville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1807,
    longitude: 151.6907,
    accuracy: 4,
  },
  {
    postcode: 4612,
    place_name: 'Wigton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0475,
    longitude: 151.624,
    accuracy: 3,
  },
  {
    postcode: 4612,
    place_name: 'Stonelands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0475,
    longitude: 151.624,
    accuracy: 3,
  },
  {
    postcode: 4612,
    place_name: 'Keysland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0475,
    longitude: 151.624,
    accuracy: 3,
  },
  {
    postcode: 4613,
    place_name: 'Abbeywood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1139,
    longitude: 151.6294,
    accuracy: 4,
  },
  {
    postcode: 4613,
    place_name: 'Stalworth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1951,
    longitude: 151.4567,
    accuracy: 3,
  },
  {
    postcode: 4613,
    place_name: 'Brigooda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2534,
    longitude: 151.4158,
    accuracy: 4,
  },
  {
    postcode: 4613,
    place_name: 'Okeden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2365,
    longitude: 151.2929,
    accuracy: 3,
  },
  {
    postcode: 4613,
    place_name: 'Boondooma',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.3036,
    longitude: 151.262,
    accuracy: 4,
  },
  {
    postcode: 4613,
    place_name: 'Melrose',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2365,
    longitude: 151.2929,
    accuracy: 3,
  },
  {
    postcode: 4613,
    place_name: 'Kinleymore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1833,
    longitude: 151.65,
    accuracy: 4,
  },
  {
    postcode: 4613,
    place_name: 'Coverty',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.2365,
    longitude: 151.2929,
    accuracy: 3,
  },
  {
    postcode: 4613,
    place_name: 'Proston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1607,
    longitude: 151.6004,
    accuracy: 4,
  },
  {
    postcode: 4613,
    place_name: 'Speedwell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1951,
    longitude: 151.4567,
    accuracy: 3,
  },
  {
    postcode: 4614,
    place_name: 'Yarraman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8417,
    longitude: 151.9814,
    accuracy: 4,
  },
  {
    postcode: 4614,
    place_name: 'Upper Yarraman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8431,
    longitude: 151.9858,
    accuracy: 3,
  },
  {
    postcode: 4614,
    place_name: 'Neumgna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8431,
    longitude: 151.9858,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Wengenville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.8333,
    longitude: 151.7,
    accuracy: 4,
  },
  {
    postcode: 4615,
    place_name: 'Glan Devon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6,
    longitude: 152.0167,
    accuracy: 4,
  },
  {
    postcode: 4615,
    place_name: 'South Nanango',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.75,
    longitude: 151.9667,
    accuracy: 4,
  },
  {
    postcode: 4615,
    place_name: 'Nanango',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6716,
    longitude: 152.0021,
    accuracy: 4,
  },
  {
    postcode: 4615,
    place_name: 'Wyalla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6526,
    longitude: 151.9716,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Tarong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7667,
    longitude: 151.85,
    accuracy: 4,
  },
  {
    postcode: 4615,
    place_name: 'Maidenwell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.85,
    longitude: 151.8,
    accuracy: 4,
  },
  {
    postcode: 4615,
    place_name: 'Brooklands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7041,
    longitude: 151.8997,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Elgin Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.7041,
    longitude: 151.8997,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Wattle Camp',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.4572,
    longitude: 151.9623,
    accuracy: 4,
  },
  {
    postcode: 4615,
    place_name: 'Johnstown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6526,
    longitude: 151.9716,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Bullcamp',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6526,
    longitude: 151.9716,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Barker Creek Flat',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6526,
    longitude: 151.9716,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'East Nanango',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6943,
    longitude: 152.0413,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Kunioon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6526,
    longitude: 151.9716,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Pimpimbudgee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6526,
    longitude: 151.9716,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Runnymede',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6526,
    longitude: 151.9716,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'Sandy Ridges',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.6526,
    longitude: 151.9716,
    accuracy: 3,
  },
  {
    postcode: 4615,
    place_name: 'South East Nanango',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.695,
    longitude: 152.0264,
    accuracy: 3,
  },
  {
    postcode: 4620,
    place_name: 'Aramara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6095,
    longitude: 152.322,
    accuracy: 4,
  },
  {
    postcode: 4620,
    place_name: 'Brooweena',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6059,
    longitude: 152.2611,
    accuracy: 4,
  },
  {
    postcode: 4620,
    place_name: 'Gungaloon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5188,
    longitude: 152.3157,
    accuracy: 3,
  },
  {
    postcode: 4620,
    place_name: 'Teebar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5188,
    longitude: 152.3157,
    accuracy: 3,
  },
  {
    postcode: 4620,
    place_name: 'Glenbar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5188,
    longitude: 152.3157,
    accuracy: 3,
  },
  {
    postcode: 4620,
    place_name: 'Gigoomgan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5188,
    longitude: 152.3157,
    accuracy: 3,
  },
  {
    postcode: 4620,
    place_name: 'Woocoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5188,
    longitude: 152.3157,
    accuracy: 3,
  },
  {
    postcode: 4620,
    place_name: 'Malarga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5188,
    longitude: 152.3157,
    accuracy: 3,
  },
  {
    postcode: 4620,
    place_name: 'North Aramara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5188,
    longitude: 152.3157,
    accuracy: 3,
  },
  {
    postcode: 4620,
    place_name: 'Doongul',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5188,
    longitude: 152.3157,
    accuracy: 3,
  },
  {
    postcode: 4621,
    place_name: 'Lakeside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5783,
    longitude: 152.1251,
    accuracy: 4,
  },
  {
    postcode: 4621,
    place_name: 'Degilbo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4833,
    longitude: 152,
    accuracy: 4,
  },
  {
    postcode: 4621,
    place_name: 'Coalstoun Lakes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6181,
    longitude: 151.8937,
    accuracy: 4,
  },
  {
    postcode: 4621,
    place_name: 'Woowoonga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.632,
    longitude: 152.0448,
    accuracy: 3,
  },
  {
    postcode: 4621,
    place_name: 'Biggenden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5086,
    longitude: 152.0448,
    accuracy: 4,
  },
  {
    postcode: 4621,
    place_name: 'Dallarnil',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4,
    longitude: 152.05,
    accuracy: 4,
  },
  {
    postcode: 4621,
    place_name: 'Coringa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.632,
    longitude: 152.0448,
    accuracy: 3,
  },
  {
    postcode: 4621,
    place_name: 'Boompa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5911,
    longitude: 152.1861,
    accuracy: 4,
  },
  {
    postcode: 4621,
    place_name: 'Wateranga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.632,
    longitude: 152.0448,
    accuracy: 3,
  },
  {
    postcode: 4621,
    place_name: 'Didcot',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4756,
    longitude: 151.868,
    accuracy: 4,
  },
  {
    postcode: 4621,
    place_name: 'Golden Fleece',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.632,
    longitude: 152.0448,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Wetheron',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5457,
    longitude: 151.7187,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Toondahra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Dirnbir',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Bon Accord',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Ideraway',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5778,
    longitude: 151.6178,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Mount Steadman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6269,
    longitude: 151.7478,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Yenda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4531,
    longitude: 151.659,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Dundarrah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.65,
    longitude: 151.8833,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Byrnestown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5254,
    longitude: 151.761,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Mount Lawless',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5395,
    longitude: 151.6533,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Gooroolba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5139,
    longitude: 151.8083,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Stockhaven',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Aranbanga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Gayndah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6252,
    longitude: 151.6114,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Deep Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.1976,
    longitude: 152.7175,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Reids Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Wahoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Humphery',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6033,
    longitude: 151.4803,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Binjour',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5151,
    longitude: 151.4562,
    accuracy: 4,
  },
  {
    postcode: 4625,
    place_name: 'Wilson Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Mount Debateable',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Penwhaupell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Ban Ban',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Blairmore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Mingo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Barlyne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Ginoondan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Campbell Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Pile Gully',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'The Limits',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Woodmillar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Branch Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Harriet',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4625,
    place_name: 'Ban Ban Springs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5553,
    longitude: 151.5923,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Riverleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5766,
    longitude: 151.2001,
    accuracy: 4,
  },
  {
    postcode: 4626,
    place_name: 'Obil Bil',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5193,
    longitude: 151.2187,
    accuracy: 4,
  },
  {
    postcode: 4626,
    place_name: 'Mundubbera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5927,
    longitude: 151.3022,
    accuracy: 4,
  },
  {
    postcode: 4626,
    place_name: 'Brovinia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7889,
    longitude: 151.1136,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Mundowran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5206,
    longitude: 151.3438,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Hawkwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7761,
    longitude: 150.8109,
    accuracy: 4,
  },
  {
    postcode: 4626,
    place_name: 'Monogorilby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -26.0585,
    longitude: 151.0135,
    accuracy: 4,
  },
  {
    postcode: 4626,
    place_name: 'Glenrae',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7889,
    longitude: 151.1136,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: "O'Bil Bil",
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5193,
    longitude: 151.2187,
    accuracy: 4,
  },
  {
    postcode: 4626,
    place_name: 'Old Cooranga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7889,
    longitude: 151.1136,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Derri Derra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7889,
    longitude: 151.1136,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Philpott',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6,
    longitude: 151.3667,
    accuracy: 4,
  },
  {
    postcode: 4626,
    place_name: 'Coonambula',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7889,
    longitude: 151.1136,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Dykehead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7889,
    longitude: 151.1136,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Cattle Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -13.1617,
    longitude: 143.0534,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Gurgeena',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6463,
    longitude: 151.1704,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Beeron',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7889,
    longitude: 151.1136,
    accuracy: 3,
  },
  {
    postcode: 4626,
    place_name: 'Boynewood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.633,
    longitude: 151.2793,
    accuracy: 3,
  },
  {
    postcode: 4627,
    place_name: 'Eidsvold',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3717,
    longitude: 151.1215,
    accuracy: 4,
  },
  {
    postcode: 4627,
    place_name: 'Ceratodus',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2916,
    longitude: 151.1395,
    accuracy: 4,
  },
  {
    postcode: 4627,
    place_name: 'Malmoe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4774,
    longitude: 151.2019,
    accuracy: 4,
  },
  {
    postcode: 4627,
    place_name: 'Wuruma Dam',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3644,
    longitude: 150.8345,
    accuracy: 3,
  },
  {
    postcode: 4627,
    place_name: 'Grosvenor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4292,
    longitude: 151.1738,
    accuracy: 4,
  },
  {
    postcode: 4627,
    place_name: 'Abercorn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1347,
    longitude: 151.1294,
    accuracy: 4,
  },
  {
    postcode: 4627,
    place_name: 'Cynthia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2123,
    longitude: 151.1287,
    accuracy: 4,
  },
  {
    postcode: 4627,
    place_name: 'Eidsvold West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3644,
    longitude: 150.8345,
    accuracy: 3,
  },
  {
    postcode: 4627,
    place_name: 'Cheltenham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3644,
    longitude: 150.8345,
    accuracy: 3,
  },
  {
    postcode: 4627,
    place_name: 'Eidsvold East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3644,
    longitude: 150.8345,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Tellebang',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0667,
    longitude: 151.2333,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Kalpowar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7,
    longitude: 151.3,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Bancroft',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7833,
    longitude: 151.2333,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Harrami',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8332,
    longitude: 150.9872,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Monto',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8648,
    longitude: 151.122,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Selene',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0167,
    longitude: 151.15,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Yarrol',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1301,
    longitude: 151.3521,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Cannindah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8332,
    longitude: 150.9872,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Kapaldo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0425,
    longitude: 151.1353,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Cania',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6333,
    longitude: 150.9667,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Three Moon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9111,
    longitude: 151.1265,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Ventnor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8332,
    longitude: 150.9872,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Mungungo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7658,
    longitude: 151.1633,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Mulgildie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9605,
    longitude: 151.1319,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Rawbelle',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0232,
    longitude: 150.8271,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Bukali',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8332,
    longitude: 150.9872,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Coominglah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7532,
    longitude: 150.8356,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Splinter Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8332,
    longitude: 150.9872,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Moonford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7667,
    longitude: 151.0333,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Glenleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8332,
    longitude: 150.9872,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Langley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.45,
    longitude: 150.4333,
    accuracy: 4,
  },
  {
    postcode: 4630,
    place_name: 'Monal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8332,
    longitude: 150.9872,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Dalga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7691,
    longitude: 151.0402,
    accuracy: 3,
  },
  {
    postcode: 4630,
    place_name: 'Coominglah Forest',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8332,
    longitude: 150.9872,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Maryborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5407,
    longitude: 152.7049,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Thinoomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6025,
    longitude: 152.4537,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Dundathu',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4632,
    longitude: 152.7174,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'The Dimonds',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Maryborough DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6401,
    longitude: 152.6462,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Gootchie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.8548,
    longitude: 152.5544,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Boonooroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6729,
    longitude: 152.9026,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Tinana South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5822,
    longitude: 152.6513,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Antigua',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6381,
    longitude: 152.5989,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Grahams Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5667,
    longitude: 152.6,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Yengarie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5562,
    longitude: 152.6082,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Mount Urah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Owanyilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6674,
    longitude: 152.6028,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Maaroom',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6112,
    longitude: 152.8718,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Yerra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.61,
    longitude: 152.5356,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Tandora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Bauple',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.8121,
    longitude: 152.6213,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Pilerwa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6069,
    longitude: 152.5653,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Mungar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6053,
    longitude: 152.5923,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Tinnanbar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6401,
    longitude: 152.6462,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Tuan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6811,
    longitude: 152.8735,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Oakhurst',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5125,
    longitude: 152.6358,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Granville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.5449,
    longitude: 152.7335,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Mount Steadman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Tiaro',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7261,
    longitude: 152.5827,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Pallas Street Maryborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6401,
    longitude: 152.6462,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Poona',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7154,
    longitude: 152.9096,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Tuan Forest',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Aldershot',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4631,
    longitude: 152.6635,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Bidwill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6077,
    longitude: 152.7144,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Netherby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.7752,
    longitude: 152.5434,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Talegalla Weir',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Tinana',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.552,
    longitude: 152.6659,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'Prawle',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6401,
    longitude: 152.6462,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Maryborough West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6401,
    longitude: 152.6462,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Boonooroo Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Bauple Forest',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.878,
    longitude: 152.6551,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Pioneers Rest',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'St Helens',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Magnolia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6401,
    longitude: 152.6462,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Dunmora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Ferney',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Gundiah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.8335,
    longitude: 152.5405,
    accuracy: 4,
  },
  {
    postcode: 4650,
    place_name: 'St Mary',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6401,
    longitude: 152.6462,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Duckinwilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4126,
    longitude: 152.4375,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Walkers Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Glenorchy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Teddington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Beaver Rock',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6657,
    longitude: 152.6858,
    accuracy: 3,
  },
  {
    postcode: 4650,
    place_name: 'Island Plantation',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.6401,
    longitude: 152.6462,
    accuracy: 3,
  },
  {
    postcode: 4655,
    place_name: 'River Heads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.4155,
    longitude: 152.9131,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Hervey Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2894,
    longitude: 152.8288,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Kingfisher Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3914,
    longitude: 153.0294,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Walliebum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3305,
    longitude: 152.8062,
    accuracy: 3,
  },
  {
    postcode: 4655,
    place_name: 'Wondunna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3176,
    longitude: 152.8591,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Sunshine Acres',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3581,
    longitude: 152.7877,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Scarness',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2841,
    longitude: 152.856,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Susan River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3305,
    longitude: 152.8062,
    accuracy: 3,
  },
  {
    postcode: 4655,
    place_name: 'Booral',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3605,
    longitude: 152.9081,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Toogoom',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2564,
    longitude: 152.6902,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Nikenbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3194,
    longitude: 152.8041,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Hervey Bay Dc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3305,
    longitude: 152.8062,
    accuracy: 3,
  },
  {
    postcode: 4655,
    place_name: 'Kawungan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3,
    longitude: 152.8333,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Kingfisher Bay Resort',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3074,
    longitude: 152.8123,
    accuracy: 3,
  },
  {
    postcode: 4655,
    place_name: 'Pialba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.287,
    longitude: 152.8437,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Great Sandy Strait',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3305,
    longitude: 152.8062,
    accuracy: 3,
  },
  {
    postcode: 4655,
    place_name: 'Craignish',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2826,
    longitude: 152.7243,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Happy Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3376,
    longitude: 153.2005,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Urangan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2921,
    longitude: 152.9053,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Bunya Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3305,
    longitude: 152.8062,
    accuracy: 3,
  },
  {
    postcode: 4655,
    place_name: 'Torquay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.285,
    longitude: 152.8789,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Walligan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3471,
    longitude: 152.7599,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Eli Waters',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2865,
    longitude: 152.815,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Point Vernon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2663,
    longitude: 152.8257,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Dundowran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2891,
    longitude: 152.7646,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Takura',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3571,
    longitude: 152.7482,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Urraween',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2879,
    longitude: 152.8345,
    accuracy: 4,
  },
  {
    postcode: 4655,
    place_name: 'Dundowran Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2756,
    longitude: 152.7676,
    accuracy: 4,
  },
  {
    postcode: 4659,
    place_name: 'Beelbi Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3432,
    longitude: 152.5402,
    accuracy: 3,
  },
  {
    postcode: 4659,
    place_name: 'Pacific Haven',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2658,
    longitude: 152.5521,
    accuracy: 4,
  },
  {
    postcode: 4659,
    place_name: 'Burrum Heads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1846,
    longitude: 152.6126,
    accuracy: 4,
  },
  {
    postcode: 4659,
    place_name: 'Burgowan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3432,
    longitude: 152.5402,
    accuracy: 3,
  },
  {
    postcode: 4659,
    place_name: 'Howard',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3193,
    longitude: 152.5599,
    accuracy: 4,
  },
  {
    postcode: 4659,
    place_name: 'Burrum Town',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3432,
    longitude: 152.5402,
    accuracy: 3,
  },
  {
    postcode: 4659,
    place_name: 'Burrum River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2734,
    longitude: 152.5807,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'Woodgate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1083,
    longitude: 152.5625,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Apple Tree Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2167,
    longitude: 152.2333,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Cordalba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1606,
    longitude: 152.2119,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Abington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2112,
    longitude: 152.3091,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'North Isis',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.177,
    longitude: 152.2643,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Doolbi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2333,
    longitude: 152.3,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Redridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1699,
    longitude: 152.3613,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Goodwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1457,
    longitude: 152.4103,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Buxton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1989,
    longitude: 152.5422,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Horton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2333,
    longitude: 152.3167,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Childers',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2371,
    longitude: 152.2788,
    accuracy: 4,
  },
  {
    postcode: 4660,
    place_name: 'Cherwell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2112,
    longitude: 152.3091,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'South Isis',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1878,
    longitude: 152.3466,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'Gregory River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2112,
    longitude: 152.3091,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'Eureka',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2112,
    longitude: 152.3091,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'Isis Central',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1946,
    longitude: 152.1876,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'Kullogum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.328,
    longitude: 152.2665,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'Farnsfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1878,
    longitude: 152.3466,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'Promisedland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2112,
    longitude: 152.3091,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'Isis River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2112,
    longitude: 152.3091,
    accuracy: 3,
  },
  {
    postcode: 4660,
    place_name: 'North Gregory',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2112,
    longitude: 152.3091,
    accuracy: 3,
  },
  {
    postcode: 4662,
    place_name: 'Torbanlea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.3453,
    longitude: 152.5938,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Bundaberg North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.177,
    longitude: 152.2643,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Kepnock',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8761,
    longitude: 152.3752,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Moore Park Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7204,
    longitude: 152.2638,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Bundaberg',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8662,
    longitude: 152.3479,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Gooburrum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.85,
    longitude: 152.3167,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Bucca',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8667,
    longitude: 152.1,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Bundaberg Dc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8542,
    longitude: 152.2352,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Moorland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7513,
    longitude: 152.1886,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Elliott',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0063,
    longitude: 152.2758,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Rubyanna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8158,
    longitude: 152.3916,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Svensson Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8863,
    longitude: 152.3357,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Kalkie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8443,
    longitude: 152.3853,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Bargara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8148,
    longitude: 152.4626,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Watalgan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.65,
    longitude: 152.0333,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Mullett Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8542,
    longitude: 152.2352,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Fairymead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7833,
    longitude: 152.3667,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Avenell Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8896,
    longitude: 152.3679,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Walkervale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8827,
    longitude: 152.3589,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Bundaberg South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8739,
    longitude: 152.3626,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Avoca',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8782,
    longitude: 152.3079,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Calavos',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9364,
    longitude: 152.4347,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Oakwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8667,
    longitude: 152.3,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Burnett Heads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7647,
    longitude: 152.4127,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Millbank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8754,
    longitude: 152.3222,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Meadowvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8161,
    longitude: 152.2771,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Elliott Heads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9162,
    longitude: 152.4899,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Welcome Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7645,
    longitude: 152.276,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Sharon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8759,
    longitude: 152.2699,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Avondale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7383,
    longitude: 152.1582,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Thabeban',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9,
    longitude: 152.35,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Winfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8801,
    longitude: 152.3305,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'South Kolan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.932,
    longitude: 152.1679,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Norville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8931,
    longitude: 152.3409,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Kensington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9171,
    longitude: 152.3182,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Abbotsford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8542,
    longitude: 152.2352,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Kinkuna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0303,
    longitude: 152.3933,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Alloway',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9568,
    longitude: 152.3598,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Qunaba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.831,
    longitude: 152.4279,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Branyan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9135,
    longitude: 152.2725,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Woongarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8801,
    longitude: 152.3305,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Innes Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8683,
    longitude: 152.4799,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Bundaberg West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8711,
    longitude: 152.3355,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Coonarr',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9699,
    longitude: 152.4723,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Bundaberg Central',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9125,
    longitude: 152.3672,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'South Bingera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9673,
    longitude: 152.223,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Bundaberg East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8534,
    longitude: 152.3801,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Ashfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8726,
    longitude: 152.3954,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Electra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9753,
    longitude: 152.1163,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Mon Repos',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8801,
    longitude: 152.3305,
    accuracy: 3,
  },
  {
    postcode: 4670,
    place_name: 'Givelda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9908,
    longitude: 152.1516,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Windermere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.85,
    longitude: 152.45,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Pine Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.011,
    longitude: 152.1697,
    accuracy: 4,
  },
  {
    postcode: 4670,
    place_name: 'Coral Cove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8831,
    longitude: 152.4827,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Mount Perry',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1792,
    longitude: 151.6471,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Redhill Farms',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Bungadoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9833,
    longitude: 152.0709,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Monduran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Wallaville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0765,
    longitude: 151.9952,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Skyring Reserve',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Booyal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2164,
    longitude: 152.0399,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Molangul',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.75,
    longitude: 151.55,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Boolboonda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0833,
    longitude: 151.6833,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Doughboy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Gin Gin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9936,
    longitude: 151.9581,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Drinan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.05,
    longitude: 152.0167,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Tirroan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0167,
    longitude: 151.9333,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Duingal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Dalysford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0167,
    longitude: 151.8833,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Maroondan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9667,
    longitude: 152.0167,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Delan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0171,
    longitude: 152.0505,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Good Night',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Wonbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1,
    longitude: 151.65,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Lake Monduran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Kolonga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Mungy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'St Agnes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Gaeta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8333,
    longitude: 151.6,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Moolboolaman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0167,
    longitude: 151.8167,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Bullyard',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.95,
    longitude: 152.0667,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Damascus',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Mcilwraith',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9833,
    longitude: 152,
    accuracy: 4,
  },
  {
    postcode: 4671,
    place_name: 'Morganville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1731,
    longitude: 151.9704,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Horse Camp',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.066,
    longitude: 151.8924,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Wonbah Forest',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'New Moonta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Nearum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'Takilberan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4671,
    place_name: 'St Kilda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0574,
    longitude: 151.6613,
    accuracy: 3,
  },
  {
    postcode: 4673,
    place_name: 'Yandaran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7217,
    longitude: 152.114,
    accuracy: 4,
  },
  {
    postcode: 4673,
    place_name: 'Waterloo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7241,
    longitude: 152.0437,
    accuracy: 3,
  },
  {
    postcode: 4673,
    place_name: 'Littabella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6857,
    longitude: 152.0781,
    accuracy: 4,
  },
  {
    postcode: 4673,
    place_name: 'Miara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.7241,
    longitude: 152.0437,
    accuracy: 3,
  },
  {
    postcode: 4674,
    place_name: 'Berajondo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6171,
    longitude: 151.8441,
    accuracy: 4,
  },
  {
    postcode: 4674,
    place_name: 'Euleilah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.45,
    longitude: 151.8667,
    accuracy: 4,
  },
  {
    postcode: 4674,
    place_name: 'Oyster Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6184,
    longitude: 151.8492,
    accuracy: 3,
  },
  {
    postcode: 4674,
    place_name: 'Baffle Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.5,
    longitude: 151.9333,
    accuracy: 4,
  },
  {
    postcode: 4674,
    place_name: 'Deepwater',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6184,
    longitude: 151.8492,
    accuracy: 3,
  },
  {
    postcode: 4674,
    place_name: 'Taunton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4205,
    longitude: 151.8218,
    accuracy: 4,
  },
  {
    postcode: 4674,
    place_name: 'Rules Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6184,
    longitude: 151.8492,
    accuracy: 3,
  },
  {
    postcode: 4674,
    place_name: 'Mount Maria',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6184,
    longitude: 151.8492,
    accuracy: 3,
  },
  {
    postcode: 4674,
    place_name: 'Rosedale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.629,
    longitude: 151.9189,
    accuracy: 4,
  },
  {
    postcode: 4676,
    place_name: 'Gindoran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6263,
    longitude: 151.5919,
    accuracy: 3,
  },
  {
    postcode: 4676,
    place_name: 'Lowmead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.5295,
    longitude: 151.7532,
    accuracy: 4,
  },
  {
    postcode: 4677,
    place_name: 'Miriam Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3321,
    longitude: 151.5629,
    accuracy: 4,
  },
  {
    postcode: 4677,
    place_name: 'Eurimbula',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1047,
    longitude: 151.7138,
    accuracy: 3,
  },
  {
    postcode: 4677,
    place_name: 'Colosseum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3902,
    longitude: 151.6121,
    accuracy: 4,
  },
  {
    postcode: 4677,
    place_name: 'Agnes Water',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.2119,
    longitude: 151.9035,
    accuracy: 4,
  },
  {
    postcode: 4677,
    place_name: 'Seventeen Seventy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1687,
    longitude: 151.8839,
    accuracy: 4,
  },
  {
    postcode: 4677,
    place_name: 'Mount Tom',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.364,
    longitude: 151.6458,
    accuracy: 3,
  },
  {
    postcode: 4677,
    place_name: 'Round Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.364,
    longitude: 151.6458,
    accuracy: 3,
  },
  {
    postcode: 4677,
    place_name: 'Captain Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.364,
    longitude: 151.6458,
    accuracy: 3,
  },
  {
    postcode: 4678,
    place_name: 'Rodds Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.0633,
    longitude: 151.3874,
    accuracy: 4,
  },
  {
    postcode: 4678,
    place_name: 'Foreshores',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1383,
    longitude: 151.5912,
    accuracy: 3,
  },
  {
    postcode: 4678,
    place_name: 'Turkey Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1942,
    longitude: 151.5456,
    accuracy: 3,
  },
  {
    postcode: 4678,
    place_name: 'Bororen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.2406,
    longitude: 151.496,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Glen Eden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8974,
    longitude: 151.2713,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Gladstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8485,
    longitude: 151.2577,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Beecher',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9,
    longitude: 151.2,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Callemondah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8493,
    longitude: 151.2312,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Byellee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.874,
    longitude: 151.1946,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Tannum Sands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9475,
    longitude: 151.3675,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Heron Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.4418,
    longitude: 151.9125,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Burua',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.95,
    longitude: 151.2167,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Benaraby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.0042,
    longitude: 151.3341,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Boyne Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9483,
    longitude: 151.3534,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Kirkwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9033,
    longitude: 151.2354,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'South End',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9766,
    longitude: 151.2768,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Kin Kora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.877,
    longitude: 151.2472,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Gladstone BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9766,
    longitude: 151.2768,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'West Stowe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9123,
    longitude: 151.1302,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Telina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8883,
    longitude: 151.2538,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Wooderson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1333,
    longitude: 151.1365,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Builyan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.525,
    longitude: 151.3848,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Gladstone Central',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9766,
    longitude: 151.2768,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Boynedale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.2144,
    longitude: 151.245,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Barney Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8568,
    longitude: 151.2713,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Iveragh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1333,
    longitude: 151.45,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'River Ranch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.982,
    longitude: 151.1583,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'South Gladstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.869,
    longitude: 151.2783,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Boyne Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3873,
    longitude: 151.1936,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Wurdong Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9766,
    longitude: 151.2768,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Tablelands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1333,
    longitude: 151.1365,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Clinton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8752,
    longitude: 151.2179,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: "O'Connell",
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1333,
    longitude: 151.1365,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'West Gladstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9123,
    longitude: 151.1302,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Taragoola',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1003,
    longitude: 151.2196,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Gladstone DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9766,
    longitude: 151.2768,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'South Trees',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.869,
    longitude: 151.2783,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'New Auckland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8836,
    longitude: 151.2356,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Toolooa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9333,
    longitude: 151.2833,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Diglum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1333,
    longitude: 151.1365,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Calliope',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.007,
    longitude: 151.2003,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Sun Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8752,
    longitude: 151.2575,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Curtis Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9766,
    longitude: 151.2768,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Gladstone Harbour',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9766,
    longitude: 151.2768,
    accuracy: 3,
  },
  {
    postcode: 4680,
    place_name: 'Ubobo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4046,
    longitude: 151.3234,
    accuracy: 4,
  },
  {
    postcode: 4680,
    place_name: 'Mount Alma',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.0312,
    longitude: 150.8779,
    accuracy: 4,
  },
  {
    postcode: 4694,
    place_name: 'Targinnie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8,
    longitude: 151.1,
    accuracy: 4,
  },
  {
    postcode: 4694,
    place_name: 'Aldoga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7602,
    longitude: 151.0899,
    accuracy: 3,
  },
  {
    postcode: 4694,
    place_name: 'Targinie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8,
    longitude: 151.1,
    accuracy: 4,
  },
  {
    postcode: 4694,
    place_name: 'Yarwun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.85,
    longitude: 151.1167,
    accuracy: 4,
  },
  {
    postcode: 4695,
    place_name: 'Bracewell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9,
    longitude: 150.9,
    accuracy: 4,
  },
  {
    postcode: 4695,
    place_name: 'The Narrows',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7923,
    longitude: 150.8651,
    accuracy: 3,
  },
  {
    postcode: 4695,
    place_name: 'Ambrose',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8,
    longitude: 150.9333,
    accuracy: 4,
  },
  {
    postcode: 4695,
    place_name: 'Darts Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7923,
    longitude: 150.8651,
    accuracy: 3,
  },
  {
    postcode: 4695,
    place_name: 'Mount Larcom',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8121,
    longitude: 150.9789,
    accuracy: 4,
  },
  {
    postcode: 4695,
    place_name: 'Machine Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7923,
    longitude: 150.8651,
    accuracy: 3,
  },
  {
    postcode: 4695,
    place_name: 'East End',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7923,
    longitude: 150.8651,
    accuracy: 3,
  },
  {
    postcode: 4697,
    place_name: 'Raglan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7167,
    longitude: 150.8167,
    accuracy: 4,
  },
  {
    postcode: 4699,
    place_name: 'Port Alma',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5831,
    longitude: 150.8608,
    accuracy: 4,
  },
  {
    postcode: 4699,
    place_name: 'Bajool',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6667,
    longitude: 150.65,
    accuracy: 4,
  },
  {
    postcode: 4700,
    place_name: 'Fairy Bower',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.4053,
    longitude: 150.4654,
    accuracy: 4,
  },
  {
    postcode: 4700,
    place_name: 'Rockhampton City',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3772,
    longitude: 150.5099,
    accuracy: 3,
  },
  {
    postcode: 4700,
    place_name: 'Wandal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3688,
    longitude: 150.4938,
    accuracy: 4,
  },
  {
    postcode: 4700,
    place_name: 'West Rockhampton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3794,
    longitude: 150.4818,
    accuracy: 4,
  },
  {
    postcode: 4700,
    place_name: 'Great Keppel Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2698,
    longitude: 150.718,
    accuracy: 3,
  },
  {
    postcode: 4700,
    place_name: 'The Range',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.392,
    longitude: 150.4962,
    accuracy: 4,
  },
  {
    postcode: 4700,
    place_name: 'Rockhampton Hospital',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3636,
    longitude: 150.559,
    accuracy: 3,
  },
  {
    postcode: 4700,
    place_name: 'Rockhampton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3803,
    longitude: 150.506,
    accuracy: 4,
  },
  {
    postcode: 4700,
    place_name: 'The Keppels',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0695,
    longitude: 150.8913,
    accuracy: 4,
  },
  {
    postcode: 4700,
    place_name: 'Allenstown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.394,
    longitude: 150.5039,
    accuracy: 4,
  },
  {
    postcode: 4700,
    place_name: 'Depot Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3902,
    longitude: 150.521,
    accuracy: 4,
  },
  {
    postcode: 4700,
    place_name: 'Port Curtis',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.4117,
    longitude: 150.5098,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Nerimbera',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.4,
    longitude: 150.5833,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Central Queensland University',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3815,
    longitude: 150.6125,
    accuracy: 3,
  },
  {
    postcode: 4701,
    place_name: 'Koongal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3689,
    longitude: 150.5492,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Nankin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3833,
    longitude: 150.6333,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Red Hill Rockhampton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3656,
    longitude: 150.5483,
    accuracy: 3,
  },
  {
    postcode: 4701,
    place_name: 'The Common',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3815,
    longitude: 150.5334,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Rockyview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2364,
    longitude: 150.5196,
    accuracy: 3,
  },
  {
    postcode: 4701,
    place_name: 'Kawana',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3393,
    longitude: 150.5049,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'North Rockhampton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3815,
    longitude: 150.6125,
    accuracy: 3,
  },
  {
    postcode: 4701,
    place_name: 'Berserker',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3582,
    longitude: 150.5204,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Sandringham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3815,
    longitude: 150.6125,
    accuracy: 3,
  },
  {
    postcode: 4701,
    place_name: 'Lakes Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3833,
    longitude: 150.5667,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Ironpot',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3656,
    longitude: 150.5483,
    accuracy: 3,
  },
  {
    postcode: 4701,
    place_name: 'Frenchville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3476,
    longitude: 150.5445,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Mount Archer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3815,
    longitude: 150.6125,
    accuracy: 3,
  },
  {
    postcode: 4701,
    place_name: 'Park Avenue',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3533,
    longitude: 150.5189,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Greenlake',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3815,
    longitude: 150.6125,
    accuracy: 3,
  },
  {
    postcode: 4701,
    place_name: 'Norman Gardens',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3304,
    longitude: 150.5275,
    accuracy: 4,
  },
  {
    postcode: 4701,
    place_name: 'Limestone Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2868,
    longitude: 150.5677,
    accuracy: 3,
  },
  {
    postcode: 4701,
    place_name: 'Rockhampton DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3656,
    longitude: 150.5483,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Rolleston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4645,
    longitude: 148.6226,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Westwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5969,
    longitude: 150.1675,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Smoky Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1376,
    longitude: 150.3786,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Yaraka',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8842,
    longitude: 144.08,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Cawarral',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2667,
    longitude: 150.6667,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'South Yaamba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Milman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1333,
    longitude: 150.4,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Garnant',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Stanwell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.4833,
    longitude: 150.3167,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Anakie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.55,
    longitude: 147.75,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Morinish',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.25,
    longitude: 150.15,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Goomally',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Alton Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3,
    longitude: 150.35,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Plum Tree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5089,
    longitude: 150.1078,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Banana',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4688,
    longitude: 150.1349,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Mimosa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Willows',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7436,
    longitude: 147.5425,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Willows Gemfields',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7436,
    longitude: 147.5425,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Kunwarara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9167,
    longitude: 150.1333,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Joskeleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3801,
    longitude: 150.7786,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Bluff',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5805,
    longitude: 149.0685,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Rossmoya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0333,
    longitude: 150.4833,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Gracemere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.4379,
    longitude: 150.4575,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Canal Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Jambin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1948,
    longitude: 150.3706,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Canoona',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0833,
    longitude: 150.2667,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Goovigen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1459,
    longitude: 150.2853,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Dumpy Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Coowonga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2833,
    longitude: 150.7167,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Comet',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6,
    longitude: 148.55,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Mount Chalmers',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3,
    longitude: 150.65,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Gainsford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Gindie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7333,
    longitude: 148.1333,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Dalma',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.35,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Pink Lily',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.35,
    longitude: 150.4667,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Stanage',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.1613,
    longitude: 150.0676,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'The Caves',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1833,
    longitude: 150.4667,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Dululu',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.85,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Bushley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5333,
    longitude: 150.25,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Jardine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Keppel Sands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3259,
    longitude: 150.7873,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Kalapa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5167,
    longitude: 150.2667,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Argoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.2527,
    longitude: 150.4359,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Kokotungo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Wowan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9167,
    longitude: 150.1833,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Shoalwater',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Tungamull',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3167,
    longitude: 150.6833,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Woolein',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Dingo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.65,
    longitude: 149.3333,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Rubyvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.4182,
    longitude: 147.6991,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Etna Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2167,
    longitude: 150.4667,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Bingegang',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Baralaba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1745,
    longitude: 149.8156,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Alsace',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2806,
    longitude: 149.3524,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Ulogie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Alberta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Coorumbene',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5089,
    longitude: 150.1078,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Marmor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6833,
    longitude: 150.7167,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Blackdown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Coorooman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2667,
    longitude: 150.7333,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Wooroona',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Wycarbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.55,
    longitude: 150.2167,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Mackenzie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Wallaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7,
    longitude: 149.5667,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Kabra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.4667,
    longitude: 150.4,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Boolburra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7333,
    longitude: 149.7667,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Yalleroi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.0682,
    longitude: 145.7578,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Bouldercombe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5705,
    longitude: 150.4695,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Thompson Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Dixalea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.9333,
    longitude: 150.2833,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Jellinbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Goowarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6667,
    longitude: 149.4167,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Stewarton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Midgee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5167,
    longitude: 150.55,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Lowesby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5089,
    longitude: 150.1078,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Sapphire',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.4573,
    longitude: 147.7244,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Central Queensland Mc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Gogango',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6667,
    longitude: 150.0333,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Balcomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Parkhurst',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.3,
    longitude: 150.5167,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Barnard',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Nine Mile',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Ridgelands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.25,
    longitude: 150.2833,
    accuracy: 4,
  },
  {
    postcode: 4702,
    place_name: 'Consuelo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5089,
    longitude: 150.1078,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Glenroy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.7953,
    longitude: 151.9335,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Coomoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Morinish South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4702,
    place_name: 'Pheasant Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8022,
    longitude: 148.7101,
    accuracy: 3,
  },
  {
    postcode: 4703,
    place_name: 'Byfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.8476,
    longitude: 150.6502,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Bungundarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.049,
    longitude: 150.6384,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Adelaide Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0968,
    longitude: 150.6957,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Hidden Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9308,
    longitude: 150.6842,
    accuracy: 3,
  },
  {
    postcode: 4703,
    place_name: 'Yeppoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1268,
    longitude: 150.7441,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Maryvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -28.0687,
    longitude: 152.2436,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Bangalee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.075,
    longitude: 150.7622,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Lake Mary',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1144,
    longitude: 150.585,
    accuracy: 3,
  },
  {
    postcode: 4703,
    place_name: 'Mulambin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1876,
    longitude: 150.7893,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Causeway Lake',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1978,
    longitude: 150.7876,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Inverness',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1158,
    longitude: 150.7231,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Mulara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9308,
    longitude: 150.6842,
    accuracy: 3,
  },
  {
    postcode: 4703,
    place_name: 'Cooee Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1443,
    longitude: 150.7611,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Stockyard',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.8801,
    longitude: 150.7321,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Tanby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2333,
    longitude: 150.75,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Cobraball',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9308,
    longitude: 150.6842,
    accuracy: 3,
  },
  {
    postcode: 4703,
    place_name: 'Barmoya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1465,
    longitude: 150.5467,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Farnborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0912,
    longitude: 150.7415,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Taranganba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1443,
    longitude: 150.7514,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Taroomball',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1727,
    longitude: 150.7422,
    accuracy: 3,
  },
  {
    postcode: 4703,
    place_name: 'Bondoola',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1667,
    longitude: 150.6833,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Kinka Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2247,
    longitude: 150.7968,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Weerriba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9308,
    longitude: 150.6842,
    accuracy: 3,
  },
  {
    postcode: 4703,
    place_name: 'Rosslyn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1661,
    longitude: 150.7783,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Meikleville Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1131,
    longitude: 150.7447,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Pacific Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0981,
    longitude: 150.7386,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Barlows Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1076,
    longitude: 150.7451,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Lammermoor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1608,
    longitude: 150.7608,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Barmaryee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1333,
    longitude: 150.7167,
    accuracy: 4,
  },
  {
    postcode: 4703,
    place_name: 'Woodbury',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9788,
    longitude: 150.7052,
    accuracy: 4,
  },
  {
    postcode: 4704,
    place_name: 'Wattlebank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1333,
    longitude: 150.3667,
    accuracy: 3,
  },
  {
    postcode: 4704,
    place_name: 'Yaamba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1333,
    longitude: 150.3667,
    accuracy: 4,
  },
  {
    postcode: 4705,
    place_name: 'Mackenzie River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9527,
    longitude: 148.9351,
    accuracy: 3,
  },
  {
    postcode: 4705,
    place_name: 'Lotus Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.5566,
    longitude: 149.6096,
    accuracy: 3,
  },
  {
    postcode: 4705,
    place_name: 'Marlborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.8167,
    longitude: 149.8833,
    accuracy: 4,
  },
  {
    postcode: 4705,
    place_name: 'Mount Gardiner',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.5566,
    longitude: 149.6096,
    accuracy: 3,
  },
  {
    postcode: 4705,
    place_name: 'Clarke Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.5566,
    longitude: 149.6096,
    accuracy: 3,
  },
  {
    postcode: 4706,
    place_name: 'Ogmore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.6167,
    longitude: 149.65,
    accuracy: 4,
  },
  {
    postcode: 4707,
    place_name: 'St Lawrence',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.3456,
    longitude: 149.5353,
    accuracy: 4,
  },
  {
    postcode: 4707,
    place_name: 'The Percy Group',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.6123,
    longitude: 149.2367,
    accuracy: 3,
  },
  {
    postcode: 4707,
    place_name: 'Collaroy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.6123,
    longitude: 149.2367,
    accuracy: 3,
  },
  {
    postcode: 4709,
    place_name: 'Tieri',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0383,
    longitude: 148.3446,
    accuracy: 4,
  },
  {
    postcode: 4710,
    place_name: 'Emu Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2568,
    longitude: 150.8268,
    accuracy: 4,
  },
  {
    postcode: 4710,
    place_name: 'Zilzie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.273,
    longitude: 150.8183,
    accuracy: 4,
  },
  {
    postcode: 4711,
    place_name: 'Glendale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2569,
    longitude: 150.4849,
    accuracy: 1,
  },
  {
    postcode: 4711,
    place_name: 'Glenlee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2569,
    longitude: 150.4849,
    accuracy: 1,
  },
  {
    postcode: 4712,
    place_name: 'Duaringa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7158,
    longitude: 149.6701,
    accuracy: 4,
  },
  {
    postcode: 4713,
    place_name: 'Woorabinda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1306,
    longitude: 149.4566,
    accuracy: 4,
  },
  {
    postcode: 4714,
    place_name: 'Hamilton Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Baree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6167,
    longitude: 150.4,
    accuracy: 4,
  },
  {
    postcode: 4714,
    place_name: 'Wura',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.8167,
    longitude: 150.3333,
    accuracy: 4,
  },
  {
    postcode: 4714,
    place_name: 'Walterhall',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6333,
    longitude: 150.4,
    accuracy: 4,
  },
  {
    postcode: 4714,
    place_name: 'Struck Oil',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6714,
    longitude: 150.3843,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Mount Morgan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6453,
    longitude: 150.389,
    accuracy: 4,
  },
  {
    postcode: 4714,
    place_name: 'Trotter Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Moongan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6,
    longitude: 150.4,
    accuracy: 4,
  },
  {
    postcode: 4714,
    place_name: 'Nine Mile Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Horse Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Leydens Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Fletcher Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Walmul',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.7167,
    longitude: 150.3833,
    accuracy: 4,
  },
  {
    postcode: 4714,
    place_name: 'Boulder Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'The Mine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6296,
    longitude: 150.3747,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Limestone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Oakey Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4714,
    place_name: 'Johnsons Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6989,
    longitude: 150.3337,
    accuracy: 3,
  },
  {
    postcode: 4715,
    place_name: 'Biloela',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3959,
    longitude: 150.515,
    accuracy: 4,
  },
  {
    postcode: 4715,
    place_name: 'Tarramba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3982,
    longitude: 150.5467,
    accuracy: 3,
  },
  {
    postcode: 4715,
    place_name: 'Prospect',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.5117,
    longitude: 150.4189,
    accuracy: 4,
  },
  {
    postcode: 4715,
    place_name: 'Greycliffe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3982,
    longitude: 150.5467,
    accuracy: 3,
  },
  {
    postcode: 4715,
    place_name: 'Valentine Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4167,
    longitude: 150.5833,
    accuracy: 4,
  },
  {
    postcode: 4715,
    place_name: 'Mount Murchison',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.412,
    longitude: 150.5031,
    accuracy: 3,
  },
  {
    postcode: 4715,
    place_name: 'Callide',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3004,
    longitude: 150.4656,
    accuracy: 4,
  },
  {
    postcode: 4715,
    place_name: 'Dumgree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3982,
    longitude: 150.5467,
    accuracy: 3,
  },
  {
    postcode: 4715,
    place_name: 'Dakenba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3485,
    longitude: 150.4976,
    accuracy: 4,
  },
  {
    postcode: 4715,
    place_name: 'Castle Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3982,
    longitude: 150.5467,
    accuracy: 3,
  },
  {
    postcode: 4715,
    place_name: 'Orange Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3582,
    longitude: 150.3213,
    accuracy: 3,
  },
  {
    postcode: 4716,
    place_name: 'Thangool',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4863,
    longitude: 150.5736,
    accuracy: 4,
  },
  {
    postcode: 4716,
    place_name: 'Lawgi Dawes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6038,
    longitude: 150.781,
    accuracy: 4,
  },
  {
    postcode: 4717,
    place_name: 'Blackwater',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5835,
    longitude: 148.8791,
    accuracy: 4,
  },
  {
    postcode: 4718,
    place_name: 'Roundstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4746,
    longitude: 149.4083,
    accuracy: 3,
  },
  {
    postcode: 4718,
    place_name: 'Kianga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6936,
    longitude: 150.0128,
    accuracy: 4,
  },
  {
    postcode: 4718,
    place_name: 'Bauhinia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4746,
    longitude: 149.4083,
    accuracy: 3,
  },
  {
    postcode: 4718,
    place_name: 'Moura',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.5722,
    longitude: 149.9673,
    accuracy: 4,
  },
  {
    postcode: 4718,
    place_name: 'Mungabunda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4746,
    longitude: 149.4083,
    accuracy: 3,
  },
  {
    postcode: 4718,
    place_name: 'Oombabeer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4746,
    longitude: 149.4083,
    accuracy: 3,
  },
  {
    postcode: 4718,
    place_name: 'Dromedary',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4746,
    longitude: 149.4083,
    accuracy: 3,
  },
  {
    postcode: 4718,
    place_name: 'Rhydding',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4746,
    longitude: 149.4083,
    accuracy: 3,
  },
  {
    postcode: 4718,
    place_name: 'Warnoah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.4746,
    longitude: 149.4083,
    accuracy: 3,
  },
  {
    postcode: 4719,
    place_name: 'Theodore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.946,
    longitude: 150.0742,
    accuracy: 4,
  },
  {
    postcode: 4719,
    place_name: 'Glenmoral',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9541,
    longitude: 150.1128,
    accuracy: 3,
  },
  {
    postcode: 4719,
    place_name: 'Camboon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.0306,
    longitude: 150.4376,
    accuracy: 4,
  },
  {
    postcode: 4719,
    place_name: 'Lonesome Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.9541,
    longitude: 150.1128,
    accuracy: 3,
  },
  {
    postcode: 4719,
    place_name: 'Isla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.1307,
    longitude: 150.0515,
    accuracy: 3,
  },
  {
    postcode: 4719,
    place_name: 'Cracow',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -25.2989,
    longitude: 150.309,
    accuracy: 4,
  },
  {
    postcode: 4720,
    place_name: 'Emerald',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.523,
    longitude: 148.1578,
    accuracy: 4,
  },
  {
    postcode: 4720,
    place_name: 'Yamala',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5833,
    longitude: 148.3667,
    accuracy: 4,
  },
  {
    postcode: 4721,
    place_name: 'Winchester',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.1309,
    longitude: 148.1868,
    accuracy: 4,
  },
  {
    postcode: 4721,
    place_name: 'Theresa Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.2273,
    longitude: 147.794,
    accuracy: 3,
  },
  {
    postcode: 4721,
    place_name: 'Clermont',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.8241,
    longitude: 147.6364,
    accuracy: 4,
  },
  {
    postcode: 4721,
    place_name: 'Elgin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.2273,
    longitude: 147.794,
    accuracy: 3,
  },
  {
    postcode: 4721,
    place_name: 'Laglan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.2273,
    longitude: 147.794,
    accuracy: 3,
  },
  {
    postcode: 4721,
    place_name: 'Gemini Mountains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.2273,
    longitude: 147.794,
    accuracy: 3,
  },
  {
    postcode: 4721,
    place_name: 'Pasha',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.727,
    longitude: 147.5588,
    accuracy: 4,
  },
  {
    postcode: 4721,
    place_name: 'Kilcummin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.2273,
    longitude: 147.794,
    accuracy: 3,
  },
  {
    postcode: 4721,
    place_name: 'Wolfang',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.2273,
    longitude: 147.794,
    accuracy: 3,
  },
  {
    postcode: 4721,
    place_name: 'Frankfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.2273,
    longitude: 147.794,
    accuracy: 3,
  },
  {
    postcode: 4721,
    place_name: 'Argyll',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.2977,
    longitude: 147.0586,
    accuracy: 3,
  },
  {
    postcode: 4721,
    place_name: 'Mistake Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.2273,
    longitude: 147.794,
    accuracy: 3,
  },
  {
    postcode: 4722,
    place_name: 'Springsure',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1148,
    longitude: 148.0885,
    accuracy: 4,
  },
  {
    postcode: 4722,
    place_name: 'Orion',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1148,
    longitude: 148.0885,
    accuracy: 3,
  },
  {
    postcode: 4722,
    place_name: 'Cona Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1148,
    longitude: 148.0885,
    accuracy: 3,
  },
  {
    postcode: 4722,
    place_name: 'Buckland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1148,
    longitude: 148.0885,
    accuracy: 3,
  },
  {
    postcode: 4722,
    place_name: 'Cairdbeign',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1148,
    longitude: 148.0885,
    accuracy: 3,
  },
  {
    postcode: 4722,
    place_name: 'Nandowrie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1148,
    longitude: 148.0885,
    accuracy: 3,
  },
  {
    postcode: 4722,
    place_name: 'Wealwandangie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.1148,
    longitude: 148.0885,
    accuracy: 3,
  },
  {
    postcode: 4723,
    place_name: 'Capella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0859,
    longitude: 148.0221,
    accuracy: 4,
  },
  {
    postcode: 4723,
    place_name: 'Retro',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0167,
    longitude: 147.9,
    accuracy: 4,
  },
  {
    postcode: 4723,
    place_name: 'Belcong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.086,
    longitude: 147.9895,
    accuracy: 3,
  },
  {
    postcode: 4723,
    place_name: 'Lowestoff',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.086,
    longitude: 147.9895,
    accuracy: 3,
  },
  {
    postcode: 4723,
    place_name: 'Crinum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.086,
    longitude: 147.9895,
    accuracy: 3,
  },
  {
    postcode: 4723,
    place_name: 'Chirnside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.1381,
    longitude: 148.0463,
    accuracy: 4,
  },
  {
    postcode: 4723,
    place_name: 'Khosh Bulduk',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.086,
    longitude: 147.9895,
    accuracy: 3,
  },
  {
    postcode: 4723,
    place_name: 'Carbine Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.086,
    longitude: 147.9895,
    accuracy: 3,
  },
  {
    postcode: 4723,
    place_name: 'Hibernia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.086,
    longitude: 147.9895,
    accuracy: 3,
  },
  {
    postcode: 4723,
    place_name: 'Lilyvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.086,
    longitude: 147.9895,
    accuracy: 3,
  },
  {
    postcode: 4723,
    place_name: 'Mount Macarthur',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.086,
    longitude: 147.9895,
    accuracy: 3,
  },
  {
    postcode: 4724,
    place_name: 'Alpha',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6492,
    longitude: 146.6398,
    accuracy: 4,
  },
  {
    postcode: 4724,
    place_name: 'Pine Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.65,
    longitude: 146.9667,
    accuracy: 4,
  },
  {
    postcode: 4724,
    place_name: 'Sedgeford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6501,
    longitude: 146.8326,
    accuracy: 3,
  },
  {
    postcode: 4724,
    place_name: 'Hobartville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6501,
    longitude: 146.8326,
    accuracy: 3,
  },
  {
    postcode: 4724,
    place_name: 'Port Wine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6501,
    longitude: 146.8326,
    accuracy: 3,
  },
  {
    postcode: 4724,
    place_name: 'Beaufort',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6511,
    longitude: 146.8914,
    accuracy: 4,
  },
  {
    postcode: 4724,
    place_name: 'Surbiton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6501,
    longitude: 146.8326,
    accuracy: 3,
  },
  {
    postcode: 4724,
    place_name: 'Drummondslope',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6501,
    longitude: 146.8326,
    accuracy: 3,
  },
  {
    postcode: 4725,
    place_name: 'Barcaldine Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2086,
    longitude: 147.5842,
    accuracy: 3,
  },
  {
    postcode: 4725,
    place_name: 'Barcaldine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5535,
    longitude: 145.2854,
    accuracy: 4,
  },
  {
    postcode: 4725,
    place_name: 'Tara Station',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2086,
    longitude: 147.5842,
    accuracy: 3,
  },
  {
    postcode: 4725,
    place_name: 'Patrick',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2086,
    longitude: 147.5842,
    accuracy: 3,
  },
  {
    postcode: 4726,
    place_name: 'Galilee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2086,
    longitude: 147.5842,
    accuracy: 3,
  },
  {
    postcode: 4726,
    place_name: 'Pelican Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.2086,
    longitude: 147.5842,
    accuracy: 3,
  },
  {
    postcode: 4726,
    place_name: 'Aramac',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9683,
    longitude: 145.245,
    accuracy: 4,
  },
  {
    postcode: 4727,
    place_name: 'Ilfracombe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.5,
    longitude: 144.5,
    accuracy: 4,
  },
  {
    postcode: 4728,
    place_name: 'Jericho',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6,
    longitude: 146.1333,
    accuracy: 4,
  },
  {
    postcode: 4728,
    place_name: 'Mexico',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6,
    longitude: 146.1333,
    accuracy: 3,
  },
  {
    postcode: 4728,
    place_name: 'Dunrobin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6,
    longitude: 146.1333,
    accuracy: 3,
  },
  {
    postcode: 4728,
    place_name: 'Garfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6,
    longitude: 146.1333,
    accuracy: 3,
  },
  {
    postcode: 4730,
    place_name: 'Chorregon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.6785,
    longitude: 143.5619,
    accuracy: 4,
  },
  {
    postcode: 4730,
    place_name: 'Morella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9667,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 4730,
    place_name: 'Stonehenge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.3546,
    longitude: 143.2873,
    accuracy: 4,
  },
  {
    postcode: 4730,
    place_name: 'Ernestina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6006,
    longitude: 143.3629,
    accuracy: 3,
  },
  {
    postcode: 4730,
    place_name: 'Longreach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9833,
    longitude: 144.4833,
    accuracy: 4,
  },
  {
    postcode: 4730,
    place_name: 'Maneroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6006,
    longitude: 143.3629,
    accuracy: 3,
  },
  {
    postcode: 4730,
    place_name: 'Camoola',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9833,
    longitude: 144.4833,
    accuracy: 4,
  },
  {
    postcode: 4730,
    place_name: 'Tocal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6006,
    longitude: 143.3629,
    accuracy: 3,
  },
  {
    postcode: 4730,
    place_name: 'Vergemont',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.6006,
    longitude: 143.3629,
    accuracy: 3,
  },
  {
    postcode: 4731,
    place_name: 'Isisford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.2597,
    longitude: 144.4393,
    accuracy: 4,
  },
  {
    postcode: 4732,
    place_name: 'Muttaburra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.6,
    longitude: 144.55,
    accuracy: 4,
  },
  {
    postcode: 4732,
    place_name: 'Tablederry',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.5243,
    longitude: 149.2101,
    accuracy: 3,
  },
  {
    postcode: 4733,
    place_name: 'Corfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.7167,
    longitude: 143.3667,
    accuracy: 4,
  },
  {
    postcode: 4735,
    place_name: 'Middleton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.3514,
    longitude: 141.5517,
    accuracy: 4,
  },
  {
    postcode: 4735,
    place_name: 'Diamantina Lakes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.0459,
    longitude: 141.9408,
    accuracy: 3,
  },
  {
    postcode: 4735,
    place_name: 'Winton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.3818,
    longitude: 143.0424,
    accuracy: 4,
  },
  {
    postcode: 4735,
    place_name: 'Opalton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -23.25,
    longitude: 142.7667,
    accuracy: 4,
  },
  {
    postcode: 4736,
    place_name: 'Jundah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.8331,
    longitude: 143.0616,
    accuracy: 4,
  },
  {
    postcode: 4737,
    place_name: 'Blue Mountain',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.5833,
    longitude: 149.0333,
    accuracy: 4,
  },
  {
    postcode: 4737,
    place_name: 'Campwin Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3742,
    longitude: 149.3145,
    accuracy: 4,
  },
  {
    postcode: 4737,
    place_name: 'Sarina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.4191,
    longitude: 149.2168,
    accuracy: 4,
  },
  {
    postcode: 4737,
    place_name: 'Sunnyside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1667,
    longitude: 148.8833,
    accuracy: 4,
  },
  {
    postcode: 4737,
    place_name: 'Freshwater Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.4172,
    longitude: 149.3265,
    accuracy: 3,
  },
  {
    postcode: 4737,
    place_name: 'Sarina Range',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.5533,
    longitude: 149.1175,
    accuracy: 4,
  },
  {
    postcode: 4737,
    place_name: 'Armstrong Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.4549,
    longitude: 149.2896,
    accuracy: 3,
  },
  {
    postcode: 4737,
    place_name: 'Sarina Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3884,
    longitude: 149.3086,
    accuracy: 4,
  },
  {
    postcode: 4738,
    place_name: 'Ilbilbie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.7,
    longitude: 149.35,
    accuracy: 4,
  },
  {
    postcode: 4738,
    place_name: 'Koumala',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.6167,
    longitude: 149.25,
    accuracy: 4,
  },
  {
    postcode: 4739,
    place_name: 'Carmila',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.9167,
    longitude: 149.4167,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'The Leap',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0833,
    longitude: 149.0167,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Mackay DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1774,
    longitude: 149.1268,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Mount Pleasant',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1181,
    longitude: 149.1596,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Bakers Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2199,
    longitude: 149.1468,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Rural View',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0645,
    longitude: 149.1646,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Blacks Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0547,
    longitude: 149.1897,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Alligator Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3333,
    longitude: 149.2,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'West Mackay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1547,
    longitude: 149.1618,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Cremorne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1333,
    longitude: 149.2,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Beaconsfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0912,
    longitude: 149.1654,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Dumbleton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1333,
    longitude: 149.1,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Munbura',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3333,
    longitude: 149.15,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Mackay West',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.15,
    longitude: 148.85,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Erakala',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1282,
    longitude: 149.1171,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Mackay Caneland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1774,
    longitude: 149.1268,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Dolphin Heads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0406,
    longitude: 149.1849,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Belmunda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2395,
    longitude: 149.0527,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Eimeo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0377,
    longitude: 149.1763,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Balnagowan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1175,
    longitude: 149.0085,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Glenella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1177,
    longitude: 149.1445,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'East Mackay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1498,
    longitude: 149.1964,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Homebush',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2833,
    longitude: 149.05,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Racecourse',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1665,
    longitude: 149.1345,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'North Mackay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1201,
    longitude: 149.1794,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Paget',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1868,
    longitude: 149.1722,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Grasstree Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.364,
    longitude: 149.3061,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Slade Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.075,
    longitude: 149.225,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Mackay South',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.65,
    longitude: 149.2333,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Planland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2395,
    longitude: 149.0527,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Andergrove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0833,
    longitude: 149.1833,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Dunnrock',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2618,
    longitude: 149.1686,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Habana',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0333,
    longitude: 149.0833,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Mackay North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1774,
    longitude: 149.1268,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Balberra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3,
    longitude: 149.1167,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Alexandra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1667,
    longitude: 149.1,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Mcewens Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2395,
    longitude: 149.0527,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Coningsby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1,
    longitude: 149.0667,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Cape Hillsborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2395,
    longitude: 149.0527,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Rosella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2365,
    longitude: 149.1459,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Ooralea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1739,
    longitude: 149.148,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Nindaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0833,
    longitude: 149.15,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Dundula',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2395,
    longitude: 149.0527,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Mount Jukes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0167,
    longitude: 148.95,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Haliday Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2395,
    longitude: 149.0527,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'Te Kowai',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1667,
    longitude: 149.1167,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Richmond',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2395,
    longitude: 149.0527,
    accuracy: 3,
  },
  {
    postcode: 4740,
    place_name: 'South Mackay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1629,
    longitude: 149.1775,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Foulden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1346,
    longitude: 149.1369,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Sandiford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.25,
    longitude: 149.1,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Hay Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2833,
    longitude: 149.3,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Mackay Harbour',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1088,
    longitude: 149.2113,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Mackay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1534,
    longitude: 149.1655,
    accuracy: 4,
  },
  {
    postcode: 4740,
    place_name: 'Chelona',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2595,
    longitude: 149.1424,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Kuttabul',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0333,
    longitude: 148.9,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Ball Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2351,
    longitude: 148.9113,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Oakenden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3,
    longitude: 149.0167,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Pinnacle',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.15,
    longitude: 148.7167,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Eton North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2333,
    longitude: 148.9667,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'South Molle',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3252,
    longitude: 149.0031,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Coppabella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.9471,
    longitude: 148.3005,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Eungella Hinterland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3252,
    longitude: 149.0031,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Yalboroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.8333,
    longitude: 148.65,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Epsom',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3252,
    longitude: 149.0031,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Farleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1057,
    longitude: 149.1024,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Pleystowe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.15,
    longitude: 149.0333,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Mackay Mc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3252,
    longitude: 149.0031,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Daydream Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3252,
    longitude: 149.0031,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Lindeman Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4483,
    longitude: 149.039,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Owens Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0833,
    longitude: 148.7167,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Gargett',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1667,
    longitude: 148.75,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Brightly',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2351,
    longitude: 148.9113,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Clairview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.1175,
    longitude: 149.5347,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Brampton Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3252,
    longitude: 149.0031,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Long Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3252,
    longitude: 149.0031,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Mount Pelion',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.9333,
    longitude: 148.8167,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Kinchant Dam',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3252,
    longitude: 149.0031,
    accuracy: 3,
  },
  {
    postcode: 4741,
    place_name: 'Seaforth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.9004,
    longitude: 148.9679,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'North Eton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2333,
    longitude: 148.9667,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Hazledean',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3833,
    longitude: 148.95,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Eton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2647,
    longitude: 148.9724,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Orkabie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.8167,
    longitude: 149.3667,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Mount Ossa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.9667,
    longitude: 148.8333,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Hampden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0667,
    longitude: 148.9333,
    accuracy: 4,
  },
  {
    postcode: 4741,
    place_name: 'Mount Charlton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0167,
    longitude: 148.7333,
    accuracy: 4,
  },
  {
    postcode: 4742,
    place_name: 'Strathfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.746,
    longitude: 148.4967,
    accuracy: 4,
  },
  {
    postcode: 4742,
    place_name: 'Oxford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.7372,
    longitude: 148.4658,
    accuracy: 3,
  },
  {
    postcode: 4742,
    place_name: 'Elphinstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.5,
    longitude: 148.2333,
    accuracy: 4,
  },
  {
    postcode: 4742,
    place_name: 'Eaglefield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.7372,
    longitude: 148.4658,
    accuracy: 3,
  },
  {
    postcode: 4742,
    place_name: 'Turrawulla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.7372,
    longitude: 148.4658,
    accuracy: 3,
  },
  {
    postcode: 4742,
    place_name: 'Mount Britton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.6431,
    longitude: 148.4711,
    accuracy: 3,
  },
  {
    postcode: 4742,
    place_name: 'Mt Britton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.7372,
    longitude: 148.4658,
    accuracy: 3,
  },
  {
    postcode: 4742,
    place_name: 'Valkyrie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.0745,
    longitude: 148.6408,
    accuracy: 3,
  },
  {
    postcode: 4742,
    place_name: 'Kemmis',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.7372,
    longitude: 148.4658,
    accuracy: 3,
  },
  {
    postcode: 4742,
    place_name: 'Burton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.7372,
    longitude: 148.4658,
    accuracy: 3,
  },
  {
    postcode: 4742,
    place_name: 'Hail Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.7372,
    longitude: 148.4658,
    accuracy: 3,
  },
  {
    postcode: 4742,
    place_name: 'Nebo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.6833,
    longitude: 148.6833,
    accuracy: 4,
  },
  {
    postcode: 4743,
    place_name: 'Glenden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3544,
    longitude: 148.1149,
    accuracy: 4,
  },
  {
    postcode: 4743,
    place_name: 'Suttor',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3476,
    longitude: 148.1158,
    accuracy: 3,
  },
  {
    postcode: 4744,
    place_name: 'Moranbah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.0016,
    longitude: 148.0466,
    accuracy: 4,
  },
  {
    postcode: 4745,
    place_name: 'Dysart',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.5882,
    longitude: 148.3492,
    accuracy: 4,
  },
  {
    postcode: 4746,
    place_name: 'Middlemount',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.8123,
    longitude: 148.6993,
    accuracy: 4,
  },
  {
    postcode: 4746,
    place_name: 'May Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.7863,
    longitude: 148.5777,
    accuracy: 3,
  },
  {
    postcode: 4750,
    place_name: 'Bucasia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0349,
    longitude: 149.1573,
    accuracy: 4,
  },
  {
    postcode: 4750,
    place_name: 'Shoal Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0048,
    longitude: 149.1527,
    accuracy: 4,
  },
  {
    postcode: 4751,
    place_name: 'Victoria Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2174,
    longitude: 149.0122,
    accuracy: 3,
  },
  {
    postcode: 4751,
    place_name: 'Walkerston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.161,
    longitude: 149.0581,
    accuracy: 4,
  },
  {
    postcode: 4751,
    place_name: 'Palmyra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1724,
    longitude: 149.0462,
    accuracy: 3,
  },
  {
    postcode: 4751,
    place_name: 'Greenmount',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1839,
    longitude: 149.0342,
    accuracy: 4,
  },
  {
    postcode: 4753,
    place_name: 'Devereux Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1167,
    longitude: 148.9167,
    accuracy: 4,
  },
  {
    postcode: 4753,
    place_name: 'Marian',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1439,
    longitude: 148.9421,
    accuracy: 4,
  },
  {
    postcode: 4754,
    place_name: 'Mount Martin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1167,
    longitude: 148.8167,
    accuracy: 4,
  },
  {
    postcode: 4754,
    place_name: 'Mia Mia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1881,
    longitude: 148.8079,
    accuracy: 3,
  },
  {
    postcode: 4754,
    place_name: 'Benholme',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1667,
    longitude: 148.8167,
    accuracy: 4,
  },
  {
    postcode: 4754,
    place_name: 'Pinevale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1881,
    longitude: 148.8079,
    accuracy: 3,
  },
  {
    postcode: 4754,
    place_name: 'Mirani',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1573,
    longitude: 148.8622,
    accuracy: 4,
  },
  {
    postcode: 4754,
    place_name: 'Dows Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1,
    longitude: 148.7833,
    accuracy: 4,
  },
  {
    postcode: 4754,
    place_name: 'Septimus',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2333,
    longitude: 148.7667,
    accuracy: 4,
  },
  {
    postcode: 4756,
    place_name: 'Netherdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.15,
    longitude: 148.5333,
    accuracy: 4,
  },
  {
    postcode: 4756,
    place_name: 'Finch Hatton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1404,
    longitude: 148.6288,
    accuracy: 4,
  },
  {
    postcode: 4757,
    place_name: 'Crediton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.203,
    longitude: 148.5171,
    accuracy: 4,
  },
  {
    postcode: 4757,
    place_name: 'Broken River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0003,
    longitude: 148.4112,
    accuracy: 3,
  },
  {
    postcode: 4757,
    place_name: 'Dalrymple Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1,
    longitude: 148.5167,
    accuracy: 4,
  },
  {
    postcode: 4757,
    place_name: 'Eungella Dam',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.0003,
    longitude: 148.4112,
    accuracy: 3,
  },
  {
    postcode: 4757,
    place_name: 'Eungella',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1333,
    longitude: 148.4833,
    accuracy: 4,
  },
  {
    postcode: 4798,
    place_name: 'Pindi Pindi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.8667,
    longitude: 148.7333,
    accuracy: 4,
  },
  {
    postcode: 4798,
    place_name: 'Mentmore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.8742,
    longitude: 148.728,
    accuracy: 3,
  },
  {
    postcode: 4798,
    place_name: 'Calen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.8975,
    longitude: 148.7711,
    accuracy: 4,
  },
  {
    postcode: 4798,
    place_name: 'St Helens Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.8821,
    longitude: 148.7522,
    accuracy: 3,
  },
  {
    postcode: 4799,
    place_name: 'Bloomsbury',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7167,
    longitude: 148.6,
    accuracy: 4,
  },
  {
    postcode: 4799,
    place_name: 'Midge Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7167,
    longitude: 148.6,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Palm Grove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.3927,
    longitude: 148.7007,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Dittmer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.45,
    longitude: 148.4,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Mount Julian',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.3833,
    longitude: 148.6167,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Breadalbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Cannon Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2833,
    longitude: 148.6833,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Lethebrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Strathdickie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.3333,
    longitude: 148.6167,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Hamilton Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.3823,
    longitude: 148.5875,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Mount Marlow',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.3667,
    longitude: 148.6167,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Gunyarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.5167,
    longitude: 148.5667,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Thoopara',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.55,
    longitude: 148.5667,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Preston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Riordanvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.828,
    longitude: 147.5322,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Foxdale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.3667,
    longitude: 148.55,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Crystal Brook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.3484,
    longitude: 148.485,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Laguna Quays',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.6543,
    longitude: 148.6667,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Kelsey Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4333,
    longitude: 148.45,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Dingo Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0904,
    longitude: 148.4959,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Cape Gloucester',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1101,
    longitude: 148.5024,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Glen Isla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4089,
    longitude: 148.62,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Proserpine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4011,
    longitude: 148.5802,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Conway',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4167,
    longitude: 148.7,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Sugarloaf',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Gregory River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Wilson Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Andromache',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Silver Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Goorganga Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Lake Proserpine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Conway Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Mount Pluto',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Goorganga Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Brandy Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Cape Conway',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2981,
    longitude: 148.747,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Myrtlevale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4800,
    place_name: 'Hideaway Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.074,
    longitude: 148.4752,
    accuracy: 4,
  },
  {
    postcode: 4800,
    place_name: 'Pauls Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4057,
    longitude: 148.4881,
    accuracy: 3,
  },
  {
    postcode: 4801,
    place_name: 'Hayman Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0511,
    longitude: 148.8849,
    accuracy: 4,
  },
  {
    postcode: 4802,
    place_name: 'Cannonvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2768,
    longitude: 148.6976,
    accuracy: 4,
  },
  {
    postcode: 4802,
    place_name: 'Flametree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.27,
    longitude: 148.7497,
    accuracy: 4,
  },
  {
    postcode: 4802,
    place_name: 'Airlie Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2675,
    longitude: 148.7147,
    accuracy: 4,
  },
  {
    postcode: 4802,
    place_name: 'Mount Rooper',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2873,
    longitude: 148.7783,
    accuracy: 3,
  },
  {
    postcode: 4802,
    place_name: 'Woodwark',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2358,
    longitude: 148.6533,
    accuracy: 4,
  },
  {
    postcode: 4802,
    place_name: 'Whitsundays',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2318,
    longitude: 148.952,
    accuracy: 4,
  },
  {
    postcode: 4802,
    place_name: 'Jubilee Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2881,
    longitude: 148.7294,
    accuracy: 4,
  },
  {
    postcode: 4802,
    place_name: 'Shute Harbour',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2913,
    longitude: 148.7858,
    accuracy: 4,
  },
  {
    postcode: 4802,
    place_name: 'Mandalay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.27,
    longitude: 148.7389,
    accuracy: 4,
  },
  {
    postcode: 4803,
    place_name: 'Hamilton Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.346,
    longitude: 148.9519,
    accuracy: 4,
  },
  {
    postcode: 4804,
    place_name: 'Mount Coolon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3833,
    longitude: 147.3333,
    accuracy: 4,
  },
  {
    postcode: 4804,
    place_name: 'Collinsville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.5522,
    longitude: 147.844,
    accuracy: 4,
  },
  {
    postcode: 4804,
    place_name: 'Springlands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.9844,
    longitude: 147.5398,
    accuracy: 3,
  },
  {
    postcode: 4804,
    place_name: 'Scottville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.5667,
    longitude: 147.8167,
    accuracy: 4,
  },
  {
    postcode: 4804,
    place_name: 'Newlands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.9844,
    longitude: 147.5398,
    accuracy: 3,
  },
  {
    postcode: 4804,
    place_name: 'Mount Wyatt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.9844,
    longitude: 147.5398,
    accuracy: 3,
  },
  {
    postcode: 4805,
    place_name: 'Gumlu',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8833,
    longitude: 147.6833,
    accuracy: 4,
  },
  {
    postcode: 4805,
    place_name: 'Bogie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1665,
    longitude: 147.8081,
    accuracy: 3,
  },
  {
    postcode: 4805,
    place_name: 'Bowen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0137,
    longitude: 148.2475,
    accuracy: 4,
  },
  {
    postcode: 4805,
    place_name: 'Merinda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0169,
    longitude: 148.1634,
    accuracy: 4,
  },
  {
    postcode: 4805,
    place_name: 'Guthalungra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.9167,
    longitude: 147.8333,
    accuracy: 4,
  },
  {
    postcode: 4805,
    place_name: 'Queens Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.9667,
    longitude: 148.25,
    accuracy: 4,
  },
  {
    postcode: 4806,
    place_name: 'Carstairs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.65,
    longitude: 147.4,
    accuracy: 4,
  },
  {
    postcode: 4806,
    place_name: 'Home Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.6592,
    longitude: 147.4123,
    accuracy: 4,
  },
  {
    postcode: 4806,
    place_name: 'Wangaratta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8433,
    longitude: 147.4016,
    accuracy: 3,
  },
  {
    postcode: 4806,
    place_name: 'Inkerman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8433,
    longitude: 147.4016,
    accuracy: 3,
  },
  {
    postcode: 4806,
    place_name: 'Rangemore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8433,
    longitude: 147.4016,
    accuracy: 3,
  },
  {
    postcode: 4806,
    place_name: 'Wunjunga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8433,
    longitude: 147.4016,
    accuracy: 3,
  },
  {
    postcode: 4806,
    place_name: 'Kirknie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8433,
    longitude: 147.4016,
    accuracy: 3,
  },
  {
    postcode: 4806,
    place_name: 'Osborne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8433,
    longitude: 147.4016,
    accuracy: 3,
  },
  {
    postcode: 4806,
    place_name: 'Fredericksfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8433,
    longitude: 147.4016,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Jarvisfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.6167,
    longitude: 147.4667,
    accuracy: 4,
  },
  {
    postcode: 4807,
    place_name: 'Mcdesme',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8733,
    longitude: 147.2013,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Mount Kelly',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.6685,
    longitude: 147.3153,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Ayr',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5739,
    longitude: 147.4067,
    accuracy: 4,
  },
  {
    postcode: 4807,
    place_name: 'Dalbeg',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.274,
    longitude: 147.3036,
    accuracy: 4,
  },
  {
    postcode: 4807,
    place_name: 'Swans Lagoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8733,
    longitude: 147.2013,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Alva',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.45,
    longitude: 147.4833,
    accuracy: 4,
  },
  {
    postcode: 4807,
    place_name: 'Airville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.6309,
    longitude: 147.3455,
    accuracy: 4,
  },
  {
    postcode: 4807,
    place_name: 'Clare',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.7833,
    longitude: 147.2333,
    accuracy: 4,
  },
  {
    postcode: 4807,
    place_name: 'Mona Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8733,
    longitude: 147.2013,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Rita Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.6167,
    longitude: 147.5333,
    accuracy: 4,
  },
  {
    postcode: 4807,
    place_name: 'Mulgrave',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8733,
    longitude: 147.2013,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Millaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0601,
    longitude: 147.28,
    accuracy: 4,
  },
  {
    postcode: 4807,
    place_name: 'Airdmillan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8733,
    longitude: 147.2013,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Eight Mile Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8733,
    longitude: 147.2013,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Rite Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8733,
    longitude: 147.2013,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Claredale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.7507,
    longitude: 147.3816,
    accuracy: 3,
  },
  {
    postcode: 4807,
    place_name: 'Parkside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8733,
    longitude: 147.2013,
    accuracy: 3,
  },
  {
    postcode: 4808,
    place_name: 'Brandon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5539,
    longitude: 147.353,
    accuracy: 4,
  },
  {
    postcode: 4808,
    place_name: 'Colevale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5557,
    longitude: 147.3568,
    accuracy: 3,
  },
  {
    postcode: 4809,
    place_name: 'Cromarty',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.4667,
    longitude: 147.05,
    accuracy: 4,
  },
  {
    postcode: 4809,
    place_name: 'Horseshoe Lagoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5649,
    longitude: 147.1252,
    accuracy: 3,
  },
  {
    postcode: 4809,
    place_name: 'Upper Haughton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5649,
    longitude: 147.1252,
    accuracy: 3,
  },
  {
    postcode: 4809,
    place_name: 'Giru',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.517,
    longitude: 147.1026,
    accuracy: 4,
  },
  {
    postcode: 4809,
    place_name: 'Mount Surround',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5649,
    longitude: 147.1252,
    accuracy: 3,
  },
  {
    postcode: 4809,
    place_name: 'Jerona',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5649,
    longitude: 147.1252,
    accuracy: 3,
  },
  {
    postcode: 4809,
    place_name: 'Shirbourne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5649,
    longitude: 147.1252,
    accuracy: 3,
  },
  {
    postcode: 4809,
    place_name: 'Barratta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5311,
    longitude: 147.2132,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'Pallarenda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.201,
    longitude: 146.7734,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'Townsville Mc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2599,
    longitude: 146.8266,
    accuracy: 3,
  },
  {
    postcode: 4810,
    place_name: 'North Ward',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2474,
    longitude: 146.8055,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'Town Common',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2183,
    longitude: 146.7552,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'Rowes Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2401,
    longitude: 146.7892,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'South Townsville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2603,
    longitude: 146.8277,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'Townsville DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2599,
    longitude: 146.8266,
    accuracy: 3,
  },
  {
    postcode: 4810,
    place_name: 'Railway Estate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2757,
    longitude: 146.8196,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'Castle Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2283,
    longitude: 146.7706,
    accuracy: 3,
  },
  {
    postcode: 4810,
    place_name: 'Shelly Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2283,
    longitude: 146.7706,
    accuracy: 3,
  },
  {
    postcode: 4810,
    place_name: 'Townsville City',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2283,
    longitude: 146.7706,
    accuracy: 3,
  },
  {
    postcode: 4810,
    place_name: 'Townsville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2664,
    longitude: 146.8057,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'Belgian Gardens',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2451,
    longitude: 146.7945,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'West End',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2653,
    longitude: 146.794,
    accuracy: 4,
  },
  {
    postcode: 4810,
    place_name: 'Cape Cleveland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3328,
    longitude: 147.0248,
    accuracy: 4,
  },
  {
    postcode: 4811,
    place_name: 'Cluden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3173,
    longitude: 146.8249,
    accuracy: 4,
  },
  {
    postcode: 4811,
    place_name: 'James Cook University',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3511,
    longitude: 146.8368,
    accuracy: 3,
  },
  {
    postcode: 4811,
    place_name: 'Idalia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3068,
    longitude: 146.8139,
    accuracy: 4,
  },
  {
    postcode: 4811,
    place_name: 'Stuart',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.347,
    longitude: 146.8438,
    accuracy: 4,
  },
  {
    postcode: 4811,
    place_name: 'Oonoonba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3,
    longitude: 146.8167,
    accuracy: 4,
  },
  {
    postcode: 4811,
    place_name: 'Oak Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.4083,
    longitude: 146.8213,
    accuracy: 4,
  },
  {
    postcode: 4811,
    place_name: 'Roseneath',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3555,
    longitude: 146.8351,
    accuracy: 4,
  },
  {
    postcode: 4811,
    place_name: 'Mount Stuart',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3722,
    longitude: 146.743,
    accuracy: 4,
  },
  {
    postcode: 4811,
    place_name: 'Wulguru',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3285,
    longitude: 146.8184,
    accuracy: 4,
  },
  {
    postcode: 4812,
    place_name: 'Pimlico',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2817,
    longitude: 146.788,
    accuracy: 4,
  },
  {
    postcode: 4812,
    place_name: 'Hyde Park Castletown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2855,
    longitude: 146.7904,
    accuracy: 3,
  },
  {
    postcode: 4812,
    place_name: 'Mundingburra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2965,
    longitude: 146.7851,
    accuracy: 4,
  },
  {
    postcode: 4812,
    place_name: 'Currajong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2757,
    longitude: 146.7792,
    accuracy: 4,
  },
  {
    postcode: 4812,
    place_name: 'Hermit Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2831,
    longitude: 146.8036,
    accuracy: 4,
  },
  {
    postcode: 4812,
    place_name: 'Mysterton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2872,
    longitude: 146.7936,
    accuracy: 4,
  },
  {
    postcode: 4812,
    place_name: 'Hyde Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2769,
    longitude: 146.7965,
    accuracy: 4,
  },
  {
    postcode: 4812,
    place_name: 'Gulliver',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2851,
    longitude: 146.7745,
    accuracy: 4,
  },
  {
    postcode: 4812,
    place_name: 'Rosslea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.298,
    longitude: 146.8027,
    accuracy: 4,
  },
  {
    postcode: 4813,
    place_name: 'Townsville Milpo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3366,
    longitude: 146.7836,
    accuracy: 1,
  },
  {
    postcode: 4814,
    place_name: 'Garbutt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2566,
    longitude: 146.7658,
    accuracy: 4,
  },
  {
    postcode: 4814,
    place_name: 'Garbutt East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2983,
    longitude: 146.7711,
    accuracy: 3,
  },
  {
    postcode: 4814,
    place_name: 'Murray',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3323,
    longitude: 146.7917,
    accuracy: 4,
  },
  {
    postcode: 4814,
    place_name: 'Mount Louisa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.274,
    longitude: 146.7509,
    accuracy: 4,
  },
  {
    postcode: 4814,
    place_name: 'Douglas',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3239,
    longitude: 146.7523,
    accuracy: 4,
  },
  {
    postcode: 4814,
    place_name: 'Heatley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2904,
    longitude: 146.7536,
    accuracy: 4,
  },
  {
    postcode: 4814,
    place_name: 'Aitkenvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2833,
    longitude: 146.8,
    accuracy: 4,
  },
  {
    postcode: 4814,
    place_name: 'Vincent',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2834,
    longitude: 146.7646,
    accuracy: 4,
  },
  {
    postcode: 4814,
    place_name: 'Annandale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3111,
    longitude: 146.7876,
    accuracy: 4,
  },
  {
    postcode: 4814,
    place_name: 'Cranbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3055,
    longitude: 146.7529,
    accuracy: 4,
  },
  {
    postcode: 4814,
    place_name: 'Thuringowa DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2983,
    longitude: 146.7711,
    accuracy: 3,
  },
  {
    postcode: 4815,
    place_name: 'Condon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3297,
    longitude: 146.7166,
    accuracy: 4,
  },
  {
    postcode: 4815,
    place_name: 'Gumlow',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3528,
    longitude: 146.6849,
    accuracy: 4,
  },
  {
    postcode: 4815,
    place_name: 'Rasmussen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3524,
    longitude: 146.7228,
    accuracy: 4,
  },
  {
    postcode: 4815,
    place_name: 'Pinnacles',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.4415,
    longitude: 146.6751,
    accuracy: 4,
  },
  {
    postcode: 4815,
    place_name: 'Kelso',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3893,
    longitude: 146.7191,
    accuracy: 4,
  },
  {
    postcode: 4815,
    place_name: 'Granite Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5988,
    longitude: 146.5943,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Nome',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3833,
    longitude: 146.9167,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Hidden Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.5619,
    longitude: 144.8924,
    accuracy: 3,
  },
  {
    postcode: 4816,
    place_name: 'Alligator Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3918,
    longitude: 146.9378,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Mutarnee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.95,
    longitude: 146.3,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Julago',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3695,
    longitude: 146.8872,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Mount Elliot',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5652,
    longitude: 146.3947,
    accuracy: 3,
  },
  {
    postcode: 4816,
    place_name: 'Torrens Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7667,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'The Cape',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.4592,
    longitude: 145.1686,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Homestead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.3667,
    longitude: 145.6667,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Greenvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.5619,
    longitude: 144.8924,
    accuracy: 3,
  },
  {
    postcode: 4816,
    place_name: 'Crystal Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.0029,
    longitude: 146.2689,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Rollingstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.05,
    longitude: 146.3833,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Crimea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.7307,
    longitude: 146.8048,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Buchanan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5652,
    longitude: 146.3947,
    accuracy: 3,
  },
  {
    postcode: 4816,
    place_name: 'Ross River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3167,
    longitude: 146.7333,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Savannah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5652,
    longitude: 146.3947,
    accuracy: 3,
  },
  {
    postcode: 4816,
    place_name: 'Barringha',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.55,
    longitude: 146.8333,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Malpas-Trenton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5652,
    longitude: 146.3947,
    accuracy: 3,
  },
  {
    postcode: 4816,
    place_name: 'Balgal Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.0163,
    longitude: 146.4083,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Carruchan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.2167,
    longitude: 145.9167,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Toonpan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5,
    longitude: 146.8333,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Kennedy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.2048,
    longitude: 145.9546,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Brookhill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3833,
    longitude: 146.8333,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Majors Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.6338,
    longitude: 147.0081,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Paluma',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.0098,
    longitude: 146.2086,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Ellerbeck',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.5652,
    longitude: 146.3947,
    accuracy: 3,
  },
  {
    postcode: 4816,
    place_name: 'Woodstock',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.6,
    longitude: 146.8333,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Prairie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.8833,
    longitude: 144.6,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Reid River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.7667,
    longitude: 146.8333,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Clemant',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.092,
    longitude: 146.4733,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Macrossan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20,
    longitude: 146.4667,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Nelia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.5619,
    longitude: 144.8924,
    accuracy: 3,
  },
  {
    postcode: 4816,
    place_name: 'Sellheim',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0167,
    longitude: 146.4167,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Calcium',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.6395,
    longitude: 146.8198,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Cungulla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3921,
    longitude: 147.1083,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Toomulla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.0828,
    longitude: 146.4749,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Mingela',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8833,
    longitude: 146.6333,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Palm Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.7356,
    longitude: 146.5779,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Pentland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.5236,
    longitude: 145.3969,
    accuracy: 4,
  },
  {
    postcode: 4816,
    place_name: 'Ravenswood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1,
    longitude: 146.8833,
    accuracy: 4,
  },
  {
    postcode: 4817,
    place_name: 'Kirwan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3032,
    longitude: 146.7253,
    accuracy: 4,
  },
  {
    postcode: 4817,
    place_name: 'Thuringowa Central',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3167,
    longitude: 146.7289,
    accuracy: 4,
  },
  {
    postcode: 4817,
    place_name: 'Bohle Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3137,
    longitude: 146.6919,
    accuracy: 4,
  },
  {
    postcode: 4817,
    place_name: 'Alice River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3344,
    longitude: 146.6139,
    accuracy: 4,
  },
  {
    postcode: 4817,
    place_name: 'Rangewood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.3114,
    longitude: 146.6388,
    accuracy: 4,
  },
  {
    postcode: 4817,
    place_name: 'Hervey Range',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.4857,
    longitude: 146.6606,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Lynam',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2751,
    longitude: 146.5469,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Beach Holm',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2107,
    longitude: 146.6499,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Yabulu',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.201,
    longitude: 146.5888,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Mount St John',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2568,
    longitude: 146.7448,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Burdell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2506,
    longitude: 146.6988,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Blue Hills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.5329,
    longitude: 150.9193,
    accuracy: 3,
  },
  {
    postcode: 4818,
    place_name: 'Bushland Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1913,
    longitude: 146.6768,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Mount Low',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2321,
    longitude: 146.6695,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Jensen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2457,
    longitude: 146.6529,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Bohle',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2625,
    longitude: 146.7156,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Saunders Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1579,
    longitude: 146.6032,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Bluewater Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.205,
    longitude: 146.5134,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Bluewater',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1816,
    longitude: 146.5582,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Deeragun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2474,
    longitude: 146.6757,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Toolakea',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1476,
    longitude: 146.5863,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Shaw',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2626,
    longitude: 146.7033,
    accuracy: 4,
  },
  {
    postcode: 4818,
    place_name: 'Cosgrove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2154,
    longitude: 146.6304,
    accuracy: 3,
  },
  {
    postcode: 4818,
    place_name: 'Black River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.2252,
    longitude: 146.6179,
    accuracy: 4,
  },
  {
    postcode: 4819,
    place_name: 'West Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1409,
    longitude: 146.8244,
    accuracy: 4,
  },
  {
    postcode: 4819,
    place_name: 'Florence Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1167,
    longitude: 146.8705,
    accuracy: 4,
  },
  {
    postcode: 4819,
    place_name: 'Horseshoe Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1176,
    longitude: 146.8605,
    accuracy: 4,
  },
  {
    postcode: 4819,
    place_name: 'Picnic Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1746,
    longitude: 146.8376,
    accuracy: 4,
  },
  {
    postcode: 4819,
    place_name: 'Nelly Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1578,
    longitude: 146.8498,
    accuracy: 4,
  },
  {
    postcode: 4819,
    place_name: 'Magnetic Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1551,
    longitude: 146.8485,
    accuracy: 4,
  },
  {
    postcode: 4819,
    place_name: 'Arcadia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.1494,
    longitude: 146.8667,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Broughton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1167,
    longitude: 146.4333,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Columbia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0041,
    longitude: 145.9004,
    accuracy: 3,
  },
  {
    postcode: 4820,
    place_name: 'Breddan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.9804,
    longitude: 146.2904,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Black Jack',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.108,
    longitude: 146.1939,
    accuracy: 3,
  },
  {
    postcode: 4820,
    place_name: 'Lissner',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0776,
    longitude: 146.2582,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Richmond Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0041,
    longitude: 145.9004,
    accuracy: 3,
  },
  {
    postcode: 4820,
    place_name: 'Towers Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0906,
    longitude: 146.2503,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Balfes Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2167,
    longitude: 145.9167,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Alabama Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0823,
    longitude: 146.2484,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Millchester',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0953,
    longitude: 146.276,
    accuracy: 3,
  },
  {
    postcode: 4820,
    place_name: 'Charters Towers',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0767,
    longitude: 146.2635,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Mosman Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0987,
    longitude: 146.2603,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Grand Secret',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0761,
    longitude: 146.2449,
    accuracy: 3,
  },
  {
    postcode: 4820,
    place_name: 'Campaspe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.108,
    longitude: 146.1939,
    accuracy: 3,
  },
  {
    postcode: 4820,
    place_name: 'Llanarth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.108,
    longitude: 146.1939,
    accuracy: 3,
  },
  {
    postcode: 4820,
    place_name: 'Southern Cross',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1333,
    longitude: 146.1167,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Toll',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0506,
    longitude: 146.2556,
    accuracy: 3,
  },
  {
    postcode: 4820,
    place_name: 'Seventy Mile',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.597,
    longitude: 146.6175,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Queenton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.0745,
    longitude: 146.2817,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Dotswood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.108,
    longitude: 146.1939,
    accuracy: 3,
  },
  {
    postcode: 4820,
    place_name: 'Basalt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.7516,
    longitude: 145.3892,
    accuracy: 4,
  },
  {
    postcode: 4820,
    place_name: 'Charters Towers City',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.108,
    longitude: 146.1939,
    accuracy: 3,
  },
  {
    postcode: 4821,
    place_name: 'Tangorin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.75,
    longitude: 144.2333,
    accuracy: 4,
  },
  {
    postcode: 4821,
    place_name: 'Stamford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2667,
    longitude: 143.8167,
    accuracy: 4,
  },
  {
    postcode: 4821,
    place_name: 'Porcupine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.844,
    longitude: 144.2003,
    accuracy: 3,
  },
  {
    postcode: 4821,
    place_name: 'Dutton River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.844,
    longitude: 144.2003,
    accuracy: 3,
  },
  {
    postcode: 4821,
    place_name: 'Hughenden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.844,
    longitude: 144.2003,
    accuracy: 4,
  },
  {
    postcode: 4822,
    place_name: 'Albion',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8,
    longitude: 143.2833,
    accuracy: 3,
  },
  {
    postcode: 4822,
    place_name: 'Cambridge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8,
    longitude: 143.2833,
    accuracy: 3,
  },
  {
    postcode: 4822,
    place_name: 'Bellfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8,
    longitude: 143.2833,
    accuracy: 3,
  },
  {
    postcode: 4822,
    place_name: 'Richmond',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7324,
    longitude: 143.1436,
    accuracy: 4,
  },
  {
    postcode: 4822,
    place_name: 'Maxwelton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7167,
    longitude: 142.6833,
    accuracy: 4,
  },
  {
    postcode: 4822,
    place_name: 'Victoria Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8,
    longitude: 143.2833,
    accuracy: 3,
  },
  {
    postcode: 4822,
    place_name: 'Saxby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8,
    longitude: 143.2833,
    accuracy: 3,
  },
  {
    postcode: 4822,
    place_name: 'Burleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8,
    longitude: 143.2833,
    accuracy: 3,
  },
  {
    postcode: 4822,
    place_name: 'Woolgar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.8,
    longitude: 143.2833,
    accuracy: 4,
  },
  {
    postcode: 4823,
    place_name: 'Julia Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.6558,
    longitude: 141.7413,
    accuracy: 4,
  },
  {
    postcode: 4823,
    place_name: 'Mckinlay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.5833,
    longitude: 141.9,
    accuracy: 4,
  },
  {
    postcode: 4823,
    place_name: 'Carpentaria',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4823,
    place_name: 'Kynuna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.5833,
    longitude: 141.9,
    accuracy: 4,
  },
  {
    postcode: 4823,
    place_name: 'Taldora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4823,
    place_name: 'Warburton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.5351,
    longitude: 141.5871,
    accuracy: 3,
  },
  {
    postcode: 4823,
    place_name: 'Stokes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4824,
    place_name: 'Kuridala',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2833,
    longitude: 140.5,
    accuracy: 4,
  },
  {
    postcode: 4824,
    place_name: 'Four Ways',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4824,
    place_name: 'Oorindi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.6833,
    longitude: 141.0667,
    accuracy: 4,
  },
  {
    postcode: 4824,
    place_name: 'Gidya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4824,
    place_name: 'Cloncurry',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7072,
    longitude: 140.513,
    accuracy: 4,
  },
  {
    postcode: 4824,
    place_name: 'Three Rivers',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Mount Isa DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Mount Isa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7252,
    longitude: 139.4973,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Winston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7037,
    longitude: 139.4991,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Menzies',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7181,
    longitude: 139.4972,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Sunset',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7103,
    longitude: 139.5082,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Happy Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7479,
    longitude: 139.4906,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Soldiers Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7053,
    longitude: 139.4899,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Waverley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Townview',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7319,
    longitude: 139.5077,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Spreadborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Duchess',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.3667,
    longitude: 139.85,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Parkside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7377,
    longitude: 139.4886,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Miles End',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7241,
    longitude: 139.4867,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Mount Isa East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Healy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7418,
    longitude: 139.5014,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'The Monument',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Gunpowder',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.7103,
    longitude: 139.3839,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'The Gap',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Pioneer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7203,
    longitude: 139.5081,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Ryan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Dajarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.6959,
    longitude: 139.5163,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Breakaway',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Buckingham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Georgina',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Mount Isa City',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Piturie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Mica Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7668,
    longitude: 139.4913,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Carrandotta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Fisher',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.2234,
    longitude: 138.7211,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Kalkadoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Lanskey',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Lawn Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Mornington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.7342,
    longitude: 139.4959,
    accuracy: 4,
  },
  {
    postcode: 4825,
    place_name: 'Fielding',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4825,
    place_name: 'Barkly',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.1386,
    longitude: 145.8436,
    accuracy: 3,
  },
  {
    postcode: 4828,
    place_name: 'Camooweal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -19.9215,
    longitude: 138.1201,
    accuracy: 4,
  },
  {
    postcode: 4829,
    place_name: 'Boulia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -22.9114,
    longitude: 139.9093,
    accuracy: 4,
  },
  {
    postcode: 4829,
    place_name: 'Toko',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6177,
    longitude: 139.1149,
    accuracy: 3,
  },
  {
    postcode: 4829,
    place_name: 'Bedourie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6177,
    longitude: 139.1149,
    accuracy: 3,
  },
  {
    postcode: 4829,
    place_name: 'Wills',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6177,
    longitude: 139.1149,
    accuracy: 3,
  },
  {
    postcode: 4829,
    place_name: 'Amaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6177,
    longitude: 139.1149,
    accuracy: 3,
  },
  {
    postcode: 4829,
    place_name: 'Warenda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6177,
    longitude: 139.1149,
    accuracy: 3,
  },
  {
    postcode: 4829,
    place_name: 'Sturt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6177,
    longitude: 139.1149,
    accuracy: 3,
  },
  {
    postcode: 4829,
    place_name: 'Min Min',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -24.6177,
    longitude: 139.1149,
    accuracy: 3,
  },
  {
    postcode: 4830,
    place_name: 'Nicholson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.1224,
    longitude: 146.0477,
    accuracy: 3,
  },
  {
    postcode: 4830,
    place_name: 'Burketown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7411,
    longitude: 139.5477,
    accuracy: 4,
  },
  {
    postcode: 4830,
    place_name: 'Gregory',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.1224,
    longitude: 146.0477,
    accuracy: 3,
  },
  {
    postcode: 4830,
    place_name: 'Doomadgee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9435,
    longitude: 138.8272,
    accuracy: 4,
  },
  {
    postcode: 4849,
    place_name: 'Cardwell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.2669,
    longitude: 146.028,
    accuracy: 4,
  },
  {
    postcode: 4849,
    place_name: 'Rungoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.3072,
    longitude: 146.1291,
    accuracy: 3,
  },
  {
    postcode: 4849,
    place_name: 'Lumholtz',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.3072,
    longitude: 146.1291,
    accuracy: 3,
  },
  {
    postcode: 4849,
    place_name: 'Hinchinbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.3511,
    longitude: 146.2352,
    accuracy: 4,
  },
  {
    postcode: 4849,
    place_name: 'Damper Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.3072,
    longitude: 146.1291,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Ingham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.6455,
    longitude: 146.1625,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Yuruga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.8333,
    longitude: 146.1667,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Toobanna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.7,
    longitude: 146.15,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Peacock Siding',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.6833,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Bemerside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.5667,
    longitude: 146.2333,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Macknade',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.5873,
    longitude: 146.2559,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Lucinda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.5278,
    longitude: 146.3313,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Abergowrie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.4382,
    longitude: 145.9313,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Halifax',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.5827,
    longitude: 146.2855,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Valley Of Lagoons',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.661,
    longitude: 146.1506,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Blackrock',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.7085,
    longitude: 146.2121,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Trebonne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.6254,
    longitude: 146.0792,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Victoria Estate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.65,
    longitude: 146.2,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Victoria Plantation',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.6466,
    longitude: 146.2019,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Hawkins Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.6,
    longitude: 146.1,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Coolbie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.9167,
    longitude: 146.2667,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Long Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.5167,
    longitude: 146.0333,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Allingham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.6667,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Braemeadows',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.661,
    longitude: 146.1506,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Dalrymple Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.661,
    longitude: 146.1506,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Bambaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.8667,
    longitude: 146.2,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Orient',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.661,
    longitude: 146.1506,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Lannercost',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.5833,
    longitude: 146.05,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Wharps',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.661,
    longitude: 146.1506,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Helens Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.7667,
    longitude: 146.1667,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Garrawalt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.661,
    longitude: 146.1506,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Cordelia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.5833,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Wallaman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.661,
    longitude: 146.1506,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Taylors Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.6327,
    longitude: 146.3245,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Foresthome',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.661,
    longitude: 146.1506,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Mount Fox',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.8167,
    longitude: 145.8333,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Forrest Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.661,
    longitude: 146.1506,
    accuracy: 3,
  },
  {
    postcode: 4850,
    place_name: 'Upper Stone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.7333,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 4850,
    place_name: 'Gairloch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.6333,
    longitude: 146.1667,
    accuracy: 4,
  },
  {
    postcode: 4852,
    place_name: "Tam O'Shanter",
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.941,
    longitude: 146.0235,
    accuracy: 4,
  },
  {
    postcode: 4852,
    place_name: 'Djiru',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8466,
    longitude: 146.0534,
    accuracy: 3,
  },
  {
    postcode: 4852,
    place_name: 'Dunk',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9518,
    longitude: 146.1557,
    accuracy: 4,
  },
  {
    postcode: 4852,
    place_name: 'Garners Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8163,
    longitude: 146.0977,
    accuracy: 4,
  },
  {
    postcode: 4852,
    place_name: 'Bingil Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8295,
    longitude: 146.0965,
    accuracy: 4,
  },
  {
    postcode: 4852,
    place_name: 'Carmoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9473,
    longitude: 146.0457,
    accuracy: 4,
  },
  {
    postcode: 4852,
    place_name: 'Wongaling Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9133,
    longitude: 146.0961,
    accuracy: 4,
  },
  {
    postcode: 4852,
    place_name: 'Midgeree Bar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8008,
    longitude: 146.0801,
    accuracy: 4,
  },
  {
    postcode: 4852,
    place_name: 'South Mission Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.95,
    longitude: 146.0833,
    accuracy: 4,
  },
  {
    postcode: 4852,
    place_name: 'Mission Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8688,
    longitude: 146.1039,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Birkalla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.921,
    longitude: 145.9401,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Midgenoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Murray Upper',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.1,
    longitude: 145.85,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'East Feluga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4854,
    place_name: 'Tully Heads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.0232,
    longitude: 146.0558,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Bilyana',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.1092,
    longitude: 145.9267,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Silky Oak',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9673,
    longitude: 145.9392,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Lower Tully',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9846,
    longitude: 146.0144,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Bulgun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8996,
    longitude: 145.9282,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Hull Heads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4854,
    place_name: 'Cardstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7509,
    longitude: 145.5993,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Rockingham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.0275,
    longitude: 145.9746,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Djarawong',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8667,
    longitude: 145.9833,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Dingo Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4854,
    place_name: 'Murrigal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.0478,
    longitude: 145.9116,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Tully',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9326,
    longitude: 145.9228,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Feluga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8795,
    longitude: 145.9516,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Kooroomool',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4854,
    place_name: 'Warrami',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4854,
    place_name: 'Mount Mackay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4854,
    place_name: 'Euramo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18,
    longitude: 145.9333,
    accuracy: 4,
  },
  {
    postcode: 4854,
    place_name: 'Munro Plains',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4854,
    place_name: 'Jarra Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4854,
    place_name: 'Merryburn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4854,
    place_name: 'Walter Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9573,
    longitude: 145.9256,
    accuracy: 3,
  },
  {
    postcode: 4855,
    place_name: 'El Arish',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8075,
    longitude: 146.0072,
    accuracy: 4,
  },
  {
    postcode: 4855,
    place_name: 'Jaffa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7833,
    longitude: 146.0167,
    accuracy: 4,
  },
  {
    postcode: 4855,
    place_name: 'Maadi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8333,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 4855,
    place_name: 'Granadilla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8057,
    longitude: 146.0047,
    accuracy: 3,
  },
  {
    postcode: 4855,
    place_name: 'Shell Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8057,
    longitude: 146.0047,
    accuracy: 3,
  },
  {
    postcode: 4855,
    place_name: 'Maria Creeks',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8057,
    longitude: 146.0047,
    accuracy: 3,
  },
  {
    postcode: 4855,
    place_name: 'Daveson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8057,
    longitude: 146.0047,
    accuracy: 3,
  },
  {
    postcode: 4855,
    place_name: 'Friday Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.8057,
    longitude: 146.0047,
    accuracy: 3,
  },
  {
    postcode: 4856,
    place_name: 'Silkwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7446,
    longitude: 146.0171,
    accuracy: 4,
  },
  {
    postcode: 4856,
    place_name: 'Goolboo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7104,
    longitude: 146.0345,
    accuracy: 4,
  },
  {
    postcode: 4856,
    place_name: 'Japoonvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7167,
    longitude: 145.9333,
    accuracy: 4,
  },
  {
    postcode: 4856,
    place_name: 'Walter Lever Estate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7786,
    longitude: 145.9193,
    accuracy: 3,
  },
  {
    postcode: 4856,
    place_name: 'No. 4 Branch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7786,
    longitude: 145.9193,
    accuracy: 3,
  },
  {
    postcode: 4856,
    place_name: 'No. 5 Branch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7786,
    longitude: 145.9193,
    accuracy: 3,
  },
  {
    postcode: 4856,
    place_name: 'Mccutcheon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7786,
    longitude: 145.9193,
    accuracy: 3,
  },
  {
    postcode: 4857,
    place_name: 'Silkwood East',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7435,
    longitude: 146.0097,
    accuracy: 1,
  },
  {
    postcode: 4858,
    place_name: 'Comoon Loop',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5607,
    longitude: 146.0337,
    accuracy: 4,
  },
  {
    postcode: 4858,
    place_name: 'Etty Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6009,
    longitude: 146.0708,
    accuracy: 3,
  },
  {
    postcode: 4858,
    place_name: 'Mourilyan Harbour',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6002,
    longitude: 146.1251,
    accuracy: 4,
  },
  {
    postcode: 4858,
    place_name: 'Mourilyan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5826,
    longitude: 146.0435,
    accuracy: 4,
  },
  {
    postcode: 4858,
    place_name: 'New Harbourline',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.614,
    longitude: 146.0637,
    accuracy: 4,
  },
  {
    postcode: 4858,
    place_name: 'Martyville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6175,
    longitude: 146.036,
    accuracy: 4,
  },
  {
    postcode: 4859,
    place_name: 'No. 6 Branch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5983,
    longitude: 145.9944,
    accuracy: 3,
  },
  {
    postcode: 4859,
    place_name: 'South Johnstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5972,
    longitude: 145.9945,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Coorumba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5563,
    longitude: 145.9143,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Innisfail Estate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.519,
    longitude: 146.0393,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Hudson',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5256,
    longitude: 146.0031,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'East Palmerston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6062,
    longitude: 145.8486,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Eubenangee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.455,
    longitude: 145.9768,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Cooroo Lands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Cullinane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5152,
    longitude: 146.026,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Garradunga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4667,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Belvedere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5208,
    longitude: 145.9892,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Nerada',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'South Innisfail',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5449,
    longitude: 146.0296,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Sundown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4654,
    longitude: 151.3113,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Goondi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5167,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Palmerston',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Daradgee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4833,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Innisfail',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5221,
    longitude: 146.031,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'East Innisfail',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5342,
    longitude: 146.0328,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Stoters Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Mighell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5329,
    longitude: 146.024,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Goondi Bend',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5192,
    longitude: 146.0107,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Wooroonooran',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3807,
    longitude: 145.8007,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Coquette Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Flying Fish Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4967,
    longitude: 146.076,
    accuracy: 4,
  },
  {
    postcode: 4860,
    place_name: 'Fitzgerald Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Eaton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Coconuts',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5059,
    longitude: 146.0645,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Njatjan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Ngatjan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5156,
    longitude: 145.9646,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Bamboo Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: "O'Briens Hill",
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Pin Gin Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Vasa Views',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4685,
    longitude: 145.9656,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Jubilee Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Webb',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5298,
    longitude: 146.0435,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Upper Daradgee',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Wanjuru',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5305,
    longitude: 145.8932,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Goondi Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5246,
    longitude: 146.0171,
    accuracy: 3,
  },
  {
    postcode: 4860,
    place_name: 'Mundoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5626,
    longitude: 146.0166,
    accuracy: 4,
  },
  {
    postcode: 4861,
    place_name: 'Bartle Frere',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.45,
    longitude: 145.8833,
    accuracy: 4,
  },
  {
    postcode: 4861,
    place_name: 'East Russell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3577,
    longitude: 145.9755,
    accuracy: 3,
  },
  {
    postcode: 4861,
    place_name: 'Babinda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3439,
    longitude: 145.9227,
    accuracy: 4,
  },
  {
    postcode: 4865,
    place_name: 'Gordonvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.0996,
    longitude: 145.7804,
    accuracy: 4,
  },
  {
    postcode: 4865,
    place_name: 'Packers Camp',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.1114,
    longitude: 145.754,
    accuracy: 3,
  },
  {
    postcode: 4865,
    place_name: 'Green Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.1114,
    longitude: 145.754,
    accuracy: 3,
  },
  {
    postcode: 4865,
    place_name: 'Kamma',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.05,
    longitude: 145.7667,
    accuracy: 4,
  },
  {
    postcode: 4865,
    place_name: 'Little Mulgrave',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.15,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 4865,
    place_name: 'Goldsborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.146,
    longitude: 145.7524,
    accuracy: 4,
  },
  {
    postcode: 4868,
    place_name: 'Bayview Heights',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9623,
    longitude: 145.7258,
    accuracy: 4,
  },
  {
    postcode: 4868,
    place_name: 'White Rock',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9833,
    longitude: 145.75,
    accuracy: 4,
  },
  {
    postcode: 4868,
    place_name: 'Woree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.95,
    longitude: 145.75,
    accuracy: 4,
  },
  {
    postcode: 4868,
    place_name: 'Mount Sheridan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9889,
    longitude: 145.7322,
    accuracy: 4,
  },
  {
    postcode: 4869,
    place_name: 'Bentley Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.0083,
    longitude: 145.7221,
    accuracy: 4,
  },
  {
    postcode: 4869,
    place_name: 'Mount Peter',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.0177,
    longitude: 145.7305,
    accuracy: 3,
  },
  {
    postcode: 4869,
    place_name: 'Edmonton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.019,
    longitude: 145.7444,
    accuracy: 4,
  },
  {
    postcode: 4869,
    place_name: 'Wrights Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.0177,
    longitude: 145.7305,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Cairns',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.923,
    longitude: 145.7663,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Westcourt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9304,
    longitude: 145.753,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Edge Hill',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9,
    longitude: 145.75,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Cairns North',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9066,
    longitude: 145.7597,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Bungalow',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9373,
    longitude: 145.757,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Cairns Central',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9071,
    longitude: 145.7325,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Manunda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9172,
    longitude: 145.7492,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Cairns City',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9078,
    longitude: 145.7329,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Parramatta Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9245,
    longitude: 145.7626,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Mooroobool',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9356,
    longitude: 145.7296,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Whitfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8951,
    longitude: 145.7333,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Cairns Orchid Plaza',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9078,
    longitude: 145.7329,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Kanimbla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9217,
    longitude: 145.7222,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Cairns DC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9071,
    longitude: 145.7325,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Aeroglen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8806,
    longitude: 145.745,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Martynvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9078,
    longitude: 145.7329,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Redlynch',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8833,
    longitude: 145.7,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Portsmith',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9071,
    longitude: 145.7325,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Brinsmead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8992,
    longitude: 145.7155,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'North Cairns',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9071,
    longitude: 145.7325,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Manoora',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9171,
    longitude: 145.7367,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Barron Gorge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8802,
    longitude: 145.6692,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Earlville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9471,
    longitude: 145.7315,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Lamb Range',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9071,
    longitude: 145.7325,
    accuracy: 3,
  },
  {
    postcode: 4870,
    place_name: 'Kamerunga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8732,
    longitude: 145.7013,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Stratford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8833,
    longitude: 145.7333,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Freshwater',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8833,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 4870,
    place_name: 'Earlville BC',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9071,
    longitude: 145.7325,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Coen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -13.9456,
    longitude: 143.199,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Stockton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5811,
    longitude: 146.0141,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Cowley Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6953,
    longitude: 146.1073,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Einasleigh',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.5167,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Gilbert River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.15,
    longitude: 142.8667,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Cowley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6833,
    longitude: 146.05,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Bellenden Ker',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2667,
    longitude: 145.9167,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Georgetown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.292,
    longitude: 143.5458,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Basilisk',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6602,
    longitude: 145.992,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Moresby',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6333,
    longitude: 146.0333,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'East Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Mena Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.65,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Mount Surprise',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.1466,
    longitude: 144.319,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'East Trinity',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8932,
    longitude: 145.8267,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Karron',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Strathmore',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Mount Molloy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.6734,
    longitude: 145.3272,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Lower Cowley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Lyndhurst',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Desailly',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Mount Mulligan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.85,
    longitude: 144.8667,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Julatten',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.6072,
    longitude: 145.3412,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Springfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Yarrabah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9203,
    longitude: 145.8715,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Lakeland Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.8585,
    longitude: 144.8546,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Lockhart',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Aloomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.1099,
    longitude: 145.8334,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Chillagoe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.1539,
    longitude: 144.5227,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Boogan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6111,
    longitude: 146.0133,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Utchee Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Miriwinni',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4,
    longitude: 145.9167,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Talaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Laura',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.5691,
    longitude: 144.4702,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Currajah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Kurrimine Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.7765,
    longitude: 146.1066,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Forsayth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.5833,
    longitude: 143.6167,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Aurukun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -13.6029,
    longitude: 141.8162,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Camp Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Kowanyama',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.4736,
    longitude: 141.7457,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Petford',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.35,
    longitude: 144.9333,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Hurricane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Green Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Gununa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.6657,
    longitude: 139.1801,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Croydon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -18.2043,
    longitude: 142.2446,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Crystalbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Portland Roads',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Claraville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Esmeralda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Coralie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Bombeeta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6833,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Woopen Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Deeral',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2167,
    longitude: 145.9167,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Glen Boughton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Mount Carbine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.5333,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Amber',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Macalister Range',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.7755,
    longitude: 145.6551,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Bolwarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Mirriwinni',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Blackbull',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Fishery Falls',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.1833,
    longitude: 145.8833,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Sandy Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Fitzroy Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Cowley Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Wangan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5775,
    longitude: 146.0067,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Fossilbrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Northhead',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Germantown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Lakeland',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.9707,
    longitude: 144.8036,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Edward River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1048,
    longitude: 143.1125,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Thornborough',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Conjuboy',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Southedge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Bulleringa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Waugh Pocket',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Pormpuraaw',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -14.6611,
    longitude: 141.835,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Bramston Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3518,
    longitude: 146.0231,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Almaden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3382,
    longitude: 144.6771,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Rookwood',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4871,
    place_name: 'Warrubullen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6913,
    longitude: 146.0167,
    accuracy: 4,
  },
  {
    postcode: 4871,
    place_name: 'Nychum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2018,
    longitude: 145.1662,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Kairi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2144,
    longitude: 145.544,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Silver Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9971,
    longitude: 145.26,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Mutchilba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.1333,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Barrine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2,
    longitude: 145.6333,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Innot Hot Springs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6667,
    longitude: 145.25,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Wairuna',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9971,
    longitude: 145.26,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Mount Garnet',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6766,
    longitude: 145.1143,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Kowrowa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8167,
    longitude: 145.5833,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Danbulla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.15,
    longitude: 145.6167,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Munderra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2313,
    longitude: 145.4035,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Dimbulah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.1475,
    longitude: 145.1097,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Barwidgi',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2313,
    longitude: 145.4035,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Tinaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.1712,
    longitude: 145.5494,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Cairns Mc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2313,
    longitude: 145.4035,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Walkamin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.1368,
    longitude: 145.4172,
    accuracy: 4,
  },
  {
    postcode: 4872,
    place_name: 'Koombooloomba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9971,
    longitude: 145.26,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Forty Mile',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2313,
    longitude: 145.4035,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Lake Tinaroo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9971,
    longitude: 145.26,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Minnamoolka',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9971,
    longitude: 145.26,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Glen Ruth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9971,
    longitude: 145.26,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Gunnawarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9971,
    longitude: 145.26,
    accuracy: 3,
  },
  {
    postcode: 4872,
    place_name: 'Kirrama',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.9971,
    longitude: 145.26,
    accuracy: 3,
  },
  {
    postcode: 4873,
    place_name: 'Cow Bay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.2254,
    longitude: 145.4286,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Forest Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.2374,
    longitude: 145.3658,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Syndicate',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3447,
    longitude: 145.3956,
    accuracy: 3,
  },
  {
    postcode: 4873,
    place_name: 'Dagmar',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3447,
    longitude: 145.3956,
    accuracy: 3,
  },
  {
    postcode: 4873,
    place_name: 'Bamboo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -14.6417,
    longitude: 143.4742,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Kimberley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.2562,
    longitude: 145.4387,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Daintree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.25,
    longitude: 145.3193,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Dedin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3447,
    longitude: 145.3956,
    accuracy: 3,
  },
  {
    postcode: 4873,
    place_name: 'Whyanbeel',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3642,
    longitude: 145.3597,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Shannonvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.4901,
    longitude: 145.3775,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Diwan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.1902,
    longitude: 145.415,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Low Isles',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3447,
    longitude: 145.3956,
    accuracy: 3,
  },
  {
    postcode: 4873,
    place_name: 'Cassowary',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.516,
    longitude: 145.4123,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Rocky Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3907,
    longitude: 145.4056,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Miallo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3997,
    longitude: 145.3803,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Noah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3447,
    longitude: 145.3956,
    accuracy: 3,
  },
  {
    postcode: 4873,
    place_name: 'Finlayvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.4666,
    longitude: 145.3451,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Bonnie Doon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.4491,
    longitude: 145.3806,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Mossman',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.4605,
    longitude: 145.3719,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Upper Daintree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.239,
    longitude: 145.3059,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Newell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.4264,
    longitude: 145.406,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Spurgeon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3447,
    longitude: 145.3956,
    accuracy: 3,
  },
  {
    postcode: 4873,
    place_name: 'Stewart Creek Valley',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.298,
    longitude: 145.3151,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Cape Tribulation',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.0867,
    longitude: 145.4618,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Mossman Gorge',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.4663,
    longitude: 145.3633,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Cooya Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.4481,
    longitude: 145.4057,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Wonga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.346,
    longitude: 145.412,
    accuracy: 4,
  },
  {
    postcode: 4873,
    place_name: 'Thornton Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.3447,
    longitude: 145.3956,
    accuracy: 3,
  },
  {
    postcode: 4873,
    place_name: 'Lower Daintree',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.276,
    longitude: 145.3821,
    accuracy: 4,
  },
  {
    postcode: 4874,
    place_name: 'Nanum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.6401,
    longitude: 141.8636,
    accuracy: 3,
  },
  {
    postcode: 4874,
    place_name: 'Weipa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.6235,
    longitude: 141.8788,
    accuracy: 4,
  },
  {
    postcode: 4874,
    place_name: 'Mapoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.019,
    longitude: 141.9006,
    accuracy: 4,
  },
  {
    postcode: 4874,
    place_name: 'Napranum',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.6832,
    longitude: 141.89,
    accuracy: 4,
  },
  {
    postcode: 4874,
    place_name: 'Evans Landing',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.6623,
    longitude: 141.8526,
    accuracy: 4,
  },
  {
    postcode: 4874,
    place_name: 'Rocky Point',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.5776,
    longitude: 141.9603,
    accuracy: 3,
  },
  {
    postcode: 4874,
    place_name: 'Wenlock',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -13.0876,
    longitude: 142.9476,
    accuracy: 4,
  },
  {
    postcode: 4874,
    place_name: 'Trunding',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.6341,
    longitude: 141.871,
    accuracy: 3,
  },
  {
    postcode: 4874,
    place_name: 'Weipa Airport',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.5776,
    longitude: 141.9603,
    accuracy: 3,
  },
  {
    postcode: 4874,
    place_name: 'Jardine River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 3,
  },
  {
    postcode: 4874,
    place_name: 'Mission River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.5951,
    longitude: 141.9576,
    accuracy: 4,
  },
  {
    postcode: 4874,
    place_name: 'Shelburne',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 3,
  },
  {
    postcode: 4875,
    place_name: 'Prince Of Wales',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Murray Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Boigu Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Badu Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -10.1201,
    longitude: 142.1394,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Dauan Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Thursday Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -10.5838,
    longitude: 142.2206,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Erub',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Coconut Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Yorke Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Stephens Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Kubin Village',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Warraber Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Horn Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Saibai Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Mabuiag Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Moa Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4875,
    place_name: 'Yam Island',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4876,
    place_name: 'Umagico',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4876,
    place_name: 'New Mapoon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4876,
    place_name: 'Seisia',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4876,
    place_name: 'Injinoo',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.913,
    longitude: 145.4479,
    accuracy: 1,
  },
  {
    postcode: 4876,
    place_name: 'Bamaga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -10.8517,
    longitude: 142.427,
    accuracy: 1,
  },
  {
    postcode: 4877,
    place_name: 'Oak Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.5976,
    longitude: 145.515,
    accuracy: 4,
  },
  {
    postcode: 4877,
    place_name: 'Mowbray',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.5602,
    longitude: 145.4794,
    accuracy: 4,
  },
  {
    postcode: 4877,
    place_name: 'Craiglie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.5379,
    longitude: 145.4696,
    accuracy: 4,
  },
  {
    postcode: 4877,
    place_name: 'Killaloe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.4918,
    longitude: 145.4206,
    accuracy: 4,
  },
  {
    postcode: 4877,
    place_name: 'Wangetti',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.6593,
    longitude: 145.5664,
    accuracy: 4,
  },
  {
    postcode: 4877,
    place_name: 'Port Douglas',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.4838,
    longitude: 145.4673,
    accuracy: 4,
  },
  {
    postcode: 4878,
    place_name: 'Machans Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8585,
    longitude: 145.7447,
    accuracy: 4,
  },
  {
    postcode: 4878,
    place_name: 'Caravonica',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8666,
    longitude: 145.6784,
    accuracy: 4,
  },
  {
    postcode: 4878,
    place_name: 'Yorkeys Knob',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8028,
    longitude: 145.7208,
    accuracy: 4,
  },
  {
    postcode: 4878,
    place_name: 'Holloways Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8421,
    longitude: 145.7392,
    accuracy: 4,
  },
  {
    postcode: 4878,
    place_name: 'Smithfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.825,
    longitude: 145.6917,
    accuracy: 4,
  },
  {
    postcode: 4878,
    place_name: 'Barron',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8367,
    longitude: 145.7022,
    accuracy: 3,
  },
  {
    postcode: 4879,
    place_name: 'Kewarra Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.7822,
    longitude: 145.6845,
    accuracy: 4,
  },
  {
    postcode: 4879,
    place_name: 'Clifton Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.7631,
    longitude: 145.6717,
    accuracy: 4,
  },
  {
    postcode: 4879,
    place_name: 'Ellis Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.7284,
    longitude: 145.6642,
    accuracy: 4,
  },
  {
    postcode: 4879,
    place_name: 'Trinity Beach',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.7888,
    longitude: 145.6968,
    accuracy: 4,
  },
  {
    postcode: 4879,
    place_name: 'Palm Cove',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.75,
    longitude: 145.6667,
    accuracy: 4,
  },
  {
    postcode: 4879,
    place_name: 'Trinity Park',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8057,
    longitude: 145.7008,
    accuracy: 3,
  },
  {
    postcode: 4880,
    place_name: 'Chewko',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.0667,
    longitude: 145.3833,
    accuracy: 4,
  },
  {
    postcode: 4880,
    place_name: 'Mareeba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.995,
    longitude: 145.4232,
    accuracy: 4,
  },
  {
    postcode: 4880,
    place_name: 'Biboohra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9333,
    longitude: 145.4167,
    accuracy: 4,
  },
  {
    postcode: 4880,
    place_name: 'Glen Russell',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9781,
    longitude: 145.3764,
    accuracy: 3,
  },
  {
    postcode: 4880,
    place_name: 'Paddys Green',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9176,
    longitude: 145.2825,
    accuracy: 4,
  },
  {
    postcode: 4880,
    place_name: 'Arriga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.9781,
    longitude: 145.3764,
    accuracy: 3,
  },
  {
    postcode: 4881,
    place_name: 'Speewah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8908,
    longitude: 145.6257,
    accuracy: 4,
  },
  {
    postcode: 4881,
    place_name: 'Koah',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8333,
    longitude: 145.5167,
    accuracy: 4,
  },
  {
    postcode: 4881,
    place_name: 'Kuranda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.8198,
    longitude: 145.6382,
    accuracy: 4,
  },
  {
    postcode: 4881,
    place_name: 'Mona Mona',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.7126,
    longitude: 145.5368,
    accuracy: 4,
  },
  {
    postcode: 4882,
    place_name: 'Tolga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2333,
    longitude: 145.4833,
    accuracy: 4,
  },
  {
    postcode: 4883,
    place_name: 'Carrington',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3435,
    longitude: 145.496,
    accuracy: 3,
  },
  {
    postcode: 4883,
    place_name: 'Atherton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2686,
    longitude: 145.4752,
    accuracy: 4,
  },
  {
    postcode: 4883,
    place_name: 'Upper Barron',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3833,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 4883,
    place_name: 'East Barron',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3167,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 4883,
    place_name: 'Wongabel',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3167,
    longitude: 145.4833,
    accuracy: 4,
  },
  {
    postcode: 4884,
    place_name: 'Lake Eacham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2998,
    longitude: 145.6284,
    accuracy: 4,
  },
  {
    postcode: 4884,
    place_name: 'Yungaburra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2717,
    longitude: 145.5855,
    accuracy: 4,
  },
  {
    postcode: 4884,
    place_name: 'Lake Barrine',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2836,
    longitude: 145.6165,
    accuracy: 3,
  },
  {
    postcode: 4884,
    place_name: 'Gadgarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.2836,
    longitude: 145.6165,
    accuracy: 3,
  },
  {
    postcode: 4885,
    place_name: 'Peeramon',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3167,
    longitude: 145.6167,
    accuracy: 4,
  },
  {
    postcode: 4885,
    place_name: 'Topaz',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4167,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 4885,
    place_name: 'North Johnstone',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3262,
    longitude: 145.6611,
    accuracy: 4,
  },
  {
    postcode: 4885,
    place_name: 'Malanda',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3523,
    longitude: 145.5953,
    accuracy: 4,
  },
  {
    postcode: 4885,
    place_name: 'Kureen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3333,
    longitude: 145.6,
    accuracy: 4,
  },
  {
    postcode: 4885,
    place_name: 'Glen Allyn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3646,
    longitude: 145.631,
    accuracy: 3,
  },
  {
    postcode: 4885,
    place_name: 'Butchers Creek',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3241,
    longitude: 145.6895,
    accuracy: 4,
  },
  {
    postcode: 4885,
    place_name: 'Tarzali',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4333,
    longitude: 145.6,
    accuracy: 4,
  },
  {
    postcode: 4885,
    place_name: 'Jaggan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4,
    longitude: 145.6,
    accuracy: 4,
  },
  {
    postcode: 4886,
    place_name: 'Beatrice',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4991,
    longitude: 145.5987,
    accuracy: 3,
  },
  {
    postcode: 4886,
    place_name: 'Mungalli',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4991,
    longitude: 145.5987,
    accuracy: 3,
  },
  {
    postcode: 4886,
    place_name: 'Millaa Millaa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5139,
    longitude: 145.6129,
    accuracy: 4,
  },
  {
    postcode: 4886,
    place_name: 'Minbun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4833,
    longitude: 145.5833,
    accuracy: 4,
  },
  {
    postcode: 4886,
    place_name: 'Moregatta',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5,
    longitude: 145.6,
    accuracy: 4,
  },
  {
    postcode: 4886,
    place_name: 'Maalan',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4991,
    longitude: 145.5987,
    accuracy: 3,
  },
  {
    postcode: 4886,
    place_name: 'Ellinjaa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4991,
    longitude: 145.5987,
    accuracy: 3,
  },
  {
    postcode: 4886,
    place_name: 'Middlebrook',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4991,
    longitude: 145.5987,
    accuracy: 3,
  },
  {
    postcode: 4887,
    place_name: 'Wondecla',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4167,
    longitude: 145.4,
    accuracy: 4,
  },
  {
    postcode: 4887,
    place_name: 'Irvinebank',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4333,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 4887,
    place_name: 'Herberton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3784,
    longitude: 145.3837,
    accuracy: 4,
  },
  {
    postcode: 4887,
    place_name: 'Watsonville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3833,
    longitude: 145.3167,
    accuracy: 4,
  },
  {
    postcode: 4887,
    place_name: 'Kalunga',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.45,
    longitude: 145.3833,
    accuracy: 4,
  },
  {
    postcode: 4887,
    place_name: 'Moomin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.3818,
    longitude: 145.2063,
    accuracy: 3,
  },
  {
    postcode: 4888,
    place_name: 'Tumoulin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5667,
    longitude: 145.4667,
    accuracy: 4,
  },
  {
    postcode: 4888,
    place_name: 'Millstream',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6267,
    longitude: 145.3756,
    accuracy: 4,
  },
  {
    postcode: 4888,
    place_name: 'Ravenshoe',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6076,
    longitude: 145.4809,
    accuracy: 4,
  },
  {
    postcode: 4888,
    place_name: 'Kaban',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5167,
    longitude: 145.4167,
    accuracy: 4,
  },
  {
    postcode: 4888,
    place_name: 'Evelyn',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.5167,
    longitude: 145.5167,
    accuracy: 4,
  },
  {
    postcode: 4890,
    place_name: 'Normanton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.6718,
    longitude: 141.0772,
    accuracy: 4,
  },
  {
    postcode: 4890,
    place_name: 'Howitt',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.461,
    longitude: 141.1801,
    accuracy: 4,
  },
  {
    postcode: 4891,
    place_name: 'Karumba',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.4869,
    longitude: 140.8426,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Archer River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -13.4384,
    longitude: 142.9403,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Lyndside',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Aurukun',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -13.6057,
    longitude: 141.8279,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Coen',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -13.9456,
    longitude: 143.199,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Wrotham',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Lakefield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'West Wellesley Islands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Bellevue',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Staaten',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Highbury',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Yagoonya',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Lizard',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -14.6641,
    longitude: 145.464,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Ravensworth',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Yarraden',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -14.3019,
    longitude: 143.3133,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Lockhart River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -12.7872,
    longitude: 143.3435,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'South Wellesley Islands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -17.0909,
    longitude: 139.5656,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Groganville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Mount Mulgrave',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Laura',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.5691,
    longitude: 144.4702,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Dixie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.1139,
    longitude: 143.3136,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Kowanyama',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.4736,
    longitude: 141.7457,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Palmer',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Wellesley Islands',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.6318,
    longitude: 139.4014,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Gununa',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -16.6657,
    longitude: 139.1801,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Pormpuraaw',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -14.8988,
    longitude: 141.6212,
    accuracy: 4,
  },
  {
    postcode: 4892,
    place_name: 'Lockhart',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Holroyd River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Arbouin',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Red River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Maramie',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Edward River',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Gamboola',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4892,
    place_name: 'Abingdon Downs',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4895,
    place_name: 'Degarra',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.9522,
    longitude: 145.3473,
    accuracy: 4,
  },
  {
    postcode: 4895,
    place_name: 'Hope Vale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.2956,
    longitude: 145.1113,
    accuracy: 4,
  },
  {
    postcode: 4895,
    place_name: 'Wujal Wujal',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.9456,
    longitude: 145.3185,
    accuracy: 4,
  },
  {
    postcode: 4895,
    place_name: 'Cooktown',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.4678,
    longitude: 145.2487,
    accuracy: 4,
  },
  {
    postcode: 4895,
    place_name: 'Ayton',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.9216,
    longitude: 145.3525,
    accuracy: 4,
  },
  {
    postcode: 4895,
    place_name: 'Starcke',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7071,
    longitude: 145.2665,
    accuracy: 3,
  },
  {
    postcode: 4895,
    place_name: 'Rossville',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.6667,
    longitude: 145.2667,
    accuracy: 4,
  },
  {
    postcode: 4895,
    place_name: 'Bloomfield',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.9333,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 4895,
    place_name: 'Helenvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -15.7167,
    longitude: 145.2333,
    accuracy: 4,
  },
  {
    postcode: 9000,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9001,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9002,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9005,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9007,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9008,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9009,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9010,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9013,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9015,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9016,
    place_name: 'Brisbane GPO Boxes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 1,
  },
  {
    postcode: 9017,
    place_name: 'Brisbane GPO Boxes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 1,
  },
  {
    postcode: 9018,
    place_name: 'Brisbane GPO Boxes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 1,
  },
  {
    postcode: 9019,
    place_name: 'Brisbane GPO Boxes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 1,
  },
  {
    postcode: 9020,
    place_name: 'Brisbane',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 4,
  },
  {
    postcode: 9021,
    place_name: 'Brisbane GPO Boxes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 1,
  },
  {
    postcode: 9022,
    place_name: 'Brisbane GPO Boxes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 1,
  },
  {
    postcode: 9023,
    place_name: 'Brisbane GPO Boxes',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -27.4679,
    longitude: 153.0281,
    accuracy: 1,
  },
  {
    postcode: 9464,
    place_name: 'Northgate Mc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -33.8816,
    longitude: 150.9844,
    accuracy: null,
  },
  {
    postcode: 9726,
    place_name: 'Gold Coast Mc',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -33.8816,
    longitude: 150.9844,
    accuracy: null,
  },
  {
    postcode: 9943,
    place_name: 'Mackay',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -21.1534,
    longitude: 149.1655,
    accuracy: 4,
  },
  {
    postcode: 9944,
    place_name: 'Cannonvale',
    state_name: 'Queensland',
    state_code: 'QLD',
    latitude: -20.2768,
    longitude: 148.6976,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Nyapari',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Fregon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -26.7639,
    longitude: 132.0277,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Mimili',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Indulkana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -26.96,
    longitude: 133.3058,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Ernabella',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -23.7917,
    longitude: 132.6818,
    accuracy: 3,
  },
  {
    postcode: 5000,
    place_name: 'Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 4,
  },
  {
    postcode: 5000,
    place_name: 'Rundle Mall',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9286,
    longitude: 138.6007,
    accuracy: 3,
  },
  {
    postcode: 5000,
    place_name: 'Hutt Street',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9286,
    longitude: 138.6007,
    accuracy: 3,
  },
  {
    postcode: 5000,
    place_name: 'Halifax Street',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9286,
    longitude: 138.6007,
    accuracy: 3,
  },
  {
    postcode: 5000,
    place_name: 'Station Arcade',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9286,
    longitude: 138.6007,
    accuracy: 3,
  },
  {
    postcode: 5000,
    place_name: 'Adelaide Bc',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9286,
    longitude: 138.6007,
    accuracy: 3,
  },
  {
    postcode: 5000,
    place_name: 'City West Campus',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 3,
  },
  {
    postcode: 5000,
    place_name: 'Sturt Street',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 3,
  },
  {
    postcode: 5001,
    place_name: 'Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 4,
  },
  {
    postcode: 5005,
    place_name: 'University Of Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9006,
    longitude: 138.5702,
    accuracy: 1,
  },
  {
    postcode: 5005,
    place_name: 'The University Of Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9006,
    longitude: 138.5702,
    accuracy: 1,
  },
  {
    postcode: 5005,
    place_name: 'Adelaide University',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9006,
    longitude: 138.5702,
    accuracy: 1,
  },
  {
    postcode: 5006,
    place_name: 'North Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9073,
    longitude: 138.5914,
    accuracy: 4,
  },
  {
    postcode: 5006,
    place_name: 'North Adelaide Melbourne St',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9006,
    longitude: 138.5702,
    accuracy: 3,
  },
  {
    postcode: 5007,
    place_name: 'Welland',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9,
    longitude: 138.5333,
    accuracy: 4,
  },
  {
    postcode: 5007,
    place_name: 'West Hindmarsh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9079,
    longitude: 138.5641,
    accuracy: 4,
  },
  {
    postcode: 5007,
    place_name: 'Hindmarsh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9014,
    longitude: 138.575,
    accuracy: 4,
  },
  {
    postcode: 5007,
    place_name: 'Brompton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8944,
    longitude: 138.5782,
    accuracy: 4,
  },
  {
    postcode: 5007,
    place_name: 'Bowden',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9026,
    longitude: 138.5791,
    accuracy: 4,
  },
  {
    postcode: 5008,
    place_name: 'Dudley Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8756,
    longitude: 138.5773,
    accuracy: 4,
  },
  {
    postcode: 5008,
    place_name: 'West Croydon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8945,
    longitude: 138.5593,
    accuracy: 4,
  },
  {
    postcode: 5008,
    place_name: 'Croydon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9006,
    longitude: 138.5702,
    accuracy: 3,
  },
  {
    postcode: 5008,
    place_name: 'Devon Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8865,
    longitude: 138.5823,
    accuracy: 4,
  },
  {
    postcode: 5008,
    place_name: 'Renown Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8917,
    longitude: 138.5773,
    accuracy: 4,
  },
  {
    postcode: 5008,
    place_name: 'Ridleyton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8928,
    longitude: 138.5716,
    accuracy: 4,
  },
  {
    postcode: 5008,
    place_name: 'Croydon Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8803,
    longitude: 138.5678,
    accuracy: 4,
  },
  {
    postcode: 5009,
    place_name: 'Kilkenny',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8791,
    longitude: 138.5529,
    accuracy: 4,
  },
  {
    postcode: 5009,
    place_name: 'Beverley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8951,
    longitude: 138.5463,
    accuracy: 4,
  },
  {
    postcode: 5009,
    place_name: 'Allenby Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8977,
    longitude: 138.5542,
    accuracy: 4,
  },
  {
    postcode: 5010,
    place_name: 'Regency Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8626,
    longitude: 138.5724,
    accuracy: 4,
  },
  {
    postcode: 5010,
    place_name: 'Regency Park Bc',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8626,
    longitude: 138.5678,
    accuracy: 3,
  },
  {
    postcode: 5010,
    place_name: 'Ferryden Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8662,
    longitude: 138.5618,
    accuracy: 4,
  },
  {
    postcode: 5010,
    place_name: 'Angle Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8599,
    longitude: 138.558,
    accuracy: 4,
  },
  {
    postcode: 5011,
    place_name: 'Woodville Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.887,
    longitude: 138.5465,
    accuracy: 4,
  },
  {
    postcode: 5011,
    place_name: 'St Clair',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8833,
    longitude: 138.55,
    accuracy: 3,
  },
  {
    postcode: 5011,
    place_name: 'Woodville South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8819,
    longitude: 138.5348,
    accuracy: 4,
  },
  {
    postcode: 5011,
    place_name: 'Woodville West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8864,
    longitude: 138.5309,
    accuracy: 4,
  },
  {
    postcode: 5011,
    place_name: 'Woodville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8833,
    longitude: 138.55,
    accuracy: 4,
  },
  {
    postcode: 5012,
    place_name: 'Woodville Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8679,
    longitude: 138.548,
    accuracy: 4,
  },
  {
    postcode: 5012,
    place_name: 'Mansfield Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8566,
    longitude: 138.5519,
    accuracy: 4,
  },
  {
    postcode: 5012,
    place_name: 'Woodville North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.872,
    longitude: 138.5431,
    accuracy: 4,
  },
  {
    postcode: 5012,
    place_name: 'Athol Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8561,
    longitude: 138.5428,
    accuracy: 4,
  },
  {
    postcode: 5013,
    place_name: 'Rosewater East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8391,
    longitude: 138.5496,
    accuracy: 3,
  },
  {
    postcode: 5013,
    place_name: 'Gillman',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8409,
    longitude: 138.5282,
    accuracy: 4,
  },
  {
    postcode: 5013,
    place_name: 'Ottoway',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8476,
    longitude: 138.5357,
    accuracy: 4,
  },
  {
    postcode: 5013,
    place_name: 'Pennington',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8576,
    longitude: 138.5316,
    accuracy: 4,
  },
  {
    postcode: 5013,
    place_name: 'Rosewater',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.852,
    longitude: 138.5216,
    accuracy: 4,
  },
  {
    postcode: 5013,
    place_name: 'Wingfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8498,
    longitude: 138.5674,
    accuracy: 4,
  },
  {
    postcode: 5014,
    place_name: 'Cheltenham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8667,
    longitude: 138.5167,
    accuracy: 4,
  },
  {
    postcode: 5014,
    place_name: 'Queenstown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8609,
    longitude: 138.5105,
    accuracy: 4,
  },
  {
    postcode: 5014,
    place_name: 'Hendon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9,
    longitude: 138.5167,
    accuracy: 4,
  },
  {
    postcode: 5014,
    place_name: 'Royal Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8742,
    longitude: 138.5119,
    accuracy: 4,
  },
  {
    postcode: 5014,
    place_name: 'Albert Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.872,
    longitude: 138.5197,
    accuracy: 4,
  },
  {
    postcode: 5014,
    place_name: 'Alberton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8593,
    longitude: 138.5214,
    accuracy: 4,
  },
  {
    postcode: 5015,
    place_name: 'Port Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.85,
    longitude: 138.4667,
    accuracy: 4,
  },
  {
    postcode: 5015,
    place_name: 'Ethelton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8487,
    longitude: 138.491,
    accuracy: 4,
  },
  {
    postcode: 5015,
    place_name: 'Glanville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8462,
    longitude: 138.4898,
    accuracy: 4,
  },
  {
    postcode: 5015,
    place_name: 'New Port',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8417,
    longitude: 138.4833,
    accuracy: 3,
  },
  {
    postcode: 5015,
    place_name: 'Birkenhead',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8368,
    longitude: 138.4979,
    accuracy: 4,
  },
  {
    postcode: 5015,
    place_name: 'Port Adelaide DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8417,
    longitude: 138.4833,
    accuracy: 3,
  },
  {
    postcode: 5016,
    place_name: 'Peterhead',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8336,
    longitude: 138.491,
    accuracy: 4,
  },
  {
    postcode: 5016,
    place_name: 'Largs North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8205,
    longitude: 138.4924,
    accuracy: 4,
  },
  {
    postcode: 5016,
    place_name: 'Largs Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8258,
    longitude: 138.4862,
    accuracy: 4,
  },
  {
    postcode: 5017,
    place_name: 'Osborne',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.798,
    longitude: 138.498,
    accuracy: 4,
  },
  {
    postcode: 5017,
    place_name: 'Taperoo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8043,
    longitude: 138.4959,
    accuracy: 4,
  },
  {
    postcode: 5018,
    place_name: 'Outer Harbor',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7766,
    longitude: 138.4874,
    accuracy: 4,
  },
  {
    postcode: 5018,
    place_name: 'North Haven',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7883,
    longitude: 138.4916,
    accuracy: 4,
  },
  {
    postcode: 5019,
    place_name: 'Semaphore',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8393,
    longitude: 138.4823,
    accuracy: 4,
  },
  {
    postcode: 5019,
    place_name: 'Semaphore Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8507,
    longitude: 138.4789,
    accuracy: 4,
  },
  {
    postcode: 5019,
    place_name: 'Semaphore South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8498,
    longitude: 138.4795,
    accuracy: 4,
  },
  {
    postcode: 5019,
    place_name: 'Exeter',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8399,
    longitude: 138.489,
    accuracy: 4,
  },
  {
    postcode: 5020,
    place_name: 'West Lakes Shore',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8601,
    longitude: 138.4897,
    accuracy: 4,
  },
  {
    postcode: 5021,
    place_name: 'West Lakes',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.873,
    longitude: 138.4946,
    accuracy: 4,
  },
  {
    postcode: 5022,
    place_name: 'Henley Beach South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9259,
    longitude: 138.4955,
    accuracy: 4,
  },
  {
    postcode: 5022,
    place_name: 'Henley Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9203,
    longitude: 138.4944,
    accuracy: 4,
  },
  {
    postcode: 5022,
    place_name: 'Tennyson',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.886,
    longitude: 138.4859,
    accuracy: 4,
  },
  {
    postcode: 5022,
    place_name: 'Grange',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9022,
    longitude: 138.4902,
    accuracy: 4,
  },
  {
    postcode: 5023,
    place_name: 'Seaton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9,
    longitude: 138.5167,
    accuracy: 4,
  },
  {
    postcode: 5023,
    place_name: 'Findon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9,
    longitude: 138.5333,
    accuracy: 4,
  },
  {
    postcode: 5024,
    place_name: 'West Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.946,
    longitude: 138.5093,
    accuracy: 4,
  },
  {
    postcode: 5024,
    place_name: 'Fulham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9274,
    longitude: 138.5137,
    accuracy: 4,
  },
  {
    postcode: 5024,
    place_name: 'Fulham Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9148,
    longitude: 138.5132,
    accuracy: 4,
  },
  {
    postcode: 5025,
    place_name: 'Flinders Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9103,
    longitude: 138.5432,
    accuracy: 4,
  },
  {
    postcode: 5025,
    place_name: 'Kidman Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9122,
    longitude: 138.5269,
    accuracy: 4,
  },
  {
    postcode: 5031,
    place_name: 'Torrensville Plaza',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9441,
    longitude: 138.5648,
    accuracy: 3,
  },
  {
    postcode: 5031,
    place_name: 'Mile End',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9265,
    longitude: 138.5703,
    accuracy: 4,
  },
  {
    postcode: 5031,
    place_name: 'Torrensville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9106,
    longitude: 138.5605,
    accuracy: 4,
  },
  {
    postcode: 5031,
    place_name: 'Mile End South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9378,
    longitude: 138.5704,
    accuracy: 4,
  },
  {
    postcode: 5031,
    place_name: 'Thebarton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9167,
    longitude: 138.5667,
    accuracy: 4,
  },
  {
    postcode: 5032,
    place_name: 'Lockleys',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9259,
    longitude: 138.528,
    accuracy: 4,
  },
  {
    postcode: 5032,
    place_name: 'Brooklyn Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9285,
    longitude: 138.5363,
    accuracy: 4,
  },
  {
    postcode: 5032,
    place_name: 'Underdale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.918,
    longitude: 138.547,
    accuracy: 4,
  },
  {
    postcode: 5033,
    place_name: 'Marleston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9476,
    longitude: 138.5596,
    accuracy: 4,
  },
  {
    postcode: 5033,
    place_name: 'Hilton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.932,
    longitude: 138.5643,
    accuracy: 4,
  },
  {
    postcode: 5033,
    place_name: 'Marleston DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9355,
    longitude: 138.5617,
    accuracy: 3,
  },
  {
    postcode: 5033,
    place_name: 'Cowandilla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9322,
    longitude: 138.5593,
    accuracy: 4,
  },
  {
    postcode: 5033,
    place_name: 'West Richmond',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9387,
    longitude: 138.5521,
    accuracy: 4,
  },
  {
    postcode: 5033,
    place_name: 'Hilton Plaza',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9441,
    longitude: 138.5648,
    accuracy: 3,
  },
  {
    postcode: 5033,
    place_name: 'Richmond',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.942,
    longitude: 138.5632,
    accuracy: 4,
  },
  {
    postcode: 5034,
    place_name: 'Wayville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.95,
    longitude: 138.5833,
    accuracy: 4,
  },
  {
    postcode: 5034,
    place_name: 'Millswood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.955,
    longitude: 138.5889,
    accuracy: 4,
  },
  {
    postcode: 5034,
    place_name: 'Goodwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9509,
    longitude: 138.5923,
    accuracy: 4,
  },
  {
    postcode: 5034,
    place_name: 'Kings Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9638,
    longitude: 138.5909,
    accuracy: 4,
  },
  {
    postcode: 5034,
    place_name: 'Clarence Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9642,
    longitude: 138.5806,
    accuracy: 4,
  },
  {
    postcode: 5035,
    place_name: 'Black Forest',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9603,
    longitude: 138.5767,
    accuracy: 4,
  },
  {
    postcode: 5035,
    place_name: 'Keswick',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9418,
    longitude: 138.5746,
    accuracy: 4,
  },
  {
    postcode: 5035,
    place_name: 'Forestville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9495,
    longitude: 138.5785,
    accuracy: 4,
  },
  {
    postcode: 5035,
    place_name: 'Ashford',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9486,
    longitude: 138.5746,
    accuracy: 4,
  },
  {
    postcode: 5035,
    place_name: 'Everard Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.952,
    longitude: 138.5748,
    accuracy: 4,
  },
  {
    postcode: 5035,
    place_name: 'Keswick Terminal',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9411,
    longitude: 138.5804,
    accuracy: 4,
  },
  {
    postcode: 5037,
    place_name: 'Kurralta Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9514,
    longitude: 138.567,
    accuracy: 4,
  },
  {
    postcode: 5037,
    place_name: 'North Plympton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9577,
    longitude: 138.5534,
    accuracy: 4,
  },
  {
    postcode: 5037,
    place_name: 'Netley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9489,
    longitude: 138.5498,
    accuracy: 4,
  },
  {
    postcode: 5037,
    place_name: 'Glandore',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9615,
    longitude: 138.5683,
    accuracy: 4,
  },
  {
    postcode: 5038,
    place_name: 'Camden Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9686,
    longitude: 138.5396,
    accuracy: 4,
  },
  {
    postcode: 5038,
    place_name: 'Plympton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.95,
    longitude: 138.55,
    accuracy: 4,
  },
  {
    postcode: 5038,
    place_name: 'Plympton Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9809,
    longitude: 138.547,
    accuracy: 4,
  },
  {
    postcode: 5038,
    place_name: 'South Plympton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9768,
    longitude: 138.5559,
    accuracy: 4,
  },
  {
    postcode: 5039,
    place_name: 'Melrose Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9833,
    longitude: 138.5754,
    accuracy: 4,
  },
  {
    postcode: 5039,
    place_name: 'Clarence Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9754,
    longitude: 138.5798,
    accuracy: 4,
  },
  {
    postcode: 5039,
    place_name: 'Edwardstown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9802,
    longitude: 138.5694,
    accuracy: 4,
  },
  {
    postcode: 5039,
    place_name: 'Melrose Park DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9827,
    longitude: 138.5741,
    accuracy: 3,
  },
  {
    postcode: 5040,
    place_name: 'Novar Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9733,
    longitude: 138.5383,
    accuracy: 4,
  },
  {
    postcode: 5041,
    place_name: 'Cumberland Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.972,
    longitude: 138.5867,
    accuracy: 4,
  },
  {
    postcode: 5041,
    place_name: 'Westbourne Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9716,
    longitude: 138.5952,
    accuracy: 4,
  },
  {
    postcode: 5041,
    place_name: 'Daw Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9898,
    longitude: 138.5841,
    accuracy: 4,
  },
  {
    postcode: 5041,
    place_name: 'Panorama',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9908,
    longitude: 138.5995,
    accuracy: 4,
  },
  {
    postcode: 5041,
    place_name: 'Colonel Light Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9863,
    longitude: 138.5975,
    accuracy: 4,
  },
  {
    postcode: 5042,
    place_name: 'St Marys',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0042,
    longitude: 138.5798,
    accuracy: 4,
  },
  {
    postcode: 5042,
    place_name: 'Bedford Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0333,
    longitude: 138.5667,
    accuracy: 4,
  },
  {
    postcode: 5042,
    place_name: 'Pasadena',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0027,
    longitude: 138.5886,
    accuracy: 4,
  },
  {
    postcode: 5042,
    place_name: 'Clovelly Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9995,
    longitude: 138.5708,
    accuracy: 4,
  },
  {
    postcode: 5042,
    place_name: 'Flinders University',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0069,
    longitude: 138.5513,
    accuracy: 3,
  },
  {
    postcode: 5043,
    place_name: 'Morphettville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9871,
    longitude: 138.544,
    accuracy: 4,
  },
  {
    postcode: 5043,
    place_name: 'Marion',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0167,
    longitude: 138.5667,
    accuracy: 4,
  },
  {
    postcode: 5043,
    place_name: 'Mitchell Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0051,
    longitude: 138.5623,
    accuracy: 4,
  },
  {
    postcode: 5043,
    place_name: 'Park Holme',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9888,
    longitude: 138.552,
    accuracy: 4,
  },
  {
    postcode: 5043,
    place_name: 'Ascot Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9881,
    longitude: 138.561,
    accuracy: 4,
  },
  {
    postcode: 5044,
    place_name: 'Somerton Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9971,
    longitude: 138.5215,
    accuracy: 4,
  },
  {
    postcode: 5044,
    place_name: 'Glengowrie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9871,
    longitude: 138.5367,
    accuracy: 4,
  },
  {
    postcode: 5045,
    place_name: 'Glenelg Jetty Road',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9833,
    longitude: 138.5167,
    accuracy: 3,
  },
  {
    postcode: 5045,
    place_name: 'Glenelg East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9764,
    longitude: 138.5296,
    accuracy: 4,
  },
  {
    postcode: 5045,
    place_name: 'Glenelg North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9679,
    longitude: 138.5175,
    accuracy: 4,
  },
  {
    postcode: 5045,
    place_name: 'Glenelg South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9808,
    longitude: 138.515,
    accuracy: 3,
  },
  {
    postcode: 5045,
    place_name: 'Glenelg',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9808,
    longitude: 138.515,
    accuracy: 4,
  },
  {
    postcode: 5046,
    place_name: 'Oaklands Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0141,
    longitude: 138.5384,
    accuracy: 4,
  },
  {
    postcode: 5046,
    place_name: 'Warradale North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0069,
    longitude: 138.5513,
    accuracy: 3,
  },
  {
    postcode: 5046,
    place_name: 'Warradale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0074,
    longitude: 138.5356,
    accuracy: 4,
  },
  {
    postcode: 5047,
    place_name: 'Seacombe Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0326,
    longitude: 138.5458,
    accuracy: 4,
  },
  {
    postcode: 5047,
    place_name: 'Seacombe Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0234,
    longitude: 138.5458,
    accuracy: 4,
  },
  {
    postcode: 5047,
    place_name: 'Darlington',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0333,
    longitude: 138.5667,
    accuracy: 4,
  },
  {
    postcode: 5047,
    place_name: 'Sturt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0167,
    longitude: 138.55,
    accuracy: 4,
  },
  {
    postcode: 5048,
    place_name: 'South Brighton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0277,
    longitude: 138.528,
    accuracy: 4,
  },
  {
    postcode: 5048,
    place_name: 'North Brighton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0037,
    longitude: 138.5218,
    accuracy: 4,
  },
  {
    postcode: 5048,
    place_name: 'Brighton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0182,
    longitude: 138.5235,
    accuracy: 4,
  },
  {
    postcode: 5048,
    place_name: 'Hove',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0112,
    longitude: 138.523,
    accuracy: 4,
  },
  {
    postcode: 5048,
    place_name: 'Dover Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.024,
    longitude: 138.537,
    accuracy: 4,
  },
  {
    postcode: 5049,
    place_name: 'Seacliff Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0298,
    longitude: 138.5313,
    accuracy: 4,
  },
  {
    postcode: 5049,
    place_name: 'Seaview Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.037,
    longitude: 138.538,
    accuracy: 4,
  },
  {
    postcode: 5049,
    place_name: 'Kingston Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0382,
    longitude: 138.5163,
    accuracy: 4,
  },
  {
    postcode: 5049,
    place_name: 'Marino',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0447,
    longitude: 138.5124,
    accuracy: 4,
  },
  {
    postcode: 5049,
    place_name: 'Seacliff',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0347,
    longitude: 138.5229,
    accuracy: 4,
  },
  {
    postcode: 5050,
    place_name: 'Eden Hills',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0211,
    longitude: 138.5957,
    accuracy: 4,
  },
  {
    postcode: 5050,
    place_name: 'Bellevue Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0302,
    longitude: 138.5831,
    accuracy: 4,
  },
  {
    postcode: 5051,
    place_name: 'Blackwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0214,
    longitude: 138.6143,
    accuracy: 4,
  },
  {
    postcode: 5051,
    place_name: 'Coromandel Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.05,
    longitude: 138.6167,
    accuracy: 4,
  },
  {
    postcode: 5051,
    place_name: 'Hawthorndene',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0223,
    longitude: 138.6304,
    accuracy: 4,
  },
  {
    postcode: 5051,
    place_name: 'Craigburn Farm',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.05,
    longitude: 138.6167,
    accuracy: 4,
  },
  {
    postcode: 5052,
    place_name: 'Belair',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9977,
    longitude: 138.6208,
    accuracy: 4,
  },
  {
    postcode: 5052,
    place_name: 'Glenalta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0079,
    longitude: 138.6259,
    accuracy: 4,
  },
  {
    postcode: 5061,
    place_name: 'Malvern',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9592,
    longitude: 138.6125,
    accuracy: 4,
  },
  {
    postcode: 5061,
    place_name: 'Unley DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9546,
    longitude: 138.6063,
    accuracy: 3,
  },
  {
    postcode: 5061,
    place_name: 'Unley Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9639,
    longitude: 138.6078,
    accuracy: 4,
  },
  {
    postcode: 5061,
    place_name: 'Unley BC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9546,
    longitude: 138.6063,
    accuracy: 3,
  },
  {
    postcode: 5061,
    place_name: 'Unley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.95,
    longitude: 138.6,
    accuracy: 4,
  },
  {
    postcode: 5061,
    place_name: 'Hyde Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9547,
    longitude: 138.6011,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Kingswood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9671,
    longitude: 138.6077,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Hawthorn',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9738,
    longitude: 138.6074,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Netherby',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9705,
    longitude: 138.625,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Brown Hill Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9868,
    longitude: 138.6421,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Springfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9782,
    longitude: 138.6325,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Lower Mitcham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9773,
    longitude: 138.6049,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Mitcham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9789,
    longitude: 138.6218,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Torrens Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9761,
    longitude: 138.6103,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Clapham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9899,
    longitude: 138.6016,
    accuracy: 4,
  },
  {
    postcode: 5062,
    place_name: 'Mitcham Shopping Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9788,
    longitude: 138.6166,
    accuracy: 3,
  },
  {
    postcode: 5062,
    place_name: 'Lynton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9973,
    longitude: 138.6074,
    accuracy: 4,
  },
  {
    postcode: 5063,
    place_name: 'Parkside',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9451,
    longitude: 138.613,
    accuracy: 4,
  },
  {
    postcode: 5063,
    place_name: 'Frewville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9476,
    longitude: 138.6306,
    accuracy: 4,
  },
  {
    postcode: 5063,
    place_name: 'Eastwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9423,
    longitude: 138.6218,
    accuracy: 4,
  },
  {
    postcode: 5063,
    place_name: 'Fullarton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.951,
    longitude: 138.629,
    accuracy: 4,
  },
  {
    postcode: 5063,
    place_name: 'Highgate',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9618,
    longitude: 138.6218,
    accuracy: 4,
  },
  {
    postcode: 5064,
    place_name: 'Mount Osmond',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9629,
    longitude: 138.6566,
    accuracy: 4,
  },
  {
    postcode: 5064,
    place_name: 'Urrbrae',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9663,
    longitude: 138.6422,
    accuracy: 4,
  },
  {
    postcode: 5064,
    place_name: 'Glen Osmond',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9596,
    longitude: 138.6487,
    accuracy: 4,
  },
  {
    postcode: 5064,
    place_name: 'Myrtle Bank',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9606,
    longitude: 138.6338,
    accuracy: 4,
  },
  {
    postcode: 5064,
    place_name: 'St Georges',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.952,
    longitude: 138.6479,
    accuracy: 4,
  },
  {
    postcode: 5064,
    place_name: 'Glenunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9501,
    longitude: 138.6384,
    accuracy: 4,
  },
  {
    postcode: 5065,
    place_name: 'Linden Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9473,
    longitude: 138.6529,
    accuracy: 4,
  },
  {
    postcode: 5065,
    place_name: 'Tusmore',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9389,
    longitude: 138.6506,
    accuracy: 4,
  },
  {
    postcode: 5065,
    place_name: 'Dulwich',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9365,
    longitude: 138.6272,
    accuracy: 4,
  },
  {
    postcode: 5065,
    place_name: 'Glenside',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9427,
    longitude: 138.6338,
    accuracy: 4,
  },
  {
    postcode: 5065,
    place_name: 'Toorak Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9348,
    longitude: 138.6364,
    accuracy: 4,
  },
  {
    postcode: 5066,
    place_name: 'Stonyfell',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9342,
    longitude: 138.6767,
    accuracy: 4,
  },
  {
    postcode: 5066,
    place_name: 'Burnside',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9383,
    longitude: 138.6673,
    accuracy: 3,
  },
  {
    postcode: 5066,
    place_name: 'Erindale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9278,
    longitude: 138.6631,
    accuracy: 4,
  },
  {
    postcode: 5066,
    place_name: 'Beaumont',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.949,
    longitude: 138.6619,
    accuracy: 4,
  },
  {
    postcode: 5066,
    place_name: 'Waterfall Gully',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9667,
    longitude: 138.679,
    accuracy: 4,
  },
  {
    postcode: 5066,
    place_name: 'Wattle Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9252,
    longitude: 138.675,
    accuracy: 4,
  },
  {
    postcode: 5066,
    place_name: 'Hazelwood Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9395,
    longitude: 138.6588,
    accuracy: 4,
  },
  {
    postcode: 5067,
    place_name: 'Beulah Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9177,
    longitude: 138.6452,
    accuracy: 4,
  },
  {
    postcode: 5067,
    place_name: 'Rose Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9276,
    longitude: 138.626,
    accuracy: 4,
  },
  {
    postcode: 5067,
    place_name: 'Norwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9273,
    longitude: 138.6285,
    accuracy: 4,
  },
  {
    postcode: 5067,
    place_name: 'Kent Town',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9216,
    longitude: 138.6201,
    accuracy: 4,
  },
  {
    postcode: 5067,
    place_name: 'Norwood South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9167,
    longitude: 138.65,
    accuracy: 3,
  },
  {
    postcode: 5068,
    place_name: 'Kensington Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9258,
    longitude: 138.6615,
    accuracy: 4,
  },
  {
    postcode: 5068,
    place_name: 'Heathpool',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9314,
    longitude: 138.6463,
    accuracy: 4,
  },
  {
    postcode: 5068,
    place_name: 'Marryatville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9284,
    longitude: 138.646,
    accuracy: 4,
  },
  {
    postcode: 5068,
    place_name: 'Leabrook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9298,
    longitude: 138.6586,
    accuracy: 4,
  },
  {
    postcode: 5068,
    place_name: 'Kensington Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9202,
    longitude: 138.6543,
    accuracy: 4,
  },
  {
    postcode: 5068,
    place_name: 'Trinity Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9144,
    longitude: 138.6414,
    accuracy: 4,
  },
  {
    postcode: 5068,
    place_name: 'St Morris',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9111,
    longitude: 138.6536,
    accuracy: 4,
  },
  {
    postcode: 5068,
    place_name: 'Kensington',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9167,
    longitude: 138.65,
    accuracy: 4,
  },
  {
    postcode: 5069,
    place_name: 'Stepney',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9123,
    longitude: 138.6292,
    accuracy: 4,
  },
  {
    postcode: 5069,
    place_name: 'College Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9117,
    longitude: 138.6198,
    accuracy: 4,
  },
  {
    postcode: 5069,
    place_name: 'St Peters',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.905,
    longitude: 138.6225,
    accuracy: 4,
  },
  {
    postcode: 5069,
    place_name: 'Hackney',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9139,
    longitude: 138.6164,
    accuracy: 4,
  },
  {
    postcode: 5069,
    place_name: 'Evandale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9055,
    longitude: 138.6354,
    accuracy: 4,
  },
  {
    postcode: 5069,
    place_name: 'Maylands',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9113,
    longitude: 138.6367,
    accuracy: 4,
  },
  {
    postcode: 5070,
    place_name: 'Payneham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8972,
    longitude: 138.6406,
    accuracy: 4,
  },
  {
    postcode: 5070,
    place_name: 'Glynde DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8988,
    longitude: 138.6447,
    accuracy: 3,
  },
  {
    postcode: 5070,
    place_name: 'Royston Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9004,
    longitude: 138.6361,
    accuracy: 4,
  },
  {
    postcode: 5070,
    place_name: 'Glynde Plaza',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8994,
    longitude: 138.6696,
    accuracy: 3,
  },
  {
    postcode: 5070,
    place_name: 'Felixstow',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.889,
    longitude: 138.647,
    accuracy: 4,
  },
  {
    postcode: 5070,
    place_name: 'Joslin',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8997,
    longitude: 138.6265,
    accuracy: 4,
  },
  {
    postcode: 5070,
    place_name: 'Glynde',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.896,
    longitude: 138.6525,
    accuracy: 4,
  },
  {
    postcode: 5070,
    place_name: 'Firle',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9046,
    longitude: 138.6532,
    accuracy: 4,
  },
  {
    postcode: 5070,
    place_name: 'Marden',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8957,
    longitude: 138.6378,
    accuracy: 4,
  },
  {
    postcode: 5070,
    place_name: 'Payneham South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9048,
    longitude: 138.6445,
    accuracy: 4,
  },
  {
    postcode: 5071,
    place_name: 'Kent Town DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9216,
    longitude: 138.6201,
    accuracy: 3,
  },
  {
    postcode: 5071,
    place_name: 'Kent Town',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9216,
    longitude: 138.6201,
    accuracy: 4,
  },
  {
    postcode: 5072,
    place_name: 'Magill South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9091,
    longitude: 138.675,
    accuracy: 4,
  },
  {
    postcode: 5072,
    place_name: 'Rosslyn Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9219,
    longitude: 138.6755,
    accuracy: 4,
  },
  {
    postcode: 5072,
    place_name: 'Skye',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9245,
    longitude: 138.6928,
    accuracy: 4,
  },
  {
    postcode: 5072,
    place_name: 'Magill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9091,
    longitude: 138.675,
    accuracy: 4,
  },
  {
    postcode: 5072,
    place_name: 'Auldana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.918,
    longitude: 138.6869,
    accuracy: 4,
  },
  {
    postcode: 5072,
    place_name: 'Woodforde',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9051,
    longitude: 138.6896,
    accuracy: 4,
  },
  {
    postcode: 5072,
    place_name: 'Magill North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8994,
    longitude: 138.6696,
    accuracy: 3,
  },
  {
    postcode: 5072,
    place_name: 'Teringie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9143,
    longitude: 138.7023,
    accuracy: 4,
  },
  {
    postcode: 5073,
    place_name: 'Tranmere',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9055,
    longitude: 138.6621,
    accuracy: 4,
  },
  {
    postcode: 5073,
    place_name: 'Tranmere North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8994,
    longitude: 138.6696,
    accuracy: 3,
  },
  {
    postcode: 5073,
    place_name: 'Rostrevor',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8939,
    longitude: 138.6918,
    accuracy: 4,
  },
  {
    postcode: 5073,
    place_name: 'Hectorville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8916,
    longitude: 138.6589,
    accuracy: 4,
  },
  {
    postcode: 5074,
    place_name: 'Newton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8821,
    longitude: 138.6825,
    accuracy: 4,
  },
  {
    postcode: 5074,
    place_name: 'Campbelltown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8833,
    longitude: 138.6667,
    accuracy: 4,
  },
  {
    postcode: 5075,
    place_name: 'Paradise',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8736,
    longitude: 138.669,
    accuracy: 4,
  },
  {
    postcode: 5075,
    place_name: 'Dernancourt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.862,
    longitude: 138.6766,
    accuracy: 4,
  },
  {
    postcode: 5076,
    place_name: 'Athelstone',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8702,
    longitude: 138.6999,
    accuracy: 4,
  },
  {
    postcode: 5076,
    place_name: 'Castambul',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8715,
    longitude: 138.7055,
    accuracy: 3,
  },
  {
    postcode: 5081,
    place_name: 'Gilberton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9005,
    longitude: 138.6126,
    accuracy: 4,
  },
  {
    postcode: 5081,
    place_name: 'Vale Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.886,
    longitude: 138.6271,
    accuracy: 4,
  },
  {
    postcode: 5081,
    place_name: 'Medindie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.896,
    longitude: 138.605,
    accuracy: 4,
  },
  {
    postcode: 5081,
    place_name: 'Walkerville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8711,
    longitude: 138.6232,
    accuracy: 3,
  },
  {
    postcode: 5081,
    place_name: 'Collinswood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8873,
    longitude: 138.6122,
    accuracy: 4,
  },
  {
    postcode: 5081,
    place_name: 'Medindie Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8909,
    longitude: 138.6039,
    accuracy: 4,
  },
  {
    postcode: 5082,
    place_name: 'Fitzroy',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8968,
    longitude: 138.5914,
    accuracy: 4,
  },
  {
    postcode: 5082,
    place_name: 'Prospect East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8845,
    longitude: 138.5925,
    accuracy: 3,
  },
  {
    postcode: 5082,
    place_name: 'Ovingham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8986,
    longitude: 138.5852,
    accuracy: 4,
  },
  {
    postcode: 5082,
    place_name: 'Thorngate',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8978,
    longitude: 138.5983,
    accuracy: 4,
  },
  {
    postcode: 5082,
    place_name: 'Prospect',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8824,
    longitude: 138.5933,
    accuracy: 4,
  },
  {
    postcode: 5083,
    place_name: 'Broadview',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8759,
    longitude: 138.615,
    accuracy: 4,
  },
  {
    postcode: 5083,
    place_name: 'Sefton Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.874,
    longitude: 138.6034,
    accuracy: 4,
  },
  {
    postcode: 5083,
    place_name: 'Nailsworth',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8844,
    longitude: 138.6062,
    accuracy: 4,
  },
  {
    postcode: 5084,
    place_name: 'Kilburn North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8595,
    longitude: 138.59,
    accuracy: 3,
  },
  {
    postcode: 5084,
    place_name: 'Kilburn',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8597,
    longitude: 138.5856,
    accuracy: 4,
  },
  {
    postcode: 5084,
    place_name: 'Blair Athol',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8589,
    longitude: 138.5966,
    accuracy: 4,
  },
  {
    postcode: 5085,
    place_name: 'Northgate',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8578,
    longitude: 138.6291,
    accuracy: 4,
  },
  {
    postcode: 5085,
    place_name: 'Northfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.85,
    longitude: 138.6333,
    accuracy: 4,
  },
  {
    postcode: 5085,
    place_name: 'Clearview',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8573,
    longitude: 138.6133,
    accuracy: 4,
  },
  {
    postcode: 5085,
    place_name: 'Enfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8526,
    longitude: 138.6026,
    accuracy: 4,
  },
  {
    postcode: 5085,
    place_name: 'Enfield Plaza',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8711,
    longitude: 138.6232,
    accuracy: 3,
  },
  {
    postcode: 5086,
    place_name: 'Oakden',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8528,
    longitude: 138.6433,
    accuracy: 4,
  },
  {
    postcode: 5086,
    place_name: 'Manningham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8711,
    longitude: 138.6232,
    accuracy: 3,
  },
  {
    postcode: 5086,
    place_name: 'Gilles Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.85,
    longitude: 138.65,
    accuracy: 4,
  },
  {
    postcode: 5086,
    place_name: 'Greenacres',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8685,
    longitude: 138.628,
    accuracy: 4,
  },
  {
    postcode: 5086,
    place_name: 'Hillcrest',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8625,
    longitude: 138.6431,
    accuracy: 4,
  },
  {
    postcode: 5086,
    place_name: 'Hampstead Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8745,
    longitude: 138.6288,
    accuracy: 4,
  },
  {
    postcode: 5087,
    place_name: 'Windsor Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8654,
    longitude: 138.647,
    accuracy: 4,
  },
  {
    postcode: 5087,
    place_name: 'Klemzig',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8793,
    longitude: 138.6356,
    accuracy: 4,
  },
  {
    postcode: 5088,
    place_name: 'Holden Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8516,
    longitude: 138.6723,
    accuracy: 4,
  },
  {
    postcode: 5089,
    place_name: 'Highbury',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8529,
    longitude: 138.6975,
    accuracy: 4,
  },
  {
    postcode: 5090,
    place_name: 'Hope Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8333,
    longitude: 138.7,
    accuracy: 4,
  },
  {
    postcode: 5091,
    place_name: 'Tea Tree Gully',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8216,
    longitude: 138.7353,
    accuracy: 3,
  },
  {
    postcode: 5091,
    place_name: 'Vista',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8355,
    longitude: 138.7232,
    accuracy: 4,
  },
  {
    postcode: 5091,
    place_name: 'Banksia Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8102,
    longitude: 138.7357,
    accuracy: 4,
  },
  {
    postcode: 5092,
    place_name: 'Modbury North DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8333,
    longitude: 138.6833,
    accuracy: 3,
  },
  {
    postcode: 5092,
    place_name: 'Modbury',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8333,
    longitude: 138.6833,
    accuracy: 4,
  },
  {
    postcode: 5092,
    place_name: 'Modbury North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8285,
    longitude: 138.6739,
    accuracy: 4,
  },
  {
    postcode: 5092,
    place_name: 'Modbury Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8097,
    longitude: 138.6854,
    accuracy: 4,
  },
  {
    postcode: 5093,
    place_name: 'Valley View',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8399,
    longitude: 138.6607,
    accuracy: 4,
  },
  {
    postcode: 5093,
    place_name: 'Para Vista',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8209,
    longitude: 138.6611,
    accuracy: 4,
  },
  {
    postcode: 5094,
    place_name: 'Gepps Cross',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8415,
    longitude: 138.5956,
    accuracy: 4,
  },
  {
    postcode: 5094,
    place_name: 'Dry Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8281,
    longitude: 138.5547,
    accuracy: 4,
  },
  {
    postcode: 5094,
    place_name: 'Cavan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8291,
    longitude: 138.5985,
    accuracy: 4,
  },
  {
    postcode: 5095,
    place_name: 'Pooraka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8247,
    longitude: 138.6264,
    accuracy: 4,
  },
  {
    postcode: 5095,
    place_name: 'The Levels',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8149,
    longitude: 138.6162,
    accuracy: 3,
  },
  {
    postcode: 5095,
    place_name: 'Mawson Lakes',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8159,
    longitude: 138.6081,
    accuracy: 4,
  },
  {
    postcode: 5096,
    place_name: 'Para Hills West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8047,
    longitude: 138.6428,
    accuracy: 4,
  },
  {
    postcode: 5096,
    place_name: 'Para Hills',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8121,
    longitude: 138.658,
    accuracy: 4,
  },
  {
    postcode: 5096,
    place_name: 'Gulfview Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7955,
    longitude: 138.6694,
    accuracy: 4,
  },
  {
    postcode: 5097,
    place_name: 'Redwood Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8116,
    longitude: 138.7069,
    accuracy: 4,
  },
  {
    postcode: 5097,
    place_name: 'St Agnes',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8308,
    longitude: 138.7107,
    accuracy: 4,
  },
  {
    postcode: 5097,
    place_name: 'Ridgehaven',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8213,
    longitude: 138.7047,
    accuracy: 4,
  },
  {
    postcode: 5098,
    place_name: 'Ingle Farm',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8333,
    longitude: 138.6333,
    accuracy: 4,
  },
  {
    postcode: 5098,
    place_name: 'Walkley Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8367,
    longitude: 138.6437,
    accuracy: 4,
  },
  {
    postcode: 5106,
    place_name: 'Parafield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.789,
    longitude: 138.6374,
    accuracy: 4,
  },
  {
    postcode: 5106,
    place_name: 'Salisbury South DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7746,
    longitude: 138.6221,
    accuracy: 3,
  },
  {
    postcode: 5106,
    place_name: 'Salisbury South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7805,
    longitude: 138.648,
    accuracy: 4,
  },
  {
    postcode: 5106,
    place_name: 'Salisbury South BC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7746,
    longitude: 138.6221,
    accuracy: 3,
  },
  {
    postcode: 5107,
    place_name: 'Parafield Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7825,
    longitude: 138.6108,
    accuracy: 4,
  },
  {
    postcode: 5107,
    place_name: 'Green Fields',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8006,
    longitude: 138.6067,
    accuracy: 4,
  },
  {
    postcode: 5108,
    place_name: 'Salisbury',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7667,
    longitude: 138.6333,
    accuracy: 4,
  },
  {
    postcode: 5108,
    place_name: 'Salisbury North Whites Road',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7619,
    longitude: 138.6208,
    accuracy: 3,
  },
  {
    postcode: 5108,
    place_name: 'Paralowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7571,
    longitude: 138.6082,
    accuracy: 4,
  },
  {
    postcode: 5108,
    place_name: 'Salisbury Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7677,
    longitude: 138.6221,
    accuracy: 4,
  },
  {
    postcode: 5108,
    place_name: 'Salisbury North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7496,
    longitude: 138.6206,
    accuracy: 4,
  },
  {
    postcode: 5109,
    place_name: 'Salisbury East Northbri Ave',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7874,
    longitude: 138.6417,
    accuracy: 3,
  },
  {
    postcode: 5109,
    place_name: 'Salisbury Plain',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7726,
    longitude: 138.6633,
    accuracy: 4,
  },
  {
    postcode: 5109,
    place_name: 'Salisbury East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7905,
    longitude: 138.6545,
    accuracy: 4,
  },
  {
    postcode: 5109,
    place_name: 'Salisbury Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7615,
    longitude: 138.6703,
    accuracy: 4,
  },
  {
    postcode: 5109,
    place_name: 'Salisbury Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7631,
    longitude: 138.6759,
    accuracy: 4,
  },
  {
    postcode: 5109,
    place_name: 'Brahma Lodge',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.776,
    longitude: 138.6531,
    accuracy: 4,
  },
  {
    postcode: 5110,
    place_name: 'Direk',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7216,
    longitude: 138.6015,
    accuracy: 4,
  },
  {
    postcode: 5110,
    place_name: 'Globe Derby Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7876,
    longitude: 138.5898,
    accuracy: 4,
  },
  {
    postcode: 5110,
    place_name: 'Waterloo Corner',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7167,
    longitude: 138.5833,
    accuracy: 4,
  },
  {
    postcode: 5110,
    place_name: 'St Kilda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7556,
    longitude: 138.5696,
    accuracy: 3,
  },
  {
    postcode: 5110,
    place_name: 'Bolivar',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7667,
    longitude: 138.6,
    accuracy: 4,
  },
  {
    postcode: 5110,
    place_name: 'Burton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7334,
    longitude: 138.6025,
    accuracy: 4,
  },
  {
    postcode: 5111,
    place_name: 'Edinburgh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7462,
    longitude: 138.6375,
    accuracy: 4,
  },
  {
    postcode: 5111,
    place_name: 'Edinburgh Raaf',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6697,
    longitude: 138.6878,
    accuracy: 3,
  },
  {
    postcode: 5112,
    place_name: 'Elizabeth Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7486,
    longitude: 138.6682,
    accuracy: 4,
  },
  {
    postcode: 5112,
    place_name: 'Hillbank',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7359,
    longitude: 138.6872,
    accuracy: 4,
  },
  {
    postcode: 5112,
    place_name: 'Elizabeth South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7318,
    longitude: 138.6619,
    accuracy: 4,
  },
  {
    postcode: 5112,
    place_name: 'Elizabeth Grove',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7353,
    longitude: 138.6689,
    accuracy: 4,
  },
  {
    postcode: 5112,
    place_name: 'Elizabeth',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7194,
    longitude: 138.6771,
    accuracy: 4,
  },
  {
    postcode: 5112,
    place_name: 'Elizabeth East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7251,
    longitude: 138.6821,
    accuracy: 4,
  },
  {
    postcode: 5113,
    place_name: 'Elizabeth Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6998,
    longitude: 138.6913,
    accuracy: 4,
  },
  {
    postcode: 5113,
    place_name: 'Davoren Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6906,
    longitude: 138.6686,
    accuracy: 4,
  },
  {
    postcode: 5113,
    place_name: 'Elizabeth West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7009,
    longitude: 138.678,
    accuracy: 3,
  },
  {
    postcode: 5113,
    place_name: 'Davoren Park South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6906,
    longitude: 138.6686,
    accuracy: 4,
  },
  {
    postcode: 5113,
    place_name: 'Elizabeth Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7097,
    longitude: 138.686,
    accuracy: 4,
  },
  {
    postcode: 5113,
    place_name: 'Elizabeth North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7068,
    longitude: 138.6761,
    accuracy: 4,
  },
  {
    postcode: 5113,
    place_name: 'Elizabeth West DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6906,
    longitude: 138.6686,
    accuracy: 3,
  },
  {
    postcode: 5113,
    place_name: 'Edinburgh North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6906,
    longitude: 138.6686,
    accuracy: 3,
  },
  {
    postcode: 5114,
    place_name: 'One Tree Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7,
    longitude: 138.7667,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Smithfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6833,
    longitude: 138.6833,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Smithfield West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6646,
    longitude: 138.6807,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Sampson Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7287,
    longitude: 138.7801,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Uleybury',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6763,
    longitude: 138.7459,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Gould Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7424,
    longitude: 138.7087,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Andrews Farm',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6763,
    longitude: 138.662,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Humbug Scrub',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7409,
    longitude: 138.8116,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Smithfield Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6764,
    longitude: 138.6779,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Yattalunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6847,
    longitude: 138.8037,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Blakeview',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6757,
    longitude: 138.7101,
    accuracy: 4,
  },
  {
    postcode: 5114,
    place_name: 'Craigmore',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7006,
    longitude: 138.7096,
    accuracy: 4,
  },
  {
    postcode: 5115,
    place_name: 'Munno Para',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6679,
    longitude: 138.7015,
    accuracy: 4,
  },
  {
    postcode: 5115,
    place_name: 'Kudla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6423,
    longitude: 138.7018,
    accuracy: 4,
  },
  {
    postcode: 5115,
    place_name: 'Munno Para West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6646,
    longitude: 138.6807,
    accuracy: 4,
  },
  {
    postcode: 5115,
    place_name: 'Munno Para Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6557,
    longitude: 138.6812,
    accuracy: 4,
  },
  {
    postcode: 5116,
    place_name: 'Evanston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6166,
    longitude: 138.7337,
    accuracy: 4,
  },
  {
    postcode: 5116,
    place_name: 'Evanston Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6232,
    longitude: 138.7217,
    accuracy: 4,
  },
  {
    postcode: 5116,
    place_name: 'Hillier',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6309,
    longitude: 138.6682,
    accuracy: 4,
  },
  {
    postcode: 5116,
    place_name: 'Evanston Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6228,
    longitude: 138.7412,
    accuracy: 4,
  },
  {
    postcode: 5116,
    place_name: 'Evanston South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6483,
    longitude: 138.7178,
    accuracy: 4,
  },
  {
    postcode: 5117,
    place_name: 'Angle Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.641,
    longitude: 138.6461,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Kingsford',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5862,
    longitude: 138.7137,
    accuracy: 3,
  },
  {
    postcode: 5118,
    place_name: 'Gawler',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6025,
    longitude: 138.749,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Kalbeeba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.625,
    longitude: 138.7928,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Concordia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5748,
    longitude: 138.8051,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Gawler East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5989,
    longitude: 138.7632,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Willaston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5833,
    longitude: 138.75,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Hewett',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.578,
    longitude: 138.7524,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Gawler River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6144,
    longitude: 138.6564,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Reid',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5913,
    longitude: 138.7397,
    accuracy: 3,
  },
  {
    postcode: 5118,
    place_name: 'Gawler West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6044,
    longitude: 138.7325,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Gawler South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6071,
    longitude: 138.7445,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Kangaroo Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.55,
    longitude: 138.7,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Bibaringa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6337,
    longitude: 138.766,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Ward Belt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5789,
    longitude: 138.6683,
    accuracy: 4,
  },
  {
    postcode: 5118,
    place_name: 'Gawler Belt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5862,
    longitude: 138.7137,
    accuracy: 3,
  },
  {
    postcode: 5118,
    place_name: 'Buchfelde',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5862,
    longitude: 138.7137,
    accuracy: 3,
  },
  {
    postcode: 5120,
    place_name: 'Virginia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6663,
    longitude: 138.5603,
    accuracy: 4,
  },
  {
    postcode: 5120,
    place_name: 'Buckland Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6853,
    longitude: 138.5417,
    accuracy: 4,
  },
  {
    postcode: 5121,
    place_name: 'Penfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7,
    longitude: 138.6333,
    accuracy: 4,
  },
  {
    postcode: 5121,
    place_name: 'Penfield Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6522,
    longitude: 138.6329,
    accuracy: 4,
  },
  {
    postcode: 5121,
    place_name: 'Macdonald Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6584,
    longitude: 138.6439,
    accuracy: 4,
  },
  {
    postcode: 5125,
    place_name: 'Golden Grove',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7833,
    longitude: 138.7333,
    accuracy: 4,
  },
  {
    postcode: 5125,
    place_name: 'Golden Grove Village',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7689,
    longitude: 138.735,
    accuracy: 3,
  },
  {
    postcode: 5125,
    place_name: 'Greenwith',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7657,
    longitude: 138.7138,
    accuracy: 4,
  },
  {
    postcode: 5126,
    place_name: 'Fairview Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8,
    longitude: 138.7286,
    accuracy: 4,
  },
  {
    postcode: 5126,
    place_name: 'Yatala Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7945,
    longitude: 138.7303,
    accuracy: 4,
  },
  {
    postcode: 5126,
    place_name: 'Surrey Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7995,
    longitude: 138.7115,
    accuracy: 4,
  },
  {
    postcode: 5127,
    place_name: 'Wynn Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7994,
    longitude: 138.6871,
    accuracy: 4,
  },
  {
    postcode: 5131,
    place_name: 'Houghton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8287,
    longitude: 138.7615,
    accuracy: 4,
  },
  {
    postcode: 5131,
    place_name: 'Upper Hermitage',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7964,
    longitude: 138.7589,
    accuracy: 4,
  },
  {
    postcode: 5131,
    place_name: 'Lower Hermitage',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7942,
    longitude: 138.7798,
    accuracy: 3,
  },
  {
    postcode: 5132,
    place_name: 'Paracombe',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8488,
    longitude: 138.78,
    accuracy: 4,
  },
  {
    postcode: 5133,
    place_name: 'Inglewood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.824,
    longitude: 138.7739,
    accuracy: 4,
  },
  {
    postcode: 5134,
    place_name: 'Montacute',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.888,
    longitude: 138.7491,
    accuracy: 4,
  },
  {
    postcode: 5134,
    place_name: 'Cherryville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.912,
    longitude: 138.7657,
    accuracy: 4,
  },
  {
    postcode: 5136,
    place_name: 'Norton Summit',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9229,
    longitude: 138.7249,
    accuracy: 4,
  },
  {
    postcode: 5137,
    place_name: 'Ashton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9395,
    longitude: 138.7371,
    accuracy: 4,
  },
  {
    postcode: 5137,
    place_name: 'Marble Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9395,
    longitude: 138.7371,
    accuracy: 3,
  },
  {
    postcode: 5138,
    place_name: 'Basket Range',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9398,
    longitude: 138.765,
    accuracy: 4,
  },
  {
    postcode: 5139,
    place_name: 'Forest Range',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.928,
    longitude: 138.807,
    accuracy: 4,
  },
  {
    postcode: 5140,
    place_name: 'Greenhill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9499,
    longitude: 138.6917,
    accuracy: 4,
  },
  {
    postcode: 5141,
    place_name: 'Summertown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9595,
    longitude: 138.7313,
    accuracy: 4,
  },
  {
    postcode: 5141,
    place_name: 'Horsnell Gully',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9351,
    longitude: 138.7044,
    accuracy: 4,
  },
  {
    postcode: 5142,
    place_name: 'Uraidla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.956,
    longitude: 138.7444,
    accuracy: 4,
  },
  {
    postcode: 5144,
    place_name: 'Carey Gully',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9647,
    longitude: 138.7618,
    accuracy: 4,
  },
  {
    postcode: 5150,
    place_name: 'Leawood Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9741,
    longitude: 138.6734,
    accuracy: 4,
  },
  {
    postcode: 5150,
    place_name: 'Eagle On The Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0887,
    longitude: 138.6997,
    accuracy: 3,
  },
  {
    postcode: 5151,
    place_name: 'Piccadilly',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9778,
    longitude: 138.7302,
    accuracy: 4,
  },
  {
    postcode: 5152,
    place_name: 'Stirling',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0152,
    longitude: 138.7364,
    accuracy: 4,
  },
  {
    postcode: 5152,
    place_name: 'Crafers',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35,
    longitude: 138.7,
    accuracy: 4,
  },
  {
    postcode: 5152,
    place_name: 'Cleland',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0076,
    longitude: 138.7182,
    accuracy: 3,
  },
  {
    postcode: 5152,
    place_name: 'Crafers West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9914,
    longitude: 138.6821,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Longwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.05,
    longitude: 138.7167,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Scott Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0575,
    longitude: 138.7052,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Jupiter Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1379,
    longitude: 138.758,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Bradbury',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0608,
    longitude: 138.7251,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Macclesfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1718,
    longitude: 138.8359,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Echunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1035,
    longitude: 138.7959,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Mylor',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.043,
    longitude: 138.759,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Biggs Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0667,
    longitude: 138.7833,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Chapel Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0842,
    longitude: 138.7582,
    accuracy: 3,
  },
  {
    postcode: 5153,
    place_name: 'Ironbank',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.05,
    longitude: 138.6833,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Heathfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0167,
    longitude: 138.7167,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Flaxley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1333,
    longitude: 138.8167,
    accuracy: 4,
  },
  {
    postcode: 5153,
    place_name: 'Green Hills Range',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0842,
    longitude: 138.7582,
    accuracy: 3,
  },
  {
    postcode: 5154,
    place_name: 'Aldgate',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0167,
    longitude: 138.7333,
    accuracy: 4,
  },
  {
    postcode: 5155,
    place_name: 'Mount George',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0037,
    longitude: 138.7637,
    accuracy: 3,
  },
  {
    postcode: 5155,
    place_name: 'Bridgewater',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35,
    longitude: 138.75,
    accuracy: 4,
  },
  {
    postcode: 5156,
    place_name: 'Upper Sturt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0167,
    longitude: 138.6833,
    accuracy: 4,
  },
  {
    postcode: 5157,
    place_name: 'Dorset Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0873,
    longitude: 138.6764,
    accuracy: 4,
  },
  {
    postcode: 5157,
    place_name: 'Clarendon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1167,
    longitude: 138.6333,
    accuracy: 4,
  },
  {
    postcode: 5157,
    place_name: 'Cherry Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0634,
    longitude: 138.6629,
    accuracy: 4,
  },
  {
    postcode: 5157,
    place_name: 'Kangarilla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1488,
    longitude: 138.6604,
    accuracy: 4,
  },
  {
    postcode: 5157,
    place_name: 'Bull Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2333,
    longitude: 138.75,
    accuracy: 4,
  },
  {
    postcode: 5157,
    place_name: 'Ashbourne',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2873,
    longitude: 138.7668,
    accuracy: 4,
  },
  {
    postcode: 5157,
    place_name: 'Coromandel East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0477,
    longitude: 138.6419,
    accuracy: 4,
  },
  {
    postcode: 5157,
    place_name: 'Mcharg Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.273,
    longitude: 138.7361,
    accuracy: 4,
  },
  {
    postcode: 5158,
    place_name: 'Sheidow Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0793,
    longitude: 138.5274,
    accuracy: 4,
  },
  {
    postcode: 5158,
    place_name: "O'Halloran Hill DC",
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.073,
    longitude: 138.5387,
    accuracy: 3,
  },
  {
    postcode: 5158,
    place_name: 'Hallett Cove',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0792,
    longitude: 138.5146,
    accuracy: 4,
  },
  {
    postcode: 5158,
    place_name: "O'Halloran Hill",
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0667,
    longitude: 138.55,
    accuracy: 4,
  },
  {
    postcode: 5158,
    place_name: 'Trott Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0758,
    longitude: 138.5419,
    accuracy: 4,
  },
  {
    postcode: 5159,
    place_name: 'Happy Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0833,
    longitude: 138.5333,
    accuracy: 4,
  },
  {
    postcode: 5159,
    place_name: 'Chandlers Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0865,
    longitude: 138.6169,
    accuracy: 4,
  },
  {
    postcode: 5159,
    place_name: 'Flagstaff Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0486,
    longitude: 138.5828,
    accuracy: 4,
  },
  {
    postcode: 5159,
    place_name: 'Aberfoyle Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0768,
    longitude: 138.5916,
    accuracy: 4,
  },
  {
    postcode: 5160,
    place_name: 'Lonsdale DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1068,
    longitude: 138.4929,
    accuracy: 3,
  },
  {
    postcode: 5160,
    place_name: 'Lonsdale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1068,
    longitude: 138.4929,
    accuracy: 4,
  },
  {
    postcode: 5161,
    place_name: 'Reynella',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0937,
    longitude: 138.5308,
    accuracy: 4,
  },
  {
    postcode: 5161,
    place_name: 'Reynella East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.092,
    longitude: 138.5574,
    accuracy: 4,
  },
  {
    postcode: 5161,
    place_name: 'Old Reynella',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1035,
    longitude: 138.5336,
    accuracy: 4,
  },
  {
    postcode: 5162,
    place_name: 'Morphett Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1333,
    longitude: 138.5167,
    accuracy: 4,
  },
  {
    postcode: 5162,
    place_name: 'Woodcroft',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1024,
    longitude: 138.5629,
    accuracy: 4,
  },
  {
    postcode: 5163,
    place_name: 'Huntfield Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1545,
    longitude: 138.5142,
    accuracy: 4,
  },
  {
    postcode: 5163,
    place_name: 'Onkaparinga Hills',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1351,
    longitude: 138.567,
    accuracy: 4,
  },
  {
    postcode: 5163,
    place_name: 'Hackham West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1431,
    longitude: 138.5144,
    accuracy: 4,
  },
  {
    postcode: 5163,
    place_name: 'Hackham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.15,
    longitude: 138.5167,
    accuracy: 4,
  },
  {
    postcode: 5164,
    place_name: 'Christie Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.13,
    longitude: 138.4963,
    accuracy: 4,
  },
  {
    postcode: 5165,
    place_name: 'Christies Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1388,
    longitude: 138.4723,
    accuracy: 4,
  },
  {
    postcode: 5165,
    place_name: 'Christies Beach North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1451,
    longitude: 138.5038,
    accuracy: 3,
  },
  {
    postcode: 5166,
    place_name: "O'Sullivan Beach",
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1188,
    longitude: 138.4734,
    accuracy: 4,
  },
  {
    postcode: 5167,
    place_name: 'Port Noarlunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.148,
    longitude: 138.471,
    accuracy: 4,
  },
  {
    postcode: 5167,
    place_name: 'Port Noarlunga South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.174,
    longitude: 138.4686,
    accuracy: 4,
  },
  {
    postcode: 5168,
    place_name: 'Old Noarlunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1821,
    longitude: 138.5014,
    accuracy: 4,
  },
  {
    postcode: 5168,
    place_name: 'Noarlunga Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1821,
    longitude: 138.5014,
    accuracy: 4,
  },
  {
    postcode: 5168,
    place_name: 'Noarlunga Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1504,
    longitude: 138.504,
    accuracy: 4,
  },
  {
    postcode: 5169,
    place_name: 'Seaford Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1988,
    longitude: 138.4909,
    accuracy: 4,
  },
  {
    postcode: 5169,
    place_name: 'Moana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1939,
    longitude: 138.4761,
    accuracy: 4,
  },
  {
    postcode: 5169,
    place_name: 'Seaford Meadows',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1728,
    longitude: 138.4879,
    accuracy: 4,
  },
  {
    postcode: 5169,
    place_name: 'Seaford',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1898,
    longitude: 138.4759,
    accuracy: 4,
  },
  {
    postcode: 5169,
    place_name: 'Seaford Rise',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.193,
    longitude: 138.4817,
    accuracy: 4,
  },
  {
    postcode: 5170,
    place_name: 'Maslin Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2259,
    longitude: 138.4798,
    accuracy: 4,
  },
  {
    postcode: 5171,
    place_name: 'Blewitt Springs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2178,
    longitude: 138.5633,
    accuracy: 3,
  },
  {
    postcode: 5171,
    place_name: 'Mclaren Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2189,
    longitude: 138.5433,
    accuracy: 4,
  },
  {
    postcode: 5171,
    place_name: 'Tatachilla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2369,
    longitude: 138.5071,
    accuracy: 4,
  },
  {
    postcode: 5171,
    place_name: 'Mclaren Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2167,
    longitude: 138.5833,
    accuracy: 4,
  },
  {
    postcode: 5171,
    place_name: 'Pedler Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.202,
    longitude: 138.566,
    accuracy: 3,
  },
  {
    postcode: 5172,
    place_name: 'Hope Forest',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2833,
    longitude: 138.6167,
    accuracy: 4,
  },
  {
    postcode: 5172,
    place_name: 'Willunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2713,
    longitude: 138.5542,
    accuracy: 4,
  },
  {
    postcode: 5172,
    place_name: 'Yundi',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3167,
    longitude: 138.6333,
    accuracy: 4,
  },
  {
    postcode: 5172,
    place_name: 'Willunga Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2751,
    longitude: 138.6196,
    accuracy: 3,
  },
  {
    postcode: 5172,
    place_name: 'Dingabledinga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2672,
    longitude: 138.6291,
    accuracy: 4,
  },
  {
    postcode: 5172,
    place_name: 'Willunga South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2838,
    longitude: 138.5548,
    accuracy: 4,
  },
  {
    postcode: 5172,
    place_name: 'The Range',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2463,
    longitude: 138.6228,
    accuracy: 4,
  },
  {
    postcode: 5172,
    place_name: 'Pages Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2751,
    longitude: 138.6196,
    accuracy: 3,
  },
  {
    postcode: 5172,
    place_name: 'Kuitpo Colony',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2876,
    longitude: 138.6963,
    accuracy: 4,
  },
  {
    postcode: 5172,
    place_name: 'Montarra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2667,
    longitude: 138.6167,
    accuracy: 4,
  },
  {
    postcode: 5172,
    place_name: 'Kyeema',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2763,
    longitude: 138.6731,
    accuracy: 4,
  },
  {
    postcode: 5172,
    place_name: 'Whites Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2711,
    longitude: 138.5221,
    accuracy: 4,
  },
  {
    postcode: 5173,
    place_name: 'Port Willunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2779,
    longitude: 138.4523,
    accuracy: 4,
  },
  {
    postcode: 5173,
    place_name: 'Aldinga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2833,
    longitude: 138.4833,
    accuracy: 4,
  },
  {
    postcode: 5173,
    place_name: 'Aldinga Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2783,
    longitude: 138.458,
    accuracy: 4,
  },
  {
    postcode: 5174,
    place_name: 'Sellicks Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3296,
    longitude: 138.4742,
    accuracy: 4,
  },
  {
    postcode: 5174,
    place_name: 'Sellicks Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3387,
    longitude: 138.4473,
    accuracy: 4,
  },
  {
    postcode: 5201,
    place_name: 'Kuitpo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2266,
    longitude: 138.6861,
    accuracy: 4,
  },
  {
    postcode: 5201,
    place_name: 'Meadows',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1802,
    longitude: 138.7625,
    accuracy: 4,
  },
  {
    postcode: 5201,
    place_name: 'Prospect Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2333,
    longitude: 138.7167,
    accuracy: 4,
  },
  {
    postcode: 5201,
    place_name: 'Blackfellows Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2159,
    longitude: 138.7406,
    accuracy: 3,
  },
  {
    postcode: 5201,
    place_name: 'Paris Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2094,
    longitude: 138.7955,
    accuracy: 4,
  },
  {
    postcode: 5202,
    place_name: 'Hindmarsh Tiers',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4,
    longitude: 138.45,
    accuracy: 3,
  },
  {
    postcode: 5202,
    place_name: 'Myponga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.404,
    longitude: 138.4712,
    accuracy: 4,
  },
  {
    postcode: 5202,
    place_name: 'Myponga Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3738,
    longitude: 138.387,
    accuracy: 4,
  },
  {
    postcode: 5203,
    place_name: 'Bald Hills',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5167,
    longitude: 138.4167,
    accuracy: 4,
  },
  {
    postcode: 5203,
    place_name: 'Yankalilla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4571,
    longitude: 138.3498,
    accuracy: 4,
  },
  {
    postcode: 5203,
    place_name: 'Wattle Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.428,
    longitude: 138.3897,
    accuracy: 4,
  },
  {
    postcode: 5203,
    place_name: 'Parawa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4851,
    longitude: 138.3791,
    accuracy: 3,
  },
  {
    postcode: 5203,
    place_name: 'Tunkalilla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4851,
    longitude: 138.3791,
    accuracy: 3,
  },
  {
    postcode: 5203,
    place_name: 'Torrens Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5167,
    longitude: 138.3667,
    accuracy: 4,
  },
  {
    postcode: 5204,
    place_name: 'Normanville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4477,
    longitude: 138.317,
    accuracy: 4,
  },
  {
    postcode: 5204,
    place_name: 'Rapid Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5251,
    longitude: 138.1893,
    accuracy: 4,
  },
  {
    postcode: 5204,
    place_name: 'Second Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5311,
    longitude: 138.2269,
    accuracy: 4,
  },
  {
    postcode: 5204,
    place_name: 'Wirrina Cove',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5225,
    longitude: 138.232,
    accuracy: 3,
  },
  {
    postcode: 5204,
    place_name: 'Delamere',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5833,
    longitude: 138.1833,
    accuracy: 4,
  },
  {
    postcode: 5204,
    place_name: 'Hay Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5225,
    longitude: 138.232,
    accuracy: 3,
  },
  {
    postcode: 5204,
    place_name: 'Carrickalinga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4275,
    longitude: 138.3244,
    accuracy: 4,
  },
  {
    postcode: 5204,
    place_name: 'Silverton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5225,
    longitude: 138.232,
    accuracy: 3,
  },
  {
    postcode: 5204,
    place_name: 'Cape Jervis',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6034,
    longitude: 138.1032,
    accuracy: 4,
  },
  {
    postcode: 5204,
    place_name: 'Deep Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5225,
    longitude: 138.232,
    accuracy: 3,
  },
  {
    postcode: 5210,
    place_name: 'Mount Compass',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3469,
    longitude: 138.6203,
    accuracy: 4,
  },
  {
    postcode: 5210,
    place_name: 'Mount Magnificent',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3484,
    longitude: 138.6518,
    accuracy: 3,
  },
  {
    postcode: 5210,
    place_name: 'Nangkita',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3455,
    longitude: 138.6865,
    accuracy: 4,
  },
  {
    postcode: 5211,
    place_name: 'Lower Inman Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.516,
    longitude: 138.5679,
    accuracy: 4,
  },
  {
    postcode: 5211,
    place_name: 'Inman Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4938,
    longitude: 138.4563,
    accuracy: 4,
  },
  {
    postcode: 5211,
    place_name: 'Waitpinga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5744,
    longitude: 138.5826,
    accuracy: 4,
  },
  {
    postcode: 5211,
    place_name: 'Encounter Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5742,
    longitude: 138.6005,
    accuracy: 4,
  },
  {
    postcode: 5211,
    place_name: 'Hayborough',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5313,
    longitude: 138.6504,
    accuracy: 4,
  },
  {
    postcode: 5211,
    place_name: 'Back Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5453,
    longitude: 138.5896,
    accuracy: 4,
  },
  {
    postcode: 5211,
    place_name: 'Willow Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5417,
    longitude: 138.5839,
    accuracy: 3,
  },
  {
    postcode: 5211,
    place_name: 'Hindmarsh Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5167,
    longitude: 138.6167,
    accuracy: 4,
  },
  {
    postcode: 5211,
    place_name: 'Mount Jagged',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5417,
    longitude: 138.5839,
    accuracy: 3,
  },
  {
    postcode: 5211,
    place_name: 'Mccracken',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5358,
    longitude: 138.6312,
    accuracy: 4,
  },
  {
    postcode: 5211,
    place_name: 'Victor Harbor',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5504,
    longitude: 138.6215,
    accuracy: 4,
  },
  {
    postcode: 5212,
    place_name: 'Port Elliot',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5302,
    longitude: 138.6792,
    accuracy: 4,
  },
  {
    postcode: 5213,
    place_name: 'Middleton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5105,
    longitude: 138.7038,
    accuracy: 4,
  },
  {
    postcode: 5214,
    place_name: 'Hindmarsh Island',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5101,
    longitude: 138.8676,
    accuracy: 4,
  },
  {
    postcode: 5214,
    place_name: 'Mosquito Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.502,
    longitude: 138.7849,
    accuracy: 3,
  },
  {
    postcode: 5214,
    place_name: 'Goolwa Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5047,
    longitude: 138.7732,
    accuracy: 4,
  },
  {
    postcode: 5214,
    place_name: 'Currency Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.45,
    longitude: 138.7667,
    accuracy: 4,
  },
  {
    postcode: 5214,
    place_name: 'Goolwa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5016,
    longitude: 138.7819,
    accuracy: 4,
  },
  {
    postcode: 5214,
    place_name: 'Mundoo Island',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5509,
    longitude: 138.9148,
    accuracy: 4,
  },
  {
    postcode: 5214,
    place_name: 'Goolwa South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5148,
    longitude: 138.7813,
    accuracy: 4,
  },
  {
    postcode: 5214,
    place_name: 'Goolwa North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4912,
    longitude: 138.8126,
    accuracy: 4,
  },
  {
    postcode: 5220,
    place_name: 'Parndana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7853,
    longitude: 137.2571,
    accuracy: 4,
  },
  {
    postcode: 5221,
    place_name: 'American River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7725,
    longitude: 137.7783,
    accuracy: 4,
  },
  {
    postcode: 5221,
    place_name: 'Ballast Head',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7717,
    longitude: 137.7804,
    accuracy: 4,
  },
  {
    postcode: 5221,
    place_name: 'Muston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7897,
    longitude: 137.757,
    accuracy: 4,
  },
  {
    postcode: 5222,
    place_name: 'Kangaroo Head',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7717,
    longitude: 137.7804,
    accuracy: 4,
  },
  {
    postcode: 5222,
    place_name: 'Island Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7975,
    longitude: 137.7987,
    accuracy: 4,
  },
  {
    postcode: 5222,
    place_name: 'Penneshaw',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7333,
    longitude: 137.9333,
    accuracy: 4,
  },
  {
    postcode: 5222,
    place_name: 'Pelican Lagoon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.807,
    longitude: 137.8039,
    accuracy: 4,
  },
  {
    postcode: 5222,
    place_name: 'Brown Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7984,
    longitude: 137.8378,
    accuracy: 4,
  },
  {
    postcode: 5222,
    place_name: 'Sapphiretown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7907,
    longitude: 137.781,
    accuracy: 4,
  },
  {
    postcode: 5222,
    place_name: 'Baudin Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7765,
    longitude: 137.8729,
    accuracy: 4,
  },
  {
    postcode: 5222,
    place_name: 'Dudley West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7661,
    longitude: 137.8681,
    accuracy: 3,
  },
  {
    postcode: 5222,
    place_name: 'Ironstone',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7661,
    longitude: 137.8681,
    accuracy: 3,
  },
  {
    postcode: 5222,
    place_name: 'Antechamber Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7661,
    longitude: 137.8681,
    accuracy: 3,
  },
  {
    postcode: 5222,
    place_name: 'Cuttlefish Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7661,
    longitude: 137.8681,
    accuracy: 3,
  },
  {
    postcode: 5222,
    place_name: 'Dudley East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7661,
    longitude: 137.8681,
    accuracy: 3,
  },
  {
    postcode: 5222,
    place_name: 'Willson River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7661,
    longitude: 137.8681,
    accuracy: 3,
  },
  {
    postcode: 5222,
    place_name: 'American Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7661,
    longitude: 137.8681,
    accuracy: 3,
  },
  {
    postcode: 5222,
    place_name: 'Porky Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7661,
    longitude: 137.8681,
    accuracy: 3,
  },
  {
    postcode: 5222,
    place_name: 'Willoughby',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7661,
    longitude: 137.8681,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'North Cape',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5808,
    longitude: 137.595,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: "D'Estrees Bay",
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.928,
    longitude: 137.5742,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Kingscote',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6559,
    longitude: 137.6393,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Newland',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8717,
    longitude: 137.1212,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Seddon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8359,
    longitude: 137.2923,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Emu Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5984,
    longitude: 137.5059,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Vivonne Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.9826,
    longitude: 137.1771,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Kohinoor',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Brownlow Ki',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6714,
    longitude: 137.6071,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Bay Of Shoals',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Wisanger',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6333,
    longitude: 137.4667,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Karatta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.9284,
    longitude: 136.8972,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Cygnet River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.7,
    longitude: 137.5167,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Cape Borda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Stokes Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6294,
    longitude: 137.2034,
    accuracy: 4,
  },
  {
    postcode: 5223,
    place_name: 'Haines',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'De Mole River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Gosse',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Birchmore',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Nepean Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Macgillivray',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Menzies',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Flinders Chase',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Cassini',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: "Stun'Sail Boom",
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Seal Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Middle River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Western River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5223,
    place_name: 'Duncan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6801,
    longitude: 137.5345,
    accuracy: 3,
  },
  {
    postcode: 5231,
    place_name: 'Chain Of Ponds',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8225,
    longitude: 138.8335,
    accuracy: 4,
  },
  {
    postcode: 5231,
    place_name: 'Kersbrook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7831,
    longitude: 138.8509,
    accuracy: 4,
  },
  {
    postcode: 5231,
    place_name: 'Millbrook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7999,
    longitude: 138.8421,
    accuracy: 3,
  },
  {
    postcode: 5232,
    place_name: 'Cudlee Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8409,
    longitude: 138.8284,
    accuracy: 4,
  },
  {
    postcode: 5233,
    place_name: 'Gumeracha',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8239,
    longitude: 138.8847,
    accuracy: 4,
  },
  {
    postcode: 5233,
    place_name: 'Forreston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7814,
    longitude: 138.9181,
    accuracy: 4,
  },
  {
    postcode: 5234,
    place_name: 'Birdwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8183,
    longitude: 138.9648,
    accuracy: 4,
  },
  {
    postcode: 5235,
    place_name: 'Flaxman Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7247,
    longitude: 139.0596,
    accuracy: 3,
  },
  {
    postcode: 5235,
    place_name: 'Springton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7074,
    longitude: 139.0893,
    accuracy: 4,
  },
  {
    postcode: 5235,
    place_name: 'Taunton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7247,
    longitude: 139.0596,
    accuracy: 3,
  },
  {
    postcode: 5235,
    place_name: 'Mount Pleasant',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7749,
    longitude: 139.0491,
    accuracy: 4,
  },
  {
    postcode: 5235,
    place_name: 'Cromer',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7627,
    longitude: 138.9901,
    accuracy: 4,
  },
  {
    postcode: 5235,
    place_name: 'Eden Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.65,
    longitude: 139.1,
    accuracy: 4,
  },
  {
    postcode: 5236,
    place_name: 'Tungkillo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8167,
    longitude: 139.0667,
    accuracy: 4,
  },
  {
    postcode: 5237,
    place_name: 'Sanderston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7554,
    longitude: 139.2219,
    accuracy: 4,
  },
  {
    postcode: 5237,
    place_name: 'Milendella',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8167,
    longitude: 139.2,
    accuracy: 4,
  },
  {
    postcode: 5237,
    place_name: 'Palmer',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.85,
    longitude: 139.1667,
    accuracy: 4,
  },
  {
    postcode: 5237,
    place_name: 'Apamurra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8056,
    longitude: 139.1944,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Bowhill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9,
    longitude: 139.6167,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Ponde',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.95,
    longitude: 139.3167,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Purnong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8667,
    longitude: 139.6167,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Frayville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Caurnamont',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8431,
    longitude: 139.5874,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Nildottie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6764,
    longitude: 139.6517,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Purnong Landing',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8667,
    longitude: 139.6167,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Five Miles',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Younghusband Holdings',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Angas Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7667,
    longitude: 139.3,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Teal Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8936,
    longitude: 139.5564,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Julanka Holdings',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Walker Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7526,
    longitude: 139.5565,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Pompoota',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35,
    longitude: 139.3333,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Wongulla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7049,
    longitude: 139.5659,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Frahns',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Rocky Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Younghusband',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Forster',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7334,
    longitude: 139.5548,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Port Mannum',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Mannum',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9153,
    longitude: 139.3033,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Bolto',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Old Teal Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Big Bend',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Punthari',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8167,
    longitude: 139.3167,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Cowirra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9,
    longitude: 139.3333,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Pellaring Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.862,
    longitude: 139.3899,
    accuracy: 4,
  },
  {
    postcode: 5238,
    place_name: 'Lake Carlet',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8411,
    longitude: 139.4865,
    accuracy: 3,
  },
  {
    postcode: 5238,
    place_name: 'Claypans',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8167,
    longitude: 139.6667,
    accuracy: 4,
  },
  {
    postcode: 5240,
    place_name: 'Lenswood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9182,
    longitude: 138.8326,
    accuracy: 4,
  },
  {
    postcode: 5241,
    place_name: 'Lobethal',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9045,
    longitude: 138.8748,
    accuracy: 4,
  },
  {
    postcode: 5242,
    place_name: 'Balhannah',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9909,
    longitude: 138.8257,
    accuracy: 4,
  },
  {
    postcode: 5243,
    place_name: 'Oakbank',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9834,
    longitude: 138.8453,
    accuracy: 4,
  },
  {
    postcode: 5244,
    place_name: 'Inverbrackie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9734,
    longitude: 138.8917,
    accuracy: 4,
  },
  {
    postcode: 5244,
    place_name: 'Charleston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9167,
    longitude: 138.9,
    accuracy: 4,
  },
  {
    postcode: 5244,
    place_name: 'Mount Torrens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8755,
    longitude: 138.9586,
    accuracy: 4,
  },
  {
    postcode: 5244,
    place_name: 'Woodside',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9543,
    longitude: 138.879,
    accuracy: 4,
  },
  {
    postcode: 5244,
    place_name: 'Harrogate',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.95,
    longitude: 139.0167,
    accuracy: 4,
  },
  {
    postcode: 5245,
    place_name: 'Paechtown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.049,
    longitude: 138.8089,
    accuracy: 4,
  },
  {
    postcode: 5245,
    place_name: 'Hahndorf',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0286,
    longitude: 138.8078,
    accuracy: 4,
  },
  {
    postcode: 5245,
    place_name: 'Verdun',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35,
    longitude: 138.7833,
    accuracy: 4,
  },
  {
    postcode: 5246,
    place_name: 'Woodhouse',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9614,
    longitude: 138.8661,
    accuracy: 1,
  },
  {
    postcode: 5250,
    place_name: 'Littlehampton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.05,
    longitude: 138.85,
    accuracy: 4,
  },
  {
    postcode: 5250,
    place_name: 'Blakiston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0421,
    longitude: 138.8815,
    accuracy: 4,
  },
  {
    postcode: 5250,
    place_name: 'Totness',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.05,
    longitude: 138.8667,
    accuracy: 3,
  },
  {
    postcode: 5251,
    place_name: 'Mount Barker Junction',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0253,
    longitude: 138.8683,
    accuracy: 4,
  },
  {
    postcode: 5251,
    place_name: 'Mount Barker Springs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.079,
    longitude: 138.8637,
    accuracy: 3,
  },
  {
    postcode: 5251,
    place_name: 'Bugle Ranges',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1327,
    longitude: 138.8716,
    accuracy: 4,
  },
  {
    postcode: 5251,
    place_name: 'Mount Barker Summit',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.079,
    longitude: 138.8637,
    accuracy: 3,
  },
  {
    postcode: 5251,
    place_name: 'Mount Barker',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0667,
    longitude: 138.8667,
    accuracy: 4,
  },
  {
    postcode: 5251,
    place_name: 'Wistow',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1,
    longitude: 138.8667,
    accuracy: 4,
  },
  {
    postcode: 5252,
    place_name: 'Nairne',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0345,
    longitude: 138.9115,
    accuracy: 4,
  },
  {
    postcode: 5252,
    place_name: 'Brukunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0029,
    longitude: 138.9425,
    accuracy: 4,
  },
  {
    postcode: 5252,
    place_name: 'Dawesley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0419,
    longitude: 138.9535,
    accuracy: 4,
  },
  {
    postcode: 5252,
    place_name: 'Kanmantoo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0701,
    longitude: 139.0127,
    accuracy: 4,
  },
  {
    postcode: 5252,
    place_name: 'St Ives',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0367,
    longitude: 138.9559,
    accuracy: 3,
  },
  {
    postcode: 5252,
    place_name: 'Hay Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0367,
    longitude: 138.9559,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Brinkley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.23,
    longitude: 139.2221,
    accuracy: 4,
  },
  {
    postcode: 5253,
    place_name: 'Riverglen',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.17,
    longitude: 139.3085,
    accuracy: 4,
  },
  {
    postcode: 5253,
    place_name: 'Ettrick',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35,
    longitude: 139.5667,
    accuracy: 4,
  },
  {
    postcode: 5253,
    place_name: 'Murray Bridge East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'White Sands',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Northern Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Swanport',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.15,
    longitude: 139.3,
    accuracy: 4,
  },
  {
    postcode: 5253,
    place_name: 'Murray Bridge',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1199,
    longitude: 139.2735,
    accuracy: 4,
  },
  {
    postcode: 5253,
    place_name: 'Chapman Bore',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1275,
    longitude: 139.5517,
    accuracy: 4,
  },
  {
    postcode: 5253,
    place_name: 'Murrawong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Monteith',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1667,
    longitude: 139.3,
    accuracy: 4,
  },
  {
    postcode: 5253,
    place_name: 'Mobilong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Sunnyside',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Willow Banks',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Riverglades',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Murray Bridge North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Toora',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Murray Bridge South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Long Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Gifford Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Greenbanks',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Avoca Dell',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Burdett',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5253,
    place_name: 'Woods Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1339,
    longitude: 139.3678,
    accuracy: 3,
  },
  {
    postcode: 5254,
    place_name: 'Murray Bridge',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1199,
    longitude: 139.2735,
    accuracy: 4,
  },
  {
    postcode: 5254,
    place_name: 'Wall Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9667,
    longitude: 139.3167,
    accuracy: 4,
  },
  {
    postcode: 5254,
    place_name: 'Caloote',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9642,
    longitude: 139.2622,
    accuracy: 4,
  },
  {
    postcode: 5254,
    place_name: 'White Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0406,
    longitude: 139.1998,
    accuracy: 3,
  },
  {
    postcode: 5254,
    place_name: 'Monarto South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.122,
    longitude: 139.129,
    accuracy: 4,
  },
  {
    postcode: 5254,
    place_name: 'Mypolonga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.05,
    longitude: 139.35,
    accuracy: 4,
  },
  {
    postcode: 5254,
    place_name: 'Pallamana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0344,
    longitude: 139.2059,
    accuracy: 4,
  },
  {
    postcode: 5254,
    place_name: 'Woodlane',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0406,
    longitude: 139.1998,
    accuracy: 3,
  },
  {
    postcode: 5254,
    place_name: 'Monarto',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.05,
    longitude: 139.1333,
    accuracy: 4,
  },
  {
    postcode: 5254,
    place_name: 'Rocky Gully',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0406,
    longitude: 139.1998,
    accuracy: 3,
  },
  {
    postcode: 5254,
    place_name: 'Tepko',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.969,
    longitude: 139.1878,
    accuracy: 4,
  },
  {
    postcode: 5254,
    place_name: 'Petwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0406,
    longitude: 139.1998,
    accuracy: 3,
  },
  {
    postcode: 5254,
    place_name: 'Callington',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1198,
    longitude: 139.0414,
    accuracy: 4,
  },
  {
    postcode: 5254,
    place_name: 'Zadows Landing',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0406,
    longitude: 139.1998,
    accuracy: 3,
  },
  {
    postcode: 5254,
    place_name: 'Rockleigh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35,
    longitude: 139.1167,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Woodchester',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2167,
    longitude: 138.95,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Tooperang',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3833,
    longitude: 138.7333,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Mount Observation',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2918,
    longitude: 138.9315,
    accuracy: 3,
  },
  {
    postcode: 5255,
    place_name: 'Finniss',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4,
    longitude: 138.8167,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Mulgundawa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2918,
    longitude: 138.9315,
    accuracy: 3,
  },
  {
    postcode: 5255,
    place_name: 'Belvidere',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2914,
    longitude: 138.9474,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Gemmells',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2918,
    longitude: 138.9315,
    accuracy: 3,
  },
  {
    postcode: 5255,
    place_name: 'Lake Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3704,
    longitude: 139.0417,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Langhorne Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.296,
    longitude: 139.0359,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Red Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1587,
    longitude: 138.9757,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Salem',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2918,
    longitude: 138.9315,
    accuracy: 3,
  },
  {
    postcode: 5255,
    place_name: 'Bletchley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2408,
    longitude: 138.9855,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Strathalbyn',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2598,
    longitude: 138.8925,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Sandergrove',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3333,
    longitude: 138.8333,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Nalpa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2918,
    longitude: 138.9315,
    accuracy: 3,
  },
  {
    postcode: 5255,
    place_name: 'Angas Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.35,
    longitude: 139,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Highland Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2918,
    longitude: 138.9315,
    accuracy: 3,
  },
  {
    postcode: 5255,
    place_name: 'Hartley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2,
    longitude: 139,
    accuracy: 4,
  },
  {
    postcode: 5255,
    place_name: 'Willyaroo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2667,
    longitude: 138.9167,
    accuracy: 4,
  },
  {
    postcode: 5256,
    place_name: 'Clayton Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4905,
    longitude: 138.9207,
    accuracy: 4,
  },
  {
    postcode: 5256,
    place_name: 'Tolderol',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4768,
    longitude: 138.9608,
    accuracy: 3,
  },
  {
    postcode: 5256,
    place_name: 'Point Sturt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5167,
    longitude: 139.0333,
    accuracy: 4,
  },
  {
    postcode: 5256,
    place_name: 'Clayton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4905,
    longitude: 138.9207,
    accuracy: 4,
  },
  {
    postcode: 5256,
    place_name: 'Milang',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4078,
    longitude: 138.9712,
    accuracy: 4,
  },
  {
    postcode: 5256,
    place_name: 'Nurragi',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4768,
    longitude: 138.9608,
    accuracy: 3,
  },
  {
    postcode: 5259,
    place_name: 'Tailem Bend',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2523,
    longitude: 139.4555,
    accuracy: 4,
  },
  {
    postcode: 5259,
    place_name: 'Wellington',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.35,
    longitude: 139.3833,
    accuracy: 4,
  },
  {
    postcode: 5259,
    place_name: 'Narrung',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5122,
    longitude: 139.1685,
    accuracy: 4,
  },
  {
    postcode: 5259,
    place_name: 'Wellington East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3333,
    longitude: 139.4,
    accuracy: 4,
  },
  {
    postcode: 5259,
    place_name: 'Ashville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5106,
    longitude: 139.3656,
    accuracy: 4,
  },
  {
    postcode: 5259,
    place_name: 'Kepa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3711,
    longitude: 139.3611,
    accuracy: 3,
  },
  {
    postcode: 5259,
    place_name: 'Jervois',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2667,
    longitude: 139.4333,
    accuracy: 4,
  },
  {
    postcode: 5259,
    place_name: 'Point Mcleay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5333,
    longitude: 139.1,
    accuracy: 4,
  },
  {
    postcode: 5259,
    place_name: 'Naturi',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1973,
    longitude: 139.5846,
    accuracy: 4,
  },
  {
    postcode: 5259,
    place_name: 'Raukkan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4358,
    longitude: 139.2417,
    accuracy: 3,
  },
  {
    postcode: 5259,
    place_name: 'Poltalloch',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3711,
    longitude: 139.3611,
    accuracy: 3,
  },
  {
    postcode: 5259,
    place_name: 'Malinong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3711,
    longitude: 139.3611,
    accuracy: 3,
  },
  {
    postcode: 5260,
    place_name: 'Tailem Bend',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2523,
    longitude: 139.4555,
    accuracy: 4,
  },
  {
    postcode: 5260,
    place_name: 'Elwomple',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2523,
    longitude: 139.4555,
    accuracy: 3,
  },
  {
    postcode: 5261,
    place_name: 'Yumali',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5084,
    longitude: 139.7578,
    accuracy: 4,
  },
  {
    postcode: 5261,
    place_name: 'Ki Ki',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.5833,
    longitude: 139.8,
    accuracy: 4,
  },
  {
    postcode: 5261,
    place_name: 'Culburra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8134,
    longitude: 139.9649,
    accuracy: 4,
  },
  {
    postcode: 5261,
    place_name: 'Cooke Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3797,
    longitude: 139.5617,
    accuracy: 4,
  },
  {
    postcode: 5261,
    place_name: 'Coomandook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4735,
    longitude: 139.6963,
    accuracy: 4,
  },
  {
    postcode: 5262,
    place_name: 'Binnum',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.7951,
    longitude: 140.9375,
    accuracy: 4,
  },
  {
    postcode: 5262,
    place_name: 'Frances',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.7167,
    longitude: 140.9667,
    accuracy: 4,
  },
  {
    postcode: 5262,
    place_name: 'Kybybolite',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.8833,
    longitude: 140.9167,
    accuracy: 4,
  },
  {
    postcode: 5262,
    place_name: 'Hynam',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.95,
    longitude: 140.85,
    accuracy: 4,
  },
  {
    postcode: 5263,
    place_name: 'Coonawarra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.2906,
    longitude: 140.8344,
    accuracy: 4,
  },
  {
    postcode: 5264,
    place_name: 'Meningie East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8184,
    longitude: 139.4684,
    accuracy: 3,
  },
  {
    postcode: 5264,
    place_name: 'Meningie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6864,
    longitude: 139.3401,
    accuracy: 4,
  },
  {
    postcode: 5264,
    place_name: 'Coorong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8184,
    longitude: 139.4684,
    accuracy: 3,
  },
  {
    postcode: 5264,
    place_name: 'Waltowa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.624,
    longitude: 139.4327,
    accuracy: 4,
  },
  {
    postcode: 5264,
    place_name: 'Meningie West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8184,
    longitude: 139.4684,
    accuracy: 3,
  },
  {
    postcode: 5264,
    place_name: 'Salt Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.15,
    longitude: 139.6333,
    accuracy: 4,
  },
  {
    postcode: 5265,
    place_name: 'Field',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8224,
    longitude: 139.6652,
    accuracy: 4,
  },
  {
    postcode: 5265,
    place_name: 'Coonalpyn',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.6942,
    longitude: 139.8578,
    accuracy: 4,
  },
  {
    postcode: 5266,
    place_name: 'Deepwater',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8871,
    longitude: 140.0565,
    accuracy: 3,
  },
  {
    postcode: 5266,
    place_name: 'Tintinara',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8871,
    longitude: 140.0565,
    accuracy: 4,
  },
  {
    postcode: 5266,
    place_name: 'Bunbury',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8871,
    longitude: 140.0565,
    accuracy: 3,
  },
  {
    postcode: 5266,
    place_name: 'Colebatch',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.8871,
    longitude: 140.0565,
    accuracy: 3,
  },
  {
    postcode: 5267,
    place_name: 'Coombe',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.962,
    longitude: 140.2192,
    accuracy: 4,
  },
  {
    postcode: 5267,
    place_name: 'Mount Charles',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.1495,
    longitude: 140.4682,
    accuracy: 3,
  },
  {
    postcode: 5267,
    place_name: 'Petherick',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.1495,
    longitude: 140.4682,
    accuracy: 3,
  },
  {
    postcode: 5267,
    place_name: 'Wirrega',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.2064,
    longitude: 140.5831,
    accuracy: 4,
  },
  {
    postcode: 5267,
    place_name: 'Sherwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.1495,
    longitude: 140.4682,
    accuracy: 3,
  },
  {
    postcode: 5267,
    place_name: 'Keith',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.0991,
    longitude: 140.3531,
    accuracy: 4,
  },
  {
    postcode: 5267,
    place_name: 'Shaugh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.1495,
    longitude: 140.4682,
    accuracy: 3,
  },
  {
    postcode: 5267,
    place_name: 'Mccallum',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.1495,
    longitude: 140.4682,
    accuracy: 3,
  },
  {
    postcode: 5267,
    place_name: 'Brimbago',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.1495,
    longitude: 140.4682,
    accuracy: 3,
  },
  {
    postcode: 5267,
    place_name: 'Laffer',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.1495,
    longitude: 140.4682,
    accuracy: 3,
  },
  {
    postcode: 5267,
    place_name: 'Willalooka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3935,
    longitude: 140.3081,
    accuracy: 4,
  },
  {
    postcode: 5267,
    place_name: 'Makin',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.1495,
    longitude: 140.4682,
    accuracy: 3,
  },
  {
    postcode: 5268,
    place_name: 'Lowan Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.1788,
    longitude: 140.6703,
    accuracy: 4,
  },
  {
    postcode: 5268,
    place_name: 'Western Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.2959,
    longitude: 140.7423,
    accuracy: 3,
  },
  {
    postcode: 5268,
    place_name: 'Cannawigara',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.25,
    longitude: 140.6667,
    accuracy: 4,
  },
  {
    postcode: 5268,
    place_name: 'Bordertown South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3077,
    longitude: 140.7717,
    accuracy: 4,
  },
  {
    postcode: 5268,
    place_name: 'Senior',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.2959,
    longitude: 140.7423,
    accuracy: 3,
  },
  {
    postcode: 5268,
    place_name: 'Bordertown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3077,
    longitude: 140.7717,
    accuracy: 4,
  },
  {
    postcode: 5268,
    place_name: 'Pooginagoric',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.4333,
    longitude: 140.8333,
    accuracy: 4,
  },
  {
    postcode: 5268,
    place_name: 'Bangham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.2959,
    longitude: 140.7423,
    accuracy: 3,
  },
  {
    postcode: 5269,
    place_name: 'Pine Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.1717,
    longitude: 133.7971,
    accuracy: 3,
  },
  {
    postcode: 5269,
    place_name: 'Wolseley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3667,
    longitude: 140.9,
    accuracy: 4,
  },
  {
    postcode: 5269,
    place_name: 'Custon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.45,
    longitude: 140.9167,
    accuracy: 4,
  },
  {
    postcode: 5270,
    place_name: 'Buckingham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3444,
    longitude: 140.5818,
    accuracy: 4,
  },
  {
    postcode: 5270,
    place_name: 'Kongal',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3167,
    longitude: 140.5167,
    accuracy: 4,
  },
  {
    postcode: 5270,
    place_name: 'Carew',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3444,
    longitude: 140.5944,
    accuracy: 3,
  },
  {
    postcode: 5270,
    place_name: 'Mundulla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3667,
    longitude: 140.6833,
    accuracy: 4,
  },
  {
    postcode: 5270,
    place_name: 'Mundulla West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3444,
    longitude: 140.5944,
    accuracy: 3,
  },
  {
    postcode: 5270,
    place_name: 'Swede Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.3444,
    longitude: 140.5944,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Bool Lagoon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.1392,
    longitude: 140.7218,
    accuracy: 4,
  },
  {
    postcode: 5271,
    place_name: 'Laurie Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Keppoch',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.7,
    longitude: 140.5667,
    accuracy: 4,
  },
  {
    postcode: 5271,
    place_name: 'Moyhall',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Naracoorte',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9578,
    longitude: 140.7383,
    accuracy: 4,
  },
  {
    postcode: 5271,
    place_name: 'Struan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0945,
    longitude: 140.791,
    accuracy: 4,
  },
  {
    postcode: 5271,
    place_name: 'Padthaway',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.5963,
    longitude: 140.4996,
    accuracy: 4,
  },
  {
    postcode: 5271,
    place_name: 'Koppamurra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Joanna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.1,
    longitude: 140.8333,
    accuracy: 4,
  },
  {
    postcode: 5271,
    place_name: 'Lochaber',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.8333,
    longitude: 140.5167,
    accuracy: 4,
  },
  {
    postcode: 5271,
    place_name: 'Cadgee',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Wrattonbully',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Spence',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Mount Light',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Wild Dog Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Marcollat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'The Gap',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5271,
    place_name: 'Stewart Range',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9763,
    longitude: 140.6925,
    accuracy: 3,
  },
  {
    postcode: 5272,
    place_name: 'Lucindale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9727,
    longitude: 140.3701,
    accuracy: 4,
  },
  {
    postcode: 5272,
    place_name: 'Woolumbool',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0906,
    longitude: 140.3179,
    accuracy: 3,
  },
  {
    postcode: 5272,
    place_name: 'Conmurra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.15,
    longitude: 140.2833,
    accuracy: 4,
  },
  {
    postcode: 5272,
    place_name: 'Coles',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0906,
    longitude: 140.3179,
    accuracy: 3,
  },
  {
    postcode: 5272,
    place_name: 'Fox',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5964,
    longitude: 135.7086,
    accuracy: 4,
  },
  {
    postcode: 5272,
    place_name: 'Greenways',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6586,
    longitude: 139.6446,
    accuracy: 4,
  },
  {
    postcode: 5273,
    place_name: 'Avenue Range',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9667,
    longitude: 140.2333,
    accuracy: 4,
  },
  {
    postcode: 5275,
    place_name: 'Rosetown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.8333,
    longitude: 139.85,
    accuracy: 4,
  },
  {
    postcode: 5275,
    place_name: 'Pinks Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0028,
    longitude: 140.0667,
    accuracy: 3,
  },
  {
    postcode: 5275,
    place_name: 'Boatswain Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0028,
    longitude: 140.0667,
    accuracy: 3,
  },
  {
    postcode: 5275,
    place_name: 'Keilira',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.7167,
    longitude: 140.15,
    accuracy: 4,
  },
  {
    postcode: 5275,
    place_name: 'West Range',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.6667,
    longitude: 140.5833,
    accuracy: 4,
  },
  {
    postcode: 5275,
    place_name: 'Kingston Se',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0028,
    longitude: 140.0667,
    accuracy: 3,
  },
  {
    postcode: 5275,
    place_name: 'Blackford',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.7667,
    longitude: 140.0167,
    accuracy: 4,
  },
  {
    postcode: 5275,
    place_name: 'Taratap',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0028,
    longitude: 140.0667,
    accuracy: 3,
  },
  {
    postcode: 5275,
    place_name: 'Tilley Swamp',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0028,
    longitude: 140.0667,
    accuracy: 3,
  },
  {
    postcode: 5275,
    place_name: 'Kingston S.E.',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.5102,
    longitude: 139.9281,
    accuracy: 3,
  },
  {
    postcode: 5275,
    place_name: 'Wangolina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0028,
    longitude: 140.0667,
    accuracy: 3,
  },
  {
    postcode: 5275,
    place_name: 'Mount Benson',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0833,
    longitude: 139.7833,
    accuracy: 4,
  },
  {
    postcode: 5275,
    place_name: 'Reedy Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.95,
    longitude: 140.0167,
    accuracy: 4,
  },
  {
    postcode: 5275,
    place_name: 'Sandy Grove',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0028,
    longitude: 140.0667,
    accuracy: 3,
  },
  {
    postcode: 5275,
    place_name: 'Cape Jaffa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -36.9455,
    longitude: 139.6982,
    accuracy: 4,
  },
  {
    postcode: 5275,
    place_name: 'Wyomi',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.0028,
    longitude: 140.0667,
    accuracy: 3,
  },
  {
    postcode: 5276,
    place_name: 'Nora Creina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.3256,
    longitude: 139.8523,
    accuracy: 4,
  },
  {
    postcode: 5276,
    place_name: 'Robe',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.1639,
    longitude: 139.7603,
    accuracy: 4,
  },
  {
    postcode: 5276,
    place_name: 'Bray',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.2461,
    longitude: 139.8082,
    accuracy: 3,
  },
  {
    postcode: 5277,
    place_name: 'Pleasant Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.4131,
    longitude: 140.8494,
    accuracy: 3,
  },
  {
    postcode: 5277,
    place_name: 'Glenroy',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.25,
    longitude: 140.85,
    accuracy: 4,
  },
  {
    postcode: 5277,
    place_name: 'Penola',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.3752,
    longitude: 140.8368,
    accuracy: 4,
  },
  {
    postcode: 5277,
    place_name: 'Comaum',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.2667,
    longitude: 140.95,
    accuracy: 4,
  },
  {
    postcode: 5277,
    place_name: 'Maaoupe',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.4131,
    longitude: 140.8494,
    accuracy: 3,
  },
  {
    postcode: 5277,
    place_name: 'Tarpeena',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.6277,
    longitude: 140.7959,
    accuracy: 4,
  },
  {
    postcode: 5277,
    place_name: 'Monbulla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.4131,
    longitude: 140.8494,
    accuracy: 3,
  },
  {
    postcode: 5277,
    place_name: 'Nangwarry',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.546,
    longitude: 140.8145,
    accuracy: 4,
  },
  {
    postcode: 5278,
    place_name: 'Kalangadoo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5648,
    longitude: 140.6992,
    accuracy: 4,
  },
  {
    postcode: 5278,
    place_name: 'Wepar',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.6083,
    longitude: 140.7077,
    accuracy: 4,
  },
  {
    postcode: 5278,
    place_name: 'Krongart',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5908,
    longitude: 140.6996,
    accuracy: 3,
  },
  {
    postcode: 5278,
    place_name: 'Moerlong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5908,
    longitude: 140.6996,
    accuracy: 3,
  },
  {
    postcode: 5279,
    place_name: 'Mount Burr',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.542,
    longitude: 140.4555,
    accuracy: 4,
  },
  {
    postcode: 5279,
    place_name: 'Trihi',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5436,
    longitude: 140.4452,
    accuracy: 3,
  },
  {
    postcode: 5279,
    place_name: 'Wattle Range East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.546,
    longitude: 140.5028,
    accuracy: 3,
  },
  {
    postcode: 5279,
    place_name: 'Short',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.546,
    longitude: 140.5028,
    accuracy: 3,
  },
  {
    postcode: 5279,
    place_name: 'Mount Mcintyre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5608,
    longitude: 140.5373,
    accuracy: 4,
  },
  {
    postcode: 5279,
    place_name: 'Koorine',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.546,
    longitude: 140.5028,
    accuracy: 3,
  },
  {
    postcode: 5280,
    place_name: 'Beachport',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.4802,
    longitude: 140.0107,
    accuracy: 4,
  },
  {
    postcode: 5280,
    place_name: 'Millicent',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5939,
    longitude: 140.3495,
    accuracy: 4,
  },
  {
    postcode: 5280,
    place_name: 'Tantanoola',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.6966,
    longitude: 140.4575,
    accuracy: 4,
  },
  {
    postcode: 5280,
    place_name: 'Clay Wells',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5441,
    longitude: 140.251,
    accuracy: 3,
  },
  {
    postcode: 5280,
    place_name: 'Furner',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.4167,
    longitude: 140.3333,
    accuracy: 4,
  },
  {
    postcode: 5280,
    place_name: 'Sebastopol',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5441,
    longitude: 140.251,
    accuracy: 3,
  },
  {
    postcode: 5280,
    place_name: 'Rendelsham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.55,
    longitude: 140.2167,
    accuracy: 4,
  },
  {
    postcode: 5280,
    place_name: 'German Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5441,
    longitude: 140.251,
    accuracy: 3,
  },
  {
    postcode: 5280,
    place_name: 'Southend',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5678,
    longitude: 140.1287,
    accuracy: 4,
  },
  {
    postcode: 5280,
    place_name: 'Thornlea',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5441,
    longitude: 140.251,
    accuracy: 3,
  },
  {
    postcode: 5280,
    place_name: 'Hatherleigh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5,
    longitude: 140.2667,
    accuracy: 4,
  },
  {
    postcode: 5280,
    place_name: 'Canunda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5441,
    longitude: 140.251,
    accuracy: 3,
  },
  {
    postcode: 5280,
    place_name: 'Wattle Range',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5441,
    longitude: 140.251,
    accuracy: 3,
  },
  {
    postcode: 5280,
    place_name: 'Kangaroo Inn',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5441,
    longitude: 140.251,
    accuracy: 3,
  },
  {
    postcode: 5280,
    place_name: 'Magarey',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5441,
    longitude: 140.251,
    accuracy: 3,
  },
  {
    postcode: 5280,
    place_name: 'Rocky Camp',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.5441,
    longitude: 140.251,
    accuracy: 3,
  },
  {
    postcode: 5290,
    place_name: 'Mount Gambier',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8318,
    longitude: 140.7792,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Glencoe West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.6667,
    longitude: 140.5833,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'German Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Caveton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Allendale East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -38.0037,
    longitude: 140.7092,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Worrolong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8062,
    longitude: 140.848,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Compton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.7667,
    longitude: 140.6833,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Eight Mile Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Yahl',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8667,
    longitude: 140.8,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Mount Gambier',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8318,
    longitude: 140.7792,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Nene Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.9895,
    longitude: 140.5204,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Pelican Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Mount Schank',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.9399,
    longitude: 140.7241,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Glencoe',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.7,
    longitude: 140.6167,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Kongorong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.9,
    longitude: 140.55,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Square Mile',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Wandilo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.7259,
    longitude: 140.7257,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Mil-Lel',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.7833,
    longitude: 140.8167,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Carpenter Rocks',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.9102,
    longitude: 140.3956,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Suttontown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Caroline',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8969,
    longitude: 140.9214,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'O.B. Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8265,
    longitude: 140.7066,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Burrungule',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.7809,
    longitude: 140.574,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Mingbool',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Port Macdonnell',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -38.0531,
    longitude: 140.6996,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Donovans',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -38.0135,
    longitude: 140.9621,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Glenburnie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8394,
    longitude: 140.8588,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Moorak',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8641,
    longitude: 140.7375,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Blackfellows Caves',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.9467,
    longitude: 140.4685,
    accuracy: 4,
  },
  {
    postcode: 5291,
    place_name: 'Dismal Swamp',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Mount Gambier West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8265,
    longitude: 140.7066,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Mount Gambier East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8265,
    longitude: 140.7066,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Cape Douglas',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Racecourse Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Ob Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5291,
    place_name: 'Wye',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -37.8571,
    longitude: 140.6831,
    accuracy: 3,
  },
  {
    postcode: 5301,
    place_name: 'Moorlands',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2899,
    longitude: 139.6455,
    accuracy: 4,
  },
  {
    postcode: 5301,
    place_name: 'Sherlock',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3167,
    longitude: 139.8167,
    accuracy: 4,
  },
  {
    postcode: 5301,
    place_name: 'Wilkawatt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3673,
    longitude: 140.3601,
    accuracy: 4,
  },
  {
    postcode: 5301,
    place_name: 'Carcuma',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3771,
    longitude: 140.0271,
    accuracy: 3,
  },
  {
    postcode: 5301,
    place_name: 'Parrakie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3833,
    longitude: 140.25,
    accuracy: 4,
  },
  {
    postcode: 5301,
    place_name: 'Jabuk',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4,
    longitude: 140.0667,
    accuracy: 4,
  },
  {
    postcode: 5301,
    place_name: 'Peake',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3667,
    longitude: 139.95,
    accuracy: 4,
  },
  {
    postcode: 5301,
    place_name: 'Geranium',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3833,
    longitude: 140.1667,
    accuracy: 4,
  },
  {
    postcode: 5301,
    place_name: 'Netherton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.4751,
    longitude: 139.9474,
    accuracy: 4,
  },
  {
    postcode: 5302,
    place_name: 'Ngarkat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.329,
    longitude: 140.5167,
    accuracy: 3,
  },
  {
    postcode: 5302,
    place_name: 'Lameroo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.329,
    longitude: 140.5167,
    accuracy: 4,
  },
  {
    postcode: 5303,
    place_name: 'Parilla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.3,
    longitude: 140.6667,
    accuracy: 4,
  },
  {
    postcode: 5304,
    place_name: 'Pinnaroo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2616,
    longitude: 140.912,
    accuracy: 4,
  },
  {
    postcode: 5304,
    place_name: 'Karte',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0667,
    longitude: 140.7,
    accuracy: 4,
  },
  {
    postcode: 5304,
    place_name: 'Kringin',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9833,
    longitude: 140.7833,
    accuracy: 4,
  },
  {
    postcode: 5304,
    place_name: 'Peebinga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9333,
    longitude: 140.9167,
    accuracy: 4,
  },
  {
    postcode: 5306,
    place_name: 'Wynarka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1333,
    longitude: 139.7333,
    accuracy: 4,
  },
  {
    postcode: 5307,
    place_name: 'Karoonda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0945,
    longitude: 139.8934,
    accuracy: 4,
  },
  {
    postcode: 5307,
    place_name: 'Marama',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1833,
    longitude: 140.1833,
    accuracy: 4,
  },
  {
    postcode: 5308,
    place_name: 'Galga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6994,
    longitude: 139.9646,
    accuracy: 4,
  },
  {
    postcode: 5308,
    place_name: 'Copeville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7973,
    longitude: 139.8431,
    accuracy: 4,
  },
  {
    postcode: 5308,
    place_name: 'Perponda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9875,
    longitude: 139.8122,
    accuracy: 4,
  },
  {
    postcode: 5308,
    place_name: 'Mantung',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6,
    longitude: 140.0667,
    accuracy: 4,
  },
  {
    postcode: 5308,
    place_name: 'Mercunda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.65,
    longitude: 140.0167,
    accuracy: 4,
  },
  {
    postcode: 5309,
    place_name: 'Mindarie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8167,
    longitude: 140.2167,
    accuracy: 4,
  },
  {
    postcode: 5309,
    place_name: 'Halidon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8833,
    longitude: 140.1667,
    accuracy: 4,
  },
  {
    postcode: 5309,
    place_name: 'Sandalwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.95,
    longitude: 140.1333,
    accuracy: 4,
  },
  {
    postcode: 5309,
    place_name: 'Borrika',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0167,
    longitude: 140.05,
    accuracy: 4,
  },
  {
    postcode: 5310,
    place_name: 'Caliph',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6667,
    longitude: 140.2833,
    accuracy: 4,
  },
  {
    postcode: 5310,
    place_name: 'Wanbi',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7667,
    longitude: 140.3167,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Taplan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5444,
    longitude: 140.8801,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Malpas',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7167,
    longitude: 140.6167,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Bugle Hut',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3774,
    longitude: 140.783,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Maggea',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4833,
    longitude: 140.0667,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Veitch',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6667,
    longitude: 140.5167,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Woodleigh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5682,
    longitude: 140.7151,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Meribah',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7034,
    longitude: 140.8542,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Taldra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4228,
    longitude: 140.8887,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Alawoona',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7333,
    longitude: 140.5167,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Wunkar',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5,
    longitude: 140.3,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Schell Well',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6058,
    longitude: 140.6087,
    accuracy: 3,
  },
  {
    postcode: 5311,
    place_name: 'Paruna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7167,
    longitude: 140.7333,
    accuracy: 4,
  },
  {
    postcode: 5311,
    place_name: 'Billiatt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6058,
    longitude: 140.6087,
    accuracy: 3,
  },
  {
    postcode: 5312,
    place_name: 'Veitch',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6667,
    longitude: 140.5167,
    accuracy: 4,
  },
  {
    postcode: 5320,
    place_name: 'Stuart',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0249,
    longitude: 137.5247,
    accuracy: 4,
  },
  {
    postcode: 5320,
    place_name: 'Eba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0669,
    longitude: 139.579,
    accuracy: 4,
  },
  {
    postcode: 5320,
    place_name: 'Beatty',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0991,
    longitude: 139.659,
    accuracy: 3,
  },
  {
    postcode: 5320,
    place_name: 'Murbko',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1631,
    longitude: 139.6629,
    accuracy: 4,
  },
  {
    postcode: 5320,
    place_name: 'Morgan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0315,
    longitude: 139.668,
    accuracy: 4,
  },
  {
    postcode: 5320,
    place_name: 'Beaumonts',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0991,
    longitude: 139.659,
    accuracy: 3,
  },
  {
    postcode: 5320,
    place_name: 'Wombats Rest',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0991,
    longitude: 139.659,
    accuracy: 3,
  },
  {
    postcode: 5320,
    place_name: 'North West Bend',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0991,
    longitude: 139.659,
    accuracy: 3,
  },
  {
    postcode: 5320,
    place_name: 'Morphetts Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0991,
    longitude: 139.659,
    accuracy: 3,
  },
  {
    postcode: 5320,
    place_name: 'Brenda Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0991,
    longitude: 139.659,
    accuracy: 3,
  },
  {
    postcode: 5320,
    place_name: 'Bundey',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0991,
    longitude: 139.659,
    accuracy: 3,
  },
  {
    postcode: 5320,
    place_name: 'Maude',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0991,
    longitude: 139.659,
    accuracy: 3,
  },
  {
    postcode: 5320,
    place_name: 'Lindley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0991,
    longitude: 139.659,
    accuracy: 3,
  },
  {
    postcode: 5321,
    place_name: 'Cadell',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0388,
    longitude: 139.7639,
    accuracy: 4,
  },
  {
    postcode: 5321,
    place_name: 'Cadell Lagoon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0333,
    longitude: 139.75,
    accuracy: 3,
  },
  {
    postcode: 5322,
    place_name: 'Golden Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1417,
    longitude: 139.9,
    accuracy: 3,
  },
  {
    postcode: 5322,
    place_name: 'Sunlands',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1417,
    longitude: 139.9,
    accuracy: 3,
  },
  {
    postcode: 5322,
    place_name: 'Ramco Heights',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1417,
    longitude: 139.9,
    accuracy: 3,
  },
  {
    postcode: 5322,
    place_name: 'Ramco',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1667,
    longitude: 139.9333,
    accuracy: 4,
  },
  {
    postcode: 5322,
    place_name: 'Qualco',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1103,
    longitude: 139.8933,
    accuracy: 4,
  },
  {
    postcode: 5330,
    place_name: 'Wigley Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1621,
    longitude: 140.0839,
    accuracy: 3,
  },
  {
    postcode: 5330,
    place_name: 'Boolgun',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4173,
    longitude: 140.04,
    accuracy: 4,
  },
  {
    postcode: 5330,
    place_name: 'Kanni',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1621,
    longitude: 140.0839,
    accuracy: 3,
  },
  {
    postcode: 5330,
    place_name: 'Waikerie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1818,
    longitude: 139.9855,
    accuracy: 4,
  },
  {
    postcode: 5330,
    place_name: 'Devlins Pound',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1621,
    longitude: 140.0839,
    accuracy: 3,
  },
  {
    postcode: 5330,
    place_name: 'Taylorville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0989,
    longitude: 139.9731,
    accuracy: 4,
  },
  {
    postcode: 5330,
    place_name: 'Pooginook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1621,
    longitude: 140.0839,
    accuracy: 3,
  },
  {
    postcode: 5330,
    place_name: 'Overland Corner',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1458,
    longitude: 140.3343,
    accuracy: 4,
  },
  {
    postcode: 5330,
    place_name: 'Stockyard Plain',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1621,
    longitude: 140.0839,
    accuracy: 3,
  },
  {
    postcode: 5330,
    place_name: 'Holder',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3267,
    longitude: 140.0174,
    accuracy: 4,
  },
  {
    postcode: 5330,
    place_name: 'Markaranka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1621,
    longitude: 140.0839,
    accuracy: 3,
  },
  {
    postcode: 5330,
    place_name: 'Good Hope Landing',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1621,
    longitude: 140.0839,
    accuracy: 3,
  },
  {
    postcode: 5330,
    place_name: 'Holder Siding',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1621,
    longitude: 140.0839,
    accuracy: 3,
  },
  {
    postcode: 5330,
    place_name: 'Woolpunda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1621,
    longitude: 140.0839,
    accuracy: 3,
  },
  {
    postcode: 5330,
    place_name: 'Lowbank',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1943,
    longitude: 140.0852,
    accuracy: 4,
  },
  {
    postcode: 5331,
    place_name: 'Kingston On Murray',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.325,
    longitude: 140.3517,
    accuracy: 1,
  },
  {
    postcode: 5331,
    place_name: 'Kingston-On-Murray',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2207,
    longitude: 140.34,
    accuracy: 1,
  },
  {
    postcode: 5332,
    place_name: 'Moorook South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2663,
    longitude: 140.3566,
    accuracy: 4,
  },
  {
    postcode: 5332,
    place_name: 'Yinkanie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3333,
    longitude: 140.3167,
    accuracy: 4,
  },
  {
    postcode: 5332,
    place_name: 'Wappilka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2886,
    longitude: 140.3433,
    accuracy: 3,
  },
  {
    postcode: 5332,
    place_name: 'Moorook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2663,
    longitude: 140.3566,
    accuracy: 4,
  },
  {
    postcode: 5333,
    place_name: 'Pyap',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4353,
    longitude: 140.4932,
    accuracy: 4,
  },
  {
    postcode: 5333,
    place_name: 'Bookpurnong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4771,
    longitude: 140.571,
    accuracy: 3,
  },
  {
    postcode: 5333,
    place_name: 'New Residence',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3787,
    longitude: 140.4026,
    accuracy: 4,
  },
  {
    postcode: 5333,
    place_name: 'Pyap West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4771,
    longitude: 140.571,
    accuracy: 3,
  },
  {
    postcode: 5333,
    place_name: 'Taplan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5444,
    longitude: 140.8801,
    accuracy: 4,
  },
  {
    postcode: 5333,
    place_name: 'Loxton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4502,
    longitude: 140.5691,
    accuracy: 4,
  },
  {
    postcode: 5333,
    place_name: 'Pata',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5667,
    longitude: 140.5167,
    accuracy: 4,
  },
  {
    postcode: 5333,
    place_name: 'Loxton North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4183,
    longitude: 140.6266,
    accuracy: 3,
  },
  {
    postcode: 5340,
    place_name: 'Wonuarra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2156,
    longitude: 140.8435,
    accuracy: 4,
  },
  {
    postcode: 5340,
    place_name: 'Paringa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1764,
    longitude: 140.7858,
    accuracy: 4,
  },
  {
    postcode: 5340,
    place_name: 'Yamba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2623,
    longitude: 140.8589,
    accuracy: 4,
  },
  {
    postcode: 5340,
    place_name: 'Murtho',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1764,
    longitude: 140.7858,
    accuracy: 3,
  },
  {
    postcode: 5340,
    place_name: 'Pike River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1764,
    longitude: 140.7858,
    accuracy: 3,
  },
  {
    postcode: 5340,
    place_name: 'Mundic Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1764,
    longitude: 140.7858,
    accuracy: 3,
  },
  {
    postcode: 5341,
    place_name: 'Cooltong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1006,
    longitude: 140.6737,
    accuracy: 4,
  },
  {
    postcode: 5341,
    place_name: 'Renmark South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2016,
    longitude: 140.7106,
    accuracy: 3,
  },
  {
    postcode: 5341,
    place_name: 'Renmark West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1653,
    longitude: 140.7097,
    accuracy: 4,
  },
  {
    postcode: 5341,
    place_name: 'Renmark',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.177,
    longitude: 140.747,
    accuracy: 4,
  },
  {
    postcode: 5341,
    place_name: 'Crescent',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1481,
    longitude: 140.704,
    accuracy: 3,
  },
  {
    postcode: 5341,
    place_name: 'Chaffey',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0934,
    longitude: 140.7065,
    accuracy: 4,
  },
  {
    postcode: 5341,
    place_name: 'Renmark North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1255,
    longitude: 140.7127,
    accuracy: 4,
  },
  {
    postcode: 5341,
    place_name: 'Old Calperum',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2178,
    longitude: 140.6803,
    accuracy: 4,
  },
  {
    postcode: 5342,
    place_name: 'Monash',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2384,
    longitude: 140.5613,
    accuracy: 4,
  },
  {
    postcode: 5343,
    place_name: 'Lyrup',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2579,
    longitude: 140.6482,
    accuracy: 4,
  },
  {
    postcode: 5343,
    place_name: 'Winkie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3,
    longitude: 140.5333,
    accuracy: 4,
  },
  {
    postcode: 5343,
    place_name: 'Berri',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2811,
    longitude: 140.5996,
    accuracy: 4,
  },
  {
    postcode: 5343,
    place_name: 'Katarapko',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4424,
    longitude: 140.559,
    accuracy: 4,
  },
  {
    postcode: 5343,
    place_name: 'Gerard',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2826,
    longitude: 140.5943,
    accuracy: 3,
  },
  {
    postcode: 5343,
    place_name: 'Gurra Gurra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2826,
    longitude: 140.5943,
    accuracy: 3,
  },
  {
    postcode: 5344,
    place_name: 'Glossop',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2769,
    longitude: 140.541,
    accuracy: 4,
  },
  {
    postcode: 5345,
    place_name: 'Spectacle Lake',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2695,
    longitude: 140.4474,
    accuracy: 3,
  },
  {
    postcode: 5345,
    place_name: 'Barmera',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2556,
    longitude: 140.4615,
    accuracy: 4,
  },
  {
    postcode: 5345,
    place_name: 'Loveday',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2833,
    longitude: 140.4333,
    accuracy: 4,
  },
  {
    postcode: 5346,
    place_name: 'Cobdogla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2438,
    longitude: 140.4043,
    accuracy: 4,
  },
  {
    postcode: 5350,
    place_name: 'Sandy Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5848,
    longitude: 138.8205,
    accuracy: 3,
  },
  {
    postcode: 5350,
    place_name: 'Rosedale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.55,
    longitude: 138.85,
    accuracy: 4,
  },
  {
    postcode: 5351,
    place_name: 'Cockatoo Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6248,
    longitude: 138.8365,
    accuracy: 4,
  },
  {
    postcode: 5351,
    place_name: 'Pewsey Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6313,
    longitude: 138.8964,
    accuracy: 3,
  },
  {
    postcode: 5351,
    place_name: 'Williamstown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6721,
    longitude: 138.8911,
    accuracy: 4,
  },
  {
    postcode: 5351,
    place_name: 'Lyndoch',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6012,
    longitude: 138.8909,
    accuracy: 4,
  },
  {
    postcode: 5351,
    place_name: 'Altona',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5962,
    longitude: 138.914,
    accuracy: 4,
  },
  {
    postcode: 5351,
    place_name: 'Barossa Goldfields',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6516,
    longitude: 138.8354,
    accuracy: 4,
  },
  {
    postcode: 5351,
    place_name: 'Mount Crawford',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6667,
    longitude: 138.95,
    accuracy: 4,
  },
  {
    postcode: 5352,
    place_name: 'Vine Vale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5145,
    longitude: 139.0054,
    accuracy: 4,
  },
  {
    postcode: 5352,
    place_name: 'Krondorf',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5413,
    longitude: 138.9486,
    accuracy: 3,
  },
  {
    postcode: 5352,
    place_name: 'Rowland Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5833,
    longitude: 138.9333,
    accuracy: 4,
  },
  {
    postcode: 5352,
    place_name: 'Stone Well',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5358,
    longitude: 138.8762,
    accuracy: 3,
  },
  {
    postcode: 5352,
    place_name: 'Tanunda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5234,
    longitude: 138.9598,
    accuracy: 4,
  },
  {
    postcode: 5352,
    place_name: 'Bethany',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5333,
    longitude: 138.9667,
    accuracy: 4,
  },
  {
    postcode: 5352,
    place_name: 'Gomersal',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.55,
    longitude: 138.8833,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Angaston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5013,
    longitude: 139.0462,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Langs Landing',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5798,
    longitude: 139.2255,
    accuracy: 3,
  },
  {
    postcode: 5353,
    place_name: 'Kongolia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.65,
    longitude: 139.3667,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Black Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7027,
    longitude: 139.4575,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Towitta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5333,
    longitude: 139.25,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Sunnydale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5798,
    longitude: 139.2255,
    accuracy: 3,
  },
  {
    postcode: 5353,
    place_name: 'Moculta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4687,
    longitude: 139.1186,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Cambrai',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.65,
    longitude: 139.2833,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Keyneton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5542,
    longitude: 139.1349,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Marks Landing',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5798,
    longitude: 139.2255,
    accuracy: 3,
  },
  {
    postcode: 5353,
    place_name: 'Mount Mckenzie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.565,
    longitude: 139.0884,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Sedan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5833,
    longitude: 139.3,
    accuracy: 4,
  },
  {
    postcode: 5353,
    place_name: 'Punyelroo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5798,
    longitude: 139.2255,
    accuracy: 3,
  },
  {
    postcode: 5353,
    place_name: 'Penrice',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5798,
    longitude: 139.2255,
    accuracy: 3,
  },
  {
    postcode: 5354,
    place_name: 'Bakara',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6635,
    longitude: 139.7823,
    accuracy: 4,
  },
  {
    postcode: 5354,
    place_name: 'Swan Reach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5666,
    longitude: 139.5975,
    accuracy: 4,
  },
  {
    postcode: 5354,
    place_name: 'Naidia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5167,
    longitude: 139.8167,
    accuracy: 4,
  },
  {
    postcode: 5354,
    place_name: 'Fisher',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5833,
    longitude: 139.7269,
    accuracy: 3,
  },
  {
    postcode: 5354,
    place_name: 'Bakara Well',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5833,
    longitude: 139.7269,
    accuracy: 3,
  },
  {
    postcode: 5355,
    place_name: 'Light Pass',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4716,
    longitude: 139.0229,
    accuracy: 4,
  },
  {
    postcode: 5355,
    place_name: 'Moppa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4167,
    longitude: 139.95,
    accuracy: 4,
  },
  {
    postcode: 5355,
    place_name: 'Daveyston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4692,
    longitude: 138.8815,
    accuracy: 4,
  },
  {
    postcode: 5355,
    place_name: 'Ebenezer',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4537,
    longitude: 139.1629,
    accuracy: 3,
  },
  {
    postcode: 5355,
    place_name: 'Marananga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4856,
    longitude: 138.9398,
    accuracy: 4,
  },
  {
    postcode: 5355,
    place_name: 'Nuriootpa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4682,
    longitude: 138.9977,
    accuracy: 4,
  },
  {
    postcode: 5355,
    place_name: 'Stockwell',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4346,
    longitude: 139.0536,
    accuracy: 4,
  },
  {
    postcode: 5355,
    place_name: 'Seppeltsfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4617,
    longitude: 138.9575,
    accuracy: 3,
  },
  {
    postcode: 5356,
    place_name: 'Annadale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3914,
    longitude: 139.3489,
    accuracy: 4,
  },
  {
    postcode: 5356,
    place_name: 'Truro',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4087,
    longitude: 139.1274,
    accuracy: 4,
  },
  {
    postcode: 5356,
    place_name: 'Dutton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3667,
    longitude: 139.1333,
    accuracy: 4,
  },
  {
    postcode: 5356,
    place_name: 'Dutton East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4033,
    longitude: 139.24,
    accuracy: 3,
  },
  {
    postcode: 5356,
    place_name: 'Steinfeld',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4033,
    longitude: 139.24,
    accuracy: 3,
  },
  {
    postcode: 5356,
    place_name: 'St Kitts',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4033,
    longitude: 139.24,
    accuracy: 3,
  },
  {
    postcode: 5356,
    place_name: 'Stonefield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3667,
    longitude: 139.2833,
    accuracy: 4,
  },
  {
    postcode: 5356,
    place_name: 'Sandleton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4667,
    longitude: 139.3333,
    accuracy: 4,
  },
  {
    postcode: 5357,
    place_name: 'Notts Well',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4159,
    longitude: 139.7875,
    accuracy: 4,
  },
  {
    postcode: 5357,
    place_name: 'Paisley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3844,
    longitude: 139.7604,
    accuracy: 3,
  },
  {
    postcode: 5357,
    place_name: 'Blanchetown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3531,
    longitude: 139.6145,
    accuracy: 4,
  },
  {
    postcode: 5357,
    place_name: 'Mcbean Pound',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3844,
    longitude: 139.7604,
    accuracy: 3,
  },
  {
    postcode: 5357,
    place_name: 'New Well',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3874,
    longitude: 139.9239,
    accuracy: 4,
  },
  {
    postcode: 5360,
    place_name: 'Greenock',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.457,
    longitude: 138.9272,
    accuracy: 4,
  },
  {
    postcode: 5360,
    place_name: 'Nain',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.457,
    longitude: 138.9272,
    accuracy: 3,
  },
  {
    postcode: 5371,
    place_name: 'Roseworthy',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.534,
    longitude: 138.7502,
    accuracy: 4,
  },
  {
    postcode: 5371,
    place_name: 'Shea-Oak Log',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5167,
    longitude: 138.8167,
    accuracy: 4,
  },
  {
    postcode: 5371,
    place_name: 'Morn Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5058,
    longitude: 138.7723,
    accuracy: 3,
  },
  {
    postcode: 5371,
    place_name: 'Templers',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4684,
    longitude: 138.7468,
    accuracy: 4,
  },
  {
    postcode: 5372,
    place_name: 'Freeling',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4541,
    longitude: 138.8087,
    accuracy: 4,
  },
  {
    postcode: 5373,
    place_name: 'Fords',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3853,
    longitude: 138.8749,
    accuracy: 4,
  },
  {
    postcode: 5373,
    place_name: 'Bethel',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3278,
    longitude: 138.8376,
    accuracy: 4,
  },
  {
    postcode: 5373,
    place_name: 'Hamilton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2167,
    longitude: 138.8667,
    accuracy: 4,
  },
  {
    postcode: 5373,
    place_name: 'Allendale North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3011,
    longitude: 138.9147,
    accuracy: 4,
  },
  {
    postcode: 5373,
    place_name: 'St Johns',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3259,
    longitude: 138.9,
    accuracy: 3,
  },
  {
    postcode: 5373,
    place_name: 'Koonunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3804,
    longitude: 138.9902,
    accuracy: 4,
  },
  {
    postcode: 5373,
    place_name: 'Kapunda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3387,
    longitude: 138.9164,
    accuracy: 4,
  },
  {
    postcode: 5373,
    place_name: 'Bagot Well',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3259,
    longitude: 138.9,
    accuracy: 3,
  },
  {
    postcode: 5374,
    place_name: 'Hampden',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.15,
    longitude: 139.05,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Mount Mary',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1,
    longitude: 139.4333,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Frankton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.29,
    longitude: 139.2054,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Buchanan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1453,
    longitude: 139.1626,
    accuracy: 3,
  },
  {
    postcode: 5374,
    place_name: 'Julia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1077,
    longitude: 139.0204,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Bower',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1241,
    longitude: 139.3512,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Brownlow',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2454,
    longitude: 139.2693,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Sutherlands',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1544,
    longitude: 139.2223,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Point Pass',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0757,
    longitude: 139.0506,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Eudunda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.177,
    longitude: 139.0841,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Australia Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.097,
    longitude: 139.1562,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Neales Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1453,
    longitude: 139.1626,
    accuracy: 3,
  },
  {
    postcode: 5374,
    place_name: 'Ngapala',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0714,
    longitude: 138.9917,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Peep Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1459,
    longitude: 139.1544,
    accuracy: 4,
  },
  {
    postcode: 5374,
    place_name: 'Hansborough',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1453,
    longitude: 139.1626,
    accuracy: 3,
  },
  {
    postcode: 5381,
    place_name: 'Brady Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9739,
    longitude: 138.9816,
    accuracy: 4,
  },
  {
    postcode: 5381,
    place_name: 'Robertstown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34,
    longitude: 139.0667,
    accuracy: 4,
  },
  {
    postcode: 5381,
    place_name: 'Rocky Plain',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9277,
    longitude: 139.0539,
    accuracy: 3,
  },
  {
    postcode: 5381,
    place_name: 'Worlds End',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8327,
    longitude: 139.0567,
    accuracy: 4,
  },
  {
    postcode: 5381,
    place_name: 'Hallelujah Hills',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9277,
    longitude: 139.0539,
    accuracy: 3,
  },
  {
    postcode: 5381,
    place_name: 'Geranium Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9667,
    longitude: 139.1667,
    accuracy: 4,
  },
  {
    postcode: 5381,
    place_name: 'Emu Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9,
    longitude: 138.9667,
    accuracy: 4,
  },
  {
    postcode: 5381,
    place_name: 'Bright',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9,
    longitude: 139.0667,
    accuracy: 4,
  },
  {
    postcode: 5400,
    place_name: 'Magdala',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4699,
    longitude: 138.6856,
    accuracy: 3,
  },
  {
    postcode: 5400,
    place_name: 'Pinkerton Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4485,
    longitude: 138.6731,
    accuracy: 3,
  },
  {
    postcode: 5400,
    place_name: 'Woolsheds',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4699,
    longitude: 138.6856,
    accuracy: 3,
  },
  {
    postcode: 5400,
    place_name: 'Wasleys',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4699,
    longitude: 138.6856,
    accuracy: 4,
  },
  {
    postcode: 5401,
    place_name: 'Alma',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2668,
    longitude: 138.6304,
    accuracy: 4,
  },
  {
    postcode: 5401,
    place_name: 'Hamley Bridge',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3576,
    longitude: 138.6807,
    accuracy: 4,
  },
  {
    postcode: 5401,
    place_name: 'Salter Springs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1843,
    longitude: 138.6347,
    accuracy: 4,
  },
  {
    postcode: 5410,
    place_name: 'Linwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3603,
    longitude: 138.7679,
    accuracy: 4,
  },
  {
    postcode: 5410,
    place_name: 'Stockport',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.334,
    longitude: 138.732,
    accuracy: 4,
  },
  {
    postcode: 5411,
    place_name: 'Tarlee',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2716,
    longitude: 138.7713,
    accuracy: 4,
  },
  {
    postcode: 5411,
    place_name: 'Giles Corner',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.223,
    longitude: 138.729,
    accuracy: 4,
  },
  {
    postcode: 5412,
    place_name: 'Woolshed Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1442,
    longitude: 138.7241,
    accuracy: 3,
  },
  {
    postcode: 5412,
    place_name: 'Riverton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1551,
    longitude: 138.7482,
    accuracy: 4,
  },
  {
    postcode: 5412,
    place_name: 'Navan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1442,
    longitude: 138.7241,
    accuracy: 3,
  },
  {
    postcode: 5412,
    place_name: 'Rhynie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1483,
    longitude: 138.6895,
    accuracy: 4,
  },
  {
    postcode: 5413,
    place_name: 'Marrabel',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.15,
    longitude: 138.8667,
    accuracy: 4,
  },
  {
    postcode: 5413,
    place_name: 'Waterloo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9833,
    longitude: 138.8667,
    accuracy: 4,
  },
  {
    postcode: 5413,
    place_name: 'Tothill Belt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0457,
    longitude: 138.8872,
    accuracy: 3,
  },
  {
    postcode: 5413,
    place_name: 'Apoinga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9279,
    longitude: 138.939,
    accuracy: 4,
  },
  {
    postcode: 5413,
    place_name: 'Black Springs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9,
    longitude: 138.9,
    accuracy: 4,
  },
  {
    postcode: 5413,
    place_name: 'Tarnma',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.15,
    longitude: 138.95,
    accuracy: 4,
  },
  {
    postcode: 5413,
    place_name: 'Saddleworth',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0826,
    longitude: 138.7806,
    accuracy: 4,
  },
  {
    postcode: 5413,
    place_name: 'Steelton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.05,
    longitude: 138.8833,
    accuracy: 4,
  },
  {
    postcode: 5413,
    place_name: 'Tothill Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1,
    longitude: 138.9167,
    accuracy: 4,
  },
  {
    postcode: 5414,
    place_name: 'Manoora',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34,
    longitude: 138.8167,
    accuracy: 4,
  },
  {
    postcode: 5415,
    place_name: 'Stanley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9167,
    longitude: 138.7167,
    accuracy: 3,
  },
  {
    postcode: 5415,
    place_name: 'Mintaro',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9173,
    longitude: 138.7215,
    accuracy: 4,
  },
  {
    postcode: 5416,
    place_name: 'Farrell Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8295,
    longitude: 138.7927,
    accuracy: 1,
  },
  {
    postcode: 5416,
    place_name: 'Porter Lagoon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8857,
    longitude: 138.8563,
    accuracy: 1,
  },
  {
    postcode: 5417,
    place_name: 'Mongolata',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5667,
    longitude: 139.1,
    accuracy: 4,
  },
  {
    postcode: 5417,
    place_name: 'Burra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6717,
    longitude: 138.9283,
    accuracy: 4,
  },
  {
    postcode: 5417,
    place_name: 'Leighton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.65,
    longitude: 138.75,
    accuracy: 4,
  },
  {
    postcode: 5417,
    place_name: 'Koonoona',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6138,
    longitude: 138.8821,
    accuracy: 3,
  },
  {
    postcode: 5417,
    place_name: 'Booborowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5667,
    longitude: 138.75,
    accuracy: 4,
  },
  {
    postcode: 5417,
    place_name: 'Gum Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6138,
    longitude: 138.8821,
    accuracy: 3,
  },
  {
    postcode: 5417,
    place_name: 'Baldina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6138,
    longitude: 138.8821,
    accuracy: 3,
  },
  {
    postcode: 5417,
    place_name: 'Hanson',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6138,
    longitude: 138.8821,
    accuracy: 3,
  },
  {
    postcode: 5417,
    place_name: 'North Booborowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6138,
    longitude: 138.8821,
    accuracy: 3,
  },
  {
    postcode: 5417,
    place_name: 'Burra Eastern Districts',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6138,
    longitude: 138.8821,
    accuracy: 3,
  },
  {
    postcode: 5418,
    place_name: 'Mount Bryan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4047,
    longitude: 139.0184,
    accuracy: 4,
  },
  {
    postcode: 5418,
    place_name: 'Collinsville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3333,
    longitude: 139.15,
    accuracy: 4,
  },
  {
    postcode: 5419,
    place_name: 'Hallett',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4,
    longitude: 138.8833,
    accuracy: 4,
  },
  {
    postcode: 5419,
    place_name: 'Canowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4,
    longitude: 138.75,
    accuracy: 4,
  },
  {
    postcode: 5419,
    place_name: 'Mount Bryan East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4047,
    longitude: 139.0184,
    accuracy: 4,
  },
  {
    postcode: 5419,
    place_name: 'Wonna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4,
    longitude: 138.8833,
    accuracy: 3,
  },
  {
    postcode: 5419,
    place_name: 'Willalo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4,
    longitude: 138.8833,
    accuracy: 3,
  },
  {
    postcode: 5419,
    place_name: 'Pine Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4,
    longitude: 138.8833,
    accuracy: 3,
  },
  {
    postcode: 5419,
    place_name: 'Ulooloo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4,
    longitude: 138.8833,
    accuracy: 3,
  },
  {
    postcode: 5420,
    place_name: 'Canowie Belt',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1826,
    longitude: 138.7625,
    accuracy: 4,
  },
  {
    postcode: 5420,
    place_name: 'Whyte Yarcowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.2047,
    longitude: 139.0367,
    accuracy: 3,
  },
  {
    postcode: 5421,
    place_name: 'Franklyn',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1352,
    longitude: 139.0737,
    accuracy: 4,
  },
  {
    postcode: 5421,
    place_name: 'Terowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1667,
    longitude: 138.9,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Ucolta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.95,
    longitude: 138.9667,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Yatina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9333,
    longitude: 138.6667,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Peterborough',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9721,
    longitude: 138.8407,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Erskine',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7333,
    longitude: 138.85,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Mannanarie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0443,
    longitude: 138.6185,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Hardy',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8905,
    longitude: 138.9224,
    accuracy: 3,
  },
  {
    postcode: 5422,
    place_name: 'Paratoo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7326,
    longitude: 139.3366,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Minvalara',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9,
    longitude: 138.75,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Parnaroo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9786,
    longitude: 139.1553,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Oodla Wirra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8833,
    longitude: 139.0667,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Dawson',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8,
    longitude: 138.9667,
    accuracy: 4,
  },
  {
    postcode: 5422,
    place_name: 'Cavenagh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8905,
    longitude: 138.9224,
    accuracy: 3,
  },
  {
    postcode: 5422,
    place_name: 'Sunnybrae',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8905,
    longitude: 138.9224,
    accuracy: 3,
  },
  {
    postcode: 5431,
    place_name: 'Orroroo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7344,
    longitude: 138.6139,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Black Rock',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8333,
    longitude: 138.7,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Amyton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.6,
    longitude: 138.3333,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Minburra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.6695,
    longitude: 138.5539,
    accuracy: 3,
  },
  {
    postcode: 5431,
    place_name: 'Walloway',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.6333,
    longitude: 138.5833,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Willowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.6833,
    longitude: 138.3333,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Johnburgh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.45,
    longitude: 138.7,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Coomooroo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.6695,
    longitude: 138.5539,
    accuracy: 3,
  },
  {
    postcode: 5431,
    place_name: 'Hammond',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5167,
    longitude: 138.3167,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Eurelia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.55,
    longitude: 138.5667,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Pekina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8333,
    longitude: 138.55,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Yalpara',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5333,
    longitude: 138.9333,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Tarcowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.95,
    longitude: 138.5167,
    accuracy: 4,
  },
  {
    postcode: 5431,
    place_name: 'Morchard',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7167,
    longitude: 138.5,
    accuracy: 4,
  },
  {
    postcode: 5432,
    place_name: 'Yanyarrie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.3042,
    longitude: 138.5417,
    accuracy: 3,
  },
  {
    postcode: 5432,
    place_name: 'Carrieton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4333,
    longitude: 138.5333,
    accuracy: 4,
  },
  {
    postcode: 5432,
    place_name: 'Belton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.2333,
    longitude: 138.7,
    accuracy: 4,
  },
  {
    postcode: 5432,
    place_name: 'Cradock',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.0833,
    longitude: 138.5,
    accuracy: 4,
  },
  {
    postcode: 5432,
    place_name: 'Moockra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4667,
    longitude: 138.4333,
    accuracy: 4,
  },
  {
    postcode: 5433,
    place_name: 'Quorn',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.3468,
    longitude: 138.0418,
    accuracy: 4,
  },
  {
    postcode: 5433,
    place_name: 'Stephenston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.3489,
    longitude: 138.125,
    accuracy: 3,
  },
  {
    postcode: 5433,
    place_name: 'Willochra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.2333,
    longitude: 138.1333,
    accuracy: 4,
  },
  {
    postcode: 5433,
    place_name: 'Yarrah',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.3489,
    longitude: 138.125,
    accuracy: 3,
  },
  {
    postcode: 5433,
    place_name: 'Bruce',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4667,
    longitude: 138.2,
    accuracy: 4,
  },
  {
    postcode: 5433,
    place_name: 'Saltia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.3489,
    longitude: 138.125,
    accuracy: 3,
  },
  {
    postcode: 5434,
    place_name: 'Hawker',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.8892,
    longitude: 138.42,
    accuracy: 4,
  },
  {
    postcode: 5434,
    place_name: 'Barndioota',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.8892,
    longitude: 138.42,
    accuracy: 3,
  },
  {
    postcode: 5434,
    place_name: 'Kanyaka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.8892,
    longitude: 138.42,
    accuracy: 3,
  },
  {
    postcode: 5440,
    place_name: 'Waukaringa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.3,
    longitude: 139.4333,
    accuracy: 4,
  },
  {
    postcode: 5440,
    place_name: 'Yunta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5833,
    longitude: 139.55,
    accuracy: 4,
  },
  {
    postcode: 5440,
    place_name: 'Nackara',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8,
    longitude: 139.2333,
    accuracy: 4,
  },
  {
    postcode: 5440,
    place_name: 'Mingary',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.1355,
    longitude: 140.7367,
    accuracy: 4,
  },
  {
    postcode: 5440,
    place_name: 'Olary',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.2829,
    longitude: 140.3311,
    accuracy: 4,
  },
  {
    postcode: 5440,
    place_name: 'Cockburn',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.0762,
    longitude: 140.9902,
    accuracy: 4,
  },
  {
    postcode: 5440,
    place_name: 'Manna Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.433,
    longitude: 139.9903,
    accuracy: 4,
  },
  {
    postcode: 5451,
    place_name: 'Auburn',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0269,
    longitude: 138.6852,
    accuracy: 4,
  },
  {
    postcode: 5451,
    place_name: 'Undalya',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0667,
    longitude: 138.6833,
    accuracy: 4,
  },
  {
    postcode: 5452,
    place_name: 'Watervale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9942,
    longitude: 138.6248,
    accuracy: 1,
  },
  {
    postcode: 5452,
    place_name: 'Leasingham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9942,
    longitude: 138.6248,
    accuracy: 1,
  },
  {
    postcode: 5453,
    place_name: 'Armagh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.825,
    longitude: 138.5748,
    accuracy: 4,
  },
  {
    postcode: 5453,
    place_name: 'Hill River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Clare',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8332,
    longitude: 138.6106,
    accuracy: 4,
  },
  {
    postcode: 5453,
    place_name: 'Spring Farm',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Hoyleton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0333,
    longitude: 138.55,
    accuracy: 4,
  },
  {
    postcode: 5453,
    place_name: 'Stanley Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Barinia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Sevenhill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Boconnoc Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Kybunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Penwortham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Emu Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Spring Gully',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Benbournie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Gillentown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5453,
    place_name: 'Polish Hill River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8972,
    longitude: 138.5785,
    accuracy: 3,
  },
  {
    postcode: 5454,
    place_name: 'Spalding',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4979,
    longitude: 138.6075,
    accuracy: 4,
  },
  {
    postcode: 5454,
    place_name: 'Mayfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0871,
    longitude: 138.6347,
    accuracy: 3,
  },
  {
    postcode: 5454,
    place_name: 'Andrews',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6763,
    longitude: 138.662,
    accuracy: 4,
  },
  {
    postcode: 5454,
    place_name: 'Hacklins Corner',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0871,
    longitude: 138.6347,
    accuracy: 3,
  },
  {
    postcode: 5454,
    place_name: 'Washpool',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0871,
    longitude: 138.6347,
    accuracy: 3,
  },
  {
    postcode: 5454,
    place_name: 'Euromina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0871,
    longitude: 138.6347,
    accuracy: 3,
  },
  {
    postcode: 5454,
    place_name: 'Broughton River Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0871,
    longitude: 138.6347,
    accuracy: 3,
  },
  {
    postcode: 5455,
    place_name: 'Hilltown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8458,
    longitude: 138.6125,
    accuracy: 1,
  },
  {
    postcode: 5460,
    place_name: 'Pinery',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3,
    longitude: 138.45,
    accuracy: 4,
  },
  {
    postcode: 5460,
    place_name: 'Owen',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.272,
    longitude: 138.5444,
    accuracy: 4,
  },
  {
    postcode: 5460,
    place_name: 'Barabba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.345,
    longitude: 138.5899,
    accuracy: 4,
  },
  {
    postcode: 5460,
    place_name: 'Stockyard Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3003,
    longitude: 138.5852,
    accuracy: 4,
  },
  {
    postcode: 5461,
    place_name: 'Goyder',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0851,
    longitude: 138.3993,
    accuracy: 3,
  },
  {
    postcode: 5461,
    place_name: 'Stow',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9833,
    longitude: 138.3667,
    accuracy: 4,
  },
  {
    postcode: 5461,
    place_name: 'Whitwarta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1,
    longitude: 138.3333,
    accuracy: 4,
  },
  {
    postcode: 5461,
    place_name: 'Saints',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.15,
    longitude: 138.3167,
    accuracy: 4,
  },
  {
    postcode: 5461,
    place_name: 'Hoskin Corner',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0851,
    longitude: 138.3993,
    accuracy: 3,
  },
  {
    postcode: 5461,
    place_name: 'Bowillia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.95,
    longitude: 138.4,
    accuracy: 4,
  },
  {
    postcode: 5461,
    place_name: 'Halbury',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0833,
    longitude: 138.5167,
    accuracy: 4,
  },
  {
    postcode: 5461,
    place_name: 'Erith',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0851,
    longitude: 138.3993,
    accuracy: 3,
  },
  {
    postcode: 5461,
    place_name: 'Dalkey',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1868,
    longitude: 138.4384,
    accuracy: 4,
  },
  {
    postcode: 5461,
    place_name: 'Mount Templeton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0851,
    longitude: 138.3993,
    accuracy: 3,
  },
  {
    postcode: 5461,
    place_name: 'Balaklava',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1454,
    longitude: 138.4115,
    accuracy: 4,
  },
  {
    postcode: 5461,
    place_name: 'Watchman',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0851,
    longitude: 138.3993,
    accuracy: 3,
  },
  {
    postcode: 5461,
    place_name: 'Everard Central',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0851,
    longitude: 138.3993,
    accuracy: 3,
  },
  {
    postcode: 5462,
    place_name: 'Blyth',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8486,
    longitude: 138.4893,
    accuracy: 4,
  },
  {
    postcode: 5464,
    place_name: 'Anama',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7,
    longitude: 138.4167,
    accuracy: 3,
  },
  {
    postcode: 5464,
    place_name: 'Condowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7667,
    longitude: 138.3,
    accuracy: 4,
  },
  {
    postcode: 5464,
    place_name: 'Hart',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7,
    longitude: 138.4167,
    accuracy: 3,
  },
  {
    postcode: 5464,
    place_name: 'Rochester',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6952,
    longitude: 138.4853,
    accuracy: 4,
  },
  {
    postcode: 5464,
    place_name: 'Koolunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5833,
    longitude: 138.3333,
    accuracy: 4,
  },
  {
    postcode: 5464,
    place_name: 'Bungaree',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7443,
    longitude: 138.5609,
    accuracy: 4,
  },
  {
    postcode: 5464,
    place_name: 'Marola',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7,
    longitude: 138.4167,
    accuracy: 3,
  },
  {
    postcode: 5464,
    place_name: 'Brinkworth',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6954,
    longitude: 138.4035,
    accuracy: 4,
  },
  {
    postcode: 5470,
    place_name: 'Yacka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5514,
    longitude: 138.4805,
    accuracy: 1,
  },
  {
    postcode: 5471,
    place_name: 'Gulnare',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4672,
    longitude: 138.443,
    accuracy: 4,
  },
  {
    postcode: 5472,
    place_name: 'Georgetown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3667,
    longitude: 138.4,
    accuracy: 4,
  },
  {
    postcode: 5473,
    place_name: 'Gladstone',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.2669,
    longitude: 138.3566,
    accuracy: 4,
  },
  {
    postcode: 5480,
    place_name: 'Stone Hut',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1176,
    longitude: 138.3595,
    accuracy: 3,
  },
  {
    postcode: 5480,
    place_name: 'Appila',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.05,
    longitude: 138.4167,
    accuracy: 4,
  },
  {
    postcode: 5480,
    place_name: 'Laura',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1852,
    longitude: 138.3023,
    accuracy: 4,
  },
  {
    postcode: 5481,
    place_name: 'Wirrabara',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0338,
    longitude: 138.2692,
    accuracy: 4,
  },
  {
    postcode: 5481,
    place_name: 'Wongyarra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9779,
    longitude: 138.2175,
    accuracy: 3,
  },
  {
    postcode: 5481,
    place_name: 'Murray Town',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9333,
    longitude: 138.2333,
    accuracy: 4,
  },
  {
    postcode: 5481,
    place_name: 'Bangor',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9667,
    longitude: 138.15,
    accuracy: 4,
  },
  {
    postcode: 5482,
    place_name: 'Wepowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8333,
    longitude: 138.4833,
    accuracy: 4,
  },
  {
    postcode: 5482,
    place_name: 'Booleroo Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8799,
    longitude: 138.3507,
    accuracy: 4,
  },
  {
    postcode: 5483,
    place_name: 'Melrose',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8253,
    longitude: 138.1863,
    accuracy: 4,
  },
  {
    postcode: 5485,
    place_name: 'Wilmington',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.6499,
    longitude: 138.0981,
    accuracy: 4,
  },
  {
    postcode: 5490,
    place_name: 'Caltowie North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1833,
    longitude: 138.4833,
    accuracy: 3,
  },
  {
    postcode: 5490,
    place_name: 'Caltowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1833,
    longitude: 138.4833,
    accuracy: 4,
  },
  {
    postcode: 5490,
    place_name: 'Caltowie West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1833,
    longitude: 138.4833,
    accuracy: 3,
  },
  {
    postcode: 5491,
    place_name: 'Hornsdale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0681,
    longitude: 138.5217,
    accuracy: 4,
  },
  {
    postcode: 5491,
    place_name: 'Bundaleer North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.136,
    longitude: 138.5608,
    accuracy: 3,
  },
  {
    postcode: 5491,
    place_name: 'Jamestown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.2053,
    longitude: 138.605,
    accuracy: 4,
  },
  {
    postcode: 5491,
    place_name: 'Bundaleer Gardens',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.136,
    longitude: 138.5608,
    accuracy: 3,
  },
  {
    postcode: 5491,
    place_name: 'Belalie North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.136,
    longitude: 138.5608,
    accuracy: 3,
  },
  {
    postcode: 5491,
    place_name: 'West Bundaleer',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.136,
    longitude: 138.5608,
    accuracy: 3,
  },
  {
    postcode: 5491,
    place_name: 'Belalie East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.136,
    longitude: 138.5608,
    accuracy: 3,
  },
  {
    postcode: 5493,
    place_name: 'Yongala',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9167,
    longitude: 137.55,
    accuracy: 4,
  },
  {
    postcode: 5495,
    place_name: 'Baroota',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.85,
    longitude: 138.0333,
    accuracy: 4,
  },
  {
    postcode: 5495,
    place_name: 'Port Germein',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0216,
    longitude: 138.0013,
    accuracy: 4,
  },
  {
    postcode: 5495,
    place_name: 'Mambray Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8,
    longitude: 137.9833,
    accuracy: 4,
  },
  {
    postcode: 5495,
    place_name: 'Nectar Brook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7333,
    longitude: 137.9333,
    accuracy: 4,
  },
  {
    postcode: 5495,
    place_name: 'Port Flinders',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1014,
    longitude: 138.0357,
    accuracy: 3,
  },
  {
    postcode: 5495,
    place_name: 'Germein Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8512,
    longitude: 137.9878,
    accuracy: 3,
  },
  {
    postcode: 5501,
    place_name: 'Parham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4333,
    longitude: 138.2667,
    accuracy: 4,
  },
  {
    postcode: 5501,
    place_name: 'Webb Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4493,
    longitude: 138.3886,
    accuracy: 3,
  },
  {
    postcode: 5501,
    place_name: 'Dublin',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.45,
    longitude: 138.35,
    accuracy: 4,
  },
  {
    postcode: 5501,
    place_name: 'Calomba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4493,
    longitude: 138.3886,
    accuracy: 3,
  },
  {
    postcode: 5501,
    place_name: 'Wild Horse Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3601,
    longitude: 138.2933,
    accuracy: 4,
  },
  {
    postcode: 5501,
    place_name: 'Port Gawler',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.504,
    longitude: 138.4302,
    accuracy: 3,
  },
  {
    postcode: 5501,
    place_name: 'Lower Light',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5326,
    longitude: 138.437,
    accuracy: 4,
  },
  {
    postcode: 5501,
    place_name: 'Middle Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.504,
    longitude: 138.4302,
    accuracy: 3,
  },
  {
    postcode: 5501,
    place_name: 'Lewiston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6045,
    longitude: 138.5913,
    accuracy: 4,
  },
  {
    postcode: 5501,
    place_name: 'Thompson Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4493,
    longitude: 138.3886,
    accuracy: 3,
  },
  {
    postcode: 5501,
    place_name: 'Long Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.359,
    longitude: 138.3783,
    accuracy: 4,
  },
  {
    postcode: 5501,
    place_name: 'Windsor',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4219,
    longitude: 138.3308,
    accuracy: 4,
  },
  {
    postcode: 5501,
    place_name: 'Avon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2839,
    longitude: 138.336,
    accuracy: 4,
  },
  {
    postcode: 5501,
    place_name: 'Two Wells',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5933,
    longitude: 138.5137,
    accuracy: 4,
  },
  {
    postcode: 5502,
    place_name: 'Mallala',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4374,
    longitude: 138.5099,
    accuracy: 4,
  },
  {
    postcode: 5502,
    place_name: 'Korunye',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5124,
    longitude: 138.5102,
    accuracy: 4,
  },
  {
    postcode: 5502,
    place_name: 'Grace Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4902,
    longitude: 138.5422,
    accuracy: 3,
  },
  {
    postcode: 5502,
    place_name: 'Reeves Plains',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5167,
    longitude: 138.6,
    accuracy: 4,
  },
  {
    postcode: 5502,
    place_name: 'Redbanks',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4902,
    longitude: 138.5422,
    accuracy: 3,
  },
  {
    postcode: 5502,
    place_name: 'Fischer',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4902,
    longitude: 138.5422,
    accuracy: 3,
  },
  {
    postcode: 5510,
    place_name: 'Lochiel',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9333,
    longitude: 138.1667,
    accuracy: 4,
  },
  {
    postcode: 5520,
    place_name: 'Bumbunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9167,
    longitude: 138.2333,
    accuracy: 4,
  },
  {
    postcode: 5520,
    place_name: 'Burnsfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7327,
    longitude: 138.1419,
    accuracy: 3,
  },
  {
    postcode: 5520,
    place_name: 'Wokurna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7167,
    longitude: 138.05,
    accuracy: 4,
  },
  {
    postcode: 5520,
    place_name: 'Barunga Gap',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5241,
    longitude: 138.0404,
    accuracy: 4,
  },
  {
    postcode: 5520,
    place_name: 'Snowtown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7808,
    longitude: 138.2175,
    accuracy: 4,
  },
  {
    postcode: 5521,
    place_name: 'Redhill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5333,
    longitude: 138.2333,
    accuracy: 4,
  },
  {
    postcode: 5522,
    place_name: 'Fisherman Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5594,
    longitude: 137.9429,
    accuracy: 4,
  },
  {
    postcode: 5522,
    place_name: 'Ward Hill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5801,
    longitude: 137.9394,
    accuracy: 3,
  },
  {
    postcode: 5522,
    place_name: 'Port Broughton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6007,
    longitude: 137.936,
    accuracy: 4,
  },
  {
    postcode: 5523,
    place_name: 'Wandearah West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3927,
    longitude: 137.9731,
    accuracy: 4,
  },
  {
    postcode: 5523,
    place_name: 'Merriton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.45,
    longitude: 138.15,
    accuracy: 4,
  },
  {
    postcode: 5523,
    place_name: 'Crystal Brook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3526,
    longitude: 138.2095,
    accuracy: 4,
  },
  {
    postcode: 5523,
    place_name: 'Clements Gap',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5241,
    longitude: 138.0404,
    accuracy: 4,
  },
  {
    postcode: 5523,
    place_name: 'Beetaloo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.2333,
    longitude: 138.2333,
    accuracy: 4,
  },
  {
    postcode: 5523,
    place_name: 'Narridy',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4333,
    longitude: 138.3,
    accuracy: 4,
  },
  {
    postcode: 5523,
    place_name: 'Huddleston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3949,
    longitude: 138.171,
    accuracy: 3,
  },
  {
    postcode: 5523,
    place_name: 'Wandearah East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3833,
    longitude: 138.0667,
    accuracy: 4,
  },
  {
    postcode: 5523,
    place_name: 'Nurom',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3949,
    longitude: 138.171,
    accuracy: 3,
  },
  {
    postcode: 5523,
    place_name: 'Wandearah',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3949,
    longitude: 138.171,
    accuracy: 3,
  },
  {
    postcode: 5540,
    place_name: 'Bungama',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.189,
    longitude: 138.0451,
    accuracy: 3,
  },
  {
    postcode: 5540,
    place_name: 'Pirie East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3833,
    longitude: 138.0667,
    accuracy: 4,
  },
  {
    postcode: 5540,
    place_name: 'Risdon Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1966,
    longitude: 137.994,
    accuracy: 4,
  },
  {
    postcode: 5540,
    place_name: 'Port Davis',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.2667,
    longitude: 137.8541,
    accuracy: 4,
  },
  {
    postcode: 5540,
    place_name: 'Port Pirie South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1918,
    longitude: 138.0175,
    accuracy: 3,
  },
  {
    postcode: 5540,
    place_name: 'Coonamia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.189,
    longitude: 138.0451,
    accuracy: 3,
  },
  {
    postcode: 5540,
    place_name: 'Nelshaby',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1261,
    longitude: 138.1105,
    accuracy: 4,
  },
  {
    postcode: 5540,
    place_name: 'Port Pirie West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3927,
    longitude: 137.9731,
    accuracy: 4,
  },
  {
    postcode: 5540,
    place_name: 'Port Pirie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1918,
    longitude: 138.0175,
    accuracy: 4,
  },
  {
    postcode: 5540,
    place_name: 'Risdon Park South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1966,
    longitude: 137.994,
    accuracy: 4,
  },
  {
    postcode: 5540,
    place_name: 'Solomontown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.2,
    longitude: 138.0333,
    accuracy: 4,
  },
  {
    postcode: 5540,
    place_name: 'Warnertown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.189,
    longitude: 138.0451,
    accuracy: 3,
  },
  {
    postcode: 5540,
    place_name: 'Telowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0549,
    longitude: 138.0684,
    accuracy: 4,
  },
  {
    postcode: 5540,
    place_name: 'Lower Broughton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.189,
    longitude: 138.0451,
    accuracy: 3,
  },
  {
    postcode: 5540,
    place_name: 'Napperby',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1574,
    longitude: 138.1164,
    accuracy: 4,
  },
  {
    postcode: 5550,
    place_name: 'Inkerman',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2799,
    longitude: 138.2378,
    accuracy: 4,
  },
  {
    postcode: 5550,
    place_name: 'Beaufort',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0833,
    longitude: 138.2,
    accuracy: 4,
  },
  {
    postcode: 5550,
    place_name: 'Bowmans',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1589,
    longitude: 138.2594,
    accuracy: 4,
  },
  {
    postcode: 5550,
    place_name: 'Nantawarra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0167,
    longitude: 138.2333,
    accuracy: 4,
  },
  {
    postcode: 5550,
    place_name: 'Port Wakefield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1848,
    longitude: 138.1453,
    accuracy: 4,
  },
  {
    postcode: 5550,
    place_name: 'Kallora',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1505,
    longitude: 138.2203,
    accuracy: 3,
  },
  {
    postcode: 5550,
    place_name: 'Proof Range',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1505,
    longitude: 138.2203,
    accuracy: 3,
  },
  {
    postcode: 5550,
    place_name: 'South Hummocks',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1505,
    longitude: 138.2203,
    accuracy: 3,
  },
  {
    postcode: 5552,
    place_name: 'Paskeville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0333,
    longitude: 137.9,
    accuracy: 4,
  },
  {
    postcode: 5552,
    place_name: 'Melton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0722,
    longitude: 137.9444,
    accuracy: 3,
  },
  {
    postcode: 5552,
    place_name: 'Thrington',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0131,
    longitude: 137.8294,
    accuracy: 4,
  },
  {
    postcode: 5552,
    place_name: 'Sunnyvale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0722,
    longitude: 137.9444,
    accuracy: 3,
  },
  {
    postcode: 5552,
    place_name: 'Kainton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1233,
    longitude: 137.9028,
    accuracy: 4,
  },
  {
    postcode: 5552,
    place_name: 'Port Arthur',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0722,
    longitude: 137.9444,
    accuracy: 3,
  },
  {
    postcode: 5552,
    place_name: 'Kulpara',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0667,
    longitude: 138.0333,
    accuracy: 4,
  },
  {
    postcode: 5554,
    place_name: 'Matta Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9199,
    longitude: 137.6638,
    accuracy: 3,
  },
  {
    postcode: 5554,
    place_name: 'Wallaroo Mines',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9725,
    longitude: 137.6939,
    accuracy: 4,
  },
  {
    postcode: 5554,
    place_name: 'Jerusalem',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9735,
    longitude: 137.711,
    accuracy: 3,
  },
  {
    postcode: 5554,
    place_name: 'Kadina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.965,
    longitude: 137.7163,
    accuracy: 4,
  },
  {
    postcode: 5554,
    place_name: 'Willamulka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.005,
    longitude: 137.711,
    accuracy: 3,
  },
  {
    postcode: 5554,
    place_name: 'Thomas Plain',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9637,
    longitude: 137.9278,
    accuracy: 4,
  },
  {
    postcode: 5554,
    place_name: 'Boors Plain',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.005,
    longitude: 137.711,
    accuracy: 3,
  },
  {
    postcode: 5554,
    place_name: 'Cunliffe',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0833,
    longitude: 137.7333,
    accuracy: 4,
  },
  {
    postcode: 5554,
    place_name: 'New Town',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9199,
    longitude: 137.6638,
    accuracy: 3,
  },
  {
    postcode: 5554,
    place_name: 'Jericho',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9199,
    longitude: 137.6638,
    accuracy: 3,
  },
  {
    postcode: 5555,
    place_name: 'Tickera',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7833,
    longitude: 137.7,
    accuracy: 4,
  },
  {
    postcode: 5555,
    place_name: 'Mundoora',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6,
    longitude: 138.0833,
    accuracy: 4,
  },
  {
    postcode: 5555,
    place_name: 'Alford',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8167,
    longitude: 137.8167,
    accuracy: 4,
  },
  {
    postcode: 5555,
    place_name: 'Collinsfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7333,
    longitude: 137.8667,
    accuracy: 3,
  },
  {
    postcode: 5555,
    place_name: 'Lake View',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8287,
    longitude: 138.0507,
    accuracy: 3,
  },
  {
    postcode: 5555,
    place_name: 'Hope Gap',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7333,
    longitude: 137.8667,
    accuracy: 3,
  },
  {
    postcode: 5556,
    place_name: 'North Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9029,
    longitude: 137.6329,
    accuracy: 4,
  },
  {
    postcode: 5556,
    place_name: 'Warburto',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34,
    longitude: 137.6167,
    accuracy: 4,
  },
  {
    postcode: 5556,
    place_name: 'Wallaroo Plain',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9472,
    longitude: 137.6277,
    accuracy: 3,
  },
  {
    postcode: 5556,
    place_name: 'Wallaroo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9388,
    longitude: 137.6337,
    accuracy: 4,
  },
  {
    postcode: 5558,
    place_name: 'Moonta Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.05,
    longitude: 137.5667,
    accuracy: 4,
  },
  {
    postcode: 5558,
    place_name: 'Moonta Mines',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0779,
    longitude: 137.6086,
    accuracy: 3,
  },
  {
    postcode: 5558,
    place_name: 'Moonta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0684,
    longitude: 137.5908,
    accuracy: 4,
  },
  {
    postcode: 5558,
    place_name: 'Hamley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3576,
    longitude: 138.6807,
    accuracy: 4,
  },
  {
    postcode: 5558,
    place_name: 'Paramatta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1174,
    longitude: 137.769,
    accuracy: 3,
  },
  {
    postcode: 5558,
    place_name: 'East Moonta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1127,
    longitude: 137.6609,
    accuracy: 3,
  },
  {
    postcode: 5558,
    place_name: 'Agery',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1568,
    longitude: 137.7429,
    accuracy: 4,
  },
  {
    postcode: 5558,
    place_name: 'Kooroona',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1127,
    longitude: 137.6609,
    accuracy: 3,
  },
  {
    postcode: 5558,
    place_name: 'Port Hughes',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0833,
    longitude: 137.55,
    accuracy: 4,
  },
  {
    postcode: 5558,
    place_name: 'Nalyappa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1127,
    longitude: 137.6609,
    accuracy: 3,
  },
  {
    postcode: 5558,
    place_name: 'North Yelta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0616,
    longitude: 137.6321,
    accuracy: 4,
  },
  {
    postcode: 5558,
    place_name: 'North Moonta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0528,
    longitude: 137.5931,
    accuracy: 3,
  },
  {
    postcode: 5558,
    place_name: 'Yelta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0616,
    longitude: 137.6321,
    accuracy: 4,
  },
  {
    postcode: 5558,
    place_name: 'Cross Roads',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1127,
    longitude: 137.6609,
    accuracy: 3,
  },
  {
    postcode: 5560,
    place_name: 'Bute',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8656,
    longitude: 138.0066,
    accuracy: 4,
  },
  {
    postcode: 5560,
    place_name: 'Ninnes',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8656,
    longitude: 138.0066,
    accuracy: 3,
  },
  {
    postcode: 5570,
    place_name: 'Winulta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2763,
    longitude: 137.8728,
    accuracy: 4,
  },
  {
    postcode: 5570,
    place_name: 'Port Clinton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2243,
    longitude: 138.0206,
    accuracy: 4,
  },
  {
    postcode: 5570,
    place_name: 'Price',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2864,
    longitude: 137.9943,
    accuracy: 4,
  },
  {
    postcode: 5570,
    place_name: 'Clinton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.183,
    longitude: 137.9648,
    accuracy: 3,
  },
  {
    postcode: 5570,
    place_name: 'Clinton Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2333,
    longitude: 137.9167,
    accuracy: 4,
  },
  {
    postcode: 5571,
    place_name: 'Ardrossan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4222,
    longitude: 137.9191,
    accuracy: 4,
  },
  {
    postcode: 5571,
    place_name: 'Pine Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5667,
    longitude: 137.8667,
    accuracy: 4,
  },
  {
    postcode: 5571,
    place_name: 'Tiddy Widdy Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4014,
    longitude: 137.9402,
    accuracy: 4,
  },
  {
    postcode: 5571,
    place_name: 'Black Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4481,
    longitude: 137.8819,
    accuracy: 3,
  },
  {
    postcode: 5571,
    place_name: 'Sandilands',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5138,
    longitude: 137.7932,
    accuracy: 4,
  },
  {
    postcode: 5571,
    place_name: 'Petersville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4481,
    longitude: 137.8819,
    accuracy: 3,
  },
  {
    postcode: 5571,
    place_name: 'Dowlingville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.327,
    longitude: 137.9193,
    accuracy: 4,
  },
  {
    postcode: 5571,
    place_name: 'Rogues Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4481,
    longitude: 137.8819,
    accuracy: 3,
  },
  {
    postcode: 5571,
    place_name: 'Cunningham',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4148,
    longitude: 137.7995,
    accuracy: 4,
  },
  {
    postcode: 5571,
    place_name: 'James Well',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4481,
    longitude: 137.8819,
    accuracy: 3,
  },
  {
    postcode: 5572,
    place_name: 'Arthurton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2667,
    longitude: 137.75,
    accuracy: 4,
  },
  {
    postcode: 5573,
    place_name: 'Yorke Valley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1494,
    longitude: 137.1512,
    accuracy: 4,
  },
  {
    postcode: 5573,
    place_name: 'Point Pearce',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4174,
    longitude: 137.5005,
    accuracy: 3,
  },
  {
    postcode: 5573,
    place_name: 'South Kilkerran',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4333,
    longitude: 137.6,
    accuracy: 4,
  },
  {
    postcode: 5573,
    place_name: 'Chinaman Wells',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3811,
    longitude: 137.4805,
    accuracy: 4,
  },
  {
    postcode: 5573,
    place_name: 'Weetulta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.25,
    longitude: 137.6333,
    accuracy: 4,
  },
  {
    postcode: 5573,
    place_name: 'Balgowan',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3333,
    longitude: 137.4833,
    accuracy: 4,
  },
  {
    postcode: 5573,
    place_name: 'Port Victoria',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4972,
    longitude: 137.4853,
    accuracy: 4,
  },
  {
    postcode: 5573,
    place_name: 'Maitland',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3739,
    longitude: 137.6733,
    accuracy: 4,
  },
  {
    postcode: 5573,
    place_name: 'Urania',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.511,
    longitude: 137.6064,
    accuracy: 4,
  },
  {
    postcode: 5573,
    place_name: 'Wauraltee',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5833,
    longitude: 137.55,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Hardwicke Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8888,
    longitude: 137.458,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Bluff Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7325,
    longitude: 137.4807,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Point Turton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9523,
    longitude: 137.3565,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Stenhouse Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2833,
    longitude: 136.9333,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Port Rickaby',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6833,
    longitude: 137.5,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Wool Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9911,
    longitude: 137.7567,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Brentwood',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8667,
    longitude: 137.5,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'White Hut',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9243,
    longitude: 137.3787,
    accuracy: 3,
  },
  {
    postcode: 5575,
    place_name: 'Koolywurtie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.65,
    longitude: 137.6333,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Minlaton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.771,
    longitude: 137.5958,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Corny Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9249,
    longitude: 137.0812,
    accuracy: 4,
  },
  {
    postcode: 5575,
    place_name: 'Ramsay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9243,
    longitude: 137.3787,
    accuracy: 3,
  },
  {
    postcode: 5575,
    place_name: 'Marion Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.2333,
    longitude: 137.0167,
    accuracy: 4,
  },
  {
    postcode: 5576,
    place_name: 'Honiton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1167,
    longitude: 137.65,
    accuracy: 4,
  },
  {
    postcode: 5576,
    place_name: 'Port Moorowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1167,
    longitude: 137.5167,
    accuracy: 4,
  },
  {
    postcode: 5576,
    place_name: 'Yorketown',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0185,
    longitude: 137.6049,
    accuracy: 4,
  },
  {
    postcode: 5577,
    place_name: 'Inneston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.275,
    longitude: 136.8917,
    accuracy: 4,
  },
  {
    postcode: 5577,
    place_name: 'Couch Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9095,
    longitude: 137.1769,
    accuracy: 4,
  },
  {
    postcode: 5577,
    place_name: 'The Pines',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9165,
    longitude: 137.1605,
    accuracy: 4,
  },
  {
    postcode: 5577,
    place_name: 'Point Souttar',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1327,
    longitude: 137.1464,
    accuracy: 3,
  },
  {
    postcode: 5577,
    place_name: 'Warooka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9903,
    longitude: 137.4012,
    accuracy: 4,
  },
  {
    postcode: 5577,
    place_name: 'Foul Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.1327,
    longitude: 137.1464,
    accuracy: 3,
  },
  {
    postcode: 5580,
    place_name: 'Port Julia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6667,
    longitude: 137.8667,
    accuracy: 4,
  },
  {
    postcode: 5580,
    place_name: 'Curramulka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7,
    longitude: 137.7,
    accuracy: 4,
  },
  {
    postcode: 5581,
    place_name: 'Sheaoak Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.778,
    longitude: 137.8608,
    accuracy: 3,
  },
  {
    postcode: 5581,
    place_name: 'Port Vincent',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7798,
    longitude: 137.8593,
    accuracy: 4,
  },
  {
    postcode: 5582,
    place_name: 'Stansbury',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9111,
    longitude: 137.799,
    accuracy: 4,
  },
  {
    postcode: 5582,
    place_name: 'Port Giles',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9111,
    longitude: 137.799,
    accuracy: 3,
  },
  {
    postcode: 5583,
    place_name: 'Coobowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.044,
    longitude: 137.7311,
    accuracy: 4,
  },
  {
    postcode: 5583,
    place_name: 'Sultana Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0671,
    longitude: 137.7309,
    accuracy: 3,
  },
  {
    postcode: 5583,
    place_name: 'Edithburgh',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0846,
    longitude: 137.744,
    accuracy: 4,
  },
  {
    postcode: 5600,
    place_name: 'Whyalla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0327,
    longitude: 137.5648,
    accuracy: 4,
  },
  {
    postcode: 5600,
    place_name: 'Whyalla Playford',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0279,
    longitude: 137.562,
    accuracy: 4,
  },
  {
    postcode: 5600,
    place_name: 'Whyalla DC',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0303,
    longitude: 137.5634,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Iron Baron',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9951,
    longitude: 137.1686,
    accuracy: 4,
  },
  {
    postcode: 5601,
    place_name: 'Point Lowly North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Backy Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Murninnie Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Port Bonython',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9907,
    longitude: 137.7754,
    accuracy: 4,
  },
  {
    postcode: 5601,
    place_name: 'Iron Knob',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.733,
    longitude: 137.151,
    accuracy: 4,
  },
  {
    postcode: 5601,
    place_name: 'Point Lowly',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Douglas Point South',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Whyalla Barson',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Cultana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Cowleds Landing',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Douglas Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Mullaquana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.2118,
    longitude: 137.3627,
    accuracy: 4,
  },
  {
    postcode: 5601,
    place_name: 'False Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5601,
    place_name: 'Fitzgerald Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.864,
    longitude: 137.1598,
    accuracy: 3,
  },
  {
    postcode: 5602,
    place_name: 'Miltalie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5833,
    longitude: 136.8333,
    accuracy: 4,
  },
  {
    postcode: 5602,
    place_name: 'Lucky Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6165,
    longitude: 136.8785,
    accuracy: 3,
  },
  {
    postcode: 5602,
    place_name: 'Cowell',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6823,
    longitude: 136.9212,
    accuracy: 4,
  },
  {
    postcode: 5602,
    place_name: 'Port Gibbon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7972,
    longitude: 136.8028,
    accuracy: 4,
  },
  {
    postcode: 5602,
    place_name: 'Minbrie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5,
    longitude: 136.9667,
    accuracy: 4,
  },
  {
    postcode: 5602,
    place_name: 'Midgee',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4209,
    longitude: 137.1546,
    accuracy: 4,
  },
  {
    postcode: 5602,
    place_name: 'Mitchellville',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6167,
    longitude: 137.15,
    accuracy: 4,
  },
  {
    postcode: 5602,
    place_name: 'Mangalo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5341,
    longitude: 136.6208,
    accuracy: 4,
  },
  {
    postcode: 5603,
    place_name: 'Verran',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.925,
    longitude: 136.4,
    accuracy: 3,
  },
  {
    postcode: 5603,
    place_name: 'Arno Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9,
    longitude: 136.5667,
    accuracy: 4,
  },
  {
    postcode: 5603,
    place_name: 'Wharminda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.9538,
    longitude: 136.2458,
    accuracy: 4,
  },
  {
    postcode: 5603,
    place_name: 'Hincks',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.925,
    longitude: 136.4,
    accuracy: 3,
  },
  {
    postcode: 5604,
    place_name: 'Port Neill',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1188,
    longitude: 136.3483,
    accuracy: 4,
  },
  {
    postcode: 5605,
    place_name: 'Butler',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1167,
    longitude: 136.1,
    accuracy: 4,
  },
  {
    postcode: 5605,
    place_name: 'Tumby Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3751,
    longitude: 136.1033,
    accuracy: 4,
  },
  {
    postcode: 5606,
    place_name: 'Port Lincoln',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7263,
    longitude: 135.8744,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Koppio',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4167,
    longitude: 135.85,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Little Douglas',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Fountain',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6,
    longitude: 135.6333,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Coomunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6437,
    longitude: 135.7074,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Louth Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.55,
    longitude: 135.9,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Point Boston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Boston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6906,
    longitude: 135.9165,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Wanilla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5,
    longitude: 135.6833,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Poonindie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5813,
    longitude: 135.881,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Green Patch',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Yallunda Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3475,
    longitude: 135.8787,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Coulta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3833,
    longitude: 135.4667,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Wangary',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.55,
    longitude: 135.4833,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Whites River',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Mount Hope',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1167,
    longitude: 135.3833,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Ungarra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1801,
    longitude: 136.0481,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Sleaford',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8144,
    longitude: 135.7438,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Duck Ponds',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7333,
    longitude: 135.7833,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Murdinga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7167,
    longitude: 135.7167,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Port Lincoln',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7263,
    longitude: 135.8744,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Mount Drummond',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.218,
    longitude: 135.4092,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Tootenilla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Charlton Gully',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.55,
    longitude: 135.8035,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Warrachie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.65,
    longitude: 135.7167,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Pearlah',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5934,
    longitude: 135.6761,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Lincoln National Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8341,
    longitude: 135.856,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Lipson',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3,
    longitude: 136.1333,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Venus Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.2333,
    longitude: 134.6833,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Coffin Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6167,
    longitude: 135.4667,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Hawson',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Tiatukia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Kiana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Sheringa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.8443,
    longitude: 135.2321,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Uley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Brooker',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1,
    longitude: 135.9,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'North Shields',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.6286,
    longitude: 135.8622,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Tulka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.7942,
    longitude: 135.7999,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Kellidie Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Tooligie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.85,
    longitude: 135.7,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Moody',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Warrow',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3333,
    longitude: 135.4667,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Whites Flat',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.5122,
    longitude: 135.8532,
    accuracy: 4,
  },
  {
    postcode: 5607,
    place_name: 'Farm Beach',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5607,
    place_name: 'Mount Dutton Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.3251,
    longitude: 135.6717,
    accuracy: 3,
  },
  {
    postcode: 5608,
    place_name: 'Whyalla Norrie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.029,
    longitude: 137.5392,
    accuracy: 4,
  },
  {
    postcode: 5608,
    place_name: 'Whyalla Norrie East',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.027,
    longitude: 137.532,
    accuracy: 3,
  },
  {
    postcode: 5608,
    place_name: 'Whyalla Stuart',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0249,
    longitude: 137.5247,
    accuracy: 4,
  },
  {
    postcode: 5608,
    place_name: 'Whyalla Norrie North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.027,
    longitude: 137.532,
    accuracy: 3,
  },
  {
    postcode: 5609,
    place_name: 'Whyalla Jenkins',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0221,
    longitude: 137.5127,
    accuracy: 4,
  },
  {
    postcode: 5630,
    place_name: 'Edillilie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.4118,
    longitude: 135.7063,
    accuracy: 4,
  },
  {
    postcode: 5631,
    place_name: 'Cockaleechie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.2601,
    longitude: 135.7273,
    accuracy: 3,
  },
  {
    postcode: 5631,
    place_name: 'Cummins',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.264,
    longitude: 135.726,
    accuracy: 4,
  },
  {
    postcode: 5632,
    place_name: 'Karkoo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.0328,
    longitude: 135.7293,
    accuracy: 4,
  },
  {
    postcode: 5632,
    place_name: 'Mitchell',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -35.0051,
    longitude: 138.5623,
    accuracy: 4,
  },
  {
    postcode: 5632,
    place_name: 'Yeelanna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.1408,
    longitude: 135.7294,
    accuracy: 4,
  },
  {
    postcode: 5632,
    place_name: 'Kapinnie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.138,
    longitude: 135.4976,
    accuracy: 4,
  },
  {
    postcode: 5633,
    place_name: 'Boonerdo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5667,
    longitude: 135.7667,
    accuracy: 3,
  },
  {
    postcode: 5633,
    place_name: 'Lock',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.568,
    longitude: 135.7565,
    accuracy: 4,
  },
  {
    postcode: 5640,
    place_name: 'Waddikee',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3131,
    longitude: 136.2551,
    accuracy: 4,
  },
  {
    postcode: 5640,
    place_name: 'Jamieson',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5083,
    longitude: 136.3583,
    accuracy: 3,
  },
  {
    postcode: 5640,
    place_name: 'Cleve',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.7,
    longitude: 136.5,
    accuracy: 4,
  },
  {
    postcode: 5640,
    place_name: 'Campoona',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5083,
    longitude: 136.3583,
    accuracy: 3,
  },
  {
    postcode: 5641,
    place_name: 'Moseley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0833,
    longitude: 136.5167,
    accuracy: 4,
  },
  {
    postcode: 5641,
    place_name: 'Pinkawillinie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.105,
    longitude: 136.3337,
    accuracy: 3,
  },
  {
    postcode: 5641,
    place_name: 'Buckleboo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9167,
    longitude: 136.2,
    accuracy: 4,
  },
  {
    postcode: 5641,
    place_name: 'Wilcherry',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.105,
    longitude: 136.3337,
    accuracy: 3,
  },
  {
    postcode: 5641,
    place_name: 'Cortlinye',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0167,
    longitude: 136.3333,
    accuracy: 4,
  },
  {
    postcode: 5641,
    place_name: 'Yalanda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.105,
    longitude: 136.3337,
    accuracy: 3,
  },
  {
    postcode: 5641,
    place_name: 'Caralue',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3667,
    longitude: 136.2,
    accuracy: 4,
  },
  {
    postcode: 5641,
    place_name: 'Panitya',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.105,
    longitude: 136.3337,
    accuracy: 3,
  },
  {
    postcode: 5641,
    place_name: 'Kimba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1416,
    longitude: 136.4187,
    accuracy: 4,
  },
  {
    postcode: 5641,
    place_name: 'Kelly',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.105,
    longitude: 136.3337,
    accuracy: 3,
  },
  {
    postcode: 5641,
    place_name: 'Cunyarie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.105,
    longitude: 136.3337,
    accuracy: 3,
  },
  {
    postcode: 5641,
    place_name: 'Solomon',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.105,
    longitude: 136.3337,
    accuracy: 3,
  },
  {
    postcode: 5641,
    place_name: 'Barna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.105,
    longitude: 136.3337,
    accuracy: 3,
  },
  {
    postcode: 5642,
    place_name: 'Rudall',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6833,
    longitude: 136.2667,
    accuracy: 4,
  },
  {
    postcode: 5642,
    place_name: 'Darke Peak',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4667,
    longitude: 136.2,
    accuracy: 4,
  },
  {
    postcode: 5642,
    place_name: 'Murlong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5778,
    longitude: 136.2278,
    accuracy: 3,
  },
  {
    postcode: 5642,
    place_name: 'Kielpa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5833,
    longitude: 136.2167,
    accuracy: 4,
  },
  {
    postcode: 5642,
    place_name: 'Hambidge',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5778,
    longitude: 136.2278,
    accuracy: 3,
  },
  {
    postcode: 5650,
    place_name: 'Koongawa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1667,
    longitude: 135.8833,
    accuracy: 4,
  },
  {
    postcode: 5650,
    place_name: 'Warramboo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.2333,
    longitude: 135.6,
    accuracy: 4,
  },
  {
    postcode: 5650,
    place_name: 'Cootra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.25,
    longitude: 135.8667,
    accuracy: 4,
  },
  {
    postcode: 5651,
    place_name: 'Kyancutta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1333,
    longitude: 135.5667,
    accuracy: 4,
  },
  {
    postcode: 5652,
    place_name: 'Paney',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0334,
    longitude: 135.4402,
    accuracy: 3,
  },
  {
    postcode: 5652,
    place_name: 'Pygery',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0167,
    longitude: 135.4167,
    accuracy: 4,
  },
  {
    postcode: 5652,
    place_name: 'Wudinna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0501,
    longitude: 135.4638,
    accuracy: 4,
  },
  {
    postcode: 5653,
    place_name: 'Yaninee',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9462,
    longitude: 135.2737,
    accuracy: 4,
  },
  {
    postcode: 5654,
    place_name: 'Mount Damper',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1167,
    longitude: 135.1,
    accuracy: 4,
  },
  {
    postcode: 5654,
    place_name: 'Karcultaby',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7667,
    longitude: 134.9667,
    accuracy: 4,
  },
  {
    postcode: 5654,
    place_name: 'Minnipa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.85,
    longitude: 135.15,
    accuracy: 4,
  },
  {
    postcode: 5654,
    place_name: 'Cocata',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.9111,
    longitude: 135.0722,
    accuracy: 3,
  },
  {
    postcode: 5655,
    place_name: 'Poochera',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7167,
    longitude: 134.85,
    accuracy: 4,
  },
  {
    postcode: 5655,
    place_name: 'Kaldoonera',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7167,
    longitude: 134.85,
    accuracy: 3,
  },
  {
    postcode: 5655,
    place_name: 'Bockelberg',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7167,
    longitude: 134.85,
    accuracy: 3,
  },
  {
    postcode: 5660,
    place_name: 'Cungena',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5833,
    longitude: 134.7167,
    accuracy: 4,
  },
  {
    postcode: 5660,
    place_name: 'Chilpenunda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5833,
    longitude: 134.7167,
    accuracy: 3,
  },
  {
    postcode: 5661,
    place_name: 'Wirrulla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4,
    longitude: 134.5167,
    accuracy: 4,
  },
  {
    postcode: 5661,
    place_name: 'Koolgera',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4,
    longitude: 134.5278,
    accuracy: 3,
  },
  {
    postcode: 5661,
    place_name: 'Yantanabie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4833,
    longitude: 134.6363,
    accuracy: 4,
  },
  {
    postcode: 5661,
    place_name: 'Wallala',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4,
    longitude: 134.5278,
    accuracy: 3,
  },
  {
    postcode: 5661,
    place_name: 'Pimbaacla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.3241,
    longitude: 134.4495,
    accuracy: 4,
  },
  {
    postcode: 5670,
    place_name: 'Kappawanta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5096,
    longitude: 134.9778,
    accuracy: 3,
  },
  {
    postcode: 5670,
    place_name: 'Colton',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4833,
    longitude: 134.9333,
    accuracy: 4,
  },
  {
    postcode: 5670,
    place_name: 'Ulyerra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5096,
    longitude: 134.9778,
    accuracy: 3,
  },
  {
    postcode: 5670,
    place_name: 'Bramfield',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6167,
    longitude: 135.0167,
    accuracy: 4,
  },
  {
    postcode: 5670,
    place_name: 'Coolillie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5096,
    longitude: 134.9778,
    accuracy: 3,
  },
  {
    postcode: 5670,
    place_name: 'Mount Wedge',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4665,
    longitude: 135.1566,
    accuracy: 4,
  },
  {
    postcode: 5670,
    place_name: 'Elliston',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.6479,
    longitude: 134.8889,
    accuracy: 4,
  },
  {
    postcode: 5670,
    place_name: 'Palkagee',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.4141,
    longitude: 135.5029,
    accuracy: 4,
  },
  {
    postcode: 5670,
    place_name: 'Talia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.3167,
    longitude: 134.8833,
    accuracy: 4,
  },
  {
    postcode: 5670,
    place_name: 'Mount Joy',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5291,
    longitude: 135.1062,
    accuracy: 4,
  },
  {
    postcode: 5670,
    place_name: 'Polda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.5281,
    longitude: 135.4578,
    accuracy: 4,
  },
  {
    postcode: 5671,
    place_name: 'Mortana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0279,
    longitude: 134.4531,
    accuracy: 4,
  },
  {
    postcode: 5671,
    place_name: 'Port Kenny',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.1667,
    longitude: 134.7,
    accuracy: 4,
  },
  {
    postcode: 5671,
    place_name: 'Calca',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0833,
    longitude: 134.35,
    accuracy: 4,
  },
  {
    postcode: 5671,
    place_name: 'Tyringa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0917,
    longitude: 134.55,
    accuracy: 3,
  },
  {
    postcode: 5671,
    place_name: 'Baird Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0917,
    longitude: 134.55,
    accuracy: 3,
  },
  {
    postcode: 5671,
    place_name: 'Mount Cooper',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0833,
    longitude: 134.7,
    accuracy: 4,
  },
  {
    postcode: 5671,
    place_name: 'Witera',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0917,
    longitude: 134.55,
    accuracy: 3,
  },
  {
    postcode: 5671,
    place_name: 'Colley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0917,
    longitude: 134.55,
    accuracy: 3,
  },
  {
    postcode: 5680,
    place_name: 'Chinbingina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.2167,
    longitude: 134.2,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Sceale Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -33.0167,
    longitude: 134.2,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Streaky Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7955,
    longitude: 134.2093,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Piednippie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.473,
    longitude: 134.2278,
    accuracy: 3,
  },
  {
    postcode: 5680,
    place_name: 'Petina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4765,
    longitude: 134.3969,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Perlubie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.473,
    longitude: 134.2278,
    accuracy: 3,
  },
  {
    postcode: 5680,
    place_name: 'Nunjikompita',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.2691,
    longitude: 134.3383,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Westall',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.473,
    longitude: 134.2278,
    accuracy: 3,
  },
  {
    postcode: 5680,
    place_name: 'Haslam',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5,
    longitude: 134.2,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Pureba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.473,
    longitude: 134.2278,
    accuracy: 3,
  },
  {
    postcode: 5680,
    place_name: 'Carawa',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.3833,
    longitude: 134.2,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Maryvale',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.473,
    longitude: 134.2278,
    accuracy: 3,
  },
  {
    postcode: 5680,
    place_name: 'Chandada',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.7548,
    longitude: 134.6731,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Yanerbie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.473,
    longitude: 134.2278,
    accuracy: 3,
  },
  {
    postcode: 5680,
    place_name: 'Puntabie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.2036,
    longitude: 134.1281,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Laura Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.2167,
    longitude: 133.8333,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Mudamuckla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.1584,
    longitude: 134.0438,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Eba Anchorage',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.473,
    longitude: 134.2278,
    accuracy: 3,
  },
  {
    postcode: 5680,
    place_name: 'Smoky Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.3784,
    longitude: 133.934,
    accuracy: 4,
  },
  {
    postcode: 5680,
    place_name: 'Inkster',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.8167,
    longitude: 134.6833,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Nadia',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.077,
    longitude: 133.4841,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Denial Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.1006,
    longitude: 133.5768,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Ceduna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.1266,
    longitude: 133.6727,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Koonibba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.9044,
    longitude: 133.431,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Thevenard',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.1458,
    longitude: 133.6485,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Charra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.0833,
    longitude: 133.3333,
    accuracy: 3,
  },
  {
    postcode: 5690,
    place_name: 'Kalanbi',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.9513,
    longitude: 133.6311,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Wandana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.0129,
    longitude: 133.2552,
    accuracy: 3,
  },
  {
    postcode: 5690,
    place_name: 'Bookabie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.8384,
    longitude: 132.6899,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Maltee',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.0833,
    longitude: 133.9,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Uworra',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.965,
    longitude: 133.327,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Coorabie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.8998,
    longitude: 132.2953,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Penong',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.9303,
    longitude: 133.0107,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Merghiny',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.1167,
    longitude: 133.8333,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Watraba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.9734,
    longitude: 133.2119,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'White Well Corner',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.0129,
    longitude: 133.2552,
    accuracy: 3,
  },
  {
    postcode: 5690,
    place_name: 'Fowlers Bay',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.9865,
    longitude: 132.4349,
    accuracy: 4,
  },
  {
    postcode: 5690,
    place_name: 'Yalata',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.4802,
    longitude: 131.8424,
    accuracy: 4,
  },
  {
    postcode: 5700,
    place_name: 'Winninowie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5369,
    longitude: 137.8122,
    accuracy: 3,
  },
  {
    postcode: 5700,
    place_name: 'Port Augusta North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.6049,
    longitude: 137.6894,
    accuracy: 3,
  },
  {
    postcode: 5700,
    place_name: 'Port Paterson',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5543,
    longitude: 137.8309,
    accuracy: 4,
  },
  {
    postcode: 5700,
    place_name: 'Port Augusta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.496,
    longitude: 137.7728,
    accuracy: 4,
  },
  {
    postcode: 5700,
    place_name: 'Commissariat Point',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5369,
    longitude: 137.8122,
    accuracy: 3,
  },
  {
    postcode: 5700,
    place_name: 'Miranda',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.6139,
    longitude: 137.8949,
    accuracy: 4,
  },
  {
    postcode: 5700,
    place_name: 'Blanche Harbor',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5369,
    longitude: 137.8122,
    accuracy: 3,
  },
  {
    postcode: 5700,
    place_name: 'Port Augusta West',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4861,
    longitude: 137.7583,
    accuracy: 4,
  },
  {
    postcode: 5700,
    place_name: 'Wami Kata',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5369,
    longitude: 137.8122,
    accuracy: 3,
  },
  {
    postcode: 5700,
    place_name: 'Mundallio',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4678,
    longitude: 137.8842,
    accuracy: 4,
  },
  {
    postcode: 5701,
    place_name: 'Tarcoola',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.7101,
    longitude: 134.5707,
    accuracy: 4,
  },
  {
    postcode: 5701,
    place_name: 'Arkaroola Village',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.3116,
    longitude: 139.3356,
    accuracy: 4,
  },
  {
    postcode: 5701,
    place_name: 'Glendambo',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.9683,
    longitude: 135.7502,
    accuracy: 4,
  },
  {
    postcode: 5701,
    place_name: 'Woolundunga',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5374,
    longitude: 137.9454,
    accuracy: 4,
  },
  {
    postcode: 5701,
    place_name: 'Cook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5369,
    longitude: 137.8122,
    accuracy: 3,
  },
  {
    postcode: 5701,
    place_name: 'Kingoonya',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.9107,
    longitude: 135.3105,
    accuracy: 4,
  },
  {
    postcode: 5701,
    place_name: 'Port Augusta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.496,
    longitude: 137.7728,
    accuracy: 4,
  },
  {
    postcode: 5710,
    place_name: 'Kingoonya',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.9107,
    longitude: 135.3105,
    accuracy: 4,
  },
  {
    postcode: 5710,
    place_name: 'Cook',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -29.3441,
    longitude: 134.786,
    accuracy: 3,
  },
  {
    postcode: 5710,
    place_name: 'Nonning',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.5,
    longitude: 136.5,
    accuracy: 4,
  },
  {
    postcode: 5710,
    place_name: 'Stirling North',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.4799,
    longitude: 137.838,
    accuracy: 4,
  },
  {
    postcode: 5710,
    place_name: 'Tarcoola',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.7101,
    longitude: 134.5707,
    accuracy: 4,
  },
  {
    postcode: 5710,
    place_name: 'Port Augusta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -32.496,
    longitude: 137.7728,
    accuracy: 4,
  },
  {
    postcode: 5720,
    place_name: 'Pimba',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.2521,
    longitude: 136.8048,
    accuracy: 4,
  },
  {
    postcode: 5720,
    place_name: 'Woomera',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.1998,
    longitude: 136.8326,
    accuracy: 4,
  },
  {
    postcode: 5722,
    place_name: 'Andamooka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.4481,
    longitude: 137.1656,
    accuracy: 4,
  },
  {
    postcode: 5723,
    place_name: 'Coober Pedy',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -29.0141,
    longitude: 134.755,
    accuracy: 4,
  },
  {
    postcode: 5724,
    place_name: 'Mintabie',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -27.3103,
    longitude: 133.2966,
    accuracy: 4,
  },
  {
    postcode: 5724,
    place_name: 'Marla',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -27.2997,
    longitude: 133.6225,
    accuracy: 4,
  },
  {
    postcode: 5725,
    place_name: 'Olympic Dam',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.4468,
    longitude: 136.8668,
    accuracy: 4,
  },
  {
    postcode: 5725,
    place_name: 'Roxby Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.5631,
    longitude: 136.8955,
    accuracy: 4,
  },
  {
    postcode: 5730,
    place_name: 'Blinman',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.1174,
    longitude: 138.6896,
    accuracy: 4,
  },
  {
    postcode: 5730,
    place_name: 'Beltana',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.8143,
    longitude: 138.4152,
    accuracy: 4,
  },
  {
    postcode: 5730,
    place_name: 'Parachilna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -31.1322,
    longitude: 138.395,
    accuracy: 4,
  },
  {
    postcode: 5731,
    place_name: 'Leigh Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.5931,
    longitude: 138.4003,
    accuracy: 4,
  },
  {
    postcode: 5731,
    place_name: 'Innamincka',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -27.7073,
    longitude: 140.737,
    accuracy: 4,
  },
  {
    postcode: 5731,
    place_name: 'Lyndhurst',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.2879,
    longitude: 138.3493,
    accuracy: 4,
  },
  {
    postcode: 5731,
    place_name: 'Cordillo Downs',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.4405,
    longitude: 138.3748,
    accuracy: 3,
  },
  {
    postcode: 5731,
    place_name: 'Coopers Creek',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.4405,
    longitude: 138.3748,
    accuracy: 3,
  },
  {
    postcode: 5732,
    place_name: 'Copley',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.5599,
    longitude: 138.4213,
    accuracy: 4,
  },
  {
    postcode: 5732,
    place_name: 'Nepabunna',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.5635,
    longitude: 138.967,
    accuracy: 4,
  },
  {
    postcode: 5733,
    place_name: 'Farina',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -30.0665,
    longitude: 138.2847,
    accuracy: 4,
  },
  {
    postcode: 5733,
    place_name: 'Marree',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -29.6512,
    longitude: 138.071,
    accuracy: 4,
  },
  {
    postcode: 5734,
    place_name: 'Oodnadatta',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -27.5514,
    longitude: 135.4491,
    accuracy: 4,
  },
  {
    postcode: 5800,
    place_name: 'Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 4,
  },
  {
    postcode: 5810,
    place_name: 'Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 4,
  },
  {
    postcode: 5839,
    place_name: 'Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 4,
  },
  {
    postcode: 5860,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5861,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5862,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5863,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5864,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5865,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5866,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5867,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5868,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5869,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5870,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5871,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5872,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5873,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5874,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5875,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5876,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5877,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5878,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5879,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5880,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5881,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5882,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5883,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5884,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5885,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5886,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5887,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5888,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5889,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5890,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5891,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5892,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5893,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5894,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5895,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5896,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5897,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5898,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5899,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 1,
  },
  {
    postcode: 5900,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8956,
    longitude: 138.5855,
    accuracy: 1,
  },
  {
    postcode: 5901,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8956,
    longitude: 138.5855,
    accuracy: 1,
  },
  {
    postcode: 5902,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8956,
    longitude: 138.5855,
    accuracy: 1,
  },
  {
    postcode: 5903,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8956,
    longitude: 138.5855,
    accuracy: 1,
  },
  {
    postcode: 5904,
    place_name: 'Adelaide Mail Centre',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8956,
    longitude: 138.5855,
    accuracy: 1,
  },
  {
    postcode: 5920,
    place_name: 'Adelaide',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9287,
    longitude: 138.5986,
    accuracy: 4,
  },
  {
    postcode: 5942,
    place_name: 'Regency Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8626,
    longitude: 138.5724,
    accuracy: 4,
  },
  {
    postcode: 5950,
    place_name: 'Export Park',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9461,
    longitude: 138.5302,
    accuracy: 3,
  },
  {
    postcode: 5950,
    place_name: 'Adelaide Airport',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.9391,
    longitude: 138.5341,
    accuracy: 4,
  },
  {
    postcode: 5960,
    place_name: 'Torrens Island',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8956,
    longitude: 138.5855,
    accuracy: 1,
  },
  {
    postcode: 5960,
    place_name: 'Garden Island',
    state_name: 'South Australia',
    state_code: 'SA',
    latitude: -34.8956,
    longitude: 138.5855,
    accuracy: 1,
  },
  {
    postcode: 7000,
    place_name: 'Mount Stuart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8716,
    longitude: 147.3016,
    accuracy: 4,
  },
  {
    postcode: 7000,
    place_name: 'North Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8741,
    longitude: 147.316,
    accuracy: 4,
  },
  {
    postcode: 7000,
    place_name: 'Queens Domain',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.877,
    longitude: 147.3288,
    accuracy: 3,
  },
  {
    postcode: 7000,
    place_name: 'West Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8766,
    longitude: 147.3069,
    accuracy: 4,
  },
  {
    postcode: 7000,
    place_name: 'Glebe',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8747,
    longitude: 147.3281,
    accuracy: 4,
  },
  {
    postcode: 7000,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7001,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7002,
    place_name: 'North Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8741,
    longitude: 147.316,
    accuracy: 4,
  },
  {
    postcode: 7004,
    place_name: 'South Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8946,
    longitude: 147.3092,
    accuracy: 4,
  },
  {
    postcode: 7004,
    place_name: 'Battery Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8899,
    longitude: 147.3332,
    accuracy: 4,
  },
  {
    postcode: 7005,
    place_name: 'Sandy Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8945,
    longitude: 147.3244,
    accuracy: 4,
  },
  {
    postcode: 7005,
    place_name: 'Dynnyrne',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9054,
    longitude: 147.3158,
    accuracy: 4,
  },
  {
    postcode: 7005,
    place_name: 'Lower Sandy Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.913,
    longitude: 147.3532,
    accuracy: 3,
  },
  {
    postcode: 7005,
    place_name: 'University Of Tasmania',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9107,
    longitude: 147.3357,
    accuracy: 3,
  },
  {
    postcode: 7006,
    place_name: 'Sandy Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8945,
    longitude: 147.3244,
    accuracy: 4,
  },
  {
    postcode: 7007,
    place_name: 'Tolmans Hill',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.914,
    longitude: 147.3071,
    accuracy: 4,
  },
  {
    postcode: 7007,
    place_name: 'Mount Nelson',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9207,
    longitude: 147.3207,
    accuracy: 4,
  },
  {
    postcode: 7008,
    place_name: 'New Town',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8576,
    longitude: 147.3087,
    accuracy: 4,
  },
  {
    postcode: 7008,
    place_name: 'Lenah Valley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8715,
    longitude: 147.2751,
    accuracy: 4,
  },
  {
    postcode: 7009,
    place_name: 'West Moonah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.85,
    longitude: 147.2818,
    accuracy: 4,
  },
  {
    postcode: 7009,
    place_name: 'Moonah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8462,
    longitude: 147.3023,
    accuracy: 4,
  },
  {
    postcode: 7009,
    place_name: 'Derwent Park',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8351,
    longitude: 147.2949,
    accuracy: 4,
  },
  {
    postcode: 7009,
    place_name: 'Lutana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8347,
    longitude: 147.3115,
    accuracy: 4,
  },
  {
    postcode: 7010,
    place_name: 'Rosetta',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.821,
    longitude: 147.2526,
    accuracy: 4,
  },
  {
    postcode: 7010,
    place_name: 'Montrose',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.829,
    longitude: 147.2473,
    accuracy: 4,
  },
  {
    postcode: 7010,
    place_name: 'Dowsing Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8208,
    longitude: 147.3022,
    accuracy: 4,
  },
  {
    postcode: 7010,
    place_name: 'Goodwood',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8282,
    longitude: 147.293,
    accuracy: 4,
  },
  {
    postcode: 7010,
    place_name: 'Elwick',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8355,
    longitude: 147.2646,
    accuracy: 3,
  },
  {
    postcode: 7010,
    place_name: 'Glenorchy',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8334,
    longitude: 147.2759,
    accuracy: 4,
  },
  {
    postcode: 7011,
    place_name: 'Berriedale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8167,
    longitude: 147.25,
    accuracy: 4,
  },
  {
    postcode: 7011,
    place_name: 'Chigwell',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8091,
    longitude: 147.2448,
    accuracy: 4,
  },
  {
    postcode: 7011,
    place_name: 'Claremont',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7833,
    longitude: 147.25,
    accuracy: 4,
  },
  {
    postcode: 7011,
    place_name: 'Austins Ferry',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7667,
    longitude: 147.25,
    accuracy: 4,
  },
  {
    postcode: 7012,
    place_name: 'Collinsvale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8417,
    longitude: 147.1961,
    accuracy: 4,
  },
  {
    postcode: 7012,
    place_name: 'Glenlusk',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8167,
    longitude: 147.2,
    accuracy: 4,
  },
  {
    postcode: 7015,
    place_name: 'Geilston Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8296,
    longitude: 147.3463,
    accuracy: 4,
  },
  {
    postcode: 7015,
    place_name: 'Lindisfarne',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8484,
    longitude: 147.3619,
    accuracy: 4,
  },
  {
    postcode: 7015,
    place_name: 'Rose Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.832,
    longitude: 147.3227,
    accuracy: 3,
  },
  {
    postcode: 7016,
    place_name: 'Risdon Vale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8124,
    longitude: 147.3565,
    accuracy: 4,
  },
  {
    postcode: 7017,
    place_name: 'Honeywood',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7261,
    longitude: 147.2802,
    accuracy: 4,
  },
  {
    postcode: 7017,
    place_name: 'Grasstree Hill',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.782,
    longitude: 147.3613,
    accuracy: 3,
  },
  {
    postcode: 7017,
    place_name: 'Risdon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8,
    longitude: 147.3333,
    accuracy: 4,
  },
  {
    postcode: 7017,
    place_name: 'Old Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7776,
    longitude: 147.2666,
    accuracy: 4,
  },
  {
    postcode: 7017,
    place_name: 'Otago',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8088,
    longitude: 147.2984,
    accuracy: 4,
  },
  {
    postcode: 7017,
    place_name: 'Tea Tree',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6833,
    longitude: 147.3167,
    accuracy: 4,
  },
  {
    postcode: 7018,
    place_name: 'Rosny',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8707,
    longitude: 147.357,
    accuracy: 4,
  },
  {
    postcode: 7018,
    place_name: 'Bellerive',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8755,
    longitude: 147.3703,
    accuracy: 4,
  },
  {
    postcode: 7018,
    place_name: 'Montagu Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8626,
    longitude: 147.3533,
    accuracy: 4,
  },
  {
    postcode: 7018,
    place_name: 'Rosny Park',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8661,
    longitude: 147.3677,
    accuracy: 4,
  },
  {
    postcode: 7018,
    place_name: 'Tranmere',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9218,
    longitude: 147.417,
    accuracy: 4,
  },
  {
    postcode: 7018,
    place_name: 'Warrane',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8555,
    longitude: 147.383,
    accuracy: 4,
  },
  {
    postcode: 7018,
    place_name: 'Mornington',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8627,
    longitude: 147.3982,
    accuracy: 4,
  },
  {
    postcode: 7018,
    place_name: 'Howrah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8873,
    longitude: 147.4055,
    accuracy: 4,
  },
  {
    postcode: 7019,
    place_name: 'Oakdowns',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9018,
    longitude: 147.4559,
    accuracy: 4,
  },
  {
    postcode: 7019,
    place_name: 'Clarendon Vale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8899,
    longitude: 147.4437,
    accuracy: 4,
  },
  {
    postcode: 7019,
    place_name: 'Rokeby',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8987,
    longitude: 147.4492,
    accuracy: 4,
  },
  {
    postcode: 7020,
    place_name: 'Sandford',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9333,
    longitude: 147.5,
    accuracy: 4,
  },
  {
    postcode: 7020,
    place_name: 'Clifton Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9853,
    longitude: 147.5211,
    accuracy: 4,
  },
  {
    postcode: 7021,
    place_name: 'Lauderdale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9136,
    longitude: 147.4875,
    accuracy: 4,
  },
  {
    postcode: 7022,
    place_name: 'South Arm',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0247,
    longitude: 147.4149,
    accuracy: 4,
  },
  {
    postcode: 7023,
    place_name: 'Opossum Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9954,
    longitude: 147.4038,
    accuracy: 4,
  },
  {
    postcode: 7024,
    place_name: 'Cremorne',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9578,
    longitude: 147.5315,
    accuracy: 4,
  },
  {
    postcode: 7025,
    place_name: 'Richmond',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7355,
    longitude: 147.4383,
    accuracy: 4,
  },
  {
    postcode: 7025,
    place_name: 'Dulcot',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7883,
    longitude: 147.4014,
    accuracy: 4,
  },
  {
    postcode: 7026,
    place_name: 'Campania',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6648,
    longitude: 147.4232,
    accuracy: 4,
  },
  {
    postcode: 7027,
    place_name: 'Colebrook',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5333,
    longitude: 147.3667,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Steppes',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.1104,
    longitude: 146.8924,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Flintstone',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Bothwell',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3848,
    longitude: 147.0083,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Pontville',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.687,
    longitude: 147.2686,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Hermitage',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.25,
    longitude: 146.8833,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Tods Corner',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Apsley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4365,
    longitude: 147.1466,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Broadmarsh',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.65,
    longitude: 147.1167,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Miena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.9817,
    longitude: 146.7232,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Morass Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Kempton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5359,
    longitude: 147.2052,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Pelham',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5833,
    longitude: 147,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Gagebrook',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7477,
    longitude: 147.2698,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Lower Marshes',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.238,
    longitude: 147.01,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Waddamana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.1271,
    longitude: 146.7476,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Elderslie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6,
    longitude: 147.0667,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Shannon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.238,
    longitude: 147.01,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Bagdad',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6297,
    longitude: 147.2234,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Jericho',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3833,
    longitude: 147.2833,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Interlaken',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.15,
    longitude: 147.1833,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Melton Mowbray',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4667,
    longitude: 147.1833,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Bagdad North',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Herdsmans Cove',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7507,
    longitude: 147.2648,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Dysart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5667,
    longitude: 147.2,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Granton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7515,
    longitude: 147.228,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Arthurs Lake',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Brighton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6981,
    longitude: 147.2449,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Mangalore',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.65,
    longitude: 147.2333,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Millers Bluff',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Bridgewater',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7372,
    longitude: 147.2278,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Lake Sorell',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Dromedary',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7333,
    longitude: 147.1667,
    accuracy: 4,
  },
  {
    postcode: 7030,
    place_name: 'Cramps Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Wilburville',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7030,
    place_name: 'Liawenee',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5042,
    longitude: 147.1203,
    accuracy: 3,
  },
  {
    postcode: 7050,
    place_name: 'Kingston Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9802,
    longitude: 147.3194,
    accuracy: 4,
  },
  {
    postcode: 7050,
    place_name: 'Kingston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9764,
    longitude: 147.3035,
    accuracy: 4,
  },
  {
    postcode: 7051,
    place_name: 'Kingston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9764,
    longitude: 147.3035,
    accuracy: 4,
  },
  {
    postcode: 7052,
    place_name: 'Blackmans Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0031,
    longitude: 147.3169,
    accuracy: 4,
  },
  {
    postcode: 7053,
    place_name: 'Bonnet Hill',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.968,
    longitude: 147.3319,
    accuracy: 4,
  },
  {
    postcode: 7053,
    place_name: 'Taroona',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9452,
    longitude: 147.3484,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Coningham',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0767,
    longitude: 147.2756,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Wellington Park',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0081,
    longitude: 147.2655,
    accuracy: 3,
  },
  {
    postcode: 7054,
    place_name: 'Margate',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0281,
    longitude: 147.2626,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Tinderbox',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0362,
    longitude: 147.3203,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Neika',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.95,
    longitude: 147.2333,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Lower Snug',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0081,
    longitude: 147.2655,
    accuracy: 3,
  },
  {
    postcode: 7054,
    place_name: 'Electrona',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0577,
    longitude: 147.2607,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Barretta',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0339,
    longitude: 147.2463,
    accuracy: 3,
  },
  {
    postcode: 7054,
    place_name: 'Snug',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0667,
    longitude: 147.2547,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Fern Tree',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.921,
    longitude: 147.2633,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Ridgeway',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.928,
    longitude: 147.289,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Leslie Vale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9743,
    longitude: 147.2295,
    accuracy: 4,
  },
  {
    postcode: 7054,
    place_name: 'Howden',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0254,
    longitude: 147.2937,
    accuracy: 4,
  },
  {
    postcode: 7055,
    place_name: 'Huntingfield',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0016,
    longitude: 147.2957,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Wattle Grove',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1673,
    longitude: 146.9986,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Huonville',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0312,
    longitude: 147.0481,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Raminea',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2109,
    longitude: 146.8676,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Glen Huon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0333,
    longitude: 146.9667,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Glendevie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2392,
    longitude: 146.9953,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Hastings',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.4089,
    longitude: 146.9195,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Southport',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2109,
    longitude: 146.8676,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Cradoc',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0991,
    longitude: 147.0285,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Grove',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9833,
    longitude: 147.1,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Lymington',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1984,
    longitude: 147.0727,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Petcheys Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2109,
    longitude: 146.8676,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Woodstock',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0736,
    longitude: 147.0376,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Glaziers Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1333,
    longitude: 147.05,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Judbury',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9924,
    longitude: 146.9326,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Lune River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.4295,
    longitude: 146.9009,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Ranelagh',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0098,
    longitude: 147.0376,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Crabtree',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9329,
    longitude: 147.0489,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Lower Longley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9791,
    longitude: 147.1444,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Waterloo',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2109,
    longitude: 146.8676,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Lucaston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9833,
    longitude: 147.05,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Strathblane',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.3341,
    longitude: 146.9705,
    accuracy: 4,
  },
  {
    postcode: 7109,
    place_name: 'Lonnavale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9754,
    longitude: 146.8397,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Ida Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1269,
    longitude: 147.0275,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Mountain River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.945,
    longitude: 147.1257,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Recherche',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1269,
    longitude: 147.0275,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Southport Lagoon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1269,
    longitude: 147.0275,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Lower Wattle Grove',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1269,
    longitude: 147.0275,
    accuracy: 3,
  },
  {
    postcode: 7109,
    place_name: 'Catamaran',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.5583,
    longitude: 146.8911,
    accuracy: 4,
  },
  {
    postcode: 7112,
    place_name: 'Nicholls Rivulet',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1585,
    longitude: 147.1447,
    accuracy: 4,
  },
  {
    postcode: 7112,
    place_name: 'Randalls Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2434,
    longitude: 147.128,
    accuracy: 4,
  },
  {
    postcode: 7112,
    place_name: 'Deep Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2082,
    longitude: 147.134,
    accuracy: 4,
  },
  {
    postcode: 7112,
    place_name: 'Verona Sands',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1696,
    longitude: 147.1296,
    accuracy: 3,
  },
  {
    postcode: 7112,
    place_name: 'Garden Island Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.25,
    longitude: 147.1667,
    accuracy: 4,
  },
  {
    postcode: 7112,
    place_name: 'Eggs And Bacon Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2455,
    longitude: 147.1047,
    accuracy: 3,
  },
  {
    postcode: 7112,
    place_name: 'Cygnet',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1533,
    longitude: 147.0725,
    accuracy: 4,
  },
  {
    postcode: 7112,
    place_name: 'Charlotte Cove',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.189,
    longitude: 147.148,
    accuracy: 3,
  },
  {
    postcode: 7112,
    place_name: 'Abels Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1696,
    longitude: 147.1296,
    accuracy: 3,
  },
  {
    postcode: 7112,
    place_name: 'Gardners Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1757,
    longitude: 147.1323,
    accuracy: 4,
  },
  {
    postcode: 7113,
    place_name: 'Franklin',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0888,
    longitude: 147.0091,
    accuracy: 4,
  },
  {
    postcode: 7116,
    place_name: 'Castle Forbes Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1323,
    longitude: 146.9763,
    accuracy: 4,
  },
  {
    postcode: 7116,
    place_name: 'Brooks Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1708,
    longitude: 146.9784,
    accuracy: 3,
  },
  {
    postcode: 7116,
    place_name: 'Surges Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2188,
    longitude: 146.9926,
    accuracy: 4,
  },
  {
    postcode: 7116,
    place_name: 'Cairns Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2775,
    longitude: 146.4256,
    accuracy: 3,
  },
  {
    postcode: 7116,
    place_name: 'Police Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2406,
    longitude: 147.0419,
    accuracy: 4,
  },
  {
    postcode: 7116,
    place_name: 'Surveyors Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1708,
    longitude: 146.9784,
    accuracy: 3,
  },
  {
    postcode: 7116,
    place_name: 'Port Huon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1564,
    longitude: 146.9638,
    accuracy: 4,
  },
  {
    postcode: 7116,
    place_name: 'Geeveston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1634,
    longitude: 146.9255,
    accuracy: 4,
  },
  {
    postcode: 7117,
    place_name: 'Dover',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.3142,
    longitude: 147.0149,
    accuracy: 4,
  },
  {
    postcode: 7119,
    place_name: 'Stonor',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4,
    longitude: 147.3833,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Swanston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3482,
    longitude: 147.4726,
    accuracy: 3,
  },
  {
    postcode: 7120,
    place_name: 'Baden',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4333,
    longitude: 147.4667,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Levendale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5333,
    longitude: 147.5667,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Lemont',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.2974,
    longitude: 147.6081,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Tiberias',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3482,
    longitude: 147.4726,
    accuracy: 3,
  },
  {
    postcode: 7120,
    place_name: 'Andover',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3333,
    longitude: 147.45,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Mount Seymour',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4,
    longitude: 147.45,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Parattah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.35,
    longitude: 147.4,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Oatlands',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.303,
    longitude: 147.3672,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Antill Ponds',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.2089,
    longitude: 147.4018,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Whitefoord',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4243,
    longitude: 147.5097,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Woodbury',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.1794,
    longitude: 147.3889,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'York Plains',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.2792,
    longitude: 147.4456,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Woodsdale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4849,
    longitude: 147.5631,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Tunbridge',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.1327,
    longitude: 147.4292,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Pawtella',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.2905,
    longitude: 147.504,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Rhyndaston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4789,
    longitude: 147.3837,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Tunnack',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4519,
    longitude: 147.4618,
    accuracy: 4,
  },
  {
    postcode: 7120,
    place_name: 'Stonehenge',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4,
    longitude: 147.6333,
    accuracy: 4,
  },
  {
    postcode: 7139,
    place_name: 'Strathgordon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7665,
    longitude: 146.0521,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Macquarie Plains',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7119,
    longitude: 146.9131,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Plenty',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7419,
    longitude: 146.9554,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Victoria Valley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3167,
    longitude: 146.7333,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Sorell Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8133,
    longitude: 147.8342,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Fitzgerald',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7496,
    longitude: 146.6424,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Magra',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.75,
    longitude: 147.0667,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Fentonbury',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.65,
    longitude: 146.7667,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Karanja',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5434,
    longitude: 146.4477,
    accuracy: 3,
  },
  {
    postcode: 7140,
    place_name: 'Hollow Tree',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5333,
    longitude: 146.9333,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Maydena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7548,
    longitude: 146.6256,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Bronte Park',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.136,
    longitude: 146.4932,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Lachlan',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8333,
    longitude: 147.05,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Malbina',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8,
    longitude: 147.1333,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Ouse',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4862,
    longitude: 146.7126,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Gretna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6809,
    longitude: 146.9365,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Glenora',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6833,
    longitude: 146.8667,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Bushy Park',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7,
    longitude: 146.9,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Hayes',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.76,
    longitude: 147.0124,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Black Hills',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7167,
    longitude: 147.0333,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Hamilton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5536,
    longitude: 146.8453,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Mount Field',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5434,
    longitude: 146.4477,
    accuracy: 3,
  },
  {
    postcode: 7140,
    place_name: 'Mount Lloyd',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8167,
    longitude: 146.95,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Lawitta',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7682,
    longitude: 147.0359,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Osterley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.35,
    longitude: 146.75,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Bradys Lake',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5434,
    longitude: 146.4477,
    accuracy: 3,
  },
  {
    postcode: 7140,
    place_name: 'Moogara',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7833,
    longitude: 146.9167,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Wayatinah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3769,
    longitude: 146.5085,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Butlers Gorge',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.279,
    longitude: 146.2777,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Lake St Clair',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5434,
    longitude: 146.4477,
    accuracy: 3,
  },
  {
    postcode: 7140,
    place_name: 'Westerway',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6833,
    longitude: 146.7833,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Ellendale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6167,
    longitude: 146.7167,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'National Park',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6805,
    longitude: 146.7277,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Molesworth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5434,
    longitude: 146.4477,
    accuracy: 3,
  },
  {
    postcode: 7140,
    place_name: 'Tarraleah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3015,
    longitude: 146.4492,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Strickland',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3667,
    longitude: 146.6667,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'New Norfolk',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7826,
    longitude: 147.0587,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Styx',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.618,
    longitude: 146.8279,
    accuracy: 3,
  },
  {
    postcode: 7140,
    place_name: 'Uxbridge',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.773,
    longitude: 146.8686,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Derwent Bridge',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.1394,
    longitude: 146.2314,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Little Pine Lagoon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.618,
    longitude: 146.8279,
    accuracy: 3,
  },
  {
    postcode: 7140,
    place_name: 'Tyenna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7287,
    longitude: 146.6665,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Boyer',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7823,
    longitude: 147.1062,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Dee',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.2833,
    longitude: 146.6167,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Glenfern',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8167,
    longitude: 147,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Rosegarland',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7,
    longitude: 146.9489,
    accuracy: 4,
  },
  {
    postcode: 7140,
    place_name: 'Meadowbank',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.618,
    longitude: 146.8279,
    accuracy: 3,
  },
  {
    postcode: 7140,
    place_name: 'Florentine',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.618,
    longitude: 146.8279,
    accuracy: 3,
  },
  {
    postcode: 7140,
    place_name: 'London Lakes',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.618,
    longitude: 146.8279,
    accuracy: 3,
  },
  {
    postcode: 7150,
    place_name: 'Allens Rivulet',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0151,
    longitude: 147.2014,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Upper Woodstock',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2345,
    longitude: 147.2537,
    accuracy: 3,
  },
  {
    postcode: 7150,
    place_name: 'South Bruny',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.4386,
    longitude: 147.2462,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Barnes Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1216,
    longitude: 147.368,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Pelverata',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0462,
    longitude: 147.1127,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Killora',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2345,
    longitude: 147.2537,
    accuracy: 3,
  },
  {
    postcode: 7150,
    place_name: 'Oyster Cove',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1052,
    longitude: 147.2436,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Lunawanna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.3666,
    longitude: 147.2432,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Simpsons Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.278,
    longitude: 147.2997,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'North Bruny',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1287,
    longitude: 147.2464,
    accuracy: 3,
  },
  {
    postcode: 7150,
    place_name: 'Gordon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.262,
    longitude: 147.2395,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Apollo Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1287,
    longitude: 147.2464,
    accuracy: 3,
  },
  {
    postcode: 7150,
    place_name: 'Dennes Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0628,
    longitude: 147.3528,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Great Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2345,
    longitude: 147.2537,
    accuracy: 3,
  },
  {
    postcode: 7150,
    place_name: 'Adventure Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.3602,
    longitude: 147.3214,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Alonnah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.315,
    longitude: 147.2443,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Longley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9708,
    longitude: 147.1947,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Sandfly',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9833,
    longitude: 147.1962,
    accuracy: 4,
  },
  {
    postcode: 7150,
    place_name: 'Kaoota',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0167,
    longitude: 147.1833,
    accuracy: 4,
  },
  {
    postcode: 7151,
    place_name: 'Macquarie Island',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7167,
    longitude: 146.9167,
    accuracy: 3,
  },
  {
    postcode: 7151,
    place_name: 'Macquarie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7119,
    longitude: 146.9131,
    accuracy: 4,
  },
  {
    postcode: 7151,
    place_name: 'Casey',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7167,
    longitude: 146.9167,
    accuracy: 3,
  },
  {
    postcode: 7151,
    place_name: 'Mawson',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7167,
    longitude: 146.9167,
    accuracy: 3,
  },
  {
    postcode: 7151,
    place_name: 'Davis',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7167,
    longitude: 146.9167,
    accuracy: 3,
  },
  {
    postcode: 7151,
    place_name: 'Heard Island',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7167,
    longitude: 146.9167,
    accuracy: 3,
  },
  {
    postcode: 7151,
    place_name: 'Mcdonald Islands',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7167,
    longitude: 146.9167,
    accuracy: 3,
  },
  {
    postcode: 7155,
    place_name: 'Kettering',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1262,
    longitude: 147.2464,
    accuracy: 4,
  },
  {
    postcode: 7162,
    place_name: 'Birchs Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1755,
    longitude: 147.242,
    accuracy: 4,
  },
  {
    postcode: 7162,
    place_name: 'Woodbridge',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1557,
    longitude: 147.2389,
    accuracy: 4,
  },
  {
    postcode: 7163,
    place_name: 'Flowerpot',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2336,
    longitude: 147.2198,
    accuracy: 3,
  },
  {
    postcode: 7163,
    place_name: 'Middleton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.2316,
    longitude: 147.2529,
    accuracy: 4,
  },
  {
    postcode: 7170,
    place_name: 'Seven Mile Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8586,
    longitude: 147.5053,
    accuracy: 4,
  },
  {
    postcode: 7170,
    place_name: 'Roches Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8902,
    longitude: 147.4953,
    accuracy: 4,
  },
  {
    postcode: 7170,
    place_name: 'Cambridge',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8333,
    longitude: 147.45,
    accuracy: 4,
  },
  {
    postcode: 7170,
    place_name: 'Mount Rumney',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8606,
    longitude: 147.4476,
    accuracy: 4,
  },
  {
    postcode: 7170,
    place_name: 'Acton Park',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8793,
    longitude: 147.4846,
    accuracy: 4,
  },
  {
    postcode: 7171,
    place_name: 'Penna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7776,
    longitude: 147.5228,
    accuracy: 4,
  },
  {
    postcode: 7171,
    place_name: 'Midway Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7816,
    longitude: 147.5627,
    accuracy: 4,
  },
  {
    postcode: 7172,
    place_name: 'Orielton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7333,
    longitude: 147.5333,
    accuracy: 4,
  },
  {
    postcode: 7172,
    place_name: 'Sorell',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7816,
    longitude: 147.5627,
    accuracy: 4,
  },
  {
    postcode: 7172,
    place_name: 'Nugent',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7172,
    longitude: 147.7522,
    accuracy: 4,
  },
  {
    postcode: 7172,
    place_name: 'Pawleena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7333,
    longitude: 147.6,
    accuracy: 4,
  },
  {
    postcode: 7172,
    place_name: 'Wattle Hill',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7667,
    longitude: 147.65,
    accuracy: 4,
  },
  {
    postcode: 7173,
    place_name: 'Forcett',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8,
    longitude: 147.6333,
    accuracy: 4,
  },
  {
    postcode: 7173,
    place_name: 'Carlton River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8474,
    longitude: 147.6451,
    accuracy: 3,
  },
  {
    postcode: 7173,
    place_name: 'Primrose Sands',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8858,
    longitude: 147.6695,
    accuracy: 4,
  },
  {
    postcode: 7173,
    place_name: 'Lewisham',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8333,
    longitude: 147.6167,
    accuracy: 4,
  },
  {
    postcode: 7173,
    place_name: 'Dodges Ferry',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8531,
    longitude: 147.6226,
    accuracy: 4,
  },
  {
    postcode: 7173,
    place_name: 'Connellys Marsh',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8222,
    longitude: 147.6703,
    accuracy: 3,
  },
  {
    postcode: 7173,
    place_name: 'Carlton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.874,
    longitude: 147.6534,
    accuracy: 4,
  },
  {
    postcode: 7174,
    place_name: 'Copping',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8167,
    longitude: 147.8,
    accuracy: 4,
  },
  {
    postcode: 7175,
    place_name: 'Bream Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8133,
    longitude: 147.8342,
    accuracy: 4,
  },
  {
    postcode: 7175,
    place_name: 'Marion Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8133,
    longitude: 147.8342,
    accuracy: 3,
  },
  {
    postcode: 7176,
    place_name: 'Kellevie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.7785,
    longitude: 147.8153,
    accuracy: 4,
  },
  {
    postcode: 7177,
    place_name: 'Boomer Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8865,
    longitude: 147.8108,
    accuracy: 3,
  },
  {
    postcode: 7177,
    place_name: 'Dunalley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8865,
    longitude: 147.8108,
    accuracy: 4,
  },
  {
    postcode: 7178,
    place_name: 'Murdunna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.9473,
    longitude: 147.8705,
    accuracy: 4,
  },
  {
    postcode: 7179,
    place_name: 'Eaglehawk Neck',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0179,
    longitude: 147.9222,
    accuracy: 4,
  },
  {
    postcode: 7180,
    place_name: 'Taranna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0629,
    longitude: 147.863,
    accuracy: 4,
  },
  {
    postcode: 7182,
    place_name: 'Port Arthur',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1458,
    longitude: 147.8437,
    accuracy: 4,
  },
  {
    postcode: 7182,
    place_name: 'Cape Pillar',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.15,
    longitude: 147.85,
    accuracy: 3,
  },
  {
    postcode: 7182,
    place_name: 'Fortescue',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.15,
    longitude: 147.85,
    accuracy: 3,
  },
  {
    postcode: 7183,
    place_name: 'Highcroft',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1333,
    longitude: 147.8,
    accuracy: 4,
  },
  {
    postcode: 7184,
    place_name: 'White Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1249,
    longitude: 147.7212,
    accuracy: 4,
  },
  {
    postcode: 7184,
    place_name: 'Nubeena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0971,
    longitude: 147.7433,
    accuracy: 4,
  },
  {
    postcode: 7184,
    place_name: 'Cape Raoul',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.111,
    longitude: 147.7322,
    accuracy: 3,
  },
  {
    postcode: 7184,
    place_name: 'Stormlea',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.1703,
    longitude: 147.7718,
    accuracy: 4,
  },
  {
    postcode: 7185,
    place_name: 'Premaydena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0546,
    longitude: 147.7708,
    accuracy: 4,
  },
  {
    postcode: 7186,
    place_name: 'Saltwater River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0234,
    longitude: 147.7183,
    accuracy: 4,
  },
  {
    postcode: 7186,
    place_name: 'Sloping Main',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.0167,
    longitude: 147.75,
    accuracy: 3,
  },
  {
    postcode: 7187,
    place_name: 'Koonya',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -43.05,
    longitude: 147.85,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Swansea',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.1228,
    longitude: 148.0743,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Little Swanport',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3375,
    longitude: 147.9334,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Dolphin Sands',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4221,
    longitude: 147.8876,
    accuracy: 3,
  },
  {
    postcode: 7190,
    place_name: 'Runnymede',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6419,
    longitude: 147.5605,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Rheban',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.63,
    longitude: 147.928,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Orford',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5602,
    longitude: 147.8718,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Cranbrook',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.0062,
    longitude: 148.0689,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Triabunna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.508,
    longitude: 147.9113,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Spring Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.5841,
    longitude: 147.9041,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Buckland',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.6,
    longitude: 147.7167,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Pontypool',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4221,
    longitude: 147.8876,
    accuracy: 3,
  },
  {
    postcode: 7190,
    place_name: 'Apslawn',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.9738,
    longitude: 148.1865,
    accuracy: 4,
  },
  {
    postcode: 7190,
    place_name: 'Maria Island',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.3064,
    longitude: 147.9158,
    accuracy: 3,
  },
  {
    postcode: 7190,
    place_name: 'Rocky Hills',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.4221,
    longitude: 147.8876,
    accuracy: 3,
  },
  {
    postcode: 7209,
    place_name: 'Ross',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.0308,
    longitude: 147.4927,
    accuracy: 4,
  },
  {
    postcode: 7209,
    place_name: 'Tooms Lake',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.0308,
    longitude: 147.4927,
    accuracy: 3,
  },
  {
    postcode: 7210,
    place_name: 'Campbell Town',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.9256,
    longitude: 147.4939,
    accuracy: 4,
  },
  {
    postcode: 7210,
    place_name: 'Lake Leake',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.0136,
    longitude: 147.7954,
    accuracy: 4,
  },
  {
    postcode: 7211,
    place_name: 'Cleveland',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.8,
    longitude: 147.4,
    accuracy: 4,
  },
  {
    postcode: 7211,
    place_name: 'Conara',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.8333,
    longitude: 147.4333,
    accuracy: 4,
  },
  {
    postcode: 7211,
    place_name: 'Epping Forest',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7599,
    longitude: 147.3498,
    accuracy: 4,
  },
  {
    postcode: 7212,
    place_name: 'Evandale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5686,
    longitude: 147.2527,
    accuracy: 4,
  },
  {
    postcode: 7212,
    place_name: 'Burns Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4147,
    longitude: 147.5265,
    accuracy: 4,
  },
  {
    postcode: 7212,
    place_name: 'Blessington',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.519,
    longitude: 147.4761,
    accuracy: 4,
  },
  {
    postcode: 7212,
    place_name: 'Upper Blessington',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4591,
    longitude: 147.5922,
    accuracy: 4,
  },
  {
    postcode: 7212,
    place_name: 'Nile',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6454,
    longitude: 147.3301,
    accuracy: 4,
  },
  {
    postcode: 7212,
    place_name: 'Deddington',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6024,
    longitude: 147.4272,
    accuracy: 4,
  },
  {
    postcode: 7212,
    place_name: 'Western Junction',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5543,
    longitude: 147.2282,
    accuracy: 4,
  },
  {
    postcode: 7212,
    place_name: 'Ben Lomond',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5535,
    longitude: 147.3829,
    accuracy: 3,
  },
  {
    postcode: 7213,
    place_name: 'Royal George',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.8307,
    longitude: 147.8968,
    accuracy: 4,
  },
  {
    postcode: 7213,
    place_name: 'Rossarden',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.667,
    longitude: 147.745,
    accuracy: 4,
  },
  {
    postcode: 7213,
    place_name: 'Avoca',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7864,
    longitude: 147.7209,
    accuracy: 4,
  },
  {
    postcode: 7214,
    place_name: 'Mangana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6081,
    longitude: 147.8854,
    accuracy: 4,
  },
  {
    postcode: 7214,
    place_name: 'Fingal',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6396,
    longitude: 147.9695,
    accuracy: 4,
  },
  {
    postcode: 7214,
    place_name: 'Upper Esk',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4308,
    longitude: 147.7256,
    accuracy: 4,
  },
  {
    postcode: 7214,
    place_name: 'Mathinna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4751,
    longitude: 147.89,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Seymour',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7438,
    longitude: 148.2762,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Scamander',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.456,
    longitude: 148.261,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Douglas River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7856,
    longitude: 148.2503,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Douglas-Apsley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6675,
    longitude: 148.2438,
    accuracy: 3,
  },
  {
    postcode: 7215,
    place_name: 'Falmouth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5058,
    longitude: 148.2709,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'St Marys',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5834,
    longitude: 148.1875,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Coles Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.1264,
    longitude: 148.2887,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Friendly Beaches',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6675,
    longitude: 148.2438,
    accuracy: 3,
  },
  {
    postcode: 7215,
    place_name: 'Bicheno',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.8743,
    longitude: 148.3024,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Cornwall',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.559,
    longitude: 148.1453,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Four Mile Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.723,
    longitude: 148.1894,
    accuracy: 3,
  },
  {
    postcode: 7215,
    place_name: 'Beaumaris',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4159,
    longitude: 148.2614,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Chain Of Lagoons',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.723,
    longitude: 148.1894,
    accuracy: 3,
  },
  {
    postcode: 7215,
    place_name: 'Gray',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6287,
    longitude: 148.2284,
    accuracy: 4,
  },
  {
    postcode: 7215,
    place_name: 'Upper Scamander',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6675,
    longitude: 148.2438,
    accuracy: 3,
  },
  {
    postcode: 7215,
    place_name: 'Freycinet',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6675,
    longitude: 148.2438,
    accuracy: 3,
  },
  {
    postcode: 7216,
    place_name: 'The Gardens',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2651,
    longitude: 148.1443,
    accuracy: 3,
  },
  {
    postcode: 7216,
    place_name: 'Pyengana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2918,
    longitude: 148.0127,
    accuracy: 4,
  },
  {
    postcode: 7216,
    place_name: 'Goshen',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2721,
    longitude: 148.1054,
    accuracy: 4,
  },
  {
    postcode: 7216,
    place_name: 'Ansons Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2687,
    longitude: 148.0987,
    accuracy: 3,
  },
  {
    postcode: 7216,
    place_name: 'St Helens',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3203,
    longitude: 148.2389,
    accuracy: 4,
  },
  {
    postcode: 7216,
    place_name: 'Lottah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2258,
    longitude: 148.0324,
    accuracy: 4,
  },
  {
    postcode: 7216,
    place_name: 'Stieglitz',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3217,
    longitude: 148.3058,
    accuracy: 4,
  },
  {
    postcode: 7216,
    place_name: 'Goulds Country',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2422,
    longitude: 148.056,
    accuracy: 4,
  },
  {
    postcode: 7216,
    place_name: 'Akaroa',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3045,
    longitude: 148.3167,
    accuracy: 3,
  },
  {
    postcode: 7216,
    place_name: 'Binalong Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2511,
    longitude: 148.3117,
    accuracy: 4,
  },
  {
    postcode: 7248,
    place_name: 'Newnham',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3971,
    longitude: 147.1313,
    accuracy: 4,
  },
  {
    postcode: 7248,
    place_name: 'Rocherlea',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3808,
    longitude: 147.1574,
    accuracy: 4,
  },
  {
    postcode: 7248,
    place_name: 'Mowbray',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4024,
    longitude: 147.1506,
    accuracy: 4,
  },
  {
    postcode: 7248,
    place_name: 'Mayfield',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3876,
    longitude: 147.1305,
    accuracy: 4,
  },
  {
    postcode: 7248,
    place_name: 'Invermay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4204,
    longitude: 147.1336,
    accuracy: 4,
  },
  {
    postcode: 7249,
    place_name: 'Youngtown',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4833,
    longitude: 147.1707,
    accuracy: 4,
  },
  {
    postcode: 7249,
    place_name: 'Kings Meadows',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4704,
    longitude: 147.162,
    accuracy: 4,
  },
  {
    postcode: 7249,
    place_name: 'Glen Dhu',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.476,
    longitude: 147.1577,
    accuracy: 3,
  },
  {
    postcode: 7249,
    place_name: 'Punchbowl',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4576,
    longitude: 147.1647,
    accuracy: 4,
  },
  {
    postcode: 7249,
    place_name: 'South Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4551,
    longitude: 147.1456,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Norwood',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4592,
    longitude: 147.1808,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Prospect',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.481,
    longitude: 147.1041,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Prospect Vale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.481,
    longitude: 147.1041,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Waverley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.436,
    longitude: 147.1859,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Trevallyn',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4425,
    longitude: 147.1048,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'East Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4384,
    longitude: 147.151,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Newstead',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4333,
    longitude: 147.1667,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Travellers Rest',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4971,
    longitude: 147.0929,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Blackstone Heights',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4598,
    longitude: 147.0841,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Summerhill',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4675,
    longitude: 147.128,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'West Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4509,
    longitude: 147.1311,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Riverside',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4211,
    longitude: 147.0509,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'Ravenswood',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4159,
    longitude: 147.1825,
    accuracy: 4,
  },
  {
    postcode: 7250,
    place_name: 'St Leonards',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4625,
    longitude: 147.2146,
    accuracy: 4,
  },
  {
    postcode: 7252,
    place_name: 'Swan Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1315,
    longitude: 147.01,
    accuracy: 3,
  },
  {
    postcode: 7252,
    place_name: 'Dilston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3296,
    longitude: 147.0652,
    accuracy: 4,
  },
  {
    postcode: 7252,
    place_name: 'Pipers River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0973,
    longitude: 147.0809,
    accuracy: 4,
  },
  {
    postcode: 7252,
    place_name: 'Weymouth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0145,
    longitude: 147.1517,
    accuracy: 4,
  },
  {
    postcode: 7252,
    place_name: 'Mount Direction',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2464,
    longitude: 147.0132,
    accuracy: 4,
  },
  {
    postcode: 7252,
    place_name: 'Lefroy',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1,
    longitude: 146.9833,
    accuracy: 4,
  },
  {
    postcode: 7252,
    place_name: 'Hillwood',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2442,
    longitude: 146.9842,
    accuracy: 4,
  },
  {
    postcode: 7252,
    place_name: 'Beechford',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1315,
    longitude: 147.01,
    accuracy: 3,
  },
  {
    postcode: 7252,
    place_name: 'Windermere',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3134,
    longitude: 147.0058,
    accuracy: 4,
  },
  {
    postcode: 7252,
    place_name: 'Stony Head',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1659,
    longitude: 147.0475,
    accuracy: 3,
  },
  {
    postcode: 7252,
    place_name: 'Lulworth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41,
    longitude: 147.07,
    accuracy: 4,
  },
  {
    postcode: 7253,
    place_name: 'George Town',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1056,
    longitude: 146.8261,
    accuracy: 4,
  },
  {
    postcode: 7253,
    place_name: 'Long Reach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0971,
    longitude: 146.8325,
    accuracy: 3,
  },
  {
    postcode: 7253,
    place_name: 'Low Head',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.08,
    longitude: 146.8075,
    accuracy: 4,
  },
  {
    postcode: 7253,
    place_name: 'Bell Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.13,
    longitude: 146.87,
    accuracy: 4,
  },
  {
    postcode: 7254,
    place_name: 'Golconda',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.167,
    longitude: 147.3094,
    accuracy: 4,
  },
  {
    postcode: 7254,
    place_name: 'Lebrina',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.179,
    longitude: 147.2283,
    accuracy: 4,
  },
  {
    postcode: 7254,
    place_name: 'Tunnel',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1952,
    longitude: 147.193,
    accuracy: 4,
  },
  {
    postcode: 7254,
    place_name: 'Pipers Brook',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0831,
    longitude: 147.1743,
    accuracy: 4,
  },
  {
    postcode: 7254,
    place_name: 'Bellingham',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0164,
    longitude: 147.1688,
    accuracy: 4,
  },
  {
    postcode: 7254,
    place_name: 'Retreat',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1505,
    longitude: 147.173,
    accuracy: 4,
  },
  {
    postcode: 7254,
    place_name: 'Wyena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.176,
    longitude: 147.2668,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Ranga',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.1522,
    longitude: 148.0673,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Emita',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.0008,
    longitude: 147.9165,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Blue Rocks',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.0571,
    longitude: 147.9555,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Loccota',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.2181,
    longitude: 148.0423,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Leeka',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.9005,
    longitude: 147.7989,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Lackrana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.1194,
    longitude: 148.0978,
    accuracy: 3,
  },
  {
    postcode: 7255,
    place_name: 'Lughrata',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.9741,
    longitude: 147.9297,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Strzelecki',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.0066,
    longitude: 147.9897,
    accuracy: 3,
  },
  {
    postcode: 7255,
    place_name: 'Lady Barron',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.21,
    longitude: 148.24,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Wingaroo',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.0066,
    longitude: 147.9897,
    accuracy: 3,
  },
  {
    postcode: 7255,
    place_name: 'Palana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.7667,
    longitude: 147.8833,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Whitemark',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.1222,
    longitude: 148.0164,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Killiecrankie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8391,
    longitude: 147.834,
    accuracy: 4,
  },
  {
    postcode: 7255,
    place_name: 'Memana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.9789,
    longitude: 148.0914,
    accuracy: 4,
  },
  {
    postcode: 7256,
    place_name: 'Naracoopa',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.9208,
    longitude: 144.1194,
    accuracy: 4,
  },
  {
    postcode: 7256,
    place_name: 'Yarra Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8478,
    longitude: 143.9795,
    accuracy: 3,
  },
  {
    postcode: 7256,
    place_name: 'Yambacoona',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.7042,
    longitude: 143.9306,
    accuracy: 4,
  },
  {
    postcode: 7256,
    place_name: 'Currie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.9298,
    longitude: 143.8538,
    accuracy: 4,
  },
  {
    postcode: 7256,
    place_name: 'Grassy',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.0492,
    longitude: 144.0561,
    accuracy: 4,
  },
  {
    postcode: 7256,
    place_name: 'Pearshape',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8478,
    longitude: 143.9795,
    accuracy: 3,
  },
  {
    postcode: 7256,
    place_name: 'Pegarah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.9417,
    longitude: 143.9958,
    accuracy: 4,
  },
  {
    postcode: 7256,
    place_name: 'Loorana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8389,
    longitude: 143.8958,
    accuracy: 4,
  },
  {
    postcode: 7256,
    place_name: 'Sea Elephant',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8286,
    longitude: 144.1111,
    accuracy: 4,
  },
  {
    postcode: 7256,
    place_name: 'Surprise Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8478,
    longitude: 143.9795,
    accuracy: 3,
  },
  {
    postcode: 7256,
    place_name: 'Reekara',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.7553,
    longitude: 143.9175,
    accuracy: 4,
  },
  {
    postcode: 7256,
    place_name: 'Nugara',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8478,
    longitude: 143.9795,
    accuracy: 3,
  },
  {
    postcode: 7256,
    place_name: 'Bungaree',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8478,
    longitude: 143.9795,
    accuracy: 3,
  },
  {
    postcode: 7256,
    place_name: 'Lymwood',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8478,
    longitude: 143.9795,
    accuracy: 3,
  },
  {
    postcode: 7256,
    place_name: 'Wickham',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.8478,
    longitude: 143.9795,
    accuracy: 3,
  },
  {
    postcode: 7256,
    place_name: 'Egg Lagoon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -39.6611,
    longitude: 143.9764,
    accuracy: 4,
  },
  {
    postcode: 7257,
    place_name: 'Cape Barren Island',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.3734,
    longitude: 148.027,
    accuracy: 4,
  },
  {
    postcode: 7258,
    place_name: 'Relbia',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5058,
    longitude: 147.2021,
    accuracy: 3,
  },
  {
    postcode: 7258,
    place_name: 'White Hills',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5055,
    longitude: 147.2543,
    accuracy: 4,
  },
  {
    postcode: 7258,
    place_name: 'Breadalbane',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5268,
    longitude: 147.1965,
    accuracy: 4,
  },
  {
    postcode: 7259,
    place_name: 'Patersonia',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3403,
    longitude: 147.303,
    accuracy: 4,
  },
  {
    postcode: 7259,
    place_name: 'Myrtle Bank',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2941,
    longitude: 147.3584,
    accuracy: 4,
  },
  {
    postcode: 7259,
    place_name: 'Targa',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3138,
    longitude: 147.367,
    accuracy: 4,
  },
  {
    postcode: 7259,
    place_name: 'Nunamara',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3896,
    longitude: 147.3067,
    accuracy: 4,
  },
  {
    postcode: 7259,
    place_name: 'Tayene',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3457,
    longitude: 147.4494,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'North Scottsdale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1215,
    longitude: 147.5595,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'Blumont',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1681,
    longitude: 147.4846,
    accuracy: 3,
  },
  {
    postcode: 7260,
    place_name: 'Nabowla',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1712,
    longitude: 147.3662,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'Jetsonville',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1167,
    longitude: 147.4667,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'Scottsdale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1578,
    longitude: 147.5173,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'Cuckoo',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1674,
    longitude: 147.5017,
    accuracy: 3,
  },
  {
    postcode: 7260,
    place_name: 'South Springfield',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2515,
    longitude: 147.5081,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'Tonganah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1862,
    longitude: 147.5881,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'Springfield',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2,
    longitude: 147.4833,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'Tulendeena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1681,
    longitude: 147.4846,
    accuracy: 3,
  },
  {
    postcode: 7260,
    place_name: 'Kamona',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1542,
    longitude: 147.6768,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'Lietinna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.15,
    longitude: 147.4667,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'Forester',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0848,
    longitude: 147.6633,
    accuracy: 4,
  },
  {
    postcode: 7260,
    place_name: 'West Scottsdale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1674,
    longitude: 147.5017,
    accuracy: 3,
  },
  {
    postcode: 7260,
    place_name: 'Lisle',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2307,
    longitude: 147.3345,
    accuracy: 4,
  },
  {
    postcode: 7261,
    place_name: 'Branxholm',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1662,
    longitude: 147.7431,
    accuracy: 4,
  },
  {
    postcode: 7261,
    place_name: 'Warrentinna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1004,
    longitude: 147.7559,
    accuracy: 4,
  },
  {
    postcode: 7262,
    place_name: 'Waterhouse',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9607,
    longitude: 147.6967,
    accuracy: 3,
  },
  {
    postcode: 7262,
    place_name: 'Tomahawk',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.867,
    longitude: 147.756,
    accuracy: 4,
  },
  {
    postcode: 7262,
    place_name: 'Bridport',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0005,
    longitude: 147.3918,
    accuracy: 4,
  },
  {
    postcode: 7263,
    place_name: 'Trenah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3106,
    longitude: 147.69,
    accuracy: 4,
  },
  {
    postcode: 7263,
    place_name: 'Talawa',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2521,
    longitude: 147.7302,
    accuracy: 3,
  },
  {
    postcode: 7263,
    place_name: 'Legerwood',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.213,
    longitude: 147.6994,
    accuracy: 4,
  },
  {
    postcode: 7263,
    place_name: 'Ringarooma',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2418,
    longitude: 147.7376,
    accuracy: 4,
  },
  {
    postcode: 7263,
    place_name: 'Alberton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2679,
    longitude: 147.8003,
    accuracy: 4,
  },
  {
    postcode: 7264,
    place_name: 'Gladstone',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9593,
    longitude: 148.0094,
    accuracy: 4,
  },
  {
    postcode: 7264,
    place_name: 'South Mount Cameron',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0246,
    longitude: 147.9639,
    accuracy: 4,
  },
  {
    postcode: 7264,
    place_name: 'Boobyalla',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0696,
    longitude: 147.9241,
    accuracy: 3,
  },
  {
    postcode: 7264,
    place_name: 'Mount William',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9221,
    longitude: 148.2129,
    accuracy: 4,
  },
  {
    postcode: 7264,
    place_name: 'Cape Portland',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0754,
    longitude: 148.0082,
    accuracy: 3,
  },
  {
    postcode: 7264,
    place_name: 'Telita',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1657,
    longitude: 147.7408,
    accuracy: 4,
  },
  {
    postcode: 7264,
    place_name: 'Eddystone',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0696,
    longitude: 147.9241,
    accuracy: 3,
  },
  {
    postcode: 7264,
    place_name: 'Moorina',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1275,
    longitude: 147.8695,
    accuracy: 4,
  },
  {
    postcode: 7264,
    place_name: 'Pioneer',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0754,
    longitude: 148.0082,
    accuracy: 3,
  },
  {
    postcode: 7264,
    place_name: 'Ansons Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0696,
    longitude: 147.9241,
    accuracy: 3,
  },
  {
    postcode: 7264,
    place_name: 'Derby',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1489,
    longitude: 147.8025,
    accuracy: 4,
  },
  {
    postcode: 7264,
    place_name: 'Musselroe Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.8803,
    longitude: 148.1374,
    accuracy: 4,
  },
  {
    postcode: 7264,
    place_name: 'Weldborough',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1938,
    longitude: 147.9067,
    accuracy: 4,
  },
  {
    postcode: 7264,
    place_name: 'Rushy Lagoon',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0696,
    longitude: 147.9241,
    accuracy: 3,
  },
  {
    postcode: 7264,
    place_name: 'Herrick',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1002,
    longitude: 147.8826,
    accuracy: 4,
  },
  {
    postcode: 7265,
    place_name: 'Winnaleah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0975,
    longitude: 147.8238,
    accuracy: 4,
  },
  {
    postcode: 7265,
    place_name: 'Banca',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0833,
    longitude: 147.8167,
    accuracy: 3,
  },
  {
    postcode: 7267,
    place_name: 'Karoola',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2443,
    longitude: 147.1534,
    accuracy: 4,
  },
  {
    postcode: 7267,
    place_name: 'Bangor',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2184,
    longitude: 147.1411,
    accuracy: 4,
  },
  {
    postcode: 7267,
    place_name: 'Turners Marsh',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2666,
    longitude: 147.1311,
    accuracy: 4,
  },
  {
    postcode: 7267,
    place_name: 'Lalla',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2565,
    longitude: 147.1783,
    accuracy: 4,
  },
  {
    postcode: 7267,
    place_name: 'Lower Turners Marsh',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2085,
    longitude: 147.1126,
    accuracy: 4,
  },
  {
    postcode: 7268,
    place_name: 'Underwood',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.288,
    longitude: 147.2136,
    accuracy: 4,
  },
  {
    postcode: 7268,
    place_name: 'Lilydale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2503,
    longitude: 147.2176,
    accuracy: 4,
  },
  {
    postcode: 7268,
    place_name: 'North Lilydale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2257,
    longitude: 147.2404,
    accuracy: 4,
  },
  {
    postcode: 7270,
    place_name: 'Rowella',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2,
    longitude: 146.9,
    accuracy: 4,
  },
  {
    postcode: 7270,
    place_name: 'York Town',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1725,
    longitude: 146.8325,
    accuracy: 3,
  },
  {
    postcode: 7270,
    place_name: 'Sidmouth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2085,
    longitude: 146.8949,
    accuracy: 4,
  },
  {
    postcode: 7270,
    place_name: 'Kelso',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1049,
    longitude: 146.7929,
    accuracy: 4,
  },
  {
    postcode: 7270,
    place_name: 'Greens Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0848,
    longitude: 146.7504,
    accuracy: 4,
  },
  {
    postcode: 7270,
    place_name: 'Badger Head',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1725,
    longitude: 146.8325,
    accuracy: 3,
  },
  {
    postcode: 7270,
    place_name: 'Kayena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1889,
    longitude: 146.895,
    accuracy: 4,
  },
  {
    postcode: 7270,
    place_name: 'Flowery Gully',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2582,
    longitude: 146.8159,
    accuracy: 4,
  },
  {
    postcode: 7270,
    place_name: 'Beauty Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1622,
    longitude: 146.8215,
    accuracy: 4,
  },
  {
    postcode: 7270,
    place_name: 'Beaconsfield',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2023,
    longitude: 146.8172,
    accuracy: 4,
  },
  {
    postcode: 7270,
    place_name: 'Clarence Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1293,
    longitude: 146.7892,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Blackwall',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3021,
    longitude: 146.969,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Swan Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2613,
    longitude: 146.9644,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Notley Hills',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3485,
    longitude: 146.9286,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Lanena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3021,
    longitude: 146.969,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Glengarry',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3488,
    longitude: 146.8655,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Robigana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2618,
    longitude: 146.9465,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Loira',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.276,
    longitude: 146.923,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Winkleigh',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2933,
    longitude: 146.8358,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Holwell',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2831,
    longitude: 146.7813,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Deviot',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2353,
    longitude: 146.9298,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Exeter',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2975,
    longitude: 146.9505,
    accuracy: 4,
  },
  {
    postcode: 7275,
    place_name: 'Frankford',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3333,
    longitude: 146.7758,
    accuracy: 4,
  },
  {
    postcode: 7276,
    place_name: 'Gravelly Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2863,
    longitude: 146.9752,
    accuracy: 4,
  },
  {
    postcode: 7277,
    place_name: 'Grindelwald',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3515,
    longitude: 147.0052,
    accuracy: 4,
  },
  {
    postcode: 7277,
    place_name: 'Legana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3657,
    longitude: 147.0458,
    accuracy: 4,
  },
  {
    postcode: 7277,
    place_name: 'Rosevears',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3243,
    longitude: 147.0043,
    accuracy: 4,
  },
  {
    postcode: 7277,
    place_name: 'Bridgenorth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3839,
    longitude: 146.9757,
    accuracy: 4,
  },
  {
    postcode: 7290,
    place_name: 'Hadspen',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5006,
    longitude: 147.0736,
    accuracy: 4,
  },
  {
    postcode: 7291,
    place_name: 'Carrick',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5324,
    longitude: 147.0103,
    accuracy: 4,
  },
  {
    postcode: 7292,
    place_name: 'Quamby Bend',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4667,
    longitude: 146.9111,
    accuracy: 3,
  },
  {
    postcode: 7292,
    place_name: 'Selbourne',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4496,
    longitude: 146.8896,
    accuracy: 4,
  },
  {
    postcode: 7292,
    place_name: 'Westwood',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4859,
    longitude: 146.9817,
    accuracy: 4,
  },
  {
    postcode: 7292,
    place_name: 'Hagley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.526,
    longitude: 146.9026,
    accuracy: 4,
  },
  {
    postcode: 7292,
    place_name: 'Rosevale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4167,
    longitude: 146.9333,
    accuracy: 4,
  },
  {
    postcode: 7300,
    place_name: 'Perth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5723,
    longitude: 147.171,
    accuracy: 4,
  },
  {
    postcode: 7300,
    place_name: 'Devon Hills',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5591,
    longitude: 147.1951,
    accuracy: 3,
  },
  {
    postcode: 7300,
    place_name: 'Powranna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.687,
    longitude: 147.2892,
    accuracy: 4,
  },
  {
    postcode: 7301,
    place_name: 'Blackwood Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7366,
    longitude: 146.8979,
    accuracy: 4,
  },
  {
    postcode: 7301,
    place_name: 'Longford',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6051,
    longitude: 147.1189,
    accuracy: 4,
  },
  {
    postcode: 7301,
    place_name: 'Bishopsbourne',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.617,
    longitude: 146.9979,
    accuracy: 4,
  },
  {
    postcode: 7301,
    place_name: 'Liffey',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6842,
    longitude: 146.8542,
    accuracy: 4,
  },
  {
    postcode: 7301,
    place_name: 'Toiberry',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6096,
    longitude: 147.0412,
    accuracy: 4,
  },
  {
    postcode: 7302,
    place_name: 'Cressy',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6856,
    longitude: 147.0779,
    accuracy: 4,
  },
  {
    postcode: 7302,
    place_name: 'Poatina',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7957,
    longitude: 146.9608,
    accuracy: 4,
  },
  {
    postcode: 7302,
    place_name: 'Bracknell',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.65,
    longitude: 146.9384,
    accuracy: 4,
  },
  {
    postcode: 7303,
    place_name: 'Osmaston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5318,
    longitude: 146.8687,
    accuracy: 3,
  },
  {
    postcode: 7303,
    place_name: 'Cluan',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.605,
    longitude: 146.8726,
    accuracy: 4,
  },
  {
    postcode: 7303,
    place_name: 'Birralee',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4096,
    longitude: 146.8172,
    accuracy: 4,
  },
  {
    postcode: 7303,
    place_name: 'Westbury',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5291,
    longitude: 146.8391,
    accuracy: 4,
  },
  {
    postcode: 7303,
    place_name: 'Exton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5209,
    longitude: 146.7421,
    accuracy: 4,
  },
  {
    postcode: 7303,
    place_name: 'Oaks',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5898,
    longitude: 146.9819,
    accuracy: 4,
  },
  {
    postcode: 7303,
    place_name: 'Whitemore',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5833,
    longitude: 146.9333,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Weegena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4881,
    longitude: 146.4819,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Dunorlan',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4834,
    longitude: 146.5449,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Western Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6889,
    longitude: 146.4032,
    accuracy: 3,
  },
  {
    postcode: 7304,
    place_name: 'Quamby Brook',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6014,
    longitude: 146.7271,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Dairy Plains',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6889,
    longitude: 146.4032,
    accuracy: 3,
  },
  {
    postcode: 7304,
    place_name: 'Montana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5955,
    longitude: 146.5871,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Reedy Marsh',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4477,
    longitude: 146.7128,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Liena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5515,
    longitude: 146.233,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Mersey Forest',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6889,
    longitude: 146.4032,
    accuracy: 3,
  },
  {
    postcode: 7304,
    place_name: 'Meander',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.653,
    longitude: 146.6296,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Moltema',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4598,
    longitude: 146.5395,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Mayberry',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5836,
    longitude: 146.3013,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Brandum',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5465,
    longitude: 146.5541,
    accuracy: 3,
  },
  {
    postcode: 7304,
    place_name: 'Walls Of Jerusalem',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5465,
    longitude: 146.5541,
    accuracy: 3,
  },
  {
    postcode: 7304,
    place_name: 'Elizabeth Town',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.468,
    longitude: 146.5637,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Red Hills',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6889,
    longitude: 146.4032,
    accuracy: 3,
  },
  {
    postcode: 7304,
    place_name: 'Jackeys Marsh',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6865,
    longitude: 146.6787,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Central Plateau',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5465,
    longitude: 146.5541,
    accuracy: 3,
  },
  {
    postcode: 7304,
    place_name: 'Reynolds Neck',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5465,
    longitude: 146.5541,
    accuracy: 3,
  },
  {
    postcode: 7304,
    place_name: 'Doctors Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5465,
    longitude: 146.5541,
    accuracy: 3,
  },
  {
    postcode: 7304,
    place_name: 'Golden Valley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6308,
    longitude: 146.7212,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Needles',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5307,
    longitude: 146.5944,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Kimberley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.417,
    longitude: 146.4923,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Weetah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4735,
    longitude: 146.6446,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Mole Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5782,
    longitude: 146.4042,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Deloraine',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5248,
    longitude: 146.657,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Parkham',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4072,
    longitude: 146.6129,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Chudleigh',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5759,
    longitude: 146.4728,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Caveside',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6164,
    longitude: 146.4238,
    accuracy: 4,
  },
  {
    postcode: 7304,
    place_name: 'Breona',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7822,
    longitude: 146.7043,
    accuracy: 4,
  },
  {
    postcode: 7305,
    place_name: 'Railton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3457,
    longitude: 146.4236,
    accuracy: 4,
  },
  {
    postcode: 7305,
    place_name: 'Merseylea',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3441,
    longitude: 146.4206,
    accuracy: 3,
  },
  {
    postcode: 7305,
    place_name: 'Sunnyside',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4001,
    longitude: 146.4364,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Staverton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4594,
    longitude: 146.1829,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Middlesex',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4267,
    longitude: 146.1847,
    accuracy: 3,
  },
  {
    postcode: 7306,
    place_name: 'West Kentish',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4113,
    longitude: 146.2561,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Paradise',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4516,
    longitude: 146.3156,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Claude Road',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.451,
    longitude: 146.2719,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Nowhere Else',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4267,
    longitude: 146.1847,
    accuracy: 3,
  },
  {
    postcode: 7306,
    place_name: 'Sheffield',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3995,
    longitude: 146.339,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Stoodley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4139,
    longitude: 146.4033,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Lower Beulah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4808,
    longitude: 146.3917,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Acacia Hills',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4185,
    longitude: 146.3153,
    accuracy: 3,
  },
  {
    postcode: 7306,
    place_name: 'Promised Land',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4322,
    longitude: 146.2088,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Lorinna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.5577,
    longitude: 146.1308,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Cradle Mountain',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.6416,
    longitude: 145.9432,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Lower Barrington',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3084,
    longitude: 146.2872,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Roland',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4236,
    longitude: 146.2274,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Barrington',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3456,
    longitude: 146.2766,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Cethana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4918,
    longitude: 146.1539,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Mount Roland',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4267,
    longitude: 146.1847,
    accuracy: 3,
  },
  {
    postcode: 7306,
    place_name: 'Gowrie Park',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4697,
    longitude: 146.2128,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Beulah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4562,
    longitude: 146.3781,
    accuracy: 4,
  },
  {
    postcode: 7306,
    place_name: 'Nook',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.328,
    longitude: 146.3396,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Sassafras',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2983,
    longitude: 146.4915,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Bakers Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2006,
    longitude: 146.5087,
    accuracy: 3,
  },
  {
    postcode: 7307,
    place_name: 'Harford',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.236,
    longitude: 146.5481,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Shearwater',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1567,
    longitude: 146.5318,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Latrobe',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2464,
    longitude: 146.4246,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Hawley Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.142,
    longitude: 146.5377,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Moriarty',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2351,
    longitude: 146.4566,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Wesley Vale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2086,
    longitude: 146.4555,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Northdown',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1829,
    longitude: 146.4924,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Thirlstane',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2037,
    longitude: 146.5382,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Port Sorell',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1665,
    longitude: 146.5528,
    accuracy: 4,
  },
  {
    postcode: 7307,
    place_name: 'Squeaking Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1887,
    longitude: 146.5567,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Forthside',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2531,
    longitude: 146.2766,
    accuracy: 3,
  },
  {
    postcode: 7310,
    place_name: 'West Devonport',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4311,
    longitude: 146.0939,
    accuracy: 3,
  },
  {
    postcode: 7310,
    place_name: 'Spreyton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2352,
    longitude: 146.3511,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Stony Rise',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2531,
    longitude: 146.2766,
    accuracy: 3,
  },
  {
    postcode: 7310,
    place_name: 'Devonport',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1769,
    longitude: 146.3515,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Ambleside',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2032,
    longitude: 146.3722,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Erriba',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4643,
    longitude: 146.1053,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Eugenana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2555,
    longitude: 146.3476,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Lower Wilmot',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3507,
    longitude: 146.1921,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Lillico',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4311,
    longitude: 146.0939,
    accuracy: 3,
  },
  {
    postcode: 7310,
    place_name: 'Quoiba',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2085,
    longitude: 146.3356,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Paloona',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2726,
    longitude: 146.276,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Don',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.175,
    longitude: 146.3188,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'East Devonport',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2531,
    longitude: 146.2766,
    accuracy: 3,
  },
  {
    postcode: 7310,
    place_name: 'Aberdeen',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2636,
    longitude: 146.324,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Miandetta',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1955,
    longitude: 146.3532,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Kindred',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2525,
    longitude: 146.2119,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Tugrah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2531,
    longitude: 146.2766,
    accuracy: 3,
  },
  {
    postcode: 7310,
    place_name: 'Melrose',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2546,
    longitude: 146.2858,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Wilmot',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3976,
    longitude: 146.1754,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Moina',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4979,
    longitude: 146.066,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'Tarleton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2531,
    longitude: 146.2766,
    accuracy: 3,
  },
  {
    postcode: 7310,
    place_name: 'Forth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2032,
    longitude: 146.2728,
    accuracy: 4,
  },
  {
    postcode: 7310,
    place_name: 'South Spreyton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2531,
    longitude: 146.2766,
    accuracy: 3,
  },
  {
    postcode: 7315,
    place_name: 'Ulverstone',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1603,
    longitude: 146.1824,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'West Ulverstone',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2427,
    longitude: 146.1546,
    accuracy: 3,
  },
  {
    postcode: 7315,
    place_name: 'Nietta',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3811,
    longitude: 146.0634,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Loongana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2427,
    longitude: 146.1546,
    accuracy: 3,
  },
  {
    postcode: 7315,
    place_name: 'South Preston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3384,
    longitude: 146.0474,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Castra',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3444,
    longitude: 146.1276,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Gunns Plains',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2887,
    longitude: 146.0292,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'North Motton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2193,
    longitude: 146.1019,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Upper Castra',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3444,
    longitude: 146.1276,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'South Nietta',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4183,
    longitude: 146.0736,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Abbotsham',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2265,
    longitude: 146.1732,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Turners Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1606,
    longitude: 146.2319,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Leith',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1576,
    longitude: 146.2589,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Spalford',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2564,
    longitude: 146.1739,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Preston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3384,
    longitude: 146.0474,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Sprent',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2766,
    longitude: 146.1591,
    accuracy: 4,
  },
  {
    postcode: 7315,
    place_name: 'Gawler',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1938,
    longitude: 146.1569,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'West Pine',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1532,
    longitude: 145.9994,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'Howth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0968,
    longitude: 146.0123,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'Sulphur Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0947,
    longitude: 146.0235,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'Loyetea',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3064,
    longitude: 145.9485,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'Camena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1814,
    longitude: 145.9571,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'Penguin',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1226,
    longitude: 146.0732,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'Heybridge',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0806,
    longitude: 145.9859,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'South Riana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.244,
    longitude: 145.9823,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'Preservation Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2565,
    longitude: 145.9734,
    accuracy: 3,
  },
  {
    postcode: 7316,
    place_name: 'Cuprona',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1398,
    longitude: 145.97,
    accuracy: 4,
  },
  {
    postcode: 7316,
    place_name: 'Riana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2159,
    longitude: 145.9974,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Camdale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0499,
    longitude: 145.8516,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Acton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8793,
    longitude: 147.4846,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Burnie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0558,
    longitude: 145.9037,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Downlands',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0827,
    longitude: 145.8902,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'South Burnie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0598,
    longitude: 145.9085,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Shorewell Park',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0699,
    longitude: 145.8769,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Upper Burnie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2444,
    longitude: 145.8987,
    accuracy: 3,
  },
  {
    postcode: 7320,
    place_name: 'Brooklyn',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0722,
    longitude: 145.9073,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Cooee',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0496,
    longitude: 145.8772,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Park Grove',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0548,
    longitude: 145.882,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Wivenhoe',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0745,
    longitude: 145.9472,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Havenview',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0839,
    longitude: 145.9118,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Hillcrest',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0632,
    longitude: 145.8944,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Round Hill',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0827,
    longitude: 145.9006,
    accuracy: 3,
  },
  {
    postcode: 7320,
    place_name: 'Emu Heights',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.076,
    longitude: 145.9204,
    accuracy: 4,
  },
  {
    postcode: 7320,
    place_name: 'Ocean Vista',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0827,
    longitude: 145.9006,
    accuracy: 3,
  },
  {
    postcode: 7320,
    place_name: 'Romaine',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0901,
    longitude: 145.8941,
    accuracy: 3,
  },
  {
    postcode: 7320,
    place_name: 'Parklands',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0827,
    longitude: 145.9006,
    accuracy: 3,
  },
  {
    postcode: 7320,
    place_name: 'Montello',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0576,
    longitude: 145.8963,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Stowport',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.12,
    longitude: 145.938,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Edgcumbe Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1418,
    longitude: 145.6144,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Rocky Cape',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9252,
    longitude: 145.4786,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'East Ridgley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1418,
    longitude: 145.6144,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Ridgley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1556,
    longitude: 145.8351,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Crayfish Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1418,
    longitude: 145.6144,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Wiltshire',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.8347,
    longitude: 145.2771,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Parrawe',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3044,
    longitude: 145.5872,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Tullah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7321,
    longitude: 145.6225,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Savage River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3598,
    longitude: 145.4991,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Guildford',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4329,
    longitude: 145.6887,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Waratah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4437,
    longitude: 145.5329,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Tewkesbury',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2101,
    longitude: 145.7291,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Upper Stowport',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1624,
    longitude: 145.8969,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Mooreville',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0985,
    longitude: 145.8613,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Port Latta',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.8333,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Luina',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1418,
    longitude: 145.6144,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'West Mooreville',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1418,
    longitude: 145.6144,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Natone',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1712,
    longitude: 145.9217,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Boat Harbour Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.3598,
    longitude: 145.4991,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Corinna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.656,
    longitude: 145.0762,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'West Ridgley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1418,
    longitude: 145.6144,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Upper Natone',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2444,
    longitude: 145.8987,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Hellyer',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1418,
    longitude: 145.6144,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Boat Harbour',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9484,
    longitude: 145.6303,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Chasm Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1418,
    longitude: 145.6144,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Montumana',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9523,
    longitude: 145.5131,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Mawbanna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9569,
    longitude: 145.3758,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Detention',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9028,
    longitude: 145.486,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Cowrie Point',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1418,
    longitude: 145.6144,
    accuracy: 3,
  },
  {
    postcode: 7321,
    place_name: 'Black River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.8536,
    longitude: 145.311,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Sisters Beach',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9197,
    longitude: 145.5597,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Hampshire',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2709,
    longitude: 145.7821,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'East Cam',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0594,
    longitude: 145.8514,
    accuracy: 4,
  },
  {
    postcode: 7321,
    place_name: 'Highclere',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2006,
    longitude: 145.8059,
    accuracy: 4,
  },
  {
    postcode: 7322,
    place_name: 'Somerset',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0406,
    longitude: 145.8305,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Takone',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1737,
    longitude: 145.6318,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Sisters Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9617,
    longitude: 145.569,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Yolla',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1362,
    longitude: 145.7088,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'West Takone',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1847,
    longitude: 145.5273,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Lapoinya',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0011,
    longitude: 145.5753,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Henrietta',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1803,
    longitude: 145.6649,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Milabena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0333,
    longitude: 145.5156,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Preolenna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0868,
    longitude: 145.5515,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Mount Hicks',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0949,
    longitude: 145.6115,
    accuracy: 3,
  },
  {
    postcode: 7325,
    place_name: 'Wynyard',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9897,
    longitude: 145.7262,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Oldina',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0833,
    longitude: 145.6833,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Oonah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2373,
    longitude: 145.6219,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Seabrook',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0949,
    longitude: 145.6115,
    accuracy: 3,
  },
  {
    postcode: 7325,
    place_name: 'Elliott',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1001,
    longitude: 145.7828,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Meunna',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.1021,
    longitude: 145.4883,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Doctors Rocks',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0949,
    longitude: 145.6115,
    accuracy: 3,
  },
  {
    postcode: 7325,
    place_name: 'Moorleah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9846,
    longitude: 145.6213,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Table Cape',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0949,
    longitude: 145.6115,
    accuracy: 3,
  },
  {
    postcode: 7325,
    place_name: 'Myalla',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9812,
    longitude: 145.576,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Calder',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0693,
    longitude: 145.63,
    accuracy: 4,
  },
  {
    postcode: 7325,
    place_name: 'Flowerdale',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9643,
    longitude: 145.6578,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Trowutta',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0332,
    longitude: 145.0834,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'South Forest',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9351,
    longitude: 145.0327,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Mengha',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.8972,
    longitude: 145.2387,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Edith Creek',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9653,
    longitude: 145.0981,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Temma',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.2285,
    longitude: 144.6961,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Lileah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9631,
    longitude: 145.156,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Roger River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0042,
    longitude: 145.0613,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Togari',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9258,
    longitude: 144.8635,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Alcomie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9303,
    longitude: 145.1896,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Scopus',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9351,
    longitude: 145.0327,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Marrawah',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9128,
    longitude: 144.705,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Christmas Hills',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.901,
    longitude: 145.0165,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Mella',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.8393,
    longitude: 145.0685,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Irishtown',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0924,
    longitude: 144.9667,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Redpa',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9198,
    longitude: 144.7692,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Forest',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.8582,
    longitude: 145.2432,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Smithton',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.8459,
    longitude: 145.125,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Arthur River',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0924,
    longitude: 144.9667,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Brittons Swamp',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9319,
    longitude: 144.9694,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Nabageena',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9907,
    longitude: 145.1252,
    accuracy: 4,
  },
  {
    postcode: 7330,
    place_name: 'Broadmeadows',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9351,
    longitude: 145.0327,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Three Hummock Island',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0924,
    longitude: 144.9667,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Scotchtown',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9351,
    longitude: 145.0327,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Woolnorth',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0924,
    longitude: 144.9667,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'West Montagu',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9351,
    longitude: 145.0327,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Couta Rocks',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9351,
    longitude: 145.0327,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Nelson Bay',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.9351,
    longitude: 145.0327,
    accuracy: 3,
  },
  {
    postcode: 7330,
    place_name: 'Montagu',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.7739,
    longitude: 144.9605,
    accuracy: 4,
  },
  {
    postcode: 7331,
    place_name: 'Stanley',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -40.7606,
    longitude: 145.2955,
    accuracy: 4,
  },
  {
    postcode: 7466,
    place_name: 'Gormanston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.0749,
    longitude: 145.5968,
    accuracy: 4,
  },
  {
    postcode: 7467,
    place_name: 'Lake Margaret',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.0805,
    longitude: 145.5565,
    accuracy: 3,
  },
  {
    postcode: 7467,
    place_name: 'Queenstown',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.0805,
    longitude: 145.5565,
    accuracy: 4,
  },
  {
    postcode: 7468,
    place_name: 'Macquarie Heads',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.1519,
    longitude: 145.3273,
    accuracy: 3,
  },
  {
    postcode: 7468,
    place_name: 'Strahan',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.1519,
    longitude: 145.3273,
    accuracy: 4,
  },
  {
    postcode: 7469,
    place_name: 'Granville Harbour',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.8417,
    longitude: 145.3764,
    accuracy: 3,
  },
  {
    postcode: 7469,
    place_name: 'Renison Bell',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7943,
    longitude: 145.4337,
    accuracy: 4,
  },
  {
    postcode: 7469,
    place_name: 'Trial Harbour',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.8417,
    longitude: 145.3764,
    accuracy: 3,
  },
  {
    postcode: 7469,
    place_name: 'Zeehan',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.8834,
    longitude: 145.3362,
    accuracy: 4,
  },
  {
    postcode: 7470,
    place_name: 'Rosebery',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.7794,
    longitude: 145.5383,
    accuracy: 4,
  },
  {
    postcode: 7800,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7802,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7803,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7804,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7805,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7806,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7807,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7808,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7809,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7810,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7811,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7812,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7813,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7814,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7823,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7824,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7827,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7828,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7829,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7845,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7850,
    place_name: 'Hobart',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -42.8794,
    longitude: 147.3294,
    accuracy: 4,
  },
  {
    postcode: 7901,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7902,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7903,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7904,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7905,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7906,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7907,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7908,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7915,
    place_name: 'Burnie',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.0558,
    longitude: 145.9037,
    accuracy: 4,
  },
  {
    postcode: 7916,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7917,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7918,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7919,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7920,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7921,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7922,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 7923,
    place_name: 'Launceston',
    state_name: 'Tasmania',
    state_code: 'TAS',
    latitude: -41.4388,
    longitude: 147.1347,
    accuracy: 4,
  },
  {
    postcode: 3000,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 3001,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 3002,
    place_name: 'East Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 144.9879,
    accuracy: 4,
  },
  {
    postcode: 3003,
    place_name: 'West Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8101,
    longitude: 144.95,
    accuracy: 4,
  },
  {
    postcode: 3004,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 3004,
    place_name: 'St Kilda Road Central',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 3,
  },
  {
    postcode: 3004,
    place_name: 'St Kilda Road Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 3,
  },
  {
    postcode: 3005,
    place_name: 'World Trade Centre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8152,
    longitude: 144.9632,
    accuracy: 1,
  },
  {
    postcode: 3006,
    place_name: 'South Wharf',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8253,
    longitude: 144.9522,
    accuracy: 4,
  },
  {
    postcode: 3006,
    place_name: 'Southbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8228,
    longitude: 144.9643,
    accuracy: 4,
  },
  {
    postcode: 3008,
    place_name: 'Docklands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8149,
    longitude: 144.9505,
    accuracy: 4,
  },
  {
    postcode: 3010,
    place_name: 'University Of Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7958,
    longitude: 144.9612,
    accuracy: 1,
  },
  {
    postcode: 3011,
    place_name: 'Seddon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8061,
    longitude: 144.8907,
    accuracy: 4,
  },
  {
    postcode: 3011,
    place_name: 'Seddon West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7995,
    longitude: 144.8963,
    accuracy: 3,
  },
  {
    postcode: 3011,
    place_name: 'Footscray',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 144.9,
    accuracy: 4,
  },
  {
    postcode: 3012,
    place_name: 'Maidstone',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7803,
    longitude: 144.8735,
    accuracy: 4,
  },
  {
    postcode: 3012,
    place_name: 'Tottenham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7985,
    longitude: 144.8626,
    accuracy: 4,
  },
  {
    postcode: 3012,
    place_name: 'West Footscray',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7975,
    longitude: 144.8773,
    accuracy: 4,
  },
  {
    postcode: 3012,
    place_name: 'Brooklyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8161,
    longitude: 144.8415,
    accuracy: 4,
  },
  {
    postcode: 3012,
    place_name: 'Kingsville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8082,
    longitude: 144.8791,
    accuracy: 4,
  },
  {
    postcode: 3013,
    place_name: 'Yarraville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 144.9,
    accuracy: 4,
  },
  {
    postcode: 3013,
    place_name: 'Yarraville West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8249,
    longitude: 144.8708,
    accuracy: 3,
  },
  {
    postcode: 3015,
    place_name: 'Newport',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8443,
    longitude: 144.8848,
    accuracy: 4,
  },
  {
    postcode: 3015,
    place_name: 'Spotswood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8298,
    longitude: 144.8852,
    accuracy: 4,
  },
  {
    postcode: 3015,
    place_name: 'South Kingsville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8302,
    longitude: 144.8709,
    accuracy: 4,
  },
  {
    postcode: 3016,
    place_name: 'Williamstown North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8552,
    longitude: 144.8826,
    accuracy: 4,
  },
  {
    postcode: 3016,
    place_name: 'Williamstown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.857,
    longitude: 144.8977,
    accuracy: 4,
  },
  {
    postcode: 3018,
    place_name: 'Seaholme',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.864,
    longitude: 144.845,
    accuracy: 4,
  },
  {
    postcode: 3018,
    place_name: 'Altona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8696,
    longitude: 144.8304,
    accuracy: 4,
  },
  {
    postcode: 3019,
    place_name: 'Braybrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7867,
    longitude: 144.8548,
    accuracy: 4,
  },
  {
    postcode: 3019,
    place_name: 'Robinson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7867,
    longitude: 144.8548,
    accuracy: 3,
  },
  {
    postcode: 3020,
    place_name: 'Sunshine West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7912,
    longitude: 144.8164,
    accuracy: 4,
  },
  {
    postcode: 3020,
    place_name: 'Albion',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 144.8333,
    accuracy: 4,
  },
  {
    postcode: 3020,
    place_name: 'Glengala',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.775,
    longitude: 144.8333,
    accuracy: 3,
  },
  {
    postcode: 3020,
    place_name: 'Sunshine North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7699,
    longitude: 144.8279,
    accuracy: 4,
  },
  {
    postcode: 3020,
    place_name: 'Sunshine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.8333,
    accuracy: 4,
  },
  {
    postcode: 3021,
    place_name: 'Albanvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7461,
    longitude: 144.7686,
    accuracy: 4,
  },
  {
    postcode: 3021,
    place_name: 'St Albans',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.745,
    longitude: 144.8005,
    accuracy: 4,
  },
  {
    postcode: 3021,
    place_name: 'Kealba',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7371,
    longitude: 144.8283,
    accuracy: 4,
  },
  {
    postcode: 3021,
    place_name: 'Kings Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.734,
    longitude: 144.7777,
    accuracy: 4,
  },
  {
    postcode: 3022,
    place_name: 'Deer Park East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7764,
    longitude: 144.8016,
    accuracy: 3,
  },
  {
    postcode: 3022,
    place_name: 'Ardeer',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7759,
    longitude: 144.8014,
    accuracy: 4,
  },
  {
    postcode: 3023,
    place_name: 'Deer Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 144.7833,
    accuracy: 4,
  },
  {
    postcode: 3023,
    place_name: 'Ravenhall',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7655,
    longitude: 144.751,
    accuracy: 4,
  },
  {
    postcode: 3023,
    place_name: 'Deer Park North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7541,
    longitude: 144.7651,
    accuracy: 3,
  },
  {
    postcode: 3023,
    place_name: 'Burnside',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7494,
    longitude: 144.753,
    accuracy: 4,
  },
  {
    postcode: 3023,
    place_name: 'Cairnlea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7593,
    longitude: 144.7878,
    accuracy: 4,
  },
  {
    postcode: 3023,
    place_name: 'Burnside Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7269,
    longitude: 144.7523,
    accuracy: 4,
  },
  {
    postcode: 3023,
    place_name: 'Caroline Springs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7412,
    longitude: 144.7363,
    accuracy: 4,
  },
  {
    postcode: 3024,
    place_name: 'Wyndham Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8415,
    longitude: 144.541,
    accuracy: 4,
  },
  {
    postcode: 3024,
    place_name: 'Mount Cottrell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 144.5833,
    accuracy: 4,
  },
  {
    postcode: 3024,
    place_name: 'Mambourin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8755,
    longitude: 144.5032,
    accuracy: 4,
  },
  {
    postcode: 3025,
    place_name: 'Altona North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8349,
    longitude: 144.8474,
    accuracy: 4,
  },
  {
    postcode: 3025,
    place_name: 'Altona Gate',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8349,
    longitude: 144.8474,
    accuracy: 3,
  },
  {
    postcode: 3025,
    place_name: 'Altona East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8349,
    longitude: 144.8474,
    accuracy: 3,
  },
  {
    postcode: 3026,
    place_name: 'Laverton North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8205,
    longitude: 144.8159,
    accuracy: 4,
  },
  {
    postcode: 3027,
    place_name: 'Williams Landing',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8619,
    longitude: 144.7437,
    accuracy: 4,
  },
  {
    postcode: 3028,
    place_name: 'Altona Meadows',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8841,
    longitude: 144.7837,
    accuracy: 4,
  },
  {
    postcode: 3028,
    place_name: 'Laverton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.862,
    longitude: 144.7698,
    accuracy: 4,
  },
  {
    postcode: 3028,
    place_name: 'Seabrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8809,
    longitude: 144.7587,
    accuracy: 4,
  },
  {
    postcode: 3029,
    place_name: 'Truganina',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 144.75,
    accuracy: 4,
  },
  {
    postcode: 3029,
    place_name: 'Hoppers Crossing',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8826,
    longitude: 144.7003,
    accuracy: 4,
  },
  {
    postcode: 3029,
    place_name: 'Tarneit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 144.6667,
    accuracy: 4,
  },
  {
    postcode: 3030,
    place_name: 'Point Cook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9148,
    longitude: 144.7509,
    accuracy: 4,
  },
  {
    postcode: 3030,
    place_name: 'Quandong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8139,
    longitude: 144.4951,
    accuracy: 4,
  },
  {
    postcode: 3030,
    place_name: 'Werribee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 144.6667,
    accuracy: 4,
  },
  {
    postcode: 3030,
    place_name: 'Derrimut',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7924,
    longitude: 144.7699,
    accuracy: 4,
  },
  {
    postcode: 3030,
    place_name: 'Cocoroc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9728,
    longitude: 144.5643,
    accuracy: 4,
  },
  {
    postcode: 3030,
    place_name: 'Werribee South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9475,
    longitude: 144.7167,
    accuracy: 4,
  },
  {
    postcode: 3031,
    place_name: 'Flemington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7882,
    longitude: 144.93,
    accuracy: 4,
  },
  {
    postcode: 3031,
    place_name: 'Kensington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.9333,
    accuracy: 4,
  },
  {
    postcode: 3032,
    place_name: 'Maribyrnong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.8833,
    accuracy: 4,
  },
  {
    postcode: 3032,
    place_name: 'Highpoint City',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7747,
    longitude: 144.8999,
    accuracy: 3,
  },
  {
    postcode: 3032,
    place_name: 'Travancore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7808,
    longitude: 144.9354,
    accuracy: 4,
  },
  {
    postcode: 3032,
    place_name: 'Ascot Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.9167,
    accuracy: 4,
  },
  {
    postcode: 3033,
    place_name: 'Keilor East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7326,
    longitude: 144.865,
    accuracy: 4,
  },
  {
    postcode: 3034,
    place_name: 'Avondale Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7616,
    longitude: 144.8626,
    accuracy: 4,
  },
  {
    postcode: 3036,
    place_name: 'Keilor North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6963,
    longitude: 144.8047,
    accuracy: 4,
  },
  {
    postcode: 3036,
    place_name: 'Keilor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 144.8333,
    accuracy: 4,
  },
  {
    postcode: 3037,
    place_name: 'Hillside',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6905,
    longitude: 144.7417,
    accuracy: 4,
  },
  {
    postcode: 3037,
    place_name: 'Calder Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6798,
    longitude: 144.7611,
    accuracy: 4,
  },
  {
    postcode: 3037,
    place_name: 'Sydenham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 144.7667,
    accuracy: 4,
  },
  {
    postcode: 3037,
    place_name: 'Taylors Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7099,
    longitude: 144.7548,
    accuracy: 4,
  },
  {
    postcode: 3037,
    place_name: 'Delahey',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7198,
    longitude: 144.7773,
    accuracy: 4,
  },
  {
    postcode: 3038,
    place_name: 'Keilor Downs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7234,
    longitude: 144.8084,
    accuracy: 4,
  },
  {
    postcode: 3038,
    place_name: 'Watergardens',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6986,
    longitude: 144.7863,
    accuracy: 3,
  },
  {
    postcode: 3038,
    place_name: 'Taylors Lakes',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6986,
    longitude: 144.7863,
    accuracy: 4,
  },
  {
    postcode: 3038,
    place_name: 'Keilor Lodge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 144.8033,
    accuracy: 4,
  },
  {
    postcode: 3039,
    place_name: 'Moonee Ponds',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 144.9167,
    accuracy: 4,
  },
  {
    postcode: 3040,
    place_name: 'Essendon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 144.9167,
    accuracy: 4,
  },
  {
    postcode: 3040,
    place_name: 'Aberfeldie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7596,
    longitude: 144.8974,
    accuracy: 4,
  },
  {
    postcode: 3040,
    place_name: 'Essendon West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7576,
    longitude: 144.879,
    accuracy: 4,
  },
  {
    postcode: 3041,
    place_name: 'Strathmore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 142.4833,
    accuracy: 4,
  },
  {
    postcode: 3041,
    place_name: 'Strathmore Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7133,
    longitude: 144.8969,
    accuracy: 4,
  },
  {
    postcode: 3041,
    place_name: 'Essendon North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7422,
    longitude: 144.9055,
    accuracy: 4,
  },
  {
    postcode: 3041,
    place_name: 'Essendon Fields',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7245,
    longitude: 144.8989,
    accuracy: 4,
  },
  {
    postcode: 3041,
    place_name: 'Cross Keys',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7133,
    longitude: 144.8969,
    accuracy: 3,
  },
  {
    postcode: 3042,
    place_name: 'Keilor Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7203,
    longitude: 144.8542,
    accuracy: 4,
  },
  {
    postcode: 3042,
    place_name: 'Niddrie North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7311,
    longitude: 144.8867,
    accuracy: 3,
  },
  {
    postcode: 3042,
    place_name: 'Airport West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7247,
    longitude: 144.8813,
    accuracy: 4,
  },
  {
    postcode: 3042,
    place_name: 'Niddrie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7375,
    longitude: 144.8921,
    accuracy: 4,
  },
  {
    postcode: 3043,
    place_name: 'Gowanbrae',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7082,
    longitude: 144.8908,
    accuracy: 4,
  },
  {
    postcode: 3043,
    place_name: 'Tullamarine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7013,
    longitude: 144.881,
    accuracy: 4,
  },
  {
    postcode: 3043,
    place_name: 'Gladstone Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6874,
    longitude: 144.8868,
    accuracy: 4,
  },
  {
    postcode: 3044,
    place_name: 'Pascoe Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 144.9333,
    accuracy: 4,
  },
  {
    postcode: 3044,
    place_name: 'Pascoe Vale South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7397,
    longitude: 144.9461,
    accuracy: 4,
  },
  {
    postcode: 3045,
    place_name: 'Melbourne Airport',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6743,
    longitude: 144.8518,
    accuracy: 4,
  },
  {
    postcode: 3046,
    place_name: 'Oak Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7184,
    longitude: 144.9195,
    accuracy: 4,
  },
  {
    postcode: 3046,
    place_name: 'Glenroy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 144.9333,
    accuracy: 4,
  },
  {
    postcode: 3046,
    place_name: 'Hadfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7073,
    longitude: 144.9416,
    accuracy: 4,
  },
  {
    postcode: 3047,
    place_name: 'Dallas',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6708,
    longitude: 144.9354,
    accuracy: 4,
  },
  {
    postcode: 3047,
    place_name: 'Broadmeadows',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6802,
    longitude: 144.9188,
    accuracy: 4,
  },
  {
    postcode: 3047,
    place_name: 'Jacana',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6878,
    longitude: 144.9111,
    accuracy: 4,
  },
  {
    postcode: 3048,
    place_name: 'Coolaroo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6568,
    longitude: 144.9346,
    accuracy: 4,
  },
  {
    postcode: 3048,
    place_name: 'Meadow Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6512,
    longitude: 144.9186,
    accuracy: 4,
  },
  {
    postcode: 3049,
    place_name: 'Westmeadows',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.676,
    longitude: 144.887,
    accuracy: 4,
  },
  {
    postcode: 3049,
    place_name: 'Attwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6696,
    longitude: 144.8868,
    accuracy: 4,
  },
  {
    postcode: 3050,
    place_name: 'Royal Melbourne Hospital',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7981,
    longitude: 144.9555,
    accuracy: 1,
  },
  {
    postcode: 3051,
    place_name: 'North Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 144.9667,
    accuracy: 4,
  },
  {
    postcode: 3051,
    place_name: 'Hotham Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7984,
    longitude: 144.9449,
    accuracy: 3,
  },
  {
    postcode: 3052,
    place_name: 'Parkville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.95,
    accuracy: 4,
  },
  {
    postcode: 3052,
    place_name: 'Melbourne University',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7603,
    longitude: 144.9502,
    accuracy: 3,
  },
  {
    postcode: 3053,
    place_name: 'Carlton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 144.9667,
    accuracy: 4,
  },
  {
    postcode: 3053,
    place_name: 'Carlton South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8004,
    longitude: 144.9681,
    accuracy: 3,
  },
  {
    postcode: 3054,
    place_name: 'Princes Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7818,
    longitude: 144.9666,
    accuracy: 4,
  },
  {
    postcode: 3054,
    place_name: 'Carlton North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.9667,
    accuracy: 4,
  },
  {
    postcode: 3055,
    place_name: 'Brunswick South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7603,
    longitude: 144.9502,
    accuracy: 3,
  },
  {
    postcode: 3055,
    place_name: 'Brunswick West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7646,
    longitude: 144.9438,
    accuracy: 4,
  },
  {
    postcode: 3055,
    place_name: 'Moreland West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7603,
    longitude: 144.9502,
    accuracy: 3,
  },
  {
    postcode: 3055,
    place_name: 'Moonee Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7603,
    longitude: 144.9502,
    accuracy: 3,
  },
  {
    postcode: 3056,
    place_name: 'Brunswick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 144.9667,
    accuracy: 4,
  },
  {
    postcode: 3056,
    place_name: 'Brunswick North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 144.9667,
    accuracy: 3,
  },
  {
    postcode: 3056,
    place_name: 'Brunswick Lower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 144.9667,
    accuracy: 3,
  },
  {
    postcode: 3057,
    place_name: 'Sumner',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7603,
    longitude: 144.9502,
    accuracy: 3,
  },
  {
    postcode: 3057,
    place_name: 'Brunswick East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7725,
    longitude: 144.9724,
    accuracy: 4,
  },
  {
    postcode: 3058,
    place_name: 'Coburg North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7287,
    longitude: 144.9613,
    accuracy: 4,
  },
  {
    postcode: 3058,
    place_name: 'Moreland',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7603,
    longitude: 144.9502,
    accuracy: 3,
  },
  {
    postcode: 3058,
    place_name: 'Batman',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 144.9667,
    accuracy: 3,
  },
  {
    postcode: 3058,
    place_name: 'Merlynston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7603,
    longitude: 144.9502,
    accuracy: 3,
  },
  {
    postcode: 3058,
    place_name: 'Coburg',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 144.9667,
    accuracy: 4,
  },
  {
    postcode: 3059,
    place_name: 'Greenvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 144.8667,
    accuracy: 4,
  },
  {
    postcode: 3060,
    place_name: 'Fawkner',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 144.9667,
    accuracy: 4,
  },
  {
    postcode: 3061,
    place_name: 'Campbellfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6833,
    longitude: 144.95,
    accuracy: 4,
  },
  {
    postcode: 3062,
    place_name: 'Somerton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.65,
    longitude: 144.9333,
    accuracy: 4,
  },
  {
    postcode: 3063,
    place_name: 'Yuroke',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5911,
    longitude: 144.8782,
    accuracy: 4,
  },
  {
    postcode: 3063,
    place_name: 'Oaklands Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.615,
    longitude: 144.8434,
    accuracy: 4,
  },
  {
    postcode: 3064,
    place_name: 'Kalkallo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5333,
    longitude: 144.95,
    accuracy: 4,
  },
  {
    postcode: 3064,
    place_name: 'Mickleham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5366,
    longitude: 144.902,
    accuracy: 4,
  },
  {
    postcode: 3064,
    place_name: 'Donnybrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5333,
    longitude: 144.9833,
    accuracy: 4,
  },
  {
    postcode: 3064,
    place_name: 'Roxburgh Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6258,
    longitude: 144.9255,
    accuracy: 4,
  },
  {
    postcode: 3064,
    place_name: 'Craigieburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 144.95,
    accuracy: 4,
  },
  {
    postcode: 3065,
    place_name: 'Fitzroy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7984,
    longitude: 144.9783,
    accuracy: 4,
  },
  {
    postcode: 3066,
    place_name: 'Collingwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 144.9833,
    accuracy: 4,
  },
  {
    postcode: 3066,
    place_name: 'Collingwood North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 144.9833,
    accuracy: 3,
  },
  {
    postcode: 3067,
    place_name: 'Abbotsford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 145,
    accuracy: 4,
  },
  {
    postcode: 3068,
    place_name: 'Fitzroy North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7886,
    longitude: 144.9788,
    accuracy: 4,
  },
  {
    postcode: 3068,
    place_name: 'Clifton Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7904,
    longitude: 144.9975,
    accuracy: 4,
  },
  {
    postcode: 3070,
    place_name: 'Northcote South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 145,
    accuracy: 3,
  },
  {
    postcode: 3070,
    place_name: 'Northcote',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 145,
    accuracy: 4,
  },
  {
    postcode: 3071,
    place_name: 'Thornbury',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145,
    accuracy: 4,
  },
  {
    postcode: 3072,
    place_name: 'Preston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 3072,
    place_name: 'Regent West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7241,
    longitude: 145.0179,
    accuracy: 3,
  },
  {
    postcode: 3072,
    place_name: 'Gilberton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.0167,
    accuracy: 3,
  },
  {
    postcode: 3072,
    place_name: 'Preston South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7241,
    longitude: 145.0179,
    accuracy: 3,
  },
  {
    postcode: 3072,
    place_name: 'Preston West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.0167,
    accuracy: 3,
  },
  {
    postcode: 3072,
    place_name: 'Northland Centre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7241,
    longitude: 145.0179,
    accuracy: 3,
  },
  {
    postcode: 3072,
    place_name: 'Preston Lower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.0167,
    accuracy: 3,
  },
  {
    postcode: 3073,
    place_name: 'Reservoir',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 145,
    accuracy: 4,
  },
  {
    postcode: 3073,
    place_name: 'Keon Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 3074,
    place_name: 'Thomastown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6833,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 3075,
    place_name: 'Lalor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 3076,
    place_name: 'Epping Dc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6307,
    longitude: 145.006,
    accuracy: 3,
  },
  {
    postcode: 3076,
    place_name: 'Epping',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.65,
    longitude: 145.0333,
    accuracy: 4,
  },
  {
    postcode: 3078,
    place_name: 'Alphington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7804,
    longitude: 145.0309,
    accuracy: 4,
  },
  {
    postcode: 3078,
    place_name: 'Fairfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7798,
    longitude: 145.0176,
    accuracy: 4,
  },
  {
    postcode: 3079,
    place_name: 'Ivanhoe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.769,
    longitude: 145.0431,
    accuracy: 4,
  },
  {
    postcode: 3079,
    place_name: 'Ivanhoe East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7832,
    longitude: 145.0525,
    accuracy: 4,
  },
  {
    postcode: 3079,
    place_name: 'Ivanhoe North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.769,
    longitude: 145.0431,
    accuracy: 3,
  },
  {
    postcode: 3081,
    place_name: 'Heidelberg West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.0333,
    accuracy: 4,
  },
  {
    postcode: 3081,
    place_name: 'Heidelberg Rgh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.743,
    longitude: 145.0464,
    accuracy: 3,
  },
  {
    postcode: 3081,
    place_name: 'Bellfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7531,
    longitude: 145.0385,
    accuracy: 4,
  },
  {
    postcode: 3081,
    place_name: 'Heidelberg Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7431,
    longitude: 145.057,
    accuracy: 4,
  },
  {
    postcode: 3082,
    place_name: 'Mill Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3083,
    place_name: 'Kingsbury',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7141,
    longitude: 145.0351,
    accuracy: 4,
  },
  {
    postcode: 3083,
    place_name: 'Bundoora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6982,
    longitude: 145.0597,
    accuracy: 4,
  },
  {
    postcode: 3083,
    place_name: 'La Trobe University',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7062,
    longitude: 145.0474,
    accuracy: 3,
  },
  {
    postcode: 3084,
    place_name: 'Viewbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7399,
    longitude: 145.0932,
    accuracy: 4,
  },
  {
    postcode: 3084,
    place_name: 'Banyule',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7464,
    longitude: 145.0771,
    accuracy: 3,
  },
  {
    postcode: 3084,
    place_name: 'Rosanna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7396,
    longitude: 145.0697,
    accuracy: 4,
  },
  {
    postcode: 3084,
    place_name: 'Heidelberg',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3084,
    place_name: 'Eaglemont',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7638,
    longitude: 145.0638,
    accuracy: 4,
  },
  {
    postcode: 3085,
    place_name: 'Yallambie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7251,
    longitude: 145.0935,
    accuracy: 4,
  },
  {
    postcode: 3085,
    place_name: 'Macleod West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7228,
    longitude: 145.0817,
    accuracy: 3,
  },
  {
    postcode: 3085,
    place_name: 'Macleod',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7204,
    longitude: 145.0698,
    accuracy: 4,
  },
  {
    postcode: 3086,
    place_name: 'La Trobe University',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7173,
    longitude: 145.0826,
    accuracy: 1,
  },
  {
    postcode: 3087,
    place_name: 'Watsonia North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6998,
    longitude: 145.0847,
    accuracy: 4,
  },
  {
    postcode: 3087,
    place_name: 'Watsonia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 145.0833,
    accuracy: 4,
  },
  {
    postcode: 3088,
    place_name: 'Saint Helena',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 3088,
    place_name: 'Briar Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3088,
    place_name: 'Greensborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7046,
    longitude: 145.103,
    accuracy: 4,
  },
  {
    postcode: 3088,
    place_name: 'St Helena',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6895,
    longitude: 145.1296,
    accuracy: 4,
  },
  {
    postcode: 3089,
    place_name: 'Diamond Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 145.15,
    accuracy: 4,
  },
  {
    postcode: 3090,
    place_name: 'Plenty',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3091,
    place_name: 'Yarrambat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6375,
    longitude: 145.1349,
    accuracy: 4,
  },
  {
    postcode: 3093,
    place_name: 'Lower Plenty',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3094,
    place_name: 'Montmorency',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3095,
    place_name: 'Research',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3095,
    place_name: 'Eltham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 145.15,
    accuracy: 4,
  },
  {
    postcode: 3095,
    place_name: 'Eltham North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 145.15,
    accuracy: 4,
  },
  {
    postcode: 3096,
    place_name: 'Wattle Glen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3097,
    place_name: 'Bend Of Islands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7008,
    longitude: 145.2848,
    accuracy: 4,
  },
  {
    postcode: 3097,
    place_name: 'Kangaroo Ground',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6892,
    longitude: 145.2317,
    accuracy: 4,
  },
  {
    postcode: 3097,
    place_name: 'Watsons Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6833,
    longitude: 145.2333,
    accuracy: 4,
  },
  {
    postcode: 3099,
    place_name: 'Strathewen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 145.2667,
    accuracy: 4,
  },
  {
    postcode: 3099,
    place_name: 'Hurstbridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6416,
    longitude: 145.1941,
    accuracy: 4,
  },
  {
    postcode: 3099,
    place_name: 'Arthurs Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 145.2,
    accuracy: 4,
  },
  {
    postcode: 3099,
    place_name: 'Cottles Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 3099,
    place_name: 'Nutfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6167,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3101,
    place_name: 'Kew',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8064,
    longitude: 145.0309,
    accuracy: 4,
  },
  {
    postcode: 3101,
    place_name: 'Cotham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8064,
    longitude: 145.0309,
    accuracy: 3,
  },
  {
    postcode: 3102,
    place_name: 'Kew East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7976,
    longitude: 145.0538,
    accuracy: 4,
  },
  {
    postcode: 3103,
    place_name: 'Balwyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8117,
    longitude: 145.081,
    accuracy: 4,
  },
  {
    postcode: 3103,
    place_name: 'Stradbroke Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8117,
    longitude: 145.081,
    accuracy: 3,
  },
  {
    postcode: 3103,
    place_name: 'Deepdene',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3103,
    place_name: 'Balwyn East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8117,
    longitude: 145.081,
    accuracy: 3,
  },
  {
    postcode: 3104,
    place_name: 'Balwyn North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7909,
    longitude: 145.0939,
    accuracy: 4,
  },
  {
    postcode: 3104,
    place_name: 'Greythorn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7909,
    longitude: 145.0939,
    accuracy: 3,
  },
  {
    postcode: 3105,
    place_name: 'Bulleen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 145.0833,
    accuracy: 4,
  },
  {
    postcode: 3106,
    place_name: 'Templestowe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3107,
    place_name: 'Templestowe Lower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3108,
    place_name: 'Doncaster',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7883,
    longitude: 145.1237,
    accuracy: 4,
  },
  {
    postcode: 3109,
    place_name: 'Tunstall Square Po',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7795,
    longitude: 145.1638,
    accuracy: 3,
  },
  {
    postcode: 3109,
    place_name: 'Doncaster East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7876,
    longitude: 145.1489,
    accuracy: 4,
  },
  {
    postcode: 3109,
    place_name: 'Doncaster Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7795,
    longitude: 145.1638,
    accuracy: 3,
  },
  {
    postcode: 3110,
    place_name: 'Nunawading Bc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7576,
    longitude: 145.2408,
    accuracy: 1,
  },
  {
    postcode: 3111,
    place_name: 'Donvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7891,
    longitude: 145.1749,
    accuracy: 4,
  },
  {
    postcode: 3113,
    place_name: 'Warrandyte',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.2333,
    accuracy: 4,
  },
  {
    postcode: 3113,
    place_name: 'North Warrandyte',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7292,
    longitude: 145.2157,
    accuracy: 4,
  },
  {
    postcode: 3114,
    place_name: 'Park Orchards',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7769,
    longitude: 145.2146,
    accuracy: 4,
  },
  {
    postcode: 3115,
    place_name: 'Wonga Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 145.2667,
    accuracy: 4,
  },
  {
    postcode: 3116,
    place_name: 'Chirnside Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7386,
    longitude: 145.3143,
    accuracy: 4,
  },
  {
    postcode: 3121,
    place_name: 'Burnley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8286,
    longitude: 145.0077,
    accuracy: 4,
  },
  {
    postcode: 3121,
    place_name: 'Cremorne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 145,
    accuracy: 4,
  },
  {
    postcode: 3121,
    place_name: 'Richmond',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8182,
    longitude: 145.0018,
    accuracy: 4,
  },
  {
    postcode: 3121,
    place_name: 'Richmond East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8271,
    longitude: 145.0064,
    accuracy: 3,
  },
  {
    postcode: 3121,
    place_name: 'Burnley North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8271,
    longitude: 145.0064,
    accuracy: 3,
  },
  {
    postcode: 3121,
    place_name: 'Richmond South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8271,
    longitude: 145.0064,
    accuracy: 3,
  },
  {
    postcode: 3121,
    place_name: 'Richmond North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8271,
    longitude: 145.0064,
    accuracy: 3,
  },
  {
    postcode: 3122,
    place_name: 'Glenferrie South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8344,
    longitude: 145.0397,
    accuracy: 3,
  },
  {
    postcode: 3122,
    place_name: 'Auburn South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 145.05,
    accuracy: 4,
  },
  {
    postcode: 3122,
    place_name: 'Hawthorn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8199,
    longitude: 145.0358,
    accuracy: 4,
  },
  {
    postcode: 3122,
    place_name: 'Hawthorn North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 145.0333,
    accuracy: 3,
  },
  {
    postcode: 3122,
    place_name: 'Hawthorn West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8344,
    longitude: 145.0397,
    accuracy: 3,
  },
  {
    postcode: 3123,
    place_name: 'Auburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 145.05,
    accuracy: 4,
  },
  {
    postcode: 3123,
    place_name: 'Hawthorn East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8248,
    longitude: 145.0464,
    accuracy: 4,
  },
  {
    postcode: 3124,
    place_name: 'Camberwell North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8248,
    longitude: 145.0705,
    accuracy: 3,
  },
  {
    postcode: 3124,
    place_name: 'Camberwell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8421,
    longitude: 145.0694,
    accuracy: 4,
  },
  {
    postcode: 3124,
    place_name: 'Camberwell West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8248,
    longitude: 145.0705,
    accuracy: 3,
  },
  {
    postcode: 3124,
    place_name: 'Camberwell South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8248,
    longitude: 145.0705,
    accuracy: 3,
  },
  {
    postcode: 3124,
    place_name: 'Hartwell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8421,
    longitude: 145.0694,
    accuracy: 3,
  },
  {
    postcode: 3124,
    place_name: 'Middle Camberwell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8248,
    longitude: 145.0705,
    accuracy: 3,
  },
  {
    postcode: 3125,
    place_name: 'Burwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8505,
    longitude: 145.0949,
    accuracy: 4,
  },
  {
    postcode: 3125,
    place_name: 'Surrey Hills South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8498,
    longitude: 145.119,
    accuracy: 3,
  },
  {
    postcode: 3125,
    place_name: 'Bennettswood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8495,
    longitude: 145.1114,
    accuracy: 3,
  },
  {
    postcode: 3126,
    place_name: 'Canterbury',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8247,
    longitude: 145.0848,
    accuracy: 4,
  },
  {
    postcode: 3126,
    place_name: 'Camberwell East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8248,
    longitude: 145.0705,
    accuracy: 3,
  },
  {
    postcode: 3127,
    place_name: 'Surrey Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8252,
    longitude: 145.1004,
    accuracy: 4,
  },
  {
    postcode: 3127,
    place_name: 'Surrey Hills North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8128,
    longitude: 145.1043,
    accuracy: 3,
  },
  {
    postcode: 3127,
    place_name: 'Mont Albert',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 145.1,
    accuracy: 4,
  },
  {
    postcode: 3128,
    place_name: 'Box Hill South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8324,
    longitude: 145.121,
    accuracy: 4,
  },
  {
    postcode: 3128,
    place_name: 'Box Hill Central',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8256,
    longitude: 145.1232,
    accuracy: 3,
  },
  {
    postcode: 3128,
    place_name: 'Box Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8189,
    longitude: 145.1254,
    accuracy: 4,
  },
  {
    postcode: 3128,
    place_name: 'Wattle Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.829,
    longitude: 145.1248,
    accuracy: 3,
  },
  {
    postcode: 3128,
    place_name: 'Houston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.829,
    longitude: 145.1248,
    accuracy: 3,
  },
  {
    postcode: 3129,
    place_name: 'Box Hill North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8027,
    longitude: 145.1266,
    accuracy: 4,
  },
  {
    postcode: 3129,
    place_name: 'Kerrimuir',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8048,
    longitude: 145.1229,
    accuracy: 3,
  },
  {
    postcode: 3129,
    place_name: 'Mont Albert North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8004,
    longitude: 145.1083,
    accuracy: 4,
  },
  {
    postcode: 3130,
    place_name: 'Blackburn North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8093,
    longitude: 145.1518,
    accuracy: 4,
  },
  {
    postcode: 3130,
    place_name: 'Blackburn South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8398,
    longitude: 145.1555,
    accuracy: 4,
  },
  {
    postcode: 3130,
    place_name: 'Blackburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.819,
    longitude: 145.1533,
    accuracy: 4,
  },
  {
    postcode: 3130,
    place_name: 'Laburnum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8232,
    longitude: 145.1511,
    accuracy: 3,
  },
  {
    postcode: 3131,
    place_name: 'Forest Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3131,
    place_name: 'Brentford Square',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8275,
    longitude: 145.1739,
    accuracy: 3,
  },
  {
    postcode: 3131,
    place_name: 'Nunawading',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8204,
    longitude: 145.1731,
    accuracy: 4,
  },
  {
    postcode: 3132,
    place_name: 'Mitcham North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8182,
    longitude: 145.199,
    accuracy: 3,
  },
  {
    postcode: 3132,
    place_name: 'Mitcham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 145.2,
    accuracy: 4,
  },
  {
    postcode: 3132,
    place_name: 'Rangeview',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8186,
    longitude: 145.1987,
    accuracy: 3,
  },
  {
    postcode: 3133,
    place_name: 'Vermont',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8362,
    longitude: 145.1943,
    accuracy: 4,
  },
  {
    postcode: 3133,
    place_name: 'Vermont South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8575,
    longitude: 145.1827,
    accuracy: 4,
  },
  {
    postcode: 3134,
    place_name: 'Warrandyte South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 145.25,
    accuracy: 4,
  },
  {
    postcode: 3134,
    place_name: 'Heathwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7944,
    longitude: 145.2389,
    accuracy: 3,
  },
  {
    postcode: 3134,
    place_name: 'Warranwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7756,
    longitude: 145.2473,
    accuracy: 4,
  },
  {
    postcode: 3134,
    place_name: 'Ringwood North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 145.2333,
    accuracy: 4,
  },
  {
    postcode: 3134,
    place_name: 'Ringwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 145.2333,
    accuracy: 4,
  },
  {
    postcode: 3135,
    place_name: 'Bedford Road',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8213,
    longitude: 145.2489,
    accuracy: 3,
  },
  {
    postcode: 3135,
    place_name: 'Ringwood East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 145.25,
    accuracy: 4,
  },
  {
    postcode: 3135,
    place_name: 'Heathmont',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 145.25,
    accuracy: 4,
  },
  {
    postcode: 3136,
    place_name: 'Croydon North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 145.3,
    accuracy: 4,
  },
  {
    postcode: 3136,
    place_name: 'Croydon South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8123,
    longitude: 145.2681,
    accuracy: 4,
  },
  {
    postcode: 3136,
    place_name: 'Croydon Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7765,
    longitude: 145.2636,
    accuracy: 4,
  },
  {
    postcode: 3136,
    place_name: 'Croydon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 145.2833,
    accuracy: 4,
  },
  {
    postcode: 3137,
    place_name: 'Kilsyth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 145.3167,
    accuracy: 4,
  },
  {
    postcode: 3137,
    place_name: 'Kilsyth South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8203,
    longitude: 145.316,
    accuracy: 4,
  },
  {
    postcode: 3138,
    place_name: 'Mooroolbark',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 145.3,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Hoddles Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 145.5833,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Beenak',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.6333,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Don Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7364,
    longitude: 145.5923,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Seville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 145.4667,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Woori Yallock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7806,
    longitude: 145.5382,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Yellingbo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.846,
    longitude: 145.5291,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Wandin North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 145.4333,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Launching Place',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7806,
    longitude: 145.5683,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Seville East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.775,
    longitude: 145.4973,
    accuracy: 4,
  },
  {
    postcode: 3139,
    place_name: 'Wandin East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8151,
    longitude: 145.458,
    accuracy: 4,
  },
  {
    postcode: 3140,
    place_name: 'Lilydale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3141,
    place_name: 'South Yarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 144.9833,
    accuracy: 4,
  },
  {
    postcode: 3142,
    place_name: 'Toorak',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8416,
    longitude: 145.0144,
    accuracy: 4,
  },
  {
    postcode: 3142,
    place_name: 'Hawksburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8416,
    longitude: 145.0177,
    accuracy: 3,
  },
  {
    postcode: 3143,
    place_name: 'Armadale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8554,
    longitude: 145.0205,
    accuracy: 4,
  },
  {
    postcode: 3143,
    place_name: 'Armadale North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 145.0333,
    accuracy: 4,
  },
  {
    postcode: 3144,
    place_name: 'Kooyong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8399,
    longitude: 145.0352,
    accuracy: 4,
  },
  {
    postcode: 3144,
    place_name: 'Malvern',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8626,
    longitude: 145.0281,
    accuracy: 4,
  },
  {
    postcode: 3144,
    place_name: 'Malvern North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8512,
    longitude: 145.0317,
    accuracy: 3,
  },
  {
    postcode: 3145,
    place_name: 'Malvern East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.874,
    longitude: 145.0425,
    accuracy: 4,
  },
  {
    postcode: 3145,
    place_name: 'Darling',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8775,
    longitude: 145.0624,
    accuracy: 3,
  },
  {
    postcode: 3145,
    place_name: 'Caulfield East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.05,
    accuracy: 4,
  },
  {
    postcode: 3145,
    place_name: 'Central Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8775,
    longitude: 145.0624,
    accuracy: 3,
  },
  {
    postcode: 3145,
    place_name: 'Darling South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.874,
    longitude: 145.0425,
    accuracy: 3,
  },
  {
    postcode: 3145,
    place_name: 'Wattletree Road Po',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.874,
    longitude: 145.0425,
    accuracy: 3,
  },
  {
    postcode: 3146,
    place_name: 'Glen Iris',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3146,
    place_name: 'Tooronga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8599,
    longitude: 145.0549,
    accuracy: 3,
  },
  {
    postcode: 3147,
    place_name: 'Ashwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8666,
    longitude: 145.1055,
    accuracy: 4,
  },
  {
    postcode: 3147,
    place_name: 'Ashburton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8626,
    longitude: 145.0794,
    accuracy: 4,
  },
  {
    postcode: 3148,
    place_name: 'Jordanville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.1167,
    accuracy: 3,
  },
  {
    postcode: 3148,
    place_name: 'Holmesglen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8821,
    longitude: 145.1002,
    accuracy: 3,
  },
  {
    postcode: 3148,
    place_name: 'Chadstone',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8877,
    longitude: 145.0952,
    accuracy: 4,
  },
  {
    postcode: 3148,
    place_name: 'Chadstone Centre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8855,
    longitude: 145.1059,
    accuracy: 3,
  },
  {
    postcode: 3149,
    place_name: 'Syndal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8742,
    longitude: 145.1479,
    accuracy: 4,
  },
  {
    postcode: 3149,
    place_name: 'Pinewood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8756,
    longitude: 145.1387,
    accuracy: 3,
  },
  {
    postcode: 3149,
    place_name: 'Mount Waverley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8771,
    longitude: 145.1294,
    accuracy: 4,
  },
  {
    postcode: 3150,
    place_name: 'Wheelers Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3150,
    place_name: 'Brandon Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8891,
    longitude: 145.174,
    accuracy: 3,
  },
  {
    postcode: 3150,
    place_name: 'Glen Waverley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8781,
    longitude: 145.1648,
    accuracy: 4,
  },
  {
    postcode: 3151,
    place_name: 'Burwood Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8535,
    longitude: 145.1503,
    accuracy: 3,
  },
  {
    postcode: 3151,
    place_name: 'Burwood East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 145.15,
    accuracy: 4,
  },
  {
    postcode: 3152,
    place_name: 'Wantirna South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 3152,
    place_name: 'Knox City Centre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8656,
    longitude: 145.2228,
    accuracy: 3,
  },
  {
    postcode: 3152,
    place_name: 'Wantirna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 3152,
    place_name: 'Studfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8656,
    longitude: 145.2228,
    accuracy: 3,
  },
  {
    postcode: 3153,
    place_name: 'Bayswater North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8261,
    longitude: 145.2983,
    accuracy: 4,
  },
  {
    postcode: 3153,
    place_name: 'Bayswater',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 145.2667,
    accuracy: 4,
  },
  {
    postcode: 3154,
    place_name: 'The Basin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 145.3167,
    accuracy: 4,
  },
  {
    postcode: 3155,
    place_name: 'Boronia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 145.2833,
    accuracy: 4,
  },
  {
    postcode: 3156,
    place_name: 'Lysterfield South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9581,
    longitude: 145.2727,
    accuracy: 4,
  },
  {
    postcode: 3156,
    place_name: 'Lysterfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 145.3,
    accuracy: 4,
  },
  {
    postcode: 3156,
    place_name: 'Mountain Gate',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9136,
    longitude: 145.2823,
    accuracy: 3,
  },
  {
    postcode: 3156,
    place_name: 'Upper Ferntree Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8942,
    longitude: 145.3124,
    accuracy: 4,
  },
  {
    postcode: 3156,
    place_name: 'Ferntree Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8846,
    longitude: 145.2954,
    accuracy: 4,
  },
  {
    postcode: 3158,
    place_name: 'Upwey',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3159,
    place_name: 'Menzies Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 145.4,
    accuracy: 4,
  },
  {
    postcode: 3159,
    place_name: 'Selby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 145.3833,
    accuracy: 4,
  },
  {
    postcode: 3160,
    place_name: 'Tecoma',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.903,
    longitude: 145.3447,
    accuracy: 4,
  },
  {
    postcode: 3160,
    place_name: 'Belgrave South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3160,
    place_name: 'Belgrave Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3160,
    place_name: 'Belgrave',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3161,
    place_name: 'Caulfield North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8739,
    longitude: 145.0249,
    accuracy: 4,
  },
  {
    postcode: 3161,
    place_name: 'Caulfield Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.872,
    longitude: 145.0216,
    accuracy: 3,
  },
  {
    postcode: 3162,
    place_name: 'Caulfield South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8956,
    longitude: 145.026,
    accuracy: 4,
  },
  {
    postcode: 3162,
    place_name: 'Hopetoun Gardens',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8914,
    longitude: 145.0254,
    accuracy: 3,
  },
  {
    postcode: 3162,
    place_name: 'Caulfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8825,
    longitude: 145.0229,
    accuracy: 4,
  },
  {
    postcode: 3163,
    place_name: 'Glen Huntly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.05,
    accuracy: 4,
  },
  {
    postcode: 3163,
    place_name: 'Carnegie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3163,
    place_name: 'Booran Road Po',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8895,
    longitude: 145.0581,
    accuracy: 3,
  },
  {
    postcode: 3163,
    place_name: 'Murrumbeena',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8902,
    longitude: 145.0711,
    accuracy: 4,
  },
  {
    postcode: 3164,
    place_name: 'Dandenong South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 145.2,
    accuracy: 4,
  },
  {
    postcode: 3165,
    place_name: 'Coatesville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9221,
    longitude: 145.0667,
    accuracy: 3,
  },
  {
    postcode: 3165,
    place_name: 'Bentleigh East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9193,
    longitude: 145.053,
    accuracy: 4,
  },
  {
    postcode: 3166,
    place_name: 'Oakleigh East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3166,
    place_name: 'Oakleigh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8981,
    longitude: 145.0884,
    accuracy: 4,
  },
  {
    postcode: 3166,
    place_name: 'Huntingdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9077,
    longitude: 145.1085,
    accuracy: 4,
  },
  {
    postcode: 3166,
    place_name: 'Hughesdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.0833,
    accuracy: 4,
  },
  {
    postcode: 3167,
    place_name: 'Oakleigh South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.1,
    accuracy: 4,
  },
  {
    postcode: 3168,
    place_name: 'Notting Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.905,
    longitude: 145.1427,
    accuracy: 4,
  },
  {
    postcode: 3168,
    place_name: 'Clayton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9173,
    longitude: 145.1304,
    accuracy: 4,
  },
  {
    postcode: 3169,
    place_name: 'Clarinda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3169,
    place_name: 'Clayton South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3170,
    place_name: 'Waverley Gardens',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 145.2,
    accuracy: 3,
  },
  {
    postcode: 3170,
    place_name: 'Mulgrave',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 145.2,
    accuracy: 4,
  },
  {
    postcode: 3171,
    place_name: 'Sandown Village',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9457,
    longitude: 145.1581,
    accuracy: 3,
  },
  {
    postcode: 3171,
    place_name: 'Springvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9485,
    longitude: 145.1527,
    accuracy: 4,
  },
  {
    postcode: 3172,
    place_name: 'Springvale South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9667,
    longitude: 145.15,
    accuracy: 4,
  },
  {
    postcode: 3172,
    place_name: 'Dingley Village',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9827,
    longitude: 145.1342,
    accuracy: 4,
  },
  {
    postcode: 3173,
    place_name: 'Keysborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9912,
    longitude: 145.1739,
    accuracy: 4,
  },
  {
    postcode: 3174,
    place_name: 'Noble Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9667,
    longitude: 145.1667,
    accuracy: 4,
  },
  {
    postcode: 3174,
    place_name: 'Noble Park North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9498,
    longitude: 145.1926,
    accuracy: 4,
  },
  {
    postcode: 3175,
    place_name: 'Dandenong East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 145.2,
    accuracy: 3,
  },
  {
    postcode: 3175,
    place_name: 'Dunearn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 145.2,
    accuracy: 3,
  },
  {
    postcode: 3175,
    place_name: 'Dandenong South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 145.2,
    accuracy: 4,
  },
  {
    postcode: 3175,
    place_name: 'Dandenong North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9665,
    longitude: 145.2081,
    accuracy: 4,
  },
  {
    postcode: 3175,
    place_name: 'Dandenong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 145.2,
    accuracy: 4,
  },
  {
    postcode: 3175,
    place_name: 'Bangholme',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0384,
    longitude: 145.1869,
    accuracy: 4,
  },
  {
    postcode: 3176,
    place_name: 'Scoresby Bc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9636,
    longitude: 145.1943,
    accuracy: 1,
  },
  {
    postcode: 3177,
    place_name: 'Doveton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9935,
    longitude: 145.2389,
    accuracy: 4,
  },
  {
    postcode: 3177,
    place_name: 'Eumemmerring',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9978,
    longitude: 145.2482,
    accuracy: 4,
  },
  {
    postcode: 3178,
    place_name: 'Rowville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 145.2333,
    accuracy: 4,
  },
  {
    postcode: 3179,
    place_name: 'Scoresby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.2333,
    accuracy: 4,
  },
  {
    postcode: 3180,
    place_name: 'Knoxfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8898,
    longitude: 145.2496,
    accuracy: 4,
  },
  {
    postcode: 3181,
    place_name: 'Prahran',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8511,
    longitude: 144.9932,
    accuracy: 4,
  },
  {
    postcode: 3181,
    place_name: 'Windsor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8548,
    longitude: 144.9927,
    accuracy: 4,
  },
  {
    postcode: 3181,
    place_name: 'Prahran East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.853,
    longitude: 144.9929,
    accuracy: 3,
  },
  {
    postcode: 3182,
    place_name: 'St Kilda West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8599,
    longitude: 144.9711,
    accuracy: 4,
  },
  {
    postcode: 3182,
    place_name: 'St Kilda South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8633,
    longitude: 144.9802,
    accuracy: 3,
  },
  {
    postcode: 3182,
    place_name: 'St Kilda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8633,
    longitude: 144.9802,
    accuracy: 3,
  },
  {
    postcode: 3183,
    place_name: 'St Kilda East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8659,
    longitude: 145.0002,
    accuracy: 4,
  },
  {
    postcode: 3183,
    place_name: 'Balaclava',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8692,
    longitude: 144.9977,
    accuracy: 4,
  },
  {
    postcode: 3184,
    place_name: 'Elwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8821,
    longitude: 144.9821,
    accuracy: 4,
  },
  {
    postcode: 3184,
    place_name: 'Brighton Road',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8809,
    longitude: 144.9855,
    accuracy: 3,
  },
  {
    postcode: 3185,
    place_name: 'Elsternwick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8864,
    longitude: 145.0025,
    accuracy: 4,
  },
  {
    postcode: 3185,
    place_name: 'Gardenvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8979,
    longitude: 145.0074,
    accuracy: 4,
  },
  {
    postcode: 3185,
    place_name: 'Ripponlea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145,
    accuracy: 4,
  },
  {
    postcode: 3186,
    place_name: 'Dendy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.895,
    longitude: 145.0191,
    accuracy: 3,
  },
  {
    postcode: 3186,
    place_name: 'Brighton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9056,
    longitude: 145.0028,
    accuracy: 4,
  },
  {
    postcode: 3186,
    place_name: 'Brighton North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9069,
    longitude: 145.0053,
    accuracy: 4,
  },
  {
    postcode: 3186,
    place_name: 'Were Street Po',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9057,
    longitude: 145.0067,
    accuracy: 3,
  },
  {
    postcode: 3187,
    place_name: 'North Road',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9667,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 3187,
    place_name: 'Brighton East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9023,
    longitude: 145.0173,
    accuracy: 4,
  },
  {
    postcode: 3188,
    place_name: 'Hampton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9372,
    longitude: 145.0046,
    accuracy: 4,
  },
  {
    postcode: 3188,
    place_name: 'Hampton East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.937,
    longitude: 145.0286,
    accuracy: 4,
  },
  {
    postcode: 3188,
    place_name: 'Hampton North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9374,
    longitude: 145.0151,
    accuracy: 3,
  },
  {
    postcode: 3189,
    place_name: 'Moorabbin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9367,
    longitude: 145.0342,
    accuracy: 4,
  },
  {
    postcode: 3189,
    place_name: 'Moorabbin East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9367,
    longitude: 145.0342,
    accuracy: 4,
  },
  {
    postcode: 3189,
    place_name: 'Wishart',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9415,
    longitude: 145.0578,
    accuracy: 3,
  },
  {
    postcode: 3190,
    place_name: 'Highett',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 145.05,
    accuracy: 4,
  },
  {
    postcode: 3191,
    place_name: 'Sandringham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 145,
    accuracy: 4,
  },
  {
    postcode: 3192,
    place_name: 'Cheltenham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9694,
    longitude: 145.0481,
    accuracy: 4,
  },
  {
    postcode: 3192,
    place_name: 'Cheltenham East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9634,
    longitude: 145.0615,
    accuracy: 3,
  },
  {
    postcode: 3192,
    place_name: 'Southland Centre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9634,
    longitude: 145.0615,
    accuracy: 3,
  },
  {
    postcode: 3193,
    place_name: 'Beaumaris',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9822,
    longitude: 145.0389,
    accuracy: 4,
  },
  {
    postcode: 3193,
    place_name: 'Black Rock North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9667,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 3193,
    place_name: 'Black Rock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9736,
    longitude: 145.0164,
    accuracy: 4,
  },
  {
    postcode: 3193,
    place_name: 'Cromer',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9773,
    longitude: 145.0323,
    accuracy: 3,
  },
  {
    postcode: 3194,
    place_name: 'Mentone',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3194,
    place_name: 'Mentone East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 145.0667,
    accuracy: 3,
  },
  {
    postcode: 3194,
    place_name: 'Moorabbin Airport',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9799,
    longitude: 145.0815,
    accuracy: 3,
  },
  {
    postcode: 3195,
    place_name: 'Braeside',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9995,
    longitude: 145.1163,
    accuracy: 4,
  },
  {
    postcode: 3195,
    place_name: 'Mordialloc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 145.0833,
    accuracy: 4,
  },
  {
    postcode: 3195,
    place_name: 'Waterways',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0148,
    longitude: 145.1305,
    accuracy: 4,
  },
  {
    postcode: 3195,
    place_name: 'Aspendale Gardens',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0228,
    longitude: 145.118,
    accuracy: 4,
  },
  {
    postcode: 3195,
    place_name: 'Parkdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9942,
    longitude: 145.0734,
    accuracy: 4,
  },
  {
    postcode: 3195,
    place_name: 'Aspendale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0291,
    longitude: 145.1027,
    accuracy: 4,
  },
  {
    postcode: 3196,
    place_name: 'Chelsea Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 3196,
    place_name: 'Edithvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0372,
    longitude: 145.1097,
    accuracy: 4,
  },
  {
    postcode: 3196,
    place_name: 'Bonbeach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0653,
    longitude: 145.1228,
    accuracy: 4,
  },
  {
    postcode: 3196,
    place_name: 'Chelsea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3197,
    place_name: 'Carrum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 3197,
    place_name: 'Patterson Lakes',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0693,
    longitude: 145.1433,
    accuracy: 4,
  },
  {
    postcode: 3198,
    place_name: 'Belvedere Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1049,
    longitude: 145.1382,
    accuracy: 3,
  },
  {
    postcode: 3198,
    place_name: 'Seaford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 3199,
    place_name: 'Karingal Centre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1278,
    longitude: 145.15,
    accuracy: 3,
  },
  {
    postcode: 3199,
    place_name: 'Frankston South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.166,
    longitude: 145.1364,
    accuracy: 4,
  },
  {
    postcode: 3199,
    place_name: 'Frankston East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 3199,
    place_name: 'Frankston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1446,
    longitude: 145.1229,
    accuracy: 4,
  },
  {
    postcode: 3199,
    place_name: 'Frankston Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1278,
    longitude: 145.15,
    accuracy: 3,
  },
  {
    postcode: 3199,
    place_name: 'Karingal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1604,
    longitude: 145.1399,
    accuracy: 3,
  },
  {
    postcode: 3200,
    place_name: 'Frankston North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1235,
    longitude: 145.1484,
    accuracy: 4,
  },
  {
    postcode: 3200,
    place_name: 'Pines Forest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1213,
    longitude: 145.1552,
    accuracy: 3,
  },
  {
    postcode: 3201,
    place_name: 'Carrum Downs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0997,
    longitude: 145.1725,
    accuracy: 4,
  },
  {
    postcode: 3202,
    place_name: 'Heatherton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.956,
    longitude: 145.0984,
    accuracy: 4,
  },
  {
    postcode: 3204,
    place_name: 'Ormond',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9039,
    longitude: 145.0415,
    accuracy: 4,
  },
  {
    postcode: 3204,
    place_name: 'Patterson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0693,
    longitude: 145.1433,
    accuracy: 4,
  },
  {
    postcode: 3204,
    place_name: 'Bentleigh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9181,
    longitude: 145.0354,
    accuracy: 4,
  },
  {
    postcode: 3204,
    place_name: 'Mckinnon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 145.05,
    accuracy: 4,
  },
  {
    postcode: 3205,
    place_name: 'South Melbourne Dc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8335,
    longitude: 144.9571,
    accuracy: 3,
  },
  {
    postcode: 3205,
    place_name: 'South Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 144.9667,
    accuracy: 4,
  },
  {
    postcode: 3206,
    place_name: 'Albert Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 145,
    accuracy: 4,
  },
  {
    postcode: 3206,
    place_name: 'Middle Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8512,
    longitude: 144.962,
    accuracy: 4,
  },
  {
    postcode: 3207,
    place_name: 'Garden City',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.836,
    longitude: 144.9183,
    accuracy: 4,
  },
  {
    postcode: 3207,
    place_name: 'Port Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8396,
    longitude: 144.9423,
    accuracy: 4,
  },
  {
    postcode: 3211,
    place_name: 'Little River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9667,
    longitude: 144.5,
    accuracy: 4,
  },
  {
    postcode: 3212,
    place_name: 'Avalon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0239,
    longitude: 144.4062,
    accuracy: 3,
  },
  {
    postcode: 3212,
    place_name: 'Lara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0239,
    longitude: 144.4062,
    accuracy: 4,
  },
  {
    postcode: 3212,
    place_name: 'Point Wilson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0239,
    longitude: 144.4062,
    accuracy: 3,
  },
  {
    postcode: 3213,
    place_name: 'Anakie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 144.25,
    accuracy: 4,
  },
  {
    postcode: 3213,
    place_name: 'Moorabool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 144.3,
    accuracy: 4,
  },
  {
    postcode: 3213,
    place_name: 'Lovely Banks',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0667,
    longitude: 144.3333,
    accuracy: 4,
  },
  {
    postcode: 3213,
    place_name: 'Batesford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 144.2833,
    accuracy: 4,
  },
  {
    postcode: 3214,
    place_name: 'Norlane',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1014,
    longitude: 144.3542,
    accuracy: 4,
  },
  {
    postcode: 3214,
    place_name: 'North Shore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 144.3667,
    accuracy: 4,
  },
  {
    postcode: 3214,
    place_name: 'Corio',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 144.3833,
    accuracy: 4,
  },
  {
    postcode: 3215,
    place_name: 'Geelong North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 144.3667,
    accuracy: 3,
  },
  {
    postcode: 3215,
    place_name: 'Bell Post Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0956,
    longitude: 144.3197,
    accuracy: 4,
  },
  {
    postcode: 3215,
    place_name: 'North Geelong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1095,
    longitude: 144.3513,
    accuracy: 4,
  },
  {
    postcode: 3215,
    place_name: 'Rippleside',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1257,
    longitude: 144.355,
    accuracy: 4,
  },
  {
    postcode: 3215,
    place_name: 'Hamlyn Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1211,
    longitude: 144.328,
    accuracy: 4,
  },
  {
    postcode: 3215,
    place_name: 'Drumcondra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1302,
    longitude: 144.3538,
    accuracy: 4,
  },
  {
    postcode: 3215,
    place_name: 'Bell Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1079,
    longitude: 144.3384,
    accuracy: 4,
  },
  {
    postcode: 3216,
    place_name: 'Highton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1706,
    longitude: 144.3114,
    accuracy: 4,
  },
  {
    postcode: 3216,
    place_name: 'Belmont',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1748,
    longitude: 144.3428,
    accuracy: 4,
  },
  {
    postcode: 3216,
    place_name: 'Freshwater Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 144.2833,
    accuracy: 4,
  },
  {
    postcode: 3216,
    place_name: 'Grovedale East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2113,
    longitude: 144.3244,
    accuracy: 3,
  },
  {
    postcode: 3216,
    place_name: 'Mount Duneed',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2409,
    longitude: 144.3375,
    accuracy: 4,
  },
  {
    postcode: 3216,
    place_name: 'Marshall',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 144.3667,
    accuracy: 4,
  },
  {
    postcode: 3216,
    place_name: 'Wandana Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1758,
    longitude: 144.3039,
    accuracy: 4,
  },
  {
    postcode: 3216,
    place_name: 'Grovedale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 144.35,
    accuracy: 4,
  },
  {
    postcode: 3216,
    place_name: 'Waurn Ponds',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 144.2833,
    accuracy: 4,
  },
  {
    postcode: 3217,
    place_name: 'Deakin University',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1974,
    longitude: 144.3023,
    accuracy: 3,
  },
  {
    postcode: 3217,
    place_name: 'Mount Duneed',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2409,
    longitude: 144.3375,
    accuracy: 4,
  },
  {
    postcode: 3217,
    place_name: 'Freshwater Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 144.2833,
    accuracy: 4,
  },
  {
    postcode: 3217,
    place_name: 'Charlemont',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2187,
    longitude: 144.3677,
    accuracy: 4,
  },
  {
    postcode: 3217,
    place_name: 'Armstrong Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2312,
    longitude: 144.3737,
    accuracy: 4,
  },
  {
    postcode: 3218,
    place_name: 'Fyansford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 144.3167,
    accuracy: 4,
  },
  {
    postcode: 3218,
    place_name: 'Stonehaven',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1236,
    longitude: 144.2291,
    accuracy: 4,
  },
  {
    postcode: 3218,
    place_name: 'Geelong West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1389,
    longitude: 144.3484,
    accuracy: 4,
  },
  {
    postcode: 3218,
    place_name: 'Manifold Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1376,
    longitude: 144.3337,
    accuracy: 4,
  },
  {
    postcode: 3218,
    place_name: 'Herne Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1343,
    longitude: 144.3241,
    accuracy: 4,
  },
  {
    postcode: 3218,
    place_name: 'Murgheboluc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3219,
    place_name: 'Thomson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1698,
    longitude: 144.3789,
    accuracy: 4,
  },
  {
    postcode: 3219,
    place_name: 'St Albans Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.188,
    longitude: 144.3921,
    accuracy: 4,
  },
  {
    postcode: 3219,
    place_name: 'Newcomb',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1662,
    longitude: 144.3943,
    accuracy: 4,
  },
  {
    postcode: 3219,
    place_name: 'East Geelong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1557,
    longitude: 144.372,
    accuracy: 4,
  },
  {
    postcode: 3219,
    place_name: 'Whittington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1808,
    longitude: 144.3932,
    accuracy: 4,
  },
  {
    postcode: 3219,
    place_name: 'Breakwater',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1799,
    longitude: 144.3676,
    accuracy: 4,
  },
  {
    postcode: 3220,
    place_name: 'South Geelong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1619,
    longitude: 144.3556,
    accuracy: 4,
  },
  {
    postcode: 3220,
    place_name: 'Bareena',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1505,
    longitude: 144.3479,
    accuracy: 3,
  },
  {
    postcode: 3220,
    place_name: 'Newtown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1539,
    longitude: 144.3351,
    accuracy: 4,
  },
  {
    postcode: 3220,
    place_name: 'Geelong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1471,
    longitude: 144.3607,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Murgheboluc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Fyansford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 144.3167,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Stonehaven',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1236,
    longitude: 144.2291,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Batesford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 144.2833,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Gnarwarre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 144.15,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Moolap',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 144.4333,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Bellarine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 144.6167,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Geelong Mc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2043,
    longitude: 144.223,
    accuracy: 3,
  },
  {
    postcode: 3221,
    place_name: 'Ceres',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 144.2667,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Staughton Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 144.2833,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Wongarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7167,
    longitude: 143.75,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Moorabool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 144.3,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Anakie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 144.25,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Barrabool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 144.2263,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Kennett River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6685,
    longitude: 143.8584,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Wye River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 143.8833,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Wallington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 144.4833,
    accuracy: 4,
  },
  {
    postcode: 3221,
    place_name: 'Lovely Banks',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0667,
    longitude: 144.3333,
    accuracy: 4,
  },
  {
    postcode: 3222,
    place_name: 'Mannerim',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 144.5833,
    accuracy: 4,
  },
  {
    postcode: 3222,
    place_name: 'Drysdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1724,
    longitude: 144.5699,
    accuracy: 4,
  },
  {
    postcode: 3222,
    place_name: 'Marcus Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 144.5667,
    accuracy: 4,
  },
  {
    postcode: 3222,
    place_name: 'Clifton Springs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1567,
    longitude: 144.5551,
    accuracy: 4,
  },
  {
    postcode: 3222,
    place_name: 'Curlewis',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 144.4833,
    accuracy: 4,
  },
  {
    postcode: 3222,
    place_name: 'Wallington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 144.4833,
    accuracy: 4,
  },
  {
    postcode: 3223,
    place_name: 'St Leonards',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1705,
    longitude: 144.718,
    accuracy: 4,
  },
  {
    postcode: 3223,
    place_name: 'Indented Head',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1431,
    longitude: 144.7134,
    accuracy: 4,
  },
  {
    postcode: 3223,
    place_name: 'Bellarine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 144.6167,
    accuracy: 4,
  },
  {
    postcode: 3223,
    place_name: 'Portarlington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1154,
    longitude: 144.6556,
    accuracy: 4,
  },
  {
    postcode: 3224,
    place_name: 'Leopold',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.19,
    longitude: 144.4682,
    accuracy: 4,
  },
  {
    postcode: 3224,
    place_name: 'Moolap',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 144.4333,
    accuracy: 4,
  },
  {
    postcode: 3225,
    place_name: 'Point Lonsdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2817,
    longitude: 144.6105,
    accuracy: 4,
  },
  {
    postcode: 3225,
    place_name: 'Queenscliff',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2679,
    longitude: 144.6619,
    accuracy: 4,
  },
  {
    postcode: 3225,
    place_name: 'Swan Island',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4034,
    longitude: 143.6912,
    accuracy: 4,
  },
  {
    postcode: 3225,
    place_name: 'Swan Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2756,
    longitude: 144.631,
    accuracy: 3,
  },
  {
    postcode: 3226,
    place_name: 'Ocean Grove',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2653,
    longitude: 144.5147,
    accuracy: 4,
  },
  {
    postcode: 3227,
    place_name: 'Connewarre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 144.4,
    accuracy: 4,
  },
  {
    postcode: 3227,
    place_name: 'Breamlea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2597,
    longitude: 144.4509,
    accuracy: 3,
  },
  {
    postcode: 3227,
    place_name: 'Barwon Heads',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2653,
    longitude: 144.5147,
    accuracy: 4,
  },
  {
    postcode: 3228,
    place_name: 'Bellbrae',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 144.2667,
    accuracy: 4,
  },
  {
    postcode: 3228,
    place_name: 'Torquay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3308,
    longitude: 144.3264,
    accuracy: 4,
  },
  {
    postcode: 3228,
    place_name: 'Bells Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3751,
    longitude: 144.2564,
    accuracy: 4,
  },
  {
    postcode: 3228,
    place_name: 'Jan Juc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3445,
    longitude: 144.2952,
    accuracy: 4,
  },
  {
    postcode: 3230,
    place_name: 'Anglesea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4073,
    longitude: 144.1859,
    accuracy: 4,
  },
  {
    postcode: 3231,
    place_name: 'Aireys Inlet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.464,
    longitude: 144.1039,
    accuracy: 4,
  },
  {
    postcode: 3231,
    place_name: 'Eastern View',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4595,
    longitude: 144.0909,
    accuracy: 3,
  },
  {
    postcode: 3231,
    place_name: 'Fairhaven',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 145.2833,
    accuracy: 4,
  },
  {
    postcode: 3231,
    place_name: 'Big Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.407,
    longitude: 144.6936,
    accuracy: 3,
  },
  {
    postcode: 3231,
    place_name: 'Moggs Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4595,
    longitude: 144.0909,
    accuracy: 3,
  },
  {
    postcode: 3232,
    place_name: 'Lorne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5404,
    longitude: 143.9764,
    accuracy: 4,
  },
  {
    postcode: 3233,
    place_name: 'Apollo Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7594,
    longitude: 143.6722,
    accuracy: 4,
  },
  {
    postcode: 3233,
    place_name: 'Cape Otway',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.85,
    longitude: 143.5167,
    accuracy: 4,
  },
  {
    postcode: 3233,
    place_name: 'Skenes Creek North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7803,
    longitude: 143.6403,
    accuracy: 3,
  },
  {
    postcode: 3233,
    place_name: 'Marengo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7783,
    longitude: 143.6555,
    accuracy: 4,
  },
  {
    postcode: 3233,
    place_name: 'Petticoat Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7803,
    longitude: 143.6403,
    accuracy: 3,
  },
  {
    postcode: 3233,
    place_name: 'Skenes Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7333,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3234,
    place_name: 'Grey River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6622,
    longitude: 143.8466,
    accuracy: 3,
  },
  {
    postcode: 3234,
    place_name: 'Wye River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 143.8833,
    accuracy: 4,
  },
  {
    postcode: 3234,
    place_name: 'Wongarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7167,
    longitude: 143.75,
    accuracy: 4,
  },
  {
    postcode: 3234,
    place_name: 'Separation Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6304,
    longitude: 143.8945,
    accuracy: 4,
  },
  {
    postcode: 3234,
    place_name: 'Sugarloaf',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6622,
    longitude: 143.8466,
    accuracy: 3,
  },
  {
    postcode: 3234,
    place_name: 'Kennett River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6685,
    longitude: 143.8584,
    accuracy: 4,
  },
  {
    postcode: 3235,
    place_name: 'Deans Marsh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 3235,
    place_name: 'Boonah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3235,
    place_name: 'Pennyroyal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 143.85,
    accuracy: 4,
  },
  {
    postcode: 3235,
    place_name: 'Benwerrin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4111,
    longitude: 143.8833,
    accuracy: 3,
  },
  {
    postcode: 3236,
    place_name: 'Mount Sabine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3236,
    place_name: 'Forrest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5167,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3237,
    place_name: 'Beech Forest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6366,
    longitude: 143.5678,
    accuracy: 4,
  },
  {
    postcode: 3237,
    place_name: 'Wattle Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7833,
    longitude: 143.25,
    accuracy: 4,
  },
  {
    postcode: 3237,
    place_name: 'Aire Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.703,
    longitude: 143.5728,
    accuracy: 4,
  },
  {
    postcode: 3237,
    place_name: 'Wyelangta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 143.4667,
    accuracy: 4,
  },
  {
    postcode: 3237,
    place_name: 'Ferguson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.631,
    longitude: 143.5258,
    accuracy: 4,
  },
  {
    postcode: 3237,
    place_name: 'Gellibrand Lower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7167,
    longitude: 143.25,
    accuracy: 4,
  },
  {
    postcode: 3237,
    place_name: 'Yuulong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6722,
    longitude: 143.4278,
    accuracy: 3,
  },
  {
    postcode: 3237,
    place_name: 'Weeaproinah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 143.5,
    accuracy: 4,
  },
  {
    postcode: 3238,
    place_name: 'Johanna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.756,
    longitude: 143.3893,
    accuracy: 4,
  },
  {
    postcode: 3238,
    place_name: 'Lavers Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6667,
    longitude: 143.4,
    accuracy: 4,
  },
  {
    postcode: 3238,
    place_name: 'Hordern Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7782,
    longitude: 143.5004,
    accuracy: 4,
  },
  {
    postcode: 3238,
    place_name: 'Glenaire',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7333,
    longitude: 143.4278,
    accuracy: 3,
  },
  {
    postcode: 3239,
    place_name: 'Chapple Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 143.3167,
    accuracy: 4,
  },
  {
    postcode: 3239,
    place_name: 'Carlisle River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5667,
    longitude: 143.3833,
    accuracy: 4,
  },
  {
    postcode: 3239,
    place_name: 'Kennedys Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6,
    longitude: 143.25,
    accuracy: 4,
  },
  {
    postcode: 3239,
    place_name: 'Gellibrand',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5167,
    longitude: 143.5333,
    accuracy: 4,
  },
  {
    postcode: 3240,
    place_name: 'Moriac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2409,
    longitude: 144.1748,
    accuracy: 4,
  },
  {
    postcode: 3240,
    place_name: 'Modewarre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3240,
    place_name: 'Buckley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 3240,
    place_name: 'Mount Moriac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 144.1833,
    accuracy: 4,
  },
  {
    postcode: 3240,
    place_name: 'Paraparap',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2504,
    longitude: 144.139,
    accuracy: 3,
  },
  {
    postcode: 3240,
    place_name: 'Gherang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3113,
    longitude: 144.1034,
    accuracy: 4,
  },
  {
    postcode: 3241,
    place_name: 'Wensleydale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 144.0333,
    accuracy: 4,
  },
  {
    postcode: 3241,
    place_name: 'Winchelsea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2448,
    longitude: 143.9832,
    accuracy: 4,
  },
  {
    postcode: 3241,
    place_name: 'Bambra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3241,
    place_name: 'Ombersley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3154,
    longitude: 144,
    accuracy: 3,
  },
  {
    postcode: 3241,
    place_name: 'Winchelsea South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3154,
    longitude: 144,
    accuracy: 3,
  },
  {
    postcode: 3241,
    place_name: 'Wurdiboluc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 144.05,
    accuracy: 4,
  },
  {
    postcode: 3242,
    place_name: 'Birregurra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3368,
    longitude: 143.7847,
    accuracy: 4,
  },
  {
    postcode: 3243,
    place_name: 'Whoorel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 143.8167,
    accuracy: 4,
  },
  {
    postcode: 3243,
    place_name: 'Murroon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4333,
    longitude: 143.8167,
    accuracy: 4,
  },
  {
    postcode: 3243,
    place_name: 'Gerangamete',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 143.7333,
    accuracy: 4,
  },
  {
    postcode: 3243,
    place_name: 'Barwon Downs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4667,
    longitude: 143.75,
    accuracy: 4,
  },
  {
    postcode: 3243,
    place_name: 'Warncoort',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3167,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Barongarook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 143.6,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Swan Marsh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 143.3833,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Dreeite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 143.5167,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Barongarook West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.364,
    longitude: 143.5632,
    accuracy: 3,
  },
  {
    postcode: 3249,
    place_name: 'Yeo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 143.7,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Dreeite South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.364,
    longitude: 143.5632,
    accuracy: 3,
  },
  {
    postcode: 3249,
    place_name: 'Larpent',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 143.5,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Coragulac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 143.5333,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Irrewarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3167,
    longitude: 143.6333,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Pirron Yallock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 143.4333,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Tanybryn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.65,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Pomborneit East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.364,
    longitude: 143.5632,
    accuracy: 3,
  },
  {
    postcode: 3249,
    place_name: 'Yeodene',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 143.7,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Corunnun',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3019,
    longitude: 143.5942,
    accuracy: 3,
  },
  {
    postcode: 3249,
    place_name: 'Warrion',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 143.5667,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Wool Wool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 143.4167,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Ondit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 143.6333,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Irrewillipe East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.364,
    longitude: 143.5632,
    accuracy: 3,
  },
  {
    postcode: 3249,
    place_name: 'Gerangamete',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 143.7333,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Balintore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.364,
    longitude: 143.5632,
    accuracy: 3,
  },
  {
    postcode: 3249,
    place_name: 'Nalangil',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 143.45,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Kawarren',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4833,
    longitude: 143.5833,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Irrewillipe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 143.4167,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Barramunga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5833,
    longitude: 143.6833,
    accuracy: 4,
  },
  {
    postcode: 3249,
    place_name: 'Alvie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 143.5,
    accuracy: 4,
  },
  {
    postcode: 3250,
    place_name: 'Colac West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3445,
    longitude: 143.5841,
    accuracy: 3,
  },
  {
    postcode: 3250,
    place_name: 'Colac East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3445,
    longitude: 143.5841,
    accuracy: 3,
  },
  {
    postcode: 3250,
    place_name: 'Colac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.339,
    longitude: 143.5849,
    accuracy: 4,
  },
  {
    postcode: 3250,
    place_name: 'Elliminyt',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 143.5833,
    accuracy: 4,
  },
  {
    postcode: 3251,
    place_name: 'Cundare North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 143.5667,
    accuracy: 4,
  },
  {
    postcode: 3251,
    place_name: 'Cundare',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 143.5667,
    accuracy: 4,
  },
  {
    postcode: 3251,
    place_name: 'Weering',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 143.6333,
    accuracy: 4,
  },
  {
    postcode: 3251,
    place_name: 'Beeac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1938,
    longitude: 143.6413,
    accuracy: 4,
  },
  {
    postcode: 3251,
    place_name: 'Eurack',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.15,
    longitude: 143.7,
    accuracy: 4,
  },
  {
    postcode: 3254,
    place_name: 'Cororooke',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2833,
    longitude: 143.5167,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Kariah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 143.2167,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Gnotuk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 143.1167,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Carpendeit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 143.2667,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Pomborneit North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2612,
    longitude: 143.2241,
    accuracy: 3,
  },
  {
    postcode: 3260,
    place_name: 'Bostocks Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 143.1167,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Pomborneit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 143.3,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Weerite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 143.25,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Koallah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2612,
    longitude: 143.2241,
    accuracy: 3,
  },
  {
    postcode: 3260,
    place_name: 'Leslie Manor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 143.3833,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Camperdown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2339,
    longitude: 143.1498,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Tandarook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2612,
    longitude: 143.2241,
    accuracy: 3,
  },
  {
    postcode: 3260,
    place_name: 'South Purrumbete',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 143.2167,
    accuracy: 4,
  },
  {
    postcode: 3260,
    place_name: 'Tesbury',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2612,
    longitude: 143.2241,
    accuracy: 3,
  },
  {
    postcode: 3260,
    place_name: 'Skibo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2612,
    longitude: 143.2241,
    accuracy: 3,
  },
  {
    postcode: 3260,
    place_name: 'Chocolyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2612,
    longitude: 143.2241,
    accuracy: 3,
  },
  {
    postcode: 3260,
    place_name: 'Bookaar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2612,
    longitude: 143.2241,
    accuracy: 3,
  },
  {
    postcode: 3260,
    place_name: 'Bungador',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2512,
    longitude: 143.2045,
    accuracy: 3,
  },
  {
    postcode: 3260,
    place_name: 'Stonyford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2512,
    longitude: 143.2045,
    accuracy: 3,
  },
  {
    postcode: 3264,
    place_name: 'Terang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2408,
    longitude: 142.9212,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Boorcan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 143.0167,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Noorat East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2472,
    longitude: 142.8397,
    accuracy: 3,
  },
  {
    postcode: 3265,
    place_name: 'Kolora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 142.9167,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'The Sisters',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 142.7833,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Ellerslie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.15,
    longitude: 142.7,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Taroon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2472,
    longitude: 142.8397,
    accuracy: 3,
  },
  {
    postcode: 3265,
    place_name: 'Dixie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 142.9333,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Garvoc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 142.8333,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Glenormiston South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 142.9667,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Framlingham East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 142.7,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Laang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 142.8167,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Panmure',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 142.7333,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Cudgee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 142.65,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Noorat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1916,
    longitude: 142.9287,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Glenormiston North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.15,
    longitude: 142.9833,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Ecklin South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 142.9167,
    accuracy: 4,
  },
  {
    postcode: 3265,
    place_name: 'Framlingham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 142.7167,
    accuracy: 4,
  },
  {
    postcode: 3266,
    place_name: 'Jancourt East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4143,
    longitude: 143.1039,
    accuracy: 3,
  },
  {
    postcode: 3266,
    place_name: 'Elingamite North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 143.0167,
    accuracy: 4,
  },
  {
    postcode: 3266,
    place_name: 'Elingamite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 143.0167,
    accuracy: 4,
  },
  {
    postcode: 3266,
    place_name: 'Simpson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5022,
    longitude: 143.2501,
    accuracy: 4,
  },
  {
    postcode: 3266,
    place_name: 'Bullaharre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3753,
    longitude: 143.0622,
    accuracy: 3,
  },
  {
    postcode: 3266,
    place_name: 'Jancourt',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 143.1,
    accuracy: 4,
  },
  {
    postcode: 3266,
    place_name: 'Cobrico',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 143.0333,
    accuracy: 4,
  },
  {
    postcode: 3266,
    place_name: 'Naroghid',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3753,
    longitude: 143.0622,
    accuracy: 3,
  },
  {
    postcode: 3266,
    place_name: 'Glenfyne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 142.9833,
    accuracy: 4,
  },
  {
    postcode: 3266,
    place_name: 'Cobden',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3286,
    longitude: 143.0748,
    accuracy: 4,
  },
  {
    postcode: 3267,
    place_name: 'Scotts Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4333,
    longitude: 143.05,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Nirranda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5,
    longitude: 142.7833,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Nullawarre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4667,
    longitude: 142.7667,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Timboon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4843,
    longitude: 142.9803,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Newfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5667,
    longitude: 143.0167,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Ayrford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4249,
    longitude: 142.8596,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Brucknell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 142.9333,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Nirranda South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4987,
    longitude: 142.9281,
    accuracy: 3,
  },
  {
    postcode: 3268,
    place_name: 'Heytesbury Lower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5667,
    longitude: 142.9333,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Curdie Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5167,
    longitude: 142.8333,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'The Cove',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4987,
    longitude: 142.9281,
    accuracy: 3,
  },
  {
    postcode: 3268,
    place_name: 'Curdievale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4987,
    longitude: 142.9281,
    accuracy: 3,
  },
  {
    postcode: 3268,
    place_name: 'Cowleys Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5,
    longitude: 143,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Curdies River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 142.9667,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Cooriemungle',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5597,
    longitude: 143.1363,
    accuracy: 4,
  },
  {
    postcode: 3268,
    place_name: 'Nirranda East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4987,
    longitude: 142.9281,
    accuracy: 3,
  },
  {
    postcode: 3268,
    place_name: 'Nullawarre North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4987,
    longitude: 142.9281,
    accuracy: 3,
  },
  {
    postcode: 3268,
    place_name: 'Timboon West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4987,
    longitude: 142.9281,
    accuracy: 3,
  },
  {
    postcode: 3268,
    place_name: 'Paaratte',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4987,
    longitude: 142.9281,
    accuracy: 3,
  },
  {
    postcode: 3269,
    place_name: 'Princetown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6833,
    longitude: 143.15,
    accuracy: 4,
  },
  {
    postcode: 3269,
    place_name: 'Port Campbell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6192,
    longitude: 142.9958,
    accuracy: 4,
  },
  {
    postcode: 3269,
    place_name: 'Waarre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6513,
    longitude: 143.0729,
    accuracy: 3,
  },
  {
    postcode: 3270,
    place_name: 'Peterborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6035,
    longitude: 142.8733,
    accuracy: 4,
  },
  {
    postcode: 3271,
    place_name: 'Dundonnell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 142.9833,
    accuracy: 4,
  },
  {
    postcode: 3271,
    place_name: 'Darlington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 143.05,
    accuracy: 4,
  },
  {
    postcode: 3271,
    place_name: 'Pura Pura',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 143.0833,
    accuracy: 4,
  },
  {
    postcode: 3272,
    place_name: 'Mortlake',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.081,
    longitude: 142.8083,
    accuracy: 4,
  },
  {
    postcode: 3272,
    place_name: 'Woorndoo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 142.8167,
    accuracy: 4,
  },
  {
    postcode: 3273,
    place_name: 'Hexham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 142.7,
    accuracy: 4,
  },
  {
    postcode: 3274,
    place_name: 'Caramut',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 142.5167,
    accuracy: 4,
  },
  {
    postcode: 3275,
    place_name: 'Mailer Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2876,
    longitude: 142.4615,
    accuracy: 3,
  },
  {
    postcode: 3275,
    place_name: 'Mailors Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 142.4667,
    accuracy: 4,
  },
  {
    postcode: 3276,
    place_name: 'Minjah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 142.4333,
    accuracy: 3,
  },
  {
    postcode: 3276,
    place_name: 'Woolsthorpe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 142.4333,
    accuracy: 4,
  },
  {
    postcode: 3277,
    place_name: 'Mepunga East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 142.7333,
    accuracy: 4,
  },
  {
    postcode: 3277,
    place_name: 'Allansford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3864,
    longitude: 142.5943,
    accuracy: 4,
  },
  {
    postcode: 3277,
    place_name: 'Naringal East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4216,
    longitude: 142.6902,
    accuracy: 3,
  },
  {
    postcode: 3277,
    place_name: 'Naringal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 142.7,
    accuracy: 4,
  },
  {
    postcode: 3277,
    place_name: 'Mepunga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 142.7333,
    accuracy: 4,
  },
  {
    postcode: 3277,
    place_name: 'Mepunga West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4216,
    longitude: 142.6902,
    accuracy: 3,
  },
  {
    postcode: 3278,
    place_name: 'Purnim',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2833,
    longitude: 142.6333,
    accuracy: 4,
  },
  {
    postcode: 3278,
    place_name: 'Purnim West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2833,
    longitude: 142.6333,
    accuracy: 3,
  },
  {
    postcode: 3279,
    place_name: 'Wangoom',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 142.5833,
    accuracy: 4,
  },
  {
    postcode: 3279,
    place_name: 'Ballangeich',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 142.6667,
    accuracy: 4,
  },
  {
    postcode: 3280,
    place_name: 'Warrnambool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3818,
    longitude: 142.488,
    accuracy: 4,
  },
  {
    postcode: 3280,
    place_name: 'Dennington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 142.45,
    accuracy: 4,
  },
  {
    postcode: 3281,
    place_name: 'Grassmere',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2833,
    longitude: 142.5333,
    accuracy: 4,
  },
  {
    postcode: 3281,
    place_name: 'Woodford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3167,
    longitude: 142.4667,
    accuracy: 4,
  },
  {
    postcode: 3281,
    place_name: 'Bushfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 142.5167,
    accuracy: 4,
  },
  {
    postcode: 3281,
    place_name: 'Winslow',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 142.45,
    accuracy: 4,
  },
  {
    postcode: 3282,
    place_name: 'Koroit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2918,
    longitude: 142.3673,
    accuracy: 4,
  },
  {
    postcode: 3282,
    place_name: 'Illowa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 142.4167,
    accuracy: 4,
  },
  {
    postcode: 3283,
    place_name: 'Warrong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 142.3667,
    accuracy: 4,
  },
  {
    postcode: 3283,
    place_name: 'Crossley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 142.3333,
    accuracy: 4,
  },
  {
    postcode: 3283,
    place_name: 'Tower Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 142.35,
    accuracy: 4,
  },
  {
    postcode: 3283,
    place_name: 'Yangery',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3167,
    longitude: 142.4167,
    accuracy: 4,
  },
  {
    postcode: 3283,
    place_name: 'Southern Cross',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 142.4,
    accuracy: 4,
  },
  {
    postcode: 3283,
    place_name: 'Yarpturk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 142.4333,
    accuracy: 4,
  },
  {
    postcode: 3283,
    place_name: 'Killarney',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 142.3333,
    accuracy: 4,
  },
  {
    postcode: 3283,
    place_name: 'Tarrone',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2852,
    longitude: 142.3574,
    accuracy: 3,
  },
  {
    postcode: 3283,
    place_name: 'Kirkstall',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 142.3167,
    accuracy: 4,
  },
  {
    postcode: 3283,
    place_name: 'Willatook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 142.2667,
    accuracy: 4,
  },
  {
    postcode: 3284,
    place_name: 'Port Fairy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3853,
    longitude: 142.2371,
    accuracy: 4,
  },
  {
    postcode: 3284,
    place_name: 'Orford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2035,
    longitude: 142.1009,
    accuracy: 4,
  },
  {
    postcode: 3285,
    place_name: 'Yambuk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3156,
    longitude: 142.0651,
    accuracy: 4,
  },
  {
    postcode: 3285,
    place_name: 'Codrington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 141.9667,
    accuracy: 4,
  },
  {
    postcode: 3285,
    place_name: 'Rosebrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3536,
    longitude: 142.2545,
    accuracy: 4,
  },
  {
    postcode: 3285,
    place_name: 'St Helens',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2395,
    longitude: 141.9049,
    accuracy: 3,
  },
  {
    postcode: 3285,
    place_name: 'Narrawong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 141.7167,
    accuracy: 4,
  },
  {
    postcode: 3285,
    place_name: 'Toolong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2395,
    longitude: 141.9049,
    accuracy: 3,
  },
  {
    postcode: 3285,
    place_name: 'Tyrendarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 141.7833,
    accuracy: 4,
  },
  {
    postcode: 3285,
    place_name: 'Tyrendarra East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 141.9,
    accuracy: 4,
  },
  {
    postcode: 3286,
    place_name: 'Warrabkook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 142.0167,
    accuracy: 4,
  },
  {
    postcode: 3286,
    place_name: 'Macarthur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0339,
    longitude: 142.0018,
    accuracy: 4,
  },
  {
    postcode: 3286,
    place_name: 'Condah Swamp',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0113,
    longitude: 141.9617,
    accuracy: 3,
  },
  {
    postcode: 3286,
    place_name: 'Knebsworth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 141.8667,
    accuracy: 4,
  },
  {
    postcode: 3287,
    place_name: 'Minhamite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 142.35,
    accuracy: 4,
  },
  {
    postcode: 3287,
    place_name: 'Hawkesdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 142.3167,
    accuracy: 4,
  },
  {
    postcode: 3289,
    place_name: 'Gazette',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8751,
    longitude: 142.2913,
    accuracy: 3,
  },
  {
    postcode: 3289,
    place_name: 'Tabor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 142.2,
    accuracy: 4,
  },
  {
    postcode: 3289,
    place_name: 'Gerrigerrup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8751,
    longitude: 142.2913,
    accuracy: 3,
  },
  {
    postcode: 3289,
    place_name: 'Penshurst',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8753,
    longitude: 142.2907,
    accuracy: 4,
  },
  {
    postcode: 3289,
    place_name: 'Purdeet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 142.3833,
    accuracy: 4,
  },
  {
    postcode: 3292,
    place_name: 'Nelson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 141.0167,
    accuracy: 4,
  },
  {
    postcode: 3293,
    place_name: 'Glenthompson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7187,
    longitude: 142.5402,
    accuracy: 1,
  },
  {
    postcode: 3293,
    place_name: 'Narrapumelap South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7187,
    longitude: 142.5402,
    accuracy: 1,
  },
  {
    postcode: 3293,
    place_name: 'Nareeb',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6555,
    longitude: 142.0879,
    accuracy: 1,
  },
  {
    postcode: 3294,
    place_name: 'Moutajup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 142.2333,
    accuracy: 4,
  },
  {
    postcode: 3294,
    place_name: 'Victoria Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5766,
    longitude: 142.3021,
    accuracy: 3,
  },
  {
    postcode: 3294,
    place_name: 'Mirranatwa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 142.4167,
    accuracy: 4,
  },
  {
    postcode: 3294,
    place_name: 'Woodhouse',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5766,
    longitude: 142.3021,
    accuracy: 3,
  },
  {
    postcode: 3294,
    place_name: 'Dunkeld',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6499,
    longitude: 142.3439,
    accuracy: 4,
  },
  {
    postcode: 3294,
    place_name: 'Karabeal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 142.1833,
    accuracy: 4,
  },
  {
    postcode: 3294,
    place_name: 'Victoria Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 142.3333,
    accuracy: 4,
  },
  {
    postcode: 3300,
    place_name: 'Hamilton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7442,
    longitude: 142.022,
    accuracy: 4,
  },
  {
    postcode: 3300,
    place_name: 'Byaduk North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8928,
    longitude: 141.9553,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Yatchaw',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 142.1,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Wannon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 141.8333,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Croxton East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 142.2167,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Mount Napier',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7904,
    longitude: 142.0065,
    accuracy: 3,
  },
  {
    postcode: 3301,
    place_name: 'Bochara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 141.9333,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Tahara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 141.7,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Tarrington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 142.1,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Hensley Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7904,
    longitude: 142.0065,
    accuracy: 3,
  },
  {
    postcode: 3301,
    place_name: 'Byaduk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9523,
    longitude: 141.9552,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Warrayure',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6833,
    longitude: 142.15,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Broadwater',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1253,
    longitude: 142.0497,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Morgiana',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7904,
    longitude: 142.0065,
    accuracy: 3,
  },
  {
    postcode: 3301,
    place_name: 'Strathkellar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 142.1,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Yulecart',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7475,
    longitude: 141.9389,
    accuracy: 4,
  },
  {
    postcode: 3301,
    place_name: 'Buckley Swamp',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7904,
    longitude: 142.0065,
    accuracy: 3,
  },
  {
    postcode: 3302,
    place_name: 'Grassdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 141.65,
    accuracy: 4,
  },
  {
    postcode: 3302,
    place_name: 'Branxholme',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 141.8,
    accuracy: 4,
  },
  {
    postcode: 3303,
    place_name: 'Condah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 141.75,
    accuracy: 4,
  },
  {
    postcode: 3303,
    place_name: 'Wallacedale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 141.8,
    accuracy: 4,
  },
  {
    postcode: 3303,
    place_name: 'Breakaway Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 141.8,
    accuracy: 4,
  },
  {
    postcode: 3303,
    place_name: 'Lake Condah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 141.8,
    accuracy: 4,
  },
  {
    postcode: 3303,
    place_name: 'Hotspur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 141.5667,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Heywood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1329,
    longitude: 141.6295,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Drik Drik',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 141.3,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Drumborg',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 141.6,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Mumbannar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9269,
    longitude: 141.1875,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Myamyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 141.7333,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Lyons',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 141.4667,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Dartmoor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9222,
    longitude: 141.2749,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Greenwald',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9667,
    longitude: 141.3667,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Winnap',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 141.3167,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Bessiebelle',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.15,
    longitude: 141.9667,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Homerton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 141.7833,
    accuracy: 4,
  },
  {
    postcode: 3304,
    place_name: 'Milltown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 141.7167,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Mount Richmond',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2019,
    longitude: 141.3416,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Cape Bridgewater',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3705,
    longitude: 141.3995,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Bolwarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2833,
    longitude: 141.6167,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Portland',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3462,
    longitude: 141.6026,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Gorae',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 141.55,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Allestree',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2833,
    longitude: 141.65,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Gorae West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 141.4833,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Cashmore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3116,
    longitude: 141.4774,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Portland West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.354,
    longitude: 141.574,
    accuracy: 4,
  },
  {
    postcode: 3305,
    place_name: 'Portland North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2855,
    longitude: 141.5377,
    accuracy: 3,
  },
  {
    postcode: 3305,
    place_name: 'Dutton Way',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2855,
    longitude: 141.5377,
    accuracy: 3,
  },
  {
    postcode: 3305,
    place_name: 'Heathmere',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 141.6167,
    accuracy: 4,
  },
  {
    postcode: 3309,
    place_name: 'Digby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 141.5333,
    accuracy: 4,
  },
  {
    postcode: 3310,
    place_name: 'Merino',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7205,
    longitude: 141.5483,
    accuracy: 4,
  },
  {
    postcode: 3310,
    place_name: 'Tahara West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7205,
    longitude: 141.5483,
    accuracy: 3,
  },
  {
    postcode: 3311,
    place_name: 'Casterton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5849,
    longitude: 141.403,
    accuracy: 4,
  },
  {
    postcode: 3311,
    place_name: 'Corndale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5849,
    longitude: 141.403,
    accuracy: 3,
  },
  {
    postcode: 3312,
    place_name: 'Lindsay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 140.9667,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Bahgallah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 141.3833,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Lake Mundi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 141,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Dunrobin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4799,
    longitude: 141.2923,
    accuracy: 3,
  },
  {
    postcode: 3312,
    place_name: 'Powers Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 141.2833,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Henty',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 141.5167,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Wando Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 141.45,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Nangeela',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4799,
    longitude: 141.2923,
    accuracy: 3,
  },
  {
    postcode: 3312,
    place_name: 'Carapook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 141.5333,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Warrock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4799,
    longitude: 141.2923,
    accuracy: 3,
  },
  {
    postcode: 3312,
    place_name: 'Brimboal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 141.4,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Killara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 145.5,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Poolaijelo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2324,
    longitude: 141.0988,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Sandford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6167,
    longitude: 141.4333,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Strathdownie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 141.1833,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Chetwynd',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2833,
    longitude: 141.4167,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Dorodong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3332,
    longitude: 141.0697,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Dergholm',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 141.2167,
    accuracy: 4,
  },
  {
    postcode: 3312,
    place_name: 'Wando Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 141.4333,
    accuracy: 4,
  },
  {
    postcode: 3314,
    place_name: 'Mooralla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4,
    longitude: 142.1333,
    accuracy: 4,
  },
  {
    postcode: 3314,
    place_name: 'Grampians',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0297,
    longitude: 142.4986,
    accuracy: 4,
  },
  {
    postcode: 3314,
    place_name: 'Cavendish',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 142.0333,
    accuracy: 4,
  },
  {
    postcode: 3314,
    place_name: 'Glenisla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2333,
    longitude: 142.1667,
    accuracy: 4,
  },
  {
    postcode: 3314,
    place_name: 'Bulart',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 141.95,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Nareen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 141.5667,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Paschendale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 141.6,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Konongwootong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5333,
    longitude: 141.6833,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Tarrenlea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6833,
    longitude: 141.7333,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Muntham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 141.5667,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Tahara Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4943,
    longitude: 141.6909,
    accuracy: 3,
  },
  {
    postcode: 3315,
    place_name: 'Tarrayoukyan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3167,
    longitude: 141.5833,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Clover Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4943,
    longitude: 141.6909,
    accuracy: 3,
  },
  {
    postcode: 3315,
    place_name: 'Gritjurk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 141.7667,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Brit Brit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 141.7667,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Coleraine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5983,
    longitude: 141.6912,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Coojar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4943,
    longitude: 141.6909,
    accuracy: 3,
  },
  {
    postcode: 3315,
    place_name: 'Melville Forest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5,
    longitude: 141.8667,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Gringegalgona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 141.8167,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Culla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2333,
    longitude: 141.65,
    accuracy: 4,
  },
  {
    postcode: 3315,
    place_name: 'Hilgay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4943,
    longitude: 141.6909,
    accuracy: 3,
  },
  {
    postcode: 3315,
    place_name: 'Wootong Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4943,
    longitude: 141.6909,
    accuracy: 3,
  },
  {
    postcode: 3317,
    place_name: 'Harrow',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1667,
    longitude: 141.6,
    accuracy: 4,
  },
  {
    postcode: 3318,
    place_name: 'Edenhope',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0365,
    longitude: 141.2966,
    accuracy: 4,
  },
  {
    postcode: 3318,
    place_name: 'Kadnook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0255,
    longitude: 141.3217,
    accuracy: 3,
  },
  {
    postcode: 3318,
    place_name: 'Connewirricoo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 141.4667,
    accuracy: 4,
  },
  {
    postcode: 3318,
    place_name: 'Ullswater',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9667,
    longitude: 141.3833,
    accuracy: 4,
  },
  {
    postcode: 3318,
    place_name: 'Langkoop',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 141.0333,
    accuracy: 4,
  },
  {
    postcode: 3318,
    place_name: 'Patyah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 141.2333,
    accuracy: 4,
  },
  {
    postcode: 3318,
    place_name: 'Charam',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.95,
    longitude: 141.5167,
    accuracy: 4,
  },
  {
    postcode: 3319,
    place_name: 'Bringalbert',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 141.1667,
    accuracy: 4,
  },
  {
    postcode: 3319,
    place_name: 'Apsley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9667,
    longitude: 141.0833,
    accuracy: 4,
  },
  {
    postcode: 3319,
    place_name: 'Benayeo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 141.05,
    accuracy: 4,
  },
  {
    postcode: 3321,
    place_name: 'Hesse',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1009,
    longitude: 144.0575,
    accuracy: 3,
  },
  {
    postcode: 3321,
    place_name: 'Inverleigh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1018,
    longitude: 144.0513,
    accuracy: 4,
  },
  {
    postcode: 3321,
    place_name: 'Wingeel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1009,
    longitude: 144.0575,
    accuracy: 3,
  },
  {
    postcode: 3322,
    place_name: 'Cressy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 143.6333,
    accuracy: 4,
  },
  {
    postcode: 3323,
    place_name: 'Foxhow',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 143.4667,
    accuracy: 4,
  },
  {
    postcode: 3323,
    place_name: 'Duverney',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 143.5667,
    accuracy: 4,
  },
  {
    postcode: 3323,
    place_name: 'Berrybank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 143.4833,
    accuracy: 4,
  },
  {
    postcode: 3324,
    place_name: 'Lismore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9527,
    longitude: 143.3378,
    accuracy: 4,
  },
  {
    postcode: 3324,
    place_name: 'Mount Bute',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 143.4167,
    accuracy: 4,
  },
  {
    postcode: 3324,
    place_name: 'Mingay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 143.3167,
    accuracy: 4,
  },
  {
    postcode: 3325,
    place_name: 'Derrinallum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9479,
    longitude: 143.2203,
    accuracy: 4,
  },
  {
    postcode: 3325,
    place_name: 'Vite Vite North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 143.2,
    accuracy: 4,
  },
  {
    postcode: 3325,
    place_name: 'Vite Vite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 143.1667,
    accuracy: 4,
  },
  {
    postcode: 3325,
    place_name: 'Larralea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8715,
    longitude: 143.1957,
    accuracy: 3,
  },
  {
    postcode: 3328,
    place_name: 'Teesdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 144.05,
    accuracy: 4,
  },
  {
    postcode: 3329,
    place_name: 'Shelford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3329,
    place_name: 'Barunah Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 143.9667,
    accuracy: 3,
  },
  {
    postcode: 3329,
    place_name: 'Barunah Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 143.9667,
    accuracy: 3,
  },
  {
    postcode: 3330,
    place_name: 'Rokewood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3331,
    place_name: 'She Oaks',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3331,
    place_name: 'Sutherlands Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9176,
    longitude: 144.1721,
    accuracy: 3,
  },
  {
    postcode: 3331,
    place_name: 'Maude',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 144.1667,
    accuracy: 4,
  },
  {
    postcode: 3331,
    place_name: 'Steiglitz',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 144.1833,
    accuracy: 4,
  },
  {
    postcode: 3331,
    place_name: 'Russells Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0166,
    longitude: 144.1818,
    accuracy: 3,
  },
  {
    postcode: 3331,
    place_name: 'Gheringhap',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 144.2333,
    accuracy: 4,
  },
  {
    postcode: 3331,
    place_name: 'Bannockburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0489,
    longitude: 144.1688,
    accuracy: 4,
  },
  {
    postcode: 3332,
    place_name: 'Lethbridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9657,
    longitude: 144.1357,
    accuracy: 4,
  },
  {
    postcode: 3333,
    place_name: 'Bamganie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 143.9833,
    accuracy: 4,
  },
  {
    postcode: 3333,
    place_name: 'Meredith',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8454,
    longitude: 144.0774,
    accuracy: 4,
  },
  {
    postcode: 3334,
    place_name: 'Bungal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7889,
    longitude: 144.0333,
    accuracy: 3,
  },
  {
    postcode: 3334,
    place_name: 'Elaine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.0333,
    accuracy: 4,
  },
  {
    postcode: 3334,
    place_name: 'Mount Doran',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7889,
    longitude: 144.0333,
    accuracy: 3,
  },
  {
    postcode: 3334,
    place_name: 'Morrisons',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 3334,
    place_name: 'Cargerie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3335,
    place_name: 'Plumpton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.687,
    longitude: 144.6908,
    accuracy: 4,
  },
  {
    postcode: 3335,
    place_name: 'Rockbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7335,
    longitude: 144.67,
    accuracy: 4,
  },
  {
    postcode: 3337,
    place_name: 'Melton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6834,
    longitude: 144.5854,
    accuracy: 4,
  },
  {
    postcode: 3337,
    place_name: 'Melton West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6785,
    longitude: 144.5688,
    accuracy: 4,
  },
  {
    postcode: 3337,
    place_name: 'Kurunjang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6759,
    longitude: 144.5969,
    accuracy: 4,
  },
  {
    postcode: 3337,
    place_name: 'Toolern Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 144.6,
    accuracy: 4,
  },
  {
    postcode: 3338,
    place_name: 'Brookfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7008,
    longitude: 144.5603,
    accuracy: 4,
  },
  {
    postcode: 3338,
    place_name: 'Eynesbury',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8004,
    longitude: 144.5746,
    accuracy: 4,
  },
  {
    postcode: 3338,
    place_name: 'Melton South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7077,
    longitude: 144.5749,
    accuracy: 4,
  },
  {
    postcode: 3338,
    place_name: 'Exford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7259,
    longitude: 144.53,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Staughton Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 144.2833,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Balliang East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.45,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Hopetoun Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6928,
    longitude: 144.501,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Balliang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 144.3667,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Bacchus Marsh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6727,
    longitude: 144.4383,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Merrimu',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6576,
    longitude: 144.4535,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Parwan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.705,
    longitude: 144.4699,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Maddingley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6852,
    longitude: 144.4324,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Darley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6581,
    longitude: 144.4434,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Coimadai',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6167,
    longitude: 144.4667,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Long Forest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6727,
    longitude: 144.514,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Glenmore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7127,
    longitude: 144.3185,
    accuracy: 4,
  },
  {
    postcode: 3340,
    place_name: 'Rowsley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7199,
    longitude: 144.3727,
    accuracy: 4,
  },
  {
    postcode: 3341,
    place_name: 'Korobeit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5465,
    longitude: 144.313,
    accuracy: 3,
  },
  {
    postcode: 3341,
    place_name: 'Dales Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5226,
    longitude: 144.298,
    accuracy: 4,
  },
  {
    postcode: 3341,
    place_name: 'Myrniong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6167,
    longitude: 144.35,
    accuracy: 4,
  },
  {
    postcode: 3341,
    place_name: 'Pentland Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6632,
    longitude: 144.4085,
    accuracy: 4,
  },
  {
    postcode: 3341,
    place_name: 'Greendale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5603,
    longitude: 144.2968,
    accuracy: 4,
  },
  {
    postcode: 3342,
    place_name: 'Colbrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6426,
    longitude: 144.2293,
    accuracy: 3,
  },
  {
    postcode: 3342,
    place_name: 'Beremboke',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 144.25,
    accuracy: 4,
  },
  {
    postcode: 3342,
    place_name: 'Ballan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5999,
    longitude: 144.2289,
    accuracy: 4,
  },
  {
    postcode: 3342,
    place_name: 'Fiskville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6819,
    longitude: 144.2264,
    accuracy: 4,
  },
  {
    postcode: 3342,
    place_name: 'Mount Wallace',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 144.2167,
    accuracy: 4,
  },
  {
    postcode: 3342,
    place_name: 'Bunding',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 144.1667,
    accuracy: 4,
  },
  {
    postcode: 3342,
    place_name: 'Durdidwarrah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6426,
    longitude: 144.2293,
    accuracy: 3,
  },
  {
    postcode: 3342,
    place_name: 'Blakeville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 144.2167,
    accuracy: 4,
  },
  {
    postcode: 3342,
    place_name: 'Ingliston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.65,
    longitude: 144.3,
    accuracy: 4,
  },
  {
    postcode: 3345,
    place_name: 'Gordon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5823,
    longitude: 144.1093,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Brown Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 143.9,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Black Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Ballarat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5662,
    longitude: 143.8496,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Lake Wendouree',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5499,
    longitude: 143.8473,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Bakery Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5635,
    longitude: 143.8658,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Mount Pleasant',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Ballarat Central',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5621,
    longitude: 143.8508,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Golden Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5749,
    longitude: 143.8687,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Mount Helen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6246,
    longitude: 143.8789,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Nerrina',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 143.8833,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Sovereign Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5677,
    longitude: 143.8786,
    accuracy: 3,
  },
  {
    postcode: 3350,
    place_name: 'Mount Clear',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 143.8833,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Canadian',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5761,
    longitude: 143.8774,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Alfredton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5667,
    longitude: 143.8167,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Invermay Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5306,
    longitude: 143.858,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Eureka',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5647,
    longitude: 143.8826,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Ballarat North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5369,
    longitude: 143.8624,
    accuracy: 3,
  },
  {
    postcode: 3350,
    place_name: 'Redan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5765,
    longitude: 143.8386,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Ballarat West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5677,
    longitude: 143.8786,
    accuracy: 3,
  },
  {
    postcode: 3350,
    place_name: 'Ballarat East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5667,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Soldiers Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5496,
    longitude: 143.8587,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Lucas',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5536,
    longitude: 143.7717,
    accuracy: 4,
  },
  {
    postcode: 3350,
    place_name: 'Newington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.564,
    longitude: 143.8276,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Chepstowe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 143.5,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Carngham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 143.5833,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Springdallah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 143.6333,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Lake Bolac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7126,
    longitude: 142.8396,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Streatham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 143.0667,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Cape Clear',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 143.6167,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Ross Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.65,
    longitude: 143.75,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Newtown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 143.65,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Snake Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6116,
    longitude: 143.585,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Bo Peep',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6967,
    longitude: 143.5151,
    accuracy: 3,
  },
  {
    postcode: 3351,
    place_name: 'Westmere',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6833,
    longitude: 142.9667,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Smythes Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 143.7833,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Illabarook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 143.6667,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Mount Emu',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6967,
    longitude: 143.5151,
    accuracy: 3,
  },
  {
    postcode: 3351,
    place_name: 'Staffordshire Reef',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 143.7,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Wallinduc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 143.5167,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Hillcrest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6167,
    longitude: 143.6333,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Smythesdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6431,
    longitude: 143.6862,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Nintingbool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6167,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Scarsdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.65,
    longitude: 143.6667,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Berringa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 143.7,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Pitfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 143.6,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Rokewood Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 143.6833,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Mininera',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 142.9667,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Piggoreet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 143.6333,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Haddon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3351,
    place_name: 'Nerrin Nerrin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 143.0167,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Mount Rowan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5,
    longitude: 143.85,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Mount Bolton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.558,
    longitude: 143.8518,
    accuracy: 3,
  },
  {
    postcode: 3352,
    place_name: 'Bunkers Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5716,
    longitude: 143.746,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Weatherboard',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 143.6667,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Waubra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 143.65,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Chapel Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.558,
    longitude: 143.8518,
    accuracy: 3,
  },
  {
    postcode: 3352,
    place_name: 'Blowhard',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 143.8,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Gong Gong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Dereel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 143.7667,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Ballarat Roadside Delivery',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.558,
    longitude: 143.8518,
    accuracy: 3,
  },
  {
    postcode: 3352,
    place_name: 'Wattle Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4667,
    longitude: 143.95,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Enfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7281,
    longitude: 143.7945,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Dean',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.45,
    longitude: 143.9833,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Navigators',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6167,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Bolwarrah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Mount Egerton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Lamplough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 143.5167,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Langi Kal Kal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.558,
    longitude: 143.8518,
    accuracy: 3,
  },
  {
    postcode: 3352,
    place_name: 'Clarkes Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 143.9833,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Claretown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.558,
    longitude: 143.8518,
    accuracy: 3,
  },
  {
    postcode: 3352,
    place_name: 'Bullarook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5277,
    longitude: 143.9976,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Bungaree',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5667,
    longitude: 144,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Magpie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6167,
    longitude: 143.85,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Lexton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2667,
    longitude: 143.5167,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Bonshaw',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.604,
    longitude: 143.8226,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Ercildoune',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.558,
    longitude: 143.8518,
    accuracy: 3,
  },
  {
    postcode: 3352,
    place_name: 'Clarendon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Millbrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 144.05,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Barkstead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.45,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Cardigan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5333,
    longitude: 143.75,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Yendon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Miners Rest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4808,
    longitude: 143.8011,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Garibaldi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 143.8833,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Glenbrae',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.35,
    longitude: 143.5667,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Addington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 143.6833,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Wallace',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 144.05,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Lal Lal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6833,
    longitude: 144.0333,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Mount Mercer',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 143.85,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Durham Lead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 143.8833,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Glen Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.558,
    longitude: 143.8518,
    accuracy: 3,
  },
  {
    postcode: 3352,
    place_name: 'Corindhap',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 143.7333,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Grenville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 143.8833,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Napoleons',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 143.8333,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Pootilla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Burrumbeet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4667,
    longitude: 143.6667,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Dunnstown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 143.9833,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Sulky',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4667,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Scotchmans Lead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.558,
    longitude: 143.8518,
    accuracy: 3,
  },
  {
    postcode: 3352,
    place_name: 'Cambrian Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 143.8333,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Werneth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 143.6167,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Windermere',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Springbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.531,
    longitude: 144.0574,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Brewster',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 143.5167,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Mitchell Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5069,
    longitude: 143.7798,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Warrenheip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5667,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Invermay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5164,
    longitude: 143.8818,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Scotsburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Cardigan Village',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5109,
    longitude: 143.7062,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Learmonth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4188,
    longitude: 143.7122,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Mollongghip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 144.0333,
    accuracy: 4,
  },
  {
    postcode: 3352,
    place_name: 'Leigh Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5667,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3353,
    place_name: 'Ballarat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5662,
    longitude: 143.8496,
    accuracy: 4,
  },
  {
    postcode: 3354,
    place_name: 'Ballarat Mc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5987,
    longitude: 143.7607,
    accuracy: 3,
  },
  {
    postcode: 3354,
    place_name: 'Bakery Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5635,
    longitude: 143.8658,
    accuracy: 4,
  },
  {
    postcode: 3355,
    place_name: 'Wendouree Village',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5314,
    longitude: 143.8286,
    accuracy: 3,
  },
  {
    postcode: 3355,
    place_name: 'Wendouree',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 143.8333,
    accuracy: 4,
  },
  {
    postcode: 3355,
    place_name: 'Mitchell Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5069,
    longitude: 143.7798,
    accuracy: 4,
  },
  {
    postcode: 3355,
    place_name: 'Lake Gardens',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5463,
    longitude: 143.8165,
    accuracy: 4,
  },
  {
    postcode: 3356,
    place_name: 'Delacombe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5891,
    longitude: 143.8147,
    accuracy: 4,
  },
  {
    postcode: 3356,
    place_name: 'Sebastopol',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5853,
    longitude: 143.8395,
    accuracy: 4,
  },
  {
    postcode: 3357,
    place_name: 'Buninyong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6502,
    longitude: 143.8839,
    accuracy: 4,
  },
  {
    postcode: 3360,
    place_name: 'Happy Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 143.6,
    accuracy: 4,
  },
  {
    postcode: 3360,
    place_name: 'Pittong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6667,
    longitude: 143.4833,
    accuracy: 4,
  },
  {
    postcode: 3360,
    place_name: 'Linton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6863,
    longitude: 143.5629,
    accuracy: 4,
  },
  {
    postcode: 3360,
    place_name: 'Willowvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 143.4667,
    accuracy: 4,
  },
  {
    postcode: 3360,
    place_name: 'Mannibadar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 143.5,
    accuracy: 4,
  },
  {
    postcode: 3361,
    place_name: 'Carranballac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 143.2167,
    accuracy: 4,
  },
  {
    postcode: 3361,
    place_name: 'Bradvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 143.4,
    accuracy: 4,
  },
  {
    postcode: 3361,
    place_name: 'Skipton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.686,
    longitude: 143.3647,
    accuracy: 4,
  },
  {
    postcode: 3363,
    place_name: 'Mount Beckworth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3167,
    longitude: 143.6833,
    accuracy: 4,
  },
  {
    postcode: 3363,
    place_name: 'Creswick North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3781,
    longitude: 143.8123,
    accuracy: 3,
  },
  {
    postcode: 3363,
    place_name: 'Dean',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.45,
    longitude: 143.9833,
    accuracy: 4,
  },
  {
    postcode: 3363,
    place_name: 'Langdons Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3781,
    longitude: 143.8123,
    accuracy: 3,
  },
  {
    postcode: 3363,
    place_name: 'Tourello',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.35,
    longitude: 143.7833,
    accuracy: 4,
  },
  {
    postcode: 3363,
    place_name: 'Creswick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.424,
    longitude: 143.8947,
    accuracy: 4,
  },
  {
    postcode: 3363,
    place_name: 'Glendaruel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.35,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Kooroocheang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2833,
    longitude: 144.0333,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Werona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 144.0333,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Barkstead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.45,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Mount Prospect',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3317,
    longitude: 143.9508,
    accuracy: 3,
  },
  {
    postcode: 3364,
    place_name: 'Ascot',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4,
    longitude: 143.8,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Broomfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3867,
    longitude: 143.9119,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Newlyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 143.9833,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Glendonald',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 143.8333,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Rocklyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 144.05,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Strathlea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Allendale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Cabbage Tree',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6967,
    longitude: 148.7197,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Smeaton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 143.95,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Blampied',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 144.05,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Ullina',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2833,
    longitude: 143.9,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Smokeytown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3317,
    longitude: 143.9508,
    accuracy: 3,
  },
  {
    postcode: 3364,
    place_name: 'Joyces Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0833,
    longitude: 144.0167,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Newlyn North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4,
    longitude: 144,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Campbelltown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 143.95,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Kingston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.35,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Coghills Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 143.7667,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Springmount',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Lawrence',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3167,
    longitude: 143.9,
    accuracy: 4,
  },
  {
    postcode: 3364,
    place_name: 'Bald Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 143.85,
    accuracy: 4,
  },
  {
    postcode: 3370,
    place_name: 'Clunes',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2947,
    longitude: 143.7864,
    accuracy: 4,
  },
  {
    postcode: 3370,
    place_name: 'Mount Cameron',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2649,
    longitude: 143.8566,
    accuracy: 3,
  },
  {
    postcode: 3370,
    place_name: 'Ullina',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2833,
    longitude: 143.9,
    accuracy: 4,
  },
  {
    postcode: 3370,
    place_name: 'Glengower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2167,
    longitude: 143.8833,
    accuracy: 4,
  },
  {
    postcode: 3371,
    place_name: 'Lillicur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2037,
    longitude: 143.6586,
    accuracy: 3,
  },
  {
    postcode: 3371,
    place_name: 'Red Lion',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2037,
    longitude: 143.6586,
    accuracy: 3,
  },
  {
    postcode: 3371,
    place_name: 'Evansford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 143.6333,
    accuracy: 4,
  },
  {
    postcode: 3371,
    place_name: 'Burnbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2333,
    longitude: 143.6,
    accuracy: 4,
  },
  {
    postcode: 3371,
    place_name: 'Talbot',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1723,
    longitude: 143.7017,
    accuracy: 4,
  },
  {
    postcode: 3371,
    place_name: 'Amherst',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 143.6667,
    accuracy: 4,
  },
  {
    postcode: 3371,
    place_name: 'Dunach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2167,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3371,
    place_name: 'Mount Glasgow',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2037,
    longitude: 143.6586,
    accuracy: 3,
  },
  {
    postcode: 3371,
    place_name: 'Stony Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5833,
    longitude: 146.0333,
    accuracy: 4,
  },
  {
    postcode: 3371,
    place_name: 'Caralulup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 143.6333,
    accuracy: 4,
  },
  {
    postcode: 3373,
    place_name: 'Trawalla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 143.4667,
    accuracy: 4,
  },
  {
    postcode: 3373,
    place_name: 'Mena Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4293,
    longitude: 143.3908,
    accuracy: 3,
  },
  {
    postcode: 3373,
    place_name: 'Lake Goldsmith',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 143.4,
    accuracy: 4,
  },
  {
    postcode: 3373,
    place_name: 'Nerring',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4667,
    longitude: 143.4333,
    accuracy: 4,
  },
  {
    postcode: 3373,
    place_name: 'Raglan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.35,
    longitude: 143.35,
    accuracy: 4,
  },
  {
    postcode: 3373,
    place_name: 'Stoneleigh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4293,
    longitude: 143.3908,
    accuracy: 3,
  },
  {
    postcode: 3373,
    place_name: 'Waterloo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 143.4167,
    accuracy: 4,
  },
  {
    postcode: 3373,
    place_name: 'Chute',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 143.3833,
    accuracy: 4,
  },
  {
    postcode: 3373,
    place_name: 'Main Lead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 143.3667,
    accuracy: 4,
  },
  {
    postcode: 3373,
    place_name: 'Cross Roads',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4293,
    longitude: 143.3908,
    accuracy: 3,
  },
  {
    postcode: 3373,
    place_name: 'Beaufort',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.43,
    longitude: 143.3835,
    accuracy: 4,
  },
  {
    postcode: 3373,
    place_name: 'Lake Wongan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4293,
    longitude: 143.3908,
    accuracy: 3,
  },
  {
    postcode: 3373,
    place_name: 'Stockyard Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 143.3167,
    accuracy: 4,
  },
  {
    postcode: 3374,
    place_name: 'Great Western',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 142.85,
    accuracy: 4,
  },
  {
    postcode: 3375,
    place_name: 'Buangor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 143.1667,
    accuracy: 4,
  },
  {
    postcode: 3375,
    place_name: 'Bayindeen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3167,
    longitude: 143.1667,
    accuracy: 4,
  },
  {
    postcode: 3375,
    place_name: 'Middle Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4,
    longitude: 143.2333,
    accuracy: 4,
  },
  {
    postcode: 3375,
    place_name: 'Ballyrogan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 143.1333,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Rocky Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2626,
    longitude: 142.9809,
    accuracy: 3,
  },
  {
    postcode: 3377,
    place_name: 'Warrak',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2667,
    longitude: 143.15,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Rhymney',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2667,
    longitude: 142.7833,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Dobie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3167,
    longitude: 143.0333,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Shays Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2626,
    longitude: 142.9809,
    accuracy: 3,
  },
  {
    postcode: 3377,
    place_name: 'Mount Cole',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 143.2,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Crowlands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 143.1167,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Mount Cole Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2333,
    longitude: 143.1333,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Cathcart',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2626,
    longitude: 142.9809,
    accuracy: 3,
  },
  {
    postcode: 3377,
    place_name: 'Langi Logan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.35,
    longitude: 142.95,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Denicull Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 142.9,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Eversley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 143.1667,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Bulgana',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2626,
    longitude: 142.9809,
    accuracy: 3,
  },
  {
    postcode: 3377,
    place_name: 'Ararat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2854,
    longitude: 142.9273,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Norval',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2667,
    longitude: 142.8833,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Moyston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3,
    longitude: 142.7667,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Rossbridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2626,
    longitude: 142.9809,
    accuracy: 3,
  },
  {
    postcode: 3377,
    place_name: 'Dunneworthy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 143.0667,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Maroona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 142.8667,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Great Western',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 142.85,
    accuracy: 4,
  },
  {
    postcode: 3377,
    place_name: 'Armstrong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2167,
    longitude: 142.9,
    accuracy: 4,
  },
  {
    postcode: 3378,
    place_name: 'Yalla-Y-Poora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5333,
    longitude: 143.0667,
    accuracy: 4,
  },
  {
    postcode: 3378,
    place_name: 'Tatyoon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 142.95,
    accuracy: 4,
  },
  {
    postcode: 3379,
    place_name: 'Chatsworth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.858,
    longitude: 142.6511,
    accuracy: 4,
  },
  {
    postcode: 3379,
    place_name: 'Bornes Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6717,
    longitude: 142.6812,
    accuracy: 3,
  },
  {
    postcode: 3379,
    place_name: 'Willaura North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6717,
    longitude: 142.6812,
    accuracy: 3,
  },
  {
    postcode: 3379,
    place_name: 'Mafeking',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6717,
    longitude: 142.6812,
    accuracy: 3,
  },
  {
    postcode: 3379,
    place_name: 'Stavely',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 142.6167,
    accuracy: 4,
  },
  {
    postcode: 3379,
    place_name: 'Wickliffe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6833,
    longitude: 142.7167,
    accuracy: 4,
  },
  {
    postcode: 3379,
    place_name: 'Willaura',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5454,
    longitude: 142.7404,
    accuracy: 4,
  },
  {
    postcode: 3380,
    place_name: 'Stawell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0563,
    longitude: 142.7809,
    accuracy: 4,
  },
  {
    postcode: 3380,
    place_name: 'Stawell West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0563,
    longitude: 142.7809,
    accuracy: 3,
  },
  {
    postcode: 3381,
    place_name: 'Bellellen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0132,
    longitude: 142.692,
    accuracy: 3,
  },
  {
    postcode: 3381,
    place_name: 'Bellfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7531,
    longitude: 145.0385,
    accuracy: 4,
  },
  {
    postcode: 3381,
    place_name: 'Lubeck',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 142.5667,
    accuracy: 4,
  },
  {
    postcode: 3381,
    place_name: 'Mount Dryden',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0132,
    longitude: 142.692,
    accuracy: 3,
  },
  {
    postcode: 3381,
    place_name: 'Mokepilly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0132,
    longitude: 142.692,
    accuracy: 3,
  },
  {
    postcode: 3381,
    place_name: 'Black Range',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0132,
    longitude: 142.692,
    accuracy: 3,
  },
  {
    postcode: 3381,
    place_name: 'Pomonal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1917,
    longitude: 142.6076,
    accuracy: 4,
  },
  {
    postcode: 3381,
    place_name: 'Lake Lonsdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0333,
    longitude: 142.5833,
    accuracy: 4,
  },
  {
    postcode: 3381,
    place_name: 'Lake Fyans',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0132,
    longitude: 142.692,
    accuracy: 3,
  },
  {
    postcode: 3381,
    place_name: 'Halls Gap',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1365,
    longitude: 142.5185,
    accuracy: 4,
  },
  {
    postcode: 3381,
    place_name: 'Campbells Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 142.7833,
    accuracy: 4,
  },
  {
    postcode: 3381,
    place_name: 'Illawarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0667,
    longitude: 142.7,
    accuracy: 4,
  },
  {
    postcode: 3381,
    place_name: 'Barkly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 143.2,
    accuracy: 4,
  },
  {
    postcode: 3381,
    place_name: 'Fyans Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0864,
    longitude: 142.5558,
    accuracy: 4,
  },
  {
    postcode: 3384,
    place_name: 'Joel South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 143.0667,
    accuracy: 4,
  },
  {
    postcode: 3384,
    place_name: 'Wattle Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9833,
    longitude: 143.1167,
    accuracy: 4,
  },
  {
    postcode: 3384,
    place_name: 'Landsborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 143.15,
    accuracy: 4,
  },
  {
    postcode: 3384,
    place_name: 'Barkly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 143.2,
    accuracy: 4,
  },
  {
    postcode: 3384,
    place_name: 'Concongella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9972,
    longitude: 143.1028,
    accuracy: 3,
  },
  {
    postcode: 3384,
    place_name: 'Joel Joel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 142.9833,
    accuracy: 4,
  },
  {
    postcode: 3384,
    place_name: 'Navarre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 143.1,
    accuracy: 4,
  },
  {
    postcode: 3384,
    place_name: 'Frenchmans',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9972,
    longitude: 143.1028,
    accuracy: 3,
  },
  {
    postcode: 3384,
    place_name: 'Tulkara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9972,
    longitude: 143.1028,
    accuracy: 3,
  },
  {
    postcode: 3384,
    place_name: 'Landsborough West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9972,
    longitude: 143.1028,
    accuracy: 3,
  },
  {
    postcode: 3385,
    place_name: 'Lubeck',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 142.5667,
    accuracy: 4,
  },
  {
    postcode: 3385,
    place_name: 'Dadswells Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 142.5167,
    accuracy: 4,
  },
  {
    postcode: 3385,
    place_name: 'Roses Gap',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8733,
    longitude: 142.6233,
    accuracy: 3,
  },
  {
    postcode: 3385,
    place_name: 'Riachella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8733,
    longitude: 142.6233,
    accuracy: 3,
  },
  {
    postcode: 3385,
    place_name: 'Deep Lead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 142.7333,
    accuracy: 4,
  },
  {
    postcode: 3385,
    place_name: 'Wal Wal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8167,
    longitude: 142.6167,
    accuracy: 4,
  },
  {
    postcode: 3385,
    place_name: 'Glenorchy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 142.6833,
    accuracy: 4,
  },
  {
    postcode: 3385,
    place_name: 'Ledcourt',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8733,
    longitude: 142.6233,
    accuracy: 3,
  },
  {
    postcode: 3387,
    place_name: 'Morrl Morrl',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 142.9333,
    accuracy: 4,
  },
  {
    postcode: 3387,
    place_name: 'Marnoo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 142.8833,
    accuracy: 4,
  },
  {
    postcode: 3387,
    place_name: 'Callawadda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 142.8,
    accuracy: 4,
  },
  {
    postcode: 3387,
    place_name: 'Wallaloo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7167,
    longitude: 142.8833,
    accuracy: 4,
  },
  {
    postcode: 3387,
    place_name: 'Kanya',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7667,
    longitude: 143,
    accuracy: 4,
  },
  {
    postcode: 3387,
    place_name: 'Wallaloo East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 142.9333,
    accuracy: 4,
  },
  {
    postcode: 3387,
    place_name: 'Bolangum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8042,
    longitude: 142.8958,
    accuracy: 3,
  },
  {
    postcode: 3387,
    place_name: 'Campbells Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 142.7833,
    accuracy: 4,
  },
  {
    postcode: 3387,
    place_name: 'Germania',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8042,
    longitude: 142.8958,
    accuracy: 3,
  },
  {
    postcode: 3387,
    place_name: 'Greens Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 142.95,
    accuracy: 4,
  },
  {
    postcode: 3387,
    place_name: 'Marnoo West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8042,
    longitude: 142.8958,
    accuracy: 3,
  },
  {
    postcode: 3388,
    place_name: 'Banyena',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5667,
    longitude: 142.8333,
    accuracy: 4,
  },
  {
    postcode: 3388,
    place_name: 'Rupanyup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6285,
    longitude: 142.6315,
    accuracy: 4,
  },
  {
    postcode: 3390,
    place_name: 'Kewell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5,
    longitude: 142.4167,
    accuracy: 4,
  },
  {
    postcode: 3390,
    place_name: 'Murtoa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6201,
    longitude: 142.4668,
    accuracy: 4,
  },
  {
    postcode: 3391,
    place_name: 'Brim',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0833,
    longitude: 142.4167,
    accuracy: 4,
  },
  {
    postcode: 3392,
    place_name: 'Sheep Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3333,
    longitude: 142.55,
    accuracy: 4,
  },
  {
    postcode: 3392,
    place_name: 'Minyip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4596,
    longitude: 142.5878,
    accuracy: 4,
  },
  {
    postcode: 3392,
    place_name: 'Boolite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.35,
    longitude: 142.6667,
    accuracy: 4,
  },
  {
    postcode: 3393,
    place_name: 'Crymelon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2043,
    longitude: 142.3909,
    accuracy: 3,
  },
  {
    postcode: 3393,
    place_name: 'Kellalac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3808,
    longitude: 142.4012,
    accuracy: 4,
  },
  {
    postcode: 3393,
    place_name: 'Willenabrina',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0667,
    longitude: 142.2,
    accuracy: 4,
  },
  {
    postcode: 3393,
    place_name: 'Wilkur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0667,
    longitude: 142.6167,
    accuracy: 4,
  },
  {
    postcode: 3393,
    place_name: 'Bangerang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2,
    longitude: 142.5833,
    accuracy: 4,
  },
  {
    postcode: 3393,
    place_name: 'Warracknabeal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2533,
    longitude: 142.3928,
    accuracy: 4,
  },
  {
    postcode: 3393,
    place_name: 'Lah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1333,
    longitude: 142.45,
    accuracy: 4,
  },
  {
    postcode: 3393,
    place_name: 'Aubrey',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2333,
    longitude: 142.2333,
    accuracy: 4,
  },
  {
    postcode: 3393,
    place_name: 'Cannum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 142.25,
    accuracy: 4,
  },
  {
    postcode: 3395,
    place_name: 'Kenmare',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9167,
    longitude: 142.1667,
    accuracy: 4,
  },
  {
    postcode: 3395,
    place_name: 'Beulah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9378,
    longitude: 142.4222,
    accuracy: 4,
  },
  {
    postcode: 3395,
    place_name: 'Reedy Dam',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9667,
    longitude: 142.65,
    accuracy: 4,
  },
  {
    postcode: 3395,
    place_name: 'Rosebery',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8167,
    longitude: 142.4167,
    accuracy: 4,
  },
  {
    postcode: 3396,
    place_name: 'Hopetoun',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.727,
    longitude: 142.3663,
    accuracy: 4,
  },
  {
    postcode: 3400,
    place_name: 'Horsham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7113,
    longitude: 142.1998,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Kalkee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 142.2333,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Quantong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 142.0167,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Nurcoung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 141.6833,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Wallup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.35,
    longitude: 142.25,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Laharum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7821,
    longitude: 142.1128,
    accuracy: 3,
  },
  {
    postcode: 3401,
    place_name: 'St Helens Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 142.3833,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Dooen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6667,
    longitude: 142.2833,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Mockinya',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9667,
    longitude: 142.15,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Zumsteins',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7821,
    longitude: 142.1128,
    accuracy: 3,
  },
  {
    postcode: 3401,
    place_name: 'Nurrabiel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9167,
    longitude: 142.05,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Murra Warra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7821,
    longitude: 142.1128,
    accuracy: 3,
  },
  {
    postcode: 3401,
    place_name: 'Telangatuk East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0833,
    longitude: 141.9667,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Drung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7167,
    longitude: 142.3333,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Lower Norton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7667,
    longitude: 142.1,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Haven',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7607,
    longitude: 142.1943,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Toolondo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9833,
    longitude: 141.9333,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Kanagulk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 141.8667,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Longerenong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6667,
    longitude: 142.4,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Rocklands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7717,
    longitude: 141.9796,
    accuracy: 3,
  },
  {
    postcode: 3401,
    place_name: 'Gymbowen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 141.6,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Vectis',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7821,
    longitude: 142.1128,
    accuracy: 3,
  },
  {
    postcode: 3401,
    place_name: 'Wartook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0239,
    longitude: 142.3385,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Riverside',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7717,
    longitude: 141.9796,
    accuracy: 3,
  },
  {
    postcode: 3401,
    place_name: 'Brimpaen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0333,
    longitude: 142.2,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Cherrypool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 142.1833,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Blackheath',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4667,
    longitude: 142.25,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Wonwondah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7821,
    longitude: 142.1128,
    accuracy: 3,
  },
  {
    postcode: 3401,
    place_name: 'Mckenzie Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 142.1833,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Bungalally',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 142.25,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Karnak',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8667,
    longitude: 141.5,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Jung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6167,
    longitude: 142.3667,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Wail',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5,
    longitude: 142.1,
    accuracy: 4,
  },
  {
    postcode: 3401,
    place_name: 'Pimpinio',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5833,
    longitude: 142.1167,
    accuracy: 4,
  },
  {
    postcode: 3402,
    place_name: 'Horsham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7113,
    longitude: 142.1998,
    accuracy: 4,
  },
  {
    postcode: 3407,
    place_name: 'Englefield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3167,
    longitude: 141.8333,
    accuracy: 4,
  },
  {
    postcode: 3407,
    place_name: 'Vasey',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 141.8833,
    accuracy: 4,
  },
  {
    postcode: 3407,
    place_name: 'Balmoral',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 141.85,
    accuracy: 4,
  },
  {
    postcode: 3407,
    place_name: 'Pigeon Ponds',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3,
    longitude: 141.6833,
    accuracy: 4,
  },
  {
    postcode: 3407,
    place_name: 'Gatum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 141.9333,
    accuracy: 4,
  },
  {
    postcode: 3409,
    place_name: 'Natimuk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7403,
    longitude: 141.9452,
    accuracy: 4,
  },
  {
    postcode: 3409,
    place_name: 'Grass Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.86,
    longitude: 141.7958,
    accuracy: 3,
  },
  {
    postcode: 3409,
    place_name: 'Clear Lake',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 141.8667,
    accuracy: 4,
  },
  {
    postcode: 3409,
    place_name: 'Arapiles',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 141.85,
    accuracy: 4,
  },
  {
    postcode: 3409,
    place_name: 'Mitre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 141.7833,
    accuracy: 4,
  },
  {
    postcode: 3409,
    place_name: 'Duchembegarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.86,
    longitude: 141.7958,
    accuracy: 3,
  },
  {
    postcode: 3409,
    place_name: 'Noradjuha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8333,
    longitude: 141.9667,
    accuracy: 4,
  },
  {
    postcode: 3409,
    place_name: 'Douglas',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0333,
    longitude: 141.7333,
    accuracy: 4,
  },
  {
    postcode: 3409,
    place_name: 'Wombelano',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37,
    longitude: 141.6,
    accuracy: 4,
  },
  {
    postcode: 3409,
    place_name: 'Jilpanger',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.86,
    longitude: 141.7958,
    accuracy: 3,
  },
  {
    postcode: 3409,
    place_name: 'Tooan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 141.7833,
    accuracy: 4,
  },
  {
    postcode: 3409,
    place_name: 'Miga Lake',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 141.6333,
    accuracy: 4,
  },
  {
    postcode: 3412,
    place_name: 'Goroke',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7179,
    longitude: 141.4731,
    accuracy: 4,
  },
  {
    postcode: 3413,
    place_name: 'Neuarpurr',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7667,
    longitude: 141.0833,
    accuracy: 4,
  },
  {
    postcode: 3413,
    place_name: 'Ozenkadnook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 141.3333,
    accuracy: 4,
  },
  {
    postcode: 3413,
    place_name: 'Minimay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7167,
    longitude: 141.1833,
    accuracy: 4,
  },
  {
    postcode: 3413,
    place_name: 'Peronne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 141.3833,
    accuracy: 4,
  },
  {
    postcode: 3414,
    place_name: 'Antwerp',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 142.05,
    accuracy: 4,
  },
  {
    postcode: 3414,
    place_name: 'Dimboola',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4562,
    longitude: 142.0329,
    accuracy: 4,
  },
  {
    postcode: 3414,
    place_name: 'Tarranyurk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2167,
    longitude: 142.05,
    accuracy: 4,
  },
  {
    postcode: 3415,
    place_name: 'Miram',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3667,
    longitude: 141.35,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Gerang Gerung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3594,
    longitude: 141.8555,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Nhill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3333,
    longitude: 141.6501,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Kiata',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3667,
    longitude: 141.8,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Lawloit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 141.4667,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Broughton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1667,
    longitude: 141.35,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Lorquon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1667,
    longitude: 141.75,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Little Desert',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4956,
    longitude: 141.7474,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Yanac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1333,
    longitude: 141.4333,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Glenlee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2167,
    longitude: 141.85,
    accuracy: 4,
  },
  {
    postcode: 3418,
    place_name: 'Netherby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1167,
    longitude: 141.65,
    accuracy: 4,
  },
  {
    postcode: 3419,
    place_name: 'Kaniva',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3786,
    longitude: 141.2422,
    accuracy: 4,
  },
  {
    postcode: 3420,
    place_name: 'Telopea Downs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.375,
    longitude: 141.0583,
    accuracy: 3,
  },
  {
    postcode: 3420,
    place_name: 'Serviceton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3833,
    longitude: 140.9833,
    accuracy: 4,
  },
  {
    postcode: 3420,
    place_name: 'Lillimur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3667,
    longitude: 141.1333,
    accuracy: 4,
  },
  {
    postcode: 3423,
    place_name: 'Jeparit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1425,
    longitude: 141.9882,
    accuracy: 4,
  },
  {
    postcode: 3424,
    place_name: 'Yaapeet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7667,
    longitude: 142.05,
    accuracy: 4,
  },
  {
    postcode: 3424,
    place_name: 'Albacutya',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8167,
    longitude: 142,
    accuracy: 4,
  },
  {
    postcode: 3424,
    place_name: 'Rainbow',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8958,
    longitude: 141.9979,
    accuracy: 4,
  },
  {
    postcode: 3427,
    place_name: 'Diggers Rest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.626,
    longitude: 144.7188,
    accuracy: 4,
  },
  {
    postcode: 3428,
    place_name: 'Bulla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6384,
    longitude: 144.8074,
    accuracy: 4,
  },
  {
    postcode: 3429,
    place_name: 'Sunbury',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5774,
    longitude: 144.7261,
    accuracy: 4,
  },
  {
    postcode: 3429,
    place_name: 'Wildwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5735,
    longitude: 144.7881,
    accuracy: 4,
  },
  {
    postcode: 3430,
    place_name: 'Clarkefield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 144.75,
    accuracy: 4,
  },
  {
    postcode: 3431,
    place_name: 'Riddells Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4605,
    longitude: 144.6796,
    accuracy: 4,
  },
  {
    postcode: 3432,
    place_name: 'Bolinda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 144.75,
    accuracy: 4,
  },
  {
    postcode: 3433,
    place_name: 'Monegeetta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 144.75,
    accuracy: 4,
  },
  {
    postcode: 3434,
    place_name: 'Romsey',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3507,
    longitude: 144.743,
    accuracy: 4,
  },
  {
    postcode: 3434,
    place_name: 'Cherokee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.342,
    longitude: 144.7798,
    accuracy: 3,
  },
  {
    postcode: 3434,
    place_name: 'Springfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 144.8167,
    accuracy: 4,
  },
  {
    postcode: 3434,
    place_name: 'Kerrie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3684,
    longitude: 144.754,
    accuracy: 3,
  },
  {
    postcode: 3435,
    place_name: 'Lancefield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2773,
    longitude: 144.736,
    accuracy: 4,
  },
  {
    postcode: 3435,
    place_name: 'Goldie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.247,
    longitude: 144.718,
    accuracy: 3,
  },
  {
    postcode: 3435,
    place_name: 'Benloch',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2167,
    longitude: 144.7,
    accuracy: 4,
  },
  {
    postcode: 3435,
    place_name: 'Nulla Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.247,
    longitude: 144.718,
    accuracy: 3,
  },
  {
    postcode: 3437,
    place_name: 'Bullengarook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 144.4833,
    accuracy: 4,
  },
  {
    postcode: 3437,
    place_name: 'Gisborne South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5026,
    longitude: 144.5388,
    accuracy: 3,
  },
  {
    postcode: 3437,
    place_name: 'Gisborne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4886,
    longitude: 144.5942,
    accuracy: 4,
  },
  {
    postcode: 3438,
    place_name: 'New Gisborne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4428,
    longitude: 144.6184,
    accuracy: 1,
  },
  {
    postcode: 3440,
    place_name: 'Macedon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4196,
    longitude: 144.5624,
    accuracy: 4,
  },
  {
    postcode: 3441,
    place_name: 'Mount Macedon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4005,
    longitude: 144.5841,
    accuracy: 4,
  },
  {
    postcode: 3442,
    place_name: 'Hesket',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.35,
    longitude: 144.65,
    accuracy: 4,
  },
  {
    postcode: 3442,
    place_name: 'Ashbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3355,
    longitude: 144.5399,
    accuracy: 3,
  },
  {
    postcode: 3442,
    place_name: 'Rochford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3167,
    longitude: 144.6833,
    accuracy: 4,
  },
  {
    postcode: 3442,
    place_name: 'Carlsruhe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2833,
    longitude: 144.5,
    accuracy: 4,
  },
  {
    postcode: 3442,
    place_name: 'Woodend',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3547,
    longitude: 144.529,
    accuracy: 4,
  },
  {
    postcode: 3442,
    place_name: 'Cadello',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3174,
    longitude: 144.5993,
    accuracy: 3,
  },
  {
    postcode: 3442,
    place_name: 'Newham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 144.6,
    accuracy: 4,
  },
  {
    postcode: 3442,
    place_name: 'Cobaw',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2667,
    longitude: 144.6333,
    accuracy: 4,
  },
  {
    postcode: 3442,
    place_name: 'Woodend North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3174,
    longitude: 144.5993,
    accuracy: 3,
  },
  {
    postcode: 3444,
    place_name: 'Barfold',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0833,
    longitude: 144.5167,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Pastoria',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1538,
    longitude: 144.4934,
    accuracy: 3,
  },
  {
    postcode: 3444,
    place_name: 'Lyal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.95,
    longitude: 144.4833,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Redesdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 144.5167,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Langley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 144.5,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Tylden South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1538,
    longitude: 144.4934,
    accuracy: 3,
  },
  {
    postcode: 3444,
    place_name: 'Kyneton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2444,
    longitude: 144.4515,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Glenhope',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0833,
    longitude: 144.6,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Tylden',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3258,
    longitude: 144.4062,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Greenhill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1538,
    longitude: 144.4934,
    accuracy: 3,
  },
  {
    postcode: 3444,
    place_name: 'Baynton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1492,
    longitude: 144.6337,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Pipers Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 144.5333,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Mia Mia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37,
    longitude: 144.5667,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Metcalfe East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1538,
    longitude: 144.4934,
    accuracy: 3,
  },
  {
    postcode: 3444,
    place_name: 'Lauriston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 144.3833,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Edgecombe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1833,
    longitude: 144.4333,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Sidonia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1667,
    longitude: 144.55,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Baynton East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1538,
    longitude: 144.4934,
    accuracy: 3,
  },
  {
    postcode: 3444,
    place_name: 'Spring Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3,
    longitude: 144.3333,
    accuracy: 4,
  },
  {
    postcode: 3444,
    place_name: 'Kyneton South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1538,
    longitude: 144.4934,
    accuracy: 3,
  },
  {
    postcode: 3444,
    place_name: 'Pastoria East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1538,
    longitude: 144.4934,
    accuracy: 3,
  },
  {
    postcode: 3446,
    place_name: 'Malmsbury',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1886,
    longitude: 144.3847,
    accuracy: 4,
  },
  {
    postcode: 3446,
    place_name: 'Drummond North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.189,
    longitude: 144.3822,
    accuracy: 3,
  },
  {
    postcode: 3447,
    place_name: 'Taradale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 144.3667,
    accuracy: 4,
  },
  {
    postcode: 3448,
    place_name: 'Sutton Grange',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9833,
    longitude: 144.3667,
    accuracy: 4,
  },
  {
    postcode: 3448,
    place_name: 'Elphinstone',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 144.3333,
    accuracy: 4,
  },
  {
    postcode: 3448,
    place_name: 'Metcalfe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 144.4333,
    accuracy: 4,
  },
  {
    postcode: 3450,
    place_name: 'Moonlight Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0671,
    longitude: 144.2168,
    accuracy: 3,
  },
  {
    postcode: 3450,
    place_name: 'Castlemaine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0671,
    longitude: 144.2168,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Gower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1426,
    longitude: 144.1861,
    accuracy: 3,
  },
  {
    postcode: 3451,
    place_name: 'Mckenzie Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1426,
    longitude: 144.1861,
    accuracy: 3,
  },
  {
    postcode: 3451,
    place_name: 'Campbells Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0867,
    longitude: 144.202,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Golden Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5749,
    longitude: 143.8687,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Barkers Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 144.25,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Muckleford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 144.15,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Guildford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1667,
    longitude: 144.1667,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Yapeen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 144.1833,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Chewton Bushlands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1426,
    longitude: 144.1861,
    accuracy: 3,
  },
  {
    postcode: 3451,
    place_name: 'Faraday',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.05,
    longitude: 144.3,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Vaughan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1426,
    longitude: 144.1861,
    accuracy: 3,
  },
  {
    postcode: 3451,
    place_name: 'Glenluce',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1667,
    longitude: 144.2333,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Chewton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0814,
    longitude: 144.2568,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Fryerstown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 144.25,
    accuracy: 4,
  },
  {
    postcode: 3451,
    place_name: 'Tarilta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1426,
    longitude: 144.1861,
    accuracy: 3,
  },
  {
    postcode: 3451,
    place_name: 'Irishtown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1426,
    longitude: 144.1861,
    accuracy: 3,
  },
  {
    postcode: 3453,
    place_name: 'Ravenswood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 144.2167,
    accuracy: 4,
  },
  {
    postcode: 3453,
    place_name: 'Harcourt North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9521,
    longitude: 144.2382,
    accuracy: 3,
  },
  {
    postcode: 3453,
    place_name: 'Harcourt',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9998,
    longitude: 144.2599,
    accuracy: 4,
  },
  {
    postcode: 3453,
    place_name: 'Ravenswood South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9566,
    longitude: 144.2381,
    accuracy: 4,
  },
  {
    postcode: 3458,
    place_name: 'Newbury',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 144.3167,
    accuracy: 4,
  },
  {
    postcode: 3458,
    place_name: 'Trentham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3888,
    longitude: 144.3216,
    accuracy: 4,
  },
  {
    postcode: 3458,
    place_name: 'Fern Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 144.4,
    accuracy: 4,
  },
  {
    postcode: 3458,
    place_name: 'Trentham East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 144.3833,
    accuracy: 4,
  },
  {
    postcode: 3458,
    place_name: 'Blackwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4722,
    longitude: 144.3034,
    accuracy: 4,
  },
  {
    postcode: 3458,
    place_name: 'Lerderderg',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3823,
    longitude: 144.3389,
    accuracy: 3,
  },
  {
    postcode: 3458,
    place_name: 'Little Hampton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4157,
    longitude: 144.3378,
    accuracy: 3,
  },
  {
    postcode: 3458,
    place_name: 'Barrys Reef',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.454,
    longitude: 144.2964,
    accuracy: 4,
  },
  {
    postcode: 3458,
    place_name: 'North Blackwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4157,
    longitude: 144.3378,
    accuracy: 3,
  },
  {
    postcode: 3460,
    place_name: 'Daylesford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3411,
    longitude: 144.1426,
    accuracy: 4,
  },
  {
    postcode: 3460,
    place_name: 'Basalt',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3411,
    longitude: 144.1426,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Korweinguboora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.45,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Strangways',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 144.1167,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Hepburn Springs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3163,
    longitude: 144.1379,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Porcupine Ridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Musk Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Eganstown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 144.0833,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Yandoit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Elevated Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Hepburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3167,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Leonards Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 144.1167,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Spargo Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 144.15,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Sailors Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Franklinford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2333,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Yandoit Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Drummond',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2333,
    longitude: 144.3333,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Bullarto South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Musk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Denver',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2667,
    longitude: 144.3,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Sailors Falls',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Mount Franklin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Lyonville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 144.2667,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Shepherds Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Glenlyon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3,
    longitude: 144.25,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Dry Diggings',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Bullarto',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4,
    longitude: 144.2333,
    accuracy: 4,
  },
  {
    postcode: 3461,
    place_name: 'Coomoora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Wheatsheaf',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3268,
    longitude: 144.1641,
    accuracy: 3,
  },
  {
    postcode: 3461,
    place_name: 'Clydesdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1833,
    longitude: 144.1,
    accuracy: 4,
  },
  {
    postcode: 3462,
    place_name: 'Newstead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.106,
    longitude: 144.0647,
    accuracy: 4,
  },
  {
    postcode: 3462,
    place_name: 'Muckleford South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3462,
    place_name: 'Sandon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0909,
    longitude: 144.0827,
    accuracy: 3,
  },
  {
    postcode: 3462,
    place_name: 'Welshmans Reef',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0667,
    longitude: 144.05,
    accuracy: 4,
  },
  {
    postcode: 3462,
    place_name: 'Green Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0909,
    longitude: 144.0827,
    accuracy: 3,
  },
  {
    postcode: 3463,
    place_name: 'Maldon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9957,
    longitude: 144.0683,
    accuracy: 4,
  },
  {
    postcode: 3463,
    place_name: 'Laanecoorie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8167,
    longitude: 143.9,
    accuracy: 4,
  },
  {
    postcode: 3463,
    place_name: 'Baringhup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9833,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3463,
    place_name: 'Bradford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.916,
    longitude: 144.0058,
    accuracy: 3,
  },
  {
    postcode: 3463,
    place_name: 'Walmer',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9667,
    longitude: 144.15,
    accuracy: 4,
  },
  {
    postcode: 3463,
    place_name: 'Baringhup West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.916,
    longitude: 144.0058,
    accuracy: 3,
  },
  {
    postcode: 3463,
    place_name: 'Eastville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3463,
    place_name: 'Tarrengower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.916,
    longitude: 144.0058,
    accuracy: 3,
  },
  {
    postcode: 3463,
    place_name: 'Nuggetty',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.916,
    longitude: 144.0058,
    accuracy: 3,
  },
  {
    postcode: 3463,
    place_name: 'Woodstock West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9317,
    longitude: 144.0176,
    accuracy: 3,
  },
  {
    postcode: 3463,
    place_name: 'Neereman',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.916,
    longitude: 144.0058,
    accuracy: 3,
  },
  {
    postcode: 3463,
    place_name: 'Shelbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8833,
    longitude: 144.0167,
    accuracy: 4,
  },
  {
    postcode: 3464,
    place_name: 'Carisbrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0481,
    longitude: 143.8167,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Havelock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9667,
    longitude: 143.7833,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Maryborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0456,
    longitude: 143.7392,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Timor West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9667,
    longitude: 143.7,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Wareek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9833,
    longitude: 143.6167,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Cotswold',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 143.9,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Homebush',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.05,
    longitude: 143.5333,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Moonlight Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0633,
    longitude: 143.6975,
    accuracy: 3,
  },
  {
    postcode: 3465,
    place_name: 'Bung Bong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0667,
    longitude: 143.6167,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Moolort',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0833,
    longitude: 143.9333,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Bowenvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Rathscar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9667,
    longitude: 143.5667,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Daisy Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1167,
    longitude: 143.7167,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Natte Yallock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.95,
    longitude: 143.5,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Timor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9859,
    longitude: 143.7139,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Simson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0301,
    longitude: 143.7073,
    accuracy: 3,
  },
  {
    postcode: 3465,
    place_name: 'Alma',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0333,
    longitude: 143.6833,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Majorca',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1167,
    longitude: 143.8,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Adelaide Lead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0667,
    longitude: 143.6833,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Flagstaff',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0633,
    longitude: 143.6975,
    accuracy: 3,
  },
  {
    postcode: 3465,
    place_name: 'Rathscar West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0333,
    longitude: 143.4833,
    accuracy: 4,
  },
  {
    postcode: 3465,
    place_name: 'Craigie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0301,
    longitude: 143.7073,
    accuracy: 3,
  },
  {
    postcode: 3465,
    place_name: 'Golden Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5749,
    longitude: 143.8687,
    accuracy: 4,
  },
  {
    postcode: 3467,
    place_name: 'Avoca',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0904,
    longitude: 143.4736,
    accuracy: 4,
  },
  {
    postcode: 3468,
    place_name: 'Mount Lonarch',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 143.4,
    accuracy: 4,
  },
  {
    postcode: 3468,
    place_name: 'Amphitheatre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1667,
    longitude: 143.4167,
    accuracy: 4,
  },
  {
    postcode: 3469,
    place_name: 'Nowhere Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1389,
    longitude: 143.2611,
    accuracy: 3,
  },
  {
    postcode: 3469,
    place_name: 'Elmhurst',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1667,
    longitude: 143.25,
    accuracy: 4,
  },
  {
    postcode: 3469,
    place_name: 'Glenlogie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1389,
    longitude: 143.2611,
    accuracy: 3,
  },
  {
    postcode: 3469,
    place_name: 'Glenpatrick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 143.3167,
    accuracy: 4,
  },
  {
    postcode: 3469,
    place_name: 'Glenlofty',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1167,
    longitude: 143.2167,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Bet Bet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9167,
    longitude: 143.75,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Eddington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8833,
    longitude: 143.85,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Bromley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8833,
    longitude: 143.7667,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Betley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 143.8,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Moliagul',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.75,
    longitude: 143.6667,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Goldsborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8167,
    longitude: 143.6833,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Dunluce',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 143.6,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Inkerman',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9148,
    longitude: 143.6487,
    accuracy: 3,
  },
  {
    postcode: 3472,
    place_name: 'Dunolly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8601,
    longitude: 143.7336,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Mount Hooghly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 143.6667,
    accuracy: 4,
  },
  {
    postcode: 3472,
    place_name: 'Mcintyre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8678,
    longitude: 143.7241,
    accuracy: 3,
  },
  {
    postcode: 3475,
    place_name: 'Emu',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 143.4667,
    accuracy: 4,
  },
  {
    postcode: 3475,
    place_name: 'Logan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6167,
    longitude: 143.4667,
    accuracy: 4,
  },
  {
    postcode: 3475,
    place_name: 'Cochranes Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7267,
    longitude: 143.51,
    accuracy: 3,
  },
  {
    postcode: 3475,
    place_name: 'Archdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8333,
    longitude: 143.5167,
    accuracy: 4,
  },
  {
    postcode: 3475,
    place_name: 'Archdale Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7267,
    longitude: 143.51,
    accuracy: 3,
  },
  {
    postcode: 3475,
    place_name: 'Burkes Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 143.55,
    accuracy: 4,
  },
  {
    postcode: 3475,
    place_name: 'Bealiba',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 143.55,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Traynors Lagoon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5833,
    longitude: 143,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Grays Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6653,
    longitude: 143.2081,
    accuracy: 3,
  },
  {
    postcode: 3477,
    place_name: 'Redbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 143.3167,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Rostron',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7667,
    longitude: 143.1833,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Gowar East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.55,
    longitude: 143.4167,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Coonooer West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6653,
    longitude: 143.2081,
    accuracy: 3,
  },
  {
    postcode: 3477,
    place_name: 'Paradise',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8333,
    longitude: 143.1333,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Stuart Mill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8053,
    longitude: 143.2862,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Gre Gre North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5833,
    longitude: 143.0667,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Gre Gre South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6653,
    longitude: 143.2081,
    accuracy: 3,
  },
  {
    postcode: 3477,
    place_name: 'Avon Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.55,
    longitude: 142.9333,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Moyreisk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9167,
    longitude: 143.3833,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'St Arnaud North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6653,
    longitude: 143.2081,
    accuracy: 3,
  },
  {
    postcode: 3477,
    place_name: 'Marnoo East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6653,
    longitude: 143.2081,
    accuracy: 3,
  },
  {
    postcode: 3477,
    place_name: 'Carapooee West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6653,
    longitude: 143.2081,
    accuracy: 3,
  },
  {
    postcode: 3477,
    place_name: 'Beazleys Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7,
    longitude: 143.1667,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Gre Gre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6667,
    longitude: 143.0667,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'York Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6653,
    longitude: 143.2081,
    accuracy: 3,
  },
  {
    postcode: 3477,
    place_name: 'Carapooee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7,
    longitude: 143.3333,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Gooroc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4667,
    longitude: 143.2,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Winjallok',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8167,
    longitude: 143.2,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'St Arnaud East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6653,
    longitude: 143.2081,
    accuracy: 3,
  },
  {
    postcode: 3477,
    place_name: 'Coonooer Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4667,
    longitude: 143.3167,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Moolerr',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6333,
    longitude: 143.2167,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Swanwater',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4833,
    longitude: 143.1333,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Dalyenong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6653,
    longitude: 143.2081,
    accuracy: 3,
  },
  {
    postcode: 3477,
    place_name: 'Sutherland',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.55,
    longitude: 143.2,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Tottington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 143.1167,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Slaty Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.55,
    longitude: 143.3,
    accuracy: 4,
  },
  {
    postcode: 3477,
    place_name: 'Kooreh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6333,
    longitude: 143.4,
    accuracy: 4,
  },
  {
    postcode: 3478,
    place_name: 'Redbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 143.3167,
    accuracy: 4,
  },
  {
    postcode: 3478,
    place_name: 'Percydale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.05,
    longitude: 143.4,
    accuracy: 4,
  },
  {
    postcode: 3478,
    place_name: 'Stuart Mill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8053,
    longitude: 143.2862,
    accuracy: 4,
  },
  {
    postcode: 3478,
    place_name: 'Warrenmang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0333,
    longitude: 143.3,
    accuracy: 4,
  },
  {
    postcode: 3478,
    place_name: 'Medlyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.827,
    longitude: 143.2969,
    accuracy: 3,
  },
  {
    postcode: 3478,
    place_name: 'Moonambel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9833,
    longitude: 143.3167,
    accuracy: 4,
  },
  {
    postcode: 3478,
    place_name: 'St Arnaud',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6168,
    longitude: 143.2587,
    accuracy: 4,
  },
  {
    postcode: 3478,
    place_name: 'Yawong Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.827,
    longitude: 143.2969,
    accuracy: 3,
  },
  {
    postcode: 3478,
    place_name: 'Tanwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.827,
    longitude: 143.2969,
    accuracy: 3,
  },
  {
    postcode: 3478,
    place_name: 'Dooboobetic',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3667,
    longitude: 143.2,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Carron',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 142.7167,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Lawler',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3668,
    longitude: 142.9058,
    accuracy: 3,
  },
  {
    postcode: 3480,
    place_name: 'Areegra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2833,
    longitude: 142.6333,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Corack East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1167,
    longitude: 143.05,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Donald',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3686,
    longitude: 142.9811,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Jeffcott North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3668,
    longitude: 142.9058,
    accuracy: 3,
  },
  {
    postcode: 3480,
    place_name: 'Laen North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4,
    longitude: 142.8167,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Cope Cope',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 143.05,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Corack',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1667,
    longitude: 143.05,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Jeffcott',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.35,
    longitude: 143.1333,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Laen East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3833,
    longitude: 142.8833,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Litchfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 142.8667,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Swanwater West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 143.0167,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Laen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 142.8167,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Rich Avon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5167,
    longitude: 142.8333,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Rich Avon East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3668,
    longitude: 142.9058,
    accuracy: 3,
  },
  {
    postcode: 3480,
    place_name: 'Rich Avon West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5167,
    longitude: 142.8333,
    accuracy: 4,
  },
  {
    postcode: 3480,
    place_name: 'Gil Gil',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3668,
    longitude: 142.9058,
    accuracy: 3,
  },
  {
    postcode: 3482,
    place_name: 'Warmur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1167,
    longitude: 142.7833,
    accuracy: 4,
  },
  {
    postcode: 3482,
    place_name: 'Massey',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2167,
    longitude: 142.8667,
    accuracy: 4,
  },
  {
    postcode: 3482,
    place_name: 'Watchem',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.15,
    longitude: 142.85,
    accuracy: 4,
  },
  {
    postcode: 3482,
    place_name: 'Morton Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0833,
    longitude: 142.9,
    accuracy: 4,
  },
  {
    postcode: 3482,
    place_name: 'Watchem West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1417,
    longitude: 142.85,
    accuracy: 3,
  },
  {
    postcode: 3483,
    place_name: 'Narraport',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36,
    longitude: 143.0333,
    accuracy: 4,
  },
  {
    postcode: 3483,
    place_name: 'Birchip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9838,
    longitude: 142.9173,
    accuracy: 4,
  },
  {
    postcode: 3483,
    place_name: 'Kinnabulla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8833,
    longitude: 142.8,
    accuracy: 4,
  },
  {
    postcode: 3483,
    place_name: 'Karyrie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9,
    longitude: 142.85,
    accuracy: 4,
  },
  {
    postcode: 3483,
    place_name: 'Birchip West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9063,
    longitude: 142.8876,
    accuracy: 3,
  },
  {
    postcode: 3483,
    place_name: 'Whirily',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8667,
    longitude: 142.9667,
    accuracy: 4,
  },
  {
    postcode: 3483,
    place_name: 'Ballapur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9667,
    longitude: 142.7667,
    accuracy: 4,
  },
  {
    postcode: 3483,
    place_name: 'Curyo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8333,
    longitude: 142.8,
    accuracy: 4,
  },
  {
    postcode: 3483,
    place_name: 'Marlbed',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9063,
    longitude: 142.8876,
    accuracy: 3,
  },
  {
    postcode: 3483,
    place_name: 'Jil Jil',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8167,
    longitude: 142.9667,
    accuracy: 4,
  },
  {
    postcode: 3485,
    place_name: 'Woomelang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6811,
    longitude: 142.6617,
    accuracy: 4,
  },
  {
    postcode: 3485,
    place_name: 'Willangie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.65,
    longitude: 142.85,
    accuracy: 4,
  },
  {
    postcode: 3485,
    place_name: 'Banyan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6333,
    longitude: 142.75,
    accuracy: 4,
  },
  {
    postcode: 3485,
    place_name: 'Watchupga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.75,
    longitude: 142.7167,
    accuracy: 4,
  },
  {
    postcode: 3487,
    place_name: 'Lascelles',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6167,
    longitude: 142.5833,
    accuracy: 4,
  },
  {
    postcode: 3488,
    place_name: 'Turriff East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4,
    longitude: 142.5333,
    accuracy: 4,
  },
  {
    postcode: 3488,
    place_name: 'Speed',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4,
    longitude: 142.4333,
    accuracy: 4,
  },
  {
    postcode: 3488,
    place_name: 'Turriff',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.45,
    longitude: 142.45,
    accuracy: 4,
  },
  {
    postcode: 3489,
    place_name: 'Tempy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3333,
    longitude: 142.4167,
    accuracy: 4,
  },
  {
    postcode: 3490,
    place_name: 'Mittyack',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.15,
    longitude: 142.65,
    accuracy: 4,
  },
  {
    postcode: 3490,
    place_name: 'Murray-Sunset',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.751,
    longitude: 141.5034,
    accuracy: 4,
  },
  {
    postcode: 3490,
    place_name: 'Torrita',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.15,
    longitude: 141.9333,
    accuracy: 4,
  },
  {
    postcode: 3490,
    place_name: 'Kulwin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.0333,
    longitude: 142.6167,
    accuracy: 4,
  },
  {
    postcode: 3490,
    place_name: 'Boinka',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2,
    longitude: 141.6,
    accuracy: 4,
  },
  {
    postcode: 3490,
    place_name: 'Big Desert',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.0817,
    longitude: 142.0153,
    accuracy: 3,
  },
  {
    postcode: 3490,
    place_name: 'Ouyen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.0709,
    longitude: 142.3203,
    accuracy: 4,
  },
  {
    postcode: 3490,
    place_name: 'Tutye',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2167,
    longitude: 141.4833,
    accuracy: 4,
  },
  {
    postcode: 3491,
    place_name: 'Patchewollock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3833,
    longitude: 142.2,
    accuracy: 4,
  },
  {
    postcode: 3494,
    place_name: 'Nangiloc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.5,
    longitude: 142.2916,
    accuracy: 3,
  },
  {
    postcode: 3494,
    place_name: 'Iraak',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.4304,
    longitude: 142.354,
    accuracy: 4,
  },
  {
    postcode: 3494,
    place_name: 'Carwarp',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.4602,
    longitude: 142.199,
    accuracy: 4,
  },
  {
    postcode: 3494,
    place_name: 'Colignan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.5248,
    longitude: 142.3656,
    accuracy: 4,
  },
  {
    postcode: 3496,
    place_name: 'Sunnycliffs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.5668,
    longitude: 141.5741,
    accuracy: 3,
  },
  {
    postcode: 3496,
    place_name: 'Cullulleraine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.278,
    longitude: 141.5994,
    accuracy: 4,
  },
  {
    postcode: 3496,
    place_name: 'Werrimull',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.3887,
    longitude: 141.5993,
    accuracy: 4,
  },
  {
    postcode: 3496,
    place_name: 'Red Cliffs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.3094,
    longitude: 142.1897,
    accuracy: 4,
  },
  {
    postcode: 3496,
    place_name: 'Merrinee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.3188,
    longitude: 141.7502,
    accuracy: 3,
  },
  {
    postcode: 3496,
    place_name: 'Cardross',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.3071,
    longitude: 142.1253,
    accuracy: 4,
  },
  {
    postcode: 3496,
    place_name: 'Meringur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.3886,
    longitude: 141.3343,
    accuracy: 4,
  },
  {
    postcode: 3496,
    place_name: 'Neds Corner',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.1425,
    longitude: 141.3304,
    accuracy: 4,
  },
  {
    postcode: 3496,
    place_name: 'Lindsay Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.3188,
    longitude: 141.7502,
    accuracy: 3,
  },
  {
    postcode: 3498,
    place_name: 'Irymple',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.2354,
    longitude: 142.171,
    accuracy: 4,
  },
  {
    postcode: 3500,
    place_name: 'Mildura West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.1631,
    longitude: 142.0026,
    accuracy: 4,
  },
  {
    postcode: 3500,
    place_name: 'Mildura',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.1855,
    longitude: 142.1625,
    accuracy: 4,
  },
  {
    postcode: 3501,
    place_name: 'Mildura Centre Plaza',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.6355,
    longitude: 142.3253,
    accuracy: 3,
  },
  {
    postcode: 3501,
    place_name: 'Nichols Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.2121,
    longitude: 142.2144,
    accuracy: 4,
  },
  {
    postcode: 3501,
    place_name: 'Hattah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.7625,
    longitude: 142.2815,
    accuracy: 4,
  },
  {
    postcode: 3501,
    place_name: 'Koorlong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.2776,
    longitude: 142.0874,
    accuracy: 4,
  },
  {
    postcode: 3501,
    place_name: 'Mildura South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.4207,
    longitude: 142.1937,
    accuracy: 3,
  },
  {
    postcode: 3502,
    place_name: 'Mildura',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.1855,
    longitude: 142.1625,
    accuracy: 4,
  },
  {
    postcode: 3505,
    place_name: 'Wargan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.1853,
    longitude: 142.0476,
    accuracy: 3,
  },
  {
    postcode: 3505,
    place_name: 'Birdwoodton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.2012,
    longitude: 142.0697,
    accuracy: 4,
  },
  {
    postcode: 3505,
    place_name: 'Merbein',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.168,
    longitude: 142.066,
    accuracy: 4,
  },
  {
    postcode: 3505,
    place_name: 'Cabarita',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.1975,
    longitude: 142.0845,
    accuracy: 4,
  },
  {
    postcode: 3505,
    place_name: 'Merbein West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.1631,
    longitude: 142.0026,
    accuracy: 4,
  },
  {
    postcode: 3505,
    place_name: 'Yelta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.1284,
    longitude: 142.0152,
    accuracy: 4,
  },
  {
    postcode: 3505,
    place_name: 'Merbein South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.2333,
    longitude: 142.0333,
    accuracy: 4,
  },
  {
    postcode: 3506,
    place_name: 'Cowangie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2333,
    longitude: 141.3667,
    accuracy: 4,
  },
  {
    postcode: 3507,
    place_name: 'Walpeup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1333,
    longitude: 142.0333,
    accuracy: 4,
  },
  {
    postcode: 3509,
    place_name: 'Linga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1667,
    longitude: 141.7,
    accuracy: 4,
  },
  {
    postcode: 3509,
    place_name: 'Underbool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1695,
    longitude: 141.8056,
    accuracy: 4,
  },
  {
    postcode: 3512,
    place_name: 'Carina',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.25,
    longitude: 141.1,
    accuracy: 4,
  },
  {
    postcode: 3512,
    place_name: 'Murrayville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2625,
    longitude: 141.1835,
    accuracy: 4,
  },
  {
    postcode: 3512,
    place_name: 'Panitya',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2333,
    longitude: 141,
    accuracy: 4,
  },
  {
    postcode: 3515,
    place_name: 'Shelbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8833,
    longitude: 144.0167,
    accuracy: 4,
  },
  {
    postcode: 3515,
    place_name: 'Wilsons Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8083,
    longitude: 144.075,
    accuracy: 3,
  },
  {
    postcode: 3515,
    place_name: 'Marong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3516,
    place_name: 'Bridgewater On Loddon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6027,
    longitude: 143.9423,
    accuracy: 4,
  },
  {
    postcode: 3516,
    place_name: 'Derby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 144.0167,
    accuracy: 4,
  },
  {
    postcode: 3516,
    place_name: 'Yarraberb',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6167,
    longitude: 144.0667,
    accuracy: 4,
  },
  {
    postcode: 3516,
    place_name: 'Leichardt',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6833,
    longitude: 144.0667,
    accuracy: 4,
  },
  {
    postcode: 3516,
    place_name: 'Bridgewater',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6027,
    longitude: 143.9423,
    accuracy: 4,
  },
  {
    postcode: 3516,
    place_name: 'Bridgewater North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6311,
    longitude: 144.0069,
    accuracy: 3,
  },
  {
    postcode: 3517,
    place_name: 'Glenalbyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5167,
    longitude: 143.7333,
    accuracy: 4,
  },
  {
    postcode: 3517,
    place_name: 'Jarklin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3517,
    place_name: 'Serpentine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4333,
    longitude: 143.9833,
    accuracy: 4,
  },
  {
    postcode: 3517,
    place_name: 'Kurting',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 143.85,
    accuracy: 4,
  },
  {
    postcode: 3517,
    place_name: 'Powlett Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 3517,
    place_name: 'Salisbury West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5167,
    longitude: 143.9667,
    accuracy: 4,
  },
  {
    postcode: 3517,
    place_name: 'Inglewood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.575,
    longitude: 143.8683,
    accuracy: 4,
  },
  {
    postcode: 3517,
    place_name: 'Bears Lagoon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.328,
    longitude: 143.9755,
    accuracy: 4,
  },
  {
    postcode: 3517,
    place_name: 'Rheola',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 143.7,
    accuracy: 4,
  },
  {
    postcode: 3517,
    place_name: 'Brenanah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4886,
    longitude: 143.866,
    accuracy: 3,
  },
  {
    postcode: 3517,
    place_name: 'Kingower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6167,
    longitude: 143.75,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Richmond Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3833,
    longitude: 143.45,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Skinners Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4161,
    longitude: 143.6325,
    accuracy: 3,
  },
  {
    postcode: 3518,
    place_name: 'Borung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 143.75,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Kurraca West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5,
    longitude: 143.5667,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Fernihurst',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Woolshed Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4161,
    longitude: 143.6325,
    accuracy: 3,
  },
  {
    postcode: 3518,
    place_name: 'Berrimal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4833,
    longitude: 143.4833,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Wedderburn Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 143.7,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Mysia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 143.7667,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Wehla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 143.6,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Nine Mile',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4333,
    longitude: 143.4833,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Fentons Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5667,
    longitude: 143.5333,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Woosang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3333,
    longitude: 143.4833,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Kurraca',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5,
    longitude: 143.5667,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Fiery Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4,
    longitude: 143.8,
    accuracy: 4,
  },
  {
    postcode: 3518,
    place_name: 'Wedderburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.419,
    longitude: 143.6139,
    accuracy: 4,
  },
  {
    postcode: 3520,
    place_name: 'Korong Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3561,
    longitude: 143.7034,
    accuracy: 4,
  },
  {
    postcode: 3520,
    place_name: 'Kinypanial',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.35,
    longitude: 143.8,
    accuracy: 4,
  },
  {
    postcode: 3521,
    place_name: 'Pyalong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1129,
    longitude: 144.8523,
    accuracy: 4,
  },
  {
    postcode: 3522,
    place_name: 'Tooborac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.05,
    longitude: 144.8333,
    accuracy: 4,
  },
  {
    postcode: 3522,
    place_name: 'Glenhope East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.05,
    longitude: 144.8333,
    accuracy: 3,
  },
  {
    postcode: 3523,
    place_name: 'Costerfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8667,
    longitude: 144.8,
    accuracy: 4,
  },
  {
    postcode: 3523,
    place_name: 'Derrinal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8833,
    longitude: 144.6333,
    accuracy: 4,
  },
  {
    postcode: 3523,
    place_name: 'Argyle',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.95,
    longitude: 144.7333,
    accuracy: 4,
  },
  {
    postcode: 3523,
    place_name: 'Moormbool West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 144.9,
    accuracy: 4,
  },
  {
    postcode: 3523,
    place_name: 'Redcastle',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 144.7833,
    accuracy: 4,
  },
  {
    postcode: 3523,
    place_name: 'Heathcote',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9221,
    longitude: 144.7084,
    accuracy: 4,
  },
  {
    postcode: 3523,
    place_name: 'Ladys Pass',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8627,
    longitude: 144.7345,
    accuracy: 3,
  },
  {
    postcode: 3523,
    place_name: 'Knowsley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8333,
    longitude: 144.5833,
    accuracy: 4,
  },
  {
    postcode: 3523,
    place_name: 'Heathcote South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8627,
    longitude: 144.7345,
    accuracy: 3,
  },
  {
    postcode: 3523,
    place_name: 'Mount Camel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8627,
    longitude: 144.7345,
    accuracy: 3,
  },
  {
    postcode: 3525,
    place_name: 'Wychitella North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2726,
    longitude: 143.4353,
    accuracy: 3,
  },
  {
    postcode: 3525,
    place_name: 'Charlton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2644,
    longitude: 143.349,
    accuracy: 4,
  },
  {
    postcode: 3525,
    place_name: 'Buckrabanyule',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 143.5167,
    accuracy: 4,
  },
  {
    postcode: 3525,
    place_name: 'Granite Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2726,
    longitude: 143.4353,
    accuracy: 3,
  },
  {
    postcode: 3525,
    place_name: 'Wooroonook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 143.2,
    accuracy: 4,
  },
  {
    postcode: 3525,
    place_name: 'Yeungroon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3833,
    longitude: 143.4,
    accuracy: 4,
  },
  {
    postcode: 3525,
    place_name: 'Barrakee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 143.45,
    accuracy: 4,
  },
  {
    postcode: 3525,
    place_name: 'Terrappee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2726,
    longitude: 143.4353,
    accuracy: 3,
  },
  {
    postcode: 3525,
    place_name: 'Wychitella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2833,
    longitude: 143.6167,
    accuracy: 4,
  },
  {
    postcode: 3525,
    place_name: 'Yeungroon East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.35,
    longitude: 143.4333,
    accuracy: 4,
  },
  {
    postcode: 3525,
    place_name: 'Chirrip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2726,
    longitude: 143.4353,
    accuracy: 3,
  },
  {
    postcode: 3525,
    place_name: 'Lake Marmal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.132,
    longitude: 143.516,
    accuracy: 4,
  },
  {
    postcode: 3525,
    place_name: 'Nareewillock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2726,
    longitude: 143.4353,
    accuracy: 3,
  },
  {
    postcode: 3527,
    place_name: 'Jeruk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.05,
    longitude: 143.3833,
    accuracy: 4,
  },
  {
    postcode: 3527,
    place_name: 'Bunguluke',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0561,
    longitude: 143.3904,
    accuracy: 4,
  },
  {
    postcode: 3527,
    place_name: 'Thalia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0667,
    longitude: 143.0833,
    accuracy: 4,
  },
  {
    postcode: 3527,
    place_name: 'Ninyeunook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9871,
    longitude: 143.4019,
    accuracy: 4,
  },
  {
    postcode: 3527,
    place_name: 'Towaninny South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9229,
    longitude: 143.3527,
    accuracy: 4,
  },
  {
    postcode: 3527,
    place_name: 'Glenloth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1451,
    longitude: 143.3388,
    accuracy: 4,
  },
  {
    postcode: 3527,
    place_name: 'Towaninny',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9229,
    longitude: 143.3527,
    accuracy: 4,
  },
  {
    postcode: 3527,
    place_name: 'Wycheproof South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0563,
    longitude: 143.2848,
    accuracy: 3,
  },
  {
    postcode: 3527,
    place_name: 'Dumosa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.936,
    longitude: 143.2245,
    accuracy: 4,
  },
  {
    postcode: 3527,
    place_name: 'Teddywaddy West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0563,
    longitude: 143.2848,
    accuracy: 3,
  },
  {
    postcode: 3527,
    place_name: 'Wycheproof',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0776,
    longitude: 143.2271,
    accuracy: 4,
  },
  {
    postcode: 3527,
    place_name: 'Glenloth East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0563,
    longitude: 143.2848,
    accuracy: 3,
  },
  {
    postcode: 3527,
    place_name: 'Teddywaddy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1937,
    longitude: 143.3374,
    accuracy: 4,
  },
  {
    postcode: 3529,
    place_name: 'Nullawil',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.85,
    longitude: 143.1833,
    accuracy: 4,
  },
  {
    postcode: 3529,
    place_name: 'Kalpienung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7826,
    longitude: 143.2558,
    accuracy: 4,
  },
  {
    postcode: 3530,
    place_name: 'Warne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7808,
    longitude: 143.1242,
    accuracy: 4,
  },
  {
    postcode: 3530,
    place_name: 'Wangie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.725,
    longitude: 143.0417,
    accuracy: 3,
  },
  {
    postcode: 3530,
    place_name: 'Sutton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7333,
    longitude: 143,
    accuracy: 4,
  },
  {
    postcode: 3530,
    place_name: 'Culgoa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7167,
    longitude: 143.0833,
    accuracy: 4,
  },
  {
    postcode: 3531,
    place_name: 'Boigbeat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.55,
    longitude: 142.9167,
    accuracy: 4,
  },
  {
    postcode: 3531,
    place_name: 'Berriwillock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6333,
    longitude: 143,
    accuracy: 4,
  },
  {
    postcode: 3533,
    place_name: 'Sea Lake',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.5039,
    longitude: 142.8498,
    accuracy: 4,
  },
  {
    postcode: 3533,
    place_name: 'Lake Tyrrell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4244,
    longitude: 142.9857,
    accuracy: 3,
  },
  {
    postcode: 3533,
    place_name: 'Ninda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4667,
    longitude: 142.75,
    accuracy: 4,
  },
  {
    postcode: 3533,
    place_name: 'Nyarrin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3833,
    longitude: 142.6833,
    accuracy: 4,
  },
  {
    postcode: 3533,
    place_name: 'Pier Milan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4244,
    longitude: 142.9857,
    accuracy: 3,
  },
  {
    postcode: 3533,
    place_name: 'Straten',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4244,
    longitude: 142.9857,
    accuracy: 3,
  },
  {
    postcode: 3533,
    place_name: 'Nandaly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3,
    longitude: 142.7,
    accuracy: 4,
  },
  {
    postcode: 3533,
    place_name: 'Tyenna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4244,
    longitude: 142.9857,
    accuracy: 3,
  },
  {
    postcode: 3533,
    place_name: 'Tyrrell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3667,
    longitude: 142.9667,
    accuracy: 4,
  },
  {
    postcode: 3533,
    place_name: 'Bimbourie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4244,
    longitude: 142.9857,
    accuracy: 3,
  },
  {
    postcode: 3533,
    place_name: 'Tyrrell Downs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3667,
    longitude: 142.9667,
    accuracy: 4,
  },
  {
    postcode: 3533,
    place_name: 'Myall',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.581,
    longitude: 144.0013,
    accuracy: 4,
  },
  {
    postcode: 3537,
    place_name: 'Canary Island',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9917,
    longitude: 143.843,
    accuracy: 4,
  },
  {
    postcode: 3537,
    place_name: 'Barraport West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0308,
    longitude: 143.7505,
    accuracy: 3,
  },
  {
    postcode: 3537,
    place_name: 'Gredgwin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9694,
    longitude: 143.6191,
    accuracy: 4,
  },
  {
    postcode: 3537,
    place_name: 'Leaghur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9637,
    longitude: 143.7847,
    accuracy: 4,
  },
  {
    postcode: 3537,
    place_name: 'Minmindie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0402,
    longitude: 143.7511,
    accuracy: 4,
  },
  {
    postcode: 3537,
    place_name: 'Catumnal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0308,
    longitude: 143.7505,
    accuracy: 3,
  },
  {
    postcode: 3537,
    place_name: 'Barraport',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0078,
    longitude: 143.6709,
    accuracy: 4,
  },
  {
    postcode: 3537,
    place_name: 'Yando',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0789,
    longitude: 143.8058,
    accuracy: 4,
  },
  {
    postcode: 3537,
    place_name: 'Boort',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1157,
    longitude: 143.7199,
    accuracy: 4,
  },
  {
    postcode: 3540,
    place_name: 'Oakvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9109,
    longitude: 143.5635,
    accuracy: 4,
  },
  {
    postcode: 3540,
    place_name: 'Quambatook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8524,
    longitude: 143.5207,
    accuracy: 4,
  },
  {
    postcode: 3540,
    place_name: 'Cannie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.754,
    longitude: 143.4485,
    accuracy: 4,
  },
  {
    postcode: 3542,
    place_name: 'Lalbert',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6672,
    longitude: 143.3779,
    accuracy: 4,
  },
  {
    postcode: 3542,
    place_name: 'Tittybong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7466,
    longitude: 143.372,
    accuracy: 4,
  },
  {
    postcode: 3542,
    place_name: 'Cokum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.725,
    longitude: 143.3667,
    accuracy: 3,
  },
  {
    postcode: 3544,
    place_name: 'Gowanford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4187,
    longitude: 143.1916,
    accuracy: 4,
  },
  {
    postcode: 3544,
    place_name: 'Ultima East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4222,
    longitude: 143.1778,
    accuracy: 3,
  },
  {
    postcode: 3544,
    place_name: 'Waitchie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3667,
    longitude: 143.0833,
    accuracy: 4,
  },
  {
    postcode: 3544,
    place_name: 'Chinangin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4222,
    longitude: 143.1778,
    accuracy: 3,
  },
  {
    postcode: 3544,
    place_name: 'Springfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 144.8167,
    accuracy: 4,
  },
  {
    postcode: 3544,
    place_name: 'Murnungin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4222,
    longitude: 143.1778,
    accuracy: 3,
  },
  {
    postcode: 3544,
    place_name: 'Ultima',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.468,
    longitude: 143.2709,
    accuracy: 4,
  },
  {
    postcode: 3546,
    place_name: 'Chinkapook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1891,
    longitude: 142.9431,
    accuracy: 4,
  },
  {
    postcode: 3546,
    place_name: 'Gerahmin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.0977,
    longitude: 142.9331,
    accuracy: 3,
  },
  {
    postcode: 3546,
    place_name: 'Turoar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1667,
    longitude: 143.05,
    accuracy: 4,
  },
  {
    postcode: 3546,
    place_name: 'Manangatang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.0551,
    longitude: 142.8823,
    accuracy: 4,
  },
  {
    postcode: 3546,
    place_name: 'Cocamba',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.116,
    longitude: 142.8978,
    accuracy: 4,
  },
  {
    postcode: 3546,
    place_name: 'Bolton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.9666,
    longitude: 142.8815,
    accuracy: 4,
  },
  {
    postcode: 3546,
    place_name: 'Winnambool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.0977,
    longitude: 142.9331,
    accuracy: 3,
  },
  {
    postcode: 3549,
    place_name: 'Robinvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.5836,
    longitude: 142.7723,
    accuracy: 4,
  },
  {
    postcode: 3549,
    place_name: 'Robinvale Irrigation District Section B',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.7137,
    longitude: 142.753,
    accuracy: 3,
  },
  {
    postcode: 3549,
    place_name: 'Annuello',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.85,
    longitude: 142.8333,
    accuracy: 4,
  },
  {
    postcode: 3549,
    place_name: 'Happy Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 143.6,
    accuracy: 4,
  },
  {
    postcode: 3549,
    place_name: 'Bannerton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.6992,
    longitude: 142.7883,
    accuracy: 4,
  },
  {
    postcode: 3549,
    place_name: 'Tol Tol',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.6514,
    longitude: 142.8258,
    accuracy: 4,
  },
  {
    postcode: 3549,
    place_name: 'Wemen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.7887,
    longitude: 142.6301,
    accuracy: 4,
  },
  {
    postcode: 3549,
    place_name: 'Robinvale Irrigation District Section E',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.7137,
    longitude: 142.753,
    accuracy: 3,
  },
  {
    postcode: 3549,
    place_name: 'Wandown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.7137,
    longitude: 142.753,
    accuracy: 3,
  },
  {
    postcode: 3549,
    place_name: 'Robinvale Irrigation District Section D',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.7137,
    longitude: 142.753,
    accuracy: 3,
  },
  {
    postcode: 3549,
    place_name: 'Liparoo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.7848,
    longitude: 142.5431,
    accuracy: 4,
  },
  {
    postcode: 3549,
    place_name: 'Robinvale Irrigation District Section C',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.7137,
    longitude: 142.753,
    accuracy: 3,
  },
  {
    postcode: 3550,
    place_name: 'Flora Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7812,
    longitude: 144.2956,
    accuracy: 4,
  },
  {
    postcode: 3550,
    place_name: 'East Bendigo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7366,
    longitude: 144.3178,
    accuracy: 3,
  },
  {
    postcode: 3550,
    place_name: 'North Bendigo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.731,
    longitude: 144.2997,
    accuracy: 3,
  },
  {
    postcode: 3550,
    place_name: 'Strathdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7657,
    longitude: 144.3155,
    accuracy: 4,
  },
  {
    postcode: 3550,
    place_name: 'Kennington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 144.3,
    accuracy: 4,
  },
  {
    postcode: 3550,
    place_name: 'Spring Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7575,
    longitude: 144.2908,
    accuracy: 3,
  },
  {
    postcode: 3550,
    place_name: 'Long Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7431,
    longitude: 144.2579,
    accuracy: 4,
  },
  {
    postcode: 3550,
    place_name: 'White Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7287,
    longitude: 144.3053,
    accuracy: 4,
  },
  {
    postcode: 3550,
    place_name: 'West Bendigo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 144.5833,
    accuracy: 4,
  },
  {
    postcode: 3550,
    place_name: 'Bendigo South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 144.2833,
    accuracy: 3,
  },
  {
    postcode: 3550,
    place_name: 'Quarry Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7765,
    longitude: 144.2802,
    accuracy: 4,
  },
  {
    postcode: 3550,
    place_name: 'Ironbark',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7548,
    longitude: 144.2638,
    accuracy: 4,
  },
  {
    postcode: 3550,
    place_name: 'Tysons Reef',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7575,
    longitude: 144.2908,
    accuracy: 3,
  },
  {
    postcode: 3550,
    place_name: 'Bendigo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7582,
    longitude: 144.2802,
    accuracy: 4,
  },
  {
    postcode: 3550,
    place_name: 'Sandhurst East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7575,
    longitude: 144.2908,
    accuracy: 3,
  },
  {
    postcode: 3551,
    place_name: 'Toolleen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7167,
    longitude: 144.7,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Lake Eppalock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7536,
    longitude: 144.2402,
    accuracy: 3,
  },
  {
    postcode: 3551,
    place_name: 'Wellsford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6333,
    longitude: 144.4667,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Myrtle Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7536,
    longitude: 144.2402,
    accuracy: 3,
  },
  {
    postcode: 3551,
    place_name: 'Maiden Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7539,
    longitude: 144.2079,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Huntly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6645,
    longitude: 144.3338,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Arnold West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6667,
    longitude: 143.8,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Huntly North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7536,
    longitude: 144.2402,
    accuracy: 3,
  },
  {
    postcode: 3551,
    place_name: 'Emu Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8333,
    longitude: 144.35,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Tarnagulla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.75,
    longitude: 143.8333,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Lockwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8111,
    longitude: 144.1576,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Painswick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7519,
    longitude: 144.2656,
    accuracy: 3,
  },
  {
    postcode: 3551,
    place_name: 'Bendigo Forward',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7536,
    longitude: 144.2402,
    accuracy: 3,
  },
  {
    postcode: 3551,
    place_name: 'Myola',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6333,
    longitude: 144.65,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Waanyarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 143.8,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Junortoun',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7711,
    longitude: 144.3631,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Llanelly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Longlea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 144.4333,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Mandurang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8167,
    longitude: 144.3,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Axedale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 144.5167,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Woodstock On Loddon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 144.05,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Bagshot',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 144.4167,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Murphys Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.75,
    longitude: 143.7667,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Bagshot North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7536,
    longitude: 144.2402,
    accuracy: 3,
  },
  {
    postcode: 3551,
    place_name: 'Newbridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 143.9167,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Eppalock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8333,
    longitude: 144.4167,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Arnold',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6833,
    longitude: 143.8667,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Axe Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7536,
    longitude: 144.2402,
    accuracy: 3,
  },
  {
    postcode: 3551,
    place_name: 'Mandurang South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 144.2833,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Kimbolton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7536,
    longitude: 144.2402,
    accuracy: 3,
  },
  {
    postcode: 3551,
    place_name: 'Epsom',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7167,
    longitude: 144.3167,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Cornella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7,
    longitude: 144.75,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Strathfieldsaye',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8067,
    longitude: 144.3549,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Lockwood South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8436,
    longitude: 144.1584,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Ascot',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7,
    longitude: 144.3333,
    accuracy: 4,
  },
  {
    postcode: 3551,
    place_name: 'Sedgwick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8833,
    longitude: 144.3167,
    accuracy: 4,
  },
  {
    postcode: 3552,
    place_name: 'Bendigo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7582,
    longitude: 144.2802,
    accuracy: 4,
  },
  {
    postcode: 3554,
    place_name: 'Bendigo Dc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.731,
    longitude: 144.2997,
    accuracy: 1,
  },
  {
    postcode: 3555,
    place_name: 'Golden Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 143.6667,
    accuracy: 4,
  },
  {
    postcode: 3555,
    place_name: 'Golden Square',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7726,
    longitude: 144.2527,
    accuracy: 4,
  },
  {
    postcode: 3555,
    place_name: 'Kangaroo Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 144.25,
    accuracy: 4,
  },
  {
    postcode: 3555,
    place_name: 'Lansell Plaza',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0909,
    longitude: 144.0565,
    accuracy: 3,
  },
  {
    postcode: 3555,
    place_name: 'Big Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0909,
    longitude: 144.0565,
    accuracy: 3,
  },
  {
    postcode: 3556,
    place_name: 'Campbells Forest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6,
    longitude: 144.1333,
    accuracy: 4,
  },
  {
    postcode: 3556,
    place_name: 'Eaglehawk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 144.25,
    accuracy: 4,
  },
  {
    postcode: 3556,
    place_name: 'Sebastian',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6,
    longitude: 144.2,
    accuracy: 4,
  },
  {
    postcode: 3556,
    place_name: 'Eaglehawk North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6731,
    longitude: 144.221,
    accuracy: 3,
  },
  {
    postcode: 3556,
    place_name: 'California Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7289,
    longitude: 144.2609,
    accuracy: 4,
  },
  {
    postcode: 3556,
    place_name: 'Woodvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 144.2167,
    accuracy: 4,
  },
  {
    postcode: 3556,
    place_name: 'Comet Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6879,
    longitude: 144.2346,
    accuracy: 3,
  },
  {
    postcode: 3556,
    place_name: 'Jackass Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7159,
    longitude: 144.286,
    accuracy: 4,
  },
  {
    postcode: 3556,
    place_name: 'Myers Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6833,
    longitude: 144.2,
    accuracy: 4,
  },
  {
    postcode: 3556,
    place_name: 'Whipstick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6731,
    longitude: 144.221,
    accuracy: 3,
  },
  {
    postcode: 3556,
    place_name: 'Sailors Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6731,
    longitude: 144.221,
    accuracy: 3,
  },
  {
    postcode: 3557,
    place_name: 'Goornong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6165,
    longitude: 144.5072,
    accuracy: 4,
  },
  {
    postcode: 3557,
    place_name: 'Barnadown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 144.55,
    accuracy: 4,
  },
  {
    postcode: 3557,
    place_name: 'Muskerry',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6833,
    longitude: 144.6333,
    accuracy: 4,
  },
  {
    postcode: 3557,
    place_name: 'Fosterville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7,
    longitude: 144.5,
    accuracy: 4,
  },
  {
    postcode: 3558,
    place_name: 'Elmore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4946,
    longitude: 144.6105,
    accuracy: 4,
  },
  {
    postcode: 3558,
    place_name: 'Hunter',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 144.5167,
    accuracy: 4,
  },
  {
    postcode: 3558,
    place_name: 'Burnewang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4723,
    longitude: 144.5636,
    accuracy: 3,
  },
  {
    postcode: 3558,
    place_name: 'Runnymede',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4723,
    longitude: 144.5636,
    accuracy: 3,
  },
  {
    postcode: 3558,
    place_name: 'Corop West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4723,
    longitude: 144.5636,
    accuracy: 3,
  },
  {
    postcode: 3559,
    place_name: 'Avonmore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 144.5667,
    accuracy: 4,
  },
  {
    postcode: 3559,
    place_name: 'Gobarup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 144.7278,
    accuracy: 3,
  },
  {
    postcode: 3559,
    place_name: 'Colbinabbin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6,
    longitude: 144.8167,
    accuracy: 4,
  },
  {
    postcode: 3559,
    place_name: 'Corop',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4667,
    longitude: 144.8,
    accuracy: 4,
  },
  {
    postcode: 3559,
    place_name: 'Burramboot',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 144.7278,
    accuracy: 3,
  },
  {
    postcode: 3561,
    place_name: 'Fairy Dell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3333,
    longitude: 144.75,
    accuracy: 4,
  },
  {
    postcode: 3561,
    place_name: 'Rochester',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3635,
    longitude: 144.7008,
    accuracy: 4,
  },
  {
    postcode: 3561,
    place_name: 'Ballendella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 144.65,
    accuracy: 4,
  },
  {
    postcode: 3561,
    place_name: 'Bonn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3305,
    longitude: 144.712,
    accuracy: 3,
  },
  {
    postcode: 3561,
    place_name: 'Timmering',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3667,
    longitude: 144.9167,
    accuracy: 4,
  },
  {
    postcode: 3561,
    place_name: 'Nanneella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3167,
    longitude: 144.7833,
    accuracy: 4,
  },
  {
    postcode: 3561,
    place_name: 'Bamawm',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2145,
    longitude: 144.6089,
    accuracy: 4,
  },
  {
    postcode: 3561,
    place_name: 'Diggora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 144.5833,
    accuracy: 4,
  },
  {
    postcode: 3562,
    place_name: 'Torrumbarry',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0231,
    longitude: 144.5533,
    accuracy: 4,
  },
  {
    postcode: 3563,
    place_name: 'Lockington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2712,
    longitude: 144.5373,
    accuracy: 4,
  },
  {
    postcode: 3564,
    place_name: 'Roslynmead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1262,
    longitude: 144.6892,
    accuracy: 3,
  },
  {
    postcode: 3564,
    place_name: 'Echuca South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1262,
    longitude: 144.6892,
    accuracy: 3,
  },
  {
    postcode: 3564,
    place_name: 'Wharparilla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.129,
    longitude: 144.6289,
    accuracy: 4,
  },
  {
    postcode: 3564,
    place_name: 'Simmie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1292,
    longitude: 144.6995,
    accuracy: 3,
  },
  {
    postcode: 3564,
    place_name: 'Echuca',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1406,
    longitude: 144.7518,
    accuracy: 4,
  },
  {
    postcode: 3564,
    place_name: 'Echuca Village',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1333,
    longitude: 144.8167,
    accuracy: 4,
  },
  {
    postcode: 3564,
    place_name: 'Bamawm Extension',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2145,
    longitude: 144.6089,
    accuracy: 4,
  },
  {
    postcode: 3564,
    place_name: 'Kanyapella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1574,
    longitude: 144.7962,
    accuracy: 4,
  },
  {
    postcode: 3564,
    place_name: 'Echuca West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1262,
    longitude: 144.6892,
    accuracy: 3,
  },
  {
    postcode: 3564,
    place_name: 'Patho',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0087,
    longitude: 144.4304,
    accuracy: 4,
  },
  {
    postcode: 3565,
    place_name: 'Kotta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2003,
    longitude: 144.5363,
    accuracy: 4,
  },
  {
    postcode: 3566,
    place_name: 'Gunbower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9561,
    longitude: 144.3692,
    accuracy: 4,
  },
  {
    postcode: 3567,
    place_name: 'Horfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9039,
    longitude: 144.3022,
    accuracy: 3,
  },
  {
    postcode: 3567,
    place_name: 'Leitchville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9039,
    longitude: 144.3022,
    accuracy: 4,
  },
  {
    postcode: 3568,
    place_name: 'Mead',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8147,
    longitude: 144.1738,
    accuracy: 3,
  },
  {
    postcode: 3568,
    place_name: 'Cohuna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.807,
    longitude: 144.2189,
    accuracy: 4,
  },
  {
    postcode: 3568,
    place_name: 'Gannawarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7333,
    longitude: 144.15,
    accuracy: 4,
  },
  {
    postcode: 3568,
    place_name: 'Macorna North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8391,
    longitude: 144.0922,
    accuracy: 4,
  },
  {
    postcode: 3568,
    place_name: 'Cullen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8147,
    longitude: 144.1738,
    accuracy: 3,
  },
  {
    postcode: 3568,
    place_name: 'Keely',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.85,
    longitude: 144.25,
    accuracy: 4,
  },
  {
    postcode: 3568,
    place_name: 'Mincha West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8147,
    longitude: 144.1738,
    accuracy: 3,
  },
  {
    postcode: 3568,
    place_name: 'Mcmillans',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8333,
    longitude: 144.15,
    accuracy: 4,
  },
  {
    postcode: 3568,
    place_name: 'Wee Wee Rup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8147,
    longitude: 144.1738,
    accuracy: 3,
  },
  {
    postcode: 3568,
    place_name: 'Daltons Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8147,
    longitude: 144.1738,
    accuracy: 3,
  },
  {
    postcode: 3568,
    place_name: 'Burkes Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8147,
    longitude: 144.1738,
    accuracy: 3,
  },
  {
    postcode: 3570,
    place_name: 'Kamarooka',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4707,
    longitude: 144.3336,
    accuracy: 4,
  },
  {
    postcode: 3570,
    place_name: 'Raywood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 144.2167,
    accuracy: 4,
  },
  {
    postcode: 3570,
    place_name: 'Drummartin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 144.4333,
    accuracy: 4,
  },
  {
    postcode: 3570,
    place_name: 'Auchmore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5972,
    longitude: 144.2121,
    accuracy: 3,
  },
  {
    postcode: 3570,
    place_name: 'Neilborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5667,
    longitude: 144.2333,
    accuracy: 4,
  },
  {
    postcode: 3571,
    place_name: 'Kamarooka North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4064,
    longitude: 144.1719,
    accuracy: 3,
  },
  {
    postcode: 3571,
    place_name: 'Dingee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3667,
    longitude: 144.2167,
    accuracy: 4,
  },
  {
    postcode: 3571,
    place_name: 'Pompapiel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.35,
    longitude: 144.0833,
    accuracy: 4,
  },
  {
    postcode: 3571,
    place_name: 'Tandarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4285,
    longitude: 144.2286,
    accuracy: 4,
  },
  {
    postcode: 3572,
    place_name: 'Tennyson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2833,
    longitude: 144.4333,
    accuracy: 4,
  },
  {
    postcode: 3572,
    place_name: 'Piavella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 144.3444,
    accuracy: 3,
  },
  {
    postcode: 3572,
    place_name: 'Milloo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3167,
    longitude: 144.3667,
    accuracy: 4,
  },
  {
    postcode: 3572,
    place_name: 'Prairie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 144.2333,
    accuracy: 4,
  },
  {
    postcode: 3573,
    place_name: 'Pine Grove',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2333,
    longitude: 144.4167,
    accuracy: 4,
  },
  {
    postcode: 3573,
    place_name: 'Calivil',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 144.0667,
    accuracy: 4,
  },
  {
    postcode: 3573,
    place_name: 'Terrick Terrick East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2389,
    longitude: 144.2389,
    accuracy: 3,
  },
  {
    postcode: 3573,
    place_name: 'Mitiamo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2078,
    longitude: 144.2306,
    accuracy: 4,
  },
  {
    postcode: 3575,
    place_name: 'Gladfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0506,
    longitude: 143.9521,
    accuracy: 4,
  },
  {
    postcode: 3575,
    place_name: 'Mologa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1437,
    longitude: 144.1791,
    accuracy: 4,
  },
  {
    postcode: 3575,
    place_name: 'Sylvaterre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.11,
    longitude: 144.2248,
    accuracy: 4,
  },
  {
    postcode: 3575,
    place_name: 'Loddon Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3561,
    longitude: 143.7034,
    accuracy: 4,
  },
  {
    postcode: 3575,
    place_name: 'Pyramid Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0558,
    longitude: 144.1147,
    accuracy: 4,
  },
  {
    postcode: 3575,
    place_name: 'Yarrawalla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1165,
    longitude: 144.0308,
    accuracy: 4,
  },
  {
    postcode: 3575,
    place_name: 'Terrick Terrick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0843,
    longitude: 144.2808,
    accuracy: 4,
  },
  {
    postcode: 3575,
    place_name: 'Jungaburra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.114,
    longitude: 144.0939,
    accuracy: 3,
  },
  {
    postcode: 3575,
    place_name: 'Mincha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0022,
    longitude: 144.0757,
    accuracy: 4,
  },
  {
    postcode: 3576,
    place_name: 'Durham Ox',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1259,
    longitude: 143.9199,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Mystic Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.5535,
    longitude: 143.7221,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Murrabit West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.5193,
    longitude: 143.9049,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Teal Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6746,
    longitude: 144.0757,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Kerang East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7167,
    longitude: 143.8618,
    accuracy: 3,
  },
  {
    postcode: 3579,
    place_name: 'Beauchamp',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6082,
    longitude: 143.5719,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Westby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7167,
    longitude: 143.8618,
    accuracy: 3,
  },
  {
    postcode: 3579,
    place_name: 'Sandhill Lake',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7248,
    longitude: 143.685,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Koroop',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7425,
    longitude: 144.0736,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Appin South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9204,
    longitude: 143.9123,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Wandella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7167,
    longitude: 143.8618,
    accuracy: 3,
  },
  {
    postcode: 3579,
    place_name: 'Budgerum East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7792,
    longitude: 143.6597,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Macorna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9177,
    longitude: 144.0305,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Capels Crossing',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6333,
    longitude: 143.4333,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Meering West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7249,
    longitude: 143.8763,
    accuracy: 3,
  },
  {
    postcode: 3579,
    place_name: 'Murrabit',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.5322,
    longitude: 143.9524,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Benjeroop',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4913,
    longitude: 143.8604,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Tragowel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8513,
    longitude: 143.9777,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Lake Meran',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7167,
    longitude: 143.8618,
    accuracy: 3,
  },
  {
    postcode: 3579,
    place_name: 'Myall',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.581,
    longitude: 144.0013,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Gonn Crossing',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7167,
    longitude: 143.8618,
    accuracy: 3,
  },
  {
    postcode: 3579,
    place_name: 'Normanville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8161,
    longitude: 143.7454,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Fairley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6868,
    longitude: 143.8612,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Milnes Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8,
    longitude: 144.0667,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Pine View',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7167,
    longitude: 143.8618,
    accuracy: 3,
  },
  {
    postcode: 3579,
    place_name: 'Reedy Lake',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7167,
    longitude: 143.8618,
    accuracy: 3,
  },
  {
    postcode: 3579,
    place_name: 'Kerang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7349,
    longitude: 143.9203,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Bael Bael',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.7167,
    longitude: 143.8618,
    accuracy: 3,
  },
  {
    postcode: 3579,
    place_name: 'Appin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9065,
    longitude: 143.8691,
    accuracy: 4,
  },
  {
    postcode: 3579,
    place_name: 'Dingwall',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.811,
    longitude: 143.8309,
    accuracy: 4,
  },
  {
    postcode: 3580,
    place_name: 'Koondrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.6318,
    longitude: 144.1248,
    accuracy: 4,
  },
  {
    postcode: 3581,
    place_name: 'Lake Charm',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.65,
    longitude: 143.7833,
    accuracy: 4,
  },
  {
    postcode: 3583,
    place_name: 'Tresco',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.5057,
    longitude: 143.6748,
    accuracy: 4,
  },
  {
    postcode: 3584,
    place_name: 'Tresco West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.463,
    longitude: 143.6326,
    accuracy: 3,
  },
  {
    postcode: 3584,
    place_name: 'Lake Boga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.463,
    longitude: 143.6326,
    accuracy: 4,
  },
  {
    postcode: 3585,
    place_name: 'Kunat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.5412,
    longitude: 143.4973,
    accuracy: 4,
  },
  {
    postcode: 3585,
    place_name: 'Goschen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4833,
    longitude: 143.4667,
    accuracy: 4,
  },
  {
    postcode: 3585,
    place_name: 'Nowie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2435,
    longitude: 143.3554,
    accuracy: 4,
  },
  {
    postcode: 3585,
    place_name: 'Winlaton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3964,
    longitude: 143.4089,
    accuracy: 3,
  },
  {
    postcode: 3585,
    place_name: 'Chillingollah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.25,
    longitude: 143.05,
    accuracy: 4,
  },
  {
    postcode: 3585,
    place_name: 'Pira',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2558,
    longitude: 143.3676,
    accuracy: 4,
  },
  {
    postcode: 3585,
    place_name: 'Meatian',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.5715,
    longitude: 143.3427,
    accuracy: 4,
  },
  {
    postcode: 3585,
    place_name: 'Speewa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3964,
    longitude: 143.4089,
    accuracy: 3,
  },
  {
    postcode: 3585,
    place_name: 'Fish Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4354,
    longitude: 143.7268,
    accuracy: 4,
  },
  {
    postcode: 3585,
    place_name: 'Swan Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3378,
    longitude: 143.5544,
    accuracy: 4,
  },
  {
    postcode: 3585,
    place_name: 'Nyrraby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3964,
    longitude: 143.4089,
    accuracy: 3,
  },
  {
    postcode: 3585,
    place_name: 'Castle Donnington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3964,
    longitude: 143.4089,
    accuracy: 3,
  },
  {
    postcode: 3585,
    place_name: 'Polisbet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3964,
    longitude: 143.4089,
    accuracy: 3,
  },
  {
    postcode: 3585,
    place_name: 'Swan Hill West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3964,
    longitude: 143.4089,
    accuracy: 3,
  },
  {
    postcode: 3586,
    place_name: 'Murrawee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3167,
    longitude: 143.5333,
    accuracy: 4,
  },
  {
    postcode: 3586,
    place_name: 'Pental Island',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.4034,
    longitude: 143.6912,
    accuracy: 4,
  },
  {
    postcode: 3586,
    place_name: 'Tyntynder South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2788,
    longitude: 143.5356,
    accuracy: 4,
  },
  {
    postcode: 3586,
    place_name: 'Bulga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3134,
    longitude: 143.5561,
    accuracy: 3,
  },
  {
    postcode: 3586,
    place_name: 'Tyntynder',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2333,
    longitude: 143.4667,
    accuracy: 4,
  },
  {
    postcode: 3586,
    place_name: 'Murraydale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.3134,
    longitude: 143.5561,
    accuracy: 3,
  },
  {
    postcode: 3588,
    place_name: 'Woorinen South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2911,
    longitude: 143.4551,
    accuracy: 1,
  },
  {
    postcode: 3589,
    place_name: 'Woorinen North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.255,
    longitude: 143.4593,
    accuracy: 4,
  },
  {
    postcode: 3589,
    place_name: 'Woorinen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2925,
    longitude: 143.4542,
    accuracy: 4,
  },
  {
    postcode: 3590,
    place_name: 'Beverford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2238,
    longitude: 143.4727,
    accuracy: 4,
  },
  {
    postcode: 3591,
    place_name: 'Vinifera',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.2393,
    longitude: 143.3195,
    accuracy: 1,
  },
  {
    postcode: 3594,
    place_name: 'Nyah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1755,
    longitude: 143.3774,
    accuracy: 4,
  },
  {
    postcode: 3595,
    place_name: 'Nyah West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1854,
    longitude: 143.3508,
    accuracy: 4,
  },
  {
    postcode: 3596,
    place_name: 'Miralie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1167,
    longitude: 143.2833,
    accuracy: 3,
  },
  {
    postcode: 3596,
    place_name: 'Wood Wood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1037,
    longitude: 143.3383,
    accuracy: 4,
  },
  {
    postcode: 3596,
    place_name: 'Towan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.1167,
    longitude: 143.2333,
    accuracy: 4,
  },
  {
    postcode: 3597,
    place_name: 'Narrung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.774,
    longitude: 143.2472,
    accuracy: 4,
  },
  {
    postcode: 3597,
    place_name: 'Kooloonong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.8774,
    longitude: 143.1459,
    accuracy: 4,
  },
  {
    postcode: 3597,
    place_name: 'Kenley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.9083,
    longitude: 143.2167,
    accuracy: 3,
  },
  {
    postcode: 3597,
    place_name: 'Natya',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.9567,
    longitude: 143.2252,
    accuracy: 4,
  },
  {
    postcode: 3597,
    place_name: 'Piangil',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.0585,
    longitude: 143.3063,
    accuracy: 4,
  },
  {
    postcode: 3597,
    place_name: 'Lake Powell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.9083,
    longitude: 143.2167,
    accuracy: 3,
  },
  {
    postcode: 3599,
    place_name: 'Boundary Bend',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -34.7213,
    longitude: 143.1648,
    accuracy: 4,
  },
  {
    postcode: 3607,
    place_name: 'Tabilk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 145.1667,
    accuracy: 4,
  },
  {
    postcode: 3608,
    place_name: 'Kirwans Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7741,
    longitude: 145.0958,
    accuracy: 3,
  },
  {
    postcode: 3608,
    place_name: 'Bailieston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 145.05,
    accuracy: 4,
  },
  {
    postcode: 3608,
    place_name: 'Graytown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8167,
    longitude: 144.95,
    accuracy: 4,
  },
  {
    postcode: 3608,
    place_name: 'Nagambie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7851,
    longitude: 145.1537,
    accuracy: 4,
  },
  {
    postcode: 3608,
    place_name: 'Goulburn Weir',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7167,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3608,
    place_name: 'Mitchellstown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3608,
    place_name: 'Wahring',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7167,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 3608,
    place_name: 'Wirrate',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 145.05,
    accuracy: 4,
  },
  {
    postcode: 3610,
    place_name: 'Murchison North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.596,
    longitude: 145.2493,
    accuracy: 3,
  },
  {
    postcode: 3610,
    place_name: 'Moorilim',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6167,
    longitude: 145.3167,
    accuracy: 4,
  },
  {
    postcode: 3610,
    place_name: 'Dhurringile',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5319,
    longitude: 145.2132,
    accuracy: 4,
  },
  {
    postcode: 3610,
    place_name: 'Murchison',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6189,
    longitude: 145.2172,
    accuracy: 4,
  },
  {
    postcode: 3610,
    place_name: 'Murchison East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6167,
    longitude: 145.25,
    accuracy: 4,
  },
  {
    postcode: 3612,
    place_name: 'Wanalta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6,
    longitude: 144.8833,
    accuracy: 4,
  },
  {
    postcode: 3612,
    place_name: 'Moora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6099,
    longitude: 144.9369,
    accuracy: 3,
  },
  {
    postcode: 3612,
    place_name: 'Rushworth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5867,
    longitude: 145.0175,
    accuracy: 4,
  },
  {
    postcode: 3612,
    place_name: 'Whroo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 3612,
    place_name: 'Waranga Shores',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6122,
    longitude: 144.9725,
    accuracy: 3,
  },
  {
    postcode: 3614,
    place_name: 'Toolamba',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3614,
    place_name: 'Toolamba West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4333,
    longitude: 145.2833,
    accuracy: 4,
  },
  {
    postcode: 3616,
    place_name: 'Waranga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3616,
    place_name: 'Girgarre East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4333,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3616,
    place_name: 'Tatura',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4396,
    longitude: 145.2299,
    accuracy: 4,
  },
  {
    postcode: 3616,
    place_name: 'Harston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4667,
    longitude: 145.15,
    accuracy: 4,
  },
  {
    postcode: 3616,
    place_name: 'Tatura East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4455,
    longitude: 145.1411,
    accuracy: 3,
  },
  {
    postcode: 3616,
    place_name: 'Gillieston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3167,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 3616,
    place_name: 'Cooma',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3616,
    place_name: 'Mooroopna North West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4455,
    longitude: 145.1411,
    accuracy: 3,
  },
  {
    postcode: 3617,
    place_name: 'Byrneside',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3618,
    place_name: 'Merrigum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3724,
    longitude: 145.1322,
    accuracy: 4,
  },
  {
    postcode: 3619,
    place_name: 'Kyabram',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3134,
    longitude: 145.0504,
    accuracy: 4,
  },
  {
    postcode: 3620,
    place_name: 'St Germains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 145.1667,
    accuracy: 4,
  },
  {
    postcode: 3620,
    place_name: 'Wyuna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1968,
    longitude: 145.0589,
    accuracy: 4,
  },
  {
    postcode: 3620,
    place_name: 'Kyabram South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3048,
    longitude: 145.0719,
    accuracy: 3,
  },
  {
    postcode: 3620,
    place_name: 'Lancaster',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3167,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 3620,
    place_name: 'Wyuna East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1968,
    longitude: 145.0589,
    accuracy: 4,
  },
  {
    postcode: 3620,
    place_name: 'Kyabram',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3134,
    longitude: 145.0504,
    accuracy: 4,
  },
  {
    postcode: 3621,
    place_name: 'Kyvalley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2833,
    longitude: 145,
    accuracy: 4,
  },
  {
    postcode: 3621,
    place_name: 'Koyuga South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.208,
    longitude: 144.9829,
    accuracy: 3,
  },
  {
    postcode: 3621,
    place_name: 'Tongala',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2547,
    longitude: 144.9562,
    accuracy: 4,
  },
  {
    postcode: 3621,
    place_name: 'Yambuna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1343,
    longitude: 145.036,
    accuracy: 4,
  },
  {
    postcode: 3622,
    place_name: 'Koyuga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2207,
    longitude: 144.8906,
    accuracy: 4,
  },
  {
    postcode: 3622,
    place_name: 'Strathallan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 144.75,
    accuracy: 4,
  },
  {
    postcode: 3623,
    place_name: 'Stanhope',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4472,
    longitude: 144.9827,
    accuracy: 4,
  },
  {
    postcode: 3623,
    place_name: 'Carag Carag',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4667,
    longitude: 144.9167,
    accuracy: 4,
  },
  {
    postcode: 3623,
    place_name: 'Stanhope South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.457,
    longitude: 144.9497,
    accuracy: 3,
  },
  {
    postcode: 3624,
    place_name: 'Girgarre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3999,
    longitude: 144.9496,
    accuracy: 4,
  },
  {
    postcode: 3629,
    place_name: 'Mooroopna North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3333,
    longitude: 145.3,
    accuracy: 4,
  },
  {
    postcode: 3629,
    place_name: 'Mooroopna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3629,
    place_name: 'Ardmona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3833,
    longitude: 145.3,
    accuracy: 4,
  },
  {
    postcode: 3629,
    place_name: 'Undera',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2833,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 3629,
    place_name: 'Coomboona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2833,
    longitude: 145.2484,
    accuracy: 3,
  },
  {
    postcode: 3630,
    place_name: 'Shepparton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3805,
    longitude: 145.3987,
    accuracy: 4,
  },
  {
    postcode: 3630,
    place_name: 'Shepparton South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4283,
    longitude: 145.5142,
    accuracy: 3,
  },
  {
    postcode: 3630,
    place_name: 'Caniambo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4761,
    longitude: 145.6297,
    accuracy: 4,
  },
  {
    postcode: 3630,
    place_name: 'Benarch',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4681,
    longitude: 145.586,
    accuracy: 3,
  },
  {
    postcode: 3630,
    place_name: 'Colliver',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4681,
    longitude: 145.586,
    accuracy: 3,
  },
  {
    postcode: 3630,
    place_name: 'Branditt',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4681,
    longitude: 145.586,
    accuracy: 3,
  },
  {
    postcode: 3630,
    place_name: 'Dunkirk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4681,
    longitude: 145.586,
    accuracy: 3,
  },
  {
    postcode: 3631,
    place_name: 'Kialla East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Kialla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4473,
    longitude: 145.4142,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Orrvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 145.45,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Arcadia South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4425,
    longitude: 145.4837,
    accuracy: 3,
  },
  {
    postcode: 3631,
    place_name: 'Pine Lodge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 145.5,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Karramomus',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 145.4833,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Lemnos',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.35,
    longitude: 145.4667,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Tamleugh West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5667,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Grahamvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3484,
    longitude: 145.4362,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Kialla West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4645,
    longitude: 145.3878,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Arcadia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5654,
    longitude: 145.3319,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Shepparton North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4425,
    longitude: 145.4837,
    accuracy: 3,
  },
  {
    postcode: 3631,
    place_name: 'Cosgrove',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.35,
    longitude: 145.6667,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Cosgrove South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4,
    longitude: 145.6167,
    accuracy: 4,
  },
  {
    postcode: 3631,
    place_name: 'Shepparton East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4293,
    longitude: 145.4749,
    accuracy: 3,
  },
  {
    postcode: 3632,
    place_name: 'Shepparton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3805,
    longitude: 145.3987,
    accuracy: 4,
  },
  {
    postcode: 3633,
    place_name: 'Congupna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 145.4333,
    accuracy: 4,
  },
  {
    postcode: 3634,
    place_name: 'Invergordon South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2456,
    longitude: 145.4686,
    accuracy: 3,
  },
  {
    postcode: 3634,
    place_name: 'Tallygaroopna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 145.4333,
    accuracy: 4,
  },
  {
    postcode: 3634,
    place_name: 'Bunbartha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2167,
    longitude: 145.3167,
    accuracy: 4,
  },
  {
    postcode: 3634,
    place_name: 'Katandra West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2241,
    longitude: 145.5583,
    accuracy: 4,
  },
  {
    postcode: 3634,
    place_name: 'Zeerust',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2248,
    longitude: 145.4883,
    accuracy: 3,
  },
  {
    postcode: 3634,
    place_name: 'Katandra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2333,
    longitude: 145.6167,
    accuracy: 4,
  },
  {
    postcode: 3634,
    place_name: 'Marionvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2248,
    longitude: 145.4883,
    accuracy: 3,
  },
  {
    postcode: 3634,
    place_name: 'Marungi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1969,
    longitude: 145.536,
    accuracy: 4,
  },
  {
    postcode: 3635,
    place_name: 'Wunghnu',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1526,
    longitude: 145.431,
    accuracy: 4,
  },
  {
    postcode: 3635,
    place_name: 'Kaarimba',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1333,
    longitude: 145.25,
    accuracy: 4,
  },
  {
    postcode: 3635,
    place_name: 'Mundoona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1545,
    longitude: 145.3298,
    accuracy: 4,
  },
  {
    postcode: 3636,
    place_name: 'Naring',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1295,
    longitude: 145.5085,
    accuracy: 3,
  },
  {
    postcode: 3636,
    place_name: 'Numurkah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0885,
    longitude: 145.4422,
    accuracy: 4,
  },
  {
    postcode: 3636,
    place_name: 'Drumanure',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1312,
    longitude: 145.5057,
    accuracy: 4,
  },
  {
    postcode: 3636,
    place_name: 'Invergordon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1667,
    longitude: 145.5833,
    accuracy: 4,
  },
  {
    postcode: 3637,
    place_name: 'Yalca',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9833,
    longitude: 145.3167,
    accuracy: 4,
  },
  {
    postcode: 3637,
    place_name: 'Waaia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0475,
    longitude: 145.3334,
    accuracy: 4,
  },
  {
    postcode: 3638,
    place_name: 'Nathalia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0577,
    longitude: 145.2041,
    accuracy: 4,
  },
  {
    postcode: 3638,
    place_name: 'Yielima',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9333,
    longitude: 145.2167,
    accuracy: 3,
  },
  {
    postcode: 3638,
    place_name: 'Kotupna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1475,
    longitude: 145.1337,
    accuracy: 4,
  },
  {
    postcode: 3639,
    place_name: 'Barmah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0159,
    longitude: 144.9628,
    accuracy: 4,
  },
  {
    postcode: 3639,
    place_name: 'Picola West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0333,
    longitude: 145.0278,
    accuracy: 3,
  },
  {
    postcode: 3639,
    place_name: 'Lower Moira',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0855,
    longitude: 144.9763,
    accuracy: 4,
  },
  {
    postcode: 3639,
    place_name: 'Picola',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9969,
    longitude: 145.1193,
    accuracy: 4,
  },
  {
    postcode: 3640,
    place_name: 'Katunga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0133,
    longitude: 145.4695,
    accuracy: 4,
  },
  {
    postcode: 3641,
    place_name: 'Bearii',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8857,
    longitude: 145.3453,
    accuracy: 4,
  },
  {
    postcode: 3641,
    place_name: 'Strathmerton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9253,
    longitude: 145.4787,
    accuracy: 4,
  },
  {
    postcode: 3641,
    place_name: 'Ulupna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8833,
    longitude: 145.4,
    accuracy: 4,
  },
  {
    postcode: 3641,
    place_name: 'Mywee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.8566,
    longitude: 145.4976,
    accuracy: 4,
  },
  {
    postcode: 3643,
    place_name: 'Cobram',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9207,
    longitude: 145.6407,
    accuracy: 4,
  },
  {
    postcode: 3644,
    place_name: 'Koonoomoo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9503,
    longitude: 145.6546,
    accuracy: 3,
  },
  {
    postcode: 3644,
    place_name: 'Cobram',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9207,
    longitude: 145.6407,
    accuracy: 4,
  },
  {
    postcode: 3644,
    place_name: 'Muckatah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0198,
    longitude: 145.6478,
    accuracy: 4,
  },
  {
    postcode: 3644,
    place_name: 'Yarroweyah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9241,
    longitude: 145.5572,
    accuracy: 4,
  },
  {
    postcode: 3644,
    place_name: 'Cobram East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9746,
    longitude: 145.7364,
    accuracy: 4,
  },
  {
    postcode: 3646,
    place_name: 'Nalinga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 3646,
    place_name: 'Dookie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3273,
    longitude: 145.6897,
    accuracy: 4,
  },
  {
    postcode: 3646,
    place_name: 'Mount Major',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3606,
    longitude: 145.6914,
    accuracy: 4,
  },
  {
    postcode: 3646,
    place_name: 'Yabba South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2755,
    longitude: 145.7146,
    accuracy: 3,
  },
  {
    postcode: 3646,
    place_name: 'Waggarandall',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 145.8,
    accuracy: 4,
  },
  {
    postcode: 3646,
    place_name: 'Youanmite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1625,
    longitude: 145.6899,
    accuracy: 4,
  },
  {
    postcode: 3646,
    place_name: 'Yabba North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2167,
    longitude: 145.6833,
    accuracy: 4,
  },
  {
    postcode: 3647,
    place_name: 'Dookie College',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3957,
    longitude: 145.7047,
    accuracy: 4,
  },
  {
    postcode: 3649,
    place_name: 'Katamatite East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0759,
    longitude: 145.6872,
    accuracy: 3,
  },
  {
    postcode: 3649,
    place_name: 'Katamatite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0759,
    longitude: 145.6872,
    accuracy: 4,
  },
  {
    postcode: 3658,
    place_name: 'Waterford Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3002,
    longitude: 145.0668,
    accuracy: 4,
  },
  {
    postcode: 3658,
    place_name: 'Clonbinane',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.292,
    longitude: 145.1406,
    accuracy: 3,
  },
  {
    postcode: 3658,
    place_name: 'Tyaak',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2167,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 3658,
    place_name: 'Strath Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2333,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 3658,
    place_name: 'Broadford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2028,
    longitude: 145.0484,
    accuracy: 4,
  },
  {
    postcode: 3658,
    place_name: 'Sugarloaf Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2602,
    longitude: 145.1482,
    accuracy: 3,
  },
  {
    postcode: 3658,
    place_name: 'Reedy Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2667,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 3658,
    place_name: 'Sunday Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2602,
    longitude: 145.1482,
    accuracy: 3,
  },
  {
    postcode: 3658,
    place_name: 'Hazeldene',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3413,
    longitude: 145.2908,
    accuracy: 4,
  },
  {
    postcode: 3659,
    place_name: 'Tallarook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0937,
    longitude: 145.1,
    accuracy: 4,
  },
  {
    postcode: 3660,
    place_name: 'Northwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9667,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3660,
    place_name: 'Dropmore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0605,
    longitude: 145.2132,
    accuracy: 3,
  },
  {
    postcode: 3660,
    place_name: 'Seymour South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0605,
    longitude: 145.2132,
    accuracy: 3,
  },
  {
    postcode: 3660,
    place_name: 'Seymour',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0266,
    longitude: 145.1392,
    accuracy: 4,
  },
  {
    postcode: 3660,
    place_name: 'Whiteheads Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0468,
    longitude: 145.2864,
    accuracy: 4,
  },
  {
    postcode: 3660,
    place_name: 'Trawool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 145.2167,
    accuracy: 4,
  },
  {
    postcode: 3660,
    place_name: 'Caveat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0605,
    longitude: 145.2132,
    accuracy: 3,
  },
  {
    postcode: 3660,
    place_name: 'Kerrisdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 145.2667,
    accuracy: 4,
  },
  {
    postcode: 3660,
    place_name: 'Highlands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 145.4,
    accuracy: 4,
  },
  {
    postcode: 3660,
    place_name: 'Hilldene',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0333,
    longitude: 145.0667,
    accuracy: 4,
  },
  {
    postcode: 3661,
    place_name: 'Seymour',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0266,
    longitude: 145.1392,
    accuracy: 4,
  },
  {
    postcode: 3662,
    place_name: 'Puckapunyal Milpo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9604,
    longitude: 145.0527,
    accuracy: 3,
  },
  {
    postcode: 3662,
    place_name: 'Puckapunyal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9949,
    longitude: 145.0401,
    accuracy: 4,
  },
  {
    postcode: 3663,
    place_name: 'Mangalore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3664,
    place_name: 'Upton Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9009,
    longitude: 145.2337,
    accuracy: 3,
  },
  {
    postcode: 3664,
    place_name: 'Avenel',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9009,
    longitude: 145.2337,
    accuracy: 4,
  },
  {
    postcode: 3665,
    place_name: 'Longwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 145.4167,
    accuracy: 4,
  },
  {
    postcode: 3665,
    place_name: 'Locksley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8333,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Miepoll',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6167,
    longitude: 145.4667,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Strathbogie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 145.75,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Creightons Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7804,
    longitude: 145.5403,
    accuracy: 3,
  },
  {
    postcode: 3666,
    place_name: 'Gooram',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9,
    longitude: 145.5833,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Kelvin View',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8167,
    longitude: 145.6667,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Ruffy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9833,
    longitude: 145.5,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Kithbrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7574,
    longitude: 145.5632,
    accuracy: 3,
  },
  {
    postcode: 3666,
    place_name: 'Creighton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 145.5,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Balmattum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7,
    longitude: 145.65,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Pranjip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 145.3667,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Euroa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7555,
    longitude: 145.5708,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Moglonemby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Molka',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6833,
    longitude: 145.45,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Tarcombe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9833,
    longitude: 145.4,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Longwood East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8167,
    longitude: 145.45,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Riggs Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 145.5667,
    accuracy: 4,
  },
  {
    postcode: 3666,
    place_name: 'Sheans Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 145.65,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Earlston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.55,
    longitude: 145.6667,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Boho',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7167,
    longitude: 145.8333,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Tamleugh North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5,
    longitude: 145.55,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Koonda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5,
    longitude: 145.7333,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Marraweeney',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.75,
    longitude: 145.75,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Violet Town',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6361,
    longitude: 145.7151,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Upotipotpon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4833,
    longitude: 145.8167,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Boho South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 145.8,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Gowangardie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4833,
    longitude: 145.7,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Tamleugh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6,
    longitude: 145.6333,
    accuracy: 4,
  },
  {
    postcode: 3669,
    place_name: 'Creek Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 147.7167,
    accuracy: 4,
  },
  {
    postcode: 3670,
    place_name: 'Tarnook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6333,
    longitude: 145.8667,
    accuracy: 3,
  },
  {
    postcode: 3670,
    place_name: 'Warrenbayne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6833,
    longitude: 145.8667,
    accuracy: 4,
  },
  {
    postcode: 3670,
    place_name: 'Baddaginnie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5833,
    longitude: 145.8667,
    accuracy: 4,
  },
  {
    postcode: 3671,
    place_name: 'Benalla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5511,
    longitude: 145.9843,
    accuracy: 4,
  },
  {
    postcode: 3672,
    place_name: 'Benalla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5511,
    longitude: 145.9843,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Goomalibee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4833,
    longitude: 145.9167,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Upper Ryans Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6418,
    longitude: 146.0718,
    accuracy: 3,
  },
  {
    postcode: 3673,
    place_name: 'Tatong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7333,
    longitude: 146.1,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Swanpool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7667,
    longitude: 146.0167,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Samaria',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 146.0833,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Upper Lurg',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5833,
    longitude: 146.1833,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Moorngag',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.75,
    longitude: 146.0667,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Winton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5167,
    longitude: 146.0833,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Benalla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5511,
    longitude: 145.9843,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Broken Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6418,
    longitude: 146.0718,
    accuracy: 3,
  },
  {
    postcode: 3673,
    place_name: 'Molyullah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 146.15,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Winton North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6418,
    longitude: 146.0718,
    accuracy: 3,
  },
  {
    postcode: 3673,
    place_name: 'Lurg',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5833,
    longitude: 146.1333,
    accuracy: 4,
  },
  {
    postcode: 3673,
    place_name: 'Lima East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6418,
    longitude: 146.0718,
    accuracy: 3,
  },
  {
    postcode: 3673,
    place_name: 'Lima',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.702,
    longitude: 146.0416,
    accuracy: 3,
  },
  {
    postcode: 3673,
    place_name: 'Lima South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6418,
    longitude: 146.0718,
    accuracy: 3,
  },
  {
    postcode: 3675,
    place_name: 'Boweya',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 146.1333,
    accuracy: 4,
  },
  {
    postcode: 3675,
    place_name: 'Greta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5833,
    longitude: 146.2833,
    accuracy: 4,
  },
  {
    postcode: 3675,
    place_name: 'Taminick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 146.1667,
    accuracy: 4,
  },
  {
    postcode: 3675,
    place_name: 'Mount Bruno',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.46,
    longitude: 146.2072,
    accuracy: 3,
  },
  {
    postcode: 3675,
    place_name: 'Greta West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 146.2167,
    accuracy: 4,
  },
  {
    postcode: 3675,
    place_name: 'Glenrowan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4635,
    longitude: 146.2245,
    accuracy: 4,
  },
  {
    postcode: 3675,
    place_name: 'Glenrowan West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.46,
    longitude: 146.2072,
    accuracy: 3,
  },
  {
    postcode: 3675,
    place_name: 'Boweya North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2047,
    longitude: 146.0685,
    accuracy: 4,
  },
  {
    postcode: 3675,
    place_name: 'Greta South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6167,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 3675,
    place_name: 'Hansonville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6,
    longitude: 146.3,
    accuracy: 4,
  },
  {
    postcode: 3676,
    place_name: 'Wangaratta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3585,
    longitude: 146.3206,
    accuracy: 4,
  },
  {
    postcode: 3677,
    place_name: 'Yarrunga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3639,
    longitude: 146.3103,
    accuracy: 3,
  },
  {
    postcode: 3677,
    place_name: 'Wangaratta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3585,
    longitude: 146.3206,
    accuracy: 4,
  },
  {
    postcode: 3677,
    place_name: 'Appin Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3639,
    longitude: 146.3103,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Oxley Flats',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3954,
    longitude: 146.3852,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Rose River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Edi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.65,
    longitude: 146.4071,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Meadow Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Waldara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3199,
    longitude: 146.2844,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Everton Upper',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Tarrawingee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4,
    longitude: 146.4667,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Boorhaman',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2333,
    longitude: 146.3,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Killawarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Wangaratta Forward',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Peechelba',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1947,
    longitude: 146.2299,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Wangandary',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Bobinawarrah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5167,
    longitude: 146.5,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Wangaratta South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'North Wangaratta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'King Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7,
    longitude: 146.4333,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Edi Upper',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7,
    longitude: 146.45,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Boorhaman East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Docker',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5167,
    longitude: 146.3833,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Peechelba East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1692,
    longitude: 146.2544,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Markwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.473,
    longitude: 146.5088,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Dockers Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Oxley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 146.3667,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Wabonga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Byawatha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Cheshunt South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Londrigan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3333,
    longitude: 146.4167,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Bowser',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Everton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 146.55,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Whitlands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4375,
    longitude: 146.3894,
    accuracy: 3,
  },
  {
    postcode: 3678,
    place_name: 'Cheshunt',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 146.4333,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'East Wangaratta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1692,
    longitude: 146.2544,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Laceby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4333,
    longitude: 146.3,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Carboor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6333,
    longitude: 146.55,
    accuracy: 4,
  },
  {
    postcode: 3678,
    place_name: 'Milawa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4333,
    longitude: 146.4333,
    accuracy: 4,
  },
  {
    postcode: 3682,
    place_name: 'Norong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1167,
    longitude: 146.4,
    accuracy: 4,
  },
  {
    postcode: 3682,
    place_name: 'Boralma',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2167,
    longitude: 146.4167,
    accuracy: 4,
  },
  {
    postcode: 3682,
    place_name: 'Springhurst',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2,
    longitude: 146.5,
    accuracy: 4,
  },
  {
    postcode: 3682,
    place_name: 'Lilliput',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1131,
    longitude: 146.4751,
    accuracy: 4,
  },
  {
    postcode: 3683,
    place_name: 'Chiltern Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1399,
    longitude: 146.5408,
    accuracy: 4,
  },
  {
    postcode: 3683,
    place_name: 'Cornishtown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1419,
    longitude: 146.5709,
    accuracy: 3,
  },
  {
    postcode: 3683,
    place_name: 'Chiltern',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1504,
    longitude: 146.6084,
    accuracy: 4,
  },
  {
    postcode: 3685,
    place_name: 'Prentice North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0333,
    longitude: 146.5,
    accuracy: 4,
  },
  {
    postcode: 3685,
    place_name: 'Boorhaman North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.045,
    longitude: 146.5117,
    accuracy: 3,
  },
  {
    postcode: 3685,
    place_name: 'Browns Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0472,
    longitude: 146.6387,
    accuracy: 4,
  },
  {
    postcode: 3685,
    place_name: 'Brimin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0513,
    longitude: 146.2249,
    accuracy: 4,
  },
  {
    postcode: 3685,
    place_name: 'Rutherglen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0543,
    longitude: 146.4621,
    accuracy: 4,
  },
  {
    postcode: 3685,
    place_name: 'Gooramadda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36,
    longitude: 146.55,
    accuracy: 4,
  },
  {
    postcode: 3685,
    place_name: 'Great Southern',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0667,
    longitude: 146.4833,
    accuracy: 4,
  },
  {
    postcode: 3685,
    place_name: 'Carlyle',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0427,
    longitude: 146.4824,
    accuracy: 3,
  },
  {
    postcode: 3687,
    place_name: 'Wahgunyah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0118,
    longitude: 146.3971,
    accuracy: 4,
  },
  {
    postcode: 3688,
    place_name: 'Indigo Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1399,
    longitude: 146.5408,
    accuracy: 4,
  },
  {
    postcode: 3688,
    place_name: 'Barnawartha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1041,
    longitude: 146.6761,
    accuracy: 4,
  },
  {
    postcode: 3689,
    place_name: 'Wodonga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1218,
    longitude: 146.8881,
    accuracy: 4,
  },
  {
    postcode: 3690,
    place_name: 'Wodonga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1218,
    longitude: 146.8881,
    accuracy: 4,
  },
  {
    postcode: 3690,
    place_name: 'Wodonga Plaza',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0997,
    longitude: 146.802,
    accuracy: 3,
  },
  {
    postcode: 3690,
    place_name: 'West Wodonga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1218,
    longitude: 146.8881,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Killara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1396,
    longitude: 146.9514,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Castle Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2843,
    longitude: 147.0692,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Upper Gundowring',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4833,
    longitude: 147.0667,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Bandiana',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1479,
    longitude: 146.9262,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Bethanga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1167,
    longitude: 147.1,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Baranduda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1911,
    longitude: 146.9538,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Allans Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 146.9,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Mount Alfred',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2959,
    longitude: 147.0534,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Bellbridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0974,
    longitude: 147.0617,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Kergunyah South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2843,
    longitude: 147.0692,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Berringama',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2167,
    longitude: 147.65,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Coral Bank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2843,
    longitude: 147.0692,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Talgarno',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0833,
    longitude: 147.1667,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Kergunyah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3833,
    longitude: 147.05,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Gundowring',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4333,
    longitude: 147.05,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Lone Pine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2959,
    longitude: 147.0534,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Glen Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 146.95,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Tangambalanga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2522,
    longitude: 147.0286,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Dederang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4833,
    longitude: 147.0167,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Wodonga Forward',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2843,
    longitude: 147.0692,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Hume Weir',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1022,
    longitude: 147.0373,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Bonegilla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1403,
    longitude: 147.0062,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Kancoona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5833,
    longitude: 146.9833,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Thologolong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2843,
    longitude: 147.0692,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Running Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6167,
    longitude: 146.9167,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Kiewa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 147.0167,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Bungil',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0667,
    longitude: 147.35,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Gateway Island',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2843,
    longitude: 147.0692,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Lucyvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2833,
    longitude: 147.6167,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Osbornes Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3167,
    longitude: 146.8833,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Leneva',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2167,
    longitude: 146.9,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Barnawartha North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2843,
    longitude: 147.0692,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Mongans Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6,
    longitude: 147.1,
    accuracy: 4,
  },
  {
    postcode: 3691,
    place_name: 'Huon Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2843,
    longitude: 147.0692,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Staghorn Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2522,
    longitude: 146.9228,
    accuracy: 3,
  },
  {
    postcode: 3691,
    place_name: 'Ebden',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.153,
    longitude: 147.0252,
    accuracy: 4,
  },
  {
    postcode: 3694,
    place_name: 'Bandiana Milpo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1454,
    longitude: 146.9188,
    accuracy: 1,
  },
  {
    postcode: 3695,
    place_name: 'Huon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2333,
    longitude: 147.0667,
    accuracy: 4,
  },
  {
    postcode: 3695,
    place_name: 'Charleroi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.332,
    longitude: 147.1146,
    accuracy: 3,
  },
  {
    postcode: 3695,
    place_name: 'Sandy Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 147.1,
    accuracy: 4,
  },
  {
    postcode: 3695,
    place_name: 'Red Bluff',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 147.1,
    accuracy: 4,
  },
  {
    postcode: 3697,
    place_name: 'Tawonga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6895,
    longitude: 147.1421,
    accuracy: 4,
  },
  {
    postcode: 3698,
    place_name: 'Tawonga South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7359,
    longitude: 147.1521,
    accuracy: 1,
  },
  {
    postcode: 3699,
    place_name: 'Mount Beauty',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7363,
    longitude: 147.1753,
    accuracy: 4,
  },
  {
    postcode: 3699,
    place_name: 'Nelse',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8005,
    longitude: 147.2233,
    accuracy: 3,
  },
  {
    postcode: 3699,
    place_name: 'Falls Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8653,
    longitude: 147.2778,
    accuracy: 4,
  },
  {
    postcode: 3699,
    place_name: 'Bogong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8047,
    longitude: 147.2237,
    accuracy: 4,
  },
  {
    postcode: 3700,
    place_name: 'Georges Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1833,
    longitude: 147.2833,
    accuracy: 4,
  },
  {
    postcode: 3700,
    place_name: 'Tallangatta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2179,
    longitude: 147.177,
    accuracy: 4,
  },
  {
    postcode: 3700,
    place_name: 'Jarvis Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2004,
    longitude: 147.2646,
    accuracy: 3,
  },
  {
    postcode: 3700,
    place_name: 'Bullioh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2,
    longitude: 147.3333,
    accuracy: 4,
  },
  {
    postcode: 3700,
    place_name: 'Tallangatta East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2004,
    longitude: 147.2646,
    accuracy: 3,
  },
  {
    postcode: 3701,
    place_name: 'Tallandoon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 147.2,
    accuracy: 4,
  },
  {
    postcode: 3701,
    place_name: 'Shelley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4158,
    longitude: 147.4436,
    accuracy: 3,
  },
  {
    postcode: 3701,
    place_name: 'Old Tallangatta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1929,
    longitude: 147.2521,
    accuracy: 4,
  },
  {
    postcode: 3701,
    place_name: 'Tallangatta South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3737,
    longitude: 147.3266,
    accuracy: 3,
  },
  {
    postcode: 3701,
    place_name: 'Mitta Mitta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.55,
    longitude: 147.3833,
    accuracy: 4,
  },
  {
    postcode: 3701,
    place_name: 'Eskdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4667,
    longitude: 147.2333,
    accuracy: 4,
  },
  {
    postcode: 3701,
    place_name: 'Tallangatta Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2936,
    longitude: 147.389,
    accuracy: 4,
  },
  {
    postcode: 3701,
    place_name: 'Dartmouth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5462,
    longitude: 147.5116,
    accuracy: 4,
  },
  {
    postcode: 3701,
    place_name: 'Granya',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1167,
    longitude: 147.3167,
    accuracy: 4,
  },
  {
    postcode: 3704,
    place_name: 'Koetong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.15,
    longitude: 147.5,
    accuracy: 4,
  },
  {
    postcode: 3705,
    place_name: 'Nariel Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3112,
    longitude: 147.726,
    accuracy: 3,
  },
  {
    postcode: 3705,
    place_name: 'Cudgewa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2,
    longitude: 147.75,
    accuracy: 4,
  },
  {
    postcode: 3707,
    place_name: 'Biggara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 148.0333,
    accuracy: 4,
  },
  {
    postcode: 3707,
    place_name: 'Tom Groggin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5192,
    longitude: 148.127,
    accuracy: 4,
  },
  {
    postcode: 3707,
    place_name: 'Towong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1333,
    longitude: 147.9833,
    accuracy: 4,
  },
  {
    postcode: 3707,
    place_name: 'Corryong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.197,
    longitude: 147.9026,
    accuracy: 4,
  },
  {
    postcode: 3707,
    place_name: 'Thowgla Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4104,
    longitude: 147.9589,
    accuracy: 3,
  },
  {
    postcode: 3707,
    place_name: 'Colac Colac',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4104,
    longitude: 147.9589,
    accuracy: 3,
  },
  {
    postcode: 3707,
    place_name: 'Towong Upper',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2167,
    longitude: 148.0333,
    accuracy: 4,
  },
  {
    postcode: 3707,
    place_name: 'Nariel Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2034,
    longitude: 147.9881,
    accuracy: 3,
  },
  {
    postcode: 3708,
    place_name: 'Tintaldra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.05,
    longitude: 147.9333,
    accuracy: 4,
  },
  {
    postcode: 3709,
    place_name: 'Walwa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -35.9667,
    longitude: 147.7333,
    accuracy: 4,
  },
  {
    postcode: 3709,
    place_name: 'Mount Alfred',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0222,
    longitude: 147.6444,
    accuracy: 3,
  },
  {
    postcode: 3709,
    place_name: 'Burrowye',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0333,
    longitude: 147.55,
    accuracy: 4,
  },
  {
    postcode: 3709,
    place_name: 'Guys Forest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0667,
    longitude: 147.65,
    accuracy: 4,
  },
  {
    postcode: 3709,
    place_name: 'Pine Mountain',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0222,
    longitude: 147.6444,
    accuracy: 3,
  },
  {
    postcode: 3711,
    place_name: 'Buxton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 3712,
    place_name: 'Thornton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2558,
    longitude: 145.7964,
    accuracy: 4,
  },
  {
    postcode: 3712,
    place_name: 'Rubicon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.256,
    longitude: 145.7977,
    accuracy: 3,
  },
  {
    postcode: 3713,
    place_name: 'Eildon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2324,
    longitude: 145.9126,
    accuracy: 4,
  },
  {
    postcode: 3713,
    place_name: 'Lake Eildon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2324,
    longitude: 145.9126,
    accuracy: 3,
  },
  {
    postcode: 3713,
    place_name: 'Taylor Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2324,
    longitude: 145.9126,
    accuracy: 3,
  },
  {
    postcode: 3714,
    place_name: 'Alexandra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1913,
    longitude: 145.7112,
    accuracy: 4,
  },
  {
    postcode: 3714,
    place_name: 'Acheron',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 145.7,
    accuracy: 4,
  },
  {
    postcode: 3714,
    place_name: 'Cathkin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 145.5833,
    accuracy: 4,
  },
  {
    postcode: 3714,
    place_name: 'Maintongoon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2041,
    longitude: 145.6741,
    accuracy: 3,
  },
  {
    postcode: 3714,
    place_name: 'Koriella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1667,
    longitude: 145.65,
    accuracy: 4,
  },
  {
    postcode: 3714,
    place_name: 'Whanregarwen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2041,
    longitude: 145.6741,
    accuracy: 3,
  },
  {
    postcode: 3714,
    place_name: 'Fawcett',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 145.6833,
    accuracy: 4,
  },
  {
    postcode: 3714,
    place_name: 'Crystal Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2041,
    longitude: 145.6741,
    accuracy: 3,
  },
  {
    postcode: 3714,
    place_name: 'Taggerty',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 3714,
    place_name: 'Devils River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2041,
    longitude: 145.6741,
    accuracy: 3,
  },
  {
    postcode: 3715,
    place_name: 'Merton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9768,
    longitude: 145.7108,
    accuracy: 4,
  },
  {
    postcode: 3715,
    place_name: 'Ancona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9833,
    longitude: 145.8167,
    accuracy: 4,
  },
  {
    postcode: 3715,
    place_name: 'Woodfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 145.8,
    accuracy: 4,
  },
  {
    postcode: 3717,
    place_name: 'Flowerdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3717,
    place_name: 'Homewood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3717,
    place_name: 'Killingworth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2962,
    longitude: 145.424,
    accuracy: 3,
  },
  {
    postcode: 3717,
    place_name: 'Yea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2107,
    longitude: 145.4276,
    accuracy: 4,
  },
  {
    postcode: 3717,
    place_name: 'Limestone',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 145.5,
    accuracy: 4,
  },
  {
    postcode: 3717,
    place_name: 'Murrindindi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3717,
    place_name: 'Ghin Ghin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2962,
    longitude: 145.424,
    accuracy: 3,
  },
  {
    postcode: 3717,
    place_name: 'Glenburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 145.4167,
    accuracy: 4,
  },
  {
    postcode: 3718,
    place_name: 'Molesworth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1667,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3719,
    place_name: 'Yarck',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 145.6167,
    accuracy: 4,
  },
  {
    postcode: 3719,
    place_name: 'Gobur',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 145.6,
    accuracy: 4,
  },
  {
    postcode: 3719,
    place_name: 'Terip Terip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37,
    longitude: 145.5667,
    accuracy: 4,
  },
  {
    postcode: 3719,
    place_name: 'Kanumbra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.05,
    longitude: 145.6667,
    accuracy: 4,
  },
  {
    postcode: 3720,
    place_name: 'Bonnie Doon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 145.8667,
    accuracy: 4,
  },
  {
    postcode: 3722,
    place_name: 'Barwite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37,
    longitude: 146.1667,
    accuracy: 4,
  },
  {
    postcode: 3722,
    place_name: 'Mirimbah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1103,
    longitude: 146.3985,
    accuracy: 4,
  },
  {
    postcode: 3722,
    place_name: 'Mansfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.052,
    longitude: 146.0885,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Howes Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1667,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Howqua Inlet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.251,
    longitude: 146.1822,
    accuracy: 3,
  },
  {
    postcode: 3723,
    place_name: 'Gaffneys Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4667,
    longitude: 146.1833,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Enochs Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 146.1,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Archerton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8833,
    longitude: 146.2,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Sawmill Settlement',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.251,
    longitude: 146.1822,
    accuracy: 3,
  },
  {
    postcode: 3723,
    place_name: 'Knockwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.45,
    longitude: 146.2333,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Howqua',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2333,
    longitude: 146.1167,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Mount Buller',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.146,
    longitude: 146.4369,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Mountain Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.251,
    longitude: 146.1822,
    accuracy: 3,
  },
  {
    postcode: 3723,
    place_name: 'Goughs Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1767,
    longitude: 146.0635,
    accuracy: 3,
  },
  {
    postcode: 3723,
    place_name: 'Howqua Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2598,
    longitude: 146.4295,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Delatite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.15,
    longitude: 146.1333,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Kevington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3667,
    longitude: 146.15,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Piries',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 146.1333,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Matlock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 146.2167,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Merrijig',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Barjarg',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.251,
    longitude: 146.1822,
    accuracy: 3,
  },
  {
    postcode: 3723,
    place_name: 'Maindample',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0333,
    longitude: 145.9333,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Boorolite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.251,
    longitude: 146.1822,
    accuracy: 3,
  },
  {
    postcode: 3723,
    place_name: 'Woods Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Bridge Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.251,
    longitude: 146.1822,
    accuracy: 3,
  },
  {
    postcode: 3723,
    place_name: 'Macs Cove',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2125,
    longitude: 146.1464,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Jamieson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3167,
    longitude: 146.1333,
    accuracy: 4,
  },
  {
    postcode: 3723,
    place_name: 'Tolmie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9333,
    longitude: 146.2333,
    accuracy: 4,
  },
  {
    postcode: 3724,
    place_name: 'Mansfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.052,
    longitude: 146.0885,
    accuracy: 4,
  },
  {
    postcode: 3725,
    place_name: 'Goorambat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 145.9167,
    accuracy: 4,
  },
  {
    postcode: 3725,
    place_name: 'Chesney Vale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3833,
    longitude: 145.8292,
    accuracy: 3,
  },
  {
    postcode: 3725,
    place_name: 'Boxwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3167,
    longitude: 145.8,
    accuracy: 4,
  },
  {
    postcode: 3725,
    place_name: 'Major Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3667,
    longitude: 145.8,
    accuracy: 4,
  },
  {
    postcode: 3725,
    place_name: 'Stewarton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4333,
    longitude: 145.8,
    accuracy: 4,
  },
  {
    postcode: 3726,
    place_name: 'Bungeet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 146.05,
    accuracy: 4,
  },
  {
    postcode: 3726,
    place_name: 'Bungeet West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3167,
    longitude: 146.0056,
    accuracy: 3,
  },
  {
    postcode: 3726,
    place_name: 'Thoona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3167,
    longitude: 146.0667,
    accuracy: 4,
  },
  {
    postcode: 3726,
    place_name: 'Devenish',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3333,
    longitude: 145.9,
    accuracy: 4,
  },
  {
    postcode: 3727,
    place_name: 'Yundool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 145.8333,
    accuracy: 4,
  },
  {
    postcode: 3727,
    place_name: 'Almonds',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 3727,
    place_name: 'Lake Rowan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 3727,
    place_name: 'Pelluebla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.25,
    longitude: 145.9167,
    accuracy: 3,
  },
  {
    postcode: 3727,
    place_name: 'St James',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2667,
    longitude: 145.9,
    accuracy: 4,
  },
  {
    postcode: 3728,
    place_name: 'Tungamah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.161,
    longitude: 145.8768,
    accuracy: 4,
  },
  {
    postcode: 3728,
    place_name: 'Boomahnoomoonah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1265,
    longitude: 146.1321,
    accuracy: 4,
  },
  {
    postcode: 3728,
    place_name: 'Wilby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1574,
    longitude: 146.022,
    accuracy: 4,
  },
  {
    postcode: 3728,
    place_name: 'Youarang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.1767,
    longitude: 145.791,
    accuracy: 4,
  },
  {
    postcode: 3730,
    place_name: 'Burramine',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0639,
    longitude: 145.854,
    accuracy: 4,
  },
  {
    postcode: 3730,
    place_name: 'Yarrawonga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0192,
    longitude: 145.9997,
    accuracy: 4,
  },
  {
    postcode: 3730,
    place_name: 'Esmond',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0797,
    longitude: 146.1833,
    accuracy: 4,
  },
  {
    postcode: 3730,
    place_name: 'Boosey',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.05,
    longitude: 145.7833,
    accuracy: 4,
  },
  {
    postcode: 3730,
    place_name: 'Bathumi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0367,
    longitude: 146.0793,
    accuracy: 4,
  },
  {
    postcode: 3730,
    place_name: 'Bundalong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0298,
    longitude: 146.1581,
    accuracy: 4,
  },
  {
    postcode: 3730,
    place_name: 'Yarrawonga South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0521,
    longitude: 146.0092,
    accuracy: 3,
  },
  {
    postcode: 3730,
    place_name: 'Telford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0966,
    longitude: 145.9337,
    accuracy: 4,
  },
  {
    postcode: 3730,
    place_name: 'Burramine South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0639,
    longitude: 145.854,
    accuracy: 4,
  },
  {
    postcode: 3730,
    place_name: 'Bundalong South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.0955,
    longitude: 146.1876,
    accuracy: 4,
  },
  {
    postcode: 3732,
    place_name: 'Moyhu',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.578,
    longitude: 146.3784,
    accuracy: 4,
  },
  {
    postcode: 3732,
    place_name: 'Myrrhee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.75,
    longitude: 146.3167,
    accuracy: 4,
  },
  {
    postcode: 3733,
    place_name: 'Whitfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 146.4167,
    accuracy: 4,
  },
  {
    postcode: 3735,
    place_name: 'Whorouly South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.55,
    longitude: 146.5833,
    accuracy: 4,
  },
  {
    postcode: 3735,
    place_name: 'Whorouly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5167,
    longitude: 146.6,
    accuracy: 4,
  },
  {
    postcode: 3735,
    place_name: 'Bowmans Forest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5706,
    longitude: 146.5783,
    accuracy: 3,
  },
  {
    postcode: 3735,
    place_name: 'Whorouly East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5333,
    longitude: 146.5917,
    accuracy: 3,
  },
  {
    postcode: 3736,
    place_name: 'Myrtleford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.561,
    longitude: 146.7237,
    accuracy: 4,
  },
  {
    postcode: 3737,
    place_name: 'Buffalo River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6333,
    longitude: 146.6833,
    accuracy: 4,
  },
  {
    postcode: 3737,
    place_name: 'Abbeyard',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6267,
    longitude: 146.7254,
    accuracy: 3,
  },
  {
    postcode: 3737,
    place_name: 'Myrtleford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.561,
    longitude: 146.7237,
    accuracy: 4,
  },
  {
    postcode: 3737,
    place_name: 'Gapsted',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5167,
    longitude: 146.6833,
    accuracy: 4,
  },
  {
    postcode: 3737,
    place_name: 'Mudgegonga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4897,
    longitude: 146.8658,
    accuracy: 3,
  },
  {
    postcode: 3737,
    place_name: 'Nug Nug',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6656,
    longitude: 146.7178,
    accuracy: 4,
  },
  {
    postcode: 3737,
    place_name: 'Merriang South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6267,
    longitude: 146.7254,
    accuracy: 3,
  },
  {
    postcode: 3737,
    place_name: 'Havilah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6267,
    longitude: 146.7254,
    accuracy: 3,
  },
  {
    postcode: 3737,
    place_name: 'Wonnangatta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6267,
    longitude: 146.7254,
    accuracy: 3,
  },
  {
    postcode: 3737,
    place_name: 'Rosewhite',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5833,
    longitude: 146.8773,
    accuracy: 4,
  },
  {
    postcode: 3737,
    place_name: 'Merriang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6267,
    longitude: 146.7254,
    accuracy: 3,
  },
  {
    postcode: 3737,
    place_name: 'Barwidgee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6267,
    longitude: 146.7254,
    accuracy: 3,
  },
  {
    postcode: 3737,
    place_name: 'Selwyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6267,
    longitude: 146.7254,
    accuracy: 3,
  },
  {
    postcode: 3737,
    place_name: 'Dandongadale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8,
    longitude: 146.6667,
    accuracy: 4,
  },
  {
    postcode: 3738,
    place_name: 'Ovens',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.5874,
    longitude: 146.7593,
    accuracy: 4,
  },
  {
    postcode: 3739,
    place_name: 'Eurobin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6352,
    longitude: 146.8596,
    accuracy: 4,
  },
  {
    postcode: 3740,
    place_name: 'Mount Buffalo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7829,
    longitude: 146.8699,
    accuracy: 3,
  },
  {
    postcode: 3740,
    place_name: 'Porepunkah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.6961,
    longitude: 146.9104,
    accuracy: 4,
  },
  {
    postcode: 3740,
    place_name: 'Buckland',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8833,
    longitude: 146.85,
    accuracy: 4,
  },
  {
    postcode: 3741,
    place_name: 'Smoko',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8202,
    longitude: 147.0642,
    accuracy: 4,
  },
  {
    postcode: 3741,
    place_name: 'Mount Hotham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0712,
    longitude: 147.0949,
    accuracy: 3,
  },
  {
    postcode: 3741,
    place_name: 'Bright',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.73,
    longitude: 146.9598,
    accuracy: 4,
  },
  {
    postcode: 3741,
    place_name: 'Germantown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7427,
    longitude: 147.0153,
    accuracy: 4,
  },
  {
    postcode: 3741,
    place_name: 'Hotham Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.993,
    longitude: 147.1523,
    accuracy: 4,
  },
  {
    postcode: 3741,
    place_name: 'Harrietville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8915,
    longitude: 147.0619,
    accuracy: 4,
  },
  {
    postcode: 3741,
    place_name: 'Freeburgh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7599,
    longitude: 147.0263,
    accuracy: 4,
  },
  {
    postcode: 3744,
    place_name: 'Wandiligong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.7833,
    longitude: 146.9833,
    accuracy: 4,
  },
  {
    postcode: 3746,
    place_name: 'Eldorado',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3,
    longitude: 146.5167,
    accuracy: 4,
  },
  {
    postcode: 3747,
    place_name: 'Wooragee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.2833,
    longitude: 146.7833,
    accuracy: 4,
  },
  {
    postcode: 3747,
    place_name: 'Baarmutha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 146.6833,
    accuracy: 4,
  },
  {
    postcode: 3747,
    place_name: 'Murmungee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.45,
    longitude: 146.6833,
    accuracy: 4,
  },
  {
    postcode: 3747,
    place_name: 'Stanley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 146.75,
    accuracy: 4,
  },
  {
    postcode: 3747,
    place_name: 'Beechworth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3583,
    longitude: 146.6858,
    accuracy: 4,
  },
  {
    postcode: 3749,
    place_name: 'Bruarong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.4167,
    longitude: 146.85,
    accuracy: 4,
  },
  {
    postcode: 3749,
    place_name: 'Yackandandah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3137,
    longitude: 146.8396,
    accuracy: 4,
  },
  {
    postcode: 3750,
    place_name: 'Wollert',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 145.0333,
    accuracy: 4,
  },
  {
    postcode: 3751,
    place_name: 'Woodstock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.55,
    longitude: 145.0333,
    accuracy: 4,
  },
  {
    postcode: 3752,
    place_name: 'South Morang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.65,
    longitude: 145.1,
    accuracy: 4,
  },
  {
    postcode: 3753,
    place_name: 'Beveridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 144.9833,
    accuracy: 4,
  },
  {
    postcode: 3754,
    place_name: 'Mernda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6007,
    longitude: 145.0956,
    accuracy: 4,
  },
  {
    postcode: 3754,
    place_name: 'Doreen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 145.15,
    accuracy: 4,
  },
  {
    postcode: 3755,
    place_name: 'Yan Yean',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5667,
    longitude: 145.1,
    accuracy: 4,
  },
  {
    postcode: 3756,
    place_name: 'Hidden Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4369,
    longitude: 144.8946,
    accuracy: 3,
  },
  {
    postcode: 3756,
    place_name: 'Upper Plenty',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4197,
    longitude: 145.0547,
    accuracy: 4,
  },
  {
    postcode: 3756,
    place_name: 'Chintin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.418,
    longitude: 145.0166,
    accuracy: 3,
  },
  {
    postcode: 3756,
    place_name: 'Wallan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4162,
    longitude: 144.9786,
    accuracy: 4,
  },
  {
    postcode: 3756,
    place_name: 'Darraweit Guim',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4369,
    longitude: 144.8946,
    accuracy: 3,
  },
  {
    postcode: 3757,
    place_name: 'Kinglake Central',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5,
    longitude: 145.3,
    accuracy: 4,
  },
  {
    postcode: 3757,
    place_name: 'Pheasant Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 145.2833,
    accuracy: 4,
  },
  {
    postcode: 3757,
    place_name: 'Humevale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5,
    longitude: 145.1667,
    accuracy: 4,
  },
  {
    postcode: 3757,
    place_name: 'Kinglake West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4667,
    longitude: 145.2333,
    accuracy: 4,
  },
  {
    postcode: 3757,
    place_name: 'Whittlesea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5115,
    longitude: 145.1184,
    accuracy: 4,
  },
  {
    postcode: 3757,
    place_name: 'Eden Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 145.0833,
    accuracy: 4,
  },
  {
    postcode: 3758,
    place_name: 'Wandong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3719,
    longitude: 145.0321,
    accuracy: 4,
  },
  {
    postcode: 3758,
    place_name: 'Heathcote Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3719,
    longitude: 145.0321,
    accuracy: 4,
  },
  {
    postcode: 3759,
    place_name: 'Panton Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6408,
    longitude: 145.2399,
    accuracy: 4,
  },
  {
    postcode: 3760,
    place_name: 'Smiths Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6202,
    longitude: 145.2603,
    accuracy: 4,
  },
  {
    postcode: 3761,
    place_name: 'St Andrews',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5782,
    longitude: 145.3074,
    accuracy: 1,
  },
  {
    postcode: 3762,
    place_name: 'Bylands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.35,
    longitude: 144.9667,
    accuracy: 4,
  },
  {
    postcode: 3763,
    place_name: 'Kinglake',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5329,
    longitude: 145.3396,
    accuracy: 4,
  },
  {
    postcode: 3764,
    place_name: 'Tantaraboo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4658,
    longitude: 145.0672,
    accuracy: 3,
  },
  {
    postcode: 3764,
    place_name: 'Kilmore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2958,
    longitude: 144.9525,
    accuracy: 4,
  },
  {
    postcode: 3764,
    place_name: 'High Camp',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1833,
    longitude: 144.9,
    accuracy: 4,
  },
  {
    postcode: 3764,
    place_name: 'Kilmore East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3,
    longitude: 144.9833,
    accuracy: 4,
  },
  {
    postcode: 3764,
    place_name: 'Moranding',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4658,
    longitude: 145.0672,
    accuracy: 3,
  },
  {
    postcode: 3764,
    place_name: 'Forbes',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3764,
    place_name: 'Glenaroua',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 144.9667,
    accuracy: 4,
  },
  {
    postcode: 3764,
    place_name: 'Willowmavin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4658,
    longitude: 145.0672,
    accuracy: 3,
  },
  {
    postcode: 3765,
    place_name: 'Montrose',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3766,
    place_name: 'Kalorama',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 145.3667,
    accuracy: 4,
  },
  {
    postcode: 3767,
    place_name: 'Mount Dandenong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 145.3667,
    accuracy: 4,
  },
  {
    postcode: 3770,
    place_name: 'Coldstream',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7249,
    longitude: 145.378,
    accuracy: 4,
  },
  {
    postcode: 3770,
    place_name: 'Gruyere',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 145.45,
    accuracy: 4,
  },
  {
    postcode: 3770,
    place_name: 'Yering',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 145.3667,
    accuracy: 4,
  },
  {
    postcode: 3775,
    place_name: 'Dixons Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 145.4167,
    accuracy: 4,
  },
  {
    postcode: 3775,
    place_name: 'Yarra Glen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6579,
    longitude: 145.3742,
    accuracy: 4,
  },
  {
    postcode: 3775,
    place_name: 'Christmas Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.65,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3775,
    place_name: 'Steels Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 145.3833,
    accuracy: 4,
  },
  {
    postcode: 3775,
    place_name: 'Tarrawarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.65,
    longitude: 145.4333,
    accuracy: 4,
  },
  {
    postcode: 3777,
    place_name: 'Healesville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.654,
    longitude: 145.5172,
    accuracy: 4,
  },
  {
    postcode: 3777,
    place_name: 'Chum Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6051,
    longitude: 145.4874,
    accuracy: 4,
  },
  {
    postcode: 3777,
    place_name: 'Healesville Main Street',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6018,
    longitude: 145.4842,
    accuracy: 3,
  },
  {
    postcode: 3777,
    place_name: 'Badger Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 145.5167,
    accuracy: 4,
  },
  {
    postcode: 3777,
    place_name: 'Toolangi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5333,
    longitude: 145.4667,
    accuracy: 4,
  },
  {
    postcode: 3777,
    place_name: 'Castella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 145.4333,
    accuracy: 4,
  },
  {
    postcode: 3777,
    place_name: 'Mount Toolebewong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6984,
    longitude: 145.5625,
    accuracy: 4,
  },
  {
    postcode: 3778,
    place_name: 'Narbethong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5639,
    longitude: 145.6557,
    accuracy: 4,
  },
  {
    postcode: 3778,
    place_name: 'Fernshaw',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6,
    longitude: 145.6333,
    accuracy: 4,
  },
  {
    postcode: 3779,
    place_name: 'Cambarville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5588,
    longitude: 145.8906,
    accuracy: 4,
  },
  {
    postcode: 3779,
    place_name: 'Marysville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5098,
    longitude: 145.7481,
    accuracy: 4,
  },
  {
    postcode: 3781,
    place_name: 'Mount Burnett',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 145.5,
    accuracy: 4,
  },
  {
    postcode: 3781,
    place_name: 'Nangana',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3781,
    place_name: 'Cockatoo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9368,
    longitude: 145.4938,
    accuracy: 4,
  },
  {
    postcode: 3782,
    place_name: 'Avonsleigh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 145.4667,
    accuracy: 4,
  },
  {
    postcode: 3782,
    place_name: 'Macclesfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.4833,
    accuracy: 4,
  },
  {
    postcode: 3782,
    place_name: 'Clematis',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 145.4167,
    accuracy: 4,
  },
  {
    postcode: 3782,
    place_name: 'Emerald',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9317,
    longitude: 145.4409,
    accuracy: 4,
  },
  {
    postcode: 3783,
    place_name: 'Gembrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9532,
    longitude: 145.5554,
    accuracy: 4,
  },
  {
    postcode: 3785,
    place_name: 'Tremont',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3786,
    place_name: 'Ferny Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3787,
    place_name: 'Sassafras Gully',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 145.35,
    accuracy: 3,
  },
  {
    postcode: 3787,
    place_name: 'Sassafras',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3788,
    place_name: 'Olinda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 145.3667,
    accuracy: 4,
  },
  {
    postcode: 3789,
    place_name: 'Sherbrooke',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3791,
    place_name: 'Kallista',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.3667,
    accuracy: 4,
  },
  {
    postcode: 3792,
    place_name: 'The Patch',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 145.3833,
    accuracy: 4,
  },
  {
    postcode: 3793,
    place_name: 'Monbulk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 145.4,
    accuracy: 4,
  },
  {
    postcode: 3795,
    place_name: 'Silvan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 145.4167,
    accuracy: 4,
  },
  {
    postcode: 3796,
    place_name: 'Mount Evelyn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 145.3833,
    accuracy: 4,
  },
  {
    postcode: 3797,
    place_name: 'Gladysdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 145.65,
    accuracy: 4,
  },
  {
    postcode: 3797,
    place_name: 'Powelltown',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 145.75,
    accuracy: 4,
  },
  {
    postcode: 3797,
    place_name: 'Three Bridges',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8454,
    longitude: 145.6845,
    accuracy: 4,
  },
  {
    postcode: 3797,
    place_name: 'Gilderoy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 3797,
    place_name: 'Yarra Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7819,
    longitude: 145.6143,
    accuracy: 4,
  },
  {
    postcode: 3799,
    place_name: 'Big Pats Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 145.75,
    accuracy: 4,
  },
  {
    postcode: 3799,
    place_name: 'Mcmahons Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 145.8333,
    accuracy: 4,
  },
  {
    postcode: 3799,
    place_name: 'Warburton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7537,
    longitude: 145.6904,
    accuracy: 4,
  },
  {
    postcode: 3799,
    place_name: 'Reefton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.752,
    longitude: 145.7172,
    accuracy: 3,
  },
  {
    postcode: 3799,
    place_name: 'East Warburton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7364,
    longitude: 145.7429,
    accuracy: 4,
  },
  {
    postcode: 3799,
    place_name: 'Millgrove',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7553,
    longitude: 145.6535,
    accuracy: 4,
  },
  {
    postcode: 3799,
    place_name: 'Wesburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 145.6333,
    accuracy: 4,
  },
  {
    postcode: 3800,
    place_name: 'Monash University',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9106,
    longitude: 145.1348,
    accuracy: 1,
  },
  {
    postcode: 3802,
    place_name: 'Endeavour Hills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.977,
    longitude: 145.2587,
    accuracy: 4,
  },
  {
    postcode: 3803,
    place_name: 'Hallam',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 145.2667,
    accuracy: 4,
  },
  {
    postcode: 3804,
    place_name: 'Narre Warren North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 145.3167,
    accuracy: 4,
  },
  {
    postcode: 3804,
    place_name: 'Narre Warren East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9639,
    longitude: 145.3629,
    accuracy: 4,
  },
  {
    postcode: 3805,
    place_name: 'Narre Warren',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 145.3,
    accuracy: 4,
  },
  {
    postcode: 3805,
    place_name: 'Fountain Gate',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0263,
    longitude: 145.3069,
    accuracy: 4,
  },
  {
    postcode: 3805,
    place_name: 'Narre Warren South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0437,
    longitude: 145.2923,
    accuracy: 4,
  },
  {
    postcode: 3806,
    place_name: 'Harkaway',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3806,
    place_name: 'Berwick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 145.35,
    accuracy: 4,
  },
  {
    postcode: 3807,
    place_name: 'Guys Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0161,
    longitude: 145.3871,
    accuracy: 4,
  },
  {
    postcode: 3807,
    place_name: 'Beaconsfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 145.3667,
    accuracy: 4,
  },
  {
    postcode: 3808,
    place_name: 'Dewhurst',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9801,
    longitude: 145.4669,
    accuracy: 4,
  },
  {
    postcode: 3808,
    place_name: 'Beaconsfield Upper',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9941,
    longitude: 145.4142,
    accuracy: 4,
  },
  {
    postcode: 3809,
    place_name: 'Officer',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0592,
    longitude: 145.4095,
    accuracy: 4,
  },
  {
    postcode: 3809,
    place_name: 'Officer South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0863,
    longitude: 145.4021,
    accuracy: 4,
  },
  {
    postcode: 3810,
    place_name: 'Rythdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 145.4333,
    accuracy: 4,
  },
  {
    postcode: 3810,
    place_name: 'Pakenham South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 145.5167,
    accuracy: 4,
  },
  {
    postcode: 3810,
    place_name: 'Pakenham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0702,
    longitude: 145.4741,
    accuracy: 4,
  },
  {
    postcode: 3810,
    place_name: 'Pakenham Upper',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 145.5167,
    accuracy: 4,
  },
  {
    postcode: 3812,
    place_name: 'Maryknoll',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.036,
    longitude: 145.5948,
    accuracy: 4,
  },
  {
    postcode: 3812,
    place_name: 'Nar Nar Goon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0825,
    longitude: 145.5701,
    accuracy: 4,
  },
  {
    postcode: 3812,
    place_name: 'Nar Nar Goon North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 145.5667,
    accuracy: 4,
  },
  {
    postcode: 3813,
    place_name: 'Tynong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0834,
    longitude: 145.6246,
    accuracy: 4,
  },
  {
    postcode: 3813,
    place_name: 'Tynong North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0667,
    longitude: 145.6167,
    accuracy: 4,
  },
  {
    postcode: 3814,
    place_name: 'Cora Lynn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.15,
    longitude: 145.6,
    accuracy: 4,
  },
  {
    postcode: 3814,
    place_name: 'Garfield North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 145.6833,
    accuracy: 4,
  },
  {
    postcode: 3814,
    place_name: 'Garfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0899,
    longitude: 145.675,
    accuracy: 4,
  },
  {
    postcode: 3814,
    place_name: 'Vervale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 145.6667,
    accuracy: 4,
  },
  {
    postcode: 3815,
    place_name: 'Tonimbuk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 145.7333,
    accuracy: 4,
  },
  {
    postcode: 3815,
    place_name: 'Bunyip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0979,
    longitude: 145.7161,
    accuracy: 4,
  },
  {
    postcode: 3815,
    place_name: 'Bunyip North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0634,
    longitude: 145.7206,
    accuracy: 4,
  },
  {
    postcode: 3815,
    place_name: 'Iona',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 145.6833,
    accuracy: 4,
  },
  {
    postcode: 3816,
    place_name: 'Labertouche',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 145.8,
    accuracy: 4,
  },
  {
    postcode: 3816,
    place_name: 'Longwarry North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 145.7667,
    accuracy: 4,
  },
  {
    postcode: 3816,
    place_name: 'Longwarry',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1128,
    longitude: 145.7685,
    accuracy: 4,
  },
  {
    postcode: 3816,
    place_name: 'Modella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 3818,
    place_name: 'Drouin West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 145.8667,
    accuracy: 4,
  },
  {
    postcode: 3818,
    place_name: 'Ripplebrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2001,
    longitude: 145.7725,
    accuracy: 3,
  },
  {
    postcode: 3818,
    place_name: 'Drouin South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 145.8333,
    accuracy: 4,
  },
  {
    postcode: 3818,
    place_name: 'Drouin East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1561,
    longitude: 145.8431,
    accuracy: 3,
  },
  {
    postcode: 3818,
    place_name: 'Hallora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 145.8333,
    accuracy: 4,
  },
  {
    postcode: 3818,
    place_name: 'Athlone',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 145.7667,
    accuracy: 4,
  },
  {
    postcode: 3818,
    place_name: 'Drouin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1366,
    longitude: 145.8584,
    accuracy: 4,
  },
  {
    postcode: 3818,
    place_name: 'Jindivick',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 145.9,
    accuracy: 4,
  },
  {
    postcode: 3820,
    place_name: 'Warragul',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1591,
    longitude: 145.9312,
    accuracy: 4,
  },
  {
    postcode: 3820,
    place_name: 'Bona Vista',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1462,
    longitude: 145.9406,
    accuracy: 3,
  },
  {
    postcode: 3820,
    place_name: 'Lillico',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 145.95,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Brandy Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 145.9,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Lardner',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 145.8667,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Seaview',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1382,
    longitude: 145.9483,
    accuracy: 3,
  },
  {
    postcode: 3821,
    place_name: 'Crossover',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Nilma North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.15,
    longitude: 145.9833,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Rokeby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0667,
    longitude: 145.9333,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Neerim North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1952,
    longitude: 145.9567,
    accuracy: 3,
  },
  {
    postcode: 3821,
    place_name: 'Buln Buln East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Nilma',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Neerim Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9296,
    longitude: 145.9612,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Torwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1382,
    longitude: 145.9483,
    accuracy: 3,
  },
  {
    postcode: 3821,
    place_name: 'Shady Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 146.05,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Bravington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1382,
    longitude: 145.9483,
    accuracy: 3,
  },
  {
    postcode: 3821,
    place_name: 'Tetoora Road',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 145.9,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Ferndale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3167,
    longitude: 145.9333,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Ellinbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 145.9333,
    accuracy: 4,
  },
  {
    postcode: 3821,
    place_name: 'Warragul South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1382,
    longitude: 145.9483,
    accuracy: 3,
  },
  {
    postcode: 3821,
    place_name: 'Warragul West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1382,
    longitude: 145.9483,
    accuracy: 3,
  },
  {
    postcode: 3821,
    place_name: 'Buln Buln',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 145.9333,
    accuracy: 4,
  },
  {
    postcode: 3822,
    place_name: 'Gainsborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 3822,
    place_name: 'Cloverlea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 3822,
    place_name: 'Darnum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1885,
    longitude: 146.0053,
    accuracy: 4,
  },
  {
    postcode: 3823,
    place_name: 'Yarragon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2052,
    longitude: 146.0608,
    accuracy: 4,
  },
  {
    postcode: 3823,
    place_name: 'Yarragon South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.236,
    longitude: 146.0471,
    accuracy: 3,
  },
  {
    postcode: 3823,
    place_name: 'Allambee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 146.0333,
    accuracy: 4,
  },
  {
    postcode: 3824,
    place_name: 'Trafalgar South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 146.15,
    accuracy: 4,
  },
  {
    postcode: 3824,
    place_name: 'Trafalgar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2091,
    longitude: 146.1535,
    accuracy: 4,
  },
  {
    postcode: 3824,
    place_name: 'Thorpdale South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 146.1667,
    accuracy: 4,
  },
  {
    postcode: 3824,
    place_name: 'Childers',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2993,
    longitude: 146.1139,
    accuracy: 4,
  },
  {
    postcode: 3824,
    place_name: 'Trafalgar East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 146.1833,
    accuracy: 4,
  },
  {
    postcode: 3824,
    place_name: 'Narracan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 146.2333,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Yallourn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1783,
    longitude: 146.261,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Jacob Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Hernes Oak',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1956,
    longitude: 146.3258,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Walhalla East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Aberfeldy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6833,
    longitude: 146.3667,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Jericho',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Rawson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9546,
    longitude: 146.3993,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Tanjil South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Willow Grove',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 146.2,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Amor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Yallourn North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1605,
    longitude: 146.3645,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Thomson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1698,
    longitude: 144.3789,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Fumina',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 146.1,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Coalville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 146.2667,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Moe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1783,
    longitude: 146.261,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Moondarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 146.3667,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Coopers Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 146.4333,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Caringal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Fumina South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 146.1167,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Westbury',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1363,
    longitude: 146.2175,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Boola',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Hill End',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 146.15,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Thalloo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Erica',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 146.3667,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Tanjil',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Walhalla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 146.45,
    accuracy: 4,
  },
  {
    postcode: 3825,
    place_name: 'Moe South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Toombon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0481,
    longitude: 146.1801,
    accuracy: 3,
  },
  {
    postcode: 3825,
    place_name: 'Newborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1717,
    longitude: 146.2926,
    accuracy: 4,
  },
  {
    postcode: 3831,
    place_name: 'Neerim East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 3831,
    place_name: 'Neerim',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9667,
    longitude: 145.95,
    accuracy: 4,
  },
  {
    postcode: 3831,
    place_name: 'Neerim South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0168,
    longitude: 145.9545,
    accuracy: 4,
  },
  {
    postcode: 3832,
    place_name: 'Neerim Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9296,
    longitude: 145.9612,
    accuracy: 4,
  },
  {
    postcode: 3832,
    place_name: 'Neerim North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9315,
    longitude: 145.9556,
    accuracy: 3,
  },
  {
    postcode: 3832,
    place_name: 'Nayook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 145.95,
    accuracy: 4,
  },
  {
    postcode: 3833,
    place_name: 'Toorongo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8728,
    longitude: 146.1029,
    accuracy: 3,
  },
  {
    postcode: 3833,
    place_name: 'Icy Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8728,
    longitude: 146.1029,
    accuracy: 3,
  },
  {
    postcode: 3833,
    place_name: 'Vesper',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 146.0833,
    accuracy: 4,
  },
  {
    postcode: 3833,
    place_name: 'Gentle Annie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.938,
    longitude: 145.8359,
    accuracy: 4,
  },
  {
    postcode: 3833,
    place_name: 'Loch Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 3833,
    place_name: 'Ada',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8728,
    longitude: 146.1029,
    accuracy: 3,
  },
  {
    postcode: 3833,
    place_name: 'Baw Baw Village',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8378,
    longitude: 146.2499,
    accuracy: 4,
  },
  {
    postcode: 3833,
    place_name: 'Noojee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 146,
    accuracy: 4,
  },
  {
    postcode: 3833,
    place_name: 'Piedmont',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8728,
    longitude: 146.1029,
    accuracy: 3,
  },
  {
    postcode: 3833,
    place_name: 'Tanjil Bren',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9222,
    longitude: 146.2121,
    accuracy: 4,
  },
  {
    postcode: 3833,
    place_name: 'Baw Baw',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7412,
    longitude: 146.331,
    accuracy: 4,
  },
  {
    postcode: 3835,
    place_name: 'Thorpdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2833,
    longitude: 146.1833,
    accuracy: 4,
  },
  {
    postcode: 3840,
    place_name: 'Hazelwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2833,
    longitude: 146.35,
    accuracy: 4,
  },
  {
    postcode: 3840,
    place_name: 'Driffield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3137,
    longitude: 146.4182,
    accuracy: 3,
  },
  {
    postcode: 3840,
    place_name: 'Hazelwood South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.317,
    longitude: 146.4626,
    accuracy: 4,
  },
  {
    postcode: 3840,
    place_name: 'Morwell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2348,
    longitude: 146.395,
    accuracy: 4,
  },
  {
    postcode: 3840,
    place_name: 'Maryvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3137,
    longitude: 146.4182,
    accuracy: 3,
  },
  {
    postcode: 3840,
    place_name: 'Mid Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2994,
    longitude: 146.483,
    accuracy: 3,
  },
  {
    postcode: 3840,
    place_name: 'Jeeralang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4038,
    longitude: 146.4753,
    accuracy: 4,
  },
  {
    postcode: 3840,
    place_name: 'Hazelwood North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3137,
    longitude: 146.4182,
    accuracy: 3,
  },
  {
    postcode: 3840,
    place_name: 'Jeeralang Junction',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 146.4167,
    accuracy: 4,
  },
  {
    postcode: 3841,
    place_name: 'Gippsland Mc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2458,
    longitude: 146.6306,
    accuracy: 1,
  },
  {
    postcode: 3842,
    place_name: 'Churchill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3078,
    longitude: 146.4137,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Mount Tassie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3008,
    longitude: 146.6242,
    accuracy: 3,
  },
  {
    postcode: 3844,
    place_name: 'Callignee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 146.5833,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Tyers',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1489,
    longitude: 146.4804,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Flynn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 146.6833,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Carrajung Lower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 146.7333,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Traralgon South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 146.55,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Blackwarry',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 146.65,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Carrajung South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 146.7333,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Koornalla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3167,
    longitude: 146.5333,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Callignee South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 146.6167,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Flynns Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3008,
    longitude: 146.6242,
    accuracy: 3,
  },
  {
    postcode: 3844,
    place_name: 'Willung South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 146.7333,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Traralgon East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3008,
    longitude: 146.6242,
    accuracy: 3,
  },
  {
    postcode: 3844,
    place_name: 'Carrajung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 146.6833,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Traralgon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1953,
    longitude: 146.5415,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Callignee North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3167,
    longitude: 146.6,
    accuracy: 4,
  },
  {
    postcode: 3844,
    place_name: 'Loy Yang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 146.6167,
    accuracy: 4,
  },
  {
    postcode: 3847,
    place_name: 'Rosedale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1545,
    longitude: 146.7886,
    accuracy: 4,
  },
  {
    postcode: 3847,
    place_name: 'Willung South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 146.7333,
    accuracy: 4,
  },
  {
    postcode: 3847,
    place_name: 'Willung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 146.8167,
    accuracy: 4,
  },
  {
    postcode: 3847,
    place_name: 'Hiamdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2011,
    longitude: 146.8055,
    accuracy: 3,
  },
  {
    postcode: 3847,
    place_name: 'Nambrok',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 146.8833,
    accuracy: 4,
  },
  {
    postcode: 3850,
    place_name: 'Wurruk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 147.0333,
    accuracy: 4,
  },
  {
    postcode: 3850,
    place_name: 'Guthridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.106,
    longitude: 147.0608,
    accuracy: 3,
  },
  {
    postcode: 3850,
    place_name: 'Sale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.111,
    longitude: 147.068,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Fulham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 146.9833,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Seaspray',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3761,
    longitude: 147.1906,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Seacombe',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 147.4333,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Darriman',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 146.9333,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'The Heart',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 147.1667,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Kilmany',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1,
    longitude: 146.9,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Montgomery',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 147.0667,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Golden Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2109,
    longitude: 147.3987,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Giffard',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 147.0833,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Airly',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 147.1167,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'The Honeysuckles',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1661,
    longitude: 147.1648,
    accuracy: 3,
  },
  {
    postcode: 3851,
    place_name: 'Dutson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 147.1833,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Cobains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0833,
    longitude: 147.15,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Paradise Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1962,
    longitude: 147.4152,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Glomar Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1661,
    longitude: 147.1648,
    accuracy: 3,
  },
  {
    postcode: 3851,
    place_name: 'Loch Sport',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0401,
    longitude: 147.5918,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Bundalaguah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 147.0167,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Longford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1624,
    longitude: 147.0859,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Clydebank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 147.2,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Myrtlebank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1588,
    longitude: 147.1833,
    accuracy: 3,
  },
  {
    postcode: 3851,
    place_name: 'Stradbroke',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 147.0333,
    accuracy: 4,
  },
  {
    postcode: 3851,
    place_name: 'Flamingo Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1661,
    longitude: 147.1648,
    accuracy: 3,
  },
  {
    postcode: 3851,
    place_name: 'Giffard West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1661,
    longitude: 147.1648,
    accuracy: 3,
  },
  {
    postcode: 3851,
    place_name: 'Dutson Downs',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1661,
    longitude: 147.1648,
    accuracy: 3,
  },
  {
    postcode: 3851,
    place_name: 'Pearsondale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1661,
    longitude: 147.1648,
    accuracy: 3,
  },
  {
    postcode: 3851,
    place_name: 'Lake Wellington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1661,
    longitude: 147.1648,
    accuracy: 3,
  },
  {
    postcode: 3851,
    place_name: 'Somerton Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1661,
    longitude: 147.1648,
    accuracy: 3,
  },
  {
    postcode: 3852,
    place_name: 'East Sale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1035,
    longitude: 147.127,
    accuracy: 4,
  },
  {
    postcode: 3852,
    place_name: 'East Sale Raaf',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.104,
    longitude: 147.1424,
    accuracy: 3,
  },
  {
    postcode: 3852,
    place_name: 'Sale East Raaf',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1035,
    longitude: 147.127,
    accuracy: 3,
  },
  {
    postcode: 3853,
    place_name: 'Sale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.111,
    longitude: 147.068,
    accuracy: 4,
  },
  {
    postcode: 3854,
    place_name: 'Glengarry',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 146.5667,
    accuracy: 4,
  },
  {
    postcode: 3854,
    place_name: 'Glengarry West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.15,
    longitude: 146.5167,
    accuracy: 4,
  },
  {
    postcode: 3854,
    place_name: 'Glengarry North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 146.5417,
    accuracy: 3,
  },
  {
    postcode: 3856,
    place_name: 'Toongabbie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 146.6167,
    accuracy: 4,
  },
  {
    postcode: 3857,
    place_name: 'Cowwarr',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0167,
    longitude: 146.6833,
    accuracy: 4,
  },
  {
    postcode: 3858,
    place_name: 'Dawson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 146.7333,
    accuracy: 4,
  },
  {
    postcode: 3858,
    place_name: 'Heyfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9813,
    longitude: 146.7856,
    accuracy: 4,
  },
  {
    postcode: 3858,
    place_name: 'Licola',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 146.6167,
    accuracy: 4,
  },
  {
    postcode: 3858,
    place_name: 'Denison',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 146.8833,
    accuracy: 4,
  },
  {
    postcode: 3858,
    place_name: 'Arbuckle',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Gillum',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Yangoura',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Billabong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Tamboritha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Reynard',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Worrowing',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Seaton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 146.6667,
    accuracy: 4,
  },
  {
    postcode: 3858,
    place_name: 'Crookayan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Winnindoo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 146.7667,
    accuracy: 4,
  },
  {
    postcode: 3858,
    place_name: 'Glenmaggie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 146.75,
    accuracy: 4,
  },
  {
    postcode: 3858,
    place_name: 'Sargood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Buragwonduc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Glenfalloch',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Howitt Plains',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3858,
    place_name: 'Licola North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9307,
    longitude: 146.7432,
    accuracy: 3,
  },
  {
    postcode: 3859,
    place_name: 'Newry',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 146.8833,
    accuracy: 4,
  },
  {
    postcode: 3859,
    place_name: 'Tinamba West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9264,
    longitude: 146.8749,
    accuracy: 3,
  },
  {
    postcode: 3859,
    place_name: 'Maffra West Upper',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 146.85,
    accuracy: 4,
  },
  {
    postcode: 3859,
    place_name: 'Tinamba',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9625,
    longitude: 146.8914,
    accuracy: 4,
  },
  {
    postcode: 3860,
    place_name: 'Riverslea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 146.95,
    accuracy: 4,
  },
  {
    postcode: 3860,
    place_name: 'Woolenook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8985,
    longitude: 146.9703,
    accuracy: 3,
  },
  {
    postcode: 3860,
    place_name: 'Coongulla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8913,
    longitude: 146.786,
    accuracy: 4,
  },
  {
    postcode: 3860,
    place_name: 'Nap Nap Marra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8985,
    longitude: 146.9703,
    accuracy: 3,
  },
  {
    postcode: 3860,
    place_name: 'Wrathung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8985,
    longitude: 146.9703,
    accuracy: 3,
  },
  {
    postcode: 3860,
    place_name: 'Bushy Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 147.0333,
    accuracy: 4,
  },
  {
    postcode: 3860,
    place_name: 'Wrixon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8985,
    longitude: 146.9703,
    accuracy: 3,
  },
  {
    postcode: 3860,
    place_name: 'Koorool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8985,
    longitude: 146.9703,
    accuracy: 3,
  },
  {
    postcode: 3860,
    place_name: 'Toolome',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8985,
    longitude: 146.9703,
    accuracy: 3,
  },
  {
    postcode: 3860,
    place_name: 'Briagolong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8436,
    longitude: 147.0727,
    accuracy: 4,
  },
  {
    postcode: 3860,
    place_name: 'Maffra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9713,
    longitude: 146.9837,
    accuracy: 4,
  },
  {
    postcode: 3860,
    place_name: 'Boisdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 146.9833,
    accuracy: 4,
  },
  {
    postcode: 3860,
    place_name: 'Moroka',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8985,
    longitude: 146.9703,
    accuracy: 3,
  },
  {
    postcode: 3860,
    place_name: 'Monomak',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8985,
    longitude: 146.9703,
    accuracy: 3,
  },
  {
    postcode: 3860,
    place_name: 'Valencia Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 146.9833,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Llowalong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9333,
    longitude: 147.0667,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Budgee Budgee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8052,
    longitude: 147.2018,
    accuracy: 3,
  },
  {
    postcode: 3862,
    place_name: 'Dargo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 147.2667,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Meerlieu',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 147.4,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Munro',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 147.1833,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Hollands Landing',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8052,
    longitude: 147.2018,
    accuracy: 3,
  },
  {
    postcode: 3862,
    place_name: 'Stockdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 147.1667,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Cobbannah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 147.1833,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Perry Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38,
    longitude: 147.2667,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Crooked River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8052,
    longitude: 147.2018,
    accuracy: 3,
  },
  {
    postcode: 3862,
    place_name: 'Stratford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9634,
    longitude: 147.0826,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Hawkhurst',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8052,
    longitude: 147.2018,
    accuracy: 3,
  },
  {
    postcode: 3862,
    place_name: 'Waterford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 147.2,
    accuracy: 4,
  },
  {
    postcode: 3862,
    place_name: 'Miowera',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8052,
    longitude: 147.2018,
    accuracy: 3,
  },
  {
    postcode: 3862,
    place_name: 'Wongungarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8052,
    longitude: 147.2018,
    accuracy: 3,
  },
  {
    postcode: 3862,
    place_name: 'Cowa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8052,
    longitude: 147.2018,
    accuracy: 3,
  },
  {
    postcode: 3862,
    place_name: 'Moornapa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8052,
    longitude: 147.2018,
    accuracy: 3,
  },
  {
    postcode: 3864,
    place_name: 'Glenaladale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7833,
    longitude: 147.35,
    accuracy: 4,
  },
  {
    postcode: 3864,
    place_name: 'Fernbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8667,
    longitude: 147.3167,
    accuracy: 4,
  },
  {
    postcode: 3865,
    place_name: 'Lindenow',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8005,
    longitude: 147.4598,
    accuracy: 4,
  },
  {
    postcode: 3869,
    place_name: 'Yinnar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3225,
    longitude: 146.3292,
    accuracy: 4,
  },
  {
    postcode: 3869,
    place_name: 'Yinnar South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 146.3833,
    accuracy: 4,
  },
  {
    postcode: 3869,
    place_name: 'Jumbuk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 146.4278,
    accuracy: 4,
  },
  {
    postcode: 3870,
    place_name: 'Budgeree',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 146.3333,
    accuracy: 4,
  },
  {
    postcode: 3870,
    place_name: 'Johnstones Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4153,
    longitude: 146.2973,
    accuracy: 3,
  },
  {
    postcode: 3870,
    place_name: 'Boolarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3794,
    longitude: 146.2753,
    accuracy: 4,
  },
  {
    postcode: 3870,
    place_name: 'Grand Ridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4153,
    longitude: 146.2973,
    accuracy: 3,
  },
  {
    postcode: 3870,
    place_name: 'Boolarra South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 146.2833,
    accuracy: 4,
  },
  {
    postcode: 3871,
    place_name: 'Mirboo North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.401,
    longitude: 146.1613,
    accuracy: 4,
  },
  {
    postcode: 3871,
    place_name: 'Dollar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5667,
    longitude: 146.1667,
    accuracy: 4,
  },
  {
    postcode: 3871,
    place_name: 'Allambee Reserve',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4141,
    longitude: 146.1797,
    accuracy: 3,
  },
  {
    postcode: 3871,
    place_name: 'Darlimurla',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 146.2167,
    accuracy: 4,
  },
  {
    postcode: 3871,
    place_name: 'Allambee South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 146.1,
    accuracy: 4,
  },
  {
    postcode: 3871,
    place_name: 'Baromi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4141,
    longitude: 146.1797,
    accuracy: 3,
  },
  {
    postcode: 3871,
    place_name: 'Mirboo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4667,
    longitude: 146.2,
    accuracy: 4,
  },
  {
    postcode: 3871,
    place_name: 'Delburn',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 146.2333,
    accuracy: 4,
  },
  {
    postcode: 3873,
    place_name: 'Gormandale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2833,
    longitude: 146.7,
    accuracy: 4,
  },
  {
    postcode: 3874,
    place_name: 'Woodside',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5238,
    longitude: 146.8751,
    accuracy: 4,
  },
  {
    postcode: 3874,
    place_name: 'Mcloughlins Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4536,
    longitude: 146.8042,
    accuracy: 3,
  },
  {
    postcode: 3874,
    place_name: 'Woodside North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4536,
    longitude: 146.8042,
    accuracy: 3,
  },
  {
    postcode: 3874,
    place_name: 'Carrajung South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 146.7333,
    accuracy: 4,
  },
  {
    postcode: 3874,
    place_name: 'Woodside Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4536,
    longitude: 146.8042,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Bairnsdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8229,
    longitude: 147.6104,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Granite Rock',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7569,
    longitude: 147.672,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Lucknow',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 147.65,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Merrijig',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Hillside',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 147.5,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Goon Nure',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.95,
    longitude: 147.65,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Walpa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 147.45,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Deptford',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 147.7,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Eastwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Lindenow South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 147.4333,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Woodglen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 147.4833,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Wentworth',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Forge Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 147.6333,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Tabberabbera',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5833,
    longitude: 147.3833,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Calulu',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 147.5167,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Waterholes',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Clifton Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7078,
    longitude: 147.6658,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Marthavale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Bullumwaal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6333,
    longitude: 147.5333,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Broadlands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Wy Yung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 147.5833,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Banksia Peninsula',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Newlands Arm',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9241,
    longitude: 147.698,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Ellaswood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'East Bairnsdale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8229,
    longitude: 147.6104,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Iguana Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 147.3167,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Wuk Wuk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7667,
    longitude: 147.4333,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Mount Taylor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7437,
    longitude: 147.5994,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Fairy Dell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.3333,
    longitude: 144.75,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Melwood',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Ryans',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Flaggy Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7479,
    longitude: 147.4935,
    accuracy: 3,
  },
  {
    postcode: 3875,
    place_name: 'Sarsfield',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 147.7167,
    accuracy: 4,
  },
  {
    postcode: 3875,
    place_name: 'Bengworden',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9833,
    longitude: 147.4667,
    accuracy: 4,
  },
  {
    postcode: 3878,
    place_name: 'Eagle Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9,
    longitude: 147.6833,
    accuracy: 4,
  },
  {
    postcode: 3880,
    place_name: 'Paynesville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9189,
    longitude: 147.7194,
    accuracy: 4,
  },
  {
    postcode: 3880,
    place_name: 'Boole Poole',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9178,
    longitude: 147.7264,
    accuracy: 3,
  },
  {
    postcode: 3880,
    place_name: 'Ocean Grange',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9178,
    longitude: 147.7264,
    accuracy: 3,
  },
  {
    postcode: 3880,
    place_name: 'Raymond Island',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.9167,
    longitude: 147.7333,
    accuracy: 4,
  },
  {
    postcode: 3882,
    place_name: 'Nicholson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 147.7333,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Wulgulmerang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0833,
    longitude: 148.2833,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Wulgulmerang East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0833,
    longitude: 148.2833,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Buchan South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5667,
    longitude: 148.1167,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Suggan Buggan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9556,
    longitude: 148.3276,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'W Tree',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2648,
    longitude: 148.1466,
    accuracy: 3,
  },
  {
    postcode: 3885,
    place_name: 'Butchers Ridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2583,
    longitude: 148.2443,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Yalmy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4142,
    longitude: 148.1025,
    accuracy: 3,
  },
  {
    postcode: 3885,
    place_name: 'Gelantipy',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1833,
    longitude: 148.2833,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Wiseleigh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7117,
    longitude: 147.8056,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Buchan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5,
    longitude: 148.1833,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Brumby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4142,
    longitude: 148.1025,
    accuracy: 3,
  },
  {
    postcode: 3885,
    place_name: 'Bruthen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7067,
    longitude: 147.831,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Wulgulmerang West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4142,
    longitude: 148.1025,
    accuracy: 3,
  },
  {
    postcode: 3885,
    place_name: 'Mossiface',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7415,
    longitude: 147.8159,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Murrindal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4167,
    longitude: 148.2,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Tambo Upper',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.767,
    longitude: 147.843,
    accuracy: 4,
  },
  {
    postcode: 3885,
    place_name: 'Timbarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4142,
    longitude: 148.1025,
    accuracy: 3,
  },
  {
    postcode: 3886,
    place_name: 'Newmerella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 148.45,
    accuracy: 4,
  },
  {
    postcode: 3887,
    place_name: 'Nowa Nowa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7167,
    longitude: 148.1,
    accuracy: 4,
  },
  {
    postcode: 3887,
    place_name: 'Lake Tyers',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.85,
    longitude: 148.0667,
    accuracy: 4,
  },
  {
    postcode: 3887,
    place_name: 'Wairewa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6892,
    longitude: 148.1693,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Tostaree',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.75,
    longitude: 148.1667,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Brodribb River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7,
    longitude: 148.5667,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Bete Bolong North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5266,
    longitude: 148.543,
    accuracy: 3,
  },
  {
    postcode: 3888,
    place_name: 'Goongerah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3321,
    longitude: 148.6943,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Marlo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7962,
    longitude: 148.5319,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Waygara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 148.3167,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Bendoc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1333,
    longitude: 148.9,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Jarrahmond',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6984,
    longitude: 148.4064,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Wombat Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5266,
    longitude: 148.543,
    accuracy: 3,
  },
  {
    postcode: 3888,
    place_name: 'Bete Bolong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6858,
    longitude: 148.3833,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Haydens Bog',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5266,
    longitude: 148.543,
    accuracy: 3,
  },
  {
    postcode: 3888,
    place_name: 'Delegate River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0667,
    longitude: 148.8,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Simpsons Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5266,
    longitude: 148.543,
    accuracy: 3,
  },
  {
    postcode: 3888,
    place_name: 'Nurran',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5266,
    longitude: 148.543,
    accuracy: 3,
  },
  {
    postcode: 3888,
    place_name: 'Bonang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2,
    longitude: 148.75,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Orbost',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6964,
    longitude: 148.457,
    accuracy: 4,
  },
  {
    postcode: 3888,
    place_name: 'Tubbut',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3755,
    longitude: 148.5993,
    accuracy: 3,
  },
  {
    postcode: 3888,
    place_name: 'Corringle',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5266,
    longitude: 148.543,
    accuracy: 3,
  },
  {
    postcode: 3888,
    place_name: 'Cape Conran',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5266,
    longitude: 148.543,
    accuracy: 3,
  },
  {
    postcode: 3888,
    place_name: 'Deddick Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5266,
    longitude: 148.543,
    accuracy: 3,
  },
  {
    postcode: 3889,
    place_name: 'Cabbage Tree Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6967,
    longitude: 148.7197,
    accuracy: 4,
  },
  {
    postcode: 3889,
    place_name: 'Bemm River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7582,
    longitude: 148.9692,
    accuracy: 4,
  },
  {
    postcode: 3889,
    place_name: 'Errinundra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3831,
    longitude: 148.8937,
    accuracy: 4,
  },
  {
    postcode: 3889,
    place_name: 'Bellbird Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5481,
    longitude: 148.8764,
    accuracy: 3,
  },
  {
    postcode: 3889,
    place_name: 'Manorina',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.6756,
    longitude: 148.7948,
    accuracy: 4,
  },
  {
    postcode: 3889,
    place_name: 'Club Terrace',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5333,
    longitude: 148.95,
    accuracy: 4,
  },
  {
    postcode: 3889,
    place_name: 'Combienbar',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 149.0167,
    accuracy: 4,
  },
  {
    postcode: 3890,
    place_name: 'Buldah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2655,
    longitude: 149.1431,
    accuracy: 4,
  },
  {
    postcode: 3890,
    place_name: 'Noorinbee North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4986,
    longitude: 149.1425,
    accuracy: 3,
  },
  {
    postcode: 3890,
    place_name: 'Cann River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5658,
    longitude: 149.151,
    accuracy: 4,
  },
  {
    postcode: 3890,
    place_name: 'Chandlers Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3385,
    longitude: 149.1951,
    accuracy: 4,
  },
  {
    postcode: 3890,
    place_name: 'Noorinbee',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5167,
    longitude: 149.1667,
    accuracy: 4,
  },
  {
    postcode: 3890,
    place_name: 'Tonghi Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5638,
    longitude: 149.0588,
    accuracy: 4,
  },
  {
    postcode: 3890,
    place_name: 'Tamboon',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7393,
    longitude: 149.1419,
    accuracy: 4,
  },
  {
    postcode: 3891,
    place_name: 'Wangarabell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.475,
    longitude: 149.6333,
    accuracy: 3,
  },
  {
    postcode: 3891,
    place_name: 'Genoa',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4833,
    longitude: 149.5833,
    accuracy: 4,
  },
  {
    postcode: 3891,
    place_name: 'Gipsy Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4667,
    longitude: 149.6833,
    accuracy: 4,
  },
  {
    postcode: 3891,
    place_name: 'Wallagaraugh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.475,
    longitude: 149.6333,
    accuracy: 3,
  },
  {
    postcode: 3891,
    place_name: 'Wroxham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.475,
    longitude: 149.6333,
    accuracy: 3,
  },
  {
    postcode: 3891,
    place_name: 'Wingan River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.475,
    longitude: 149.6333,
    accuracy: 3,
  },
  {
    postcode: 3891,
    place_name: 'Maramingo Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.475,
    longitude: 149.6333,
    accuracy: 3,
  },
  {
    postcode: 3892,
    place_name: 'Mallacoota',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.559,
    longitude: 149.7541,
    accuracy: 4,
  },
  {
    postcode: 3893,
    place_name: 'Double Bridges',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4667,
    longitude: 147.7917,
    accuracy: 3,
  },
  {
    postcode: 3893,
    place_name: 'Stirling',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4333,
    longitude: 147.75,
    accuracy: 4,
  },
  {
    postcode: 3893,
    place_name: 'Tambo Crossing',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.5,
    longitude: 147.8333,
    accuracy: 4,
  },
  {
    postcode: 3895,
    place_name: 'Ensay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3833,
    longitude: 147.8333,
    accuracy: 4,
  },
  {
    postcode: 3895,
    place_name: 'Reedy Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.4,
    longitude: 147.9,
    accuracy: 4,
  },
  {
    postcode: 3895,
    place_name: 'Doctors Flat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3,
    longitude: 147.75,
    accuracy: 4,
  },
  {
    postcode: 3895,
    place_name: 'Ensay North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3611,
    longitude: 147.8278,
    accuracy: 3,
  },
  {
    postcode: 3896,
    place_name: 'Swifts Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.25,
    longitude: 147.7167,
    accuracy: 4,
  },
  {
    postcode: 3896,
    place_name: 'Brookville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.3333,
    longitude: 147.6167,
    accuracy: 4,
  },
  {
    postcode: 3896,
    place_name: 'Nunniong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2208,
    longitude: 147.725,
    accuracy: 3,
  },
  {
    postcode: 3896,
    place_name: 'Tongio',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1833,
    longitude: 147.7333,
    accuracy: 4,
  },
  {
    postcode: 3896,
    place_name: 'Bindi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1167,
    longitude: 147.8333,
    accuracy: 4,
  },
  {
    postcode: 3898,
    place_name: 'Hinnomunjie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0167,
    longitude: 147.65,
    accuracy: 4,
  },
  {
    postcode: 3898,
    place_name: 'Glen Wills',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.85,
    longitude: 147.5167,
    accuracy: 4,
  },
  {
    postcode: 3898,
    place_name: 'Omeo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0961,
    longitude: 147.5949,
    accuracy: 4,
  },
  {
    postcode: 3898,
    place_name: 'Cobungra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.1,
    longitude: 147.4167,
    accuracy: 4,
  },
  {
    postcode: 3898,
    place_name: 'Dinner Plain',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0236,
    longitude: 147.2412,
    accuracy: 4,
  },
  {
    postcode: 3898,
    place_name: 'Bingo Munjie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0204,
    longitude: 147.4992,
    accuracy: 3,
  },
  {
    postcode: 3898,
    place_name: 'Cassilis',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.2167,
    longitude: 147.6,
    accuracy: 4,
  },
  {
    postcode: 3898,
    place_name: 'Omeo Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0204,
    longitude: 147.4992,
    accuracy: 3,
  },
  {
    postcode: 3898,
    place_name: 'Anglers Rest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.9935,
    longitude: 147.4905,
    accuracy: 4,
  },
  {
    postcode: 3898,
    place_name: 'Bundara',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0204,
    longitude: 147.4992,
    accuracy: 3,
  },
  {
    postcode: 3898,
    place_name: 'Shannonvale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.0204,
    longitude: 147.4992,
    accuracy: 3,
  },
  {
    postcode: 3898,
    place_name: 'Glen Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.8667,
    longitude: 147.4833,
    accuracy: 4,
  },
  {
    postcode: 3900,
    place_name: 'Benambra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.95,
    longitude: 147.7167,
    accuracy: 4,
  },
  {
    postcode: 3900,
    place_name: 'Cobberas',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -36.95,
    longitude: 147.7167,
    accuracy: 3,
  },
  {
    postcode: 3902,
    place_name: 'Johnsonville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 147.8,
    accuracy: 4,
  },
  {
    postcode: 3902,
    place_name: 'Bumberrah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8,
    longitude: 147.8333,
    accuracy: 4,
  },
  {
    postcode: 3903,
    place_name: 'Swan Reach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8333,
    longitude: 147.8667,
    accuracy: 4,
  },
  {
    postcode: 3904,
    place_name: 'Metung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8915,
    longitude: 147.853,
    accuracy: 4,
  },
  {
    postcode: 3909,
    place_name: 'Lakes Entrance',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8812,
    longitude: 147.981,
    accuracy: 4,
  },
  {
    postcode: 3909,
    place_name: 'Nungurner',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8819,
    longitude: 147.8858,
    accuracy: 4,
  },
  {
    postcode: 3909,
    place_name: 'Lake Tyers Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.859,
    longitude: 148.0868,
    accuracy: 3,
  },
  {
    postcode: 3909,
    place_name: 'Toorloo Arm',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8758,
    longitude: 147.9423,
    accuracy: 3,
  },
  {
    postcode: 3909,
    place_name: 'Kalimna West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8568,
    longitude: 147.9522,
    accuracy: 4,
  },
  {
    postcode: 3909,
    place_name: 'Lake Bunga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8758,
    longitude: 147.9423,
    accuracy: 3,
  },
  {
    postcode: 3909,
    place_name: 'Nyerimilang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8758,
    longitude: 147.9423,
    accuracy: 3,
  },
  {
    postcode: 3909,
    place_name: 'Kalimna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8833,
    longitude: 147.95,
    accuracy: 4,
  },
  {
    postcode: 3910,
    place_name: 'Langwarrin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 145.1667,
    accuracy: 4,
  },
  {
    postcode: 3911,
    place_name: 'Langwarrin South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1987,
    longitude: 145.1807,
    accuracy: 4,
  },
  {
    postcode: 3911,
    place_name: 'Baxter',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 145.15,
    accuracy: 4,
  },
  {
    postcode: 3912,
    place_name: 'Somerville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 145.1667,
    accuracy: 4,
  },
  {
    postcode: 3912,
    place_name: 'Pearcedale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.203,
    longitude: 145.2349,
    accuracy: 4,
  },
  {
    postcode: 3913,
    place_name: 'Tyabb',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3915,
    place_name: 'Tuerong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2987,
    longitude: 145.0967,
    accuracy: 4,
  },
  {
    postcode: 3915,
    place_name: 'Hastings',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 145.1833,
    accuracy: 4,
  },
  {
    postcode: 3916,
    place_name: 'Shoreham',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4263,
    longitude: 145.0469,
    accuracy: 4,
  },
  {
    postcode: 3916,
    place_name: 'Merricks',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 145.0833,
    accuracy: 4,
  },
  {
    postcode: 3916,
    place_name: 'Point Leo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.415,
    longitude: 145.0694,
    accuracy: 4,
  },
  {
    postcode: 3918,
    place_name: 'Bittern',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 145.1667,
    accuracy: 4,
  },
  {
    postcode: 3919,
    place_name: 'Crib Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 145.2,
    accuracy: 4,
  },
  {
    postcode: 3920,
    place_name: 'Hmas Cerberus',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3649,
    longitude: 145.2004,
    accuracy: 4,
  },
  {
    postcode: 3921,
    place_name: 'French Island',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3406,
    longitude: 145.341,
    accuracy: 4,
  },
  {
    postcode: 3921,
    place_name: 'Elizabeth Island',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 145.3241,
    accuracy: 3,
  },
  {
    postcode: 3921,
    place_name: 'Tankerton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3868,
    longitude: 145.2896,
    accuracy: 4,
  },
  {
    postcode: 3922,
    place_name: 'Sunset Strip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4922,
    longitude: 145.2627,
    accuracy: 4,
  },
  {
    postcode: 3922,
    place_name: 'Sunderland Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4568,
    longitude: 145.0754,
    accuracy: 3,
  },
  {
    postcode: 3922,
    place_name: 'Ventnor',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4627,
    longitude: 145.195,
    accuracy: 4,
  },
  {
    postcode: 3922,
    place_name: 'Cowes',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4523,
    longitude: 145.2386,
    accuracy: 4,
  },
  {
    postcode: 3922,
    place_name: 'Summerlands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4568,
    longitude: 145.0754,
    accuracy: 3,
  },
  {
    postcode: 3922,
    place_name: 'Wimbledon Heights',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4568,
    longitude: 145.0754,
    accuracy: 3,
  },
  {
    postcode: 3922,
    place_name: 'Silverleaves',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4539,
    longitude: 145.2727,
    accuracy: 4,
  },
  {
    postcode: 3922,
    place_name: 'Surf Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3751,
    longitude: 144.2564,
    accuracy: 4,
  },
  {
    postcode: 3922,
    place_name: 'Smiths Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5007,
    longitude: 145.2552,
    accuracy: 4,
  },
  {
    postcode: 3923,
    place_name: 'Rhyll',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4642,
    longitude: 145.2995,
    accuracy: 4,
  },
  {
    postcode: 3925,
    place_name: 'Churchill Island',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5262,
    longitude: 145.3584,
    accuracy: 3,
  },
  {
    postcode: 3925,
    place_name: 'San Remo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5204,
    longitude: 145.37,
    accuracy: 4,
  },
  {
    postcode: 3925,
    place_name: 'Newhaven',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5121,
    longitude: 145.3558,
    accuracy: 4,
  },
  {
    postcode: 3925,
    place_name: 'Cape Woolamai',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5428,
    longitude: 145.3441,
    accuracy: 4,
  },
  {
    postcode: 3926,
    place_name: 'Merricks North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 145.0833,
    accuracy: 4,
  },
  {
    postcode: 3926,
    place_name: 'Balnarring Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3926,
    place_name: 'Balnarring',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 145.1333,
    accuracy: 4,
  },
  {
    postcode: 3926,
    place_name: 'Merricks Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3988,
    longitude: 145.106,
    accuracy: 4,
  },
  {
    postcode: 3927,
    place_name: 'Somers',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 145.1667,
    accuracy: 4,
  },
  {
    postcode: 3928,
    place_name: 'Main Ridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3973,
    longitude: 144.9724,
    accuracy: 4,
  },
  {
    postcode: 3929,
    place_name: 'Flinders',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4739,
    longitude: 145.019,
    accuracy: 4,
  },
  {
    postcode: 3930,
    place_name: 'Mount Eliza',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 145.0833,
    accuracy: 4,
  },
  {
    postcode: 3930,
    place_name: 'Kunyung',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1943,
    longitude: 145.0926,
    accuracy: 3,
  },
  {
    postcode: 3931,
    place_name: 'Mornington',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2179,
    longitude: 145.0388,
    accuracy: 4,
  },
  {
    postcode: 3933,
    place_name: 'Moorooduc',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 145.1167,
    accuracy: 4,
  },
  {
    postcode: 3934,
    place_name: 'Mount Martha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 3936,
    place_name: 'Dromana',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3338,
    longitude: 144.9646,
    accuracy: 4,
  },
  {
    postcode: 3936,
    place_name: 'Safety Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3153,
    longitude: 145.0003,
    accuracy: 4,
  },
  {
    postcode: 3936,
    place_name: 'Arthurs Seat',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3632,
    longitude: 144.9598,
    accuracy: 4,
  },
  {
    postcode: 3937,
    place_name: 'Red Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.35,
    longitude: 145.0167,
    accuracy: 4,
  },
  {
    postcode: 3937,
    place_name: 'Red Hill South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 145.0333,
    accuracy: 4,
  },
  {
    postcode: 3938,
    place_name: 'Mccrae',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3497,
    longitude: 144.9281,
    accuracy: 4,
  },
  {
    postcode: 3939,
    place_name: 'Boneo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 144.8833,
    accuracy: 4,
  },
  {
    postcode: 3939,
    place_name: 'Rosebud Plaza',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4106,
    longitude: 144.8837,
    accuracy: 3,
  },
  {
    postcode: 3939,
    place_name: 'Rosebud',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3554,
    longitude: 144.9068,
    accuracy: 4,
  },
  {
    postcode: 3939,
    place_name: 'Fingal',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.414,
    longitude: 144.8499,
    accuracy: 4,
  },
  {
    postcode: 3939,
    place_name: 'Cape Schanck',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4616,
    longitude: 144.9014,
    accuracy: 4,
  },
  {
    postcode: 3940,
    place_name: 'Rosebud West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3644,
    longitude: 144.8775,
    accuracy: 4,
  },
  {
    postcode: 3941,
    place_name: 'Rye',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 144.8167,
    accuracy: 4,
  },
  {
    postcode: 3941,
    place_name: 'Tootgarook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3777,
    longitude: 144.8502,
    accuracy: 4,
  },
  {
    postcode: 3941,
    place_name: 'St Andrews Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4141,
    longitude: 144.8244,
    accuracy: 3,
  },
  {
    postcode: 3942,
    place_name: 'Blairgowrie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3613,
    longitude: 144.7795,
    accuracy: 4,
  },
  {
    postcode: 3943,
    place_name: 'Sorrento',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3396,
    longitude: 144.7413,
    accuracy: 4,
  },
  {
    postcode: 3944,
    place_name: 'Portsea',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3167,
    longitude: 144.7167,
    accuracy: 4,
  },
  {
    postcode: 3945,
    place_name: 'Krowera',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4333,
    longitude: 145.6667,
    accuracy: 4,
  },
  {
    postcode: 3945,
    place_name: 'Jeetho',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 145.7167,
    accuracy: 4,
  },
  {
    postcode: 3945,
    place_name: 'Woodleigh',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 145.6333,
    accuracy: 4,
  },
  {
    postcode: 3945,
    place_name: 'Loch',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3691,
    longitude: 145.7081,
    accuracy: 4,
  },
  {
    postcode: 3946,
    place_name: 'Bena',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 145.7667,
    accuracy: 4,
  },
  {
    postcode: 3950,
    place_name: 'Strzelecki',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3333,
    longitude: 145.9,
    accuracy: 4,
  },
  {
    postcode: 3950,
    place_name: 'Whitelaw',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 145.7833,
    accuracy: 4,
  },
  {
    postcode: 3950,
    place_name: 'Kardella South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 145.8833,
    accuracy: 4,
  },
  {
    postcode: 3950,
    place_name: 'Korumburra South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4667,
    longitude: 145.85,
    accuracy: 4,
  },
  {
    postcode: 3950,
    place_name: 'Korumburra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4319,
    longitude: 145.8235,
    accuracy: 4,
  },
  {
    postcode: 3951,
    place_name: 'Arawata',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 145.8667,
    accuracy: 4,
  },
  {
    postcode: 3951,
    place_name: 'Fairbank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 145.7929,
    accuracy: 3,
  },
  {
    postcode: 3951,
    place_name: 'Ranceby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3833,
    longitude: 145.85,
    accuracy: 4,
  },
  {
    postcode: 3951,
    place_name: 'Kardella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 145.8667,
    accuracy: 4,
  },
  {
    postcode: 3951,
    place_name: 'Moyarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4833,
    longitude: 145.7333,
    accuracy: 4,
  },
  {
    postcode: 3951,
    place_name: 'Outtrim',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4833,
    longitude: 145.7667,
    accuracy: 4,
  },
  {
    postcode: 3951,
    place_name: 'Jumbunna',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4667,
    longitude: 145.7667,
    accuracy: 4,
  },
  {
    postcode: 3951,
    place_name: 'Kongwak',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5167,
    longitude: 145.7,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Ruby',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 145.9167,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Leongatha North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4236,
    longitude: 146.0027,
    accuracy: 3,
  },
  {
    postcode: 3953,
    place_name: 'Hallston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 146.0333,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Mount Eccles South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 145.9833,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Mount Eccles',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3667,
    longitude: 145.9833,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Koorooman',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4236,
    longitude: 146.0027,
    accuracy: 3,
  },
  {
    postcode: 3953,
    place_name: 'Berrys Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 146.0667,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Mardan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 146.1333,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Leongatha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4761,
    longitude: 145.9469,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Boorool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4236,
    longitude: 146.0027,
    accuracy: 3,
  },
  {
    postcode: 3953,
    place_name: 'Leongatha South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5333,
    longitude: 145.8833,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Wild Dog Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4236,
    longitude: 146.0027,
    accuracy: 3,
  },
  {
    postcode: 3953,
    place_name: 'Nerrena',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5167,
    longitude: 146.0833,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Wooreen',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4,
    longitude: 146.0333,
    accuracy: 4,
  },
  {
    postcode: 3953,
    place_name: 'Trida',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3167,
    longitude: 145.9667,
    accuracy: 4,
  },
  {
    postcode: 3954,
    place_name: 'Koonwarra',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.55,
    longitude: 145.95,
    accuracy: 4,
  },
  {
    postcode: 3956,
    place_name: 'Venus Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7004,
    longitude: 145.8224,
    accuracy: 4,
  },
  {
    postcode: 3956,
    place_name: 'Tarwin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5833,
    longitude: 145.9833,
    accuracy: 4,
  },
  {
    postcode: 3956,
    place_name: 'Dumbalk',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5333,
    longitude: 146.0833,
    accuracy: 4,
  },
  {
    postcode: 3956,
    place_name: 'Walkerville North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6678,
    longitude: 145.9843,
    accuracy: 3,
  },
  {
    postcode: 3956,
    place_name: 'Meeniyan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5769,
    longitude: 146.0193,
    accuracy: 4,
  },
  {
    postcode: 3956,
    place_name: 'Walkerville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6777,
    longitude: 146.0009,
    accuracy: 3,
  },
  {
    postcode: 3956,
    place_name: 'Walkerville South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.8599,
    longitude: 145.9933,
    accuracy: 4,
  },
  {
    postcode: 3956,
    place_name: 'Middle Tarwin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6667,
    longitude: 145.9333,
    accuracy: 4,
  },
  {
    postcode: 3956,
    place_name: 'Tarwin Lower',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7,
    longitude: 145.85,
    accuracy: 4,
  },
  {
    postcode: 3956,
    place_name: 'Dumbalk North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5167,
    longitude: 146.1667,
    accuracy: 4,
  },
  {
    postcode: 3957,
    place_name: 'Stony Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5833,
    longitude: 146.0333,
    accuracy: 4,
  },
  {
    postcode: 3958,
    place_name: 'Buffalo',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.65,
    longitude: 146.0333,
    accuracy: 4,
  },
  {
    postcode: 3959,
    place_name: 'Sandy Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.8199,
    longitude: 146.1174,
    accuracy: 4,
  },
  {
    postcode: 3959,
    place_name: 'Fish Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6833,
    longitude: 146.0833,
    accuracy: 4,
  },
  {
    postcode: 3959,
    place_name: 'Waratah Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.7697,
    longitude: 146.0757,
    accuracy: 3,
  },
  {
    postcode: 3960,
    place_name: 'Gunyah',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5333,
    longitude: 146.3167,
    accuracy: 4,
  },
  {
    postcode: 3960,
    place_name: 'Boolarong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5667,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 3960,
    place_name: 'Woorarra West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5638,
    longitude: 146.1564,
    accuracy: 3,
  },
  {
    postcode: 3960,
    place_name: 'Bennison',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6667,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 3960,
    place_name: 'Shallow Inlet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5638,
    longitude: 146.1564,
    accuracy: 3,
  },
  {
    postcode: 3960,
    place_name: 'Turtons Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.55,
    longitude: 146.25,
    accuracy: 4,
  },
  {
    postcode: 3960,
    place_name: 'Tidal River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.8342,
    longitude: 146.2888,
    accuracy: 3,
  },
  {
    postcode: 3960,
    place_name: 'Yanakie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.8114,
    longitude: 146.2057,
    accuracy: 4,
  },
  {
    postcode: 3960,
    place_name: 'Foster North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5638,
    longitude: 146.1564,
    accuracy: 3,
  },
  {
    postcode: 3960,
    place_name: 'Wilsons Promontory',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.9602,
    longitude: 146.3677,
    accuracy: 4,
  },
  {
    postcode: 3960,
    place_name: 'Wonga',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.7333,
    longitude: 145.2667,
    accuracy: 4,
  },
  {
    postcode: 3960,
    place_name: 'Mount Best',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6,
    longitude: 146.3,
    accuracy: 4,
  },
  {
    postcode: 3960,
    place_name: 'Foster',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6527,
    longitude: 146.2007,
    accuracy: 4,
  },
  {
    postcode: 3962,
    place_name: 'Woorarra East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6167,
    longitude: 146.3833,
    accuracy: 4,
  },
  {
    postcode: 3962,
    place_name: 'Toora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6629,
    longitude: 146.3279,
    accuracy: 4,
  },
  {
    postcode: 3962,
    place_name: 'Wonyip',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5622,
    longitude: 146.3924,
    accuracy: 4,
  },
  {
    postcode: 3962,
    place_name: 'Toora North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.633,
    longitude: 146.3711,
    accuracy: 3,
  },
  {
    postcode: 3962,
    place_name: 'Agnes',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6857,
    longitude: 146.3898,
    accuracy: 4,
  },
  {
    postcode: 3964,
    place_name: 'Port Franklin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6833,
    longitude: 146.2667,
    accuracy: 4,
  },
  {
    postcode: 3965,
    place_name: 'Port Welshpool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6992,
    longitude: 146.4531,
    accuracy: 4,
  },
  {
    postcode: 3966,
    place_name: 'Binginwarri',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5833,
    longitude: 146.45,
    accuracy: 4,
  },
  {
    postcode: 3966,
    place_name: 'Welshpool',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6667,
    longitude: 146.4333,
    accuracy: 4,
  },
  {
    postcode: 3966,
    place_name: 'Hazel Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 146.3833,
    accuracy: 4,
  },
  {
    postcode: 3967,
    place_name: 'Hedley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 146.5,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Gelliondale',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6194,
    longitude: 146.6036,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Yarram',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5648,
    longitude: 146.6756,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Balook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4333,
    longitude: 146.5667,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Manns Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5398,
    longitude: 146.6065,
    accuracy: 3,
  },
  {
    postcode: 3971,
    place_name: 'Tarraville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 146.7167,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Madalya',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5,
    longitude: 146.4667,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Langsborough',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6551,
    longitude: 146.678,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Hunterston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5398,
    longitude: 146.6065,
    accuracy: 3,
  },
  {
    postcode: 3971,
    place_name: 'Alberton',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6167,
    longitude: 146.6667,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Won Wron',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4667,
    longitude: 146.7167,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Hiawatha',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5167,
    longitude: 146.4667,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Robertsons Beach',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5398,
    longitude: 146.6065,
    accuracy: 3,
  },
  {
    postcode: 3971,
    place_name: 'Devon North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.505,
    longitude: 146.6497,
    accuracy: 3,
  },
  {
    postcode: 3971,
    place_name: 'Jack River',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.55,
    longitude: 146.5667,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Tarra Valley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4333,
    longitude: 146.5333,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Snake Island',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5398,
    longitude: 146.6065,
    accuracy: 3,
  },
  {
    postcode: 3971,
    place_name: 'Macks Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4667,
    longitude: 146.6333,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Alberton West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6,
    longitude: 146.6,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Staceys Bridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.55,
    longitude: 146.5333,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Calrossie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4833,
    longitude: 146.6667,
    accuracy: 4,
  },
  {
    postcode: 3971,
    place_name: 'Port Albert',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6654,
    longitude: 146.6885,
    accuracy: 4,
  },
  {
    postcode: 3975,
    place_name: 'Lyndhurst',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.05,
    longitude: 145.25,
    accuracy: 4,
  },
  {
    postcode: 3975,
    place_name: 'Lynbrook',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0559,
    longitude: 145.2562,
    accuracy: 4,
  },
  {
    postcode: 3976,
    place_name: 'Hampton Park',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0333,
    longitude: 145.25,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Botanic Ridge',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1392,
    longitude: 145.2684,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Skye',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1051,
    longitude: 145.2163,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Cannons Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2122,
    longitude: 145.3153,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Devon Meadows',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 145.3,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Cranbourne West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0965,
    longitude: 145.2671,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Cranbourne South',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.135,
    longitude: 145.2396,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Cranbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1134,
    longitude: 145.2833,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Junction Village',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1364,
    longitude: 145.2968,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Cranbourne East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1153,
    longitude: 145.2981,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Five Ways',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 145.3167,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Sandhurst',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.081,
    longitude: 145.2077,
    accuracy: 4,
  },
  {
    postcode: 3977,
    place_name: 'Cranbourne North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.0829,
    longitude: 145.2781,
    accuracy: 4,
  },
  {
    postcode: 3978,
    place_name: 'Clyde North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1167,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3978,
    place_name: 'Cardinia',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.15,
    longitude: 145.4167,
    accuracy: 4,
  },
  {
    postcode: 3978,
    place_name: 'Clyde',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1333,
    longitude: 145.3333,
    accuracy: 4,
  },
  {
    postcode: 3979,
    place_name: 'Glen Alvie',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5167,
    longitude: 145.7,
    accuracy: 4,
  },
  {
    postcode: 3979,
    place_name: 'Kernot',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4333,
    longitude: 145.6,
    accuracy: 4,
  },
  {
    postcode: 3979,
    place_name: 'Almurta',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4667,
    longitude: 145.5833,
    accuracy: 4,
  },
  {
    postcode: 3980,
    place_name: 'Blind Bight',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2143,
    longitude: 145.3377,
    accuracy: 4,
  },
  {
    postcode: 3980,
    place_name: 'Warneet',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2253,
    longitude: 145.3101,
    accuracy: 4,
  },
  {
    postcode: 3980,
    place_name: 'Tooradin',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2147,
    longitude: 145.3833,
    accuracy: 4,
  },
  {
    postcode: 3981,
    place_name: 'Koo Wee Rup North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1667,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3981,
    place_name: 'Heath Hill',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 145.7,
    accuracy: 4,
  },
  {
    postcode: 3981,
    place_name: 'Koo Wee Rup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1994,
    longitude: 145.4908,
    accuracy: 3,
  },
  {
    postcode: 3981,
    place_name: 'Yannathan',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2333,
    longitude: 145.65,
    accuracy: 4,
  },
  {
    postcode: 3981,
    place_name: 'Bayles',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 145.5667,
    accuracy: 4,
  },
  {
    postcode: 3981,
    place_name: 'Dalmore',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.1833,
    longitude: 145.4167,
    accuracy: 4,
  },
  {
    postcode: 3981,
    place_name: 'Catani',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2,
    longitude: 145.6333,
    accuracy: 4,
  },
  {
    postcode: 3984,
    place_name: 'The Gurdies',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3151,
    longitude: 145.5606,
    accuracy: 3,
  },
  {
    postcode: 3984,
    place_name: 'Tenby Point',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.441,
    longitude: 145.5107,
    accuracy: 3,
  },
  {
    postcode: 3984,
    place_name: 'Pioneer Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3807,
    longitude: 145.5495,
    accuracy: 4,
  },
  {
    postcode: 3984,
    place_name: 'Coronet Bay',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4391,
    longitude: 145.4457,
    accuracy: 4,
  },
  {
    postcode: 3984,
    place_name: 'Adams Estate',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3488,
    longitude: 145.5081,
    accuracy: 3,
  },
  {
    postcode: 3984,
    place_name: 'Lang Lang',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.266,
    longitude: 145.5621,
    accuracy: 4,
  },
  {
    postcode: 3984,
    place_name: 'Queensferry',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4167,
    longitude: 145.5,
    accuracy: 4,
  },
  {
    postcode: 3984,
    place_name: 'Grantville',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.407,
    longitude: 145.5301,
    accuracy: 4,
  },
  {
    postcode: 3984,
    place_name: 'Monomeith',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2167,
    longitude: 145.5167,
    accuracy: 4,
  },
  {
    postcode: 3984,
    place_name: 'Jam Jerrup',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3151,
    longitude: 145.5606,
    accuracy: 3,
  },
  {
    postcode: 3984,
    place_name: 'Corinella',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4141,
    longitude: 145.4276,
    accuracy: 4,
  },
  {
    postcode: 3984,
    place_name: 'Lang Lang East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3488,
    longitude: 145.5081,
    accuracy: 3,
  },
  {
    postcode: 3984,
    place_name: 'Caldermeade',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.25,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3987,
    place_name: 'Nyora',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3336,
    longitude: 145.6725,
    accuracy: 4,
  },
  {
    postcode: 3988,
    place_name: 'Poowong',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3428,
    longitude: 145.7638,
    accuracy: 4,
  },
  {
    postcode: 3988,
    place_name: 'Poowong East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 145.8333,
    accuracy: 4,
  },
  {
    postcode: 3988,
    place_name: 'Mountain View',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.2667,
    longitude: 145.8667,
    accuracy: 4,
  },
  {
    postcode: 3988,
    place_name: 'Poowong North',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.3,
    longitude: 145.8,
    accuracy: 4,
  },
  {
    postcode: 3990,
    place_name: 'Glen Forbes',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.45,
    longitude: 145.5333,
    accuracy: 4,
  },
  {
    postcode: 3991,
    place_name: 'Bass',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.4833,
    longitude: 145.45,
    accuracy: 4,
  },
  {
    postcode: 3992,
    place_name: 'Ryanston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5333,
    longitude: 145.5833,
    accuracy: 4,
  },
  {
    postcode: 3992,
    place_name: 'West Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5333,
    longitude: 145.5833,
    accuracy: 3,
  },
  {
    postcode: 3992,
    place_name: 'Dalyston',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5667,
    longitude: 145.55,
    accuracy: 4,
  },
  {
    postcode: 3992,
    place_name: 'Blackwood Forest',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5,
    longitude: 145.6167,
    accuracy: 4,
  },
  {
    postcode: 3995,
    place_name: 'Wattle Bank',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5667,
    longitude: 145.7,
    accuracy: 4,
  },
  {
    postcode: 3995,
    place_name: 'Lance Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5752,
    longitude: 145.5665,
    accuracy: 3,
  },
  {
    postcode: 3995,
    place_name: 'North Wonthaggi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6,
    longitude: 145.6,
    accuracy: 4,
  },
  {
    postcode: 3995,
    place_name: 'Anderson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5333,
    longitude: 145.45,
    accuracy: 4,
  },
  {
    postcode: 3995,
    place_name: 'Kilcunda',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.55,
    longitude: 145.4833,
    accuracy: 4,
  },
  {
    postcode: 3995,
    place_name: 'Harmers Haven',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5752,
    longitude: 145.5665,
    accuracy: 3,
  },
  {
    postcode: 3995,
    place_name: 'Archies Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.55,
    longitude: 145.5667,
    accuracy: 4,
  },
  {
    postcode: 3995,
    place_name: 'Woolamai',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5,
    longitude: 145.5,
    accuracy: 4,
  },
  {
    postcode: 3995,
    place_name: 'South Dudley',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6,
    longitude: 145.5833,
    accuracy: 4,
  },
  {
    postcode: 3995,
    place_name: 'St Clair',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.5752,
    longitude: 145.5665,
    accuracy: 3,
  },
  {
    postcode: 3995,
    place_name: 'Wonthaggi',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6059,
    longitude: 145.5936,
    accuracy: 4,
  },
  {
    postcode: 3995,
    place_name: 'Cape Paterson',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6709,
    longitude: 145.6213,
    accuracy: 4,
  },
  {
    postcode: 3996,
    place_name: 'Pound Creek',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6333,
    longitude: 145.8,
    accuracy: 4,
  },
  {
    postcode: 3996,
    place_name: 'Inverloch',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -38.6266,
    longitude: 145.7226,
    accuracy: 4,
  },
  {
    postcode: 8001,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8002,
    place_name: 'East Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8167,
    longitude: 144.9879,
    accuracy: 4,
  },
  {
    postcode: 8003,
    place_name: 'Collins Street East',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8153,
    longitude: 144.9756,
    accuracy: 1,
  },
  {
    postcode: 8004,
    place_name: 'St Kilda Road',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8153,
    longitude: 144.9756,
    accuracy: 1,
  },
  {
    postcode: 8005,
    place_name: 'World Trade Centre',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8153,
    longitude: 144.9756,
    accuracy: 1,
  },
  {
    postcode: 8006,
    place_name: 'Abeckett Street',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8153,
    longitude: 144.9756,
    accuracy: 1,
  },
  {
    postcode: 8007,
    place_name: 'Collins Street West',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8153,
    longitude: 144.9756,
    accuracy: 1,
  },
  {
    postcode: 8008,
    place_name: 'St Kilda Road Central',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8153,
    longitude: 144.9756,
    accuracy: 1,
  },
  {
    postcode: 8009,
    place_name: 'Flinders Lane',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8153,
    longitude: 144.9756,
    accuracy: 1,
  },
  {
    postcode: 8010,
    place_name: 'Law Courts',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8149,
    longitude: 144.9505,
    accuracy: 1,
  },
  {
    postcode: 8011,
    place_name: 'Little Lonsdale Street',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8149,
    longitude: 144.9505,
    accuracy: 1,
  },
  {
    postcode: 8012,
    place_name: 'Docklands',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8149,
    longitude: 144.9505,
    accuracy: 4,
  },
  {
    postcode: 8045,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8051,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8060,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8061,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8066,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8069,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8070,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8071,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8102,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8103,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8107,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8108,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8111,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8120,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8205,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8383,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8386,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8388,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8390,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8393,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8394,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8396,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8399,
    place_name: 'Melbourne',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.814,
    longitude: 144.9633,
    accuracy: 4,
  },
  {
    postcode: 8627,
    place_name: 'Camberwell',
    state_name: 'Victoria',
    state_code: 'VIC',
    latitude: -37.8421,
    longitude: 145.0694,
    accuracy: 4,
  },
  {
    postcode: 872,
    place_name: 'Ngaanyatjarra-Giles',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Gibson Desert North',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Tjirrkarli',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Wannan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Gibson Desert South',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Patjarr',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Wingellina',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Kiwirrkurra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 872,
    place_name: 'Tjukurla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3256,
    longitude: 133.0557,
    accuracy: 3,
  },
  {
    postcode: 6000,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6000,
    place_name: 'Perth GPO',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 3,
  },
  {
    postcode: 6000,
    place_name: 'City Delivery Centre',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 3,
  },
  {
    postcode: 6001,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6003,
    place_name: 'Northbridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9478,
    longitude: 115.8588,
    accuracy: 4,
  },
  {
    postcode: 6003,
    place_name: 'Highgate',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9397,
    longitude: 115.8701,
    accuracy: 4,
  },
  {
    postcode: 6004,
    place_name: 'East Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9587,
    longitude: 115.8711,
    accuracy: 4,
  },
  {
    postcode: 6005,
    place_name: 'West Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.949,
    longitude: 115.842,
    accuracy: 4,
  },
  {
    postcode: 6005,
    place_name: 'Kings Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.955,
    longitude: 115.8359,
    accuracy: 3,
  },
  {
    postcode: 6006,
    place_name: 'North Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9272,
    longitude: 115.8528,
    accuracy: 4,
  },
  {
    postcode: 6007,
    place_name: 'Leederville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9363,
    longitude: 115.8419,
    accuracy: 4,
  },
  {
    postcode: 6007,
    place_name: 'West Leederville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9414,
    longitude: 115.8312,
    accuracy: 4,
  },
  {
    postcode: 6008,
    place_name: 'Shenton Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9583,
    longitude: 115.7972,
    accuracy: 4,
  },
  {
    postcode: 6008,
    place_name: 'Daglish',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9483,
    longitude: 115.819,
    accuracy: 4,
  },
  {
    postcode: 6008,
    place_name: 'Subiaco',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9485,
    longitude: 115.8268,
    accuracy: 4,
  },
  {
    postcode: 6008,
    place_name: 'Subiaco East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9542,
    longitude: 115.7986,
    accuracy: 3,
  },
  {
    postcode: 6009,
    place_name: 'Nedlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9818,
    longitude: 115.8073,
    accuracy: 4,
  },
  {
    postcode: 6009,
    place_name: 'Broadway Nedlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9853,
    longitude: 115.8052,
    accuracy: 3,
  },
  {
    postcode: 6009,
    place_name: 'Dalkeith',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9952,
    longitude: 115.7998,
    accuracy: 4,
  },
  {
    postcode: 6009,
    place_name: 'Nedlands DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9875,
    longitude: 115.8014,
    accuracy: 3,
  },
  {
    postcode: 6009,
    place_name: 'Crawley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9842,
    longitude: 115.816,
    accuracy: 4,
  },
  {
    postcode: 6010,
    place_name: 'Karrakatta',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9676,
    longitude: 115.798,
    accuracy: 4,
  },
  {
    postcode: 6010,
    place_name: 'Claremont',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9796,
    longitude: 115.7823,
    accuracy: 4,
  },
  {
    postcode: 6010,
    place_name: 'Mount Claremont',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.97,
    longitude: 115.7775,
    accuracy: 3,
  },
  {
    postcode: 6010,
    place_name: 'Swanbourne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9688,
    longitude: 115.7673,
    accuracy: 4,
  },
  {
    postcode: 6010,
    place_name: 'Claremont North',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.97,
    longitude: 115.7775,
    accuracy: 3,
  },
  {
    postcode: 6011,
    place_name: 'Peppermint Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9988,
    longitude: 115.7682,
    accuracy: 4,
  },
  {
    postcode: 6011,
    place_name: 'Cottesloe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32,
    longitude: 115.7667,
    accuracy: 4,
  },
  {
    postcode: 6012,
    place_name: 'Mosman Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0054,
    longitude: 115.7638,
    accuracy: 4,
  },
  {
    postcode: 6014,
    place_name: 'Wembley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9333,
    longitude: 115.8,
    accuracy: 4,
  },
  {
    postcode: 6014,
    place_name: 'Floreat',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9386,
    longitude: 115.7921,
    accuracy: 4,
  },
  {
    postcode: 6014,
    place_name: 'Jolimont',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9456,
    longitude: 115.8095,
    accuracy: 4,
  },
  {
    postcode: 6015,
    place_name: 'City Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9344,
    longitude: 115.7617,
    accuracy: 4,
  },
  {
    postcode: 6016,
    place_name: 'Mount Hawthorn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.92,
    longitude: 115.8351,
    accuracy: 4,
  },
  {
    postcode: 6016,
    place_name: 'Glendalough',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9199,
    longitude: 115.8194,
    accuracy: 4,
  },
  {
    postcode: 6017,
    place_name: 'Herdsman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9106,
    longitude: 115.8055,
    accuracy: 4,
  },
  {
    postcode: 6017,
    place_name: 'Osborne Park DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9007,
    longitude: 115.8108,
    accuracy: 3,
  },
  {
    postcode: 6017,
    place_name: 'Osborne Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9007,
    longitude: 115.8108,
    accuracy: 4,
  },
  {
    postcode: 6018,
    place_name: 'Churchlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9214,
    longitude: 115.7932,
    accuracy: 4,
  },
  {
    postcode: 6018,
    place_name: 'Woodlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8873,
    longitude: 115.7865,
    accuracy: 3,
  },
  {
    postcode: 6018,
    place_name: 'Gwelup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8716,
    longitude: 115.7948,
    accuracy: 4,
  },
  {
    postcode: 6018,
    place_name: 'Innaloo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8927,
    longitude: 115.7951,
    accuracy: 4,
  },
  {
    postcode: 6018,
    place_name: 'Doubleview',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8963,
    longitude: 115.7816,
    accuracy: 4,
  },
  {
    postcode: 6018,
    place_name: 'Gwelup DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8909,
    longitude: 115.7883,
    accuracy: 3,
  },
  {
    postcode: 6018,
    place_name: 'Karrinyup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8723,
    longitude: 115.7768,
    accuracy: 4,
  },
  {
    postcode: 6019,
    place_name: 'Wembley Downs',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9117,
    longitude: 115.7736,
    accuracy: 4,
  },
  {
    postcode: 6019,
    place_name: 'Scarborough',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8958,
    longitude: 115.7643,
    accuracy: 4,
  },
  {
    postcode: 6020,
    place_name: 'North Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.863,
    longitude: 115.7562,
    accuracy: 4,
  },
  {
    postcode: 6020,
    place_name: 'Marmion',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8416,
    longitude: 115.7569,
    accuracy: 4,
  },
  {
    postcode: 6020,
    place_name: 'Watermans Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8492,
    longitude: 115.7559,
    accuracy: 4,
  },
  {
    postcode: 6020,
    place_name: 'Sorrento',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8253,
    longitude: 115.7525,
    accuracy: 4,
  },
  {
    postcode: 6020,
    place_name: 'Carine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8523,
    longitude: 115.7826,
    accuracy: 4,
  },
  {
    postcode: 6021,
    place_name: 'Stirling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8834,
    longitude: 115.8099,
    accuracy: 4,
  },
  {
    postcode: 6021,
    place_name: 'Balcatta',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.875,
    longitude: 115.8284,
    accuracy: 4,
  },
  {
    postcode: 6022,
    place_name: 'Hamersley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.849,
    longitude: 115.8076,
    accuracy: 4,
  },
  {
    postcode: 6023,
    place_name: 'Duncraig',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8329,
    longitude: 115.7759,
    accuracy: 4,
  },
  {
    postcode: 6024,
    place_name: 'Warwick',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8397,
    longitude: 115.8086,
    accuracy: 4,
  },
  {
    postcode: 6024,
    place_name: 'Greenwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8272,
    longitude: 115.8025,
    accuracy: 4,
  },
  {
    postcode: 6025,
    place_name: 'Hillarys',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.807,
    longitude: 115.7405,
    accuracy: 4,
  },
  {
    postcode: 6025,
    place_name: 'Craigie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7873,
    longitude: 115.7699,
    accuracy: 4,
  },
  {
    postcode: 6025,
    place_name: 'Kallaroo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.791,
    longitude: 115.753,
    accuracy: 4,
  },
  {
    postcode: 6025,
    place_name: 'Padbury',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8064,
    longitude: 115.7688,
    accuracy: 4,
  },
  {
    postcode: 6026,
    place_name: 'Kingsley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8104,
    longitude: 115.8008,
    accuracy: 4,
  },
  {
    postcode: 6026,
    place_name: 'Woodvale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7893,
    longitude: 115.7968,
    accuracy: 4,
  },
  {
    postcode: 6027,
    place_name: 'Ocean Reef',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7587,
    longitude: 115.736,
    accuracy: 4,
  },
  {
    postcode: 6027,
    place_name: 'Beldon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7747,
    longitude: 115.7627,
    accuracy: 4,
  },
  {
    postcode: 6027,
    place_name: 'Joondalup DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7619,
    longitude: 115.7565,
    accuracy: 3,
  },
  {
    postcode: 6027,
    place_name: 'Connolly',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7486,
    longitude: 115.7505,
    accuracy: 4,
  },
  {
    postcode: 6027,
    place_name: 'Heathridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7624,
    longitude: 115.7589,
    accuracy: 4,
  },
  {
    postcode: 6027,
    place_name: 'Edgewater',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7655,
    longitude: 115.7822,
    accuracy: 4,
  },
  {
    postcode: 6027,
    place_name: 'Mullaloo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.779,
    longitude: 115.7368,
    accuracy: 4,
  },
  {
    postcode: 6027,
    place_name: 'Joondalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7444,
    longitude: 115.7683,
    accuracy: 4,
  },
  {
    postcode: 6028,
    place_name: 'Iluka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7355,
    longitude: 115.7306,
    accuracy: 4,
  },
  {
    postcode: 6028,
    place_name: 'Currambine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7331,
    longitude: 115.7477,
    accuracy: 4,
  },
  {
    postcode: 6028,
    place_name: 'Kinross',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7187,
    longitude: 115.7384,
    accuracy: 4,
  },
  {
    postcode: 6028,
    place_name: 'Burns Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7206,
    longitude: 115.7205,
    accuracy: 4,
  },
  {
    postcode: 6029,
    place_name: 'Trigg',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8694,
    longitude: 115.7571,
    accuracy: 4,
  },
  {
    postcode: 6030,
    place_name: 'Merriwa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6649,
    longitude: 115.7127,
    accuracy: 4,
  },
  {
    postcode: 6030,
    place_name: 'Tamala Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7061,
    longitude: 115.7201,
    accuracy: 4,
  },
  {
    postcode: 6030,
    place_name: 'Clarkson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6828,
    longitude: 115.7247,
    accuracy: 4,
  },
  {
    postcode: 6030,
    place_name: 'Quinns Rocks',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6738,
    longitude: 115.7037,
    accuracy: 4,
  },
  {
    postcode: 6030,
    place_name: 'Ridgewood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6615,
    longitude: 115.7226,
    accuracy: 4,
  },
  {
    postcode: 6030,
    place_name: 'Mindarie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6893,
    longitude: 115.707,
    accuracy: 4,
  },
  {
    postcode: 6031,
    place_name: 'Neerabup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6734,
    longitude: 115.7517,
    accuracy: 4,
  },
  {
    postcode: 6031,
    place_name: 'Banksia Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6962,
    longitude: 115.805,
    accuracy: 4,
  },
  {
    postcode: 6031,
    place_name: 'Carramar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6902,
    longitude: 115.7716,
    accuracy: 4,
  },
  {
    postcode: 6032,
    place_name: 'Nowergup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6371,
    longitude: 115.7625,
    accuracy: 4,
  },
  {
    postcode: 6033,
    place_name: 'Carabooda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6058,
    longitude: 115.7144,
    accuracy: 4,
  },
  {
    postcode: 6034,
    place_name: 'Eglinton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5868,
    longitude: 115.6888,
    accuracy: 4,
  },
  {
    postcode: 6035,
    place_name: 'Yanchep',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5468,
    longitude: 115.6317,
    accuracy: 4,
  },
  {
    postcode: 6036,
    place_name: 'Butler',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.643,
    longitude: 115.7043,
    accuracy: 4,
  },
  {
    postcode: 6036,
    place_name: 'Jindalee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6494,
    longitude: 115.695,
    accuracy: 4,
  },
  {
    postcode: 6037,
    place_name: 'Two Rocks',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4998,
    longitude: 115.5874,
    accuracy: 4,
  },
  {
    postcode: 6038,
    place_name: 'Alkimos',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6304,
    longitude: 115.6864,
    accuracy: 4,
  },
  {
    postcode: 6041,
    place_name: 'Wilbinga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3591,
    longitude: 115.5608,
    accuracy: 3,
  },
  {
    postcode: 6041,
    place_name: 'Gabbadah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.292,
    longitude: 115.5059,
    accuracy: 4,
  },
  {
    postcode: 6041,
    place_name: 'Caraban',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3591,
    longitude: 115.5608,
    accuracy: 3,
  },
  {
    postcode: 6041,
    place_name: 'Woodridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3338,
    longitude: 115.5959,
    accuracy: 4,
  },
  {
    postcode: 6041,
    place_name: 'Guilderton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3451,
    longitude: 115.499,
    accuracy: 4,
  },
  {
    postcode: 6042,
    place_name: 'Seabird',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2772,
    longitude: 115.4439,
    accuracy: 4,
  },
  {
    postcode: 6043,
    place_name: 'Ledge Point',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1002,
    longitude: 115.3909,
    accuracy: 4,
  },
  {
    postcode: 6043,
    place_name: 'Breton Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1025,
    longitude: 115.3766,
    accuracy: 3,
  },
  {
    postcode: 6044,
    place_name: 'Lancelin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0225,
    longitude: 115.3365,
    accuracy: 4,
  },
  {
    postcode: 6044,
    place_name: 'Wedge Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8531,
    longitude: 115.3072,
    accuracy: 3,
  },
  {
    postcode: 6044,
    place_name: 'Karakin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9704,
    longitude: 115.3976,
    accuracy: 3,
  },
  {
    postcode: 6044,
    place_name: 'Nilgen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9704,
    longitude: 115.3976,
    accuracy: 3,
  },
  {
    postcode: 6050,
    place_name: 'Menora',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9189,
    longitude: 115.8621,
    accuracy: 4,
  },
  {
    postcode: 6050,
    place_name: 'Coolbinia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9147,
    longitude: 115.8489,
    accuracy: 4,
  },
  {
    postcode: 6050,
    place_name: 'Mount Lawley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9344,
    longitude: 115.8716,
    accuracy: 4,
  },
  {
    postcode: 6051,
    place_name: 'Maylands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.931,
    longitude: 115.8949,
    accuracy: 4,
  },
  {
    postcode: 6052,
    place_name: 'Inglewood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9166,
    longitude: 115.8798,
    accuracy: 4,
  },
  {
    postcode: 6052,
    place_name: 'Bedford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9113,
    longitude: 115.8892,
    accuracy: 4,
  },
  {
    postcode: 6053,
    place_name: 'Bayswater',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9169,
    longitude: 115.9178,
    accuracy: 4,
  },
  {
    postcode: 6054,
    place_name: 'Ashfield',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9172,
    longitude: 115.938,
    accuracy: 4,
  },
  {
    postcode: 6054,
    place_name: 'Bassendean',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9091,
    longitude: 115.9437,
    accuracy: 4,
  },
  {
    postcode: 6054,
    place_name: 'Eden Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8891,
    longitude: 115.9467,
    accuracy: 4,
  },
  {
    postcode: 6054,
    place_name: 'Lockridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8817,
    longitude: 115.9503,
    accuracy: 4,
  },
  {
    postcode: 6054,
    place_name: 'Kiara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8816,
    longitude: 115.9389,
    accuracy: 4,
  },
  {
    postcode: 6055,
    place_name: 'Hazelmere',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9174,
    longitude: 116.0118,
    accuracy: 4,
  },
  {
    postcode: 6055,
    place_name: 'Henley Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8139,
    longitude: 115.9881,
    accuracy: 4,
  },
  {
    postcode: 6055,
    place_name: 'West Swan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8476,
    longitude: 115.9799,
    accuracy: 3,
  },
  {
    postcode: 6055,
    place_name: 'Brabham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8273,
    longitude: 115.9742,
    accuracy: 4,
  },
  {
    postcode: 6055,
    place_name: 'Caversham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8833,
    longitude: 115.9667,
    accuracy: 4,
  },
  {
    postcode: 6055,
    place_name: 'South Guildford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9122,
    longitude: 115.9682,
    accuracy: 4,
  },
  {
    postcode: 6055,
    place_name: 'Guildford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9,
    longitude: 115.9667,
    accuracy: 4,
  },
  {
    postcode: 6055,
    place_name: 'Dayton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8525,
    longitude: 115.9749,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Koongamia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9066,
    longitude: 116.0422,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Viveash',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8777,
    longitude: 115.9975,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Jane Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8637,
    longitude: 116.0504,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Midvale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8888,
    longitude: 116.0329,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Boya',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9333,
    longitude: 116.05,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Woodbridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8869,
    longitude: 115.992,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Greenmount',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9007,
    longitude: 116.0495,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Red Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.849,
    longitude: 116.0584,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Helena Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9194,
    longitude: 116.0398,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Midland',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9,
    longitude: 116,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Baskerville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7978,
    longitude: 116.0436,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Bellevue',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9,
    longitude: 116.0167,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Middle Swan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.85,
    longitude: 116.0167,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Millendon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8167,
    longitude: 116.0333,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Stratton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8683,
    longitude: 116.0407,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Swan View',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8833,
    longitude: 116.05,
    accuracy: 4,
  },
  {
    postcode: 6056,
    place_name: 'Herne Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8255,
    longitude: 116.0227,
    accuracy: 4,
  },
  {
    postcode: 6057,
    place_name: 'High Wycombe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9444,
    longitude: 116.0034,
    accuracy: 4,
  },
  {
    postcode: 6057,
    place_name: 'Maida Vale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9519,
    longitude: 116.0276,
    accuracy: 4,
  },
  {
    postcode: 6058,
    place_name: 'Forrestfield',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9828,
    longitude: 116.0067,
    accuracy: 4,
  },
  {
    postcode: 6059,
    place_name: 'Dianella',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8882,
    longitude: 115.8719,
    accuracy: 4,
  },
  {
    postcode: 6060,
    place_name: 'Joondanna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9077,
    longitude: 115.8423,
    accuracy: 4,
  },
  {
    postcode: 6060,
    place_name: 'Dog Swamp',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.902,
    longitude: 115.8455,
    accuracy: 3,
  },
  {
    postcode: 6060,
    place_name: 'Tuart Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8981,
    longitude: 115.8349,
    accuracy: 4,
  },
  {
    postcode: 6060,
    place_name: 'Yokine South',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8996,
    longitude: 115.8441,
    accuracy: 3,
  },
  {
    postcode: 6060,
    place_name: 'Yokine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9011,
    longitude: 115.8532,
    accuracy: 4,
  },
  {
    postcode: 6061,
    place_name: 'Westminster',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8674,
    longitude: 115.8392,
    accuracy: 4,
  },
  {
    postcode: 6061,
    place_name: 'Balga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.855,
    longitude: 115.8399,
    accuracy: 4,
  },
  {
    postcode: 6061,
    place_name: 'Mirrabooka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8596,
    longitude: 115.8659,
    accuracy: 4,
  },
  {
    postcode: 6061,
    place_name: 'Nollamara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8802,
    longitude: 115.8454,
    accuracy: 4,
  },
  {
    postcode: 6062,
    place_name: 'Embleton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9035,
    longitude: 115.909,
    accuracy: 4,
  },
  {
    postcode: 6062,
    place_name: 'Morley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8877,
    longitude: 115.9099,
    accuracy: 4,
  },
  {
    postcode: 6062,
    place_name: 'Noranda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8733,
    longitude: 115.899,
    accuracy: 4,
  },
  {
    postcode: 6063,
    place_name: 'Beechboro',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8651,
    longitude: 115.9366,
    accuracy: 4,
  },
  {
    postcode: 6063,
    place_name: 'Bennett Springs',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8651,
    longitude: 115.9366,
    accuracy: 3,
  },
  {
    postcode: 6064,
    place_name: 'Girrawheen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.841,
    longitude: 115.8398,
    accuracy: 4,
  },
  {
    postcode: 6064,
    place_name: 'Alexander Heights',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8276,
    longitude: 115.865,
    accuracy: 4,
  },
  {
    postcode: 6064,
    place_name: 'Marangaroo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8271,
    longitude: 115.8384,
    accuracy: 4,
  },
  {
    postcode: 6064,
    place_name: 'Koondoola',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8415,
    longitude: 115.8665,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Madeley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8111,
    longitude: 115.8281,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Gnangara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7761,
    longitude: 115.8662,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Sinagra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7426,
    longitude: 115.8063,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Hocking',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7701,
    longitude: 115.8183,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Darch',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8125,
    longitude: 115.8458,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Wangara DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7671,
    longitude: 115.8255,
    accuracy: 3,
  },
  {
    postcode: 6065,
    place_name: 'Pearsall',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7812,
    longitude: 115.8182,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Tapping',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7195,
    longitude: 115.7956,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Wanneroo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.75,
    longitude: 115.8,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Kingsway',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6923,
    longitude: 115.8437,
    accuracy: 3,
  },
  {
    postcode: 6065,
    place_name: 'Wangara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7914,
    longitude: 115.8203,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Landsdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8075,
    longitude: 115.866,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Lexia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7934,
    longitude: 115.9215,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Mariginiup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7111,
    longitude: 115.8432,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Ashby',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7325,
    longitude: 115.7977,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Jandabup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.755,
    longitude: 115.8607,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Pinjar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6936,
    longitude: 115.8431,
    accuracy: 4,
  },
  {
    postcode: 6065,
    place_name: 'Melaleuca',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7745,
    longitude: 115.9176,
    accuracy: 4,
  },
  {
    postcode: 6066,
    place_name: 'Ballajura',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8406,
    longitude: 115.8955,
    accuracy: 4,
  },
  {
    postcode: 6067,
    place_name: 'Cullacabardee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8194,
    longitude: 115.9055,
    accuracy: 4,
  },
  {
    postcode: 6068,
    place_name: 'Whiteman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8245,
    longitude: 115.9416,
    accuracy: 4,
  },
  {
    postcode: 6069,
    place_name: 'Aveley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7813,
    longitude: 115.9896,
    accuracy: 4,
  },
  {
    postcode: 6069,
    place_name: 'Upper Swan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7695,
    longitude: 116.0273,
    accuracy: 4,
  },
  {
    postcode: 6069,
    place_name: 'The Vines',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7549,
    longitude: 116.0024,
    accuracy: 4,
  },
  {
    postcode: 6069,
    place_name: 'Ellenbrook East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7673,
    longitude: 116.0259,
    accuracy: 3,
  },
  {
    postcode: 6069,
    place_name: 'Belhus',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7816,
    longitude: 116.0095,
    accuracy: 4,
  },
  {
    postcode: 6069,
    place_name: 'Ellenbrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7674,
    longitude: 115.9694,
    accuracy: 4,
  },
  {
    postcode: 6069,
    place_name: 'Brigadoon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7726,
    longitude: 116.0727,
    accuracy: 4,
  },
  {
    postcode: 6070,
    place_name: 'Darlington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9191,
    longitude: 116.0812,
    accuracy: 4,
  },
  {
    postcode: 6071,
    place_name: 'Hovea',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8833,
    longitude: 116.1,
    accuracy: 4,
  },
  {
    postcode: 6071,
    place_name: 'Glen Forrest',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9193,
    longitude: 116.1073,
    accuracy: 4,
  },
  {
    postcode: 6072,
    place_name: 'Mahogany Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9095,
    longitude: 116.1043,
    accuracy: 4,
  },
  {
    postcode: 6073,
    place_name: 'Mundaring DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9021,
    longitude: 116.1676,
    accuracy: 3,
  },
  {
    postcode: 6073,
    place_name: 'Mundaring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9021,
    longitude: 116.1676,
    accuracy: 4,
  },
  {
    postcode: 6074,
    place_name: 'Sawyers Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9023,
    longitude: 116.2007,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Bickley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32,
    longitude: 116.0833,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Paulls Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9692,
    longitude: 116.1094,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Reservoir',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9647,
    longitude: 116.166,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Carmel',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0167,
    longitude: 116.1,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Lesmurdie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.002,
    longitude: 116.0482,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Hacketts Gully',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9774,
    longitude: 116.0928,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Kalamunda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9868,
    longitude: 116.0704,
    accuracy: 3,
  },
  {
    postcode: 6076,
    place_name: 'Piesse Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.972,
    longitude: 116.0779,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Gooseberry Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9544,
    longitude: 116.0492,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Pickering Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0333,
    longitude: 116.1,
    accuracy: 4,
  },
  {
    postcode: 6076,
    place_name: 'Walliston',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9968,
    longitude: 116.0743,
    accuracy: 4,
  },
  {
    postcode: 6077,
    place_name: 'Gnangara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7761,
    longitude: 115.8662,
    accuracy: 4,
  },
  {
    postcode: 6077,
    place_name: 'Jandabup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.755,
    longitude: 115.8607,
    accuracy: 4,
  },
  {
    postcode: 6078,
    place_name: 'Pinjar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6936,
    longitude: 115.8431,
    accuracy: 4,
  },
  {
    postcode: 6078,
    place_name: 'Mariginiup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7111,
    longitude: 115.8432,
    accuracy: 4,
  },
  {
    postcode: 6079,
    place_name: 'Melaleuca',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7745,
    longitude: 115.9176,
    accuracy: 4,
  },
  {
    postcode: 6079,
    place_name: 'Lexia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7934,
    longitude: 115.9215,
    accuracy: 4,
  },
  {
    postcode: 6081,
    place_name: 'Stoneville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8783,
    longitude: 116.1707,
    accuracy: 4,
  },
  {
    postcode: 6081,
    place_name: 'Parkerville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8747,
    longitude: 116.138,
    accuracy: 4,
  },
  {
    postcode: 6082,
    place_name: 'Mount Helena',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8761,
    longitude: 116.2103,
    accuracy: 4,
  },
  {
    postcode: 6082,
    place_name: 'Bailup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7392,
    longitude: 116.3102,
    accuracy: 4,
  },
  {
    postcode: 6083,
    place_name: 'Morangup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.599,
    longitude: 116.3541,
    accuracy: 3,
  },
  {
    postcode: 6083,
    place_name: 'Gidgegannup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7544,
    longitude: 116.1875,
    accuracy: 4,
  },
  {
    postcode: 6084,
    place_name: 'Chittering',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4677,
    longitude: 116.0801,
    accuracy: 4,
  },
  {
    postcode: 6084,
    place_name: 'Lower Chittering',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5833,
    longitude: 116.1167,
    accuracy: 4,
  },
  {
    postcode: 6084,
    place_name: 'Bullsbrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.669,
    longitude: 115.9999,
    accuracy: 4,
  },
  {
    postcode: 6084,
    place_name: 'Walyunga National Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5924,
    longitude: 116.1014,
    accuracy: 3,
  },
  {
    postcode: 6084,
    place_name: 'Avon Valley National Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6361,
    longitude: 116.1887,
    accuracy: 4,
  },
  {
    postcode: 6090,
    place_name: 'Malaga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8526,
    longitude: 115.881,
    accuracy: 4,
  },
  {
    postcode: 6100,
    place_name: 'Burswood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9587,
    longitude: 115.904,
    accuracy: 4,
  },
  {
    postcode: 6100,
    place_name: 'Lathlain',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9676,
    longitude: 115.9063,
    accuracy: 4,
  },
  {
    postcode: 6100,
    place_name: 'Victoria Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9762,
    longitude: 115.9052,
    accuracy: 4,
  },
  {
    postcode: 6101,
    place_name: 'Carlisle South',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9842,
    longitude: 115.911,
    accuracy: 3,
  },
  {
    postcode: 6101,
    place_name: 'Carlisle',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9795,
    longitude: 115.9181,
    accuracy: 4,
  },
  {
    postcode: 6101,
    place_name: 'East Victoria Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9889,
    longitude: 115.9039,
    accuracy: 4,
  },
  {
    postcode: 6101,
    place_name: 'Carlisle North',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9842,
    longitude: 115.911,
    accuracy: 3,
  },
  {
    postcode: 6102,
    place_name: 'Bentley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32,
    longitude: 115.9167,
    accuracy: 4,
  },
  {
    postcode: 6102,
    place_name: 'St James',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32,
    longitude: 115.9098,
    accuracy: 4,
  },
  {
    postcode: 6102,
    place_name: 'Bentley DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32,
    longitude: 115.9132,
    accuracy: 3,
  },
  {
    postcode: 6102,
    place_name: 'Bentley South',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32,
    longitude: 115.9132,
    accuracy: 3,
  },
  {
    postcode: 6103,
    place_name: 'Rivervale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9555,
    longitude: 115.9131,
    accuracy: 4,
  },
  {
    postcode: 6104,
    place_name: 'Redcliffe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9384,
    longitude: 115.9464,
    accuracy: 4,
  },
  {
    postcode: 6104,
    place_name: 'Belmont',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9435,
    longitude: 115.9255,
    accuracy: 4,
  },
  {
    postcode: 6104,
    place_name: 'Ascot',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9382,
    longitude: 115.9226,
    accuracy: 4,
  },
  {
    postcode: 6105,
    place_name: 'Perth Airport',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9321,
    longitude: 115.9564,
    accuracy: 4,
  },
  {
    postcode: 6105,
    place_name: 'Kewdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9789,
    longitude: 115.9516,
    accuracy: 4,
  },
  {
    postcode: 6105,
    place_name: 'Cloverdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9628,
    longitude: 115.9443,
    accuracy: 4,
  },
  {
    postcode: 6106,
    place_name: 'Welshpool',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9944,
    longitude: 115.9222,
    accuracy: 4,
  },
  {
    postcode: 6107,
    place_name: 'Wattle Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0067,
    longitude: 116.0005,
    accuracy: 4,
  },
  {
    postcode: 6107,
    place_name: 'Queens Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0031,
    longitude: 115.9462,
    accuracy: 4,
  },
  {
    postcode: 6107,
    place_name: 'Kenwick',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0333,
    longitude: 115.9667,
    accuracy: 4,
  },
  {
    postcode: 6107,
    place_name: 'Beckenham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0248,
    longitude: 115.9599,
    accuracy: 4,
  },
  {
    postcode: 6107,
    place_name: 'East Cannington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0098,
    longitude: 115.955,
    accuracy: 4,
  },
  {
    postcode: 6107,
    place_name: 'Wilson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0206,
    longitude: 115.9118,
    accuracy: 4,
  },
  {
    postcode: 6107,
    place_name: 'Cannington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0167,
    longitude: 115.95,
    accuracy: 4,
  },
  {
    postcode: 6108,
    place_name: 'Thornlie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.06,
    longitude: 115.955,
    accuracy: 4,
  },
  {
    postcode: 6109,
    place_name: 'Maddington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.05,
    longitude: 115.9833,
    accuracy: 4,
  },
  {
    postcode: 6109,
    place_name: 'Orange Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0293,
    longitude: 116.0057,
    accuracy: 4,
  },
  {
    postcode: 6110,
    place_name: 'Gosnells',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.081,
    longitude: 116.0054,
    accuracy: 4,
  },
  {
    postcode: 6110,
    place_name: 'Huntingdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0832,
    longitude: 115.9661,
    accuracy: 4,
  },
  {
    postcode: 6110,
    place_name: 'Southern River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1066,
    longitude: 115.959,
    accuracy: 4,
  },
  {
    postcode: 6110,
    place_name: 'Martin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0755,
    longitude: 116.0454,
    accuracy: 4,
  },
  {
    postcode: 6111,
    place_name: 'Champion Lakes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1191,
    longitude: 115.9862,
    accuracy: 4,
  },
  {
    postcode: 6111,
    place_name: 'Ashendon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1831,
    longitude: 116.1341,
    accuracy: 4,
  },
  {
    postcode: 6111,
    place_name: 'Kelmscott',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1243,
    longitude: 116.0259,
    accuracy: 4,
  },
  {
    postcode: 6111,
    place_name: 'Camillo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1129,
    longitude: 116.0021,
    accuracy: 4,
  },
  {
    postcode: 6111,
    place_name: 'Roleystone',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1146,
    longitude: 116.0706,
    accuracy: 4,
  },
  {
    postcode: 6111,
    place_name: 'Karragullen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1,
    longitude: 116.1333,
    accuracy: 4,
  },
  {
    postcode: 6111,
    place_name: 'Canning Mills',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0804,
    longitude: 116.1047,
    accuracy: 4,
  },
  {
    postcode: 6111,
    place_name: 'Lesley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.185,
    longitude: 116.2075,
    accuracy: 4,
  },
  {
    postcode: 6111,
    place_name: 'Kelmscott DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1286,
    longitude: 116.0542,
    accuracy: 3,
  },
  {
    postcode: 6111,
    place_name: 'Westfield',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.15,
    longitude: 115.9667,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Mount Nasura',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1378,
    longitude: 116.0247,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Piara Waters',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1334,
    longitude: 115.9163,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Wungong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1802,
    longitude: 116.0095,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Forrestdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1552,
    longitude: 115.9342,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Hilbert',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1782,
    longitude: 115.9849,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Harrisdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1108,
    longitude: 115.9356,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Mount Richon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1735,
    longitude: 116.0214,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Brookdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.167,
    longitude: 116.0019,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Haynes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.162,
    longitude: 115.9815,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Bedfordale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1741,
    longitude: 116.0504,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Armadale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1461,
    longitude: 116.0093,
    accuracy: 4,
  },
  {
    postcode: 6112,
    place_name: 'Seville Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1364,
    longitude: 115.989,
    accuracy: 4,
  },
  {
    postcode: 6121,
    place_name: 'Oldbury',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2657,
    longitude: 115.9183,
    accuracy: 4,
  },
  {
    postcode: 6121,
    place_name: 'Oakford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2009,
    longitude: 115.9339,
    accuracy: 4,
  },
  {
    postcode: 6122,
    place_name: 'Byford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.221,
    longitude: 116.009,
    accuracy: 4,
  },
  {
    postcode: 6122,
    place_name: 'Karrakup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2233,
    longitude: 116.035,
    accuracy: 4,
  },
  {
    postcode: 6122,
    place_name: 'Cardup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.25,
    longitude: 116,
    accuracy: 4,
  },
  {
    postcode: 6122,
    place_name: 'Darling Downs',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1944,
    longitude: 115.9919,
    accuracy: 4,
  },
  {
    postcode: 6123,
    place_name: 'Whitby',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.292,
    longitude: 116.0124,
    accuracy: 4,
  },
  {
    postcode: 6123,
    place_name: 'Mundijong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2922,
    longitude: 115.9856,
    accuracy: 4,
  },
  {
    postcode: 6124,
    place_name: 'Jarrahdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3358,
    longitude: 116.0595,
    accuracy: 4,
  },
  {
    postcode: 6125,
    place_name: 'Serpentine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3605,
    longitude: 115.9772,
    accuracy: 4,
  },
  {
    postcode: 6125,
    place_name: 'Hopeland',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3816,
    longitude: 115.8873,
    accuracy: 4,
  },
  {
    postcode: 6125,
    place_name: 'Mardella',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3333,
    longitude: 115.9833,
    accuracy: 4,
  },
  {
    postcode: 6126,
    place_name: 'Keysbrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4333,
    longitude: 115.9667,
    accuracy: 4,
  },
  {
    postcode: 6147,
    place_name: 'Lynwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0401,
    longitude: 115.9289,
    accuracy: 4,
  },
  {
    postcode: 6147,
    place_name: 'Langford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0409,
    longitude: 115.9416,
    accuracy: 4,
  },
  {
    postcode: 6147,
    place_name: 'Parkwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0491,
    longitude: 115.9184,
    accuracy: 4,
  },
  {
    postcode: 6148,
    place_name: 'Shelley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0296,
    longitude: 115.8866,
    accuracy: 4,
  },
  {
    postcode: 6148,
    place_name: 'Rossmoyne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0397,
    longitude: 115.8678,
    accuracy: 4,
  },
  {
    postcode: 6148,
    place_name: 'Ferndale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0349,
    longitude: 115.9223,
    accuracy: 4,
  },
  {
    postcode: 6148,
    place_name: 'Riverton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0347,
    longitude: 115.8986,
    accuracy: 4,
  },
  {
    postcode: 6149,
    place_name: 'Bull Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0563,
    longitude: 115.8624,
    accuracy: 4,
  },
  {
    postcode: 6149,
    place_name: 'Leeming',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0751,
    longitude: 115.8661,
    accuracy: 4,
  },
  {
    postcode: 6150,
    place_name: 'Murdoch',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0699,
    longitude: 115.8376,
    accuracy: 4,
  },
  {
    postcode: 6150,
    place_name: 'Bateman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0555,
    longitude: 115.8474,
    accuracy: 4,
  },
  {
    postcode: 6150,
    place_name: 'Winthrop',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0522,
    longitude: 115.8297,
    accuracy: 4,
  },
  {
    postcode: 6151,
    place_name: 'South Perth Angelo St',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9833,
    longitude: 115.8667,
    accuracy: 3,
  },
  {
    postcode: 6151,
    place_name: 'South Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9833,
    longitude: 115.8667,
    accuracy: 4,
  },
  {
    postcode: 6151,
    place_name: 'Kensington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.981,
    longitude: 115.8696,
    accuracy: 3,
  },
  {
    postcode: 6152,
    place_name: 'Waterford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0157,
    longitude: 115.8866,
    accuracy: 4,
  },
  {
    postcode: 6152,
    place_name: 'Como',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9912,
    longitude: 115.8634,
    accuracy: 4,
  },
  {
    postcode: 6152,
    place_name: 'Karawara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0086,
    longitude: 115.8811,
    accuracy: 4,
  },
  {
    postcode: 6152,
    place_name: 'Manning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0159,
    longitude: 115.8671,
    accuracy: 4,
  },
  {
    postcode: 6152,
    place_name: 'Salter Point',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0238,
    longitude: 115.8658,
    accuracy: 4,
  },
  {
    postcode: 6153,
    place_name: 'Mount Pleasant',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0279,
    longitude: 115.8489,
    accuracy: 4,
  },
  {
    postcode: 6153,
    place_name: 'Ardross',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.027,
    longitude: 115.8355,
    accuracy: 4,
  },
  {
    postcode: 6153,
    place_name: 'Applecross North',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0323,
    longitude: 115.8367,
    accuracy: 3,
  },
  {
    postcode: 6153,
    place_name: 'Brentwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0435,
    longitude: 115.8525,
    accuracy: 4,
  },
  {
    postcode: 6153,
    place_name: 'Canning Bridge Applecross',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0323,
    longitude: 115.8367,
    accuracy: 3,
  },
  {
    postcode: 6153,
    place_name: 'Applecross',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0167,
    longitude: 115.8333,
    accuracy: 4,
  },
  {
    postcode: 6154,
    place_name: 'Myaree',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.04,
    longitude: 115.8176,
    accuracy: 4,
  },
  {
    postcode: 6154,
    place_name: 'Booragoon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0395,
    longitude: 115.8337,
    accuracy: 4,
  },
  {
    postcode: 6154,
    place_name: 'Alfred Cove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0332,
    longitude: 115.8126,
    accuracy: 4,
  },
  {
    postcode: 6155,
    place_name: 'Canning Vale East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0552,
    longitude: 115.903,
    accuracy: 3,
  },
  {
    postcode: 6155,
    place_name: 'Willetton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0525,
    longitude: 115.8878,
    accuracy: 4,
  },
  {
    postcode: 6155,
    place_name: 'Canning Vale DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0552,
    longitude: 115.903,
    accuracy: 3,
  },
  {
    postcode: 6155,
    place_name: 'Canning Vale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.058,
    longitude: 115.9181,
    accuracy: 4,
  },
  {
    postcode: 6155,
    place_name: 'Canning Vale South',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.074,
    longitude: 115.9095,
    accuracy: 3,
  },
  {
    postcode: 6156,
    place_name: 'Melville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0404,
    longitude: 115.8012,
    accuracy: 4,
  },
  {
    postcode: 6156,
    place_name: 'Willagee Central',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0373,
    longitude: 115.8033,
    accuracy: 3,
  },
  {
    postcode: 6156,
    place_name: 'Attadale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0249,
    longitude: 115.802,
    accuracy: 4,
  },
  {
    postcode: 6156,
    place_name: 'Willagee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.052,
    longitude: 115.8053,
    accuracy: 4,
  },
  {
    postcode: 6157,
    place_name: 'Palmyra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.045,
    longitude: 115.7859,
    accuracy: 4,
  },
  {
    postcode: 6157,
    place_name: 'Palmyra DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0364,
    longitude: 115.7846,
    accuracy: 3,
  },
  {
    postcode: 6157,
    place_name: 'Bicton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0278,
    longitude: 115.7833,
    accuracy: 4,
  },
  {
    postcode: 6158,
    place_name: 'East Fremantle',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0382,
    longitude: 115.7676,
    accuracy: 4,
  },
  {
    postcode: 6159,
    place_name: 'North Fremantle',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0333,
    longitude: 115.75,
    accuracy: 4,
  },
  {
    postcode: 6160,
    place_name: 'Fremantle',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.05,
    longitude: 115.7667,
    accuracy: 4,
  },
  {
    postcode: 6161,
    place_name: 'Rottnest Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9952,
    longitude: 115.5404,
    accuracy: 4,
  },
  {
    postcode: 6162,
    place_name: 'White Gum Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0603,
    longitude: 115.7706,
    accuracy: 4,
  },
  {
    postcode: 6162,
    place_name: 'South Fremantle',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0691,
    longitude: 115.7545,
    accuracy: 4,
  },
  {
    postcode: 6162,
    place_name: 'Beaconsfield',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0675,
    longitude: 115.764,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: 'Bibra Lake',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0976,
    longitude: 115.8192,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: 'Hamilton Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0845,
    longitude: 115.7795,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: 'Spearwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1053,
    longitude: 115.778,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: 'North Lake',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0819,
    longitude: 115.8357,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: "O'Connor",
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0581,
    longitude: 115.7973,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: 'Hilton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0621,
    longitude: 115.7805,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: 'Kardinya',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0656,
    longitude: 115.8148,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: 'Bibra Lake DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0799,
    longitude: 115.8042,
    accuracy: 3,
  },
  {
    postcode: 6163,
    place_name: 'Samson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.07,
    longitude: 115.7977,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: 'Coolbellup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0812,
    longitude: 115.8061,
    accuracy: 4,
  },
  {
    postcode: 6163,
    place_name: 'North Coogee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0799,
    longitude: 115.8042,
    accuracy: 3,
  },
  {
    postcode: 6164,
    place_name: 'Atwell',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1435,
    longitude: 115.8653,
    accuracy: 4,
  },
  {
    postcode: 6164,
    place_name: 'Banjup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1418,
    longitude: 115.8867,
    accuracy: 4,
  },
  {
    postcode: 6164,
    place_name: 'Success',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1428,
    longitude: 115.85,
    accuracy: 4,
  },
  {
    postcode: 6164,
    place_name: 'Beeliar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1334,
    longitude: 115.8064,
    accuracy: 4,
  },
  {
    postcode: 6164,
    place_name: 'Hammond Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1685,
    longitude: 115.8504,
    accuracy: 4,
  },
  {
    postcode: 6164,
    place_name: 'Yangebup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1206,
    longitude: 115.8162,
    accuracy: 4,
  },
  {
    postcode: 6164,
    place_name: 'Aubin Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1671,
    longitude: 115.8626,
    accuracy: 4,
  },
  {
    postcode: 6164,
    place_name: 'South Lake',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1112,
    longitude: 115.8387,
    accuracy: 4,
  },
  {
    postcode: 6164,
    place_name: 'Jandakot',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1016,
    longitude: 115.8708,
    accuracy: 4,
  },
  {
    postcode: 6164,
    place_name: 'Cockburn Central',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1213,
    longitude: 115.8478,
    accuracy: 4,
  },
  {
    postcode: 6165,
    place_name: 'Hope Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2045,
    longitude: 115.81,
    accuracy: 4,
  },
  {
    postcode: 6165,
    place_name: 'Naval Base',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.191,
    longitude: 115.7777,
    accuracy: 4,
  },
  {
    postcode: 6166,
    place_name: 'Wattleup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1696,
    longitude: 115.824,
    accuracy: 4,
  },
  {
    postcode: 6166,
    place_name: 'Munster',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1365,
    longitude: 115.783,
    accuracy: 4,
  },
  {
    postcode: 6166,
    place_name: 'Coogee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1193,
    longitude: 115.7665,
    accuracy: 4,
  },
  {
    postcode: 6166,
    place_name: 'Henderson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1764,
    longitude: 115.7746,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Wandi',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1943,
    longitude: 115.8763,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Parmelia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2578,
    longitude: 115.8221,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Casuarina',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2414,
    longitude: 115.8698,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Medina',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2333,
    longitude: 115.7833,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Anketell',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2186,
    longitude: 115.8656,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Kwinana Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2484,
    longitude: 115.7619,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Bertram',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2437,
    longitude: 115.8457,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Postans',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2247,
    longitude: 115.8158,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Calista',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.241,
    longitude: 115.7976,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Kwinana Town Centre',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2444,
    longitude: 115.8161,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Mandogalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1929,
    longitude: 115.8435,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'The Spectacles',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2202,
    longitude: 115.8387,
    accuracy: 4,
  },
  {
    postcode: 6167,
    place_name: 'Orelia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2333,
    longitude: 115.8223,
    accuracy: 4,
  },
  {
    postcode: 6168,
    place_name: 'Hillman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2833,
    longitude: 115.7636,
    accuracy: 4,
  },
  {
    postcode: 6168,
    place_name: 'Rockingham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2768,
    longitude: 115.7298,
    accuracy: 4,
  },
  {
    postcode: 6168,
    place_name: 'Peron',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2766,
    longitude: 115.7005,
    accuracy: 4,
  },
  {
    postcode: 6168,
    place_name: 'Garden Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2426,
    longitude: 115.695,
    accuracy: 4,
  },
  {
    postcode: 6168,
    place_name: 'Rockingham DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2879,
    longitude: 115.7429,
    accuracy: 3,
  },
  {
    postcode: 6168,
    place_name: 'Cooloongup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3151,
    longitude: 115.7777,
    accuracy: 4,
  },
  {
    postcode: 6168,
    place_name: 'Rockingham Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2879,
    longitude: 115.7429,
    accuracy: 3,
  },
  {
    postcode: 6168,
    place_name: 'East Rockingham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2623,
    longitude: 115.7489,
    accuracy: 4,
  },
  {
    postcode: 6169,
    place_name: 'Safety Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3046,
    longitude: 115.7421,
    accuracy: 4,
  },
  {
    postcode: 6169,
    place_name: 'Shoalwater',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2909,
    longitude: 115.711,
    accuracy: 4,
  },
  {
    postcode: 6169,
    place_name: 'Waikiki',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3161,
    longitude: 115.7546,
    accuracy: 4,
  },
  {
    postcode: 6169,
    place_name: 'Warnbro',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3402,
    longitude: 115.7473,
    accuracy: 4,
  },
  {
    postcode: 6170,
    place_name: 'Leda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2677,
    longitude: 115.7993,
    accuracy: 4,
  },
  {
    postcode: 6170,
    place_name: 'Wellard',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2667,
    longitude: 115.8333,
    accuracy: 4,
  },
  {
    postcode: 6171,
    place_name: 'Baldivis',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3289,
    longitude: 115.8305,
    accuracy: 4,
  },
  {
    postcode: 6172,
    place_name: 'Port Kennedy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3734,
    longitude: 115.7515,
    accuracy: 4,
  },
  {
    postcode: 6173,
    place_name: 'Secret Harbour',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4059,
    longitude: 115.7585,
    accuracy: 4,
  },
  {
    postcode: 6174,
    place_name: 'Golden Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.425,
    longitude: 115.761,
    accuracy: 4,
  },
  {
    postcode: 6175,
    place_name: 'Singleton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.444,
    longitude: 115.7572,
    accuracy: 4,
  },
  {
    postcode: 6176,
    place_name: 'Karnup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3667,
    longitude: 115.8167,
    accuracy: 4,
  },
  {
    postcode: 6180,
    place_name: 'Parklands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4956,
    longitude: 115.773,
    accuracy: 4,
  },
  {
    postcode: 6180,
    place_name: 'Lakelands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4775,
    longitude: 115.7557,
    accuracy: 4,
  },
  {
    postcode: 6181,
    place_name: 'Stake Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4778,
    longitude: 115.7942,
    accuracy: 4,
  },
  {
    postcode: 6182,
    place_name: 'Keralup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4299,
    longitude: 115.8314,
    accuracy: 4,
  },
  {
    postcode: 6207,
    place_name: 'Solus',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5627,
    longitude: 116.1411,
    accuracy: 3,
  },
  {
    postcode: 6207,
    place_name: 'Nambeelup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5318,
    longitude: 115.8317,
    accuracy: 4,
  },
  {
    postcode: 6207,
    place_name: 'Whittaker',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5627,
    longitude: 116.1411,
    accuracy: 3,
  },
  {
    postcode: 6207,
    place_name: 'North Dandalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5168,
    longitude: 115.9719,
    accuracy: 4,
  },
  {
    postcode: 6207,
    place_name: 'Myara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5627,
    longitude: 116.1411,
    accuracy: 3,
  },
  {
    postcode: 6208,
    place_name: 'Ravenswood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5824,
    longitude: 115.8315,
    accuracy: 4,
  },
  {
    postcode: 6208,
    place_name: 'Pinjarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6298,
    longitude: 115.8735,
    accuracy: 4,
  },
  {
    postcode: 6208,
    place_name: 'Meelon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7,
    longitude: 115.9333,
    accuracy: 4,
  },
  {
    postcode: 6208,
    place_name: 'West Pinjarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6428,
    longitude: 115.8124,
    accuracy: 3,
  },
  {
    postcode: 6208,
    place_name: 'North Yunderup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.578,
    longitude: 115.7943,
    accuracy: 4,
  },
  {
    postcode: 6208,
    place_name: 'Point Grey',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6606,
    longitude: 115.9613,
    accuracy: 3,
  },
  {
    postcode: 6208,
    place_name: 'South Yunderup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5851,
    longitude: 115.7802,
    accuracy: 4,
  },
  {
    postcode: 6208,
    place_name: 'Blythewood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6606,
    longitude: 115.9613,
    accuracy: 3,
  },
  {
    postcode: 6208,
    place_name: 'Fairbridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6606,
    longitude: 115.9613,
    accuracy: 3,
  },
  {
    postcode: 6208,
    place_name: 'Nirimba',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6606,
    longitude: 115.9613,
    accuracy: 3,
  },
  {
    postcode: 6208,
    place_name: 'Oakley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6606,
    longitude: 115.9613,
    accuracy: 3,
  },
  {
    postcode: 6209,
    place_name: 'Furnissdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5585,
    longitude: 115.7604,
    accuracy: 4,
  },
  {
    postcode: 6209,
    place_name: 'Barragup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5498,
    longitude: 115.7796,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Mandurah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5269,
    longitude: 115.7217,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Parklands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4956,
    longitude: 115.773,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Bouvard',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7189,
    longitude: 115.6389,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Greenfields',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5243,
    longitude: 115.7631,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Wannanup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5995,
    longitude: 115.6449,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Mandurah DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5582,
    longitude: 115.719,
    accuracy: 3,
  },
  {
    postcode: 6210,
    place_name: 'Dudley Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5522,
    longitude: 115.731,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Clifton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7699,
    longitude: 115.6321,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Meadow Springs',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4972,
    longitude: 115.7533,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Mandurah East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5733,
    longitude: 115.7613,
    accuracy: 3,
  },
  {
    postcode: 6210,
    place_name: 'Silver Sands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5086,
    longitude: 115.736,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Lakelands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4775,
    longitude: 115.7557,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Furnissdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5585,
    longitude: 115.7604,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Mandurah North',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5733,
    longitude: 115.7613,
    accuracy: 3,
  },
  {
    postcode: 6210,
    place_name: 'San Remo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4899,
    longitude: 115.7447,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Coodanup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5519,
    longitude: 115.7535,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Herron',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7552,
    longitude: 115.674,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Falcon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5824,
    longitude: 115.662,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Dawesville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6323,
    longitude: 115.629,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Barragup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5498,
    longitude: 115.7796,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Stake Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4778,
    longitude: 115.7942,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Halls Head',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5431,
    longitude: 115.6966,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Erskine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5572,
    longitude: 115.7066,
    accuracy: 4,
  },
  {
    postcode: 6210,
    place_name: 'Madora Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4695,
    longitude: 115.7526,
    accuracy: 4,
  },
  {
    postcode: 6211,
    place_name: 'Dawesville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6323,
    longitude: 115.629,
    accuracy: 4,
  },
  {
    postcode: 6211,
    place_name: 'Bouvard',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7189,
    longitude: 115.6389,
    accuracy: 4,
  },
  {
    postcode: 6211,
    place_name: 'Clifton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7699,
    longitude: 115.6321,
    accuracy: 4,
  },
  {
    postcode: 6211,
    place_name: 'Herron',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7552,
    longitude: 115.674,
    accuracy: 4,
  },
  {
    postcode: 6213,
    place_name: 'Marrinup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7,
    longitude: 116.0167,
    accuracy: 4,
  },
  {
    postcode: 6213,
    place_name: 'Dwellingup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7144,
    longitude: 116.0622,
    accuracy: 4,
  },
  {
    postcode: 6213,
    place_name: 'Etmilyn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7126,
    longitude: 116.061,
    accuracy: 3,
  },
  {
    postcode: 6213,
    place_name: 'Inglehope',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7667,
    longitude: 116.1667,
    accuracy: 4,
  },
  {
    postcode: 6213,
    place_name: 'Banksiadale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.65,
    longitude: 116.0833,
    accuracy: 4,
  },
  {
    postcode: 6213,
    place_name: 'Teesdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7126,
    longitude: 116.061,
    accuracy: 3,
  },
  {
    postcode: 6213,
    place_name: 'Holyoake',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7167,
    longitude: 116.0833,
    accuracy: 4,
  },
  {
    postcode: 6214,
    place_name: 'West Coolup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.75,
    longitude: 115.8667,
    accuracy: 4,
  },
  {
    postcode: 6214,
    place_name: 'Coolup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.75,
    longitude: 115.8667,
    accuracy: 4,
  },
  {
    postcode: 6214,
    place_name: 'Birchmont',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7289,
    longitude: 115.7752,
    accuracy: 3,
  },
  {
    postcode: 6215,
    place_name: 'Waroona',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8432,
    longitude: 115.922,
    accuracy: 4,
  },
  {
    postcode: 6215,
    place_name: 'Hamel',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8741,
    longitude: 115.927,
    accuracy: 4,
  },
  {
    postcode: 6215,
    place_name: 'Lake Clifton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8134,
    longitude: 115.6856,
    accuracy: 4,
  },
  {
    postcode: 6215,
    place_name: 'Nanga Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8167,
    longitude: 116.0667,
    accuracy: 4,
  },
  {
    postcode: 6215,
    place_name: 'Wagerup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9102,
    longitude: 115.8915,
    accuracy: 4,
  },
  {
    postcode: 6215,
    place_name: 'Preston Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8668,
    longitude: 115.6703,
    accuracy: 4,
  },
  {
    postcode: 6218,
    place_name: 'Yarloop',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9537,
    longitude: 115.8969,
    accuracy: 4,
  },
  {
    postcode: 6220,
    place_name: 'Hoffman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0367,
    longitude: 115.9527,
    accuracy: 3,
  },
  {
    postcode: 6220,
    place_name: 'Myalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1021,
    longitude: 115.692,
    accuracy: 4,
  },
  {
    postcode: 6220,
    place_name: 'Cookernup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9833,
    longitude: 115.8833,
    accuracy: 4,
  },
  {
    postcode: 6220,
    place_name: 'Harvey',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0799,
    longitude: 115.8945,
    accuracy: 4,
  },
  {
    postcode: 6220,
    place_name: 'Warawarrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0367,
    longitude: 115.9527,
    accuracy: 3,
  },
  {
    postcode: 6220,
    place_name: 'Uduc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0493,
    longitude: 115.8101,
    accuracy: 4,
  },
  {
    postcode: 6221,
    place_name: 'Mornington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.15,
    longitude: 115.9333,
    accuracy: 4,
  },
  {
    postcode: 6221,
    place_name: 'Wokalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1167,
    longitude: 115.8667,
    accuracy: 4,
  },
  {
    postcode: 6223,
    place_name: 'Benger',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1667,
    longitude: 115.85,
    accuracy: 4,
  },
  {
    postcode: 6224,
    place_name: 'Beela',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1965,
    longitude: 115.9656,
    accuracy: 3,
  },
  {
    postcode: 6224,
    place_name: 'Brunswick',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2324,
    longitude: 115.8628,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Allanson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3359,
    longitude: 116.0994,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Mumballup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5286,
    longitude: 116.1133,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Palmer',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4062,
    longitude: 119.6815,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Mcalinden',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.578,
    longitude: 116.2985,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Cardiff',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4292,
    longitude: 116.2078,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Shotts',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3833,
    longitude: 116.2667,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Lyalls Mill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2836,
    longitude: 116.2116,
    accuracy: 3,
  },
  {
    postcode: 6225,
    place_name: 'Bowelling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4167,
    longitude: 116.5,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Noggerup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5833,
    longitude: 116.1667,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Muja',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2836,
    longitude: 116.2116,
    accuracy: 3,
  },
  {
    postcode: 6225,
    place_name: 'Buckingham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4,
    longitude: 116.3333,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Collie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3621,
    longitude: 116.1562,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Collie Burn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4,
    longitude: 116.1833,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Mungalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2836,
    longitude: 116.2116,
    accuracy: 3,
  },
  {
    postcode: 6225,
    place_name: 'Worsley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3069,
    longitude: 116.0067,
    accuracy: 4,
  },
  {
    postcode: 6225,
    place_name: 'Preston Settlement',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4129,
    longitude: 116.1578,
    accuracy: 3,
  },
  {
    postcode: 6225,
    place_name: 'Yourdamung Lake',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2072,
    longitude: 116.2349,
    accuracy: 3,
  },
  {
    postcode: 6225,
    place_name: 'Harris River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2836,
    longitude: 116.2116,
    accuracy: 3,
  },
  {
    postcode: 6226,
    place_name: 'Roelands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2833,
    longitude: 115.8167,
    accuracy: 4,
  },
  {
    postcode: 6227,
    place_name: 'Burekup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3071,
    longitude: 115.8137,
    accuracy: 4,
  },
  {
    postcode: 6228,
    place_name: 'Waterloo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3186,
    longitude: 115.7567,
    accuracy: 1,
  },
  {
    postcode: 6229,
    place_name: 'Picton East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3471,
    longitude: 115.7016,
    accuracy: 3,
  },
  {
    postcode: 6229,
    place_name: 'Picton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.35,
    longitude: 115.7,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'Withers',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3681,
    longitude: 115.6305,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'Dalyellup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4084,
    longitude: 115.6151,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'Gelorup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4051,
    longitude: 115.6344,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'Vittoria',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3305,
    longitude: 115.6659,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'Glen Iris',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3371,
    longitude: 115.6743,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'South Bunbury',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3361,
    longitude: 115.6403,
    accuracy: 3,
  },
  {
    postcode: 6230,
    place_name: 'Pelican Point',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3183,
    longitude: 115.6929,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'Bunbury',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3271,
    longitude: 115.6414,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'East Bunbury',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3363,
    longitude: 115.6566,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'College Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3759,
    longitude: 115.6591,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'Davenport',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.374,
    longitude: 115.6852,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'Carey Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3488,
    longitude: 115.6493,
    accuracy: 4,
  },
  {
    postcode: 6230,
    place_name: 'Usher',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3798,
    longitude: 115.6292,
    accuracy: 4,
  },
  {
    postcode: 6231,
    place_name: 'Bunbury',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3271,
    longitude: 115.6414,
    accuracy: 4,
  },
  {
    postcode: 6232,
    place_name: 'Eaton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3166,
    longitude: 115.705,
    accuracy: 4,
  },
  {
    postcode: 6232,
    place_name: 'Millbridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3062,
    longitude: 115.735,
    accuracy: 4,
  },
  {
    postcode: 6233,
    place_name: 'Parkfield',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2139,
    longitude: 115.7196,
    accuracy: 3,
  },
  {
    postcode: 6233,
    place_name: 'Australind',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2792,
    longitude: 115.715,
    accuracy: 4,
  },
  {
    postcode: 6233,
    place_name: 'Wellesley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2343,
    longitude: 115.7526,
    accuracy: 4,
  },
  {
    postcode: 6233,
    place_name: 'Leschenault',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2452,
    longitude: 115.7251,
    accuracy: 4,
  },
  {
    postcode: 6233,
    place_name: 'Binningup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1483,
    longitude: 115.6952,
    accuracy: 4,
  },
  {
    postcode: 6236,
    place_name: 'Wellington Mill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4667,
    longitude: 115.9,
    accuracy: 4,
  },
  {
    postcode: 6236,
    place_name: 'Paradise',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4403,
    longitude: 115.8631,
    accuracy: 3,
  },
  {
    postcode: 6236,
    place_name: 'Dardanup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3983,
    longitude: 115.7518,
    accuracy: 4,
  },
  {
    postcode: 6236,
    place_name: 'Wellington Forest',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4403,
    longitude: 115.8631,
    accuracy: 3,
  },
  {
    postcode: 6236,
    place_name: 'Ferguson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4403,
    longitude: 115.8631,
    accuracy: 3,
  },
  {
    postcode: 6236,
    place_name: 'Dardanup West',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3819,
    longitude: 115.7017,
    accuracy: 3,
  },
  {
    postcode: 6236,
    place_name: 'Crooked Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4403,
    longitude: 115.8631,
    accuracy: 3,
  },
  {
    postcode: 6236,
    place_name: 'Henty',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4403,
    longitude: 115.8631,
    accuracy: 3,
  },
  {
    postcode: 6237,
    place_name: 'Stratham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4632,
    longitude: 115.5862,
    accuracy: 4,
  },
  {
    postcode: 6237,
    place_name: 'Elgin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5068,
    longitude: 115.68,
    accuracy: 3,
  },
  {
    postcode: 6237,
    place_name: 'Boyanup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4829,
    longitude: 115.7284,
    accuracy: 4,
  },
  {
    postcode: 6237,
    place_name: 'North Boyanup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4311,
    longitude: 115.6889,
    accuracy: 3,
  },
  {
    postcode: 6237,
    place_name: 'Gwindinup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5068,
    longitude: 115.68,
    accuracy: 3,
  },
  {
    postcode: 6237,
    place_name: 'The Plains',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5068,
    longitude: 115.68,
    accuracy: 3,
  },
  {
    postcode: 6239,
    place_name: 'Beelerup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5756,
    longitude: 115.8506,
    accuracy: 4,
  },
  {
    postcode: 6239,
    place_name: 'Donnybrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5713,
    longitude: 115.8245,
    accuracy: 4,
  },
  {
    postcode: 6239,
    place_name: 'Argyle',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5458,
    longitude: 115.7707,
    accuracy: 4,
  },
  {
    postcode: 6239,
    place_name: 'Upper Capel',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6486,
    longitude: 115.8189,
    accuracy: 3,
  },
  {
    postcode: 6239,
    place_name: 'Yabberup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5386,
    longitude: 116.0125,
    accuracy: 3,
  },
  {
    postcode: 6239,
    place_name: 'Thomson Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6087,
    longitude: 115.9784,
    accuracy: 3,
  },
  {
    postcode: 6239,
    place_name: 'Paynedale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6087,
    longitude: 115.9784,
    accuracy: 3,
  },
  {
    postcode: 6239,
    place_name: 'Queenwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6087,
    longitude: 115.9784,
    accuracy: 3,
  },
  {
    postcode: 6239,
    place_name: 'Brookhampton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6087,
    longitude: 115.9784,
    accuracy: 3,
  },
  {
    postcode: 6239,
    place_name: 'Charley Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6087,
    longitude: 115.9784,
    accuracy: 3,
  },
  {
    postcode: 6239,
    place_name: 'Glen Mervyn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6087,
    longitude: 115.9784,
    accuracy: 3,
  },
  {
    postcode: 6240,
    place_name: 'Lowden',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5344,
    longitude: 115.9756,
    accuracy: 4,
  },
  {
    postcode: 6243,
    place_name: 'Wilga West',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6833,
    longitude: 116.2333,
    accuracy: 4,
  },
  {
    postcode: 6243,
    place_name: 'Wilga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6833,
    longitude: 116.2333,
    accuracy: 4,
  },
  {
    postcode: 6244,
    place_name: 'Mayanup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9355,
    longitude: 116.4529,
    accuracy: 4,
  },
  {
    postcode: 6244,
    place_name: 'Chowerup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0849,
    longitude: 116.6746,
    accuracy: 4,
  },
  {
    postcode: 6244,
    place_name: 'Boyup Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8343,
    longitude: 116.3878,
    accuracy: 4,
  },
  {
    postcode: 6244,
    place_name: 'Tonebridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8918,
    longitude: 116.524,
    accuracy: 3,
  },
  {
    postcode: 6244,
    place_name: 'Dinninup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8141,
    longitude: 116.5368,
    accuracy: 4,
  },
  {
    postcode: 6244,
    place_name: 'Kulikup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8263,
    longitude: 116.6771,
    accuracy: 4,
  },
  {
    postcode: 6244,
    place_name: 'Trigwell',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9035,
    longitude: 116.5476,
    accuracy: 3,
  },
  {
    postcode: 6244,
    place_name: 'Scotts Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8918,
    longitude: 116.524,
    accuracy: 3,
  },
  {
    postcode: 6251,
    place_name: 'Newlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6667,
    longitude: 115.8667,
    accuracy: 4,
  },
  {
    postcode: 6251,
    place_name: 'Kirup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7,
    longitude: 115.9,
    accuracy: 4,
  },
  {
    postcode: 6251,
    place_name: 'Brazier',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7636,
    longitude: 115.8561,
    accuracy: 3,
  },
  {
    postcode: 6252,
    place_name: 'Mullalyup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7333,
    longitude: 115.9333,
    accuracy: 4,
  },
  {
    postcode: 6253,
    place_name: 'Balingup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8,
    longitude: 115.9667,
    accuracy: 4,
  },
  {
    postcode: 6253,
    place_name: 'Southampton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7948,
    longitude: 116.0503,
    accuracy: 3,
  },
  {
    postcode: 6253,
    place_name: 'Grimwade',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7948,
    longitude: 116.0503,
    accuracy: 3,
  },
  {
    postcode: 6254,
    place_name: 'Greenbushes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8497,
    longitude: 116.0582,
    accuracy: 4,
  },
  {
    postcode: 6254,
    place_name: 'North Greenbushes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8484,
    longitude: 116.0569,
    accuracy: 3,
  },
  {
    postcode: 6255,
    place_name: 'Benjinup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7667,
    longitude: 116.3167,
    accuracy: 4,
  },
  {
    postcode: 6255,
    place_name: 'Hester',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9,
    longitude: 116.15,
    accuracy: 4,
  },
  {
    postcode: 6255,
    place_name: 'Bridgetown',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9624,
    longitude: 116.136,
    accuracy: 4,
  },
  {
    postcode: 6255,
    place_name: 'Kangaroo Gully',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9619,
    longitude: 116.1986,
    accuracy: 3,
  },
  {
    postcode: 6255,
    place_name: 'Catterick',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9057,
    longitude: 116.1308,
    accuracy: 3,
  },
  {
    postcode: 6255,
    place_name: 'Winnejup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9057,
    longitude: 116.1308,
    accuracy: 3,
  },
  {
    postcode: 6255,
    place_name: 'Hester Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9057,
    longitude: 116.1308,
    accuracy: 3,
  },
  {
    postcode: 6256,
    place_name: 'Yornup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.05,
    longitude: 116.1667,
    accuracy: 4,
  },
  {
    postcode: 6256,
    place_name: 'Sunnyside',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0777,
    longitude: 116.2364,
    accuracy: 3,
  },
  {
    postcode: 6256,
    place_name: 'Glenlynn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0063,
    longitude: 116.1535,
    accuracy: 4,
  },
  {
    postcode: 6256,
    place_name: 'Maranup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0777,
    longitude: 116.2364,
    accuracy: 3,
  },
  {
    postcode: 6256,
    place_name: 'Wandillup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0777,
    longitude: 116.2364,
    accuracy: 3,
  },
  {
    postcode: 6256,
    place_name: 'Kingston',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0777,
    longitude: 116.2364,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Yanmah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1833,
    longitude: 116.0167,
    accuracy: 4,
  },
  {
    postcode: 6258,
    place_name: 'Wilgarrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Palgarup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1625,
    longitude: 116.1845,
    accuracy: 4,
  },
  {
    postcode: 6258,
    place_name: 'Diamond Tree',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Jardee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.285,
    longitude: 116.12,
    accuracy: 4,
  },
  {
    postcode: 6258,
    place_name: 'Ringbark',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Manjimup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2412,
    longitude: 116.1456,
    accuracy: 4,
  },
  {
    postcode: 6258,
    place_name: 'Linfarne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Balbarrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2292,
    longitude: 116.2017,
    accuracy: 4,
  },
  {
    postcode: 6258,
    place_name: 'Smith Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Lake Muir',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Dixvale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Quinninup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4355,
    longitude: 116.2498,
    accuracy: 4,
  },
  {
    postcode: 6258,
    place_name: 'Shannon River Mill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Deanmill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2647,
    longitude: 116.0742,
    accuracy: 4,
  },
  {
    postcode: 6258,
    place_name: 'Crowea',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Upper Warren',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Glenoran',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Donnelly River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Middlesex',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Mordalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Dingup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2584,
    longitude: 116.1878,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Nyamup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6258,
    place_name: 'Perup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.352,
    longitude: 116.3659,
    accuracy: 3,
  },
  {
    postcode: 6260,
    place_name: 'Eastbrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4,
    longitude: 116.1,
    accuracy: 4,
  },
  {
    postcode: 6260,
    place_name: 'Pemberton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4431,
    longitude: 116.0369,
    accuracy: 4,
  },
  {
    postcode: 6260,
    place_name: 'Yeagarup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4216,
    longitude: 116.0684,
    accuracy: 3,
  },
  {
    postcode: 6260,
    place_name: 'Collins',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4216,
    longitude: 116.0684,
    accuracy: 3,
  },
  {
    postcode: 6260,
    place_name: 'Biddelia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4447,
    longitude: 115.9282,
    accuracy: 3,
  },
  {
    postcode: 6260,
    place_name: 'Channybearup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4216,
    longitude: 116.0684,
    accuracy: 3,
  },
  {
    postcode: 6260,
    place_name: 'Callcup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4216,
    longitude: 116.0684,
    accuracy: 3,
  },
  {
    postcode: 6260,
    place_name: 'Beedelup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4216,
    longitude: 116.0684,
    accuracy: 3,
  },
  {
    postcode: 6260,
    place_name: 'Lake Jasper',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4447,
    longitude: 115.9282,
    accuracy: 3,
  },
  {
    postcode: 6260,
    place_name: 'Peerabeelup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4447,
    longitude: 115.9282,
    accuracy: 3,
  },
  {
    postcode: 6262,
    place_name: 'Crowea',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.7331,
    longitude: 116.0778,
    accuracy: 3,
  },
  {
    postcode: 6262,
    place_name: 'Windy Harbour',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8333,
    longitude: 116.0333,
    accuracy: 4,
  },
  {
    postcode: 6262,
    place_name: 'Northcliffe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.6329,
    longitude: 116.1224,
    accuracy: 4,
  },
  {
    postcode: 6262,
    place_name: 'Boorara Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.7331,
    longitude: 116.0778,
    accuracy: 3,
  },
  {
    postcode: 6262,
    place_name: 'Meerup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.7331,
    longitude: 116.0778,
    accuracy: 3,
  },
  {
    postcode: 6262,
    place_name: 'Shannon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.7331,
    longitude: 116.0778,
    accuracy: 3,
  },
  {
    postcode: 6271,
    place_name: 'Peppermint Grove Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5271,
    longitude: 115.5065,
    accuracy: 4,
  },
  {
    postcode: 6271,
    place_name: 'Capel',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5581,
    longitude: 115.5628,
    accuracy: 4,
  },
  {
    postcode: 6271,
    place_name: 'Forrest Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5746,
    longitude: 115.5801,
    accuracy: 3,
  },
  {
    postcode: 6271,
    place_name: 'Capel River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5746,
    longitude: 115.5801,
    accuracy: 3,
  },
  {
    postcode: 6271,
    place_name: 'Stirling Estate',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5746,
    longitude: 115.5801,
    accuracy: 3,
  },
  {
    postcode: 6275,
    place_name: 'Nannup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9787,
    longitude: 115.7646,
    accuracy: 4,
  },
  {
    postcode: 6275,
    place_name: 'Jarrahwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8,
    longitude: 115.6667,
    accuracy: 4,
  },
  {
    postcode: 6275,
    place_name: 'Darradup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1358,
    longitude: 115.6706,
    accuracy: 3,
  },
  {
    postcode: 6275,
    place_name: 'Barrabup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1358,
    longitude: 115.6706,
    accuracy: 3,
  },
  {
    postcode: 6275,
    place_name: 'Carlotta',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1358,
    longitude: 115.6706,
    accuracy: 3,
  },
  {
    postcode: 6275,
    place_name: 'Cundinup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1358,
    longitude: 115.6706,
    accuracy: 3,
  },
  {
    postcode: 6275,
    place_name: 'Scott River East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1358,
    longitude: 115.6706,
    accuracy: 3,
  },
  {
    postcode: 6275,
    place_name: 'Yoganup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1358,
    longitude: 115.6706,
    accuracy: 3,
  },
  {
    postcode: 6275,
    place_name: 'East Nannup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1358,
    longitude: 115.6706,
    accuracy: 3,
  },
  {
    postcode: 6275,
    place_name: 'Jalbarragup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1358,
    longitude: 115.6706,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Carbunup River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7,
    longitude: 115.1833,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Bovell',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6801,
    longitude: 115.361,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Busselton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6525,
    longitude: 115.3455,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Broadwater',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.663,
    longitude: 115.2842,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Yelverton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7505,
    longitude: 115.1196,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Yoongarillup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7152,
    longitude: 115.4268,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Ludlow',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6,
    longitude: 115.4667,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Kaloorup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Vasse',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6667,
    longitude: 115.25,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Abbey',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6636,
    longitude: 115.2564,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Metricup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7755,
    longitude: 115.1329,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Ruabon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Ambergate',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7373,
    longitude: 115.3262,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Reinscourt',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6535,
    longitude: 115.4079,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Wonnerup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6333,
    longitude: 115.4333,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'West Busselton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6559,
    longitude: 115.3227,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Yalyalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.675,
    longitude: 115.4145,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Kealy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Siesta Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Tutunup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Anniebrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6591,
    longitude: 115.1726,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Kalgup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'North Jindong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Hithergreen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Walsall',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Jindong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Sabina River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Chapman Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7783,
    longitude: 115.3272,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Boallia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Wilyabrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7956,
    longitude: 115.0461,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Marybrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6594,
    longitude: 115.2,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Abba River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6280,
    place_name: 'Geographe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6397,
    longitude: 115.3836,
    accuracy: 4,
  },
  {
    postcode: 6280,
    place_name: 'Acton Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7296,
    longitude: 115.4072,
    accuracy: 3,
  },
  {
    postcode: 6281,
    place_name: 'Quindalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6356,
    longitude: 115.149,
    accuracy: 4,
  },
  {
    postcode: 6281,
    place_name: 'Dunsborough',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6151,
    longitude: 115.1056,
    accuracy: 4,
  },
  {
    postcode: 6281,
    place_name: 'Eagle Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6164,
    longitude: 115.0811,
    accuracy: 3,
  },
  {
    postcode: 6281,
    place_name: 'Quedjinup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6206,
    longitude: 115.0917,
    accuracy: 4,
  },
  {
    postcode: 6281,
    place_name: 'Naturaliste',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6164,
    longitude: 115.0811,
    accuracy: 3,
  },
  {
    postcode: 6282,
    place_name: 'Yallingup Siding',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.683,
    longitude: 115.0977,
    accuracy: 4,
  },
  {
    postcode: 6282,
    place_name: 'Yallingup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7,
    longitude: 115.1,
    accuracy: 4,
  },
  {
    postcode: 6284,
    place_name: 'Baudin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.865,
    longitude: 115.4242,
    accuracy: 3,
  },
  {
    postcode: 6284,
    place_name: 'Cowaramup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8504,
    longitude: 115.1038,
    accuracy: 4,
  },
  {
    postcode: 6284,
    place_name: 'Gracetown',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8673,
    longitude: 114.9859,
    accuracy: 3,
  },
  {
    postcode: 6284,
    place_name: 'Treeton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.875,
    longitude: 115.102,
    accuracy: 3,
  },
  {
    postcode: 6285,
    place_name: 'Prevelly',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9791,
    longitude: 114.9943,
    accuracy: 4,
  },
  {
    postcode: 6285,
    place_name: 'Rosa Glen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0152,
    longitude: 115.2033,
    accuracy: 4,
  },
  {
    postcode: 6285,
    place_name: 'Margaret River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9536,
    longitude: 115.0739,
    accuracy: 4,
  },
  {
    postcode: 6285,
    place_name: 'Osmington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.921,
    longitude: 115.206,
    accuracy: 3,
  },
  {
    postcode: 6285,
    place_name: 'Bramley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9194,
    longitude: 115.1135,
    accuracy: 4,
  },
  {
    postcode: 6285,
    place_name: 'Schroeder',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9504,
    longitude: 115.3202,
    accuracy: 3,
  },
  {
    postcode: 6285,
    place_name: 'Burnside',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9369,
    longitude: 115.0181,
    accuracy: 4,
  },
  {
    postcode: 6285,
    place_name: 'Rosa Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.95,
    longitude: 115.1833,
    accuracy: 4,
  },
  {
    postcode: 6285,
    place_name: 'Gnarabup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9919,
    longitude: 114.9943,
    accuracy: 4,
  },
  {
    postcode: 6286,
    place_name: 'Forest Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0667,
    longitude: 115.0833,
    accuracy: 4,
  },
  {
    postcode: 6286,
    place_name: 'Witchcliffe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0333,
    longitude: 115.1,
    accuracy: 4,
  },
  {
    postcode: 6286,
    place_name: 'Redgate',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1242,
    longitude: 115.0573,
    accuracy: 3,
  },
  {
    postcode: 6286,
    place_name: 'Boranup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1242,
    longitude: 115.0573,
    accuracy: 3,
  },
  {
    postcode: 6288,
    place_name: 'Karridale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2167,
    longitude: 115.0833,
    accuracy: 4,
  },
  {
    postcode: 6288,
    place_name: 'Scott River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1742,
    longitude: 115.2399,
    accuracy: 3,
  },
  {
    postcode: 6288,
    place_name: 'Hamelin Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2264,
    longitude: 115.0571,
    accuracy: 4,
  },
  {
    postcode: 6288,
    place_name: 'Nillup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1742,
    longitude: 115.2399,
    accuracy: 3,
  },
  {
    postcode: 6288,
    place_name: 'Warner Glen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1742,
    longitude: 115.2399,
    accuracy: 3,
  },
  {
    postcode: 6288,
    place_name: 'Alexandra Bridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1742,
    longitude: 115.2399,
    accuracy: 3,
  },
  {
    postcode: 6288,
    place_name: 'Courtenay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1742,
    longitude: 115.2399,
    accuracy: 3,
  },
  {
    postcode: 6290,
    place_name: 'Leeuwin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.3455,
    longitude: 115.1365,
    accuracy: 4,
  },
  {
    postcode: 6290,
    place_name: 'Kudardup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2833,
    longitude: 115.1,
    accuracy: 4,
  },
  {
    postcode: 6290,
    place_name: 'East Augusta',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0202,
    longitude: 115.1211,
    accuracy: 4,
  },
  {
    postcode: 6290,
    place_name: 'Molloy Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2684,
    longitude: 115.2097,
    accuracy: 3,
  },
  {
    postcode: 6290,
    place_name: 'Augusta',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.3157,
    longitude: 115.1592,
    accuracy: 4,
  },
  {
    postcode: 6290,
    place_name: 'Deepdene',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2528,
    longitude: 115.1632,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Talbot West',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0333,
    longitude: 116.6833,
    accuracy: 4,
  },
  {
    postcode: 6302,
    place_name: 'Gwambygine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9757,
    longitude: 116.8063,
    accuracy: 4,
  },
  {
    postcode: 6302,
    place_name: 'Burges',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8167,
    longitude: 116.7833,
    accuracy: 4,
  },
  {
    postcode: 6302,
    place_name: 'St Ronans',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'York',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8881,
    longitude: 116.7678,
    accuracy: 4,
  },
  {
    postcode: 6302,
    place_name: 'Mount Observation',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Mount Hardey',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9,
    longitude: 116.8667,
    accuracy: 4,
  },
  {
    postcode: 6302,
    place_name: 'Cold Harbour',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Malebelling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Narraloggan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Gilgering',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0243,
    longitude: 116.7984,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Wilberforce',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Flint',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Caljie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Talbot',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0333,
    longitude: 116.6833,
    accuracy: 4,
  },
  {
    postcode: 6302,
    place_name: 'Balladong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Greenhills',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9208,
    longitude: 116.9643,
    accuracy: 4,
  },
  {
    postcode: 6302,
    place_name: 'Daliak',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Inkpen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Quellington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Flynn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Badgin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6302,
    place_name: 'Kauring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9264,
    longitude: 116.7354,
    accuracy: 3,
  },
  {
    postcode: 6304,
    place_name: 'Westdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3134,
    longitude: 116.6088,
    accuracy: 4,
  },
  {
    postcode: 6304,
    place_name: 'Kokeby',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2102,
    longitude: 116.9712,
    accuracy: 4,
  },
  {
    postcode: 6304,
    place_name: 'Bally Bally',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1667,
    longitude: 117.1,
    accuracy: 4,
  },
  {
    postcode: 6304,
    place_name: 'Beverley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1084,
    longitude: 116.9274,
    accuracy: 4,
  },
  {
    postcode: 6304,
    place_name: 'East Beverley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0333,
    longitude: 117.0167,
    accuracy: 4,
  },
  {
    postcode: 6304,
    place_name: 'Morbinning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1,
    longitude: 117.15,
    accuracy: 4,
  },
  {
    postcode: 6304,
    place_name: 'Dale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0796,
    longitude: 116.8083,
    accuracy: 4,
  },
  {
    postcode: 6306,
    place_name: 'Brookton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3723,
    longitude: 117.0105,
    accuracy: 4,
  },
  {
    postcode: 6306,
    place_name: 'Jelcobine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.362,
    longitude: 117.0031,
    accuracy: 3,
  },
  {
    postcode: 6306,
    place_name: 'Kweda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3667,
    longitude: 117.4333,
    accuracy: 4,
  },
  {
    postcode: 6306,
    place_name: 'Aldersyde',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3747,
    longitude: 117.2834,
    accuracy: 4,
  },
  {
    postcode: 6306,
    place_name: 'Bulyee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3667,
    longitude: 117.5167,
    accuracy: 4,
  },
  {
    postcode: 6308,
    place_name: 'Springs',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5363,
    longitude: 116.8413,
    accuracy: 3,
  },
  {
    postcode: 6308,
    place_name: 'Hastings',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5363,
    longitude: 116.8413,
    accuracy: 3,
  },
  {
    postcode: 6308,
    place_name: 'Pingelly',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5325,
    longitude: 117.0824,
    accuracy: 4,
  },
  {
    postcode: 6308,
    place_name: 'Dwarda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7667,
    longitude: 116.6667,
    accuracy: 4,
  },
  {
    postcode: 6308,
    place_name: 'East Pingelly',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5103,
    longitude: 117.1464,
    accuracy: 3,
  },
  {
    postcode: 6308,
    place_name: 'Gillimanning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5363,
    longitude: 116.8413,
    accuracy: 3,
  },
  {
    postcode: 6308,
    place_name: 'West Pingelly',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5363,
    longitude: 116.8413,
    accuracy: 3,
  },
  {
    postcode: 6308,
    place_name: 'Wandering',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6833,
    longitude: 116.6667,
    accuracy: 4,
  },
  {
    postcode: 6308,
    place_name: 'Codjatotine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5363,
    longitude: 116.8413,
    accuracy: 3,
  },
  {
    postcode: 6308,
    place_name: 'Pumphreys Bridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5363,
    longitude: 116.8413,
    accuracy: 3,
  },
  {
    postcode: 6309,
    place_name: 'Popanyinning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6667,
    longitude: 117.1333,
    accuracy: 4,
  },
  {
    postcode: 6309,
    place_name: 'Stratherne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6618,
    longitude: 117.1292,
    accuracy: 3,
  },
  {
    postcode: 6309,
    place_name: 'East Popanyinning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6329,
    longitude: 117.2604,
    accuracy: 4,
  },
  {
    postcode: 6309,
    place_name: 'West Popanyinning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6618,
    longitude: 117.1292,
    accuracy: 3,
  },
  {
    postcode: 6311,
    place_name: 'Yornaning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7333,
    longitude: 117.15,
    accuracy: 4,
  },
  {
    postcode: 6311,
    place_name: 'Contine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8667,
    longitude: 116.9667,
    accuracy: 4,
  },
  {
    postcode: 6311,
    place_name: 'Dryandra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7667,
    longitude: 116.9667,
    accuracy: 4,
  },
  {
    postcode: 6311,
    place_name: 'Wardering',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7358,
    longitude: 117.1593,
    accuracy: 3,
  },
  {
    postcode: 6311,
    place_name: 'Cuballing',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8196,
    longitude: 117.1758,
    accuracy: 4,
  },
  {
    postcode: 6311,
    place_name: 'Commodine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7358,
    longitude: 117.1593,
    accuracy: 3,
  },
  {
    postcode: 6311,
    place_name: 'Lol Gray',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7358,
    longitude: 117.1593,
    accuracy: 3,
  },
  {
    postcode: 6311,
    place_name: 'Townsendale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7358,
    longitude: 117.1593,
    accuracy: 3,
  },
  {
    postcode: 6312,
    place_name: 'Yilliminning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8994,
    longitude: 117.3744,
    accuracy: 4,
  },
  {
    postcode: 6312,
    place_name: 'Narrogin Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9953,
    longitude: 117.2623,
    accuracy: 3,
  },
  {
    postcode: 6312,
    place_name: 'Nomans Lake',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9256,
    longitude: 117.5194,
    accuracy: 4,
  },
  {
    postcode: 6312,
    place_name: 'Hillside',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9953,
    longitude: 117.2623,
    accuracy: 3,
  },
  {
    postcode: 6312,
    place_name: 'Dumberning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0042,
    longitude: 117.101,
    accuracy: 4,
  },
  {
    postcode: 6312,
    place_name: 'Minigin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9953,
    longitude: 117.2623,
    accuracy: 3,
  },
  {
    postcode: 6312,
    place_name: 'Narrogin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9328,
    longitude: 117.1776,
    accuracy: 4,
  },
  {
    postcode: 6312,
    place_name: 'Boundain',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.95,
    longitude: 117.3167,
    accuracy: 4,
  },
  {
    postcode: 6312,
    place_name: 'Toolibin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.946,
    longitude: 117.6225,
    accuracy: 4,
  },
  {
    postcode: 6313,
    place_name: 'Highbury',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0667,
    longitude: 117.25,
    accuracy: 4,
  },
  {
    postcode: 6315,
    place_name: 'Piesseville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1909,
    longitude: 117.2901,
    accuracy: 4,
  },
  {
    postcode: 6315,
    place_name: 'Gundaring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3398,
    longitude: 117.1803,
    accuracy: 3,
  },
  {
    postcode: 6315,
    place_name: 'Jaloran',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.15,
    longitude: 117.4333,
    accuracy: 4,
  },
  {
    postcode: 6315,
    place_name: 'Dongolocking',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1333,
    longitude: 117.8167,
    accuracy: 4,
  },
  {
    postcode: 6315,
    place_name: 'Lime Lake',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3398,
    longitude: 117.1803,
    accuracy: 3,
  },
  {
    postcode: 6315,
    place_name: 'Ballaying',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3064,
    longitude: 117.5525,
    accuracy: 4,
  },
  {
    postcode: 6315,
    place_name: 'Minding',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3398,
    longitude: 117.1803,
    accuracy: 3,
  },
  {
    postcode: 6315,
    place_name: 'Collanilling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2667,
    longitude: 117.5,
    accuracy: 4,
  },
  {
    postcode: 6315,
    place_name: 'Wedgecarrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3398,
    longitude: 117.1803,
    accuracy: 3,
  },
  {
    postcode: 6315,
    place_name: 'Wagin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3089,
    longitude: 117.3433,
    accuracy: 4,
  },
  {
    postcode: 6315,
    place_name: 'Arthur River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.351,
    longitude: 117.0318,
    accuracy: 4,
  },
  {
    postcode: 6315,
    place_name: 'Cancanning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1333,
    longitude: 117.5667,
    accuracy: 4,
  },
  {
    postcode: 6316,
    place_name: 'Woodanilling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5667,
    longitude: 117.4333,
    accuracy: 4,
  },
  {
    postcode: 6316,
    place_name: 'Boyerine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5336,
    longitude: 117.3382,
    accuracy: 3,
  },
  {
    postcode: 6316,
    place_name: 'Cartmeticup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.47,
    longitude: 117.5175,
    accuracy: 4,
  },
  {
    postcode: 6316,
    place_name: 'Glencoe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5336,
    longitude: 117.3382,
    accuracy: 3,
  },
  {
    postcode: 6316,
    place_name: 'Kenmare',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5336,
    longitude: 117.3382,
    accuracy: 3,
  },
  {
    postcode: 6316,
    place_name: 'Westwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5336,
    longitude: 117.3382,
    accuracy: 3,
  },
  {
    postcode: 6317,
    place_name: 'Badgebup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6333,
    longitude: 117.9,
    accuracy: 4,
  },
  {
    postcode: 6317,
    place_name: 'Datatine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4667,
    longitude: 117.9167,
    accuracy: 4,
  },
  {
    postcode: 6317,
    place_name: 'Ewlyamartup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7055,
    longitude: 117.7372,
    accuracy: 4,
  },
  {
    postcode: 6317,
    place_name: 'Moojebing',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6483,
    longitude: 117.6843,
    accuracy: 3,
  },
  {
    postcode: 6317,
    place_name: 'Murdong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7577,
    longitude: 117.5995,
    accuracy: 4,
  },
  {
    postcode: 6317,
    place_name: 'Katanning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6879,
    longitude: 117.5584,
    accuracy: 4,
  },
  {
    postcode: 6317,
    place_name: 'Coyrecup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6781,
    longitude: 117.83,
    accuracy: 4,
  },
  {
    postcode: 6317,
    place_name: 'Pinwernying',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6483,
    longitude: 117.6843,
    accuracy: 3,
  },
  {
    postcode: 6317,
    place_name: 'Marracoonda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.65,
    longitude: 117.4167,
    accuracy: 4,
  },
  {
    postcode: 6317,
    place_name: 'Coblinine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6483,
    longitude: 117.6843,
    accuracy: 3,
  },
  {
    postcode: 6317,
    place_name: 'Carrolup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7435,
    longitude: 117.3999,
    accuracy: 4,
  },
  {
    postcode: 6317,
    place_name: 'South Datatine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6483,
    longitude: 117.6843,
    accuracy: 3,
  },
  {
    postcode: 6317,
    place_name: 'Bullock Hills',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6483,
    longitude: 117.6843,
    accuracy: 3,
  },
  {
    postcode: 6317,
    place_name: 'South Glencoe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6483,
    longitude: 117.6843,
    accuracy: 3,
  },
  {
    postcode: 6318,
    place_name: 'Broomehill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.843,
    longitude: 117.6401,
    accuracy: 4,
  },
  {
    postcode: 6318,
    place_name: 'Broomehill West',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8785,
    longitude: 117.6161,
    accuracy: 3,
  },
  {
    postcode: 6318,
    place_name: 'Broomehill East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8785,
    longitude: 117.6161,
    accuracy: 3,
  },
  {
    postcode: 6318,
    place_name: 'Broomehill Village',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8785,
    longitude: 117.6161,
    accuracy: 3,
  },
  {
    postcode: 6320,
    place_name: 'Tambellup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0433,
    longitude: 117.6414,
    accuracy: 4,
  },
  {
    postcode: 6320,
    place_name: 'Moonies Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0833,
    longitude: 117.5167,
    accuracy: 4,
  },
  {
    postcode: 6320,
    place_name: 'Wansbrough',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1432,
    longitude: 117.6715,
    accuracy: 4,
  },
  {
    postcode: 6320,
    place_name: 'Lake Toolbrunup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1035,
    longitude: 117.6981,
    accuracy: 3,
  },
  {
    postcode: 6320,
    place_name: 'Bobalong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1035,
    longitude: 117.6981,
    accuracy: 3,
  },
  {
    postcode: 6320,
    place_name: 'Borderdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1035,
    longitude: 117.6981,
    accuracy: 3,
  },
  {
    postcode: 6320,
    place_name: 'Dartnall',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1035,
    longitude: 117.6981,
    accuracy: 3,
  },
  {
    postcode: 6321,
    place_name: 'Cranbrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2976,
    longitude: 117.5518,
    accuracy: 4,
  },
  {
    postcode: 6322,
    place_name: 'Tenterden',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.362,
    longitude: 117.5549,
    accuracy: 4,
  },
  {
    postcode: 6323,
    place_name: 'Kendenup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4821,
    longitude: 117.6253,
    accuracy: 4,
  },
  {
    postcode: 6324,
    place_name: 'Takalarup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4793,
    longitude: 117.8964,
    accuracy: 3,
  },
  {
    postcode: 6324,
    place_name: 'Porongurup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.65,
    longitude: 117.9,
    accuracy: 4,
  },
  {
    postcode: 6324,
    place_name: 'Denbarker',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4793,
    longitude: 117.8964,
    accuracy: 3,
  },
  {
    postcode: 6324,
    place_name: 'Woogenellup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.5167,
    longitude: 117.8333,
    accuracy: 4,
  },
  {
    postcode: 6324,
    place_name: 'Perillup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4793,
    longitude: 117.8964,
    accuracy: 3,
  },
  {
    postcode: 6324,
    place_name: 'Mount Barker',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.6308,
    longitude: 117.6661,
    accuracy: 4,
  },
  {
    postcode: 6324,
    place_name: 'Forest Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4793,
    longitude: 117.8964,
    accuracy: 3,
  },
  {
    postcode: 6324,
    place_name: 'South Stirling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4793,
    longitude: 117.8964,
    accuracy: 3,
  },
  {
    postcode: 6326,
    place_name: 'Narrikup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.7723,
    longitude: 117.7042,
    accuracy: 4,
  },
  {
    postcode: 6327,
    place_name: 'Redmond',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8667,
    longitude: 117.6833,
    accuracy: 4,
  },
  {
    postcode: 6327,
    place_name: 'Redmond West',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8667,
    longitude: 117.6833,
    accuracy: 4,
  },
  {
    postcode: 6328,
    place_name: 'Mettler',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8333,
    longitude: 118.1667,
    accuracy: 3,
  },
  {
    postcode: 6328,
    place_name: 'Manypeaks',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8381,
    longitude: 118.1698,
    accuracy: 4,
  },
  {
    postcode: 6328,
    place_name: 'Palmdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.6048,
    longitude: 118.4088,
    accuracy: 3,
  },
  {
    postcode: 6328,
    place_name: 'Cape Riche',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.6048,
    longitude: 118.4088,
    accuracy: 3,
  },
  {
    postcode: 6328,
    place_name: 'Gnowellen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.6048,
    longitude: 118.4088,
    accuracy: 3,
  },
  {
    postcode: 6328,
    place_name: 'Cheynes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.6048,
    longitude: 118.4088,
    accuracy: 3,
  },
  {
    postcode: 6328,
    place_name: 'Green Range',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.6048,
    longitude: 118.4088,
    accuracy: 3,
  },
  {
    postcode: 6328,
    place_name: 'Wellstead',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4952,
    longitude: 118.6014,
    accuracy: 3,
  },
  {
    postcode: 6328,
    place_name: 'Kojaneerup South',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.6048,
    longitude: 118.4088,
    accuracy: 3,
  },
  {
    postcode: 6330,
    place_name: 'Kalgan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8847,
    longitude: 118.0131,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Napier',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8333,
    longitude: 118,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Mount Clarence',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0253,
    longitude: 117.9023,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Green Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9448,
    longitude: 117.8359,
    accuracy: 3,
  },
  {
    postcode: 6330,
    place_name: 'Torbay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0233,
    longitude: 117.6606,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Milpara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9889,
    longitude: 117.8707,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Lower King',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9578,
    longitude: 117.9383,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Lowlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9448,
    longitude: 117.8359,
    accuracy: 3,
  },
  {
    postcode: 6330,
    place_name: 'Willyung',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9433,
    longitude: 117.8795,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Mckail',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9853,
    longitude: 117.8301,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Mount Elphinstone',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0194,
    longitude: 117.8518,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Collingwood Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9448,
    longitude: 117.8359,
    accuracy: 3,
  },
  {
    postcode: 6330,
    place_name: 'Marbelup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9675,
    longitude: 117.7333,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Orana',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.996,
    longitude: 117.8592,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Yakamia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0013,
    longitude: 117.8751,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Millbrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9068,
    longitude: 117.8726,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Frenchman Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0935,
    longitude: 117.9491,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Bornholm',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0606,
    longitude: 117.5769,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Seppings',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0099,
    longitude: 117.9087,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Nanarup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9448,
    longitude: 117.8359,
    accuracy: 3,
  },
  {
    postcode: 6330,
    place_name: 'Mira Mar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0152,
    longitude: 117.901,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Albany',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0031,
    longitude: 117.866,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'West Cape Howe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0827,
    longitude: 117.6255,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Nullaki',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9448,
    longitude: 117.8359,
    accuracy: 3,
  },
  {
    postcode: 6330,
    place_name: 'Centennial Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0153,
    longitude: 117.8805,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Youngs Siding',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0167,
    longitude: 117.5167,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Big Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0808,
    longitude: 117.879,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Sandpatch',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9448,
    longitude: 117.8359,
    accuracy: 3,
  },
  {
    postcode: 6330,
    place_name: 'Lange',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9914,
    longitude: 117.8817,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Cuthbert',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0158,
    longitude: 117.7983,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'King River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9344,
    longitude: 117.9119,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Vancouver Peninsula',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9448,
    longitude: 117.8359,
    accuracy: 3,
  },
  {
    postcode: 6330,
    place_name: 'Gledhow',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0063,
    longitude: 117.8269,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Collingwood Heights',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9927,
    longitude: 117.9207,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Lockyer',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0057,
    longitude: 117.8588,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Port Albany',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9448,
    longitude: 117.8359,
    accuracy: 3,
  },
  {
    postcode: 6330,
    place_name: 'Mount Melville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0148,
    longitude: 117.8689,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Bayonet Head',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.973,
    longitude: 117.9345,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Emu Point',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35,
    longitude: 117.9381,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Little Grove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.068,
    longitude: 117.872,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Warrenup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9755,
    longitude: 117.8472,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Spencer Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0052,
    longitude: 117.9001,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Elleker',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0092,
    longitude: 117.7303,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Robinson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0316,
    longitude: 117.8408,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Kronkup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0506,
    longitude: 117.6186,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Middleton Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0237,
    longitude: 117.911,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Goode Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0887,
    longitude: 117.9358,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Drome',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9572,
    longitude: 117.8109,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Walmsley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9739,
    longitude: 117.903,
    accuracy: 4,
  },
  {
    postcode: 6330,
    place_name: 'Torndirrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0941,
    longitude: 117.906,
    accuracy: 4,
  },
  {
    postcode: 6331,
    place_name: 'Albany Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.6956,
    longitude: 117.882,
    accuracy: 1,
  },
  {
    postcode: 6332,
    place_name: 'Albany',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0031,
    longitude: 117.866,
    accuracy: 4,
  },
  {
    postcode: 6332,
    place_name: 'Albany Po',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0031,
    longitude: 117.866,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Kentdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9,
    longitude: 117.05,
    accuracy: 4,
  },
  {
    postcode: 6333,
    place_name: 'Hay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8905,
    longitude: 117.1188,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Parryville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9833,
    longitude: 117.1833,
    accuracy: 4,
  },
  {
    postcode: 6333,
    place_name: 'Denmark',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9607,
    longitude: 117.3532,
    accuracy: 4,
  },
  {
    postcode: 6333,
    place_name: 'Nornalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9917,
    longitude: 116.8202,
    accuracy: 4,
  },
  {
    postcode: 6333,
    place_name: 'Shadforth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9638,
    longitude: 117.2692,
    accuracy: 4,
  },
  {
    postcode: 6333,
    place_name: 'William Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8905,
    longitude: 117.1188,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Mount Romance',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8119,
    longitude: 117.1347,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Ocean Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0202,
    longitude: 117.3263,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Trent',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9131,
    longitude: 116.9396,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Tingledale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8905,
    longitude: 117.1188,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Peaceful Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -35.0225,
    longitude: 116.9165,
    accuracy: 4,
  },
  {
    postcode: 6333,
    place_name: 'Hazelvale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8905,
    longitude: 117.1188,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Bow Bridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8905,
    longitude: 117.1188,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Mount Lindesay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.8905,
    longitude: 117.1188,
    accuracy: 3,
  },
  {
    postcode: 6333,
    place_name: 'Kordabup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.929,
    longitude: 117.1486,
    accuracy: 4,
  },
  {
    postcode: 6333,
    place_name: 'Scotsdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.9081,
    longitude: 117.2825,
    accuracy: 3,
  },
  {
    postcode: 6335,
    place_name: 'Kebaringup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0333,
    longitude: 118.15,
    accuracy: 4,
  },
  {
    postcode: 6335,
    place_name: 'Gnowangerup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.937,
    longitude: 118.009,
    accuracy: 4,
  },
  {
    postcode: 6335,
    place_name: 'Pallinup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8664,
    longitude: 118.1111,
    accuracy: 3,
  },
  {
    postcode: 6335,
    place_name: 'Jackitup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8664,
    longitude: 118.1111,
    accuracy: 3,
  },
  {
    postcode: 6336,
    place_name: 'Needilup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.95,
    longitude: 118.8167,
    accuracy: 4,
  },
  {
    postcode: 6336,
    place_name: 'Toompup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0133,
    longitude: 118.4278,
    accuracy: 4,
  },
  {
    postcode: 6336,
    place_name: 'Ongerup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9656,
    longitude: 118.4869,
    accuracy: 4,
  },
  {
    postcode: 6336,
    place_name: 'Mindarabin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8753,
    longitude: 118.5061,
    accuracy: 3,
  },
  {
    postcode: 6336,
    place_name: 'Mills Lake',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8753,
    longitude: 118.5061,
    accuracy: 3,
  },
  {
    postcode: 6336,
    place_name: 'Cowalellup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8753,
    longitude: 118.5061,
    accuracy: 3,
  },
  {
    postcode: 6337,
    place_name: 'Jerramungup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9422,
    longitude: 118.9173,
    accuracy: 4,
  },
  {
    postcode: 6337,
    place_name: 'West Fitzgerald',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9438,
    longitude: 119.2324,
    accuracy: 3,
  },
  {
    postcode: 6337,
    place_name: 'Jacup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8167,
    longitude: 119.2667,
    accuracy: 4,
  },
  {
    postcode: 6337,
    place_name: 'Gairdner',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9438,
    longitude: 119.2324,
    accuracy: 3,
  },
  {
    postcode: 6337,
    place_name: 'Fitzgerald',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9438,
    longitude: 119.2324,
    accuracy: 3,
  },
  {
    postcode: 6338,
    place_name: 'Bremer Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.3925,
    longitude: 119.3798,
    accuracy: 4,
  },
  {
    postcode: 6338,
    place_name: 'Borden',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0724,
    longitude: 118.2721,
    accuracy: 4,
  },
  {
    postcode: 6338,
    place_name: 'Nalyerlup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1703,
    longitude: 118.7076,
    accuracy: 3,
  },
  {
    postcode: 6338,
    place_name: 'North Stirlings',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1703,
    longitude: 118.7076,
    accuracy: 3,
  },
  {
    postcode: 6338,
    place_name: 'Monjebup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1703,
    longitude: 118.7076,
    accuracy: 3,
  },
  {
    postcode: 6338,
    place_name: 'Boxwood Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.3617,
    longitude: 118.7467,
    accuracy: 4,
  },
  {
    postcode: 6338,
    place_name: 'Magitup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1703,
    longitude: 118.7076,
    accuracy: 3,
  },
  {
    postcode: 6338,
    place_name: 'Stirling Range National Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.4044,
    longitude: 118.0419,
    accuracy: 3,
  },
  {
    postcode: 6338,
    place_name: 'Amelup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.2385,
    longitude: 118.2209,
    accuracy: 3,
  },
  {
    postcode: 6341,
    place_name: 'Nyabing',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5413,
    longitude: 118.146,
    accuracy: 4,
  },
  {
    postcode: 6343,
    place_name: 'Pingrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5378,
    longitude: 118.5122,
    accuracy: 4,
  },
  {
    postcode: 6346,
    place_name: 'Ravensthorpe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.583,
    longitude: 120.0469,
    accuracy: 4,
  },
  {
    postcode: 6346,
    place_name: 'Fitzgerald River National Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0016,
    longitude: 119.598,
    accuracy: 4,
  },
  {
    postcode: 6346,
    place_name: 'Jerdacuttup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8964,
    longitude: 120.213,
    accuracy: 4,
  },
  {
    postcode: 6346,
    place_name: 'West River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4166,
    longitude: 120.1755,
    accuracy: 3,
  },
  {
    postcode: 6348,
    place_name: 'Hopetoun',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9506,
    longitude: 120.1264,
    accuracy: 4,
  },
  {
    postcode: 6350,
    place_name: 'Nippering',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.295,
    longitude: 117.638,
    accuracy: 4,
  },
  {
    postcode: 6350,
    place_name: 'Dumbleyung',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3126,
    longitude: 117.7393,
    accuracy: 4,
  },
  {
    postcode: 6350,
    place_name: 'Nairibin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2563,
    longitude: 117.7554,
    accuracy: 3,
  },
  {
    postcode: 6351,
    place_name: 'Moulyinning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2254,
    longitude: 117.9269,
    accuracy: 4,
  },
  {
    postcode: 6351,
    place_name: 'North Moulyinning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.25,
    longitude: 117.9333,
    accuracy: 3,
  },
  {
    postcode: 6352,
    place_name: 'Kukerin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1833,
    longitude: 118.0833,
    accuracy: 4,
  },
  {
    postcode: 6352,
    place_name: 'Merilup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2667,
    longitude: 118.1667,
    accuracy: 4,
  },
  {
    postcode: 6352,
    place_name: 'North Kukerin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2087,
    longitude: 118.0664,
    accuracy: 3,
  },
  {
    postcode: 6352,
    place_name: 'South Kukerin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2087,
    longitude: 118.0664,
    accuracy: 3,
  },
  {
    postcode: 6353,
    place_name: 'Beenong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.045,
    longitude: 118.5853,
    accuracy: 4,
  },
  {
    postcode: 6353,
    place_name: 'Kuender',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.95,
    longitude: 118.5333,
    accuracy: 4,
  },
  {
    postcode: 6353,
    place_name: 'North Lake Grace',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.95,
    longitude: 118.4667,
    accuracy: 4,
  },
  {
    postcode: 6353,
    place_name: 'Mallee Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9592,
    longitude: 118.4872,
    accuracy: 3,
  },
  {
    postcode: 6353,
    place_name: 'Lake Grace',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1032,
    longitude: 118.4646,
    accuracy: 4,
  },
  {
    postcode: 6353,
    place_name: 'Neendaling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1167,
    longitude: 118.3333,
    accuracy: 4,
  },
  {
    postcode: 6353,
    place_name: 'Tarin Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.1075,
    longitude: 118.2308,
    accuracy: 4,
  },
  {
    postcode: 6353,
    place_name: 'North Burngup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9592,
    longitude: 118.4872,
    accuracy: 3,
  },
  {
    postcode: 6353,
    place_name: 'Buniche',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0039,
    longitude: 118.766,
    accuracy: 4,
  },
  {
    postcode: 6353,
    place_name: 'South Lake Grace',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8333,
    longitude: 119.5333,
    accuracy: 4,
  },
  {
    postcode: 6355,
    place_name: 'Lake Biddy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0019,
    longitude: 118.9292,
    accuracy: 4,
  },
  {
    postcode: 6355,
    place_name: 'Lake Camm',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9833,
    longitude: 119.5833,
    accuracy: 4,
  },
  {
    postcode: 6355,
    place_name: 'Varley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7667,
    longitude: 119.45,
    accuracy: 4,
  },
  {
    postcode: 6355,
    place_name: 'South Newdegate',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0865,
    longitude: 118.9698,
    accuracy: 3,
  },
  {
    postcode: 6355,
    place_name: 'Newdegate',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0939,
    longitude: 119.0339,
    accuracy: 4,
  },
  {
    postcode: 6355,
    place_name: 'Holt Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6722,
    longitude: 119.406,
    accuracy: 4,
  },
  {
    postcode: 6355,
    place_name: 'Mount Sheridan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0865,
    longitude: 118.9698,
    accuracy: 3,
  },
  {
    postcode: 6355,
    place_name: 'East Newdegate',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0865,
    longitude: 118.9698,
    accuracy: 3,
  },
  {
    postcode: 6355,
    place_name: 'Magenta',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0865,
    longitude: 118.9698,
    accuracy: 3,
  },
  {
    postcode: 6355,
    place_name: 'Dunn Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0865,
    longitude: 118.9698,
    accuracy: 3,
  },
  {
    postcode: 6355,
    place_name: 'Little Italy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0865,
    longitude: 118.9698,
    accuracy: 3,
  },
  {
    postcode: 6356,
    place_name: 'Lake King',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0859,
    longitude: 119.6755,
    accuracy: 4,
  },
  {
    postcode: 6356,
    place_name: 'Mount Madden',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2667,
    longitude: 119.7833,
    accuracy: 4,
  },
  {
    postcode: 6356,
    place_name: 'Hatter Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.175,
    longitude: 119.725,
    accuracy: 3,
  },
  {
    postcode: 6357,
    place_name: 'Pingaring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7546,
    longitude: 118.6278,
    accuracy: 4,
  },
  {
    postcode: 6358,
    place_name: 'Karlgarin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4971,
    longitude: 118.7098,
    accuracy: 4,
  },
  {
    postcode: 6359,
    place_name: 'Hyden',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.45,
    longitude: 118.8833,
    accuracy: 4,
  },
  {
    postcode: 6359,
    place_name: 'Forrestania',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4947,
    longitude: 119.4469,
    accuracy: 3,
  },
  {
    postcode: 6361,
    place_name: 'Tincurrin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9768,
    longitude: 117.7739,
    accuracy: 4,
  },
  {
    postcode: 6361,
    place_name: 'Harrismith',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9357,
    longitude: 117.8609,
    accuracy: 4,
  },
  {
    postcode: 6363,
    place_name: 'Dudinin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8762,
    longitude: 117.9053,
    accuracy: 4,
  },
  {
    postcode: 6363,
    place_name: 'Walyurin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8483,
    longitude: 117.9961,
    accuracy: 3,
  },
  {
    postcode: 6365,
    place_name: 'Jilakin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6667,
    longitude: 118.3333,
    accuracy: 4,
  },
  {
    postcode: 6365,
    place_name: 'Kulin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.6668,
    longitude: 118.158,
    accuracy: 4,
  },
  {
    postcode: 6365,
    place_name: 'Jitarning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7869,
    longitude: 118.0047,
    accuracy: 4,
  },
  {
    postcode: 6365,
    place_name: 'Kulin West',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7164,
    longitude: 118.2755,
    accuracy: 3,
  },
  {
    postcode: 6367,
    place_name: 'Kondinin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4986,
    longitude: 118.2653,
    accuracy: 4,
  },
  {
    postcode: 6368,
    place_name: 'South Kumminin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2,
    longitude: 118.3333,
    accuracy: 4,
  },
  {
    postcode: 6369,
    place_name: 'Mount Walker',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0823,
    longitude: 118.7442,
    accuracy: 3,
  },
  {
    postcode: 6369,
    place_name: 'Wadderin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.994,
    longitude: 118.4505,
    accuracy: 4,
  },
  {
    postcode: 6369,
    place_name: 'West Holleton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.95,
    longitude: 119.0333,
    accuracy: 4,
  },
  {
    postcode: 6369,
    place_name: 'Woolocutty',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0823,
    longitude: 118.7442,
    accuracy: 3,
  },
  {
    postcode: 6369,
    place_name: 'Narembeen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0637,
    longitude: 118.3957,
    accuracy: 4,
  },
  {
    postcode: 6370,
    place_name: 'Wickepin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7816,
    longitude: 117.503,
    accuracy: 4,
  },
  {
    postcode: 6370,
    place_name: 'Kirk Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.767,
    longitude: 117.7208,
    accuracy: 3,
  },
  {
    postcode: 6370,
    place_name: 'East Wickepin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7833,
    longitude: 117.65,
    accuracy: 4,
  },
  {
    postcode: 6370,
    place_name: 'Malyalling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7,
    longitude: 117.5333,
    accuracy: 4,
  },
  {
    postcode: 6370,
    place_name: 'Wogolin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.767,
    longitude: 117.7208,
    accuracy: 3,
  },
  {
    postcode: 6372,
    place_name: 'Yealering',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5921,
    longitude: 117.6273,
    accuracy: 4,
  },
  {
    postcode: 6373,
    place_name: 'Bullaring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4964,
    longitude: 117.7453,
    accuracy: 4,
  },
  {
    postcode: 6375,
    place_name: 'Bilbarin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2061,
    longitude: 117.9529,
    accuracy: 4,
  },
  {
    postcode: 6375,
    place_name: 'Corrigin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3338,
    longitude: 117.8718,
    accuracy: 4,
  },
  {
    postcode: 6375,
    place_name: 'Kurrenkutten',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.25,
    longitude: 118.2167,
    accuracy: 4,
  },
  {
    postcode: 6375,
    place_name: 'Gorge Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4567,
    longitude: 117.9978,
    accuracy: 4,
  },
  {
    postcode: 6375,
    place_name: 'Kunjin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3554,
    longitude: 117.7709,
    accuracy: 4,
  },
  {
    postcode: 6375,
    place_name: 'Adamsvale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3416,
    longitude: 117.8368,
    accuracy: 3,
  },
  {
    postcode: 6383,
    place_name: 'South Quairading',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1667,
    longitude: 117.4667,
    accuracy: 4,
  },
  {
    postcode: 6383,
    place_name: 'Mount Stirling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0292,
    longitude: 117.3523,
    accuracy: 3,
  },
  {
    postcode: 6383,
    place_name: 'Balkuling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9812,
    longitude: 117.1139,
    accuracy: 4,
  },
  {
    postcode: 6383,
    place_name: 'Cubbine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0292,
    longitude: 117.3523,
    accuracy: 3,
  },
  {
    postcode: 6383,
    place_name: 'Doodenanning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8667,
    longitude: 117.15,
    accuracy: 4,
  },
  {
    postcode: 6383,
    place_name: 'Mawson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0292,
    longitude: 117.3523,
    accuracy: 3,
  },
  {
    postcode: 6383,
    place_name: 'Quairading',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.012,
    longitude: 117.3955,
    accuracy: 4,
  },
  {
    postcode: 6383,
    place_name: 'Dulbelling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.05,
    longitude: 117.2667,
    accuracy: 4,
  },
  {
    postcode: 6383,
    place_name: 'Wamenusking',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1258,
    longitude: 117.5997,
    accuracy: 4,
  },
  {
    postcode: 6383,
    place_name: 'Badjaling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9972,
    longitude: 117.4989,
    accuracy: 4,
  },
  {
    postcode: 6383,
    place_name: 'Yoting',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9602,
    longitude: 117.5914,
    accuracy: 4,
  },
  {
    postcode: 6383,
    place_name: 'Dangin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.05,
    longitude: 117.3167,
    accuracy: 4,
  },
  {
    postcode: 6384,
    place_name: 'Pantapin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9525,
    longitude: 117.6572,
    accuracy: 4,
  },
  {
    postcode: 6385,
    place_name: 'Kwolyin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9295,
    longitude: 117.7501,
    accuracy: 4,
  },
  {
    postcode: 6386,
    place_name: 'Shackleton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9325,
    longitude: 117.8313,
    accuracy: 4,
  },
  {
    postcode: 6390,
    place_name: 'Ranford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8,
    longitude: 116.5,
    accuracy: 4,
  },
  {
    postcode: 6390,
    place_name: 'Boddington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8007,
    longitude: 116.4752,
    accuracy: 4,
  },
  {
    postcode: 6390,
    place_name: 'Crossman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.7833,
    longitude: 116.6,
    accuracy: 4,
  },
  {
    postcode: 6390,
    place_name: 'North Bannister',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5833,
    longitude: 116.4333,
    accuracy: 4,
  },
  {
    postcode: 6390,
    place_name: 'Bannister',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.5833,
    longitude: 116.4333,
    accuracy: 4,
  },
  {
    postcode: 6390,
    place_name: 'Marradong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8631,
    longitude: 116.4508,
    accuracy: 4,
  },
  {
    postcode: 6390,
    place_name: 'Wuraming',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8,
    longitude: 116.2833,
    accuracy: 4,
  },
  {
    postcode: 6390,
    place_name: 'Lower Hotham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.817,
    longitude: 116.4003,
    accuracy: 3,
  },
  {
    postcode: 6390,
    place_name: 'Mount Wells',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.817,
    longitude: 116.4003,
    accuracy: 3,
  },
  {
    postcode: 6390,
    place_name: 'Upper Murray',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.817,
    longitude: 116.4003,
    accuracy: 3,
  },
  {
    postcode: 6390,
    place_name: 'Mount Cooke',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.817,
    longitude: 116.4003,
    accuracy: 3,
  },
  {
    postcode: 6391,
    place_name: 'Williams',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.028,
    longitude: 116.8792,
    accuracy: 4,
  },
  {
    postcode: 6391,
    place_name: 'Narrakine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0714,
    longitude: 116.7281,
    accuracy: 3,
  },
  {
    postcode: 6391,
    place_name: 'Congelin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8402,
    longitude: 116.8854,
    accuracy: 4,
  },
  {
    postcode: 6391,
    place_name: 'Tarwonga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0714,
    longitude: 116.7281,
    accuracy: 3,
  },
  {
    postcode: 6391,
    place_name: 'Quindanning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0466,
    longitude: 116.5698,
    accuracy: 4,
  },
  {
    postcode: 6391,
    place_name: 'Culbin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0714,
    longitude: 116.7281,
    accuracy: 3,
  },
  {
    postcode: 6391,
    place_name: 'Hillman River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2833,
    longitude: 115.7636,
    accuracy: 4,
  },
  {
    postcode: 6391,
    place_name: 'Boraning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.0714,
    longitude: 116.7281,
    accuracy: 3,
  },
  {
    postcode: 6392,
    place_name: 'Meeking',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3563,
    longitude: 116.8259,
    accuracy: 3,
  },
  {
    postcode: 6392,
    place_name: 'Bokal',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4814,
    longitude: 116.9005,
    accuracy: 4,
  },
  {
    postcode: 6392,
    place_name: 'Boolading',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4836,
    longitude: 116.5773,
    accuracy: 3,
  },
  {
    postcode: 6392,
    place_name: 'Darkan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.3357,
    longitude: 116.7443,
    accuracy: 4,
  },
  {
    postcode: 6392,
    place_name: 'Dardadine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2333,
    longitude: 116.8333,
    accuracy: 4,
  },
  {
    postcode: 6393,
    place_name: 'Duranillin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5159,
    longitude: 116.8072,
    accuracy: 4,
  },
  {
    postcode: 6393,
    place_name: 'Moodiarrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5167,
    longitude: 116.725,
    accuracy: 3,
  },
  {
    postcode: 6393,
    place_name: 'Cordering',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4971,
    longitude: 116.6572,
    accuracy: 4,
  },
  {
    postcode: 6393,
    place_name: 'Trigwell',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.5167,
    longitude: 116.725,
    accuracy: 3,
  },
  {
    postcode: 6394,
    place_name: 'Muradup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8333,
    longitude: 116.9833,
    accuracy: 4,
  },
  {
    postcode: 6394,
    place_name: 'Orchid Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7898,
    longitude: 116.8998,
    accuracy: 3,
  },
  {
    postcode: 6394,
    place_name: 'Boscabel',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6667,
    longitude: 117.05,
    accuracy: 4,
  },
  {
    postcode: 6394,
    place_name: 'Beaufort River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7898,
    longitude: 116.8998,
    accuracy: 3,
  },
  {
    postcode: 6394,
    place_name: 'Boilup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7898,
    longitude: 116.8998,
    accuracy: 3,
  },
  {
    postcode: 6394,
    place_name: 'Mokup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7778,
    longitude: 116.9444,
    accuracy: 3,
  },
  {
    postcode: 6394,
    place_name: 'Qualeup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8333,
    longitude: 116.8,
    accuracy: 4,
  },
  {
    postcode: 6394,
    place_name: 'Changerup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7898,
    longitude: 116.8998,
    accuracy: 3,
  },
  {
    postcode: 6395,
    place_name: 'Kojonup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8305,
    longitude: 117.1595,
    accuracy: 4,
  },
  {
    postcode: 6395,
    place_name: 'Mobrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.1257,
    longitude: 116.9282,
    accuracy: 4,
  },
  {
    postcode: 6395,
    place_name: 'Ryansbrook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.0305,
    longitude: 117.0231,
    accuracy: 3,
  },
  {
    postcode: 6395,
    place_name: 'Jingalup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9667,
    longitude: 117.0333,
    accuracy: 4,
  },
  {
    postcode: 6395,
    place_name: 'Lumeah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.9833,
    longitude: 117.2333,
    accuracy: 4,
  },
  {
    postcode: 6395,
    place_name: 'Cherry Tree Pool',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.75,
    longitude: 117.2833,
    accuracy: 4,
  },
  {
    postcode: 6396,
    place_name: 'Frankland',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.3667,
    longitude: 117.0667,
    accuracy: 4,
  },
  {
    postcode: 6396,
    place_name: 'Frankland River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.3667,
    longitude: 117.0667,
    accuracy: 4,
  },
  {
    postcode: 6397,
    place_name: 'Rocky Gully',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.5105,
    longitude: 117.0106,
    accuracy: 4,
  },
  {
    postcode: 6398,
    place_name: 'Broke',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.796,
    longitude: 116.5997,
    accuracy: 3,
  },
  {
    postcode: 6398,
    place_name: 'North Walpole',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.7234,
    longitude: 116.6612,
    accuracy: 4,
  },
  {
    postcode: 6398,
    place_name: 'Walpole',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -34.976,
    longitude: 116.7302,
    accuracy: 4,
  },
  {
    postcode: 6401,
    place_name: 'Southern Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5451,
    longitude: 116.8231,
    accuracy: 4,
  },
  {
    postcode: 6401,
    place_name: 'Cunjardine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5588,
    longitude: 116.7519,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Northam',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6493,
    longitude: 116.6742,
    accuracy: 4,
  },
  {
    postcode: 6401,
    place_name: 'Spencers Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7366,
    longitude: 116.6306,
    accuracy: 4,
  },
  {
    postcode: 6401,
    place_name: 'Jennapullin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5329,
    longitude: 116.7201,
    accuracy: 4,
  },
  {
    postcode: 6401,
    place_name: 'Muluckine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6363,
    longitude: 116.7491,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Rossmore',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5588,
    longitude: 116.7519,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Burlong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5588,
    longitude: 116.7519,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Muresk',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.75,
    longitude: 116.6667,
    accuracy: 4,
  },
  {
    postcode: 6401,
    place_name: 'Irishtown',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5588,
    longitude: 116.7519,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Mokine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7441,
    longitude: 116.5727,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Buckland',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5588,
    longitude: 116.7519,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Malabaine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5588,
    longitude: 116.7519,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Meenaar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5588,
    longitude: 116.7519,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Throssell',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5588,
    longitude: 116.7519,
    accuracy: 3,
  },
  {
    postcode: 6401,
    place_name: 'Jennacubbine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4402,
    longitude: 116.7247,
    accuracy: 4,
  },
  {
    postcode: 6401,
    place_name: 'Wongamine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.476,
    longitude: 116.617,
    accuracy: 4,
  },
  {
    postcode: 6401,
    place_name: 'Mumberkine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5588,
    longitude: 116.7519,
    accuracy: 3,
  },
  {
    postcode: 6403,
    place_name: 'Grass Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6373,
    longitude: 116.7976,
    accuracy: 4,
  },
  {
    postcode: 6405,
    place_name: 'Warding East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6254,
    longitude: 117.0059,
    accuracy: 3,
  },
  {
    postcode: 6405,
    place_name: 'Meckering',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6277,
    longitude: 117.0114,
    accuracy: 4,
  },
  {
    postcode: 6405,
    place_name: 'Quelagetting',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6254,
    longitude: 117.0059,
    accuracy: 3,
  },
  {
    postcode: 6405,
    place_name: 'Greenwoods Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6254,
    longitude: 117.0059,
    accuracy: 3,
  },
  {
    postcode: 6407,
    place_name: 'Youndegin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7885,
    longitude: 117.3287,
    accuracy: 4,
  },
  {
    postcode: 6407,
    place_name: 'Wyola West',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5995,
    longitude: 117.1561,
    accuracy: 3,
  },
  {
    postcode: 6407,
    place_name: 'Cunderdin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.654,
    longitude: 117.2337,
    accuracy: 4,
  },
  {
    postcode: 6407,
    place_name: 'Wyola',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5995,
    longitude: 117.1561,
    accuracy: 3,
  },
  {
    postcode: 6407,
    place_name: 'Watercarrin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5995,
    longitude: 117.1561,
    accuracy: 3,
  },
  {
    postcode: 6407,
    place_name: 'Waeel',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5995,
    longitude: 117.1561,
    accuracy: 3,
  },
  {
    postcode: 6409,
    place_name: 'South Tammin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8099,
    longitude: 117.5363,
    accuracy: 4,
  },
  {
    postcode: 6409,
    place_name: 'North Tammin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6167,
    longitude: 117.5389,
    accuracy: 3,
  },
  {
    postcode: 6409,
    place_name: 'Tammin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6667,
    longitude: 117.5,
    accuracy: 4,
  },
  {
    postcode: 6409,
    place_name: 'Doongin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6067,
    longitude: 117.495,
    accuracy: 3,
  },
  {
    postcode: 6409,
    place_name: 'Yorkrakine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3679,
    longitude: 117.5858,
    accuracy: 4,
  },
  {
    postcode: 6410,
    place_name: 'Mount Caroline',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9586,
    longitude: 117.5524,
    accuracy: 3,
  },
  {
    postcode: 6410,
    place_name: 'Daadenning Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7667,
    longitude: 117.7833,
    accuracy: 4,
  },
  {
    postcode: 6410,
    place_name: 'North Kellerberrin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5,
    longitude: 117.6833,
    accuracy: 4,
  },
  {
    postcode: 6410,
    place_name: 'Bungulla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6238,
    longitude: 117.5898,
    accuracy: 4,
  },
  {
    postcode: 6410,
    place_name: 'Kellerberrin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6318,
    longitude: 117.7198,
    accuracy: 4,
  },
  {
    postcode: 6411,
    place_name: 'South Doodlakine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5822,
    longitude: 117.8148,
    accuracy: 3,
  },
  {
    postcode: 6411,
    place_name: 'Doodlakine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6092,
    longitude: 117.8722,
    accuracy: 4,
  },
  {
    postcode: 6412,
    place_name: 'Baandee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5832,
    longitude: 117.9536,
    accuracy: 4,
  },
  {
    postcode: 6412,
    place_name: 'North Baandee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5833,
    longitude: 117.9667,
    accuracy: 3,
  },
  {
    postcode: 6413,
    place_name: 'Hines Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5342,
    longitude: 118.0748,
    accuracy: 4,
  },
  {
    postcode: 6414,
    place_name: 'Nangeenan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5333,
    longitude: 118.1667,
    accuracy: 4,
  },
  {
    postcode: 6415,
    place_name: 'Merredin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4809,
    longitude: 118.2745,
    accuracy: 4,
  },
  {
    postcode: 6415,
    place_name: 'Goomarin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2444,
    longitude: 118.4358,
    accuracy: 4,
  },
  {
    postcode: 6415,
    place_name: 'Tandegin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4231,
    longitude: 118.2344,
    accuracy: 3,
  },
  {
    postcode: 6415,
    place_name: 'Norpa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.584,
    longitude: 118.4238,
    accuracy: 4,
  },
  {
    postcode: 6415,
    place_name: 'Nokaning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3733,
    longitude: 118.2183,
    accuracy: 4,
  },
  {
    postcode: 6415,
    place_name: 'Korbel',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6602,
    longitude: 118.1489,
    accuracy: 4,
  },
  {
    postcode: 6418,
    place_name: 'Yarding',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9276,
    longitude: 117.9851,
    accuracy: 4,
  },
  {
    postcode: 6418,
    place_name: 'Bruce Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8805,
    longitude: 118.1475,
    accuracy: 4,
  },
  {
    postcode: 6419,
    place_name: 'Ardath',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0325,
    longitude: 118.0952,
    accuracy: 4,
  },
  {
    postcode: 6420,
    place_name: 'Cramphorne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.805,
    longitude: 118.5601,
    accuracy: 4,
  },
  {
    postcode: 6420,
    place_name: 'Muntadgin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7578,
    longitude: 118.5619,
    accuracy: 4,
  },
  {
    postcode: 6421,
    place_name: 'Burracoppin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3975,
    longitude: 118.4815,
    accuracy: 4,
  },
  {
    postcode: 6421,
    place_name: 'South Burracoppin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5667,
    longitude: 118.1167,
    accuracy: 4,
  },
  {
    postcode: 6421,
    place_name: 'Warralakin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0167,
    longitude: 118.6051,
    accuracy: 4,
  },
  {
    postcode: 6422,
    place_name: 'Walgoolan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3833,
    longitude: 118.5667,
    accuracy: 4,
  },
  {
    postcode: 6423,
    place_name: 'Warrachuppin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9683,
    longitude: 118.6569,
    accuracy: 3,
  },
  {
    postcode: 6423,
    place_name: 'Carrabin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3936,
    longitude: 118.6719,
    accuracy: 4,
  },
  {
    postcode: 6423,
    place_name: 'Westonia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3029,
    longitude: 118.6962,
    accuracy: 4,
  },
  {
    postcode: 6423,
    place_name: 'Boodarockin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0127,
    longitude: 118.8046,
    accuracy: 4,
  },
  {
    postcode: 6424,
    place_name: 'Bodallin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3766,
    longitude: 118.8761,
    accuracy: 4,
  },
  {
    postcode: 6424,
    place_name: 'South Bodallin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3667,
    longitude: 118.8667,
    accuracy: 3,
  },
  {
    postcode: 6424,
    place_name: 'North Bodallin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3667,
    longitude: 118.8667,
    accuracy: 3,
  },
  {
    postcode: 6425,
    place_name: 'Moorine Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3208,
    longitude: 119.1267,
    accuracy: 4,
  },
  {
    postcode: 6425,
    place_name: 'Dulyalbin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6054,
    longitude: 119.034,
    accuracy: 3,
  },
  {
    postcode: 6426,
    place_name: 'Parker Range',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.6333,
    longitude: 119.5833,
    accuracy: 4,
  },
  {
    postcode: 6426,
    place_name: 'Ghooli',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2618,
    longitude: 119.4568,
    accuracy: 4,
  },
  {
    postcode: 6426,
    place_name: 'Holleton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.95,
    longitude: 119.0333,
    accuracy: 4,
  },
  {
    postcode: 6426,
    place_name: 'Turkey Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7573,
    longitude: 119.6086,
    accuracy: 3,
  },
  {
    postcode: 6426,
    place_name: 'Marvel Loch',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4678,
    longitude: 119.4873,
    accuracy: 4,
  },
  {
    postcode: 6426,
    place_name: 'Mount Hampton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7573,
    longitude: 119.6086,
    accuracy: 3,
  },
  {
    postcode: 6426,
    place_name: 'Southern Cross',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2268,
    longitude: 119.3238,
    accuracy: 4,
  },
  {
    postcode: 6426,
    place_name: 'Mount Holland',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7573,
    longitude: 119.6086,
    accuracy: 3,
  },
  {
    postcode: 6426,
    place_name: 'Yellowdine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2927,
    longitude: 119.6532,
    accuracy: 4,
  },
  {
    postcode: 6426,
    place_name: 'Corinthia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1171,
    longitude: 119.234,
    accuracy: 4,
  },
  {
    postcode: 6426,
    place_name: 'Mount Palmer',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4062,
    longitude: 119.6815,
    accuracy: 4,
  },
  {
    postcode: 6426,
    place_name: 'Mount Jackson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7573,
    longitude: 119.6086,
    accuracy: 3,
  },
  {
    postcode: 6426,
    place_name: 'Skeleton Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7573,
    longitude: 119.6086,
    accuracy: 3,
  },
  {
    postcode: 6426,
    place_name: 'South Yilgarn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7573,
    longitude: 119.6086,
    accuracy: 3,
  },
  {
    postcode: 6427,
    place_name: 'Koolyanobbing',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8212,
    longitude: 119.5214,
    accuracy: 4,
  },
  {
    postcode: 6428,
    place_name: 'Babakin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1264,
    longitude: 118.0241,
    accuracy: 4,
  },
  {
    postcode: 6429,
    place_name: 'Mount Burges',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.852,
    longitude: 121.117,
    accuracy: 4,
  },
  {
    postcode: 6429,
    place_name: 'Wallaroo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8763,
    longitude: 120.5734,
    accuracy: 4,
  },
  {
    postcode: 6429,
    place_name: 'Karramindie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4493,
    longitude: 120.7804,
    accuracy: 3,
  },
  {
    postcode: 6429,
    place_name: 'Londonderry',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.074,
    longitude: 121.1228,
    accuracy: 4,
  },
  {
    postcode: 6429,
    place_name: 'Victoria Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4493,
    longitude: 120.7804,
    accuracy: 3,
  },
  {
    postcode: 6429,
    place_name: 'Boorabbin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.214,
    longitude: 120.3101,
    accuracy: 4,
  },
  {
    postcode: 6429,
    place_name: 'Coolgardie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9536,
    longitude: 121.166,
    accuracy: 4,
  },
  {
    postcode: 6429,
    place_name: 'Bullabulling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0135,
    longitude: 120.871,
    accuracy: 4,
  },
  {
    postcode: 6430,
    place_name: 'Kalgoorlie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7461,
    longitude: 121.4742,
    accuracy: 4,
  },
  {
    postcode: 6430,
    place_name: 'Karlkurla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8326,
    longitude: 121.49,
    accuracy: 3,
  },
  {
    postcode: 6430,
    place_name: 'West Lamington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7342,
    longitude: 121.4616,
    accuracy: 4,
  },
  {
    postcode: 6430,
    place_name: 'Binduli',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8326,
    longitude: 121.49,
    accuracy: 3,
  },
  {
    postcode: 6430,
    place_name: 'Mullingar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7168,
    longitude: 121.4746,
    accuracy: 4,
  },
  {
    postcode: 6430,
    place_name: 'South Kalgoorlie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.764,
    longitude: 121.48,
    accuracy: 3,
  },
  {
    postcode: 6430,
    place_name: 'Somerville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7698,
    longitude: 121.4544,
    accuracy: 4,
  },
  {
    postcode: 6430,
    place_name: 'West Kalgoorlie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8326,
    longitude: 121.49,
    accuracy: 3,
  },
  {
    postcode: 6430,
    place_name: 'Lamington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7342,
    longitude: 121.4616,
    accuracy: 4,
  },
  {
    postcode: 6430,
    place_name: 'Broadwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7903,
    longitude: 121.4549,
    accuracy: 4,
  },
  {
    postcode: 6430,
    place_name: 'Hannans',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7191,
    longitude: 121.4548,
    accuracy: 4,
  },
  {
    postcode: 6430,
    place_name: 'Yilkari',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8326,
    longitude: 121.49,
    accuracy: 3,
  },
  {
    postcode: 6430,
    place_name: 'Piccadilly',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7428,
    longitude: 121.4642,
    accuracy: 4,
  },
  {
    postcode: 6430,
    place_name: 'Williamstown',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.75,
    longitude: 121.4833,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Kanowna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.6117,
    longitude: 121.6074,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Feysville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7126,
    longitude: 123.8117,
    accuracy: 3,
  },
  {
    postcode: 6431,
    place_name: 'Ora Banda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.377,
    longitude: 121.0619,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Boorara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8167,
    longitude: 121.6333,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Kookynie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3317,
    longitude: 121.4925,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Emu Flat',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7126,
    longitude: 123.8117,
    accuracy: 3,
  },
  {
    postcode: 6431,
    place_name: 'Kurnalpi',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.5367,
    longitude: 122.2306,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Lakewood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8,
    longitude: 121.5333,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Trafalgar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7769,
    longitude: 121.5122,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Plumridge Lakes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7126,
    longitude: 123.8117,
    accuracy: 3,
  },
  {
    postcode: 6431,
    place_name: 'Bulong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7484,
    longitude: 121.7945,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Brown Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7667,
    longitude: 121.5,
    accuracy: 4,
  },
  {
    postcode: 6431,
    place_name: 'Warburton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.5278,
    longitude: 121.5963,
    accuracy: 3,
  },
  {
    postcode: 6432,
    place_name: 'Boulder',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.782,
    longitude: 121.4912,
    accuracy: 4,
  },
  {
    postcode: 6432,
    place_name: 'Victory Heights',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7788,
    longitude: 121.4726,
    accuracy: 4,
  },
  {
    postcode: 6432,
    place_name: 'Fimiston',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7765,
    longitude: 121.5119,
    accuracy: 3,
  },
  {
    postcode: 6432,
    place_name: 'South Boulder',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7917,
    longitude: 121.4887,
    accuracy: 3,
  },
  {
    postcode: 6433,
    place_name: 'Kalgoorlie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7461,
    longitude: 121.4742,
    accuracy: 4,
  },
  {
    postcode: 6433,
    place_name: 'Hannans',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7191,
    longitude: 121.4548,
    accuracy: 4,
  },
  {
    postcode: 6433,
    place_name: 'Kalgoorlie Po',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7326,
    longitude: 121.4645,
    accuracy: 3,
  },
  {
    postcode: 6433,
    place_name: 'Kalgoorlie DC',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7326,
    longitude: 121.4645,
    accuracy: 3,
  },
  {
    postcode: 6433,
    place_name: 'Hannans Po',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7326,
    longitude: 121.4645,
    accuracy: 3,
  },
  {
    postcode: 6434,
    place_name: 'Cundeelee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0505,
    longitude: 123.2116,
    accuracy: 4,
  },
  {
    postcode: 6434,
    place_name: 'Rawlinna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0115,
    longitude: 125.3302,
    accuracy: 4,
  },
  {
    postcode: 6434,
    place_name: 'Parkeston',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8136,
    longitude: 127.0639,
    accuracy: 3,
  },
  {
    postcode: 6434,
    place_name: 'Forrest',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.851,
    longitude: 128.1,
    accuracy: 4,
  },
  {
    postcode: 6434,
    place_name: 'Zanthus',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0351,
    longitude: 123.5775,
    accuracy: 4,
  },
  {
    postcode: 6436,
    place_name: 'Ularring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.5567,
    longitude: 124.3118,
    accuracy: 3,
  },
  {
    postcode: 6436,
    place_name: 'Menzies',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.6939,
    longitude: 121.0285,
    accuracy: 4,
  },
  {
    postcode: 6437,
    place_name: 'Leinster',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.9203,
    longitude: 120.6967,
    accuracy: 4,
  },
  {
    postcode: 6437,
    place_name: 'Sir Samuel',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.9168,
    longitude: 120.6938,
    accuracy: 3,
  },
  {
    postcode: 6438,
    place_name: 'Leonora',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.8851,
    longitude: 121.3307,
    accuracy: 4,
  },
  {
    postcode: 6438,
    place_name: 'Lake Darlot',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1845,
    longitude: 121.8832,
    accuracy: 3,
  },
  {
    postcode: 6440,
    place_name: 'Burtville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7706,
    longitude: 122.6428,
    accuracy: 4,
  },
  {
    postcode: 6440,
    place_name: 'Neale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.5861,
    longitude: 126.0718,
    accuracy: 3,
  },
  {
    postcode: 6440,
    place_name: 'Laverton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.6253,
    longitude: 122.3994,
    accuracy: 4,
  },
  {
    postcode: 6440,
    place_name: 'Bandya',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.5861,
    longitude: 126.0718,
    accuracy: 3,
  },
  {
    postcode: 6440,
    place_name: 'Beadell',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.5861,
    longitude: 126.0718,
    accuracy: 3,
  },
  {
    postcode: 6440,
    place_name: 'Lake Wells',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.5861,
    longitude: 126.0718,
    accuracy: 3,
  },
  {
    postcode: 6440,
    place_name: 'Cosmo Newbery',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.9962,
    longitude: 122.8953,
    accuracy: 4,
  },
  {
    postcode: 6442,
    place_name: 'Kambalda West',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2017,
    longitude: 121.6306,
    accuracy: 4,
  },
  {
    postcode: 6442,
    place_name: 'Kambalda East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2017,
    longitude: 121.6507,
    accuracy: 3,
  },
  {
    postcode: 6442,
    place_name: 'Kambalda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2017,
    longitude: 121.6708,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Higginsville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.75,
    longitude: 121.7167,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Norseman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1977,
    longitude: 121.7787,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Fraser Range',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0134,
    longitude: 124.3774,
    accuracy: 3,
  },
  {
    postcode: 6443,
    place_name: 'Widgiemooltha',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4959,
    longitude: 121.5787,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Balladonia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3458,
    longitude: 123.62,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Madura',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8995,
    longitude: 127.0197,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Dundas',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.3872,
    longitude: 121.7733,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Caiguna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2734,
    longitude: 125.4939,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Cocklebiddy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.037,
    longitude: 126.091,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Mundrabilla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.815,
    longitude: 128.2273,
    accuracy: 4,
  },
  {
    postcode: 6443,
    place_name: 'Eucla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0134,
    longitude: 124.3774,
    accuracy: 3,
  },
  {
    postcode: 6445,
    place_name: 'North Cascade',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9695,
    longitude: 121.2179,
    accuracy: 3,
  },
  {
    postcode: 6445,
    place_name: 'Salmon Gums',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.9833,
    longitude: 121.6333,
    accuracy: 4,
  },
  {
    postcode: 6446,
    place_name: 'Grass Patch',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2317,
    longitude: 121.7222,
    accuracy: 4,
  },
  {
    postcode: 6447,
    place_name: 'Lort River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.45,
    longitude: 121.7167,
    accuracy: 3,
  },
  {
    postcode: 6447,
    place_name: 'Scaddan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.4445,
    longitude: 121.731,
    accuracy: 4,
  },
  {
    postcode: 6447,
    place_name: 'Wittenoom Hills',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.45,
    longitude: 121.7167,
    accuracy: 3,
  },
  {
    postcode: 6447,
    place_name: 'Mount Ney',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.45,
    longitude: 121.7167,
    accuracy: 3,
  },
  {
    postcode: 6448,
    place_name: 'Gibson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.6442,
    longitude: 121.8157,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Chadwick',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8394,
    longitude: 121.8856,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Condingup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7564,
    longitude: 122.5259,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Nulsen',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.857,
    longitude: 121.875,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Coomalbidgup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7194,
    longitude: 121.3606,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Neridup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2828,
    longitude: 122.5813,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Dalyup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7191,
    longitude: 121.598,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Monjingup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7532,
    longitude: 121.7408,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Merivale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2828,
    longitude: 122.5813,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Pink Lake',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8516,
    longitude: 121.8593,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Munglinup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2828,
    longitude: 122.5813,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Windabout',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8192,
    longitude: 121.9204,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Esperance',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8592,
    longitude: 121.8916,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Castletown',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8324,
    longitude: 121.9156,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Bandy Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8278,
    longitude: 121.9281,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'West Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8746,
    longitude: 121.8901,
    accuracy: 4,
  },
  {
    postcode: 6450,
    place_name: 'Myrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.7911,
    longitude: 121.9242,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Cascade',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2828,
    longitude: 122.5813,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Cape Le Grand',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2828,
    longitude: 122.5813,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Boyatup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2828,
    longitude: 122.5813,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Beaumont',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2828,
    longitude: 122.5813,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Howick',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2828,
    longitude: 122.5813,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'Sinclair',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8623,
    longitude: 121.8723,
    accuracy: 3,
  },
  {
    postcode: 6450,
    place_name: 'East Munglinup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.2828,
    longitude: 122.5813,
    accuracy: 3,
  },
  {
    postcode: 6452,
    place_name: 'Cape Arid',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8667,
    longitude: 121.9,
    accuracy: 1,
  },
  {
    postcode: 6452,
    place_name: 'Israelite Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8667,
    longitude: 121.9,
    accuracy: 1,
  },
  {
    postcode: 6452,
    place_name: 'Buraminya',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -33.8667,
    longitude: 121.9,
    accuracy: 1,
  },
  {
    postcode: 6460,
    place_name: 'Ucarty West',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.215,
    longitude: 116.7035,
    accuracy: 3,
  },
  {
    postcode: 6460,
    place_name: 'Goomalling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3016,
    longitude: 116.8253,
    accuracy: 4,
  },
  {
    postcode: 6460,
    place_name: 'Karranadgin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2333,
    longitude: 116.75,
    accuracy: 4,
  },
  {
    postcode: 6460,
    place_name: 'Walyormouring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.215,
    longitude: 116.7035,
    accuracy: 3,
  },
  {
    postcode: 6460,
    place_name: 'Hulongine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.215,
    longitude: 116.7035,
    accuracy: 3,
  },
  {
    postcode: 6461,
    place_name: 'Dowerin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1937,
    longitude: 117.0396,
    accuracy: 4,
  },
  {
    postcode: 6461,
    place_name: 'Koomberkine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1,
    longitude: 117.0333,
    accuracy: 4,
  },
  {
    postcode: 6462,
    place_name: 'Minnivale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1383,
    longitude: 117.1809,
    accuracy: 4,
  },
  {
    postcode: 6462,
    place_name: 'Ucarty',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9957,
    longitude: 117.1834,
    accuracy: 3,
  },
  {
    postcode: 6462,
    place_name: 'Hindmarsh',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9957,
    longitude: 117.1834,
    accuracy: 3,
  },
  {
    postcode: 6463,
    place_name: 'Benjaberring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1471,
    longitude: 117.4098,
    accuracy: 1,
  },
  {
    postcode: 6465,
    place_name: 'Manmanning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8486,
    longitude: 117.0977,
    accuracy: 4,
  },
  {
    postcode: 6466,
    place_name: 'Cadoux',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7694,
    longitude: 117.1328,
    accuracy: 4,
  },
  {
    postcode: 6467,
    place_name: 'Burakin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.5246,
    longitude: 117.1769,
    accuracy: 4,
  },
  {
    postcode: 6468,
    place_name: 'Petrudor',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0614,
    longitude: 117.2011,
    accuracy: 3,
  },
  {
    postcode: 6468,
    place_name: 'Kalannie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3625,
    longitude: 117.1181,
    accuracy: 4,
  },
  {
    postcode: 6468,
    place_name: 'Goodlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0614,
    longitude: 117.2011,
    accuracy: 3,
  },
  {
    postcode: 6470,
    place_name: 'Kulja',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4854,
    longitude: 117.2775,
    accuracy: 4,
  },
  {
    postcode: 6472,
    place_name: 'Tampu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4333,
    longitude: 117.7417,
    accuracy: 3,
  },
  {
    postcode: 6472,
    place_name: 'Remlap',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0362,
    longitude: 117.9778,
    accuracy: 3,
  },
  {
    postcode: 6472,
    place_name: 'Cleary',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4364,
    longitude: 117.6438,
    accuracy: 4,
  },
  {
    postcode: 6472,
    place_name: 'Karroun Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0362,
    longitude: 117.9778,
    accuracy: 3,
  },
  {
    postcode: 6472,
    place_name: 'Mouroubra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.8015,
    longitude: 117.7043,
    accuracy: 4,
  },
  {
    postcode: 6472,
    place_name: 'Bimbijy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0362,
    longitude: 117.9778,
    accuracy: 3,
  },
  {
    postcode: 6472,
    place_name: 'Beacon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4485,
    longitude: 117.863,
    accuracy: 4,
  },
  {
    postcode: 6473,
    place_name: 'North Wialki',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.5744,
    longitude: 118.0118,
    accuracy: 3,
  },
  {
    postcode: 6473,
    place_name: 'Wialki',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.478,
    longitude: 118.12,
    accuracy: 4,
  },
  {
    postcode: 6475,
    place_name: 'Koorda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8256,
    longitude: 117.4879,
    accuracy: 4,
  },
  {
    postcode: 6475,
    place_name: 'Badgerin Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4589,
    longitude: 117.4554,
    accuracy: 3,
  },
  {
    postcode: 6475,
    place_name: 'Mollerin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.455,
    longitude: 117.5256,
    accuracy: 4,
  },
  {
    postcode: 6475,
    place_name: 'Booralaming',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4589,
    longitude: 117.4554,
    accuracy: 3,
  },
  {
    postcode: 6475,
    place_name: 'Dukin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9404,
    longitude: 117.4303,
    accuracy: 4,
  },
  {
    postcode: 6475,
    place_name: 'Newcarlbeon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4589,
    longitude: 117.4554,
    accuracy: 3,
  },
  {
    postcode: 6475,
    place_name: 'Lake Margarette',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4589,
    longitude: 117.4554,
    accuracy: 3,
  },
  {
    postcode: 6476,
    place_name: 'Gabbin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8011,
    longitude: 117.6788,
    accuracy: 4,
  },
  {
    postcode: 6477,
    place_name: 'Bencubbin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8161,
    longitude: 117.8614,
    accuracy: 4,
  },
  {
    postcode: 6477,
    place_name: 'Welbungin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8285,
    longitude: 117.9886,
    accuracy: 4,
  },
  {
    postcode: 6479,
    place_name: 'Lake Brown',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.95,
    longitude: 118.3333,
    accuracy: 4,
  },
  {
    postcode: 6479,
    place_name: 'Dandanning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7281,
    longitude: 118.3019,
    accuracy: 3,
  },
  {
    postcode: 6479,
    place_name: 'Barbalin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7281,
    longitude: 118.3019,
    accuracy: 3,
  },
  {
    postcode: 6479,
    place_name: 'Mukinbudin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9141,
    longitude: 118.2078,
    accuracy: 4,
  },
  {
    postcode: 6479,
    place_name: 'Karloning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7281,
    longitude: 118.3019,
    accuracy: 3,
  },
  {
    postcode: 6479,
    place_name: 'Wattoning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7281,
    longitude: 118.3019,
    accuracy: 3,
  },
  {
    postcode: 6479,
    place_name: 'Elachbutting',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7281,
    longitude: 118.3019,
    accuracy: 3,
  },
  {
    postcode: 6479,
    place_name: 'Bonnie Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.5408,
    longitude: 118.3649,
    accuracy: 4,
  },
  {
    postcode: 6479,
    place_name: 'Wilgoyne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7281,
    longitude: 118.3019,
    accuracy: 3,
  },
  {
    postcode: 6480,
    place_name: 'Nukarni',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2833,
    longitude: 118.2,
    accuracy: 4,
  },
  {
    postcode: 6484,
    place_name: 'Ennuin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9847,
    longitude: 119.1143,
    accuracy: 3,
  },
  {
    postcode: 6484,
    place_name: 'Bullfinch',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9842,
    longitude: 119.1085,
    accuracy: 4,
  },
  {
    postcode: 6484,
    place_name: 'Lake Deborah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9847,
    longitude: 119.1143,
    accuracy: 3,
  },
  {
    postcode: 6485,
    place_name: 'Korrelocking',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2037,
    longitude: 117.4709,
    accuracy: 4,
  },
  {
    postcode: 6485,
    place_name: 'Wyalkatchem',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1806,
    longitude: 117.3836,
    accuracy: 4,
  },
  {
    postcode: 6485,
    place_name: 'Cowcowing',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9983,
    longitude: 117.4497,
    accuracy: 4,
  },
  {
    postcode: 6485,
    place_name: 'Nalkain',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0504,
    longitude: 117.4197,
    accuracy: 4,
  },
  {
    postcode: 6485,
    place_name: 'Nembudding',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1949,
    longitude: 117.5737,
    accuracy: 4,
  },
  {
    postcode: 6487,
    place_name: 'Yelbeni',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1703,
    longitude: 117.6607,
    accuracy: 4,
  },
  {
    postcode: 6487,
    place_name: 'South Yelbeni',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2399,
    longitude: 117.7926,
    accuracy: 3,
  },
  {
    postcode: 6487,
    place_name: 'North Yelbeni',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2399,
    longitude: 117.7926,
    accuracy: 3,
  },
  {
    postcode: 6488,
    place_name: 'Trayning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1169,
    longitude: 117.7935,
    accuracy: 4,
  },
  {
    postcode: 6488,
    place_name: 'South Trayning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2328,
    longitude: 117.7928,
    accuracy: 4,
  },
  {
    postcode: 6488,
    place_name: 'North Trayning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1117,
    longitude: 117.7921,
    accuracy: 3,
  },
  {
    postcode: 6489,
    place_name: 'North Kununoppin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.043,
    longitude: 117.8218,
    accuracy: 3,
  },
  {
    postcode: 6489,
    place_name: 'South Kununoppin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.043,
    longitude: 117.8218,
    accuracy: 3,
  },
  {
    postcode: 6489,
    place_name: 'Kununoppin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1117,
    longitude: 117.9179,
    accuracy: 4,
  },
  {
    postcode: 6490,
    place_name: 'Elabbin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2312,
    longitude: 118.1443,
    accuracy: 4,
  },
  {
    postcode: 6490,
    place_name: 'Nungarin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1864,
    longitude: 118.104,
    accuracy: 4,
  },
  {
    postcode: 6490,
    place_name: 'Chandler',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.253,
    longitude: 118.0448,
    accuracy: 3,
  },
  {
    postcode: 6490,
    place_name: 'Burran Rock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2553,
    longitude: 117.9989,
    accuracy: 4,
  },
  {
    postcode: 6490,
    place_name: 'Kwelkan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1447,
    longitude: 117.9956,
    accuracy: 4,
  },
  {
    postcode: 6490,
    place_name: 'Talgomine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.253,
    longitude: 118.0448,
    accuracy: 3,
  },
  {
    postcode: 6501,
    place_name: 'Muchea',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5821,
    longitude: 115.9716,
    accuracy: 4,
  },
  {
    postcode: 6502,
    place_name: 'Bindoon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3866,
    longitude: 116.0966,
    accuracy: 4,
  },
  {
    postcode: 6502,
    place_name: 'Bindoon Training Area',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3894,
    longitude: 116.0789,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Cullalla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Gingin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3472,
    longitude: 115.9043,
    accuracy: 4,
  },
  {
    postcode: 6503,
    place_name: 'Wanerie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1985,
    longitude: 115.4117,
    accuracy: 4,
  },
  {
    postcode: 6503,
    place_name: 'Coonabidgee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Yeal',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Granville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Orange Springs',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Cowalla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Bambun',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Breera',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Moondah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Moore River National Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Mindarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Boonanarring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Red Gully',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Beermullah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Ginginup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3178,
    longitude: 115.9083,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Muckenburra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3347,
    longitude: 115.7819,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Neergabby',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2995,
    longitude: 115.6374,
    accuracy: 3,
  },
  {
    postcode: 6503,
    place_name: 'Lennard Brook',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2044,
    longitude: 115.7501,
    accuracy: 3,
  },
  {
    postcode: 6504,
    place_name: 'Mooliabeenee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3303,
    longitude: 116.0212,
    accuracy: 4,
  },
  {
    postcode: 6505,
    place_name: 'Wannamal',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1597,
    longitude: 116.056,
    accuracy: 4,
  },
  {
    postcode: 6506,
    place_name: 'Mogumber',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0403,
    longitude: 116.0425,
    accuracy: 4,
  },
  {
    postcode: 6507,
    place_name: 'Dandaragan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.6762,
    longitude: 115.7029,
    accuracy: 4,
  },
  {
    postcode: 6507,
    place_name: 'Cooljarloo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7317,
    longitude: 115.5874,
    accuracy: 3,
  },
  {
    postcode: 6507,
    place_name: 'Cataby',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7317,
    longitude: 115.5874,
    accuracy: 3,
  },
  {
    postcode: 6507,
    place_name: 'Yathroo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7317,
    longitude: 115.5874,
    accuracy: 3,
  },
  {
    postcode: 6507,
    place_name: 'Mimegarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7317,
    longitude: 115.5874,
    accuracy: 3,
  },
  {
    postcode: 6507,
    place_name: 'Regans Ford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7317,
    longitude: 115.5874,
    accuracy: 3,
  },
  {
    postcode: 6509,
    place_name: 'New Norcia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9706,
    longitude: 116.2125,
    accuracy: 4,
  },
  {
    postcode: 6509,
    place_name: 'Waddington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.85,
    longitude: 116.25,
    accuracy: 4,
  },
  {
    postcode: 6509,
    place_name: 'Yarawindah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9083,
    longitude: 116.2333,
    accuracy: 3,
  },
  {
    postcode: 6509,
    place_name: 'Glentromie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9083,
    longitude: 116.2333,
    accuracy: 3,
  },
  {
    postcode: 6510,
    place_name: 'Gillingarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9378,
    longitude: 116.0495,
    accuracy: 4,
  },
  {
    postcode: 6510,
    place_name: 'Barberton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7274,
    longitude: 116.0258,
    accuracy: 4,
  },
  {
    postcode: 6510,
    place_name: 'Moora',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.64,
    longitude: 116.0036,
    accuracy: 4,
  },
  {
    postcode: 6510,
    place_name: 'Berkshire Valley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.55,
    longitude: 116.1667,
    accuracy: 4,
  },
  {
    postcode: 6510,
    place_name: 'Koojan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.805,
    longitude: 116.0283,
    accuracy: 4,
  },
  {
    postcode: 6510,
    place_name: 'Walebing',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.6945,
    longitude: 116.2148,
    accuracy: 4,
  },
  {
    postcode: 6511,
    place_name: 'Cervantes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4961,
    longitude: 115.0672,
    accuracy: 4,
  },
  {
    postcode: 6512,
    place_name: 'Coomberdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4633,
    longitude: 116.0393,
    accuracy: 4,
  },
  {
    postcode: 6512,
    place_name: 'Namban',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3803,
    longitude: 116.046,
    accuracy: 4,
  },
  {
    postcode: 6513,
    place_name: 'Gunyidi',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3585,
    longitude: 116.02,
    accuracy: 3,
  },
  {
    postcode: 6513,
    place_name: 'Watheroo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.2976,
    longitude: 116.0572,
    accuracy: 4,
  },
  {
    postcode: 6514,
    place_name: 'Green Head',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0677,
    longitude: 114.968,
    accuracy: 4,
  },
  {
    postcode: 6514,
    place_name: 'Leeman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.9494,
    longitude: 114.9821,
    accuracy: 4,
  },
  {
    postcode: 6515,
    place_name: 'Waddy Forest',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0217,
    longitude: 115.7621,
    accuracy: 3,
  },
  {
    postcode: 6515,
    place_name: 'Coorow',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.8819,
    longitude: 116.0226,
    accuracy: 4,
  },
  {
    postcode: 6515,
    place_name: 'Marchagee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0543,
    longitude: 116.074,
    accuracy: 4,
  },
  {
    postcode: 6515,
    place_name: 'Eganu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0217,
    longitude: 115.7621,
    accuracy: 3,
  },
  {
    postcode: 6516,
    place_name: 'Jurien Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3059,
    longitude: 115.0383,
    accuracy: 4,
  },
  {
    postcode: 6517,
    place_name: 'Winchester',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.7737,
    longitude: 115.9273,
    accuracy: 4,
  },
  {
    postcode: 6517,
    place_name: 'Carnamah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.6887,
    longitude: 115.8859,
    accuracy: 4,
  },
  {
    postcode: 6518,
    place_name: 'Warradarge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.8242,
    longitude: 115.2604,
    accuracy: 3,
  },
  {
    postcode: 6518,
    place_name: 'Eneabba',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.8196,
    longitude: 115.2659,
    accuracy: 4,
  },
  {
    postcode: 6519,
    place_name: 'Three Springs',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.5336,
    longitude: 115.7605,
    accuracy: 4,
  },
  {
    postcode: 6519,
    place_name: 'Arrino',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.4435,
    longitude: 115.63,
    accuracy: 4,
  },
  {
    postcode: 6519,
    place_name: 'Womarden',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.5254,
    longitude: 115.5772,
    accuracy: 3,
  },
  {
    postcode: 6519,
    place_name: 'Arrowsmith East',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.5254,
    longitude: 115.5772,
    accuracy: 3,
  },
  {
    postcode: 6519,
    place_name: 'Kadathinni',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.5254,
    longitude: 115.5772,
    accuracy: 3,
  },
  {
    postcode: 6519,
    place_name: 'Dudawa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3861,
    longitude: 115.7353,
    accuracy: 4,
  },
  {
    postcode: 6521,
    place_name: 'Nambung',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3427,
    longitude: 115.662,
    accuracy: 1,
  },
  {
    postcode: 6521,
    place_name: 'Badgingarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3427,
    longitude: 115.662,
    accuracy: 1,
  },
  {
    postcode: 6521,
    place_name: 'Boothendarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3427,
    longitude: 115.662,
    accuracy: 1,
  },
  {
    postcode: 6521,
    place_name: 'Grey',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3427,
    longitude: 115.662,
    accuracy: 1,
  },
  {
    postcode: 6521,
    place_name: 'Hill River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3427,
    longitude: 115.662,
    accuracy: 1,
  },
  {
    postcode: 6522,
    place_name: 'Yandanooka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3121,
    longitude: 115.5686,
    accuracy: 4,
  },
  {
    postcode: 6522,
    place_name: 'Bundanoon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1579,
    longitude: 115.4843,
    accuracy: 3,
  },
  {
    postcode: 6522,
    place_name: 'Mingenew',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1908,
    longitude: 115.4416,
    accuracy: 4,
  },
  {
    postcode: 6522,
    place_name: 'Holmwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1579,
    longitude: 115.4843,
    accuracy: 3,
  },
  {
    postcode: 6522,
    place_name: 'Yarragadee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1579,
    longitude: 115.4843,
    accuracy: 3,
  },
  {
    postcode: 6522,
    place_name: 'Lockier',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1579,
    longitude: 115.4843,
    accuracy: 3,
  },
  {
    postcode: 6522,
    place_name: 'Nangetty',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1579,
    longitude: 115.4843,
    accuracy: 3,
  },
  {
    postcode: 6522,
    place_name: 'Mooriary',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1579,
    longitude: 115.4843,
    accuracy: 3,
  },
  {
    postcode: 6522,
    place_name: 'Mount Budd',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1579,
    longitude: 115.4843,
    accuracy: 3,
  },
  {
    postcode: 6522,
    place_name: 'Ikewa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1579,
    longitude: 115.4843,
    accuracy: 3,
  },
  {
    postcode: 6525,
    place_name: 'Irwin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.2204,
    longitude: 115.0801,
    accuracy: 4,
  },
  {
    postcode: 6525,
    place_name: 'Port Denison',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.2833,
    longitude: 114.9167,
    accuracy: 4,
  },
  {
    postcode: 6525,
    place_name: 'Dongara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.2582,
    longitude: 114.9328,
    accuracy: 4,
  },
  {
    postcode: 6525,
    place_name: 'Allanooka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3265,
    longitude: 115.0917,
    accuracy: 3,
  },
  {
    postcode: 6525,
    place_name: 'Mount Adams',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3265,
    longitude: 115.0917,
    accuracy: 3,
  },
  {
    postcode: 6525,
    place_name: 'Milo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3265,
    longitude: 115.0917,
    accuracy: 3,
  },
  {
    postcode: 6525,
    place_name: 'Bookara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3265,
    longitude: 115.0917,
    accuracy: 3,
  },
  {
    postcode: 6525,
    place_name: 'Yardarino',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3265,
    longitude: 115.0917,
    accuracy: 3,
  },
  {
    postcode: 6525,
    place_name: 'Arrowsmith',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3265,
    longitude: 115.0917,
    accuracy: 3,
  },
  {
    postcode: 6525,
    place_name: 'Mount Horner',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3265,
    longitude: 115.0917,
    accuracy: 3,
  },
  {
    postcode: 6525,
    place_name: 'Springfield',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3265,
    longitude: 115.0917,
    accuracy: 3,
  },
  {
    postcode: 6525,
    place_name: 'Bonniefield',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.2058,
    longitude: 114.9392,
    accuracy: 3,
  },
  {
    postcode: 6528,
    place_name: 'South Greenough',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.9269,
    longitude: 114.8997,
    accuracy: 3,
  },
  {
    postcode: 6528,
    place_name: 'Walkaway',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.9412,
    longitude: 114.8031,
    accuracy: 4,
  },
  {
    postcode: 6528,
    place_name: 'Mount Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.9269,
    longitude: 114.8997,
    accuracy: 3,
  },
  {
    postcode: 6530,
    place_name: 'Tarcoola Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.81,
    longitude: 114.6224,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Strathalbyn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7517,
    longitude: 114.6458,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Woorree',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7668,
    longitude: 114.6581,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Wandina',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.8194,
    longitude: 114.6346,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Rangeway',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7866,
    longitude: 114.6306,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Waggrakine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7188,
    longitude: 114.6392,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Karloo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.791,
    longitude: 114.6411,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Beresford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7575,
    longitude: 114.6196,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Utakarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7833,
    longitude: 114.65,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Sunset Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7191,
    longitude: 114.623,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Webberton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7524,
    longitude: 114.6322,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'West End',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7817,
    longitude: 114.5793,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Beachlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7857,
    longitude: 114.6019,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Spalding',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7352,
    longitude: 114.6365,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Houtman Abrolhos',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.5586,
    longitude: 113.7584,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Bluff Point',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7374,
    longitude: 114.6228,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Meru',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.8033,
    longitude: 114.6864,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Moresby',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7352,
    longitude: 114.6724,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Wonthella',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7669,
    longitude: 114.6334,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Mahomets Flats',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.792,
    longitude: 114.6133,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Mount Tarcoola',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7986,
    longitude: 114.6253,
    accuracy: 4,
  },
  {
    postcode: 6530,
    place_name: 'Geraldton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.779,
    longitude: 114.6146,
    accuracy: 4,
  },
  {
    postcode: 6531,
    place_name: 'Geraldton Po',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.779,
    longitude: 114.6146,
    accuracy: 3,
  },
  {
    postcode: 6531,
    place_name: 'Geraldton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.779,
    longitude: 114.6146,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Meadow',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.4972,
    longitude: 115.7533,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Ajana',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.9558,
    longitude: 114.6302,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Wandana',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Drummond Cove',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.6738,
    longitude: 114.6128,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Eradu South',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Rudds Gully',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.8355,
    longitude: 114.6416,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Yuna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3333,
    longitude: 115,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Yetna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.6102,
    longitude: 114.6989,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Coburn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.5613,
    longitude: 114.747,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Moonyoonooka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.781,
    longitude: 114.727,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Deepdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7864,
    longitude: 114.6951,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Tamala',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7061,
    longitude: 115.7201,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Sandsprings',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Glenfield',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.6925,
    longitude: 114.6214,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Northern Gully',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7138,
    longitude: 114.9087,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Nabawa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.4995,
    longitude: 114.7968,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Rockwell',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.346,
    longitude: 114.925,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Narngulu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.8205,
    longitude: 114.6925,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Nerren Nerren',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.5613,
    longitude: 114.747,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Kojarena',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7224,
    longitude: 114.8656,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Durawah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Oakajee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.5697,
    longitude: 114.631,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Nolba',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3667,
    longitude: 114.8667,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Georgina',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.8775,
    longitude: 114.7536,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Narra Tarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7,
    longitude: 114.7167,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Bootenal',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.8558,
    longitude: 114.7275,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Toolonga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.5613,
    longitude: 114.747,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Dindiloa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.4307,
    longitude: 114.7895,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Binnu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.0424,
    longitude: 114.678,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Naraling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.4,
    longitude: 114.8333,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Howatharra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.5333,
    longitude: 114.6167,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Cape Burney',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.8675,
    longitude: 114.6489,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Coolcalalaya',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Greenough',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.95,
    longitude: 114.7333,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Bringo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7491,
    longitude: 114.8439,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Wicherina',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7178,
    longitude: 115.0144,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Mount Erin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Nanson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.5788,
    longitude: 114.7545,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'Wicherina South',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'West Binnu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.0424,
    longitude: 114.678,
    accuracy: 4,
  },
  {
    postcode: 6532,
    place_name: 'South Yuna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'North Eradu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Carrarang',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.5613,
    longitude: 114.747,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Valentine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Dartmoor',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Ellendale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'East Chapman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'White Peak',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.6359,
    longitude: 114.6434,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Eradu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Hickety',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Minnenooka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.8244,
    longitude: 114.9003,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Hamelin Pool',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Marrah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Eurardy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Burma Road',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Tibradden',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'East Yuna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'Buller',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6532,
    place_name: 'East Nabawa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3989,
    longitude: 115.0313,
    accuracy: 3,
  },
  {
    postcode: 6535,
    place_name: 'Ogilvie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.15,
    longitude: 114.6333,
    accuracy: 4,
  },
  {
    postcode: 6535,
    place_name: 'Horrocks',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3861,
    longitude: 114.43,
    accuracy: 4,
  },
  {
    postcode: 6535,
    place_name: 'Isseka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.4333,
    longitude: 114.6333,
    accuracy: 4,
  },
  {
    postcode: 6535,
    place_name: 'Yallabatharra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.1311,
    longitude: 114.3442,
    accuracy: 3,
  },
  {
    postcode: 6535,
    place_name: 'Gregory',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.1865,
    longitude: 114.2533,
    accuracy: 4,
  },
  {
    postcode: 6535,
    place_name: 'Bowes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3833,
    longitude: 114.6667,
    accuracy: 4,
  },
  {
    postcode: 6535,
    place_name: 'Northampton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3499,
    longitude: 114.6382,
    accuracy: 4,
  },
  {
    postcode: 6535,
    place_name: 'Alma',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.0013,
    longitude: 114.8555,
    accuracy: 3,
  },
  {
    postcode: 6535,
    place_name: 'East Bowes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.0013,
    longitude: 114.8555,
    accuracy: 3,
  },
  {
    postcode: 6535,
    place_name: 'Sandy Gully',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.0013,
    longitude: 114.8555,
    accuracy: 3,
  },
  {
    postcode: 6536,
    place_name: 'Zuytdorp',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.6034,
    longitude: 114.5223,
    accuracy: 3,
  },
  {
    postcode: 6536,
    place_name: 'Kalbarri',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.7105,
    longitude: 114.165,
    accuracy: 4,
  },
  {
    postcode: 6536,
    place_name: 'Kalbarri National Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.6034,
    longitude: 114.5223,
    accuracy: 3,
  },
  {
    postcode: 6537,
    place_name: 'Denham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.9287,
    longitude: 113.5355,
    accuracy: 4,
  },
  {
    postcode: 6537,
    place_name: 'Monkey Mia',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.794,
    longitude: 113.718,
    accuracy: 4,
  },
  {
    postcode: 6537,
    place_name: 'Francois Peron National Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8167,
    longitude: 116.0667,
    accuracy: 3,
  },
  {
    postcode: 6537,
    place_name: 'Dirk Hartog Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8167,
    longitude: 116.0667,
    accuracy: 3,
  },
  {
    postcode: 6537,
    place_name: 'Nanga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8167,
    longitude: 116.0667,
    accuracy: 3,
  },
  {
    postcode: 6537,
    place_name: 'Shark Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8167,
    longitude: 116.0667,
    accuracy: 3,
  },
  {
    postcode: 6537,
    place_name: 'Useless Loop',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8167,
    longitude: 116.0667,
    accuracy: 3,
  },
  {
    postcode: 6556,
    place_name: 'Beechina',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.85,
    longitude: 116.3167,
    accuracy: 4,
  },
  {
    postcode: 6556,
    place_name: 'The Lakes',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8745,
    longitude: 116.3359,
    accuracy: 4,
  },
  {
    postcode: 6556,
    place_name: 'Chidlow',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8609,
    longitude: 116.2724,
    accuracy: 4,
  },
  {
    postcode: 6556,
    place_name: 'Malmalling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8473,
    longitude: 116.3095,
    accuracy: 3,
  },
  {
    postcode: 6556,
    place_name: 'Gorrie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8473,
    longitude: 116.3095,
    accuracy: 3,
  },
  {
    postcode: 6558,
    place_name: 'Wooroloo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8038,
    longitude: 116.3131,
    accuracy: 4,
  },
  {
    postcode: 6560,
    place_name: 'Wundowie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7616,
    longitude: 116.3799,
    accuracy: 4,
  },
  {
    postcode: 6562,
    place_name: 'Copley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.835,
    longitude: 116.4467,
    accuracy: 3,
  },
  {
    postcode: 6562,
    place_name: 'Bakers Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7469,
    longitude: 116.4587,
    accuracy: 4,
  },
  {
    postcode: 6562,
    place_name: 'Woottating',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.835,
    longitude: 116.4467,
    accuracy: 3,
  },
  {
    postcode: 6564,
    place_name: 'Clackline',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.7333,
    longitude: 116.5333,
    accuracy: 4,
  },
  {
    postcode: 6566,
    place_name: 'Carani',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.9925,
    longitude: 116.4053,
    accuracy: 4,
  },
  {
    postcode: 6566,
    place_name: 'Culham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4167,
    longitude: 116.4667,
    accuracy: 4,
  },
  {
    postcode: 6566,
    place_name: 'West Toodyay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5333,
    longitude: 116.4167,
    accuracy: 4,
  },
  {
    postcode: 6566,
    place_name: 'Coondle',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4818,
    longitude: 116.4276,
    accuracy: 4,
  },
  {
    postcode: 6566,
    place_name: 'Nunile',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4304,
    longitude: 116.4453,
    accuracy: 3,
  },
  {
    postcode: 6566,
    place_name: 'Toodyay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5498,
    longitude: 116.4674,
    accuracy: 4,
  },
  {
    postcode: 6566,
    place_name: 'Katrine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4304,
    longitude: 116.4453,
    accuracy: 3,
  },
  {
    postcode: 6566,
    place_name: 'Bejoording',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4,
    longitude: 116.5,
    accuracy: 4,
  },
  {
    postcode: 6566,
    place_name: 'Hoddys Well',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4304,
    longitude: 116.4453,
    accuracy: 3,
  },
  {
    postcode: 6566,
    place_name: 'Dumbarton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.5682,
    longitude: 116.523,
    accuracy: 3,
  },
  {
    postcode: 6567,
    place_name: 'Julimar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4779,
    longitude: 116.2483,
    accuracy: 4,
  },
  {
    postcode: 6567,
    place_name: 'Dewars Pool',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4833,
    longitude: 116.4333,
    accuracy: 4,
  },
  {
    postcode: 6567,
    place_name: 'Moondyne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.4812,
    longitude: 116.2698,
    accuracy: 3,
  },
  {
    postcode: 6568,
    place_name: 'Wattening',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.3333,
    longitude: 116.5167,
    accuracy: 4,
  },
  {
    postcode: 6568,
    place_name: 'Wyening',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.1887,
    longitude: 116.4857,
    accuracy: 4,
  },
  {
    postcode: 6568,
    place_name: 'Bolgart',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.2667,
    longitude: 116.5,
    accuracy: 4,
  },
  {
    postcode: 6569,
    place_name: 'Old Plains',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0885,
    longitude: 116.4488,
    accuracy: 3,
  },
  {
    postcode: 6569,
    place_name: 'Calingiri',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0916,
    longitude: 116.4478,
    accuracy: 4,
  },
  {
    postcode: 6571,
    place_name: 'Yerecoin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.675,
    longitude: 116.3667,
    accuracy: 1,
  },
  {
    postcode: 6572,
    place_name: 'Piawaning',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.841,
    longitude: 116.3884,
    accuracy: 4,
  },
  {
    postcode: 6574,
    place_name: 'Gabalong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7214,
    longitude: 116.389,
    accuracy: 4,
  },
  {
    postcode: 6574,
    place_name: 'Bindi Bindi',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.6352,
    longitude: 116.3587,
    accuracy: 4,
  },
  {
    postcode: 6575,
    place_name: 'Miling',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.4897,
    longitude: 116.3624,
    accuracy: 4,
  },
  {
    postcode: 6603,
    place_name: 'Konnongorring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.0833,
    longitude: 116.7833,
    accuracy: 4,
  },
  {
    postcode: 6603,
    place_name: 'Lake Hinds',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7635,
    longitude: 116.846,
    accuracy: 3,
  },
  {
    postcode: 6603,
    place_name: 'Wongan Hills',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.8929,
    longitude: 116.7193,
    accuracy: 4,
  },
  {
    postcode: 6603,
    place_name: 'Lake Ninan',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7635,
    longitude: 116.846,
    accuracy: 3,
  },
  {
    postcode: 6603,
    place_name: 'Mocardy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7635,
    longitude: 116.846,
    accuracy: 3,
  },
  {
    postcode: 6605,
    place_name: 'Kondut',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.7109,
    longitude: 116.7732,
    accuracy: 4,
  },
  {
    postcode: 6606,
    place_name: 'West Ballidu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.5917,
    longitude: 116.7691,
    accuracy: 3,
  },
  {
    postcode: 6606,
    place_name: 'East Ballidu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.6833,
    longitude: 116.9,
    accuracy: 4,
  },
  {
    postcode: 6606,
    place_name: 'Ballidu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.5984,
    longitude: 116.7703,
    accuracy: 4,
  },
  {
    postcode: 6608,
    place_name: 'Pithara',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.3919,
    longitude: 116.6688,
    accuracy: 4,
  },
  {
    postcode: 6608,
    place_name: 'Marne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.2704,
    longitude: 116.643,
    accuracy: 3,
  },
  {
    postcode: 6608,
    place_name: 'East Damboring',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.2704,
    longitude: 116.643,
    accuracy: 3,
  },
  {
    postcode: 6609,
    place_name: 'Dalwallinu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.2805,
    longitude: 116.6601,
    accuracy: 4,
  },
  {
    postcode: 6609,
    place_name: 'Xantippe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.0333,
    longitude: 117.0333,
    accuracy: 4,
  },
  {
    postcode: 6609,
    place_name: 'Nugadong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.2039,
    longitude: 116.6636,
    accuracy: 4,
  },
  {
    postcode: 6612,
    place_name: 'Wubin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -30.1065,
    longitude: 116.6332,
    accuracy: 4,
  },
  {
    postcode: 6612,
    place_name: 'Miamoon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1266,
    longitude: 117.3905,
    accuracy: 3,
  },
  {
    postcode: 6612,
    place_name: 'Paynes Find',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.2592,
    longitude: 117.6893,
    accuracy: 4,
  },
  {
    postcode: 6612,
    place_name: 'Jibberding',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1266,
    longitude: 117.3905,
    accuracy: 3,
  },
  {
    postcode: 6613,
    place_name: 'Buntine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.987,
    longitude: 116.5713,
    accuracy: 4,
  },
  {
    postcode: 6614,
    place_name: 'Maya',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.8849,
    longitude: 116.5044,
    accuracy: 4,
  },
  {
    postcode: 6616,
    place_name: 'Latham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.7587,
    longitude: 116.4457,
    accuracy: 4,
  },
  {
    postcode: 6620,
    place_name: 'Rothsay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.2833,
    longitude: 116.8833,
    accuracy: 4,
  },
  {
    postcode: 6620,
    place_name: 'Perenjori',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.4361,
    longitude: 116.2856,
    accuracy: 4,
  },
  {
    postcode: 6623,
    place_name: 'Pintharuka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.094,
    longitude: 115.982,
    accuracy: 4,
  },
  {
    postcode: 6623,
    place_name: 'Koolanooka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.271,
    longitude: 116.0747,
    accuracy: 4,
  },
  {
    postcode: 6623,
    place_name: 'Morawa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.2134,
    longitude: 116.0079,
    accuracy: 4,
  },
  {
    postcode: 6623,
    place_name: 'Bunjil',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.653,
    longitude: 116.3664,
    accuracy: 4,
  },
  {
    postcode: 6623,
    place_name: 'Bowgada',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.3349,
    longitude: 116.1743,
    accuracy: 4,
  },
  {
    postcode: 6623,
    place_name: 'Gutha',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.994,
    longitude: 115.946,
    accuracy: 4,
  },
  {
    postcode: 6625,
    place_name: 'Merkanooka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -29.1963,
    longitude: 116.1275,
    accuracy: 1,
  },
  {
    postcode: 6627,
    place_name: 'Canna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.9002,
    longitude: 115.8621,
    accuracy: 4,
  },
  {
    postcode: 6628,
    place_name: 'Tardun',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.7937,
    longitude: 115.7478,
    accuracy: 4,
  },
  {
    postcode: 6630,
    place_name: 'Nerramyne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.2164,
    longitude: 115.966,
    accuracy: 3,
  },
  {
    postcode: 6630,
    place_name: 'Murchison',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.8949,
    longitude: 115.9575,
    accuracy: 4,
  },
  {
    postcode: 6630,
    place_name: 'Woolgorong',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.2164,
    longitude: 115.966,
    accuracy: 3,
  },
  {
    postcode: 6630,
    place_name: 'Mullewa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.5395,
    longitude: 115.5117,
    accuracy: 4,
  },
  {
    postcode: 6630,
    place_name: 'Nunierra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.2164,
    longitude: 115.966,
    accuracy: 3,
  },
  {
    postcode: 6630,
    place_name: 'Wongoondy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.2164,
    longitude: 115.966,
    accuracy: 3,
  },
  {
    postcode: 6630,
    place_name: 'Devils Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.2164,
    longitude: 115.966,
    accuracy: 3,
  },
  {
    postcode: 6630,
    place_name: 'West Casuarinas',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.2164,
    longitude: 115.966,
    accuracy: 3,
  },
  {
    postcode: 6631,
    place_name: 'Pindar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.4804,
    longitude: 115.793,
    accuracy: 4,
  },
  {
    postcode: 6632,
    place_name: 'Tenindewa',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.6178,
    longitude: 115.3625,
    accuracy: 4,
  },
  {
    postcode: 6632,
    place_name: 'Ambania',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.6965,
    longitude: 115.1016,
    accuracy: 4,
  },
  {
    postcode: 6635,
    place_name: 'Yalgoo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3436,
    longitude: 116.6825,
    accuracy: 4,
  },
  {
    postcode: 6635,
    place_name: 'South Murchison',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.3163,
    longitude: 116.5712,
    accuracy: 3,
  },
  {
    postcode: 6638,
    place_name: 'Mount Magnet',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.0648,
    longitude: 117.849,
    accuracy: 4,
  },
  {
    postcode: 6638,
    place_name: 'Paynesville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3061,
    longitude: 118.822,
    accuracy: 3,
  },
  {
    postcode: 6638,
    place_name: 'Daggar Hills',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.3061,
    longitude: 118.822,
    accuracy: 3,
  },
  {
    postcode: 6638,
    place_name: 'Cooladar Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -28.4825,
    longitude: 118.1683,
    accuracy: 3,
  },
  {
    postcode: 6639,
    place_name: 'Sandstone',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.9868,
    longitude: 119.2973,
    accuracy: 4,
  },
  {
    postcode: 6640,
    place_name: 'Cue',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.424,
    longitude: 117.8974,
    accuracy: 4,
  },
  {
    postcode: 6640,
    place_name: 'East Murchison',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.8712,
    longitude: 117.4493,
    accuracy: 3,
  },
  {
    postcode: 6640,
    place_name: 'Weld Range',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.8712,
    longitude: 117.4493,
    accuracy: 3,
  },
  {
    postcode: 6640,
    place_name: 'Reedy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -27.15,
    longitude: 118.2667,
    accuracy: 4,
  },
  {
    postcode: 6640,
    place_name: 'Lake Austin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.8712,
    longitude: 117.4493,
    accuracy: 3,
  },
  {
    postcode: 6642,
    place_name: 'Peak Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.6375,
    longitude: 118.7244,
    accuracy: 4,
  },
  {
    postcode: 6642,
    place_name: 'Capricorn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.1448,
    longitude: 118.5342,
    accuracy: 3,
  },
  {
    postcode: 6642,
    place_name: 'Meekatharra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.5952,
    longitude: 118.4939,
    accuracy: 4,
  },
  {
    postcode: 6642,
    place_name: 'Angelo River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.1448,
    longitude: 118.5342,
    accuracy: 3,
  },
  {
    postcode: 6642,
    place_name: 'Karalundi',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.5952,
    longitude: 118.4939,
    accuracy: 3,
  },
  {
    postcode: 6642,
    place_name: 'Kumarina',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.1448,
    longitude: 118.5342,
    accuracy: 3,
  },
  {
    postcode: 6646,
    place_name: 'Wiluna',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.5921,
    longitude: 120.224,
    accuracy: 4,
  },
  {
    postcode: 6646,
    place_name: 'Lake Carnegie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.3077,
    longitude: 122.2496,
    accuracy: 3,
  },
  {
    postcode: 6646,
    place_name: 'Little Sandy Desert',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.3077,
    longitude: 122.2496,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Lyndon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Kingsford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.8639,
    longitude: 113.6955,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Gilroyd',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.5828,
    longitude: 119.1891,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Talisker',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.5828,
    longitude: 119.1891,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Wooramel',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Coral Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.1408,
    longitude: 113.7763,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Yandoo Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Woodleigh',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.5828,
    longitude: 119.1891,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'South Plantations',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.8423,
    longitude: 113.7632,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Morgantown',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.8765,
    longitude: 113.6589,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Brown Range',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.8916,
    longitude: 113.6985,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Bernier Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'South Carnarvon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.8569,
    longitude: 113.7148,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'East Carnarvon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.864,
    longitude: 113.6777,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Carbla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -26.1976,
    longitude: 114.3872,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Inggarda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'North Plantations',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.8447,
    longitude: 113.7088,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Kennedy Range',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Ningaloo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.8361,
    longitude: 113.8012,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Babbage Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Brockman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.8813,
    longitude: 113.6536,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Yalardy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.5828,
    longitude: 119.1891,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Massey Bay',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Mauds Landing',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Macleod',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Carnarvon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.8826,
    longitude: 113.6571,
    accuracy: 4,
  },
  {
    postcode: 6701,
    place_name: 'Greys Plain',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.883,
    longitude: 113.7018,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Dorre Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6701,
    place_name: 'Minilya',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.161,
    longitude: 114.4806,
    accuracy: 3,
  },
  {
    postcode: 6705,
    place_name: 'Gascoyne Junction',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.0499,
    longitude: 115.2091,
    accuracy: 4,
  },
  {
    postcode: 6705,
    place_name: 'East Lyons River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.7255,
    longitude: 116.3552,
    accuracy: 3,
  },
  {
    postcode: 6705,
    place_name: 'West Lyons River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -24.7255,
    longitude: 116.3552,
    accuracy: 3,
  },
  {
    postcode: 6705,
    place_name: 'Gascoyne River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -25.0499,
    longitude: 115.2091,
    accuracy: 4,
  },
  {
    postcode: 6707,
    place_name: 'Exmouth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.9306,
    longitude: 114.1209,
    accuracy: 4,
  },
  {
    postcode: 6707,
    place_name: 'Learmonth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.25,
    longitude: 114.0833,
    accuracy: 4,
  },
  {
    postcode: 6707,
    place_name: 'Cape Range National Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.2143,
    longitude: 113.9896,
    accuracy: 3,
  },
  {
    postcode: 6707,
    place_name: 'North West Cape',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.882,
    longitude: 114.1325,
    accuracy: 4,
  },
  {
    postcode: 6707,
    place_name: 'Exmouth Gulf',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.2143,
    longitude: 113.9896,
    accuracy: 3,
  },
  {
    postcode: 6710,
    place_name: 'Onslow',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.6376,
    longitude: 115.1122,
    accuracy: 4,
  },
  {
    postcode: 6710,
    place_name: 'Talandji',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.5027,
    longitude: 116.2032,
    accuracy: 3,
  },
  {
    postcode: 6710,
    place_name: 'Peedamulla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.5027,
    longitude: 116.2032,
    accuracy: 3,
  },
  {
    postcode: 6710,
    place_name: 'Yannarie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.5027,
    longitude: 116.2032,
    accuracy: 3,
  },
  {
    postcode: 6710,
    place_name: 'Cane',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.5027,
    longitude: 116.2032,
    accuracy: 3,
  },
  {
    postcode: 6711,
    place_name: 'Thevenard Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.9833,
    longitude: 116.6917,
    accuracy: 1,
  },
  {
    postcode: 6712,
    place_name: 'Barrow Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.9833,
    longitude: 116.6917,
    accuracy: 1,
  },
  {
    postcode: 6713,
    place_name: 'Dampier',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.6627,
    longitude: 116.7126,
    accuracy: 4,
  },
  {
    postcode: 6713,
    place_name: 'Dampier Archipelago',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.668,
    longitude: 116.7191,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Mulataga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7395,
    longitude: 116.8774,
    accuracy: 4,
  },
  {
    postcode: 6714,
    place_name: 'Karratha',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7377,
    longitude: 116.8463,
    accuracy: 4,
  },
  {
    postcode: 6714,
    place_name: 'Baynton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7516,
    longitude: 116.8014,
    accuracy: 4,
  },
  {
    postcode: 6714,
    place_name: 'Karratha Industrial Estate',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7678,
    longitude: 116.8708,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Millars Well',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7417,
    longitude: 116.8173,
    accuracy: 4,
  },
  {
    postcode: 6714,
    place_name: 'Pegs Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7383,
    longitude: 116.8328,
    accuracy: 4,
  },
  {
    postcode: 6714,
    place_name: 'Balla Balla',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0631,
    longitude: 116.4802,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Bulgarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7258,
    longitude: 116.8567,
    accuracy: 4,
  },
  {
    postcode: 6714,
    place_name: 'Antonymyre',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0631,
    longitude: 116.4802,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Cleaverville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0631,
    longitude: 116.4802,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Stove Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7574,
    longitude: 116.8495,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Nickol',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7456,
    longitude: 116.7954,
    accuracy: 4,
  },
  {
    postcode: 6714,
    place_name: 'Mount Anketell',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0631,
    longitude: 116.4802,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Sherlock',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0631,
    longitude: 116.4802,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Burrup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.5901,
    longitude: 116.796,
    accuracy: 4,
  },
  {
    postcode: 6714,
    place_name: 'Cooya Pooya',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0631,
    longitude: 116.4802,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Gap Ridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7461,
    longitude: 116.7856,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Mardie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0631,
    longitude: 116.4802,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Maitland',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0631,
    longitude: 116.4802,
    accuracy: 3,
  },
  {
    postcode: 6714,
    place_name: 'Gnoorea',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0631,
    longitude: 116.4802,
    accuracy: 3,
  },
  {
    postcode: 6716,
    place_name: 'Pannawonica',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.6392,
    longitude: 116.3222,
    accuracy: 4,
  },
  {
    postcode: 6716,
    place_name: 'Millstream',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.6374,
    longitude: 116.3258,
    accuracy: 3,
  },
  {
    postcode: 6716,
    place_name: 'Hamersley Range',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.6374,
    longitude: 116.3258,
    accuracy: 3,
  },
  {
    postcode: 6716,
    place_name: 'Fortescue',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.6374,
    longitude: 116.3258,
    accuracy: 3,
  },
  {
    postcode: 6718,
    place_name: 'Roebourne',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7723,
    longitude: 117.1465,
    accuracy: 4,
  },
  {
    postcode: 6718,
    place_name: 'Whim Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.8386,
    longitude: 117.8314,
    accuracy: 4,
  },
  {
    postcode: 6720,
    place_name: 'Wickham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.6747,
    longitude: 117.1378,
    accuracy: 4,
  },
  {
    postcode: 6720,
    place_name: 'Cossack',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.7,
    longitude: 117.2,
    accuracy: 4,
  },
  {
    postcode: 6720,
    place_name: 'Point Samson',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.6289,
    longitude: 117.1927,
    accuracy: 4,
  },
  {
    postcode: 6721,
    place_name: 'Mundabullangana',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.929,
    longitude: 118.6739,
    accuracy: 3,
  },
  {
    postcode: 6721,
    place_name: 'Wedgefield',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.3609,
    longitude: 118.5958,
    accuracy: 4,
  },
  {
    postcode: 6721,
    place_name: 'Port Hedland',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.3121,
    longitude: 118.6106,
    accuracy: 4,
  },
  {
    postcode: 6721,
    place_name: 'Strelley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.1167,
    longitude: 119.05,
    accuracy: 4,
  },
  {
    postcode: 6721,
    place_name: 'Indee',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.929,
    longitude: 118.6739,
    accuracy: 3,
  },
  {
    postcode: 6721,
    place_name: 'Redbank',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.3452,
    longitude: 118.6296,
    accuracy: 4,
  },
  {
    postcode: 6721,
    place_name: 'Pardoo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.1086,
    longitude: 119.5798,
    accuracy: 3,
  },
  {
    postcode: 6721,
    place_name: 'Wallareenya',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.929,
    longitude: 118.6739,
    accuracy: 3,
  },
  {
    postcode: 6722,
    place_name: 'Boodarie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.3675,
    longitude: 118.5586,
    accuracy: 4,
  },
  {
    postcode: 6722,
    place_name: 'Finucane',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.2977,
    longitude: 118.569,
    accuracy: 4,
  },
  {
    postcode: 6722,
    place_name: 'De Grey',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.4091,
    longitude: 118.6091,
    accuracy: 3,
  },
  {
    postcode: 6722,
    place_name: 'Pippingarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.4091,
    longitude: 118.6091,
    accuracy: 3,
  },
  {
    postcode: 6722,
    place_name: 'South Hedland',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.4066,
    longitude: 118.6007,
    accuracy: 4,
  },
  {
    postcode: 6723,
    place_name: 'Goldsworthy',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.35,
    longitude: 119.5333,
    accuracy: 4,
  },
  {
    postcode: 6725,
    place_name: 'Roebuck',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -18.1713,
    longitude: 122.5012,
    accuracy: 4,
  },
  {
    postcode: 6725,
    place_name: 'Minyirr',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.9813,
    longitude: 122.2097,
    accuracy: 4,
  },
  {
    postcode: 6725,
    place_name: 'Dampier Peninsula',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -16.9324,
    longitude: 122.8656,
    accuracy: 4,
  },
  {
    postcode: 6725,
    place_name: 'Lagrange',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -18.7056,
    longitude: 121.9933,
    accuracy: 3,
  },
  {
    postcode: 6725,
    place_name: 'Broome',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.9554,
    longitude: 122.2392,
    accuracy: 4,
  },
  {
    postcode: 6725,
    place_name: 'Gingerah',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -18.6605,
    longitude: 122.4748,
    accuracy: 3,
  },
  {
    postcode: 6725,
    place_name: 'Bilingurr',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.9091,
    longitude: 122.2292,
    accuracy: 4,
  },
  {
    postcode: 6725,
    place_name: 'Waterbank',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.4547,
    longitude: 122.6775,
    accuracy: 3,
  },
  {
    postcode: 6725,
    place_name: 'Djugun',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.9539,
    longitude: 122.2279,
    accuracy: 4,
  },
  {
    postcode: 6725,
    place_name: 'Eighty Mile Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -19.2407,
    longitude: 121.6133,
    accuracy: 4,
  },
  {
    postcode: 6726,
    place_name: 'Cable Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.961,
    longitude: 122.2127,
    accuracy: 4,
  },
  {
    postcode: 6728,
    place_name: 'Camballin',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -18.209,
    longitude: 123.9018,
    accuracy: 4,
  },
  {
    postcode: 6728,
    place_name: 'Kimbolton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.4159,
    longitude: 118.7072,
    accuracy: 3,
  },
  {
    postcode: 6728,
    place_name: 'King Leopold Ranges',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.1099,
    longitude: 125.4806,
    accuracy: 4,
  },
  {
    postcode: 6728,
    place_name: 'Geegully Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.4159,
    longitude: 118.7072,
    accuracy: 3,
  },
  {
    postcode: 6728,
    place_name: 'Derby',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.3029,
    longitude: 123.6286,
    accuracy: 4,
  },
  {
    postcode: 6728,
    place_name: 'Willare',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.4159,
    longitude: 118.7072,
    accuracy: 3,
  },
  {
    postcode: 6728,
    place_name: 'Meda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.4159,
    longitude: 118.7072,
    accuracy: 3,
  },
  {
    postcode: 6728,
    place_name: 'St George Ranges',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -18.982,
    longitude: 125.0087,
    accuracy: 4,
  },
  {
    postcode: 6731,
    place_name: 'Cockatoo Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -20.5828,
    longitude: 119.1891,
    accuracy: 1,
  },
  {
    postcode: 6733,
    place_name: 'Koolan Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.0454,
    longitude: 118.4324,
    accuracy: 1,
  },
  {
    postcode: 6740,
    place_name: 'Prince Regent River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -15.9162,
    longitude: 126.8148,
    accuracy: 1,
  },
  {
    postcode: 6740,
    place_name: 'Kalumburu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -14.2872,
    longitude: 126.6345,
    accuracy: 1,
  },
  {
    postcode: 6740,
    place_name: 'Mitchell Plateau',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -15.9162,
    longitude: 126.8148,
    accuracy: 1,
  },
  {
    postcode: 6740,
    place_name: 'Oombulgurri',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -15.9162,
    longitude: 126.8148,
    accuracy: 1,
  },
  {
    postcode: 6740,
    place_name: 'Wyndham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -15.4869,
    longitude: 128.1243,
    accuracy: 4,
  },
  {
    postcode: 6740,
    place_name: 'Drysdale River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -15.9162,
    longitude: 126.8148,
    accuracy: 1,
  },
  {
    postcode: 6743,
    place_name: 'Lake Argyle',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -16.1088,
    longitude: 128.7443,
    accuracy: 1,
  },
  {
    postcode: 6743,
    place_name: 'Kununurra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -15.7739,
    longitude: 128.739,
    accuracy: 1,
  },
  {
    postcode: 6743,
    place_name: 'Gibb',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0844,
    longitude: 119.0982,
    accuracy: 1,
  },
  {
    postcode: 6743,
    place_name: 'Warmun',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.0338,
    longitude: 128.2236,
    accuracy: 1,
  },
  {
    postcode: 6743,
    place_name: 'Cambridge Gulf',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.4122,
    longitude: 129.6861,
    accuracy: 1,
  },
  {
    postcode: 6743,
    place_name: 'Durack',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.4122,
    longitude: 129.6861,
    accuracy: 1,
  },
  {
    postcode: 6751,
    place_name: 'Rocklea',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.8696,
    longitude: 118.4233,
    accuracy: 3,
  },
  {
    postcode: 6751,
    place_name: 'Tom Price',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.6939,
    longitude: 117.7931,
    accuracy: 4,
  },
  {
    postcode: 6751,
    place_name: 'Chichester',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.6856,
    longitude: 117.7906,
    accuracy: 3,
  },
  {
    postcode: 6751,
    place_name: 'Wittenoom',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.2404,
    longitude: 118.3355,
    accuracy: 4,
  },
  {
    postcode: 6751,
    place_name: 'Mulga Downs',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.6856,
    longitude: 117.7906,
    accuracy: 3,
  },
  {
    postcode: 6751,
    place_name: 'Nanutarra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.6856,
    longitude: 117.7906,
    accuracy: 3,
  },
  {
    postcode: 6751,
    place_name: 'Innawanga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.6856,
    longitude: 117.7906,
    accuracy: 3,
  },
  {
    postcode: 6751,
    place_name: 'Mount Sheila',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.6856,
    longitude: 117.7906,
    accuracy: 3,
  },
  {
    postcode: 6751,
    place_name: 'Karijini',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.6856,
    longitude: 117.7906,
    accuracy: 3,
  },
  {
    postcode: 6751,
    place_name: 'Juna Downs',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -22.6856,
    longitude: 117.7906,
    accuracy: 3,
  },
  {
    postcode: 6753,
    place_name: 'Newman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.3564,
    longitude: 119.7355,
    accuracy: 4,
  },
  {
    postcode: 6754,
    place_name: 'Paraburdoo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -23.2042,
    longitude: 117.6697,
    accuracy: 4,
  },
  {
    postcode: 6758,
    place_name: 'Nullagine',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.8897,
    longitude: 120.1114,
    accuracy: 4,
  },
  {
    postcode: 6760,
    place_name: 'Marble Bar',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.1707,
    longitude: 119.7444,
    accuracy: 4,
  },
  {
    postcode: 6761,
    place_name: 'Shay Gap',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.1707,
    longitude: 119.7444,
    accuracy: 1,
  },
  {
    postcode: 6762,
    place_name: 'Telfer',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.2117,
    longitude: 123.2617,
    accuracy: 4,
  },
  {
    postcode: 6765,
    place_name: 'Mount Hardman',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -21.1707,
    longitude: 119.7444,
    accuracy: 3,
  },
  {
    postcode: 6765,
    place_name: 'Fitzroy Crossing',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -18.1971,
    longitude: 125.5666,
    accuracy: 4,
  },
  {
    postcode: 6770,
    place_name: 'Sturt Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -18.8386,
    longitude: 128.1783,
    accuracy: 3,
  },
  {
    postcode: 6770,
    place_name: 'Mueller Ranges',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -18.3983,
    longitude: 126.8562,
    accuracy: 4,
  },
  {
    postcode: 6770,
    place_name: 'Mcbeath',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -19.3668,
    longitude: 127.5678,
    accuracy: 3,
  },
  {
    postcode: 6770,
    place_name: 'Purnululu',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -19.3668,
    longitude: 127.5678,
    accuracy: 3,
  },
  {
    postcode: 6770,
    place_name: 'Tanami',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -19.3668,
    longitude: 127.5678,
    accuracy: 3,
  },
  {
    postcode: 6770,
    place_name: 'Halls Creek',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -19.3668,
    longitude: 127.5678,
    accuracy: 3,
  },
  {
    postcode: 6770,
    place_name: 'Ord River',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -17.3857,
    longitude: 128.9207,
    accuracy: 3,
  },
  {
    postcode: 6798,
    place_name: 'Christmas Island',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -10.4854,
    longitude: 105.6365,
    accuracy: 1,
  },
  {
    postcode: 6799,
    place_name: 'Home Island Cocos (Keeling) Islands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -12.1352,
    longitude: 96.8628,
    accuracy: 1,
  },
  {
    postcode: 6799,
    place_name: 'West Island Cocos (Keeling) Islands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -12.1352,
    longitude: 96.8628,
    accuracy: 1,
  },
  {
    postcode: 6800,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6803,
    place_name: 'Northbridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9478,
    longitude: 115.8588,
    accuracy: 4,
  },
  {
    postcode: 6809,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6817,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6820,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6827,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6830,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6831,
    place_name: 'Perth St Georges Tce',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9333,
    longitude: 115.8333,
    accuracy: 1,
  },
  {
    postcode: 6832,
    place_name: 'Perth Adelaide Tce',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 1,
  },
  {
    postcode: 6832,
    place_name: 'Perth East St Georges Tce',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9333,
    longitude: 115.8333,
    accuracy: 1,
  },
  {
    postcode: 6837,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6838,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6839,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6840,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6841,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6842,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6843,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6844,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6845,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6846,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6847,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6848,
    place_name: 'Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9522,
    longitude: 115.8614,
    accuracy: 4,
  },
  {
    postcode: 6849,
    place_name: 'Perth Bc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9333,
    longitude: 115.8333,
    accuracy: 1,
  },
  {
    postcode: 6850,
    place_name: 'Cloisters Square Po',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.952,
    longitude: 115.8607,
    accuracy: 1,
  },
  {
    postcode: 6850,
    place_name: 'Cloisters Square',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9333,
    longitude: 115.8333,
    accuracy: 1,
  },
  {
    postcode: 6865,
    place_name: 'Northbridge',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9478,
    longitude: 115.8588,
    accuracy: 4,
  },
  {
    postcode: 6872,
    place_name: 'West Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.949,
    longitude: 115.842,
    accuracy: 4,
  },
  {
    postcode: 6892,
    place_name: 'East Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9587,
    longitude: 115.8711,
    accuracy: 4,
  },
  {
    postcode: 6900,
    place_name: 'Leederville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9363,
    longitude: 115.8419,
    accuracy: 4,
  },
  {
    postcode: 6901,
    place_name: 'West Leederville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9414,
    longitude: 115.8312,
    accuracy: 4,
  },
  {
    postcode: 6902,
    place_name: 'Leederville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9363,
    longitude: 115.8419,
    accuracy: 4,
  },
  {
    postcode: 6903,
    place_name: 'Leederville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9363,
    longitude: 115.8419,
    accuracy: 4,
  },
  {
    postcode: 6904,
    place_name: 'Subiaco',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9485,
    longitude: 115.8268,
    accuracy: 4,
  },
  {
    postcode: 6904,
    place_name: 'Subiaco Po',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.95,
    longitude: 115.8,
    accuracy: 3,
  },
  {
    postcode: 6905,
    place_name: 'Northlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9722,
    longitude: 115.8,
    accuracy: 1,
  },
  {
    postcode: 6905,
    place_name: 'Northlands Po',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9486,
    longitude: 115.8275,
    accuracy: 1,
  },
  {
    postcode: 6906,
    place_name: 'North Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9272,
    longitude: 115.8528,
    accuracy: 4,
  },
  {
    postcode: 6907,
    place_name: 'Nedlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9818,
    longitude: 115.8073,
    accuracy: 4,
  },
  {
    postcode: 6909,
    place_name: 'Nedlands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9818,
    longitude: 115.8073,
    accuracy: 4,
  },
  {
    postcode: 6910,
    place_name: 'Claremont',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9796,
    longitude: 115.7823,
    accuracy: 4,
  },
  {
    postcode: 6911,
    place_name: 'Cottesloe',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32,
    longitude: 115.7667,
    accuracy: 4,
  },
  {
    postcode: 6912,
    place_name: 'Mosman Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0054,
    longitude: 115.7638,
    accuracy: 4,
  },
  {
    postcode: 6913,
    place_name: 'Wembley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9333,
    longitude: 115.8,
    accuracy: 4,
  },
  {
    postcode: 6914,
    place_name: 'Balcatta',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.875,
    longitude: 115.8284,
    accuracy: 4,
  },
  {
    postcode: 6915,
    place_name: 'Mount Hawthorn',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.92,
    longitude: 115.8351,
    accuracy: 4,
  },
  {
    postcode: 6916,
    place_name: 'Osborne Park Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9802,
    longitude: 115.7757,
    accuracy: 3,
  },
  {
    postcode: 6916,
    place_name: 'Osborne Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9007,
    longitude: 115.8108,
    accuracy: 4,
  },
  {
    postcode: 6917,
    place_name: 'Osborne Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9007,
    longitude: 115.8108,
    accuracy: 4,
  },
  {
    postcode: 6918,
    place_name: 'Innaloo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8927,
    longitude: 115.7951,
    accuracy: 4,
  },
  {
    postcode: 6919,
    place_name: 'Joondalup Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9802,
    longitude: 115.7757,
    accuracy: 1,
  },
  {
    postcode: 6920,
    place_name: 'North Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.863,
    longitude: 115.7562,
    accuracy: 4,
  },
  {
    postcode: 6921,
    place_name: 'Karrinyup',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8723,
    longitude: 115.7768,
    accuracy: 4,
  },
  {
    postcode: 6922,
    place_name: 'Scarborough',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8958,
    longitude: 115.7643,
    accuracy: 4,
  },
  {
    postcode: 6923,
    place_name: 'Hillarys',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.807,
    longitude: 115.7405,
    accuracy: 4,
  },
  {
    postcode: 6924,
    place_name: 'Greenwood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8272,
    longitude: 115.8025,
    accuracy: 4,
  },
  {
    postcode: 6925,
    place_name: 'Walliston Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9167,
    longitude: 115.8556,
    accuracy: 1,
  },
  {
    postcode: 6926,
    place_name: 'Kalamunda',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9868,
    longitude: 116.0704,
    accuracy: 1,
  },
  {
    postcode: 6929,
    place_name: 'Mount Lawley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9344,
    longitude: 115.8716,
    accuracy: 4,
  },
  {
    postcode: 6931,
    place_name: 'Maylands',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.931,
    longitude: 115.8949,
    accuracy: 4,
  },
  {
    postcode: 6932,
    place_name: 'Inglewood',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9166,
    longitude: 115.8798,
    accuracy: 4,
  },
  {
    postcode: 6933,
    place_name: 'Bayswater',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9169,
    longitude: 115.9178,
    accuracy: 4,
  },
  {
    postcode: 6934,
    place_name: 'Bassendean',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9091,
    longitude: 115.9437,
    accuracy: 4,
  },
  {
    postcode: 6935,
    place_name: 'Guildford',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9,
    longitude: 115.9667,
    accuracy: 4,
  },
  {
    postcode: 6936,
    place_name: 'Midland Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9111,
    longitude: 115.9333,
    accuracy: 1,
  },
  {
    postcode: 6937,
    place_name: 'Tuart Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8981,
    longitude: 115.8349,
    accuracy: 4,
  },
  {
    postcode: 6938,
    place_name: 'Tuart Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8981,
    longitude: 115.8349,
    accuracy: 4,
  },
  {
    postcode: 6939,
    place_name: 'Tuart Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8981,
    longitude: 115.8349,
    accuracy: 4,
  },
  {
    postcode: 6940,
    place_name: 'Tuart Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8981,
    longitude: 115.8349,
    accuracy: 4,
  },
  {
    postcode: 6941,
    place_name: 'Mirrabooka',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8596,
    longitude: 115.8659,
    accuracy: 4,
  },
  {
    postcode: 6942,
    place_name: 'Bassendean Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.75,
    longitude: 115.8,
    accuracy: 1,
  },
  {
    postcode: 6943,
    place_name: 'Morley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8877,
    longitude: 115.9099,
    accuracy: 4,
  },
  {
    postcode: 6944,
    place_name: 'Malaga',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.8526,
    longitude: 115.881,
    accuracy: 4,
  },
  {
    postcode: 6945,
    place_name: 'Malaga Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.75,
    longitude: 115.8,
    accuracy: 1,
  },
  {
    postcode: 6946,
    place_name: 'Wanneroo',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.75,
    longitude: 115.8,
    accuracy: 4,
  },
  {
    postcode: 6947,
    place_name: 'Wangara Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.75,
    longitude: 115.8,
    accuracy: 1,
  },
  {
    postcode: 6951,
    place_name: 'South Perth',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9833,
    longitude: 115.8667,
    accuracy: 4,
  },
  {
    postcode: 6952,
    place_name: 'Como',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9912,
    longitude: 115.8634,
    accuracy: 4,
  },
  {
    postcode: 6953,
    place_name: 'Applecross',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0167,
    longitude: 115.8333,
    accuracy: 4,
  },
  {
    postcode: 6954,
    place_name: 'Booragoon',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0395,
    longitude: 115.8337,
    accuracy: 4,
  },
  {
    postcode: 6955,
    place_name: 'Willetton',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0525,
    longitude: 115.8878,
    accuracy: 4,
  },
  {
    postcode: 6956,
    place_name: 'Melville',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0404,
    longitude: 115.8012,
    accuracy: 4,
  },
  {
    postcode: 6957,
    place_name: 'Palmyra',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.045,
    longitude: 115.7859,
    accuracy: 4,
  },
  {
    postcode: 6958,
    place_name: 'Royal Australian Navy Warships',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.05,
    longitude: 115.7667,
    accuracy: 1,
  },
  {
    postcode: 6959,
    place_name: 'Fremantle',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.05,
    longitude: 115.7667,
    accuracy: 4,
  },
  {
    postcode: 6960,
    place_name: 'Myaree Bc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2833,
    longitude: 115.7167,
    accuracy: 1,
  },
  {
    postcode: 6961,
    place_name: 'Palmyra Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2833,
    longitude: 115.7167,
    accuracy: 1,
  },
  {
    postcode: 6963,
    place_name: 'Hamilton Hill',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0845,
    longitude: 115.7795,
    accuracy: 4,
  },
  {
    postcode: 6964,
    place_name: 'Success',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1428,
    longitude: 115.85,
    accuracy: 4,
  },
  {
    postcode: 6965,
    place_name: 'Bibra Lake Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2833,
    longitude: 115.7167,
    accuracy: 1,
  },
  {
    postcode: 6966,
    place_name: 'Kwinana',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2301,
    longitude: 115.7813,
    accuracy: 4,
  },
  {
    postcode: 6967,
    place_name: 'Rockingham Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2833,
    longitude: 115.7167,
    accuracy: 1,
  },
  {
    postcode: 6968,
    place_name: 'Rockingham',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2768,
    longitude: 115.7298,
    accuracy: 4,
  },
  {
    postcode: 6969,
    place_name: 'Rockingham Beach',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.2833,
    longitude: 115.7167,
    accuracy: 1,
  },
  {
    postcode: 6970,
    place_name: 'Canning Vale Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9919,
    longitude: 115.8645,
    accuracy: 1,
  },
  {
    postcode: 6979,
    place_name: 'Victoria Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9762,
    longitude: 115.9052,
    accuracy: 4,
  },
  {
    postcode: 6980,
    place_name: 'Cannington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0167,
    longitude: 115.95,
    accuracy: 4,
  },
  {
    postcode: 6981,
    place_name: 'East Victoria Park',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9889,
    longitude: 115.9039,
    accuracy: 4,
  },
  {
    postcode: 6982,
    place_name: 'Bentley',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32,
    longitude: 115.9167,
    accuracy: 4,
  },
  {
    postcode: 6983,
    place_name: 'Bentley Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0048,
    longitude: 115.9411,
    accuracy: 1,
  },
  {
    postcode: 6984,
    place_name: 'Belmont',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9435,
    longitude: 115.9255,
    accuracy: 4,
  },
  {
    postcode: 6985,
    place_name: 'Cloverdale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -31.9628,
    longitude: 115.9443,
    accuracy: 4,
  },
  {
    postcode: 6986,
    place_name: 'Welshpool Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0048,
    longitude: 115.9411,
    accuracy: 1,
  },
  {
    postcode: 6987,
    place_name: 'Cannington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.0167,
    longitude: 115.95,
    accuracy: 4,
  },
  {
    postcode: 6988,
    place_name: 'Thornlie',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.06,
    longitude: 115.955,
    accuracy: 4,
  },
  {
    postcode: 6989,
    place_name: 'Maddington',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.05,
    longitude: 115.9833,
    accuracy: 4,
  },
  {
    postcode: 6990,
    place_name: 'Gosnells',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.081,
    longitude: 116.0054,
    accuracy: 4,
  },
  {
    postcode: 6991,
    place_name: 'Kelmscott',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1243,
    longitude: 116.0259,
    accuracy: 4,
  },
  {
    postcode: 6992,
    place_name: 'Armadale',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1461,
    longitude: 116.0093,
    accuracy: 4,
  },
  {
    postcode: 6997,
    place_name: 'Kelmscott Dc',
    state_name: 'Western Australia',
    state_code: 'WA',
    latitude: -32.1167,
    longitude: 116.0056,
    accuracy: 1,
  },
];

function distinctByPostcodeAndStateCode(locations: LocationCode[]): LocationCode[] {
  const uniqueCombinations = new Set<string>();

  return locations.filter((location) => {
    const combination = `${location.postcode}-${location.state_code}`;
    if (!uniqueCombinations.has(combination)) {
      uniqueCombinations.add(combination);
      return true;
    }
    return false;
  });
}

// Example usage
export const distinctLocations = distinctByPostcodeAndStateCode(AULocationCodeList);

export function getLocationDetailsByPostcode(postcode: number): LocationCode[] {
  const matchingLocations = AULocationCodeList.filter((location) => location.postcode === postcode);
  // console.log(JSON.stringify(matchingLocations));
  return matchingLocations || null;
}

export const populateDropdownOptions = (): { label: string; value: string }[] => {
  return AULocationCodeList.map((location) => ({
    label: `${location.postcode}, ${location.place_name}, ${location.state_code}`,
    value: `${location.postcode}|${location.place_name}|${location.state_code}`,
  }));
};

interface AUStateCode {
  name: string;
  code: string;
}

export const AUStateCodeList: AUStateCode[] = [
  {
    name: 'New South Wales',
    code: 'NSW',
  },
  {
    name: 'Victoria',
    code: 'VIC',
  },
  {
    name: 'Queensland',
    code: 'QLD',
  },
  {
    name: 'South Australia',
    code: 'SA',
  },
  {
    name: 'Western Australia',
    code: 'WA',
  },
  {
    name: 'Tasmania',
    code: 'TAS',
  },
];
