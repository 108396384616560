import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, InputNumber, message, Row, Space, Tag } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { BookingActions } from '../store/BookingSlice';
import { BookingSelector } from '../store/BookingSelector';
import { SenderDetailsSelector } from '@features/SenderDetails';
import { ReceiverSelector } from '@features/Receiver';
import TextArea from 'antd/es/input/TextArea';
import { Constants } from 'common/constants';
import styles from 'features/Booking/Booking.module.less';
import { TransactionSelector } from '@features/Transaction';
import { BoxContentModel } from 'common/models/BookingTypes';
import { UpdateBoxContent } from '../business/BookingBusiness';
export const BoxContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const bookingState = useSelector(BookingSelector);
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const receiverState = useSelector(ReceiverSelector);

  const [form] = Form.useForm();
  const transactionState = useSelector(TransactionSelector);
  useEffect(() => {
    if (!transactionState.FromUpdateLink) {
      if (
        senderDetailsState.Sender === null ||
        senderDetailsState.Sender === undefined ||
        receiverState.Receiver === null ||
        receiverState.Receiver === undefined
      ) {
        history.push('/home');
      }
    }
  }, [bookingState, history]);

  const onFinish = async (values: any) => {
    dispatch(
      BookingActions.SetBoxContent({
        Details: selectedTags,
        Others: values.Others,
        DeclaredValue: values.DeclaredValue,
      })
    );
    if (transactionState.FromUpdateLink) {
      const data: BoxContentModel = {
        Id: transactionState.TransactionSummary?.BoxContent.Id,
        Others: values.Others,
        Details: selectedTags,
        DeclaredValue: values.DeclaredValue,
      };
      await dispatch(UpdateBoxContent(data));
      message.success('Box content updated successfully');
      history.push('/transaction/summary');
    } else {
      history.push('/booking/boxdetails');
    }
  };

  const { CheckableTag } = Tag;

  const tagsData = [
    'Clothes',
    'Footwear',
    'Food',
    'Kitchenwares',
    'Tools',
    'Health and beauty products',
    'Books',
    'Toys',
    'Bags',
    'Sports equipments',
    'Electronics',
    'Others',
  ];
  const [selectedTags, setSelectedTags] = useState<string[]>(
    bookingState.BoxContent?.Details || transactionState?.TransactionSummary?.BoxContent.Details || []
  );

  const [isOthersTagSelected, setIsOthersTagSelected] = useState(selectedTags.includes('Others'));
  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
    setIsOthersTagSelected(nextSelectedTags.includes('Others'));
  };

  const validateDeclaredValue = (_, value, callback) => {
    if (value === undefined || value === null || value <= 0) {
      callback(Constants.REQUIRED_FIELD);
    } else {
      callback();
    }
  };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        {' '}
        <h1>Box contents</h1>
      </Row>

      <Form name="senderForm" form={form} onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            {!transactionState.FromUpdateLink && (
              <Breadcrumb
                items={[
                  {
                    title: 'Sender',
                  },
                  {
                    title: 'Sender address',
                  },
                  {
                    title: 'Receiver',
                  },
                  {
                    title: 'Box content',
                  },
                ]}
              />
            )}
            <br />
            <Form.Item
              label="What’s in the box?"
              name="categories"
              rules={[
                {
                  required: !selectedTags || selectedTags.length === 0,
                  message: !selectedTags || selectedTags.length === 0 ? 'Please select an item' : '',
                },
              ]}
            >
              <Space size="small" direction="horizontal" className={styles.tag1}>
                {tagsData.map((tag) => (
                  <CheckableTag
                    key={tag}
                    checked={selectedTags.includes(tag)}
                    onChange={(checked) => handleChange(tag, checked)}
                    className={styles.anttag}
                  >
                    {tag}
                  </CheckableTag>
                ))}
              </Space>
            </Form.Item>
            <Form.Item
              initialValue={
                !transactionState.FromUpdateLink
                  ? bookingState.BoxContent?.Others
                  : transactionState.TransactionSummary?.BoxContent?.Others
              }
              label="Others"
              name="Others"
              rules={[
                {
                  required: isOthersTagSelected,
                  message: Constants.REQUIRED_FIELD,
                },
              ]}
              hidden={!isOthersTagSelected} // Hide the field if 'Others' is not selected
            >
              <TextArea size="large" maxLength={150} />
            </Form.Item>

            <Form.Item
              initialValue={
                !transactionState.FromUpdateLink
                  ? bookingState.BoxContent?.DeclaredValue
                  : transactionState.TransactionSummary?.BoxContent?.DeclaredValue
              }
              label="Box declared value"
              name="DeclaredValue"
              rules={[{ validator: validateDeclaredValue }]}
            >
              <InputNumber
                size="large"
                className="full-width"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>

            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              onClick={() =>
                history.push(!transactionState.FromUpdateLink ? '/booking/receiver' : '/transaction/summary')
              }
            >
              {Constants.BTN_BACK}
            </Button>
            <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
              {transactionState.FromUpdateLink ? Constants.BTN_UPDATE : Constants.BTN_CONTINUE}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
