import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Input, message, Row } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { Constants } from 'common/constants';
import { Link } from 'react-router-dom';
import { ResetChangePassword, ResetPasswordLink } from '../business/AccountBusiness';
import { AccountActions } from '../store/AccountSlice';
import { AccountSelector } from '../store/AccountSelector';
import { ResetPasswordViewModel } from 'common/models/UserTypes';
import { PATHS } from '@routing/routes';

export const PasswordReset = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accountState = useSelector(AccountSelector);

  const [isContentVisible, setIsContentVisible] = useState(true);
  const [isFromSignup, setFromSignup] = useState(false);
  const onFinish = async (values: any) => {
    if (!accountState.ResetPasswordLink) return;

    const myResetPasswordModel: ResetPasswordViewModel = {
      Data: accountState.ResetPasswordLink,
      NewPassword: values.NewPassword,
      ConfirmPassword: values.ConfirmPassword,
    };
    const response = await dispatch(ResetChangePassword(myResetPasswordModel));
    const msg = isFromSignup ? 'Password saved successfully. ' : 'Password reset successful';
    if (typeof response === 'string' && response === 'S') {
      message.success(msg);
      history.push(PATHS.Login.path);
    } else {
      message.error('Password reset error');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = new URLSearchParams(location.search).get('token');
      const s = new URLSearchParams(location.search).get('s');
      if (s) {
        setFromSignup(true);
      }
      if (token) {
        dispatch(AccountActions.SetResetPasswordLink(token));
        const response = await dispatch(ResetPasswordLink(token));
        const isVisible = response && response !== null && response !== undefined;
        setIsContentVisible(!isVisible);
      }
    };
    fetchData();
  }, [dispatch, location.search]);

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject('This field is required');
    }

    if (!Constants.PASSWORD_REGEX.test(value)) {
      const informationMessage = (
        <div>
          <p className="pHeader">Passwords should be a minimum of 8 characters long and contain a mix of:</p>
          <ul className="pBottom">
            <li>numbers</li>
            <li>uppercase and lowercase letters</li>
            <li>special characters {Constants.ERROR_PASSWORD_ssFORMAT}</li>
          </ul>
        </div>
      );
      return Promise.reject(<Alert description={informationMessage} type="error" />);
    }

    return Promise.resolve();
  };
  return (
    <div className="App">
      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        {isContentVisible && (
          <>
            {isFromSignup ? (
              <Row justify="center" align="middle">
                <Col xs={24} sm={20} md={12} lg={8} xl={8} className="text-center">
                  <br />
                  <h2>Account confirmed!</h2>
                  <label>Thank you for confirming your email address.</label>
                  <br />

                  <h4> Please assign a new passord for your account.</h4>
                </Col>
              </Row>
            ) : (
              <Row justify="center" align="middle">
                <h4>Reset password</h4>
              </Row>
            )}
            <Row justify="center" align="middle">
              <Col xs={24} sm={20} md={12} lg={8} xl={8}>
                <Form.Item label="New password" name="NewPassword" rules={[{ validator: validatePassword }]}>
                  <Input.Password type="password" size="large" maxLength={20} />
                </Form.Item>
                <Form.Item
                  label="Confirm password"
                  name="ConfirmPassword"
                  dependencies={['Password']}
                  rules={[
                    { required: true, message: Constants.REQUIRED_FIELD },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('NewPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('The new password and confirm password do not match.');
                      },
                    }),
                  ]}
                >
                  <Input.Password type="password" size="large" maxLength={150} />
                </Form.Item>
                <Button className="nextSenderBtn full-width" size="large" type="primary" htmlType="submit">
                  Reset password
                </Button>
              </Col>
            </Row>
            <br />
            <Row justify="center">
              <Link to={PATHS.Login.path} className="normal-font center">
                Back to Log in
              </Link>
            </Row>
          </>
        )}
        {!isContentVisible && (
          <>
            <br />
            <Row justify="center" align="middle">
              <label>The link has expired.</label>
            </Row>
            <br />
            <Row justify="center" align="middle">
              <Link to="/account/resetpassword" className="normal-font">
                Go to the reset password page to resend the reset password link.
              </Link>
            </Row>
            <br />
            <Row justify="center">
              <Link to={PATHS.Login.path} className="normal-font center">
                Back to Log in
              </Link>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
};
