import { useState } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Constants } from 'common/constants';
import { AUStateCodeList } from 'common/code/AUPostalCode';
import { BookingTypeCodeList } from 'common/code/BookingCode';
import Barcode from 'react-barcode';
import { BarcodeGenerateRequestModel } from 'common/models/BarcodeTypes';
import { GenerateBarcodeBusiness } from '../business/BarcodeBusiness';
import { BarcodeSelector } from '../store/BarcodeSelector';
import React from 'react';
import { OperationStatus } from 'store/rootTypes';

export const GenerateBarcode = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [generatedBarcodes, setGeneratedBarcodes] = useState<string[]>([]);
  const barcodeState = useSelector(BarcodeSelector);
  const onFinish = async (values: BarcodeGenerateRequestModel) => {
    // const { State, Type } = values;
    // const requestData: BarcodeGenerateRequestModel = {
    //   Type,
    //   State,
    // };

    await dispatch(GenerateBarcodeBusiness(values));
  };

  const handlePrint = () => {
    // const barcodeElements = document.querySelectorAll('.barcodeVertical');
    // if (barcodeElements) {
    //   barcodeElements.forEach((barcodeElement) => {
    //     const elementWithStyle = barcodeElement as HTMLElement;
    //     elementWithStyle.classList.add('printing'); // Add a class for styling during print
    //   });
    // }

    // Trigger window print
    window.print();

    // Remove styles after printing
    // if (barcodeElements) {
    //   barcodeElements.forEach((barcodeElement) => {
    //     const elementWithStyle = barcodeElement as HTMLElement;
    //     elementWithStyle.classList.remove('printing'); // Remove the print-specific class
    //   });
    // }
  };

  return (
    <div className="App">
      <Row justify="center" align="middle" className="non-printable">
        <h1>Generate barcode</h1>
      </Row>

      <Form name="senderForm" form={form} onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle" className="non-printable">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <br />
            <Form.Item
              label="State"
              name="Abbreviations1"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Select size="large" showSearch placeholder="Select state" optionFilterProp="children">
                {AUStateCodeList.map((destination) => (
                  <Select.Option key={destination.code} value={destination.code}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Type"
              name="Abbreviations2"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                {BookingTypeCodeList.map((destination) => (
                  <Select.Option key={destination.code} value={destination.code}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              onClick={() => history.push('/home')}
              loading={barcodeState.submitStatus === OperationStatus.pending}
            >
              {Constants.BTN_BACK}
            </Button>
            <Button
              className="nextSenderBtn"
              size="large"
              type="primary"
              htmlType="submit"
              loading={barcodeState.submitStatus === OperationStatus.pending}
            >
              Generate
            </Button>
          </Col>
        </Row>
        <div>
          {barcodeState?.BarcodeList && (
            <>
              <Button className="PrintBtn non-printable" size="large" type="primary" onClick={handlePrint}>
                Print
              </Button>
              <br />
            </>
          )}
          {barcodeState?.BarcodeList &&
            barcodeState.BarcodeList.map((barcode, index) => (
              <React.Fragment key={index}>
                <div className="">
                  <p className="pHeaderBarcode1">{barcode.Number}</p>
                  <Barcode
                    value={`${barcode.Abbreviations1}${barcode.Number}${barcode.Abbreviations2}`}
                    width={10.5}
                    height={430}
                    fontOptions="bold"
                    fontSize={150}
                  />
                </div>
              </React.Fragment>
            ))}
        </div>
      </Form>
    </div>
  );
};
