import { Button, Col, DatePicker, Form, Input, InputNumber, List, Row, Typography } from 'antd';
import { useHistory } from 'react-router';
import { DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Constants } from 'common/constants';
import 'App.css';
import TextArea from 'antd/es/input/TextArea';
import { PATHS } from '@routing/routes';
import { useState } from 'react';
import React from 'react';

interface TodoItem {
  id: number;
  text: string;
  completed: boolean;
}
export const Todo = () => {
  const history = useHistory();

  const onFinish = async (values: any) => {
    console.error('SelectedId is undefined');
  };

  const [todos, setTodos] = useState<TodoItem[]>([
    { id: 1, text: 'Finish project', completed: false },
    { id: 2, text: 'Buy groceries', completed: false },
    { id: 3, text: 'Call mom', completed: true },
  ]);
  const [inputValue, setInputValue] = useState<string>('');

  const handleAddTodo = () => {
    if (inputValue.trim() === '') return;
    const newTodo: TodoItem = {
      id: Date.now(),
      text: inputValue,
      completed: false,
    };
    setTodos([...todos, newTodo]);
    setInputValue('');
  };

  const handleDeleteTodo = (id: number) => {
    const updatedTodos = todos.filter((todo) => todo.id !== id);
    setTodos(updatedTodos);
  };

  const handleToggleComplete = (id: number) => {
    const updatedTodos = todos.map((todo) => (todo.id === id ? { ...todo, completed: !todo.completed } : todo));
    setTodos(updatedTodos);
  };

  return (
    <div className="App borderApp">
      <Row justify="center" align="middle">
        <h1>Todo </h1>
      </Row>

      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={14} lg={10} xl={12} className="">
            <TextArea
              placeholder="Add new todo"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
            <br />
            <Button className="floatRight mt-10 ml-10" type="primary" onClick={handleAddTodo}>
              Add Todo
            </Button>
            <Button
              className="floatRight mt-10 textColorPrimary"
              type="default"
              onClick={() => history.push(PATHS.Home.path)}
            >
              Close
            </Button>
            {todos && todos.length > 0 && (
              <>
                <br />
                <br />
                <br />
                <br />
                <List
                  dataSource={todos}
                  renderItem={(todo) => (
                    <List.Item
                      key={todo.id}
                      style={{ textDecoration: todo.completed ? 'line-through' : 'none' }}
                      actions={[
                        <Button
                          key={todo.id}
                          className={todo.completed ? 'textColorPrimary' : ''}
                          onClick={() => handleToggleComplete(todo.id)}
                          size="small"
                          type={todo.completed ? 'default' : 'primary'}
                        >
                          {todo.completed ? 'Incomplete' : 'Complete'}
                        </Button>,
                        <Button
                          className="textColorPrimary"
                          key={todo.id}
                          onClick={() => handleDeleteTodo(todo.id)}
                          size="small"
                          type="default"
                        >
                          Delete
                        </Button>,
                      ]}
                    >
                      <Typography.Text>
                        {todo.text.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography.Text>
                    </List.Item>
                  )}
                />
              </>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
