interface ChargesTypeCode {
  Type: string;
  DestinationCode: string;
  Amount: number;
}

export const ChargesTypeCodeCodeList: ChargesTypeCode[] = [
  {
    Type: 'S',
    DestinationCode: 'MNL',
    Amount: 105.0,
  },
  {
    Type: 'S',
    DestinationCode: 'LUZ',
    Amount: 115.0,
  },
  {
    Type: 'S',
    DestinationCode: 'VIS',
    Amount: 130.0,
  },
  {
    Type: 'S',
    DestinationCode: 'MIN',
    Amount: 140.0,
  },
  {
    Type: 'A',
    DestinationCode: 'MNL',
    Amount: 6.0,
  },
  {
    Type: 'A',
    DestinationCode: 'LUZ',
    Amount: 6.0,
  },
  {
    Type: 'A',
    DestinationCode: 'VIS',
    Amount: 8.0,
  },
  {
    Type: 'A',
    DestinationCode: 'MIN',
    Amount: 9.0,
  },
];

export const getAmountByTypeAndDestination = (
  type: string,
  destinationCode: string | undefined
): number | undefined => {
  const charge = ChargesTypeCodeCodeList.find((item) => item.Type === type && item.DestinationCode === destinationCode);
  return charge?.Amount;
};
