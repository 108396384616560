import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { SenderDetailsActions } from '@features/SenderDetails/';
import { Constants } from 'common/constants';
import { MobileCountryCodeList } from 'common/code/MobileCountryCode';
import { handleFocusContactNumber, handleKeyDownContactNumberNew } from 'common/utils/utility';
import { SenderModel } from 'common/models';
import { KFSSignupCheckUser } from '@features/Account';
import { BookingSelector } from '@features/Booking';

interface SenderCommonProps {
  header?: string;
  showSenderForm?: boolean;
  onBack: string;
  onNext: string;
  senderModel?: SenderModel;
  buttonNames?: {
    prevButton?: string;
    nextButton?: string;
  };
  listingPath?: string;
  showEmailSearch?: boolean;
}

export default function SenderCommon({
  header,
  showSenderForm,
  onBack,
  onNext,
  senderModel,
  buttonNames,
  listingPath,
  showEmailSearch,
}: SenderCommonProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedMobileCode, setSelectedMobileCode] = useState(senderModel?.MobileCode || '+61');
  const [form] = Form.useForm();
  const bookingState = useSelector(BookingSelector);
  const onFinish = (values: any) => {
    dispatch(
      SenderDetailsActions.SetSender({
        ...values,
        MobileCode: selectedMobileCode,
        UserId: bookingState.FromSignupExistingUserId,
      })
    );
    history.push(onNext);
  };

  const onBackFunction = () => {
    history.push(onBack);
  };

  const handleFormKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const { Option } = Select;

  const selectStyle = { width: '100px' };
  const inputStyle = { width: 'calc(100% - 100px)' };
  const { Search } = Input;
  const onSearch = async (value: string) => {
    const isValidEmail = Constants.EMAIL_REGEX_INPUT.test(value);
    if (!isValidEmail) return;
    const response = await dispatch(KFSSignupCheckUser(value));

    if (response != undefined && listingPath != undefined) {
      history.push(listingPath);
    } else {
      dispatch(SenderDetailsActions.SetShowSenderForm(true));
    }
  };
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>{header}</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical" onKeyDown={handleFormKeyDown}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            {showEmailSearch ? (
              <>
                <Form.Item
                  initialValue={senderModel?.Email}
                  label="Email"
                  name="Email"
                  rules={[
                    { required: true, message: Constants.REQUIRED_FIELD },
                    {
                      pattern: Constants.EMAIL_REGEX_INPUT,
                      message: 'Please enter a valid email address.',
                    },
                  ]}
                >
                  <Search maxLength={100} enterButton size="large" onSearch={onSearch} />
                </Form.Item>
              </>
            ) : null}

            {showSenderForm ? (
              <>
                <Form.Item
                  initialValue={senderModel?.GivenName}
                  label="Given name"
                  name="GivenName"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input size="large" maxLength={25} />
                </Form.Item>
                <Form.Item
                  initialValue={senderModel?.Surname}
                  label="Surname"
                  name="Surname"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input size="large" maxLength={25} />
                </Form.Item>
                <Form.Item
                  initialValue={form.getFieldValue('ContactNo') || senderModel?.ContactNo}
                  label="Contact number"
                  name="ContactNo"
                >
                  <Input.Group compact>
                    <Form.Item noStyle initialValue="+1">
                      <Select
                        style={selectStyle}
                        value={selectedMobileCode}
                        onChange={(value) => setSelectedMobileCode(value)}
                        size="large"
                        showSearch
                        optionFilterProp="children"
                      >
                        {MobileCountryCodeList.map((country) => (
                          <Option key={country.code} value={country.dial_code}>
                            {`${country.dial_code}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name="ContactNo"
                      initialValue=""
                      rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    >
                      <Input
                        style={inputStyle}
                        placeholder="Mobile number"
                        size="large"
                        onKeyDown={handleKeyDownContactNumberNew}
                        onFocus={handleFocusContactNumber}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <div>
                  <Button className="prevSenderBtn" size="large" type="default" onClick={() => onBackFunction()}>
                    {buttonNames?.prevButton}
                  </Button>
                  <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                    {buttonNames?.nextButton}
                  </Button>
                </div>
              </>
            ) : null}
          </Col>
        </Row>
      </Form>
    </div>
  );
}
