import { Col, Divider, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
export const AboutUs = () => {
  return (
    <>
      {/* <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="webPageHeaderTextAboutUs">
          <p className="headertextAboutUs">About Kalinga Freight Solutions (KFS)</p>
        </Col>
      </Row> */}
      <Row justify="center" align="middle">
        <div className="center-text">
          <h1 className="mtb header-fs">Our Journey </h1>
          <p className="textColor mtb sub-header-fs"> How Kasangga came to be</p>
        </div>
      </Row>
      <div className="bodyAboutUs">
        <Row gutter={0} align="middle" className="textColor">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
            <p>
              Welcome to the story behind{' '}
              <b>
                <i>Kasangga</i>
              </b>
              , a journey born out of the necessity to alleviate the struggles faced by small businesses like yours.
            </p>
            <p>
              <b> The seed of an idea.</b> As a small business owner myself, specifically running a water refilling
              station, I intimately understood the challenges of manual monitoring. Tracking sales, managing expenses,
              and overseeing employees was not just difficult; it was often overwhelming. The reliance on Excel sheets
              was a common practice, but it came with its own set of issues, including data corruption and
              inefficiencies.
            </p>
            <p>
              <b> Identifying a common struggle.</b> Observing fellow small and medium-sized businesses grappling with
              the same problems further fueled the need for a solution. However, the reality was stark: many
              couldn&apos;t afford the expensive systems or apps available in the market to automate and monitor their
              operations effectively. inefficiencies.
            </p>
            <p>
              <b> A solution takes shape.</b> With a background in software engineering, I recognized an opportunity to
              bridge this gap. Drawing from my expertise, I embarked on a journey to create an app that would serve as
              an ally to businesses like ours-a companion in navigating the complexities of daily operations.
            </p>
            <p>
              <b> The birth of Kasangga.</b> Thus, Kasangga was born—a comprehensive business management platform
              designed to empower small and medium-sized enterprises. Rooted in Filipino values, Kasangga embodies the
              spirit of
              <b>
                <i> &quot;Kasangga&quot;</i>
              </b>{' '}
              - being a trusted ally and partner to businesses, rooted in the Filipino tradition of collaboration,
              support, and camaraderie. From point of sale to employee management, expenses tracking to data analytics,
              Kasangga aimed to streamline operations and foster growth for businesses of all sizes.
            </p>
            <p>
              <b> Empowering businesses together.</b> Today, Kasangga stands as a testament to the power of innovation
              and determination. With a commitment to providing accessible, user-friendly solutions, we continue to
              empower businesses like yours, helping them thrive in an ever-evolving landscape.
            </p>
            <p>
              <b> Our journey doesn&apos;t end here.</b> As we look ahead, we remain dedicated to our mission of
              supporting businesses in reaching their full potential. With Kasangga by your side, consider us your
              partner in success—a testament to what can be achieved when passion meets purpose.
            </p>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={11} lg={11} xl={11} className=" padding-smlV">
            <h2 className="aboutVMH1">Our Mission</h2>
            <p className="textColor aboutVM">
              To empower businesses of all sizes by providing innovative, user-friendly solutions that streamline
              operations, foster growth, and drive success in today&apos;s dynamic marketplace.
            </p>
          </Col>
          {/* <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col> */}
          <Col xs={24} sm={12} md={12} lg={12} xl={11} className=" padding-smlV">
            <h2 className="aboutVMH1">Our Vision</h2>
            <p className="textColor aboutVM">
              To be the trusted ally and partner to businesses worldwide, rooted in the Filipino tradition of
              collaboration, support, and camaraderie, while continually innovating and expanding our solutions to meet
              the evolving needs of our clients.
            </p>
          </Col>
        </Row>
        <br />
      </div>
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
