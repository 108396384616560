import './App.less';
import RootLayout from '@Layout/RootLayout/RootLayout';
import { Route, Switch } from 'react-router-dom';
import { RouteConfig } from 'react-router-config';
// import { IdleChecker } from '@features/Login/components/IdleChecker';
import { ConfigProvider } from 'antd';

export const RecursiveSubRoutes = (route: RouteConfig) => {
  const { routes, path, exact } = route;
  return (
    <>
      <Route key={`route-${path}`} path={path} exact={exact} component={route.component} />
      {routes?.map((route: RouteConfig) => {
        const { path, exact, component, routes, displayTitle } = route;
        {
          return (
            <Switch key={`sub-${path}`}>
              <Route path={path} exact={exact} component={component} />
              <Switch>
                {routes && routes.map((subroute) => <RecursiveSubRoutes key={displayTitle} {...subroute} />)}
              </Switch>
            </Switch>
          );
        }
      })}
    </>
  );
};

function App() {
  return (
    // <IdleChecker>
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            colorBgHeader: '#fff',
          },
        },
      }}
    >
      <div className="App">
        <Switch>
          <Route>
            <RootLayout />
          </Route>
        </Switch>
      </div>
    </ConfigProvider>
    // </IdleChecker>
  );
}

export default App;
