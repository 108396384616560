import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BookingStatus } from 'common/constants';
import {
  BoxContentModel,
  BoxDetailsModel,
  PerksModel,
  ReceiverModel,
  ReferralResponse,
  SenderModel,
  TransactionsHistoryViewModel,
  TransactionsSaveResponse,
} from 'common/models/BookingTypes';
import { OperationStatus } from 'store/rootTypes';

const name = 'OrderBox';

interface IOrderBoxState {
  submitStatus: OperationStatus;
  Sender?: SenderModel;
  Receiver?: ReceiverModel;
  BoxContent?: BoxContentModel;
  BoxDetails?: BoxDetailsModel;
  BookingStatus: typeof BookingStatus.New | typeof BookingStatus.Update;
  TransactionsSaveResponse?: TransactionsSaveResponse;
  TransactionHistory?: TransactionsHistoryViewModel[];
  IsSendABox: boolean;
  Perks?: PerksModel;
  ReferralResponse?: ReferralResponse;
  IsFromSignupKFS: boolean;
  FromSignupExistingUserId?: string;
}

const initialState: IOrderBoxState = {
  submitStatus: OperationStatus.idle,
  BoxContent: undefined,
  Sender: undefined,
  Receiver: undefined,
  BoxDetails: undefined,
  BookingStatus: BookingStatus.Idle,
  TransactionsSaveResponse: undefined,
  TransactionHistory: undefined,
  IsSendABox: false,
  Perks: undefined,
  ReferralResponse: undefined,
  IsFromSignupKFS: false,
  FromSignupExistingUserId: undefined,
};

export const OrderBoxSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetBookingStatus: (state, action: PayloadAction<typeof BookingStatus.New | typeof BookingStatus.Update>) => {
      state.BookingStatus = action.payload;
    },
    SetIsSendABox: (state, action: PayloadAction<boolean>) => {
      state.IsSendABox = action.payload;
    },
    SetIsFromSignupKFS: (state, action: PayloadAction<boolean>) => {
      state.IsFromSignupKFS = action.payload;
    },
    SetFromSignupExistingUserId: (state, action: PayloadAction<string>) => {
      state.FromSignupExistingUserId = action.payload;
    },
    SetSender: (state, action: PayloadAction<SenderModel>) => {
      state.Sender = {
        ...state.Sender,
        ...action.payload,
      };
    },
    SetReceiver: (state, action: PayloadAction<ReceiverModel>) => {
      state.Receiver = action.payload;
    },
    SetBoxContent: (state, action: PayloadAction<BoxContentModel>) => {
      state.BoxContent = action.payload;
    },
    SetBoxDetails: (state, action: PayloadAction<BoxDetailsModel>) => {
      state.BoxDetails = action.payload;
    },
    SetPerks: (state, action: PayloadAction<PerksModel>) => {
      state.Perks = action.payload;
      // console.log(state.Perks);
    },
    SetReferralResponse: (state, action: PayloadAction<ReferralResponse>) => {
      state.ReferralResponse = action.payload;
    },
    SetTransactionHistory: (state, action: PayloadAction<TransactionsHistoryViewModel[]>) => {
      state.submitStatus = OperationStatus.succeeded;
      state.TransactionHistory = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state, action: PayloadAction<TransactionsSaveResponse>) => {
      state.submitStatus = OperationStatus.succeeded;
      state.TransactionsSaveResponse = action.payload;
    },
    submitStatusSucceededNoPayload: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: OrderBoxActions } = OrderBoxSlice;

export default OrderBoxSlice.reducer;
