import { APIResponse, PostData } from 'common/utils/jsonFetch';
import { TrackingActions } from '../store/TrackingSlice';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { APIResponseCode } from 'common/constants';
import { Validator } from 'common/validators/validators';
import { message } from 'antd';
import { TrackingStatusResponse } from 'common/models/TrackingTypes';

export const GetTrackingStatus = (searchString: string, IsFromHomeSearch: boolean) => {
  return async function GetTrackingStatus(dispatch) {
    const methodName = 'GetTrackingStatus';
    try {
      dispatch(TrackingActions.resetState());
      dispatch(TrackingActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.Tracking_GetTrackingStatus, {
        SearchString: searchString,
        IsFromHomeSearch: IsFromHomeSearch,
      });
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      const data: TrackingStatusResponse[] = response.parsedBody?.Results;

      dispatch(TrackingActions.SetTrackingStatusResponse(data));
      dispatch(TrackingActions.submitStatusSucceeded());
    } catch (e) {
      dispatch(TrackingActions.SetTrackingStatusResponse([]));
      dispatch(TrackingActions.submitStatusSucceeded());

      throw Error();
    }
  };
};
