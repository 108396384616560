import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterType, FromPageStatus } from 'common/constants';
import {
  BookingBackModel,
  FilterSearchParameters,
  InvoiceModel,
  TransactionSearchResult,
  TransactionSummaryViewModel,
} from 'common/models/BookingTypes';
import { OperationStatus } from 'store/rootTypes';

const name = 'Transaction';

interface ITransactionState {
  submitStatus: OperationStatus;
  FilterType: typeof FilterType.RefNo | typeof FilterType.Email | typeof FilterType.Date;
  SelectedTransId?: string;
  TransactionType?: string;
  TransactionSummary?: TransactionSummaryViewModel;
  Invoice?: InvoiceModel;
  FromPageStatus: typeof FromPageStatus.Invoice | typeof FromPageStatus.Barcode;
  FromUpdateLink: boolean;
  FromUpdateLinkOrderABox: boolean;
  BookingBackData?: BookingBackModel;
  TransactionSearchResult?: TransactionSearchResult[];
  FilterSearchParameters?: FilterSearchParameters;
  ORDateSend?: string;
}

const initialState: ITransactionState = {
  submitStatus: OperationStatus.idle,
  FilterType: FilterType.RefNo,
  SelectedTransId: undefined,
  TransactionType: undefined,
  TransactionSummary: undefined,
  Invoice: undefined,
  FromPageStatus: FromPageStatus.Invoice,
  FromUpdateLink: false,
  FromUpdateLinkOrderABox: false,
  BookingBackData: undefined,
  TransactionSearchResult: undefined,
  FilterSearchParameters: undefined,
};

export const TransactionSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetFilterType: (
      state,
      action: PayloadAction<typeof FilterType.RefNo | typeof FilterType.Email | typeof FilterType.Date>
    ) => {
      state.FilterType = action.payload;
    },
    SetSelectedTransId: (state, action: PayloadAction<string | undefined>) => {
      state.SelectedTransId = action.payload;
    },
    SetORDateSend: (state, action: PayloadAction<string | undefined>) => {
      state.ORDateSend = action.payload;
    },
    SetTransactionType: (state, action: PayloadAction<string | undefined>) => {
      state.TransactionType = action.payload;
    },
    SetTransactionSummary: (state, action: PayloadAction<TransactionSummaryViewModel>) => {
      state.TransactionSummary = action.payload;
      state.Invoice = state?.TransactionSummary.Invoice;
    },
    SetInvoice: (state, action: PayloadAction<InvoiceModel>) => {
      state.Invoice = action.payload;
    },
    SetTransactionSearchResult: (state, action: PayloadAction<TransactionSearchResult[]>) => {
      state.TransactionSearchResult = action.payload;
    },
    SetFilterSearchParameters: (state, action: PayloadAction<FilterSearchParameters>) => {
      // console.log(JSON.stringify(action.payload));
      state.FilterSearchParameters = action.payload;
    },
    SetFromStatus: (state, action: PayloadAction<typeof FromPageStatus.Invoice | typeof FromPageStatus.Invoice>) => {
      state.FromPageStatus = action.payload;
    },
    SetFromUpdateLink: (state) => {
      state.FromUpdateLink = true;
    },
    SetFromUpdateLinkOrderABox: (state) => {
      state.FromUpdateLinkOrderABox = true;
    },
    SetFromUpdateLinkFalse: (state) => {
      state.FromUpdateLink = false;
    },
    SetBookingBackData: (state, action: PayloadAction<BookingBackModel>) => {
      state.BookingBackData = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    submitStatusRejected: (state) => {
      state.submitStatus = OperationStatus.failed;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: TransactionActions } = TransactionSlice;

export default TransactionSlice.reducer;
