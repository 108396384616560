import { PATHS } from '@routing/routes';
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, Table } from 'antd';
import { BookingTypeCodeList, ContainerStatusTypeCodeList } from 'common/code/BookingCode';
import { Constants } from 'common/constants';
import { ContainerViewModel } from 'common/models/ContainerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { InterStateSelector } from '../store/InterStateSelector';
import { OperationStatus } from 'store/rootTypes';
import {
  DeleteInterState,
  GetAll,
  GetAllInterStateDetailsId,
  Save,
  SaveInterStateDetails,
} from '../business/InterStateBusiness';
import { TableLocaleCustom } from '@components/Custom/CustomTable';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { InterStateActions } from '../store/InterStateSlice';
import moment from 'moment';
import { formatContainerTimestamp } from 'common/utils/utility';
import { PlusCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { InterStateDetailsViewModel, InterStateViewModel } from 'common/models/InterStateTypes';
import dayjs from 'dayjs'; // Import dayjs
import {
  ContainerDepartedCodeList,
  ContainerDepartedToCodeList,
  ContainerTypeCodeList,
  InterStateDepartedCodeList,
  InterStateDepartedToCodeList,
  InterStateTypeCodeList,
} from 'common/code/DestinationCode';

export const InterStateStatus = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const interState = useSelector(InterStateSelector);
  let data: InterStateDetailsViewModel[] = interState.InterStateDetailsList || [];

  const onFinish = async (values: InterStateDetailsViewModel) => {
    if (selectedInterStateDetails?.Id) {
      values.Id = selectedInterStateDetails?.Id ?? '';
    }
    values.InterStateId = interState.InterState?.Id ?? '';
    await dispatch(SaveInterStateDetails(values));

    form.resetFields();
  };

  const handleReferenceClick = async (id) => {
    history.push(PATHS.ContainerProfile.path);
  };

  const handleLoadClick = async (id) => {
    history.push(PATHS.ContainerLoading.path);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (interState.InterState?.Id) {
        await dispatch(GetAllInterStateDetailsId(interState.InterState?.Id));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    data = interState.InterStateDetailsList || [];
  }, [interState.InterStateDetailsList]);

  const showConfirmDelete = () => {
    alert('');
  };

  const getInterStateTypeCodeTypeName = (code) => {
    const type = InterStateTypeCodeList.find((item) => item.code === code);
    return type ? type.name : '';
  };

  const getInterStateFromCodeTypeName = (code) => {
    const type = InterStateDepartedCodeList.find((item) => item.code === code);
    return type ? type.name : '';
  };

  const getInterStatToCodeTypeName = (code) => {
    const type = InterStateDepartedToCodeList.find((item) => item.code === code);
    return type ? type.name : '';
  };

  const sequenceExists = data.some((item) => item.Sequence !== undefined);
  const columns = [
    {
      title: 'Sequence',
      dataIndex: 'Sequence',
      key: 'Sequence',
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      render: (text, record) => getInterStateTypeCodeTypeName(record.Type),
    },
    {
      title: 'From',
      dataIndex: 'From',
      key: 'From',
      render: (text, record) => getInterStateFromCodeTypeName(record.From),
    },
    {
      title: 'To',
      dataIndex: 'To',
      key: 'To',
      render: (text, record) => getInterStatToCodeTypeName(record.To),
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      render: (text) => formatContainerTimestamp(text),
    },
    {
      title: 'Action',
      dataIndex: 'Load',
      key: 'Load',
      render: (text, record) => (
        <span>
          <a onClick={() => handleInterStateDetailsUpdateClick(record)}>Update</a> |{' '}
          <a onClick={() => showConfirm(record)}>Delete</a>
        </span>
      ),
    },
  ];
  const { confirm } = Modal;
  const showConfirm = (details: InterStateDetailsViewModel) => {
    //Shift yes or no to satisfy the req. will refactor next time
    form.resetFields();
    confirm({
      title: 'Do you want to delete this status?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'No',
      cancelText: 'Yes',
      okButtonProps: { className: 'ant-btn-default-okcancelbooking' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      async onCancel() {
        await dispatch(DeleteInterState(details.Id));
        if (interState.InterState?.Id) {
          await dispatch(GetAllInterStateDetailsId(interState.InterState?.Id));
        }

        data = interState.InterStateDetailsList || [];
      },
      async onOk() {
        form.resetFields();
      },
    });
  };
  const convertToDayjs = (date) => {
    return date ? dayjs(date) : null;
  };
  const [selectedInterStateDetails, setInterStateDetails] = useState<InterStateDetailsViewModel | null>(null);
  const handleInterStateDetailsUpdateClick = (state: InterStateDetailsViewModel) => {
    // Handle the update logic here
    // For example, you can set the selected container to be used in a form for updating
    setInterStateDetails(state);
    form.setFieldsValue({
      Type: state.Type,
      Sequence: state.Sequence,
      From: state.From,
      To: state.To,
      Date: convertToDayjs(state.Date),
    });
  };

  const onCancel = () => {
    form.resetFields();
    setInterStateDetails(null);
  };
  return (
    <div className="App">
      {interState.InterState && (
        <Form name="containerForm" onFinish={onFinish} form={form} layout="vertical">
          <br />
          <br />
          <br />
          <Card className="card-custom">
            <Row justify="center" align="middle">
              <h1>Interstate status</h1>
            </Row>

            <Row justify="center" align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table dataSource={data} columns={columns} locale={TableLocaleCustom} />
              </Col>
            </Row>

            <>
              <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <h1>{selectedInterStateDetails?.Id ? 'Update status' : 'Add status'} </h1>
                  <Row gutter={[10, 0]}>
                    <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                      <Form.Item
                        label="Sequence"
                        name="Sequence"
                        rules={[
                          { required: true, message: Constants.REQUIRED_FIELD },
                          {
                            validator: (_, value) => {
                              if (
                                sequenceExists &&
                                data.some((item) => item.Sequence === value && item.Id != selectedInterStateDetails?.Id)
                              ) {
                                return Promise.reject('Sequence already exist');
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                          {Array.from({ length: 2 }, (_, index) => (
                            <Select.Option key={index + 1} value={index + 1}>
                              {index + 1}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                      <Form.Item
                        label="Type"
                        name="Type"
                        rules={[
                          { required: true, message: Constants.REQUIRED_FIELD },
                          {
                            validator: (_, value) => {
                              if (
                                sequenceExists &&
                                data.some((item) => item.Type === value && item.Id != selectedInterStateDetails?.Id)
                              ) {
                                return Promise.reject('Type already exist');
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}

                        // initialValue={containerState.Container?.From}
                      >
                        <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                          {InterStateTypeCodeList.map((x) => (
                            <Select.Option key={x.code} value={x.code}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[10, 0]}>
                    <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                      <Form.Item
                        label="From"
                        name="From"
                        rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                        // initialValue={containerState.Container?.From}
                      >
                        <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                          {InterStateDepartedCodeList.map((x) => (
                            <Select.Option key={x.code} value={x.code}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                      <Form.Item
                        label="To"
                        name="To"
                        rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                        // initialValue={containerState.Container?.From}
                      >
                        <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                          {InterStateDepartedToCodeList.map((x) => (
                            <Select.Option key={x.code} value={x.code}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[10, 0]}>
                    <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                      <Form.Item
                        label="Date"
                        name="Date"
                        rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                      >
                        <DatePicker
                          size="large"
                          className="full-width"
                          placeholder="Date"
                          showTime={true}
                          // format="YYYY-MM-DD HH:mm:ss"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[10, 0]}>
                    <Col xs={24} sm={20} md={12} lg={10} xl={10}>
                      <Button className="prevSenderBtn" size="large" type="default" onClick={onCancel}>
                        {Constants.BTN_CANCEL}
                      </Button>
                      <Button
                        className="nextSenderBtn"
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={interState.submitStatus == OperationStatus.pending}
                      >
                        {selectedInterStateDetails?.Id ? Constants.BTN_UPDATE : Constants.BTN_SAVE}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>

            <br />
            <a onClick={() => history.push(PATHS.InterStateListing.path)} className="underline">
              Back to listing
            </a>
          </Card>
        </Form>
      )}
    </div>
  );
};
